/* eslint-disable */
import React from "react";
import { useEffect, Dispatch } from "react";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from "@material-ui/icons/Cancel";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import "./SpecialPriceRequest.css"
import Badge from '@material-ui/core/Badge';
import ViewWriteComments from "./ViewWriteComments";
import { useSelector } from 'react-redux';
import RejectPartDialog from "./RejectPartDialog";
import RejectPriceRequestDialog from "./RejectPriceRequestDialog";
import "./ViewPriceRequest.css"
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ViewJustification from "./ViewJustification";
import XLSX, { read, readFile, utils } from "xlsx";
import { addComment, getCommentPart, getCommentTotal } from "../../store/actions/specialprice.actions";
import { useDispatch, } from "react-redux";
import moment from "moment";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import ViewWriteCommentsTotal from "./ViewWriteCommentsTotal";
import ForwardToKamDialog from "./ForwardToKamDialog";
import _, { get } from "lodash";
import { getSpecialPrice, postForwardRequestToKam, postSaveFormData, userAccesssRestriction, checkandavaliabilityApi, checkAvailabilityResetData } from "../../store/actions/specialprice.actions";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { Prompt } from "react-router-dom"
import GetAppIcon from "@material-ui/icons/GetApp";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from "@material-ui/icons/Help";
import ReopnedStatusDialog from "./RequestStatusDialog";
import { getSpecialPriceStatus, storeOrderNumberPT, viewSpecialPriceAPI } from "../../store/actions/specialprice.actions";
import CommentApproveDialog from "./CommentApproveDialog";
import NavigationPrompt from "react-router-navigation-prompt";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Dialog } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import RequestCorrection from "./RequestCorrection";
import { requestCorrection } from '../../store/actions/specialprice.actions';
import Documents from "./Documents";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: 'black',
    height: "15px",
    width: "15px",
    // cursor: "default"
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  logoutText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  actionButtonEye: {
    color: '#1A9FE0',
  },
  actionButtonComment: {
    color: '#1A9FE0',
    height: "20px",
    width: "20px"
  },
}))

const styles = makeStyles({
  notchedOutline: { borderColor: "rgba(224, 224, 224, 1) !important", borderRadius: "25px 0px 0px 25px" },
  root: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "blue",
      height: "14px",
      fontSize: "11px"
    },

  },
  root2: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "black",
      height: "14.2px",
      fontSize: "",

    },
  },
  root3: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "black",
      height: "14px",
      fontSize: "11px",

    },

  },
  notchedOutline1: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "0px 0px 0px 0px", },
  notchedOutline2: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "0px 25px 25px 0px", background: "", color: "" },
})

const isBluelegend = (ele: any) => {
  let airPrice: any = ele.airOfferedPriceType == "byCurrency" ? parseFloat(ele.airOfferedPrice) : parseFloat(ele.airOfferedCalculatedPrice)
  let nowPrice: any = ele.nowOfferedPriceType == "byCurrency" ? parseFloat(ele.nowOfferedPrice) : parseFloat(ele.nowOfferedCalculatedPrice)
  if (ele.nowAvailabilityChecked && ele.airAvailabilityChecked) {
    if (((parseFloat(ele.nspev)) > parseFloat(nowPrice)) || (parseFloat(ele.aop) > airPrice)) {
      return true;
    } else {
      return false;
    }
  } else if (ele.nowAvailabilityChecked) {
    if ((parseFloat(ele.nspev) > nowPrice)) {
      return true;
    } else {
      return false;
    }
  } else if (ele.airAvailabilityChecked) {
    if ((parseFloat(ele.aop) > airPrice)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

const ViewPriceRequest: React.FC = () => {
  const classes = useStyles();
  const history: any = useHistory()
  const [open, setOpen] = React.useState(false);
  const [openRejectPart, setOpenRejectPart] = React.useState(false);
  const [openPriceRequest, setOpenPriceRequest] = React.useState(false);
  const [openJustfication, setOpenJustification] = React.useState(false);
  const [percentage, setPercentage] = React.useState("%0");
  const [offeredBy, setOfferedBy] = React.useState("Parts");
  /* special price dropdown*/
  const [valuePriceNow, setValuePriceNow] = React.useState<any>([]);
  const [valueSelectPriceNow, setValueSelectPriceNow] = React.useState<any>([]);
  const [valueCalculatedNow, setValueCalculatedNow] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<any>('');
  const [valuePriceBo, setValuePriceBo] = React.useState<any>([]);
  const [valueSelectPriceBo, setValueSelectPriceBo] = React.useState<any>([]);
  const [valueCalculatedBo, setValueCalculatedBo] = React.useState<any>([]);
  const [select, setSetSelect] = React.useState<any>([]);
  const [selectByTotal, setSelectByTotal] = React.useState<any>("");
  const [offeredTotalAED, setOfferedTotalAED] = React.useState<any>("");
  const [offeredTotalPercent, setOfferedTotalPercent] = React.useState<any>("");
  const [calculatedTotal, setTotalCalculatedTotal] = React.useState<any>("");
  const [totalError, setTotalError] = React.useState<any>(false);
  const [totalOfferedAED, setTotalOfferedAED] = React.useState<any>("")
  const [excelError, setExcelError] = React.useState<any>("");
  const [importDataExcel, setImportDataExcel] = React.useState<any>([]);
  const [isImport, setIsImport] = React.useState<boolean>(false);
  const [id, setId] = React.useState<any>("")
  const [uid, setUid] = React.useState<any>("")
  const [openTotal, setOpenTotal] = React.useState(false);
  const [sapId, setSapId] = React.useState("")
  const [total, setTotal] = React.useState<any>("")
  const [discountTotal, setDiscountTotal] = React.useState<any>("")
  const [isDiscountPricePart, setIsDiscountPricePart] = React.useState<boolean>(false)
  const [totalComment, setTotalComment] = React.useState(0);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { viewSpecialPrice, checkandavailibilityData, orderno, forwardRequestToKam, saveFormData } = useSelector((state: any) => state.specialPrice)
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [responseSuccess, setResponseSuccess] = React.useState<boolean>(false);
  const sapCustomerId = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
  const classes2 = styles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [productData, setProductData] = React.useState<any>();
  const [responseDetails, setResponseDetails] = React.useState<any>();

  const [responseIDData, setResponseIDData] = React.useState<any>();
  const b2bUserId = _.get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const [showReopenDialog, setShowReopenDialog] = React.useState<boolean>(false);

  const [estimateGrandTotal, setEstimateGrandTotal] = React.useState<any>();
  const [requestTotalPriceType, setRequestTotalPriceType] = React.useState<any>();
  const [requestTotalPriceCurrency, setRequestTotalPriceCurrency] = React.useState<any>();
  const [requestTotalPrice, setRequestTotalPrice] = React.useState<any>();
  const [requestTotalPriceCalculated, setRequestTotalPriceCalculated] = React.useState<any>();
  const [responseStatus, setResponseStatus] = React.useState<any>();
  const [requestedTotalParts, setRequestedTotalParts] = React.useState<any>("")
  const [RequestedTotalPartsTotal, setRequestedTotalPartsTotal] = React.useState<any>("")
  const [requestedTotal, setRequestedTotal] = React.useState<any>("")
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const [B2bEnquiryId, setB2bEnquiryId] = React.useState<any>();
  const [sapCustomerIdData, setSapCustomerIdData] = React.useState<any>();
  const [offeredSaved, setOfferedSaved] = React.useState(false);
  const [offeredTotalData, setOfferedTotalData] = React.useState<any>();
  const [dataUpdatedPrompt, setDataUpdatedPrompt] = React.useState<boolean>(false);
  const b2cUserId = get(
    profileDetails,
    "data.data.basicInfo.b2cUserId",
    ""
  );
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [nowPricevalid, setNowPricevalid] = React.useState<any>();
  const [rejectPartRemoveValidation, setRejectPartRemoveValidation] = React.useState<any>();
  const [boPricevalid, setBoPricevalid] = React.useState<any>();
  const [textRejectPartError, setTextRejectPartError] = React.useState<boolean>(false);
  const [textRejectPartErrorMessage, setTextRejectPartErrorMessage] = React.useState<boolean>(false)
  const [nowAndBoValue, setNowAndBoValue] = React.useState<Array<any>>([]);
  const [nowValue, setNowValue] = React.useState<any>();
  const [boValue, setBoValue] = React.useState<any>();
  const [UID, setUID] = React.useState<any>();
  const [isSaved, setIsSaved] = React.useState<any>(false);
  const [isSavedTotal, setIsSavedTotal] = React.useState<any>(false);
  const [quantity, setQuantity] = React.useState<any>()
  const [requestValue, setRequestValue] = React.useState<any>("")
  const [respId, setRespId] = React.useState<any>();
  const [openApproveCommentDialog, setCopenApproveCommentDialog] = React.useState<boolean>(false);
  const [removeValidationPopup, setRemoveValidationPopup] = React.useState<boolean>(false);
  const [iscorrectionDisabled, setIsCorrectionDisabled] = React.useState<boolean>(false)
  const [openCorrection, setOpenCorrection] = React.useState(false)
  const [requestCorrections, setRequestCorrections] = React.useState(0)
  const [url, setUrl] = React.useState([])
  const [openDocuments, setOpenDocuments] = React.useState(false)
  const [reject, setReject] = React.useState(false);



  const orgIdBrandsMapping: any = {
    "2001": "Toyota",
    "2002": "Honda",
    "2003": "Famco",
    "2380": "TE",
    "2195": "OES",
    "7099": "Toyota"
  }


  React.useEffect(() => {
    let item: any = []

    if (viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.length >= 1 && importDataExcel.length >= 1) {

      let len = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products;
      let item: any = []
      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.forEach((ele: any, i: any) => {
        let errorNow = false
        let errorBo = false
        let valueNow: any = []
        let valueBo: any = []
        let valueAEDNow: any = 0
        let valueAEDBo: any = 0
        valueAEDNow = ((parseFloat(ele.nspev) - parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"])) / parseFloat(ele.nspev));
        valueAEDNow = (valueAEDNow * 100).toFixed(2) + "%"
        valueNow.push(valueAEDNow)
        valueAEDBo = (parseFloat(ele.aop) - parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"])) / parseFloat(ele.aop);
        valueAEDBo = (valueAEDBo * 100).toFixed(2) + "%"
        valueBo.push(valueAEDBo)
        let requestValueNow: any = ele.nowRequestPrice ? ele.nowRequestPriceType == "byCurrency" ? `${ele.nowRequestPrice}` : `${ele.nowRequestCalculatedPrice}` : ""
        let requestValueBo: any = ele.airRequestPrice ? ele.airRequestPriceType == "byCurrency" ? `${ele.airRequestPrice}` : `${ele.airRequestCalculatedPrice}` : ""

        if (parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"
        ]) >= parseFloat(ele.nspev) || parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"
        ]) < parseFloat(requestValueNow)) {
          errorNow = true
        } else if (parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"]) > parseFloat(ele.aop) || parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"]) < parseFloat(requestValueBo)) {
          errorBo = true
        }
        item.push({
          value: "By AED", priceAED: ele.nowAvailabilityChecked && ele.isSpecialPriced === false ? importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"] ? parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"]) : "" : "", priceAEDExcel: ele.nowAvailabilityChecked ? importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"] ? importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"] : "" : "", pricePercent: "", priceGreaterThanNow: "",
          errorNow: ele.nowAvailabilityChecked ? parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"]) > parseFloat(ele.nspev) || parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"]) < parseFloat(requestValueNow) ? true : false : false, valueBo: "By AED", calcuatedPrice: ele.nowAvailabilityChecked ? importDataExcel && importDataExcel[i] && importDataExcel[i]["Now Offered Price"] ? valueAEDNow : "" : "", priceBo: valueAEDNow,
          priceBoAED: ele.airAvailabilityChecked ? importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"] ? parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"]) : "" : "", priceBoAEDExcel: ele.airAvailabilityChecked ? importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"] ? importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"] : "" : "", priceBoPercent: "", priceGreaterThanBo: "", calcuatedPriceBo: valueAEDBo,
          errorBo: ele.airAvailabilityChecked ? parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"]) > parseFloat(ele.aop) || parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["BO Offered Price"]) < parseFloat(requestValueBo) ? true : false : false
        })
      })






      if (isImport) {
        setValuePriceNow(item);
        setValueSelectPriceNow(item);
        setValueCalculatedNow(item);
        setValuePriceBo(item);
        setValueSelectPriceBo(item);
        setValueCalculatedBo(item);
      }
    }
  }, [importDataExcel, isImport])

  useEffect(() => {
    let nowAvaliabilityValue: any
    let boAvaliabilityValue: any
    let nowAndAirAvailabilityValue: Array<any> = []

    checkandavailibilityData && checkandavailibilityData.data && checkandavailibilityData.data.data && checkandavailibilityData.data.data.map((subEnquiries: any, i: any) => {
      return (
        <>
          {subEnquiries &&
            subEnquiries["verifiedData"] &&
            subEnquiries["verifiedData"]["confirmations"] &&
            Array.isArray(subEnquiries["verifiedData"]["confirmations"]) &&
            subEnquiries["verifiedData"]["confirmations"].map((confirmationsingle: any) => {
              nowAvaliabilityValue = confirmationsingle && confirmationsingle.deliveryBucketId === "NOW" ? confirmationsingle && confirmationsingle.availableQuantityforProposedArticle : "0"
              boAvaliabilityValue = confirmationsingle && confirmationsingle.deliveryBucketId === "AIR" ? confirmationsingle && confirmationsingle.availableQuantityforProposedArticle : "0"

              nowAndAirAvailabilityValue.push({
                nowAvaliabilityValue, boAvaliabilityValue
              })
            })}
        </>
      )
    })
    setNowAndBoValue(nowAndAirAvailabilityValue)
    setNowValue(nowAvaliabilityValue)
    // setBoValue(boAvaliabilityValue)
  }, [checkandavailibilityData])

  useEffect(() => {
    return () => {
      dispatch(checkAvailabilityResetData());
    }
  }, [])

  useEffect(() => {
    let responseID: any
    let responseUpdatedByPricingTeam: any
    let offeredTotalPriceData: any
    let offeredTotalPriceCurrencyData: any
    let requestTotalPriceCalculatedData: any
    let requestTotalPriceData: any
    let requestTotalPriceCurrencyData: any
    let requestTotalPriceTypeData: any
    let estimateGrandTotalData: any
    let statusData: any
    let sapIDData: any
    let B2bEnquiryIdData: any

    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data &&
      viewSpecialPrice.data.data.data && (
        <>
          {responseID = viewSpecialPrice?.data?.data?.data?.id}
          {requestTotalPriceCalculatedData = viewSpecialPrice?.data?.data?.data?.requestTotalPriceCalculated}
          {requestTotalPriceData = viewSpecialPrice?.data?.data?.data?.requestTotalPrice}
          {requestTotalPriceCurrencyData = viewSpecialPrice?.data?.data?.data?.requestTotalPriceCurrency}
          {requestTotalPriceTypeData = viewSpecialPrice?.data?.data?.data?.requestTotalPriceType}
          {estimateGrandTotalData = viewSpecialPrice?.data?.data?.data?.estimateGrandTotal}
          {statusData = viewSpecialPrice?.data?.data?.data?.status}
          {B2bEnquiryIdData = viewSpecialPrice?.data?.data?.data?.B2bEnquiryId}
          {sapIDData = viewSpecialPrice?.data?.data?.data?.sapCustomerId}
        </>
      )
    setResponseIDData(responseID)
    setEstimateGrandTotal(estimateGrandTotalData)
    setRequestTotalPriceType(requestTotalPriceTypeData)
    setRequestTotalPriceCurrency(requestTotalPriceCurrencyData)
    setRequestTotalPrice(requestTotalPriceData)
    setRequestTotalPriceCalculated(requestTotalPriceCalculatedData)
    setResponseStatus(statusData)
    setB2bEnquiryId(B2bEnquiryIdData)
    setSapCustomerIdData(sapIDData)
  }, [])

  useEffect(() => {
    let quantitiyStore: any = []
    let responseData: any
    let nowPricevalidation: any
    let airpriceValidation: any
    let partRemoveValidation: any


    let offeredType: any = ""
    let quantity: any = 0
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
      return (
        <>
          {partRemoveValidation = ele.isRejected}
          {nowPricevalidation = valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"])}
          {airpriceValidation = valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]}
        </>
      )
    })
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.forEach((ele: any, i: any) => {
      quantity += parseFloat(ele.nowAvailability) + parseFloat(ele.airAvailability)
    })
    if (viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceType) {
      offeredType = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceType
    } else if (viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestedByType) {
      offeredType = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestedByType
    }
    setRejectPartRemoveValidation(partRemoveValidation)
    setNowPricevalid(nowPricevalidation);
    setBoPricevalid(airpriceValidation);
    if (quantity >= 1) {
      setQuantity(quantity)
    }
    setRequestValue(offeredType);

    if (offeredType) {
      if (offeredType == "part") {
        setOfferedBy("Parts")
      } else {
        setOfferedBy("Total")
      }
    }
    setRequestValue(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestedByType)
    let callData = async () => {
      let data: any = await dispatch(requestCorrection(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.id))
      setRequestCorrections(data && data.data && data.data.history.length)
      if (data && data.data && data.data.history.length >= 1) {
        setIsCorrectionDisabled(false)
      } else {
        setIsCorrectionDisabled(true)
      }
    }
    callData()
    let url: any = []
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.attachments.forEach((ele: any) => {
      url.push({ name: ele.url })
    })
    setUrl(url)
  }, [viewSpecialPrice])

  useEffect(() => {
    let quantitiyStore: any = []
    let responseData: any

    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
      return (
        <>
          {quantitiyStore.push({
            "uid": ele.uid,

            "nowRequestPriceType": ele && ele.nowRequestPriceType ? ele && ele.nowRequestPriceType : "",
            "nowRequestPrice": ele && ele.nowRequestPrice ? ele && ele.nowRequestPrice : "",
            "nowRequestCalculatedPrice": ele && ele.nowRequestCalculatedPrice ? ele && ele.nowRequestCalculatedPrice : "",

            "airRequestPriceType": ele && ele.airRequestPriceType ? ele && ele.airRequestPriceType : "",
            "airRequestPrice": ele && ele.airRequestPrice ? ele && ele.airRequestPrice : "",
            "airRequestCalculatedPrice": ele && ele.airRequestCalculatedPrice ? ele && ele.airRequestCalculatedPrice : "",

            "nowOfferedPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "nowOfferedPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
            "nowOfferedCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "",

            "airOfferedPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["value"] && valueSelectPriceBo[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "airOfferedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"],
            "airOfferedCalculatedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "",
          })}
        </>
      )
    })
    setProductData(quantitiyStore)
  }, [viewSpecialPrice])


  useEffect(() => {
    let len = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.length;
    let item: any = []
    let total: any = 0
    let discountTotal: any = 0;
    let isDiscountPart: any = [];
    let commentTotal: any = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.commentOnTotal && viewSpecialPrice.data.data.data.commentOnTotal && viewSpecialPrice.data.data.data.commentOnTotal.length ? viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.commentOnTotal.length : 0

    //  ele.airOfferedPriceType==="byPercentage"?ele.airOfferedCalculatedPrice:ele.airOfferedPrice

    // for (let i = 0; i < len; i++) {
    //     item.push({ value: "By AED", priceAED: "", pricePercent: "", priceGreaterThanNow: "", errorNow: false, valueBo: "By AED", caluclatedPrice: "233", calculatedPriceGreaterNow: "", priceBo: "", priceBoAED: "", priceBoPercent: "", priceGreaterThanBo: "", calcuatedPriceBo: "", errorBo: false, calculatedPriceGreaterThanBo: "" })
    // }



    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any) => {
      let valueNow: any = ((ele.nspev) - parseFloat(ele.nspev)) / parseFloat(ele.nspev)
      valueNow = (parseFloat(valueNow) * 100).toFixed(2) + "%"

      let valueBo: any = ((ele.aop) - parseFloat(ele.aop)) / parseFloat(ele.aop)
      valueBo = (parseFloat(valueBo) * 100).toFixed(2) + "%"

      item.push(
        {
          value: ele && ele.nowOfferedPriceType ? ele && ele.nowOfferedPriceType === "byPercentage" ? "By %" : "By AED" : "By AED",
          priceAED: ele.nowOfferedPriceType !== "byPercentage" ? ele.nowOfferedPrice && !ele.isRejected && ele.nowRequestPrice ? ele.nowOfferedPrice : ele.nowOfferedPrice && ele.isRejected && ele.nowRequestPrice ? ele.nspev : "" : "",
          pricePercent: ele.nowOfferedPriceType === "byPercentage" ? ele.nowOfferedPrice : "",
          priceGreaterThanNow: "",
          errorNow: false,
          valueBo: ele && ele.airOfferedPriceType ? ele && ele.airOfferedPriceType === "byPercentage" ? "By %" : "By AED" : "By AED",
          calcuatedPrice: ele.nowOfferedPrice && !ele.isRejected && ele.nowRequestPrice ? ele.nowOfferedCalculatedPrice : ele.nowOfferedPrice && ele.isRejected && ele.nowRequestPrice ? valueNow : "",
          calculatedPriceGreaterNow: "",
          priceBo: ele && ele.airOfferedPriceType ? ele && ele.airOfferedPriceType === "byPercentage" ? "By %" : "By AED" : "By AED",
          priceBoAED: ele.airOfferedPriceType !== "byPercentage" ? ele.airOfferedPrice && !ele.isRejected && ele.airRequestPrice ? ele.airOfferedPrice : ele.airOfferedPrice && ele.isRejected && ele.airRequestPrice ? ele.aop : "" : "",
          priceBoPercent: ele.airOfferedPriceType === "byPercentage" ? ele.airOfferedPrice : "",
          priceGreaterThanBo: "",
          calcuatedPriceBo: ele.airOfferedPrice && !ele.isRejected && ele.airRequestPrice ? ele.airOfferedCalculatedPrice : ele.airOfferedPrice && ele.isRejected && ele.airRequestPrice ? valueBo : "",
          errorBo: false,
          calculatedPriceGreaterThanBo: ""
        })
    })
    if (!isImport) {
      setValuePriceNow(item);
      setValueSelectPriceNow(item);
      setValueCalculatedNow(item);
      setValuePriceBo(item);
      setValueSelectPriceBo(item);
      setValueCalculatedBo(item);
    }
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.forEach((ele: any) => {
      isDiscountPart.push(ele.isSpecialPriced);
      total += parseFloat(ele.nspev) * parseFloat((ele.nowAvailability)) + parseFloat(ele.aop) * parseFloat((ele.airAvailability))
      if (ele.isSpecialPriced == false) {
        discountTotal += parseFloat(ele.nspev) * parseFloat((ele.nowAvailability)) + parseFloat(ele.aop) * parseFloat((ele.airAvailability))
      }
    })
    setTotal(total)
    if (isDiscountPart && isDiscountPart.length > 0 && isDiscountPart.includes(true)) {
      setIsDiscountPricePart(true);
    }
    setDiscountTotal(discountTotal)
    setTotalComment(commentTotal ? commentTotal : "")
    // Requested Total
    let RequestedTotal: any = 0
    let RequestedTotalParts: any = 0
    let RequestedTotalPartsTotal: any = 0
    if (viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestTotalPriceType === "By AED") {
      if (quantity) {
        RequestedTotal = parseFloat(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestTotalPrice)
      }
    } else {
      if (quantity) {
        RequestedTotal = parseFloat(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestTotalPriceCalculated)
      }
    }
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.forEach((ele2: any) => {
      if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalParts += (parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) * (ele2.airAvailability) : 0)
      } else if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byPercentage") {
        RequestedTotalParts += (parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) * (ele2.airAvailability) : 0)
      } else if (ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalParts += (parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) * (ele2.airAvailability) : 0)
      } else if (
        ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byPercentage"
      ) {
        RequestedTotalParts += (parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) * (ele2.airAvailability) : 0)
      }
    })
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.forEach((ele2: any) => {
      if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) : 0 + parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) : 0
      } else if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byPercentage") {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) : 0 + parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) : 0
      } else if (ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) : 0 + parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) : 0
      } else if (
        ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byPercentage"
      ) {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) : 0 + parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) : 0
      }
    })
    setRequestedTotal(RequestedTotal ? RequestedTotal : 0);
    setRequestedTotalParts(RequestedTotalParts ? RequestedTotalParts : 0);
    setRequestedTotalPartsTotal(RequestedTotalPartsTotal ? RequestedTotalPartsTotal : 0);
    //Offered By Total
    setSelectByTotal(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency === "byPercentage" || viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency === "By %" ? "By %" : "By AED")
    setOfferedTotalAED(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency !== "byPercentage" || viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency != "By %" ? viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPrice : "")
    setOfferedTotalPercent(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency === "byPercentage" || viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency === "By %" ? viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPrice : "")
    setTotalCalculatedTotal(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCalculated)
  }, [viewSpecialPrice, quantity])

  useEffect(() => {
    let sum: any = 0
    valuePriceNow.forEach((ele: any) => {
      sum += ele.priceAED ? parseFloat(ele.priceAED) : 0
    })
    valuePriceBo.forEach((ele: any) => {
      sum += ele.priceBoAED ? parseFloat(ele.priceBoAED) : 0
    })
    setTotalOfferedAED(sum ? sum : "")
  }, [valuePriceNow, valuePriceBo])

  function handleOpenCommentApproveDialog() {
    setOpen(true)
    setCopenApproveCommentDialog(false)

  }

  function closeApproveDialog() {
    setCopenApproveCommentDialog(false)
  }

  function importExcel(data: any) {
    let len = [{ priceNow: "203" }, { priceNow: "203" }, { priceNow: "203" }];
    let item: any = []
    if (isImport) {
      setValuePriceNow(item);
      setValueSelectPriceNow(item);
      setValueCalculatedNow(item);
      setValuePriceBo(item);
      setValueSelectPriceBo(item);
      setValueCalculatedBo(item);
    }
  }

  // ON CHANGE OPEN CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    // handleopenCloseErrorSnackbar(false)
    setOpenErrorSnackbar(open ? true : false);
  };

  // ON CHANGE OPEN CLOSE - SUCCESS SNACKBAR
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleChange = (event: any) => {
    setSelected(event.target.value);
  };
  function handleopenClose() {
    setOpen(false)
  }
  function handleopenCloseTotal() {
    setOpenTotal(false)
  }
  function handleOpenCloseRejectPartDialog() {
    setOpenRejectPart(false)
  }

  function handleOpenSpecialPrice() {
    setOpenPriceRequest(false)
  }
  function handleJustification() {
    setOpenJustification(false)
  }
  function handleDocuments() {
    setOpenDocuments(false)
  }
  function handleSelect() {
  }

  const statusReopenedChecked = () => {
    setRemoveValidationPopup(true);
    setShowReopenDialog(true);
  }

  const handeleViewSpecialPrice = async (id: any, name: "pricing_team") => {
    let data: any = await dispatch(viewSpecialPriceAPI(id, name))

    if (data.message.status === "E") {
      handleopenCloseErrorSnackbar(true)
      showErrorMessage(data && data.message && data.message.message)
    } else {
      history.push('/viewspecialprice');
    }
  }

  // FOR POST API CALL FOR CHECK AVALIABLITITY & PRICE
  const checkAvailibilityAndPriceAPI = async () => {
    setLoading(true)
    setDataUpdatedPrompt(false)
    let quantitiyStore: any = []
    let responseData: any

    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
      return (
        <>
          {quantitiyStore.push({
            "uid": ele.uid,

            "nowRequestPriceType": ele && ele.nowRequestPriceType ? ele && ele.nowRequestPriceType : "",
            "nowRequestPrice": ele && ele.nowRequestPrice ? ele && ele.nowRequestPrice : "",
            "nowRequestCalculatedPrice": ele && ele.nowRequestCalculatedPrice ? ele && ele.nowRequestCalculatedPrice : "",

            "airRequestPriceType": ele && ele.airRequestPriceType ? ele && ele.airRequestPriceType : "",
            "airRequestPrice": ele && ele.airRequestPrice ? ele && ele.airRequestPrice : "",
            "airRequestCalculatedPrice": ele && ele.airRequestCalculatedPrice ? ele && ele.airRequestCalculatedPrice : "",

            "nowOfferedPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "nowOfferedPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
            "nowOfferedCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "",

            "airOfferedPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
            "airOfferedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"],
            "airOfferedCalculatedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "",
          })}
        </>
      )
    })
    setProductData(quantitiyStore)
    const data = {
      "sessionDetails": {
        "webApplicationLoginId": webApplicationLoginId,
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "loginPassword": "",
        "applicationSecurityKey": "",
        "initialLogonIndicator": "",
        "initialPassword": ""
      },
      "sapCustomerId": sapCustomerIdData,
    }
    let savedData: any = await dispatch(checkandavaliabilityApi(B2bEnquiryId, data));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      setSuccessMessage(savedData && savedData.message && savedData.message.message);
      handleopenCloseSnackbar(true);
      setLoading(false)
    } else if (savedData && savedData.message && savedData.message.status === "E" || savedData.error) {
      showErrorMessage(savedData && savedData.error)
      handleopenCloseErrorSnackbar(true);
      setOpenErrorSnackbar(true);
      setLoading(false)
      setTimeout(() => {
        handleopenCloseErrorSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 2000)
    }
  }

  const getIsAirAvalibityExistCount = () => {
    let airAvailabilityProducts = productData.filter((data: any) => data.nowOfferedPrice !== "" || data.nowOfferedPrice === undefined || data.airOfferedPrice !== "" || data.airOfferedPrice === undefined)
    return airAvailabilityProducts.length;
  }


  //  POST API CALL FOR SAVE FORM BUTTON
  const saveFormDetailsAPI = async () => {
    setDataUpdatedPrompt(false)
    setLoading(true)
    let quantitiyStore: any = []
    let responseData: any

    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
      let valueNow: any = ((ele.nspev) - parseFloat(ele.nspev)) / parseFloat(ele.nspev)
      valueNow = (parseFloat(valueNow) * 100).toFixed(2) + "%"

      let valueBo: any = ((ele.aop) - parseFloat(ele.aop)) / parseFloat(ele.aop)
      valueBo = (parseFloat(valueBo) * 100).toFixed(2) + "%"


      return (
        <>
          {quantitiyStore.push({
            "uid": ele.uid,

            "nowRequestPriceType": ele && ele.nowRequestPriceType ? ele && ele.nowRequestPriceType : "",
            "nowRequestPrice": ele && ele.nowRequestPrice ? ele && ele.nowRequestPrice : "",
            "nowRequestCalculatedPrice": ele && ele.nowRequestCalculatedPrice ? ele && ele.nowRequestCalculatedPrice : "",

            "airRequestPriceType": ele && ele.airRequestPriceType ? ele && ele.airRequestPriceType : "",
            "airRequestPrice": ele && ele.airRequestPrice ? ele && ele.airRequestPrice : "",
            "airRequestCalculatedPrice": ele && ele.airRequestCalculatedPrice ? ele && ele.airRequestCalculatedPrice : "",

            "nowOfferedPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "nowOfferedPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? !ele.isRejected && ele.nowRequestPrice && valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : ele.isSpecialPriced === false && ele.nowRequestPrice ? parseFloat(ele.nspev) : "" : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
            "nowOfferedCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] && !ele.isRejected ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : ele.nowRequestPrice && ele.isSpecialPriced === false ? valueNow : "",

            "airOfferedPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
            "airOfferedPrice": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? !ele.isRejected && valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : ele.isSpecialPriced === false && ele.airRequestPrice ? parseFloat(ele.aop) : "" : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] : "",
            "airOfferedCalculatedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] && !ele.isRejected || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] && !ele.isRejected ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : ele.airRequestPrice && ele.isSpecialPriced === false ? valueBo : "",
          })}
        </>
      )
    })
    setProductData(quantitiyStore)
    if (quantitiyStore.length >= 1) {
      const data = {
        "requestDetails": {
          "id": responseIDData,
          "updatedByPricingTeam": webApplicationLoginId,
          "estimateGrandTotal": total.toString(),
          "requestTotalPriceType": requestTotalPriceType,
          "offeredTotalPriceType": offeredBy === "Parts" ? "part" : "total",
          "requestTotalPriceCurrency": requestTotalPriceCurrency,
          "requestTotalPrice": requestTotalPrice,
          "requestTotalPriceCalculated": requestTotalPriceCalculated,
          "offeredTotalPriceCurrency": selectByTotal,
          'offeredTotalPrice': selectByTotal === "By AED" ? offeredTotalAED : offeredTotalPercent,
          "offeredTotalPriceCalculated": calculatedTotal ? calculatedTotal.toString() : "",
          "products": quantitiyStore,
          "sapCustomerId": sapCustomerIdData
        },
        "sessionDetails": {
          "webApplicationLoginId": webApplicationLoginId,
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "userId": ""
        }
      }
      let requestedBy: any = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestedByType === "part" ? "Parts" : "Total"
      if (requestedBy === "Total" && offeredBy === "Parts") {
        let checkError: any = false
        let offeredValue: any = TotalOfferedAed()
        let requestTotal: any = offeredBy === "Parts" ? requestedTotalParts ? parseFloat(requestedTotalParts).toFixed(2) : (parseFloat(requestedTotal).toFixed(2)) : (requestedTotal) ? parseFloat(requestedTotal).toFixed(2) : parseFloat(requestedTotalParts).toFixed(2)
        let totalAed: any = isDiscountPricePart ? discountTotal : total
        checkError = parseFloat(offeredValue) > parseFloat(totalAed) || offeredValue < parseFloat(requestTotal) ? true : false
        if (checkError) {
          showErrorMessage("Offered prices cannot greater than estimated total & less than the requested price");
          handleopenCloseErrorSnackbar(true);
          setOpenErrorSnackbar(true);
          setLoading(false)
          setResponseSuccess(false)
          setTimeout(() => {
            handleopenCloseErrorSnackbar(false);
            setOpenErrorSnackbar(false);
          }, 2000)
        }
        else {
          let savedData: any = await dispatch(postSaveFormData(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setIsSaved(true)
            setSuccessMessage(savedData && savedData.message && savedData.message.message);
            handleopenCloseSnackbar(true);
            setLoading(false)
            setResponseSuccess(true)
            setTimeout(() => {
              handleopenCloseSnackbar(false);
            }, 2000)
          } else if (savedData && savedData.message && savedData.message.status === "E") {
            showErrorMessage(savedData.error);
            handleopenCloseErrorSnackbar(true);
            setOpenErrorSnackbar(true);
            setLoading(false)
            setResponseSuccess(false)
            setTimeout(() => {
              handleopenCloseErrorSnackbar(false);
              setOpenErrorSnackbar(false);
            }, 2000)
          }
        }
      } else {
        let checkError: any = false
        if (offeredBy === "Parts") {
          let errorNow = valuePriceNow.filter((ele: any) => ele.errorNow)
          let errorBo = valuePriceBo.filter((ele: any) => ele.errorBo)
          if (errorNow && errorNow.length >= 1 || errorBo && errorBo.length >= 1) {
            checkError = true
          } else {
            checkError = false
          }
        } else {
          if (totalError) {
            checkError = true
          } else { checkError = false }
        }
        if (checkError) {
          showErrorMessage("Please enter the correct offered prices");
          handleopenCloseErrorSnackbar(true);
          setOpenErrorSnackbar(true);
          setLoading(false)
          setResponseSuccess(false)
          setTimeout(() => {
            handleopenCloseErrorSnackbar(false);
            setOpenErrorSnackbar(false);
          }, 2000)
        }
        else {
          let savedData: any = await dispatch(postSaveFormData(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setIsSaved(true)
            setSuccessMessage(savedData && savedData.message && savedData.message.message);
            handleopenCloseSnackbar(true);
            setLoading(false)
            setResponseSuccess(true)
            setTimeout(() => {
              handleopenCloseSnackbar(false);
            }, 2000)
          } else if (savedData && savedData.message && savedData.message.status === "E") {
            showErrorMessage(savedData.error);
            handleopenCloseErrorSnackbar(true);
            setOpenErrorSnackbar(true);
            setLoading(false)
            setResponseSuccess(false)
            setTimeout(() => {
              handleopenCloseErrorSnackbar(false);
              setOpenErrorSnackbar(false);
            }, 2000)
          }
        }

      }
    }
  }

  // POST API CALL FOR FORWARD TO KAM BUTTON
  const saveDetailsAPIForwardkam = async () => {
    setLoading(true)
    let quantitiyStore: any = []
    let responseData: any

    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
      let valueNow: any = ((ele.nspev) - parseFloat(ele.nspev)) / parseFloat(ele.nspev)
      valueNow = (valueNow * 100).toFixed(2) + "%"

      let valueBo: any = ((ele.aop) - parseFloat(ele.aop)) / parseFloat(ele.aop)
      valueBo = (valueBo * 100).toFixed(2) + "%"
      return (
        <>
          {quantitiyStore.push({
            "uid": ele.uid,

            "nowRequestPriceType": ele && ele.nowRequestPriceType ? ele && ele.nowRequestPriceType : "",
            "nowRequestPrice": ele && ele.nowRequestPrice ? ele && ele.nowRequestPrice : "",
            "nowRequestCalculatedPrice": ele && ele.nowRequestCalculatedPrice ? ele && ele.nowRequestCalculatedPrice : "",

            "airRequestPriceType": ele && ele.airRequestPriceType ? ele && ele.airRequestPriceType : "",
            "airRequestPrice": ele && ele.airRequestPrice ? ele && ele.airRequestPrice : "",
            "airRequestCalculatedPrice": ele && ele.airRequestCalculatedPrice ? ele && ele.airRequestCalculatedPrice : "",

            "nowOfferedPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "nowOfferedPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : ele.isSpecialPriced === false && ele.nowRequestPrice ? parseFloat(ele.nspev) : "" : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
            "nowOfferedCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : ele.nowRequestPrice && ele.isSpecialPriced === false ? valueNow : "",

            "airOfferedPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
            "airOfferedPrice": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : ele.isSpecialPriced === false && ele.airRequestPrice ? parseFloat(ele.aop) : "" : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] : "",
            "airOfferedCalculatedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : ele.airRequestPrice && ele.isSpecialPriced === false ? valueBo : "",
          })}
        </>
      )
    })
    setProductData(quantitiyStore)
    if (quantitiyStore.length >= 1) {
      const data = {
        "requestDetails": {
          "id": responseIDData,
          "updatedByPricingTeam": webApplicationLoginId,
          "estimateGrandTotal": total.toString(),
          "requestTotalPriceType": requestTotalPriceType,
          "requestTotalPriceCurrency": requestTotalPriceCurrency,
          "requestTotalPrice": requestTotalPrice,
          "requestTotalPriceCalculated": requestTotalPriceCalculated,
          "offeredTotalPriceType": offeredBy === "Parts" ? "part" : "total",
          "offeredTotalPriceCurrency": selectByTotal,
          'offeredTotalPrice': selectByTotal === "By AED" ? offeredTotalAED : offeredTotalPercent,
          "offeredTotalPriceCalculated": calculatedTotal ? calculatedTotal.toString() : "",
          "products": quantitiyStore,
          "sapCustomerId": sapCustomerIdData
        },
        "sessionDetails": {
          "webApplicationLoginId": webApplicationLoginId,
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "userId": ""
        }
      }
      let savedData: any = await dispatch(postSaveFormData(data));
      if (savedData && savedData.message && savedData.message.status === 'S') {
        // setSuccessMessage(savedData && savedData.message && savedData.message.message);
        // handleopenCloseSnackbar(true);
        setLoading(false)
        fordwardToKamAPIDetails();
      } else if (savedData && savedData.message && savedData.message.status === "E") {
        showErrorMessage(savedData.error);
        handleopenCloseErrorSnackbar(true);
        setOpenErrorSnackbar(true);
        setLoading(false)
        setTimeout(() => {
          handleopenCloseSnackbar(false);
        }, 2000)
      }
    }
  }

  //Clear the data
  const clearFormDetailsAPI = async () => {
    setDataUpdatedPrompt(false)
    setLoading(true)
    let quantitiyStore: any = []

    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
      return (
        <>
          {quantitiyStore.push({
            "uid": ele.uid,

            "nowRequestPriceType": ele && ele.nowRequestPriceType ? ele && ele.nowRequestPriceType : "",
            "nowRequestPrice": ele && ele.nowRequestPrice ? ele && ele.nowRequestPrice : "",
            "nowRequestCalculatedPrice": ele && ele.nowRequestCalculatedPrice ? ele && ele.nowRequestCalculatedPrice : "",

            "airRequestPriceType": ele && ele.airRequestPriceType ? ele && ele.airRequestPriceType : "",
            "airRequestPrice": ele && ele.airRequestPrice ? ele && ele.airRequestPrice : "",
            "airRequestCalculatedPrice": ele && ele.airRequestCalculatedPrice ? ele && ele.airRequestCalculatedPrice : "",

            "nowOfferedPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "nowOfferedPrice": "",
            "nowOfferedCalculatedPrice": "",

            "airOfferedPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
            "airOfferedPrice": "",
            "airOfferedCalculatedPrice": "",
          })}
        </>
      )
    })

    let item: any = []
    let len: any = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.length


    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any) => {
      item.push(
        {
          value: ele && ele.nowOfferedPriceType ? ele && ele.nowOfferedPriceType === "byPercentage" ? "By %" : "By AED" : "By AED",
          priceAED: "",
          pricePercent: "",
          priceGreaterThanNow: "",
          errorNow: false,
          valueBo: ele && ele.airOfferedPriceType ? ele && ele.airOfferedPriceType === "byPercentage" ? "By %" : "By AED" : "By AED",
          calcuatedPrice: "",
          calculatedPriceGreaterNow: "",
          priceBo: ele && ele.airOfferedPriceType ? ele && ele.airOfferedPriceType === "byPercentage" ? "By %" : "By AED" : "By AED",
          priceBoAED: "",
          priceBoPercent: "",
          priceGreaterThanBo: "",
          calcuatedPriceBo: "",
          errorBo: false,
          calculatedPriceGreaterThanBo: ""
        })
    })
    if (item.length == len) {
      setValuePriceNow(item);
      setValueSelectPriceNow(item);
      setValueCalculatedNow(item);
      setValuePriceBo(item);
      setValueSelectPriceBo(item);
      setValueCalculatedBo(item);
    }
    setSelectByTotal(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency === "byPercentage" || viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency && viewSpecialPrice.data.data.data.offeredTotalPriceCurrency === "By %" ? "By %" : "By AED")
    setOfferedTotalAED("")
    setOfferedTotalPercent("")
    setTotalCalculatedTotal("")


    if (quantitiyStore.length >= 1) {
      const data = {
        "requestDetails": {
          "id": responseIDData,
          "updatedByPricingTeam": webApplicationLoginId,
          "estimateGrandTotal": total.toString(),
          "requestTotalPriceType": requestTotalPriceType,
          "offeredTotalPriceType": offeredBy === "Parts" ? "part" : "total",
          "requestTotalPriceCurrency": requestTotalPriceCurrency,
          "requestTotalPrice": requestTotalPrice,
          "requestTotalPriceCalculated": requestTotalPriceCalculated,
          "offeredTotalPriceCurrency": selectByTotal,
          'offeredTotalPrice': "",
          "offeredTotalPriceCalculated": "",
          "products": quantitiyStore,
          "sapCustomerId": sapCustomerIdData
        },
        "sessionDetails": {
          "webApplicationLoginId": webApplicationLoginId,
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "userId": ""
        }
      }
      let savedData: any = await dispatch(postSaveFormData(data));
      if (savedData && savedData.message && savedData.message.status === 'S') {
        setIsSaved(true)
      } else if (savedData && savedData.message && savedData.message.status === "E") {
      }
    }
  }


  function handelSelectTotal(e: any) {
    setDataUpdatedPrompt(true)
    setSelectByTotal(e.target.value)
    setOfferedTotalAED("")
    setOfferedTotalPercent("")
    setTotalCalculatedTotal("")
    if (e.target.value === "By AED") {
      setOfferedTotalPercent("");
      if ((offeredTotalAED !== "")) {
        let total: any = 0;
      } else {
        setTotalCalculatedTotal("");
      }
    } else if (e.target.value === "By %") {
      setOfferedTotalAED("");
      if ((offeredTotalPercent !== "")) {
        if (offeredTotalPercent <= 100) {
          let percent = (100 - offeredTotalPercent) / 100
          let total: any = 0;
          [{ priceNow: "203" }, { priceNow: "203" }, { priceNow: "203" }].forEach((ele: any) => {
            total += parseFloat(ele.priceNow)
          })
          const value = percent * parseFloat(total)
          value.toFixed()
          setTotalCalculatedTotal(value)
          setTotalError(false)
        } else if (offeredTotalPercent >= 100) {
          setTotalError(true)
        }
      } else {
        setTotalCalculatedTotal("");
      }
    }
  }


  function handleCalculationTotal(e: any) {
    setDataUpdatedPrompt(true)
    setIsSaved("updated")
    if (selectByTotal === "By AED") {
      isDiscountPricePart ? e.target.value ? parseFloat(e.target.value) <= discountTotal && setOfferedTotalAED(e.target.value) : setOfferedTotalAED("") : e.target.value ? parseFloat(e.target.value) <= total && setOfferedTotalAED(e.target.value) : setOfferedTotalAED("")
      let requestValue = offeredBy === "Parts" ? requestedTotalParts ? parseFloat(requestedTotalParts).toFixed(2) : (parseFloat(requestedTotal).toFixed(2)) : (requestedTotal) ? parseFloat(requestedTotal).toFixed(2) : parseFloat(requestedTotalParts).toFixed(2)
      if (requestValue) {
        let value: any = e.target.value < (isDiscountPricePart ? parseFloat(discountTotal) : parseFloat(total)) ? e.target.value : offeredTotalAED
        e.target.value ? parseFloat(value) < parseFloat(requestValue) ? setTotalError(true) : setTotalError(false) : setTotalError(false)
        if ((e.target.value !== "")) {
          if (parseFloat(e.target.value) <= parseFloat(isDiscountPricePart ? discountTotal : total)) {
            let value: any = (parseFloat(isDiscountPricePart ? discountTotal : total) - (parseFloat(e.target.value) <= parseFloat(isDiscountPricePart ? discountTotal : total) ? parseFloat(e.target.value) : parseFloat(offeredTotalAED))) / parseFloat(isDiscountPricePart ? discountTotal : total);
            setTotalCalculatedTotal(`${(value * 100).toFixed(2)}%`)
          }
        } else {
          setTotalCalculatedTotal("");
        }
      } else {
        isDiscountPricePart ? e.target.value ? parseFloat(e.target.value) <= discountTotal && setOfferedTotalAED(e.target.value) : setOfferedTotalAED("") : e.target.value ? parseFloat(e.target.value) <= total && setOfferedTotalAED(e.target.value) : setOfferedTotalAED("")
        if ((e.target.value !== "")) {
          if (parseFloat(e.target.value) <= parseFloat(isDiscountPricePart ? discountTotal : total)) {
            let value: any = (parseFloat(isDiscountPricePart ? discountTotal : total) - (parseFloat(e.target.value) <= parseFloat(discountTotal) ? parseFloat(e.target.value) : parseFloat(offeredTotalAED))) / parseFloat(isDiscountPricePart ? discountTotal : total);
            setTotalCalculatedTotal(`${(value * 100).toFixed(2)}%`)
          }
        } else {
          setTotalCalculatedTotal("");
        }

      }

    } else if (selectByTotal === "By %") {
      let requestPercent: any = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestTotalPriceType === "By AED" ?
        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestTotalPriceCalculated : viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestTotalPrice
      requestPercent = requestPercent && requestPercent.includes("-") ? requestPercent.split("-")[1] : requestPercent
      if (requestPercent) {
        if ((e.target.value !== "")) {
          let requestValue: any = requestPercent.split("%")[0] < 1 ? (requestPercent.split("%")[0]) * (-1) : (requestPercent.split("%")[0])
          if (e.target.value <= parseFloat(requestValue) || (e.target.value = "")) {
            setOfferedTotalPercent(e.target.value);
            let percent = (100 - parseFloat(e.target.value)) / 100
            let value = (percent) * (parseFloat(isDiscountPricePart ? discountTotal : total))
            setTotalCalculatedTotal(value.toFixed(2))
            setTotalError(false)
          }
          else if (e.target.value >= 100) {
            setTotalError(true)

          }
        } else {
          setTotalCalculatedTotal("");
          setOfferedTotalPercent("");
        }
      } else {
        if ((e.target.value !== "")) {
          if (e.target.value <= 100 || (e.target.value = "")) {
            setOfferedTotalPercent(e.target.value);
            let percent = (100 - parseFloat(e.target.value)) / 100
            let requestValue = offeredBy === "Parts" ? requestedTotalParts ? parseFloat(requestedTotalParts).toFixed(2) : (parseFloat(requestedTotal).toFixed(2)) : (requestedTotal) ? parseFloat(requestedTotal).toFixed(2) : parseFloat(requestedTotalParts).toFixed(2)
            const value: any = (percent) * (parseFloat(isDiscountPricePart ? discountTotal : total))
            value.toFixed(2)
            setTotalCalculatedTotal(value.toFixed(2))
            let isGreater: any = isDiscountPricePart ? value ? parseFloat(value) > parseFloat(discountTotal) && true : false : value ? parseFloat(value) > parseFloat(total) && true : false
            parseFloat(value) < parseFloat(requestValue) || isGreater === true ? setTotalError(true) : setTotalError(false)
          }
          else if (e.target.value >= 100) {
            setTotalError(true)
          }
        } else {
          setTotalCalculatedTotal("");
          setOfferedTotalPercent("");
        }
      }
    }
  }

  function TotalOfferedAed() {
    try {
      let sum: any = 0
      let products: any = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products

      let sum1 = valuePriceNow.forEach((ele: any, i: any) => {
        if (products && products.length >= 1 && products && products[i] && products[i].isSpecialPriced === false && products && products[i].nowRequestPrice && products && products.length >= 1 && products && products[i] && products[i].isRejected === false) {
          return sum += ele.calcuatedPrice === "" && ele.priceAED === "" ? parseFloat(products[i] && products[i].nspev) * parseFloat(products[i] && products[i].nowAvailability) : 0
        } else if (products && products.length >= 1 && products && products[i] && products[i].isSpecialPriced === false && products && products[i].nowRequestPrice && products && products.length >= 1 && products && products[i] && products[i].isRejected === true) {
          return sum += parseFloat(products[i] && products[i].nspev) * parseFloat(products[i] && products[i].nowAvailability)
        }
      })

      let sum2 = valuePriceBo.forEach((ele: any, i: any) => {
        if (products && products.length >= 1 && products && products[i] && products[i].isSpecialPriced === false && products && products[i].airRequestPrice && products && products.length >= 1 && products && products[i] && products[i].isRejected === false) {
          return sum += ele.calcuatedPriceBo === "" && ele.priceBoAED === "" ? parseFloat(products[i] && products[i].aop) * parseFloat(products[i] && products[i].airAvailability) : 0
        }
        else if (products && products.length >= 1 && products && products[i] && products[i].isSpecialPriced === false && products && products[i].airRequestPrice && products && products.length >= 1 && products && products[i] && products[i].isRejected === true) {
          return sum += parseFloat(products[i] && products[i].aop) * parseFloat(products[i] && products[i].airAvailability)
        }
      })

      let sum3 = valuePriceNow.forEach((ele: any, i: any) => {
        if (products && products.length >= 1 && products && products[i] && products[i].isRejected === false) {
          return sum += ele.value === "By %"
            ? parseFloat(ele.calcuatedPrice) ? parseFloat(ele.calcuatedPrice) * parseFloat(products[i] && products[i].nowAvailability) : 0
            : ele.priceAED ? (parseFloat(ele.priceAED)) * parseFloat(products[i] && products[i].nowAvailability) : 0
        }
      })


      let sum4 = valuePriceBo.forEach((ele: any, i: any) => {

        if (products && products.length >= 1 && products && products[i] && products[i].isRejected === false && products && products.length >= 1 && products && products[i] && products[i].isRejected === false) {
          return sum += ele.valueBo === "By %" ? parseFloat(ele.calcuatedPriceBo) ? (parseFloat(ele.calcuatedPriceBo) * parseFloat(products[i] && products[i].airAvailability)) : 0 : ele.priceBoAED ? (parseFloat(ele.priceBoAED) * parseFloat(products[i] && products[i].airAvailability)) : 0
        }
      })
      return sum ? parseFloat(sum).toFixed(2) : 0
    }
    catch (error) {

    }

  }

  const exportData = () => {
    let filename = `${viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.sapCustomerId}_${viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.B2bEnquiryId
      }.xlsx`;
    let data: any = [];
    const columns = viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((item: any, i: any) => {
      data.push({
        SNo: i + 1,
        PartNumber: item.productId,
        "Part Description": item.productIdDescription,
        "Brand": item.make === "OES" && customerTierType !== 'Workshop' ? "ALAC" : item.make,
        "Req Quantity": item.quantity,
        "Now Quantity": item.nowAvailability,
        "Now Price": item.nspev,
        "Now Requested Price ": item.nowRequestPriceType == "byCurrency" ? item.nowRequestPrice : item.nowRequestCalculatedPrice,
        "Now Offered Price": item.nowRequestPriceType === "byCurrency" ? item.nowOfferedPrice : "",
        "BO Quantity": item.airAvailability,
        "BO Price": item.aop,
        "BO Requested Price": item.airRequestPriceType == "byCurrency" ? item.airRequestPrice : item.airRequestCalculatedPrice,
        "BO Offered Price": item.airRequestPriceType === "byCurrency" ? item.airOfferedPrice : "",

      });
      return undefined;
    });
    var ws: any = XLSX.utils.json_to_sheet(data);
    var wb: any = XLSX.utils.book_new();
    ws["!cols"] = [{ wch: 8 }]
    ws["!rows"] = []
    XLSX.utils.book_append_sheet(wb, ws, "sheet1");
    XLSX.writeFile(wb, filename);

    //for read
  };
  const fordwardToKamAPIDetails = () => {
    setDataUpdatedPrompt(false)
    setshowApproveDialog(true);
  }

  const handleFile = (e: any) => {
    setIsImport(false)
    setDataUpdatedPrompt(true)
    const file = e.target.files[0];
    if (file && file.name && file.name.split('.').includes("xlsx") || file && file.name && file.name.split('.').includes('xls')) {
      const promise = new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = (e: any) => {
          const bufferArray = e.target.result;
          const wb = XLSX.read(bufferArray, { type: "buffer" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws);
          resolve(data);
        }
        fileReader.onerror = (error) => {
          reject(error);
        }
      })
      promise.then((data: any): any => {
        let partDataExcel: any = [];
        let partsData: any = []
        let res: any = []
        data.map((ele: any) => {
          partDataExcel.push(ele && ele.PartNumber && ele.PartNumber);
        })

        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any) => {
          partsData.push(ele && ele.productId && ele.productId)
        })
        data.forEach((ele: any) => {
          if (partsData.includes(ele && ele.PartNumber && ele.PartNumber)) {
            res.push(ele.PartNumber)
          }
        })
        if (res && res.length === partsData.length) {
          setImportDataExcel(data)
          setIsImport(true);
        } else if (res && res.length !== partsData.length) {
          setIsImport(false)
          setExcelError(`Invalid data format`)
        }
      })
      promise.catch((err: any) => {
        setExcelError(err)
      })

    } else {
      setExcelError(`Please upload excel file`)
    }
  }

  const handleClick = (e: any) => {
    const { target = {} } = e || {}
    target.value = ""
    setImportDataExcel([])
    setExcelError("")
  }

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = null
  }

  const handleDownload = (e: any) => {
    if (url.length >= 1) {
      setOpenDocuments(true)
    } else {
      setOpenDocuments(false)
    }
    // window.removeEventListener('beforeunload', handleBeforeUnload)
    // url.forEach((file: any, i: any) => {
    //   const link = document.createElement('a');
    //   link.target="_blank"
    //   link.rel="noopener noreferrer"

    //   link.href = file.name;
    //   document.body.appendChild(link);
    //   link.click()
    //   document.body.removeChild(link)

    // })
    // window.removeEventListener('beforeunload', handleBeforeUnload)

  }

  const validateTextFiledsOnClickOfSave = () => {
    let pricesNow: any = []
    let pricesBo: any = []

    valuePriceNow.forEach((ele: any) => {
      if (ele.priceAED) {
        pricesNow.push(ele.priceAED)
      }
    })
    valuePriceBo.forEach((ele: any) => {
      if (ele.priceBoAED) {
        pricesBo.push(ele.priceBoAED)
      }
    })

    let valuePriceNowfield = valuePriceNow.filter((valuePriceNow: any) => !_.isEmpty(valuePriceNow.priceAED) || !_.isEmpty(valuePriceNow.pricePercent))
    let valuePriceBofield = valuePriceBo.filter((valuePriceBo: any) => !_.isEmpty(valuePriceBo.priceBoAED) || !_.isEmpty(valuePriceBo.priceBoPercent))


    // if (offeredBy === "Parts" && pricesNow.length <= 0 && pricesBo.length <= 0) {
    //   showErrorMessage("Please fill atleast one text field");
    //   handleopenCloseErrorSnackbar(true);
    //   setOpenErrorSnackbar(true);
    // }
    if (offeredBy === "Total") {
      if (selectByTotal === "By AED" ? offeredTotalAED === "" : offeredTotalPercent === "") {
        showErrorMessage("Please fill offered total");
        handleopenCloseErrorSnackbar(true);
        setOpenErrorSnackbar(true);
      } else {
        saveFormDetailsAPI();
        handleopenCloseErrorSnackbar(false);
        setOpenErrorSnackbar(false);
      }
    } else {
      saveFormDetailsAPI();
      handleopenCloseErrorSnackbar(false);
      setOpenErrorSnackbar(false);
    }
  }

  const validateTextFiledsForForwardToKam = () => {
    let valuePriceNowfield = valuePriceNow.filter((valuePriceNow: any) => (valuePriceNow.priceAED) || (valuePriceNow.pricePercent))
    let valuePriceBofield = valuePriceBo.filter((valuePriceBo: any) => (valuePriceBo.priceBoAED) || (valuePriceBo.priceBoPercent))


    // if (offeredBy === "Parts" && valuePriceBofield.length <= 0 && valuePriceNowfield.length <= 0) {
    //   showErrorMessage("Please fill atleast one text field");
    //   handleopenCloseErrorSnackbar(true);
    //   setOpenErrorSnackbar(true);

    // } 
    if (offeredBy === "Total") {
      if (selectByTotal === "By AED" ? offeredTotalAED === "" : offeredTotalPercent === "") {
        showErrorMessage("Please fill offered total");
        handleopenCloseErrorSnackbar(true);
        setOpenErrorSnackbar(true);
      } else {
        saveDetailsAPIForwardkam();
        handleopenCloseErrorSnackbar(false);
        setOpenErrorSnackbar(false);
      }
    } else {
      saveDetailsAPIForwardkam();
      handleopenCloseErrorSnackbar(false);
      setOpenErrorSnackbar(false);
    }
  }

  // FOR POST API CALL FOR BACK BUTTON API RESTRICTION
  const backButtonUserRestriction = async (id: any) => {
    setLoading(true)
    const data = {
      "requestId": id,
    }
    let savedData: any = await dispatch(userAccesssRestriction(data));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      handleopenCloseErrorSnackbar(false);
      handleopenCloseSnackbar(true);
      setSuccessMessage(savedData && savedData.message && savedData.message.message);
      setLoading(false)
    } else if (savedData && savedData.message && savedData.message.status === "E") {
      handleopenCloseErrorSnackbar(true);
      showErrorMessage(savedData && savedData.error);
      handleopenCloseSnackbar(false);
      setLoading(false)
    }
  }

  const handleCloseCorrection = () => {
    setOpenCorrection(false)
  }

  const handleOpenCorrection = () => {
    setOpenCorrection(true)
  }

  return (
    <div className="">
      {viewSpecialPrice && viewSpecialPrice.loading && <FullscreenLoader />}
      {checkandavailibilityData && checkandavailibilityData.loading && <FullscreenLoader />}
      <div className="margin-bottom">
        <div className="">
          <div className="mb-4 d-flex align-items-center">
            <div className="d-flex flex-row dealer-table-content">
              <h2 className="page-title pr-4">
                Special Price Request {'  '}
              </h2>
              <p className="pr-2 pl-2 margin-top-vertical">{viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.sapCustomerId

              }</p>
              <div className="pr-2 margin-top-vertical" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "25px", opacity: 0.3 }}>
              </div>
              <p className="pr-2 mt-1 margin-top-vertical">{viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.customerType
              }</p>
              <div className="pr-2 mt-1 margin-top-vertical" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "25px", opacity: 0.3 }}>
              </div>
              <p className="pr-2 margin-top-vertical">{
                viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.customerName
              }</p>
            </div>
            <div className="ml-auto d-flex">
              <div className="legendItemCreateEnquiry ml-1 mt-1 green-legend-margin">
                <span className="dot-blue mt-1"></span>
              </div>
            </div>
            <span className="legendItemLabel mr-4 ml-2">Special Price</span>
            <div className="d-flex">
              <div className="legendItemspr ml-1 mt-1 green-legend-margin">
                <span className="dot-red mt-1"></span>
              </div>
            </div>
            <span className="legendItemLabel mr-4 ml-2">Not approved / Not offered</span>
            <div className="d-flex">
              <div className="legendItemCreateEnquiry ml-1 mt-1 green-legend-margin">
                <span className="dot mt-1"></span>
              </div>
            </div>
            <span className="legendItemLabel mr-4 ml-2">Discounted Price</span>
            <NotificationIcon />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div className="dealer-table-content mb-0 pl-1"><h6 className="page-sub-title mb-0">
                  (Order No - {orderno && orderno.data && orderno.data.length > 0 ? orderno.data.map((o: any, i: any) => (<span>{o.orderNumber}{i === (orderno.data.length - 1) ? '' : ', '}</span>)) : <span className="text-danger">Order Not Placed</span>})
                </h6></div>
                <div className="dealer-table-content mb-0 pl-1"><h6 className="page-sub-title mb-0">
                </h6></div>
                <div className="dealer-table-content mb-0 px-2"><h6 className="page-sub-title mb-0">
                  (Enquiry Id-{viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.B2bEnquiryId})
                </h6></div>
                <div className="row-space-start ml-auto sm">
                  <div className="button-hover button-hover1 d-flex flex-column mt-2 " style={{}}>
                    <div className="button-hover button-hover1 d-flex flex-column " style={{}}>
                      <label className=" button-hover import-excel-color button-hover1 d-flex flex-row pr-4 hover-cursor" style={{ background: "none", transition: "red !important" }}>
                        <div className="pr-2 import-excel-color hover-cursor">
                        </div>
                        <ListAltIcon fontSize="inherit" className="hover-cursor" onChange={handleFile} onClick={handleClick} style={{ height: "22px", width: "22px" }} />
                        <span className="pl-2">
                          Import Data
                        </span>
                        {viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out"
                          ? <span></span> : <input type="file" hidden onChange={handleFile} onClick={handleClick} />}

                      </label>
                    </div>
                  </div>
                  <IconButton className="exportExcel" size="small" onClick={exportData} >
                    <ArrowDownwardIcon className="" onClick={exportData} style={{ border: "2px solid #1A9FE0", borderRadius: "8px", height: "19px", width: "19px", color: "rgb(26, 159, 224)", paddingLeft: "", fontWeight: "bold" }} fontSize="inherit" />
                  </IconButton>
                  <span className="pl-3 pr-3 fontTop hover-cursor" onClick={exportData}>Export To Excel</span>
                  <IconButton size="small" className={classes.actionButtonEye} onClick={() => {
                    setOpenJustification(true)
                  }}
                  >
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                  <span className="pl-3 pr-2 fontTop viewJustificationHover" onClick={() => {
                    setOpenJustification(true)
                  }}>View Justification</span>
                  {viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.attachments.length >= 1 ?
                    <div>
                      <IconButton className="exportExcel" size="small" onClick={handleDownload} >
                        <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={handleDownload} />
                      </IconButton>
                      <span className="pl-3 pr-3 fontTop hover-cursor" onClick={handleDownload}>Download Proof</span>
                    </div>
                    :
                    <div>
                      <Tooltip title="This special price has no proof attached" placement="top">
                        <IconButton className="exportExcel" size="small" onClick={handleDownload} >
                          <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={handleDownload} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="This special price has no proof attached" placement="top">
                        <span className="pl-2 pr-2 fontTop hover-cursor" onClick={handleDownload}>Download Proof</span>
                      </Tooltip>
                    </div>
                  }
                  <Button variant="contained" color="primary" onClick={(e) => {
                    e.preventDefault();
                    history.push("specialpricerequest");
                  }} className="blueActionButton border-left rounded-pill">Back To List</Button>
                </div>
              </div>
            </div>
          </div>



          <div className="row">
            <div className="col-md-12 ">
              <div className="d-flex align-items-center">
                <div className="row-space-start sm">
                  <div className="button-hover button-hover1 d-flex flex-column " style={{}}>
                    <label className="button-hover  button-hover1 import-excel-error d-flex flex-row pr-4  padding-excel">
                      <div className="pr-5 mr-1 import-excel-error margin_left"></div>
                      {excelError ? excelError : ""}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="cardCommon margin-bottom-card ">
              <div className="enquiryPartsSpecialPrice  " unselectable="on">
                <div className="customTableContainerSpecialPrice min-height-table">
                  <div className="tableRootSpecialPrice ">
                    <thead className="tableHeadSpecialPrice">
                      <tr className="tSpecialPrice">
                        <th className="thSpecialPrice">
                          <h6 className="blueLabelTableSpecialPrice"># Part No.</h6>
                          <div className="greyLabelTable">
                            Part Name &amp; Desc
                          </div>
                        </th>
                        <th className="thSpecialPrice mw-100p">
                          <h6 className="blueLabelTableSpecialPrice">Quantity</h6>
                          <div className="row d-flex flex-row no-gutters">
                            <div className="col-3 greyLabelTable">Req </div>
                            <div className="col-3 greyLabelTable pl-2">NOW </div>
                            <div className="col-3 pl-4 greyLabelTable">BO</div>
                          </div>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444 mt-0 pt-0">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">Total ATP</h6>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice">NSP</h6><div className="greyLabelTable">(Excl. VAT)</div>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice">NOW Req Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice mw-160p font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">NOW Offered Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444 mt-0 pt-0">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">BOP</h6>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice">BO Req Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice mw-160p font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">BO Offered Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice mw-160p font-10 font-weight-700 text-444 mt-0 pt-0">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">Comment</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
                        return (
                          <tr className={`${ele.isRejected === false ? "tableSpecialPrice" : "row-color-changes"}`}>
                            <td className="tdchildSpecialPrice">
                              <div className="d-flex">
                                <div className=" py-1 pr-1" style={{ display: "inline" }}>
                                  <div className="dealer-table-content dealer-company-name d-flex flex-row">
                                    {ele.isRejected === false &&
                                      <div>
                                        <div className={`cancelBotton cancelBottonClicked ${ele.isSpecialPriced ? "padding-zero" : ""} cancelBotton  `}>
                                          <IconButton
                                            disableRipple={true}
                                            className={`cancelBotton cancelBottonClicked ${ele.isSpecialPriced ? "padding-zero" : "padding-left-cancel"} cancelBotton ${openRejectPart ? "cancelBottonClicked" : ""} `}
                                            disabled={
                                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "KAM Review"
                                            }
                                            onClick={() => {
                                              setUID(ele.uid)
                                              setOpenRejectPart(true)
                                            }}>
                                            <CancelIcon fontSize="inherit" className={`${classes.actionButton} padding-left-cancel cancelBotton cancelBottonClicked`} />
                                          </IconButton>
                                        </div>
                                      </div>
                                    }
                                    {ele.isRejected ?
                                      <div className="red-legend">
                                        <span className="pl-1"></span>
                                      </div>
                                      :
                                      <div className=" ">
                                        <span className="pl-2"></span>
                                      </div>}
                                    {viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status !== "New Request" && (
                                      <>
                                        {!ele.isSpecialPriced && (ele.nowRequestPrice || ele.airRequestPrice) && (
                                          <>
                                            {isBluelegend(ele) && !ele.isRejected ? (
                                              <div className="blue-legend">
                                                <span className="pl-1"></span>
                                              </div>
                                            ) : (
                                              <>
                                                {!ele.isRejected && (
                                                  <div className="red-legend">
                                                    <span className="pl-1"></span>
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                    {ele.isSpecialPriced ?
                                      <div className="green-legend">
                                        <span className="pl-1"></span>
                                      </div>
                                      :
                                      <div className=" ">
                                        <span className="pl-2"></span>
                                      </div>}
                                  </div>
                                  <div className="dealer-table-subcontent"> { }</div>
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="pt-1  dealer-table-content"> {ele.productId
                                  }</div>
                                  <div className="pt-1 pb-1 dealer-table-subcontent"> {ele.productIdDescription
                                  }</div>
                                </div>
                              </div>
                            </td>
                            <td className="tdchildSpecialPrice">
                              <div className="dealer-table-contentSpecialPrice d-flex flex-row ">
                                <div className="pl-0 ml-0" style={{ minWidth: "35px" }}>{ele.quantity
                                }</div>
                                <div className="" style={{ minWidth: "38px" }}>{ele.nowAvailability
                                }</div>
                                <div className="mw-50p">{ele.airAvailability}</div>
                              </div>
                            </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">
                              <div className="dealer-table-contentSpecialPrice d-flex flex-row ">
                                <div className="pl-3 ml-0" style={{ minWidth: "35px" }}>{parseInt(nowAndBoValue && nowAndBoValue[i] && nowAndBoValue[i].nowAvaliabilityValue || "0").toFixed(0)}</div>
                                {/* <div className="" style={{ minWidth: "38px" }}>{parseInt(nowAndBoValue && nowAndBoValue[i] && nowAndBoValue[i].boAvaliabilityValue || "0").toFixed(0)}</div> */}
                              </div>
                            </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">{ele.nspev}</td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">
                              <div className="d-flex flex-row">
                                <div>{ele.nowRequestPrice ? ele.nowRequestPriceType == "byCurrency" ? `${ele.nowRequestPrice}` : `${ele.nowRequestCalculatedPrice}` : ""} </div>
                                <div className="pl-2 requestedPriceColor">{ele.nowRequestPriceType == "byCurrency" ? `${ele.nowRequestCalculatedPrice ? `(${ele.nowRequestCalculatedPrice.includes("-") ? "" : "-"}` : ""}${ele.nowRequestCalculatedPrice}${ele.nowRequestCalculatedPrice ? ")" : ""}` : `${ele.nowRequestPrice ? "(-" : ""}${ele.nowRequestPrice}${ele.nowRequestCalculatedPrice ? "%)" : ""}`} </div>
                              </div>
                            </td>
                            <td className="tdchildSpecialPrice">
                              <div className="">
                                <div className=" mb-2 pr-0 d-flex flex-row " style={{ position: "relative" }}>
                                  <div className="mt-2">
                                    <TextField select
                                      id=""
                                      label=""
                                      value={valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] ? valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] : ""}
                                      variant="outlined"
                                      className="commonRoundedInputs-dropdown-viewSpecialPrice"
                                      size="small"
                                      InputLabelProps={{
                                        style: { color: '#000000', background: "white !important" },
                                      }}
                                      onChange={(e: any) => {
                                        setDataUpdatedPrompt(true)
                                        let data = [...valueSelectPriceNow]
                                        data[i]["value"] = e.target.value
                                        let value: any = e.target.value
                                        setValueSelectPriceNow(data)

                                        if (e.target.value === "By AED") {
                                          let data: any = [...valuePriceNow];
                                          data[i]["pricePercent"] = "";
                                          setValuePriceNow(data);
                                          if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"]) !== "") {
                                            let value: any = ((ele.priceNow) - (valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"])) / parseFloat(ele.priceNow)
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = (value * 100).toFixed(2) + "%"
                                            setValueCalculatedNow(valueBo)
                                          } else {
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = ""
                                            setValueCalculatedNow(valueBo)
                                          }
                                        } else if (e.target.value === "By %") {
                                          let data: any = [...valuePriceNow];
                                          data[i]["priceAED"] = "";
                                          setValuePriceNow(data);
                                          let valueBo = [...valueCalculatedNow]
                                          valueBo[i]["errorNow"] = false
                                          setValueCalculatedNow(valueBo)
                                          if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) !== "") {
                                            if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) <= 100) {
                                              let dataCalculated = [...valueCalculatedNow]
                                              let percent = (100 - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"])) / 100
                                              const value = percent * parseFloat(ele.priceNow)
                                              valueBo[i]["calcuatedPrice"] = value.toFixed(2)
                                            } else if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) >= 100) {
                                              let valueBo = [...valueCalculatedNow]
                                              valueBo[i]["errorNow"] = false
                                              setValueCalculatedNow(valueBo)
                                            }
                                          } else {
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = ""
                                            setValueCalculatedNow(valueBo)

                                          }
                                        }
                                      }}
                                      style={{ width: "90px" }}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes2.notchedOutline,
                                          root: classes2.root
                                        }
                                      }}
                                      disabled={
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                                          ele.isRejected === true ||
                                          ele.isSpecialPriced === true ? true : false ||
                                            ele && ele.nowAvailability && ele.nowAvailabilityChecked && offeredBy === "Parts" && ele.nowRequestPrice ? false : offeredBy === "Total" ? true : requestValue !== "part" && ele.nowAvailability && ele.nowAvailabilityChecked ? false : true
                                      } >
                                      {["By AED", "By %"].map((data: any, index: any) => (
                                        <MenuItem key={index} value={data}> {data} </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>{ }
                                  <TextField id="august"
                                    type="string"
                                    value={ele.isRejected === false || ele.isRejected === true ? valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]) : ""}
                                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onChange={(e: any) => {
                                      setDataUpdatedPrompt(true)
                                      setIsSaved("updated")
                                      let data: any = [...valuePriceNow]
                                      parseFloat(e.target.value) ? parseFloat(e.target.value) <= (ele.nspev) || parseFloat(e.target.value) <= data[i]["priceAED"] ? data[i]["priceAED"] = e.target.value : data[i]["priceGreaterThanNow"] = e.target.value : data[i]["priceAED"] = "";
                                      let selected = data[i]["value"]
                                      setValueSelectPriceNow(data)
                                      if (selected === "By AED") {
                                        let requestValue: any = ele.nowRequestPrice ? ele.nowRequestPriceType == "byCurrency" ? `${ele.nowRequestPrice}` : `${ele.nowRequestCalculatedPrice}` : ""
                                        if (requestValue) {
                                          let data: any = [...valuePriceNow]
                                          let valueBo = [...valueCalculatedNow]

                                          parseFloat(e.target.value) ? data[i]["priceAED"] < parseFloat(requestValue) ? data[i]["errorNow"] = true : data[i]["errorNow"] = false : data[i]["errorNow"] = false

                                          // parseFloat(e.target.value) ? valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"] <= parseFloat(ele.nspev) ? valueBo[i]["errorNow"] = false : data[i]["priceGreaterThanNow"] = "" : data[i]["priceAED"] = ""
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= (ele.nspev) ? (data[i]["priceAED"] = e.target.value)
                                            : data[i]["priceGreaterThanNow"] = e.target.value
                                            : data[i]["priceAED"] = "";
                                          data[i]["pricePercent"] = ""

                                          setValueSelectPriceNow(data)
                                          setValueCalculatedNow(valueBo)
                                          if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"]) !== "") {
                                            let value: any = ((ele.nspev) - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"])) / parseFloat(ele.nspev)
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i][parseFloat(e.target.value) <= data[i]["priceAED"] ? "calcuatedPrice" : "calculatedPriceGreaterNow"] = (value * 100).toFixed(2) + "%"
                                            setValueCalculatedNow(valueBo)
                                          }
                                          else {
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }
                                        } else {
                                          let data: any = [...valuePriceNow]
                                          let valueBo = [...valueCalculatedNow]
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= (ele.nspev) ? (data[i]["priceAED"] = e.target.value)
                                            : data[i]["priceGreaterThanNow"] = e.target.value
                                            : data[i]["priceAED"] = "";
                                          data[i]["pricePercent"] = ""

                                          setValueSelectPriceNow(data)
                                          setValueCalculatedNow(valueBo)
                                          if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"]) !== "") {
                                            let value: any = ((ele.nspev) - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"])) / parseFloat(ele.nspev)
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i][parseFloat(e.target.value) <= data[i]["priceAED"] ? "calcuatedPrice" : "calculatedPriceGreaterNow"] = (value * 100).toFixed(2) + "%"
                                            setValueCalculatedNow(valueBo)
                                          }
                                          else {
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }
                                        }

                                      } else if (selected === "By %") {
                                        let data: any = [...valuePriceNow]
                                        let requestPercent = ele.nowRequestPriceType == "byCurrency" ? ele.nowRequestCalculatedPrice.split("%")[0] : ele.nowRequestPrice
                                        requestPercent = requestPercent && requestPercent.includes("-") ? requestPercent.split("-")[1] : requestPercent
                                        if (requestPercent) {
                                          data[i]["priceAED"] = ""
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat(requestPercent) ? data[i]["pricePercent"] = e.target.value : data[i]["priceGreaterThanNow"] = e.target.value : data[i]["pricePercent"] = "";
                                          setValueSelectPriceNow(data)
                                          if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) !== "") {
                                            if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) <= 100) {
                                              let percent = (100 - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"])) / 100
                                              const value = percent * parseFloat(ele.nspev);
                                              let valueBo = [...valueCalculatedNow]
                                              valueBo[i]["calcuatedPrice"] = value.toFixed(2)
                                              valueBo[i]["errorNow"] = false;
                                              setValueCalculatedBo(valueBo)
                                            } else if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) >= 100) {
                                              let valueBo = [...valueCalculatedNow]
                                              valueBo[i]["errorNow"] = false;
                                              setValueCalculatedBo(valueBo)
                                            }
                                          } else {
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = ""
                                            setValueCalculatedBo(valueBo)

                                          }
                                        } else {
                                          data[i]["priceAED"] = ""
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat("99.99") ? data[i]["pricePercent"] = e.target.value : data[i]["priceGreaterThanNow"] = e.target.value : data[i]["pricePercent"] = "";
                                          setValueSelectPriceNow(data)
                                          if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) !== "") {
                                            if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) <= 100) {
                                              let percent = (100 - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"])) / 100
                                              const value = percent * parseFloat(ele.nspev);
                                              let valueBo = [...valueCalculatedNow]
                                              valueBo[i]["calcuatedPrice"] = value.toFixed(2)
                                              let error: any = false
                                              let requestValue = TotalOfferedAed()
                                              valueBo[i]["errorNow"] = false;
                                              setValueCalculatedBo(valueBo)
                                            } else if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) >= 100) {
                                              let valueBo = [...valueCalculatedNow]
                                              valueBo[i]["errorNow"] = false;
                                              setValueCalculatedBo(valueBo)
                                            }
                                          } else {
                                            let valueBo = [...valueCalculatedNow]
                                            valueBo[i]["calcuatedPrice"] = ""
                                            setValueCalculatedBo(valueBo)

                                          }

                                        }

                                      }

                                    }}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes2.notchedOutline1,
                                        root: classes2.root2
                                      },
                                      inputProps: {
                                        type: "number",
                                        min: 0, max: 99


                                      }
                                    }}
                                    inputProps={{
                                      maxLength: valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? "" : 2,

                                    }}
                                    style={{ height: "0px !important", width: "105px" }}
                                    size="small" className=""
                                    InputLabelProps={valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) || valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) || valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]) ? { shrink: valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) || valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) || valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]) ? true : false } : {}}
                                    label={valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? "Enter Price" : "Enter %"} variant="outlined" margin="dense"
                                    disabled={
                                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                                        ele.isRejected === true || ele.isSpecialPriced === true ? true : false ||
                                          ele && ele.nowAvailability && ele.nowAvailabilityChecked && offeredBy === "Parts" && ele.nowRequestPrice ? false : offeredBy === "Total" ? true : requestValue !== "part" && ele.nowAvailability && ele.nowAvailabilityChecked ? false : true
                                    } />
                                  <TextField id="august"
                                    type="string"
                                    value={ele.isRejected === false || ele.isRejected === true ? valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? `-${valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"]}` : "" : valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "" : ""}
                                    style={{ minWidth: "0px", width: "75px", backgroundColor: "rgba(224, 224, 224, 1)", borderRadius: "10px !important" }}
                                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onChange={(e: any) => {
                                      setPercentage(e.target.value)
                                    }}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes2.notchedOutline2,
                                        root: classes2.root3
                                      }
                                    }}
                                    size="small" className="borderRadiusText"
                                    label="" variant="outlined" margin="dense" disabled={offeredBy !== "Parts" ? true : false} />
                                </div>
                                {(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["errorNow"]) === true && <p className="errorPrice">Offered Price cannot greater than NSP price<br />& less than requested price</p>}
                              </div>
                            </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">{ele.aop} </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice"><div className="d-flex flex-row">
                              <div>{ele.airRequestPrice ? ele.airRequestPriceType == "byCurrency" ? `${ele.airRequestPrice}` : `${ele.airRequestCalculatedPrice}` : ""}</div>
                              <div className="pl-2 requestedPriceColor">{ele.airRequestPriceType == "byCurrency" ? `${ele.airRequestCalculatedPrice ? `(${ele.airRequestCalculatedPrice.includes("-") ? "" : "-"}` : ""}${ele.airRequestCalculatedPrice}${ele.airRequestCalculatedPrice ? ")" : ""}` : `${ele.airRequestPrice ? "(-" : ""}${ele.airRequestPrice}${ele.airRequestPrice ? "%)" : ""}`}</div>
                            </div></td>

                            <td className="tdchildSpecialPrice">
                              <div>
                                <div className=" mb-2 pr-0 d-flex flex-row " style={{ position: "relative" }}>
                                  <div className="mt-2">
                                    <TextField select
                                      id="selectYear"
                                      label=""
                                      value={valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] ? valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] : ""}
                                      variant="outlined"
                                      className="commonRoundnputs-dropdown-viewSpecialPrice"
                                      size="small"
                                      InputLabelProps={{
                                        style: { color: '#000000', background: "white !important" },
                                      }}
                                      onChange={(e: any) => {
                                        let data = [...valueCalculatedBo]
                                        setDataUpdatedPrompt(true)
                                        data[i]["valueBo"] = e.target.value
                                        setValueSelectPriceBo(data)
                                        let valueBo = [...valueCalculatedBo]
                                        valueBo[i]["calcuatedPriceBO"] = ""
                                        setValueCalculatedNow(valueBo)
                                        if (e.target.value === "By AED") {
                                          let data: any = [...valuePriceBo]
                                          data[i]["priceBoPercent"] = ""
                                          setValuePriceBo(data);
                                          if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"]) !== "") {
                                            let value: any = (parseFloat(ele.aop) - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"])) / parseFloat(ele.aop)
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBo"] = ""
                                            setValueCalculatedBo(valueBo)
                                          } else {
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBo"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }

                                        } else if (e.target.value === "By %") {
                                          let data: any = [...valuePriceBo]
                                          data[i]["priceBoAED"] = ""
                                          setValuePriceBo(data);
                                          let valueBo = [...valueCalculatedBo]
                                          valueBo[i]["errorBo"] = false
                                          setValueCalculatedBo(valueBo)
                                          if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) !== "") {
                                            if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) <= 100) {
                                              let dataCalculated = [...valueCalculatedBo]
                                              let percent = (100 - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"])) / 100
                                              const value = percent * parseFloat(ele.aop)
                                              let valueBo = [...valueCalculatedBo]
                                              valueBo[i]["calcuatedPriceBo"] = ""
                                              valueBo[i]["errorBo"] = false
                                              setValueCalculatedBo(valueBo)
                                            } else if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["price"]) >= 100) {
                                              let valueBo = [...valueCalculatedBo]
                                              valueBo[i]["errorBo"] = true
                                              setValueCalculatedBo(valueBo)
                                            }

                                          } else {
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBO"] = ""
                                            setValueCalculatedNow(valueBo)
                                          }
                                        }
                                      }}
                                      style={{ width: "90px" }}
                                      InputProps={{
                                        classes: {
                                          notchedOutline: classes2.notchedOutline,
                                          root: classes2.root
                                        }
                                      }}
                                      disabled={
                                        ele.isSpecialPriced === true ? true : false ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                                          viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                                          ele.isRejected === true ||
                                          ele && ele.airAvailability && ele.airAvailabilityChecked && offeredBy === "Parts" ? false : offeredBy === "Total" ? true : requestValue !== "part" && ele && ele.airAvailability && ele.airAvailabilityChecked ? false : true
                                      }
                                    >
                                      {["By AED", "By %"].map((data: any, index: any) => (
                                        <MenuItem key={index} value={data}> {data} </MenuItem>
                                      ))}
                                    </TextField>
                                  </div>
                                  <TextField id="august"
                                    type="number"
                                    value={ele.isRejected === false || ele.isRejected === false || ele.isRejected === true || ele.isRejected === true ? valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] : ""}
                                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onChange={(e: any) => {
                                      setIsSaved("updated")
                                      setDataUpdatedPrompt(true)
                                      let data: any = [...valuePriceBo]
                                      let dataSelected = [...valueSelectPriceBo]
                                      let selected = data[i]["valueBo"]
                                      if (selected === "By AED") {
                                        let requestValueBo: any = ele.airRequestPrice ? ele.airRequestPriceType == "byCurrency" ? `${ele.airRequestPrice}` : `${ele.airRequestCalculatedPrice}` : ""
                                        parseFloat(e.target.value) ? parseFloat(e.target.value) <= (ele.aop) || parseFloat(e.target.value) <= data[i]["priceBoAED"] ? data[i]["priceBoAED"] = e.target.value : data[i]["priceGreaterThanBo"] = e.target.value : data[i]["priceBoAED"] = "";
                                        if (requestValueBo) {
                                          let data: any = [...valuePriceBo]
                                          let valueBo = [...valueCalculatedBo]
                                          parseFloat(e.target.value) ? data[i]["priceBoAED"] < parseFloat(requestValueBo) ? valueBo[i]["errorBo"] = true : data[i]["errorBo"] = false : data[i]["errorBo"] = false
                                          setValueCalculatedBo(valueBo)


                                          setValuePriceBo(data)
                                          setValueCalculatedBo(valueBo)
                                          if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"]) !== "") {
                                            let value: any = (parseFloat(ele.aop) - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"])) / parseFloat(ele.aop)
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i][parseFloat(e.target.value) <= data[i]["priceBoAED"] ? "calcuatedPriceBo" : "calculatedPriceGreaterThanBo"] = (value * 100).toFixed(2) + "%";
                                            setValueCalculatedBo(valueBo)
                                          }
                                          else {
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBo"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }

                                        } else {
                                          let data: any = [...valuePriceBo]
                                          let valueBo = [...valueCalculatedBo]
                                          setValueCalculatedBo(valueBo)
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= (ele.aop) || parseFloat(e.target.value) <= data[i]["priceBoAED"] ? data[i]["priceBoAED"] = e.target.value : data[i]["priceGreaterThanBo"] = e.target.value : data[i]["priceBoAED"] = "";
                                          parseFloat(e.target.value) ? valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceBoAED"] <= parseFloat(ele.aop) ? valueBo[i]["errorBo"] = false : data[i]["priceGreaterThanBo"] = "" : data[i]["priceBoAED"] = ""
                                          setValuePriceBo(data)
                                          setValueCalculatedBo(valueBo)
                                          if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"]) !== "") {
                                            let value: any = (parseFloat(ele.aop) - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"])) / parseFloat(ele.aop)
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i][parseFloat(e.target.value) <= data[i]["priceBoAED"] ? "calcuatedPriceBo" : "calculatedPriceGreaterThanBo"] = (value * 100).toFixed(2) + "%";
                                            setValueCalculatedBo(valueBo)
                                          }
                                          else {
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBo"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }
                                        }
                                      } else if (selected === "By %") {
                                        let requestPricePercent: any = ele.airRequestPrice ? ele.airRequestPriceType == "byCurrency" ? ele.airRequestCalculatedPrice.split("%")[0] : `${ele.airRequestPrice}` : ""
                                        requestPricePercent = requestPricePercent && requestPricePercent.includes("-") ? requestPricePercent.split("-")[1] : requestPricePercent
                                        if (requestPricePercent) {
                                          let data: any = [...valuePriceBo]
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat(requestPricePercent) ? data[i]["priceBoPercent"] = e.target.value : data[i]["priceGreaterThanBo"] = e.target.value : data[i]["priceBoPercent"] = "";
                                          setValuePriceBo(data)
                                          let valueBo = [...valueCalculatedBo]
                                          valueBo[i]["errorBo"] = false
                                          setValueCalculatedBo(valueBo)
                                          if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) !== "") {
                                            if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) <= 100) {
                                              let percent = (100 - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"])) / 100
                                              const value = percent * parseFloat(ele.aop);
                                              let valueBo = [...valueCalculatedBo]
                                              valueBo[i]["calcuatedPriceBo"] = value.toFixed(2)
                                              valueBo[i]["errorBo"] = false
                                              setValueCalculatedBo(valueBo)
                                            } else if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) >= 100) {
                                              let valueBo = [...valueCalculatedBo]
                                              valueBo[i]["errorBo"] = true
                                              setValueCalculatedBo(valueBo)
                                            }
                                          } else {
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBo"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }
                                        } else {
                                          let data: any = [...valuePriceBo]
                                          parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat("99.99") ? data[i]["priceBoPercent"] = e.target.value : data[i]["priceGreaterThanBo"] = e.target.value : data[i]["priceBoPercent"] = "";
                                          setValuePriceBo(data)
                                          let valueBo = [...valueCalculatedBo]
                                          valueBo[i]["errorBo"] = false
                                          setValueCalculatedBo(valueBo)
                                          if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) !== "") {
                                            if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) <= 100) {
                                              let percent = (100 - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"])) / 100
                                              const value = percent * parseFloat(ele.aop);
                                              let valueBo = [...valueCalculatedBo]
                                              valueBo[i]["calcuatedPriceBo"] = value.toFixed(2)
                                              valueBo[i]["errorBo"] = false
                                              setValueCalculatedBo(valueBo)
                                            } else if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) >= 100) {
                                              let valueBo = [...valueCalculatedBo]
                                              valueBo[i]["errorBo"] = true
                                              setValueCalculatedBo(valueBo)
                                            }
                                          } else {
                                            let valueBo = [...valueCalculatedBo]
                                            valueBo[i]["calcuatedPriceBo"] = ""
                                            setValueCalculatedBo(valueBo)
                                          }
                                        }
                                      }

                                    }}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes2.notchedOutline1,
                                        root: classes2.root2
                                      }
                                    }}
                                    style={{ height: "0px !important", width: "105px" }}
                                    size="small" className=""
                                    InputLabelProps={valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? { shrink: valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? true : false } : {}}
                                    label={valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "Enter Price" : "Enter %"} variant="outlined" margin="dense"
                                    disabled={
                                      ele.isSpecialPriced === true ? true : false ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                                        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                                        ele.isRejected === true ? true : false ||
                                          ele && ele.airAvailability && ele.airAvailabilityChecked && offeredBy === "Parts" ? false : offeredBy === "Total" ? true : requestValue !== "part" && ele && ele.airAvailability && ele.airAvailabilityChecked ? false : true
                                    } />
                                  <TextField id="august"
                                    type="text"
                                    value={ele.isRejected === false || ele.isRejected === true ? valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? `-${valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"]}` : "" : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "" : ""}
                                    style={{ minWidth: "0px", width: "75px", backgroundColor: "rgba(224, 224, 224, 1)", borderRadius: "10px !important", fontSize: "8px" }}
                                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                                    onChange={(e: any) => {
                                      setPercentage(e.target.value)
                                    }}
                                    InputProps={{
                                      classes: {
                                        notchedOutline: classes2.notchedOutline2,
                                        root: classes2.root3
                                      }
                                    }}
                                    size="small" className="borderRadiusText"
                                    label={""} variant="outlined" margin="dense" disabled={offeredBy !== "Parts" ? true : false} />
                                </div>
                                {(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["errorBo"]) === true && <p className="errorPrice">Offered Price cannot greater than BOP price <br /> &
                                  less than the requested price
                                </p>}
                              </div></td>
                            <td className="tdchildCreateEnquiry"> <IconButton
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              onClick={() => {
                                if (offeredBy === "Parts") {
                                  // setOpen(true)  
                                  if (isSaved === "updated") {
                                    if (valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) {
                                      if (isSaved === "updated") {
                                        setCopenApproveCommentDialog(true)
                                      }
                                    }
                                  }
                                  else {
                                    setOpen(true)
                                  }
                                }

                                setId(viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.id)
                                setUid(ele.uid)
                                // dispatch(addComment( viewSpecialPrice &&viewSpecialPrice.data.data&&viewSpecialPrice.data.data.data))
                                dispatch(getCommentPart(viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.id, ele.uid, viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.sapCustomerId
                                ))
                                setSapId(viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.sapCustomerId)

                              }}>
                              <Badge
                                badgeContent={ele.unreadCommentCounts ? ele.unreadCommentCounts : ele.totalCommentCount
                                }
                                color="error"
                                className={ele.unreadCommentCounts ? "backgroundRed" : "backgroundGrey"}>
                                <QuestionAnswerOutlinedIcon className={classes.actionButtonComment} />
                              </Badge>

                            </IconButton></td>
                          </tr>)
                      })
                      }
                    </tbody>
                  </div>
                </div>
              </div>
              <div className="col-12 borderBottomViewPrice">
                <div className="row tableBottom col-12">
                  <div className="p-3 d-flex flex-row total-margin">
                    <p className="fontBottomHeader">
                      Estimated Total (excl. of VAT) :
                    </p>
                    <p className="fontBottomBold pl-1">
                      AED {parseFloat(total).toFixed(2)}
                    </p>
                  </div>
                  <div className="pl-2 pb-3 pt-3 d-flex flex-row">
                    <p className="fontBottomHeader">
                      Requested Total
                    </p>
                    <p className="fontBottomBold pl-1">
                      : {offeredBy === "Parts" ? requestedTotalParts ? parseFloat(requestedTotalParts).toFixed(2) : (parseFloat(requestedTotal).toFixed(2)) : (requestedTotal) ? parseFloat(requestedTotal).toFixed(2) : parseFloat(requestedTotalParts).toFixed(2)}
                    </p>

                    {offeredBy === "Total" ?
                      <div className="d-flex flex-row">
                        <p className="fontBottomHeader pl ml-5 marign-left-offered">
                          Offered Total:
                        </p>
                        <p className="fontBottomBold pl-1 width-spl" >
                          {selectByTotal === "By AED" ? offeredTotalAED ? parseFloat(offeredTotalAED).toFixed(2) : 0 : calculatedTotal ? parseFloat(calculatedTotal).toFixed(2) : 0}
                        </p> </div> : ""}

                  </div>

                  {offeredBy === "Parts" ?
                    <div className="p-3  d-flex flex-row mltotal">
                      <p className="fontBottomHeader pl-1">
                        Offered Total
                      </p>
                      <p className="pl-1 fontBottomBold">
                        : {TotalOfferedAed()}
                      </p>
                    </div> :
                    // Total Text Field

                    <div className="" style={{}}>
                      {/* <p>Offered Total</p> */}
                      <div className=" mb-2 pr-0 pl-2 d-flex flex-row " style={{ position: "relative" }}>
                        <div className="mt-2">
                          <TextField select
                            id="selectYear"
                            label=""
                            value={selectByTotal}
                            disabled={
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled"
                            }
                            variant="outlined"
                            className="commonRoundedInputs-dropdown-viewSpecialPrice"
                            size="small"
                            InputLabelProps={{
                              style: { color: '#000000', background: "white !important" },
                            }}
                            onChange={handelSelectTotal}
                            style={{ width: "90px" }}
                            InputProps={{
                              classes: {
                                notchedOutline: classes2.notchedOutline,
                                root: classes2.root
                              }
                            }}

                          >
                            {["By AED", "By %"].map((data: any, index: any) => (
                              <MenuItem key={index} value={data}> {data} </MenuItem>
                            ))}
                          </TextField>
                        </div>
                        <div className="pr-3">
                          <TextField id="august"
                            type="number"
                            value={selectByTotal === "By AED" ? offeredTotalAED : offeredTotalPercent}
                            onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            onChange={handleCalculationTotal}
                            InputProps={{
                              classes: {
                                notchedOutline: classes2.notchedOutline1,
                                root: classes2.root2
                              }
                            }}
                            style={{ height: "0px !important", width: "110px" }}
                            size="small" className=""
                            inputProps={{ maxLength: selectByTotal === "By AED" ? "" : 2 }}
                            label={`${selectByTotal === "By AED" ? "Enter Price" : "Enter %"}`} variant="outlined" margin="dense"
                            disabled={
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                              false} />
                          <TextField id="august"
                            type="string"
                            value={selectByTotal === "By AED" ? calculatedTotal ? `-${(calculatedTotal)}` : "" : calculatedTotal ? (calculatedTotal) : ""}
                            style={{ minWidth: "0px", width: "88px", backgroundColor: "rgba(224, 224, 224, 1)", borderRadius: "10px !important" }}
                            onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                            onChange={(e: any) => {
                            }}
                            InputProps={{
                              classes: {
                                notchedOutline: classes2.notchedOutline2,
                                root: classes2.root3
                              }
                            }}
                            size="small" className="borderRadiusText"
                            label="" variant="outlined" margin="dense"
                            disabled={
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                              viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                              false}
                          />
                        </div>
                        <IconButton
                          aria-controls="customized-menu"
                          aria-haspopup="true"
                          onClick={() => {
                            setOpenTotal(true)
                            setId(viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.id)
                            dispatch(getCommentTotal(viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.id, viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.sapCustomerId
                            ))
                            setSapId(viewSpecialPrice && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data.sapCustomerId)
                          }}>
                          <Badge
                            badgeContent={viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.commentCountOnTotal ? viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.commentCountOnTotal : totalComment
                            }
                            color="error"
                            className={viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.commentCountOnTotal
                              ? "backgroundRed" : totalComment ? "backgroundGrey" : viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.commentCountOnTotal == 0 ? "backgroundNone" : ""}>
                            <QuestionAnswerOutlinedIcon className={classes.actionButtonComment} />
                          </Badge>
                        </IconButton>
                      </div>
                      {totalError === true && <div className="errorPrice">Offered Price cannot greater than estimated price <br /> &
                        less than the requested price</div>}
                    </div>
                  }
                </div>
                {isDiscountPricePart && (
                  <div className="row tableBottom col-12">
                    <div className="pt-0 pl-3 d-flex flex-row total-margin">
                      <p className="fontBottomHeader">
                        Estimated Total (Excluding Discounted Parts) :
                      </p>
                      <p className="fontBottomBold pl-1">
                        AED {parseFloat(discountTotal).toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {open && <ViewWriteComments open={open}
            openRequestSnackbar={openSnackbar}
            showErrorMessage={showErrorMessage}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
            setSuccessMessage={setSuccessMessage} handleopenClose={handleopenClose} id={id} uid={uid} sapId={sapId} />}
          {openTotal && <ViewWriteCommentsTotal openTotal={openTotal} handleopenCloseTotal={handleopenCloseTotal} id={id} uid={uid} sapId={sapId} />}
          {openRejectPart && <RejectPartDialog openRejectPart={openRejectPart}
            openRequestSnackbar={openSnackbar}
            showErrorMessage={showErrorMessage}
            UID={UID}
            setRemoveValidationPopup={setRemoveValidationPopup}
            setDataUpdatedPrompt={setDataUpdatedPrompt}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
            setSuccessMessage={setSuccessMessage} handleOpenCloseRejectPartDialog={handleOpenCloseRejectPartDialog} />}
          {openPriceRequest && <RejectPriceRequestDialog openPriceRequest={openPriceRequest}
            openRequestSnackbar={openSnackbar}
            showErrorMessage={showErrorMessage}
            setRemoveValidationPopup={setRemoveValidationPopup}
            setDataUpdatedPrompt={setDataUpdatedPrompt}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
            setSuccessMessage={setSuccessMessage} handleOpenSpecialPrice={handleOpenSpecialPrice} />}
          {openJustfication && <ViewJustification openJustfication={openJustfication} handleJustification={handleJustification} />}
        </div>
        {/* Reject dialog for cancelling the part */}
      </div>

      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280 " : "row card card-body p-2 fixed-bottom ml-70"} style={{ zIndex: 5, overflow: "hidden" }}>
        <div className="col-lg-12 d-flex mt-2 p-0">
          <div className="col-lg-4 d-flex flex-row">
            <div className="d-flex flex-column ml-5">
              <p className="fontCommonSpecialPrice">Status</p>
              <p className="fontBottomBold">{viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status}</p>
            </div>
            <div className="d-flex flex-column ml-5">
              <p className="fontCommonSpecialPrice">Requested By</p>
              <p className="fontBottomBold">{viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.requestedByType === "part" ? "Parts" : "Total"}</p>
            </div>
            <div className="d-flex flex-column ml-5">
              <p className="fontCommonSpecialPrice">Offered By</p>
              <Tooltip title="Are you sure to change the dropdown, By doing so your data will be lost." placement="top">
                <p><TextField select id="Offered By"
                  disabled={
                    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "KAM Review"
                  }
                  value={offeredBy}
                  label="" variant="outlined" margin="dense"
                  size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                  onChange={(e: any) => {
                    setOfferedBy(e.target.value);
                    clearFormDetailsAPI()
                  }}
                >
                  {["Parts", "Total"].map((ele: any) => {
                    return (
                      <MenuItem value={ele}>{ele}</MenuItem>
                    )
                  })}
                </TextField></p>
              </Tooltip>
            </div>
          </div>
          <div className="col-lg-8 onboard-footer">
            <div className="">

              {viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ?
                <Button
                  // variant="contained"
                  color="primary" className={`Reopned-ActionButton border-left rounded-pill ml-2 mb-2`}
                  onClick={async () => {
                    setRemoveValidationPopup(true);
                    if (viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out") {
                      statusReopenedChecked();
                      dispatch(storeOrderNumberPT(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.orderNumber));
                      setResponseIDData(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.id)
                      setSapId(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.sapCustomerId)
                      handeleViewSpecialPrice(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.id, "pricing_team")
                    } else {

                    }
                  }}>Reopen</Button>
                :
                <>
                  <Button
                    variant="contained"
                    disabled={
                      //     viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                      //     viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out"
                    }
                    onClick={() => { checkAvailibilityAndPriceAPI() }} style={{ border: "" }} className={`actionButtonBottom  rounded-pill ml-2 mb-2 ${''}`}>Check Availability</Button>
                  <Button variant="contained" onClick={() => {
                    setOpenCorrection(true)
                  }}
                    className={`yellowActionButton border-left rounded-pill ml-4 mb-2 ${iscorrectionDisabled ? 'disabledButtonCommon' : ''}`}>View Corrections {requestCorrections ? `(${requestCorrections})` : ""}</Button>
                  <Button
                    disabled={
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out"
                      // viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "KAM Review"
                    }
                    variant="contained"
                    color="primary" className={`blueActionButton border-left rounded-pill ml-2 mb-2`}
                    onClick={async () => {
                      // validateTextFiledsOnClickOfSave();
                      setRemoveValidationPopup(true);
                      saveFormDetailsAPI();
                    }}>Save</Button>
                  <Button
                    disabled={
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "KAM Review"
                    }
                    variant="contained" onClick={() => {
                      setRemoveValidationPopup(true);
                      setOpenPriceRequest(true)
                    }}
                    className={`ml-2 mb-2 ${viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ? "redActionButton border-left rounded-pill disabledButtonCommon" : "redActionButton border-left rounded-pill"}`} >Reject</Button>
                  <Button
                    disabled={
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Rejected" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Approved" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Cancelled" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "Timed Out" ||
                      viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.status === "KAM Review" ||
                      responseSuccess === false
                    }
                    variant="contained"
                    onClick={async () => {
                      setRemoveValidationPopup(true);
                      validateTextFiledsForForwardToKam();
                    }}
                    color="primary" className={`blueActionButton border-left rounded-pill ml-2 mb-2`} >Forward to KAM</Button>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      {/* <Prompt when={dataUpdatedPrompt} message={"Do you wish to proceed without saving the data?"}></Prompt> */}
      {/* <Prompt when={offeredSaved === true} message={"Do you wish to proceed without saving the data?"}></Prompt> */}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={100}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <NavigationPrompt when={(dataUpdatedPrompt === true || dataUpdatedPrompt === false && removeValidationPopup !== true)}>
        {({ onConfirm, onCancel }) => (
          <React.Fragment>
            <Dialog
              disableBackdropClick={true}
              open={true}
              fullWidth={true}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className={`mt-3 ${classes.logoutText}`}>
                      Are you sure you want to leave this Page ?
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions>
                <>
                  <Button
                    className={`mx-4 `}
                    onClick={async () => {
                      setRespId(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.id);
                      backButtonUserRestriction(viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.id)
                      onConfirm();
                    }}>Yes</Button>
                  <Button
                    className={`mr-4 ${classes.cancel}`}
                    onClick={() => {
                      onCancel();
                    }}>No</Button>
                </>
              </DialogActions>
            </Dialog>
          </React.Fragment>
        )}
      </NavigationPrompt>
      <ForwardToKamDialog
        open={showApproveDialog}
        fordwardToKamAPIDetails={fordwardToKamAPIDetails}
        openRequestSnackbar={openSnackbar}
        setDataUpdatedPrompt={setDataUpdatedPrompt}
        showErrorMessage={showErrorMessage}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
        setSuccessMessage={setSuccessMessage}
        handleopenClose={(data: any) => {
          setDataUpdatedPrompt(false)
          setshowApproveDialog(data ? true : false)
        }}
      />
      <ReopnedStatusDialog
        open={showReopenDialog}
        sapId={sapId}
        responseIDData={responseIDData}
        respId={respId}
        statusReopenedChecked={statusReopenedChecked}
        setRemoveValidationPopup={setRemoveValidationPopup}
        openRequestSnackbar={openSnackbar}
        showErrorMessage={showErrorMessage}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
        setSuccessMessage={setSuccessMessage}
        handleopenClose={(data: any) => {
          setShowReopenDialog(data ? true : false)
        }}
      />
      {<CommentApproveDialog handleOpenCommentApproveDialog={handleOpenCommentApproveDialog} openApproveCommentDialog={openApproveCommentDialog} closeApproveDialog={closeApproveDialog} />}
      {openCorrection && <RequestCorrection openCorrection={openCorrection} handleCloseCorrection={handleCloseCorrection} />}
      <Documents openDocuments={openDocuments} handleDocuments={handleDocuments} />
    </div >
  )
}

export default ViewPriceRequest;