import { requestQuoatationServices } from "../../services/quotation.service";
import { quotation } from "../../services/quotation.service";
import { QUOTATION } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";

export function postRequestQuotation(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.POST_REQUEST_QUOTATION_FETCHING));
        try {
            const response = await requestQuoatationServices.postRequestQuotation(data);
            dispatch(apiSuccess(QUOTATION.POST_REQUEST_QUOTATION_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(QUOTATION.POST_REQUEST_QUOTATION_ERROR, error));
            return error;
        }
    };
}

export function viewLPO(webApplicationLoginId: any, enqId: any,sapID: any, id: any, orderNumber: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.VIEW_LPO_FETCHING));
        try {
            const response = await requestQuoatationServices.viewLPO(webApplicationLoginId, enqId, sapID, id, orderNumber)
            dispatch(apiSuccess(QUOTATION.VIEW_LPO_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(QUOTATION.VIEW_LPO_ERROR, error));
            return error;
        }
    };
}

export function postOpenQuotation(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.POST_OPEN_QUOTATION_FETCHING));
        try {
            const response = await requestQuoatationServices.postOpenQuotation(data);
            dispatch(apiSuccess(QUOTATION.POST_OPEN_QUOTATION_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(QUOTATION.POST_OPEN_QUOTATION_ERROR, error));
            return error;
        }
    };
}

export function getQuotation(data: any, page: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.GET_QUOTATION_FETCHING))
        try {
            const response = await quotation.getQuotation(data, page)
            dispatch(apiSuccess(QUOTATION.GET_QUOTATION_SUCCESS, response))
            return response || []
        } catch (error) {
            dispatch(apiError(QUOTATION.GET_QUOTATION_ERROR, error))

        }
    }
}

export function getFSEQuotation(data: any, page?: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.GET_QUOTATION_FETCHING))
        try {
            const response = await quotation.getFSEQuotation(data, page)
            dispatch(apiSuccess(QUOTATION.GET_QUOTATION_SUCCESS, response))
            return response || []
        } catch (error) {
            dispatch(apiError(QUOTATION.GET_QUOTATION_ERROR, error))

        }
    }
}

export function uploadLpo(data: any, customHeaders: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.UPLOAD_LPO_QUOTATION_FETCHING))
        try {
            const response = await quotation.uploadLpo(data, customHeaders)
            dispatch(apiSuccess(QUOTATION.UPLOAD_LPO_QUOTATION_SUCCESS, response))
            return response || []
        } catch (error) {
            dispatch(apiError(QUOTATION.GET_QUOTATION_ERROR, error))

        }
    }
}

export function uploadRequestOrder(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.REQUEST_ORDER_QUOTATION_FETCHING))
        try {
            const response = await quotation.uploadRequestOrder(data)
            dispatch(apiSuccess(QUOTATION.REQUEST_ORDER_QUOTATION_SUCCESS, response))
            return response || []
        }
        catch (error) {
            dispatch(apiError(QUOTATION.REQUEST_ORDER_QUOTATION_ERROR, error))
        }
    }
}

export function getQuotationListStatus() {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.GET_QUOTATION_LIST_STATUS_FETCHING))
        try {
            const response = await quotation.getQuotationListStatus()
            dispatch(apiSuccess(QUOTATION.GET_QUOTATION_LIST_STATUS_SUCCESS, response))
            return response || []
        }
        catch (error) {
            dispatch(apiError(QUOTATION.GET_QUOTATION_LIST_STATUS_ERROR, error))
        }
    }
}


export function cancelQuotation(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.CANCEL_QUOTATION_FETCHING))
        try {
            const response = await quotation.cancelQuotation(data)
            dispatch(apiSuccess(QUOTATION.CANCEL_QUOTATION_SUCCESS, response))
            return response || []
        }
        catch (error) {
            dispatch(apiError(QUOTATION.CANCEl_QUOTATION_ERROR, error))
        }
    }
}

export function getUser(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.GET_USER_FETCHING))
        try {
            const response = await quotation.getUser(data)
            dispatch(apiSuccess(QUOTATION.GET_USER_SUCCESS, response))
            return response || []
        }
        catch (error: any) {
            dispatch(apiError(QUOTATION.GET_USER_ERROR, error))
        }
    }
}

export function downloadQuotation(enqId: any, id: any, brandId: any, type?: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.DOWNLOAD_QUOTATION_FETCHING))
        try {
            const response = await quotation.downloadQuotation(enqId, id, brandId, type)
            dispatch(apiSuccess(QUOTATION.DOWNLOAD_QUOTATION_SUCCESS, response))
            return response || []
        }
        catch (error: any) {
            dispatch(apiError(QUOTATION.DOWNLOAD_QUOTATION_ERROR, error))
        }
    }
}


export function uploadLpoEmail(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(QUOTATION.UPLOAD_LPO_EMAIL_FETCHING))
        try {
            const response = await quotation.uploadLpoEmail(data)
            dispatch(apiSuccess(QUOTATION.UPLOAD_LPO_EMAIL_SUCCESS, response))
            return response || []
        }
        catch (error: any) {
            dispatch(apiError(QUOTATION.DOWNLOAD_QUOTATION_ERROR, error))
        }
    }
}

export function quotationDetailsViewOrder(data: any) {
    return async (dispatch: any) => {
        dispatch(apiSuccess(QUOTATION.QUOTATION_DETAILS_VIEWORDER_SUCCESS, data))
    }
}