/* eslint-disable */
import { useSelector } from 'react-redux';
import "./ViewWriteComments.css";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import NoImage from "../../assets/NoImage.png";
import { addComment } from '../../store/actions/specialprice.actions';
import { useDispatch, } from "react-redux";
import React, { Fragment, Dispatch, useEffect } from "react";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import moment from "moment";
import { getCommentPart, viewSpecialPriceAPI } from '../../store/actions/specialprice.actions';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import defaultLogo from '../../assets/default-user-logo.png';
import { viewSpecialPriceKamAPI } from '../../store/actions/specialPriceKam.actions';


const useStyles = makeStyles((theme) => ({

    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    cancelButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: 1000,
        height: 35,
        textAlign: 'center',
        marginRight: 24,
        padding: "10px !important"
    },
    close: {
        color: '#000000',
        fontWeight: 900
    }
}));

const styles = makeStyles({
    notchedOutline: { borderColor: "rgba(224, 224, 224, 1) !important", borderRadius: "25px 0px 0px 25px" },
    root: {
        "& .MuiOutlinedInput-inputMarginDense": {
            paddingBottom: "11px !important",
            paddingTop: "11px !important",
            borderRadius: "40px 40px 0px 0px !important",
        },

    },
    notchedOutline1: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "25px", },
    notchedOutline2: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1)  !important", borderLeftColor: "rgba(224, 224, 224, 1)  !important", borderRadius: "0px 25px 25px 0px", background: "rgba(224, 224, 224, 1)  !important", width: "100px" },
})

const ViewWriteComments = (props: any) => {
    const [comment, setComment] = React.useState("");
    const [isAddComment, setIsAddComment] = React.useState(false);
    const [commentData, setCommentData] = React.useState<any>([])
    const leftMenuVisibility: any = useSelector(
        (state: any) => state.global && state.global.sideMenuOpened
    );
    const [loading, setLoading] = React.useState<any>(false);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const dispatch: Dispatch<any> = useDispatch()
    let firstName: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.firstName
    let lastName: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.lastName
    let name: any = firstName + " " + lastName
    let webId: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.webApplicationLoginId
    let userType: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType
    const { getCommentsPart, addComments } = useSelector((state: any) => state.specialPrice)
    const classes = useStyles();
    const classes2 = styles();
    let dataCommentdata: any


    useEffect(() => {
        if (addComments) {
            if (getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.comments.length >= addComments && addComments.data && addComments.data.data && addComments.data.data.data && addComments.data.data.data.comments.length) {
                dataCommentdata = getCommentsPart
                setCommentData(getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.comments)
            } else if (getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.comments.length <= addComments && addComments.data && addComments.data.data && addComments.data.data.data && addComments.data.data.data.comments.length) {
                setCommentData(addComments && addComments.data && addComments.data.data && addComments.data.data.data && addComments.data.data.data.comments)
            }
            else {
                setCommentData(getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.comments)
            }
        } else {
            setCommentData(getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.comments)
        }
    }, [getCommentsPart, addComments])

    const handleAddComment = async () => {
        let data: any = await dispatch(addComment(props.id, props.uid, webId, name, userType, comment))
        let dataGetcomment: any = await dispatch(getCommentPart(props.id, props.uid, props.sapId, 'kam'))
        //     if (data.hasOwnProperty("error")) {
        //         setErrorSnackBar(true)
        //         setErrorMessage(data && data.error)
        //     }
        //     setComment("")
        // }
        if (data && data.message && data.message.status === 'S') {
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage(data && data.message && data.message.message);
            setLoading(false)
            dispatch(viewSpecialPriceKamAPI(props.id, "kam"))
            props.handleopenClose(false);
        } else if (data && data.message && data.message.status === "E") {
            props.handleopenCloseErrorSnackbar(true);
            props.showErrorMessage(data && data.error);
            setTimeout(() => {
                setLoading(false)
                props.handleopenCloseSnackbar(false);
                props.handleopenClose(false);
            }, 2000)
        }
        setComment("")
    }

    return (
        <div>
            {addComments && addComments.loading || getCommentsPart && getCommentsPart.loading && <FullscreenLoader />}
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false) }}
                fullWidth={true}
                maxWidth={'md'}
            >

                <DialogTitle>
                    <div className="justifyContentHorizontalSpacebetween align-items-center">
                        <h4 className="page-title">View / Write Comments</h4>
                        <IconButton onClick={() => { props.handleopenClose(false); let data: any = dispatch(viewSpecialPriceKamAPI(props.id, "kam")) }}>
                            <CloseIcon fontSize="inherit" className={classes.close} />
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12 pr-3 mb-3 d-flex flex-row">
                            <p className="pr-2">{getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.B2bEnquiryId
                            }</p>
                            <div className="pr-2" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "20px", opacity: 0.3 }}>
                            </div>
                            <p className="pr-2">{getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.productId} - {getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.productIdDescription
                            }</p>
                            <div className="pr-2" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "20px", opacity: 0.3 }}>
                            </div>
                            <p className="pr-2">{getCommentsPart && getCommentsPart.data && getCommentsPart.data.data && getCommentsPart.data.data.data && getCommentsPart.data.data.data.companyName
                            }</p>
                        </div>
                        {commentData && commentData.map((ele: any) => {
                            return (
                                <div className="col-12 col-sm-12 col-md-12 col-lg-12  mb-3 d-flex flex-row mt-2">
                                    <div className="d-flex">
                                        <div style={{ display: "inline" }}>
                                            <img
                                                src={defaultLogo}
                                                alt="Logo Client"
                                                className="dealer-grid-logo"
                                            /></div>
                                    </div>
                                    <div className="col-9 d-flex flex-column">
                                        <p className="colorBlackPriceRequest">
                                            {ele.comment}
                                        </p>
                                        <div className="d-flex flex-row">
                                            <p className="colorBlackPriceRequest">{`By ${ele.addedByRole}
                                            `}</p>
                                            <p className="pl-2">|</p>
                                            <p className="pl-2 pr-2">{moment(ele.addedOn
                                            ).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</p>
                                        </div>
                                    </div>
                                </div>

                            )
                        })}

                        <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-3">
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-3">
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-3">
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-3">
                        </div>
                    </div>
                </DialogContent>
                <div className="d-flex flex-row justify-content-end col-12 d-flex flex-row">
                    `<div className="mt-2 col-8 "> <TextField id="august"
                        type="text"
                        value={comment}
                        onKeyDown={(e: any) => [""].includes(e.key) && e.preventDefault()}
                        onChange={(e: any) => {
                            let updateValue = parseInt(e.target.value || 0);
                            let initialValue = ""
                            setComment(e.target.value)
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: classes2.notchedOutline1,
                                root: classes2.root
                            }
                        }}
                        style={{ minWidth: "0px", width: "700px" }}
                        size="small" className=""
                        label="Write your comment here" variant="outlined" margin="dense" disabled={false} /></div>
                    <DialogActions className="mt-2 mb-4 col-4 pl-4 justify-content-end">
                        <Button className="add-user-tabs-button font-size-11 mr-2" onClick={() => {
                            handleAddComment();
                        }}>Add</Button>
                        <Button variant="contained" className={classes.cancelButton} onClick={() => {
                            props.handleopenClose(false);
                            let data: any = dispatch(viewSpecialPriceKamAPI(props.id, "kam"))
                        }}>Close</Button>
                    </DialogActions>
                </div>
            </Dialog >
        </div>
    )
}

export default ViewWriteComments