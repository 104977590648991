import { DELIVERY } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { deliveryService } from "../../services/delivery.service";

export const deliveryActions = {
  getDeliveryList,
  getDeliveryDetails,
  selectedViewDelivery
}

export function getDeliveryList(orderNumber: any, enquiryNumber: any, make: any, backOrderNumber: any, sapId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DELIVERY.GET_DELIVERY_LIST_FETCHING));
    const response = await deliveryService.getDeliveryList(orderNumber, enquiryNumber, make, backOrderNumber, sapId);
    if (response.error) {
      dispatch(apiError(DELIVERY.GET_DELIVERY_LIST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DELIVERY.GET_DELIVERY_LIST_SUCCESS, response));
      return response || [];
    }
  };
}

export function getDeliveryDetails(enquiryNumber: any, deliveryNumber: any, make: any, sapId: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(DELIVERY.GET_DELIVERY_DETAILS_FETCHING));
    const response = await deliveryService.getDeliveryDetails(enquiryNumber, deliveryNumber, make, sapId, page);
    if (response.error) {
      dispatch(apiError(DELIVERY.GET_DELIVERY_DETAILS_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(DELIVERY.GET_DELIVERY_DETAILS_SUCCESS, response));
      return response || [];
    }
  };
}


export function ViewDelivery(order: any) {
  return async (dispatch: any) => {
    if (order) {
      dispatch(apiSuccess(DELIVERY.VIEW_DEILVERY_SUCCESS, order))
    } else if (!order) {
      dispatch(apiError(DELIVERY.VIEW_DELIVERY_ERROR, {}))
    }
  }
}

export function selectedViewDelivery(order: any) {
  return async (dispatch: any) => {
    if (order) {
      dispatch(apiSuccess(DELIVERY.VIEW_DEILVERY_SELECTED_SUCCESS, order))
    } else if (!order) {
      dispatch(apiError(DELIVERY.VIEW_DELIVERY_SELECTED_ERROR, {}))
    }
  }
}

export function downloadInvoice() {
  return async (dispatch: any) => {

  }
}