import React, { Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { useEffect } from 'react';
import { storeBrandValues } from '../../store/actions/dealer.action';

function BrandDetails(props: any) {
    const [brands, setBrandValues] = React.useState<any>({ creditControlArea: '', distributionChannel: '', division: '', salesOffice: '', salesOrganization: '', salesGroup: '', salesEmployee: '', assignKam: '' });
    const { brandValues, userDetails }: any = useSelector((state: any) => state.dealer);
    const dispatch: Dispatch<any> = useDispatch();
    const { partiallyCompleteBrandValidation, fillAtleastOneBrandDetail, formSubmitted } = props;
    const userType = sessionStorage.getItem('userType');

    const isDisabled = (brand: any) => {
        if (userType === 'afg_super_admin' || brand.status === 'deActive') {
            return true;
        } else {
            return false;
        }
    }
    const onChange = (e: any, value: any) => {
        // Object.assign(brands, { [value]: e.target.value })
        Object.assign(brands, value === "creditControlArea" || "salesOffice" ? { [value]: e.target.value.toUpperCase() } : { [value]: e.target.value })
        dispatch(storeBrandValues(brandValues));
    }

    useEffect(() => {
        if (brandValues && brandValues[props.make]) {
            setBrandValues(brandValues[props.make]);
        }
    }, [brandValues]) // eslint-disable-line

    return (
        <Grid container>
            {brands.status === 'deActive' && (
                <Grid item xs={12} style={{ color: 'red' }} className='font-weight-600 mb-2'>
                    Brand access denied for the Customer.
                </Grid>
            )}
            <Grid item xs={6}>
                <TextField required value={brands.creditControlArea}
                    error={formSubmitted && (fillAtleastOneBrandDetail || (partiallyCompleteBrandValidation && partiallyCompleteBrandValidation.length > 0)) && !brands.creditControlArea}
                    label="Credit Control Area" className="rounded-input-dealer w-100 pr-4 pb-2"
                    disabled={isDisabled(brands)}
                    margin="dense" variant="outlined"
                    onChange={(e) => {
                        if (!e.target.value || (e.target.value && e.target.value.length < 5))
                            onChange(e, 'creditControlArea')
                    }}
                    helperText={(brands.creditControlArea && brands.creditControlArea.length < 4) && <span style={{ color: 'red' }}>Please enter 4 character Credit Control Area</span>}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.salesOrganization}
                    label="Sales Org" className="rounded-input-dealer w-100 pb-2"
                    margin="dense" variant="outlined"
                    disabled={isDisabled(brands) ? isDisabled(brands) : userDetails && userDetails.status !== 'Active'}
                // onChange={(e) =>  onChange(e, 'salesOrganization') } 
                // helperText={!brands.creditControlArea || (brands.creditControlArea && brands.creditControlArea.length < 4) && <span style={{ color: 'red' }}>Please Enter 4 character Credit control area</span> }
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.distributionChannel}
                    error={formSubmitted && (fillAtleastOneBrandDetail || (partiallyCompleteBrandValidation && partiallyCompleteBrandValidation.length > 0)) && !brands.distributionChannel}
                    label="Distribution Channel" className="rounded-input-dealer w-100 pr-4 pb-2"
                    disabled={isDisabled(brands)}
                    margin="dense" variant="outlined"
                    onChange={(e) => { if (!e.target.value || (e.target.value && e.target.value.length < 3)) onChange(e, 'distributionChannel') }}
                    helperText={(brands.distributionChannel && brands.distributionChannel.length < 2) && <span style={{ color: 'red' }}>Please enter 2 digit Distribution Channel</span>}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.division}
                    error={formSubmitted && (fillAtleastOneBrandDetail || (partiallyCompleteBrandValidation && partiallyCompleteBrandValidation.length > 0)) && !brands.division}
                    label="Division" className="rounded-input-dealer w-100 pb-2"
                    margin="dense" variant="outlined"
                    disabled={isDisabled(brands)}
                    onChange={(e) => { if (!e.target.value || (e.target.value && e.target.value.length < 3)) onChange(e, 'division') }}
                    helperText={(brands.division && brands.division.length < 2) && <span style={{ color: 'red' }}>Please enter 2 Digit Division</span>}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.salesOffice}
                    error={formSubmitted && (fillAtleastOneBrandDetail || (partiallyCompleteBrandValidation && partiallyCompleteBrandValidation.length > 0)) && !brands.salesOffice}
                    label="Sales Office" className="rounded-input-dealer w-100 pr-4 pb-2"
                    disabled={isDisabled(brands)}
                    margin="dense" variant="outlined" onChange={(e) => { if (!e.target.value || (e.target.value && e.target.value.length < 5)) onChange(e, 'salesOffice') }}
                    helperText={(brands.salesOffice && brands.salesOffice.length < 4) && <span style={{ color: 'red' }}>Please enter 4 Character Sales Office</span>}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.salesGroup}
                    error={formSubmitted && (fillAtleastOneBrandDetail || (partiallyCompleteBrandValidation && partiallyCompleteBrandValidation.length > 0)) && !brands.salesGroup}
                    label="Sales Group" className="rounded-input-dealer w-100 pb-2"
                    disabled={isDisabled(brands)}
                    margin="dense" variant="outlined" onChange={(e) => { if (!e.target.value || (e.target.value && e.target.value.length < 4)) onChange(e, 'salesGroup') }}
                    helperText={(brands.salesGroup && brands.salesGroup.length < 3) && <span style={{ color: 'red' }}>Please enter 3 character Sales Group</span>}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.salesEmployee}
                    error={formSubmitted && (fillAtleastOneBrandDetail || (partiallyCompleteBrandValidation && partiallyCompleteBrandValidation.length > 0)) && !brands.salesEmployee}
                    label="Sales Employee" placeholder="Enter FSE Employee ID" className="rounded-input-dealer w-100 pr-4 pb-2"
                    margin="dense" variant="outlined"
                    onChange={(e) => { if (!e.target.value || (e.target.value && e.target.value.length < 7)) onChange(e, 'salesEmployee') }}
                    disabled={isDisabled(brands)}
                    helperText={(brands.salesEmployee && brands.salesEmployee.length < 6) && <span style={{ color: 'red' }}>Please enter 6 digit FSE Sales Employee ID</span>}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField required value={brands.assignKam}
                    label="Assigned KAM" placeholder="Enter KAM Employee ID" className="rounded-input-dealer w-100 pb-2"
                    disabled
                    margin="dense" variant="outlined"
                    onChange={(e) => { if (!e.target.value || (e.target.value && e.target.value.length < 7)) onChange(e, 'assignKam') }}
                // helperText={(brands.assignKam && brands.assignKam.length < 6) && <span style={{ color: 'red' }}>Please enter 6 digit KAM Employee ID</span>}
                />
            </Grid>
        </Grid>
    )
}

export default BrandDetails