/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import RequestOrder from "./RequestOrder";
import UploadLpo from "./UploadLpo";
import CancelQuotation from "./CancelQuotation";
import { downloadQuotation, viewLPO } from "../../store/actions/quotation.action";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { useDispatch, useSelector } from "react-redux";
import _, { get } from "lodash";

const useStyles = makeStyles((theme) => ({
  uploadButton: {
    minWidth: "180px !important",
    border: "1px solid",
    background: '#1A9FE0',
    borderRadius: "25px !important",
    color: 'white !important',
    fontSize: "12px !important",
    padding: '2px 20px',
    marginTop: '8px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    height: "2.6rem",
    paddingTop: "0px",
    paddingBottom: "1px",
    "&:hover, &:focus": {
      background: '#1A9FE0',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 12,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    },
    "&:disabled": {
      background: '#8ED1FC',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
}));

const OrderHistoryViewLPO = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [openCancelQuotation, setÖpenCancelQuotation] = React.useState(false)
  const classes = useStyles();
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  const customerID = sessionStorage.getItem('selectedCustomerID');
  const sapCustomerId = sessionStorage.getItem('userType') === 'FSE' && props.sapCustomerId !== "" || undefined ? customerID : sessionStorage.getItem('userType') === 'FSE' ? props.sapCustomerId : get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const dispatch: Dispatch<any> = useDispatch();
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  let overallDeliveryStatus = sessionStorage.getItem('overallDeliveryStatus');

  const handleUploadLpo = () => {
    setOpenUploadLpo(false)
  }

  const handleCloseRequestOrder = () => {
    setOpenRequestOrder(false)
  }

  const handleCancelQuotation = () => {
    setÖpenCancelQuotation(false)
  }

  // ON CHANGE OPEN - CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleViewLPO = async () => {
    if (props.quotationNumber) {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, props.enquiryNumber, sapCustomerId, props.quotationNumber, ""))
      if (data && data.error) {
        showErrorMessage(data && data.error)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
      } else {
        if (data) {
          if (props.fileExtension === 'pdf') {
            fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
              const file = new Blob([resp], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = "LPO" + ".pdf";
              link.click();
            });
          } else if (props.fileExtension === 'png' || props.fileExtension === 'jpg' || props.fileExtension === 'jpeg') {
            window.open(props.viewQuotationUrl);
          } else {
            window.open(props.viewQuotationUrl);
          }
        }
        // if (data) {
        //   fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
        //     const file = new Blob([resp], { type: 'application/pdf' });
        //     const fileURL = URL.createObjectURL(file);
        //     const link = document.createElement('a');
        //     link.href = fileURL;
        //     link.download = "LPO" + ".pdf";
        //     link.click();
        //   });
        // }
      }
    } else {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, props.enquiryNumber, sapCustomerId, "", props.orderNumber))
      if (data && data.error) {
        showErrorMessage(data && data.error)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
      } else {
        if (data) {
          if (props.fileExtension === 'pdf') {
            fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
              const file = new Blob([resp], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = "LPO" + ".pdf";
              link.click();
            });
          } else if (props.fileExtension === 'png' || props.fileExtension === 'jpg' || props.fileExtension === 'jpeg') {
            window.open(props.viewQuotationUrl);
          } else {
            window.open(props.viewQuotationUrl);
          }
        }
      }
      // data && data.data && data.data.map((ele: any) => {
      //   window.open(ele && ele.url);
      // })
    }
  }

  return (
    <div className="full-dialog">
      <React.Fragment>
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog className="full-dialog" fullWidth={true} maxWidth="xl" open={props.openViewLPO ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleCloseViewLPO()
            }
          }}>
          <DialogTitle className="pb-0" id="form-dialog-title">
            <div className="page-title">View LPO
              <IconButton aria-label="close" className="float-right" onClick={() => props.handleCloseViewLPO()}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers className="p-0">
            {props.fileExtension === 'pdf' && (
              <iframe
                title="pdf"
                src={props.viewQuotationUrl}
                className="h-100vh w-100"
                width="100%"
              />
            )}
            {(props.fileExtension === 'png' || props.fileExtension === 'jpg' || props.fileExtension === 'jpeg') && (
              <img
                src={props.viewQuotationUrl}
                alt="format"
                className="h-100vh w-100"
                width="100%"
              />
            )}
            {/* <iframe
              title="pdf"
              src={props.viewQuotationUrl}
              // src="https://strautohlcommqa.blob.core.windows.net/mallstore/Sales_Quotation_20005922.pdf"
              className="h-100vh w-100"
              width="100%"
            /> */}
          </DialogContent>
          <DialogActions className=" p-3 justify-content-start">
            <div className="float-left">
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); handleViewLPO() }}>
                Download LPO
              </Button>
              {props.overallDeliveryStatus === "Fully Invoiced" || props.overallDeliveryStatus === "Partially Invoiced" || props.overallDeliveryStatus === "Fully delivered" || props.overallDeliveryStatus === "Partially delivered" || props.overallDeliveryStatus === "Delivery Created" || sessionStorage.getItem('SubEnquiry-Status') === "Delivery Created"  ||  sessionStorage.getItem('SubEnquiry-Status') === "Fully delivered" || sessionStorage.getItem('SubEnquiry-Status') === "Partially delivered" ?
                <Button variant="contained" className={classes.uploadButton} disabled onClick={() => { setOpen(false); props.updateLPO() }}>
                  Update LPO
                </Button>
                :
                <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); props.updateLPO() }}>
                  Update LPO
                </Button>
              }
            </div>
          </DialogActions>
          <DialogActions className="justify-content-end mt-n-65">
            {/* <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); setOpenRequestOrder(true) }}>
              Request Quotation
            </Button> */}
            <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { props.handleCloseViewLPO() }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div >
  )
}
export default OrderHistoryViewLPO;
