import { ACHIEVEMENT } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { achievementService } from "../../services/achievment.service";

export const achievementActions ={
   getPurChaseHistory,
   getPurChaseHistoryByYear,
   isAccesstoAchievement,
}

export function getPurChaseHistory(b2b:string,webid:string,sapid:string,year:string){
     return async (dispatch:any)=>{
       dispatch(apiStart(ACHIEVEMENT.GET_PURCHASE_FETCHING))
        try {
          const response = await(achievementService.getPurChaseHistoryData(b2b,webid,sapid,year))
          dispatch(apiSuccess(ACHIEVEMENT.GET_PURCHASE_SUCCESS,response))
          return  response || []
        }
        catch (error) {
          dispatch(apiSuccess(ACHIEVEMENT.GET_PURCHASE_ERROR,error))
          return error
        }
     }
}

export  function getPurChaseHistoryByYear(b2b:string,webid:string,sapid:string,year:string, dealer: any){
     return async(dispatch:any)=>{
       dispatch(apiStart(ACHIEVEMENT.GET_PURCHASE_BY_YEAR_FETCHING))
       try{
         const response = await(achievementService.getPurChaseHistoryYearData(b2b,webid,sapid,year, dealer))
         dispatch(apiSuccess(ACHIEVEMENT.GET_PURCHASE_BY_YEAR_SUCCESS,response))
         return response || []
       }
       catch(error){
         dispatch(apiError(ACHIEVEMENT.GET_PURCHASE_BY_YEAR_ERROR,error))
         return error
       }
     }
    }

export function isAccesstoAchievement(b2b: string, sapid: string) {
  return async (dispatch: any) => {
    dispatch(apiStart(ACHIEVEMENT.IS_ACCESS_ACHIEVEMENT_FETCHING))
    try {
      const response = await (achievementService.isAccesstoAchievement(b2b, sapid))
      dispatch(apiSuccess(ACHIEVEMENT.IS_ACCESS_ACHIEVEMENT_SUCCESS, response))
      return response || []
    }
    catch (error) {
      dispatch(apiSuccess(ACHIEVEMENT.IS_ACCESS_ACHIEVEMENT_ERROR, error))
      return error
    }
  }
}