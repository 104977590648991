/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import { get } from 'lodash';
import { createTheme } from '@material-ui/core/styles';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { createUpdateEnquiryService } from '../../services/createUpdateEnquiry.service ';
import { getNewBrand } from '../../common/components/util';
import { getOfflinePayment } from '../../store/actions/orderSummary.actions';
import creditcardicon from '../../assets/creditcard.png';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    title: {
        flex: '1 1 100%',
    },
    createButton: {
        background: '#4ec766 !important',
        width: 160,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: 'white !important',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 8
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 160,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
}));

const theme = createTheme({
    palette: {
        secondary: {
            main: '#1A9FE0'
        },
    }
});

export default function MakePaymentViewOrder(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const { creditLimitDetails }: any = useSelector((state: any) => state.creditLimitDetails);
    const creditLimitData = get(creditLimitDetails, 'data.data.data', []);
    const { setOpenDialog, orderData } = props;
    const [paymentStatus, setPaymentStatus] = React.useState<any>();
    const [orderStatus, setOrderStatus] = React.useState<any>();
    const [loading, setLoading] = React.useState<any>(false);
    const [notSelected, setNotSelected] = React.useState<any>(false);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const companyCurrencyType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType
    const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', "");
    const { enquiryInfo, enquirySessionId }: any = useSelector((state: any) => state.createUpdateEnquiry);
    const sapCustomerId = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
    const [selected, setSelected] = React.useState<any>('');
    const paymentMode = profileDetails?.data?.data?.customerInfo?.paymentMode;
    const { setSuccessMessage, handleopenCloseSnackbar, setOpenErrorSnackbar, openErrorSnackbar } = props;
    const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
    const companyCurrencyTypeByFSE: any = sessionStorage.getItem('companyCurrencyType');
    const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');

    const handleChange = (event: any) => {
        setSelected(event.target.value);
    };
    const [selectedCredit, setSelectedCredit] = React.useState<any>('');

    const handleCreditChange = (event: any) => {
        setSelectedCredit(event.target.value);
    };


    const orgIdBrandsMappingReverse: any = {
        "Toyota": "2001",
        "Honda": "2002",
        "Famco": "2003",
        "TE": "2380",
        "OES": "2195"
    }

    // ON CLICK OF PAY BUTTON - API INTEGRATION
    const userMakePaymentAPI = async () => {
        if (selected !== 'OFFLINE') {
            setLoading(true);
            let data: any = await createUpdateEnquiryService.userPaymentData(
                {
                    "b2bUserId": b2bUserId,
                    "B2bEnquiryId": orderData.B2bEnquiryId,
                    customerType: customerTierType.toLowerCase(),
                    "enquiryNumber": orderData.enquiryNumber,
                    "sapCustomerId": sapCustomerId,
                    "orderNumber": orderData.orderNumber ? orderData.orderNumber : orderData.backOrderNumber,
                    "isBackOrder": orderData.isBackOrder ? true : false,
                    "webApplicationLoginId": sessionStorage.getItem("webApplicationLoginId"),
                    "items": [
                        {
                            "itemNumber": "0010",
                            "description": "RESERVATION",
                            "currency": sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED',
                            "outstandingAmount": `${parseInt(orderData.orderTotal)}`,
                            "amount": `${parseInt(orderData.orderTotal)}`
                        }
                    ],
                    "notes": "RESERVATION",
                    "modeOfPayment": selected === 'BANK' ? "BANK" : "CARD",
                    "brandId": orgIdBrandsMappingReverse[orderData.make],
                    "make": orderData.make
                }
            )
            if (data && data.data && data.data.Registration) {
                const form = document.createElement('form');
                form.method = "POST";
                form.action = data.data.Registration.PaymentPage;
                const hiddenField = document.createElement('input');
                hiddenField.type = 'hidden';
                hiddenField.name = "TransactionID"
                hiddenField.value = data.data.Registration.TransactionID;
                form.appendChild(hiddenField);
                document.body.appendChild(form);
                form.submit();
                setLoading(false);
            } else if (data && data.message && data.message.status === 'S') {
                props.setSuccessMessage(data.message.message);
                props.handleopenCloseSnackbar(true);
            } else if (data && data.message && data.message.status === 'E') {
                props.setSuccessMessage(typeof data.error == 'string' ? data.error : 'Payment Request failed');
                // props.setSuccessMessage(data.message.message);
                props.handleopenCloseSnackbar(true);
                //   props.openErrorSnackbar(true);
                props.setOpenErrorSnackbar(true);
            }
        } else {
            setLoading(true);
            let data: any = await createUpdateEnquiryService.offlinePaymentData(
                {
                    "masterId": orderData.B2bEnquiryId,
                    "enquiryNumber": orderData.enquiryNumber,
                    "paymentMode": "OFFLINE",
                    customerType: customerTierType.toLowerCase(),
                    "orderNumber": orderData.orderNumber ? orderData.orderNumber : orderData.backOrderNumber,
                    "isBackOrder": orderData.isBackOrder ? true : false,
                }
            )

            if (data && data.message && data.message.status === 'S') {
                props.setSuccessMessage("Order Successfully Placed");
                props.handleopenCloseSnackbar(true);
                dispatch(getOfflinePayment(data))
            } else if (data && data.message && data.message.status === 'E') {
                props.setSuccessMessage(data.error);
                // props.setSuccessMessage(data.message.message);
                props.handleopenCloseSnackbar(true);
                //   props.openErrorSnackbar(true);
                props.setOpenErrorSnackbar(true);
                dispatch(getOfflinePayment(data))
            }
            setOpenDialog();
        }
    }

    const getCreditLimitAsperBrand = (ele: any) => {
        let creditValue = creditLimitData.filter((ele: any) => {
            return ele.brand === orderData.make
        })
        return creditValue && creditValue[0] && creditValue[0].availableBalance ? creditValue && creditValue[0].availableBalance : "0"
    }


    const getCredit = (ele: any) => {
        let wallet = getCreditLimitAsperBrand("");
        return parseFloat(wallet)
        // if (wallet == "0" || parseInt(wallet) < 0) {
        //     return "0"
        // } else if (parseFloat(wallet) <= parseFloat((orderData.price))) {
        //     return parseFloat(wallet)
        // } else {
        //     let value: any = parseFloat(wallet) - parseFloat((orderData.price));
        //     return parseFloat(value)
        // }
    }

    // const getCredit = (ele: any) => {
    //     let wallet = getCreditLimitAsperBrand("")
    //     if (wallet == "0") {
    //         return wallet
    //     } else if (parseInt(wallet) <= parseInt((orderData.price))) {
    //         return wallet
    //     } else {
    //         return parseInt(wallet) - parseInt((orderData.price))
    //     }
    // }

    const classes = useStyles();

    return (
        <Fragment>
            <div className={classes.root}>
                {loading && (<FullscreenLoader />)}
                {creditLimitDetails && creditLimitDetails.loading && <FullscreenLoader />}
                <>
                    <Toolbar
                        className={clsx(classes.root)}>
                        <div className="col-12 mb-2 mt-2">
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title">
                                    <div className="col-12">
                                        Please Select Payment Mode
                                    </div>
                                </h4>
                            </Typography>
                        </div>
                    </Toolbar>
                    <div>
                        <div className="p-0 mt-1 mb-1">
                            <div className="row no-gutters table-bottom p-0">
                                <div className="col-lg-7 col-md-7 col-sm-7 px-5">
                                    <p className="bottom-subheader mt-2">
                                        Brand :
                                    </p>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 d-flex justify-content-left">
                                    <p className="bottom-field-name-table mt-2">
                                        {getNewBrand(orderData.make) === 'Tradepoint' && customerTierType && customerTierType !== "Workshop" ? 'ALAC' : getNewBrand(orderData.make)}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="p-0 mt-1 mb-1">
                            <div className="row no-gutters table-bottom p-0">
                                <div className="col-lg-7 col-md-7 col-sm-7 px-5">
                                    <p className="bottom-subheader mt-2">
                                        Order Amount :
                                    </p>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 d-flex justify-content-left">
                                    <p className="bottom-subheader mt-2">
                                        {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {orderData.price ? orderData.price : "0"}
                                    </p>
                                </div>
                            </div>
                        </div>
                        {customerTierType != "Workshop" && <> <div className="p-0 mt-1 mb-3">
                            <div className="row no-gutters table-bottom p-0">
                                <div className="col-lg-7 col-md-7 col-sm-7 px-5">
                                    <p className="bottom-subheader mt-2">
                                        Available Credit :
                                    </p>
                                </div>
                                <div className="col-lg-5 col-md-5 col-sm-5 d-flex justify-content-left">
                                    <p className="bottom-subheader mt-2">
                                        {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {getCredit("")}
                                    </p>
                                </div>
                            </div>
                        </div></>}
                        {/* <div className="p-0 mt-1 mb-3">
                            <div className="row no-gutters table-bottom p-0">
                                <div className="col-lg-6 col-md-6 col-sm-6 px-5">
                                    <p className="bottom-subheader mt-2">
                                        Additional Credit Utilized:
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                                    <p className="payment-bottom-subheader-content-additional mt-2">
                                        AED {Math.abs(parseInt(getCreditLimitAsperBrand(orderData.make)) - parseInt(orderData.price))}
                                    </p>
                                </div>
                            </div>
                        </div> */}
                        <div className="p-0 mt-1 mb-1">
                            <div className="row no-gutters table-bottom p-0">
                                <div className="col-lg-6 col-md-6 col-sm-6 px-5">
                                    <p className="bottom-subheader mt-2">
                                        Do you still wish to pay now ?
                                    </p>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 p-0">
                            <div className="col-12 border-top w-100 p-0">
                                <div className='payment-dialog-content container justify-content-around' id="demo-radio-buttons-group-label">
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        defaultValue="BANK"
                                        name="radio-buttons-group"
                                        onChange={handleChange} value={selected}
                                    >
                                        <div className="row">
                                            {
                                                paymentMode?.map((ele: string) => {
                                                    if (ele === "BANK") {
                                                        return (
                                                            <div className={`${paymentMode[0] === "BANK" ? "col" : "col"}`}>
                                                                <FormControlLabel className={`${paymentMode[0] === "BANK" ? "col mx-2" : "col"}`}
                                                                    value="BANK" control={<Radio color="primary" />}
                                                                    disabled
                                                                    label="Bank Transfer" />
                                                            </div>
                                                        )
                                                    } else if (ele === "CARD" && customerTierType === "Workshop") {
                                                        return (
                                                            <div className={`${paymentMode[0] === "CARD" ? "col" : "col"}`}>
                                                                <FormControlLabel className={`${paymentMode[0] === "CARD" ? "col mx-2" : "col"}`} disabled={customerTierType !== "Workshop"} value="CARD" control={<Radio color="primary" />} label="Credit Card" />
                                                            </div>
                                                        )
                                                    } else if (ele === "OFFLINE") {
                                                        return (
                                                            <div className={`${paymentMode[0] === "OFFLINE" ? "col px-4" : "col"}`}>
                                                                <FormControlLabel className={`${paymentMode[0] === "OFFLINE" ? "col px-5" : "col"}`} value="OFFLINE" control={<Radio color="primary" />}
                                                                    label={orderData.deliveryType === "Self Pickup" ? "Payment at Counter"
                                                                        : orderData.deliveryType === "Onsite Delivery" ? "Credit card at Delivery"
                                                                            : orderData.deliveryType === "Select" ? "Offline" : "Offline"}
                                                                />
                                                            </div>
                                                        )
                                                    }
                                                })
                                            }
                                            {sessionStorage.getItem('userType') === 'FSE' && customerTierType === "workshop" ?
                                                <div className={"col px-4"}>
                                                    <FormControlLabel className={"col px-5"} value="OFFLINE" control={<Radio color="primary" />}
                                                        label={orderData.deliveryType === "Self Pickup" ? "Payment at Counter"
                                                            : orderData.deliveryType === "Onsite Delivery" ? "Credit card at Delivery"
                                                                : orderData.deliveryType === "Select" ? "Offline" : ""}
                                                    />
                                                </div>
                                                : sessionStorage.getItem('userType') === 'FSE' && customerTierType !== "workshop" ? <div className={"col px-4"}>
                                                    <FormControlLabel className={"col px-5"} value="OFFLINE" control={<Radio color="primary" />}
                                                        label={"Offline"}
                                                    />
                                                </div> : ""}
                                        </div>
                                    </RadioGroup>
                                </div>
                            </div>
                        </div>
                        {
                            paymentMode?.map((ele: string) => {
                                if (ele === "BANK") {
                                    return (
                                        <div className="p-0 mt-1 mb-1">
                                            <div className="row no-gutters table-bottom p-0">
                                                <div className="col-lg-12 col-md-6 col-sm-6 px-5">
                                                    <span className="bottom-subheader-Payment-modes col-12 mt-2 row">Note - <p className="edit-font fontweight-subheader mt-0 px-2">Currently "Bank Transfer" payment mode is unavailable.</p></span>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 d-flex justify-content-center">
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                        {/* <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="BANK"
                            name="radio-buttons-group"
                            onChange={handleCreditChange} value={selectedCredit}
                        >
                            {
                                paymentMode?.map((ele: string) => {
                                    if (ele === "CARD" && selected === "CARD") {
                                        return (
                                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 mt-2 mb-2">
                                                <div className='container justify-content-around'>
                                                    <RadioGroup
                                                        aria-labelledby="demo-radio-buttons-group-label"
                                                        defaultValue="BANK"
                                                        name="radio-buttons-group"
                                                        onChange={handleCreditChange} value={selectedCredit}
                                                    >
                                                        <div className="row pl-4">
                                                            <div className="col-5 pl-4 mr-3 d-flex flex-column pb-0 mb-0">
                                                                <FormControlLabel
                                                                    value="AED"
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <div className=""><div className="pt-3 bottom-subheader">AED {orderData.price}</div>
                                                                            <p className="bottom-header-payment">(Order Amount)</p>
                                                                        </div>}
                                                                    className='payment-radiobutton-subheader pb-0 mb-0'
                                                                />
                                                            </div>
                                                            <div className="col-5 px-4 pl-1 ml-5 padding-credit-payment" style={{ padding: "0px !important" }}>
                                                                <FormControlLabel
                                                                    value="AED1"
                                                                    control={<Radio color="primary" />}
                                                                    label={
                                                                        <div>
                                                                            <div className="pt-3 bottom-subheader">
                                                                                AED {Math.abs(parseInt(getCreditLimitAsperBrand(orderData.make)) - parseInt(orderData.price))}
                                                                            </div>
                                                                            <p className="bottom-header-payment">(Additional Credit Utilized)</p>
                                                                        </div>
                                                                    }
                                                                    className='payment-radiobutton-subheader'
                                                                />
                                                            </div>
                                                            <div className="col">
                                                            </div>
                                                        </div>
                                                    </RadioGroup>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </RadioGroup> */}
                    </div>
                </>
            </div>
            {notSelected && <div className="ml-4 pl-3 mt-2 payment-validation">Please Select Payment Mode</div>}
            <DialogActions className="mt-3 justify-content-center">
                {selected === "OFFLINE" ? <><Button variant="contained"
                    color="secondary"
                    className={`createFavourite ${classes.createButton} ml-4`}
                    onClick={() => {
                        if (selected === "") {
                            setNotSelected(true)
                        } else if (selected !== "") {
                            userMakePaymentAPI();
                            setNotSelected(false);
                        }
                    }}>
                    Submit
                </Button></> : <><Button variant="contained"
                    color="secondary"
                    className={`createFavourite ${classes.createButton}`}
                    onClick={() => {
                        if (selected === "") {
                            setNotSelected(true)
                        } else if (selected !== "") {
                            userMakePaymentAPI();
                            setNotSelected(false);
                        }
                    }}>
                    Pay {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {orderData.price ? orderData.price : "0"}
                </Button></>}
                <Button variant="contained" className={classes.closeButton}
                    onClick={() => {
                        setOpenDialog();
                    }}
                >Cancel</Button>
            </DialogActions>
            {customerTierType === "Workshop" && (
                <DialogActions className="mt-2 justify-content-center bg-aliceblue">
                    <img className="" alt="credit card icon" width="230px"
                        src={creditcardicon} />
                </DialogActions>
            )}
        </Fragment>
    );
}