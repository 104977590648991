import { DELIVERY } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  deliveryList: null,
  deliverDEtails: null,
  viewDelivery: null
};

function deliveryReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case DELIVERY.GET_DELIVERY_LIST_FETCHING:
      return {
        ...state,
        deliveryList: { loading: true, data: null, err: null }
      };
    case DELIVERY.GET_DELIVERY_LIST_SUCCESS:
      return {
        ...state,
        deliveryList: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case DELIVERY.GET_DELIVERY_LIST_ERROR:
      return {
        ...state,
        deliveryList: { loading: false, data: null, err: action.payload.error }
      };
    case DELIVERY.GET_DELIVERY_DETAILS_FETCHING:
      return {
        ...state,
        deliveryDetails: { loading: true, data: null, err: null }
      };
    case DELIVERY.GET_DELIVERY_DETAILS_SUCCESS:
      return {
        ...state,
        deliveryDetails: {
          loading: false,
          data: action.payload.data,
          err: null
        }
      };
    case DELIVERY.GET_DELIVERY_DETAILS_ERROR:
      return {
        ...state,
        deliveryDetails: { loading: false, data: null, err: action.payload.error }
      };
    case DELIVERY.VIEW_DEILVERY_SUCCESS:
      return {
        ...state,
        viewDelivery: { loading: true, data: action.payload.data, err: null }
      }
    case DELIVERY.VIEW_DELIVERY_ERROR:
      return {
        ...state,
        viewDelivery: { loading: false, data: null, err: { error: "unable to fetch data" } }
      };
    case DELIVERY.VIEW_DEILVERY_SELECTED_SUCCESS:
      return {
        ...state,
        viewDeliverySelected: { loading: true, data: action.payload.data, err: null }
      }
    case DELIVERY.VIEW_DELIVERY_SELECTED_ERROR:
      return {
        ...state,
        viewDeliverySelected: { loading: false, data: null, err: { error: "unable to fetch data" } }
      }
    case DELIVERY.DOWNLOAD_DELIVERY_FETCHING:
      return {
        ...state,
        downloadInvoice: { loading: true, data: null, err: null }
      }
    case DELIVERY.DOWLOAD_DELIVERY_SUCCES:
      return {
        ...state,
        downloadInvoice: { loading: true, data: action.payload.data, err: null }
      }
    case DELIVERY.DOWNLOAD_DELIVERY_ERROR:
      return {
        ...state,
        dowloadInvoice: { loading: true, data: null, err: { error: "unable to fetch data" } }
      }
    default:
      return state;
  }
};

export default deliveryReducer;