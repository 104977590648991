// API Actions
export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const ORDER_ENQUIRY = {
  GET_ORDER_ENQYUIRY_SUCCESS: "GET_ORDER_ENQYUIRY_SUCCESS",
  GET_ORDER_ENQYUIRY_ERROR: "GET_ORDER_ENQYUIRY_ERROR",
  GET_ORDER_ENQYUIRY_FETCHING: "GET_ORDER_ENQYUIRY_FETCHING",

  GET_FSE_LIST_FETCHING: "GET_FSE_LIST_FETCHING",
  GET_FSE_LIST_SUCCESS: "GET_FSE_LIST_SUCCESS",
  GET_FSE_LIST_ERROR: "GET_FSE_LIST_ERROR",

  GET_ENQUIRY_STATUS_FETCHING: "GET_ENQUIRY_STATUS_FETCHING",
  GET_ENQUIRY_STATUS_SUCCESS: "GET_ENQUIRY_STATUS_SUCCESS",
  GET_ENQUIRY_STATUS_ERROR: "GET_ENQUIRY_STATUS_ERROR",

  IS_ORDER_ENQUIRY_DETAILS: "IS_ORDER_ENQUIRY_DETAILS",
};

export const MATERIAL_GROUPS = {
  GET_MATERIAL_GROUPS_SUCCESS: "GET_MATERIAL_GROUPS_SUCCESS",
  GET_MATERIAL_GROUPS_ERROR: "GET_MATERIAL_GROUPS_ERROR",
  GET_MATERIAL_GROUPS_FETCHING: "GET_MATERIAL_GROUPS_FETCHING",
};

export const DELIVERY = {
  GET_DELIVERY_LIST_SUCCESS: "GET_DELIVERY_LIST_SUCCESS",
  GET_DELIVERY_LIST_ERROR: "GET_DELIVERY_LIST_ERROR",
  GET_DELIVERY_LIST_FETCHING: "GET_DELIVERY_LIST_FETCHING",

  GET_DELIVERY_DETAILS_SUCCESS: "GET_DELIVERY_DETAILS_SUCCESS",
  GET_DELIVERY_DETAILS_ERROR: "GET_DELIVERY_DETAILS_ERROR",
  GET_DELIVERY_DETAILS_FETCHING: "GET_DELIVERY_DETAILS_FETCHING",

  VIEW_DEILVERY_SUCCESS: "VIEW_DELIVERY_SUCCESS",
  VIEW_DELIVERY_ERROR: "VIEW_DELIVERY_ERROR",

  VIEW_DEILVERY_SELECTED_SUCCESS: "VIEW_DELIVERY_SELECTED_SUCCESS",
  VIEW_DELIVERY_SELECTED_ERROR: "VIEW_DELIVERY_SELECTED_ERROR",

  DOWLOAD_DELIVERY_SUCCES: "DOWNLOAD_DELIVERY_SUCCESS",
  DOWNLOAD_DELIVERY_FETCHING: "DOWNLOAD_DELIVERY_FETCHING",
  DOWNLOAD_DELIVERY_ERROR: "DOWNLOAD_DELIVERY_ERROR"

};

export const ORDER_HISTORY = {
  GET_ORDER_HISTORY_LIST_SUCCESS: "GET_ORDER_HISTORY_LIST_SUCCESS",
  GET_ORDER_HISTORY_LIST_ERROR: "GET_ORDER_HISTORY_LIST_ERROR",
  GET_ORDER_HISTORY_LIST_FETCHING: "GET_ORDER_HISTORY_LIST_FETCHING",

  GET_PARTS_HISTORY_LIST_SUCCESS: "GET_PARTS_HISTORY_LIST_SUCCESS",
  GET_PARTS_HISTORY_LIST_ERROR: "GET_PARTS_HISTORY_LIST_ERROR",
  GET_PARTS_HISTORY_LIST_FETCHING: "GET_PARTS_HISTORY_LIST_FETCHING",

  GET_ORDER_HISTORY_DETAILS_SUCCESS: "GET_ORDER_HISTORY_DETAILS_SUCCESS",
  GET_ORDER_HISTORY_DETAILS_ERROR: "GET_ORDER_HISTORY_DETAILS_ERROR",
  GET_ORDER_HISTORY_DETAILS_FETCHING: "GET_ORDER_HISTORY_DETAILS_FETCHING",

  GET_PAYMENT_TRANSACTION_DETAILS_SUCCESS: "GET_PAYMENT_TRANSACTION_DETAILS_SUCCESS",
  GET_PAYMENT_TRANSACTION_DETAILS_ERROR: "GET_PAYMENT_TRANSACTION_DETAILS_ERROR",
  GET_PAYMENT_TRANSACTION_DETAILS_FETCHING: "GET_PAYMENT_TRANSACTION_DETAILS_FETCHING",

  CREATE_RETURN_REQUEST_SUCCESS: "CREATE_RETURN_REQUEST_SUCCESS",
  CREATE_RETURN_REQUEST_ERROR: "CREATE_RETURN_REQUEST_ERROR",
  CREATE_RETURN_REQUEST_FETCHING: "CREATE_RETURN_REQUEST_FETCHING",

  GET_ORDER_STATUS_FETCHING: "GET_ORDER_STATUS_FETCHING",
  GET_ORDER_STATUS_SUCCESS: "GET_ORDER_STATUS_SUCCESS",
  GET_ORDER_STATUS_ERROR: "GET_ORDER_STATUS_ERROR",

  GET_INVOICE_SUCCESS: "GET_INVOICE_SUCCESS",
  GET_INVOICE_ERROR: "GET_INVOICE_ERROR",
  GET_INVOICE_FETCHING: "GET_INVOICE_FETCHING",

  GET_ORDER_ID: "GET_ORDER_ID",
  RESET_INVOICE: "RESET_INVOICE",
  SET_PAGE_NUMBER: "SET_PAGE_NUMBER",

  VIEWING_FROM_ORDER_HISTORY: "VIEWing_FROM_ORDER_HISTORY"
};

export const FAVORITES = {
  GET_FAVORITES_LIST_SUCCESS: "GET_FAVORITES_LIST_SUCCESS",
  GET_FAVORITES_LIST_ERROR: "GET_FAVORITES_LIST_ERROR",
  GET_FAVORITES_LIST_FETCHING: "GET_FAVORITES_LIST_FETCHING",

  GET_FAVORITES_DETAILS_SUCCESS: "GET_FAVORITES_DETAILS_SUCCESS",
  GET_FAVORITES_DETAILS_ERROR: "GET_FAVORITES_DETAILS_ERROR",
  GET_FAVORITES_DETAILS_FETCHING: "GET_FAVORITES_DETAILS_FETCHING",

  CREATE_FAVORITES_FETCHING: "CREATE_FAVORITES_FETCHING",
  CREATE_FAVORITES_SUCCESS: "CREATE_FAVORITES_SUCCESS",
  CREATE_FAVORITES_ERROR: "CREATE_FAVORITES_ERROR",

  UPDATE_FAVORITES_FETCHING: "UPDATE_FAVORITES_FETCHING",
  UPDATE_FAVORITES_SUCCESS: "UPDATE_FAVORITES_SUCCESS",
  UPDATE_FAVORITES_ERROR: "UPDATE_FAVORITES_ERROR",

  DELETE_FAVORITES_FETCHING: "DELETE_FAVORITES_FETCHING",
  DELETE_FAVORITES_SUCCESS: "DELETE_FAVORITES_SUCCESS",
  DELETE_FAVORITES_ERROR: "DELETE_FAVORITES_ERROR",
};

export const MYSCHEDULE = {
  GET_MYSCHEDULE_LIST_SUCCESS: "GET_MYSCHEDULE_LIST_SUCCESS",
  GET_MYSCHEDULE_LIST_ERROR: "GET_MYSCHEDULE_LIST_ERROR",
  GET_MYSCHEDULE_LIST_FETCHING: "GET_MYSCHEDULE_LIST_FETCHING",

  GET_MYSCHEDULE_DETAILS_SUCCESS: "GET_MYSCHEDULE_DETAILS_SUCCESS",
  GET_MYSCHEDULE_DETAILS_ERROR: "GET_MYSCHEDULE_DETAILS_ERROR",
  GET_MYSCHEDULE_DETAILS_FETCHING: "GET_MYSCHEDULE_DETAILS_FETCHING",

  CREATE_MYSCHEDULE_FETCHING: "CREATE_MYSCHEDULE_FETCHING",
  CREATE_MYSCHEDULE_SUCCESS: "CREATE_MYSCHEDULE_SUCCESS",
  CREATE_MYSCHEDULE_ERROR: "CREATE_MYSCHEDULE_ERROR",

  UPDATE_MYSCHEDULE_FETCHING: "UPDATE_MYSCHEDULE_FETCHING",
  UPDATE_MYSCHEDULE_SUCCESS: "UPDATE_MYSCHEDULE_SUCCESS",
  UPDATE_MYSCHEDULE_ERROR: "UPDATE_MYSCHEDULE_ERROR",

  DELETE_MYSCHEDULE_FETCHING: "DELETE_MYSCHEDULE_FETCHING",
  DELETE_MYSCHEDULE_SUCCESS: "DELETE_MYSCHEDULE_SUCCESS",
  DELETE_MYSCHEDULE_ERROR: "DELETE_MYSCHEDULE_ERROR",
};

export const ANNOUNCEMENTS = {
  GET_ANNOUNCEMENTS_SUCCESS: "GET_ANNOUNCEMENTS_SUCCESS",
  GET_ANNOUNCEMENTS_ERROR: "GET_ANNOUNCEMENTS_ERROR",
  GET_ANNOUNCEMENTS_FETCHING: "GET_ANNOUNCEMENTS_FETCHING",

  GET_ANNOUNCEMENT_DETAIL_SUCCESS: "GET_ANNOUNCEMENT_DETAIL_SUCCESS",
  GET_ANNOUNCEMENT_DETAIL_ERROR: "GET_ANNOUNCEMENT_DETAIL_ERROR",
  GET_ANNOUNCEMENT_DETAIL_FETCHING: "GET_ANNOUNCEMENT_DETAIL_FETCHING",

  SEND_ANNOUNCEMENT_TO_CUSTOMER_SUCCESS: "SEND_ANNOUNCEMENT_TO_CUSTOMER_SUCCESS",
  SEND_ANNOUNCEMENT_TO_CUSTOMER_ERROR: "SEND_ANNOUNCEMENT_TO_CUSTOMER_ERROR",
  SEND_ANNOUNCEMENT_TO_CUSTOMER_FETCHING: "SEND_ANNOUNCEMENT_TO_CUSTOMER_FETCHING",

};

export const TASKS = {
  GET_TASKS_SUCCESS: "GET_TASKS_SUCCESS",
  GET_TASKS_ERROR: "GET_TASKS_ERROR",
  GET_TASKS_FETCHING: "GET_TASKS_FETCHING",

  GET_DOCUMENT_REPORT_SUCCESS: "GET_DOCUMENT_REPORT_SUCCESS",
  GET_DOCUMENT_REPORT_ERROR: "GET_DOCUMENT_REPORT_ERROR",
  GET_DOCUMENT_REPORT_FETCHING: "GET_DOCUMENT_REPORT_FETCHING",
  
  POST_REVIEW_TASKS_SUCCESS: "POST_REVIEW_TASKS_SUCCESS",
  POST_REVIEW_TASKS_ERROR: "POST_REVIEW_TASKS_ERROR",
  POST_REVIEW_TASKS_FETCHING: "POST_REVIEW_TASKS_FETCHING",

  POST_TASKS_SUCCESS: "POST_TASKS_SUCCESS",
  POST_TASKS_ERROR: "POST_TASKS_ERROR",
  POST_TASKS_FETCHING: "POST_TASKS_FETCHING",

  TASK_APPROVE_REUPLOAD_SUCCESS: "TASK_APPROVE_REUPLOAD_SUCCESS",
  TASK_APPROVE_REUPLOAD_ERROR: "TASK_APPROVE_REUPLOAD_ERROR",
  TASK_APPROVE_REUPLOAD_FETCHING: "TASK_APPROVE_REUPLOAD_FETCHING",

  CLOSE_TASKS_SUCCESS: "CLOSE_TASKS_SUCCESS",
  CLOSE_TASKS_ERROR: "CLOSE_TASKS_ERROR",
  CLOSE_TASKS_FETCHING: "CLOSE_TASKS_FETCHING",

  SET_DOCUMENT_REPORT: "SET_DOCUMENT_REPORT"
};

export const SPECIAL_OFFERS = {
  GET_SPECIAL_OFFERS_SUCCESS: "GET_SPECIAL_OFFERS_SUCCESS",
  GET_SPECIAL_OFFERS_ERROR: "GET_SPECIAL_OFFERS_ERROR",
  GET_SPECIAL_OFFERS_FETCHING: "GET_SPECIAL_OFFERS_FETCHING",

  GET_SPECIAL_OFFER_DETAIL_SUCCESS: "GET_SPECIAL_OFFER_DETAIL_SUCCESS",
  GET_SPECIAL_OFFER_DETAIL_ERROR: "GET_SPECIAL_OFFER_DETAIL_ERROR",
  GET_SPECIAL_OFFER_DETAIL_FETCHING: "GET_SPECIAL_OFFER_DETAIL_FETCHING",

};

export const TERMS_AND_CONDITIONS = {
  GET_TERMS_AND_CONDITIONS_SUCCESS: "GET_TERMS_AND_CONDITIONS_SUCCESS",
  GET_TERMS_AND_CONDITIONS_ERROR: "GET_TERMS_AND_CONDITIONS_ERROR",
  GET_TERMS_AND_CONDITIONS_FETCHING: "GET_TERMS_AND_CONDITIONS_FETCHING",
};

export const NOTIFICATIONS = {
  GET_ALL_NOTIFICATIONS_SUCCESS: "GET_ALL_NOTIFICATIONS_SUCCESS",
  GET_ALL_NOTIFICATIONS_ERROR: "GET_ALL_NOTIFICATIONS_ERROR",
  GET_ALL_NOTIFICATIONS_FETCHING: "GET_ALL_NOTIFICATIONS_FETCHING",

  MARK_NOTIFICATIONS_READ_SUCCESS: "MARK_NOTIFICATIONS_READ_SUCCESS",
  MARK_NOTIFICATIONS_READ_ERROR: "MARK_NOTIFICATIONS_READ_ERROR",
  MARK_NOTIFICATIONS_READ: "MARK_NOTIFICATIONS_READ",

  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  ADD_NOTIFICATION_ERROR: "ADD_NOTIFICATION_ERROR",
  ADD_NOTIFICATION_SUCCESS: "ADD_NOTIFICATION_SUCCESS",

  NOTIFY_USER_MAIL: "NOTIFY_USER_MAIL",
  NOTIFY_USER_MAIL_ERROR: "NOTIFY_USER_MAIL_ERROR",
  NOTIFY_USER_MAIL_SUCCESS: "NOTIFY_USER_MAIL_SUCCESS",
};

export const ASSIGNED_KAM = {
  GET_ASSIGNED_KAM_SUCCESS: "GET_ASSIGNED_KAM_SUCCESS",
  GET_ASSIGNED_KAM_ERROR: "GET_ASSIGNED_KAM_ERROR",
  GET_ASSIGNED_KAM_FETCHING: "GET_ASSIGNED_KAM_FETCHING"
};

export const LOGON_USER = {
  GET_LOGON_USER_SUCCESS: "GET_LOGON_USER_SUCCESS",
  GET_LOGON_USER_ERROR: "GET_LOGON_USER_ERROR",
  GET_LOGON_USER_FETCHING: "GET_LOGON_USER_FETCHING",

  GET_JWT_TOKEN_FETCHING: "GET_JWT_TOKEN_FETCHING",
  GET_JWT_TOKEN_SUCCESS: "GET_JWT_TOKEN_SUCCESS",
  GET_JWT_TOKEN_ERROR: "GET_JWT_TOKEN_ERROR",

  VALIDATE_REFRESH_TOKEN_FETCHING: "VALIDATE_REFRESH_TOKEN_FETCHING",
  VALIDATE_REFRESH_TOKEN_SUCCESS: "VALIDATE_REFRESH_TOKEN_SUCCESS",
  VALIDATE_REFRESH_TOKEN_ERROR: "VALIDATE_REFRESH_TOKEN_SUCCESS",

  GET_KAM_TOKEN_FETCHING: "GET_JWT_TOKEN_FETCHING",
  GET_KAM_TOKEN_SUCCESS: "GET_JWT_TOKEN_SUCCESS",
  GET_KAM_TOKEN_ERROR: "GET_JWT_TOKEN_ERROR",

  LOGOUT_USER_SUCCESS: "LOGOUT_USER_SUCCESS",
  LOGOUT_USER_ERROR: "LOGOUT_USER_ERROR",
  LOGOUT_USER_FETCHING: "LOGOUT_USER_FETCHING",
};

export const CREATE_UPDATE = {
  POST_CREATE_UPDATE_SUCCESS: "POST_CREATE_UPDATE_SUCCESS",
  POST_CREATE_UPDATE_ERROR: "POST_CREATE_UPDATE_ERROR",
  POST_CREATE_UPDATE_FETCHING: "POST_CREATE_UPDATE_FETCHING",
  GET_UNIQUE_ENQUIRY_ID: "GET_UNIQUE_ENQUIRY_ID",
  SET_ENQUIRY_ID: "SET_ENQUIRY_ID",

  SEND_EMAIL: "SEND_EMAIL",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_ERROR: "SEND_EMAIL_ERROR",

  REFRESH_DETAILS_API_FETCHING: "REFRESH_DETAILS_API_FETCHING",
  REFRESH_DETAILS_API_SUCCESS: "REFRESH_DETAILS_API_SUCCESS",
  REFRESH_DETAILS_API_ERROR: "REFRESH_DETAILS_API_ERROR",

  SEND_EMAIL_PROCUREMENTPART_FETCHING: "SEND_EMAIL_PROCUREMENTPART_FETCHING",
  SEND_EMAIL_PROCUREMENTPART_SUCCESS: "SEND_EMAIL_PROCUREMENTPART_SUCCESS",
  SEND_EMAIL_PROCUREMENTPART_ERROR: "SEND_EMAIL_PROCUREMENTPART_ERROR",


  DELETE_LOST_SALE_REPORT_FETCHING: 'DELETE_LOST_SALE_REPORT_FETCHING',
  DELETE_LOST_SALE_REPORT_SUCCESS: 'DELETE_LOST_SALE_REPORT_SUCCESS',
  DELETE_LOST_SALE_REPORT_ERROR: 'DELETE_LOST_SALE_REPORT_ERROR',
};

export const CREATE_UPDATE_QUEUE_CHECK = {
  CREATE_UPDATE_QUEUE_CHECK_SUCCESS: "CREATE_UPDATE_QUEUE_CHECK_SUCCESS",
  CREATE_UPDATE_QUEUE_CHECK_ERROR: "CREATE_UPDATE_QUEUE_CHECK_ERROR",
  CREATE_UPDATE_QUEUE_CHECK_FETCHING: "CREATE_UPDATE_QUEUE_CHECK_FETCHING"
};

export const FETCH_DL_EMAIL_BY_REGION = {
  FETCH_DL_EMAIL_BY_REGION_SUCCESS: "FETCH_DL_EMAIL_BY_REGION_SUCCESS",
  FETCH_DL_EMAIL_BY_REGION_ERROR: "FETCH_DL_EMAIL_BY_REGION_ERROR",
  FETCH_DL_EMAIL_BY_REGION_FETCHING: "FETCH_DL_EMAIL_BY_REGION_FETCHING",
}

export const PRODUCTS_SEARCH = {
  GET_PRODUCTS_SEARCH_SUCCESS: "GET_PRODUCTS_SEARCH_SUCCESS",
  GET_PRODUCTS_SEARCH_ERROR: "GET_PRODUCTS_SEARCH_ERROR",
  GET_PRODUCTS_SEARCH_FETCHING: "GET_LOGON_USER_FETCHING",

  REMOVE_PRODUCTS: 'REMOVE_PRODUCTS'
};

export const DEALER = {
  POST_DEALER_SUCCESS: "POST_DEALER_SUCCESS",
  POST_DEALER_ERROR: "POST_DEALER_ERROR",
  POST_DEALER_FETCHING: "POST_DEALER_FETCHING",

  POST_DEALER_USER_SUCCESS: "POST_DEALER_USER_SUCCESS",
  POST_DEALER_USER_ERROR: "POST_DEALER_USER_ERROR",
  POST_DEALER_USER_FETCHING: "POST_DEALER_USER_FETCHING",

  GET_ALL_DEALERS_SUCCESS: "GET_ALL_DEALER_SUCCESS",
  GET_ALL_DEALERS_ERROR: "GET_ALL_DEALER_ERROR",
  GET_ALL_DEALERS_FETCHING: "GET_ALL_DEALER_FETCHING",

  GET_ALL_CURRENCY_DATA_SUCCESS: "GET_ALL_CURRENCY_DATA_SUCCESS",
  GET_ALL_CURRENCY_DATA_ERROR: "GET_ALL_CURRENCY_DATA_ERROR",
  GET_ALL_CURRENCY_DATA_FETCHING: "GET_ALL_CURRENCY_DATA_FETCHING",

  GET_ON_REFRESH_SAP_DATA_SUCCESS: "GET_ON_REFRESH_SAP_DATA_SUCCESS",
  GET_ON_REFRESH_SAP_DATA_ERROR: "GET_ON_REFRESH_SAP_DATA_ERROR",
  GET_ON_REFRESH_SAP_DATA_FETCHING: "GET_ON_REFRESH_SAP_DATA_FETCHING",

  GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_SUCCESS: "GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_SUCCESS",
  GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_ERROR: "GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_ERROR",
  GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_FETCHING: "GET_CUSTOMER_DETAILS_REFRESH_SAP_DATA_FETCHING",

  GET_FSE_CUSTOMERS_SUCCESS: "GET_ALL_DEALER_SUCCESS",
  GET_FSE_CUSTOMERS_ERROR: "GET_ALL_DEALER_ERROR",
  GET_FSE_CUSTOMERS_FETCHING: "GET_ALL_DEALER_FETCHING",

  GET_FSE_CUSTOMERS_LIST_SUCCESS: "GET_FSE_CUSTOMERS_LIST_SUCCESS",
  GET_FSE_CUSTOMERS_LIST_ERROR: "GET_FSE_CUSTOMERS_LIST_ERROR",
  GET_FSE_CUSTOMERS_LIST_FETCHING: "GET_FSE_CUSTOMERS_LIST_FETCHING",

  GET_FSE_ENQUIRY_STATUS_SUCCESS: "GET_FSE_ENQUIRY_STATUS_SUCCESS",
  GET_FSE_ENQUIRY_STATUS_ERROR: "GET_FSE_ENQUIRY_STATUS_ERROR",
  GET_FSE_ENQUIRY_STATUS_FETCHING: "GET_FSE_ENQUIRY_STATUS_FETCHING",

  GET_FSE_CUSTOMER_DETAILS_BY_ID_SUCCESS: "GET_FSE_CUSTOMER_DETAILS_BY_ID_SUCCESS",
  GET_FSE_CUSTOMER_DETAILS_BY_ID_ERROR: "GET_FSE_CUSTOMER_DETAILS_BY_ID_ERROR",
  GET_FSE_CUSTOMER_DETAILS_BY_ID_FETCHING: "GET_FSE_CUSTOMER_DETAILS_BY_ID_FETCHING",

  RESET_VALUES_FOR_ChOOSE_CUSTOMER: "RESET_VALUES_FOR_ChOOSE_CUSTOMER",

  STORE_VALUES_FOR_ChOOSE_CUSTOMER: "STORE_VALUES_FOR_ChOOSE_CUSTOMER",
  
  SET_VALUES_FOR_ChOOSE_CUSTOMER_SUCCESS: "SET_VALUES_FOR_ChOOSE_CUSTOMER_SUCCESS",
  SET_VALUES_FOR_ChOOSE_CUSTOMER_ERROR: "SET_VALUES_FOR_ChOOSE_CUSTOMER_ERROR",
  SET_VALUES_FOR_ChOOSE_CUSTOMER_FETCHING: "SET_VALUES_FOR_ChOOSE_CUSTOMER_FETCHING",

  GET_CUSTOMER_FILTER_STATUS_SUCCESS: "GET_CUSTOMER_FILTER_STATUS_SUCCESS",
  GET_CUSTOMER_FILTER_STATUS_FETCHING: "GET_CUSTOMER_FILTER_STATUS_FETCHING",
  GET_CUSTOMER_FILTER_STATUS_ERROR: "GET_CUSTOMER_FILTER_STATUS_ERROR",

  GET_ALL_DEALER_REQUESTS_SUCCESS: "GET_ALL_DEALER_REQUEST_SUCCESS",
  GET_ALL_DEALER_REQUESTS_ERROR: "GET_ALL_DEALER_REQUEST_ERROR",
  GET_ALL_DEALER_REQUESTS_FETCHING: "GET_ALL_DEALER_REQUEST_FETCHING",

  GET_USER_LIST_FETCHING: 'GET_USER_LIST_FETCHING',
  GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
  GET_USER_LIST_ERROR: 'GET_USER_LIST_ERROR',

  GET_DEALER_DETAILS_SUCCESS: "GET_DEALER_DETAILS_SUCCESS",
  GET_DEALER_DETAILS_ERROR: "GET_DEALER_DETAILS_ERROR",
  GET_DEALER_DETAILS_FETCHING: "GET_DEALER_DETAILS_FETCHING",

  POST_DEALER_SET_DEFAULT_ADDRESS_SUCCESS: "POST_DEALER_SET_DEFAULT_ADDRESS_SUCCESS",
  POST_DEALER_SET_DEFAULT_ADDRESS_ERROR: "POST_DEALER_SET_DEFAULT_ADDRESS_ERROR",
  POST_DEALER_SET_DEFAULT_ADDRESS_FETCHING: "POST_DEALER_SET_DEFAULT_ADDRESS_FETCHING",

  CHECK_USER_AVAILABILITY_SUCCESS: "CHECK_USER_AVAILABILITY_SUCCESS",
  CHECK_USER_AVAILABILITY_ERROR: "CHECK_USER_AVAILABILITY_ERROR",
  CHECK_USER_AVAILABILITY_FETCHING: "CHECK_USER_AVAILABILITY_FETCHING",

  PUT_DEALER_SUCCESS: "PUT_DEALER_SUCCESS",
  PUT_DEALER_ERROR: "PUT_DEALER_ERROR",
  PUT_DEALER_FETCHING: "PUT_DEALER_FETCHING",

  PUT_DEALER_USER_SUCCESS: "PUT_DEALER_USER_SUCCESS",
  PUT_DEALER_USER_ERROR: "PUT_DEALER_USER_ERROR",
  PUT_DEALER_USER_FETCHING: "PUT_DEALER_USER_FETCHING",

  PUT_DEALER_USER_IN_PROGRESS_SUCCESS: "PUT_DEALER_USER_IN_PROGRESS_SUCCESS",
  PUT_DEALER_USER_IN_PROGRESS_ERROR: "PUT_DEALER_USER_IN_PROGRESS_ERROR",
  PUT_DEALER_USER_IN_PROGRESS_FETCHING: "PUT_DEALER_USER_IN_PROGRESS_FETCHING",

  VERIFY_SAPCUSTOMERID_SUCCESS: "VERIFY_SAPCUSTOMERID_SUCCESS",
  VERIFY_SAPCUSTOMERID_ERROR: "VERIFY_SAPCUSTOMERID_ERROR",
  VERIFY_SAPCUSTOMERID_FETCHING: "VERIFY_SAPCUSTOMERID_FETCHING",

  GET_USER_PARAMETER_FETCHING: "GET_USER_PARAMETER_FETCHING",
  GET_USER_PARAMETER_SUCCESS: "GET_USER_PARAMETER_SUCCESS",
  GET_USER_PARAMETER_ERROR: "GET_USER_PARAMETER_ERROR",

  ADD_USER_PARAMETER_FETCHING: "ADD_USER_PARAMETER_FETCHING",
  ADD_USER_PARAMETER_SUCCESS: "ADD_USER_PARAMETER_SUCCESS",
  ADD_USER_PARAMETER_ERROR: "ADD_USER_PARAMETER_ERROR",

  SEND_EMAIL_FETCHING: "SEND_EMAIL_FETCHING",
  SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
  SEND_EMAIL_ERROR: "SEND_EMAIL_ERROR",

  EDIT_USER_PARAMETER_FETCHING: "EDIT_USER_PARAMETER_FETCHING",
  EDIT_USER_PARAMETER_SUCCESS: "EDIT_USER_PARAMETER_SUCCESS",
  EDIT_USER_PARAMETER_ERROR: "EDIT_USER_PARAMETER_ERROR",

  STORE_BRAND_VALUES: "STORE_BRAND_VALUES",
  SET_USER_DETAILS: "SET_USER_DETAILS",

  GET_ACCESS_PARAM_SUCCESS: "GET_ACCESS_PARAM_SUCCESS",
  GET_ACCESS_PARAM_ERROR: "GET_ACCESS_PARAM_ERROR",
  GET_ACCESS_PARAM_FETCHING: "GET_ACCESS_PARAM_FETCHING",

  PUT_ACCESS_PARAM_ERROR: "PUT_ACCESS_PARAM_ERROR",
  PUT_ACCESS_PARAM_SUCCESS: "PUT_ACCESS_PARAM_SUCCESS",
  PUT_ACCESS_PARAM_FETCHING: "PUT_ACCESS_PARAM_FETCHING",

  POST_ACCESS_PARAM_SUCCESS: "POST_ACCESS_PARAM_SUCCESS",
  POST_ACCESS_PARAM_ERROR: "POST_ACCESS_PARAM_ERROR",
  POST_ACCESS_PARAM_FETCHING: "POST_ACCESS_PARAM_FETCHING",

  POST_UPLOAD_DOCUMENT_SUCCESS: "POST_UPLOAD_DOCUMENT_SUCCESS",
  POST_UPLOAD_DOCUMENT_ERROR: "POST_UPLOAD_DOCUMENT_ERROR",
  POST_UPLOAD_DOCUMENT_FETCHING: "POST_UPLOAD_DOCUMENT_FETCHING",

  Get_UPLOADED_DOCUMENT_SUCCESS: "Get_UPLOADED_DOCUMENT_SUCCESS",
  Get_UPLOADED_DOCUMENT_ERROR: "Get_UPLOADED_DOCUMENT_ERROR",
  Get_UPLOADED_DOCUMENT_FETCHING: "Get_UPLOADED_DOCUMENT_FETCHING",

  GET_USER_DETAILS_FETCHING: "GET_USER_DETAILS_FETCHING",
  GET_USER_DETAILS_ERROR: "GET_USER_DETAILS_ERROR",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",

  GET_KAMUSER_SUCCESS: "GET_KAMUSER_SUCCESS",
  GET_KAMUSER_ERROR: "GET_KAMUSER_ERROR",
  GET_KAMUSER_FETCHING: "GET_KAMUSER_FETCHING",

  GET_FSEKAMUSER_SUCCESS: "GET_FSEKAMUSER_SUCCESS",
  GET_FSEKAMUSER_ERROR: "GET_FSEKAMUSER_ERROR",
  GET_FSEKAMUSER_FETCHING: "GET_FSEKAMUSER_FETCHING",

  GET_KAMUSERFORKAM_SUCCESS: "GET_KAMUSERFORKAM_SUCCESS",
  GET_KAMUSERFORKAM_ERROR: "GET_KAMUSERFORKAM_ERROR",
  GET_KAMUSERFORKAM_FETCHING: "GET_KAMUSERFORKAM_FETCHING",

  GET_DDL_SUCCESS: "GET_DDL_SUCCESS",
  GET_DDL_ERROR: "GET_DDL_ERROR",
  GET_DDL_FETCHING: "GET_DDL_FETCHING",

  GET_DROPDOWN_SUCCESS: "GET_DROPDOWN_SUCCESS",
  GET_DROPDOWN_ERROR: "GET_DROPDOWN_ERROR",
  GET_DROPDOWN_FETCHING: "GET_DROPDOWN_FETCHING",

  GET_FSE_SALES_REGION_SUCCESS: "GET_FSE_SALES_REGION_SUCCESS",
  GET_FSE_SALES_REGION_ERROR: "GET_FSE_SALES_REGION_ERROR",
  GET_FSE_SALES_REGION_FETCHING: "GET_FSE_SALES_REGION_FETCHING",

  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_ERROR: "DELETE_DOCUMENT_ERROR",
  DELETE_DOCUMENT_FETCHING: "DELETE_DOCUMENT_FETCHING",

  RESEND_INVITATION_LINK_FETCHING: "RESEND_INVITATION_LINK_FETCHING",
  RESEND_INVITATION_LINK_SUCCESS: "RESEND_INVITATION_LINK_SUCCESS",
  RESEND_INVITATION_LINK_ERROR: "RESEND_INVITATION_LINK_ERROR",

  GET_SAP_CUSTOMER_IDS_FETCHING: "GET_SAP_CUSTOMER_IDS_FETCHING",
  GET_SAP_CUSTOMER_IDS_SUCCESS: "GET_SAP_CUSTOMER_IDS_SUCCESS",
  GET_SAP_CUSTOMER_IDS_ERROR: "GET_SAP_CUSTOMER_IDS_ERROR",

  GET_LOST_SALE_REASON_FETCHING: "GET_LOST_SALE_REASON_FETCHING",
  GET_LOST_SALE_REASON_SUCCESS: "GET_LOST_SALE_REASON_SUCCESS",
  GET_LOST_SALE_REASON_ERROR: "GET_LOST_SALE_REASON_ERROR",

  GET_ALL_BRANDS_FETCHING: "GET_ALL_BRANDS_FETCHING",
  GET_ALL_BRANDS_SUCCESS: "GET_ALL_BRANDS_SUCCESS",
  GET_ALL_BRANDS_ERROR: "GET_ALL_BRANDS_ERROR",

  GET_ALL_CR_FETCHING: "GET_ALL_CR_FETCHING",
  GET_ALL_CR_SUCCESS: "GET_ALL_CR_SUCCESS",
  GET_ALL_CR_ERROR: "GET_ALL_CR_ERROR",

  GET_LOST_SALE_REPORT_FETCHING: "GET_LOST_SALE_REPORT_FETCHING",
  GET_LOST_SALE_REPORT_SUCCESS: "GET_LOST_SALE_REPORT_SUCCESS",
  GET_LOST_SALE_REPORT_ERROR: "GET_LOST_SALE_REPORT_ERROR",

  RESET_REPORT_DATA: "RESET_REPORT_DATA",
  SET_NEW_DEALER_DATA: "SET_NEW_DEALER_DATA",
  SET_TAB_VALUE: "SET_TAB_VALUE",
  IS_EDIT: "IS_EDIT",

  GET_DEALER_CREDIT_REQUEST_FETCHING: "GET_DEALER_CREDIT_REQUEST_FETCHING",
  GET_DEALER_CREDIT_REQUEST_SUCCESS: "GET_DEALER_CREDIT_REQUEST_SUCCESS",
  GET_DEALER_CREDIT_REQUEST_ERROR: "GET_DEALER_CREDIT_REQUEST_ERROR",

  SAVE_ADDITIONAL_CREDIT_REQUEST_FETCHING: "SAVE_ADDITIONAL_CREDIT_REQUEST_FETCHING",
  SAVE_ADDITIONAL_CREDIT_REQUEST_SUCCESS: "SAVE_ADDITIONAL_CREDIT_REQUEST_SUCCESS",
  SAVE_ADDITIONAL_CREDIT_REQUEST_ERROR: "SAVE_ADDITIONAL_CREDIT_REQUEST_ERROR",

  DELETE_BANK_STATEMENT_CREDIT_REQUEST_FETCHING: "DELETE_BANK_STATEMENT_CREDIT_REQUEST_FETCHING",
  DELETE_BANK_STATEMENT_CREDIT_REQUEST_SUCCESS: "DELETE_BANK_STATEMENT_CREDIT_REQUEST_SUCCESS",
  DELETE_BANK_STATEMENT_CREDIT_REQUEST_ERROR: "DELETE_BANK_STATEMENT_CREDIT_REQUEST_ERROR",

  SEND_INVITATION_LINK_FETCHING: "SEND_INVITATION_LINK_FETCHING",
  SEND_INVITATION_LINK_SUCCESS: "SEND_INVITATION_LINK_SUCCESS",
  SEND_INVITATION_LINK_ERROR: "SEND_INVITATION_LINK_ERROR",

  GENERATE_B2B_USER_ID_FETCHING: "GENERATE_B2B_USER_ID_FETCHING",
  GENERATE_B2B_USER_ID_SUCCESS: "GENERATE_B2B_USER_ID_SUCCESS",
  GENERATE_B2B_USER_ID_ERROR: "GENERATE_B2B_USER_ID_ERROR",

  CONFIRM_DATA_FROM_DEALER_FETCHING: "CONFIRM_DATA_FROM_DEALER_FETCHING",
  CONFIRM_DATA_FROM_DEALER_SUCCESS: "CONFIRM_DATA_FROM_DEALER_SUCCESS",
  CONFIRM_DATA_FROM_DEALER_ERROR: "CONFIRM_DATA_FROM_DEALER_ERROR",

  GET_VIEWCREDIT_DEALER_FETCHING: "GET_VIEWCREDIT_DEALER_FETCHING",
  GET_VIEWCREDIT_DEALER_SUCCESS: "GET_VIEWCREDIT_DEALER_SUCCESS",
  GET_VIEWCREDIT_DEALER_ERROR: "GET_VIEWCREDIT_DEALER_ERROR",

  GET_SHIP_TO_PARTY_FETCHING: "GET_SHIP_TO_PARTY_FETCHING",
  GET_SHIP_TO_PARTY_SUCCESS: "GET_SHIP_TO_PARTY_SUCCESS",
  GET_SHIP_TO_PARTY_ERROR: "GET_SHIP_TO_PARTY_ERROR"


};

export const PROFILE = {
  GET_PROFILE_SUCCESS: "GET_PROFILE_SUCCESS",
  GET_PROFILE_ERROR: "GET_PROFILE_ERROR",
  GET_PROFILE_FETCHING: "GET_PROFILE_FETCHING",

  GET_PROFILE_PLACED_PROFILE_SUCCESS: "GET_PROFILE_PLACED_PROFILE_SUCCESS",
  GET_PROFILE_PLACED_PROFILE_ERROR: "GET_PROFILE_PLACED_PROFILE_ERROR",
  GET_PROFILE_PLACED_PROFILE_FETCHING: "GET_PROFILE_PROFILE_FETCHING",

  PUT_PROFILE_SUCCESS: "PUT_PROFILE_SUCCESS",
  PUT_PROFILE_ERROR: "PUT_PROFILE_ERROR",
  PUT_PROFILE_FETCHING: "PUT_PROFILE_FETCHING",

  POST_SUPPORT_SUCCESS: "POST_SUPPORT_SUCCESS",
  POST_SUPPORT_ERROR: "POST_SUPPORT_ERROR",
  POST_SUPPORT_FETCHING: "POST_SUPPORT_FETCHING",

  GET_DOCUMENT_EXPIRY_SUCCESS: "GET_DOCUMENT_EXPIRY_SUCCESS",
  GET_DOCUMENT_EXPIRY_ERROR: "GET_DOCUMENT_EXPIRY_ERROR",
  GET_DOCUMENT_EXPIRY_FETCHING: "GET_DOCUMENT_EXPIRY_FETCHING",

  IS_SELLING_PRICE_TOGGLE_BUTTON: "IS_SELLING_PRICE_TOGGLE_BUTTON",
}

export const CREDITLIMIT = {
  GET_CREDITLIMIT_SUCCESS: "GET_CREDITLIMIT_SUCCESS",
  GET_CREDITLIMIT_ERROR: "GET_CREDITLIMIT_ERROR",
  GET_CREDITLIMIT_FETCHING: "GET_CREDITLIMIT_FETCHING"
}

export const REQUEST_SPECIAL_PRICE = {
  GET_REQUEST_SPECIAL_PRICE_SUCCESS: "GET_REQUEST_SPECIAL_PRICE_SUCCESS",
  GET_REQUEST_SPECIAL_PRICE_ERROR: "GET_REQUEST_SPECIAL_PRICE_ERROR",
  GET_REQUEST_SPECIAL_PRICE_FETCHING: "GET_REQUEST_SPECIAL_PRICE_FETCHING",

  REQUEST_SPECIAL_PRICE_ACCESS_SUCCESS: "REQUEST_SPECIAL_PRICE_ACCESS_SUCCESS",
  REQUEST_SPECIAL_PRICE_ACCESS_ERROR: "REQUEST_SPECIAL_PRICE_ACCESS_ERROR",
  REQUEST_SPECIAL_PRICE_ACCESS_FETCHING: "REQUEST_SPECIAL_PRICE_ACCESS_FETCHING",

  VIEW_SPECIAL_PRICE_SUCCESS: "VIEW_SPECIAL_PRICE_SUCCESS",
  VIEW_SPECIAL_PRICE_ERROR: "VIEW_SPECIAL_PRICE_ERROR",
  VIEW_SPECIAL_PRICE_FETCHING: "VIEW_SPECIAL_PRICE_FETCHING",

}

export const SUPER_ADMIN = {
  UPDATE_DEFAULT_TARGET_SUCCESS: "UPDATE_DEFAULT_TARGET_SUCCESS",
  UPDATE_DEFAULT_TARGET_ERROR: "UPDATE_DEFAULT_TARGET_ERROR",
  UPDATE_DEFAULT_TARGET_FETCHING: "UPDATE_DEFAULT_TARGET_FETCHING",

  UPDATE_CUSTOMER_TARGET_DATA_SUCCESS: "UPDATE_CUSTOMER_TARGET_DATA_SUCCESS",
  UPDATE_CUSTOMER_TARGET_DATA_ERROR: "UPDATE_CUSTOMER_TARGET_DATA_ERROR",
  UPDATE_CUSTOMER_TARGET_DATA_FETCHING: "UPDATE_CUSTOMER_TARGET_DATA_FETCHING",

  GET_DEFAULT_TARGET_DATA_SUCCESS: "GET_DEFAULT_TARGET_DATA_SUCCESS",
  GET_DEFAULT_TARGET_DATA_ERROR: "GET_DEFAULT_TARGET_DATA_ERROR",
  GET_DEFAULT_TARGET_DATA_FETCHING: "GET_DEFAULT_TARGET_DATA_FETCHING",

  GET_DEALER_LIST_TARGET_DATA_SUCCESS: "GET_DEALER_LIST_TARGET_DATA_SUCCESS",
  GET_DEALER_LIST_TARGET_DATA_ERROR: "GET_DEALER_LIST_TARGET_DATA_ERROR",
  GET_DEALER_LIST_TARGET_DATA_FETCHING: "GET_DEALER_LIST_TARGET_DATA_FETCHING",

  GET_SUPER_ADMIN_B2B_FETCHING: "GET_B22_FETCHING",
  GET_SUPER_ADMIN_B2B_SUCCESS: "GET_B2B_SUCCESS",
  GET_SUPER_ADMI_B2B_ERROR: "GET_B2B_ERROR",

  GET_TIER_FETCHING: "GET_TIER_FETCHING",
  GET_TIER_SUCCESS: "GET_TIER_SUCCESS",
  GET_TIER_ERROR: "GET_TIER_ERROR",

  UPDATE_SUPER_ADMIN_EDIT_DEALER_SUCCESS: "UPDATE_SUPER_ADMIN_EDIT_DEALER_SUCCESS",
  UPDATE_SUPER_ADMIN_EDIT_DEALER_ERROR: "UPDATE_SUPER_ADMIN_EDIT_DEALER_ERROR",
  UPDATE_SUPER_ADMIN_EDIT_DEALER_FETCHING: "UPDATE_SUPER_ADMIN_EDIT_DEALER_FETCHING",

}

export const CREDITREQUEST = {
  GET_ALL_CREDIT_REQUEST_SUCCESS: "GET_ALL_CREDIT_REQUEST_SUCCESS",
  GET_ALL_CREDIT_REQUEST_ERROR: "GET_ALL_CREDIT_REQUEST_ERROR",
  GET_ALL_CREDIT_REQUEST_FETCHING: "GET_ALL_CREDIT_REQUEST_FETCHING",

  GET_CREDIT_REQUEST_STATUS_SUCCESS: "GET_CREDIT_REQUEST_STATUS_SUCCESS",
  GET_CREDIT_REQUEST_STATUS_ERROR: "GET_CREDIT_REQUEST_STATUS_ERROR",
  GET_CREDIT_REQUEST_STATUS_FETCHING: "GET_CREDIT_REQUEST_STATUS_FETCHING",

  GET_CREDIT_REQUEST_DETAILS_SUCCESS: "GET_CREDIT_REQUEST_DETAILS_SUCCESS",
  GET_CREDIT_REQUEST_DETAILS_ERROR: "GET_CREDIT_REQUEST_DETAILS_ERROR",
  GET_CREDIT_REQUEST_DETAILS_FETCHING: "GET_CREDIT_REQUEST_DETAILS_FETCHING",

  APPROVE_CREDIT_REQUEST_DETAILS_SUCCESS: "APPROVE_CREDIT_REQUEST_DETAILS_SUCCESS",
  APPROVE_CREDIT_REQUEST_DETAILS_ERROR: "APPROVE_CREDIT_REQUEST_DETAILS_ERROR",
  APPROVE_CREDIT_REQUEST_DETAILS_FETCHING: "APPROVE_CREDIT_REQUEST_DETAILS_FETCHING",

  REJECT_CREDIT_REQUEST_DETAILS_SUCCESS: "REJECT_CREDIT_REQUEST_DETAILS_SUCCESS",
  REJECT_CREDIT_REQUEST_DETAILS_ERROR: "REJECT_CREDIT_REQUEST_DETAILS_ERROR",
  REJECT_CREDIT_REQUEST_DETAILS_FETCHING: "REJECT_CREDIT_RQUEST_DETAILS_FETCHING",
}

export const ONBOARD = {

  GET_DEALER_DETAILS_SUCCESS: "GET_DEALER_DETAILS_SUCCESS",
  GET_DEALER_DETAILS_ERROR: "GET_DEALER_DETAILS_ERROR",
  GET_DEALER_DETAILS_FETCHING: "GET_DEALER_DETAILS_FETCHING",

  GET_IB_CUST_COUNTRY_SALES_REGION_SUCCESS: "GET_IB_CUST_COUNTRY_SALES_REGION_SUCCESS",
  GET_IB_CUST_COUNTRY_SALES_REGION_ERROR: "GET_IB_CUST_COUNTRY_SALES_REGION_ERROR",
  GET_IB_CUST_COUNTRY_SALES_REGION_FETCHING: "GET_IB_CUST_COUNTRY_SALES_REGION_FETCHING",

  GET_REGION_SUCCESS: "GET_REGION_SUCCESS",
  GET_REGION_ERROR: "GET_REGION_ERROR",
  GET_REGION_FETCHING: "GET_REGION_FETCHING",

  POST_DELIVERY_TYPE_SUCCESS: "POST_DELIVERY_TYPE_SUCCESS",
  POST_DELIVERY_TYPE_ERROR: "POST_DELIVERY_TYPE_ERROR",
  POST_DELIVERY_TYPE_FETCHING: "POST_DELIVERY_TYPE_FETCHING",

  GET_INDUSTRY_TYPE_SUCCESS: "GET_INDUSTRY_TYPE_SUCCESS",
  GET_INDUSTRY_TYPE_ERROR: "GET_INDUSTRY_TYPE_ERROR",
  GET_INDUSTRY_TYPE_FETCHING: "GET_INDUSTRY_TYPE_FETCHING",

  GET_NATIONALITY_SUCCESS: "GET_NATIONALITY_SUCCESS",
  GET_NATIONALITY_ERROR: "GET_NATIONALITY_ERROR",
  GET_NATIONALITY_FETCHING: "GET_NATIONALITY_FETCHING",

  GET_EXPIRED_DOC_COUNT_SUCCESS: "GET_EXPIRED_DOC_COUNT_SUCCESS",
  GET_EXPIRED_DOC_COUNT_ERROR: "GET_EXPIRED_DOC_COUNT_ERROR",
  GET_EXPIRED_DOC_COUNT_FETCHING: "GET_EXPIRED_DOC_COUNT_FETCHING",

  GET_TRADE_REGION_SUCCESS: "GET_TRADE_REGION_SUCCESS",
  GET_TRADE_REGION_ERROR: "GET_TRADE_REGION_ERROR",
  GET_TRADE_REGION_FETCHING: "GET_TRADE_REGION_FETCHING",

  GET_DEALINGIN_SUCCESS: "GET_DEALINGIN_SUCCESS",
  GET_DEALINGIN_ERROR: "GET_DEALINGIN_ERROR",
  GET_DEALINGIN_FETCHING: "GET_DEALINGIN_FETCHING",

  GET_TYPEOFTRADE_SUCCESS: "GET_TYPEOFTRADE_SUCCESS",
  GET_TYPEOFTRADE_ERROR: "GET_TYPEOFTRADE_ERROR",
  GET_TYPEOFTRADE_FETCHING: "GET_TYPEOFTRADE_FETCHING",

  SAVE_DEALER_DETAILS_SUCCESS: "SAVE_DEALER_DETAILS_SUCCESS",
  SAVE_DEALER_DETAILS_ERROR: "SAVE_DEALER_DETAILS_ERROR",
  SAVE_DEALER_DETAILS_FETCHING: "SAVE_DEALER_DETAILS_FETCHING",

  GET_DETAILS_BY_SAP_ID_FETCHING: "GET_DETAILS_BY_SAP_ID_FETCHING",
  GET_DETAILS_BY_SAP_ID_SUCCESS: "GET_DETAILS_BY_SAP_ID_SUCCESS",
  GET_DETAILS_BY_SAP_ID_ERROR: "GET_DETAILS_BY_SAP_ID_ERROR",

  GET_PARTNER_DETAILS_FETCHING: "GET_PARTNER_DETAILS_FETCHING",
  GET_PARTNER_DETAILS_SUCCESS: "GET_PARTNER_DETAILS_SUCCESS",
  GET_PARTNER_DETAILS_ERROR: "GET_PARTNER_DETAILS_ERROR",

  SAVE_PARTNER_DETAILS_FETCHING: "SAVE_PARTNER_DETAILS_FETCHING",
  SAVE_PARTNER_DETAILS_SUCCESS: "SAVE_PARTNER_DETAILS_SUCCESS",
  SAVE_PARTNER_DETAILS_ERROR: "SAVE_PARTNER_DETAILS_ERROR",

  UPDATE_PARTNER_DETAILS_FETCHING: "UPDATE_PARTNER_DETAILS_FETCHING",
  UPDATE_PARTNER_DETAILS_SUCCESS: "UPDATE_PARTNER_DETAILS_SUCCESS",
  UPDATE_PARTNER_DETAILS_ERROR: "UPDATE_PARTNER_DETAILS_ERROR",

  DELETE_PARTNER_DETAILS_FETCHING: "DELETE_PARTNER_DETAILS_FETCHING",
  DELETE_PARTNER_DETAILS_SUCCESS: "DELETE_PARTNER_DETAILS_SUCCESS",
  DELETE_PARTNER_DETAILS_ERROR: "DELETE_PARTNER_DETAILS_ERROR",

  // ACTION TYPES FOR ADD NEW AUTHORISED SIGNATORIES
  GET_DETAILS_AUTHORISED_SIGNATORIES_FETCHING: "GET_DETAILS_AUTHORISED_SIGNATORIES_FETCHING",
  GET_DETAILS_AUTHORISED_SIGNATORIES_SUCCESS: "GET_DETAILS_AUTHORISED_SIGNATORIES_SUCCESS",
  GET_DETAILS_AUTHORISED_SIGNATORIES_ERROR: "GET_DETAILS_AUTHORISED_SIGNATORIES_ERROR",

  SAVE_AUTHORISED_SIGNATORIES_FETCHING: "SAVE_AUTHORISED_SIGNATORIES_FETCHING",
  SAVE_AUTHORISED_SIGNATORIES_SUCCESS: "SAVE_AUTHORISED_SIGNATORIES_SUCCESS",
  SAVE_AUTHORISED_SIGNATORIES_ERROR: "SAVE_AUTHORISED_SIGNATORIES_ERROR",

  UPDATED_AUTHORISED_SIGNATORIES_FETCHING: "UPDATED_AUTHORISED_SIGNATORIES_FETCHING",
  UPDATED_AUTHORISED_SIGNATORIES_SUCCESS: "UPDATED_AUTHORISED_SIGNATORIES_SUCCESS",
  UPDATED_AUTHORISED_SIGNATORIES_ERROR: "UPDATED_AUTHORISED_SIGNATORIES_ERROR",

  DELETE_AUTHORISED_SIGNATORIES_FETCHING: "DELETE_AUTHORISED_SIGNATORIES_FETCHING",
  DELETE_AUTHORISED_SIGNATORIES_SUCCESS: "DELETE_AUTHORISED_SIGNATORIES_SUCCESS",
  DELETE_AUTHORISED_SIGNATORIES_ERROR: "DELETE_AUTHORISED_SIGNATORIES_ERROR",

  // ACTIONS TYPES FOR DOCUMENT TAB
  SAVE_DOCUMENT_FETCHING: "SAVE_DOCUMENT_FETCHING",
  SAVE_DOCUMENT_SUCCESS: "SAVE_DOCUMENT_SUCCESS",
  SAVE_DOCUMENT_ERROR: "SAVE_DOCUMENT_ERROR",

  SUBMIT_ADDRESS_FETCHING: "SUBMIT_ADDRESS_FETCHING",
  SUBMIT_ADDRESS_SUCCESS: "SUBMIT_ADDRESS_SUCCESS",
  SUBMIT_ADDRESS_ERROR: "SUBMIT_ADDRESS_ERROR",

  UPDATE_DELIVERY_TYPE_FETCHING: "UPDATE_DELIVERY_TYPE_FETCHING",
  UPDATE_DELIVERY_TYPE_SUCCESS: "UPDATE_DELIVERY_TYPE_SUCCESS",
  UPDATE_DELIVERY_TYPE_ERROR: "UPDATE_DELIVERY_TYPE_ERROR",

  UPLOAD_DOCUMENT_FETCHING: "UPLOAD_DOCUMENT_FETCHING",
  UPLOAD_DOCUMENT_SUCCESS: "UPLOAD_DOCUMENT_SUCCESS",
  UPLOAD_DOCUMENT_ERROR: "UPLOAD_DOCUMENT_ERROR",

  DELETE_DOCUMENT_FETCHING: "DELETE_DOCUMENT_FETCHING",
  DELETE_DOCUMENT_SUCCESS: "DELETE_DOCUMENT_SUCCESS",
  DELETE_DOCUMENT_ERROR: "DELETE_DOCUMENT_ERROR",

  //ACTION TYPES FOR GENRATE OTP 
  GET_GENARTE_OTP_DETAILS_FETCHING: "GET_GENARTE_OTP_DETAILS_FETCHING",
  GET_GENARTE_OTP_DETAILS_SUCCESS: "GET_GENARTE_OTP_DETAILS_SUCCESS",
  GET_GENARTE_OTP_DETAILS_ERROR: "GET_GENARTE_OTP_DETAILS_ERROR",

  GET_CUSTOMER_TYPE_FETCHING: "GET_CUSTOMER_TYPE_FETCHING",
  GET_CUSTOMER_TYPE_SUCCESS: "GET_CUSTOMER_TYPE_SUCCESS",
  GET_CUSTOMER_TYPE_ERROR: "GET_CUSTOMER_TYPE_ERROR",

  // ACTION TYPES VERIFY OTP
  GET_VERIFY_OTP_DETAILS_FETCHING: "GET_VERIFY_OTP_DETAILS_FETCHING",
  GET_VERIFY_OTP_DETAILS_SUCCESS: "GET_VERIFY_OTP_DETAILS_SUCCESS",
  GET_VERIFY_OTP_DETAILS_ERROR: "GET_VERIFY_OTP_DETAILS_ERROR",

  GET_ALL_ADDRESS_FETCHING: "GET_ALL_ADDRESS_FETCHING",
  GET_ALL_ADDRESS_SUCCESS: "GET_ALL_ADDRESS_SUCCESS",
  GET_ALL_ADDRESS_ERROR: "GET_ALL_ADDRESS_ERROR",

  GET_DELIVERY_TYPE_FETCHING: "GET_DELIVERY_TYPE_FETCHING",
  GET_DELIVERY_TYPE_SUCCESS: "GET_DELIVERY_TYPE_SUCCESS",
  GET_DELIVERY_TYPE_ERROR: "GET_DELIVERY_TYPE_ERROR",

  GET_DELIVERY_TYPE_CUSTOMER_FETCHING: "GET_DELIVERY_TYPE_CUSTOMER_FETCHING",
  GET_DELIVERY_TYPE_CUSTOMER_SUCCESS: "GET_DELIVERY_TYPE_CUSTOMER_SUCCESS",
  GET_DELIVERY_TYPE_CUSTOMER_ERROR: "GET_DELIVERY_TYPE_CUSTOMER_ERROR",

  SAVE_ADDRESS_FETCHING: "SAVE_ADDRESS_FETCHING",
  SAVE_ADDRESS_SUCCESS: "SAVE_ADDRESS_SUCCESS",
  SAVE_ADDRESS_ERROR: "SAVE_ADDRESS_ERROR",

  DELETE_ADDRESS_DOCUMENT_FETCHING: "DELETE_ADDRESS_DOCUMENT_FETCHING",
  DELETE_ADDRESS_DOCUMENT_SUCCESS: "DELETE_ADDRESS_DOCUMENT_SUCCESS",
  DELETE_ADDRESS_DOCUMENT_ERROR: "DELETE_ADDRESS_DOCUMENT_ERROR",

  DELETE_SIGNATORY_DOCUMENT_FETCHING: "DELETE_SIGNATORY_DOCUMENT_FETCHING",
  DELETE_SIGNATORY_DOCUMENT_SUCCESS: "DELETE_SIGNATORY_DOCUMENT_SUCCESS",
  DELETE_SIGNATORY_DOCUMENT_ERROR: "DELETE_SIGNATORY_DOCUMENT_ERROR",

  GET_SELECTED_ADDRESS_FETCHING: "GET_SELECTED_ADDRESS_FETCHING",
  GET_SELECTED_ADDRESS_SUCCESS: "GET_SELECTED_ADDRESS_SUCCESS",
  GET_SELECTED_ADDRESS_ERROR: "GET_SELECTED_ADDRESS_ERROR",

  GET_ADDRESS_BY_CUSTOMER_ID_FETCHING: "GET_ADDRESS_BY_CUSTOMER_ID_FETCHING",
  GET_ADDRESS_BY_CUSTOMER_ID_SUCCESS: "GET_ADDRESS_BY_CUSTOMER_ID_SUCCESS",
  GET_ADDRESS_BY_CUSTOMER_ID_ERROR: "GET_ADDRESS_BY_CUSTOMER_ID_ERROR",

  UPDATE_ADDRESS_FETCHING: "UPDATE_ADDRESS_FETCHING",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_ERROR: "UPDATE_ADDRESS_ERROR",

  DELETE_ADDRESS_FETCHING: "DELETE_ADDRESS_FETCHING",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_ERROR: "DELETE_ADDRESS_ERROR",

  // ACTION TYPES FOR GET DOCUMENT BY ID
  GET_DOCUMENT_BY_ID_DETAILS_FETCHING: "GET_DOCUMENT_BY_ID_DETAILS_FETCHING",
  GET_DOCUMENT_BY_ID_DETAILS_SUCCESS: "GET_DOCUMENT_BY_ID_DETAILS_SUCCESS",
  GET_DOCUMENT_BY_ID_DETAILS_ERROR: "GET_DOCUMENT_BY_ID_DETAILS_ERROR",

  // FOR CUSTOMER TIER
  SET_CUSTOMER_TIER: "SET_CUSTOMER_TIER",

  // FOR CUSTOMER TYPE
  SET_CUSTOMER_TYPE: "SET_CUSTOMER_TYPE",
  SET_STATUS: "SET_STATUS",

  // ACTION TYPES FOR CHANGE CUSTOMER TYPE
  CHANGE_CUSTOMER_TYPE_DETAILS_FETCHING: "CHANGE_CUSTOMER_TYPE_DETAILS_FETCHING",
  CHANGE_CUSTOMER_TYPE_DETAILS_SUCCESS: "CHANGE_CUSTOMER_TYPE_DETAILS_SUCCESS",
  CHANGE_CUSTOMER_TYPE_DETAILS_ERROR: "CHANGE_CUSTOMER_TYPE_DETAILS_ERROR",

  // ACTION TYPES FOR CHANGE ACCEPTING DELIVERY TYPE
  CHANGE_ACCEPTING_DELIVERY_COUNTRY_DETAILS_FETCHING: "CHANGE_ACCEPTING_DELIVERY_COUNTRY_DETAILS_FETCHING",
  CHANGE_ACCEPTING_DELIVERY_COUNTRY_SUCCESS: "CHANGE_ACCEPTING_DELIVERY_COUNTRY_SUCCESS",
  CHANGE_ACCEPTING_DELIVERY_COUNTRY_ERROR: "CHANGE_ACCEPTING_DELIVERY_COUNTRY_ERROR",

  // ACTION TYPES FOR DELETE ADDRESS DOCUMENT
  DELETE_DOCUMENT_ADDRESS_FETCHING: "DELETE_DOCUMENT_ADDRESS_FETCHING",
  DELETE_DOCUMENT_ADDRESS_SUCCESS: "DELETE_DOCUMENT_ADDRESS_SUCCESS",
  DELETE_DOCUMENT_ADDRESS_ERROR: "DELETE_DOCUMENT_ADDRESS_ERROR",

  RESET_VALUES: "RESET_VALUES",

  // ACTION TYPES FOR GET ALL PARTNERS DETAILS
  GET_ALL_PARTNERS_DETAILS_FETCHING: "GET_ALL_PARTNERS_DETAILS_FETCHING",
  GET_ALL_PARTNERS_DETAILS_SUCCESS: "GET_ALL_PARTNERS_DETAILS_SUCCESS",
  GET_ALL_PARTNERS_DETAILS_ERROR: "GET_ALL_PARTNERS_DETAILS_ERROR",

  // ACTION TYPES FOR GET ALL SIGNATORIES DETAILS
  GET_ALL_SIGNATORIES_DETAILS_FETCHING: "GET_ALL_SIGNATORIES_DETAILS_FETCHING",
  GET_ALL_SIGNATORIES_DETAILS_SUCCESS: "GET_ALL_SIGNATORIES_DETAILS_SUCCESS",
  GET_ALL_SIGNATORIES_DETAILS_ERROR: "GET_ALL_SIGNATORIES_DETAILS_ERROR",

  // ACTION TYPES FOR GET REQUEST CORRECTION DETAILS
  GET_REQUEST_CORRECTION_DETAILS_FETCHING: "GET_REQUEST_CORRECTION_DETAILS_FETCHING",
  GET_REQUEST_CORRECTION_DETAILS_SUCCESS: "GET_REQUEST_CORRECTION_DETAILS_SUCCESS",
  GET_REQUEST_CORRECTION_DETAILS_ERROR: "GET_REQUEST_CORRECTION_DETAILS_ERROR",

  // ACTION TYPES FOR SAVE REQUEST CORRECTION DETAILS
  SAVE_REQUEST_CORRECTION_DETAILS_FETCHING: "SAVE_REQUEST_CORRECTION_DETAILS_FETCHING",
  SAVE_REQUEST_CORRECTION_DETAILS_SUCCESS: "SAVE_REQUEST_CORRECTION_DETAILS_SUCCESS",
  SAVE_REQUEST_CORRECTION_DETAILS_ERROR: "SAVE_REQUEST_CORRECTION_DETAILS_ERROR",

  // ACTION TYPES FOR HISTORY LIST CORRECTION DETAILS
  HISTORY_LIST_REQUEST_CORRECTION_DETAILS_FETCHING: "HISTORY_LIST_REQUEST_CORRECTION_DETAILS_FETCHING",
  HISTORY_LIST_REQUEST_CORRECTION_DETAILS_SUCCESS: "HISTORY_LIST_REQUEST_CORRECTION_DETAILS_SUCCESS",
  HISTORY_LIST_REQUEST_CORRECTION_DETAILS_ERROR: "HISTORY_LIST_REQUEST_CORRECTION_DETAILS_ERROR",

  // ACTION TYPES FOR GET TRANSPORTATION ZONE
  GET_TRANSPORTATION_ZONE_DETAILS_FETCHING: "GET_TRANSPORTATION_ZONE_DETAILS_FETCHING",
  GET_TRANSPORTATION_ZONE_DETAILS_SUCCESS: "GET_TRANSPORTATION_ZONE_DETAILS_SUCCESS",
  GET_TRANSPORTATION_ZONE_DETAILS_ERROR: "GET_TRANSPORTATION_ZONE_DETAILS_ERROR",

  // ACTION TYPES FOR SAVE ZONE
  SAVE_ZONE_DETAILS_FETCHING: "SAVE_ZONE_DETAILS_FETCHING",
  SAVE_ZONE_DETAILS_SUCCESS: "SAVE_ZONE_DETAILS_SUCCESS",
  SAVE_ZONE_DETAILS_ERROR: "SAVE_ZONE_DETAILS_ERROR",

  SEND_TO_SAP_FETCHING: "SEND_TO_SAP_FETCHING",
  SEND_TO_SAP_SUCCESS: "SEND_TO_SAP_SUCCESS",
  SEND_TO_SAP_ERROR: "SEND_TO_SAP_ERROR",

  // ACTION TYPES FOR GET PARAMETERS DETAILS
  GET_PARAMETERS_DETAILS_FETCHING: "GET_PARAMETERS_DETAILS_FETCHING",
  GET_PARAMETERS_DETAILS_SUCCESS: "GET_PARAMETERS_DETAILS_SUCCESS",
  GET_PARAMETERS_DETAILS_ERROR: "GET_PARAMETERS_DETAILS_ERROR",

  // ACTION TYPES FOR GET PARAMETER VALUES
  GET_PARAMETER_VALUES_FETCHING: "GET_PARAMETER_VALUES_FETCHING",
  GET_PARAMETER_VALUES_SUCCESS: "GET_PARAMETER_VALUES_SUCCESS",
  GET_PARAMETER_VALUES_ERROR: "GET_PARAMETER_VALUES_ERROR",

  // ACTION TYPES FOR SAVE PARAMETER VALUES
  SAVE_PARAMETER_VALUES_FETCHING: "SAVE_PARAMETER_VALUES_FETCHING",
  SAVE_PARAMETER_VALUES_SUCCESS: "SAVE_PARAMETER_VALUES_SUCCESS",
  SAVE_PARAMETER_VALUES_ERROR: "SAVE_PARAMETER_VALUES_ERROR",

  // ACTION TYPES FOR UPDATE PARAMETER VALUES
  UPDATE_PARAMETER_VALUES_FETCHING: "UPDATE_PARAMETER_VALUES_FETCHING",
  UPDATE_PARAMETER_VALUES_SUCCESS: "UPDATE_PARAMETER_VALUES_SUCCESS",
  UPDATE_PARAMETER_VALUES_ERROR: "UPDATE_PARAMETER_VALUES_ERROR",

  // ACTION TYPES FOR GET SAP DETAILS
  GET_SAP_DETAILS_FETCHING: "GET_SAP_DETAILS_FETCHING",
  GET_SAP_DETAILS_SUCCESS: "GET_SAP_DETAILS_SUCCESS",
  GET_SAP_DETAILS_ERROR: "GET_SAP_DETAILS_ERROR",

  // ACTION TYPES FOR SAVE SAP DETAILS
  SAVE_SAP_DETAILS_FETCHING: "SAVE_SAP_DETAILS_FETCHING",
  SAVE_SAP_DETAILS_SUCCESS: "SAVE_SAP_DETAILS_SUCCESS",
  SAVE_SAP_DETAILS_ERROR: "SAVE_SAP_DETAILS_ERROR",

  // ACTION TYPES FOR UPDATE SAP DETAILS
  UPDATE_SAP_DETAILS_FETCHING: "UPDATE_SAP_DETAILS_FETCHING",
  UPDATE_SAP_DETAILS_SUCCESS: "UPDATE_SAP_DETAILS_SUCCESS",
  UPDATE_SAP_DETAILS_ERROR: "UPDATE_SAP_DETAILS_ERROR",

  // ACTION TYPES FOR GET KAMREVIEW DETAILS
  GET_KAM_REVIEW_DETAILS: "GET_KAM_REVIEW_DETAILS",
  GET_KAM_REVIEW_ERROR: "GET_KAM_REVIEW_ERROR",
  GET_KAM_REVIEW_SUCCESS: "GET_KAM_REVIEW_ERROR",

  //Action TYPES FOR Paymentmode
  GET_PAYMENT_MODE_SUCCESS: "GET_PAYMENT_MODE_SUCCESS",
  GET_PAYMENT_MODE_ERROR: "GET_PAYMENT_MODE_ERROR",
  GET_PAYMENT_MODE_FETCHING: "GET_PAYMENT_MODE_FETCHING",

  //Edit delaer superadmin
  UPDATE_EDIT_SUPERDEALER: "UPDATE_EDIT_DEALER",

  ACTIVATE_ADDRESS_FETCHING: "ACTIVATE_ADDRESS_FETCHING",
  ACTIVATE_ADDRESS_SUCCESS: "ACTIVATE_ADDRESS_SUCCESS",
  ACTIVATE_ADDRESS_ERROR: "ACTIVATE_ADDRESS_ERROR",

  DEACTIVATE_ADDRESS_FETCHING: "DEACTIVATE_ADDRESS_FETCHING",
  DEACTIVATE_ADDRESS_SUCCESS: "DEACTIVATE_ADDRESS_SUCCESS",
  DEACTIVATE_ADDRESS_ERROR: "DEACTIVATE_ADDRESS_ERROR",

  RESET_ONCANCEL_EXISTING: "RESET_ONCANCEL_EXISTING"
};

export const HOME = {
  GET_FEATURES: "GET_FEATURES",
  GET_BRANDS: "GET_BRANDS",
  GET_HOME_ERROR: "GET_HOME_ERROR"
}

export const ACHIEVEMENT = {
  GET_PURCHASE_SUCCESS: "GET_PURCHASE_SUCCESS",
  GET_PURCHASE_ERROR: "GET_PURCHASE_ERROR",
  GET_PURCHASE_FETCHING: "GET_PURCHASE_FETCHING",

  //GET_PURCHASE_BY_YEAR
  GET_PURCHASE_BY_YEAR_SUCCESS: "GET_PURCHASE_BY_YEAR_SUCCESS",
  GET_PURCHASE_BY_YEAR_ERROR: "GET_PURCHASE_BY_YEAR_ERROR",
  GET_PURCHASE_BY_YEAR_FETCHING: "GET_PURCHASE_BY_YEAR_FETCHING",

  GET_PURCHASE_SUCCESS_kAM: "GET_PURCHASE_SUCCESS_KAM",
  GET_PURCHASE_ERROR_KAM: "GET_PURCHASE_ERROR_KAM",
  GET_PURCHASE_FETCHING_KAM: "GET_PURCHASE_FETCHING_KAM",


  GET_PURCHASE_SUCCESS_SUPERADMIN: "GET_PURCHASE_SUCCESS_SUPERADMIN",
  GET_PURCHASE_ERROR_SUPERADMIN: "GET_PURCHASE_ERROR_SUPERADMIN",
  GET_PURCHASE_FETCHING_SUPERADMIN: "GET_PURCHASE_FETCHING_SUPERADMIN",

  IS_ACCESS_ACHIEVEMENT_SUCCESS: "IS_ACCESS_ACHIEVEMENT_SUCCESS",
  IS_ACCESS_ACHIEVEMENT_ERROR: "IS_ACCESS_ACHIEVEMENT_ERROR",
  IS_ACCESS_ACHIEVEMENT_FETCHING: "IS_ACCESS_ACHIEVEMENT_FETCHING"
}

export const ORDER_SUMMARY = {
  GET_ORDER_SUMMARY_SUCCES: "GET_ORDER_SUMMARY_SUCCESS",
  GET_ORDER_SUMMARY_FETCHING: "GET_ORDER_SUMMARY_FETCHING",
  GET_ORDER_SUMMARY_ERROR: "GET_ORDER_ERROR",

  GET_OFFLINEMODE_UPDATE_SUCCESS: "GET_OFFLINEMODE_UPDATE_SUCCESS",
  GET_OFFLINEMODE_UPDATE_FETCHING: "GET_OFFLINEMODE_UPDATE_FETCHING",
  GET_OFFLINEMODE_UPDATE_ERROR: "GET_OFFLINEMODE_UPDATE_ERROR",

  GET_ORDER_SUMMARY_DETAILS_SUCCESS: "GET_ORDER_SUMMARY_DETAILS_SUCCESS",
  GET_ORDER_SUMMARY_DETAILS_FETCHING: "GET_ORDER_SUMMARY_DETAILS_FETCHING",
  GET_ORDER_SUMMARY_DETAILS_ERROR: "GET_ORDER_SUMMARY_DETAILS_ERROR"
}

export const SELECTED_PARTS = {
  GET_SELECTED_PARTS_SUCCESS: "GET_SELECTED_PARTS_SUCCESS",
  GET_SELECTED_PARTS_FETCHING: "GET_SELECTED_PARTS_FETCHING",
  GET_SELECTED_PARTS_ERROR: "GET_SELECTED_PARTS_ERRROR"
}

export const VIEW_SELECTED_PARTS = {
  VIEW_SELECTED_SUCCESS: "VIEW_SELECTED_SUCCESS",
  VIEW_SELECTED_FETCHING: "VIEW_SELECTED_FETCHING",
  VIEW_SELECTED_ERROR: "VIEW_SELECTED_ERROR",
  MODIFY_SPR_DATA_SUCCESS: "MODIFY_SPR_DATA_SUCCESS",
}

export const SPECIAL_PRICE = {
  GET_SPECIALPRICE_LIST_SUCCESS: "GET_SPECIAL_PRICE_SUCCESS",
  GET_SPECIALPRICE_LIST_FETCHING: "GET_SPECIAL_PRICE_FETCHING",
  GET_SPECIALPRICE_LIST_ERROR: "GET_SPECIAL_PRICE_ERROR",

  POST_BACK_BUTTON_RESTRICTION_SUCCESS: "POST_BACK_BUTTON_RESTRICTION_SUCCESS",
  POST_BACK_BUTTON_RESTRICTION_FETCHING: "POST_BACK_BUTTON_RESTRICTION_FETCHING",
  POST_BACK_BUTTON_RESTRICTION_ERROR: "POST_BACK_BUTTON_RESTRICTION_ERROR",

  POST_REQUEST_CORRECTION_KAM_SUCCESS: "POST_REQUEST_CORRECTION_KAM_SUCCESS",
  POST_REQUEST_CORRECTION_KAM_FETCHING: "POST_REQUEST_CORRECTION_KAM_FETCHING",
  POST_REQUEST_CORRECTION_KAM_ERROR: "POST_REQUEST_CORRECTION_KAM_ERROR",

  POST_FORWARD_TO_CUSTOMER_SUCCESS: "POST_FORWARD_TO_CUSTOMER_SUCCESS",
  POST_FORWARD_TO_CUSTOMER_FETCHING: "POST_FORWARD_TO_CUSTOMER_FETCHING",
  POST_FORWARD_TO_CUSTOMER_ERROR: "POST_FORWARD_TO_CUSTOMER_ERROR",

  POST_STATUS_REOPENED_API_SUCCESS: "POST_STATUS_REOPENED_API_SUCCESS",
  POST_STATUS_REOPENED_API_FETCHING: "POST_STATUS_REOPENED_API_FETCHING",
  POST_STATUS_REOPENED_API_ERROR: "POST_STATUS_REOPENED_API_ERROR",

  GET_SPECIALPRICE_STATUS_SUCCES: "GET_SPECIAL_PRICE_STATUS_SUCCESS",
  GET_SPECIALPRICE_STATUS_FETCHING: "GET_SPECIAL_PRICE_STATUS_FETCHING",
  GET_SPECIALPRICE_STATUS_ERROR: "GET_SPECIAL_PRICE_STATUS_ERROR",

  POST_FORWARD_TO_KAM_STATUS_SUCCESS: "POST_FORWARD_TO_KAM_STATUS_SUCCESS",
  POST_FORWARD_TO_KAM_STATUS_FETCHING: "POST_FORWARD_TO_KAM_STATUS_FETCHING",
  POST_FORWARD_TO_KAM_STATUS_ERROR: "POST_FORWARD_TO_KAM_STATUS_ERROR",

  STORE_ORDER_NUMBER: "STORE_ORDER_NUMBER",

  STORE_DETAILS_REOPENED: "STORE_DETAILS_REOPENED",

  SAVE_FORM_API_SUCCESS: "SAVE_FORM_API_SUCCESS",
  SAVE_FORM_API_FETCHING: "SAVE_FORM_API_FETCHING",
  SAVE_FORM_API_ERROR: "SAVE_FORM_API_ERROR",

  REJECT_BY_PART_API_SUCCESS: "REJECT_BY_PART_API_SUCCESS",
  REJECT_BY_PART_API_FETCHING: "REJECT_BY_PART_API_FETCHING",
  REJECT_BY_PART_API_ERROR: "REJECT_BY_PART_API_ERROR",

  REJECT_REQUEST_API_SUCCESS: "REJECT_REQUEST_API_SUCCESS",
  REJECT_REQUEST_API_FETCHING: "REJECT_REQUEST_API_FETCHING",
  REJECT_REQUEST_API_ERROR: "REJECT_REQUEST_API_ERROR",

  CHECK_AVALIABLILITY_API_SUCCESS: "CHECK_AVALIABLILITY_API_SUCCESS",
  CHECK_AVALIABLILITY_API_FETCHING: "CHECK_AVALIABLILITY_API_FETCHING",
  CHECK_AVALIABLILITY_API_ERROR: "CHECK_AVALIABLILITY_API_ERROR",

  // CHECK_AVALIABLILITY_RESET_DATA_API_SUCCESS: "CHECK_AVALIABLILITY_RESET_DATA_API_SUCCESS",
  CHECK_AVALIABLILITY_RESET_DATA: "CHECK_AVALIABLILITY_RESET_DATA",
  // CHECK_AVALIABLILITY_RESET_DATA_API_ERROR:"CHECK_AVALIABLILITY_RESET_DATA_API_ERROR",

  VIEW_SPECIALPRCIEREQUEST_FETCHING: "VIEW_SPECIALPRICEREQUEST_FETCHING",
  VIEW_SPECIALPRICEREQUEST_SUCCESS: "VIEW_SPECIALPRICEREQUEST_SUCCESS",
  VIEW_SPECIALRICEREQUEST_ERROR: "VIEW_SPECIALPRICEREQUEST_ERRROR",

  CHECK_SPECIALPRICE_FETCHING: "CHECK_SPECIALPRICE_FETCHING",
  CHECK_SPECIALPRICE_SUCCESS: "CHECK_SPECIALPRICE_SUCCESS",
  CHECK_SPECIALPRICE_ERROR: "CHECK_SPECIALPRICE_ERROR",

  REJECT_SPECIALPRICE_FETCHING: "REJECT_SPECIALPRICE_FETCHING",
  REJECT_SPECIALPRICE_SUCCESS: "REJECT_SPECIALPRICE_SUCCESS",
  REJECT_SPECIALPRICE_ERROR: "REJECT_SPECIALPRICE_ERROR",

  REJECTTOTAL_SPECIALPRICE_FETCHING: "REJECTTOTAL_SPECIALPRICE_FETCHING",
  REJECTTOTAL_SPECIALPRICE_SUCCESS: "REJECTTOTAL_SPECIALPRICE_SUCCESS",
  REJECTTOTAL_SPECIALPRICE_ERROR: "REJECTTOTAL_SPECIALPRICE_ERROR",


  FORWARDTOKAM_SPECIALPRICE_FETCHING: "FORWARDTOKAM_SPECIALPRICE_FECHING",
  FORWARDTOKAM_SPECIALPRICE_SUCCESS: "FORWARDTOKAM_SPECIALPRICE_SUCCCESS",
  FORWARDTOKAM_SPECIALPRICE_ERROR: "FORWARDTOKAM_SPECIALPRICE_ERROR",

  VIEWCOMMENT_SPECIALPRICE_FETCHING: "VIEW_SPECIALPRICE_FETCHING",
  VIEWCOMMENT_SPECIALPRICE_SUCCESS: "VIEWCOMMENT_SPECIALPRICE_SUCCESS",
  VIEWCOMMENT_SPECIALPRICE_ERROR: "VIEWCOMMENT_SPECIALPRICE_ERROR",

  ADDCOMMENT_SPECIALPRICE_FETCHING: "ADDCOMMENT_SPECIALPRICE_FETCHING",
  ADDCOMMENT_SPECIALPRICE_SUCCESS: "ADDCOMMENT_SPECIALPRICE_SUCCESS",
  ADDCOMMENT_SPECIALPRICE_ERROR: "ADDCOMMENT_SPECAILPRICE_ERROR",

  GETCOMMENTPART_SPECIALPRICE_FETCHING: "GETCOMMENTPART_SPECIALPRICE_FETCHING",
  GETCOMMENTPART_SPECIALPRICE_SUCCESS: "GETCOMMENTPART_SPECIALPRICE_SUCCESS",
  GETCOMMENTPART_SPECIALPRICE_ERROR: "GETCOMMENTPART_SPECIALPRICE_ERROR",

  ADDCOMMENTTOTAL_SPECIALPRICE_FETCHING: "ADDCOMMENT_SPECIALPRICE_FETCHING",
  ADDCOMMENTTOTAL_SPECIALPRICE_SUCCESS: "ADDCOMMENT_SPECIALPRICE_SUCCESS",
  ADDCOMMENTTOTAL_SPECIALPRICE_ERROR: "ADDCOMMENT_SPECAILPRICE_ERROR",

  GETCOMMENTTOTAL_SPECIALPRICE_FETHCING: "GETCOMMENTTOTAL_SPECIALPRICE_FETCHING",
  GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS: "GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS",
  GETCOMMENTTOTAL_SPECIALPRICE_ERROR: "GETCOMMENTTOTAL_SPECIALPRICE_ERROR",


  DOWNLOADPROOF_SPECIALPRICE_FETCHING: "DOWNLOADPROOF_SPECIALPRICE_FETCHING",
  DOWNLOADPROFF_SPECIALPRICE_SUCCESS: "DOWNLOADPROOF_SPECIALPRICE_SUCCESS",
  DOWNLOADPROOF_SPECIALPRICE_ERROR: "DOWNLOADPROOF_SPECIALPRICE_ERROR",

  REMOVE_SPECIALPRICE_FETHCING: "REMOVE_SPECIALPRICE_FETHCING",
  REMOVE_SPECIALPRICE_SUCCESS: "REMOVE_SPECIALPRICE_SUCCESS",
  REMOVE_SPECIALPRICE_ERROR: "REMOVE_SPECIALPRICE_ERROR",

  GET_REQUEST_CORRECTION__PRCING_FETCHING: "GET_REQUEST_CORRECTION_FETCHING",
  GET_REQUEST_CORRRECTION_PRICING_SUCCES: "GET-REQUEST_CORRECTION_SUCCESS",
  GET_REQUEST_CORRECTION_PRICING_ERROR: "GET_REQUEST_CORRECTION_ERROR",
}

export const QUOTATION = {
  POST_REQUEST_QUOTATION_SUCCESS: "POST_REQUEST_QUOTATION_SUCCESS",
  POST_REQUEST_QUOTATION_FETCHING: "POST_REQUEST_QUOTATION_FETCHING",
  POST_REQUEST_QUOTATION_ERROR: "POST_REQUEST_QUOTATION_ERROR",

  GET_QUOTATION_SUCCESS: "GET_QUOTATION_SUCCESS",
  GET_QUOTATION_FETCHING: "GET_QUOATION_FETCHING",
  GET_QUOTATION_ERROR: "GET_QUOTATION-ERROR",

  VIEW_LPO_SUCCESS: "VIEW_LPO_SUCCESS",
  VIEW_LPO_FETCHING: "VIEW_LPO_FETCHING",
  VIEW_LPO_ERROR: "VIEW_LPO_ERROR",

  POST_OPEN_QUOTATION_SUCCESS: "POST_OPEN_QUOTATION_SUCCESS",
  POST_OPEN_QUOTATION_FETCHING: "POST_OPEN_QUOTATION_FETCHING",
  POST_OPEN_QUOTATION_ERROR: "POST_OPEN_QUOTATION_ERROR",

  POST_OPEN_QUOTATION_SUCCESS_LIST: "POST_OPEN_QUOTATION_SUCCESS_LIST",
  POST_OPEN_QUOTATION_FETCHING_LIST: "POST_OPEN_QUOTATION_FETCHING_LIST",
  POST_OPEN_QUOTATION_ERROR_LIST: "POST_OPEN_QUOTATION_ERROR_LIST",

  REQUEST_ORDER_QUOTATION_FETCHING: "REQUEST_ORDER_QUOTATION_FETCHING",
  REQUEST_ORDER_QUOTATION_SUCCESS: "REQUEST_ORDER_QUOTATION_SUCCESS",
  REQUEST_ORDER_QUOTATION_ERROR: " REQUEST_ORDER_QUOTATION_ERROR",

  UPLOAD_LPO_QUOTATION_FETCHING: "UPLOAD_LPO_QUOTATION_FETCHING",
  UPLOAD_LPO_QUOTATION_SUCCESS: "UPLOAD_LPO_QUOTATION_SUCCESS",
  UPLOAD_LPO_QUOTATION_ERROR: " UPLOAD_LPO_QUOTATION_ERROR",

  CANCEL_QUOTATION_SUCCESS: "CANCEL_QUOTATION_SUCCESS",
  CANCEL_QUOTATION_FETCHING: "CANCEL_QUOTATION_FETCHING",
  CANCEl_QUOTATION_ERROR: "CANCEL_QUOTATION_ERROR",


  GET_QUOTATION_LIST_STATUS_FETCHING: "GET_QUOTATION_LIST_STATUS_FETCHING",
  GET_QUOTATION_LIST_STATUS_SUCCESS: "GET_QUOTATION_LIST_STATUS_SUCCESS",
  GET_QUOTATION_LIST_STATUS_ERROR: "GET_QUOTATION_LIST_STATUS_ERROR",

  GET_USER_FETCHING: "GET-USER-FILTER_FETCHING",
  GET_USER_SUCCESS: "GET_USER_FILTER_SUCCESS",
  GET_USER_ERROR: "GET_USER_FILTER_ERROR",


  DOWNLOAD_QUOTATION_FETCHING: "DOWNLOAD_QUOTATION_FETCHING",
  DOWNLOAD_QUOTATION_SUCCESS: "DOWNLOAD_QUOTATION_SUCCESS",
  DOWNLOAD_QUOTATION_ERROR: "DOWNLOAD_QUOATION_ERROR",

  UPLOAD_LPO_EMAIL_FETCHING: "UPLOAD_LPO_EMAIL_FETCHING",
  UPLOAD_LPO_EMAIL_SUCCESS: "UPLOAD_LPO_EMAIL_SUCCESS",
  UPLOAD_LPO_EMAIL_ERROR: "UPLOAD_LPO_EMAIL_ERROR",


  QUOTATION_DETAILS_VIEWORDER_SUCCESS: "QUOTATION_VIEWORDER_DETAILS_SUCCESS"
}

export const SPECIAL_PRICE_KAM = {
  GET_SPECIALPRICE_LIST_SUCCESS: "GET_SPECIAL_PRICE_SUCCESS",
  GET_SPECIALPRICE_LIST_FETCHING: "GET_SPECIAL_PRICE_FETCHING",
  GET_SPECIALPRICE_LIST_ERROR: "GET_SPECIAL_PRICE_ERROR",

  GET_SPECIALPRICE_STATUS_SUCCES: "GET_SPECIAL_PRICE_STATUS_SUCCESS",
  GET_SPECIALPRICE_STATUS_FETCHING: "GET_SPECIAL_PRICE_STATUS_FETCHING",
  GET_SPECIALPRICE_STATUS_ERROR: "GET_SPECIAL_PRICE_STATUS_ERROR",

  POST_FORWARD_TO_KAM_STATUS_SUCCESS: "POST_FORWARD_TO_KAM_STATUS_SUCCESS",
  POST_FORWARD_TO_KAM_STATUS_FETCHING: "POST_FORWARD_TO_KAM_STATUS_FETCHING",
  POST_FORWARD_TO_KAM_STATUS_ERROR: "POST_FORWARD_TO_KAM_STATUS_ERROR",

  SAVE_FORM_API_SUCCESS: "SAVE_FORM_API_SUCCESS",
  SAVE_FORM_API_FETCHING: "SAVE_FORM_API_FETCHING",
  SAVE_FORM_API_ERROR: "SAVE_FORM_API_ERROR",

  REJECT_BY_PART_API_SUCCESS: "REJECT_BY_PART_API_SUCCESS",
  REJECT_BY_PART_API_FETCHING: "REJECT_BY_PART_API_FETCHING",
  REJECT_BY_PART_API_ERROR: "REJECT_BY_PART_API_ERROR",

  REJECT_REQUEST_API_SUCCESS: "REJECT_REQUEST_API_SUCCESS",
  REJECT_REQUEST_API_FETCHING: "REJECT_REQUEST_API_FETCHING",
  REJECT_REQUEST_API_ERROR: "REJECT_REQUEST_API_ERROR",

  CHECK_AVALIABLILITY_API_SUCCESS: "CHECK_AVALIABLILITY_API_SUCCESS",
  CHECK_AVALIABLILITY_API_FETCHING: "CHECK_AVALIABLILITY_API_FETCHING",
  CHECK_AVALIABLILITY_API_ERROR: "CHECK_AVALIABLILITY_API_ERROR",

  VIEW_SPECIALPRCIEREQUEST_FETCHING: "VIEW_SPECIALPRICEREQUEST_FETCHING",
  VIEW_SPECIALPRICEREQUEST_SUCCESS: "VIEW_SPECIALPRICEREQUEST_SUCCESS",
  VIEW_SPECIALRICEREQUEST_ERROR: "VIEW_SPECIALPRICEREQUEST_ERRROR",

  CHECK_SPECIALPRICE_FETCHING: "CHECK_SPECIALPRICE_FETCHING",
  CHECK_SPECIALPRICE_SUCCESS: "CHECK_SPECIALPRICE_SUCCESS",
  CHECK_SPECIALPRICE_ERROR: "CHECK_SPECIALPRICE_ERROR",

  REJECT_SPECIALPRICE_FETCHING: "REJECT_SPECIALPRICE_FETCHING",
  REJECT_SPECIALPRICE_SUCCESS: "REJECT_SPECIALPRICE_SUCCESS",
  REJECT_SPECIALPRICE_ERROR: "REJECT_SPECIALPRICE_ERROR",

  REJECTTOTAL_SPECIALPRICE_FETCHING: "REJECTTOTAL_SPECIALPRICE_FETCHING",
  REJECTTOTAL_SPECIALPRICE_SUCCESS: "REJECTTOTAL_SPECIALPRICE_SUCCESS",
  REJECTTOTAL_SPECIALPRICE_ERROR: "REJECTTOTAL_SPECIALPRICE_ERROR",


  FORWARDTOKAM_SPECIALPRICE_FETCHING: "FORWARDTOKAM_SPECIALPRICE_FECHING",
  FORWARDTOKAM_SPECIALPRICE_SUCCESS: "FORWARDTOKAM_SPECIALPRICE_SUCCCESS",
  FORWARDTOKAM_SPECIALPRICE_ERROR: "FORWARDTOKAM_SPECIALPRICE_ERROR",

  VIEWCOMMENT_SPECIALPRICE_FETCHING: "VIEW_SPECIALPRICE_FETCHING",
  VIEWCOMMENT_SPECIALPRICE_SUCCESS: "VIEWCOMMENT_SPECIALPRICE_SUCCESS",
  VIEWCOMMENT_SPECIALPRICE_ERROR: "VIEWCOMMENT_SPECIALPRICE_ERROR",

  ADDCOMMENT_SPECIALPRICE_FETCHING: "ADDCOMMENT_SPECIALPRICE_FETCHING",
  ADDCOMMENT_SPECIALPRICE_SUCCESS: "ADDCOMMENT_SPECIALPRICE_SUCCESS",
  ADDCOMMENT_SPECIALPRICE_ERROR: "ADDCOMMENT_SPECAILPRICE_ERROR",

  GETCOMMENTPART_SPECIALPRICE_FETCHING: "GETCOMMENTPART_SPECIALPRICE_FETCHING",
  GETCOMMENTPART_SPECIALPRICE_SUCCESS: "GETCOMMENTPART_SPECIALPRICE_SUCCESS",
  GETCOMMENTPART_SPECIALPRICE_ERROR: "GETCOMMENTPART_SPECIALPRICE_ERROR",

  ADDCOMMENTTOTAL_SPECIALPRICE_FETCHING: "ADDCOMMENT_SPECIALPRICE_FETCHING",
  ADDCOMMENTTOTAL_SPECIALPRICE_SUCCESS: "ADDCOMMENT_SPECIALPRICE_SUCCESS",
  ADDCOMMENTTOTAL_SPECIALPRICE_ERROR: "ADDCOMMENT_SPECAILPRICE_ERROR",

  GETCOMMENTTOTAL_SPECIALPRICE_FETHCING: "GETCOMMENTTOTAL_SPECIALPRICE_FETCHING",
  GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS: "GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS",
  GETCOMMENTTOTAL_SPECIALPRICE_ERROR: "GETCOMMENTTOTAL_SPECIALPRICE_ERROR",


  DOWNLOADPROOF_SPECIALPRICE_FETCHING: "DOWNLOADPROOF_SPECIALPRICE_FETCHING",
  DOWNLOADPROFF_SPECIALPRICE_SUCCESS: "DOWNLOADPROOF_SPECIALPRICE_SUCCESS",
  DOWNLOADPROOF_SPECIALPRICE_ERROR: "DOWNLOADPROOF_SPECIALPRICE_ERROR",

  REMOVE_SPECIALPRICE_FETHCING: "REMOVE_SPECIALPRICE_FETHCING",
  REMOVE_SPECIALPRICE_SUCCESS: "REMOVE_SPECIALPRICE_SUCCESS",
  REMOVE_SPECIALPRICE_ERROR: "REMOVE_SPECIALPRICE_ERROR",

  GET_REQUEST_CORRECTION__KAM_FETCHING: "GET_REQUEST_CORRECTION_FETCHING",
  GET_REQUEST_CORRRECTION_KAM_SUCCES: "GET-REQUEST_CORRECTION_SUCCESS",
  GET_REQUEST_CORRECTION_KAM_ERROR: "GET_REQUEST_CORRECTION_ERROR",

}