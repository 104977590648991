/* eslint-disable */
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import React, { Dispatch } from "react";
import TextField from "@material-ui/core/TextField";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { deleteAddressDocument, resetValues, updateDocument, getTradeRegion, getSelectedShipToPartyAddress, getAddressWithCustomerNo, getNationality } from '../../store/actions/onboard.actions';

const useStyles = makeStyles(() => (
    {
        root: {
            width: '100%',
        },
        title: {
            flex: '1 1 100%',
        },
        closeButton: {
            backgroundColor: '#EAEAEA',
            width: 120,
            fontWeight: 800,
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
            fontSize: '0.688rem',
            color: '#666666',
            borderRadius: '2.5rem',
            height: 35,
            textAlign: 'center',
            marginRight: 24
        },
        icon: {
            float: "left",
            margin: "0 20px 20px 0",
            height: "100%",
            fill: "white",
        },
        cancel: {
            fontSize: '0.813rem',
            fontFamily: 'Nunito',
            fontWeight: 700,
            color: '#919191',
            textTransform: 'capitalize'
        },
        rejectText: {
            color: '#616161',
            fontSize: '5rem',
            fontFamily: 'Nunito',
            fontWeight: 900,
            textTransform: 'inherit'
        }
    }));


const ShippingAddSelectionDialog = (props: any) => {
    const classes = useStyles();
    const [customerNumber, setCustomerNumber] = React.useState<any>();
    const [customerNumberError, setCustomerNumberError] = React.useState(false);
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const dispatch: Dispatch<any> = useDispatch();

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const resetData = () => {
        setCustomerNumber("");
    }

    function validateCustomerNumber(number: any) {
        const re = /^[0-9]+$/;
        return re.test(number);
    }

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const handleCustomerChange = (e: any) => {
        if (e.target.required) {
            setCustomerNumber(e.target.value)
            if (validateCustomerNumber(e.target.value) === false) {
                setCustomerNumberError(true);
            }
            else {
                setCustomerNumberError(false);
            }
        }
    }

    const handleSubmitCustomerNo = async () => {
        if (customerNumber && validateCustomerNumber(customerNumber)) {
            if (customerNumber) {
                let appendedString: any = customerNumber + '';
                if (appendedString.length < 10) {
                    let newString = '';
                    let customerLength = 10 - appendedString.length;
                    for (let i = 0; i < customerLength; i++) {
                        newString += '0'
                    }
                    appendedString = newString + customerNumber;
                }
                setCustomerNumber(appendedString);
                let addData: any = await dispatch(getAddressWithCustomerNo(props.b2bUserId, appendedString));

                if (addData && addData.data && addData.data.customerInformation && addData.data.customerInformation.addresses && addData.data.customerInformation.addresses.length > 0) {
                    // setAddressById(addData.data.customerInformation);
                    let customerInformation = addData.data.customerInformation
                    let addressDataFromSap = addData.data
                    let telePhoneNumber = customerInformation && customerInformation.telephones && customerInformation.telephones[0] && customerInformation.telephones[0].telephone
                    setSuccessMessage('Address details fetched successfully');
                    handleopenCloseSnackbar(true);
                    let address = {
                        ...customerInformation.addresses[0],
                        "companyName": customerInformation.companyName ? customerInformation.companyName : '',
                        "customerFirstName": customerInformation.customerFirstName,
                        "streetName": customerInformation.streetName ? customerInformation.streetName : '',
                        "customerLastName": customerInformation.customerLastName ? customerInformation.customerLastName : '',
                        "telephoneNumber": telePhoneNumber ? telePhoneNumber : '',
                        "mobileNumber": addressDataFromSap.primaryMobileNumber ? addressDataFromSap.primaryMobileNumber : '',
                        "emailId": addressDataFromSap.primaryEmailId ? addressDataFromSap.primaryEmailId : '',
                        "shippingLabels": customerInformation && customerInformation.shippingLabels ? customerInformation.shippingLabels : [],
                        "companyBrands": customerInformation.companyBrands,
                        "shiftToPartyCustomerNumber": addressDataFromSap.id,
                        "sapCustomerId": props.b2bUserId,
                        "transportationZone": customerInformation.transportationZone ? customerInformation.transportationZone : ''
                    }
                    setCustomerNumberError(false);
                    props.openCloseShippingAddSelectionDialog(address);
                    resetData();
                } else if (addData && addData.error) {
                    showErrorMessage(addData.error);
                    handleopenCloseErrorSnackbar(true);
                    setCustomerNumberError(true);
                }
            } else {
                showErrorMessage('Kindly enter the existing address’s Ship to party Customer ID or uncheck the option Pull from existing address');
                handleopenCloseErrorSnackbar(true);
                setCustomerNumberError(true);
            }
        } else {
            setCustomerNumberError(true)
        }
    }

    return (
        <div>
            <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseErrorSnackbar(false)
                }} message={errorMessage} />
            <Snackbar
                open={openSnackbar}
                handleopenClose={() => {
                    handleopenCloseSnackbar(false)
                }} message={successMessage} />
            <Dialog
                open={props.openCloseShippingAddSelectionDialog}
                aria-abelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                {/* <div className='px-3'> */}
                <div className="d-flex flex-row col-12 mt-3 border-bottom">
                    <h4 className="col-10 shift-to-party-page-title mb-2">Shipping Address Details</h4>
                </div>
                <div className="col-12 col-sm-12 mt-2 col-md-12 mb-2 col-lg-12 d-inline-flex pl-4">
                    <TextField
                        id="Ship to party Customer ID" value={customerNumber}
                        error={customerNumberError}
                        helperText={customerNumberError ? 'Please enter valid Ship to party Customer ID' : ''}
                        onChange={(e: any) => handleCustomerChange(e)}
                        size="small"
                        onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        className="col-lg-8 col-md-8 col-sm-8 pr-3 commonRoundedInputs common-input-font-size"
                        type="number"
                        label="Ship to party Customer ID" required variant="outlined" margin="dense" />
                </div>
                {/* </div> */}
                <div className="row-space-start col-12 mb-2 mt-0">
                    <div className="col-12 m-0 p-0">
                        <div className="row mb-0 px-2">
                            <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="row-space-start ml-auto">
                                        <DialogActions className="mt-3 mb-1 justify-content-end">
                                            <Button variant="contained" className={classes.closeButton}
                                                onClick={() => { props.openCloseShippingAddSelectionDialog(); resetData(); }}
                                            >Cancel
                                            </Button>
                                            <Button variant="contained"
                                                color="secondary" className="submit-button-chooseCustomer"
                                                onClick={() => { handleSubmitCustomerNo() }}
                                            >Submit
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}
export default ShippingAddSelectionDialog