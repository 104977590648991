/* eslint-disable */
import React, { Fragment, useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import AddIcon from '@material-ui/icons/Add';
import {
  getShipToPartyAddress, AddShipToPartyAddress, deleteShipToPartyAddress,
  getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues,
  getTransportationZone, changeCustomerType, customerAcceptingDeliveryInUAE, getRegion, submitAddressTab, getDeliveryType, updateDeliveryType
} from '../../../store/actions/onboard.actions';
import TextField from '@material-ui/core/TextField';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { MenuItem, Button, DialogContent, DialogContentText, DialogActions, FormGroup, FormControlLabel, Switch, FormControl, Radio, RadioGroup, Toolbar } from '@material-ui/core';
import { get } from 'lodash';
import FullscreenLoader from './../../../common/components/FullscreenLoader';
import Snackbar from '../../../common/components/CreatedEnquirySnackbar';
import Alert from '@material-ui/lab/Alert';
import ActionButtons from './ActionButtons';
import './ShipToParty.css';
import { AddNewAddressDailog } from '../../newUserOnboard/AddNewAddressDailog';
import DeleteDialog from '../../../common/components/deleteDialog';
import { Prompt, useHistory } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import WarningIcon from "@material-ui/icons/Warning";
import DeleteIcon from '@material-ui/icons/Delete';
import SubmitDialog from '../../newUserOnboard/submitYourRequestDialog';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  goToLogin: {
    backgroundColor: '#EAEAEA',
    width: 125,
    fontWeight: 800,
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  correctionText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  icon: {
    float: "left",
    // margin: "0 10px px 0",
    height: "100%",
    fill: "white",
  },
  close: {
    color: '#000000',
    fontWeight: 900
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

export const ShipToParty = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [isCustomerAcceptingDelivery, setIsCustomerAcceptingDelivery] = React.useState(true)
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("")
  const [salesRegion, setSalesRegion] = React.useState("")
  const [openCustomerAcceptingDelivery, setOpenCustomerAcceptingDelivery] = React.useState(false)
  const [openConfirmationPopup, setOpenConfirmationPopup] = React.useState(false)
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer, generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  // const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const [selected, setSelected] = React.useState<string>("");
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [tempSetCustomer, TempSetCustomer] = React.useState<any>(["New", "Existing"]);
  const [loading, setLoading] = React.useState<any>(true);
  const { shipToPartyAddressData, getSelectedAddress, regionData, updateAddressData, deleteAddressData, saveNewAdress, zoneData, dealerDetails, deliveryTypes }: any = useSelector((state: any) => state.onboard);
  const [editedAddress, setEditAddress] = React.useState({});
  const [addressList, setAddressList] = React.useState<any>([]);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [shippingId, setShippingId] = React.useState("")
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const userTypeFromProfileDetails: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const { getIndustryTypeAPI }: any = useSelector((state: any) => state.onboard);
  const { customerDetails }: any = useSelector((state: any) => state.onboard);
  const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { tradeRegionData }: any = useSelector((state: any) => state.onboard);
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const customer: any = sessionStorage.getItem('customerOnboardingType');
  const [deliveryType, setDeliveryType] = React.useState<string>('DLV');
  const classes = useStyles();
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
    if (!open) {
      setSuccessMessage(null)
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  // ON CHANGE SELECT DEALER TYPE 
  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  // ON CHANGE OF DEALER TYPE 
  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }


  const isValidShipToPartyTab = () => {
    if (userTypeFromProfileDetails === "FSE" && (sessionStorage.getItem('customerType') === 'new' || sessionStorage.getItem('customerType') === 'existing') && sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      return true
    }
    if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0 && customer !== "Government") {
      // if (userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerType') === 'new' ||
      //   sessionStorage.getItem('customerType') === 'existing') {
      //   return true;
      // }
      if (tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && customer !== "Government") {
        let selected = tradeRegionData.data.filter((data: any) => data.id === addressList[0].tradeLicenseNoId);
        if (selected && selected.length > 0 && selected[0].tradeLicenseNo) {
          return true;
        } else {
          if (customer && customer.toLowerCase() === 'workshop' && deliveryType !== "DLV") {
            return true;
          } else if (userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerType') === 'new' || sessionStorage.getItem('customerType') === 'existing') {
            return true;
          } else {
            setOpenErrorSnackbar(true);
            setSuccessMessage('Please add Trade License Number');
            return false;
          }
        }
      }
    } else {
      if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0) {
        return true;
      } else {
        if (customer && customer.toLowerCase() === 'workshop' && deliveryType !== "DLV") {
          return true;
        } else {
          setOpenErrorSnackbar(true);
          setSuccessMessage('Please add Authorized Person to Accept the Deliveries');
          return false;
        }
      }
    }
  }

  // ON CLICK OF NEXT BUTTON - SAVE DATA AND VALIDATIONS CHECK
  const handleNextClick = async () => {
    // saveDocumentDetails(true);
    if (isValidShipToPartyTab()) {
      if (userTypeFromProfileDetails === "FSE") {
        let data = {
          "b2bUserId": b2bUserId,
        }
        setLoading(true);
        const isSaveAddress: any = await dispatch(submitAddressTab(data));
        if (isSaveAddress && isSaveAddress.message) {
          setLoading(false);
          setApiResponseText(isSaveAddress.message.message ? isSaveAddress.message.message : 'Customer details save successfully');
          setOpenSnackbar(true);
          sessionStorage.setItem('dealerDetailsSaved', "");
          props.setDataUpdated(false);
          await setTimeout(() => {
            setOpenSnackbar(false)
            history.push('/dealers')
          }, 1000);
        } else {
          props.setTabValue(3);
        }
      } else {
        props.setTabValue(3);
      }
    }
  }

  // ON CLCIK OF BACK BUTTON FUNCTIONALITY
  const handleBackClick = () => {
    props.setTabValue(1)
  }

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/dealers');
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }

  // TO DISPATCH - NEW ADDRESS API & UPDATE API 
  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(b2bUserId, data, selected))
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
          setApiResponseText('')
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(b2bUserId, data));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
          setApiResponseText('')
        }, 2000);
      }
      setSelected("");
    }
  }

  // REGION API INTEGRATION
  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  // ON CLICK OF DELETE ADDRESS
  const onClickDeleteAddress = async (shippingAddress: any) => {
    handleopenClose(true);
    setIsEdited(false);
    setSelected("");
    setShippingAddress(shippingAddress);
  }

  // ON CLICK OF DELETE - API INTEGRATION
  const deleteShippingAddress = async (action: any) => {
    handleopenClose(false);
    var userType = {
      "userType": "kam"
    }

    let result: any = await dispatch(deleteShipToPartyAddress(b2bUserId, shippingId, userType));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
        setApiResponseText('')
      }, 2000);
    }
    else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
      handleopenClose(false);
      setOpenErrorSnackbar(true);
      setSuccessMessage(apiResponse);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  // TO DISPATCH API - ON CLICK OF EDIT
  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    await dispatch(getSelectedShipToPartyAddress(b2bUserId, addressId));
    setSelected(addressId);
    setOpen(true);
  }

  // ZONE TRANSPORTATION  CODE API INTEGRATION
  function getZoneCode(zoneName: any) {
    let zoneCode: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zone: any) => {
        if (zone.title === zoneName) {
          zoneCode = zone.code
        }
      });
    return zoneCode;
  }

  // ON CHANGE OF TRANSPORTATION ZONE FUNCTIONALITY
  const onChangeZone = (e: any, data: any, index: any) => {
    props.setDataUpdated(true);
    let address = [...addressList]
    if (e.target.value && e.target.value) {
      if (address[index].type == "New") {
        address[index].transportationZone = e.target.value;
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].shiftToPartyCustomerNumber = e.target.value;
      }
      setAddressList(address);
    }
    else if (!e.target.value) {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }

  // ON CHANGE OF NEW TRANSPORTATION ZONE
  const onChangeNew = (e: any, data: any, index: any) => {
    // props.setDataUpdated(true);
    if (e.target.value && e.target.value) {
      let address = [...addressList]
      address[index].type = e.target.value;
      if (e.target.value == "New") {
        address[index].isExisting = false;
        address[index].transportationZone = "";
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].isExisting = true;
        address[index].shiftToPartyCustomerNumber = "";
      }
      // address[index].transportationZoneCode = getZoneCode(e.target.value);
      setAddressList(address);
    }
  }

  // TO SAVE ADDRESS ON CLICK OF SAVE & NEXT
  // const saveZoneAddress = async () => {
  //   setIsValid(true);
  //   let isValidTemp = true
  //   let address = [...addressList];
  //   let data: any = {
  //     addresses: []
  //   };
  //   address.map((add: any) => {
  //     if (add.transportationZone && add.transportationZoneCode && !add.isExisting) {
  //       data.addresses.push({
  //         id: add.id,
  //         transportationZone: add.transportationZone,
  //         transportationZoneCode: add.transportationZoneCode,
  //         isExisting: add.isExisting,
  //       })
  //     }
  //     else if (add.isExisting && add.shiftToPartyCustomerNumber) {
  //       data.addresses.push({
  //         id: add.id,
  //         isExisting: add.isExisting,
  //         shiftToPartyCustomerNumber: add.shiftToPartyCustomerNumber
  //       })
  //     } else {
  //       isValidTemp = false;
  //       setIsValid(false);
  //     }
  //   });
  //   if (isValidTemp && address.length === data.addresses.length) {
  //     let savedZone: any = await dispatch(saveZone(b2bUserId, data));
  //     if (savedZone && savedZone.message && savedZone.message.status === 'S') {
  //       props.setDataUpdated(false);
  //       setApiResponseText(savedZone.message.message);
  //       handleopenCloseSnackbar(true);
  //     } else {
  //       setApiResponseText(savedZone.error);
  //       handleopenCloseErrorSnackbar(true);
  //     }
  //   } else {
  //     setApiResponseText('Please fill all mandatory fields');
  //     handleopenCloseErrorSnackbar(true);
  //   }
  // }

  // ON CLICK OF SAVE NEW ADDRESS
  useEffect(() => {
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(false);
    }
  }, [saveNewAdress]);

  // ON CLICK OF UPDATE ADDRESS
  useEffect(() => {
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [updateAddressData]);

  // ON CLICK OF DELETE ADDRESS
  useEffect(() => {
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [deleteAddressData]);

  useEffect(() => {
    loadshipDetails();
    let customerType: any = userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerType') === 'new' || sessionStorage.getItem('customerType') === 'existing' ? ("New Int. Business") : ("New Dealer")
    setOpenCustomerAcceptingDelivery(sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? true : false)
  }, []);

  const handleCustomerAcceptingDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    customerAcceptDelivery(event.target.checked)
  };

  const customerAcceptDelivery = async (isCustomerAcceptingDeliveryValue: boolean) => {
    setIsCustomerAcceptingDelivery(isCustomerAcceptingDeliveryValue);
    props.freeZoneProps({
      isDefaultUAE,
      salesRegion,
      isCustomerAcceptingDelivery: isCustomerAcceptingDeliveryValue,
      isIntBuisness: sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? true : false
      // isIntBuisness: userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerType') === 'new' ||
      //   sessionStorage.getItem('customerType') === 'existing' ? true : false
    })
    setOpenCustomerAcceptingDelivery(false)

    let data: any = await dispatch(customerAcceptingDeliveryInUAE({
      "id": b2bUserId, "typeOfData": "acceptDeliveryInUAE", "updatedBy": sessionStorage.getItem('webApplicationLoginId'),
      "updatedByRole": "FSE",
      "acceptDeliveryInUAE": isCustomerAcceptingDeliveryValue
    }));

    if (data && data.message && data.message.status === 'S') {
      handleopenClose(false);
      setSuccessMessage("Outsidefreezone is updated")
      handleopenCloseSnackbar(true);
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false)
      }, 2000);
      handleopenCloseSnackbar(true);
    }

  }
  // TO GET THE - SHIP TO PARTY ADDRESS & TRANSPORTATION ZONE
  const loadshipDetails = async () => {
    setLoading(true);
    let response: any = await dispatch(getShipToPartyAddress(b2bUserId));
    if (customer && customer.toLowerCase() === 'workshop') {
      await dispatch(getDeliveryType());
    }
    if (zoneData && zoneData.data && zoneData.data.length > 0) {
    } else {
      await dispatch(getTransportationZone(b2bUserId));
    }
    dispatch(getRegion(sessionStorage.getItem('customerOnboardingType')));
    if (response) {
      setLoading(false);
    }
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
  }

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setMasterCompanyBrands(shipToPartyAddressData.data.masterCompanyBrands);
      setAddressList(setaddressData(shipToPartyAddressData.data.addresses));
      setSapId(shipToPartyAddressData.data.sapCustomerId);
      setDeliveryType(shipToPartyAddressData.data.deliveryType ? shipToPartyAddressData.data.deliveryType : "DLV")
      let defaultAddress = shipToPartyAddressData.data.addresses.find((address: any) => address.dafault)
      if (defaultAddress) {
        setIsDefaultUAE(defaultAddress.country)
        setSalesRegion(defaultAddress.salesRegion)
      }
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      setSelected("");
    }
  }, [shipToPartyAddressData, getSelectedAddress])

  // TO SET NEW & EXISITING ADDRESS DATA
  function setaddressData(data: any) {
    data.forEach((element: any) => {
      element["type"] = "";
      if (dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer) {
        element.type = "New";
        element.isExisting = false;
      } else {
        element.type = "Existing";
        element.isExisting = true;
      }
    });
    return data;
  }

  const handleChangeDeliveryType = async (e: any) => {
    setDeliveryType(e.target.value);
    let data: any = {
      "id": b2bUserId,
      "deliveryType": e.target.value,
      "updatedBy": b2bUserId,
      "updatedByRole": sessionStorage.getItem('userType') === 'FSE' ? 'FSE' : "user"
    }
    let updatedData: any = await dispatch(updateDeliveryType(data));
    if (updatedData && updatedData.message && updatedData.message.status === 'S') {
      dispatch(getShipToPartyAddress(b2bUserId));
    } else {

    }
  }

  function getTelePhoneNumber(telePhone: any) {
    if (typeof telePhone === "string") {
      return telePhone
    } else {
      return telePhone && telePhone.length > 0 ? telePhone && telePhone[0] && telePhone[0].telephone : "-"
    }
  }

  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active') {
      return true;
    } else {
      return false;
    }
  }


  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      {sessionStorage.getItem('customerOnboardingType') === "Int. Business" && (

        <FormGroup className="mt-4 pl-4" aria-label="position" row>
          <FormControlLabel
            control={<AntSwitch color="primary" checked={isCustomerAcceptingDelivery} onChange={handleCustomerAcceptingDelivery} />}
            label={<h6 className={`${classes.correctionText}`}>Is the customer accepting deliveries within UAE to a PDC authorized delivery address ? &nbsp;</h6>}
            labelPlacement="start"
          />
        </FormGroup>
      )}
      <div className="row">
        {customer && customer.toLowerCase() === 'workshop' && (
          <FormControl component="fieldset" name="customer-type" className="mt-4 pl-2">
            <h6 className="page-title ml-4" style={{ fontSize: "15px" }}>Delivery Type</h6>
            <RadioGroup className="mt-2 ml-4" row onChange={handleChangeDeliveryType} value={deliveryType}>
              {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                if (ele.value === "SELF-PIC") {
                  return (<>
                    <FormControlLabel
                      value={ele.value}
                      control={<Radio color="primary" />}
                      label={ele.title} disabled={isDisabled()} />
                  </>)
                } else if (ele.value ===
                  "DLV") {
                  return (<>
                    <FormControlLabel
                      value={ele.value}
                      control={<Radio color="primary" />}
                      label={ele.title}
                      disabled={isDisabled()}
                    />
                  </>)
                }
              })}
            </RadioGroup>
          </FormControl>
        )}
        {customer && customer.toLowerCase() === 'workshop' ?
          <>
            <div className='col-12 mb-2 px-2'>
              {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                if (ele.title === "Self-pickup" && deliveryType === "SELF-PIC") {
                  return (<>
                    <h6 className="dealer-table-content mb-0 px-4">{ele.title === "Self-pickup" && deliveryType === "SELF-PIC" ? ele.description : ""}</h6>
                  </>)
                } else if (ele.value === "DLV") {
                  return (<>
                    <h6 className="dealer-table-content mb-0 px-4">{ele.title === "Delivery" && deliveryType !== "SELF-PIC" ? ele.description : ""}</h6>
                  </>)
                }
              })}
            </div>
            <div className="border-bottom"></div>
          </>
          : ""}
        {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
          <>
            <div className="col-12 m-0 pl-3">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card-body border-bottom pt-1" >
                    <div className="w-50 d-inline-block">
                      <div className="shipAddTitle">
                        {shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;
                        {shipppingAddress.dafault && (
                          <Button
                            className="defaultAddressButton cursor-default"
                            size="small"
                            variant="contained"
                            color="secondary"
                          >Head Office</Button>
                        )}
                      </div>
                      <div className="shipAdd mt-2">
                        {shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}
                        {shipppingAddress.city ? shipppingAddress.city + " - " : ""}
                        {shipppingAddress.country}
                      </div>
                      {sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="shipAdd">Contact Telephone Number:
                        {getTelePhoneNumber(shipppingAddress.telephoneNumber)}
                      </div>
                        : <div className="shipAdd">Telephone Office:
                          {getTelePhoneNumber(shipppingAddress.telephoneNumber)}
                        </div>}
                      {
                        sessionStorage.getItem('customerOnboardingType') !== "Int. Business" &&
                        <div className="shipAdd">PO Box: {shipppingAddress.poBox}</div>
                      }
                      <div className="shipAdd">Emirates/Region:
                        {shipppingAddress.region} {getRegionName(shipppingAddress.region)}
                      </div>
                      {sessionStorage.getItem('customerOnboardingType') === "Int. Business" && <div className="shipAdd">IB Sales Region: {salesRegion}</div>}
                      <div className="shipAdd">Ship To Party Customer ID:{" "}{shipppingAddress.shiftToPartyCustomerNumber ? shipppingAddress.shiftToPartyCustomerNumber : '-'}</div>
                    </div>
                    {shipppingAddress.dafault && (
                      <div className="markDefaultIcon w-50 d-inline-block text-right">
                        <Button
                          className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                          startIcon={((shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0) || (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && sessionStorage.getItem('customerOnboardingType') === "Int. Business")) ? <EditOutlinedIcon /> : <AddIcon />}
                          onClick={() => {
                            setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">
                            {((shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0) || (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && sessionStorage.getItem('customerOnboardingType') === "Int. Business")) ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'}
                          </span>
                        </Button>
                      </div>
                    )}
                    {(!shipppingAddress.dafault && !shipppingAddress.transportationZone) && (
                      <div className="markDefaultIcon w-50 d-inline-block text-right">
                        <Button
                          className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                          disabled={isDisabled() ? true : false}
                          startIcon={((shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0) || (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && sessionStorage.getItem('customerOnboardingType') === "Int. Business")) ? <EditOutlinedIcon /> : <AddIcon />}
                          onClick={() => {
                            setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                            onClickEditAddress(shipppingAddress.id)
                          }}><span className="onshiftoparty-font">
                            {((shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0) || (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && sessionStorage.getItem('customerOnboardingType') === "Int. Business")) ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'}
                          </span>
                        </Button>
                        {!shipppingAddress.dafault && !shipppingAddress.transportationZone && !shipppingAddress.shiftToPartyCustomerNumber &&
                          <Button
                            onClick={() => { setShippingId(shipppingAddress.id); setOpenDeleteDialog(true) }}
                            disabled={shipppingAddress.shiftToPartyCustomerNumber || shipppingAddress.transportationZone || isDisabled() ? true : false}
                            className={shipppingAddress.shiftToPartyCustomerNumber || shipppingAddress.transportationZone || isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                            startIcon={<DeleteIcon />}
                          > <span className="onshiftoparty-font">Delete</span>
                          </Button>
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
        <div className="col-12 display-flex p-0">
          <div className="col-8 p-0">
            <div className="row m-0">
              {/* <div className="col-5 p-0">
                <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' ? (
                  <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                ) : (
                  <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                    <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                      Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                  </p>
                )}
              </div> */}
              {/* <div className="col-4 mt-3 p-0">
                <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                // onClick={() => saveDocumentDetails(false)}
                >Save Form</Button>
              </div> */}
            </div>
          </div>
          <div className="col-4 onboard-footer float-right">
            <div className="p-3 mr-3">
              <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                onClick={() => handleBackClick()}
              >Back</Button>
            </div>
            <div className="p-3 mr-3">
              <Button variant="contained" color="primary"
                onClick={() => {
                  if (userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerType') === 'new' ||
                    sessionStorage.getItem('customerType') === 'existing') {
                    setOpenConfirmationPopup(true)
                  } else {
                    handleNextClick()
                  }
                }}
                className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerType') === 'new' ||
                  sessionStorage.getItem('customerType') === 'existing' ? "Submit" : props.showSave ? 'Next' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SubmitDialog
        open={openConfirmationPopup}
        handleopenClose={(data: any) => {
          if (data === "Submit") {
            handleNextClick();
            setOpenConfirmationPopup(false)
          }
          if (!data) setOpenConfirmationPopup(false)
        }}
      />
      <Dialog
        open={openCustomerAcceptingDelivery}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className={`row ml-2 w-80 px-3  ${classes.icon}`} style={{ marginRight: '1px' }}>
            <WarningIcon className="delete-warning-icon-customer" fontSize="small" />
          </div>
          <div>
            <DialogContentText id="alert-dialog-description" component="div">
              <div>
                <h6 className={`d-flex flex-row ml-3 mt-1 ${classes.correctionText}`}>Is the customer accepting deliveries within UAE to a PDC authorized delivery address ?</h6>
                {/* <h6 className="document-sub-content pl-4 pb-2 mt-2">Note -</h6><h6 className="document-sub-content-sub mt-2 ml-1">You will be able to change this later too.</h6> */}
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-3 mt-0"
            onClick={() => {
              customerAcceptDelivery(true)
            }}>
            Yes
          </Button>
          <Button variant="contained" className={classes.closeButton}
            onClick={() => {
              customerAcceptDelivery(false)
            }}
          >No
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} deliveryType={deliveryType} firstAddressCheck={firstAddressCheck} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} addDealer={true} isOutsideFreezone={isCustomerAcceptingDelivery} isIntBuisness={userTypeFromProfileDetails === "FSE" && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? true : false} isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
      </Dialog>
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }} />
      {apiResponseText !== '' && <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />}
      {<Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={successMessage} />}
      <DeleteDialog
        open={confirmDialog}
        text="Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost."
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteCustomerData();
          if (!data) {
            handleopenCloseConfirmPopup(false);
          }
        }} />
      {/* <ActionButtons
        isSaveDisabled={!props.dataUpdated}
        iscorrectionDisabled={true}
        showBackButton={true}
        saveCallback={() => {
          saveZoneAddress();
        }}
        correctionCallback={() => { }}
      /> */}
    </Fragment>
  )
}
export default ShipToParty;
