/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Dialog } from '@material-ui/core';
import WarningIcon from "@material-ui/icons/Warning";
import { useHistory } from 'react-router-dom';
import _, { get } from "lodash";
import { KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  closeButton1: {
    backgroundColor: '#EAEAEA',
    width: 200,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    width: "100%",
    fill: "white",
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  correctionTextCSS: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 200,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.955rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  gotoQuotation: {
    backgroundColor: '#1A9FE0',
    width: 160,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.955rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
}));

export default function RequestQuotationDialog(props: any) {
  const { statusSuccessChecked } = props
  const dispatch: Dispatch<any> = useDispatch();
  const { isBackOrder, handleopenClose } = props;
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [quotationCreated, setQuotationCreated] = React.useState<any>(false);
  const classes = useStyles();
  const [correctionList, setCorrectionList] = React.useState([]);
  const regex: any = /<br\s*[\/]?>/gi;
  const [comments, setComments] = React.useState<any>("");
  const [commentsTextError, setCommentsTextError] = React.useState<boolean>(false);
  const history = useHistory();
  const [loading, setLoading] = React.useState<any>(false);
  const [validUntilDate, setValidUntilDate] = React.useState<any>(null);
  const [validUntilDateError, setValidUntilDateError] = React.useState<boolean>(false);
  const [invalidUntilDateError, setInvalidUntilDateError] = React.useState<boolean>(false);
  const { getCustomerDetailsBySapId }: any = useSelector((state: any) => state.dealer);
  const [error, setError] = React.useState<any>(null)
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);

  const b2cUserId = get(
    profileDetails,
    "data.data.basicInfo.b2cUserId",
    ""
  );

  React.useEffect(() => {
    if (props.statusSuccessChecked) {
      setQuotationCreated(true);
    }
    setValidUntilDate(maxDate);
  }, [props])



  // ONCHANGE OF TEXTAREA-AUTOSIZE 
  const handleCustomerChange = (e: any) => {
    setComments(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setCommentsTextError(true);
      }
      else {
        setCommentsTextError(false);
      }
    }
  }

  const currentDate = new Date();
  const maxDate = new Date();
  const maxDateForIB = new Date();
  const maxDateForGovt = new Date();
  const maxDateForOthers = new Date();


  if (sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government") {
    maxDate.setDate(currentDate.getDate() + 90);
    maxDateForGovt.setDate(maxDateForGovt.getDate() + 90);

  } else if (sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Dealer" || getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Workshop") {
    maxDate.setDate(currentDate.getDate() + 30);
    maxDateForOthers.setDate(maxDateForIB.getDate() + 30);
  } else {
    // FOR IB
    maxDate.setDate(currentDate.getDate() + 30);
    maxDateForIB.setDate(maxDateForIB.getDate() + 30);
  }

  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      setError("Invalid Date Format");
      state(true);
      setInvalidDate(true);
    } else {
      state(false);
      setInvalidDate(false);
      setError(null);
    }
  }

  function validateDate() {
    let isValid: boolean = true;
    if (!validUntilDate) {
      setValidUntilDateError(true);
      setInvalidDate(true);
      setError('Please select date.')
      isValid = false;
    } else {
      setValidUntilDateError(false);
      setInvalidDate(false);
      setError('');
      isValid = true;
      props.requestQuotation(comments, validUntilDate);
      setValidUntilDate(null);
    }
    return isValid;
  }

  // const requestQuotation = async () => {
  //   let data: any;
  //   let updationData: any = {};
  //   let insertData: any = [];
  //   productsTemp.map((singleProduct: any) => {
  //     if (singleProduct["id"]) {
  //       if (updationData[singleProduct["id"]]) {
  //         updationData[singleProduct["id"]].push(singleProduct);
  //       } else {
  //         updationData[singleProduct["id"]] = [singleProduct];
  //       }
  //     } else {
  //       insertData.push(singleProduct)
  //     }
  //   });
  //   let finalData: any = [];
  //   Object.keys(updationData).map((singlekey: any) => {
  //     let tc: any = getEstimatedTotal(isAvailabiityChecked, true, withDeletion);
  //     finalData.push({
  //       id: singlekey,
  //       masterReferenceNumber: referenceNumber,
  //       products: updationData[singlekey],
  //       // totalCost: changeCheckBox ? getEstimatedTotal(true, withDeletion) : estTotal,
  //       totalCost: getEstimatedTotal(isAvailabiityChecked, true, withDeletion),
  //       netOrderValue: parseInt(tc) == 0 ? 0 : getnetOrderValue(updationData[singlekey], true, withDeletion)
  //     });
  //   });
  //   data = await dispatch(
  //     updateEnquiry(props.customerTierType, props.enquiryData[0].B2bEnquiryId, finalData, false, insertData, props.enquiryData && Array.isArray(props.enquiryData) && props.enquiryData.length > 0 && props.enquiryData[0].enquiryNumber, props.enquiryData && Array.isArray(props.enquiryData) && props.enquiryData.length > 0 && props.enquiryData[0].b2bLostSalesReportId ? props.enquiryData[0].b2bLostSalesReportId : getUniqueTimestamp()
  //     )


  //       setLoading(true);
  //   // data = await requestQuoatationServices.postRequestQuotation(
  //   //     {
  //   //         "sessionDetails": {
  //   //             "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
  //   //             "clientIPAddress": sessionStorage.getItem('clientIp'),
  //   //             "userId": b2cUserId,
  //   //         },
  //   //         "quotationsRequestDetails": {
  //   //             "B2bEnquiryId": "34800020003826",
  //   //             "sapCustomerId": "0200158601",
  //   //             "enquirySessionId": 1672298350578,
  //   //             "customerType": "Fleet",
  //   //             "createdByB2bUserId": "b2b0000000061",
  //   //             "createdByWebApplicationLoginId": "Terry01",
  //   //             "userName": "Shrikant",
  //   //             "userId": "ivl020",
  //   //             "type": "quotationRequest",
  //   //             "estimatedGrandTotal": "42114",
  //   //             "masterReferenceNumber": "test",
  //   //             "commonCommentsQuotation": "Test",
  //   //             "quotations": [
  //   //                 {
  //   //                     "enquiryReferenceNumber": "refmix",
  //   //                     "make": "Honda",
  //   //                     "brandId": "2002",
  //   //                     "enquiryNumber": "DU0000046268",
  //   //                     "estimatedTotal": "1099.35",
  //   //                     "isAirOrder": true
  //   //                 },
  //   //                 {
  //   //                     "enquiryReferenceNumber": "toyataref",
  //   //                     "make": "toyata",
  //   //                     "brandId": "2001",
  //   //                     "enquiryNumber": "DU0000046269",
  //   //                     "estimatedTotal": "1099.35",
  //   //                     "isAirOrder": false
  //   //                 }
  //   //             ]
  //   //         }
  //   //     }
  //   // )
  //   if (data && data.message && data.message.status === 'S') {
  //     props.setSuccessMessage(data && data.message && data.message.message);
  //     props.handleopenCloseSnackbar(true);
  //     setQuotationCreated(true);
  //     setTimeout(() => {
  //       setLoading(false)
  //     }, 2000)
  //   } else if (data && data.message && data.message.status === "E") {
  //     props.setSuccessMessage(data && data.error);
  //     props.handleopenCloseSnackbar(true);
  //     props.setOpenErrorSnackbar(true);
  //     setLoading(false)
  //     setTimeout(() => {
  //       props.handleopenCloseSnackbar(false);
  //       props.setOpenErrorSnackbar(false);
  //     }, 2000)
  //   }
  // }

  return (
    <Fragment>
      <Dialog
        open={props.open ? true : false}
        className='quotation'
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={'sm'}
        fullWidth={true}
      >
        <Snackbar
          open={props.openRequestSnackbar ? true : false}
          handleopenClose={() => { props.handleopenCloseSnackbar(false); }} message={props.successMessage}
        />
        {!statusSuccessChecked &&
          <DialogContent>
            <div className={`row ${classes.icon}`}>
              <WarningIcon className="delete-warning mt-3 ml-2" fontSize="small" />
              {isBackOrder ? (
                <><h5 className="submit-dialog-content mt-4 ml-4">Are you sure to request the quotation?</h5><br /></>
              ) : (
                <><h5 className="submit-dialog-content mt-4 ml-3">Do you want to proceed with quotation creation?</h5><br /></>
              )}
              {/* </span> */}
            </div>
            <div className='mt-3 mb-2'>
              <DialogContentText id="alert-dialog-description">
                {isBackOrder && (
                  <div className="pr-2 mb-2">
                    <h3 className="document-content-fontcolor pt-3 pb-2">
                      Your Enquiry has part numbers that are not in stock. If you proceed with Quotation creation, then a separate Quotation will be created for those Part numbers. You can also contact Al Futtaim Sales Executives to check their availability.
                    </h3>
                    <h3 className="document-content-fontcolor pb-2 ">
                      Do you want to proceed with quotation creation?</h3>
                  </div>
                )}
                <div className="mb-2 px-1">
                  <span className="file-close-color px-4 row">Note : For any amendments to the Quotation,
                    please reach out to your assigned - </span>
                  <span className="file-close-color px-4 pb-1 row">Al Futtaim Sales Executive.
                  </span>
                </div>
                {sessionStorage.getItem('userType') === 'FSE' ?
                  <div className="col-md-6 col-lg-6 mb-3">
                    <KeyboardDatePicker disablePast autoOk size="small"
                      required
                      disableToolbar variant="inline" inputVariant="outlined"
                      views={["year", "month", "date"]}
                      openTo="year"
                      maxDate={sessionStorage.getItem('userType') === 'FSE' && getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Int. Business" ? maxDateForIB :
                       getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Fleet" ||
                       getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Dealer" || 
                       getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Workshop" ? maxDateForOthers :
                       getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Government" ? maxDateForGovt :
                       validUntilDate}
                      onError={(e, v) => datePickerValid(e, setInvalidUntilDateError)}
                      format="dd/MM/yyyy" margin="normal" label="Valid Until Date" value={validUntilDate}
                      error={validUntilDateError || invalidDate ? true : false}
                      minDate={new Date()}
                      helperText={invalidDate && <span style={{ color: 'red' }}>{error}</span>}
                      onChange={(date: Date | null) => {
                        if (date) {
                          setValidUntilDate(date);
                          setValidUntilDateError(false)
                        } else {
                          setValidUntilDateError(true)
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                      className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                    />
                  </div>
                  : ""}
                <div className='px-3'>
                  <TextareaAutosize id="comments" value={comments} onChange={(e: any) => handleCustomerChange(e)}
                    aria-label="minimum height" minRows={3}
                    placeholder="Comments (Optional)" className="textArea-correction1 w-100 p-4"
                  />
                </div>
              </DialogContentText>
            </div>
          </DialogContent>}
        {statusSuccessChecked &&
          <>
            <div className="d-flex justify-content-center mt-4">
              {statusSuccessChecked === 'Queued' ? (
                <img src='./pending.svg' className="w-60p" />
              ) : (
                <CheckCircleIcon className="quotation-created" fontSize="small" />
              )}
            </div>
            <div className='mt-2 mb-2'>
              {statusSuccessChecked === 'Queued' ? (<>
                <DialogContentText id="alert-dialog-description">
                  <div className="mt-2">
                    <h5 className="text-center submit-quotation-dialog-content ">Your Requested Quotation is in Queue</h5>
                  </div>
                  <div className="pr-2 mb-2">
                    <h3 className="text-center document-content-fontcolor pt-3 pb-2 pr-3 pl-3">You can view or download the Quotation once verified from the Quotation Section.</h3>
                  </div>
                </DialogContentText>
              </>) : (<>
                <DialogContentText id="alert-dialog-description">
                  <div className="mt-2">
                    <h5 className="text-center submit-quotation-dialog-content ">Quotation Created Successfully</h5>
                  </div>
                  <div className="pr-2 mb-2">
                    <h3 className="text-center document-content-fontcolor pt-3 pb-2 pr-3 pl-3">You can view or download the Quotation from the Quotation Section.</h3>
                  </div>
                </DialogContentText>
              </>)}
            </div>
          </>
        }
        {!statusSuccessChecked && <DialogActions className="mt-3 mb-4 justify-content-end">
          <Button variant="contained"
            color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => {
              if (sessionStorage.getItem('userType') === 'FSE') {
                if (!validUntilDate) {
                  validateDate();
                } else if (invalidDate) {
                } else {
                  validateDate();
                }
              } else {
                props.requestQuotation(comments);
              }
            }}>Create Quotation
          </Button>
          <Button variant="contained" className={classes.closeButton1}
            onClick={() => {
              setValidUntilDate(null); setInvalidDate(false); setValidUntilDateError(false);
              handleopenClose(); setQuotationCreated(false); setComments("");
            }}>Return to Enquiry
          </Button>
        </DialogActions>}
        {/* {quotationCreated && props.statusSuccessChecked === true && <DialogActions className="mt-3 mb-4 justify-content-center"> */}
        {statusSuccessChecked && <DialogActions className="mt-3 mb-4 justify-content-center">
          <Button variant="contained"
            color="secondary" className={`createFavourite ml-2 ${classes.gotoQuotation}`}
            onClick={() => {
              if (statusSuccessChecked === 'Queued') props.loadQueueQuotation();
              setQuotationCreated(false); handleopenClose();
              history.push('/quotation'); setComments("");
            }}>Go to Quotation
          </Button>
          <Button variant="contained" className={classes.closeButton}
            onClick={() => {
              if (statusSuccessChecked === 'Queued') props.loadQueueQuotation();
              handleopenClose(); setQuotationCreated(false); setComments("");
              sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                mode: 'view',
                masterId: props.masterIDData,
                quotation: true
              }))
              history.push('/viewenquiry');
            }}
          >Close
          </Button>
        </DialogActions>}
      </Dialog>
    </Fragment >
  );
}