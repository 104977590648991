/* eslint-disable */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import { ThemeProvider } from '@material-ui/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { Checkbox } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import InputAdornment from '@material-ui/core/InputAdornment';
import ActionButtons from './ActionButtons';
import './Access.css';
import { useEffect } from 'react';
import { get } from 'lodash';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { editDealerService } from '../../services/editDealer.service';
import TradePointAccessDialog from '../NewDealerRequestsDetails/TradePointAccessDialog';
import { onboardingService } from '../../services/onboard.service';
import { ValidationErrorMessage } from '../../constants/messages';
import { getNewBrand } from '../../common/components/util';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function AccessIBKam(props: any) {
  const [selectedTab, setselectedTab] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { selectedNewDealer, isEditDealer, dealerDetails }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const [paramsdata, setparamsdata] = React.useState<any>([])
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [validationIssue, setValidationIssue] = React.useState(false);
  const [notfetchedInitialData, setnotfetchedInitialData] = React.useState(true);
  const [emailError, setEmailError] = React.useState<boolean>(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState<string>("");
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [dealerstatus, setStatus] = React.useState<any>("");
  const [sapAvailabilityChecked, setsapAvailabilityChecked] = React.useState<any>(false);
  const [sapId, setSapId] = React.useState<any>();
  const [ibAccessTabData, setIbAccessTabData] = React.useState<any>({
    "sapCustomerId": "",
    "actionTakenById": "",
    "addParameter": {
      "payerId": "",
      "billToParty": "",
      "shipToParty": "",
      "webApplicationLoginId": "",
      "firstName": "",
      "lastName": "",
      "emailId": "",
      "brands": [
      ]
    }
  });
  const [formData, setformData] = React.useState<any>({
    "featureAccessibility": []
  });
  const [isTradePointAccess, setIsTradepointAccess] = React.useState(false);
  const onboardingType = sessionStorage.getItem('customerOnboardingType');
  const [openTradePointAccessDialog, setopenTradePointAccessDialog] = React.useState<boolean>(false);
  const userType = sessionStorage.getItem('userType');
  const [accessTabData, setAccessTabData] = React.useState<any>();

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const classes = useStyles();

  useEffect(() => {
    loadInitialData()
  }, []);

  const loadInitialData = async () => {
    let paramsData = await editDealerService.getAllAcessParams();
    let data = await editDealerService.getDealerDetails(sapCustomerId, 'accessData');
    if (data && data.data) {
      setAccessTabData(data.data);
      setIsTradepointAccess(data.data.tradepointAccessToCustomer);
      setformData(data.data)
      if (data.data && data.data.addParameter && typeof data.data.addParameter == 'object' && (Object.keys(data.data.addParameter) && Object.keys(data.data.addParameter).length > 0)) {
        data.data.addParameter["emailId"] = data.data.addParameter.email;
      }
      setIbAccessTabData(data.data);
      if (data && data.data && data.data.addParameter && data.data.addParameter['webApplicationLoginId']) {
        setsapAvailabilityChecked(true)
      }
      if (data.data.featureAccessibility && Array.isArray(data.data.featureAccessibility)) {
        let allowedIds: any = [];
        data.data.featureAccessibility.map((feature: any) => {
          if (feature.value) {
            if (data.data.customerTierType === 'Workshop' && feature.featureCode === "VIEW_CR_STATUS") {
            } else {
              allowedIds.push(feature.featureCode)
            }
          }
        })
        if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
          let tempData = paramsData.data[0].featureAccessibility
          tempData.map((param: any) => {
            if (allowedIds.includes(param.featureCode)) {
              param.value = true
            } else {
              param.value = false
            }
            return param
          })
          setparamsdata(tempData)
        } else {
          setparamsdata(paramsData.data[0].featureAccessibility)
        }
      }
      else {
        setparamsdata(paramsData.data[0].featureAccessibility)
      }
      if (data.data.status) {
        setStatus(data.data.status);
      }
    }
    setnotfetchedInitialData(false)
  }

  const saveAccessTab = async () => {
    if (isTradePointAccess) {
      if (sapAvailabilityChecked) {
        let data = {
          "sapCustomerId": ibAccessTabData.sapCustomerId,
          "sessionDetails": {
            "clientIPAddress": sessionStorage.getItem('clientIp'),
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          },
          "actionTakenById": sessionStorage.getItem('webApplicationLoginId'),
          "tradepointAccessToCustomer": true,
          "addParameter": {
            "payerId": ibAccessTabData.addParameter && ibAccessTabData.addParameter.payerId,
            "billToParty": ibAccessTabData.addParameter && ibAccessTabData.addParameter.billToParty,
            "shipToParty": ibAccessTabData.addParameter && ibAccessTabData.addParameter.shipToParty,
            "webApplicationLoginId": ibAccessTabData.addParameter && ibAccessTabData.addParameter.webApplicationLoginId,
            "firstName": ibAccessTabData.addParameter && ibAccessTabData.addParameter.firstName,
            "lastName": ibAccessTabData.addParameter && ibAccessTabData.addParameter.lastName,
            "emailId": ibAccessTabData.addParameter && ibAccessTabData.addParameter.emailId,
            "brands": ibAccessTabData.addParameter && ibAccessTabData.addParameter.brands
          }
        }
        let hasValidationIssue = false;
        const requiredBandField = ['shipToParty', 'payerId', 'billToParty', 'webApplicationLoginId', 'firstName', 'lastName', 'emailId']
        requiredBandField.map((item: any) => {
          if (!ibAccessTabData['addParameter'] || !ibAccessTabData['addParameter'][item]) {
            hasValidationIssue = true;
          }
        })
        if (hasValidationIssue) {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setValidationIssue(true)
          setSuccessMessage("Please fill all mandatory fields");
          return
        } else {
          setValidationIssue(false)
        }
        let counter = 0;
        let brandName = ""
        let tempdata: any = JSON.parse(JSON.stringify(data.addParameter['brands']));
        if (tempdata) {
          tempdata = tempdata.map((item: any) => {
            if (item.assignKam && item.salesEmployee && item.salesGroup && item.salesOffice && item.division && item.distributionChannel && item.salesOrganization && item.creditControlArea) {
              counter += 1
              return item
            } else {
              let fields = ["creditControlArea", "distributionChannel", "division", "salesOffice", "salesGroup", "salesEmployee"]
              fields.map((subItem) => {
                if (item[subItem]) {
                  hasValidationIssue = true
                  brandName = item.make
                }
              })
              return item
            }
          })
        }
        if (counter == 0) {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setSuccessMessage("Please fill the details for atleast one brand below.");
          return
        } else if (hasValidationIssue) {
          setOpenErrorSnackbar(true);
          setOpenSnackbar(true);
          setValidationIssue(true)
          setSuccessMessage(`Please complete the details for the following brand(s): ${brandName}`);
          return
        } else {
          setValidationIssue(false)
        }

        setnotfetchedInitialData(true)
        data.addParameter['brands'] = tempdata;
        setformSubmitted(true);
        let featureData = paramsdata.filter((item: any) => item.value)
        let data1 = {
          "featureAccessibility": featureData,
        }
        setnotfetchedInitialData(true);
        setLoading(true);
        const responseData = await editDealerService.saveUpdateAcessTab(sapCustomerId, formData.b2bUserId, data1);
        setnotfetchedInitialData(false)
        if (responseData && responseData.message && responseData.message.status == "S") {
          // TODO: Call save action for access param
          setLoading(true);
          const response = await editDealerService.provideTPAccess(data);
          if (response && response.message && response.message.status == "S") {
            props.setDataUpdated(false);
            setOpenErrorSnackbar(false);
            setLoading(false);
            setOpenSnackbar(true);
            setSuccessMessage(response.message.message);
          } else {
            setOpenErrorSnackbar(true);
            setLoading(false);
            setOpenSnackbar(true);
            setSuccessMessage(response.error ? response.error : "Something went Wrong");
          }
          // props.setDataUpdated(false);
          // setOpenErrorSnackbar(false);
          // setOpenSnackbar(true);
          // setSuccessMessage(responseData.message.message);
        } else {
          setOpenErrorSnackbar(true);
          setLoading(false);
          setOpenSnackbar(true);
          setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
        }
      } else {
        setOpenErrorSnackbar(true);
        setOpenSnackbar(true);
        setSuccessMessage("Check User Id Availability before Saving");
      }
    } else {
      let data: any = {
        "sessionDetails": {
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        "sapCustomerId": ibAccessTabData.sapCustomerId,
        "actionTakenById": sessionStorage.getItem('webApplicationLoginId'),
        "tradepointAccessToCustomer": false
      }
      setLoading(true);
      const responseData = await editDealerService.provideTPAccess(data);
      if (responseData && responseData.message && responseData.message.status == "S") {
        props.setDataUpdated(false);
        setOpenErrorSnackbar(false);
        setLoading(false);
        setOpenSnackbar(true);
        setSuccessMessage(responseData.message.message);
      } else {
        setOpenErrorSnackbar(true);
        setLoading(false);
        setOpenSnackbar(true);
        setSuccessMessage(responseData.error ? responseData.error : "Something went Wrong");
      }
    }
  }

  const isDisabled = (access: any) => {
    if (access.featureCode === 'SEARCH_EPC'
      || access.featureCode === 'LIST_BILL_DOC' || access.featureCode === 'ACCOUNT_STATEMENT'
      || access.featureCode === 'CURT_PREVI_MONTH_SALES' || access.featureCode === 'DOC_EXP') {
      return true
    }
  }

  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      {notfetchedInitialData && <FullscreenLoader />}
      {sessionStorage.getItem('isAddedByFse') === "FSE" && (
        <div className="col-12 mt-1">
          <div className="d-flex flex-row pt-3">
            <div className="pt-1">
              <Checkbox
                checked={isTradePointAccess}
                // disabled={isEditDealer ? false : true}
                // disabled
                disabled={sessionStorage.getItem('userType') === 'FSE' || !isEditDealer }
                onChange={(e) => {
                  // setIsTradepointAccess(e.target.checked);
                  setopenTradePointAccessDialog(true);
                }}
                className=""
              />
            </div>
            <span className="pt-3 pr-2 fonts-catalogue">Enable Tradepoint access to Customer</span>
          </div>
          {!isTradePointAccess && sessionStorage.getItem('isAddedByFse') === "FSE" && (
            <div className='mb-2'><span className="file-close-color ml-2 mr-1">Note:</span> Kindly check this box if you want to provide Tradepoint access to Customer.</div>
          )}
          {accessTabData && accessTabData.sapCustomerId && !isTradePointAccess && sessionStorage.getItem('isAddedByFse') === "FSE" && (
            <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between ">
              <TextField id="sapCustomerId"
                disabled
                required
                value={ibAccessTabData && ibAccessTabData.sapCustomerId}
                // error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.sapCustomerId) && formSubmitted}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Customer No" variant="outlined" margin="dense"
              // onChange={(e) => {
              //   props.setDataUpdated(true);
              //   let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
              //   formDataTemp.addParameter['sapCustomerId'] = e.target.value
              //   setIbAccessTabData(formDataTemp)
              // }}
              >
              </TextField>
            </div>
          )}
        </div>
      )}
      {sessionStorage.getItem('isAddedByFse') === "FSE" && !isTradePointAccess ? '' : (
        <>
          <div className="card col-12 m-0 p-0">
            <div className="row mb-0 px-2">
              <div className="col-12 mt-1">
                <div className="d-flex align-items-center">
                  <h5 className="page-sub-title px-2 mt-1">Customer Access</h5>
                  <div className="row-space-start ml-auto">
                    {isEditDealer && (
                      <Button
                        color="secondary" className="mr-2 text-info"
                        disabled={dealerstatus && dealerstatus === "Deactive" ? true : false}
                        startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                        onClick={async () => {
                          const paramsData = await editDealerService.getAllAcessParams();
                          if (paramsData && paramsData.data && paramsData.data[0] && paramsData.data[0].featureAccessibility) {
                            setparamsdata(paramsData.data[0].featureAccessibility)
                          }
                        }}
                      ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Reset to Default
                        </span>
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card col-12 m-0 p-0">
            <div className="row mb-1 mt-1">
              {paramsdata && paramsdata.map((itemParam: any, index: any) => {
                return <div className="col-sm-12 col-md-4 col-lg-4 px-3 pr-2 mb-2 mt-2 row-space-between border-right">
                  <div className="lock-indicator font-size-12 px-2">
                    {itemParam.label}
                  </div>
                  <Typography component="div">
                    <Grid component="label" container alignItems="center" spacing={1}>
                      <Grid item>
                        <AntSwitch
                          checked={(formData.customerTierType === 'Workshop' && itemParam.featureCode === "VIEW_CR_STATUS") ? false : itemParam.value}
                          disabled={(itemParam.featureCode === 'MANAGE_EXPORT_TO_EXL') || (formData.customerTierType === 'Workshop' && itemParam.featureCode === "VIEW_CR_STATUS") || (isEditDealer ? isDisabled(itemParam) || dealerstatus && dealerstatus === "Deactive" ? true : false : true)}
                          onChange={(e) => {
                            props.setDataUpdated(true);
                            let paramDataTemp = JSON.parse(JSON.stringify(paramsdata));
                            paramDataTemp[index].value = !itemParam.value
                            setparamsdata(paramDataTemp)
                          }} />
                      </Grid>
                    </Grid>
                  </Typography>
                </div>
              })}
            </div>
          </div>
        </>
      )}
      {sessionStorage.getItem('isAddedByFse') === "FSE" && !isTradePointAccess ? '' : (
        <>
          <div className="card col-12 m-0 p-0">
            <div className="row mt-2 mb-1">
              <h6 className="page-sub-title mb-1 px-4 mt-2 mb-0">Add Parameter For Admin</h6>
            </div>
            <div className="row mb-1 mt-0 px-4">
              {sessionStorage.getItem('isAddedByFse') === "FSE" && (
                <>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between">
                    <TextField id="firstName"
                      required
                      type="text"
                      disabled={isEditDealer ? false : true}
                      value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.firstName}
                      error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.firstName) && formSubmitted}
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                        formDataTemp.addParameter['firstName'] = e.target.value
                        setIbAccessTabData(formDataTemp)
                      }}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="First Name" variant="outlined">
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between">
                    <TextField id="lastName"
                      required
                      type="text"
                      disabled={isEditDealer ? false : true}
                      value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.lastName}
                      error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.lastName) && formSubmitted}
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                        formDataTemp.addParameter['lastName'] = e.target.value
                        setIbAccessTabData(formDataTemp)
                      }}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Last Name" variant="outlined">
                    </TextField>
                  </div>
                  <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between">
                    <TextField id="emailId"
                      required
                      type="text"
                      disabled={isEditDealer ? false : true}
                      value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.emailId}
                      error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.emailId) && formSubmitted}
                      onChange={(e) => {
                        props.setDataUpdated(true);
                        let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                        formDataTemp.addParameter['emailId'] = e.target.value
                        if (e.target.value) {
                          if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value.trim()))) {
                            setEmailError(true);
                            setEmailErrorMessage("Invalid email");
                          }
                          else {
                            setEmailError(false);
                            setEmailErrorMessage("");
                          }
                        }
                        else {
                          setEmailError(false);
                          setEmailErrorMessage("");
                        }
                        setIbAccessTabData(formDataTemp)
                      }}
                      helperText={emailError && ibAccessTabData.addParameter && ibAccessTabData.addParameter['emailId'] == "" && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Email" variant="outlined">
                    </TextField>
                  </div>
                </>
              )}
              <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between ">
                <TextField id="sapCustomerId"
                  disabled
                  required
                  value={ibAccessTabData && ibAccessTabData.sapCustomerId}
                  error={(!ibAccessTabData || !ibAccessTabData.sapCustomerId) && formSubmitted}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Customer No" variant="outlined" margin="dense"
                  onChange={(e) => {
                    props.setDataUpdated(true);
                    let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                    formDataTemp.addParameter['sapCustomerId'] = e.target.value
                    setIbAccessTabData(formDataTemp)
                  }}
                >
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-2 row-space-between">
                <TextField id="shipToParty"
                  required
                  value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.shipToParty}
                  disabled={isEditDealer ? false : true}
                  error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.shipToParty) && formSubmitted}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Ship To Party" variant="outlined" margin="dense"
                  onChange={(e) => {
                    props.setDataUpdated(true);
                    let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                    formDataTemp.addParameter['shipToParty'] = e.target.value
                    setIbAccessTabData(formDataTemp)
                  }}
                >
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-2 row-space-between">
                <TextField id="payer"
                  required
                  value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.payerId}
                  error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.payerId) && formSubmitted}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  disabled={isEditDealer ? false : true}
                  label="Payer" variant="outlined" margin="dense"
                  onChange={(e) => {
                    props.setDataUpdated(true);
                    let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                    formDataTemp.addParameter['payerId'] = e.target.value
                    setIbAccessTabData(formDataTemp)
                  }}
                >
                </TextField>
              </div>
            </div>
            <div className="row mb-1 mt-0 px-4">
              <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between ">
                <TextField id="billToParty"
                  required
                  value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.billToParty}
                  error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.billToParty) && formSubmitted}
                  disabled={isEditDealer ? false : true}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Bill to Party" variant="outlined" margin="dense"
                  onChange={(e) => {
                    props.setDataUpdated(true);
                    let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                    formDataTemp.addParameter['billToParty'] = e.target.value
                    setIbAccessTabData(formDataTemp)
                  }}
                >
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between">
                <TextField id="userSapId"
                  required
                  value={ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.webApplicationLoginId}
                  error={(!ibAccessTabData || !ibAccessTabData.addParameter || !ibAccessTabData.addParameter.webApplicationLoginId) && formSubmitted}
                  disabled={isEditDealer ? false : true}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  onChange={(e) => {
                    props.setDataUpdated(true);
                    if (e.target.value) {
                      let value: any = e.target.value.split(' ')
                      if ((value && value.length > 1) || !/^[a-z0-9]+$/i.test(e.target.value) || e.target.value.length > 10) {
                        return
                      }
                    }
                    let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                    formDataTemp.addParameter['webApplicationLoginId'] = e.target.value
                    setIbAccessTabData(formDataTemp)
                    setsapAvailabilityChecked(false)
                  }}
                  InputProps={sapAvailabilityChecked ? {
                    endAdornment: <InputAdornment position="end">{
                      <CheckCircleIcon className="valid-user-id" />
                    }</InputAdornment>
                  } : {}}
                  label="User ID" variant="outlined" margin="dense">
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between">
                <Button
                  onClick={async () => {
                    if (ibAccessTabData.addParameter.webApplicationLoginId && ibAccessTabData.addParameter.webApplicationLoginId.trim()) {
                      setLoading(true);
                      const responseData = await onboardingService.checkSapAvailability({
                        "webApplicationLoginId": ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.webApplicationLoginId
                      })
                      if (responseData && responseData.message && responseData.message.status == "S") {
                        setOpenErrorSnackbar(false);
                        setOpenSnackbar(true);
                        setSuccessMessage(responseData.message.message);
                        setLoading(false);
                        setsapAvailabilityChecked(true)
                      } else {
                        setOpenErrorSnackbar(true);
                        setLoading(false);
                        setOpenSnackbar(true);
                        setSuccessMessage(responseData.message ? "User already exists with this ID" : "Something went Wrong");
                      }
                    } else {
                      setOpenErrorSnackbar(true);
                      setOpenSnackbar(true);
                      setSuccessMessage("Please enter User ID");
                    }
                  }}
                  disabled={isEditDealer ? false : true}
                  variant="contained"
                  className="check-availability-button-access-tab font-size-11 mt-2">
                  Check Availability
                </Button>
              </div>
            </div>
            <div className="mt-3">
              <ThemeProvider theme={theme}>
                <Paper square>
                  <Tabs
                    className="tabSelect"
                    value={selectedTab}
                    disabled={isEditDealer ? false : true}
                    textColor="primary"
                    indicatorColor="primary"
                    onChange={(event, newValue) => {
                      setselectedTab(newValue);
                    }}
                    aria-label="disabled tabs example">
                    {ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.brands && ibAccessTabData.addParameter.brands.map((brand: any, index: any) => {
                      return <Tab
                        label={getNewBrand(brand.make) === 'Tradepoint' && sessionStorage.getItem('itemCustomerTierType') !== 'Workshop' ? 'ALAC' : getNewBrand(brand.make)} value={index}
                      />
                    })}
                  </Tabs>
                </Paper>
              </ThemeProvider>
            </div>
          </div>
          {ibAccessTabData && ibAccessTabData.addParameter && ibAccessTabData.addParameter.brands && ibAccessTabData.addParameter.brands.map((brand: any, index: any) => {
            if (selectedTab == index) {
              return <div className="card col-12 m-0 p-0">
                <TabPanel value={index} index={index}>
                  <div className="row mb-1 mt-2 px-4">
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-1 row-space-between">
                      <TextField id="creditControlArea"
                        required
                        inputProps={{ maxLength: 4 }}
                        value={brand.creditControlArea}
                        disabled={isEditDealer ? false : true}
                        error={!brand.creditControlArea && formSubmitted}
                        helperText={(brand.creditControlArea && brand.creditControlArea !== '' && brand.creditControlArea.length < 4 && !(/^[a-zA-Z0-9]+$/i.test(brand.creditControlArea))) && <span style={{ color: 'red' }}>Please enter 4 character in Credit Control Area and Special characters are not allowed.</span>}
                        label="Credit Control Area" variant="outlined" margin="dense" size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        onChange={(e) => {
                          // if (!e.target.value || (e.target.value && e.target.value.length < 5 && !(/^[a-zA-Z0-9]+$/i.test(brand.creditControlArea)))) {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['creditControlArea'] = e.target.value.toUpperCase();
                          setIbAccessTabData(formDataTemp)
                          // }
                        }}
                      >
                      </TextField>
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-2 row-space-between">
                      <TextField id="salesOrg"
                        disabled
                        required
                        inputProps={{ maxLength: 4 }}
                        value={brand.salesOrganization}
                        error={!brand.salesOrganization && formSubmitted}
                        helperText={(brand.salesOrganization && brand.salesOrganization !== '' && brand.salesOrganization.length < 4 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesOrganization))) && <span style={{ color: 'red' }}>Please enter 4 character in Sales Organization and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['salesOrganization'] = e.target.value
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 5))
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Sales Org" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-2 row-space-between">
                      <TextField id="distributionChannel"
                        required
                        inputProps={{ maxLength: 2 }}
                        disabled={isEditDealer ? false : true}
                        value={brand.distributionChannel}
                        error={!brand.distributionChannel && formSubmitted}
                        helperText={validationIssue && (brand.distributionChannel && brand.distributionChannel !== '' && brand.distributionChannel.length < 2 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.distributionChannel))) && <span style={{ color: 'red' }}>Please enter 2 digit in Distribution Channel and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['distributionChannel'] = e.target.value
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 3))
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Distribution Channel" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                  </div>
                  <div className="row mb-1 mt-0 px-4">
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between ">
                      <TextField id="division"
                        required
                        inputProps={{ maxLength: 2 }}
                        value={brand.division}
                        disabled={isEditDealer ? false : true}
                        error={!brand.division && formSubmitted}
                        helperText={validationIssue && (brand.division && brand.division !== '' && brand.division.length < 2 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.division))) && <span style={{ color: 'red' }}>Please enter 2 Digit in Division and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['division'] = e.target.value
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 3))
                          //   onChange(e, 'division')
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Division" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between">
                      <TextField id="salesOffice"
                        required
                        inputProps={{ maxLength: 4 }}
                        value={brand.salesOffice}
                        disabled={isEditDealer ? false : true}
                        error={!brand.salesOffice && formSubmitted}
                        helperText={validationIssue && (brand.salesOffice && brand.salesOffice !== '' && brand.salesOffice.length < 4 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesOffice))) && <span style={{ color: 'red' }}>Please enter 4 Character in Sales Office and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['salesOffice'] = e.target.value.toUpperCase();
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 5))
                          //   onChange(e, 'salesOffice')
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Sales Office" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between">
                      <TextField id="salesGroup"
                        required
                        inputProps={{ maxLength: 3 }}
                        value={brand.salesGroup}
                        disabled={isEditDealer ? false : true}
                        error={!brand.salesGroup && formSubmitted}
                        helperText={validationIssue && (brand.salesGroup && brand.salesGroup !== '' && brand.salesGroup.length < 3 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesGroup))) && <span style={{ color: 'red' }}>Please enter 3 character in Sales Group and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['salesGroup'] = e.target.value
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 4))
                          //   onChange(e, 'salesGroup')
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Sales Group" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                  </div>
                  <div className="row mb-1 mt-0 px-4">
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between ">
                      <TextField id="salesEmployee"
                        required
                        inputProps={{ maxLength: 6 }}
                        value={brand.salesEmployee}
                        disabled={isEditDealer ? false : true}
                        error={!brand.salesEmployee && formSubmitted}
                        helperText={validationIssue && (brand.salesEmployee && brand.salesEmployee !== '' && brand.salesEmployee.length < 6 || !(/^[a-zA-Z0-9 ]+$/i.test(brand.salesEmployee))) && <span style={{ color: 'red' }}>Please enter 6 character in Sales Employee and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['salesEmployee'] = e.target.value
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 7))
                          //   onChange(e, 'salesEmployee')
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Sales Employee" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-3 mt-0 row-space-between">
                      <TextField id="assignedKam"
                        disabled
                        required
                        inputProps={{ maxLength: 6 }}
                        value={brand.assignKam}
                        // error={!brand.assignKam && formSubmitted}
                        // helperText={(brand.assignKam && brand.assignKam.length < 6) && <span style={{ color: 'red' }}>Please enter 6 character in Assign Kam and Special characters are not allowed.</span>}
                        onChange={(e) => {
                          props.setDataUpdated(true);
                          let formDataTemp = JSON.parse(JSON.stringify(ibAccessTabData));
                          formDataTemp.addParameter['brands'][index]['assignKam'] = e.target.value
                          setIbAccessTabData(formDataTemp)
                          // if (!e.target.value || (e.target.value && e.target.value.length < 7))
                          //   onChange(e, 'assignKam')
                        }}
                        size="small" className="col-sm-12 my-2 commonRoundedInputs"
                        label="Assigned KAM" variant="outlined" margin="dense">
                      </TextField>
                    </div>
                  </div>
                </TabPanel>
              </div>
            } else {
              return null
            }
          })}
        </>
      )}
      {isEditDealer && (
        <ActionButtons
          openRequestSnackbar={true}
          deactiveShow={true}
          dealerDetails={dealerDetails}
          dealerStatus={dealerstatus}
          saveCallback={() => {
            saveAccessTab()
          }}
          showSave
        />
      )}
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          setOpenSnackbar(false)
        }} message={successMessage} />
      <TradePointAccessDialog
        open={openTradePointAccessDialog}
        isTradePointAccess={isTradePointAccess}
        handleopenClose={(data: any,) => {
          if (data == 'Submit') {
            setIsTradepointAccess(isTradePointAccess ? false : true);
            props.setDataUpdated(true);
            setopenTradePointAccessDialog(false);
          } else {
            setopenTradePointAccessDialog(false)
          }
        }}
      />
    </Fragment>
  );
}

