import React, { Dispatch } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import moment from "moment-timezone";
import { getFavoriteDetails, getFavoritesList } from "../../store/actions/favorites.actions";
import { useDispatch } from "react-redux";
import SearchBar from '../favorites/searchBar';
import DialogActions from '@material-ui/core/DialogActions';
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';


const headCells = [
  { id: 'srNo', numeric: true, disablePadding: true, label: 'Favorite Name' },
  { id: 'partNo', numeric: true, disablePadding: false, label: 'Status' },
  { id: 'desc', numeric: true, disablePadding: false, label: 'Date' },
  { id: 'brand', numeric: true, disablePadding: false, label: 'Created By' },
  { id: 'qty', numeric: true, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props: any) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHeader}
            key={headCell.id}
            align={headCell.numeric ? 'left' : 'center'}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = () => {
  const [createdByME, setcreatedByME] = React.useState<any>(false);
  const [searchText, setsearchText] = React.useState<any>('');
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const { favoritesList }: any = useSelector((state: any) => state.favorites);

  React.useEffect(() => {
    if (!favoritesList) dispatch(getFavoritesList(createdByME, searchText));
  }, [dispatch, favoritesList, createdByME, searchText]);

  return (
    <React.Fragment>
      <Toolbar className={clsx(classes.root)}>
        <div className="d-flex justify-content-end w-100">
          <h4 className="page-title mt-2">Load from Favorites</h4>
          <div className="ml-auto">
            <SearchBar callAPI={() => {
              dispatch(getFavoritesList(createdByME, searchText));
            }} value={searchText} onChange={(e: any) => {
              setsearchText(e.target.value);
              if (e.target.value === '') {
                dispatch(getFavoritesList(createdByME, ''));
              }
            }} label="Search by name" className="mr-3" />
          </div>
        </div>
      </Toolbar>
      <div className="p-2 mb-2">
        <Checkbox className="checkbox"
          onChange={() => {
            dispatch(getFavoritesList(!createdByME, searchText));
            setcreatedByME(!createdByME)
          }} />
        <span className="text-info">Created by me</span>
      </div>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: 10,
  },
  title: {
    flex: '1 1 100%',
    fontSize: 25,
    fontWeight: 'bold',
    marginLeft: '0.7rem   '
  },
  tableHeader: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: 12,
    fontFamily: "Nunito",
    minWidth: 60,
  },
  offer: {
    paddingRight: 30,
    borderRight: '3px solid grey',
    fontWeight: 'bold',
    color: '#999999'
  }
}));

export default function ViewFavoriteInfo(props: any) {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { favoritesList }: any = useSelector((state: any) => state.favorites);

  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  return (<>
    <div className={classes.root}>
      <EnhancedTableToolbar />
      <TableContainer >
        <Table
          aria-labelledby="tableTitle"
          size='medium'
          aria-label="enhanced table"
          className="w-100"
        >
          <EnhancedTableHead classes={classes}
          />
          <TableBody>
            {favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.map((favorite: any) => {
              return <TableRow hover key={favorite.id} onClick={() => {
                props.setselectedFavourite(true)
                dispatch(getFavoriteDetails(favorite))
              }} className="click-entire-row">
                <TableCell component="th" id={''} scope="row" className="text-blue">{favorite.name}</TableCell>
                <TableCell align="left" className="text-light-gray">{favorite.isGlobal ? 'isGlobal' : 'Self'}</TableCell>
                <TableCell align="left" className="text-light-gray">{moment(new Date(favorite.updatedAt)).utc().format("DD MMM YY")}</TableCell>
                <TableCell align="left" className="text-light-gray">{favorite.userId}</TableCell>
                <TableCell> <ArrowForwardIosIcon className="text-blue ml-2" fontSize="small" /> </TableCell>
              </TableRow>
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div >
    <DialogActions className="mt-2 mb-3 justify-content-end">
      {showCreateChangeEnquiry &&
        <Button
          autoFocus
          variant="contained"
          color="secondary"
          className={`font-size-11 mr-1 actionButtomButtomsBlue actionButtomButtomsBlueDisabled`}
        >
          Add to Order Enquiry
        </Button>
      }
      <Button className="cancel-button font-size-11 mr-3" onClick={() => props.setOpen(false)}>
        Close
      </Button>
    </DialogActions>
  </>);
}
