
import { CREATE_UPDATE, CREATE_UPDATE_QUEUE_CHECK, SELECTED_PARTS, FETCH_DL_EMAIL_BY_REGION } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { createUpdateEnquiryService } from "../../services/createUpdateEnquiry.service ";

export const createUpdateEnquiryActions = {
  createEnquiry,
  updateEnquiry,
  getEnquiryDetails,
  getRefreshOrderDetailsAPI,
};

export function createEnquiry(customerTierType: any, reference: any, products: any, userName?: any, userId?: any, enquirySessionId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData = {
        "masterReferenceNumber": reference,
        "products": products,
        "customerId": sessionStorage.getItem('customerId'),
        "customerName": sessionStorage.getItem('customerName'),
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
        },
        "dealerCode": "test01",
        "userName": userName,
        "userId": userId,
        enquirySessionId: `b2bLSR${enquirySessionId}`,
        customerType: customerTierType.toLowerCase()
      }
      const response = await createUpdateEnquiryService.createEnquiry(sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function updateEnquiry(customerTierType: any, shiftToPartyCustomerNumber: any, masterId: any, products: any, isCostUpdate: any, insertData?: any, enquiryNumber?: any, enquirySessionId?: any, isConfirmation?: any, isQuotation?: any, comment?: any, b2bUserId?: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "updateData": products,
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "loginPassword": "",
          "applicationSecurityKey": "",
          "initialLogonIndicator": "",
          "initialPassword": "",
          "username": "",
          "password": ""
        },
        "insertData": insertData && Array.isArray(insertData) ? insertData : [],
        "isCostUpdate": isCostUpdate,
        "isConfirmation": isConfirmation ? true : false,
        "enquiryNumber": enquiryNumber,
        "customerId": sessionStorage.getItem('customerId'),
        "customerName": sessionStorage.getItem('customerName'),
        customerType: customerTierType && customerTierType.toLowerCase(),
        shiftToPartyCustomerNumber: shiftToPartyCustomerNumber,
        "enquirySessionId": sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
      }
      if (isQuotation) {
        sendData.isQuotation = true;
        sendData.quotationComment = comment;
        if(sessionStorage.getItem('userType') === 'FSE'){
        sendData.quotationToDate = sessionStorage.getItem('validUntilDate');
        }
        sendData.b2bUserId = b2bUserId;
      }
      const response = await createUpdateEnquiryService.updateEnquiry(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function verifyEnquiry(customerTierType: any, masterId: any, enquirySessionId: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "loginPassword": "",
          "applicationSecurityKey": "",
          "initialLogonIndicator": "",
          "initialPassword": "",
        },
        customerType: customerTierType && customerTierType.toLowerCase(),
        customerId: sessionStorage.getItem('customerId'),
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
      }

      const response = await createUpdateEnquiryService.verifyEnquiry(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function getDLEmailByRegion(region: string): any {
  return async (dispatch: any) => {
    dispatch(apiStart(FETCH_DL_EMAIL_BY_REGION.FETCH_DL_EMAIL_BY_REGION_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getDLEmailByRegion(region);
      dispatch(apiSuccess(FETCH_DL_EMAIL_BY_REGION.FETCH_DL_EMAIL_BY_REGION_SUCCESS, response))
      return response || [];
    } catch (error) {
      dispatch(apiError(FETCH_DL_EMAIL_BY_REGION.FETCH_DL_EMAIL_BY_REGION_ERROR, error));
      return error
    }
  }
}

export function getRefreshOrderDetailsAPI(customerType?: any, loginId?: any, sapCustomerId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.REFRESH_DETAILS_API_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getRefreshOrderDetailsAPI(customerType, loginId, sapCustomerId);
      dispatch(apiSuccess(CREATE_UPDATE.REFRESH_DETAILS_API_SUCCESS, response))
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.REFRESH_DETAILS_API_ERROR, error));
      return error
    }
  }
}

export function verifyEnquiryQueueCheck(customerTierType: any, b2bEnquiryId: any, customerId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getEnquiryDetailsQueueCheck(customerTierType, b2bEnquiryId, sessionStorage.getItem('webApplicationLoginId'), customerId);
      dispatch(apiSuccess(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_ERROR, error));
      return error;
    }
  };
}

export function verifyQuotationQueueCheck(enquiryId: any, b2bEnquiryId: any, sapCustomerId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getQuotationDetailsQueueCheck(enquiryId, b2bEnquiryId, sessionStorage.getItem('webApplicationLoginId'), sapCustomerId);
      dispatch(apiSuccess(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE_QUEUE_CHECK.CREATE_UPDATE_QUEUE_CHECK_ERROR, error));
      return error;
    }
  };
}

export function sendZeroProductsEmail(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.SEND_EMAIL));
    try {
      const response = await createUpdateEnquiryService.sendEmail(data);
      dispatch(apiSuccess(CREATE_UPDATE.SEND_EMAIL_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.SEND_EMAIL_ERROR, error));
      return error;
    }
  };
}
export function sendEmailProcurePart(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.SEND_EMAIL_PROCUREMENTPART_FETCHING));
    try {
      const response = await createUpdateEnquiryService.sendEmailProcurePart(data);
      dispatch(apiSuccess(CREATE_UPDATE.SEND_EMAIL_PROCUREMENTPART_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.SEND_EMAIL_PROCUREMENTPART_ERROR, error));
      return error;
    }
  };
}

export function cancelEnquiry(customerTierType: any, masterId: any, customerId?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp'),
          "loginPassword": "",
          "applicationSecurityKey": "",
          "initialLogonIndicator": "",
          "initialPassword": "",
        },
        customerType: customerTierType && customerTierType.toLowerCase(),
        customerId: sessionStorage.getItem('userType') === 'FSE' ? customerId : sessionStorage.getItem('customerId')
      }

      if (sessionStorage.getItem('userType') === 'FSE') {
        sendData.userRole = 'FSE'
      }
      const response = await createUpdateEnquiryService.cancelEnquiry(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function getEnquiryDetails(customerTierType: any, masterId: any, isOrder: any = false, quotation: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      const response = await createUpdateEnquiryService.getEnquiryDetails(customerTierType, masterId, isOrder, quotation);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function placeOrder(customerTierType: any, deliveryType: any, masterId: any, userName?: any, userId?: any, enquirySessionId?: any, productsWithBo?: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.POST_CREATE_UPDATE_FETCHING));
    try {
      let sendData: any = {
        "sessionDetails": {
          "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
          "clientIPAddress": sessionStorage.getItem('clientIp')
        },
        "userName": userName,
        "userId": userId,
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
        customerType: customerTierType.toLowerCase(),
        customerId: sessionStorage.getItem('customerId'),
        deliveryType: deliveryType === "DLV" ? "Onsite Delivery" : deliveryType === "SELF-PIC" ? "Self Pickup" : "Select",
        productsWithBo: productsWithBo || []
      }
      const response = await createUpdateEnquiryService.placeOrder(masterId, sendData);
      dispatch(apiSuccess(CREATE_UPDATE.POST_CREATE_UPDATE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.POST_CREATE_UPDATE_ERROR, error));
      return error;
    }
  };
}

export function deleteLostSaleReport(customerTierType: any, ids: any, enquirySessionId: any) {
  let sessionId = enquirySessionId && enquirySessionId.toString();
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.DELETE_LOST_SALE_REPORT_FETCHING));
    try {
      let sendData: any = {
        "id": ids,
        enquirySessionId: sessionId && sessionId.includes('b2bLSR') ? enquirySessionId : `b2bLSR${enquirySessionId}`,
        customerType: customerTierType && customerTierType.toLowerCase(),
      }
      const response = await createUpdateEnquiryService.deleteLostSaleReport(sendData);
      dispatch(apiSuccess(CREATE_UPDATE.DELETE_LOST_SALE_REPORT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(CREATE_UPDATE.DELETE_LOST_SALE_REPORT_ERROR, error));
      return error;
    }
  };
}

export function getUniqueEnquirySessionId(enquirySessionId: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(CREATE_UPDATE.GET_UNIQUE_ENQUIRY_ID, enquirySessionId));
    return enquirySessionId || {};
  };
}

export function setEnquiryId(item: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(CREATE_UPDATE.SET_ENQUIRY_ID, item));
  };
}

export function selectedParts(item: any, estTotal: any, enquiryDataDetails: any) {
  return async (dispatch: any) => {
    let estTotalGrand = {
      selectedParts: item, estPartsTotal: estTotal, enquiryData: enquiryDataDetails
    }
    dispatch(apiStart(SELECTED_PARTS.GET_SELECTED_PARTS_SUCCESS, estTotalGrand))
  }
}