import React from "react";
import "./styles/theme.css";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
} from "react-router-dom";
import ErrorBoundary from "./common/errorBoundary/ErrorBoundary";

// Browser History
// import {history} from "./helpers/history";

import Admin from "./components/Admin/Admin";
import { PrivateRoute } from "./common/components/PrivateRoute";
import { AccountRoute } from "./common/components/AccountRoute";
import { AdminAccountRoute } from "./common/components/AdminAccountRoute";

import { useSelector } from "react-redux";

import {
  MsalProvider,
} from "@azure/msal-react";
import { msalConfig, azureADConfigAdmin } from "./authConfig";
import LoginAzure from "./components/Login/LoginAzure";
import AdminLoginAzure from "./components/Login/AdminLoginAzure";
import AuthenticateAzure from "./components/Login/AuthenticateAzure";
import { PublicClientApplication } from "@azure/msal-browser";
import SignupAzureB2C from "./components/Login/SignupAzureB2C";
import DealerUserOnboardingAuthenticateAzure from "./components/Login/DealerUserOnboardingAuthenticateAzure";
import Home from "./components/home";
import WorkshopHomeComponent from './components/workshophome'
import TermsAndConditions from "./components/Dealers/AddDealer/Terms&Conditions";
import PlaceOrderSummary from "./components/CreateEnquiry/PlacedOrderSummary";

const App: React.FC<any> = () => {
  // constuseSelector((state: any) => { return state.account && state.account;
  const isAdminLogin = window.location.href.includes("/admin")
  const msalInstance = new PublicClientApplication(isAdminLogin ? azureADConfigAdmin : msalConfig);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);


  return (
    <MsalProvider instance={msalInstance}>
      <div className="App" id="wrapper">
        <Router>
          <ErrorBoundary>
            <Switch>
              <Route path="/signup">
                <SignupAzureB2C />
              </Route>
              <Route path="/ordersummary"><PlaceOrderSummary /></Route>
              <Route path="/home">
                <Home />
              </Route>
              <Route path="/workshophome">
                <WorkshopHomeComponent />
              </Route>
              <Route path="/onboard">
                <DealerUserOnboardingAuthenticateAzure />
              </Route>
              <AccountRoute path="/login">
                <LoginAzure />
              </AccountRoute>
              <AccountRoute path="/adminauth">
                <LoginAzure />
              </AccountRoute>
              <AdminAccountRoute path="/admin">
                <AdminLoginAzure />
              </AdminAccountRoute>
              <Route path="/authenticate">
                <AuthenticateAzure />
              </Route>
              <Route path="/termsandconditions"><TermsAndConditions /></Route>
              <PrivateRoute isAuthenticated={profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo ? true : false} path="/">
                <Admin />
              </PrivateRoute>
              <Redirect from="*" to="/" />
            </Switch>
          </ErrorBoundary>
        </Router>
      </div>
    </MsalProvider>
  );
};

export default App;
