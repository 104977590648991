/* eslint-disable */
import React from "react"
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import "./ViewWriteComments.css";
import "./RejectPartDialog.css";
import IconButton from '@material-ui/core/IconButton';
import { useSelector } from 'react-redux';
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { Link } from "react-router-dom";


const useStyles = makeStyles(() => (
    {
        root: {
            width: '100%',
        },
        title: {
            flex: '1 1 100%',
        },
        closeButton: {
            backgroundColor: '#EAEAEA',
            width: 120,
            fontWeight: 800,
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
            fontSize: '0.688rem',
            color: '#666666',
            borderRadius: '2.5rem',
            height: 35,
            textAlign: 'center',
            marginRight: 24
        },
        icon: {
            float: "left",
            margin: "0 20px 20px 0",
            height: "100%",
            fill: "white",

        },
        cancel: {
            fontSize: '0.813rem',
            fontFamily: 'Nunito',
            fontWeight: 700,
            color: '#919191',
            textTransform: 'capitalize'
        },
        rejectText: {
            color: '#616161',
            fontSize: '5rem',
            fontFamily: 'Nunito',
            fontWeight: 900,
            textTransform: 'inherit'
        }
    }));



const Documents = (props: any) => {
    const classes = useStyles();
    const { viewSpecialPrice } = useSelector((state: any) => state.specialPrice)


    const download = (url: any) => {
        const a = document.createElement("a");
        a.href = url;
        a.target = "_blank"
        document.body.appendChild(a);
        a.click()
        document.body.removeChild(a)
    }



    return (

        <div>
            <Dialog
                open={props.openDocuments}
                aria-abelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogContent>
                    <div className="d-flex flex-row col-12">
                        <h4 className="col-6 shift-to-party-page-title">Documents</h4>
                        <Typography className={`${classes.title} `} variant="h4" id="tableTitle" component="div">
                            <h4 className="shift-to-party-page-title px-3 pl-4">

                                <IconButton aria-label="close" className="float-right  "
                                    onClick={() => { props.handleDocuments() }}>
                                    <CloseIcon />
                                </IconButton>
                            </h4>
                        </Typography>
                    </div>
                    <div className={classes.root}>
                        <div className="col-12 mt-0">
                            <span className="mt-5"></span>
                        </div>
                        <DialogContent className="viewJustificationColor" style={{ fontSize: "12px !important" }}>
                            {viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data
                                .attachments.map((ele: any) => {
                                    return (
                                        <div>
                                            <IconButton className="exportExcel pr-2 pb-2" size="small" onClick={() => { download(ele.url) }}>
                                                <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit"
                                                />
                                            </IconButton>
                                            {ele.selectedFileName}
                                        </div>
                                    )
                                })}
                            <div>
                                <div className={`mt-3 `}>
                                </div>
                            </div>
                        </DialogContent>
                    </div>

                </DialogContent>
                <div className="row-space-start col-12 mb-2 mt-0">
                    <div className="col-12 m-0 p-0">
                        <div className="row mb-0 px-2">
                            <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="row-space-start ml-auto">
                                        <DialogActions className="mt-3 mb-1 justify-content-end">
                                            {/* <Button variant="contained"
                                                color="secondary" className="submit-reject-button"
                                                onClick={() => { }}
                                            >Reject
                                            </Button> */}
                                            <Button variant="contained" className={classes.closeButton}
                                                onClick={() => { props.handleDocuments() }}
                                            >Close
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>



        </div>
    )



}
export default Documents