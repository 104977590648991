import { apiStart, apiError, apiSuccess } from "./apiActions";
import { ORDER_SUMMARY} from "./actionsTypes";
import {createUpdateEnquiryService} from "../../services/createUpdateEnquiry.service ";

//createUpdateEnquiryService


export  function  getOrderSummary(customerTierType: any, masterId: any, value: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(ORDER_SUMMARY.GET_ORDER_SUMMARY_FETCHING));
      const response = await createUpdateEnquiryService.getOrderSummaryData(customerTierType, masterId, value);
      if (response.error) {
        dispatch(apiError(ORDER_SUMMARY.GET_ORDER_SUMMARY_ERROR, response));
        return await response || [];
      } else {
        dispatch(apiSuccess(ORDER_SUMMARY.GET_ORDER_SUMMARY_SUCCES, response));
        return response || [];
      }
    };
  }

  export  function  getOrderSummaryDetails(data: any) {
    return async (dispatch: any) => {
      dispatch(apiStart(ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_FETCHING));
      // const response = await createUpdateEnquiryService.getOrderSummaryData(data);
      if (data) {
        dispatch(apiSuccess(ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_SUCCESS, data));
        // dispatch(apiError(ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_ERROR, ));
      } else {
        dispatch(apiError(ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_ERROR, data ));
      }
    };
  }

 export  function getOfflinePayment(data:any){
   return async (dispatch:any)=>{
     dispatch(apiStart(ORDER_SUMMARY.GET_OFFLINEMODE_UPDATE_FETCHING));
     if(data.error){
       dispatch(apiError(ORDER_SUMMARY.GET_OFFLINEMODE_UPDATE_ERROR,data))
       return data || []
     }else  {
       let dataPayment:any=[]
       dataPayment.push(data)
       dispatch(apiSuccess(ORDER_SUMMARY.GET_OFFLINEMODE_UPDATE_SUCCESS,dataPayment))
       return dataPayment
     }
   }
 }