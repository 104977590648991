import { QUOTATION } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
    quotationRequest: null, openQuotationRequest: null, openQuotationRequestList: null, viewLPODetails: null
};

function requestQuoatation(state: any = initialState, action: IActionBase): IGlobal {
    switch (action.type) {
        case QUOTATION.POST_REQUEST_QUOTATION_FETCHING:
            return {
                ...state,
                quotationRequest: { loading: true, data: null, err: null }
            };
        case QUOTATION.POST_REQUEST_QUOTATION_SUCCESS:
            return {
                ...state,
                orderno: null,
                quotationRequest: { loading: false, data: action.payload.data, err: null }
            };
        case QUOTATION.POST_REQUEST_QUOTATION_ERROR:
            return {
                ...state,
                quotationRequest: { loading: false, data: null, err: action.payload }
            };

        case QUOTATION.VIEW_LPO_FETCHING:
            return {
                ...state,
                viewLPODetails: { loading: true, data: null, err: null }
            };
        case QUOTATION.VIEW_LPO_SUCCESS:
            return {
                ...state,
                orderno: null,
                viewLPODetails: { loading: false, data: action.payload.data, err: null }
            };
        case QUOTATION.VIEW_LPO_ERROR:
            return {
                ...state,
                viewLPODetails: { loading: false, data: null, err: action.payload }
            };

        case QUOTATION.POST_OPEN_QUOTATION_FETCHING:
            return {
                ...state,
                openQuotationRequest: { loading: true, data: null, err: null }
            };
        case QUOTATION.POST_OPEN_QUOTATION_SUCCESS:
            return {
                ...state,
                orderno: null,
                openQuotationRequest: { loading: false, data: action.payload.data, err: null }
            };
        case QUOTATION.POST_OPEN_QUOTATION_ERROR:
            return {
                ...state,
                openQuotationRequest: { loading: false, data: null, err: action.payload }
            };

        case QUOTATION.GET_QUOTATION_FETCHING:
            return {
                ...state,
                quotation: { loading: true, data: null, err: null }
            }
        case QUOTATION.GET_QUOTATION_SUCCESS:
            return {
                ...state,
                quotation: { loading: false, data: action.payload, err: null }
            }
        case QUOTATION.GET_QUOTATION_ERROR:
            return {
                ...state,
                quotation: { loading: false, data: null, err: action.payload }
            }

        case QUOTATION.REQUEST_ORDER_QUOTATION_FETCHING:
            return {
                ...state,
                requestOrderQuotation: { loading: true, data: null, err: null }
            }

        case QUOTATION.REQUEST_ORDER_QUOTATION_SUCCESS:
            return {
                ...state,
                requestOrderQuotation: { loading: false, data: action.payload, err: null }
            }

        case QUOTATION.REQUEST_ORDER_QUOTATION_ERROR:
            return {
                ...state,
                requestOrderQuotation: { loading: false, data: null, err: action.payload }
            }


        case QUOTATION.UPLOAD_LPO_QUOTATION_FETCHING:
            return {
                ...state,
                lpoQuotation: { loading: true, data: null, err: null }
            }
        case QUOTATION.UPLOAD_LPO_QUOTATION_SUCCESS:
            return {
                ...state,
                lpoQuotation: { loading: false, data: action.payload, err: null }
            }
        case QUOTATION.UPLOAD_LPO_QUOTATION_ERROR:
            return {
                ...state,
                lpoQuotation: { loading: false, data: null, err: action.payload }
            }

        case QUOTATION.CANCEL_QUOTATION_FETCHING:
            return {
                ...state,
                cancelQuotation: { loading: true, data: null, err: null }
            }

        case QUOTATION.CANCEL_QUOTATION_SUCCESS:
            return {
                ...state,
                cancelQuotation: { loading: true, payload: action.payload, err: null }
            }

        case QUOTATION.CANCEl_QUOTATION_ERROR:
            return {
                ...state,
                cancelQuotation: { loading: true, payload: null, err: action.payload }
            }

        case QUOTATION.GET_QUOTATION_LIST_STATUS_FETCHING:
            return {
                ...state,
                quotationListStatus: { loading: true, data: null, err: null }
            }

        case QUOTATION.GET_QUOTATION_LIST_STATUS_SUCCESS:
            return {
                ...state,
                quotationListStatus: { loading: false, data: action.payload.data, err: null }
            }

        case QUOTATION.GET_USER_FETCHING:
            return {
                ...state,
                quotationUser: { loading: true, data: null, err: null }
            }
        case QUOTATION.GET_USER_SUCCESS:
            return {
                ...state,
                quotationUser: { loading: false, data: action.payload.data, err: null }
            }
        case QUOTATION.GET_USER_ERROR:
            return {
                ...state,
                quotationUser: { loading: false, data: null, err: action.payload }
            }

        case QUOTATION.QUOTATION_DETAILS_VIEWORDER_SUCCESS:
            return {
                ...state,
                quotationDetails: { loading: false, data: action.payload.data, err: null }
            }
        case QUOTATION.UPLOAD_LPO_EMAIL_FETCHING:
            return {
                ...state,
                quotationEmail: { loading: true, data: null, err: null }
            }
        case QUOTATION.UPLOAD_LPO_EMAIL_SUCCESS:
            return {
                ...state,
                quotationEmail: { loading: false, data: action.payload.data, err: null }
            }
        case QUOTATION.UPLOAD_LPO_EMAIL_ERROR:
            return {
                ...state,
                quotationEmail: { loading: false, data: null, err: action.payload }
            }
        default:
            return state;
    }
};

export default requestQuoatation;