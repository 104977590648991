/* eslint-disable */
import React, { Fragment, Dispatch, useState } from "react";
import IconButton from '@material-ui/core/IconButton';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import NotificationIcon from "../../common/components/NotificationIcon";
import { get } from "lodash";
import XLSX from "xlsx";
import moment from "moment-timezone";
import "./ViewDeliveryDetails.css"
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito'
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        minWidth: 100
    },
    actionButton: {
        color: '#1A9FE0',
    },
}));

const columns = [
    {
        id: 'deliveryItem',
        label: 'S. No',
        align: 'left',
    },
    {
        id: 'materialNumber',
        label: 'Part No.',
        align: 'left'
    },
    {
        id: 'shortTextForSalesOrderItem',
        label: 'Description',
        align: 'left'
    },
    {
        id: 'actualQuantityDelivered',
        label: 'Quantity',
        align: 'left'
    },
    {
        id: 'baseUnitOfMeasure',
        label: 'Unit',
        align: 'left'
    },
];

const DeliveryDetails: React.FC = (props: any) => {
    const history = useHistory()
    const location = useLocation()
    const [page, setPage] = React.useState(0);
    const [selected, setSelected] = useState<any>(sessionStorage.getItem("selectedDelivery"))
    const rowsPerPage = 10;
    const classes = useStyles();
    const dispatch: Dispatch<any> = useDispatch();
    const { deliveryDetails }: any = useSelector((state: any) => state.delivery);
    const { viewDelivery, deliveryList }: any = useSelector((state: any) => state.delivery);
    const { orderId, orderDetails }: any = useSelector((state: any) => state.orderHistory);
    const tableData = get(deliveryDetails, 'data.data.deliveryItem', [{}]);
    //  const deliveryData = get(deliveryDetails, 'data.data', {});
    // let selected = sessionStorage.getItem("selectedDelivery"):any
    const showDownloadOrders = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ORDER);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
        //  dispatch(getDeliveryDetails(deliveryData.deliveryList.enquiryNumber, deliveryData.deliveryList.delivery, sessionStorage.getItem('selectedOrderMake'), newPage));
    };

    const exportData = () => {
        if (viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.length >= 1) {
            let filename = "deliveryItems.xlsx";
            let data: any = [];

            viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)] && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)].products.map((ele: any, i: any) => {
                data.push([{
                    "SI No": i + 1,
                    "Part No": ele.productId,
                    "Description": ele.productIdDescription,
                    "Quantity": ele.quantity,
                    "Unit": ele.unitOfMeasure,
                }])
            })

            // </TableCell>
            // <TableCell className={classes.bodyContent}>
            //     {ele.productId}
            // </TableCell>
            // <TableCell className={classes.bodyContent}>
            //     {ele.productIdDescription}
            // </TableCell>
            // <TableCell className={classes.bodyContent}>
            //    {ele.quantity}
            // </TableCell>
            // <TableCell className={classes.bodyContent}>
            //    {ele.unitOfMeasure}
            // </TableCell>    


            var ws = XLSX.utils.json_to_sheet(data);
            var wb = XLSX.utils.book_new();
            viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)] && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)].products.forEach((ele: any, i: any) => {
                return XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(data[i]), ele.productId)
            })
                ;
            XLSX.writeFile(wb, filename);
        }
    };

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Delivery Details
                    <Breadcrumbs>
                        <Typography color="textSecondary" className="breadcrumb-text-order-history">Order History</Typography>
                        <Typography color="textSecondary" className="breadcrumb-text-order-history">Delivery List</Typography>
                        <Typography color="textPrimary" className="breadcrumb-text-delivery-details">Delivery Details</Typography>
                    </Breadcrumbs>
                </h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>

            <div className="row mt-1">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex align-items-center">
                        <h5 className="page-sub-title">Delivery Details</h5>
                        <IconButton onClick={() => { history.push(`/viewdelivery`) }} className={`ml-auto ${classes.actionButton}`}>
                            <ChevronLeftIcon fontSize="small" />
                            <span className="backLinkLabel">Back to List</span>
                        </IconButton>
                    </div>
                </div>
                <div className="col-12">
                    <div className="cardCommon d-flex font-11">
                        <div className="col-lg-12 col-md-12 col-sm-12 p-3 flex-wrap">
                            <h6 className="title mb-3">Enquiry Details</h6>
                            <div className="row">
                                <div className="col-3 col-lg-3 col-md-6 col-sm-6">
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Delivery No:

                                        </div>
                                        <span className="subTitleValueDelivery pl-4 ">--</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Enquiry No:

                                        </div>
                                        <span className="subTitleValueDelivery pl-4">{sessionStorage.getItem("selectedOrderEnquiryNumber")?.split("").slice(0, 5).join("")}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Created On:

                                        </div>
                                        <span className="subTitleValueDelivery pl-4">{moment(new Date(viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.createdOn)).utc().format('DD MMM YYYY')}</span>
                                    </div>
                                </div>
                                <div className="col-3 col-lg-3 col-md-6 col-sm-3">
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2 ">Shipping Plan Status:
                                        </div>
                                        <span className="subTitleValueDelivery pl-3 ">{"Open Transp Planning"}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Order No:
                                        </div>
                                        <div className="pl-1" >

                                        </div>
                                        <span className="subTitleValueDelivery pl-4 ml-5">{viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.orderId}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Shipping Form:

                                        </div>
                                        <span className="subTitleValueDelivery pl-5">{"T027"}</span>
                                    </div>
                                </div>
                                <div className="col-3 col-lg-3 col-md-6 col-sm-3">
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Invoice Status:

                                        </div>
                                        <span className="subTitleValueDelivery pl-3">{"Not Invoiced"}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Invoice Date:
                                        </div>
                                        <span className="subTitleValueDelivery pl-4">{"29 July 2021"}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Picking Status:

                                        </div>
                                        <span className="subTitleValueDelivery pl-3">{"Fully Picked"}</span>
                                    </div>
                                </div>
                                <div className="col-3 col-lg-3 col-md-6 col-sm-3 ">
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Overall Delivery Status:
                                        </div>
                                        <span className="subTitleValueDelivery pl-3">{"--"}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Goods Issue Status:

                                        </div>
                                        <span className="subTitleValueDelivery pl-3 ml-4">{"Not Yet Sarted"}</span>
                                    </div>
                                    <div className="d-flex flex-row">
                                        <div className="subTitleDeliveryView mb-2">Invoice No:

                                        </div>
                                        <span className="subTitleValueDelivery pl-4 ml-5 padding-delivery">{"--"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex align-items-center">
                        <h5 className="page-sub-title">Delivery Items</h5>
                        {showDownloadOrders && (
                            <div className="exportExcel ml-auto" onClick={exportData} style={{ cursor: 'pointer' }}>
                                <IconButton className="exportExcel" size="small" >
                                    <ArrowDownwardIcon fontSize="inherit" />
                                </IconButton>
                                Export to Excel
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="cardCommon orderItems">
                        <TableContainer className={`classes.container`}>
                            <Table aria-label="table" >
                                <TableHead className="w-100">
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>S. No</TableCell>
                                        <TableCell className={classes.tableHead}>Part No.</TableCell>
                                        <TableCell className={classes.tableHead}>Description</TableCell>
                                        <TableCell className={classes.tableHead}>Quantity</TableCell>
                                        <TableCell className={classes.tableHead}>Unit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
                                        return (
                                            <TableRow>
                                                {
                                                    columns.map((column: any) => {
                                                        const value: any = row[column.id];
                                                        return (
                                                            <TableCell key={column.id} className={classes.bodyContent}>
                                                                {value}
                                                            </TableCell>
                                                        );
                                                    })
                                                }
                                             
                                            </TableRow>
                                        );
                                    })} */}
                                    {viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)] && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)].products.map((ele: any, i: any) => {
                                        return (<TableRow>

                                            <TableCell className={classes.bodyContent}>
                                                {i + 1}
                                            </TableCell>
                                            <TableCell className={classes.bodyContent}>
                                                {ele.productId}
                                            </TableCell>
                                            <TableCell className={classes.bodyContent}>
                                                {ele.productIdDescription}
                                            </TableCell>
                                            <TableCell className={classes.bodyContent}>
                                                {ele.quantity}
                                            </TableCell>
                                            <TableCell className={classes.bodyContent}>
                                                {ele.unitOfMeasure}
                                            </TableCell>
                                        </TableRow>)
                                    })}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="col-12 d-flex justify-content-end">
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)] && viewDelivery.data.parentOrder.subEnquiries[parseInt(selected)].products.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    );
};

export default DeliveryDetails;

