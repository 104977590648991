/* eslint-disable */
import React, { Fragment } from 'react';
import { useSelector } from "react-redux";
import CustomerOnboarding from './customerOnboarding';
import { NewCustomerDialog } from './newCustomerDialog';
import { signOut, useUserDispatch } from "../../context/UserContext";
import { logoutUser } from "../../store/actions/logon.actions";
import { logout } from "../../store/actions/account.actions";
import { useDispatch, } from "react-redux";
import { Dispatch, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { getCustomerType } from "../../store/actions/onboard.actions";

import { onboardingService } from '../../services/onboard.service';

const Onboarding: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [openCustomerDialog, setOpenCustomerDialog] = React.useState(true);
  const [showOnboard, showOnboarding] = React.useState(false);
  const [customerData, setCustomerData] = React.useState();
  const { dealerDetails }: any = useSelector((state: any) => state.onboard);
  const userDispatch = useUserDispatch();



  const { instance } = useMsal();


  useEffect(() => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType) {
      showOnboarding(true)
    }
  }, [dealerDetails])


  const verifySuccess = () => {
    showOnboarding(true);
  }

  const loadCustomerType = async () => {
    await dispatch(getCustomerType());
  }

  useEffect(() => {
    // if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false && !showOnboard) {
    loadCustomerType()
    // }
  }, [])



  return (
    <Fragment>
      {profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false && !showOnboard ? (
        <>
          <NewCustomerDialog setOpenCustomerDialog={setOpenCustomerDialog} openCustomerDialog={openCustomerDialog} verifySuccess={verifySuccess} />
        </>
      ) : (
        <>
          {sessionStorage.getItem('customerType') !== '' && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false ? (
            <CustomerOnboarding />
          ) : (
            <>
              <CustomerOnboarding />
            </>
          )}
        </>
      )}

    </Fragment>
  )
}

export default Onboarding;
