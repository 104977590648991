import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import ViewFavoriteInfo from './viewFavoriteInfo';
import ViewFavoriteList from './viewFavoriteList';
import IconButton from '@material-ui/core/IconButton';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';

export default function ViewFavorite(props: any) {

  const [open, setOpen] = React.useState(false);
  const [selectedFavourite, setselectedFavourite] = React.useState(false);

  const showFavorites = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.LIST_FAVORITES);

  return <>

      <span className="cursor-pointer mr-2" onClick={() => { setOpen(true) }}>
      {showFavorites &&
      <IconButton className="mr-1 searchbarOptions" size="small">
        <FavoriteBorderIcon fontSize="inherit" />
        <span className="font-size-13 load-from-fav ml-1">Load from Favorites </span>
      </IconButton>
      }
    </span>
 
    <Dialog
      open={open}
      onClose={() => { setselectedFavourite(false); setOpen(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth="md"
    >
      {!selectedFavourite ? <ViewFavoriteList setselectedFavourite={setselectedFavourite} setOpen={setOpen} /> : <ViewFavoriteInfo
        setOpen={setOpen}
        setselectedFavourite={setselectedFavourite}
        commonInsertionLogic={props.commonInsertionLogic} />}
    </Dialog>
  </>
}
