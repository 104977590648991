/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import { Tab, Tabs } from "@material-ui/core";
import Snackbar from '../../../common/components/CreatedEnquirySnackbar';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
// import { getAllBrands } from "../../store/actions/dealer.action";
// import { getDealingIn, getRegion, getTypeOfTrade } from "../../store/actions/onboard.actions";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MoveOutDialog from "../../ViewEditDealerDetailsKam/MoveOutDialog";
import FullscreenLoader from "../../../common/components/FullscreenLoader";
import AnnouncementIcon from "../../../common/components/AnnouncementIcon";
import NotificationIcon from "../../../common/components/NotificationIcon";
import DeleteDialog from "../../../common/components/deleteDialog";
import AddNewKamDealerDetails from "./DealerDetails";
import AddNewKamDocuments from "./Documents";
import AddNewKamShipToParty from "./ShipToParty";
import AddNewDealerKamReview from "./KamReview";
import AddNewKamAccess from "./Access";
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import { AddShipToPartyAddress, } from '../../../store/actions/onboard.actions';
import { AddNewAddressDailog } from '../../newUserOnboard/AddNewAddressDailog';
import { get } from 'lodash';
import { useRef } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';



const useStyles = makeStyles(() => ({
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const AddNewDealerKam: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { allDealers }: any = useSelector((state: any) => state.dealer);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [newValue, setNewValue] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const history = useHistory();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [open, setOpen] = React.useState(false);
  const [apiResponseText, setApiResponseText] = React.useState(false);
  const [deliveryType, setDeliveryType] = React.useState<string>('DLV');
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [moveOutDialog, setmoveOutDialog] = React.useState(false);
  const [sapId, setSapId] = React.useState<any>("");
  const [editedAddress, setEditAddress] = React.useState({});
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [isOutsideFreezone, setIsOutsideFreezone] = React.useState(true);
  const [isIntBuisness, setIsIntBuisness] = React.useState(false);
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("");
  const [salesRegion, setSalesRegion] = React.useState("");
  // const [dealerDetails, setDealerDetails] = React.useState<any>();
  const { shipToPartyAddressData, dealerDetails }: any = useSelector((state: any) => state.onboard);
  // const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const { selectedNewDealer, generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  const { regionData, }: any = useSelector((state: any) => state.onboard);
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";
  const addressDialogRef: any = useRef();
  const classes = useStyles();
  const customerTypeAccess: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.customerTypeAccess) || "";
  const [customerAcceessForIB, setCustomerAcceessForIB] = React.useState(false);
  const customerFirstName = sessionStorage.getItem('customerFirstName');
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);

  useEffect(() => {
    if (customerTypeAccess && customerTypeAccess.length > 0) {
      if (customerTypeAccess.includes("intbusiness")) {
        setCustomerAcceessForIB(true);
      } else {
        setCustomerAcceessForIB(false);
      }
    }
  }, [customerTypeAccess])

  const handleClose = () => {
    setOpen(false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };
  const handleFreezoneValue = (value: any) => {
    setIsOutsideFreezone(value.isCustomerAcceptingDelivery)
    setIsIntBuisness(value.isIntBuisness)
    setIsDefaultUAE(value.isDefaultUAE)
    setSalesRegion(value.salesRegion)
  }
  const handleopenCloseMoveOut = (open?: any) => {
    setmoveOutDialog(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };


  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active') {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setMasterCompanyBrands(shipToPartyAddressData.data.masterCompanyBrands);
      setDeliveryType(shipToPartyAddressData.data.deliveryType ? shipToPartyAddressData.data.deliveryType : "DLV")
      setSapId(shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {

    }
  }, [shipToPartyAddressData])


  const getNewAddressData = async (data: any) => {

    let result: any = await dispatch(AddShipToPartyAddress(b2bUserId, data));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    } else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
      handleopenClose(false);
      setOpenErrorSnackbar(true);
      setSuccessMessage(apiResponse);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }
    // setSelected("");

    // let result = await dispatch(AddShipToPartyAddress(b2bUserId, data));
    // const apiResponse = get(result, 'message', {});
    // if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
    //   setOpenSnackbar(true);
    //   setApiResponseText(apiResponse.message);
    //   setTimeout(() => {
    //     setOpenSnackbar(false);
    //   }, 2000);
    // }

    // setSelected("");
  }

  return (
    <Fragment>
      {false && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title"> Add New Customer  </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={successMessage} />
      <div className="row">
        <div className="col-md-12 mt-2 mb-2">
          <div className="d-flex align-items-center mb-2">
            <h6 className="dealer-table-content mb-0">Please fill in all of customer details</h6>
            <div className="dealer-table-content mb-0 px-2"><h6 className="page-sub-title mb-0">{userType === "FSE" && sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? ("New Workshop") : userType === "FSE" && sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" ? ("New Fleet") : userType === "FSE" && sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" ? ("New Government") : userType === "FSE" && customerAcceessForIB && sessionStorage.getItem('customerType') === 'new' ? ("New Int. Business") : sessionStorage.getItem('customerType') === 'existing' ? customerFirstName : ("New Dealer")}</h6></div>
            <div className="row-space-start ml-auto sm">
              <div className="row-space-start ml-auto">
                {((selectedTab == 2 && isDefaultUAE === "AE" && sessionStorage.getItem('customerOnboardingType') === "Int. Business") || (selectedTab == 2 && sessionStorage.getItem('customerOnboardingType') !== "Int. Business")) && <Button
                  onClick={() => {
                    setOpen(true)
                  }}
                  className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                  disabled={isDisabled() ? true : false}
                  startIcon={<AddIcon />}
                ><span className="onshiftoparty-font">
                    Add New Address
                  </span>
                </Button>}
              </div>
              <Button variant="contained" color="primary" onClick={(e) => {
                e.preventDefault();
                if (dataUpdated) {
                  handleopenCloseMoveOut(true)
                } else {
                  sessionStorage.setItem('dealerDetailsSaved', "");
                  history.push('/dealers');
                }
              }} className="blueActionButton border-left rounded-pill">Back to customer list</Button>
            </div>
          </div>
        </div>
      </div>
      <Grid container className="marginScrollableArea">
        <Grid item xs={12} sm={12}>
          <ThemeProvider theme={theme}>
            <Paper square>
              <div className="border-bottom">
                <Tabs
                  className="tabSelect"
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  style={{ pointerEvents: 'none' }}
                // onChange={(event, newValue) => {
                //   if (!dataUpdated) {
                //     setselectedTab(newValue);
                //   } else {
                //     setNewValue(newValue);
                //     handleopenClose(true);
                //   }
                // }}
                >
                  <Tab label="Customer Details" value={0} {...a11yProps('DealerList')} />
                  <Tab label="Documents" value={1} {...a11yProps('Documents.')} />
                  <Tab label="Shipping Address" value={2} {...a11yProps('ShipToParty')} />
                  {(userType !== "FSE" && !customerAcceessForIB) || userType === "KAM" && (
                    <>
                      <Tab label="KAM Review" value={3} {...a11yProps('KamReview')} />
                      <Tab label="Access" value={4} {...a11yProps('Access')} />
                    </>
                  )}
                </Tabs>
              </div>
              <div className="d-block w-100">
                {selectedTab == 0 && <AddNewKamDealerDetails setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 1 && <AddNewKamDocuments setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 2 && <AddNewKamShipToParty setDataUpdated={setDataUpdated} setTabValue={setselectedTab} freeZoneProps={handleFreezoneValue} />}
                {selectedTab == 3 && userType !== "FSE" && <AddNewDealerKamReview setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 4 && userType !== "FSE" && <AddNewKamAccess setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
              </div>
            </Paper>
          </ThemeProvider>
        </Grid>
      </Grid>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data === 'delete') { setselectedTab(newValue); handleopenClose(false); setDataUpdated(false); }
          if (!data) { handleopenClose(false); }
        }} />
      <MoveOutDialog
        open={moveOutDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data) {
            history.push('/dealers');
            handleopenCloseMoveOut(false);
          }
          if (!data) { handleopenCloseMoveOut(false); }
        }} />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>

      <Dialog
        open={open}
        onClose={(event, reason) => {
          // if (reason !== 'backdropClick') {
          handleClose()
          // }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog firstAddressCheck={firstAddressCheck} addDealer={true} setOpenDialog={handleClose} deliveryType={deliveryType} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={false} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} ref={addressDialogRef} isOutsideFreezone={isOutsideFreezone}
          isIntBuisness={isIntBuisness} isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
      </Dialog>
    </Fragment >
  );
};

export default AddNewDealerKam;
