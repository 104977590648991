/* eslint-disable */
import React, { Fragment, useEffect } from "react";
import Button from '@material-ui/core/Button';
import { Markup } from 'interweave';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgb(108 197 229 / 14%);',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  bodyText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

export default function TermsAndConditionsLandingPageDialog(props: any) {
  const [footerArrayData, setFooterArrayData] = React.useState<any>();
  const [expanded, setExpanded] = React.useState<any | true>();
  const [isSelected, setSelected] = React.useState<any>([])
  const classes = useStyles();

  useEffect(() => {
    let data = props && props.propsData && props.propsData.footer.filter((item: any) => item.name === "terms-and-conditions");
    setFooterArrayData(data)
  }, [props]);

  const handleChange = (panel: any) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded);
  };

  React.useEffect(() => {
    let data: any = []
    footerArrayData && footerArrayData.forEach((ele: any) => {
      data.push(true)
    })
    setSelected(data)
  }, [footerArrayData])


  React.useEffect(() => {
    setExpanded(true);
  }, []);

  const handleClose = () => {
    let data: any = []
    footerArrayData && footerArrayData.forEach((ele: any) => {
      data.push(true)
    })
    setSelected(data)
  }

  return (
    <Fragment>
      <DialogContent>
        <Grid item xs={12}>
          <div>
            <DialogContentText id="alert-dialog-description">
              {footerArrayData && footerArrayData.map((data: any, i: any) => (
                <Accordion square expanded={expanded === true && isSelected[i] === true} onChange={handleChange(!isSelected[i])}>
                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                    onClick={() => {
                      let data: any = [...isSelected]
                      let dataActual: any = []
                      data.forEach((ele: any, i2: any) => {
                        if (i === i2) {
                          data[i2] = !data[i]
                        } else if (i !== i2) {
                          data[i2] = false
                        }
                      })
                      setSelected(data)
                    }}
                  >
                    <span className="" style={{ fontSize: "18px" }}>{isSelected[i] ? "-" : "+"}</span>  <h6 className="ml-2 font-weight-600"> Terms and Conditions</h6>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={`mt-3 ${classes.bodyText}`}>
                      <Markup content={data.content} />
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </DialogContentText>
          </div>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button className={`mr-4 ${classes.cancel}`} onClick={() => { props.handleClosePopup(); setExpanded(true); handleClose(); }}>
          Close
        </Button>
      </DialogActions>
    </Fragment>
    // <Fragment>
    //   {(footerArrayData && footerArrayData.content === "") && (
    //     <div className="col-12 mt-4">
    //       <Alert severity="info"> Terms And Conditions is not avaliable !</Alert>
    //     </div>
    //   )}
    //   <div className="col-12 row m-0 p-5">
    //     <div className="col-12 mt-2 mr-2">
    //       <div className="col-12 mt-2 ml-2 d-flex justify-content-center">
    //         <Markup content={footerArrayData && footerArrayData.content} />
    //       </div>
    //     </div>
    //     <div className="col-12 mt-4">
    //       <div className="col-12 d-flex justify-content-center">
    //         <Button className={`blueActionButton border-left rounded-pill mt-3 mb-2`} onClick={() => { window.location.reload(); }}> Close
    //         </Button>
    //       </div>
    //     </div>
    //   </div>
    // </Fragment>
  );
}
