/* eslint-disable */
import React, { Dispatch, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button, IconButton, DialogTitle } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useHistory } from "react-router-dom";
import { makeStyles, createTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CloseIcon from '@material-ui/icons/Close';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { getDetailsBySapId, getGenrateOTP, getVerifyOTP, setCustomerType, setCustomerTier } from "../../store/actions/onboard.actions";
import { get } from "lodash";
import { useMsal } from "@azure/msal-react";
import { logoutUser } from "../../store/actions/logon.actions";
import { logout } from "../../store/actions/account.actions";
import { signOut, useUserDispatch } from "../../context/UserContext";
import { OnboardingEmailVarificationValidation } from "../../constants/messages";
import "./newCustomerDialog.css"
import WarningIcon from "@material-ui/icons/Warning";
import { onboardingService } from "../../services/onboard.service";

const useStyles = makeStyles((theme) => ({
  icon: {
    float: "left",
    // margin: "0 10px px 0",
    height: "100%",
    fill: "white",
  },
  iconButton: {
    padding: 10,
  },
  goToLogin: {
    backgroundColor: '#EAEAEA',
    width: 125,
    fontWeight: 800,
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  correctionText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  close: {
    color: '#000000',
    fontWeight: 900
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export function NewCustomerDialog(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [customerNumber, setCustomerNumber] = React.useState<any>();
  const [customerNumberError, setCustomerNumberError] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [notSelected, setNotSelected] = React.useState<any>(false);
  const [errorMessage, setErrorMessage] = React.useState<any>();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [customerVerifyOTP, setCustomerVerifyOTP] = React.useState<any>();
  const [customerVerifyOTPError, setCustomerVerifyOTPError] = React.useState<boolean>(false);
  const [selected, setSelected] = React.useState<any>('new');
  const [selectedTier, setSelectedTier] = React.useState<any>("Dealer")
  const [selectedSubType, setSelectedSubType] = React.useState<any>();
  const [selectedGroupCode, setSelectedGroupCode] = React.useState<any>('');
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { verifyOtp, generateOTP, customerType, customerTypeData }: any = useSelector((state: any) => state.onboard);
  const { openCustomerDialog, verifySuccess } = props;
  const [verify, setVerify] = React.useState<any>(false);
  const [openConfirmation, setOpenConfirmation] = React.useState<any>(false);
  const [error, setError] = React.useState<any>();
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const userDispatch = useUserDispatch();
  const history = useHistory();



  const handleChangeTier = (e: any) => {
    // setNotSelected(false);
    setCustomerNumber(null);
    setError(null);
    setSelectedSubType(null);
    sessionStorage.setItem('customerOnboardingType', e.target.value);
    let value: any = e.target.value
    if (value === "Dealer" || value === "Workshop") {
      let data = customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.filter((ele: any) => ele.value === e.target.value)
      setSelectedGroupCode(data && data[0] && data[0].subType && data[0].subType[0] && data[0].subType[0].groupCode)
    } else {
      setSelectedGroupCode("")
    }
    // setSelected(e.target.value);
    setSelectedTier(e.target.value);
  }

  const handleChangeSubTask = (e: any, groupCode: any) => {
    setCustomerNumber(null);
    setError(null);
    setSelectedGroupCode(groupCode)
    setSelectedSubType(e.target.value);
  }

  const handleChange = (event: any) => {
    // setSelectedSubType(null); setSelectedGroupCode(null); setSelectedTier("Dealer"); 
    setSelected(event.target.value);
    setCustomerNumber(null);
    setNotSelected(false);
    setError(null);
    setSelectedGroupCode("")
    dispatch(setCustomerType(event.target.value));
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCustomerChange = (e: any) => {
    setCustomerNumber(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setCustomerNumberError(true);
      } else if (validateCustomerNumber(e.target.value) === false) {
        setCustomerNumberError(true);
      }
      else {
        setCustomerNumberError(false);
      }
    }
  }

  function validateCustomerNumber(number: any) {
    const re = /^[0-9]+$/;
    return re.test(number);
  }

  function validateVarificationCode(number: any) {
    const re = /^\d{6}$/;
    return re.test(number);
  }

  const handleCustomerVerifyOTPChange = (e: any) => {
    setCustomerVerifyOTP(e.target.value && e.target.value.length ? e.target.value : "");
    if (e.target.value === "" || e.target.value.trim() === "") {
      if (!validateVarificationCode(customerVerifyOTP)) {
        setCustomerVerifyOTPError(true);
      }
      else {
        setCustomerVerifyOTPError(false);
      }
    }
  }

  useEffect(() => {
    if (generateOTP && generateOTP.data) {
      setError(null);
      setVerify(true);
    } else if (generateOTP && generateOTP.err) {
      setError(generateOTP.err.error);
    }
  }, [generateOTP])


  const validateSubtype = () => {
    if (selectedTier !== "Dealer" && selectedTier !== "Workshop") {
      if (selectedGroupCode === "" || selectedGroupCode === null) {
        setErrorMessage("Please select at least one sub type");
        handleopenCloseErrorSnackbar(true);
      } else {
        handleopenCloseErrorSnackbar(false);
        setOpenConfirmation(true);
      }
    } else {
      setOpenConfirmation(true);
    }
  };


  const handleSubmit = async () => {
    dispatch(setCustomerTier(selectedTier));
    if (selected === 'new') {
      dispatch(setCustomerType('new'));
      sessionStorage.setItem('customerOnboardingType', selectedTier);
      let data: any = {
        "b2bUserId": b2bUserId,
        "customerType": selectedTier,
        "isNewCustomer": true,
        "groupCode": selectedGroupCode,
      }
      sessionStorage.setItem('groupCode', selectedGroupCode);
      await onboardingService.updateSelectedCustomerType(data);
      setOpen(false);
      verifySuccess();
      sessionStorage.setItem('sapCustomerId', '');
      sessionStorage.setItem('customerType', 'new');

    } else {
      if (customerNumber) {
        // let appendedString: any = '';
        // if (appendedString.length < 10) {
        //   let newString = '0';
        //   let customerLength = 10 - appendedString.length;
        //   for (let i = 0; i < customerLength; i++) {
        //     newString += '0'
        //   }
        //   appendedString = newString + customerNumber;
        // }
        // setCustomerNumber(appendedString);
        dispatch(setCustomerType('existing'));
        sessionStorage.setItem('customerType', 'existing');
        let number = customerNumber.length <= 9 && customerNumber[0] !== "0" ? "0" + customerNumber : customerNumber
        setCustomerNumber(number)
        let data: any = dispatch(getGenrateOTP(number, b2bUserId))  // comment this line for skip generate otp
        // if (data && data.data && data.data.message && data.data.message.status === 'S') {
        setVerify(true) // comment this line for skip generate otp
        setOpenConfirmation(false) // comment this line for skip generate otp
        // }

        // uncomment these all line for skip generate otp
        // dispatch(getDetailsBySapId(customerNumber));
        // sessionStorage.setItem('sapCustomerId', customerNumber);
        // verifySuccess();
        // setErrorMessage(null);
        // handleopenCloseSnackbar(true);
        // setOpen(false);
        // setCustomerNumber(null);
        // setCustomerVerifyOTP(null);
        // remove end

        setCustomerNumberError(false);
      } else {
        setCustomerNumberError(true);
      }

    }
  }




  const resendVerificationLink = () => {
    dispatch(getGenrateOTP(customerNumber, b2bUserId));
    setCustomerVerifyOTP("");
  }


  async function handleVerifyOTP() {
    if (customerVerifyOTP) {
      let verifyData: any = await dispatch(getVerifyOTP(customerNumber, customerVerifyOTP, b2bUserId));
      if (verifyData && verifyData.message && verifyData.message.status === 'S') {
        handleopenCloseSnackbar(true);
        setSuccessMessage('OTP verified successfully');
        let data: any = await dispatch(getDetailsBySapId(customerNumber));
        if (data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.organizationIds) {
          let res = data.data.customerInformation.organizationIds.filter((ele: any) => {
            return ele.organizationId === "2195"
          })
          if (selectedTier.toLowerCase() === 'workshop') {
            if (res.length >= 1) {
              sessionStorage.setItem('sapCustomerId', customerNumber);
              verifySuccess();
              setErrorMessage(null);
              handleopenCloseSnackbar(true)
              setOpen(false);
              setCustomerNumber(null);
              setCustomerVerifyOTP(null);
            } else {
              setErrorMessage("Tradepoint brand is required for existing workshop users")
              // setOpenErrorSnackbar(true)
            }
          } else if (selectedTier.toLowerCase() === 'dealer') {
            // if (res.length >= 1) {
            //   setErrorMessage("OES brand (2195) is detected. Kindly connect with your KAM.")
            // } else {
            sessionStorage.setItem('sapCustomerId', customerNumber);
            verifySuccess();
            setErrorMessage(null);
            handleopenCloseSnackbar(true);
            setOpen(false);
            setCustomerNumber(null);
            setCustomerVerifyOTP(null);
            // }
          } else if (selectedTier.toLowerCase() === "government") {
            // if (res.length >= 1) {
            //   setErrorMessage("OES brand (2195) is detected. Kindly connect with your KAM.")
            // } else {
            sessionStorage.setItem('sapCustomerId', customerNumber);
            verifySuccess();
            setErrorMessage(null);
            handleopenCloseSnackbar(true);
            setOpen(false);
            setCustomerNumber(null);
            setCustomerVerifyOTP(null);
            // }
          } else if (selectedTier.toLowerCase() === "fleet") {
            // if (res.length >= 1) {
            //   setErrorMessage("OES brand (2195) is detected. Kindly connect with your KAM.")
            // } else {
            sessionStorage.setItem('sapCustomerId', customerNumber);
            verifySuccess();
            setErrorMessage(null);
            handleopenCloseSnackbar(true);
            setOpen(false);
            setCustomerNumber(null);
            setCustomerVerifyOTP(null);
            // }
          }
        }
      }
      else {
        setErrorMessage(verifyData.error);
      }
      setCustomerVerifyOTPError(false);
    } else {
      setCustomerVerifyOTPError(true);
    }
  }

  useEffect(() => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.isExistingUser === false && !customerType) {
      setOpen(true);
    }
  }, [profileDetails, customerType])

  useEffect(() => {
    if (openCustomerDialog) {
      setVerify(false);
      handleopenCloseSnackbar(false);
      setOpen(true);
    }
  }, [openCustomerDialog])

  const classes = useStyles();
  const { instance } = useMsal();

  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: "/" });
  }

  async function handleDetials() {
    if (selected === 'new') {
      if (selectedTier === "Dealer" || selectedTier === "Workshop") {
        let value = customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.filter((ele: any) => ele.value === selectedTier)
        setSelectedGroupCode(value && value[0] && value[0].subType && value[0].subType[0] && value[0].subType[0].groupCode)
        validateSubtype();
      } else {
        validateSubtype();
      }
    } else {
      if (customerNumber) {
        let number = customerNumber.length <= 9 && customerNumber[0] !== "0" ? "0" + customerNumber : customerNumber
        setCustomerNumber(number)
        let data: any = await dispatch(getDetailsBySapId(number));
        let groupCode = data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.customerGroupCode
        let customerType = data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.customerType
        setSelectedTier(customerType)
        sessionStorage.setItem('customerOnboardingType', customerType);
        if (data && data.data && data.data && data.data.customerInformation && data.data.customerInformation.customerGroupCode) {
          setSelected(customerType ? customerType.toLowerCase() : selected
          )
          let data: any = {
            "b2bUserId": b2bUserId,
            "customerType": customerType,
            "isNewCustomer": false,
            "groupCode": groupCode,
          }
          let updateCustomer: any = await onboardingService.updateSelectedCustomerType(data);
          if (updateCustomer && updateCustomer.message && updateCustomer.message && updateCustomer.message.status === "S") {
            handleSubmit();
            // setOpenConfirmation(true);
          } else {
            setError(updateCustomer && updateCustomer.message && updateCustomer.message && updateCustomer.message)
          }
        }
        else {
          setError(data && data.message)
        }
      } else {
        setError("Customer Number is empty")

      }
    }
  }

  return (
    <Dialog
      open={open ? true : false}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          setOpen(false)
        }
      }}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth={true}
      disableEscapeKeyDown
      maxWidth="xs"
      className="typeDialogParent">
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <DialogTitle>
        {!verify && !openConfirmation && (
          <div className="justifyContentHorizontalSpacebetween align-items-center">
            <h4 className="page-title ml-4 mt-2">Please make the selection </h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
        {verify && !openConfirmation && (
          <div className="justifyContentHorizontalSpacebetween align-items-center">
            <h4 className="page-title mt-2 mb-0">Verify Your Email</h4>
            {isExistingUser && (
              <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); setCustomerVerifyOTP(""); }}>
                <CloseIcon fontSize="inherit" className={classes.close} />
              </IconButton>
            )}
          </div>
        )}
      </DialogTitle>
      {openConfirmation && (
        <div className="justifyContentHorizontalSpacebetween align-items-center">
          <h4 className="page-title px-3 ml-2">Confirmation</h4>
          {isExistingUser && (
            <IconButton onClick={() => { setOpen(false); props.setOpenCustomerDialog(false); setSelectedSubType(null); setSelectedGroupCode(null); setSelectedTier("Dealer"); }}>
              <CloseIcon fontSize="inherit" className={classes.close} />
            </IconButton>
          )}
        </div>
      )}
      <DialogContent>
        {!verify && !openConfirmation && (
          <div>
            <FormControl component="fieldset" name="customer-type" className="mt-4">
              <h6 className="page-title ml-4" style={{ fontSize: "15px" }}>Select Customer Type</h6>
              <RadioGroup className="mt-2 ml-4" row onChange={handleChange} value={selected}>
                <FormControlLabel
                  value="new"
                  control={<Radio color="primary" />}
                  label="New Customer"
                />
                <FormControlLabel
                  value="existing"
                  control={<Radio color="primary" />}
                  label="Existing Al Futtaim Customer"
                  className="ml-2"
                  style={{ fontWeight: "bold" }}
                />
              </RadioGroup>
            </FormControl>
          </div>
        )}
        {!verify && !openConfirmation && (
          <div>
            {selected && selected !== '' && selected === 'new' && (
              <FormControl component="fieldset" name="customer-tier" className="mt-2">
                <h1 className="page-title ml-4" style={{ fontSize: "15px" }}>You want to be ?</h1>
                <RadioGroup className="mt-2 ml-4" row onChange={(e: any) => { handleChangeTier(e) }} value={selectedTier} >
                  {customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.map((type: any) => (
                    <>
                      <>
                        <FormControlLabel
                          value={type.value}
                          control={<Radio color="primary" />}
                          label={type.Name}
                          className="px-0 col-12 mb-0"
                        ></FormControlLabel>
                        <span className="ml-2 row"><p className="ml-4 row font-customer-type-subheader">{type.description}</p></span>
                        {type && type.subType && type.subType.map((ele: any, i: any) => {
                          return <>
                            {selectedTier === type.value && type.value !== "Dealer" && type.value !== "Workshop" ?
                              <FormControl component="fieldset" name="customer-tier-type" className="mt-0 mb-0 col-12">
                                <RadioGroup className="mt-0 mb-0" row onChange={((e: any) => { handleChangeSubTask(e, ele.groupCode) })} value={selectedSubType} >
                                  <FormControlLabel
                                    value={ele.name}
                                    control={<Radio color="primary" />}
                                    label={ele.name}
                                    className="ml-3"
                                  ></FormControlLabel>
                                </RadioGroup>
                              </FormControl>
                              : ""}
                          </>
                        })}
                      </>
                      <>
                      </>
                    </>
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            {selected === 'existing' && (
              <TextField id="customer Number"
                value={customerNumber}
                error={customerNumberError}
                onChange={(e: any) => handleCustomerChange(e)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo"
                type="number"
                label="Al Futtaim Customer Number" required variant="outlined" margin="dense" />
            )}
            {error && (
              <p className="d-flex text-red text-center justify-content-center mt-3">{error}</p>
            )}
          </div>
        )}
      </DialogContent>
      <DialogContent>
        {verify && !openConfirmation && (
          <div className="col-12">
            <div className="d-flex flex-row">
              <IconButton className="p-0 m-1 toast-header" edge="end" aria-label="add" onClick={() => { setVerify(false); setErrorMessage(null); setCustomerVerifyOTP(""); setCustomerNumberError(false); setError(null); setSelectedTier("Dealer"); setSelected('new'); }}>
                <ChevronLeftIcon fontSize="medium" />
                <span className="info-sub-header-document m-2 mt-2">Back</span>
              </IconButton>
              <div className="d-flex flex-row">
                <h6 className="customerDialog pt-1 pb-2 mt-2"> | </h6>
              </div>
              <div className="d-flex flex ml-2">
                <h6 className="document-content pt-1 pb-2 mt-2">UAE-AFG-0001 </h6>
              </div>
            </div>
            <h6 className="document-sub-content mb-1 mt-2">Please enter 6 digit code which we have sent to  your registered email {generateOTP && generateOTP.data && generateOTP.data.email}</h6>
            <div className="">
              <TextField id="customer Number"
                value={customerVerifyOTP}
                error={customerVerifyOTPError}
                helperText={customerVerifyOTPError &&
                  <span style={{ color: 'red' }}>{OnboardingEmailVarificationValidation.EMAIL_VALIDATION_TEXT}</span>
                }
                onChange={(e: any) => handleCustomerVerifyOTPChange(e)}
                type="text"
                inputProps={{ maxLength: 6, minLength: 0 }}
                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo"
                label="Enter Code" required variant="outlined" margin="dense" />
            </div>
            <div className="d-flex align-items-center">
              <h5 className="document-sub-content">Haven't received ?</h5>
              <div className="row-space-start ml-auto">
                <span className="bottom-link p-3 d-block info-sub-header-document cursor-pointer"
                  onClick={() => { resendVerificationLink(); setCustomerVerifyOTP(""); }}>Resend Verification Code</span>
              </div>
            </div>
            <h6 className="document-sub-content pt-1 pb-2">Incase of any issues, please contact your assigned KAM</h6>
            {errorMessage && (
              <p className="d-flex text-red text-center justify-content-center mt-3">{errorMessage}</p>
            )}
          </div>
        )}
      </DialogContent>

      {openConfirmation && (
        <>
          <div className="mb-4">
            <div className={`row ml-2 w-100 px-3  ${classes.icon}`}>
              <WarningIcon className="delete-warning-icon-customer" fontSize="small" />
              <h6 className={`d-flex flex-row ml-3 mt-1 ${classes.correctionText}`}>Are you sure you want to register as a -<p className="row confirmation-type-subheader ">{`(${selectedTier}) ${selectedSubType ? '' : '?'}`}<br /> {selectedSubType ? `- (${selectedSubType}) ?` : ''}</p></h6>
              {/* <h6 className="document-sub-content pl-4 pb-2 mt-2">Note -</h6><h6 className="document-sub-content-sub mt-2 ml-1">You will be able to change this later too.</h6> */}
            </div><br />
            <div className="row">
              <div className="d-flex flex mb-3">
                <h6 className="document-sub-content px-5 pb-2 mt-2"></h6><h6 className="document-sub-content-sub mt-2 ml-0">Note - You will be able to change this later too.</h6>
              </div>
            </div>
          </div>
        </>
      )}
      {/* <span className="document-sub-content mb-3 mt-4">Please enter 6 digit code which we have sent to  your registered email {generateOTP && generateOTP.data && generateOTP.data.email}</span> */}
      {notSelected && <div className="ml-4 pl-1 mt-2 payment-validation">Please make the selection </div>}
      <DialogActions className="mb-4 justify-content-end">
        {!verify && !openConfirmation && (
          <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-2"
            onClick={() => {
              // if (selected === "" || selectedTier === "" || selected != "existing" && selected != "new") {
              //   setNotSelected(true);
              // } else if (selected !== "" || selectedTier !== "" || selected == "existing" || selected == "new") {
              // handleSubmit();
              //   setNotSelected(false);
              // }
              // validateSubtype();
              handleDetials();
            }}> Submit
          </Button>
        )}
        {verify && !openConfirmation && (
          <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-3" onClick={() => { handleVerifyOTP() }}>
            Verify
          </Button>
        )}
        {openConfirmation && (
          <>
            <Button variant="contained" color="primary" className="rounded-pill font-size-11 mr-3"
              onClick={() => { handleSubmit(); setErrorMessage(""); }}>
              Yes, Continue
            </Button>
            <Button variant="contained" className={classes.closeButton}
              onClick={() => { setOpenConfirmation(false); setSelectedSubType(null); setSelectedGroupCode(null); setSelectedTier("Dealer"); setSelected('new'); setCustomerNumberError(false); setError(null); }}
            >Cancel
            </Button>
          </>
        )}
        {!isExistingUser && !openConfirmation && (
          <Button variant="contained" className={classes.goToLogin}
            onClick={() => { callLogout(); setCustomerVerifyOTP(""); }}
          >Go to Login</Button>
        )}
      </DialogActions>
    </Dialog >
  );
}
