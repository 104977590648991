/* eslint-disable */
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { Button } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@material-ui/core";
import { cancelQuotation, getFSEQuotation } from '../../store/actions/quotation.action';
import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import _, { get } from "lodash";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { getQuotation } from "../../store/actions/quotation.action";


const CancelQuotation = (props: any) => {
  const { quotationNumber, sapId, enquiryNumber, status, value, myQuotation, user, page, handleCloseViewQuotation } = props;
  const [selected, setSelected] = React.useState("");
  const [reason, setReason] = React.useState("")
  const [openSnackbar, setOpenSnackBar] = React.useState<any>(false)
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
  const [successMessage, setSuccessMessage] = React.useState("")
  const [validateError, setValidateError] = React.useState(false);
  const dispatch: any = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  let sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  if (sessionStorage.getItem('userType') === 'FSE') {
    sapCustomerId = sapId;
  } else {
    sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  }
  const handleChange = (e: any) => {
    setSelected(e.target.value)
  }

  const handleCancel = async () => {
    let res = await dispatch(cancelQuotation(
      {
        "masterEnquiryId": enquiryNumber,
        "quotationNumber": quotationNumber,
        "cancelationReason": selected !== "Other" ? selected : reason,
        "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        "sapCustomerId": sapCustomerId,
      }
    ))

    if (res && res.error) {
      setOpenErrorSnackbar(true);
      setSuccessMessage(res.error);
      setOpenSnackBar(true);
    } else {
      props.handleCancelQuotation()
      setSuccessMessage(res && res.message && res.message.message);
      setOpenSnackBar(true);
      setOpenErrorSnackbar(false);

      if (sessionStorage.getItem('userType') === 'FSE') {
        let payload = {
          "userRole": "FSE",
          "loginUserId": sessionStorage.getItem('webApplicationLoginId'),
          "openQuotation": false,
          "isDateFilterApply": false,
          "fromDate": "",
          "toDate": "",
          "filterByStatus": "",
          "searchBy": "",
          "filterByCustomer": [],
          "filterByInterUserIds": [sessionStorage.getItem('webApplicationLoginId')]
        }
        dispatch(getFSEQuotation(payload, page))
      } else {
        let quotation = {
          "filterBy": status === "All" ? "" : status,
          "searchBy": value,
          "myQuotation": myQuotation || user ? true : false,
          "webApplicationLoginId": user ? user : sessionStorage.getItem('webApplicationLoginId'),
          "sapCustomerId": sapCustomerId,
        }
        dispatch(getQuotation(quotation, page))
      }
      setSelected("");
      setReason("")
      handleCloseViewQuotation()
    }
  }

  const handleReason = (e: any) => {
    setReason(e.target.value)
    if (e.target.value.split(" ").join("").replace(/\n/g, "").length <= 500) {
      setValidateError(false)
    } else if (e.target.value.split(" ").join("").replace(/\n/g, "").length > 500) {
      setValidateError(true)
    }
  }

  const handleopenCloseSnackbar = (open: any) => {
    setOpenSnackBar(open ? true : false);
  }



  return (
    <div>
      <Snackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Dialog fullWidth={true} maxWidth="sm" open={props.openCancelQuotation ? true : false} onClose={() => { props.handleCloseQuotation() }} aria-labelledby="form-dialog-title">
        <DialogContent>
          <div className="d-flex justifyContentHorizontalSpacebetween mb-2">
            <h4 className="page-title mt-4">Are you sure want to cancel the quotation? </h4>

            <span className="cursor-pointer">
              <IconButton className="mr-1" size="small">
                {/* <IconButton aria-label="close" className="float-right" >
                                        <CloseIcon />
                                    </IconButton> */}
              </IconButton>
            </span>

          </div>
          {/* <div className="mt-4 mb-5 pb-5">
                       <h3>Are you sure want to cancel the quotation ?</h3>

                    </div> */}

          <FormControl component="fieldset" name="customer-type" className="mt-4">
            <h6 className="page-title" style={{ fontSize: "15px" }}>Select Reason</h6>
            <div className="d-flex flex-column">
              <RadioGroup className="mt-2 " onChange={handleChange} value={selected}>
                <FormControlLabel
                  value="Price is too high"
                  control={<Radio color="primary" />}
                  label="Price is too high"
                />
                <FormControlLabel
                  value="Stock not available"
                  control={<Radio color="primary" />}
                  label="Stock not available"
                  className=""
                  style={{ fontWeight: "bold" }}
                />
                <FormControlLabel
                  value="No longer required"
                  control={<Radio color="primary" />}
                  label="No longer required"
                  className=""
                  style={{ fontWeight: "bold" }}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio color="primary" />}
                  label="Other"
                  className=""
                  style={{ fontWeight: "bold" }}
                />
              </RadioGroup>
              {selected === "Other" && <TextareaAutosize
                value={reason}
                placeholder='enter the reason'
                minRows={4}
                aria-label="minimum height"
                className="cancel-text p-4 ml-1"
                id="12"
                onChange={handleReason}
              />}
              {validateError && selected === "Other" && <div className="d-flex font-weight-bold  pl-0 ml-1 mt-2 text-danger">Reason Should Not Exceed 500 Characters</div>}
            </div>
          </FormControl>

        </DialogContent>


        <DialogActions className="mt-2 mb-4 justify-content-end">
          <Button variant="contained" className={`submit-button font-size-11 mr-3 ${(!selected || selected === "Other" && !reason || validateError) && "cancel-button-disabled"}`} onClick={handleCancel}>
            Yes
          </Button>
          <Button variant="contained" className={`cancel-button font-size-11  mr-3`} onClick={() => { props.handleCancelQuotation(); setSelected(""); setReason("") }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CancelQuotation