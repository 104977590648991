/* eslint-disable */
import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import CancelIcon from "@material-ui/icons/CancelRounded"
import { getNewBrand, getNewlyAddedBrand } from '../../common/components/util';
import OpenQuotationCancelDialog from "./OpenQuotationCancelDialog";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VisibilityIcon from "@material-ui/icons/Visibility";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { getFSEQuotation, postOpenQuotation } from "../../store/actions/quotation.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import _, { get } from "lodash";
import OpenViewQuotation from "./OpenViewQuotationDialog";
import { downloadQuotation } from "../../store/actions/quotation.action";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';

const useStyles = makeStyles((theme) => ({
  tableHeadSmallBrand: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 19,
    lineHeight: 0,
    width: 200,
  },
  tableHeading: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 200,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 20,
    lineHeight: 0,
    width: 140,
  },
  // tableAction: {
  //     color: '#133F8A',
  //     fontWeight: 900,
  //     fontSize: '0.75rem',
  //     fontFamily: 'Nunito',
  //     paddingLeft: 90,
  //     lineHeight: 0,
  //     width: 140,
  // },
  tableAction: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    paddingLeft: 30,
    lineHeight: 0,
    width: 140,
  },
  tableHeadExpiry: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    paddingTop: 10,
    paddingRight: 10,
    paddingLeft: 5,
    paddingDown: 5,
    lineHeight: 0,
    width: 140,
  },
  body: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 20,
    lineHeight: 0,
    width: 180,
  },
  bodycus: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.7rem',
    fontFamily: 'Nunito',
    lineHeight: 0,
    width: 310,
  },
  bodyExpiry: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    lineHeight: 0,
    // padding:40,
    // paddingRight: 40,
    width: 240,
  },
  actions: {
    color: '#1A9FE0',
    fontSize: 13,
    fontFamily: 'Nunito',
    fontWeight: 700,
    width: 200
  },
  subTable2: {
    color: '#444444',
    fontWeight: 350,
    width: 97,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable6: {
    color: '#444444',
    fontWeight: 350,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  actionButton: {
    color: '#1A9FE0',

  },
}));

const OpenQuotationDialog = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [openCancelQuotation, setÖpenCancelQuotation] = React.useState(false)
  const classes = useStyles()
  const history: any = useHistory();
  const dispatch: any = useDispatch();
  const [openViewQuotation, setOpenViewQuotation] = React.useState(false);
  const { openQuotationRequest, quotation }: any = useSelector((state: any) => state.quotation)
  const [enquiryNumber, setEnquiryNumber] = React.useState("");
  const [quotationNumber, setQuotationNumber] = React.useState("");
  const [openQuotationList, setOpenQuotationList] = React.useState<any>({});
  const [orgId, setOrgId] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("");
  const [sapId, setSAPId] = React.useState("");
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [quotationStatus, setQuotationStatus] = React.useState("")
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAchorEl, setOpenAchorEl] = React.useState(false);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const [loading, setLoading] = React.useState<any>(false);
  const [viewQuotationUrl, setViewQuotationUrl] = React.useState("")
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [brandId, setBrandId] = React.useState("")
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const id = open ? "simple-popover" : undefined

  const handleClose = () => {
    openQuotationAPI();
    setOpenAchorEl(false);
    setAnchorEl(null);
  }

  const openQuotationAPI = async () => {
    setLoading(true)
    let data: any;
    if (sessionStorage.getItem('userType') === 'FSE') {
      let payload = {
        "userRole": "FSE",
        "loginUserId": sessionStorage.getItem('webApplicationLoginId'),
        "openQuotation": true,
        "isDateFilterApply": false,
        "fromDate": "",
        "toDate": "",
        "filterByStatus": "",
        "searchBy": "",
        "filterByCustomer": [],
        "filterByInterUserIds": [sessionStorage.getItem('webApplicationLoginId')]
      }
      data = await dispatch(getFSEQuotation(payload, 0))
    } else {
      data = await dispatch(postOpenQuotation({
        "myQuotation": true,
        "webApplicationLoginId": webApplicationLoginId,
        "sapCustomerId": sapCustomerId
      }))
    }
    if (data && data.message && data.message.status === 'S') {
      setLoading(false)
    } else if (data && data.message && data.message.status === "E") {
      props.showErrorMessage(data && data.error);
      props.handleopenCloseSnackbar(true);
      props.setOpenErrorSnackbar(true);
      setLoading(false)
    }
  }

  const handleUploadLpo = () => {
    setOpenUploadLpo(false)
  }

  const handleCloseViewQuotation = () => {
    setOpenViewQuotation(false);
  }

  const handleCloseRequestOrder = () => {
    setOpenRequestOrder(false)
  }

  const handleCancelQuotation = () => {
    setÖpenCancelQuotation(false)
  }

  const handleViewQuotation = async () => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId))
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
      setOpenViewQuotation(false)
    } else {
      // window.open(data && data.data)
      if (data) {
        setOpenViewQuotation(true)
        setViewQuotationUrl(data)
      }
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  return (
    <div>
      <React.Fragment>
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        {loading && (<FullscreenLoader />)}
        {loading && (<FullscreenLoader />)}
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog maxWidth="xl" open={props.openQuotation ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleCloseOpenQuotation()
            }
          }}>
          <DialogTitle className="pb-0 mt-2 mb-2" id="form-dialog-title">
            <div className="page-title">Quotation Reminder
              <IconButton aria-label="close" className="float-right mb-1" onClick={() => props.handleCloseOpenQuotation()}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 mb-5 mt- 4 pb-5">
            <div className="cardCommon mh-300p">
              <TableContainer>
                <Table aria-label="table" >
                  <TableHead className="w-100">
                    <TableRow>
                      <TableCell className={classes.tableHead}>Quotation No.</TableCell>
                      <TableCell className={classes.tableHeadSmallBrand}>Brand</TableCell>
                      <TableCell className={classes.tableHeading}>Reference Number</TableCell>
                      <TableCell className={classes.tableHeadExpiry}>Quotation Expiry Date</TableCell>
                      {sessionStorage.getItem('userType') === 'FSE' && (
                        <TableCell className={`pl-0 pr-0 ${classes.tableHead}`}>Customer Name</TableCell>
                      )}
                      <TableCell className={classes.tableHead}>Status</TableCell>
                      <TableCell className={classes.tableAction}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sessionStorage.getItem('userType') === 'FSE' ? (
                      <>
                        {quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.records && quotation.data.data.data.records.length > 0 && quotation.data.data.data.records.map((data: any, index: any) => {
                          return (
                            <React.Fragment>
                              <TableRow>
                                <TableCell className={classes.body}>{data.quotationNumber}</TableCell>
                                <TableCell className={classes.body}>{(customerTierType && customerTierType.toLowerCase() !== 'workshop' || customerTypeAccess && !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(data.make) : (sessionStorage.getItem('customerTypeofCustomer') !== "workshop") ? getNewlyAddedBrand(data.make) : getNewBrand(data.make)}</TableCell>
                                <TableCell className={classes.body}>{data.enquiryReferenceNumber}</TableCell>
                                <TableCell className={classes.bodyExpiry}>{moment(data.expiryDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                {sessionStorage.getItem('userType') === 'FSE' && (
                                  <TableCell className={`pl-0 pr-0 ${classes.bodycus}`}>{data.customerName.substring(0, 38)}</TableCell>
                                )}
                                <TableCell className={classes.body}>{data.quotationStatus}</TableCell>
                                {/* <TableCell>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setOpenViewQuotation(true)
                                                                }}
                                                                className={`mr-2 ${classes.actions}`} size="small">
                                                                <Visibility fontSize="inherit" />
                                                                <span className="ml-1">View Quotation</span>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setQuotationStatus(data.quotationStatus); setQuotationNumber(data.quotationNumber); setEnquiryNumber(data.B2bEnquiryId);setOrgId(data.brandId)
                                                                    setÖpenCancelQuotation(true)
                                                                }}
                                                                className={classes.actions} size="small">
                                                                <CancelIcon fontSize="inherit" />
                                                                <span className="ml-1">Cancel Quotation</span>
                                                            </IconButton>
                                                        </TableCell> */}
                                <TableCell className={`${classes.subTable6} padding-action`}>
                                  <div style={{ background: "", }}>
                                    {<IconButton aria-describedby={id} onClick={(event: any) => {
                                      handleClick(event);
                                      setQuotationStatus(data.quotationStatus);
                                      setQuotationNumber(data.quotationNumber);
                                      setBrandId(data.brandId);
                                      setEnquiryNumber(data.B2bEnquiryId);
                                      setOrgId(data.brandId);
                                      setOrderNumber(data.orderNumber)
                                      setSAPId(data.sapCustomerId);
                                    }} className={classes.actionButton} size="small">
                                      <div className="px-2 pt-1 pb-1 boxshadow-icon"> <MoreVertIcon fontSize="inherit" className={classes.actionButton} /> </div>
                                    </IconButton>}
                                  </div>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {openQuotationRequest && openQuotationRequest.data && openQuotationRequest.data.data && openQuotationRequest.data.data.records && openQuotationRequest.data.data.records.length > 0 && openQuotationRequest.data.data.records.map((data: any, index: any) => {
                          return (
                            <React.Fragment>
                              <TableRow>
                                <TableCell className={classes.body}>{data.quotationNumber}</TableCell>
                                <TableCell className={classes.body}>{(customerTierType && customerTierType.toLowerCase() !== 'workshop' || customerTypeAccess && !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(data.make) : (sessionStorage.getItem('customerTypeofCustomer') !== "workshop") ? getNewlyAddedBrand(data.make) : getNewBrand(data.make)}</TableCell>
                                <TableCell className={classes.body}>{data.enquiryReferenceNumber}</TableCell>
                                <TableCell className={classes.bodyExpiry}>{moment(data.expiryDate).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                                <TableCell className={classes.body}>{data.quotationStatus}</TableCell>
                                {/* <TableCell>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setOpenViewQuotation(true)
                                                                }}
                                                                className={`mr-2 ${classes.actions}`} size="small">
                                                                <Visibility fontSize="inherit" />
                                                                <span className="ml-1">View Quotation</span>
                                                            </IconButton>
                                                            <IconButton
                                                                onClick={() => {
                                                                    setQuotationStatus(data.quotationStatus); setQuotationNumber(data.quotationNumber); setEnquiryNumber(data.B2bEnquiryId);setOrgId(data.brandId)
                                                                    setÖpenCancelQuotation(true)
                                                                }}
                                                                className={classes.actions} size="small">
                                                                <CancelIcon fontSize="inherit" />
                                                                <span className="ml-1">Cancel Quotation</span>
                                                            </IconButton>
                                                        </TableCell> */}
                                <TableCell className={`${classes.subTable6} padding-action`}>
                                  <div style={{ background: "", }}>
                                    {<IconButton aria-describedby={id} onClick={(event: any) => {
                                      handleClick(event);
                                      setQuotationStatus(data.quotationStatus);
                                      setQuotationNumber(data.quotationNumber);
                                      setBrandId(data.brandId);
                                      setEnquiryNumber(data.B2bEnquiryId);
                                      setOrgId(data.brandId);
                                      setOrderNumber(data.orderNumber)
                                    }} className={classes.actionButton} size="small">
                                      <div className="px-2 pt-1 pb-1 boxshadow-icon"> <MoreVertIcon fontSize="inherit" className={classes.actionButton} /> </div>
                                    </IconButton>}
                                  </div>
                                </TableCell>
                              </TableRow>
                            </React.Fragment>
                          );
                        })}
                      </>
                    )}
                  </TableBody>
                </Table>
                {sessionStorage.getItem('userType') !== 'FSE' && (
                  <>
                    {openQuotationRequest && openQuotationRequest.data && openQuotationRequest.data.data && openQuotationRequest.data.data.records && openQuotationRequest.data.data.records.length === 0 &&
                      <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Quotations Found.</div>
                    }
                  </>
                )}

                {sessionStorage.getItem('userType') === 'FSE' && (
                  <>
                    {quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.records && quotation.data.data.data.records.length === 0 &&
                      <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Quotations Found.</div>
                    }
                  </>
                )}
              </TableContainer>
            </div>
            <Menu
              id={id}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorReference="anchorEl"
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              className="ml-3 mt-5 "
            >
              <MenuItem className="pl-3 pr-3" onClick={() => { handleViewQuotation() }}>
                <div className="pr-2">
                  <IconButton className={`${classes.actionButton} `} size="small">
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                </div>
                View Quotation</MenuItem>
              <MenuItem className="quotation-text" onClick={() => { setÖpenCancelQuotation(true); }} >
                <div className="pr-2">
                  <IconButton className="exportExcel " size="small" >
                    <CancelIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" />
                  </IconButton>
                </div>
                Cancel Quotation
              </MenuItem>
            </Menu>
          </div>
          <DialogActions className="justify-content-end mt-n-65">
            <Button variant="contained" className="submit-button font-size-11 mr-3 mb-3" onClick={() => { props.handleCloseOpenQuotation() }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <OpenViewQuotation openViewQuotation={openViewQuotation} handleCloseViewQuotation={handleCloseViewQuotation} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} viewQuotationUrl={viewQuotationUrl} handleClose={handleClose} orgId={orgId} orderNumber={orderNumber} setOpenViewQuotation={setOpenViewQuotation} brandId={brandId} />
      <OpenQuotationCancelDialog openCancelQuotation={openCancelQuotation} handleCancelQuotation={handleCancelQuotation} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} handleClose={handleClose} />
    </div >
  )
}
export default OpenQuotationDialog;
