/* eslint-disable */
import React, { forwardRef, Fragment, Dispatch, useImperativeHandle, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { useEffect } from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FormControl, Checkbox, InputAdornment, MenuItem, Tooltip, Button } from '@material-ui/core';
import { ValidationErrorMessage } from '../../constants/messages';
import ErrorIcon from '@material-ui/icons/Error';
import moment from "moment-timezone";
import { getActualBrand, getNewBrand, getUniqueTimestamp } from '../../common/components/util';
import { editPartnerDetails, getDealerDetails, getPartnerDetails, saveDealerDetails, savePartnerDetails, deletePartnerDetails, setCustomerType, getNationality, resetValues, setStatus, getAllPartners, changeCustomerType, } from '../../store/actions/onboard.actions';
import { get, isUndefined, stubFalse, truncate } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
// import DeleteDialog as DeleteCustomerDialog from '../../common/components/deleteDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import AddNewOwnerPartnerDialog from './addNewOwnerPartnerDialog';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { Prompt, useHistory } from 'react-router-dom';
import { getDealingIn, getRegion, getTypeOfTrade } from "../../store/actions/onboard.actions";
import ListItemText from '@material-ui/core/ListItemText';
import Visibility from "@material-ui/icons/Visibility";
import { getAllBrands } from '../../store/actions/dealer.action';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },
  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  dropdown: {
    // min-width: 100% !important;
    // font-size: large;
    minWidth: 100,
    fontSize: 'large'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

//TABLE HEADER COLOUMN CODE
const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'nationality', numeric: true, disablePadding: false, label: 'Nationality' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];
const workshopHeadCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

export const DealerDetails = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [error, setError] = React.useState<any>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [selected, setSelected] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [companyNameErrorMessage, setCompanyNameErrorMessage] = React.useState<string>("");
  const [customerLastName, setCustomerLastName] = React.useState<string>("");
  const [customerLastNameError, setCustomerLastNameError] = React.useState<boolean>(false);
  const [customerLastNameErrorMessage, setCustomerLastNameErrorMessage] = React.useState<string>("");
  const [country, setCountry] = React.useState<string>("");
  const [countryError, setCountryError] = React.useState<boolean>(false);
  const [invalidCountry, setInvalidCountry] = React.useState<boolean>(false);
  const [countryErrorMessage, setCountryErrorMessage] = React.useState<string>("");
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [postalCodeError, setPostalCodeError] = React.useState<boolean>(false);
  const [invalidPostalCode, setInvalidPostalCode] = React.useState<boolean>(false);
  const [city, setCity] = React.useState<string>("");
  const [cityError, setCityError] = React.useState<boolean>(false);
  const [invalidCity, setInvalidCity] = React.useState<boolean>(false);
  const [addStreet, setAddStreet] = React.useState<string>("");
  const [addStreetError, setAddStreetError] = React.useState<boolean>(false);
  const [invalidAddStreet, setInvalidAddStreet] = React.useState<boolean>(false);
  // const [addStreetErrorMessage, setAddStreetErrorMessage] = React.useState<string>("");
  const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean>(false);
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [telephoneOfficError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [TelephoneOfficeErrorMessage, setTelephoneOfficeErrorMessage] = React.useState<string>("");
  const [vatRegistrationNumber, setVatRegistrationNumber] = React.useState<string>("");
  const [shippingLabels, setShippingLabels] = React.useState<any>([{ name: "SORT1", value: '' }, { name: "SORT2", value: '' }]);
  const [vatRegistrationNumberError, setVatRegistrationNumberError] = React.useState<boolean>(false);
  const [invalidVatRegistrationNumber, setInvalidVatRegistrationNumber] = React.useState<boolean>(false);
  const [VatRegistrationNumberErrorMessage, setVatRegistrationNumberErrorMessage] = React.useState<string>("");
  const [dealerEmail, setDealerEmail] = React.useState<string>("");
  const [dealerEmailError, setDealerEmailError] = React.useState<boolean>(false);
  const [invalidDealerEmail, setInvalidDealerEmail] = React.useState<boolean>(false);
  const [headOfficeRegion, setHeadOfficeRegion] = React.useState<string>("");
  const [headOfficeRegionError, setHeadOfficeRegionError] = React.useState<boolean>(false);
  const [invalidHeadOfficeRegion, setInvalidHeadOfficeRegion] = React.useState<boolean>(false);
  const [typeOfTrade, setTypeOfTrade] = React.useState<string>("");
  const [typeOfTradeError, setTypeOfTradeError] = React.useState<boolean>(false);
  const [invalidTypeOfTrade, setInvalidTypeOfTrade] = React.useState<boolean>(false);
  const [dealingIn, setDealingIn] = React.useState<string>("");
  const [dealingInError, setDealingInError] = React.useState<boolean>(false);
  const [invalidDealingIn, setInvalidDealingIn] = React.useState<boolean>(false);
  const [headOfficeAddress, setAddress] = React.useState<any>({});
  const [annualTurnover, setAnnualTurnover] = React.useState<string>("");
  const [establishedDate, setEstablishedDate] = React.useState<any>(null);
  const [establishedDateError, setEstablishedDateError] = React.useState<boolean>(false);
  const [invalidEstablishedDate, setInvalidEstablishedDate] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [PoBoxErrorMessage, setPoBoxErrorMessage] = React.useState<string>("");
  const [selectedPartnerData, setSelectedPartnerData] = React.useState<any>("");
  const [selectBrand, setSelectBrand] = React.useState<any>([]);
  const [orgIds, setOrgIds] = React.useState<any>([]);
  const [selectBrandError, setSelectBrandError] = React.useState<boolean>(false);
  const [invalidSelectBrand, setInvalidSelectBrand] = React.useState<boolean>(false);
  const [creditLimit, setCreditLimit] = React.useState<string>("");
  const [creditLimitError, setCreditLimitError] = React.useState<boolean>(false);
  const [invalidCreditLimit, setInvalidCreditLimit] = React.useState<boolean>(false);
  const [initalStockOrder, setInitalStockOrder] = React.useState<string>("");
  const [initalStockOrderError, setInitalStockOrderError] = React.useState<boolean>(false);
  const [invalidInitalStockOrder, setInvalidInitalStockOrder] = React.useState<boolean>(false);
  const [totalInvestment, setTotalInvestment] = React.useState<string>("");
  const [totalInvestmentError, setTotalInvestmentError] = React.useState<boolean>(false);
  const [invalidTotalInvestment, setInvalidTotalInvestment] = React.useState<boolean>(false);
  const [otherBusinessEstablishments, setOtherBusinessEstablishments] = React.useState<string>("");
  const [OtherBusinessEstablishmentsErrorMessage, setOtherBusinessEstablishmentsErrorMessage] = React.useState<string>("");
  const [otherBusinessEstablishmentsError, setOtherBusinessEstablishmentsError] = React.useState<boolean>(false);
  const [invalidOtherBusinessEstablishments, setInvalidOtherBusinessEstablishments] = React.useState<boolean>(false);
  const [otherBrandsProductsSold, setOtherBrandsProductsSold] = React.useState<string>("");
  const [otherBrandsProductsSoldError, setOtherBrandsProductsSoldError] = React.useState<boolean>(false);
  const [invalidOtherBrandsProductsSold, setInvalidOtherBrandsProductsSold] = React.useState<boolean>(false);
  const [creditLimitSelector, setCreditLimitSelector] = React.useState<string>("AED");
  const [creditLimitSelectorError, setCreditLimitSelectorError] = React.useState<boolean>(false);
  const [invalidCreditLimitSelector, setInvalidCreditLimitSelector] = React.useState<boolean>(false);
  const [initalStockOrderSelector, setInitalStockOrderSelector] = React.useState<string>("AED");
  const [totalInvestmentSelector, setTotalInvestmentSelector] = React.useState<string>("AED");
  const [totalInvestmentSelectorError, setTotalInvestmentSelectorError] = React.useState<boolean>(false);
  const [invalidTotalInvestmentSelector, setInvalidTotalInvestmentSelector] = React.useState<boolean>(false);
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [annualTurnoverSelector, setAnnualTurnoverSelector] = React.useState<string>("AED");
  const [brandList, setBrandList] = React.useState<any>([]);
  const [oesBrandList, setOesBrandList] = React.useState<any>([]);
  const [existWorkShop, setExistWorkShopBrand] = React.useState<any>([]);
  const [brandListError, setBrandListError] = React.useState<any>([]);
  const [selectedRegion, setSelectedRegion] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  // const [dataUpdated, setdataUpdated] = React.useState<any>(false);
  const currencies = ["AED"];
  const [tradeRowRefDeleteIndex, setTradeRowRefDeleteIndex] = React.useState<any>();
  const [typeOfDelete, setTypeOfDelete] = React.useState<string>("");
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [selectedTradeReference, setSelectedTradeReference] = React.useState<any>([]);
  const [inCompleteFileds, setInCompleteFileds] = React.useState<any>(false);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const { customerDetails, regionData, dealingInData, typeofTradeData,
    savePartnerData, editPartnerData, deletePartnerData, dealerDetails, getAllPartnersData,
    getNationalityData }: any = useSelector((state: any) => state.onboard);
  const { brandsList }: any = useSelector((state: any) => state.dealer);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  let customerOnboardingType: any = sessionStorage.getItem('customerOnboardingType');
  let customerType: any = sessionStorage.getItem('customerType');
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const customerInfo = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data : customerDetails && customerDetails.data && customerDetails.data.customerInformation;
  const [tradeReference, setTradeReference] = React.useState<any>([
    // {
    //   companyName: "",
    //   name: "",
    //   addresses: "",
    //   telephone: ""
    // }
  ]);
  const isKamOnboarded = get(profileDetails, "data.data.basicInfo.isAddedByKam", false);
  const [tradeReferenceErrors, setTradeReferenceErrors] = React.useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const history = useHistory();
  const [isView, setIsView] = React.useState(false);
  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  const [email, setEmailId] = React.useState("")
  const [isDeactivated, setIsDeactivated] = React.useState("");
  const [deactivationReason, setDeactivationReason] = React.useState("");
  const groupCode = sessionStorage.getItem('groupCode');
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  let onboardingType: any = sessionStorage.getItem('customerOnboardingType');

  useEffect(() => {
    if (customerDetails && customerDetails.data && customerDetails.data.customerInformation && customerDetails.data.customerInformation.addresses && customerDetails.data.customerInformation.addresses.length > 0 && customerDetails.data.customerInformation.addresses[0].region && customerDetails.data.customerInformation.addresses[0].region !== '') {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if (r.Code === customerDetails.data.customerInformation.addresses[0].region) {
          setHeadOfficeRegion(r.Code);
          setSelectedRegion(r);
        }
      })
    }

    const typeOfTrades: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
      return customerDetails && customerDetails.data && customerDetails.data.customerInformation && customerDetails.data.customerInformation.customerGroupCode === ele.groupCode;
    })
    const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
      return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
    })
    if (customerType === "existing") {
      setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
    } else {
      setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)
    }
    if (dealerDetails && dealerDetails.data && dealerDetails.data.companyDetails && dealerDetails.data.companyDetails.typeOfTrade && dealerDetails.data.companyDetails.typeOfTrade !== '') {
      setTypeOfTrade(dealerDetails.data.companyDetails.typeOfTrade);
    }
  }, [customerDetails, regionData, typeofTradeData]);


  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/onboarding');
      window.location.reload();
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }


  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  useEffect(() => {
    if (headOfficeRegion !== '') {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if (r.Code === headOfficeRegion) {
          setHeadOfficeRegion(r.Code);
          setSelectedRegion(r);
        }
      })
    }
  }, [headOfficeRegion, regionData]);

  useEffect(() => {
    if (headOfficeRegion) {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if (r.Region === headOfficeRegion) {
          setHeadOfficeRegion(r.Region);
          setSelectedRegion(r);
        }
      })
    }
  }, [headOfficeRegion, regionData])

  useEffect(() => {
    loadPartners();
  }, []);

  useEffect(() => {
    if (country) {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Country === country) {
          setCountry(r);
        }
      })
    }
    // if (onboardingType && onboardingType.toLowerCase() === 'workshop' && sessionStorage.getItem('customerType') === 'new') {
    getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
      if (r.Code === 'AE') {
        setCountry(r.Code);
      }
    })
    // }
  }, [country, getNationalityData, onboardingType])

  const handleEditPartnerDetails = (partner: any, isView: any) => {
    dispatch(getPartnerDetails(b2bUserId, partner.id));
    handleClickOpen();
    setIsView(isView);
    setIsEdit(true);
    setEditData(partner);
  }


  const loadPartners = async () => {
    dispatch(resetValues());
    setLoading(true);
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      await dispatch(getNationality());
    }
    await dispatch(getTypeOfTrade());
    handleopenCloseSnackbar(false);
    await dispatch(getAllPartners(b2bUserId));
    const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails'));

    if (data && data.data && Object.keys(data.data.companyDetails).length > 0) {
      props.setdealerStatus(data.data.status);
      dispatch(getRegion(data && data.data && data.data.customerTierType));
      if (data && data.data && data.data.customerTierType === 'Workshop') {
        await dispatch(getAllBrands('workshop'));
        if (data.data.isNewCustomer) {
          sessionStorage.setItem('customerType', 'new');
        } else {
          sessionStorage.setItem('customerType', 'existing');
        }
        sessionStorage.setItem('customerOnboardingType', 'workshop');
      } else if (data && data.data && data.data.customerTierType === 'Dealer') {
        await dispatch(getAllBrands('dealer'));
        sessionStorage.setItem('customerOnboardingType', data.data.customerTierType);
        if (data.data.isNewCustomer) {
          sessionStorage.setItem('customerType', 'new');
        } else {
          sessionStorage.setItem('customerType', 'existing');
        }
      } else if (data && data.data && data.data.customerTierType === 'Government') {
        await dispatch(getAllBrands('government'));
        sessionStorage.setItem('customerOnboardingType', data.data.customerTierType);
        if (data.data.isNewCustomer) {
          sessionStorage.setItem('customerType', 'new');
        } else {
          sessionStorage.setItem('customerType', 'existing');
        }
      } else {
        await dispatch(getAllBrands('fleet'));
        sessionStorage.setItem('customerOnboardingType', data.data.customerTierType);
        if (data.data.isNewCustomer) {
          sessionStorage.setItem('customerType', 'new');
        } else {
          sessionStorage.setItem('customerType', 'existing');
        }

      }

      onboardingType = data && data.data && data.data.customerTierType ? data.data.customerTierType : sessionStorage.getItem('customerOnboardingType');

      setCompanyName(data.data.companyDetails.customerFirstName);
      setCustomerLastName(data.data.companyDetails.customerLastName);
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber);
      if (data.data.isNewCustomer && !isGovernment()) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];

        tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
          tradereferenceItems.push({
            addresses: element.addresses,
            companyName: element.companyName,
            name: element.name,
            telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        });
        if (data.data.treadReference.length == 0) {
          tradereferenceItems.push({
            companyName: "",
            name: "",
            addresses: "",
            telephone: ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        }

        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
      if (data.data.isNewCustomer) {
        setDealerEmail(data.data.companyDetails.emails && data.data.companyDetails.emails.length > 0 && data.data.companyDetails.emails[0].emailId);
        setTelephoneOffice(data.data.companyDetails.telephones && data.data.companyDetails.telephones.length > 0 && data.data.companyDetails.telephones[0].telephone);
      } else {
        setDealerEmail(data.data.companyDetails.emails);
        setTelephoneOffice(data.data.companyDetails.telephones);
      }
      setHeadOfficeRegion(data.data.companyDetails.regionCode);
      setDealingIn(data.data.companyDetails.dealingIn);
      setTypeOfTrade(data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setEstablishedDate(isGovernment() && data && data.data && data.data.companyDetails && data.data.companyDetails.establishedDate === "Invalid date" ? null : data && data.data && data.data.companyDetails && data.data.companyDetails.establishedDate);
    }
    if (data && data.data && !data.data.isNewCustomer && tradeReference.length == 0) {
      let tradereferenceItems = [...tradeReference];
      let tradeError = [...tradeReferenceErrors];
      tradereferenceItems.push({
        companyName: "",
        name: "",
        addresses: "",
        telephone: ""
      })
      tradeError.push({
        companyNameError: false,
        companyNameErrorMessage: "",
        nameError: false,
        nameErrorMessage: "",
        addressError: false,
        addressErrorMessage: "",
        telephoneError: false,
        telephoneErrorMessage: "",
      })
      setTradeReferenceErrors(tradeError)
      setTradeReference(tradereferenceItems)
    }

    if (data && data.data && data.data.addresses && data.data.addresses.length > 0) {
      setAddress(data.data.addresses[0])
      setAddStreet(data.data.addresses[0].streetName)
      setPostalCode(data.data.addresses[0].postalCode)
      setCity(data.data.addresses[0].city);
      setCountry(data.data.addresses[0].country);
      setPoBox(data.data.addresses[0].poBox);
      setLatitude(data.data.addresses[0].lattitude)
      setLongitude(data.data.addresses[0].longitude)
      setEmailId(data.data.addresses[0].emailId)
      setDeactivationReason(data.data.addresses[0].deactivationReason)
      setIsDeactivated(data.data.addresses[0].isDeactivated)
      if (data.data.addresses[0].shippingLabels && data.data.addresses[0].shippingLabels.length > 0) setShippingLabels(data.data.addresses[0].shippingLabels);
    }
    if (onboardingType && onboardingType.toLowerCase() === 'workshop') {
      if (brandsList && brandsList.data && brandsList.data.length > 0) {
        let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
        setOesBrandList(oesBrands);
        if (oesBrands && oesBrands.length > 0)
          setSelectBrand([getNewBrand(oesBrands[0].name)]);
      } else {
        setOesBrandList([{ "name": "OES", "displayName": "Tradepoint", "brandId": "2195", "brandType": "OES", "paymentBrandCode": "" }])
        setSelectBrand([getNewBrand("OES")]);
      }
    }
    if (data && data.data && data.data.customerTierType && data.data.customerTierType.toLowerCase() === 'workshop') {
      if (data.data.selectedBrand && data.data.selectedBrand.length > 0) {
        let orgdata: any = [];
        data.data.selectedBrand.map((org: any) => {
          orgdata.push(getNewBrand(org.make));
        })
        setSelectBrand(orgdata)
        setOesBrandList([{ "name": "OES", "displayName": "Tradepoint", "brandId": "2195", "brandType": "OES", "paymentBrandCode": "" }])
      } else {
        if (brandsList && brandsList.data && brandsList.data.length > 0) {
          let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
          setOesBrandList(oesBrands);
          if (oesBrands && oesBrands.length > 0)
            setSelectBrand([getNewBrand(oesBrands[0].name)]);
        } else {
          setOesBrandList([{ "name": "OES", "displayName": "Tradepoint", "brandId": "2195", "brandType": "OES", "paymentBrandCode": "" }])
          setSelectBrand([getNewBrand("OES")]);
        }
      }
    } else {
      if (data.data.organizationIds && data.data.organizationIds.length > 0) {
        setOrgIds(data.data.organizationIds.filter((brand: any) => brand && brand.make !== 'Famco'));
        let orgdata: any = [];
        data.data.organizationIds.map((org: any) => {
          orgdata.push(org.make === 'OES' && onboardingType && onboardingType.toLowerCase() !== 'workshop' ? 'ALAC' : getNewBrand(org.make));
        })
        setSelectBrand(orgdata);
      }
    }
    setCreditLimit(data.data.initialStockOrder);
    setTotalInvestment(data.data.totalInvesment);
    setOtherBusinessEstablishments(data.data.otherBusinessEstablishments);
    setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold);
    // if (isExistingUser || (data.data.status === 'Submitted' && !customerType)) dispatch(setCustomerType('existing'));
    setLoading(false);
    if (data && data.data && Object.keys(data.data.companyDetails).length === 0 && data.data.isNewCustomer && data.data.customerTierType === "Fleet" || data && data.data && Object.keys(data.data.companyDetails).length === 0 && data.data.isNewCustomer && data.data.customerTierType === "Dealer" || data && data.data && Object.keys(data.data.companyDetails).length === 0 && data.data.isNewCustomer && data.data.customerTierType === "Government" || data && data.data && Object.keys(data.data.companyDetails).length === 0 && data.data.isNewCustomer && data.data.customerTierType === "Workshop") {
      let tradereferenceItems = [...tradeReference];
      let tradeError = [...tradeReferenceErrors];
      tradereferenceItems.push({
        companyName: "",
        name: "",
        addresses: "",
        telephone: ""
      })
      tradeError.push({
        companyNameError: false,
        companyNameErrorMessage: "",
        nameError: false,
        nameErrorMessage: "",
        addressError: false,
        addressErrorMessage: "",
        telephoneError: false,
        telephoneErrorMessage: "",
      })
      if (tradeReference.length === 0) {
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
    }
  }

  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || (dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer') || ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active'))) {
      return true;
    } else {
      return false;
    }
  }

  const brands = [
    // {
    //   "make": "Famco",
    //   "organizationId": "2003",
    // },
    {
      "make": "Honda",
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "organizationId": "2001",
    },
    {
      "make": "OES",
      "organizationId": "2195",
    }
  ]

  const materialBrands = [
    // {
    //   "make": "Famco",
    //   "materilGroup": [],
    //   "organizationId": "2003",
    // },
    {
      "make": "Honda",
      "materilGroup": [],
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "materilGroup": [],
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "materilGroup": [],
      "organizationId": "2001",
    },
    {
      "make": "OES",
      "materilGroup": [],
      "organizationId": "2195",
    }
  ]

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, errorErrorMessage?: any, type?: any) => {
    props.setDataUpdated(true);
    setterName(e.target.value);
    if (type === 'brand') {
      // setterName(e.target.value);
      errorSetterName(false);
    }
    else if (type === 'intialStock') {
      props.setDataUpdated(true);
      setCreditLimit(e.target.value)
      errorSetterName(false);
    }
    // else if (type === "bussinessEst") {
    //   // if (e.target.value != "") {
    //   //   // if (!validateForm(e.target.value, "bussinessEst")) {
    //   //     // errorErrorMessage(ValidationErrorMessage.SPECIAL_CHARACTERS)
    //   //     errorSetterName(true);
    //   //   // }
    //   //   // else {
    //   //   //   errorErrorMessage("")
    //   //   //   errorSetterName(false);
    //   //   // }
    //   // }
    //   // else {
    //   //   errorErrorMessage("")
    //   //   errorSetterName(false);
    //   // }
    // }
    else if (type === "brandproductsold") {
      props.setDataUpdated(true);

      // if (e.target.value != "") {
      //   if (!validateForm(e.target.value, "brandproductsold")) {
      //     errorSetterName(true);
      //   }
      //   else {
      //     errorSetterName(false);
      //   }
      // }
      // else {
      //   errorSetterName(false);
      // }
    }
    // else if (type === "street") {
    //   if (e.target.value != "") {
    //     if (e.target.value.length > 250) {
    //       errorErrorMessage(ValidationErrorMessage.LENGTH)
    //       errorSetterName(true);
    //     // } else if (!validateForm(e.target.value, "street")) {
    //     //   errorSetterName(true);
    //     }
    //     else {
    //       errorSetterName(false);
    //     }
    //   }
    //   else {
    //     errorSetterName(false);
    //   }
    // }
    // else if (type === "city") {
    //   if (e.target.value != "") {
    //      if (!validateForm(e.target.value, "city")) {
    //       errorSetterName(true);
    //     }
    //     else {
    //       errorSetterName(false);
    //     }
    //   }
    //   else {
    //     errorSetterName(false);
    //   }
    // }
    else if (type === "vatReg") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        if (!validateForm(e.target.value, "vatReg")) {
          errorErrorMessage(ValidationErrorMessage.TAX_REGISTRATION_TEXT)
          errorSetterName(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "telephone") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        //if ((e.target.value.charAt(0) != "5" || e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
        if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
          errorErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
          errorSetterName(true);
        }
        //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
        else if (e.target.value.charAt(0) != "0") {
          // errorErrorMessage("It should be start with 5 or 0.")
          errorErrorMessage("It should be start with 0.")
          errorSetterName(true);
        }
        else if (e.target.value.length > 9) {
          errorErrorMessage("It should not exceed 9 digits.")
          setTelephoneOfficeError(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "companyName") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          errorErrorMessage(ValidationErrorMessage.LENGTH)
          errorSetterName(true);
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   errorErrorMessage(ValidationErrorMessage.LENGTH);
        //   errorSetterName(true);

        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   errorErrorMessage(ValidationErrorMessage.SPECIAL_CHARACTERS)
        //   errorSetterName(true);
        // }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
    }
    else if (type === "pobox") {
      props.setDataUpdated(true);

      if (e.target.value != "") {
        if (e.target.value.length > 7) {
          errorErrorMessage(ValidationErrorMessage.POBOX_TEXT);
          errorSetterName(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else {
      if (errorSetterName) {
        props.setDataUpdated(true);

        if (e.target.value === "" || e.target.value.trim() === "") {
          errorSetterName(true);
        }
        else {
          errorErrorMessage("");
          errorSetterName(false);
        }
      }
    }
  }

  const tradeReferenceItem = (e: any, index: any, type: any) => {
    let trade = [...tradeReference];
    let tradeErrors = [...tradeReferenceErrors];
    if (type === "name") {
      props.setDataUpdated(true);

      trade[index].name = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].nameError = true;
          tradeErrors[index].nameErrorMessage = ValidationErrorMessage.COMPANY_NAME_TEXT;
        }
        else {
          tradeErrors[index].nameError = false;
          tradeErrors[index].nameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].nameError = false;
        tradeErrors[index].nameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }

    if (type === "companyName") {
      props.setDataUpdated(true);

      trade[index].companyName = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].companyNameError = true;
          tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.SPECIAL_CHARACTERS;
        // }
        else {
          tradeErrors[index].companyNameError = false;
          tradeErrors[index].companyNameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].companyNameError = false;
        tradeErrors[index].companyNameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }
    else if (type === "addresses") {
      props.setDataUpdated(true);

      trade[index].addresses = e.target.value;
      tradeErrors[index].addressError = false;
      tradeErrors[index].addressErrorMessage = "";
      // if (e.target.value != "") {
      //   if (!validateForm(e.target.value, "address")) {
      //     tradeErrors[index].addressError = true;
      //     tradeErrors[index].addressErrorMessage = ValidationErrorMessage.SPECIAL_CHARACTERS;
      //   }
      //   else {
      //     tradeErrors[index].addressError = false;
      //     tradeErrors[index].addressErrorMessage = "";
      //   }
      // }
      // else {
      //   tradeErrors[index].addressError = false;
      //   tradeErrors[index].addressErrorMessage = "";
      // }
    }
    else if (type == "telephone") {
      props.setDataUpdated(true);

      trade[index].telephone = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 20) {
          tradeErrors[index].telephoneError = true;
          tradeErrors[index].telephoneErrorMessage = ValidationErrorMessage.ANNUAL_TURNOVER_TEXT;
        }
        else {
          tradeErrors[index].telephoneError = false;
          tradeErrors[index].telephoneErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].telephoneError = false;
        tradeErrors[index].telephoneErrorMessage = "";
      }
    }
    setTradeReferenceErrors(tradeErrors);
    setTradeReference(trade)
  }

  function validateForm(value: any, type: string) {
    switch (type) {
      case "country":
      // case "bussinessEst":
      case "brandproductsold":
        {
          let re = /^[a-z\d\-_\s]+$/i;
          return !re.test(value) ? false : true;
        }
      // case "companyName":
      // case "address": {
      //   let re = /^[a-zA-Z0-9 ]$/;
      //   return !re.test(value) ? false : true;
      // }

      case "vatReg": {
        const re = /^[0-9]{15}$/;
        return !re.test(value) ? false : true;
      }
      case "telephone":
        {
          const re = /^[0-9]{9}$/;
          return !re.test(value) ? false : true;
        }
      // case "street":
      //   {
      //     let re = /^[a-z\d\-_\s]{1,250}$/i;
      //     return !re.test(value) ? false : true;
      //   }
      // case "city":
      //   {
      //     let re = /^[a-z\d\-_\s]{1,250}$/i;
      //     return !re.test(value) ? false : true;
      //   }
    }
  }

  function isGovernment() {
    if (onboardingType && onboardingType.toLowerCase() === 'government') {
      return true
    } else {
      return false
    }
    // if (sessionStorage.getItem('customerOnboardingType') == 'Dealer' || sessionStorage.getItem('customerOnboardingType') == 'dealer' ||
    //   sessionStorage.getItem('customerOnboardingType') == 'workshop' || sessionStorage.getItem('customerOnboardingType') == 'Workshop' || sessionStorage.getItem('customerOnboardingType') == 'Fleet') {
    //   return false
    // } else {
    //   return true
    // }
  }

  function isFleet() {
    if (onboardingType && onboardingType.toLowerCase() === 'fleet') {
      return true
    } else {
      return false
    }
  }

  function validateExistingTab() {
    setOpenErrorSnackbar(false);
    handleopenCloseSnackbar(false);
    let isValid: boolean = true;
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (!isGovernment()) {
      if ((!dealingIn) || (dealingIn && dealingIn.trim() === '')) {
        setDealingInError(true);
        isValid = false;
      }
    }

    if ((!typeOfTrade) || (typeOfTrade && typeOfTrade.trim() === '')) {
      setTypeOfTradeError(true);
      isValid = false;
    }
    if (!isGovernment()) {
      if (!establishedDate) {
        setEstablishedDateError(true);
        isValid = false;
      }

      if (establishedDate) {
        var varDate = new Date(establishedDate);
        var today = new Date();
        if (varDate > today) {
          setInvalidDate(true);
          setError('Date should be less than today date.')
          setEstablishedDateError(true);
          isValid = false;
        }
        else {
          setEstablishedDateError(false);
          setInvalidDate(false);
          setError(null)
        }
      }
      if (error) {
        isValid = false;
      }
    }

    if (getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length === 0) {
      isValid = false;
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage('Please add atleast one Owner/Partner');
    }
    return isValid;
  }

  function validateNewDealerDetailsTab() {
    let isValid: boolean = true;
    if (!validateExistingTab()) {
      isValid = false;
    }
    if (companyName === "") {
      setCompanyNameError(true);
      isValid = false;
    }
    if (selectBrand.length == 0) {
      setSelectBrandError(true);
      isValid = false;
    }
    else {
      setSelectBrandError(false);
    }
    if (customerOnboardingType && customerOnboardingType.toLowerCase() !== 'workshop') {
      if (vatRegistrationNumber.trim() == "" || vatRegistrationNumberError) {
        setVatRegistrationNumberError(true);
        isValid = false;
      }
    }
    if (telephoneOffice.trim() == "") {
      setTelephoneOfficeError(true);
      isValid = false;
    }
    if (telephoneOffice) {
      //if ((telephoneOffice.charAt(0) != "5" || telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
      if ((telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
        setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
        setTelephoneOfficeError(true);
        isValid = false;
      }
      //else if (telephoneOffice.charAt(0) != "5" && telephoneOffice.charAt(0) != "0") {
      else if (telephoneOffice.charAt(0) != "0") {
        //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
        setTelephoneOfficeErrorMessage("It should be start with 0.")
        setTelephoneOfficeError(true);
        isValid = false;
      }
      else if (telephoneOffice.length > 9) {
        setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
        setTelephoneOfficeError(true);
        isValid = false;
      }
      else {
        setTelephoneOfficeErrorMessage("")
        setTelephoneOfficeError(false);
      }
    }
    if ((!country) || (country && country.trim() === undefined)) {
      setCountryError(true);

      isValid = false;
    }
    if (((!isGovernment()) && (!isFleet() && isExistingUser == false)) && (onboardingType && onboardingType.toLowerCase() !== 'workshop')) {
      if ((!totalInvestment) || (totalInvestment && totalInvestment.trim() === '')) {
        setTotalInvestmentError(true);
        isValid = false;
      } else {
        setTotalInvestmentError(false);
      }
      // if (!isFleet() || sessionStorage.getItem('customerOnboardingType') !== 'fleet' || sessionStorage.getItem('customerOnboardingType') !== 'Fleet') {
      if ((!creditLimit) || (creditLimit && creditLimit.trim() === '')) {
        setCreditLimitError(true);
        isValid = false;
      }
      if (creditLimit.trim() !== "") {
        setCreditLimitError(false);
        setInvalidCreditLimit(false);
      }
    }
    // }
    if ((!addStreet) || (addStreet && addStreet.trim() === '')) {
      setAddStreetError(true);
      isValid = false;
    }
    if (addStreet.trim() !== "") {
      if (addStreet.split('').length > 250) {
        setInvalidAddStreet(true);
        isValid = false;
      }
      else {
        setInvalidAddStreet(false);
      }
    }
    if ((!city) || (city && city.trim() === '')) {
      setCityError(true);
      isValid = false;
    }
    // if (city.trim() !== "") {
    //   if (!(/^[a-z\d\-_\s]+$/i.test(city.trim()))) {
    //     setInvalidCity(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidCity(false);
    //   }
    // }
    if (onboardingType && onboardingType.toLowerCase() !== 'workshop') {
      if ((!poBox) || (poBox && poBox.trim() === '')) {
        setPoBoxError(true);
        isValid = false;
      }
      if (poBox.trim() !== "") {
        if (poBox.split('').length > 7 || !(/^[a-z\d\-_\s]+$/i.test(poBox.trim()))) {
          setInvalidPoBox(true);
          isValid = false;
        }
        else {
          setInvalidPoBox(false);
        }
      }
    }

    if (!isGovernment() && !isFleet() && onboardingType && onboardingType.toLowerCase() !== 'workshop') {
      if ((!postalCode) || (postalCode && postalCode.trim() === '')) {
        setPostalCodeError(true);
        isValid = false;
      }
      else {
        setPostalCodeError(false);
      }
    }
    if (dealerEmail.trim() == "") {
      setDealerEmailError(true);
      isValid = false;
    }
    if (dealerEmail.trim() != "") {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dealerEmail.trim()))) {
        setInvalidDealerEmail(true);
        isValid = false;
      }
      else {
        setInvalidDealerEmail(false);
      }
    }
    // if (otherBusinessEstablishments.trim() === "") {
    //   // if (!(/^[a-z\d\-_\s]+$/i.test(otherBusinessEstablishments.trim()))) {
    //     setOtherBusinessEstablishmentsError(true);
    //     isValid = false;
    //   }
    //   else {
    //     setOtherBusinessEstablishmentsError(false);
    //   // }
    // }
    // if (otherBrandsProductsSold.trim() !== "") {
    //   if (!(/^[a-z\d\-_\s]+$/i.test(otherBrandsProductsSold.trim()))) {
    //     setInvalidOtherBrandsProductsSold(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidOtherBrandsProductsSold(false);
    //   }
    // }


    if (!isGovernment()) {
      if (onboardingType && onboardingType.toLowerCase() !== 'workshop') {
        if (creditLimit.trim() == "") {
          setCreditLimitError(true);
          isValid = false;
        }
        else {
          setCreditLimitError(false);
        }
      }
      // if (onboardingType && onboardingType.toLowerCase() !== 'fleet') {
      if (onboardingType && onboardingType.toLowerCase() !== 'workshop') {
        let trade1 = [...tradeReference];
        let tradeError = [...tradeReferenceErrors]
        trade1 && trade1.length > 0 && trade1.forEach((element: any, index: any) => {
          if (element.companyName == "" || element.companyName.length > 250) {
            isValid = false;
            tradeError[index].companyNameError = true;
          }
          else {
            tradeError[index].companyNameError = false;
          }
          if (element.name == "") {
            isValid = false;
            tradeError[index].nameError = true;
          }
          else {
            tradeError[index].nameError = false;
          }
          if (element.addresses == "") {
            tradeReferenceErrors[index].addressError = true;
            isValid = false;
          }
          else {
            tradeReferenceErrors[index].addressError = false;
          }
          if (element.telephone == "") {
            tradeReferenceErrors[index].telephoneError = true;
            isValid = false;
          }
          else {
            tradeReferenceErrors[index].telephoneError = false;
          }
        })
      }
    }
    // }
    return isValid;
  }
  function datePickerValid(e: any, state: any) {
    if (!isGovernment()) {
      if (e === "Invalid Date Format") {
        setError("Invalid Date Format");
        state(true);
      } else {
        if (establishedDate) {
          var varDate = new Date(establishedDate);
          var today = new Date();
          if (varDate >= today) {
            setInvalidDate(true);
            setError('It should be less than today date.')
            setEstablishedDateError(true);
          }
          else {
            setEstablishedDateError(false);
            state(false);
            setError(null)
          }
        }
      }
    } else {
      setEstablishedDateError(false);
      state(false);
      setError(null)
    }

  }

  const getTelePhoneNumber = (customerInfo: any) => {
    if (customerInfo && customerInfo.telephones && typeof customerInfo.telephones == 'string') {
      return customerInfo.telephones;
    } else if (customerInfo && customerInfo.telephones && customerInfo.telephones.length > 0 && customerInfo.telephones[0].telephone && typeof customerInfo.telephones[0].telephone == 'string') {
      return customerInfo.telephones[0].telephone;
    } else if (customerInfo && customerInfo.companyDetails && customerInfo.companyDetails.telephones && customerInfo.companyDetails.telephones.length > 0 && customerInfo.companyDetails.telephones[0].telephone && typeof customerInfo.companyDetails.telephones[0].telephone == 'string') {
      return customerInfo.companyDetails.telephones[0].telephone
    } else {
      return ''
    }
  }

  async function saveDetails(isSubmitted?: any) {
    props.setDataUpdated(false);
    let region: any;
    if (headOfficeRegion) {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if (r.Code === headOfficeRegion) {
          setHeadOfficeRegion(r.Code);
          region = r;
          setSelectedRegion(r);
        }
      })
    }

    if (sessionStorage.getItem('customerOnboardingType') == 'Dealer' || sessionStorage.getItem('customerOnboardingType') == 'dealer') {
      if (sessionStorage.getItem('customerType') === 'existing') {
        if (validateExistingTab()) {
          let selectedBrands: any[] = [];
          customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.organizationId === element.organizationId)[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");

          if (headOfficeAddress) {
            headOfficeAddress.companyName = customerInfo.customerFirstName ? customerInfo.customerFirstName + '' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerFirstName = customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerLastName = customerInfo.customerLastName ? customerInfo.customerLastName : "";
            headOfficeAddress.telephoneNumber = getTelePhoneNumber(customerInfo);
            headOfficeAddress.poBox = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox;
            headOfficeAddress.city = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city;
            headOfficeAddress.country = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country;
            headOfficeAddress.streetName = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '';
            headOfficeAddress.postalCode = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '';
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].id ? customerInfo.addresses[0].id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].shippingLabels ? customerInfo.addresses[0].shippingLabels : shippingLabels;
          }
          let merged: any = [];
          if (selectedBrands && selectedBrands.length > 0) {
            if (orgIds && orgIds.length > 0) {
              merged = selectedBrands.map((objA: any) => {
                var matchedObject = orgIds.find((objB: any) => objB.organizationId === objA.organizationId);
                if (matchedObject) {
                  return { ...objA, fseId: matchedObject?.fseId };
                } return objA;
              });
              if (merged && merged.length > 0) selectedBrands = merged;
            }
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": "Dealer",
            "companyDetails": {
              "title": customerInfo && customerInfo.title,
              "companyName": customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerFirstName": customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerLastName": customerInfo.customerLastName ? customerInfo.customerLastName : '',
              "vatRegistrationNumber": "",
              "telephones": customerInfo.telephones ? customerInfo.telephones : customerInfo.companyDetails && customerInfo.companyDetails.telephones ? customerInfo.companyDetails.telephones : '',
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": customerInfo.emails ? customerInfo.emails : customerInfo.companyDetails && customerInfo.companyDetails.emails,
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
            // "organizationIds": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": [],
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message) {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
      else if (sessionStorage.getItem('customerType') === 'new') {
        if (validateNewDealerDetailsTab()) {
          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.name && element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                "name": element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": "-",
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })
          let selectedBrands: any[] = [];
          selectBrand && selectBrand.length > 0 && selectBrand.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.make === getActualBrand(element))[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = addStreet;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = false;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }

          let merged: any = [];
          if (selectedBrands && selectedBrands.length > 0) {
            if (orgIds && orgIds.length > 0) {
              merged = selectedBrands.map((objA: any) => {
                var matchedObject = orgIds.find((objB: any) => objB.organizationId === objA.organizationId);
                if (matchedObject) {
                  return { ...objA, fseId: matchedObject?.fseId };
                } return objA;
              });
              if (merged && merged.length > 0) selectedBrands = merged;
            }
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
            "isNewCustomer": true,
            "customerTierType": "Dealer",
            "companyDetails": {
              "title": 'M/s',
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "telephones": [
                {
                  "country": country,
                  "telephone": telephoneOffice,
                  "phoneType": "PRIMARYMOBILE"
                }
              ],
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": [
                {
                  "emailId": dealerEmail,
                  "emailType": "PRIMARY"
                }
              ],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": poBox ? poBox : '',
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : creditLimitSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": traderefe,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    } else if (sessionStorage.getItem('customerOnboardingType') == 'workshop' || sessionStorage.getItem('customerOnboardingType') == 'Workshop') {
      // Add existing workshop payload here for validation use validateExistingTab() function
      if (sessionStorage.getItem('customerType') === 'new') {
        if (validateNewDealerDetailsTab()) {
          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.name && element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                "name": element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": "-",
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }

          })
          let selectedBrands: any[] = [];
          selectBrand && selectBrand.length > 0 && selectBrand.forEach((element: any) => {
            selectedBrands.push(materialBrands.filter((item: any) => item.make === getActualBrand(element))[0]);
          });

          let oes: any[] = [];
          oesBrandList && oesBrandList.length > 0 && oesBrandList.forEach((element: any) => {
            oes.push(brands.filter((item: any) => item.make === element.name)[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox ? poBox : '';
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = addStreet;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = false;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
            "customerTierType": "Workshop",
            "isNewCustomer": true,
            "companyDetails": {
              "title": 'M/s',
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "telephones": [
                {
                  "country": country,
                  "telephone": telephoneOffice,
                  "phoneType": "PRIMARYMOBILE"
                }
              ],
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": [
                {
                  "emailId": dealerEmail,
                  "emailType": "PRIMARY"
                }
              ],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": poBox ? poBox : '',
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": oes.filter((brand: any) => brand),
            "selectedBrand": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : creditLimitSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": traderefe,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      } else if (sessionStorage.getItem('customerType') === 'existing') {
        if (validateExistingTab()) {
          let selectedBrands: any[] = [];
          customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.organizationId === element.organizationId)[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");

          if (headOfficeAddress) {
            headOfficeAddress.companyName = customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerFirstName = customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerLastName = customerInfo.customerLastName ? customerInfo.customerLastName : "";
            headOfficeAddress.telephoneNumber = getTelePhoneNumber(customerInfo);
            headOfficeAddress.poBox = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox;
            headOfficeAddress.city = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city ? customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city : "";
            headOfficeAddress.country = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country;
            headOfficeAddress.streetName = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '';
            headOfficeAddress.postalCode = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '';
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].id ? customerInfo.addresses[0].id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].shippingLabels ? customerInfo.addresses[0].shippingLabels : shippingLabels;
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": "Workshop",
            "companyDetails": {
              "title": customerInfo && customerInfo.title,
              "companyName": customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerFirstName": customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerLastName": customerInfo.customerLastName ? customerInfo.customerLastName : '',
              "vatRegistrationNumber": "",
              "telephones": customerInfo.telephones ? customerInfo.telephones : customerInfo.companyDetails && customerInfo.companyDetails.telephones ? customerInfo.companyDetails.telephones : '',
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": customerInfo.emails ? customerInfo.emails : customerInfo.companyDetails && customerInfo.companyDetails.emails,
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": [
              {
                "make": "OES",
                "organizationId": "2195"
              }
            ],
            "selectedBrand": [
              {
                "make": "OES",
                "materilGroup": [],
                "organizationId": "2195"
              },
              {
                "make": "Toyota",
                "materilGroup": [],
                "organizationId": "2001"
              },
              {
                "make": "Honda",
                "materilGroup": [],
                "organizationId": "2002"
              },
              {
                "make": "TE",
                "materilGroup": [],
                "organizationId": "2380"
              }

            ],
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": [],
            "isAddedByKam": false,
          }

          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message) {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          }
        }
        else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    } else if (sessionStorage.getItem('customerOnboardingType') == 'Government' || sessionStorage.getItem('customerOnboardingType') == 'government') {
      if (sessionStorage.getItem('customerType') === 'existing') {
        if (validateExistingTab()) {
          let selectedBrands: any[] = [];
          customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.organizationId === element.organizationId)[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");

          if (headOfficeAddress) {
            headOfficeAddress.companyName = customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerFirstName = customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerLastName = customerInfo.customerLastName ? customerInfo.customerLastName : "";
            headOfficeAddress.telephoneNumber = getTelePhoneNumber(customerInfo);
            headOfficeAddress.poBox = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox;
            headOfficeAddress.city = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city;
            headOfficeAddress.country = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country;
            headOfficeAddress.streetName = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '';
            headOfficeAddress.postalCode = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '';
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].id ? customerInfo.addresses[0].id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].shippingLabels ? customerInfo.addresses[0].shippingLabels : shippingLabels;
          }

          let merged: any = [];
          if (selectedBrands && selectedBrands.length > 0) {
            if (orgIds && orgIds.length > 0) {
              merged = selectedBrands.map((objA: any) => {
                var matchedObject = orgIds.find((objB: any) => objB.organizationId === objA.organizationId);
                if (matchedObject) {
                  return { ...objA, fseId: matchedObject?.fseId };
                } return objA;
              });
              if (merged && merged.length > 0) selectedBrands = merged;
            }
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": "Government",
            "companyDetails": {
              "title": customerInfo && customerInfo.title,
              "companyName": customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerFirstName": customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerLastName": customerInfo.customerLastName ? customerInfo.customerLastName : '',
              "vatRegistrationNumber": "",
              "telephones": customerInfo.telephones ? customerInfo.telephones : customerInfo.companyDetails && customerInfo.companyDetails.telephones ? customerInfo.companyDetails.telephones : '',
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": customerInfo.emails ? customerInfo.emails : customerInfo.companyDetails && customerInfo.companyDetails.emails,
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
            // "organizationIds": selectedBrands.filter((brand: any) => brand.make !== 'Famco'),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": [],
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message) {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
      else if (sessionStorage.getItem('customerType') === 'new') {
        if (validateNewDealerDetailsTab()) {
          let selectedBrands: any[] = [];
          selectBrand && selectBrand.length > 0 && selectBrand.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.make === getActualBrand(element))[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = addStreet;
            headOfficeAddress.postalCode = "";
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = false;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }

          let merged: any = [];
          if (selectedBrands && selectedBrands.length > 0) {
            if (orgIds && orgIds.length > 0) {
              merged = selectedBrands.map((objA: any) => {
                var matchedObject = orgIds.find((objB: any) => objB.organizationId === objA.organizationId);
                if (matchedObject) {
                  return { ...objA, fseId: matchedObject?.fseId };
                } return objA;
              });
              if (merged && merged.length > 0) selectedBrands = merged;
            }
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
            "isNewCustomer": true,
            "customerTierType": "Government",
            "companyDetails": {
              "title": 'M/s',
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "telephones": [
                {
                  "country": country,
                  "telephone": telephoneOffice,
                  "phoneType": "PRIMARYMOBILE"
                }
              ],
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": [
                {
                  "emailId": dealerEmail,
                  "emailType": "PRIMARY"
                }
              ],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": "",
              "companyAnnualTurnover": "",
              "typeOfTrade": typeOfTrade,
              "poBox": poBox,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
            "stockOrderCurrencyType": "",
            "initialStockOrder": "",
            "totalInvesmentCurrencyType": "",
            "totalInvesment": "",
            "otherBusinessEstablishments": "",
            "otherBrandsOrProductsSold": "",
            "treadReference": [],
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    }
    else {
      if (sessionStorage.getItem('customerType') === 'existing') {
        if (validateExistingTab()) {
          let selectedBrands: any[] = [];
          customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.organizationId === element.organizationId)[0]);
          });
          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");

          if (headOfficeAddress) {
            headOfficeAddress.companyName = customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerFirstName = customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerLastName = customerInfo.customerLastName ? customerInfo.customerLastName : "";
            headOfficeAddress.telephoneNumber = getTelePhoneNumber(customerInfo);
            headOfficeAddress.poBox = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox;
            headOfficeAddress.city = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city;
            headOfficeAddress.country = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country;
            headOfficeAddress.streetName = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '';
            headOfficeAddress.postalCode = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '';
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = true;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].id ? customerInfo.addresses[0].id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].shippingLabels ? customerInfo.addresses[0].shippingLabels : shippingLabels;
          }

          let merged: any = [];
          if (selectedBrands && selectedBrands.length > 0) {
            if (orgIds && orgIds.length > 0) {
              merged = selectedBrands.map((objA: any) => {
                var matchedObject = orgIds.find((objB: any) => objB.organizationId === objA.organizationId);
                if (matchedObject) {
                  return { ...objA, fseId: matchedObject?.fseId };
                } return objA;
              });
              if (merged && merged.length > 0) selectedBrands = merged;
            }
          }

          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": "Fleet",
            "companyDetails": {
              "title": customerInfo && customerInfo.title,
              "companyName": customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerFirstName": customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerLastName": customerInfo.customerLastName ? customerInfo.customerLastName : '',
              "vatRegistrationNumber": "",
              "telephones": customerInfo.telephones ? customerInfo.telephones : customerInfo.companyDetails && customerInfo.companyDetails.telephones ? customerInfo.companyDetails.telephones : '',
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": customerInfo.emails ? customerInfo.emails : customerInfo.companyDetails && customerInfo.companyDetails.emails,
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": [],
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message) {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
      else if (sessionStorage.getItem('customerType') === 'new') {
        if (validateNewDealerDetailsTab()) {
          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.name && element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "companyName": element.companyName,
                "name": element.name,
                "addresses": element.addresses,
                "telephones": [{
                  "country": "-",
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })
          let selectedBrands: any[] = [];
          selectBrand && selectBrand.length > 0 && selectBrand.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.make === getActualBrand(element))[0]);
          });

          const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.streetName = addStreet;
            headOfficeAddress.postalCode = "";
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = false;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = "user"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }

          let merged: any = [];
          if (selectedBrands && selectedBrands.length > 0) {
            if (orgIds && orgIds.length > 0) {
              merged = selectedBrands.map((objA: any) => {
                var matchedObject = orgIds.find((objB: any) => objB.organizationId === objA.organizationId);
                if (matchedObject) {
                  return { ...objA, fseId: matchedObject?.fseId };
                } return objA;
              });
              if (merged && merged.length > 0) selectedBrands = merged;
            }
          }
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
            "isNewCustomer": true,
            "customerTierType": "Fleet",
            "companyDetails": {
              "title": 'M/s',
              "companyName": companyName ? companyName : "" + ' ' + customerInfo && customerInfo.customerLastName ? customerInfo.customerLastName : "",
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "telephones": [
                {
                  "country": country,
                  "telephone": telephoneOffice,
                  "phoneType": "PRIMARYMOBILE"
                }
              ],
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "emails": [
                {
                  "emailId": dealerEmail,
                  "emailType": "PRIMARY"
                }
              ],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": poBox,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : creditLimitSelector,
            "initialStockOrder": creditLimit,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": traderefe,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    }
  }

  const handleNextClick = async () => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer') {
      props.setTabValue(1);
    } else {
      saveDetails(true);
    }
  }

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      if (sessionStorage.getItem('customerOnboardingType') === 'workshop' || sessionStorage.getItem('customerOnboardingType') === 'fleet' || sessionStorage.getItem('customerOnboardingType') === 'Fleet' || sessionStorage.getItem('customerOnboardingType') === 'Government' || sessionStorage.getItem('customerOnboardingType') === 'government' || sessionStorage.getItem('customerOnboardingType') === 'Dealer' || sessionStorage.getItem('customerOnboardingType') === 'dealer' || sessionStorage.getItem('customerOnboardingType') === 'Workshop') {
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if (onboardingType && onboardingType.toLowerCase() !== 'workshop' && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        // data = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
      } else {
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if (onboardingType && onboardingType.toLowerCase() !== 'workshop' && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        // data = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
      }
      let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
      let workshopExist: any = brandsList.data.filter((brand: any) => brand.displayName !== "Famco" && brand.displayName !== 'All');
      setBrandList(data);
      setOesBrandList(oesBrands);
      setExistWorkShopBrand(workshopExist)
    }
  }, [brandsList])

  const handleClose = () => {
    setOpen(false);
  };

  function resetValue() {
    handleClose();
    dispatch(resetValues());
  }

  useEffect(() => {
    if (savePartnerData && savePartnerData.data && savePartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(savePartnerData.data.message.message)
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
    }
  }, [savePartnerData]);

  useEffect(() => {
    if (deletePartnerData && deletePartnerData.data && deletePartnerData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deletePartnerData.data.message.message)
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
      dispatch(getAllPartners(b2bUserId));
    }
  }, [deletePartnerData]);

  useEffect(() => {
    if (editPartnerData && editPartnerData.data && editPartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(editPartnerData.data.message.message)
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
    }
  }, [editPartnerData]);

  const setOwnerData = (data: any) => {
    dispatch(savePartnerDetails(data));
  }

  const updateNewPartnerData = (data: any) => {
    dispatch(editPartnerDetails(data));
  }

  const onClickDeleteAddress = (partnerData: any) => {
    setTypeOfDelete('address')
    setDeleteMessage("Are you sure you want to delete this Owner/Partner ?");
    handleopenClose(true);
    setSelectedPartnerData(partnerData);
  }
  const onClickDeleteTradeRef = () => {
    setTypeOfDelete('trade')
    setDeleteMessage("Are you sure you want to delete this Trade Reference ?");
    handleopenClose(true);
  }

  const deletePartner = () => {
    dispatch(deletePartnerDetails(selectedPartnerData.id, b2bUserId));
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsView(false);
  };

  const addTradeRow = () => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors];
    if (!isDisabledTradeRow()) {
      if (trade[trade.length - 1].companyName || trade[trade.length - 1].name || trade[trade.length - 1].addresses || trade[trade.length - 1].telephone) {
        const data = {
          companyName: "",
          name: "",
          addresses: "",
          telephone: ""
        }
        const errors =
        {
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        }

        // validateDealerDetailsTradeReferance();
        trade.push(data);
        setTradeReference(trade);
        tradeError.push(errors)
        setTradeReferenceErrors(tradeError);
      }
    }
  }

  const populateTradeDetails = (data: any) => {
    if (data && data.data && data.data.tradeReferance && !dataFetched) {
      let trade: any = [];
      data.data.tradeReferance.map((tl: any) => {
        trade.push({
          companyName: tl.companyName,
          name: tl.name,
          addresses: tl.addresses,
          telephone: tl.telephone,
        })
      })
      if (data.data.tradeReferance.length === 0) {
        trade.push({
          companyName: "",
          name: "",
          addresses: "",
          telephone: "",
        })
      }
      setTradeReference(trade);
      setSelectedTradeReference(trade);
      handleopenClose(false);
    }
  }

  const validateTradeLicense = (value: any, index: any) => {
    let trade = [...tradeReference];
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(value)) {
      // trade[index].isError = true;
      // trade[index].errormessage = "special characters not allowed";
    }
    else {
      // trade[index].errormessage = ""
      // trade[index].isError = false;
    }
  }

  const deleteTradeRow = (index: any) => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors]
    trade = trade.filter((data, i) => {
      if (index != i) {
        return data;
      }
    })
    tradeError = trade.filter((data, i) => {
      if (i <= trade.length) {
        return data;
      }
    })
    setTradeReferenceErrors(tradeError);
    setTradeReference(trade);
    handleopenClose(false);
  }

  const isDisabledTradeRow = () => {
    let tradeError = [...tradeReferenceErrors]
    let length = tradeReference.length - 1;
    tradeError[length].companyNameError = false;
    tradeError[length].nameError = false;
    tradeReferenceErrors[length].addressError = false;
    if (tradeReference.length && ((tradeReference[length].companyName == "" || tradeReference[length].name == "" || tradeReference[length].addresses == "" || tradeReference[length].telephone == ""))) {
      // if (tradeReference.length == 1) {
      if (tradeReference[length].companyName == "") {
        tradeError[length].companyNameError = true;
      }
      else {
        tradeError[length].companyNameError = false;
      }
      if (tradeReference[length].name == "") {
        tradeError[length].nameError = true;
      }
      else {
        tradeError[length].nameError = false;
      }
      if (tradeReference[length].addresses == "") {
        tradeReferenceErrors[length].addressError = true;
      }
      else {
        tradeReferenceErrors[length].addressError = false;
      }
      if (tradeReference[length].telephone == "") {
        tradeReferenceErrors[length].telephoneError = true;
      }
      else {
        tradeReferenceErrors[length].telephoneError = false;
      }
      setTradeReferenceErrors(tradeError)
      // }
      return true;
    }
    return false;
  }

  const selectAllBrands = () => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      brandsList.data.map((brand: any) => data.push(brand.displayName));
      setSelectBrand(data);
    }
  }

  const handleSelectAllClick = (event: any, value: any) => {
    if (event.target.checked && value === 'All') {
      selectAllBrands();
    } else if (event.target.checked === false && value === 'All') {
      setBrandList([]);
    }
  };

  //TABLE HEADER CODE
  function EnhancedTableHead(props: any) {
    let data: any = onboardingType && onboardingType.toLowerCase() === 'workshop' ? workshopHeadCells : headCells;
    return (
      <TableHead>
        <TableRow>
          {data.map((headCell: any) => (
            <>
              <TableCell
                className={classes.bodyContent}
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {headCell.label}
              </TableCell>
            </>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const verifySuccess = () => {
    props.loadDealerDetails();
  }

  return (
    <Fragment>
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <div className='card m-0 p-2'>
        <div className="row m-0">
          <div className="col-12">
            <h6 className="dealer-table-content mt-2 mb-3">Company Details</h6>
          </div>
          {loading && (<FullscreenLoader />)}
          {sessionStorage.getItem('customerType') === 'existing' ? (
            <>
              <div className="col-md-4 col-lg-4">
                <TextField id="headOfficeRegion"
                  error={headOfficeRegionError}
                  helperText={invalidHeadOfficeRegion}
                  disabled
                  // disabled={isDisabled() ? isDisabled() : customerInfo && customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].region ? true : false}
                  required
                  value={selectedRegion && selectedRegion.Region ? selectedRegion.Region : headOfficeRegion ? headOfficeRegion : ""}
                  onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={onboardingType && onboardingType.toLowerCase() === 'workshop' ? "Trade Point Counter Location" : "Head Office Regon"} variant="outlined" margin="dense">
                </TextField>
              </div>
              <div className="col-md-4 col-lg-4">
                <KeyboardDatePicker
                  required={isGovernment() && customerType === "existing" ? false : true}
                  disableFuture
                  views={["year", "month", "date"]}
                  openTo="year"
                  disabled={isDisabled()}
                  autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                  onError={(e, v) => datePickerValid(e, setInvalidDate)}
                  format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
                  onChange={(date: Date | null) => {
                    props.setDataUpdated(true);
                    if (date) {
                      setEstablishedDate(date);
                      setEstablishedDateError(false)
                    } else {
                      if (!isGovernment()) setEstablishedDateError(true)
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className="rounded-input-dealer w-100 mt-2 cal-icon"
                  error={!isGovernment() ? establishedDateError || invalidDate : false}
                  helperText={invalidDate && !isGovernment() && <span style={{ color: 'red' }}>{error}</span>}
                />
              </div>
              {isGovernment() ? '' : (
                <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 onboarding-field">
                  <TextField
                    disabled
                    defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                  <TextField id="annualTurnover salestoCustomerKam" type="number"
                    value={annualTurnover}
                    // error={annualTurnoverSelectorError}
                    // helperText={invalidAnnualTurnover}
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    disabled={isDisabled()}
                    onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
                    size="small" className="col-sm-9 commonRoundedInputs-textfield aed1-width"
                    label="Annual Turnover" variant="outlined" margin="dense" />
                </div>
              )}
              <div className="col-md-4 col-lg-4">
                <TextField select id="dealingIn"
                  error={dealingInError}
                  helperText={invalidDealingIn}
                  disabled={isDisabled()}
                  required={isGovernment() ? false : true}
                  value={dealingIn}
                  onChange={(e: any) => handleInputChange(e, setDealingIn,
                    setDealingInError, setInvalidDealingIn)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type Of Ownership" variant="outlined" margin="dense" >
                  {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                    dealingInData.data[0].regions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="typeOfTrade"
                  // error={typeOfTradeError}
                  // helperText={invalidTypeOfTrade}
                  disabled
                  select
                  value={typeOfTrade}
                  defaultValue={typeOfTrade}
                  onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type of Trade" required variant="outlined" margin="dense">
                  {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              </div>
              {customerInfo && (
                <div className="row m-0 col-12 mt-3">
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    <div className="info-sub-title-value font-size-12">Company Name Line 1</div>
                    <div className="subTitle mt-2">{customerInfo.title}{' '}{customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails && customerInfo.companyDetails.companyName ? customerInfo.companyDetails.companyName : '-'}</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    <div className="info-sub-title-value font-size-12">Company Name Line 2</div>
                    <div className="subTitle mt-2">{customerInfo.customerLastName ? customerInfo.customerLastName : '-'}</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    <div className="info-sub-title-value font-size-12"> VAT Registration No</div>
                    <div className="subTitle mt-2">-</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    {sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="info-sub-title-value font-size-12">Contact Telephone Number</div> : <div className="info-sub-title-value font-size-12">Telephone Office(Landline No.)</div>}
                    <div className="subTitle mt-2">{customerInfo.telephones && customerInfo.telephones.length > 0 ? customerInfo.telephones[0].telephone : customerInfo && customerInfo.companyDetails && customerInfo.companyDetails.telephones && customerInfo.companyDetails.telephones.length > 0 && customerInfo.companyDetails.telephones[0].telephone ? customerInfo.companyDetails.telephones[0].telephone : '-'}</div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    <div className="info-sub-title-value font-size-12">Customer Email</div>
                    <div className="subTitle mt-2">{customerInfo.emails && customerInfo.emails.length > 0 ? customerInfo.emails[0].emailId : customerInfo && customerInfo.companyDetails && customerInfo.companyDetails.emails && customerInfo.companyDetails.emails.length > 0 && customerInfo.companyDetails.emails[0].emailId ? customerInfo.companyDetails.emails[0].emailId : '-'}</div>
                  </div>
                  {customerInfo.addresses && customerInfo.addresses.length > 0 && (
                    <>
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">PO Box</div>
                        <div className="subTitle mt-2">{customerInfo.addresses[0].poBox ? customerInfo.addresses[0].poBox : '-'}</div>
                      </div>
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">Street Name</div>
                        <div className="subTitle mt-2">{customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '-'}</div>
                      </div>
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">Country</div>
                        <div className="subTitle mt-2">{customerInfo.addresses[0].country ? customerInfo.addresses[0].country : '-'}</div>
                      </div>
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">City</div>
                        <div className="subTitle mt-2">{customerInfo.addresses[0].city ? customerInfo.addresses[0].city : '-'}</div>
                      </div>
                      {!isGovernment() && !isFleet() && (
                        <div className="col-3 col-md-3 col-sm-3 mb-4">
                          <div className="info-sub-title-value font-size-12">Postal Code</div>
                          <div className="subTitle mt-2">{customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '-'}</div>
                        </div>
                      )}
                    </>
                  )}
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    <div className="info-sub-title-value font-size-12">Al-Futtaim Customer Number</div>
                    <div className="subTitle mt-2">{customerInfo.customerCode ? customerInfo.customerCode : customerInfo.sapCustomerId}</div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <div className="col-md-4 col-lg-4">
                <TextField required id="companyName"
                  value={companyName}
                  error={companyNameError}
                  inputProps={{ maxLength: 40 }}
                  // onChange={(e: any) => onChangeCompany(e)}
                  onChange={(e: any) => handleInputChange(e, setCompanyName, setCompanyNameError, setCompanyNameErrorMessage, "companyName")}
                  disabled={isDisabled()}
                  label="Company Name Line 1" className="commonRoundedInputs w-100"
                  helperText={companyNameError && <span style={{ color: 'red' }}>{companyNameErrorMessage}</span>}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                    endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                  }} margin="dense" variant="outlined" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="companyNameLine2"
                  value={customerLastName}
                  onChange={(e: any) => handleInputChange(e, setCustomerLastName, setCustomerLastNameError, setCustomerLastNameErrorMessage, "companyNameLine2")}
                  disabled={isDisabled()}
                  inputProps={{ maxLength: 40 }}
                  label="Company Name Line 2" className="commonRoundedInputs w-100"
                  InputProps={{
                    // startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                    endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                  }} margin="dense" variant="outlined" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="vatRegistrationNumber"
                  type="number"
                  required={customerOnboardingType && customerOnboardingType.toLowerCase() === 'workshop' ? false : true}
                  value={vatRegistrationNumber}
                  disabled={isDisabled()}
                  error={vatRegistrationNumberError}
                  helperText={vatRegistrationNumberError && <span style={{ color: 'red' }}>{VatRegistrationNumberErrorMessage}</span>}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  onChange={(e: any) => handleInputChange(e, setVatRegistrationNumber, setVatRegistrationNumberError, setVatRegistrationNumberErrorMessage, "vatReg")}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="VAT Registration Number" variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="telephoneOffice"
                  error={telephoneOfficError}
                  type="number"
                  disabled={isDisabled()}
                  helperText={telephoneOfficError && <span style={{ color: 'red' }}>{TelephoneOfficeErrorMessage}</span>}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  onChange={(e: any) => handleInputChange(e, setTelephoneOffice, setTelephoneOfficeError, setTelephoneOfficeErrorMessage, "telephone")}
                  value={telephoneOffice} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={onboardingType && onboardingType.toLowerCase() === 'workshop' ? "Contact Telephone Number" : "Telephone Office(Landline No.)"} required variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <KeyboardDatePicker disableFuture autoOk size="small" disabled={isDisabled()}
                  required={isGovernment() ? false : true}
                  disableToolbar variant="inline" inputVariant="outlined"
                  views={["year", "month", "date"]}
                  openTo="year"
                  onError={(e, v) => datePickerValid(e, setInvalidEstablishedDate)}
                  format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
                  error={!isGovernment() ? establishedDateError || invalidDate : false}
                  helperText={invalidDate && !isGovernment() && <span style={{ color: 'red' }}>{error}</span>}
                  onChange={(date: Date | null) => {
                    props.setDataUpdated(true);
                    if (date) {
                      setEstablishedDate(date);
                      setEstablishedDateError(false)
                    } else {
                      if (!isGovernment()) setEstablishedDateError(true)
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField select id="headOfficeRegion"
                  error={headOfficeRegionError}
                  helperText={invalidHeadOfficeRegion}
                  value={selectedRegion && selectedRegion.Code ? selectedRegion.Code : headOfficeRegion}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={onboardingType && onboardingType.toLowerCase() === 'workshop' ? "Trade Point Counter Location" : "Head Office Region"} required variant="outlined" margin="dense" >
                  {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                </TextField>
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="dealerEmail"
                  error={dealerEmailError}
                  value={dealerEmail}
                  disabled={isDisabled()}
                  helperText={invalidDealerEmail && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                  onChange={(e: any) => handleInputChange(e, setDealerEmail, setDealerEmailError, setInvalidDealerEmail)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Customer Email" required variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField select id="dealingIn"
                  error={dealingInError}
                  helperText={invalidDealingIn}
                  value={dealingIn}
                  required={isGovernment() ? false : true}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setDealingIn, setDealingInError, setInvalidDealingIn)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type Of Ownership" variant="outlined" margin="dense" >
                  {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                    dealingInData.data[0].regions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              </div>
              {isGovernment() ? '' : (
                <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 onboarding-field">
                  <TextField disabled defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                  <TextField id="annualTurnover" type="number"
                    value={annualTurnover}
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    disabled={isDisabled()}
                    onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
                    size="small" className="col-sm-9 commonRoundedInputs-textfield aed1-width"
                    label="Annual Turnover" variant="outlined" margin="dense" />
                </div>
              )}
              <div className="col-md-4 col-lg-4">
                <TextField id="typeOfTrade"
                  // error={typeOfTradeError}
                  // helperText={invalidTypeOfTrade}
                  value={typeOfTrade}
                  disabled
                  required
                  select
                  onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type of Trade" variant="outlined" margin="dense">
                  {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="poBox"
                  type="number"
                  error={poBoxError}
                  value={poBox}
                  onChange={(e: any) => handleInputChange(e, setPoBox, setPoBoxError, setPoBoxErrorMessage, "pobox")}
                  disabled={isDisabled()}
                  helperText={poBoxError && <span style={{ color: 'red' }}>{PoBoxErrorMessage}</span>}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="PO Box" required={onboardingType && onboardingType.toLowerCase() === 'workshop' ? false : true} variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="addStreet"
                  error={addStreetError}
                  value={addStreet}
                  // disabled
                  onChange={(e: any) => handleInputChange(e, setAddStreet, setAddStreetError, setInvalidAddStreet, "street")}
                  // helperText={addStreetError && <span style={{ color: 'red' }}>{addStreetErrorMessage}</span>}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Street Name" required variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField id="city"
                  error={cityError}
                  value={city}
                  // disabled
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setCity, setCityError, setInvalidCity)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="City" required variant="outlined" margin="dense" />
              </div>
              <div className="col-md-4 col-lg-4">
                <TextField select id="country"
                  error={countryError}
                  value={country}
                  disabled
                  onChange={(e: any) => handleInputChange(e, setCountry, setCountryError, setCountryErrorMessage, "country")}
                  // disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Country" required variant="outlined" margin="dense">
                  {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
                </TextField>
              </div>
              {!isGovernment() && !isFleet() && (
                <div className="col-md-4 col-lg-4">
                  <TextField id="postalCode"
                    type="number"
                    error={onboardingType && onboardingType.toLowerCase() == 'workshop' ? false : postalCodeError}
                    helperText={onboardingType && onboardingType.toLowerCase() == 'workshop' ? !invalidPostalCode : invalidPostalCode}
                    value={postalCode}
                    // disabled
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    disabled={isDisabled()}
                    onChange={(e: any) => handleInputChange(e, setPostalCode, setPostalCodeError, setInvalidPostalCode)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Postal Code" required={onboardingType && onboardingType.toLowerCase() == 'workshop' ? false : true} variant="outlined" margin="dense" />
                </div>
              )}
            </>
          )}
          <div className="row col-12  mt-2 mb-2 pl-3 border-top">
            <div className="col-lg-4 mt-3">
              <h6 className="dealer-table-content mb-2">Owner/Partners Details</h6>
            </div>
            <div className="col-md-8 bg-white pb-0 pl-lg-10 mt-3">
              <Button variant="contained" color="primary"
                className="blueActionButton border-left rounded-pill pr-0 w-200p ml-2 mr-2 float-right"
                disabled={isDisabled()}
                onClick={() => {
                  handleClickOpen();
                  setTypeOfDailog('add')
                }}>Add Owner/Partner
              </Button>
            </div>
            {getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length > 0 && (
              <div className="row col-12 mt-2 mb-2 pl-3 border-top">
                <div className="col-lg-12 mt-3">
                  <TableContainer>
                    <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                      <EnhancedTableHead numSelected={selected.length} />
                      <TableBody>
                        <>
                          {getAllPartnersData.data.map((newPartnerData: any, index: any) => (
                            <TableRow hover tabIndex={-1} key={index}>
                              <TableCell component="th"
                                scope="row" padding="none" className="document-tablecell-data">
                                {newPartnerData.firstName}{'  '}{newPartnerData.lastName}
                              </TableCell>
                              <TableCell align="left" className="document-tablecell-data">
                                {newPartnerData.email}
                              </TableCell>
                              <TableCell align="left" className="document-tablecell-data">
                                {newPartnerData.mobile}
                              </TableCell>
                              {onboardingType && onboardingType.toLowerCase() !== 'workshop' && (
                                <TableCell align="left" className="document-tablecell-data">
                                  {onboardingType && onboardingType.toLowerCase() !== 'workshop' ? newPartnerData.nationality : '-'}
                                </TableCell>
                              )}
                              <TableCell align="left" className="document-tablecell-data">
                                {newPartnerData.designation ? newPartnerData.designation : '-'}
                              </TableCell>
                              <TableCell align="left" className="document-tablecell-data">
                                {newPartnerData.emiratesIdNumber}
                              </TableCell>
                              <TableCell align="left">
                                <div className="row">
                                  <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                    {isAdmin && (
                                      <Button
                                        onClick={() => {
                                          setTypeOfDailog('view')
                                          handleEditPartnerDetails(newPartnerData, true);
                                        }}
                                        className='text-info'
                                        startIcon={<Visibility />}
                                      >
                                        View
                                      </Button>
                                    )}
                                    {/* <ButtonGroup variant="text" color="primary" aria-label="text primary button group"> */}
                                    {isAdmin && (
                                      <Button
                                        className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                        disabled={isDisabled()}
                                        startIcon={<EditOutlinedIcon />}
                                        onClick={() => {
                                          setTypeOfDailog('edit')
                                          handleEditPartnerDetails(newPartnerData, false);
                                        }}>
                                        Edit
                                      </Button>
                                    )}
                                    {isAdmin && (
                                      <Button
                                        onClick={() => onClickDeleteAddress(newPartnerData)}
                                        className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                        disabled={isDisabled()}
                                        startIcon={<DeleteOutlineOutlinedIcon />}
                                      >
                                        Delete
                                      </Button>
                                    )}
                                  </ButtonGroup>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))
                          }
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            )}
            <Dialog className="pb-0"
              open={open}
              onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                  handleClose()
                }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              fullWidth={true}
              maxWidth="md"
              disableEscapeKeyDown>
              <AddNewOwnerPartnerDialog
                setOpenDialog={handleClose} setOwnerData={setOwnerData}
                updateNewPartnerData={updateNewPartnerData}
                isView={isView} typeofDailog={typeofDailog}
              />
            </Dialog>
          </div>
          <div className="row col-12  mt-2 mb-3 pl-3 border-top">
            <div className="col-12 mt-3">
              <h6 className="dealer-table-content mb-2">Brand & Others</h6>
            </div>
            {sessionStorage.getItem('customerType') === 'existing' && onboardingType && onboardingType.toLowerCase() !== 'workshop' ? (
              <>
                <div className="info-sub-title-value col-12">Brands</div>
                <div className="mt-3">
                  {customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.map((org: any, index: any) => {
                    const brand: any = brands.filter(x => x.organizationId === org.organizationId);
                    if (org.organizationId && org.organizationId !== '2003' && brand && brand.length > 0) {
                      return (
                        <Button key={index} onClick={() => {
                        }}
                          className="brandsButton mr-2 cursor-default"
                          variant="contained">
                          {(getNewBrand(brands.filter(x => x.organizationId === org.organizationId)[0].make)) === 'Tradepoint' ? 'ALAC' : getNewBrand(brands.filter(x => x.organizationId === org.organizationId)[0].make)}
                        </Button>)
                    }
                  })
                  }
                </div>
              </>
            ) : sessionStorage.getItem('customerType') === 'existing' && onboardingType && onboardingType.toLowerCase() === 'workshop' ?
              <>
                <div className="info-sub-title-value col-12">Brands</div>
                <div className="mt-3">
                  {existWorkShop.map((ele: any, index: any) => (
                    <Button key={index} onClick={() => {
                    }}
                      className="brandsButton mr-2 cursor-default"
                      variant="contained">
                      {ele.displayName}
                    </Button>
                  ))}
                </div>
              </> :
              (
                <>
                  <div className="col-md-8 col-lg-8">
                    <FormControl className="commonRoundedInputs w-100 mt-2" size="small" variant="outlined">
                      <InputLabel error={selectBrandError} id="companyBrandsLabel">Select Brand *</InputLabel>
                      <Select
                        multiple
                        required
                        variant="outlined"
                        className={classes.dropdown}
                        disabled={isDisabled()}
                        value={selectBrand}
                        error={selectBrandError}
                        onChange={(e: any) => handleInputChange(e, setSelectBrand, setSelectBrandError, setInvalidSelectBrand, 'brand')}
                        input={<OutlinedInput label="Select Brand" />}
                        renderValue={(selected: any) => selected && selected.join(', ')}>
                        {brandList && brandList.length > 0 && brandList.map((brand: any, i: any) => (
                          <MenuItem key={i} value={brand.brandType === "OES" && onboardingType && onboardingType.toLowerCase() !== 'workshop' ? 'ALAC' : brand.displayName}>
                            <Checkbox onChange={(e: any) => {
                              handleSelectAllClick(e, brand.brandType === "OES" && onboardingType && onboardingType.toLowerCase() !== 'workshop' ? 'ALAC' : brand.displayName)
                            }}
                              // disabled={brand.brandType === "OES"}
                              checked={selectBrand && selectBrand.includes(brand.displayName) ? true : false}
                            />
                            {/* <ListItemText primary={brand.displayName} /> */}
                            <p className="pr-2"> {brand.brandId}</p><p className="pr-2">{"-"}</p>
                            <span className={selectBrand && selectBrand.includes(brand.displayName) ? "brandsButton-content" : ""}>{brand.brandType === "OES" && onboardingType && onboardingType.toLowerCase() !== 'workshop' ? 'ALAC' : brand.displayName}</span>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                  {!isGovernment() && (
                    <>
                      {onboardingType && onboardingType.toLowerCase() !== 'workshop' && (
                        <>
                          <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                            <TextField select id="creditLimitSelector"
                              error={creditLimitSelectorError}
                              value={creditLimitSelector} disabled defaultValue={currencies[0]}
                              onChange={(e: any) => handleInputChange(e, setCreditLimitSelector, setCreditLimitSelectorError, setInvalidCreditLimitSelector)}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                            <TextField id="creditLimit"
                              type="number"
                              error={creditLimitError}
                              helperText={invalidCreditLimit}
                              value={creditLimit}
                              disabled={isDisabled()}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => handleInputChange(e, setCreditLimit, setCreditLimitError, setInvalidCreditLimit, 'intialStock')}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                              label="Initial Stock Order*" variant="outlined" margin="dense" />
                          </div>
                          <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                            <TextField select id="totalInvestmentSelector"
                              error={totalInvestmentSelectorError}
                              value={totalInvestmentSelector}
                              disabled onChange={(e: any) => handleInputChange(e, setTotalInvestmentSelector, setTotalInvestmentSelectorError, setInvalidTotalInvestmentSelector)}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                              {currencies.map(item => (
                                <MenuItem key={item} value={item}>{item}</MenuItem>))}
                            </TextField>
                          </div>
                          <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                            <TextField id="totalInvestment"
                              type="number"
                              error={totalInvestmentError}
                              helperText={invalidTotalInvestment}
                              value={totalInvestment}
                              disabled={isDisabled()}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => handleInputChange(e, setTotalInvestment, setTotalInvestmentError, setInvalidTotalInvestment)}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                              label={`Total Investment${isFleet() ? "" : "*"}`} variant="outlined" margin="dense" />
                          </div>
                        </>
                      )}
                      <div className="col-md-4 col-lg-4">
                        <TextField id="otherBusinessEstablishments"
                          // error={otherBusinessEstablishmentsError}
                          // helperText={otherBusinessEstablishmentsError && <span style={{ color: 'red' }}>{OtherBusinessEstablishmentsErrorMessage}</span>}
                          value={otherBusinessEstablishments}
                          onChange={(e: any) => handleInputChange(e, setOtherBusinessEstablishments)}
                          disabled={isDisabled()}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Other Business Establishments" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField id="otherBrandsProductsSold"
                          // error={otherBrandsProductsSoldError}
                          // helperText={otherBrandsProductsSoldError && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                          disabled={isDisabled()}
                          value={otherBrandsProductsSold}
                          onChange={(e: any) => handleInputChange(e, setOtherBrandsProductsSold, setOtherBrandsProductsSoldError, "", "brandproductsold")}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Other Brands/Products Sold" variant="outlined" margin="dense" />
                      </div>
                    </>
                  )}
                </>
              )}
          </div>
          {sessionStorage.getItem('customerType') === 'existing' || isGovernment() || (onboardingType && onboardingType.toLowerCase() == 'workshop') ? '' : (
            <>
              <div className="row col-md-12 m-0 border-top pl-3" >
                <h6 className="dealer-table-content mb-2 mt-4">Trade Reference</h6>
              </div>
              {tradeReference.map((tradeItem: any, index: any) => {
                return (
                  <div className="col-md-12 m-0" key={index}>
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.name}
                          type="text"
                          disabled={isDisabled()}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "name")}
                          helperText={tradeReferenceErrors[index].nameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].nameErrorMessage}</span>}
                          name={tradeItem.name + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Name" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.companyName}
                          type="text"
                          disabled={isDisabled()}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "companyName")}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                          }}
                          helperText={tradeReferenceErrors[index].companyNameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].companyNameErrorMessage}</span>}
                          name={tradeItem.companyName + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Company Name" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.addresses}
                          type="text"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "addresses")}
                          disabled={isDisabled()}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].addressErrorMessage}</span>}
                          inputProps={{ maxLenght: 30 }}
                          name={tradeItem.addresses + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Address" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.telephone}
                          type="number"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError}
                          onChange={(e: any) => tradeReferenceItem(e, index, 'telephone')}
                          disabled={isDisabled()}
                          onKeyDown={(e: any) => ["e", "E", "-"].includes(e.key) && e.preventDefault()}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].telephoneErrorMessage}</span>}
                          inputProps={{ maxLenght: 20 }}
                          name={tradeItem.telephone + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Telephone" variant="outlined" margin="dense" />
                      </div>
                      {
                        tradeReference.length !== 1 ?
                          <div className="col-md-4 col-lg-2">
                            <IconButton className={isDisabled() ? "text-info disabledCustomclass mr-1 mt-n2" : 'text-info mr-1 mt-n2'} size="small"
                              disabled={isDisabled()}
                              onClick={() => {
                                onClickDeleteTradeRef();
                                setTradeRowRefDeleteIndex(index)
                                // deleteTradeRow(index)
                              }}                          >
                              <DeleteIcon fontSize="inherit" />
                              <span className="font-size-13 load-from-fav ml-1 mt-0">
                                Delete
                              </span>
                            </IconButton>
                          </div>
                          : ""}
                    </div>
                  </div>
                )
              })}
              <div className="row col-md-12 m-0">
                <div className="row-space-start">
                  <Button
                    className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                    startIcon={<AddIcon className="font-size-18" />}
                    disabled={isDisabled()}
                    // disabled={isDisabled() ? isDisabled() : isDisabledTradeRow()}
                    onClick={() => addTradeRow()}
                  ><span className="onshiftoparty-font">Add More
                    </span>
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
        <div className="col-12 display-flex p-0">
          <div className="col-6 p-0">
            <div className="row m-0">
              <div className='px-3'></div>
              {isKamOnboarded ? '' : (
                <div className='row col-6 p-0 customer-type-popup'>
                  <div className="p-0">
                    <p className={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? "mt-2 px-5 customer-type-font" : "mt-3 px-5 customer-type-font"}>Customer Type</p>
                    {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' ? (
                      <p className="customer-header-font ml-5 mt-2 mb-2 text-capitalize">{sessionStorage.getItem('customerType')}{' '} {sessionStorage.getItem('customerOnboardingType')} User</p>
                    ) : (
                      <p className="cursor-pointer px-5 mt-2" onClick={() => { changeDealerType() }}>
                        <span className="customer-header-font text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                          {sessionStorage.getItem('customerOnboardingType') == 'Fleet' || sessionStorage.getItem('customerOnboardingType') == 'fleet' ? 'Corporate (Fleet)' : sessionStorage.getItem('customerOnboardingType')}</span><KeyboardArrowDownIcon className='customer-font' />{'  '}<span className="customer-sub-header-font">Change Type</span>
                      </p>
                    )}
                  </div>
                </div>
              )}
              {dealerDetails && dealerDetails.data && dealerDetails.data.status !== 'Submitted' && dealerDetails && dealerDetails.data && dealerDetails.data.status !== 'Active' && (
                <div className="col-4 mt-4 p-0 px-3">
                  <Button variant="contained" className="border-left rounded-pill ml-4 w-150p mb-2"
                    onClick={() => saveDetails(false)}
                  >Save Form</Button>
                </div>
              )}
            </div>
          </div>
          <div className="col-6 mt-1 onboard-footer float-right">
            <div className="p-3 mr-3">
              <Button variant="contained" color="primary" className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2"
                onClick={() => { handleNextClick() }}>Next
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DeleteDialog
        open={openDeleteDialog}
        type={typeOfDelete}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            deletePartner()
          }
          else if (data === 'tradeRef') {
            deleteTradeRow(tradeRowRefDeleteIndex)
          }
          if (!data) handleopenClose(false);
        }} />
      <DeleteDialog
        open={confirmDialog}
        text="Are you sure you want to switch from the current Customer type. By doing so, all your saved data will be lost."
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteCustomerData();
          if (!data) {
            // loadPartners();
            handleopenCloseConfirmPopup(false);
          }
        }} />
    </Fragment >
  )
}