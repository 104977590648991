/* eslint-disable */
import TextField from "@material-ui/core/TextField";
import { useState, useEffect, Dispatch } from "react";
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import MenuItem from "@material-ui/core/MenuItem";
import { getYears } from "../../helpers/commonmethod";
import { Divider } from '@material-ui/core';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import React from "react"
import {  makeStyles, } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {  getPurChaseHistoryByYear } from "../../store/actions/achievement.actions";
import XLSX from "xlsx";
import { getNewBrand } from "../../common/components/util";
import _ from "lodash";


const styles = makeStyles({
  notchedOutline: { borderColor: "rgba(224, 224, 224, 1) !important", borderRadius: "25px" },
  root: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "6px !important",
      paddingTop: "6px !important",
      borderRadius: "25px !important",
    },
  },
})

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

const PurChaseHistory: React.FC = () => {

  const [selectYear, setSelectYear] = useState<any>(new Date().getUTCFullYear());
  const [currentQuarter, setCurrentQuarter] = useState<string>("")

  const [purchaseHistoryData, setPurchaseHistoryData] = useState<any>()

  //All brands
  const [yearlyTotal, setYearlyTotal] = useState<any>(0);
  const [yearlyTarget, setYearlyTarget] = useState<any>(0);

  const [quarterlyTotal, setQuarterlyTotal] = useState<any>(0);
  const [quartelyTarget, setQuarterlyTarget] = useState<any>(0);

  const [monthlyTotal, setMonthlyTotal] = useState<any>(0);
  const [monthlyTarget, setMonthlyTarget] = useState<any>(0);

  const [month, setMonth] = useState<string>(months[new Date().getMonth()])
  const [quarter, setQuarter] = useState<string>("");

  //different brands
  const [brandsTotal, setBrandsTotal] = useState<any>()
  const [brandsTarget, setBrandsTarget] = useState<any>()

  const [quarterDropdown, setQuarterDropdown] = useState<any>()
  const [monthlyDropdown, setMonthlyDropdown] = useState<any>()

  const [quaterlyBrandTotal, setQuaterlyTotal] = useState<any>()
  const [monthlyBrandTotal, setMonthlyBrandTotal] = useState<any>()

  const [quaterlyBrandTarget, setQuaterlyTarget] = useState<any>()
  const [monthlyBrandTarget, setMonthlyBrandTarget] = useState<any>()

  const [changeQuater, setChangeQuarter] = useState<any>(["", ""])
  const [changeMonth, setChangeMonth] = useState<any>(["", ""])
  const [count, setCount] = useState(0);

  const [toyotaMonthsData, setToyotaMonthsData] = useState()
  const [isTargetSet, setTargetNotSet] = useState<boolean>(false)
  const [achievement, setAchievementNotFound] = useState(false)

  const years = getYears();
  const history = useHistory()

  const classes = styles()
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { purchaseHistory }: any = useSelector((state: any) => state.achievement);
  const { purchaseHistoryByYear }: any = useSelector((state: any) => state.achievement);

  const { purchaseHistoryKam }: any = useSelector((state: any) => state.achievement);
  const { purchaseHistorySuperAdmin }: any = useSelector((state: any) => state.achievement)
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);

  const sapCustomerIdNew: any = selectedNewDealer && selectedNewDealer.sapCustomerId;

  const b2bUserId = _.get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");

  const orgIdBrandsMapping: any = {
    "2001": "Toyota",
    "2002": "Honda",
    "2003": "Famco",
    "2380": "TE",
    "2195": "ALAC"
  }

  useEffect(() => {
    //setPurchaseHistoryData(purchaseHistoryByYear&&purchaseHistoryByYear.data&&purchaseHistoryByYear.data.isTargetSetForGivenYear===true&&purchaseHistoryByYear&&purchaseHistoryByYear) 
    setPurchaseHistoryData(purchaseHistoryByYear && purchaseHistoryByYear.data && purchaseHistoryByYear.data.isTargetSetForGivenYear === true && purchaseHistoryByYear && purchaseHistoryByYear)

  }, [purchaseHistoryByYear])

  //Initial current month quarter Calculations
  useEffect(() => {

    const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const currentMonth = new Date();
    const name = currentMonth.getMonth()
    const currentYear = new Date().getUTCFullYear();

    let valuesMonth: any = []
    const len = purchaseHistoryData && purchaseHistoryData.purchaseHistoryData && purchaseHistoryData.purchaseHistory && purchaseHistoryData.purchaseHistory.data && purchaseHistoryData.purchaseHistory.data.targetsData && purchaseHistoryData.purchaseHistory.data.targetsData && purchaseHistoryData.purchaseHistory.data.targetsData.brandTargets.length

    if (name <= 3) {
      setQuarter("Q1")
    } else if (name <= 5) {
      setQuarter("Q2")
    } else if (name <= 8) {
      setQuarter("Q3")
    } else if (name <= 6) {
      setQuarter("Q4")
    }

    for (let i = 0; i < len; i++) {
      valuesMonth[i] = month[new Date().getMonth()]
    }
    setMonthlyDropdown(valuesMonth)
  }, [])

  useEffect(() => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let quarters = ""
    let currentMonth: Array<string> = []
    if (quarter === "Q1") {
      currentMonth = months.slice(0, 3)
      quarters = "quarter1"
    } else if (quarter === "Q2") {
      currentMonth = months.slice(3, 6)
      quarters = "quarter2"
    } else if (quarter === "Q3") {
      currentMonth = months.slice(6, 9)
      quarters = "quarter3"
    } else if (quarter === "Q4") {
      currentMonth = months.slice(9, 12)
      quarters = "quarter4"
    }

    let yearlyTotals = 0
    let yearlyTarget = 0
    let quaterlyTotal = 0
    let quaterlyTarget = 0
    let monthlyTotal = 0
    let monthlyTarget = 0

    const res = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.length >= 1 && purchaseHistoryData.data.purchaseData.map((ele: any) => {
      yearlyTotals += parseInt(ele.purchaseAmount)
    })
    const res1 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
      yearlyTarget += ele && ele.yearlyTaget && ele.yearlyTaget.amount
    })
    const res2 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele: any) => {
      if (currentMonth.includes(ele.month)) {
        quaterlyTotal += parseInt(ele.purchaseAmount)
      }
    })

    let quarterTargteAmount: Array<any> = []

    const res3 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.filter((ele: any) => {
      quarterTargteAmount.push(ele && ele.quarterlyTaget && ele.quarterlyTaget[quarters])
    })

    const res4 = quarterTargteAmount.map((ele: any) => {
      quaterlyTarget += ele && ele.amount
    })

    const res5 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele: any) => {
      if (ele.month === month.split("").slice(0, 3).join("")) {
        monthlyTotal += parseInt(ele.purchaseAmount)
      }
    })

    let monthylTagetAmount: Array<any> = []

    const res6 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
      monthylTagetAmount.push(ele && ele.monthlyTaget[month.split("").slice(0, 3).join("")])
    })

    const res7 = monthylTagetAmount.map((ele: any) => {
      monthlyTarget += ele && parseInt(ele.amount)
    })

    if (yearlyTotals >= 0) {
      setYearlyTotal(yearlyTotals)
    }
    if (yearlyTarget >= 0) {
      setYearlyTarget(yearlyTarget)
    }
    if (quaterlyTotal >= 0) {
      setQuarterlyTotal(quaterlyTotal)
    } if (quaterlyTarget >= 0) {
      setQuarterlyTarget(quaterlyTarget)
    } if (monthlyTotal >= 0) {
      setMonthlyTotal(monthlyTotal)
    } if (monthlyTarget >= 0) {
      setMonthlyTarget(monthlyTarget)
    }

    //all-brands-total
    const len = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.length
    let values: any = []
    let valuesTarget: any = []

    for (let i = 0; i < len; i++) {
      values.push(0)
      valuesTarget.push(0)
    }


    const res8 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: any) => {
      purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele2: any) => {
        if (ele.brandId === ele2.organizationId) {
          values[i] += parseInt(ele2.purchaseAmount)
        }
      })
    })

    const res9 = count === 0 && purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: any) => {
      valuesTarget[i] = (ele && ele.yearlyTaget && ele.yearlyTaget.amount)
    })
    const res10 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
      purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele2: any) => {
        if (ele.brandId === ele2.organizationId) {
        }
      })
    })
  }, [quarter, purchaseHistoryData, month])



  //Calculations for all brands
  useEffect(() => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let quarters = ""
    let currentMonth: Array<string> = []
    if (quarter === "Q1") {
      currentMonth = months.slice(0, 3)
      quarters = "quarter1"
    } else if (quarter === "Q2") {
      currentMonth = months.slice(3, 6)
      quarters = "quarter2"
    } else if (quarter === "Q3") {
      currentMonth = months.slice(6, 9)
      quarters = "quarter3"
    } else if (quarter === "Q4") {
      currentMonth = months.slice(9, 12)
      quarters = "quarter4"
    }

    let yearlyTotals = 0
    let yearlyTarget = 0
    let quaterlyTotal = 0
    let quaterlyTarget = 0
    let monthlyTotal = 0
    let monthlyTarget = 0

    const res = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.length >= 1 && purchaseHistoryData.data.purchaseData.map((ele: any) => {
      yearlyTotals += parseInt(ele.purchaseAmount)
    })
    const res1 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
      yearlyTarget += ele && ele.yearlyTaget && ele.yearlyTaget.amount
    })
    const res2 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele: any) => {
      if (currentMonth.includes(ele.month)) {
        quaterlyTotal += parseInt(ele.purchaseAmount)
      }
    })

    let quarterTargteAmount: Array<any> = []

    const res3 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.filter((ele: any) => {
      quarterTargteAmount.push(ele && ele.quarterlyTaget && ele.quarterlyTaget[quarters])
    })

    const res4 = quarterTargteAmount.map((ele: any) => {
      quaterlyTarget += ele && ele.amount
    })

    const res5 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele: any) => {
      if (ele.month === month.split("").slice(0, 3).join("")) {
        monthlyTotal = parseInt(ele.purchaseAmount)
      }
    })

    let monthylTagetAmount: Array<any> = []

    const res6 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
      monthylTagetAmount.push(ele && ele.monthlyTaget[month.split("").slice(0, 3).join("")])
    })

    const res7 = monthylTagetAmount.map((ele: any) => {
      monthlyTarget += ele && parseInt(ele.amount)
    })


    // if (yearlyTotals >= 0) {
    //   setYearlyTotal(yearlyTotals)
    // }
    // if (yearlyTarget >= 0) {
    //   setYearlyTarget(yearlyTarget)
    // }
    // if (quaterlyTotal >= 0) {
    //   setQuarterlyTotal(quaterlyTotal)
    // } if (quaterlyTarget >= 0) {
    //   setQuarterlyTarget(quaterlyTarget)
    // } if (monthlyTotal >= 0) {
    //   setMonthlyTotal(monthlyTotal)
    // } if (monthlyTarget >= 0) {
    //   setMonthlyTarget(monthlyTarget)
    // }

    //all-brands-total
    const len = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.length
    let values: any = []
    let valuesTarget: any = []

    for (let i = 0; i < len; i++) {
      values.push(0)
      valuesTarget.push(0)
    }

    const res8 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: any) => {
      purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele2: any) => {
        if (ele.brandId === ele2.organizationId) {
          values[i] += parseInt(ele2.purchaseAmount)
        }
      })
    })

    const res9 = count === 0 && purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: any) => {
      valuesTarget[i] = (ele && ele.yearlyTaget && ele.yearlyTaget.amount)
    })

    if (count <= 1) {
      setBrandsTotal(values);
    }
    if (count <= 1) {
      setBrandsTarget(valuesTarget);
    }
    const res10 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
      purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.map((ele2: any) => {
        if (ele.brandId === ele2.organizationId) {
        }
      })
    })

  }, [purchaseHistoryData, month])


  useEffect(() => {
    const month1 = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
    const currentMonth1 = new Date();
    const name1 = currentMonth1.getMonth();
    let valuesQuarter: any = []
    let valuesMonth: any = []
    let valuesQuarterTotal: any = []
    let valuesQuarterTarget: any = []
    let valuesMonthTotal: any = []
    let valuesMonthTarget: any = []

    const len = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.length

    for (let i = 0; i < len; i++) {

      valuesQuarter.push("")
      valuesMonth.push("")
      valuesQuarterTotal.push(0)
      valuesQuarterTarget.push(0)
      valuesMonthTotal.push(0)
      valuesMonthTarget.push(0)
    }

    for (let i = 0; i < len; i++) {
      if (name1 <= 3) {
        valuesQuarter[i] = "Q1"
      } else if (name1 <= 5) {
        valuesQuarter[i] = "Q2"
      } else if (name1 <= 8) {
        valuesQuarter[i] = "Q3"
      } else if (name1 <= 6) {
        valuesQuarter[i] = "Q4"
      }
    }

    for (let i = 0; i < len; i++) {
      valuesMonth[i] = month1[new Date().getMonth()]
    }

    setQuarterDropdown(valuesQuarter.length >= 0 && valuesQuarter)
    setMonthlyDropdown(valuesMonth)
    setQuaterlyTotal(valuesQuarterTotal)
    setMonthlyBrandTotal(valuesMonthTotal)
    setMonthlyBrandTarget(valuesMonthTarget)
    setQuaterlyTarget(valuesQuarterTarget)

  }, [purchaseHistoryData])



  useEffect(() => {
    //  let currentTotal=[...quaterlyBrandTotal]

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let quarters = ""
    let currentMonth: Array<string> = []
    let quarterValues: any = []
    const len = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.length
    const monthValues: any = []


    for (let i = 0; i < len; i++) {
      quarterValues.push(0);
      monthValues.push(0);
      if (quarterDropdown && quarterDropdown[i] === "Q1") {
        currentMonth = months.slice(0, 3)
        quarters = "quarter1"
      } else if (quarterDropdown && quarterDropdown[i] === "Q2") {
        currentMonth = months.slice(3, 6)
        quarters = "quarter2"
      } else if (quarterDropdown && quarterDropdown[i] === "Q3") {
        currentMonth = months.slice(6, 9)
        quarters = "quarter3"
      } else if (quarterDropdown && quarterDropdown[i] === "Q4") {
        currentMonth = months.slice(9, 12)
        quarters = "quarter4"
      }
    }

    const res10 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: any) => {

      const result = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.filter((ele2: any) => {
        // if (ele.brandId === ele2.organizationId && currentMonth.includes(ele2.month)) {

        // }
        return ele.brandId === ele2.organizationId && currentMonth.includes(ele2.month)

      })
      let sum = 0

      if (result.length >= 1) {
        result.forEach((ele: any) => {

          sum += parseInt(ele.purchaseAmount)
          let values1 = [...quaterlyBrandTotal]
          quarterValues[i] = sum != 0 && sum
          if (count <= 1) {
            setQuaterlyTotal(quarterValues)
          }
        })
      }
    })

    let quarterTargteAmount: Array<any> = []

    const res3 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.filter((ele: any) => {
      quarterTargteAmount.push(ele && ele.quarterlyTaget && ele.quarterlyTaget[quarters])
    })

    let arr: any = []
    const res4 = count === 0 && quarterTargteAmount && quarterTargteAmount.forEach((ele: any, i: any) => {
      arr.push(ele && ele.amount)
      if (count === 0) {
        setQuaterlyTarget(arr)
      }
    })



    const res5 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: any) => {

      let res1 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.filter((ele2: any) => {
        // return ele.brandId === ele2.organizationId && ele2.month === monthlyDropdown[i] && monthlyDropdown[i].split("").slice(0, 3).join("")
        return ele.brandId === ele2.organizationId
        // // monthlyTotal = ele.purchaseAmount

        // sum2+=parseInt(ele2.purchaseAmount)

        // values1[i] += sum2
        // if (count<=1) {
        //   setMonthlyBrandTotal(values1)
        // }

      })
      let res2 = res1.filter((ele: any) => {
        return monthlyDropdown[i] && monthlyDropdown[i].split("").slice(0, 3).join("") == ele.month
      })

      let values1 = [...monthlyBrandTotal]
      let sum2 = 0
      if (res2.length >= 1) {
        res2.forEach((ele: any) => {
          sum2 = ele.purchaseAmount
          monthValues[i] = sum2
          if (count <= 1) {
            setMonthlyBrandTotal(monthValues)
          }
        })
      }
    })

    let monthlyTargetAmount: any = []

    const res6 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.filter((ele: any, i: any) => {
      monthlyTargetAmount.push(monthlyDropdown && ele && ele.monthlyTaget && ele.monthlyTaget[monthlyDropdown.length >= 1 && monthlyDropdown[i]==="September"? monthlyDropdown.length >= 1 &&  monthlyDropdown[i].split("").slice(0, 4).join(""): monthlyDropdown.length >= 1 &&  monthlyDropdown[i].split("").slice(0, 3).join("")])
    })
    

    let arr1: any = []
    const res7 = count === 0 && monthlyTargetAmount && monthlyTargetAmount.forEach((ele: any, i: any) => {
      arr1.push(ele && ele.amount)
      if (count === 0) {
        setMonthlyBrandTarget(arr1)
      }
    })

  }, [purchaseHistoryData, monthlyDropdown])


  //For quarterly calculations dropdown
  useEffect(() => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
    let quarters = ""
    let currentMonth: Array<string> = [];

    if (changeQuater[0] === "Q1") {
      currentMonth = months.slice(0, 3)
      quarters = "quarter1"
    } else if (changeQuater[0] === "Q2") {
      currentMonth = months.slice(3, 6)
      quarters = "quarter2"
    } else if (changeQuater[0] === "Q3") {
      currentMonth = months.slice(6, 9)
      quarters = "quarter3"
    } else if (changeQuater[0] === "Q4") {
      currentMonth = months.slice(9, 12)
      quarters = "quarter4"
    }

    const res1 =
      purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.filter((ele2: any) => {
        return changeQuater[2] == ele2.organizationId && currentMonth.includes(ele2.month)
      })

    let sum: any = 0
    const res2 = res1 && res1.length >= 1 && res1.forEach((ele: any) => {
      sum += parseInt(ele.purchaseAmount)
    })

    if (sum && sum >= 0) {
      let values1 = [...quaterlyBrandTotal]
      values1[changeQuater[1]] = sum
      setQuaterlyTotal(values1)
    }
    else if (quaterlyBrandTotal && res1 && res1.length >= 0) {
      let values1 = [...quaterlyBrandTotal]
      values1[changeQuater[1]] = 0
      setQuaterlyTotal(values1)
    }

    let quarterTargteAmount: any = []
    const res3 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.filter((ele: any) => {
      quarterTargteAmount.push(ele && ele.quarterlyTaget && ele.quarterlyTaget[quarters])
    })

    let arr: any = []

    const res4 = quarterTargteAmount && quarterTargteAmount.forEach((ele: any, i: any) => {
      arr.push(ele && ele.amount)
    })

    let sum2 = arr[changeQuater[1]]

    if (sum2 == 0 || sum2 >= 0) {
      let values1 = [...quaterlyBrandTarget]
      values1[changeQuater[1]] = sum2
      setQuaterlyTarget(values1)
    }

  }, [changeQuater])

  //forMonthly calculations Dropdown
  useEffect(() => {
    //MonthlyBrandTotal
    const res5 =
      purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.purchaseData.filter((ele2: any) => {
        return changeMonth[2] === ele2.organizationId && ele2.month === changeMonth[0].split("").slice(0, 3).join("")
      })
    let sum3: any = 0
    const res6 = res5 && res5.length >= 1 && res5.forEach((ele: any) => {
      sum3 += parseInt(ele.purchaseAmount)
    })

    if (sum3 && sum3 >= 0) {
      let values1 = [...monthlyBrandTotal]
      values1[changeMonth[1]] = sum3
      setMonthlyBrandTotal(values1)
    }
    else if (monthlyBrandTotal && res5 && res5.length >= 0) {
      let values1 = [...monthlyBrandTotal]
      values1[changeMonth[1]] = 0
      setMonthlyBrandTotal(values1)
    }

    let monthlyTargetAmount: any = []

    const res7 = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.filter((ele: any, i: any) => {
      monthlyTargetAmount.push(ele && ele.monthlyTaget && ele.monthlyTaget[changeMonth[0] === "September" ? changeMonth[0].split("").slice(0, 4).join("") : changeMonth[0].split("").slice(0, 3).join("")])
    })

    let arr1: any = []
    const res8 = monthlyTargetAmount && monthlyTargetAmount.forEach((ele: any, i: any) => {
      arr1.push(ele && ele.amount)

      if (count >= 1) {
        let values1 = monthlyBrandTarget && [...monthlyBrandTarget]
        values1[changeMonth && changeMonth[1]] = arr1 && arr1[changeMonth && changeMonth[1]]
        setMonthlyBrandTarget(values1)
      }
    })
  }, [changeMonth])


  // EXPORT TO EXCEL FUNCTIONALITY
  const exportData = () => {
    if (purchaseHistoryData && purchaseHistoryData.data) {
      let filename = `Purchase_history_${selectYear}.xlsx`;

      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
      const len = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.length

      const sortPurchaseBrands = purchaseHistoryData && purchaseHistoryData.data &&
        purchaseHistoryData.data.purchaseData.sort((a: any, b: any) => {
          return a.organizationId - b.organizationId
        });

      const filterByBrand = (id: any, monthName: any) => {
        const filterByBrandID = sortPurchaseBrands.filter((ele: any) => {
          return ele.organizationId === id
        })

        const filterByBrandMonthName = filterByBrandID.length >= 1 && filterByBrandID.filter((ele2: any) => {
          return ele2.month === monthName

        })
        return filterByBrandMonthName.length === 1 ? filterByBrandMonthName && filterByBrandMonthName[0] && parseInt(filterByBrandMonthName[0].purchaseAmount) : 0
      }

      let allBrandsData: any = []

      const res1: any = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData &&
        purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any) => {
          allBrandsData.push([
            { Period: "January", Sales: filterByBrand(ele.brandId, "Jan"), Target: parseInt(ele.monthlyTaget?.Jan.amount) },
            { Period: "February", Sales: filterByBrand(ele.brandId, "Feb"), Target: parseInt(ele.monthlyTaget?.Feb.amount) },
            { Period: "March", Sales: filterByBrand(ele.brandId, "Mar"), Target: parseInt(ele.monthlyTaget?.Mar.amount) },
            { Period: "April", Sales: filterByBrand(ele.brandId, "Apr"), Target: parseInt(ele.monthlyTaget?.Apr.amount) },
            { Period: "May", Sales: filterByBrand(ele.brandId, "May"), Target: parseInt(ele.monthlyTaget?.May.amount) },
            { Period: "June", Sales: filterByBrand(ele.brandId, "Jun"), Target: parseInt(ele.monthlyTaget?.Jun.amount) },
            { Period: "July", Sales: filterByBrand(ele.brandId, "Jul"), Target: parseInt(ele.monthlyTaget?.Jul.amount) },
            { Period: "August", Sales: filterByBrand(ele.brandId, "Aug"), Target: parseInt(ele.monthlyTaget?.Aug.amount) },
            { Period: "September", Sales: filterByBrand(ele.brandId, "Sept"), Target: parseInt(ele.monthlyTaget?.Sept.amount) },
            { Period: "October", Sales: filterByBrand(ele.brandId, "Oct"), Target: parseInt(ele.monthlyTaget?.Oct.amount) },
            { Period: "November", Sales: filterByBrand(ele.brandId, "Nov"), Target: parseInt(ele.monthlyTaget?.Nov.amount) },
            { Period: "December", Sales: filterByBrand(ele.brandId, "Dec"), Target: parseInt(ele.monthlyTaget?.Dec.amount) },
            { Period: "Quarter 1", Sales: filterByBrand(ele.brandId, "Jan") + filterByBrand(ele.brandId, "Feb") + filterByBrand(ele.brandId, "Mar"), Target: parseInt(ele.quarterlyTaget?.quarter1.amount) },
            { Period: "Quarter 2", Sales: filterByBrand(ele.brandId, "Apr") + filterByBrand(ele.brandId, "May") + filterByBrand(ele.brandId, "Jun"), Target: parseInt(ele.quarterlyTaget?.quarter2.amount) },
            { Period: "Quarter 3", Sales: filterByBrand(ele.brandId, "Jul") + filterByBrand(ele.brandId, "Aug") + filterByBrand(ele.brandId, "Sept"), Target: parseInt(ele.quarterlyTaget?.quarter3.amount) },
            { Period: "Quarter 4", Sales: filterByBrand(ele.brandId, "Oct") + filterByBrand(ele.brandId, "Nov") + filterByBrand(ele.brandId, "Dec"), Target: parseInt(ele.quarterlyTaget?.quarter4.amount) },
            {
              Period: "Yearly", Sales: (parseInt(filterByBrand(ele.brandId, "Jan") + filterByBrand(ele.brandId, "Feb") + filterByBrand(ele.brandId, "Mar") +
                filterByBrand(ele.brandId, "Apr") + filterByBrand(ele.brandId, "May") + filterByBrand(ele.brandId, "Jun") + filterByBrand(ele.brandId, "Jul") + filterByBrand(ele.brandId, "Aug") + filterByBrand(ele.brandId, "Sept")
                + filterByBrand(ele.brandId, "Oct") + filterByBrand(ele.brandId, "Nov") + filterByBrand(ele.brandId, "Dec"))),
              Target: (parseInt(ele.yearlyTaget.amount))
            },
          ]);
        })

      let allBrandsDataTotal: any = [
        { Period: "January", Sales: 0, Target: 0 },
        { Period: "February", Sales: 0, Target: 0 },
        { Period: "March", Sales: 0, Target: 0 },
        { Period: "April", Sales: 0, Target: 0 },
        { Period: "May", Sales: 0, Target: 0 },
        { Period: "June", Sales: 0, Target: 0 },
        { Period: "July", Sales: 0, Target: 0 },
        { Period: "August", Sales: 0, Target: 0 },
        { Period: "September", Sales: 0, Target: 0 },
        { Period: "October", Sales: 0, Target: 0 },
        { Period: "November", Sales: 0, Target: 0 },
        { Period: "December", Sales: 0, Target: 0 },
        { Period: "Quarter 1", Sales: 0, Target: 0 },
        { Period: "Quarter 2", Sales: 0, Target: 0 },
        { Period: "Quarter 3", Sales: 0, Target: 0 },
        { Period: "Quarter 4", Sales: 0, Target: 0 },
        {
          Period: "Yearly", Sales: parseInt(yearlyTotal),
          Target: parseInt(yearlyTarget)
        },
      ];

      const res3 = allBrandsData.map((ele: any) => {
        allBrandsDataTotal[0].Sales += parseInt(ele[0].Sales)
        allBrandsDataTotal[0].Target += parseInt(ele[0].Target)

        allBrandsDataTotal[1].Sales += parseInt(ele[1].Sales)
        allBrandsDataTotal[1].Target += parseInt(ele[1].Target)

        allBrandsDataTotal[2].Sales += parseInt(ele[2].Sales)
        allBrandsDataTotal[2].Target += parseInt(ele[2].Target)

        allBrandsDataTotal[3].Sales += parseInt(ele[3].Sales)
        allBrandsDataTotal[3].Target += parseInt(ele[3].Target)

        allBrandsDataTotal[4].Sales += parseInt(ele[4].Sales)
        allBrandsDataTotal[4].Target += parseInt(ele[4].Target)

        allBrandsDataTotal[5].Sales += parseInt(ele[5].Sales)
        allBrandsDataTotal[5].Target += parseInt(ele[5].Target)

        allBrandsDataTotal[6].Sales += parseInt(ele[6].Sales)
        allBrandsDataTotal[6].Target += parseInt(ele[6].Target)

        allBrandsDataTotal[7].Sales += parseInt(ele[7].Sales)
        allBrandsDataTotal[7].Target += parseInt(ele[7].Target)

        allBrandsDataTotal[8].Sales += parseInt(ele[8].Sales)
        allBrandsDataTotal[8].Target += parseInt(ele[8].Target)

        allBrandsDataTotal[9].Sales += parseInt(ele[9].Sales)
        allBrandsDataTotal[9].Target += parseInt(ele[9].Target)

        allBrandsDataTotal[10].Sales += parseInt(ele[10].Sales)
        allBrandsDataTotal[10].Target += parseInt(ele[10].Target)

        allBrandsDataTotal[11].Sales += parseInt(ele[11].Sales)
        allBrandsDataTotal[11].Target += parseInt(ele[11].Target)

        allBrandsDataTotal[12].Sales += parseInt(ele[12].Sales)
        allBrandsDataTotal[12].Target += parseInt(ele[12].Target)

        allBrandsDataTotal[13].Sales += parseInt(ele[13].Sales)
        allBrandsDataTotal[13].Target += parseInt(ele[13].Target)

        allBrandsDataTotal[14].Sales += parseInt(ele[14].Sales)
        allBrandsDataTotal[14].Target += parseInt(ele[14].Target)

        allBrandsDataTotal[15].Sales += parseInt(ele[15].Sales)
        allBrandsDataTotal[15].Target += parseInt(ele[15].Target)

        // allBrandsDataTotal[16].Sales += convertToInternationalCurrencySystem(parseInt(yearlyTotal))
        // allBrandsDataTotal[16].Target += convertToInternationalCurrencySystem(parseInt(yearlyTarget))


      })

      let allBrands: any = []

      let monthBrands: any = []
      const brand: any = purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: number, data: any) => {
        allBrands.push(getNewBrand(orgIdBrandsMapping[ele.brandId]))
        monthBrands.push(ele.monthlyTaget)
      })


      // var ws = XLSX.utils.json_to_sheet();
      var wb = XLSX.utils.book_new();
      allBrands.forEach((ele: any, i: any) => {
        return XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(allBrandsData[i]), ele)
      })
      XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(allBrandsDataTotal), "ALL_Brands")
      XLSX.writeFile(wb, filename);
    }
  };

  function convertToInternationalCurrencySystem(labelValue: any) {
    return Math.abs(Number(labelValue)) >= 1.0e+9
      ? (Math.abs(Number(labelValue)) / 1.0e+9).toFixed(2) + "B"
      : Math.abs(Number(labelValue)) >= 1.0e+6
        ? (Math.abs(Number(labelValue)) / 1.0e+6).toFixed(2) + "M"
        : Math.abs(Number(labelValue)) >= 1.0e+3
          ? (Math.abs(Number(labelValue)) / 1.0e+3).toFixed(2) + "K"
          : Math.abs(Number(labelValue));
  }

  const calculatePercentage = (achivement: any, target: any) => {
    let percent: any = ((Number(target) - Number(achivement)) / Number(target) * 100);
    percent = percent.toString().split("").includes(".") ? parseInt(percent).toFixed(0) : parseInt(percent)
    if (achivement === 0 || target === 0) {
      return "0%"
    } else if (((Number(target) - Number(achivement)) / Number(target)) === -1) {
      return "100%"
    }
    else {
      return Number.isNaN(parseInt(percent)) ? "0%" : percent.toString().split("")[0] === "-" ? (parseInt(percent)) * -1 + "%" : parseInt(percent) + "%";
    }
  }

  return (
    <div>
      {achievement === false ?
        (<>
          <div className="d-flex flex-lg-row flex-md-row flex-column align-items-center page-sub-title mt-3">
            <h6 className="heading-text-purchase-history">Purchase History</h6>
            <div className="ml-auto float-right mt-4">
              <div className="pb-2 d-flex flex-row flex-lg-row flex-md-column flex-column">
                <div className="ml-auto">
                  <TextField select
                    id="selectYear"
                    label="Select Year"
                    value={selectYear}
                    variant="outlined"
                    className="rounded-input-dealer mw-200p mr-2 mb-2"
                    size="small"
                    InputLabelProps={{
                      style: { color: '#000000' },
                    }}
                    onChange={(e: any) => {
                      setSelectYear(e.target.value);
                      let url = window.location.href.split("/")
                      let currentUrl = url[url.length - 1]
                      if (currentUrl === "achievementssuperadmin") {
                        dispatch(getPurChaseHistoryByYear(b2bUserId, webApplicationLoginId, sapCustomerIdNew, e.target.value, "superadmin"))
                      } if (currentUrl === "achievementskam") {
                        dispatch(getPurChaseHistoryByYear(b2bUserId, webApplicationLoginId, sapCustomerIdNew, e.target.value, "kam"))
                      } if (currentUrl === "achievements") {
                        dispatch(getPurChaseHistoryByYear(b2bUserId, webApplicationLoginId, sapCustomerId, e.target.value, "dealer"))
                      }

                    }}
                  >
                    {years.map((data: any, index: any) => (
                      <MenuItem key={index} value={data}> {data} </MenuItem>
                    ))}
                  </TextField>
                </div>
                {purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.isTargetSetForGivenYear === true &&
                  <Button className="float-right font-size-5 pl-0 addOrderEnqButton mb-4" onClick={() => {
                    exportData()
                  }}><div className="px-2 pr-2 mt-0 font-color-dealer" >Export Report</div></Button>}
              </div>
            </div>
          </div>

          {/* Purchase history for all brands */}
          {purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.isTargetSetForGivenYear === true ?
            (<>
              <div className="d-flex align-items-center page-sub-title brands-heading-text-purchase-history mt-3">All Brands</div>
              <div className="row mt-2">
                <div className="col-lg-4 col-sm-12 mb-2">
                  <h5 className="page-sub-title mb-2"></h5>
                  <div className="mt-1 cardCommon " style={{ overflow: "auto" }}>
                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row p-3">
                      <div className="font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Yearly Purchase ({selectYear})</div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row pl-3 mt-3 pr-3">
                      <h4 style={{ fontSize: "30px" }} className="pr-0 page-title col-md-6 col-lg-6 col-sm-6">{convertToInternationalCurrencySystem(yearlyTotal && yearlyTotal)}</h4>
                      <Divider orientation="vertical" flexItem className="">
                      </Divider>
                      <div className="col-md-6 col-lg-6 col-sm-6 ">
                        <Button className="ml-4 cursor" style={{ color: `${Number(yearlyTotal) > Number(yearlyTarget) ? "green" : "red"}` }} startIcon={Number(yearlyTotal) > Number(yearlyTarget) ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        ><span className="Font-changes">{calculatePercentage(yearlyTotal, yearlyTarget)}</span></Button>
                      </div>
                    </div>
                    <p className="pl-3 pt-2 pb-3 font-purchase-heading" style={{ fontSize: "12px" }}>Target : {convertToInternationalCurrencySystem(yearlyTarget)}</p>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-12 mb-2">
                  <h5 className="page-sub-title mb-2"></h5>
                  <div className="mt-1 cardCommon scroll-purchase">
                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row p-3">
                      <div className="font-achivement  font-purchase-heading" style={{ fontSize: "12px" }}>Quarterly Purchase</div>
                      <div className="ml-auto mw-50p float-right" >
                        <div className="">
                          <div className="ml-auto">
                            <Box >
                              <TextField select
                                id="2"
                                label="Quarter"
                                value={quarter}
                                variant="outlined"
                                className=" achivemnt-text"
                                size="small"
                                InputLabelProps={{
                                  style: { color: '#000000' },
                                }}
                                onChange={(e: any) => {
                                  setQuarter(e.target.value)
                                }}
                                onClick={() => {
                                  setCount(count + 2)
                                }}
                                style={{ width: "102px" }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes.notchedOutline,
                                    root: classes.root
                                  }
                                }}
                              >
                                {["Q1", "Q2", "Q3", "Q4"].map((data: any, index: any) => (
                                  <MenuItem key={index} value={data}> {data} </MenuItem>
                                ))}
                              </TextField>
                            </Box>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row pl-3 pr-3">
                      <div style={{ fontSize: "30px" }} className="pr-0 page-title col-md-6 col-lg-6 col-sm-6">{convertToInternationalCurrencySystem(quarterlyTotal)}</div>
                      <Divider orientation="vertical" flexItem className="align-items-center"></Divider>
                      <div className="col-md-6 col-lg-6 col-sm-6 ">
                        <Button className="ml-4 cursor" style={{ color: `${quarterlyTotal > quartelyTarget ? "green" : "red"}`, cursor: "text" }} startIcon={quarterlyTotal > quartelyTarget ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        ><span className="Font-changes">{calculatePercentage(quarterlyTotal, quartelyTarget)}</span></Button>
                      </div>
                    </div>
                    <p className="pl-3 pt-3 pb-3 font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Target : {convertToInternationalCurrencySystem(quartelyTarget)}</p>
                  </div>
                </div>

                <div className="col-lg-4 col-sm-12 mb-2">
                  <h5 className="page-sub-title mb-2"></h5>
                  <div className="mt-1 cardCommon scroll-purchase">
                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row p-3">
                      <div className="font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Monthly Purchases</div>
                      <div className="ml-auto mw-50p float-right">
                        <div className=" d-flex flex-row">
                          <div className="ml-auto">
                            <TextField select
                              id="selectYear"
                              label="Month"
                              value={month}
                              variant="outlined"
                              className=" mw-10p"
                              size="small"
                              InputLabelProps={{
                                style: { color: '#000000' },
                              }}
                              onChange={(e: any) => {
                                setMonth(e.target.value)
                              }}
                              style={{ width: "102px" }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes.notchedOutline,
                                  root: classes.root
                                }
                              }}
                            >
                              {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((data: any, index: any) => (
                                <MenuItem key={index} value={data}> {data} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row pl-3 pr-3">
                      <div style={{ fontSize: "30px" }} className="pr-0 page-title col-md-6 col-lg-6 col-sm-6">{convertToInternationalCurrencySystem(monthlyTotal)}</div>
                      <Divider orientation="vertical" flexItem className="align-items-center"></Divider>
                      <div className="col-md-6 col-lg-6 col-sm-6 ">
                        <Button className="ml-4 cursor" style={{ color: `${Number(monthlyTotal) > Number(monthlyTarget) ? "green" : "red"}` }} startIcon={Number(monthlyTotal) > Number(monthlyTarget) ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        ><span className="Font-changes">{calculatePercentage(monthlyTotal, monthlyTarget)}</span></Button>
                      </div>
                    </div>
                    <p className="pl-3 pt-3 pb-3 font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Target : {convertToInternationalCurrencySystem(monthlyTarget)}</p>
                  </div>
                </div>
              </div>

              {purchaseHistoryData && purchaseHistoryData.data && purchaseHistoryData.data.targetsData && purchaseHistoryData.data.targetsData.brandTargets && purchaseHistoryData.data.targetsData.brandTargets.map((ele: any, i: number, data: any) => {
                return (<>
                  <div className="d-flex align-items-center page-sub-title brands-heading-text-purchase-history mt-3">{getNewBrand(orgIdBrandsMapping[ele.brandId])}</div>
                  <div className="row ">
                    <div className="col-lg-4 col-sm-12 mb-2">
                      <h5 className="page-sub-title mb-2"></h5>
                      <div className="mt-1 cardCommon scroll-purchase">
                        <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row p-3">
                          <div className="font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Yearly Purchases ({selectYear})</div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row pl-3 mt-3 pr-3">
                          <div style={{ fontSize: "30px" }} className="pr-0 page-title col-md-6 col-lg-6 col-sm-6">{convertToInternationalCurrencySystem(brandsTotal && brandsTotal[i])}</div>
                          <Divider orientation="vertical" flexItem className="align-items-center"></Divider>
                          <div className="col-md-6 col-lg-6 col-sm-6 ">
                            <Button className="ml-4 cursor" style={{ color: `${brandsTotal && Number(brandsTotal[i]) > Number(brandsTarget && Number(brandsTarget[i])) ? "green" : "red"}` }} startIcon={Number(brandsTotal && brandsTotal[i]) > Number(brandsTarget && Number(brandsTarget[i])) ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            ><span className="Font-changes">{calculatePercentage(brandsTotal && brandsTotal[i], brandsTarget && brandsTarget[i])}</span></Button>
                          </div>
                        </div>
                        <p className="pl-3 pt-2 pb-3 font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Target : {convertToInternationalCurrencySystem(brandsTarget && brandsTarget[i])}</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 mb-2">
                      <h5 className="page-sub-title mb-2"></h5>
                      <div className="mt-1 cardCommon scroll-purchase">
                        <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row p-3">
                          <div className="font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Quarterly Purchase</div>
                          <div className="ml-auto float-right">
                            <div className=" d-flex flex-row">
                              <div className="ml-auto">
                                <TextField select
                                  id={`s${i}`}
                                  label="Quarter"
                                  value={quarterDropdown && quarterDropdown.length > 0 && quarterDropdown[i]}
                                  variant="outlined"
                                  className="mw-10p "
                                  size="small"
                                  InputLabelProps={{
                                    style: { color: '#000000' },
                                  }}
                                  onChange={(e: any) => {
                                    let currentstate = [...quarterDropdown];
                                    currentstate[i] = e.target.value;
                                    setQuarterDropdown(currentstate)
                                    setChangeQuarter([e.target.value, i, ele.brandId])
                                    setCount(count + 2)
                                  }}
                                  style={{ width: "102px" }}
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline,
                                      root: classes.root
                                    }
                                  }}
                                >
                                  {["Q1", "Q2", "Q3", "Q4"].map((data: any, index: any) => (
                                    <MenuItem key={index} value={data}> {data} </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row pl-3 pr-3">
                          <div style={{ fontSize: "30px" }} className="pr-0 page-title col-md-6 col-lg-6 col-sm-6">{convertToInternationalCurrencySystem(quarterDropdown && quaterlyBrandTotal[i])}</div>
                          <Divider orientation="vertical" flexItem className="align-items-center"></Divider>
                          <div className="col-md-6 col-lg-6 col-sm-6 ">
                            <Button className="ml-4 cursor" style={{ color: `${Number(quaterlyBrandTotal && quaterlyBrandTotal[i]) > Number(quaterlyBrandTarget && quaterlyBrandTarget[i]) ? "green" : "red"}` }} startIcon={Number(quaterlyBrandTotal && quaterlyBrandTotal[i]) > Number(quaterlyBrandTarget && quaterlyBrandTarget[i]) ? < ArrowUpwardIcon /> : < ArrowDownwardIcon />}
                            ><span className="Font-changes">{calculatePercentage(quaterlyBrandTotal && quaterlyBrandTotal[i], quaterlyBrandTarget && quaterlyBrandTarget[i])}</span></Button>
                          </div>
                        </div>
                        <p className="pl-3 pt-3 pb-3 font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Target : {convertToInternationalCurrencySystem(quaterlyBrandTarget && quaterlyBrandTarget[i])}</p>
                      </div>
                    </div>

                    <div className="col-lg-4 col-sm-12 mb-2">
                      <h5 className="page-sub-title mb-2"></h5>
                      <div className="mt-1 cardCommon scroll-purchase">
                        <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-lg-row flex-md-row flex-sm-column flex-column p-3">
                          <div className="font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Monthly Purchases</div>
                          <div className="ml-auto float-right">
                            <div className=" d-flex flex-row">
                              <div className="ml-auto">
                                <TextField select
                                  id={`m${i}`}
                                  label="Month"
                                  value={monthlyDropdown && monthlyDropdown.length > 0 && monthlyDropdown[i]}
                                  variant="outlined"
                                  className="mw-10p "
                                  size="small"
                                  InputLabelProps={{
                                    style: { color: '#000000' },
                                  }}
                                  onChange={(e: any) => {
                                    let currentstate = [...monthlyDropdown]
                                    currentstate[i] = e.target.value
                                    setMonthlyDropdown(currentstate)
                                    setChangeMonth([e.target.value, i, ele.brandId])
                                    setCount(count + 2)
                                  }}
                                  style={{ width: "102px" }}
                                  InputProps={{
                                    classes: {
                                      notchedOutline: classes.notchedOutline,
                                      root: classes.root
                                    }
                                  }}
                                >
                                  {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"].map((data: any, index: any) => (
                                    <MenuItem key={index} value={data}> {data} </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-lg-12 col-sm-12 d-flex flex-row pl-3 pr-3">
                          <div style={{ fontSize: "30px" }} className="pr-0 page-title col-md-6 col-lg-6 col-sm-6">{convertToInternationalCurrencySystem(monthlyBrandTotal && monthlyBrandTotal[i])}</div>
                          <Divider orientation="vertical" flexItem className="align-items-center"></Divider>
                          <div className="col-md-6 col-lg-6 col-sm-6 ">
                            <Button className="ml-4 cursor" style={{ color: `${monthlyBrandTotal && Number(monthlyBrandTotal[i]) > Number(monthlyBrandTarget && monthlyBrandTarget[i]) ? "green" : "red"}` }} startIcon={monthlyBrandTotal && Number(monthlyBrandTotal[i]) > Number(monthlyBrandTarget && monthlyBrandTarget[i]) ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                            ><span className="Font-changes">{calculatePercentage(monthlyBrandTotal && monthlyBrandTotal[i], monthlyBrandTarget && monthlyBrandTarget[i])}</span></Button>
                          </div>
                        </div>
                        <p className="pl-3 pt-3 pb-3 font-achivement font-purchase-heading" style={{ fontSize: "12px" }}>Target : {convertToInternationalCurrencySystem(monthlyBrandTarget && monthlyBrandTarget[i])}</p>
                      </div>
                    </div>
                  </div>
                </>)
              })
              }
            </>
            )
            : <h3 className="font-achivement">Targets not set for the desired year!</h3>}
        </>) : <h1></h1>
      }
    </div>
  )
}
export default PurChaseHistory;