/* eslint-disable */
import _ from "lodash";
import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const specialprice = {
    getSpecialPrice, getSpecialPriceStatus, viewSpecialPriceAPI, addComment, addCommentAll, getCommentPart, 
    getCommentPartTotal, postSaveFormData, postForwardRequestToKam, rejectRequest, rejectPart, checkandavaliabilityApi,
    statusReopened, userAccesssRestriction,getRequestCorrection
}


async function getSpecialPrice(statusValue: any, isFilterByDate: any, fromDate: any, toDate: any, search: any, page: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST;
    const bodyObj = {
        userType: 'pricing_team',
        filterByStatus: true,
        statusValue: statusValue ? statusValue : 'All',
        filterByDate: isFilterByDate ? isFilterByDate : false,
        fromDate: fromDate,
        toDate: toDate,
        searchBy: search ? search : '',
        webApplicationLoginId:sessionStorage.getItem("webApplicationLoginId")
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: bodyObj,
        customHeaders: {
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getSpecialPriceStatus() {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE_STATUS}?source=pricing_team`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function viewSpecialPriceAPI(id: any, name: any) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/specialPriceDetailsByRole`;
    const obj = {
        "requestId": id,
        "role": "pricing_team",
        "updatedByPricingTeam": sessionStorage.getItem("webApplicationLoginId")
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addComment(id: any, uid: any, addedBy: any, addedName: any, addedRole: any, comment: any) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/addCommentsOnRequest`;
    const obj = {
        "requestId": id,
        "commentAddedOn": "onpart",
        "uid": uid,
        "addedBy": addedBy,
        "addedByName": addedName,
        "addedByRole": addedRole,
        "comment": comment
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getCommentPart(id: any, uid: any, sapCustomerId:any, role:string) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/getCommentOfRequest`;
    const obj = {
        "requestId": id,
        "sapCustomerId": sapCustomerId,
        "uid": uid,
        "fetchComments": "ofpart",
        "readByRole": role?.toUpperCase()
    }

    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function addCommentAll(id: any, uid: any, addedBy: any, addedName: any, addedRole: any, comment: any) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/addCommentsOnRequest`;
    const obj = {
        "requestId": id,
        "commentAddedOn": "ontotal",
        "uid": "",
        "addedBy": addedBy,
        "addedByName": addedName,
        "addedByRole": addedRole,
        "comment": comment
    }
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getCommentPartTotal(id: any, sapCustomerId:any) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/getCommentOfRequest`;
    const obj = {
        "requestId": id,
        "sapCustomerId": sapCustomerId,
        "uid": "",
        "fetchComments": "oftotal",
        "readByRole": "pricing_team".toUpperCase()
    }

    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function postForwardRequestToKam(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/forwardRequestToKam`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    }
    return await makeRequest(options)
}

async function getRejectPart(id: any, sapCustomerId:any,uid:any,rejectedBy:any,rejectedByName:any,rejectByName:any,rejectReason:any) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/rejectReject`;
    const obj = {
        "requestId": id,
        "rejectionType": "bypart",
        "sapCustomerId":sapCustomerId,
        "uid":"",
        "rejectedBy":"",
        "rejectedByName":"",
        "rejectReason":"",
        "rejctedByRole":""
    }

    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}


async function getRejectPartTotal(id: any, sapCustomerId:any) {
    const apiPath = `${API_ENDPOINTS.SPECIAL_PRICE}/getCommentOfRequest`;
    const obj = {
        "requestId": id,
        "sapCustomerId": sapCustomerId,
        "uid": "",
        "fetchComments": "oftotal",
        "readByRole": "pricing_team".toUpperCase()
    }

    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: obj,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function postSaveFormData(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/saveSpecialPrice/pricing_team`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function rejectPart(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/rejectRequest`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function rejectRequest(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/rejectRequest`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function checkandavaliabilityApi(B2bEnquiryId: any , data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/${B2bEnquiryId}/verifySpecialPriceRequest`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function statusReopened(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/reopneSpecialRequest`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function userAccesssRestriction(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM + `/removeRequestRestriction`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function getRequestCorrection(data:any){
    const apiPath=API_ENDPOINTS.SPECIAL_PRICE_REQUEST_TO_KAM+`/requestCorrectionHistory?requestId=${data}&callBy=pricing_team`
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)

}