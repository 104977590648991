import { combineReducers, Reducer } from "redux";
import { UPDATE_CURRENT_PATH } from "../actions/root.actions";
import { IRootStateType, IActionBase, IStateType } from "../models/root.interface";
import notificationReducer from "./notification.reducer";
import accountReducer from "./account.reducer";
import orderEnquiryReducer from './orderEnquiry.reducer';
import favoritesReducer from './favorites.reducer';
import tasksReducer from './tasks.reducer';
import globalReducer from "./global.reducer";
import announcementsReducer from "./announcements.reducer";
import specialOffersReducer from "./specialOffers.reducer";
import assignedKAMsReducer from "./assignedKAMs.reducer";
import logonReducer from "./logon.reducer";
import productsReducer from './products.reducer'
import orderHistoryReducer from "./orderHistory.reducer";
import dealerReducer from "./dealer.reducer";
import createEnquiryReducer from "./createEnquiry.reducer";
import profileReducer from "./profile.reducer";
import creditLimitReducer from "./creditLimit.reducer"
import deliveryReducer from "./delivery.reducer";
import termsAndConditionsReducer from "./termsAndConditions.reducer";
import materialGroupsReducer from "./materialGroups.reducer";
import onboardingReducer from "./onboard.reducer";
import creditRequestReducer from "./creditRequest.reducer";
import superAdminReducer from "./superAdmin.reducer";
import achievementReducer from "./achievement.reducer";
import orderSummary from "./orderSummary.reducer"
import specialPrice from "./specialPrice.reducer";
import specialPriceKam from "./specialPriceKam.reducer";
import requestQuoatation from "./quotation.reducer";
import homeReducer from "./home.reducer";
import requestSpecialPriceReducer from "./requestSpecialPrice.reducer";
import myscheduleReducer from "./myschedule.reducer";

const initialState: IRootStateType = {
    page: {area: "home", subArea: ""}
};

function rootReducer(state: IRootStateType = initialState, action: IActionBase): IRootStateType {
    switch (action.type) {
        case UPDATE_CURRENT_PATH:
            return { ...state, page: {area: action.area, subArea: action.subArea}};
        default:
            return state;
    }
}

const rootReducers: Reducer<IStateType> = combineReducers({
    global:globalReducer,
    root: rootReducer,
    notifications: notificationReducer,
    account: accountReducer,
    favorites: favoritesReducer,
    myschedule: myscheduleReducer,
    tasks: tasksReducer,
    orderEnquiry: orderEnquiryReducer,
    onboard: onboardingReducer,
    announcements: announcementsReducer,
    specialOffers: specialOffersReducer,
    assignedKAMs: assignedKAMsReducer,
    orderHistory: orderHistoryReducer,
    logon:logonReducer,
    products: productsReducer,
    dealer: dealerReducer,
    createUpdateEnquiry:createEnquiryReducer,
    creditLimitDetails:creditLimitReducer,
    requestSpecialPrice: requestSpecialPriceReducer,
    creditRequest: creditRequestReducer,
    profileDetails: profileReducer,
    delivery: deliveryReducer,
    termsAndConditions: termsAndConditionsReducer,
    materialGroups: materialGroupsReducer,
    home: homeReducer,
    superAdmin:superAdminReducer,
    achievement:achievementReducer,
    orderSummary:orderSummary,
    specialPrice:specialPrice,
    specialPriceKam:specialPriceKam,
    quotation:requestQuoatation
});

export default rootReducers;
