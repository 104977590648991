/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from "./../../common/components/CreatedEnquirySnackbar";
import _ from 'lodash';
import { TextField } from '@material-ui/core';
import { createMyschedules, deleteMyschedules, getMyschedulesList, updateMyschedules } from '../../store/actions/myschedule.actions';
import moment from "moment-timezone";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { KeyboardDatePicker } from '@material-ui/pickers';

const MySchedule: React.FC = () => {

  const dispatch: Dispatch<any> = useDispatch();
  const today: any = moment(new Date()).format("YYYY-MM-DD");
  const [initialEvents, setInitialEvents] = React.useState<any>([]);
  const [openAddEventDialog, setOpenAddEventDialog] = React.useState(false);
  const [calendarInfo, setCalendarInfo] = React.useState<any>();
  const [message, setMessage] = React.useState<any>('');
  const [fromDate, setFromDate] = React.useState<Date | null>(today);;
  const [toDate, setToDate] = React.useState<any>(today);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [maxDate, setMaxDate] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  const [isEditMode, setEditMode] = React.useState<any>(false);
  const [editInfo, setEditClickInfo] = React.useState<any>();
  const [isFetched, setIsFetched] = React.useState<any>(false);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const [adminValidFromError, setAdminValidFromError] = React.useState<boolean>(false);
  const [adminValidToError, setAdminValidToError] = React.useState<boolean>(false);
  const [messageError, setMessageError] = React.useState<boolean>(false);

  let eventGuid = 0;
  let calendarRef: any = React.createRef();

  function createEventId() {
    return String(eventGuid++);
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const getMaxDate = (date: any) => {
    let d = new Date(date);
    setMaxDate(d.setDate(d.getDate() + 7))
  }

  useEffect(() => {
    if (!isFetched) {
      loadSchedule();
    }
  }, [loading]);

  const loadSchedule = async () => {
    // setLoading(true);
    let data: any = await dispatch(getMyschedulesList(moment(new Date()).format('YYYY-MM-DD')));
    setIsFetched(true);
    if (data && data.data) {
      let currentEvent: Array<any> = data.data.map((data: any) => {
        if (data && data.reason) {
          return {
            id: data.id,
            title: data.reason,
            start: data.startDate,
            end: moment(data.endDate).add(1, 'day').format('YYYY-MM-DD'),
            allDay: true,
          }
        }

      })
      setInitialEvents([...currentEvent]);
      setIsFetched(false);
      // setLoading(false);
    } else if (data && data.error) {
      setIsFetched(false);
      // setLoading(false);
    }
  }

  const isValidForm = (isSave: any) => {
    let isValid: boolean = true;
    if (isSave) {
      if (!fromDate) {
        setAdminValidFromError(true);
        isValid = false;
      }
      if (!toDate) {
        setAdminValidToError(true);
        isValid = false;
      }
      if (fromDate && toDate) {
        let from = moment(fromDate).format('YYYY-MM-DD')
        let to = moment(toDate).format('YYYY-MM-DD')
        if (from == to) {
          setInvalidDate(false);
        } else if (to < from) {
          setInvalidDate(true);
          isValid = false;
        } else {
          setInvalidDate(false);
        }
      }
    } else {
      return true
    }
    return isValid;
  }

  const checkIsExisting = async (startDate: any, endDate: any) => {
    let selectedStartDate = moment(startDate)
    let selectedEndDate = moment(endDate)
    let existEvent = initialEvents.find((event: any) => {

      let startEvent = moment(event.start).subtract(1, 'day')
      let endEvent = moment(event.end)
      return selectedStartDate.isBetween(startEvent, endEvent) || selectedEndDate.isBetween(startEvent, endEvent)
      // return selectedStartDate.isBetween(startEvent, endEvent)
      // return moment(event.start) >= selectedStartDate && moment(event.start) <= selectedEndDate
    })
    return existEvent
  }
  const handleDateSelect = async (selectInfo?: any, isSave?: any) => {
    let isExist = false;

    if (initialEvents && initialEvents.length > 0) {

      let selectedStartDate = moment(selectInfo.startStr)

      let existEvent = initialEvents.find((event: any) => {

        let startEvent = moment(event.start).subtract(1, 'day')
        let endEvent = moment(event.end)

        return selectedStartDate.isBetween(startEvent, endEvent)
        // return selectedStartDate.isBetween(startEvent, endEvent)
        // return moment(event.start) >= selectedStartDate && moment(event.start) <= selectedEndDate
      })
      if (!_.isEmpty(existEvent)) {
        let existData = {
          "event": {
            "id": existEvent.id,
            "title": existEvent.title,
            "startStr": existEvent.start,
            "endStr": existEvent.end,
            "allDay": true
          }

        }
        return handleEventClick(existData)
      }
    }
    setCalendarInfo(selectInfo);
    setOpenAddEventDialog(true);
    if (selectInfo && !isSave) {
      setFromDate(selectInfo.startStr);
      setToDate(moment(selectInfo.endStr).subtract(1, 'day').format("YYYY-MM-DD"));
      getMaxDate(selectInfo.startStr);
    }
    let calendarApi = selectInfo.view.calendar;
    calendarApi.unselect(); // clear date selection

    if (message === '' && isSave) {
      setMessageError(true);
    }
    let startDate = fromDate && isSave ? fromDate : selectInfo.startStr;
    let endDate = toDate && isSave ? toDate : moment(selectInfo.endStr).subtract(1, 'day').format("YYYY-MM-DD");
    let checkEvent = await checkIsExisting(startDate, endDate)

    if (moment(startDate).isBefore(moment(today)) || moment(endDate).isBefore(moment(today))) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `You can't select previous date`
      );
      handleopenCloseSnackbar(true);
    } else if (!_.isEmpty(checkEvent)) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `Already event is there on your selected date`
      );
      handleopenCloseSnackbar(true);
    } else if (adminValidFromError && adminValidToError) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `Please select valid date`
      );
      handleopenCloseSnackbar(true);
    } else if (!_.isEmpty(message) && isValidForm(isSave)) {
      // calendarApi.addEvent({
      //   id: createEventId(),
      //   title: message,
      //   start: moment(startDate).format('YYYY-MM-DD'),
      //   end: moment(endDate).add(1, 'day').format('YYYY-MM-DD'),
      //   allDay: true,
      // });
      // let newTodate: any = new Date(toDate);
      // newTodate.setDate(newTodate.getDate() + 1);
      let data = {
        "startDate": moment(startDate).format('YYYY-MM-DD'),
        "endDate": moment(endDate).format('YYYY-MM-DD'),
        "reason": message,
        "addedBy": sessionStorage.getItem('webApplicationLoginId')
      }
      let savedData: any = await dispatch(createMyschedules(data));
      if (savedData) {
        await loadSchedule();
        setMessage('');
        setEditMode(false);
        setFromDate(today);
        setToDate(today);
        getMaxDate(today);
        setInvalidDate(false);
        setOpenAddEventDialog(false);
      }
    } else {
      if (isSave) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          `Please fill all fields`
        );
        handleopenCloseSnackbar(true);
      }
    }
    // }
  };

  function renderEventContent(eventInfo: any) {

    return (
      loading && <>
        <i>{eventInfo.event.title}</i>
      </>
    );
  }

  const handleEventClick = (clickInfo: any) => {
    // let newTodate: any = new Date(clickInfo.event.endStr);
    // newTodate.setDate(newTodate.getDate());
    setMessage(clickInfo.event.title);
    getMaxDate(clickInfo.event.startStr);
    setFromDate(clickInfo.event.startStr);
    setEditClickInfo(clickInfo);
    setEditMode(true);
    setOpenAddEventDialog(true);
    setToDate(moment(clickInfo.event.endStr).subtract(1, 'day').format('YYYY-MM-DD'));
  };

  const handleUpdateClick = async () => {
    if (message && isValidForm(true)) {
      let newTodate: any = new Date(toDate);
      newTodate.setDate(newTodate.getDate());

      const data = {
        "existingData": {
          "startDate": editInfo.event.startStr,
          "endDate": editInfo.event.endStr,
          "id": editInfo.event.id,
          "reason": editInfo.event.title,
          "addedBy": sessionStorage.getItem('webApplicationLoginId')
        },
        "updatedData": {
          "startDate": fromDate ? moment(fromDate).format('YYYY-MM-DD') : editInfo.event.startStr,
          "endDate": newTodate ? moment(newTodate).format('YYYY-MM-DD') : editInfo.event.endStr,
          "reason": message,
          "id": editInfo.event.id,
          "addedBy": sessionStorage.getItem('webApplicationLoginId')
        }
      }
      if (moment(data.existingData.startDate).isBefore(moment(today)) || moment(data.existingData.endDate).isBefore(moment(today))) {
        setOpenSnackbarType("error");
        setSuccessMessage(
          `You can't update previous event`
        );
        handleopenCloseSnackbar(true);
        return true
      }
      let updatedData: any = await dispatch(updateMyschedules(data));
      if (updatedData) {
        await loadSchedule();
        setMessage('');
        setEditMode(false);
        setFromDate(today);
        setToDate(today);
        getMaxDate(today);
        setInvalidDate(false);
        setOpenAddEventDialog(false);
      }
    } else {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `Please fill all fields`
      );
      handleopenCloseSnackbar(true);
    }
  }

  const handleDeleteClick = async () => {
    let data = {
      "startDate": fromDate ? fromDate : editInfo.event.startStr,
      "endDate": toDate ? toDate : editInfo.event.endStr,
      id: editInfo.event.id,
      "reason": editInfo.event.title,
      "addedBy": sessionStorage.getItem('webApplicationLoginId')
    }
    if (moment(data.startDate).isBefore(moment(today)) || moment(data.endDate).isBefore(moment(today))) {
      setOpenSnackbarType("error");
      setSuccessMessage(
        `You can't delete previous event`
      );
      handleopenCloseSnackbar(true);
      return true
    }
    let deleteData: any = await dispatch(deleteMyschedules(data));

    if (deleteData) {
      setEditMode(false);
      await loadSchedule();
      setMessage('');
      setFromDate(today);
      setToDate(today);
      getMaxDate(today);
      setInvalidDate(false);
      setOpenAddEventDialog(false);
      setEditClickInfo(null);
      setCalendarInfo(null);
    }
  }

  const handleEvents = (events: any) => {
    setInitialEvents(events);
  };

  return (
    <div className="h-75">
      {loading && (<FullscreenLoader />)}
      <Snackbar
        open={openSnackbar}
        type={openSnackbarType}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
          setOpenSnackbarType("");
        }}
      />
      {/* <p className="text-primary font-weight-700">Please select date if you want to add or update event.</p> */}
      {!loading && (
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today myCustomButton",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          customButtons={{
            myCustomButton: {
              text: 'Please select date if you want to add or update event.',
            }
          }}
          initialView="dayGridMonth"
          editable={true}
          height="130%"
          selectable={true}
          timeZone="Asia/Dubai"
          themeSystem="bootstrap"
          // selectMirror={false}
          dayMaxEvents={true}
          weekends={false}
          events={initialEvents} // alternatively, use the `events` setting to fetch from a feed
          select={(e) => handleDateSelect(e)}
          // eventContent={(e) => renderEventContent(e)} // custom render function
          eventClick={(e) => handleEventClick(e)}
          eventStartEditable={false}
        // eventsSet={(e) => handleEvents(e)} // called after events are initialized/added/changed/removed
        // eventAdd={function () { }}
        // eventChange={function () { }}
        // eventRemove={function () { }}
        />
      )}
      <div className="float-left">
        <Dialog
          open={openAddEventDialog ? true : false}
          onClose={() => {
            setOpenAddEventDialog(false); setEditMode(false); setMessage(''); setFromDate(today);
            setToDate(today);
            getMaxDate(today);
            setInvalidDate(false);
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
        >
          <DialogContent>
            <div>
              <h5 className="font-bold">{isEditMode ? 'Update Event' : 'Add Event'}</h5>
            </div>
            <div>
              <DialogContentText component="span" id="alert-dialog-description">
                <div className="col-12 text-center">
                  <TextField id="message"
                    error={messageError}
                    value={message}
                    // helperText={messageError && <span style={{ color: 'red' }}>Please Enter Message</span>}
                    onChange={(e) => {
                      setMessage(e.target.value);
                      setMessageError(false);
                    }}
                    size="small" className="col-sm-12 commonRoundedInputs mt-3 w-100 mb-2"
                    label="Enter Message" required variant="outlined" margin="dense" />
                  <KeyboardDatePicker required autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="From Date"
                    value={fromDate === null ? today : fromDate}
                    views={["year", "month", "date"]}
                    openTo="date"
                    disablePast
                    onChange={(date: Date | null) => {
                      setFromDate(date);
                      getMaxDate(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer w-100 mt-3 cal-icon"
                    error={adminValidFromError}
                  />
                  <KeyboardDatePicker required autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="To Date"
                    views={["year", "month", "date"]}
                    openTo="date"
                    maxDate={maxDate}
                    disablePast
                    value={toDate === null ? today : toDate}
                    onChange={(date: Date | null) => {
                      setToDate(date);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer w-100 mt-3 cal-icon"
                    helperText={invalidDate && <span style={{ color: 'red' }}>To date must be greater than from date.</span>}
                    error={adminValidToError}
                  />
                </div>
              </DialogContentText>
            </div>
          </DialogContent>
          <DialogActions>
            {isEditMode ? (
              <>
                <Button variant="contained" color="primary" className="mt-2 actionButtomButtomsBlue" onClick={() => handleUpdateClick()} >
                  Update
                </Button>
                <Button variant="contained" className="mt-2 redActionButton border-left rounded-pill" onClick={() => handleDeleteClick()} >
                  Delete
                </Button>
              </>
            ) : (
              <Button variant="contained" color="primary" className="mt-2 actionButtomButtomsBlue" onClick={() => handleDateSelect(calendarInfo, true)} >
                Yes
              </Button>
            )}
            <Button variant="contained" className="cancel-button font-size-11 mr-3 mt-2" onClick={() => {
              setOpenAddEventDialog(false); setEditMode(false); setMessage(''); setFromDate(today);
              getMaxDate(today);
              setToDate(today);
              setInvalidDate(false);
            }}>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div >
    </div>
  )
}

export default MySchedule
