import { API_ENDPOINTS, ORDER_ENQUIRY, CREATE_UPDATE_ENQUIRY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const orderHistoryService = {
    getOrdersList,
    getOrdersDetails,
    returnRequest,
    getInvoiceDetails,
    getOrderStatus,
    getPartList,
    getPaymentTransactionDetails
};

async function getOrdersList(filterParams: any, page?: any) {
    const FSEApiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + `/FSE/${sessionStorage.getItem('webApplicationLoginId')}/orders`;
    const UserApiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + `/customers/${sessionStorage.getItem('customerId')}/orders`;
    const apiPath = sessionStorage.getItem('userType') === "FSE" ? FSEApiPath : UserApiPath
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
                "loginPassword": "",
                "applicationSecurityKey": "",
                "initialLogonIndicator": "",
                "initialPassword": "",
                "username": "",
                "password": ""
            },
            "searchParams": filterParams,
            "userRole" : sessionStorage.getItem('userType') === "FSE" ? "FSE" : ""
        },
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getPartList(partnumber: any, brand: any) {


    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH;
    const options = {
        path: `${apiPath}/getSuperSessionDetails/${brand}/${partnumber}`,
        method: "GET",
        customHeaders: {
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrdersDetails(id: any, backOrder?: any) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + CREATE_UPDATE_ENQUIRY.ORDERS + `/${id}?backOrder=${backOrder ? 'true' : 'false'}`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        customHeaders: {
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getPaymentTransactionDetails(id: any, enqId?: any) {
    const apiPath = API_ENDPOINTS.ENQUIRY + ORDER_ENQUIRY.API_PATH + `/order/${enqId}/${id}/payment-transaction-details`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        customHeaders: {
            "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getInvoiceDetails(data: any) {
    const apiPath = API_ENDPOINTS.DELIVERY + ORDER_ENQUIRY.INVOICE + `?enquiryNumber=${data.enquiryNumber}&orderNumber=${data.orderNumber}&make=${data.make}&webApplicationLoginId=${data.webApplicationLoginId}`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function returnRequest(data: any) {
    const apiPath = API_ENDPOINTS.RETURN_REQUEST;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {

        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getOrderStatus() {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + ORDER_ENQUIRY.FORMFIELD + '/orderStatus';
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true
    };
    return await makeRequest(options)
}
