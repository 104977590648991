import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import NotificationIcon from "../../common/components/NotificationIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import RejectDialog from "./RejectDialog";
import CreditRequestApproveDialog from "./creditRequestApproveDialog";
import { getNewBrand } from '../../common/components/util';

const CreditDetails: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [creditList, setCreditList] = React.useState<any>();
  const { regionData }: any = useSelector((state: any) => state.onboard);
  const { creditRequestDetails, isApproveCR, isRejectCR }: any = useSelector((state: any) => state.creditRequest);
  const [showRejectDialog, setshowRejectDialog] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [customerTypes, setcustomerTypes] = React.useState<any>([]);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [approvalData, setapprovalData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState<any>(false);
  const [statusColor, setStatusColor] = React.useState<string>("");

  const handleopenCloseSnackbar = (openRequestSnackbar?: any) => {
    setOpenSnackbar(openRequestSnackbar ? true : false);
  };
  const history = useHistory();

  useEffect(() => {
    if (creditRequestDetails && creditRequestDetails.data && creditRequestDetails.data.data
      && creditRequestDetails.data.data.dealerDetails && creditRequestDetails.data.data.dealerDetails.status) {
      if (creditRequestDetails.data.data.dealerDetails.status === "Rejected") {
        setStatusColor('red');
      }
      else if (creditRequestDetails.data.data.dealerDetails.status === "Approved") {
        setStatusColor('limegreen');
      }
      else if (creditRequestDetails.data.data.dealerDetails.status === "Submitted") {
        setStatusColor('darkorange');
      }
      else {
        setStatusColor('#666666');
      }
    }
  }, [creditRequestDetails])

  const showloader = () => {
    setLoading(true);
    if (creditRequestDetails) {
      setLoading(false);
      setTimeout(() => {
        history.push('/creditteam')
      }, 3000);
    }
  };

  const setSnackbar = (response: any) => {
    if (response) {
      setOpenSnackbar(true);
    }
  };
  const setmessage = (message: any) => {
    if (message) {
      setSuccessMessage(message);
    }
  };

  return (
    <Fragment>
      {loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Credit Request
        </h2>
        <div className="ml-auto">
          <NotificationIcon />
        </div>
      </div>
      <Snackbar
        open={openSnackbar ? true : false}
        autoHideDuration={4000}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => { handleopenCloseSnackbar(false); }}
        message={successMessage}
      />
      {creditRequestDetails && creditRequestDetails.loading ? <FullscreenLoader /> : (<>
        {creditRequestDetails && creditRequestDetails.data && creditRequestDetails.data.data && creditRequestDetails.data.data.dealerDetails && (
          <>
            <div className="row">
              <div className="col-md-12 mt-2 mb-1">
                <div className="d-flex align-items-center mb-2">Additional Credit Request by
                  <div className="info-sub-title-values mb-0 mt-2"><h6 className="subTitleKamCredit mt-0 mb-1 px-2">M/s {creditRequestDetails.data.data.dealerDetails.companyName}</h6> </div>
                  <div className="row-space-start ml-auto sm">
                    <Button variant="contained" color="primary" onClick={(e) => { e.preventDefault(); history.push('/creditteam'); }} className="blueActionButton border-left rounded-pill ml-4">Back To Credit Request</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="card col-12 m-0 p-2">
              <div className="row mt-3 px-2">
                <h6 className="dealer-table-content mb-1 px-4 mt-0 mb-0">Customer Details</h6>
              </div>
              <div className="row mt-1 mb-1 px-3">
                <Grid container>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3 px-3">Customer Name</div>
                    <div className="subTitle mt-1 mb-3 px-3">M/S {creditRequestDetails.data.data.dealerDetails.companyName}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Address No.</div>
                    <div className="subTitle mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.addresses.streetName},{creditRequestDetails.data.data.dealerDetails.addresses.city}, {creditRequestDetails.data.data.dealerDetails.addresses.country}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Email</div>
                    <div className="subTitle mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.email}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Mobile</div>
                    <div className="subTitle mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.telephoneNumber}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3 px-3">KAM </div>
                    <div className="subTitle mt-1 mb-3 px-3">{creditRequestDetails.data.data.dealerDetails.assignKam}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">FSE</div>
                    <div className="subTitle mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.assignFse}</div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="card col-12 m-0 p-2">
              <div className="row mt-1 mb-1 px-3">
                <Grid container>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3 px-3">Brand</div>
                    <div className="subTitle mt-1 mb-3 px-3">
                      <Button
                        className="brandbutton cursor-default"
                        size="small"
                        variant="contained"
                        color="secondary"
                      >{getNewBrand(creditRequestDetails.data.data.dealerDetails.brand)}</Button>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Existing Credit Limit</div>
                    <div className="subTitle mt-1 mb-3">
                      <Button
                        className="creditAmountButtonKAM cursor-default"
                        size="small"
                        variant="contained"
                        color="secondary"
                      >{creditRequestDetails.data.data.dealerDetails.additionalCreditAmountCurrencyType} {creditRequestDetails.data.data.dealerDetails.existingCreditAmount}</Button>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Credit Period (Days)</div>
                    <div className="subTitle mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.creditPeriodInDays ? creditRequestDetails.data.data.dealerDetails.creditPeriodInDays : '-'}</div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="card col-12 m-0 p-2">
              <div className="row mt-1 mb-1 px-3">
                <Grid container>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3 px-3">Additional Credit Request</div>
                    <div className="subTitle mt-1 mb-3 px-3">
                      <Button
                        className="creditAmountButton cursor-default"
                        size="small"
                        variant="contained"
                        color="secondary"
                      >{creditRequestDetails.data.data.dealerDetails.additionalCreditAmountCurrencyType} {creditRequestDetails.data.data.dealerDetails.additionalCreditAmount ? creditRequestDetails.data.data.dealerDetails.additionalCreditAmount : '-'}</Button>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Document Uploaded</div>
                    <div className="info-sub-header-document  mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.documents && creditRequestDetails.data.data.dealerDetails.documents.length > 0 && creditRequestDetails.data.data.dealerDetails.documents[0].documentId !== '' ?
                      <span className="file-name">
                        <span className="cursor-pointer" onClick={() => {
                          window.open(creditRequestDetails.data.data.dealerDetails.documents[0].documentId.url)
                        }}>{creditRequestDetails.data.data.dealerDetails.documents[0].documentId.name ? creditRequestDetails.data.data.dealerDetails.documents[0].documentId.name : creditRequestDetails.data.data.dealerDetails.documents[0].documentId.selectedFileName}</span>
                      </span>
                      : '-'}</div>
                  </Grid>
                  <Grid item xs={3}>
                    <div className="info-sub-title-value mt-3">Justification For Credit</div>
                    <div className="subTitle mt-1 mb-3">{creditRequestDetails.data.data.dealerDetails.justificationMsg} </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            {creditRequestDetails.data.data.dealerDetails.status === "Rejected" ?
              <div className="card col-12 m-0 p-2">

                <div className="row mt-1 mb-1 px-3">
                  <Grid container>
                    <Grid item xs={3}>
                      <div className="info-sub-title-value mt-3 px-3">Reason By Credit Team</div>
                      <div className="subTitle mt-1 mb-3 px-3">{
                        creditRequestDetails.data.data.dealerDetails.rejectionMessage ? <div className="subTitle mt-1 mb-3" style={{ color: 'red' }}>{creditRequestDetails.data.data.dealerDetails.rejectionMessage} </div> :
                          "-"
                      }</div>
                    </Grid>
                  </Grid>
                </div>
              </div> : ""}
            <div className="row card card-body p-2 fixed-bottom">
              <div className="col-12 display-flex">
                <div className="col-2"></div>
                <div className="col-6">
                  <div className="row m-0">
                    <div className="col-4">
                      <p className="mt-2 ml-5 shipAdd">Status</p>
                      {/* <div className="col-4 mt-2 mb-2"> */}
                      <span className="mt-2 ml-5 statusDivision" style={{ color: statusColor }}>{creditRequestDetails.data && creditRequestDetails.data.data && creditRequestDetails.data.data.dealerDetails && creditRequestDetails.data.data.dealerDetails.status}</span>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                {creditRequestDetails && creditRequestDetails.data && creditRequestDetails.data.data && creditRequestDetails.data.data.dealerDetails &&
                  creditRequestDetails.data.data.dealerDetails.status != "Rejected" && creditRequestDetails.data.data.dealerDetails.status != "Approved" ?
                  <div className="col-4 onboard-footer">
                    <div className="p-3 mr-2">
                      <Button variant="contained"
                        className="redActionButton rounded-pill ml-3 w-150p" onClick={() => { setshowRejectDialog(true); setOpenSnackbar(false) }}>Reject</Button>
                    </div>
                    <div className="p-3 mr-5">
                      <Button variant="contained" color="primary"
                        className="rounded-pill ml-3 w-150p" onClick={() => { setshowApproveDialog(true); setOpenSnackbar(false) }}>Approve</Button>
                    </div>
                  </div>
                  : ""}
              </div>
            </div>
          </>
        )}
      </>
      )}

      <CreditRequestApproveDialog
        customerTypes={customerTypes}
        open={showApproveDialog}
        openRequestSnackbar={openSnackbar}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        approvalData={approvalData}
        fromResponse={(data: any) => {
          showloader();
          setSnackbar(data ? true : false)

        }}
        message={(data: any) => {
          setmessage(data ? data : "")
        }}
        handleopenClose={(data: any) => {
          setshowApproveDialog(data ? true : false)
        }}
      />

      <RejectDialog
        open={showRejectDialog}
        openRequestSnackbar={openSnackbar}
        fromResponse={(data: any) => {
          showloader();
          setSnackbar(data ? true : false)
        }}
        message={(data: any) => {
          setmessage(data ? data : "")
        }}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenClose={(data: any) => {
          setshowRejectDialog(data ? true : false)
        }}
      />

    </Fragment>
  );
}
export default CreditDetails;
