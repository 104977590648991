import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const tasksService = {
    getTasks,
    closeTasks,
    getDocumentReport,
    postOpenTask,
    getReviewDocuments,
    handleApproveReupload
};

async function getTasks(page?: any) {
    const apiPath = API_ENDPOINTS.TASKS + `/${sessionStorage.getItem('webApplicationLoginId')}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 4 : 0}|no-of-records=4`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getDocumentReport(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/getDocumentList`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function postOpenTask(data?: any, page?:any) {
    const apiPath = API_ENDPOINTS.OPENTASK;
    const options = {
        path: apiPath,
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
        method: "POST",
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}

async function getReviewDocuments(data?: any, page?:any) {
    const apiPath = API_ENDPOINTS.REVIEWDOCUMENT;
    const options = {
        path: apiPath,
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
        method: "POST",
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}

async function handleApproveReupload(data: any) {
    const apiPath = API_ENDPOINTS.APPROVEDOCUMENT;
    const options = {
        path: apiPath,
        customHeaders: {},
        method: "POST",
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}

async function closeTasks(id: any) {
    const apiPath = API_ENDPOINTS.TASKS;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: {
            "id": id,
            'webApplicationLoginId': sessionStorage.getItem('webApplicationLoginId')
        },
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}
