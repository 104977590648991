import { MYSCHEDULE } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  myscheduleList: null,
  myscheduleDetails: null,
  createMyschedule: null,
  updateMyschedule: null,
  deleteMyschedule: null,
};

function myscheduleReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case MYSCHEDULE.GET_MYSCHEDULE_LIST_FETCHING:
      return {
        ...state,
        myscheduleList: { loading: true, data: null, err: null },
        createMyschedule: null,
        updateMyschedule: null,
        deleteMyschedule: null,
      };
    case MYSCHEDULE.GET_MYSCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        myscheduleList: { loading: false, data: action.payload.data.data, err: null }
      };
    case MYSCHEDULE.GET_MYSCHEDULE_LIST_ERROR:
      return {
        ...state,
        myscheduleList: { loading: false, data: null, err: action.payload.error }
      };
    case MYSCHEDULE.GET_MYSCHEDULE_DETAILS_SUCCESS:
      return {
        ...state,
        myscheduleDetails: action.payload
      };
    case MYSCHEDULE.CREATE_MYSCHEDULE_FETCHING:
      return {
        ...state,
        createMyschedule: { loading: true, data: null, err: null }
      };
    case MYSCHEDULE.CREATE_MYSCHEDULE_SUCCESS:
      return {
        ...state,
        createMyschedule: { loading: false, data: action.payload, err: null }
      };
    case MYSCHEDULE.CREATE_MYSCHEDULE_ERROR:
      return {
        ...state,
        createMyschedule: { loading: false, data: null, err: action.payload.error }
      };
    case MYSCHEDULE.DELETE_MYSCHEDULE_FETCHING:
      return {
        ...state,
        deleteMyschedule: { loading: true, data: null, err: null }
      };
    case MYSCHEDULE.DELETE_MYSCHEDULE_SUCCESS:
      return {
        ...state,
        deleteMyschedule: { loading: false, data: action.payload, err: null }
      };
    case MYSCHEDULE.DELETE_MYSCHEDULE_ERROR:
      return {
        ...state,
        deleteMyschedule: { loading: false, data: null, err: action.payload.error }
      };
    case MYSCHEDULE.UPDATE_MYSCHEDULE_FETCHING:
      return {
        ...state,
        updateMyschedule: { loading: true, data: null, err: null }
      };
    case MYSCHEDULE.UPDATE_MYSCHEDULE_SUCCESS:
      return {
        ...state,
        updateMyschedule: { loading: false, data: action.payload, err: null }
      };
    case MYSCHEDULE.UPDATE_MYSCHEDULE_ERROR:
      return {
        ...state,
        updateMyschedule: { loading: false, data: null, err: action.payload.error }
      };
    default:
      return state;
  }
};

export default myscheduleReducer;
