/* eslint-disable */
import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import CancelIcon from '@material-ui/icons/Cancel';
import Grid from '@material-ui/core/Grid';
import BrandDetails from './BrandDetails';
import { addUserParameters, editUserParameters, sendEmail, storeBrandValues, } from '../../store/actions/dealer.action';
import { API_ENDPOINTS } from "../../constants/apiConfig";
import moment from "moment-timezone";
import { ADD_PARAMS_BRANDS } from '../../constants/messages';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { get } from 'lodash';
import { getShipToParty } from '../../store/actions/dealer.action';
import { getNewBrand } from '../../common/components/util';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import MenuItem from "@material-ui/core/MenuItem";
import { CheckBox } from '@material-ui/icons';
import { withStyles, createStyles, Theme } from '@material-ui/core/styles';
import { FormControl, InputLabel, ListItemIcon, Select, TextField, Input, } from '@material-ui/core';
import { styles } from '@material-ui/pickers/views/Calendar/Calendar';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { getDealerDetails } from '../../store/actions/dealer.action';
export interface UsersParameterDialogProps {
  open: boolean;
  onClose: (value: boolean) => void;
  shipToPartyFeatureAccess: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`userdetail-tabpanel-${index}`}
      aria-labelledby={`userdetail-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function allTabProps(index: any) {
  return {
    id: `userdetail-tab-${index}`,
    'aria-controls': `userdetail-tabpanel-${index}`,
  };
}

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const BrandMenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };


const UsersParameterDialog: React.FC<UsersParameterDialogProps> = (props: UsersParameterDialogProps) => {
  const [brandName, setBrandName] = React.useState<string[]>([]);
  const [tabValue, setTabValue] = React.useState(0);
  const [shipToParty, setShipToParty] = React.useState<string>("");
  const [shipToPartyError, setShipToPartyError] = React.useState<boolean>(false);
  const [billToParty, setBillToParty] = React.useState<string>("");
  const [billToPartyError, setBillToPartyError] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [payerId, setPayerId] = React.useState<string>("");
  const [payerIdError, setPayerIdError] = React.useState<boolean>(false);
  const [sapCustomerId, setSapCustomerId] = React.useState<string>("");
  const [sapCustomerIdError, setSapCustomerIdError] = React.useState<boolean>(false);
  const [userParams, setUserParams] = React.useState<any>();
  const [isNewlyExtended, setIsNewlyExtended] = React.useState<any>(false);
  const [extendedBrand, setExtendBrand] = React.useState<any>([]);
  const [extendedBrandName, setExtendBrandName] = React.useState<any>([]);
  const [fillAtleastOneBrandDetail, setFillAtleastOneBrandDetail] = React.useState<any>(false);
  const [formSubmitted, setFormSubmitted] = React.useState<any>(false);
  const [partiallyCompleteBrandValidation, setPartiallyCompleteBrandValidation] = React.useState<any>([]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [apiResponseText, setApiResponseText] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const { shipToPartyNumber, usersList, dealerDetails }: any = useSelector((state: any) => state.dealer);
  const userType = sessionStorage.getItem('userType');
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [shipToPartyAddressAPIResponse, setshipToPartyAddressAPIResponse] = React.useState<any>([]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  const isDisabled = () => {
    if (userType === 'afg_super_admin') {
      return true;
    } else {
      return false;
    }
  }

  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
  const { userDetails, brandValues, addParamInfo, editParamInfo, userParameters }: any = useSelector((state: any) => state.dealer);
  const brands = [
    // {
    //   "make": "Famco",
    //   "organizationId": "2003",
    // },
    {
      "make": "Honda",
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "organizationId": "2001",
    },
    {
      "make": "OES",
      "organizationId": "2195",
    }
  ]

  useEffect(() => {
    // if ((addParamInfo && addParamInfo.data) || (editParamInfo && editParamInfo.data)) {
    if ((editParamInfo && editParamInfo.data)) {
      resetValues();
    }
  }, [editParamInfo]) // eslint-disable-line
  // }, [addParamInfo, editParamInfo]) // eslint-disable-line


  useEffect(() => {
    const addressDetails: any = usersList && usersList.data && usersList.data.data && usersList.data.data.customerDetails && usersList.data.data.customerDetails.addresses && usersList.data.data.customerDetails.addresses.length > 0 && usersList.data.data.customerDetails.addresses.map((ele: any) => {
      return ele
    })
    if (addressDetails && addressDetails.length > 0) {
      const filteredShipToPartyAddress: any = addressDetails && addressDetails.filter((address: any) => {
        return address.shiftToPartyCustomerNumber === shipToParty
      })
      setshipToPartyAddressAPIResponse(filteredShipToPartyAddress);
    }
  }, [shipToParty, shipToPartyNumber, usersList])

  function resetValues() {
    setTabValue(0);
    setBrandName([]);
    setShipToParty('');
    setShipToPartyError(false);
    setBillToParty('');
    setBillToPartyError(false);
    setPayerId('');
    setPayerIdError(false);
    setSapCustomerId('');
    setSapCustomerIdError(false);
    dispatch(storeBrandValues(null));
  }
  // const isBrandActive = (brand: any, orgIds: any) => {
  //   // Iterate through the list of organizations
  //   if (brand && brand !== '' && orgIds && Array.isArray(orgIds) && orgIds.length > 0) {
  //     for (const org of orgIds) {
  //       // Check if the organizationId matches and isActive is true
  //       if (org.organizationId === brand && org.isActive) {
  //         return true;
  //       }
  //     }
  //   }
  //   // If no matching organizationId with isActive true is found, return false
  //   return false;
  // };


  const isBrandActive = (brand: any, orgIds: any): boolean => {
    if (brand && brand !== '' && orgIds && Array.isArray(orgIds) && orgIds.length > 0) {
      for (const org of orgIds) {
        // Check if the organizationId matches
        if (org.organizationId === brand) {
          // Check if isActive is true or missing (consider as true)
          // if (org.isActive === undefined || org.isActive === true) {
          if (org.isActive !== false) {
            return true;
          }
        }
      }
    }
    // If no matching organizationId with isActive true or missing is found, return false
    return false;
  };



  useEffect(() => {
    if (userDetails) {
      let isNewlyObject: any = userDetails?.salesOrganization?.some((obj: any) => obj.isNewlyExtendedBrand === true);
      if (isNewlyObject) {
        let isNewlyExtended = false;
        let brands: any[] = []; // Array to hold all matching brands
        let brandNames: any[] = []; // Array to hold all corresponding brand names

        for (let i = 0; i < userDetails?.salesOrganization.length; i++) {
          if (userDetails?.salesOrganization[i].isNewlyExtendedBrand === true) {
            isNewlyExtended = true;
            brands.push(userDetails?.salesOrganization[i].organizationId);
            brandNames.push(userDetails?.salesOrganization[i].make);
          }
        }
        setIsNewlyExtended(isNewlyExtended);
        setExtendBrand(brands);
        setExtendBrandName(brandNames);
      } else {
        setIsNewlyExtended(false);
        setExtendBrand('');
        setExtendBrandName('');
      }
      let fetchedBrands: string[] = [];
      let brandValues: any = {};
      if (userParameters && userParameters.data && userParameters.data.data && userParameters.data.data.length > 0) {
        let data = userParameters.data.data[0];
        setUserParams(data);
        setShipToParty(data.organizationIds[0].shipToParty);
        setBillToParty(data.organizationIds[0].billToParty);
        setSapCustomerId(data.sapCustomerId);
        setPayerId(data.payerId);
        data.organizationIds.forEach((item: any) => {
          let fetchedBrand = brands.filter(x => x.organizationId === item.salesOrganization)[0];
          if (fetchedBrand && fetchedBrand.make !== "")
            fetchedBrands.push(fetchedBrand.make);
          if (data.status === 'Active' && fetchedBrand && fetchedBrand !== undefined) {
            Object.assign(brandValues, {
              [fetchedBrand.make]: {
                make: fetchedBrand.make,
                salesOrganization: item.salesOrganization ? item.salesOrganization : '',
                salesOffice: item.salesOffice ? item.salesOffice : '',
                distributionChannel: item.distributionChannel ? item.distributionChannel : '',
                division: item.division ? item.division : '',
                allowIpPattern: item.allowIpPattern ? item.allowIpPattern : '',
                salesGroup: item.salesGroup ? item.salesGroup : '',
                salesEmployee: item.salesEmployee ? item.salesEmployee : '',
                creditControlArea: item.creditControlArea ? item.creditControlArea : '',
                status: isBrandActive(item?.salesOrganization, dealerDetails.data?.data?.organizationIds) ? (item.status ? item.status : 'In Progress') : 'deActive',
                assignKam: item.assignKam ? item.assignKam : '',
                sapUserId: item.sapUserId ? item.sapUserId : ''
              }
            });
          } else if (fetchedBrand && fetchedBrand !== undefined) {
            Object.assign(brandValues, {
              [fetchedBrand.make]: {
                make: fetchedBrand.make,
                salesOrganization: item.salesOrganization,
                salesOffice: "",
                distributionChannel: "",
                division: "",
                allowIpPattern: "",
                salesGroup: "",
                salesEmployee: item.salesEmployee ? item.salesEmployee : '',
                creditControlArea: "",
                status: isBrandActive(item.salesOrganization, dealerDetails.data?.data?.organizationIds) ? (item.status ? item.status : 'In Progress') : 'deActive',
                assignKam: item.assignKam ? item.assignKam : '',
              }
            });
          }
        });
      }
      dispatch(storeBrandValues(brandValues));
      setBrandName(fetchedBrands);
    }
  }, [userDetails, dealerDetails, userParameters, dispatch]); // eslint-disable-line

  const resetValuesOnClickCancel = () => {
    setPartiallyCompleteBrandValidation(false);
    setFillAtleastOneBrandDetail(false);
    setSapCustomerIdError(false);
    setShipToPartyError(false);
    setPayerIdError(false);
    setBillToPartyError(false);
    setFormSubmitted(false);
  }
  const emailLinkOnAddUserParam = (createDealerUser: any) => {
    if (createDealerUser && createDealerUser.data) {
      if (createDealerUser.data.sendLink) {
        const data = {
          "fromAddress": "tradepoint@alfuttaim.com",
          "toAddress": createDealerUser.data.email,
          "subject": "Account Verification and Self-Registration",
          "senderName": "Auto B2B",
          "templateName": "B2B_CUSTOMER_INVITE",
          "templateData": {
            "DateOfMail": moment(new Date()).format("DD/MM/YYYY"),
            "Url": `${API_ENDPOINTS.ON_BOARDING_URL}/signup?b2bUserId=${createDealerUser.data.b2bUserId}`,
            "CustomerName": "",
            //createDealer && createDealer.data && createDealer.data.data ? `${createDealer.data.data.data.title} ${createDealer.data.data.data.companyName}` : '',
            "Name": `${createDealerUser.data.firstName} ${createDealerUser.data.lastName}`
          }
        }
        dispatch(sendEmail(data));
      }
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  // const handleBrandChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setBrandName(event.target.value as string[]);
  // };

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName && e.target.required) {
      const regex = /^[0-9 \b]+$/
      if (!regex.test(e.target.value)) {
        errorSetterName(true);
      } else if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  function validateDetails() {
    let isValid: boolean = true;
    if (sapCustomerId.trim() === "") {
      setSapCustomerIdError(true);
      isValid = false;
    }
    if (shipToParty.trim() === "") {
      setShipToPartyError(true);
      isValid = false;
    }
    if (payerId.trim() === "") {
      setPayerIdError(true);
      isValid = false;
    }
    if (billToParty.trim() === "") {
      setBillToPartyError(true);
      isValid = false;
    }
    return isValid;
  }



  function brandFieldCount(brandValues: any) {
    let requiredCount = 0;
    if (Object.keys(brandValues).length > 1) {
      if (brandValues.creditControlArea.trim() === "") {
        requiredCount += 1;
      }
      if (brandValues.distributionChannel.trim() === "") {
        requiredCount += 1;
      }
      if (brandValues.division.trim() === "") {
        requiredCount += 1;
      }
      if (brandValues.salesOffice.trim() === "") {
        requiredCount += 1;
      }
      if (brandValues.salesGroup.trim() === "") {
        requiredCount += 1;
      }
      // if (brandValues.salesEmployee.trim() === "") {
      //   requiredCount += 1;
      // }
      // if(brandValues.assignKam.trim() === ""){
      //   requiredCount += 1;
      // }
    }
    return requiredCount
  }

  function brandFieldDetailsCheck(brandValues: any) {
    let validated = 0;
    if (Object.keys(brandValues).length > 1) {
      if (brandValues.creditControlArea.length !== 4 && brandValues.creditControlArea.length !== 0) {
        validated += 1;
      }
      if (brandValues.distributionChannel.length !== 2 && brandValues.distributionChannel.length !== 0) {
        validated += 1;
      }
      if (brandValues.division.length !== 2 && brandValues.division.length !== 0) {
        validated += 1;
      }
      if (brandValues.salesOffice.length !== 4 && brandValues.salesOffice.length !== 0) {
        validated += 1;
      }
      if (brandValues.salesGroup.length !== 3 && brandValues.salesGroup.length !== 0) {
        validated += 1;
      }
      if (brandValues.salesEmployee.length !== 6 && brandValues.salesEmployee.length !== 0) {
        validated += 1;
      }
      // if (brandValues.assignKam.length !== 6 && brandValues.assignKam.length !== 0) {
      //   validated += 1;
      // }
    }
    return validated
  }

  function getAddParamData() {
    let selectedBrands: any[] = [];

    Object.values(brandValues).forEach((element: any) => {
      element.shipToParty = shipToParty.trim();
      element.billToParty = billToParty.trim();
      selectedBrands.push(element);
    });

    return {
      "sessionDetails": {
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "webApplicationLoginId": webApplicationLoginId,
      },
      "b2bUserId": userDetails.b2bUserId,
      "sapCustomerId": sapCustomerId.trim(),
      "payerId": payerId.trim(),
      "organizationIds": selectedBrands.filter((brand: any) => brand),
    }
  }

  function checkBrandValidation() {
    let toyotaValidation = null;
    let teValidation = null;
    let hondaValidation = null;
    let famcoValidation = null;
    let tradeValidation = null;

    let validationObj: any = {};
    if (brandValues.Toyota) {
      toyotaValidation = brandFieldCount(brandValues.Toyota);
      validationObj.Toyota = toyotaValidation;
    }
    if (brandValues.TE) {
      teValidation = brandFieldCount(brandValues.TE);
      validationObj.TE = teValidation;
    }
    if (brandValues.Honda) {
      hondaValidation = brandFieldCount(brandValues.Honda);
      validationObj.Honda = hondaValidation;
    }
    if (brandValues.Famco) {
      famcoValidation = brandFieldCount(brandValues.Famco);
      validationObj.Famco = famcoValidation;
    }
    if (brandValues.OES) {
      tradeValidation = brandFieldCount(brandValues.OES);
      validationObj.OES = tradeValidation;
    }

    let allUntouched = 0;
    let partialValidation: any = [];

    Object.keys(validationObj).forEach((vObj: any) => {
      if (validationObj[`${vObj}`] === 5) {
        allUntouched += 1;
      }
      if (validationObj[`${vObj}`] < 5 && validationObj[`${vObj}`] > 0) {
        partialValidation.push(vObj);
      }
    })

    if (allUntouched === Object.keys(validationObj).length) {
      setFillAtleastOneBrandDetail(true);
      setPartiallyCompleteBrandValidation([]);
      return "allEmpty";
    }
    else if (partialValidation.length > 0) {
      setPartiallyCompleteBrandValidation(partialValidation);
      setFillAtleastOneBrandDetail(false);
      return "partiallyEmpty"
    }
    else {
      setFillAtleastOneBrandDetail(false);
      setPartiallyCompleteBrandValidation([]);
      return ""
    }
  }

  async function getEditParamData() {
    let selectedBrands: any = [];
    let selectedBrandNames: any = [];

    // Collect all selected brands and their names
    Object.values(brandValues).forEach((element: any) => {
      element.shipToParty = shipToParty.trim();
      element.billToParty = billToParty.trim();
      selectedBrands.push(element);
      selectedBrandNames.push(element.make);
    });

    if (isNewlyExtended && extendedBrandName && extendedBrandName.length > 0) {
      let newData: any = [];

      // Iterate over each brand name in extendedBrandName
      extendedBrandName.forEach((brandName: any) => {
        // Filter selected brands for the current brandName
        let brandData = selectedBrands.filter((brand: any) => brand.make === brandName);

        // Check if all specified fields are not empty for the current brand
        let allFieldsFilled = brandData.every((brand: any) =>
          brand.salesOffice !== '' &&
          brand.distributionChannel !== '' &&
          brand.division !== '' &&
          brand.salesGroup !== '' &&
          brand.creditControlArea !== ''
        );

        if (allFieldsFilled) {
          newData.push(...brandData);
        } else {
          // If not all fields are filled, push the first brand data with any filled fields
          let partiallyFilledBrand = brandData.find((brand: any) =>
            brand.salesOffice !== '' ||
            brand.distributionChannel !== '' ||
            brand.division !== '' ||
            brand.salesGroup !== '' ||
            brand.creditControlArea !== ''
          );

          if (partiallyFilledBrand) {
            newData.push(partiallyFilledBrand);
          }
        }
      });

      if (newData.length > 0) {
        let data: any = {
          sessionDetails: {
            webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
            clientIPAddress: sessionStorage.getItem('clientIp')
          },
          isNewlyExtendedBrand: isNewlyExtended,
          b2bUserId: userDetails.b2bUserId,
          payerId: payerId.trim(),
          organizationIds: newData
        };

        let userData = await dispatch(addUserParameters(data)); // POST call for newly added SAP brand

        if (isNewlyExtended) {
          dispatch(getDealerDetails(sapCustomerId, 'dealerDetails'));
        }

        emailLinkOnAddUserParam(userData);
        setLoading(false);
        // resetValuesOnClickCancel();
      }
    }

    // Prepare editData for editing user parameters
    let editData: any = {
      sessionDetails: {
        webApplicationLoginId: sessionStorage.getItem('webApplicationLoginId'),
        clientIPAddress: sessionStorage.getItem('clientIp')
      },
      b2bUserId: userDetails.b2bUserId,
      payerId: payerId.trim(),
      organizationIds: extendedBrandName && isNewlyExtended ?
        selectedBrands.filter((brand: any) => !extendedBrandName.includes(brand.make)) :
        selectedBrands
    };

    await dispatch(editUserParameters(editData)); // PUT call existing feature
    setLoading(false);
    resetValuesOnClickCancel();
  }

  const validateBrandDetails = () => {
    let toyotaValid = null;
    let teValid = null;
    let hondaValid = null;
    let famcoValid = null;
    let tradeValid = null;
    let brandsError = [];

    let validationObj: any = {};

    if (brandValues.Toyota) {
      toyotaValid = brandFieldDetailsCheck(brandValues.Toyota);
      validationObj.Toyota = toyotaValid;
      if (toyotaValid > 0) brandsError.push(toyotaValid);
    }
    if (brandValues.TE) {
      teValid = brandFieldDetailsCheck(brandValues.TE);
      validationObj.TE = teValid;
      if (teValid > 0) brandsError.push(teValid);
    }
    if (brandValues.Honda) {
      hondaValid = brandFieldDetailsCheck(brandValues.Honda);
      validationObj.Honda = hondaValid;
      if (hondaValid > 0) brandsError.push(hondaValid);
    }
    if (brandValues.Famco) {
      famcoValid = brandFieldDetailsCheck(brandValues.Famco);
      validationObj.Famco = famcoValid;
      if (famcoValid > 0) brandsError.push(famcoValid);
    }
    if (brandValues.OES) {
      tradeValid = brandFieldDetailsCheck(brandValues.OES);
      validationObj.OES = tradeValid;
      if (tradeValid > 0) brandsError.push(tradeValid);
    }
    return brandsError;
  }

  const saveParameters = async () => {
    setFormSubmitted(true);
    let brandValidate = validateBrandDetails();
    let brandValidation = checkBrandValidation();

    if (validateDetails() && brandValidation !== "allEmpty" && brandValidation !== "partiallyEmpty" && brandValidate && brandValidate.length === 0) {
      setLoading(true);
      if (userDetails.status === 'Active') {
        getEditParamData();
        // await dispatch(editUserParameters(paramsData));
        setLoading(false);
        resetValuesOnClickCancel();
      } else {
        let paramsData = getAddParamData();
        let userData: any = await dispatch(addUserParameters(paramsData));
        emailLinkOnAddUserParam(userData);
        setLoading(false);
        if (userData && userData.data) {
          resetValues()
        }
        resetValuesOnClickCancel();
      }
    }
  }

  const selectedFieldProps: any = {
    IconComponent: <i className="material-icons">more_vert</i>,
  }

  return (
    <Dialog
      open={props.open ? true : false}
      onClose={() => { props.onClose(false); resetValuesOnClickCancel(); }}
      fullWidth={true}
      maxWidth={'md'}
      disableEscapeKeyDown>
      {loading && <FullscreenLoader />}
      {shipToPartyNumber && shipToPartyNumber.loading && <FullscreenLoader />}
      <DialogTitle className="pb-0" id="form-dialog-title">
        <div className="page-title">
          {userType === 'afg_super_admin' ? 'View Parameter' : (
            <>
              {userDetails && userDetails.status === 'Active' ? 'Edit Parameter' : 'Add Parameter'}
            </>
          )}
          {addParamInfo && addParamInfo.err && addParamInfo.err.error && addParamInfo.err.error.statusText && addParamInfo.err.error.statusText.data && (
            <span style={{ color: 'red', fontSize: '14px' }}> <ErrorOutlineOutlinedIcon /> {addParamInfo.err.error.statusText.data.message.message}</span>
          )}
          {editParamInfo && editParamInfo.err && editParamInfo.err.error && editParamInfo.err.error.statusText && editParamInfo.err.error.statusText.data && (
            <span style={{ color: 'red', fontSize: '14px' }}> <ErrorOutlineOutlinedIcon /> {editParamInfo.err.error.statusText.data.message.message}</span>
          )}
          <IconButton aria-label="close" className="float-right" onClick={() => { props.onClose(false); resetValuesOnClickCancel() }}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent className="p-0">
        <div className="p-0">
          {userParams && (
            <Grid container className="user-details-bg pl-4 pt-3 pb-3">
              <Grid item xs={6} sm={4}>
                <span className="info-text">Name: </span>
                <span className="dealer-table-content">{userParams.title}{' '}{userParams.firstName}{' '}{userParams.lastName}</span>
              </Grid>
              <Grid item xs={6} sm={4}>
                <span className="info-text">Email: </span>
                <span className="dealer-table-content">{userParams.email}</span>
              </Grid>
              <Grid item xs={6} sm={4}>
                <span className="info-text">Mobile: </span>
                <span className="dealer-table-content">{userParams.mobileNumber}</span>
              </Grid>
              <Grid item xs={6} sm={4}>
                <span className="info-text">Dealer: </span>
                <span className="dealer-table-content">{userParams.companyTitle}{' '}{userParams.companyName}</span>
              </Grid>
              <Grid item xs={6} sm={4}>
                <span className="info-text">User ID: </span>
                <span className="dealer-table-content">{userParams.webApplicationLoginId}</span>
              </Grid>
              <Grid item xs={6} sm={4}>
                <span className="info-text">Status: </span>
                <span className="dealer-table-content">{userParams.status}</span>
              </Grid>
            </Grid>
          )}
          {shipToPartyAddressAPIResponse && shipToPartyAddressAPIResponse.length > 0 && shipToPartyAddressAPIResponse.map((ele: any) => {
            return <>
              <h4 className="dealer-table-content ml-4 mt-2 mb-2">Shipping Address Details :</h4>
              <Grid container className="user-details-bg pl-4 pt-3 pb-3">
                <Grid item xs={6} sm={4}>
                  <span className="info-text">Name of Location: </span>
                  <span className="dealer-table-content">{ele && ele.location ? ele.location : '-'}</span>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <span className="info-text">City: </span>
                  <span className="dealer-table-content">{ele && ele.city ? ele.city : '-'}</span>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <span className="info-text">Street Name: </span>
                  <span className="dealer-table-content">{ele && ele.streetName ? ele.streetName : '-'}</span>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <span className="info-text">Transportation Zone: </span>
                  <span className="dealer-table-content">{ele && ele.transportationZone ? ele.transportationZone : '-'}</span>
                </Grid>
              </Grid>
            </>
          })}
          <Grid container className="p-4">
            <Grid item xs={6}>
              <TextField
                required
                value={sapCustomerId}
                disabled={isDisabled() ? isDisabled() : sapCustomerId ? true : false}
                id="sapCustomerId"
                error={sapCustomerIdError}
                label="Customer No."
                onChange={(e: any) => handleInputChange(e, setSapCustomerId, setSapCustomerIdError)}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    {(sapCustomerId && !sapCustomerIdError) && <CheckCircleIcon className="valid-user-id" />}
                    {(sapCustomerId && sapCustomerIdError) && <CancelIcon color="secondary" />}</InputAdornment>,
                }}
                className="rounded-input-dealer w-100 pr-4 pb-2"
                margin="dense" variant="outlined" />
            </Grid>
            {props.shipToPartyFeatureAccess ? (<Grid item xs={6}>
              <FormControl margin="dense" required className="commonRoundedInputs w-100" variant="outlined">
                <InputLabel id="companyBrandsLabel" className="label-formField" >Ship to party</InputLabel>
                <Select
                  variant="outlined"
                  id="shipToParty"
                  value={shipToParty}
                  error={shipToPartyError}
                  disabled={isDisabled()}
                  className="dropdown"
                  onChange={(e: any) => handleInputChange(e, setShipToParty, setShipToPartyError)}
                  input={<OutlinedInput label="Ship to Party" />}
                  renderValue={() => shipToParty}
                >
                  {shipToPartyNumber && shipToPartyNumber.data && shipToPartyNumber.data.data && shipToPartyNumber.data.data.shipToPartyNumber && shipToPartyNumber.data.data.shipToPartyNumber.map((item: any) => (
                    <MenuItem className="menu-dropdown" value={item && item.shipToPartyNumber} key={item && item.shipToPartyNumber}>
                      {item.shipToPartyNumber}
                      {item.selecteddefaultAddressId
                        && <Button
                          className="defaultAddressButton cursor-default ml-3"
                          size="small"
                          variant="contained"
                          color="secondary"
                        >Assigned Address</Button>}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            ) : (
              <Grid item xs={6}>
                <TextField required
                  value={shipToParty}
                  id="shipToParty"
                  onChange={(e: any) => handleInputChange(e, setShipToParty, setShipToPartyError)}
                  disabled={isDisabled()}
                  error={shipToPartyError}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      {shipToParty && !shipToPartyError && <CheckCircleIcon className="valid-user-id" />}
                      {(shipToParty && shipToPartyError) && <CancelIcon color="secondary" />}</InputAdornment>,
                  }}
                  label="Ship to Party" className="rounded-input-dealer w-100 pb-2" margin="dense" variant="outlined" />
              </Grid>
            )}


            <Grid item xs={6}>
              <TextField required
                value={payerId}
                id="payerId"
                error={payerIdError}
                disabled={isDisabled()}
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    {payerId && !payerIdError && <CheckCircleIcon className="valid-user-id" />}
                    {(payerId && payerIdError) && <CancelIcon color="secondary" />}</InputAdornment>,
                }}
                onChange={(e: any) => handleInputChange(e, setPayerId, setPayerIdError)}
                label="Payer" className="rounded-input-dealer w-100 pr-4 pb-2" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                value={billToParty}
                id="billToParty"
                error={billToPartyError}
                disabled={isDisabled()}
                label="Bill to Party"
                InputProps={{
                  endAdornment: <InputAdornment position="end">
                    {billToParty && !billToPartyError && <CheckCircleIcon className="valid-user-id" />}
                    {(billToParty && billToPartyError) && <CancelIcon color="secondary" />}</InputAdornment>,
                }}
                onChange={(e: any) => handleInputChange(e, setBillToParty, setBillToPartyError)}
                className="rounded-input-dealer w-100 pb-2"
                margin="dense" variant="outlined" />
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl required className="rounded-input-dealer w-100 mt-2" variant="outlined">
                <InputLabel id="companyBrandsLabel">Select Brands (Multiselect)</InputLabel>
                <Select
                  disabled
                  labelId="companyBrandsLabel"
                  id="companyBrandsSelect"
                  // error={brandNamesError}
                  multiple
                  variant="outlined"
                  value={brandName}
                  onChange={handleBrandChange}
                  input={<Input id="companyBrandsInput" />}
                  renderValue={(selected) => (
                    <div>
                      {(selected as string[]).map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </div>
                  )}
                  MenuProps={BrandMenuProps}
                >
                  {brands.map((item) => (
                    <MenuItem key={item.organizationId} value={item.make}>
                      {item.make}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
          </Grid>
          {fillAtleastOneBrandDetail && <div style={{ textIndent: '20px', color: 'red', fontWeight: 600 }}>{ADD_PARAMS_BRANDS.NO_DETAILS}</div>}
          {partiallyCompleteBrandValidation.length > 0 && <div style={{ paddingLeft: '20px', paddingRight: '20px', color: 'red', fontWeight: 600 }}><div>{ADD_PARAMS_BRANDS.PLEASE_COMPLETE} {partiallyCompleteBrandValidation.length === 1 ? partiallyCompleteBrandValidation.map((brand: any) => <span>{getNewBrand(brand) === 'Tradepoint' && sessionStorage.getItem('itemCustomerTierType') !== 'Workshop' ? 'ALAC' : getNewBrand(brand)}</span>) : partiallyCompleteBrandValidation.map((brand: any, index: number) => <span>{getNewBrand(brand)}{index < partiallyCompleteBrandValidation.length - 1 ? " - " : ""}</span>)}.</div> <div>*Note: {ADD_PARAMS_BRANDS.OTHER_PANELS_EMPTY}</div></div>}
          <Paper square style={{ marginTop: '-10px' }}>
            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Dealer Tab">
              {brandName && brandName.length > 0 && brandName.map((brand, index) => (
                <Tab label={getNewBrand(brand) === 'Tradepoint' && sessionStorage.getItem('itemCustomerTierType') !== 'Workshop' ? 'ALAC' : getNewBrand(brand)} {...allTabProps(index)} key={index} />
              ))}
            </Tabs>
          </Paper>
          {brandName && brandName.length > 0 && brandName.map((brand, index) => (
            <TabPanel value={tabValue} index={index} key={index}>
              <div className="p-4">
                <BrandDetails make={brand} formSubmitted={formSubmitted} fillAtleastOneBrandDetail={fillAtleastOneBrandDetail} partiallyCompleteBrandValidation={partiallyCompleteBrandValidation} />
              </div>
            </TabPanel>
          ))}
        </div>
      </DialogContent>
      <DialogActions className="my-2 mr-4">
        {userType !== 'afg_super_admin' && (
          <Button disabled={loading} variant="contained" color="secondary"
            className="rounded-button-dealer pl-4 pr-4" onClick={() => saveParameters()} >Save</Button>
        )}
        <Button variant="contained" className="rounded-button-dealer-cancel pl-4 pr-4" onClick={() => { props.onClose(false); resetValuesOnClickCancel(); }} >Cancel</Button>
      </DialogActions>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          // handleopenCloseSnackbar(false)
        }} message={apiResponseText} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
    </Dialog >
  )
}

export default UsersParameterDialog;