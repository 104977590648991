import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { requestCorrection, historyListRequestCorrection } from '../../store/actions/onboard.actions';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './Access.css';
import moment from 'moment-timezone';
import { Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function RequestCorrectionDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const [correctionText, setCorrectionText] = React.useState<any>("");
    const [correctionTextErrorMessage, setCorrectionTextErrorMessage] = React.useState<any>("");
    const [correctionTextError, setCorrectionTextError] = React.useState<boolean>(false);
    const { handleopenClose } = props;
    const [verify, setVerify] = React.useState<any>(false);
    const classes = useStyles();
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
    const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
    const [correctionList, setCorrectionList] = React.useState([]);
    // eslint-disable-next-line no-useless-escape
    const regex: any = /<br\s*[\/]?>/gi;

    // ONCHANGE OF TEXTAREA-AUTOSIZE 
    const handleCustomerChange = (e: any) => {
        setCorrectionText(e.target.value);
        if (e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "") {
                setCorrectionTextError(true);
            }
            else {
                setCorrectionTextError(false);
            }
        }
    }

    // VALIDATION FOR TEXTAREA OF CORRECTION MESSAGE 
    function validateCorrectionMessage() {
        let isValid: boolean = true;
        if (!correctionText || correctionText.trim() === "") {
            setCorrectionTextError(true);
            isValid = false;
            return
        } else {
            if (correctionText.split('').length > 500) {
                setCorrectionTextError(false);
                setCorrectionTextErrorMessage(true);
                isValid = false;
                return
            }
            else {
                setCorrectionTextError(false);
                setCorrectionTextErrorMessage(false);
            }
        }
        if (isValid) {
            requestCorrectionMain();
        }
    }

    // FOR POST API CALL FOR REQUEST CORRECTION API
    const requestCorrectionMain = async () => {
        let payload = {
            "correctionMessage": correctionText.replace(/(\r\n|\r|\n)/g, '<br />'),
            "addedBy": sessionStorage.getItem('webApplicationLoginId'),
            "b2bUserId": b2bUserId,
            "sapCustomerId": sapCustomerId,
        }
        let responseData: any = await dispatch(requestCorrection(payload))
        if (responseData && responseData.status === 400) {
            // props.handleopenClose(true);
            props.setOpenErrorSnackbar(true);
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage(responseData && responseData.error);
            // setCorrectionText(null);
        } else {
            props.handleopenClose(false);
            props.setOpenErrorSnackbar(false);
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage(responseData && responseData.message && responseData.message.message);
            setCorrectionText(null);
        }
    }

    // ONCLICK OF VIEW HISTORY 
    const onClickViewHistory = async () => {
        setVerify(true);
        setCorrectionText(null);
        const correctionData: any = await dispatch(historyListRequestCorrection(b2bUserId));
        if (correctionData && correctionData.data) {
            setCorrectionList(correctionData.data.dataCorrectionHistory)
        }
        // props.handleopenCloseSnackbar(true);
        // props.setOpenErrorSnackbar(false);
        // props.setSuccessMessage('Customer data correction history fetch successfully');
    }

    return (
        <Fragment>
            <Dialog
                open={props.open ? true : false}
                onClose={() => { setCorrectionText(""); props.handleopenClose(false); props.handleopenCloseSnackbar(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    handleopenClose={(event: any, reason: any) => { props.handleopenCloseSnackbar(false); setCorrectionText(""); }} message={props.successMessage}
                />
                <div className={classes.root}>
                    <div className="col-12 mt-3 p-0">
                        {verify && (
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title px-4">
                                    Correction History
                                    <IconButton aria-label="close" className="float-right" onClick={() => { props.handleopenClose(false); props.handleopenCloseSnackbar(false); handleopenClose(); setVerify(false); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); setCorrectionText(null); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        )}
                    </div>
                    {!verify && (
                        <div className="row justify-content-end">
                            <div className="col-10 mt-0">
                                <DialogContent>
                                    <div className={classes.icon}>
                                        <CheckCircleIcon className="correction-meesage-warning" fontSize="small" />
                                    </div>
                                    <div>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className={`mt-3 ${classes.correctionTextCSS}`}>
                                                <h6 className="mb-0">Please write your correction message. </h6>
                                            </div>
                                        </DialogContentText>
                                    </div>
                                </DialogContent>
                            </div>
                            <div className="col-2 mt-0">
                                <IconButton aria-label="close" className="float-right pr-5 mt-3 cursor-pointer" onClick={() => { props.handleopenClose(false); props.handleopenCloseSnackbar(false); handleopenClose(); setVerify(false); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); setCorrectionText(null); }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                    )}
                    <DialogContent>
                        {verify && (
                            <div className="col-12">
                                <div className="d-flex flex-row border-bottom">
                                    <IconButton className="p-0 m-1 toast-header-icon" edge="end" aria-label="add"
                                        onClick={() => { setVerify(false); }}>
                                        <ChevronLeftIcon fontSize="medium" />
                                        <span className="info-sub-header-document-text m-2 mt-2 px-0">Back</span>
                                    </IconButton>
                                </div>
                                {correctionList.map((data: any) => {
                                    return <div>
                                        <div className="subTitle text-preline mt-2">{data.correctionMessage ? data.correctionMessage.replace(regex, "\n") : '-'}</div>
                                        <h6 className="document-sub-content pt-1 pb-2 mb-2 border-bottom">{data.createdAt ? moment(data.createdAt).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss') : '-'}</h6>
                                    </div>
                                })
                                }
                            </div>
                        )}
                    </DialogContent>
                </div>
                {!verify && (
                    <div className="row col-md-12 mb-1 mt-0 px-5">
                        <div className="col-sm-12 col-md-12 px-5 pr-2 mb-0 mt-0">
                            <div className="col-6">
                                <TextareaAutosize id="correctionText" value={correctionText} onChange={(e: any) => handleCustomerChange(e)}
                                    aria-label="minimum height" minRows={6}
                                    placeholder="Correction Message.." className="textArea-correction p-4"
                                />
                            </div>
                            {correctionTextError &&
                                <span className="col-12" style={{ color: 'red' }}>Please enter correction message.</span>
                            }
                            {correctionTextErrorMessage &&
                                <span className="col-12" style={{ color: 'red' }}>Only 500 characters are allowed.</span>
                            }
                        </div>
                    </div>
                )}
                {!verify && (
                    <div className="row-space-start col-12">
                        <div className="col-12 m-0 p-0">
                            <div className="row mb-0 px-2">
                                <div className="col-12 mt-2">
                                    <div className="d-flex align-items-center">
                                        <Button
                                            className="mr-2 text-info"
                                            startIcon={<HistoryIcon className="history-icon font-size-22 mb-0 mt-2" />}
                                            onClick={() => { }}
                                        ><span className="onAccess-font mt-3 mb-2 pr-0" onClick={() => { onClickViewHistory(); }}
                                        >View History
                                            </span>
                                        </Button>
                                        <div className="row-space-start ml-auto">
                                            <DialogActions className="mt-3 mb-1 justify-content-end">
                                                <Button variant="contained"
                                                    color="secondary" className="submit-correction-button"
                                                    onClick={() => { validateCorrectionMessage(); }}
                                                >Submit
                                                </Button>
                                                <Button variant="contained" className={classes.closeButton}
                                                    onClick={() => { handleopenClose(); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); props.handleopenCloseSnackbar(false); setCorrectionText(null); setVerify(false); }}
                                                >Cancel
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row-space-start ml-auto">
                    <DialogActions className="mb-1 justify-content-end">
                        {verify && (
                            <Button variant="contained" className={classes.closeButton}
                                onClick={() => { handleopenClose(); setVerify(false); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); }}
                            >Close
                            </Button>
                        )}
                    </DialogActions>
                </div>
            </Dialog>
        </Fragment>
    );
}