/* eslint-disable */
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from "@material-ui/icons/Cancel";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React, { useEffect, useState, Dispatch, useRef } from 'react';
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import FullscreenLoader from '../../common/components/FullscreenLoader';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import RequestApproveDialog from "./RequestApproveDialog";
import DeleteDialog from '../../common/components/deleteDialog';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import _, { get } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { requestSpecialPriceService } from '../../services/requestSpecialPrice.service';
import { resetValues } from '../../store/actions/onboard.actions';
import { deleteDocument, uploadDocument } from '../../store/actions/dealer.action';
import { getNewBrand } from '../../common/components/util';
import { Prompt } from "react-router-dom"
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';
import Tooltip from '@material-ui/core/Tooltip';
import { getNewlyAddedBrand } from "../../common/components/util";

const useStyles = makeStyles((theme) => ({
  actionButton: {
    backgroundColor: '#00FF20',
    color: "#00FF20",
    height: "12px",
    width: "12px",
    borderRadius: "50%",
    display: "inline-block",
    marginLeft: "5px"
  },
  actionButtonComment: {
    color: '#1A9FE0',
    height: "20px",
    width: "20px"
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24,
    marginBottom: 17,
  },
}))

const styles = makeStyles({
  notchedOutline: { borderColor: "rgba(224, 224, 224, 1) !important", borderRadius: "25px 0px 0px 25px" },
  root: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "blue",
      height: "14px"
    },
  },
  root2: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "black",
      height: "14.2px",
      fontSize: "",
    },
  },
  root3: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "black",
      height: "14px",
      fontSize: "",
    },
  },
  notchedOutline1: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "0px 0px 0px 0px", },
  notchedOutline2: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "0px 25px 25px 0px", background: "", color: "" },
})

// BRAND MAPPING WITH ORG-ID's
const orgIdBrandsMapping: any = {
  "2001": "Toyota",
  "2002": "Honda",
  "2003": "Famco",
  "2380": "TE",
  "2195": "OES",
  "7099": "Toyota"
}

const RequestSpecialPrice = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [openRejectPart, setOpenRejectPart] = React.useState(false);
  const [openPriceRequest, setOpenPriceRequest] = React.useState(false);
  const history = useHistory();
  const classes2 = styles();
  const [percentage, setPercentage] = React.useState<any>("");
  const [justificationText, setJustificationText] = React.useState<any>("");
  const [justificationTextErrorMessage, setJustificationTextErrorMessage] = React.useState<any>("");
  const [justificationTextError, setJustificationTextError] = React.useState<boolean>(false);
  const [invalidUploadSpecialPriceDoc, setInvalidUploadSpecialPriceDoc] = React.useState<any>("");
  const { viewSpecialPriceDetails }: any = useSelector((state: any) => state.requestSpecialPrice);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [products, setproducts] = React.useState<any>([]);
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [openSpecialPrice, setOpenSpecialPrice] = React.useState<any>(false)
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<any>();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [openDeleteDialogkam, setOpenDeleteDialogkam] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [isEnquirySaved, setisEnquirySaved] = React.useState(false);
  const [isDiscountPrice, setIsDiscountPrice] = React.useState<any>([]);
  const [valuePriceNow, setValuePriceNow] = React.useState<any>([]);
  const [valueSelectPriceNow, setValueSelectPriceNow] = React.useState<any>(["By AED"]);
  const [valueCalculatedNow, setValueCalculatedNow] = React.useState<any>([]);
  const [selectedRadioButton, setSelectedRadioButton] = React.useState<any>('byPartsPrice');
  const [valuePriceBo, setValuePriceBo] = React.useState<any>([]);
  const [valueSelectPriceBo, setValueSelectPriceBo] = React.useState<any>([]);
  const [valueCalculatedBo, setValueCalculatedBo] = React.useState<any>([]);
  const [selectByTotal, setSelectByTotal] = React.useState<any>("By AED");
  const [offeredTotalAED, setOfferedTotalAED] = React.useState<any>("");
  const [offeredTotalPercent, setOfferedTotalPercent] = React.useState<any>("");
  const [calculatedTotal, setTotalCalculatedTotal] = React.useState<any>("");
  const [totalError, setTotalError] = React.useState<any>(false);
  const [totalOfferedAED, setTotalOfferedAED] = React.useState<any>("")
  const [quantity, setQuantity] = React.useState<any>();
  const [estimatedTotal, setEstimatedTotal] = React.useState<any>('');
  const [estimateDiscountTotal, setEstimatedDiscountTotal] = React.useState<any>('');
  const { enquirySessionId }: any = useSelector((state: any) => state.createUpdateEnquiry);
  const leftMenuVisibility: any = useSelector((state: any) => state.global && state.global.sideMenuOpened);
  const { selectedParts }: any = useSelector((state: any) => state.createUpdateEnquiry);
  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', "");
  const [uploadProofAndReference, setUploadProofAndReference] = React.useState<any>([]);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const { isAccessAchievement }: any = useSelector((state: any) => state.achievement);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const companyName = get(profileDetails, 'data.data.customerInfo.companyName', '');
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const currentTier = _.get(isAccessAchievement, "data.currentTier", "");
  const customerType = _.get(isAccessAchievement, "data.customerType", "");
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);
  const customerDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data) ? dealerDetails.data.data : null;
  const [isSubmited, setIsSubmited] = React.useState(false);
  const [brandName, setBrandName] = React.useState<any>();
  const [docPayloadID, setDocPayloadID] = React.useState<any>();
  const [docPayloadFilename, setDocPayloadFilename] = React.useState<any>();
  const [docPayloadUrl, setDocPayloadUrl] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [isdiscountedPart, setIsdiscountedPart] = React.useState<any>();
  const [requestId, setRequestId] = React.useState<any>('');
  const [docData, setDocData] = React.useState<any>();
  const [dataUpdatedPrompt, setDataUpdatedPrompt] = React.useState<boolean>(false);
  const [b2bID, setB2bID] = React.useState<any>();
  const { materialGroups }: any = useSelector((state: any) => state.materialGroups);
  let materialGroupsData = get(materialGroups, 'data.data', null);

  useEffect(() => {
    let nowReq: any
    let nowAED: any
    let nowCalculated: any
    let boReq: any = []
    let boAED: any
    let boCalculated: any
    let docuploaded: any
    let justMText: any

    viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
      justMText = ele && ele.justification
      ele && ele.products.map((data: any) => {
        nowReq = data && data.nowRequestPrice
        nowAED = data && data.nowRequestPriceType === "byPercentage" ? "By %" : data && data.nowRequestPriceType === "byCurrency" ? "By AED" : ""
        nowCalculated = data && data.nowRequestCalculatedPrice
        boReq.push({
          priceBoAED: data && data.airRequestPrice
        })
        boAED = data && data.airRequestPriceType === "byPercentage" ? "By %" : data && data.airRequestPriceType === "byCurrency" ? "By AED" : ""
        boCalculated = data && data.airRequestCalculatedPrice
      })
      ele && ele.attachments.map((docData: any) => {
        docuploaded = docData && docData.selectedFileName
      })
    })
    setValuePriceNow(nowReq)
    setValueSelectPriceNow(nowAED)
    setValueCalculatedNow(nowCalculated)
    setValuePriceBo(boReq)
    setValueSelectPriceBo(boAED)
    setValueCalculatedBo(boCalculated)
    // setUploadProofAndReference(docuploaded)
    // setJustificationText(justMText)
  }, [viewSpecialPriceDetails])

  useEffect(() => {
    let B2bEnqID: any
    viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
      B2bEnqID = ele && ele.B2bEnquiryId
    })
    setB2bID(B2bEnqID)
  }, [viewSpecialPriceDetails])

  const calculateEstimatedTotal = (productsTemp: any) => {
    if (productsTemp && productsTemp.length > 0) {
      let total: any = 0;
      let isSpecialPriceExist: any = [];
      let individualPrice: any = [];
      productsTemp.map((item: any) => {
        isSpecialPriceExist.push(item.isSpecialPriced);
        let brand: any = enquiryMapping[`${formatMaterialGroup(item.materialGroup)}`];
        if (item.nsp || item.aop) {
          if (item.nowAvailabilityChecked && item.isSpecialPriced === false) {
            if (item.isSpecialPriceRequestPart && item.isRejected == false) {
              if (item.nowRequestPriceType == 'byPercentage' && item["nowOfferedCalculatedPrice"] && item["nowOfferedCalculatedPrice"] !== '' && item["nowOfferedCalculatedPrice"] !== 'NaN') {
                individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedCalculatedPrice) })
                total += parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedCalculatedPrice);
              } else if (item.nowRequestPriceType == 'byCurrency' && item["nowOfferedPrice"] && item["nowOfferedPrice"] !== '' && item["nowOfferedPrice"] !== 'NaN') {
                individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedPrice) })
                total += parseFloat(item.nowAvailability) * parseFloat(item.nowOfferedPrice);
              } else {
                individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nsp) })
                total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
              }
            } else {
              individualPrice.push({ make: brand, total: parseFloat(item.nowAvailability) * parseFloat(item.nsp) })
              total += parseFloat(item.nowAvailability) * parseFloat(item.nsp);
            }
          }
          // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> 24 HRS CODE <<<<<<<<<<<<<<<<<<<<<
          if (customerTierType && customerTierType === "Workshop" && item.hrs24AvailabilityChecked && item.isSpecialPriced === false) {
            individualPrice.push({ make: brand, total: parseFloat(item.hrs24Availability) * parseFloat(item.nsp) })
            total += parseFloat(item.hrs24Availability) * parseFloat(item.nsp);
          }
          // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
          if (item.airAvailabilityChecked && customerTierType && customerTierType !== "Workshop" && item.isSpecialPriced === false) {
            if (item.isSpecialPriceRequestPart && item.isRejected == false) {
              if (item.airRequestPriceType == 'byPercentage' && item["airOfferedCalculatedPrice"] && item["airOfferedCalculatedPrice"] !== '' && item["airOfferedCalculatedPrice"] !== 'NaN') {
                individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.airOfferedCalculatedPrice) })
                total += parseFloat(item.airAvailability) * parseFloat(item.airOfferedCalculatedPrice);
              } else if (item.airRequestPriceType == 'byCurrency' && item["airOfferedPrice"] && item["airOfferedPrice"] !== '' && item["airOfferedPrice"] !== 'NaN') {
                individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.airOfferedPrice) })
                total += parseFloat(item.airAvailability) * parseFloat(item.airOfferedPrice);
              } else {
                individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.nsp) })
                total += parseFloat(item.airAvailability) * parseFloat(item.aop);
              }
            } else {
              individualPrice.push({ make: brand, total: parseFloat(item.airAvailability) * parseFloat(item.aop) })
              total += parseFloat(item.airAvailability) * parseFloat(item.aop);
            }
          }
        } else {
          total = total;
        }
      });
      // if (requestedTotalType === "total" && requestedCalculatedTotalType === "By %") {
      //     return parseFloat(requestedCaluclatedTotalPrice).toFixed(2);
      // } else if (requestedTotalType === "total" && requestedCalculatedTotalType === "By AED") {
      //     return parseFloat(estmateTotal).toFixed(2);
      // } else {
      //     return parseFloat(total).toFixed(2);
      // }
      if (isSpecialPriceExist && isSpecialPriceExist.length > 0 && isDiscountPrice && isDiscountPrice.length == 0) {
        setIsDiscountPrice(isSpecialPriceExist);
      }
      return parseFloat(total).toFixed(2);
    }
  }

  // TO CAPUTURE THE PROODUCTS - DATA AND SET THE DATA INTO THE STATE VALUES
  useEffect(() => {
    let quantitiyStore: any = []
    let productData = selectedParts && selectedParts.selectedParts && selectedParts.selectedParts.length && selectedParts.selectedParts.map((ele: any, i: any) => {
      quantitiyStore.push({
        "quantity": ele.quantity,
        "enquiryNumber": ele.enquiryNumber,
        "invalidMaterialGroup": ele.invalidMaterialGroup,
        "productId": ele.productId,
        "materialGroup": ele.materialGroup,
        "productIdDescription": ele.productIdDescription,
        "productCost": ele.productCost,
        "currency": ele.currency,
        "unitOfMeasure": ele.unitOfMeasure,
        "VAT": ele.VAT,
        "orgId": ele.orgId,
        "salesOrganization": ele.salesOrganization,
        "make": enquiryMapping[`${formatMaterialGroup(ele.materialGroup)}`],
        "enquiryReferenceNumber": ele.enquiryReferenceNumber,
        "isSpecialPriced": ele.isSpecialPriced,
        "nowAvailability": ele.nowAvailability,
        "nowAvailabilityChecked": ele.nowAvailabilityChecked,
        "hrs24Availability": ele.hrs24Availability,
        "hrs24AvailabilityChecked": ele.hrs24AvailabilityChecked,
        "airAvailability": ele.airAvailability,
        "airAvailabilityChecked": ele.airAvailabilityChecked,
        "nowRequestPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
        "nowRequestPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
        "nowRequestCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "",
        "airRequestPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["value"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
        "airRequestPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"],
        "airRequestCalculatedPrice": valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "",
        "nowEnquiryItemNumber": ele.nowEnquiryItemNumber,
        "nowProposedItemNumber": ele.nowProposedItemNumber,
        "hrs24EnquiryItemNumber": ele.hrs24EnquiryItemNumber,
        "hrs24ProposedItemNumber": ele.hrs24ProposedItemNumber,
        "airEnquiryItemNumber": ele.airEnquiryItemNumber,
        "airProposedItemNumber": ele.airProposedItemNumber,
        "rsp": ele.rsp,
        "nsp": ele.nsp,
        "amt": ele.amt,
        "nspev": ele.nspev,
        "aop": ele.aop,
        "airCost": ele.airCost,
        "nowCost": ele.nowCost,
        "hrs24Cost": ele.hrs24Cost
      })
    })
    setQuantity(quantitiyStore)
    let estimatedDiscountTotal = calculateEstimatedTotal(selectedParts && selectedParts.selectedParts);
    setEstimatedDiscountTotal(estimatedDiscountTotal);
    let selectedPartArray: Array<any> = selectedParts && selectedParts.selectedParts && selectedParts.selectedParts;
    let item: any = []
    for (let i = 0; i < selectedPartArray.length; i++) {
      let partDetails = selectedPartArray[i]
      item.push({ discountPart: partDetails && partDetails.isSpecialPriced, value: "By AED", priceAED: "", pricePercent: "", priceGreaterThanNow: "", errorNow: false, valueBo: "By AED", caluclatedPrice: "", priceBo: "", priceBoAED: "", priceBoPercent: "", priceGreaterThanBo: "", calcuatedPriceBo: "", errorBo: false })
    }
    setValuePriceNow(item);
    setValueSelectPriceNow(item);
    setValueCalculatedNow(item);
    setValuePriceBo(item);
    setB2bID(selectedParts && selectedParts.enquiryData && selectedParts.enquiryData ? selectedParts.enquiryData : b2bID);
    setValueSelectPriceBo(item);
    setValueCalculatedBo(item);
  }, [selectedParts])

  useEffect(() => {
    let sum: any = 0
    valuePriceNow && valuePriceNow.forEach((ele: any) => {
      sum += ele && ele.priceAED ? parseFloat(ele && ele.priceAED) : 0
    })
    valuePriceBo && valuePriceBo.forEach((ele: any) => {
      sum += ele && ele.priceBoAED ? parseFloat(ele && ele.priceBoAED) : 0
    })
    setTotalOfferedAED(sum ? sum : "")
  }, [valuePriceNow, valuePriceBo])

  useEffect(() => {
    let Data: any = [];
    let docID: any
    let docUrl: any
    let docFilename: any

    uploadProofAndReference && uploadProofAndReference.length > 0 && uploadProofAndReference.map((ele: any, i: any) => {
      Data.push(
        {
          documentId: ele && ele.documentId && ele.documentId.id,
          url: ele && ele.documentId && ele.documentId.url,
          fileName: ele && ele.documentId && ele.documentId.fileName,
          selectedFileName: ele && ele.documentId && ele.documentId.selectedFileName
        })
    })
    setDocData(Data);
    setDocPayloadID(docID);
    setDocPayloadUrl(docUrl);
    setDocPayloadFilename(docFilename);
  }, [uploadProofAndReference])

  useEffect(() => {
    let estimateTotal: any
    selectedParts && selectedParts && (
      estimateTotal = selectedParts.estPartsTotal
    )
    setEstimatedTotal(estimateTotal)
  }, [selectedParts])

  // THIS IS FOR ENQUIRY MAPPING - FOR MATERIAL GROOUP BRANDS
  let enquiryMapping: any = {};

  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    group.materialGroup.forEach((item: any) => {
      enquiryMapping[item] = group.make;
    })
  })

  // THIS IS FOR MATERIAL GROUP
  const formatMaterialGroup = (data: any) => {
    if (data) {
      return data.substring(0, 5).toUpperCase()
    } else {
      return "XXXXX"
    }
  }

  useEffect(() => {
    let brandId: any
    selectedParts && selectedParts.selectedParts && selectedParts.selectedParts.length && selectedParts.selectedParts.map((ele: any, i: any) => {
      // brandId = orgIdBrandsMapping[ele.orgId]
      brandId = enquiryMapping[`${formatMaterialGroup(ele.materialGroup)}`]
    })
    setBrandName(brandId);
  }, [selectedParts])

  // SAVE - FORM BUTTON API INTEGARTION
  const saveFormButtonAPI = async () => {
    setDataUpdatedPrompt(false);
    setLoading(true);
    let data: any
    let quantitiyStore: any = []
    let productData = selectedParts && selectedParts.selectedParts && selectedParts.selectedParts.length && selectedParts.selectedParts.map((ele: any, i: any) => {

      quantitiyStore.push({
        "quantity": ele.quantity,
        "enquiryNumber": ele.enquiryNumber,
        "invalidMaterialGroup": ele.invalidMaterialGroup,
        "productId": ele.productId,
        "materialGroup": ele.materialGroup,
        "productIdDescription": ele.productIdDescription,
        "productCost": ele.productCost,
        "currency": ele.currency,
        "unitOfMeasure": ele.unitOfMeasure,
        "VAT": ele.VAT,
        "orgId": ele.orgId,
        "salesOrganization": ele.salesOrganization,
        "make": enquiryMapping[`${formatMaterialGroup(ele.materialGroup)}`],
        "enquiryReferenceNumber": ele.enquiryReferenceNumber,
        "isSpecialPriced": ele.isSpecialPriced,
        "nowAvailability": ele.nowAvailability,
        "nowAvailabilityChecked": ele.nowAvailabilityChecked,
        "hrs24Availability": ele.hrs24Availability,
        "hrs24AvailabilityChecked": ele.hrs24AvailabilityChecked,
        "airAvailability": ele.airAvailability,
        "airAvailabilityChecked": ele.airAvailabilityChecked,
        "nowRequestPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
        "nowRequestPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
        "nowRequestCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "",
        "airRequestPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["value"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
        "airRequestPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"],
        "airRequestCalculatedPrice": valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "",
        "nowEnquiryItemNumber": ele.nowEnquiryItemNumber,
        "nowProposedItemNumber": ele.nowProposedItemNumber,
        "hrs24EnquiryItemNumber": ele.hrs24EnquiryItemNumber,
        "hrs24ProposedItemNumber": ele.hrs24ProposedItemNumber,
        "airEnquiryItemNumber": ele.airEnquiryItemNumber,
        "airProposedItemNumber": ele.airProposedItemNumber,
        "rsp": ele.rsp,
        "nsp": ele.nsp,
        "amt": ele.amt,
        "nspev": ele.nspev,
        "aop": ele.aop,
        "airCost": ele.airCost,
        "nowCost": ele.nowCost,
        "hrs24Cost": ele.hrs24Cost
      })
    })
    setQuantity(quantitiyStore)
    if (quantitiyStore.length >= 1) {
      data = await requestSpecialPriceService.getRequestSpecialPriceAPI(
        {
          "requestDetails": {
            "sapCustomerId": sapCustomerId,
            "B2bEnquiryId": selectedParts && selectedParts.enquiryData && selectedParts.enquiryData ? selectedParts.enquiryData : b2bID,
            "createdByB2bUserId": b2bUserId,
            "createdByWebApplicationLoginId": webApplicationLoginId,
            "requestedByType": selectedRadioButton === "byPartsPrice" ? "part" : "total",
            "enquirySessionId": enquirySessionId,
            "isSubmited": false,
            "customerType": customerTierType,
            "customerTierType": currentTier,
            "customerName": companyName,
            "id": requestId,
            "products": quantitiyStore,
            "justification": justificationText ? justificationText : "",
            "attachments": docData,
            "brandsName": [brandName],
            "requestTotalPrice": selectByTotal === "By AED" ? offeredTotalAED : offeredTotalPercent,
            "requestTotalPriceType": selectByTotal,
            "requestTotalPriceCalculated": calculatedTotal ? calculatedTotal.toString() : "",
            "estimateGrandTotal": estimatedTotal.toString(),
          },
          "sessionDetails": {
            "webApplicationLoginId": webApplicationLoginId,
            "clientIPAddress": sessionStorage.getItem('clientIp'),
            "userId": sessionStorage.getItem('userId')
          }
        }
      )
    }
    setRequestId(data.data.id)
    if (data && data.message && data.message.status === 'S') {
      setSuccessMessage(data && data.message && data.message.message);
      handleopenCloseSnackbar(true);
      setLoading(false)
      setshowApproveDialog(false);
      setDataUpdated(false);
    } else if (data && data.message && data.message.status === "E") {
      setSuccessMessage(data && data.error);
      handleopenCloseSnackbar(true);
      setOpenErrorSnackbar(true);
      setshowApproveDialog(false);
      setLoading(false)
      setTimeout(() => {
        handleopenCloseSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 2000)
    }
  }


  // REQUEST BUTTON API INTEGRATION 
  const requestButtonAPI = async () => {
    setDataUpdatedPrompt(false);
    setLoading(true);
    let data: any
    let quantitiyStore: any = []
    let productData = selectedParts && selectedParts.selectedParts && selectedParts.selectedParts.length && selectedParts.selectedParts.map((ele: any, i: any) => {
      quantitiyStore.push({
        "quantity": ele.quantity,
        "enquiryNumber": ele.enquiryNumber,
        "invalidMaterialGroup": ele.invalidMaterialGroup,
        "productId": ele.productId,
        "materialGroup": ele.materialGroup,
        "productIdDescription": ele.productIdDescription,
        "productCost": ele.productCost,
        "currency": ele.currency,
        "unitOfMeasure": ele.unitOfMeasure,
        "VAT": ele.VAT,
        "orgId": ele.orgId,
        "salesOrganization": ele.salesOrganization,
        "make": enquiryMapping[`${formatMaterialGroup(ele.materialGroup)}`],
        "enquiryReferenceNumber": ele.enquiryReferenceNumber,
        "isSpecialPriced": ele.isSpecialPriced,
        "nowAvailability": ele.nowAvailability,
        "nowAvailabilityChecked": ele.nowAvailabilityChecked,
        "hrs24Availability": ele.hrs24Availability,
        "hrs24AvailabilityChecked": ele.hrs24AvailabilityChecked,
        "airAvailability": ele.airAvailability,
        "airAvailabilityChecked": ele.airAvailabilityChecked,
        "nowRequestPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
        "nowRequestPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
        "nowRequestCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "",
        "airRequestPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["value"] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "byCurrency" : "byPercentage",
        "airRequestPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"],
        "airRequestCalculatedPrice": valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "",
        "nowEnquiryItemNumber": ele.nowEnquiryItemNumber,
        "nowProposedItemNumber": ele.nowProposedItemNumber,
        "hrs24EnquiryItemNumber": ele.hrs24EnquiryItemNumber,
        "hrs24ProposedItemNumber": ele.hrs24ProposedItemNumber,
        "airEnquiryItemNumber": ele.airEnquiryItemNumber,
        "airProposedItemNumber": ele.airProposedItemNumber,
        "rsp": ele.rsp,
        "nsp": ele.nsp,
        "amt": ele.amt,
        "nspev": ele.nspev,
        "aop": ele.aop,
        "airCost": ele.airCost,
        "nowCost": ele.nowCost,
        "hrs24Cost": ele.hrs24Cost
      })
    })
    setQuantity(quantitiyStore)
    if (quantitiyStore.length >= 1) {
      data = await requestSpecialPriceService.getRequestSpecialPriceAPI(
        {
          "requestDetails": {
            "sapCustomerId": sapCustomerId,
            "B2bEnquiryId": selectedParts && selectedParts.enquiryData && selectedParts.enquiryData ? selectedParts.enquiryData : b2bID,
            "createdByB2bUserId": b2bUserId,
            "createdByWebApplicationLoginId": webApplicationLoginId,
            "requestedByType": selectedRadioButton === "byPartsPrice" ? "part" : "total",
            "enquirySessionId": enquirySessionId,
            "isSubmited": true,
            "customerType": customerTierType,
            "customerTierType": currentTier,
            "customerName": companyName,
            "id": requestId,
            "products": quantitiyStore,
            "justification": justificationText ? justificationText : "",
            "attachments": docData,
            "brandsName": [brandName],
            "requestTotalPrice": selectByTotal === "By AED" ? offeredTotalAED : offeredTotalPercent,
            "requestTotalPriceType": selectByTotal,
            "requestTotalPriceCalculated": calculatedTotal ? calculatedTotal.toString() : "",
            "estimateGrandTotal": estimatedTotal.toString(),
          },
          "sessionDetails": {
            "webApplicationLoginId": webApplicationLoginId,
            "clientIPAddress": sessionStorage.getItem('clientIp'),
            "userId": sessionStorage.getItem('userId')
          }
        }
      )
    }
    setRequestId(data.data.id)
    if (data && data.message && data.message.status === 'S') {
      setSuccessMessage(data && data.message && data.message.message);
      handleopenCloseSnackbar(true);
      setDataUpdated(false);
      setTimeout(() => {
        // sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        //   mode: 'update',
        //   masterId: b2bID
        // }))
        setshowApproveDialog(false);
        setLoading(false)
        // history.push('/updateenquiry');
        history.push('/createenquiry');
      }, 2000)
    } else if (data && data.message && data.message.status === "E") {
      setSuccessMessage(data && data.error);
      handleopenCloseSnackbar(true);
      setOpenErrorSnackbar(true);
      setLoading(false)
      setshowApproveDialog(false);
      setTimeout(() => {
        handleopenCloseSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 2000)
    }
  }

  const handleCloseSpecialPrice = () => {
    setOpenSpecialPrice(false);
  }

  const handleopenCloseCustomerType = (open?: any) => {
    setOpenDeleteDialogkam(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
  };

  // ON CLICK OF DELETE - DIALOG OPEN & CLOSE FUNCTIONALITY
  const deleteDocumentDialog = () => {
    setDeleteMessage("Are you sure you want to delete this document ?");
    // setOpenDeleteDialog(true);
    setOpenDeleteDialog(true);
  }

  // ON CHANGE OPEN - CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  // ON CLICK OF RADIO - BUTTON HANDLE ON CHANGE- TOOLTIP - FUNCTIONALITY 
  const handleOnChangeRadioButtonDataLost = () => {
    if (dataUpdated) {
      setDeleteMessage('Are you sure to switch the tab ? by doing so your data will be lost.')
      handleopenClose(true);
    } else {
      setJustificationTextError(false);
      setJustificationTextErrorMessage("");
      setJustificationText("");
      setOfferedTotalPercent("");
      setOfferedTotalAED("");
      setTotalCalculatedTotal('');
    }
  }

  // HANDLE CHANGES ON CLICK OF TEXTFILEDS FOR NOW & BO
  const handleChange = (event: any) => {
    setJustificationTextError(false);
    setJustificationTextErrorMessage("");
    setJustificationText("");
    setOfferedTotalPercent("");
    setOfferedTotalAED("");
    setTotalCalculatedTotal('');
    setUploadProofAndReference("");
    if (event.target.value === "byTotalPrice") {
      let len = selectedParts && selectedParts.selectedParts && selectedParts.selectedParts.length;
      let item: any = []
      for (let i = 0; i < len; i++) {
        item.push({ value: "By AED", priceAED: "", pricePercent: "", priceGreaterThanNow: "", errorNow: false, valueBo: "By AED", caluclatedPrice: "", priceBo: "", priceBoAED: "", priceBoPercent: "", priceGreaterThanBo: "", calcuatedPriceBo: "", errorBo: false })
      }
      setValuePriceNow(item);
      setValueSelectPriceNow(item);
      setValueCalculatedNow(item);
      setValuePriceBo(item);
      setValueSelectPriceBo(item);
      setValueCalculatedBo(item);
    }
    setSelectedRadioButton(event.target.value);
  };

  // ON CHANGE OPEN CLOSE - SUCCESS SNACKBAR
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleOpenCloseRejectPartDialog = () => {
    setOpenRejectPart(false)
  }

  // ON CHANGE SELECT DEALER TYPE 
  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  // ON CHANGE OF DEALER TYPE 
  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleOpenSpecialPrice = () => {
    setOpenPriceRequest(false)
  }

  // DELETE FUNCTIONALITY FOR THE SELECTED PARTS OF THE ENQUIRY - PARTWISE
  const deleteCustomerData = async () => {
    let data: any = await dispatch(deleteDocument(customerDetails.logoId, customerDetails.sapCustomerId, false));
    if (data && data.message && data.message.status === 'S') {
      history.push('/requestspecialprice');
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }

  // ON CLICK OF BROWSE - FILE FUNCTIONALITY 
  const onClickBrowseFileasync = async (type: any, item: any) => {
    setDataUpdatedPrompt(true);
    setDataUpdated(true);
    showErrorMessage(null);
    setSuccessMessage(null);
    handleopenCloseSnackbar(false);
    handleopenCloseErrorSnackbar(false);
    setLoading(true);
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
      return;
    }
    if (item && item.size < 10000000 && uploadProofAndReference.length < 10) {
      let keyAttribute = `fieldname=&docExtension=${doctExtension}`;

      if (type == "proofAndReference") {
        let po = [...uploadProofAndReference];
        po.push({ documentId: item });
        setUploadProofAndReference(po);
      }
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };

      let docType = '';
      if (type === 'proofAndReference') { docType = 'proofAndReference' }
      const formData: any = new FormData();
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapCustomerId);
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealer');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      let documentRes: any = await dispatch(uploadDocument(formData, customHeaders));
      if (documentRes && documentRes.message && documentRes.message.status === 'S') {
        const { docType, id } = documentRes.data;
        if (docType === "proofAndReference") {
          let po = [...uploadProofAndReference];
          po.push({ documentId: documentRes.data });
          setUploadProofAndReference(po);
          setLoading(false)
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        setLoading(false)
      }
    }
    else if (uploadProofAndReference.length >= 10) {
      showErrorMessage('Should not exceed 10 files.');
      handleopenCloseErrorSnackbar(true);
      setLoading(false)
    } else if (item && item.size > 10000000) {
      showErrorMessage('Please upload less than 10MB file');
      handleopenCloseErrorSnackbar(true);
      setLoading(false)
    }
    setLoading(false)
  }

  // ON CLICK OF REMOVE FILE BUTTON FUNCTIONALITY
  const removeDocument = async (docType: any) => {
    setDataUpdated(true);
    dispatch(resetValues());
    let doc = [...uploadProofAndReference]

    const obj = {
      "docType": docType
    }
    const documentId = doc[currentIndex].documentId && doc[currentIndex].documentId.id;
    let response: any = await dispatch(deleteDocument(documentId, sapCustomerId, true));
    if (response && response.message && response.message.status === 'S') {
      doc[currentIndex].documentId = null;
      doc.splice(currentIndex, 1);
      setUploadProofAndReference(doc);
      setLoading(true);
      setSuccessMessage(response.message.message);
      handleopenCloseSnackbar(true);
      setOpenDeleteDialog(false);
      setTimeout(() => {
        setLoading(false);
      }, 4000)
    } else {
      showErrorMessage(response.error);
      handleopenCloseErrorSnackbar(true);
    }
  }

  // ON-CHANGE OF TEXTAREA-AUTOSIZE - JUSTIFICATION
  const handleJustificationChange = (e: any) => {
    setDataUpdatedPrompt(true);
    setDataUpdated(true);
    setJustificationText(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setJustificationTextError(true);
      }
      else {
        setJustificationTextError(false);
      }
    }
  }

  // ON CLICK OF BACK BUTTON FUNCTIONALITY 
  const handleBackClick = () => {
    if (dataUpdatedPrompt) {
      setDeleteMessage('Do you wish to proceed without saving the data?')
      handleopenClose(true);
      history.push('/updateenquiry');
      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        mode: 'update',
        masterId: b2bID
      }))
    } else {
      history.push('/updateenquiry');
      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        mode: 'update',
        masterId: b2bID
      }))
      setJustificationText("");
    }
  }

  // VALIDATION FOR REQUEST BUTTON - TO FILL AT LEAST 1 FILED
  const validateRequest = () => {
    let valuePriceNowfield = valuePriceNow.filter((valuePriceNow: any) => (valuePriceNow.priceAED) || (valuePriceNow.pricePercent))
    let valuePriceBofield = valuePriceBo.filter((valuePriceBo: any) => (valuePriceBo.priceBoAED) || (valuePriceBo.priceBoPercent))
    let check = valuePriceNow.every((element: any) => element.discountPart === true);

    if (selectedRadioButton === "byPartsPrice" && valuePriceBofield.length <= 0 && valuePriceNowfield.length <= 0 && check !== true) {
      showErrorMessage("Please fill at least one text field");
      handleopenCloseErrorSnackbar(true);
      setOpenErrorSnackbar(true);
    } else if (selectedRadioButton === "byTotalPrice") {
      if (selectByTotal === "By AED" ? offeredTotalAED === "" : offeredTotalPercent === "") {
        showErrorMessage("Please fill offered total");
        handleopenCloseErrorSnackbar(true);
        setOpenErrorSnackbar(true);
      } else {
        setshowApproveDialog(true);
        handleopenCloseErrorSnackbar(false);
        setOpenErrorSnackbar(false);
      }
    } else {
      handleopenCloseErrorSnackbar(false);
      setOpenErrorSnackbar(false);
      setshowApproveDialog(true);
    }
  }

  // ON CLICK OF SAVE FORM BUTTON VALIDATION 
  const validateJustificationMessageSaveForm = () => {
    let isValid: boolean = true;
    if (!justificationText || justificationText.trim() === "") {
      setJustificationTextError(true);
      isValid = false;
      return
    } else {
      if (justificationText.split('').length > 1000) {
        setJustificationTextError(false);
        setJustificationTextErrorMessage(true);
        isValid = false;
        return
      }
      else {
        setJustificationTextError(false);
        setJustificationTextErrorMessage(false);
      }
    }
    if (isValid) {
      setInvalidUploadSpecialPriceDoc(false)
      setshowApproveDialog(false);
      saveFormButtonAPI();
    }
  }

  // ON CLICK OF REQUEST BUTTON VALIDATION 
  const validateJustificationMessageRequest = () => {
    let isValid: boolean = true;
    if (!justificationText || justificationText.trim() === "") {
      setJustificationTextError(true);
      isValid = false;
      return
    } else {
      if (justificationText.split('').length > 1000) {
        setJustificationTextError(false);
        setJustificationTextErrorMessage(true);
        isValid = false;
        return
      }
      else {
        setJustificationTextError(false);
        setJustificationTextErrorMessage(false);
      }
    }
    if (isValid) {
      setInvalidUploadSpecialPriceDoc(false)
      setJustificationTextErrorMessage(false);
      setJustificationTextError(false);
      validateRequest();
    }
  }

  // HANDLE TOTAL CALCULATIONS - FUNCTION 
  const handleCalculationTotal = (e: any) => {
    setDataUpdatedPrompt(true);
    setDataUpdated(true);
    if (selectByTotal === "By AED") {
      let maxValue = ""
      let total: any = estimateDiscountTotal && isDiscountPrice && isDiscountPrice.length > 0 && isDiscountPrice.includes(true) ? estimateDiscountTotal : estimatedTotal;
      // let total: any = estimatedTotal;
      e.target.value ? parseFloat(e.target.value) <= total && setOfferedTotalAED(e.target.value) : setOfferedTotalAED("")
      if ((e.target.value !== "")) {
        let total: any = estimateDiscountTotal && isDiscountPrice && isDiscountPrice.length > 0 && isDiscountPrice.includes(true) ? estimateDiscountTotal : estimatedTotal;
        // let total: any = estimatedTotal;
        if (parseFloat(e.target.value) <= parseFloat(total)) {
          let value: any = (parseFloat(total) - parseFloat(e.target.value) <= parseFloat(total) ? parseFloat(e.target.value) : parseFloat(offeredTotalAED)) / parseFloat(total);
          setTotalCalculatedTotal(((value * 100) - 100).toFixed(2) + "%")
        }
      } else {
        setTotalCalculatedTotal("");
      }
    } else if (selectByTotal === "By %") {
      if ((e.target.value !== "")) {
        if (e.target.value <= 100) {
          setOfferedTotalPercent(e.target.value);
          let percent = (100 - e.target.value) / 100
          let total: any = estimateDiscountTotal && isDiscountPrice && isDiscountPrice.length > 0 && isDiscountPrice.includes(true) ? estimateDiscountTotal : estimatedTotal;
          // let total: any = estimatedTotal;
          const value = percent * parseFloat(total)
          value.toFixed(2)
          setTotalCalculatedTotal(value)
          setTotalError(false)
        }
        else if (e.target.value >= 100) {
          setTotalError(true)
        }
      } else {
        setTotalCalculatedTotal("");
        setOfferedTotalPercent("");
      }
    }
  }

  // HANDLE SELECTED TOTAL - FUNCTION
  const handelSelectTotal = (e: any) => {
    setSelectByTotal(e.target.value)
    if (e.target.value === "By AED") {
      setOfferedTotalPercent("");
      if ((offeredTotalAED !== "")) {
        let total: any = estimateDiscountTotal && isDiscountPrice && isDiscountPrice.length > 0 && isDiscountPrice.includes(true) ? estimateDiscountTotal : estimatedTotal;
        let value: any = (parseFloat(total) - parseFloat(offeredTotalAED)) / parseFloat(total);
        setTotalCalculatedTotal(((value * 100) - 100).toFixed(2) + "%")
      } else {
        setTotalCalculatedTotal("");
      }
    } else if (e.target.value === "By %") {
      setOfferedTotalAED("");
      if ((offeredTotalPercent !== "")) {
        if (offeredTotalPercent <= 100) {
          let percent = (100 - offeredTotalPercent) / 100
          let total: any = 0;
          const value = percent * parseFloat(total)
          value.toFixed()
          setTotalCalculatedTotal(value)
          setTotalError(false)
        } else if (offeredTotalPercent >= 100) {
          setTotalError(true)
        }
      } else {
        setTotalCalculatedTotal("");
      }
    }
  }

  // TOTAL OFFERED AED - FUNCTION
  const TotalOfferedAed = () => {
    let sum: any = 0
    valuePriceNow.forEach((ele: any) => {
      sum += ele.priceAED ? parseFloat(ele.priceAED) : 0
    })
    valuePriceBo.forEach((ele: any) => {
      sum += ele.priceBoAED ? parseFloat(ele.priceBoAED) : 0
    })
    return sum.toString()
  }

  // TO RESET THE DATA ON CLICK OF CLOSE
  const closeButtonResetData = () => {
    if (dataUpdated) {
      setDeleteMessage('Do you wish to proceed without saving the data?')
      handleopenClose(true);
      history.push('/updateenquiry');
      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        mode: 'update',
        masterId: b2bID
      }))
    } else {
      history.push('/updateenquiry');
      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        mode: 'update',
        masterId: b2bID
      }))
      setJustificationText("");
    }
  }

  return (
    <div>
      {loading && (<FullscreenLoader />)}
      {uploadProofAndReference && uploadProofAndReference.loading && <FullscreenLoader />}
      {selectedParts && selectedParts.loading && <FullscreenLoader />}
      <div>
        <div className="d-flex align-items-center mt-2 mb-4">
          <h2 className="page-title"> Request Special Price</h2>
          <h3 className="page-sub-title mb-0 px-3">
            ( Enquiry Id -{selectedParts && selectedParts.enquiryData && selectedParts.enquiryData ? selectedParts.enquiryData : b2bID} )
          </h3>
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        </div>
        <div>
          <div className="d-flex bd-highlight mt-2 mb-2">
            <div className="flex-grow-1 bd-highlight">
              {/* <h5 className="heading-text-special-text mt-2">Date - </h5> */}
            </div>
            <div className="row bd-highlight">
              <div className="row legendItemCreateEnquiry m-2 mt-3">
                <span className="dot"></span>
                <span className="row font-style-color mr-4 ml-2">Discounted Price</span>
              </div>
              <Button variant="contained" className={`resetToDefaultActionButton border-left rounded-pill mr-3`}
                onClick={() => { handleBackClick(); }}
              >Back To Order Enquiry</Button>
            </div>
          </div>
        </div>
        <div className="col-12 card special-price-radiobutton mb-2" id="demo-radio-buttons-group-label">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue=""
            name="radio-buttons-group"
            onChange={handleChange} value={selectedRadioButton}
          >
            <div className="row col-12 mt-2 d-flex justify-content-start">

              <div className="col-3 headers-font-style">
                <Tooltip title="Are you sure to change the tab, By doing so your data will be lost." placement="top">
                  <FormControlLabel className=""
                    value="byPartsPrice" control={<Radio color="primary" />}
                    label="By Parts Price" />
                </Tooltip>
              </div>
              <div className="col-3 headers-font-style">
                <Tooltip title="Are you sure to change the tab, By doing so your saved data will be lost." placement="top">
                  <FormControlLabel className=''
                    value="byTotalPrice" control={<Radio color="primary" />}
                    label="By Total Price" />
                </Tooltip>
              </div>
              <div className="col-5">
              </div>
              <div className="col-1 mt-2 px-3">
                <span
                  className="mr-2 cursor-pointer"
                  onClick={() => {
                    let hasDeletions = false;
                    let selected: any = [];
                    let productsTemp: any = JSON.parse(
                      JSON.stringify(products)
                    );
                    productsTemp = productsTemp.filter(
                      (item: any) => {
                        if (item.selected) {
                          selected.push(item.productId)
                          hasDeletions = true;
                        }
                        return !item.selected
                      }
                    );
                    if (isEnquirySaved) {
                      if (hasDeletions) {
                        // saveUpdateEnquiry(false, true)
                      }
                    } else {
                      setproducts([...productsTemp]);
                    }
                  }}
                >
                </span>
              </div>
            </div>
          </RadioGroup>
        </div>
        <div className="cardCommon mb-6">
          <div className="card" unselectable="on">
            <div className="customTableContainerCreateEnquiry">
              <div className="tableRootCreateEnquiry">
                <thead className="tableHeadCreateEnquiry">
                  <tr className="trCreateEnquiry">
                    <th className="thCreateEnquiry ml-1">
                      <h6 className="blueLabelTable-font"></h6>
                      <h6 className="dealer-table-title-desc"></h6>
                    </th>
                    <th className="thCreateEnquiry">
                      <h6 className="blueLabelTable-font">Part No.</h6>
                      <h6 className="dealer-table-title-desc"></h6>
                    </th>
                    <th className="thCreateEnquiry mw-100p">
                      <h6 className="blueLabelTable-font">Brand</h6>
                      <h6 className="dealer-table-title-desc"></h6>
                      <div className="row d-flex flex-row no-gutters">
                      </div>
                    </th>
                    <th className="thCreateEnquiry ml-1">
                      <h6 className="blueLabelTable-font"></h6>
                      <h6 className="dealer-table-title-desc"></h6>
                    </th>
                    <th className="thCreateEnquiry px-0 font-10 font-weight-700 text-444">
                      <h6 className="blueLabelTable-font px-0 mt-3 pr-3">NSP</h6>
                      <h6 className="dealer-table-title-desc px-0 pr-3">(Excl. VAT)</h6>
                    </th>
                    <th className="thCreateEnquiry font-10 font-weight-700 text-444">
                      <h6 className="blueLabelTable-font">NOW Qty</h6>
                      <h6 className="dealer-table-title-desc"></h6>
                    </th>
                    <th className="thCreateEnquiry font-10 font-weight-700 text-444">
                      <h6 className="blueLabelTable-font mt-3">NOW Req Price</h6>
                      <h6 className="dealer-table-title-desc">(Per Qty)</h6>
                    </th>
                    <th className="thCreateEnquiry font-10 font-weight-700 text-444">
                      <h6 className="blueLabelTable-font">BOP</h6>
                      <h6 className="dealer-table-title-desc"></h6>
                    </th>
                    <th className="thCreateEnquiry font-10 font-weight-700 text-444">
                      <h6 className="blueLabelTable-font">BO Qty</h6>
                      <h6 className="dealer-table-title-desc"></h6>
                    </th>
                    <th className="thCreateEnquiry font-10 font-weight-700 text-444">
                      <h6 className="blueLabelTable-font mt-3">BO Req Price</h6>
                      <h6 className="dealer-table-title-desc">(Per Qty)</h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedParts && selectedParts.selectedParts && selectedParts.selectedParts.map((ele: any, i: any) => {
                    return (
                      <tr className="tableRowCreateEnquiry">
                        <td className="tdchildCreateEnquiry-specialprice"><div className="d-flex" onClick={() => { setOpenRejectPart(true) }}>
                          <div className="pl-3 pr-0" style={{ display: "inline" }}>
                            <div className="dealer-table-content dealer-company-name hoverRejectPart">
                              {ele.isSpecialPriced === true ?
                                <FiberManualRecordIcon fontSize="inherit" className={classes.actionButton} />
                                : ""}
                            </div>
                            <div className="dealer-table-subcontent"> { }</div>
                          </div>
                        </div>
                        </td>
                        <td className="tdchildCreateEnquiry-specialprice">
                          <div className="d-flex flex-column">
                            <div className="pt-1 font-style-color"> {ele.productId}</div>
                            <div className="pt-1 pb-1 font-style-color-subheader"> {ele.productIdDescription}</div>
                          </div>
                        </td>
                        <td className="tdchildCreateEnquiry-specialprice font-style-color">{customerTierType !== 'Workshop' ?  getNewlyAddedBrand(enquiryMapping[`${formatMaterialGroup(ele.materialGroup)}`]) : getNewBrand(enquiryMapping[`${formatMaterialGroup(ele.materialGroup)}`])}</td>
                        <td className="tdchildCreateEnquiry-specialprice">
                          <div className="d-flex flex-column">
                          </div>
                        </td>
                        <td className="tdchildCreateEnquiry-specialprice font-style-color ml-3">{ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "-"}</td>
                        <td className="tdchildCreateEnquiry-specialprice font-style-color px-3">{ele.nowAvailability && ele.nowAvailabilityChecked ? ele.nowAvailability : "0"}</td>
                        <td className="tdchildCreateEnquiry-specialprice">
                          <div className="mb-2 pr-0 d-flex flex-row " style={{ position: "relative" }}>
                            <div className="mt-2">
                              <TextField select
                                id=""
                                label=""
                                value={valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] ? valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] : ""}
                                variant="outlined"
                                className="commonRoundedInputs-dropdown-viewSpecialPrice"
                                size="small"
                                disabled={selectedRadioButton === "byTotalPrice" || (ele && ele.nowAvailabilityChecked === false) || (ele && ele.isSpecialPriced && ele.isSpecialPriced === true)}
                                InputLabelProps={{
                                  style: { color: '#000000', background: "white !important" },
                                }}
                                onChange={(e: any) => {
                                  setDataUpdatedPrompt(true);
                                  setDataUpdated(true);
                                  let data = [...valueSelectPriceNow]
                                  data[i]["value"] = e.target.value
                                  let value: any = e.target.value

                                  setValueSelectPriceNow(data)
                                  if (e.target.value === "By AED") {
                                    let data: any = [...valuePriceNow];
                                    data[i]["pricePercent"] = "";
                                    setValuePriceNow(data);
                                    if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"]) !== "") {
                                      let value: any = (parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "") - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"])) / parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "")
                                      let valueBo = [...valueCalculatedNow]
                                      valueBo[i]["calcuatedPrice"] = (value * 100).toFixed(2) + "%"
                                      setValueCalculatedNow(valueBo)
                                    } else {
                                      let valueBo = [...valueCalculatedNow]
                                      valueBo[i]["calcuatedPrice"] = ""
                                      setValueCalculatedNow(valueBo)
                                    }
                                  } else if (e.target.value === "By %") {
                                    let data: any = [...valuePriceNow];
                                    data[i]["priceAED"] = "";
                                    setValuePriceNow(data);
                                    let valueBo = [...valueCalculatedNow]
                                    valueBo[i]["errorNow"] = false
                                    setValueCalculatedNow(valueBo)
                                    if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) !== "") {
                                      if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) <= 100) {
                                        let dataCalculated = [...valueCalculatedNow]
                                        let percent = (100 - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"])) / 100
                                        const value = percent * parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "")
                                        valueBo[i]["calcuatedPrice"] = value.toFixed(2)
                                      } else if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) >= 100) {
                                        let valueBo = [...valueCalculatedNow]
                                        valueBo[i]["errorNow"] = false
                                        setValueCalculatedNow(valueBo)
                                      }
                                    } else {
                                      let valueBo = [...valueCalculatedNow]
                                      valueBo[i]["calcuatedPrice"] = ""
                                      setValueCalculatedNow(valueBo)
                                    }
                                  }
                                }}
                                style={{ width: "90px" }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes2.notchedOutline,
                                    root: classes2.root
                                  }
                                }}
                              >
                                {["By AED", "By %"].map((data: any, index: any) => (
                                  <MenuItem key={index} value={data}> {data} </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <TextField id="august"
                              type="number"
                              disabled={selectedRadioButton === "byTotalPrice" || (ele && ele.nowAvailabilityChecked === false) || (ele && ele.isSpecialPriced && ele.isSpecialPriced === true)}
                              value={valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"])}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                setDataUpdatedPrompt(true);
                                setDataUpdated(true);
                                let data: any = [...valuePriceNow]
                                parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "") ? data[i]["priceAED"] = e.target.value : data[i]["priceGreaterThanNow"] = e.target.value : data[i]["priceAED"] = "";
                                let selected = data[i]["value"]
                                setValueSelectPriceNow(data)
                                if (selected === "By AED") {
                                  let data: any = [...valuePriceNow]
                                  let valueBo = [...valueCalculatedNow]
                                  parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "") ? data[i]["priceAED"] = e.target.value : data[i]["priceGreaterThanNow"] = e.target.value : data[i]["priceAED"] = "";
                                  parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat(ele.nspev) ? (data[i]["priceAED"] = e.target.value)
                                    : data[i]["priceGreaterThanNow"] = e.target.value
                                    : data[i]["priceAED"] = "";
                                  data[i]["pricePercent"] = ""
                                  setValueSelectPriceNow(data)
                                  setValueCalculatedNow(valueBo)
                                  if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"]) !== "") {
                                    let value: any = (parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "") - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["priceAED"])) / parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "")
                                    let valueBo = [...valueCalculatedNow]
                                    valueBo[i][parseFloat(e.target.value) <= data[i]["priceAED"] ? "calcuatedPrice" : "calculatedPriceGreaterNow"] = (value * 100).toFixed(2) + "%"
                                    setValueCalculatedNow(valueBo)
                                  }
                                  else {
                                    let valueBo = [...valueCalculatedNow]
                                    valueBo[i]["calcuatedPrice"] = ""
                                    setValueCalculatedBo(valueBo)
                                  }
                                } else if (selected === "By %") {
                                  let data: any = [...valuePriceNow]
                                  data[i]["priceAED"] = ""
                                  parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat("99.99") ? data[i]["pricePercent"] = e.target.value : data[i]["priceGreaterThanNow"] = e.target.value : data[i]["pricePercent"] = "";
                                  setValueSelectPriceNow(data)
                                  if ((valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) !== "") {
                                    if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) <= 100) {
                                      let percent = (100 - parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"])) / 100
                                      const value = percent * parseFloat(ele.nowAvailabilityChecked && ele.nspev ? ele.nspev : "");
                                      let valueBo = [...valueCalculatedNow]
                                      valueBo[i]["calcuatedPrice"] = value.toFixed(2)
                                      valueBo[i]["errorNow"] = false;
                                      setValueCalculatedBo(valueBo)
                                    } else if (parseFloat(valuePriceNow && valuePriceNow[i] && valuePriceNow[i] && valuePriceNow[i]["pricePercent"]) >= 100) {
                                      let valueBo = [...valueCalculatedNow]
                                      valueBo[i]["errorNow"] = false;
                                      setValueCalculatedBo(valueBo)
                                    }
                                  } else {
                                    let valueBo = [...valueCalculatedNow]
                                    valueBo[i]["calcuatedPrice"] = ""
                                    setValueCalculatedBo(valueBo)
                                  }
                                }
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes2.notchedOutline1,
                                  root: classes2.root2
                                }
                              }}
                              // inputProps={{ maxLength: valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? "" : 2 }}
                              style={{ height: "0px !important", width: "110px" }}
                              size="small" className=""
                              label={valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? "Enter Price" : "Enter %"} variant="outlined" margin="dense" />
                            <TextField id="august"
                              type="text"
                              disabled={selectedRadioButton === "byTotalPrice" && selectedRadioButton === "byPartsPrice"}
                              value={valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? `-${valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"]}` : "" : valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : ""}
                              style={{ minWidth: "0px", width: "90px", backgroundColor: "rgba(224, 224, 224, 1)", borderRadius: "10px !important" }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                setPercentage(e.target.value)
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes2.notchedOutline2,
                                  root: classes2.root3
                                }
                              }}
                              size="small" className="borderRadiusText"
                              label="" variant="outlined" margin="dense" />
                          </div>
                        </td>
                        <td className="tdchildCreateEnquiry-specialprice font-style-color ml-3 px-3">{ele.airAvailabilityChecked && ele.amt ? ele.amt : "-"}</td>
                        <td className="tdchildCreateEnquiry-specialprice font-style-color">{ele.airAvailability && ele.airAvailabilityChecked ? ele.airAvailability : "0"}</td>
                        {/* <td className="tdchildCreateEnquiry-specialprice font-style-color">{"-"}</td> */}
                        <td className="tdchildCreateEnquiry-specialprice">
                          <div className=" mb-2 pr-0 d-flex flex-row " style={{ position: "relative" }}>
                            <div className="mt-2">
                              <TextField select
                                id="selectYear"
                                label=""
                                disabled={selectedRadioButton === "byTotalPrice" || (ele && ele.airAvailabilityChecked === false) || (ele && ele.isSpecialPriced && ele.isSpecialPriced === true)}
                                value={valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] ? valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] : ""}
                                variant="outlined"
                                className="commonRoundedInputs-dropdown-viewSpecialPrice"
                                size="small"
                                InputLabelProps={{
                                  style: { color: '#000000', background: "white !important" },
                                }}
                                onChange={(e: any) => {
                                  setDataUpdated(true);
                                  let data = [...valueCalculatedBo]
                                  data[i]["valueBo"] = e.target.value
                                  setValueSelectPriceBo(data)
                                  let valueBo = [...valueCalculatedBo]
                                  valueBo[i]["calcuatedPriceBO"] = ""
                                  setValueCalculatedNow(valueBo)
                                  if (e.target.value === "By AED") {
                                    let data: any = [...valuePriceBo]
                                    data[i]["priceBoPercent"] = ""
                                    setValuePriceBo(data);
                                    if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"]) !== "") {
                                      let value: any = (parseFloat(ele.amt) - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"])) / parseFloat(ele.amt)
                                      let valueBo = [...valueCalculatedBo]
                                      valueBo[i]["calcuatedPriceBo"] = ""
                                      setValueCalculatedBo(valueBo)
                                    } else {
                                      let valueBo = [...valueCalculatedBo]
                                      valueBo[i]["calcuatedPriceBo"] = ""
                                      setValueCalculatedBo(valueBo)
                                    }

                                  } else if (e.target.value === "By %") {
                                    let data: any = [...valuePriceBo]
                                    data[i]["priceBoAED"] = ""
                                    setValuePriceBo(data);
                                    if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) !== "") {
                                      if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) <= 100) {
                                        let dataCalculated = [...valueCalculatedBo]
                                        let percent = (100 - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"])) / 100
                                        const value = percent * parseFloat(ele.amt)
                                        let valueBo = [...valueCalculatedBo]
                                        valueBo[i]["calcuatedPriceBo"] = ""
                                        valueBo[i]["errorBo"] = false
                                        setValueCalculatedBo(valueBo)
                                      } else if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["price"]) >= 100) {
                                        let valueBo = [...valueCalculatedBo]
                                        valueBo[i]["errorBo"] = true
                                        setValueCalculatedBo(valueBo)
                                      }

                                    } else {
                                      let valueBo = [...valueCalculatedBo]
                                      valueBo[i]["calcuatedPriceBO"] = ""
                                      setValueCalculatedNow(valueBo)
                                    }
                                  }
                                }}
                                style={{ width: "90px" }}
                                InputProps={{
                                  classes: {
                                    notchedOutline: classes2.notchedOutline,
                                    root: classes2.root
                                  }
                                }}
                              >
                                {["By AED", "By %"].map((data: any, index: any) => (
                                  <MenuItem key={index} value={data}> {data} </MenuItem>
                                ))}
                              </TextField>
                            </div>
                            <TextField id="august"
                              type="number"
                              disabled={selectedRadioButton === "byTotalPrice" || (ele && ele.airAvailabilityChecked === false) || (ele && ele.isSpecialPriced && ele.isSpecialPriced === true)}
                              value={valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                setDataUpdatedPrompt(true);
                                setDataUpdated(true);
                                let data: any = [...valuePriceBo]
                                let dataSelected = [...valueSelectPriceBo]
                                let selected = data[i]["valueBo"]
                                if (selected === "By AED") {
                                  let data: any = [...valuePriceBo]
                                  parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat(ele.amt) ? data[i]["priceBoAED"] = e.target.value : data[i]["priceGreaterThanBo"] = e.target.value : data[i]["priceBoAED"] = "";
                                  setValuePriceBo(data)
                                  if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"]) !== "") {
                                    let value: any = (parseFloat(ele.amt) - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"])) / parseFloat(ele.amt)
                                    let valueBo = [...valueCalculatedBo]
                                    valueBo[i]["calcuatedPriceBo"] = (value * 100).toFixed(2) + "%";
                                    setValueCalculatedBo(valueBo)
                                  }
                                  else {
                                    let valueBo = [...valueCalculatedBo]
                                    valueBo[i]["calcuatedPriceBo"] = ""
                                    setValueCalculatedBo(valueBo)
                                  }
                                } else if (selected === "By %") {
                                  let data: any = [...valuePriceBo]
                                  parseFloat(e.target.value) ? parseFloat(e.target.value) <= parseFloat("99.99") ? data[i]["priceBoPercent"] = e.target.value : data[i]["priceGreaterThanBo"] = e.target.value : data[i]["priceBoPercent"] = "";
                                  setValuePriceBo(data)
                                  if ((valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) !== "") {
                                    if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) <= 100) {
                                      let percent = (100 - parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"])) / 100
                                      const value = percent * parseFloat(ele.amt);
                                      let valueBo = [...valueCalculatedBo]
                                      valueBo[i]["calcuatedPriceBo"] = value.toFixed(2)
                                      valueBo[i]["errorBo"] = false
                                      setValueCalculatedBo(valueBo)
                                    } else if (parseFloat(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]) >= 100) {
                                      let valueBo = [...valueCalculatedBo]
                                      valueBo[i]["errorBo"] = true
                                      setValueCalculatedBo(valueBo)
                                    }
                                  } else {
                                    let valueBo = [...valueCalculatedBo]
                                    valueBo[i]["calcuatedPriceBo"] = ""
                                    setValueCalculatedBo(valueBo)
                                  }
                                }
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes2.notchedOutline1,
                                  root: classes2.root2
                                }
                              }}
                              style={{ height: "0px !important", width: "110px" }}
                              size="small" className=""
                              label={valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? "Enter Price" : "Enter %"} variant="outlined" margin="dense" />
                            <TextField id="august"
                              type="text"
                              disabled={selectedRadioButton === "byTotalPrice" && selectedRadioButton === "byPartsPrice"}
                              // value={valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : ""}
                              value={selectedRadioButton === "byTotalPrice" ? "" : valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? `${valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"].includes("-") ? "" : "-"}${valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"]}` : "" : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] || valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : ""}
                              style={{ minWidth: "0px", width: "90px", backgroundColor: "rgba(224, 224, 224, 1)", borderRadius: "10px !important" }}
                              onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                              onChange={(e: any) => {
                                setPercentage(e.target.value)
                              }}
                              InputProps={{
                                classes: {
                                  notchedOutline: classes2.notchedOutline2,
                                  root: classes2.root3
                                }
                              }}
                              size="small" className="borderRadiusText"
                              label={""} variant="outlined" margin="dense" />
                          </div>
                        </td>
                      </tr>)
                    // }
                  })
                  }
                </tbody>
              </div>
            </div>
          </div>

          <div className="col-12 card card special-price-content borderBottomViewPrice mt-1 mb-2" id="demo-radio-buttons-group-label">
            <div className="row  col-12  ">
              <div className="p-1 d-flex flex-row total-margin pr-5">
                <>
                  <>
                    <div className="d-flex flex-column">
                      <h6 className="row font-style-color-header mt-2 ml-1"> Estimated Total : AED  {parseFloat(estimatedTotal).toFixed(2)} </h6>
                      <div className="pb-1 ml-1 font-weight-light font-size-14"> For All Parts</div>
                    </div>
                    {isDiscountPrice && isDiscountPrice.length > 0 && isDiscountPrice.includes(true) && (
                      <div className="d-flex flex-column pl-5">
                        <h6 className="row font-style-color-header mt-2 ml-1"> Estimated Total : AED  {calculateEstimatedTotal(selectedParts && selectedParts.selectedParts)} </h6>
                        <div className="pb-1 ml-1 font-weight-light font-size-14">Excluding Discount Parts</div>
                      </div>
                    )}
                  </>
                </>
              </div>
              {selectedRadioButton !== "byTotalPrice" ? <></> : <div className="" style={{}}>
                {/* <p>Offered Total</p> */}
                <div className=" mb-2 pr-0 pl-2 d-flex flex-row mt-2" style={{ position: "relative" }}>
                  <div className=" d-flex flex-row">
                    <div className="row">
                      <div className="mt-2">
                        <TextField select
                          id="selectYear"
                          label=""
                          value={selectByTotal}
                          variant="outlined"
                          className="commonRoundedInputs-dropdown-viewSpecialPrice"
                          size="small"
                          InputLabelProps={{
                            style: { color: '#000000', background: "white !important" },
                          }}
                          onChange={handelSelectTotal}
                          style={{ width: "90px" }}
                          InputProps={{
                            classes: {
                              notchedOutline: classes2.notchedOutline,
                              root: classes2.root
                            }
                          }}
                        >
                          {["By AED", "By %"].map((data: any, index: any) => (
                            <MenuItem key={index} value={data}> {data} </MenuItem>
                          ))}
                        </TextField>
                      </div>
                      <TextField id="august"
                        type="number"
                        value={selectByTotal === "By AED" ? offeredTotalAED : offeredTotalPercent}
                        onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        onChange={handleCalculationTotal}
                        InputProps={{
                          classes: {
                            notchedOutline: classes2.notchedOutline1,
                            root: classes2.root2
                          }
                        }}
                        style={{ height: "0px !important", width: "110px" }}
                        size="small" className=""
                        inputProps={{ maxLength: selectByTotal === "By AED" ? "" : 2 }}
                        label={`${selectByTotal === "By AED" ? "Enter Price" : "Enter %"}`} variant="outlined" margin="dense" disabled={false} />
                      <TextField id="august"
                        type="text"
                        value={calculatedTotal ? calculatedTotal : ""}
                        style={{ minWidth: "0px", width: "120px", backgroundColor: "rgba(224, 224, 224, 1)", borderRadius: "10px !important" }}
                        onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                        onChange={(e: any) => {
                          TotalOfferedAed()
                        }}
                        InputProps={{
                          classes: {
                            notchedOutline: classes2.notchedOutline2,
                            root: classes2.root3
                          }
                        }}
                        size="small" className="borderRadiusText"
                        label="" variant="outlined" margin="dense" disabled={false} />
                    </div>
                  </div>
                </div>
              </div>}
            </div>
          </div>
          <div className="col-12 col-md-12 col-sm-12 mb-0">
            <TextareaAutosize
              value={justificationText} onChange={(e: any) => handleJustificationChange(e)}
              aria-label="minimum height" minRows={5} placeholder="Justification"
              className="mb-0 sborderTextPart textAreaJsutificationSpecialPrice p-4"
            />
            {justificationTextError &&
              <span className="col-12" style={{ color: 'red' }}>Please enter justification message.</span>
            }
            {justificationTextErrorMessage &&
              <span className="col-12" style={{ color: 'red' }}>Only 1000 characters are allowed.</span>
            }
          </div>
          <div className="col-12 m-0 mt-2 px-1 p-0">
            <div className="row px-3 mb-3">
              <div className="col-md-3 col-lg-3 pr-2 mb-4 px-4 pl-0 ">
                <h6 className="document-content pt-3 pb-2">Upload Proof/Reference <span style={{ color: 'grey' }}></span></h6>
                <small className="dealer-table-content">Photos(.jpg, .jpeg, .png, .pdf  and maximum 10 files upto 10 MB can be uploaded)</small>
              </div>
              <div className="col-md-2 col-lg-2 mb-2 pl-0 mt-4">
                <Button variant="contained"
                  component="label" color="secondary"
                  className="rounded-button-dealer pl-4 pr-4 mt-0">
                  Browse File
                  <input type="file" hidden multiple
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFileasync("proofAndReference", e.target.files[0]) : ""
                    }
                  />
                </Button>
                {/* {(!uploadProofAndReference || uploadProofAndReference.length == 0) && setSave ?
                                    <div className="row col-12" style={{ color: 'red' }}>Please upload atleast one proof / reference</div> : ""} */}
                {invalidUploadSpecialPriceDoc &&
                  <span className="row col-12" style={{ color: 'red' }}>Please upload atleast one proof / reference.</span>
                }
              </div>
              <div className="col-md-7 col-lg-7 mb-2 pl-0 mt-2">
                {uploadProofAndReference && uploadProofAndReference.length > 0 && uploadProofAndReference.map((file: any, index: any) => (
                  <div className="file-name-kam">
                    <span className="cursor-pointer"
                      onClick={() => {
                        window.open(file.documentId.url)
                      }}
                    >{file.documentId && file.documentId.selectedFileName ? file.documentId.selectedFileName : file.documentId && file.documentId.fileName}</span>
                    {file.documentId && file.documentId.selectedFileName ? (
                      <IconButton className="p-1" aria-label="delete"
                        onClick={(e: any) => { deleteDocumentDialog(); setCurrentIndex(index) }}>
                        <CancelIcon className="file-close-color" fontSize="small" />
                      </IconButton>
                    ) : ''}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="row px-5 d-flex align-items-center page-sub-title brands-heading-text-purchase-history mt-3">Disclaimer - <p className='row disclaimer-heading-font px-3'> Raising a Special price request does not reserve the stock and the stock quantity may deplete post approval.</p></div>
        <div className="col-12 row d-flex mt-4 p-0">
          <div className="col-6 d-flex mt-4 flex-row">
            <div className="d-flex flex-column mt-4 ml-5">
            </div>
          </div>
        </div>
        <div className="col-12 row d-flex mt-4 p-0">
          <div className="col-6 d-flex mt-4 flex-row">
            <div className="d-flex flex-column mt-4 ml-5">
            </div>
          </div>
        </div>
        <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
          <div className="col-12 d-flex mt-2 p-0">
            <div className="col-6 d-flex flex-row">
              <div className="d-flex flex-column ml-4">
                <Button variant="contained"
                  onClick={async () => {
                    setIsSubmited(false);
                    validateJustificationMessageSaveForm();
                    setTimeout(() => {
                      setLoading(false);
                    }, 4000)
                  }}
                  color="primary" className="request-button-styling rounded-pill ml-2 mr-2 mb-2" >Save</Button>
              </div>
            </div>
            <div className="col-6 onboard-footer">
              <div className="">
                <Button variant="contained"
                  onClick={async () => {
                    setIsSubmited(true);
                    validateJustificationMessageRequest();
                  }}
                  color="primary" className="request-button-styling rounded-pill ml-2 mr-2 mb-2" >Request</Button>

                <Button variant="contained" className="closebutton-styling rounded-pill mb-2 mr-2 ml-2"
                  onClick={async () => {
                    closeButtonResetData()
                  }}>Close
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Prompt when={dataUpdatedPrompt} message={"Do you wish to proceed without saving the data?"}></Prompt>
        <RequestApproveDialog
          open={showApproveDialog}
          requestButtonAPI={requestButtonAPI}
          setOpenSnackbar={setOpenSnackbar}
          setSuccessMessage={setSuccessMessage}
          handleopenCloseSnackbar={handleopenCloseSnackbar}
          closeButtonResetData={closeButtonResetData}
          setJustificationText={setJustificationText}
          showText={docData && docData.length > 0 ? false : true}
          handleopenClose={(data: any) => {
            setshowApproveDialog(data ? true : false)
          }}
        />
        <DeleteDialog
          open={openDeleteDialog}
          text={deleteMessage}
          handleopenClose={(data: any) => {
            if (data === 'delete') {
              removeDocument("uploadSpecialPriceDocument");
            }
            else if (data === 'back') {
              history.push('/createenquiry');
              setJustificationText("");
            }
            if (!data) handleopenClose(false);
          }} />
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
      </div>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        handleopenClose={(data: any) => {
          if (data === 'delete') { history.push('/orderhistory'); setDataUpdated(false); handleopenClose(false); }
          if (!data) { handleopenClose(false); }
        }} />
    </div >
  )
}

export default RequestSpecialPrice;