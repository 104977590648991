/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Button, Dialog, MenuItem, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { onboardingService } from '../../services/onboard.service';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import _, { get } from "lodash";
import { useEffect } from "react";
import { fowardToCustomerKam, viewSpecialPriceKamAPI, requestCorrectionToKam } from "../../store/actions/specialPriceKam.actions";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    kamText: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    },
    textFontColor: {
        color: 'red',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 900,
        textTransform: 'inherit'
    }
}));

export default function SentToCustomerDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = React.useState<boolean>(false);
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
    const [isValid, setIsValid] = React.useState<boolean>(true);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const { openRequestSnackbar, handleopenCloseSnackbar } = props;
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [kamText, setkamText] = React.useState<any>("");
    const [kamTextErrorMessage, setkamTextErrorMessage] = React.useState<any>("");
    const [kamTextError, setkamTextError] = React.useState<boolean>(false);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const sapCustomerId = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
    const { viewSpecialPrice } = useSelector((state: any) => state.specialPrice)
    const [responseIDData, setResponseIDData] = React.useState<any>();
    const [sapId, setSapId] = React.useState<any>();
    const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
    const userName = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.firstName + " " + profileDetails.data.data.basicInfo.lastName) || "";
    const { viewSpecialPriceKam } = useSelector((state: any) => state.specialPriceKam)
    const displayName = _.get(profileDetails, "data.data.basicInfo.displayName", "");
    const [B2bEnquiryId, setB2bEnquiryId] = React.useState<any>();
    const [sapCustomerIdData, setSapCustomerIdData] = React.useState<any>();

    useEffect(() => {
        let responseID: any
        let sapIDData: any
        let B2bEnquiryIdData: any

        viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data &&
            viewSpecialPriceKam.data.data.data && (
                <>
                    {responseID = viewSpecialPriceKam?.data?.data?.data?.id}
                    {B2bEnquiryIdData = viewSpecialPriceKam?.data?.data?.data?.B2bEnquiryId}
                    {sapIDData = viewSpecialPriceKam?.data?.data?.data?.sapCustomerId}
                </>
            )
        setResponseIDData(responseID)
        setB2bEnquiryId(B2bEnquiryIdData)
        setSapCustomerIdData(sapIDData)
    }, [])

    // ONCHANGE OF TEXTAREA-AUTOSIZE 
    const handleCustomerChange = (e: any) => {
        setkamText(e.target.value);
        if (e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "") {
                setkamTextError(true);
            }
            else {
                setkamTextError(false);
            }
        }
    }

    // VALIDATION FOR TEXTAREA OF NOTE MESSAGE 
    function validateNoteMessage() {
        let isValid: boolean = true;
        if (!kamText || kamText.trim() == "") {
            setkamTextError(true);
            isValid = false;
            return
        } else {
            if (kamText.split('').length > 500) {
                setkamTextError(false);
                setkamTextErrorMessage(true);
                isValid = false;
                return
            }
            else {
                setkamTextError(false);
                setkamTextErrorMessage(false);
            }
        }
        if (isValid) {
            forwardKamAPIDetails();
        }
    }

    // FOR POST API CALL FOR FORWARD TO KAM
    const forwardKamAPIDetails = async () => {
        setLoading(true)
        const data = {
                "sapCustomerId": sapCustomerIdData,
                "requestId": responseIDData,
                "requestForwardBy": sessionStorage.getItem('webApplicationLoginId'),
                "noteOnRequestApproval": kamText.replace(/(\r\n|\r|\n)/g, '<br />')
        }
        let savedData: any = await dispatch(fowardToCustomerKam(data));
        if (savedData && savedData.message && savedData.message.status === 'S') {
            props.handleopenCloseErrorSnackbar(false);
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage(savedData && savedData.message && savedData.message.message);
            setkamText(null);
            setLoading(false);
            setTimeout(() => {
                props.handleopenClose(false);
                history.push('/specialpricekam');
                setkamTextErrorMessage(false);
                setkamTextError(false);
                setkamText(null);
            }, 2000)
        } else if (savedData && savedData.message && savedData.message.status === "E") {
            props.handleopenCloseErrorSnackbar(true);
            props.showErrorMessage(savedData && savedData.error);
            setkamTextErrorMessage(false);
            setkamTextError(false);
            setkamText(null);
            setLoading(false)
            setTimeout(() => {
                props.handleopenClose(false);
            }, 2000)
        }
    }

    return (
        <Fragment>
            {loading && <FullscreenLoader />}
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false); props.setOpenErrorSnackbar(false); props.setSuccessMessage(''); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                {/* <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    type={props.openErrorSnackbar ? 'error' : ''}
                    handleopenClose={() => {  }} 
                    // message={props.successMessage}
                /> */}
                <DialogContent>
                    <div className={classes.root}>
                        <div className="col-12 mt-1 px-0 pr-0">
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title">
                                    <IconButton aria-label="close" className="float-right pr-3 mt-2" onClick={() => { props.handleopenClose(false); setkamTextErrorMessage(false); setkamTextError(false); props.handleopenCloseSnackbar(false); setkamText(null); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        </div>
                        <DialogContent>
                            <div className={classes.icon}>
                                <CheckCircleIcon className="forward-to-kam-right-arrow" fontSize="small" />
                            </div>
                            <div className='mt-3'>
                                <DialogContentText id="alert-dialog-description">
                                    <div className={`${classes.textFontColor}`}>
                                    </div>
                                    <div className={`mt-0${classes.kamText}`}>
                                        <h6 className="mb-0"> Are you sure you want to approve ?  </h6>
                                    </div>
                                </DialogContentText>
                            </div>
                        </DialogContent>
                    </div>
                    <div className="row col-md-12 mb-1 mt-0 px-5">
                        <div className="col-sm-12 col-md-12 px-5 pr-2 mb-0 mt-0">
                            <div className="col-6">
                                <TextareaAutosize id="kamText" value={kamText} onChange={(e: any) => handleCustomerChange(e)}
                                    aria-label="minimum height" minRows={3}
                                    placeholder="Notes.." className="textArea-correction p-4"
                                />
                            </div>
                            {kamTextError &&
                                <span className="col-12" style={{ color: 'red' }}>Please enter notes.</span>
                            }
                            {kamTextErrorMessage &&
                                <span className="col-12" style={{ color: 'red' }}>Only 500 characters are allowed.</span>
                            }
                        </div>
                    </div>
                    <div className="col-md-12 mt-2 px-5">
                        <div className="d-flex align-items-center">
                            <div className="col-md-">
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <div className="col-12 mt-2 mb-2">
                    <div className="d-flex align-items-center">
                        <div className="row-space-start ml-auto">
                            <DialogActions className="mt-3 mb-1 justify-content-end">
                                <Button variant="contained"
                                    color="secondary" className="check-availability-button-access-tab"
                                    onClick={() => {
                                        validateNoteMessage()
                                    }}
                                >Approve
                                </Button>
                                <Button variant="contained" className={classes.closeButton}
                                    onClick={() => { props.handleopenClose(false); setkamTextErrorMessage(false); setkamTextError(false); setkamText(null); }}
                                >Cancel
                                </Button>
                            </DialogActions>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
}
