/* eslint-disable  */
import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import TablePagination from '@material-ui/core/TablePagination';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { ButtonGroup, } from "@material-ui/core";
import { createTheme, } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import "./SpecialPriceRequest.css"
import DatePicker from '../../common/components/dateRangePicker';
import { specialprice } from "../../services/specialPrice.servise";
import { getSpecialPrice, getSpecialPriceStatus, storeOrderNumberPT, viewSpecialPriceAPI } from "../../store/actions/specialprice.actions";
import moment from "moment";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import ReopnedStatusDialog from "./RequestStatusDialog";
import ViewPriceRequest from "./ViewPriceRequest";
import defaultLogo from '../../assets/default-user-logo.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: '#1A9FE0',
  },

}))

const SpecialPrcieRequest: React.FC = () => {
  const [page, setPage] = React.useState(0);
  const dispatch: Dispatch<any> = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [search, setSearch] = React.useState("")
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [status, setStatus] = React.useState("All")
  const [openSnackbar, setOpenSnackBar] = React.useState<any>(false);
  const [openErrorSnackbar, setErrorSnackBar] = React.useState<any>(false);
  const [successMessage, setSuccessMessage] = React.useState<any>("");
  const [errorMessage, setErrorMessage] = React.useState<any>("");
  const { specialPrice, specialPriceStatus, storeReopenedDetails } = useSelector((state: any) => state.specialPrice)
  const [showReopenDialog, setShowReopenDialog] = React.useState<boolean>(false);
  const [responseIDData, setResponseIDData] = React.useState<any>();
  const [sapId, setSapId] = React.useState<any>();

  React.useEffect(() => {
    dispatch(getSpecialPriceStatus())
  }, [])

  useEffect(() => {
    let responseID: any
    let responseSapId: any
    specialPrice && specialPrice.data && specialPrice.data.data && specialPrice.data.data.records && specialPrice.data.data.records.map((item: any) =>
      <>
        {responseID = item.id}
        {responseSapId = item.sapCustomerId}
      </>
    )
  }, [])

  React.useEffect(() => {
    if (status && startDate && endDate && search) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (startDate && endDate && status) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (startDate && endDate && status && search) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (search && status) {
      dispatch(getSpecialPrice(status, false, "", "", search, page))
    }
    else if (status && startDate == null && endDate === null) {
      dispatch(getSpecialPrice(status, false, "", "", "", page))
    } else if (status && startDate == "" && endDate === "") {
      dispatch(getSpecialPrice(status, false, "", "", "", page))
    }
  }, [startDate, endDate, status, search, page])

  React.useEffect(() => {
    setPage(0)
    if (status && startDate && endDate && search) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (startDate && endDate && status) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (startDate && endDate && status && search) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (search && status) {
      dispatch(getSpecialPrice(status, false, "", "", search, page))
    }
    else if (status && startDate == null && endDate === null) {
      dispatch(getSpecialPrice(status, false, "", "", "", page))
    } else if (status && startDate == "" && endDate === "") {
      dispatch(getSpecialPrice(status, false, "", "", "", page))
    }
  }, [status])

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  function handleopenCloseSnackbar(value: any) {
    setOpenSnackBar(false)
  }

  function handleopenCloseErrorSnackbar(value: any) {
    setErrorSnackBar(false)
  }


  const handelRequest = () => {
    if (status && startDate && endDate && search) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (startDate && endDate && status) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (startDate && endDate && status && search) {
      dispatch(getSpecialPrice(status, true, startDate, endDate, search, page))
    } else if (search && status) {
      dispatch(getSpecialPrice(status, false, "", "", search, page))
    }
    else if (status && startDate == null && endDate === null) {
      dispatch(getSpecialPrice(status, false, "", "", "", page))
    }
    else if (status && startDate == "" && endDate === "") {
      dispatch(getSpecialPrice(status, false, "", "", "", page))
    }
  }
  const id = open ? "simple-popover" : undefined

  const handeleViewSpecialPrice = async (id: any, name: "pricing_team") => {
    let data: any = await dispatch(viewSpecialPriceAPI(id, name))

    if (data && data.message && data.message.status === "E") {
      setErrorSnackBar(true)
      setErrorMessage(data && data.message && data.message.message)

    } else {
      history.push('/viewspecialprice');
    }
  }

  const assignedFSE = (item: Array<any> = []) => {

    let fses: any = []
    item && item.length > 0 && item.forEach((ele: any) => {
      fses.push(ele.fseName)
    })
    return fses.join(",")
  }

  const getBrand = (item: Array<any> = []) => {

    let fses: any = []
    item && item.length > 0 && item.forEach((ele: any) => {
      fses.push(ele.make)
    })

    let data: any = {
      "Toyota": "TY",
      "Honda": "HN",
      "Famco": "FM",
      "TE": "2380",
      "OES": "OES"
    }
    return data[fses.join("")]
  }

  const ViewDetailsData = () => {
    specialPrice && specialPrice.data && specialPrice.data.data && specialPrice.data.data && specialPrice.data.data.records.map((item: any) =>
      <>
        {dispatch(storeOrderNumberPT(item.orderNumber))}
        {setResponseIDData(item.id)}
        {setSapId(item.sapCustomerId)}
        {handeleViewSpecialPrice(item.id, "pricing_team")}
      </>
    )
  }

  return (
    <Fragment>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      {specialPriceStatus && specialPriceStatus.loading && <FullscreenLoader />}
      {specialPrice && specialPrice.loading && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Special Price Request</h2>
        <div className="ml-auto">
          <NotificationIcon />
        </div>
      </div>
      <div className="d-flex align-items-center">
        <p className="">
          List of all request by Customers</p>
        <div className="ml-auto d-flex flex-row">
          <div className="pl-2 pr-2 calendar-dates" style={{}}>
            <DatePicker
              className="padding-date pl-2 pr-2 calendar-dates "
              startDate={startDate}
              endDate={endDate}
              setstartDate={setStartDate}
              setendDate={setEndDate}
              onChange={(start: any, end: any) => {
                setStartDate(start);
                setEndDate(end);

              }}
              disabled={true}
            />
          </div>
          <FormControl variant="outlined" className="">
            <InputLabel htmlFor="dealer-search" className="pl-2 pr-2" margin="dense">Search by enquiry no or Customer name</InputLabel>
            <OutlinedInput
              id="dealer-search"
              value={search}
              onKeyPress={(ev) => {
              }}
              onChange={(e: any) => {
                setSearch(e.target.value)
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Search by enquiry no or Customer Name."
                    edge="start"
                    onClick={(e: any) => {
                      setPage(0);
                      handelRequest()
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
              labelWidth={255} margin="dense" className="rounded-input-specialPrice bd-highlight mw-250p mr-2 mb-2 inputWidthSpecialPrice" />
          </FormControl>
          <TextField
            id="kamList"
            select
            label="Filter By Status"
            variant="outlined"
            className="rounded-input-dealer bd-highlight mw-120p mr-0 mb-2"
            size="small"
            onChange={(e: any) => { setStatus(e.target.value); }}
            value={status}
          >
            <MenuItem key={1} value={"All"}>
              {"All"}
            </MenuItem>
            {specialPriceStatus && specialPriceStatus.data && specialPriceStatus.data.data && specialPriceStatus.data.data.map((item: any, index: any) => (
              <MenuItem key={index + 1} value={item.title}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>

        </div>
      </div>

      <Grid item xs={12} className="mt-2 cardCommon">
        <TableContainer component={Paper} className="height-500">
          {<Table aria-label="dealer table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="dealer-table-title">Customer</div>
                  <div className="dealer-table-title-desc">Logo, Name & SAP Customer Id</div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title">Contact</div>
                  <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title">Assigned</div>
                  <div className="dealer-table-title-desc">Pricing Team Executive</div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title">Assgined</div>
                  <div className="dealer-table-title-desc">KAM</div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title mb-3">Enquiry No</div>
                  <div className="dealer-table-title-desc"></div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title mb-3">Order No</div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title mb-3">RequestedDate</div>
                </TableCell>
                <TableCell>
                  <div className="dealer-table-title mb-3">Status</div>
                </TableCell>
                <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>              
              {specialPrice && specialPrice.data && specialPrice.data.data && specialPrice.data.data && specialPrice.data.data.records.map((item: any) =>
                <TableRow key={1}>
                  <TableCell component="th" scope="row" className="mw-200p">
                        <Grid container>
                          <div className="d-flex">
                            {/* <Grid item xs={3}> */}
                            <div style={{ display: "inline" }}>
                              <img
                                src={item.logo ? item.logo : defaultLogo}
                                alt="Logo Client"
                                className="dealer-grid-logo"
                              /></div>

                            <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                              <div className="dealer-table-content dealer-company-name"
                                onClick={async () => {
                                }}>{item.companyTitle} {item.companyName}</div>
                              <div className="dealer-table-subcontent"> {item.sapCustomerId}</div>
                            </div>
                          </div>
                        </Grid>
                      </TableCell>
                  <TableCell className="mw-150p">
                    <div className="dealer-table-content">{item.telephones
                    } </div>
                    <div className="dealer-table-subcontent">{item.emails}</div>
                  </TableCell>
                  <TableCell className="mw-150p">
                    <div className="dealer-table-content">{item.assignedPricingExecutiveName?item.assignedPricingExecutiveName:"NA"
                    }</div>
                    <div className="dealer-table-subcontent">{1 ? "" : "-"}</div>
                  </TableCell>
                  <TableCell className="mw-150p">
                    <div className="dealer-table-content">{item.assignKamName}</div>
                    <div className="dealer-table-subcontent">{1 ? "" : "-"}</div>
                  </TableCell>
                  <TableCell className="mw-150p">
                    <div className="dealer-table-content">{item.B2bEnquiryId
                    }</div>
                  </TableCell>
                  <TableCell className="mw-100p">
                    <div className="dealer-table-content">{item.orderNumber && item.orderNumber.length > 0 ? item.orderNumber.map((o: any, i: any) => (<span>{o.orderNumber}{i === (item.orderNumber.length - 1) ? '' : ', '}</span>)) : 'Not placed'
                    }</div>
                  </TableCell>
                  <TableCell className="mw-220p">
                    <div className="dealer-table-content">{moment(item.requestedDate
                    ).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')} </div>
                  </TableCell>
                  <TableCell>
                    <div className={item.status ===
                      "New Request" ? `dealer-table-content` : item.status === "KAM Review" ? "yellow" : item.status === "Approved" ? "green" : "red"}>{item.status} </div>
                  </TableCell>
                  <TableCell className="mw-220p">
                    <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                      <Button className="text-info btn-link" startIcon={<AttachMoneyIcon />}
                        onClick={() => {
                          if (item.status === "Timed Out") {
                            dispatch(storeOrderNumberPT(item.orderNumber));
                            setResponseIDData(item.id)
                            setSapId(item.sapCustomerId)
                            handeleViewSpecialPrice(item.id, "pricing_team")
                            // statusReopenedChecked();
                          } else {
                            dispatch(storeOrderNumberPT(item.orderNumber));
                            if (item.status !== "Approved" || item.status !== "Rejected") {
                              handeleViewSpecialPrice(item.id, "pricing_team")
                            }
                          }
                        }}>{item.status === "Approved" || item.status === "Rejected" ? "View Details" : item.status === "Timed Out" ? "View Details" : "View Price Request"}</Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>)}
            </TableBody>
          </Table>}
          {specialPrice && specialPrice.data && specialPrice.data.data && specialPrice.data.data && specialPrice.data.data.records.length == 0 && (
            <div className="my-5 mx-auto text-center">
              No records found.
            </div>
          )}
          {<div className="col-12 d-flex justify-content-end">
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={specialPrice && specialPrice.data && specialPrice.data.data && specialPrice.data.data.counts ? specialPrice && specialPrice.data && specialPrice.data.data && specialPrice.data.data.counts : 0}
              rowsPerPage={10}
              page={page}
              onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
            />
          </div>}

        </TableContainer>
      </Grid>

      <Dialog className="pb-0"
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
      </Dialog>
      <ReopnedStatusDialog
        open={showReopenDialog}
        sapId={sapId}
        ViewDetailsData={ViewDetailsData}
        responseIDData={responseIDData}
        // statusReopenedChecked={statusReopenedChecked}
        openRequestSnackbar={openSnackbar}
        setErrorMessage={setErrorMessage}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
        setSuccessMessage={setSuccessMessage}
        handleopenClose={(data: any) => {
          setShowReopenDialog(data ? true : false)
        }}
      />
    </Fragment>
  );
};

export default SpecialPrcieRequest;
