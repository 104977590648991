/* eslint-disable */
import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import XLSX from 'xlsx';
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import moment from "moment-timezone";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { TestRow } from '../NewDealerRequestsDetails/OnboardComponentStyling';
import { OnboardingAuthorisedSignatoriesValidation } from '../../constants/messages';
import { deleteSignatoryDocument, resetValues, updateDocument, getNationality } from '../../store/actions/editDealer.actions';
import { get } from 'lodash';
import DeleteDialog from '../../common/components/deleteDialog';
import { MenuItem } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  addUpdateDailogTitle: {
    position: "absolute",
    top: 10,
    left: 10
  },
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export default function AddNewAuthorisedSignatoriesDailog(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const [Data, setData] = React.useState<any>([]);
  const [firstName, setFirstName] = React.useState<string>("");
  const [adminFirstNameError, setAdminFirstNameError] = React.useState<boolean>(false);
  const [invalidAdminFirstName, setInvalidAdminFirstName] = React.useState<boolean>(false);
  const [lastName, setLastName] = React.useState<string>("");
  const [adminLastNameError, setAdminLastNameError] = React.useState<boolean>(false);
  const [invalidAdminLastName, setInvalidAdminLastName] = React.useState<boolean>(false);
  const [email, setEmail] = React.useState<string>("");
  const [adminEmailError, setAdminEmailError] = React.useState<boolean>(false);
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
  const [nationality, setNationality] = React.useState('');
  const [adminNationalityError, setAdminNationalityError] = React.useState<boolean>(false);
  const [invalidAdminNationality, setInvalidAdminNationality] = React.useState<boolean>(false);
  const [mobile, setMobile] = React.useState<string>("");
  const [adminMobileError, setAdminMobileError] = React.useState<boolean>(false);
  const [invalidMobile, setInvalidMobile] = React.useState<boolean>(false);
  const [designation, setDesignation] = React.useState<string>("");
  const [adminDesignationError, setAdminDesignationError] = React.useState<boolean>(false);
  const [invalidAdminDesignation, setInvalidAdminDesignation] = React.useState<boolean>(false);
  const [emiratesIdNo, setEmiratesIdNo] = React.useState<string>("");
  const [adminEmiratesIdNoError, setAdminEmiratesIdNoError] = React.useState<boolean>(false);
  const [invalidAdminEmaritesIdNo, setInvalidAdminEmaritesIdNo] = React.useState<boolean>(false);
  const [cheques, setCheques] = React.useState<boolean>(false);
  const [purchaseOrders, setPurchaseOrders] = React.useState<boolean>(false);
  const [isValidEdit, setIsValidEdit] = React.useState<boolean>(true);
  const [signatureFile, setSignatureFile] = React.useState<any>(null);

  const [passportExpiryDate, setPassportExpiryDate] = React.useState<any>(null);
  const [isValidPassportExpiryDate, setIsValidPassportExpiryDate] = React.useState<any>(true);
  const [passportFile, setPassportFile] = React.useState<any>(null);
  const [passportCopy, setPassportCopy] = React.useState<any>({ documentId: '', expiryDate: '' });

  const [emiratesIdExpiryDate, setEmiratesIdExpiryDate] = React.useState<any>(null);
  const [isValidEmiratesIdExpiryDate, setIsValidEmiratesIdExpiryDate] = React.useState<any>(true);
  const [emiratesIdFile, setEmiratesIdFile] = React.useState<any>(null);
  const [emiratesDcoumentId, setEmiratesDcoumentId] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [visaExpiryDate, setVisaExpiryDate] = React.useState<any>(null);
  const [isValidVisaExpiryDate, setIsValidVisaExpiryDate] = React.useState<any>(true);
  const [visaFile, setVisaFile] = React.useState<any>(null);
  const [visaCopy, setVisaCopy] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [poaExpiryDate, setPoaExpiryDate] = React.useState<any>(null);
  const [isValidPoaExpiryDate, setIsValidPoaExpiryDate] = React.useState<any>(true);
  const [poaFile, setPoaFile] = React.useState<any>(null);
  const [poaCopy, setPoaCopy] = React.useState<any>({ documentId: '', expiryDate: '' });
  const [signatureCopy, setSignatureCopy] = React.useState<any>({ documentId: '' });
  const [deletedType, setDeletedType] = React.useState();
  const { setOpenDialog, getNewAuthorisedSignatories, isEdit, editData, updateNewAuthorisedSignatories, isView } = props;
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const { getSignatoriesData, deleteSignatoryDocumentData, saveSignatoriesData, editSignatoriesData, deleteSignatoriesData, dealerDetails, getNationalityData }: any = useSelector((state: any) => state.onboard);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [customer, setCustomer] = React.useState("")
  const [isBackKey, setIsBackKey] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const userType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType
  // const blockIntent = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent;
  const sapCustomerIdAdmin = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId ? profileDetails.data.data.basicInfo.sapCustomerId : '';
  const { getDocumentExpiryStatusData }: any = useSelector((state: any) => state.profileDetails);
  const [blockIntent, setBlockedIntent] = React.useState<any>(false);
  const [reUploadedDocument, setReUploadedDocument] = React.useState<any>(false);

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  function populateSignatoriesDetails() {
    if (getSignatoriesData && getSignatoriesData.data) {
      let selectedSignatories = getSignatoriesData.data;
      setFirstName(selectedSignatories.firstName);
      setLastName(selectedSignatories.lastName);
      setNationality(selectedSignatories.nationality);
      setEmail(selectedSignatories.email);
      setMobile(selectedSignatories.mobile);
      setDesignation(selectedSignatories.designation);
      setEmiratesIdNo(selectedSignatories.emiratesIdNumber);
      setCheques(selectedSignatories.authorizedForCheque);
      setPurchaseOrders(selectedSignatories.authorizedForPurchaseOffice);
      if (selectedSignatories.signature && selectedSignatories.signature.documentId) {
        const { id } = selectedSignatories.signature.documentId;
        setSignatureFile(selectedSignatories.signature.documentId)
        setSignatureCopy({ documentId: id });
      }
      if (selectedSignatories && selectedSignatories.passportCopy && selectedSignatories.passportCopy.documentId) {
        const { expiryDate, id } = selectedSignatories.passportCopy.documentId;
        const documentStatus  = selectedSignatories.passportCopy.documentStatus;
        setPassportExpiryDate(expiryDate);
        setPassportCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
        setPassportFile(selectedSignatories.passportCopy.documentId);
      }
      if (selectedSignatories && selectedSignatories.emiratesDcoumentId && selectedSignatories.emiratesDcoumentId) {
        const { expiryDate, id } = selectedSignatories.emiratesDcoumentId;
        const documentStatus  = selectedSignatories.documentStatus;
        setEmiratesIdExpiryDate(expiryDate);
        setEmiratesDcoumentId({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
        setEmiratesIdFile(selectedSignatories.emiratesDcoumentId);
      }
      if (selectedSignatories && selectedSignatories.visaCopy && selectedSignatories.visaCopy.documentId) {
        const { expiryDate, id } = selectedSignatories.visaCopy.documentId;
        const documentStatus  = selectedSignatories.visaCopy.documentStatus;
        setVisaExpiryDate(expiryDate)
        setVisaCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
        setVisaFile(selectedSignatories.visaCopy.documentId);
      }
      if (selectedSignatories && selectedSignatories.powerOfAttorney && selectedSignatories.powerOfAttorney.documentId) {
        const { expiryDate, id } = selectedSignatories.powerOfAttorney.documentId;
        const documentStatus  = selectedSignatories.powerOfAttorney.documentStatus;
        setPoaExpiryDate(expiryDate)
        setPoaCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
        setPoaFile(selectedSignatories.powerOfAttorney.documentId);
      }
    }
  }

  const getPrevDocId = (type: any, index?: any) => {
    if (getSignatoriesData && getSignatoriesData.data) {
      let selectedSignatories = getSignatoriesData.data;
      if (type === 'signature') {
        if (selectedSignatories.signature && selectedSignatories.signature.documentId) {
          const { id } = selectedSignatories.signature.documentId;
          return id;
        }
      } else if (type === 'passportCopy') {
        if (selectedSignatories && selectedSignatories.passportCopy && selectedSignatories.passportCopy.documentId) {
          const { id } = selectedSignatories.passportCopy.documentId;
          return id;
        }
      } else if (type === 'emiratesDcoumentId') {
        if (selectedSignatories && selectedSignatories.emiratesDcoumentId && selectedSignatories.emiratesDcoumentId) {
          const { id } = selectedSignatories.emiratesDcoumentId;
          return id;
        }
      } else if (type === 'visaCopy') {
        if (selectedSignatories && selectedSignatories.visaCopy && selectedSignatories.visaCopy.documentId) {
          const { id } = selectedSignatories.visaCopy.documentId;
          return id;
        }
      } else if (type === 'powerOfAttorney') {
        if (selectedSignatories && selectedSignatories.powerOfAttorney && selectedSignatories.powerOfAttorney.documentId) {
          const { id } = selectedSignatories.powerOfAttorney.documentId;
          return id;
        }
      }
    }
  }

  function isDisabled() {
    if (isView) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType) setCustomer(dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType)
  }, [dealerDetails])

  useEffect(() => {
    if (nationality) {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Country === nationality) {
          setNationality(r);
        }
      })
    }
    if (nationality === 'AE') setVisaCopy({ documentId: '', expiryDate: '' })
  }, [nationality, getNationalityData])

  useEffect(() => {
    if (getDocumentExpiryStatusData && getDocumentExpiryStatusData.data && getDocumentExpiryStatusData.data.data && (getDocumentExpiryStatusData.data.data.messages.length === 0 || getDocumentExpiryStatusData.data.data.messages.length > 0)) {
      setBlockedIntent(true);
    } else {
      setBlockedIntent(false);
    }
    // !isEmpty(getDocumentExpiryStatusData) && getDocumentExpiryStatusData && getDocumentExpiryStatusData.data && getDocumentExpiryStatusData.data.data && getDocumentExpiryStatusData.data.data.messages.length > 0 && getDocumentExpiryStatusData.data.data.messages.map((ele: any) => {
    //   setBlockedIntent(true);
    // })
  }, [getDocumentExpiryStatusData]);

  useEffect(() => {
    if (deleteSignatoryDocumentData && deleteSignatoryDocumentData.data && deleteSignatoryDocumentData.data.message && deleteSignatoryDocumentData.data.message.status === 'S') {
      if (deletedType === 'passportCopy') {
        setPassportCopy({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'visaCopy') {
        setVisaCopy({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'emiratesDcoumentId') {
        setEmiratesDcoumentId({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'poaCopy') {
        setPoaCopy({ documentId: '', expiryDate: '' });
      }
      if (deletedType === 'signature') {
        setSignatureCopy({ documentId: '' });
      }
    }
  }, [deleteSignatoryDocumentData])

  useEffect(() => {
    if (getSignatoriesData && getSignatoriesData.data) {
      populateSignatoriesDetails();
      if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
      } else {
        dispatch(getNationality());
      }
    }
  }, [getSignatoriesData]); // eslint-disable-line

  useEffect(() => {
    if ((saveSignatoriesData && saveSignatoriesData.data && saveSignatoriesData.data.data || editSignatoriesData && editSignatoriesData.data && editSignatoriesData.data.data) || (deleteSignatoriesData && deleteSignatoriesData.data && deleteSignatoriesData.data.data)) {
      resetData();
    }
  }, [saveSignatoriesData, editSignatoriesData, deleteSignatoriesData])

  const resetData = () => {
    dispatch(resetValues());
    setFirstName("");
    setAdminFirstNameError(false);
    setInvalidAdminFirstName(false);

    setLastName("");
    setAdminLastNameError(false);
    setInvalidAdminLastName(false);

    setNationality("");
    setAdminNationalityError(false);

    setMobile("");
    setAdminMobileError(false);
    setInvalidMobile(false);

    setEmail("");
    setAdminEmailError(false);
    setInvalidEmail(false);

    setDesignation("");
    setAdminDesignationError(false);
    setInvalidAdminDesignation(false);

    setEmiratesIdNo("");
    setAdminEmiratesIdNoError(false);
    setInvalidAdminEmaritesIdNo(false);

    setCheques(false);
    setPurchaseOrders(false);

    setSignatureFile(null);
    setPassportExpiryDate(null);
    setEmiratesIdExpiryDate(null);
    setPassportFile(null);
    setEmiratesIdFile(null);
    setIsValidPassportExpiryDate(null);
    setIsValidEmiratesIdExpiryDate(null);
    setVisaExpiryDate(null);
    setIsValidVisaExpiryDate(null);
    setVisaFile(null);
    setPassportCopy({ documentId: '', expiryDate: '' });
    setEmiratesDcoumentId({ documentId: '', expiryDate: '' });
    setVisaCopy({ documentId: '', expiryDate: '' });
    setPoaExpiryDate(null);
    setIsValidPoaExpiryDate(null);
    setPoaFile(null);
    setPoaCopy({ documentId: '', expiryDate: '' });
    setSignatureCopy({ documentId: '' });
  }

  // const deleteDocument = (type: any, data: any) => {
  //   setDeletedType(type);
  //   const obj = {
  //     "type": type
  //   }
  //   dispatch(deleteSignatoryDocument(getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id, data.id, obj));
  // }

  function isButtonEnabled(selectedDate: any) {
    const currentDate: any = new Date();
    moment(currentDate).utc().format();
    if (new Date(selectedDate).getTime() <= currentDate.getTime()) {
      return false;
    } else {
      const oneDay = 24 * 60 * 60 * 1000;
      const expiryDate: any = new Date(selectedDate);
      const diffDays = Math.round(Math.abs((expiryDate - currentDate) / oneDay));
      if (diffDays <= 15) {
        return false
      } else {
        return true
      }
    }
  }

  const deleteDocument = async (type?: any, dataObj?: any) => {
    let data: any;
    setDeletedType(documentType);
    if (documentType == 'signature') {
      data = signatureFile;
    } else if (documentType == 'passportCopy') {
      data = passportFile;
    } else if (documentType == 'emiratesDcoumentId') {
      data = emiratesIdFile;
    } else if (documentType == 'visaCopy') {
      data = visaFile;
    } else if (documentType == 'powerOfAttorney') {
      data = poaFile;
    }
    data = dataObj ? dataObj : data;
    const obj = {
      "type": type ? type : documentType
    }
    if (userType === "Admin" && blockIntent === true) {
      if (documentType == 'signature') {
        setSignatureFile(null);
      } else if (documentType == 'passportCopy') {
        setPassportFile(null);
        setPassportExpiryDate(null);
      } else if (documentType == 'emiratesDcoumentId') {
        setEmiratesIdFile(null);
        setEmiratesIdExpiryDate(null);
      } else if (documentType == 'visaCopy') {
        setVisaFile(null);
        setVisaExpiryDate(null);
      } else if (documentType == 'powerOfAttorney') {
        setPoaFile(null);
        setPoaExpiryDate(null);
        setPoaCopy({ documentId: '', expiryDate: '' })
      }
      handleopenClose(false);
    } else {
      let deleteDocumentData: any = await dispatch(deleteSignatoryDocument(getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id, data.id, obj, sapCustomerId));
      if (deleteDocumentData && deleteDocumentData.message) {
        if (documentType == 'signature') {
          setSignatureFile(null);
        } else if (documentType == 'passportCopy') {
          setPassportFile(null);
          setPassportExpiryDate(null);
        } else if (documentType == 'emiratesDcoumentId') {
          setEmiratesIdFile(null);
          setEmiratesIdExpiryDate(null);
        } else if (documentType == 'visaCopy') {
          setVisaFile(null);
          setVisaExpiryDate(null);
        } else if (documentType == 'powerOfAttorney') {
          setPoaFile(null);
          setPoaExpiryDate(null);
          setPoaCopy({ documentId: '', expiryDate: '' })
        }
      }
      handleopenClose(false);
    }
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, emiratesId?: any) => {
    if (emiratesId === 'emiratesId') {
      if (!isBackKey) {
        let value = e.target.value;
        value = value.replace(/[^0-9-]/g, '');
        let emiratesNo = '';
        if (value.length < 3) {
          emiratesNo = value;
        } else if (value.length == 3) {
          emiratesNo = value.slice(0, 3) + '-'
        } else if (value.length == 8) {
          emiratesNo = value.slice(0, 8) + '-'
        }
        else if (value.length == 16) {
          emiratesNo = value.slice(0, 16) + '-'
        } else {
          emiratesNo = value;
        }
        setterName(emiratesNo)
      }

    }
    else if (emiratesId === "mobileNo") {
      let value = e.target.value;
      value = value.replace(/[^0-9]/g, '');
      setterName(value)
      if (validateMobile(value) === false) {
        setInvalidMobile(true);
      } else {
        setInvalidMobile(false);
      }
    }
    else {
      setterName(e.target.value);
    }
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Backspace') {
      const value = event.target.value;
      const selectionStart = event.target.selectionStart;
      const selectionEnd = event.target.selectionEnd;
      if (selectionStart === selectionEnd && selectionStart > 0) {
        const beforeSelection = value.slice(0, selectionStart - 1);
        const afterSelection = value.slice(selectionStart);
        if (value.charAt(selectionStart - 1) === '-') {
          const value = beforeSelection + afterSelection;
          setEmiratesIdNo(value);
          setIsBackKey(true)
        } else {
          setIsBackKey(false)
        }
      }
    } else {
      setIsBackKey(false)
    }
  };

  const removeUserData = (e: any, index: any) => {
    const userData: any = [...Data];
    userData.splice(index, 1);
    setData(userData);
  };

  const onChangeOfNationalitySelector = (e: any) => {
    setNationality(e.target.value);
    if (visaFile && visaFile.url) {
      deleteDocument('visaCopy', visaFile);
      setVisaFile(null);
      setVisaExpiryDate(null);
    }
    if (e.target.value === "" || e.target.value.trim() === "") {
      setAdminNationalityError(true);
    }
    else {
      setAdminNationalityError(false);
    }
  }

  function validateFormFields(action: string, value: any) {
    switch (action) {
      // case "nationality": {
      //   const re = /^[a-zA-Z0-9 ]{1,250}$/;
      //   return value.length > 250 || !re.test(value) ? false : true;
      // }
      case "lastName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 || !re.test(value) ? false : true;
      }
      case "designation": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return value.length > 250 || !re.test(value) ? false : true;
      }
      case "firstName": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return !re.test(value) ? false : true;
      }
      default:
        return true

    }
  }

  function validateAddNewAuthorisedSignatoriesDialog() {
    let isValid: boolean = true;
    let fields: boolean = true;
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (firstName.trim() === "") {
        setAdminFirstNameError(true);
        isValid = false;
        fields = false;
      }
      if (firstName.trim() !== "") {
        if (firstName.split('').length > 250) {
          setInvalidAdminFirstName(true);
          isValid = false;
          fields = false;
        }
        else if (!validateFormFields("firstName", firstName)) {
          setInvalidAdminFirstName(true);
          isValid = false;
          fields = false;
        }
        else {
          setInvalidAdminFirstName(false);
        }
      }
      if (lastName.trim() === "") {
        setAdminLastNameError(true);
        isValid = false;
        fields = false;
      }
      if (lastName.trim() !== "") {
        if (lastName.split('').length > 250) {
          setInvalidAdminLastName(true);
          isValid = false;
          fields = false;
        }
        else if (!validateFormFields("lastName", lastName)) {
          setInvalidAdminLastName(true);
          isValid = false;
          fields = false;
        }
        else {
          setInvalidAdminLastName(false);
        }
      }
      // if (nationality.trim() === "") {
      //   setAdminNationalityError(true);
      //   isValid = false;
      // }
      // if (nationality.trim() !== "") {
      //   if (nationality.split('').length > 250) {
      //     setInvalidAdminNationality(true);
      //     isValid = false;
      //   }
      //   else if (!validateFormFields("nationality", nationality)) {
      //     setInvalidAdminNationality(true);
      //     isValid = false;
      //   }
      //   else {
      //     setInvalidAdminNationality(false);
      //   }
      // }
      if (nationality == "" || nationality == undefined) {
        setAdminNationalityError(true);
        isValid = false;
        fields = false;
      }
      else {
        // setInvalidAdminNationality(false);
        setAdminNationalityError(false);
      }
      if (designation.trim() === "") {
        setAdminDesignationError(true);
        isValid = false;
        fields = false;
      }
      if (designation.trim() !== "") {

        if (designation.split('').length > 250) {
          setInvalidAdminDesignation(true);
          isValid = false;
          fields = false;
        }
        else if (!validateFormFields("designation", designation)) {
          setInvalidAdminDesignation(true);
          isValid = false;
          fields = false;
        }
        else {
          setInvalidAdminDesignation(false);
        }
      }
      if (mobile.trim() === "") {
        setAdminMobileError(true);
        isValid = false;
        fields = false;
      }
      if (mobile.trim() !== "") {
        if (!validateMobile(mobile)) {
          isValid = false;
          fields = false;
        }
        if (validateMobile(mobile) === false) {
          setInvalidMobile(true);
        }
        else {
          setInvalidMobile(false);
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer === 'Government') {
        if (emiratesIdNo && validateEmaritesIdNO(emiratesIdNo)) {
          if (emiratesDcoumentId.documentId.trim() === '') {
            isValid = false;
            showErrorMessage("Please fill all mandatory fields and documents.");
            handleopenCloseErrorSnackbar(true);
            handleopenClose(false);
          }
        } else if (emiratesDcoumentId && emiratesDcoumentId.documentId) {
          if (emiratesIdNo.trim() === "") {
            setAdminEmiratesIdNoError(true);
            showErrorMessage("Please fill all mandatory fields and documents.");
            handleopenCloseErrorSnackbar(true)
            isValid = false;
          }
          if (emiratesIdNo.trim() !== "") {
            if (!validateEmaritesIdNO(emiratesIdNo)) {
              isValid = false;
              setInvalidAdminEmaritesIdNo(true);
            }
            else {
              setInvalidAdminEmaritesIdNo(false);
            }
          }
        }
      }
      if (emiratesIdNo.trim() === "" && customer !== "Government") {
        setAdminEmiratesIdNoError(true);
        isValid = false;
        fields = false;
      }
      if (emiratesIdNo.trim() !== "") {
        if (!validateEmaritesIdNO(emiratesIdNo)) {
          isValid = false;
          fields = false;
          setInvalidAdminEmaritesIdNo(true);
        }
        else {
          setInvalidAdminEmaritesIdNo(false);
        }
      }
      if (email.trim() === "") {
        setAdminEmailError(true);
        isValid = false;
        fields = false;
      }
      if (email.trim() !== "") {
        if (!validateEmail(email)) {
          isValid = false;
          fields = false;
        }
        if (validateEmail(email) === false) {
          setInvalidEmail(true);
        }
        else {
          setInvalidEmail(false);
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (signatureCopy.documentId.trim() === '' && customer !== "Government") {
        isValid = false;
        showErrorMessage("Please upload all mandatory documents");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      }
      if (passportCopy.documentId.trim() === '' && customer !== "Government") {
        isValid = false;
        showErrorMessage("Please upload all mandatory documents");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      }
      if (emiratesDcoumentId.documentId.trim() === '' && customer !== "Government") {
        isValid = false;
        showErrorMessage("Please upload all mandatory documents");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      }

      if (nationality !== 'AE') {
        if (visaCopy.documentId.trim() === '' && customer !== "Government" && customer !== "Fleet") {
          isValid = false;
          showErrorMessage("Please upload all mandatory documents");
          handleopenCloseErrorSnackbar(true);
          handleopenClose(false);
        }
      }
      if (poaCopy.documentId.trim() === '' && customer !== "Government" && customer !== "Fleet") {
        isValid = false;
        showErrorMessage("Please upload all mandatory documents");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      }
      if (fields === true && cheques === false && purchaseOrders === false) {
        isValid = false;
        showErrorMessage("Please select Authorized For");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      } else {
        showErrorMessage("Please fill all mandatory fields and documents.");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
      }
    }
    if (isValid) {
      if (getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id) {
        updateAuthorisedSignatories();
      }
      else {
        createNewAuthorisedSignatories();
      }
    }
  }

  function validationAddNewAuthorisedSignatoriesDialog() {
    let isValid: boolean = true;
    let fields: boolean = true;
    if (isValid) {
      if (getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id) {
        updateAuthorisedSignatories();
      }
      else {
        createNewAuthorisedSignatories();
      }
    }
  }

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateMobile(number: string) {
    const re = sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? /^\d{5,30}$/ : /^\d{10}$/;;
    return re.test(number);
  }

  function validateEmaritesIdNO(number: string) {
    const re = /^\d{3}-\d{4}-\d{7}-\d{1}$/;
    return re.test(number);
  }

  const createNewAuthorisedSignatories = () => {
    const data = {
      sapCustomerId: sapCustomerId,
      createdByB2bUserId: b2bUserId,
      firstName: firstName,
      lastName: lastName,
      nationality: nationality,
      email: email,
      mobile: mobile,
      designation: designation,
      emiratesIdNumber: emiratesIdNo,
      authorizedForCheque: cheques,
      authorizedForPurchaseOffice: purchaseOrders,
      signature: signatureCopy,
      passportCopy: passportCopy,
      emiratesDcoumentId: emiratesDcoumentId.documentId,
      emiratesIdexpiryDate: emiratesDcoumentId.expiryDate,
      visaCopy: visaCopy,
      powerOfAttorney: poaCopy,
    }
    // getNewAuthorisedSignatories(data);
    // setOpenDialog();
    if (mobile.trim() !== "") {
      if (!validateMobile(mobile)) {
        showErrorMessage("Mobile no should not exceed 30 digits.");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
        return
      }
      else {
        setInvalidMobile(false);
      }
    }
    if (data.firstName || data.lastName || data.nationality || data.email || data.designation || data.emiratesIdNumber || data.authorizedForCheque || data.authorizedForPurchaseOffice || data.signature.documentId || data.passportCopy.documentId || data.emiratesDcoumentId || data.emiratesIdexpiryDate || data.visaCopy.documentId || data.powerOfAttorney.documentId) {
      getNewAuthorisedSignatories(data);
      setOpenDialog();
    }
    else {
      setOpenDialog();
      resetData();
    }
  }

  const updateAuthorisedSignatories = () => {
    const data = {
      signatoriesId: getSignatoriesData && getSignatoriesData.data && getSignatoriesData.data.id,
      id: userType === "Admin" && blockIntent === true ? sapCustomerIdAdmin : sapCustomerId,
      sapCustomerId: userType === "Admin" && blockIntent === true ? sapCustomerIdAdmin : sapCustomerId,
      createdByB2bUserId: b2bUserId,
      firstName: firstName,
      lastName: lastName,
      revisedDocument: userType === "Admin" && blockIntent === true ? true : false,
      reUpload: reUploadedDocument ? true : false,
      nationality: nationality,
      email: email,
      mobile: mobile,
      designation: designation,
      emiratesIdNumber: emiratesIdNo,
      authorizedForCheque: cheques,
      authorizedForPurchaseOffice: purchaseOrders,
      signature: signatureCopy,
      passportCopy: passportCopy,
      emiratesDcoumentId: emiratesDcoumentId.documentId,
      emiratesIdexpiryDate: emiratesDcoumentId.expiryDate,
      visaCopy: visaCopy,
      powerOfAttorney: poaCopy,
    }
    if (mobile.trim() !== "") {
      if (!validateMobile(mobile)) {
        showErrorMessage("Mobile no should not exceed 30 digits.");
        handleopenCloseErrorSnackbar(true);
        handleopenClose(false);
        return
      }
      else {
        setInvalidMobile(false);
      }
    }
    updateNewAuthorisedSignatories(data);
    setOpenDialog();
  }

  const exportData = () => {
    let filename = 'SpecialOffers.xlsx';
    let data: any = [];
    data.push({
    });
    return undefined;
    var ws = XLSX.utils.json_to_sheet(data);
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `SpecialOffers`);
    XLSX.writeFile(wb, filename);
  }

  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      state(false);
    } else if (e === 'Date should not be before minimal date') {
      state(false);
    } else if (e === 'Date should not be after maximal date') {
      state(false);
    } else {
      state(true);
    }
  }

  const onClickBrowseFile = async (type: any, item: any, setCallback: any, preId?: any) => {
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    let size: any = 2000000;
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      signatureDocExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      emiratesIdFileExtensions = ['jpg', 'jpeg', 'png', 'pdf'],

      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1,
      canUploadSignatureFile = signatureDocExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    const canUploademiratesIdFile = emiratesIdFileExtensions.indexOf(doctExtension.toLowerCase()) !== -1;

    if (type === 'signatureFile' && !canUploadSignatureFile) {
      showErrorMessage('Invalid File type format');
      handleopenCloseErrorSnackbar(true);
      return;
    } else {
      if (!canUploadFile) {
        showErrorMessage('Invalid File type format');
        handleopenCloseErrorSnackbar(true);
        return;
      }
    }
    if (type === 'emiratesIdFile') {
      size = 5000000;
      if (!canUploademiratesIdFile) {
        showErrorMessage('Invalid File type format, please upload jpg, png or pdf');
        handleopenCloseErrorSnackbar(true);
        return;
      } else {
        if (!canUploadFile) {
          showErrorMessage('Invalid File type format');
          handleopenCloseErrorSnackbar(true);
          return;
        }
      }
    }
    if (item && item.size < size) {
      const canNotUploadWithoutDate = type === 'passportFile' || type === 'visaCopyFile' || type === 'emiratesIdFile' || type === 'poaFile';
      const formData: any = new FormData();
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      let expiryDate: any = '';
      if (setCallback) setCallback(item);
      if (canNotUploadWithoutDate) {
        if (type === 'visaCopyFile') expiryDate = moment(visaExpiryDate).utc().format();
        else if (type === 'poaFile') expiryDate = moment(poaExpiryDate).utc().format();
        else if (type === 'passportFile') expiryDate = moment(passportExpiryDate).utc().format();
        else if (type === 'emiratesIdFile') expiryDate = moment(emiratesIdExpiryDate).utc().format();
      }
      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };
      let docType = '';
      if (type === 'visaCopyFile') { docType = 'Valid Visa Copy' }
      else if (type === 'poaFile') { docType = 'Power Of Attorney File' }
      else if (type === 'passportFile') { docType = 'Valid Passport Copy' }
      else if (type === 'emiratesIdFile') { docType = 'Valid Emirates ID Copy' }
      else if (type === 'signatureFile') { docType = 'Signature File' }
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", isEdit && isView === false && userType === "Admin" && blockIntent === true ? sapCustomerIdAdmin : sapCustomerId ? sapCustomerId : '');
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);
      formData.append("previousDocumentId", preId ? preId : '');

      setLoading(true);
      let uploadedDocumentData: any = await dispatch(updateDocument(formData, customHeaders));
      if (uploadedDocumentData && uploadedDocumentData.message && uploadedDocumentData.message.status === 'S') {
        const item = uploadedDocumentData && uploadedDocumentData.data ? uploadedDocumentData.data : {};
        const { documentStatus, expiryDate, id, label } = item;
         if (userType === "Admin" && blockIntent === true 
         && emiratesDcoumentId && emiratesDcoumentId.documentStatus === "Re Upload" || 
         userType === "Admin" && blockIntent === true 
         && passportCopy && passportCopy.documentStatus === "Re Upload" || 
         userType === "Admin" && blockIntent === true 
         && poaCopy && poaCopy.documentStatus === "Re Upload" || 
         userType === "Admin" && blockIntent === true 
         && visaCopy && visaCopy.documentStatus === "Re Upload") {
          setReUploadedDocument(true);
        } else {
          setReUploadedDocument(false);
        }
        if (label === 'passportFile') {
          setPassportExpiryDate(expiryDate);
          setPassportCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  });
          setPassportFile(item);
        } else if (label === 'emiratesIdFile') {
          setEmiratesIdExpiryDate(expiryDate);
          setEmiratesDcoumentId({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
          setEmiratesIdFile(item);
        } else if (label === 'visaCopyFile') {
          setVisaCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
          setVisaExpiryDate(expiryDate);
          setVisaFile(item);
        } else if (label === 'signatureFile') {
          setSignatureCopy({ documentId: id });
          setSignatureFile(item);
        }
        else if (label === 'poaFile') {
          setPoaCopy({ documentId: id, expiryDate: expiryDate, documentStatus: documentStatus ? documentStatus : ""  })
          setPoaExpiryDate(expiryDate);
          setPoaFile(item);
        }
        setLoading(false);
      } else {
        setLoading(false);
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        if (type === 'passportFile') {
          setPassportExpiryDate(null);
          setPassportCopy({ documentId: '', expiryDate: '' });
          setPassportFile(null);
        } else if (type === 'emiratesIdFile') {
          setEmiratesIdExpiryDate(null);
          setEmiratesDcoumentId({ documentId: '', expiryDate: '' })
          setEmiratesIdFile(null);
        } else if (type === 'visaCopyFile') {
          setVisaCopy({ documentId: '', expiryDate: '' })
          setVisaExpiryDate(null);
          setVisaFile(null);
        } else if (type === 'signatureFile') {
          setSignatureCopy({ documentId: '' });
          setSignatureFile(null);
        } else if (type === 'poaFile') {
          setPoaCopy({ documentId: '', expiryDate: '' })
          setPoaExpiryDate(null);
          setPoaFile(null);
        }
      }
    } else {
      showErrorMessage(`Please upload less than ${type === 'emiratesIdFile' ? '5' : '2'}MB file`);
      handleopenCloseErrorSnackbar(true);
    }
  }

  const classes = useStyles();

  return (
    <Fragment>
      <div className={classes.root}>
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} text={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <>
          <Toolbar
            className={clsx(classes.root)}>
            <div className="col-12 mb-2 mt-2">
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                <h4 className="shift-to-party-page-title">
                  <div className={classes.addUpdateDailogTitle}>
                    {isDisabled() ? 'View Authorized Signatories' : (
                      <>
                        {isEdit || props.typeofDailog === 'edit' ? 'Update Authorized Signatories' : props.typeofDailog === 'add' ? 'Add New Authorized Signatories' : ''}
                      </>
                    )}
                  </div>
                  <IconButton aria-label="close" className="float-right" onClick={() => { setOpenDialog(); resetData(); }}>
                    <CloseIcon />
                  </IconButton>
                </h4>
              </Typography>
            </div>
          </Toolbar>
          <Toolbar className="mb-2 mt-2">
            <TextField id="firstName"
              value={firstName}
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminFirstNameError || invalidAdminFirstName}
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              inputProps={{ maxLength: 250 }}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidAdminFirstName &&
                <span style={{ color: 'red' }}> {OnboardingAuthorisedSignatoriesValidation.ADMIN_FIRST_NAME_TEXT}</span>
              }
              onChange={(e: any) => handleInputChange(e, setFirstName, setAdminFirstNameError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="First Name"
              variant="outlined"
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer.toLowerCase() !== "workshop" ? true : false}
            />
            <TextField id="lastName"
              value={lastName}
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminLastNameError || invalidAdminLastName}
              inputProps={{ maxLength: 250 }}
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidAdminLastName &&
                <span style={{ color: 'red' }}>{OnboardingAuthorisedSignatoriesValidation.ADMIN_LAST_NAME_TEXT}</span>
              }
              onChange={(e: any) => handleInputChange(e, setLastName, setAdminLastNameError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Last Name"
              variant="outlined"
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer.toLowerCase() !== "workshop" ? true : false}
            />
          </Toolbar>
          <Toolbar className="mb-2 nationality">
            <TextField select id="nationality"
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminNationalityError}
              // inputProps={{ maxLength: 250 }}
              // helperText={invalidAdminNationality &&
              //   <span style={{ color: 'red' }}> {OnboardingAuthorisedSignatoriesValidation.ADMIN_LAST_NATIONALITY}</span>
              // }
              // onChange={(e: any) => handleInputChange(e, setNationality, setAdminNationalityError)}
              onChange={(e: any) => onChangeOfNationalitySelector(e)}
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              value={nationality}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Nationality"
              variant="outlined"
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer.toLowerCase() !== "workshop" ? true : false}
            >
              {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
            </TextField>
            <TextField id="email" error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminEmailError || invalidEmail}
              value={email}
              helperText={invalidEmail &&
                <span style={{ color: 'red' }}>Please enter a valid email address.</span>
              }
              onChange={(e: any) => handleInputChange(e, setEmail, setAdminEmailError)}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              label="Email"
              variant="outlined"
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer.toLowerCase() !== "workshop" ? true : false}
            />
          </Toolbar>
          <Toolbar className="mb-2">
            <TextField id="mobile" error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminMobileError || invalidMobile}
              placeholder={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? OnboardingAuthorisedSignatoriesValidation.IB_CUSTOMER_MOBILE_TEXT : OnboardingAuthorisedSignatoriesValidation.MOBILE_TEXT}
              value={mobile}
              helperText={invalidMobile && <span style={{ color: 'red' }}>{sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "It can be upto 30 digits." : "It should be 10 digits."}</span>}
              onChange={(e: any) => handleInputChange(e, setMobile, setAdminMobileError, "mobileNo")}
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Mobile"
              variant="outlined"
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' ? true : false}
            />
            <TextField id="designation"
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminDesignationError || invalidAdminDesignation}
              inputProps={{ maxLength: 250 }}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidAdminDesignation &&
                <span style={{ color: 'red' }}>{OnboardingAuthorisedSignatoriesValidation.ADMIN_LAST_DESIGNATION}</span>
              }

              onChange={(e: any) => handleInputChange(e, setDesignation, setAdminDesignationError)}
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              value={designation}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Designation"
              variant="outlined"
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer.toLowerCase() !== "workshop" ? true : false}
            />
          </Toolbar>
          <Toolbar className="mb-2 border-bottom">
            <TextField
              id="emiratesIdNo"
              error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : adminEmiratesIdNoError || invalidAdminEmaritesIdNo}
              placeholder={OnboardingAuthorisedSignatoriesValidation.EMARITESIDNO_TEXT}
              disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
              value={emiratesIdNo}
              onKeyDown={handleKeyDown}
              helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidAdminEmaritesIdNo &&
                <span style={{ color: 'red' }}>{OnboardingAuthorisedSignatoriesValidation.EMARITESIDNO_ERROR_TEXT}</span>
              }
              onChange={(e: any) => handleInputChange(e, setEmiratesIdNo, setAdminEmiratesIdNoError, 'emiratesId')}
              inputProps={{ maxLength: 18 }}
              required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer !== "Government" && customer.toLowerCase() !== "workshop" ? true : false}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Emirates ID No."
              variant="outlined"
            />
            <div className="">
              {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customer.toLowerCase() === "workshop" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? <h6 className="document-content float-left">Authorized For :</h6> : <h6 className="document-content float-left">Authorized For*{' '}:</h6>}
            </div>
            <div className="dealer-table-content float-right px-2">
              <ThemeProvider theme={theme}>
                <Checkbox
                  checked={cheques}
                  onChange={(e) => { setCheques(e.target.checked) }}
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
                  className="checkbox" />
              </ThemeProvider>
              <span className="dealer-table-content">Cheques </span>
            </div>
            <div>
              <ThemeProvider theme={theme}>
                <Checkbox
                  checked={purchaseOrders}
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
                  onChange={(e) => {
                    setPurchaseOrders(e.target.checked)
                  }} className="checkbox" />
              </ThemeProvider>
              <span className="dealer-table-content mr-3">Purchase Orders</span>
            </div>
          </Toolbar>
          <div className="row m-0 p-0 border-bottom">
            <div className="col-12 mt-3 mb-3">
              <h6 className="info-sub-header-document px-3">Supported File Extension : .jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB can be uploaded.</h6>
            </div>
          </div>
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3">
              {sessionStorage.getItem('customerOnboardingType') === 'Workshop' ||
                customer.toLowerCase() === "workshop" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? <h6 className="document-content">Signature{' '}</h6> : <h6 className="document-content">Signature{' '}{customer !== "Government" ? "*" : ""}</h6>}
              <a href="./signatureexample.docx" download="signatureexample.docx">
                <IconButton size="small" className="" onClick={() => exportData()}>
                  <AssignmentReturnedIcon className="download-icon mr-1" />
                  <span className="download-offer mr-2">
                    Download Template
                  </span>
                </IconButton>
              </a>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 pl-0">
              {signatureFile !== null ?
                <span className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(signatureFile.url)
                  }}>{signatureFile.name ? signatureFile.name : signatureFile.selectedFileName}</span>
                  <IconButton
                    disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
                    className="p-1 mt-2" aria-label="delete" onClick={(e) => {
                      // setSignatureFile(null); deleteDocument('signature', signatureFile);
                      setDocumentType('signature');
                      handleopenClose(true);
                    }}>
                    <CancelIcon className={isEdit && isView === false && userType === "Admin" && blockIntent === true ? 'file-close-color disabledCustomclass' : isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained"
                  component="label" color="secondary"
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true ? true : isDisabled()}
                  className="rounded-button-dealer pl-4 pr-4 mt-2">
                  Browse File
                  <input type="file" hidden
                    onClick={(e: any) => {
                      const { target = {} } = e || {}
                      target.value = ""
                      setSignatureFile(null)
                      showErrorMessage('');
                      handleopenCloseErrorSnackbar(false);
                    }}
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('signatureFile', e.target.files[0], setSignatureFile, getPrevDocId('signature') ? getPrevDocId('signature') : '') : setSignatureFile(null)
                    } />
                </Button>
              }
            </div>
          </TestRow>
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3 mb-1 mt-1">
              {customer === "Government" && !validateEmaritesIdNO(emiratesIdNo) || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? <h6 className="document-content mt-1">Valid Emirates ID Copy {' '}</h6> : sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customer.toLowerCase() === "workshop" ? <h6 className="document-content mt-1">Valid Emirates ID Copy {' '}</h6> : <h6 className="document-content mt-1">Valid Emirates ID Copy {' '}*</h6>}
              <small className="document-sub-content pb-3">Owner/Partner</small>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
              {emiratesIdFile !== null ?
                <div className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(emiratesIdFile.url)
                  }}>{emiratesIdFile.name ? emiratesIdFile.name : emiratesIdFile.selectedFileName}</span>
                  <IconButton disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesDcoumentId && emiratesDcoumentId.documentStatus === "Re Upload" ? false : isButtonEnabled(emiratesIdExpiryDate) && userType === "Admin" ? true : isEdit && isView === false && userType === "Admin" && blockIntent === true
                    ? false : isDisabled()} className="p-1 mt-2" aria-label="delete" onClick={(e) => {
                      // setEmiratesIdFile(null);
                      //  setEmiratesIdExpiryDate(null);
                      //  deleteDocument('emiratesDcoumentId', emiratesIdFile);
                      setDocumentType('emiratesDcoumentId');
                      handleopenClose(true);
                      // dispatch(deleteDocument(emiratesIdFile.id, emiratesIdFile.sapCustomerId, true));
                    }}>
                    <CancelIcon className={isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesDcoumentId && emiratesDcoumentId.documentStatus === "Re Upload" ? "file-close-color" : isButtonEnabled(emiratesIdExpiryDate) && userType === "Admin" ? 'file-close-color disabledCustomclass' : isEdit && isView === false && userType === "Admin" && blockIntent === true
                      ? "file-close-color" : isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={emiratesIdExpiryDate && isValidEmiratesIdExpiryDate ? "label" : "button"}
                  color="secondary"
                  className={isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesDcoumentId && emiratesDcoumentId.documentStatus === "Re Upload" ? "rounded-button-dealer pl-4 pr-4 mt-2" : isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesIdExpiryDate === null ? "rounded-button-dealer pl-4 pr-4 mt-2 actionButtomButtomsBlueDisabled" : "rounded-button-dealer pl-4 pr-4 mt-2"}
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesDcoumentId && emiratesDcoumentId.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesIdExpiryDate === null ? false : isDisabled() ? isDisabled() : !emiratesIdExpiryDate || isValidEmiratesIdExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden
                    onClick={(e: any) => {
                      const { target = {} } = e || {}
                      target.value = ""
                      setEmiratesIdFile(null)
                      showErrorMessage('');
                      handleopenCloseErrorSnackbar(false);
                    }}
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('emiratesIdFile', e.target.files[0], setEmiratesIdFile, getPrevDocId('emiratesDcoumentId') ? getPrevDocId('emiratesDcoumentId') : '') : setEmiratesIdFile(null)
                    } />
                </Button>
              }
            </div>
            <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
              <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onError={(e, v) => datePickerValid(e, setIsValidEmiratesIdExpiryDate)}
                disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesDcoumentId && emiratesDcoumentId.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && emiratesIdFile === null ? false : isDisabled() ? isDisabled() : emiratesIdExpiryDate && emiratesIdFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={emiratesIdExpiryDate}
                onChange={(date: Date | null) => { setEmiratesIdExpiryDate(date); !date && setEmiratesIdFile(null) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
              />
            </div>
          </TestRow>
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-2">
              {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customer.toLowerCase() === "workshop" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? <h6 className="document-content">Valid Passport Copy{' '}</h6> : <h6 className="document-content">Valid Passport Copy{' '}{customer !== "Government" ? "*" : ""}</h6>}
              <small className="document-sub-content pb-3">Owner/Partner</small>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
              {passportFile !== null ?
                <div className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(passportFile.url)
                  }}>{passportFile.name ? passportFile.name : passportFile.selectedFileName}</span>

                  <IconButton className="p-1 mt-2" aria-label="delete"
                    disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && passportCopy && passportCopy.documentStatus === "Re Upload" ? false : isButtonEnabled(passportExpiryDate) && userType === "Admin" ? true : isEdit && isView === false && userType === "Admin" && blockIntent === true
                      ? false : isDisabled()}
                    onClick={(e) => {
                      // setPassportFile(null); setPassportExpiryDate(null); deleteDocument('passportCopy', passportFile);
                      setDocumentType('passportCopy');
                      handleopenClose(true);
                      // dispatch(deleteDocument(passportFile.id, passportFile.sapCustomerId, true));
                    }}>
                    <CancelIcon className={isEdit && isView === false && userType === "Admin" && blockIntent === true && passportCopy && passportCopy.documentStatus === "Re Upload" ? "file-close-color" : isButtonEnabled(passportExpiryDate) && userType === "Admin" ? 'file-close-color disabledCustomclass' : isEdit && isView === false && userType === "Admin" && blockIntent === true
                      ? "file-close-color" : isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={passportExpiryDate && isValidPassportExpiryDate ? "label" : "button"}
                  color="secondary"
                  className={isEdit && isView === false && userType === "Admin" && blockIntent === true && passportCopy && passportCopy.documentStatus === "Re Upload" ?  "rounded-button-dealer pl-4 pr-4 mt-2" : isEdit && isView === false && userType === "Admin" && blockIntent === true && passportExpiryDate === null ? "rounded-button-dealer pl-4 pr-4 mt-2 actionButtomButtomsBlueDisabled" : "rounded-button-dealer pl-4 pr-4 mt-2"}
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && passportCopy && passportCopy.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && passportExpiryDate === null ? false : isDisabled() ? isDisabled() : !passportExpiryDate || isValidPassportExpiryDate === false}
                > Browse File
                  <input type="file" hidden
                    onClick={(e: any) => {
                      const { target = {} } = e || {}
                      target.value = ""
                      setPassportFile(null)
                      showErrorMessage('');
                      handleopenCloseErrorSnackbar(false);
                    }}
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('passportFile', e.target.files[0], setPassportFile, getPrevDocId('passportCopy') ? getPrevDocId('passportCopy') : '') : setPassportFile(null)
                    } />
                </Button>
              }
            </div>
            <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
              <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onError={(e, v) => datePickerValid(e, setIsValidPassportExpiryDate)}
                disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && passportCopy && passportCopy.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && passportFile === null ? false : isDisabled() ? isDisabled() : passportExpiryDate && passportFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={passportExpiryDate}
                onChange={(date: Date | null) => { setPassportExpiryDate(date); !date && setPassportFile(null) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
              />
            </div>
          </TestRow>
          {nationality === 'AE' ? '' : (
            <TestRow>
              <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-2">
                {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customer.toLowerCase() === "workshop" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? <h6 className="document-content">Valid Visa Copy{' '}</h6> : <h6 className="document-content">Valid Visa Copy{' '}{customer !== "Government" && customer !== "Fleet" ? "*" : ""}</h6>}
                <small className="document-sub-content pb-3">Owner/Partner</small>
              </div>
              <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
                {visaFile !== null ?
                  <div className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(visaFile.url)
                    }}>{visaFile.name ? visaFile.name : visaFile.selectedFileName}</span>
                    <IconButton disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && visaCopy && visaCopy.documentStatus === "Re Upload" ? false : isButtonEnabled(visaExpiryDate) && userType === "Admin" ? true : isEdit && isView === false && userType === "Admin" && blockIntent === true
                      ? false : isDisabled()} className="p-1 mt-2" aria-label="delete" onClick={(e) => {
                        // setVisaFile(null); setVisaExpiryDate(null); deleteDocument('visaCopy', visaFile);
                        setDocumentType('visaCopy');
                        handleopenClose(true);
                      }}>
                      <CancelIcon className={isEdit && isView === false && userType === "Admin" && blockIntent === true && visaCopy && visaCopy.documentStatus === "Re Upload" ? "file-close-color" : isButtonEnabled(visaExpiryDate) && userType === "Admin" ? 'file-close-color disabledCustomclass' : isEdit && isView === false && userType === "Admin" && blockIntent === true
                        ? "file-close-color" : isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                    </IconButton>
                  </div>
                  : <Button
                    variant="contained"
                    component={visaExpiryDate && isValidVisaExpiryDate ? "label" : "button"}
                    color="secondary"
                    className={isEdit && isView === false && userType === "Admin" && blockIntent === true && visaCopy && visaCopy.documentStatus === "Re Upload" ? "rounded-button-dealer pl-4 pr-4 mt-2" : isEdit && isView === false && userType === "Admin" && blockIntent === true && visaExpiryDate === null ? "rounded-button-dealer pl-4 pr-4 mt-2 actionButtomButtomsBlueDisabled" : "rounded-button-dealer pl-4 pr-4 mt-2"}
                    disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && visaCopy && visaCopy.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && visaExpiryDate === null ? false : isDisabled() ? isDisabled() : !visaExpiryDate || isValidVisaExpiryDate === false}
                  > Browse File
                    <input type="file" hidden
                      onClick={(e: any) => {
                        const { target = {} } = e || {}
                        target.value = ""
                        // setPassportFile(null)
                        showErrorMessage('');
                        handleopenCloseErrorSnackbar(false);
                      }}
                      onChange={(e) =>
                        (e.target && e.target.files) ?
                          onClickBrowseFile('visaCopyFile', e.target.files[0], setVisaFile, getPrevDocId('visaCopy') ? getPrevDocId('visaCopy') : '') : setVisaFile(null)
                      } />
                  </Button>
                }
              </div>
              <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
                <KeyboardDatePicker
                  views={["year", "month", "date"]}
                  openTo="year"
                  disablePast
                  onError={(e, v) => datePickerValid(e, setIsValidVisaExpiryDate)}
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && visaCopy && visaCopy.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && visaFile === null ? false : isDisabled() ? isDisabled() : visaExpiryDate && visaFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                  format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={visaExpiryDate}
                  onChange={(date: Date | null) => { setVisaExpiryDate(date); !date && setVisaFile(null) }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
                />
              </div>
            </TestRow>
          )}
          <TestRow>
            <div className="col-md-4 col-lg-4 pr-3 mb-2 mt-2">
              {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customer.toLowerCase() === "workshop" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? <h6 className="document-content">Power Of Attorney (POA){' '}</h6> : <h6 className="document-content">Power Of Attorney (POA){' '}{customer !== "Government" && customer !== "Fleet" ? "*" : ""}</h6>}
              <small className="document-sub-content pb-3">Owner/Partner</small>
            </div>
            <div className="col-md-2 col-lg-2 pr-2 mb-2 pl-0 mt-1">
              {poaFile !== null ?
                <div className="file-name">
                  <span className="cursor-pointer" onClick={() => {
                    window.open(poaFile.url)
                  }}>{poaFile.name ? poaFile.name : poaFile.selectedFileName}</span>
                  <IconButton
                    disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && poaCopy && poaCopy.documentStatus === "Re Upload" ? false : isButtonEnabled(poaExpiryDate) && userType === "Admin" ? true : isEdit && isView === false && userType === "Admin" && blockIntent === true
                      ? false : isDisabled()}
                    className="p-1 mt-2" aria-label="delete" onClick={(e) => {
                      // setPoaFile(null); setPoaExpiryDate(null); deleteDocument('poaCopy', poaFile);
                      setDocumentType('powerOfAttorney');
                      handleopenClose(true);
                    }}>
                    <CancelIcon className={isEdit && isView === false && userType === "Admin" && blockIntent === true && poaCopy && poaCopy.documentStatus === "Re Upload" ? "file-close-color" : isButtonEnabled(poaExpiryDate) && userType === "Admin" ? 'file-close-color disabledCustomclass' : isEdit && isView === false && userType === "Admin" && blockIntent === true
                      ? "file-close-color" : isDisabled() ? 'file-close-color disabledCustomclass' : "file-close-color"} fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={poaExpiryDate && isValidPoaExpiryDate ? "label" : "button"}
                  color="secondary"
                  className={isEdit && isView === false && userType === "Admin" && blockIntent === true && poaCopy && poaCopy.documentStatus === "Re Upload" ?  "rounded-button-dealer pl-4 pr-4 mt-2" : isEdit && isView === false && userType === "Admin" && blockIntent === true && poaExpiryDate === null ? "rounded-button-dealer pl-4 pr-4 mt-2 actionButtomButtomsBlueDisabled" : "rounded-button-dealer pl-4 pr-4 mt-2"}
                  disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && poaCopy && poaCopy.documentStatus === "Re Upload" ? false :  isEdit && isView === false && userType === "Admin" && blockIntent === true && poaExpiryDate === null ? false : isDisabled() ? isDisabled() : !poaExpiryDate || isValidPoaExpiryDate === false}
                > Browse File
                  <input type="file" hidden
                    onClick={(e: any) => {
                      const { target = {} } = e || {}
                      target.value = ""
                      setPoaFile(null)
                      showErrorMessage('');
                      handleopenCloseErrorSnackbar(false);
                    }}
                    onChange={(e) =>
                      (e.target && e.target.files) ?
                        onClickBrowseFile('poaFile', e.target.files[0], setPoaFile, getPrevDocId('powerOfAttorney') ? getPrevDocId('powerOfAttorney') : '') : setPoaFile(null)
                    } />
                </Button>
              }
            </div>
            <div className="col-md-6 col-lg-6 pr-2 mb-2 pl-0 mt-1 px-4">
              <KeyboardDatePicker
                views={["year", "month", "date"]}
                openTo="year"
                disablePast
                onError={(e, v) => datePickerValid(e, setIsValidPoaExpiryDate)}
                disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && poaCopy && poaCopy.documentStatus === "Re Upload" ? false : isEdit && isView === false && userType === "Admin" && blockIntent === true && poaFile === null ? false : isDisabled() ? isDisabled() : poaExpiryDate && poaFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label={`Expiry Date ${customer !== "Government" && customer !== "Fleet" && sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && customer.toLowerCase() !== "workshop" ? "*" : ""}`} value={poaExpiryDate}
                onChange={(date: Date | null) => { setPoaExpiryDate(date); !date && setPoaFile(null) }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className={'pl-0 pr-4 mt-2 rounded-input-dealer cal-icon'}
              />
            </div>
          </TestRow>
        </>
      </div>
      <DialogActions className="mt-3 mb-3 justify-content-end">
        {isDisabled() ? '' : (
          <Button variant="contained"
            color="secondary"
            className={`createFavourite ${isEdit && isView === false && userType === "Admin" && blockIntent === true && poaFile === null || nationality !== 'AE' && visaFile === null || passportFile === null || emiratesIdFile === null ? "actionButtomButtomsBlue actionButtomButtomsBlueDisabled" : loading || !isValidEdit ? "actionButtomButtomsBlue actionButtomButtomsBlueDisabled" : "actionButtomButtomsBlue "}`}
            disabled={isEdit && isView === false && userType === "Admin" && blockIntent === true && poaFile === null || nationality !== 'AE' && visaFile === null || passportFile === null || emiratesIdFile === null ? true : loading || !isValidEdit ? true : false}
            onClick={() => {
              if (sessionStorage.getItem('customerOnboardingType') === 'Workshop') {
                validationAddNewAuthorisedSignatoriesDialog();
                handleopenClose(false);
              } else {
                validateAddNewAuthorisedSignatoriesDialog();
                handleopenClose(false);
              }
            }}
          >{isEdit ? 'Update' : 'Save'}
          </Button>
        )}
        <DeleteDialog
          open={openDeleteDialog}
          text="Are you sure you want to delete this document ?"
          handleopenClose={(data: any) => {
            if (data === 'delete') deleteDocument();
            if (!data) handleopenClose(false);
          }} />
        <Button variant="contained" className={classes.closeButton}
          onClick={() => {
            setOpenDialog();
            resetData();
          }}
        >Cancel</Button>
      </DialogActions>
    </Fragment>
  );
}