/* eslint-disable */
import React, { Fragment, Dispatch, useEffect, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import AssignmentReturnedIcon from "@material-ui/icons/AssignmentReturned";
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import TableCell from '@material-ui/core/TableCell';
import { TextField, MenuItem } from '@material-ui/core';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import AddIcon from '@material-ui/icons/Add';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from "moment-timezone";
import CancelIcon from '@material-ui/icons/Cancel';
import { ValidationErrorMessage } from '../../constants/messages';
import { get } from 'lodash';
import { deleteAddressDocument, resetValues, updateDocument, getTradeRegion, getSelectedShipToPartyAddress, getAddressWithCustomerNo, getNationality } from '../../store/actions/onboard.actions';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import DeleteDialog from '../../common/components/deleteDialog';
import DeleteDocumentDialog from '../../common/components/deleteDocumentDialog';
import { getDealerDetails } from '../../store/actions/editDealer.actions';
import Checkbox from '@material-ui/core/Checkbox';
import FullscreenLoader from './../../common/components/FullscreenLoader';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  title: {
    flex: '1 1 100%',
  },
  buttonDel: {
    color: "#1A9FE0",
    fontSize: '0.625rem',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    float: 'right'
  },
  buttonIconDel: {
    color: "#1A9FE0",
  },
  bodyContent: {
    color: '#666666',
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 600
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  oppacityLess: {
    opacity: 0.5,
  }
}));

const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

export const AddNewAddressDailog = forwardRef((props: {
  setOpenDialog: any, firstAddressCheck: any, getNewAddressData: any, editedAddress: any, isEdited: boolean, regionData: any, sapId: any, masterCompanyBrands: any, status: any, selectedDelivery: any, isView: any, isOutsideFreezone?: any, isIntBuisness?: any, isDefaultUAE?: any, salesRegion?: string
}, ref) => {
  const { setOpenDialog, getNewAddressData, editedAddress, isEdited, regionData, sapId, firstAddressCheck, isOutsideFreezone, isIntBuisness, isDefaultUAE, salesRegion, masterCompanyBrands, selectedDelivery, isView } = props;
  const [address, setAddress] = React.useState<any>([]);
  const [disabledFields, setDisabledFields] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState('');
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [streetName, setStreetName] = React.useState<string>("");
  const [streetNameError, setStreetNameError] = React.useState<boolean>(false);
  const [city, setCity] = React.useState<string>("");
  const [cityError, setCityError] = React.useState<boolean>(false);
  const [country, setCountry] = React.useState<string>("");
  const [ibSalesRegion, setIbSalesRegion] = React.useState<string>("");
  const [countryError, setCountryError] = React.useState<boolean>(false);
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [postalCodeError, setPostalCodeError] = React.useState<boolean>(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { tradeRegionData, zoneData, getIBCountryAndRegionData }: any = useSelector((state: any) => state.onboard);
  const [isExistingAddress, setIsExistingAddress] = React.useState(false);
  const [nameError, showError] = React.useState<boolean>(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [isSubmitClicked, setIsSubmitClicked] = React.useState<any>(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [isEdit, setIsEdit] = React.useState(true);
  const [expiryDate, setExpiryDate] = React.useState<string>();
  const today: any = moment(new Date()).format("YYYY-MM-DD");
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [telephoneOfficeError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [telephoneOfficeErrorMessage, setTelephoneOfficeValidtionMessage] = React.useState<string>("");
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [selectEmirates, setSelectEmirates] = React.useState<string>("");
  const [selectEmiratesError, setSelectEmiratesError] = React.useState<boolean>(false);
  const [dealerAddress, setDealerAddress] = React.useState<string>("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [isSubmit, setSubmit] = React.useState<any>(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [documentType, setDocumentType] = React.useState<any>(null);
  const [openDeleteDialogDocument, setOpenDeleteDialogDocument] = React.useState(false);
  const [selectTrade, setSelectTrade] = React.useState<string>("");
  const [regionDataByCountry, setRegionDataByCountry] = React.useState<any>([]);
  const [selectTradeId, setSelectTradeId] = React.useState<string>("");
  const [selectTradeError, setSelectTradeError] = React.useState<boolean>(false);
  const [authority, setAuthority] = React.useState<any>(null);
  const [authorityDocumentId, setAuthorityDocumentId] = React.useState<any>({ documentId: '' });
  const [deliveryLocationRegFile, setDeliveryLocationRegFile] = React.useState<any>(null);
  const [deliveryLocation, setDeliveryLocation] = React.useState<any>({ documentId: '' });
  const [permises, setPermises] = React.useState<any>(null);
  const [permisesDocumentId, setPermisesDocumentId] = React.useState<any>({ documentId: '' });
  const [invalidAuthorized, setInvalidAuthorized] = React.useState<boolean>(false);
  const [invalidStreetName, setInvalidStreetName] = React.useState<boolean>(false);
  const [invalidCityName, setInvalidCityName] = React.useState<boolean>(false);
  const [invalidCountryName, setInvalidCountryName] = React.useState<boolean>(false);
  const [dealerAddressError, setDealerAddressError] = React.useState<boolean>(false);
  const [invalidDoucmentupload, setInvalidDoucmentupload] = React.useState<boolean>(false);
  const [shippingLabelOne, setShippingLabelOne] = React.useState<string>("");
  const [shippingLabelTwo, setShippingLabelTwo] = React.useState<string>("");
  const [invalidPhotopremises, setInvalidPhotopremises] = React.useState<boolean>(false);
  const [visaExpiryDate, setVisaExpiryDate] = React.useState<any>(null);
  const [openDeleteDialogAddress, setOpenDeleteDialogAddress] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [pictureDocumentId, setPictureDocumentId] = React.useState<string>("");
  const [currentEmiratesIndex, setCurrentEmiratesIndex] = React.useState<any>(0);
  const { selectedNewDealer, shippingDropdownValues }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const [symbolArr] = React.useState<any>(["e", "E", ".", "-"]);
  const [uniqueContactPersonNames, setUniqueContactPersonNames] = React.useState<string[]>(['']);
  const [companyBrandDetails, setCompanyBrands] = React.useState<any>(masterCompanyBrands ? masterCompanyBrands : []);
  const [authorizedPersons, setAuthorizedPersons] = React.useState<any>([
    {
      name: "",
      ValidationErrorMessage: "",
      document: null,
      isValidEmail: true,
      isValidContactNumber: true,
      emailId: "",
      isDocumentUploaded: false,
      isValidName: true,
      expiryDate: null,
      // documentId: 0,
      isValidExpiryDate: false
    }
  ])
  const { dealerDetails, getNationalityData, shipToPartyAddressData, getSelectedAddress, }: any = useSelector((state: any) => state.onboard);
  const [customer, setCustomer] = React.useState<any>("")
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  const [email, setEmailId] = React.useState("")
  const [invalidEmailId, setInvalidEmailId] = React.useState<any>(false)
  const [longitudeError, setLongitudeError] = React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [lattitudeError, setLattitudeError] = React.useState<any>(false)
  const [emailIdError, setEmailIdError] = React.useState<any>(false);
  const [defaultAddress, setDefaultAddress] = React.useState(false);
  const [customerTierType, setCustomerTierType] = React.useState("")
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId') || '';
  const companyNameData = get(shipToPartyAddressData, "data.companyName", "");
  let trimmedString: any;
  if (companyNameData) {
    let string = companyNameData;
    if (string.length > 33) {
      trimmedString = string.substr(0, 33);
      let lastSpaceIndex = trimmedString.lastIndexOf(" ");
      if (lastSpaceIndex !== -1) {
        trimmedString = trimmedString.substr(0, lastSpaceIndex);
      }
    } else {
      trimmedString = string;
    }
  }
  const [firstName, setFirstName] = React.useState<string>(trimmedString);
  const [lastName, setLastName] = React.useState<string>();
  const [selectedRegionCode, setSelectedRegionCode] = React.useState<string>("");
  const [locationNameErrorMessage, setLocationNameErrorMessage] = React.useState<string>("");
  const [locationName, setLocationName] = React.useState<string>(trimmedString);
  const [locationNameError, setLocationNameError] = React.useState<boolean>(false);
  const [invalidLocationName, setInvalidLocatioName] = React.useState<boolean>(false);
  const [customerNumber, setCustomerNumber] = React.useState<any>();
  const [customerNumberError, setCustomerNumberError] = React.useState(false);
  const [isPullFromSAP, setIsPullfromSAP] = React.useState(false);
  const [addressFromSAP, setAddressById] = React.useState<any>();
  const [transportationZone, setTransportationZone] = React.useState<string>("");
  const [loading, setLoading] = React.useState<any>(false);

  useEffect(() => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType) setCustomer(dealerDetails && dealerDetails.data && dealerDetails.data.customerTierType)
    setCustomerTierType(dealerDetails && dealerDetails.data && dealerDetails.data.isNewCustomer)
  }, [dealerDetails])

  useEffect(() => {
    if (defaultAddress) {
      populateAddressDetails(getSelectedAddress.data);
    }
  }, [getSelectedAddress])

  useEffect(() => {
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      if (getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion && getIBCountryAndRegionData.data.countryAndSalesRegion.length > 0) {
        const regionArray = getRegionsByCountryCode(getIBCountryAndRegionData.data.countryAndSalesRegion, isDefaultUAE)
        setRegionDataByCountry(regionArray)
      }
    } else {
      setRegionDataByCountry(regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions)
    }
  }, [])

  function getRegionsByCountryCode(arr: any, countryCode: any) {
    return arr.filter((obj: any) => obj.countryCode === countryCode).map((obj: any) => ({ Code: obj.regionCode, Region: obj.regionDesc }));
  }

  function getZone(zoneCode: any) {
    let zone: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zoneData: any) => {
        if (zoneData.code === zoneCode) {
          zone = zoneData.title
        }
      });
    return zone;
  }

  const addContactPerson = () => {
    // Check if the last text field is not empty before adding a new one 
    if (uniqueContactPersonNames[uniqueContactPersonNames.length - 1] !== '') {
      setUniqueContactPersonNames([...uniqueContactPersonNames, '']);
    }
  };
  const handlePersonNameChange = (index: number, newName: string) => {
    const updatedNames = [...uniqueContactPersonNames];
    updatedNames[index] = newName;
    setUniqueContactPersonNames(updatedNames);
  };

  function isFieldsDisabled() {
    if (props.status === 'Incomplete from KAM' || props.status === 'Incomplete from FSE' || props.status === "Awaiting feedback from FSE" || props.status === 'Dealer confirmation pending' || props.isView == false) {
      return false;
    } else if (isEdited) {
      return true
    }
    else {
      return true;
    }
  }

  const handleopenCloseDocument = (open?: any) => {
    setOpenDeleteDialogDocument(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  function validateCustomerNumber(number: any) {
    const re = /^[0-9]+$/;
    return re.test(number);
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleCustomerChange = (e: any) => {
    setCustomerNumber(e.target.value);
    setIsSubmitClicked(false);
    setStreetName("");
    setPoBox("");
    setTelephoneOffice("");
    setFirstName(trimmedString);
    setCity("");
    setCountry("");
    setPostalCode("");
    setEmailId("");
    setSelectedRegionCode("");
    setSelectEmirates("");
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "") {
        setIsSubmitClicked(true);
        setCustomerNumberError(true);
      } else if (validateCustomerNumber(e.target.value) === false) {
        setCustomerNumberError(true);
      }
      else {
        setCustomerNumberError(false);
      }
    }
  }

  const handleSubmitCustomerNo = async () => {
    if (customerNumber && validateCustomerNumber(customerNumber)) {
      if (customerNumber) {
        let appendedString: any = customerNumber + '';
        if (appendedString.length < 10) {
          let newString = '';
          let customerLength = 10 - appendedString.length;
          for (let i = 0; i < customerLength; i++) {
            newString += '0'
          }
          appendedString = newString + customerNumber;
        }
        setCustomerNumber(appendedString);
        let addData: any = await dispatch(getAddressWithCustomerNo(b2bUserId, appendedString));
        // let addData: any = await dispatch(getAddressWithCustomerNo(b2bUserId, customerNumber));
        if (addData && addData.data && addData.data.customerInformation && addData.data.customerInformation.addresses && addData.data.customerInformation.addresses.length > 0) {
          setAddressById(addData.data.customerInformation);
          setSuccessMessage('Address details fetched successfully');
          handleopenCloseSnackbar(true);
          setIsSubmitClicked(true);
          populateAddressDetails(addData.data.customerInformation.addresses[0], false, addData.data.customerInformation);
          setIsPullfromSAP(true);
          setCustomerNumberError(false);
        } else if (addData && addData.error) {
          showErrorMessage(addData.error);
          setIsSubmitClicked(false);
          handleopenCloseErrorSnackbar(true);
          setIsPullfromSAP(true);
          setCustomerNumberError(true);
        }
      }
    } else {
      showErrorMessage('Kindly enter the existing address’s Ship to party Customer ID or uncheck the option Pull from existing address');
      setIsPullfromSAP(true);
      handleopenCloseErrorSnackbar(true);
      setCustomerNumberError(true);
    }
  }

  const addAuthorizedPersonsRows = () => {
    let isValid = true;
    setSubmit(false);
    authorizedPersons.forEach((item: any, index: any) => {
      item.isValidName = true;
      item.isValidEmail = true;
      item.isValidExpiryDate = true;
      item.ValidationErrorMessage = "";
      item.isDocumentUploaded = true;
      if (isIntBuisness && item.name == "") {
        isValid = false;
        item.isValidName = false;
        item.ValidationErrorMessage = "Please fill the details and upload the document";
      }
      else if (isIntBuisness && item.document == null) {
        isValid = false;
        item.isDocumentUploaded = false;
        if (item.name != "") {
          setSubmit(true);
          item.ValidationErrorMessage = "Please upload the Emirates Id (jpg, pdf or png)";
        }
      }
      else if (isIntBuisness && item.expiryDate == "Invalid date") {
        item.isValidExpiryDate = false;
        isValid = false;
        item.ValidationErrorMessage = "Please mention the expiry date";
      }
      authorizedPersons[index] = {
        ...item
      }
    });
    let authorizedPersonsRow = [...authorizedPersons];
    setAuthorizedPersons(authorizedPersonsRow);
    const row = {
      name: "",
      ValidationErrorMessage: "",
      document: null,
      documentid: 0,
      isValidEmail: true,
      isValidContactNumber: true,
      contactNumber: '',
      emailId: '',
      isDocumentUploaded: false,
      isValidName: true,
      expiryDate: null,
      isValidExpiryDate: false
    }
    if (isValid) {
      authorizedPersonsRow.push(row);
      setAuthorizedPersons(authorizedPersonsRow);
      setInvalidAuthorized(false);
    }
    else {
      setInvalidAuthorized(true);
    }
  }
  const deleteAuthorizedPersonsRows = () => {
    let authorizedPersonsRow = [...authorizedPersons];
    authorizedPersonsRow = authorizedPersonsRow.filter((element, index1) => {
      return index1 != currentEmiratesIndex;
    })
    setAuthorizedPersons(authorizedPersonsRow);
    handleopenClose(false);
  }

  const removeAddress = (e: any, index: any) => {
    const addressData: any = [...address];
    addressData.splice(index, 1);
    setAddress(addressData);
  };

  function emailvalidation(email: any, index: any) {
    if (email.length > 0 && !(/^\w+([\..-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim()))) {
      return false;
    }
    else {
      return true;
    }
  }

  function validateAuthorisedPersonsName(name: string) {
    if (name !== '') {
      if (validateFormFields('authorizeName', name)) {
        return true
      } else {
        return false
      }
    } else {
      return name === "" || name === undefined || name === null
    }
  }

  function validateAuthorizedPersonsDocument(doc: any) {
    return doc === "" || doc === undefined || doc === null
  }

  function validateAuthorizedPersonDate(doc: any) {
    return doc === "" || doc === undefined || doc === null
  }

  function validatecontactNumber(contactNumber: any, index: any) {
    if (contactNumber && contactNumber !== '' && contactNumber.length <= 10) {
      authorizedPersons[index].isValidContactNumber = true;
      setAuthorizedPersons(authorizedPersons);
      return true;
    }
    authorizedPersons[index].isValidContactNumber = false;
    setAuthorizedPersons(authorizedPersons);
    return false
  }

  function validateAuthorisedPersons() {
    let ValidArray: any = [];
    authorizedPersons.map((eachPerson: any, index: any) => {
      if (validatePerson(eachPerson, index)) {
        ValidArray.push(eachPerson);
        eachPerson.emailError = false
      }
      // if (eachPerson.name !== '' && validatecontactNumber(eachPerson.contactNumber, index) && !validateAuthorizedPersonDate(eachPerson.expiryDate) && !validateAuthorizedPersonsDocument(eachPerson.document)) {
      //   ValidArray.push(eachPerson);
      // }
    })
    if (authorizedPersons.length !== 0 && ValidArray && (ValidArray.length === 0 || ValidArray.length > 0) && authorizedPersons.length !== ValidArray.length) {
      const updatedDataWithErrors = authorizedPersons.map((person: any, index: any) => {
        return {
          ...person,
          isValidName: person.name === '' ? false : validateAuthorisedPersonsName(person.name) ? true : false,
          isValidEmail: customer === "Government" || customer === "Fleet" || selectedDelivery === "DLV" ? person.emailId == "" ? false : emailvalidation(person.emailId, index) : emailvalidation(person.emailId, index)
        }
      })
      setAuthorizedPersons(updatedDataWithErrors)
      return false
    }
    return true
  }

  function validatePerson(eachPerson: any, index: any) {
    let isvalid = true
    if (!isIntBuisness && eachPerson.name === "") {
      isvalid = false;
    }
    if (!isIntBuisness && ((eachPerson.emailId === "" && selectedDelivery === "DLV") || (eachPerson.emailId === "" && customer === "Government"))) {
      isvalid = false;
      eachPerson.emailError = true
    }
    if (!isIntBuisness && ((!validatecontactNumber(eachPerson.contactNumber, index)) && selectedDelivery === "DLV")) {
      isvalid = false;
    }
    if (!isIntBuisness && ((validateAuthorizedPersonDate(eachPerson.expiryDate)) && selectedDelivery === "DLV")) {
      isvalid = false;
    }
    if (!isIntBuisness && ((validateAuthorizedPersonsDocument(eachPerson.document)) && selectedDelivery === "DLV")) {
      isvalid = false;
    }

    return isvalid;
  }


  function validateFormFields(action: string, value: any) {
    switch (action) {
      case "telephoneOffice": {
        const re = /^[0-9]{20}$/
        return value.length > 20 || !re.test(value) ? false : true;
      }
      case "location": {
        if (firstAddressCheck === true) {
          return value.length > 250 ? false : true;
        } else {
          return value.length > 33 ? false : true;
        }
      }
      case "poBox": {
        const re = /^[0-9]{7}$/
        return !re.test(value) ? false : true;
      }
      // case "firstname": {
      //   // const re = /^[a-zA-Z0-9 ]{1,250}$/;
      //   // return !re.test(value) ? false : true;
      //   return value.length > 33 ? false : true;
      // }
      case "streename": {
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        // return !re.test(value) ? false : true;
        return value.length > 250 ? false : true;
      }
      case "city": {
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        // return !re.test(value) ? false : true;
        return value.length > 250 ? false : true;
      }
      case "country": {
        const re = /^[a-zA-Z0-9 ]{1,250}$/;
        return !re.test(value) ? false : true;
      }
      case "authorizeName": {
        return true;
        // const re = /^[a-zA-Z0-9 ]{1,250}$/;
        // return !re.test(value) ? false : true;
      }
      default:
        return true
    }
  }


  function ValidateOnSaveAddress() {
    return new Promise((resolve, reject) => {
      let isValidationSucess: boolean = true;
      if (locationName == "" || locationName == undefined) {
        setLocationNameError(true);
        setIsValid(false);
        isValidationSucess = false;
      } else {
        setIsValid(true);
      }
      if (locationName && locationName.trim() !== "") {
        if (!validateFormFields("location", locationName)) {
          setLocationNameError(true);
          setIsValid(false);
          isValidationSucess = false;
        }
        else {
          setLocationNameError(false);
        }
      } else {
        setIsValid(true);
      }
      if ((selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE')) {
      } else {
        if ((latitude == "" || latitude == undefined) && !isIntBuisness) {
          setIsValid(false);
          isValidationSucess = false;
          setLattitudeError(true)
        } else {
          setIsValid(true)
        }
        if ((longitude == "" || longitude == undefined) && !isIntBuisness) {
          setIsValid(false);
          isValidationSucess = false;
          setLongitudeError(true)
        } else {
          setIsValid(true)
        }
      }
      if (!isPullFromSAP && !editedAddress.shiftToPartyCustomerNumber) {
        if ((email == "" || email == undefined || invalidEmailId) && !isIntBuisness) {
          setIsValid(false);
          isValidationSucess = false;
          setEmailIdError(true)
        } else {
          setIsValid(true)
          setEmailIdError(false);
          setInvalidEmailId(false)
        }
      }
      if (isView === false) {
        if (isIntBuisness || isPullFromSAP || editedAddress.shiftToPartyCustomerNumber) {
          setTelephoneOfficeError(false);
          setInvalidTelephoneOffice(false);
        }
        else if ((telephoneOffice == "") || (telephoneOffice == undefined) || (invalidTelephoneOffice == true)) {
          setTelephoneOfficeError(true);
          setIsValid(false);
          isValidationSucess = false;
        } else {
          setTelephoneOfficeError(false);
          setInvalidTelephoneOffice(false);
        }

        if (customer.toLowerCase() !== 'workshop' && !isIntBuisness) {
          if (!isPullFromSAP && !editedAddress.shiftToPartyCustomerNumber) {
            if (poBox == "" || poBox == undefined) {
              setPoBoxError(true);
              setIsValid(false);
              isValidationSucess = false;
            }
            if (poBox && poBox.trim() !== "") {
              if (poBox.length > 7) {
                setPoBoxError(true);
                setInvalidPoBox(true);
                isValidationSucess = false;
              }
              else {
                setInvalidPoBox(false);
                setPoBoxError(false);
              }
            }
          }
        }
        if (((selectEmirates == undefined && customer.toLowerCase() === "workshop" && selectedDelivery === "DLV" || customer.toLowerCase() === "workshop" && selectEmirates == "" && selectedDelivery === "DLV") || (selectEmirates == "" && customer.toLowerCase() !== "workshop" || selectEmirates == undefined && customer.toLowerCase() !== "workshop"))) {
          setSelectEmiratesError(true);
          setIsValid(false);
          isValidationSucess = false;
        }
        else if (selectEmirates !== "") {
          setSelectEmiratesError(false);
        }
        if (!isPullFromSAP && !editedAddress.shiftToPartyCustomerNumber) {
          if ((streetName == "" || streetName == undefined) && !isIntBuisness) {
            setStreetNameError(true);
            setIsValid(false);
            isValidationSucess = false;
          }
          if ((streetName && streetName.trim() !== "") && !isIntBuisness) {
            if (!validateFormFields("streename", streetName)) {
              setStreetNameError(true);
              setIsValid(false);
              isValidationSucess = false;
            }
            else {
              setStreetNameError(false);
            }
          }
        }
        // if (editedAddress && editedAddress.dafault === true) {
        //   setFirstNameError(false);
        // } else {
        //   if (firstName == "" || firstName == undefined) {
        //     setFirstNameError(true);
        //     setIsValid(false);
        //     isValidationSucess = false;
        //   }
        //   if (firstName && firstName.trim() !== "") {
        //     if (!validateFormFields("firstname", firstName)) {
        //       setFirstNameError(true);
        //       setIsValid(false);
        //       isValidationSucess = false;
        //     }
        //     else {
        //       setFirstNameError(false);
        //     }
        //   }
        // }
        if (!isPullFromSAP && !editedAddress.shiftToPartyCustomerNumber) {
          if ((city == "" || city == undefined) && !isIntBuisness) {
            setCityError(true);
            setIsValid(false);
            isValidationSucess = false;
          }
          if ((city && city.trim() !== "") && !isIntBuisness) {
            if (!validateFormFields("city", city)) {
              setCityError(true);
              setIsValid(false);
              isValidationSucess = false;
            }
            else {
              setCityError(false);
            }
          }
        }
        if (country == "" || country == undefined) {
          setCountryError(true);
          setIsValid(false);
          isValidationSucess = false;
        }
        if (country && country.trim() !== "") {
          if (!validateFormFields("country", country)) {
            setCountryError(true);
            setIsValid(false);
            isValidationSucess = false;
          }
          else {
            setCountryError(false);
          }
        }
        if ((customer.toLowerCase() !== 'workshop' && customer.toLowerCase() !== 'dealer') && !isIntBuisness) {
          if (postalCode == "" && customer !== "Government" && customer !== "Fleet" || postalCode == undefined && customer !== "Government" && customer !== "Fleet") {
            setPostalCodeError(true);
            setIsValid(false);
            isValidationSucess = false;
          }
        }
        // NEED TO REMOVE THIS CODE FOR INTBUISNESS FOR NOW DISBALED THE VALIDATION 
        if (isIntBuisness || isPullFromSAP || editedAddress.shiftToPartyCustomerNumber) {
          setTelephoneOfficeError(false);
          setInvalidTelephoneOffice(false);
        } else if (isIntBuisness && telephoneOffice.length > 30) {
          setInvalidTelephoneOffice(true);
          isValidationSucess = false;
          setIsValid(false);
        } else if (telephoneOffice !== "" && telephoneOffice != undefined) {
          if (telephoneOffice.split('').length > 20 || invalidTelephoneOffice === true) {
            setInvalidTelephoneOffice(true);
            isValidationSucess = false;
            setIsValid(false);
          }
          else {
            setInvalidTelephoneOffice(false);
          }
        }
      } else {
        setIsValid(true);
      }

      if (!isIntBuisness && ((selectTradeId == "" && customer !== "Government" && customer.toLowerCase() !== "workshop" || selectTradeId == undefined && customer !== "Government" && customer.toLowerCase() !== "workshop") || (customer.toLowerCase() === "workshop" && selectedDelivery === "DLV" && selectTradeId === "") || (customer.toLowerCase() === "workshop" && selectedDelivery === "DLV" && selectTradeId === undefined))) {
        setSelectTradeError(true);
        setIsValid(false);
        isValidationSucess = false;
      } else if (selectTradeId !== "") {
        setSelectTradeError(false)
      }

      if (sessionStorage.getItem('userType') === 'FSE' && customer.toLowerCase() === "workshop") {
      } else {
        if (!isIntBuisness && ((!deliveryLocationRegFile && customer !== "Government" && customer !== "Fleet") && selectedDelivery === "DLV")) {
          isValidationSucess = false;
        } else {
          setIsValid(true);
        }
        if (!isIntBuisness && ((!permises && customer !== "Government" && customer !== "Fleet") && selectedDelivery === "DLV")) {
          isValidationSucess = false;
        } else {
          setIsValid(true);
        }
      }



      if ((selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE')) {
      } else {
        if (!isIntBuisness && ((!authority && selectedDelivery === "DLV" && editedAddress && editedAddress.dafault) || (!authority && firstAddressCheck === true && editedAddress && editedAddress.dafault))) {
          isValidationSucess = false;
        } else {
          setIsValid(true);
        }
      }

      if (isIntBuisness) {
        if (!validateAuthorisedPersons() && selectedDelivery === "DLV") {
          isValidationSucess = false
        } else {
          setIsValid(true);
        }
      } else if (!isIntBuisness) {
        if ((!validateAuthorisedPersons() && selectedDelivery === "DLV")) {
          isValidationSucess = false
        } else {
          setIsValid(true);
        }
      } else {
        setIsValid(true);
      }

      resolve(isValidationSucess)
    })
  }

  const populateAddressDetails = async (editedAddress: any, ispullfromsap: any = true, addressBySAP: any = null) => {
    setFirstName(trimmedString);
    setLocationName(trimmedString);
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
    if (addressBySAP && typeof addressBySAP === 'object') {
      setCustomerNumber(addressBySAP.customerCode ? addressBySAP.customerCode : '')
      setFirstName(addressBySAP.customerFirstName ? addressBySAP.customerFirstName : trimmedString);
      setLocationName(addressBySAP.customerFirstName ? addressBySAP.customerFirstName : trimmedString);
      setLastName(addressBySAP.customerLastName ? addressBySAP.customerLastName : '');
      setTransportationZone(addressBySAP.companyTransportationZone ? addressBySAP.companyTransportationZone : '')
      setTelephoneOffice(addressBySAP && addressBySAP.telephones && addressBySAP.telephones.length > 0 && addressBySAP.telephones[0].telephone);
      setEmailId(addressBySAP && addressBySAP.emails && addressBySAP.emails.length > 0 && addressBySAP.emails[0].emailId);
      if (addressBySAP && addressBySAP.shippingLabels && addressBySAP.shippingLabels.length > 0) {
        let shippingLabelOneFropmSAP = addressBySAP.shippingLabels.find((ele: any) => ele.name === "SORT1")
        if (shippingLabelOneFropmSAP) {
          setShippingLabelOne(shippingLabelOneFropmSAP)
        }
      }
      if (addressBySAP && addressBySAP.shippingLabels && addressBySAP.shippingLabels.length > 0) {
        let shippingLabelTwoFropmSAP = addressBySAP.shippingLabels.find((ele: any) => ele.name === "SORT2")
        if (shippingLabelTwoFropmSAP) {
          setShippingLabelTwo(shippingLabelTwoFropmSAP)
        }
      }
      if (addressBySAP.companyBrands && addressBySAP.companyBrands.length > 0) {
        if (addressBySAP.companyBrands && addressBySAP.companyBrands[0].shippingDetails && typeof addressBySAP.companyBrands[0].shippingDetails === 'object') {
          // const convertedCompanyBrands = addressBySAP.companyBrands.map((companyBrand: any) => {
          //   const { name, paymentType, group, shippingDetails } = companyBrand;
          //   return {
          //     organizationId: name,
          //     shippingDetails
          //   };
          // });

          // setCompanyBrands(convertedCompanyBrands);
          const updatedMasterCompanyBrands = masterCompanyBrands.map((masterBrand: any) => {
            const matchingCompanyBrand = addressBySAP.companyBrands.find((brand: any) => brand.name === masterBrand.organizationId);

            if (matchingCompanyBrand) {
              return {
                ...masterBrand,
                shippingDetails: {
                  ...masterBrand.shippingDetails,
                  ...matchingCompanyBrand.shippingDetails
                }
              };
            }
            return masterBrand;
          });
          setCompanyBrands(updatedMasterCompanyBrands);
          const uniqueNames: any = [...new Set(addressBySAP.companyBrands.flatMap((brand: any) => brand.shippingDetails && brand.shippingDetails.shippingPartnerName))];
          if (uniqueNames && uniqueNames.length > 0) {
            setUniqueContactPersonNames(uniqueNames);
          } else {
            setUniqueContactPersonNames(['']);
          }
        } else {
          setCompanyBrands(masterCompanyBrands);
          setUniqueContactPersonNames(['']);
        }
      }
    }
    if ((editedAddress && defaultAddress) || (editedAddress && !ispullfromsap)) {
      if (!ispullfromsap) {
        let array: any = [];
        // if (editedAddress.streetName) array.push('streetName')
        // if (editedAddress.poBox) array.push('pobox')
        // if (editedAddress.city) array.push('city')
        // if (editedAddress.country) array.push('country')
        // if (editedAddress.postalCode) array.push('postalCode')
        // if (email || (addressBySAP && addressBySAP.emails && addressBySAP.emails.length > 0 && addressBySAP.emails[0].emailId)) array.push('emailId')
        // if (editedAddress.region) array.push('region')
        // if (telephoneOffice || (addressBySAP && addressBySAP.telephones && addressBySAP.telephones.length > 0 && addressBySAP.telephones[0].telephone)) array.push('telephoneOffice')
        array.push('streetName', 'pobox', 'city', 'country', 'postalCode', 'region', 'emailId', 'telephoneOffice');
        setDisabledFields(array);
      }
      setStreetName(editedAddress.streetName);
      setPoBox(editedAddress.poBox);
      setCity(editedAddress.city);
      setCountry((isIntBuisness && isEdited === false && !isView) ? isDefaultUAE : editedAddress.country);
      setIbSalesRegion(editedAddress.salesRegion)
      if (!defaultAddress) setCustomerNumber(editedAddress && editedAddress.shiftToPartyCustomerNumber ? editedAddress.shiftToPartyCustomerNumber : addressBySAP && addressBySAP.customerCode ? addressBySAP.customerCode : '');
      setPostalCode(editedAddress.postalCode);
      if (!ispullfromsap) {
        setSelectedRegionCode(editedAddress.region);
        setSelectEmirates(editedAddress.region);
      } else {
        setTelephoneOffice(typeof editedAddress.telephoneNumber == 'string' ? editedAddress.telephoneNumber : editedAddress && editedAddress.telephoneNumber && editedAddress.telephoneNumber.length > 0 && editedAddress.telephoneNumber[0].telephone);
        setEmailId(typeof editedAddress.emailId == 'string' ? editedAddress.emailId : editedAddress && editedAddress.emailId && editedAddress.emailId.length > 0 && editedAddress.emailId[0].emailId);
      }
    } else if (editedAddress && !defaultAddress && ispullfromsap) {
      setLocationName(editedAddress && editedAddress.companyName ? editedAddress.companyName : editedAddress && editedAddress.location ? editedAddress.location : trimmedString);
      setIsExistingAddress(editedAddress.isExisting);
      setCustomerNumber(editedAddress && editedAddress.shiftToPartyCustomerNumber ? editedAddress.shiftToPartyCustomerNumber : addressBySAP && addressBySAP.customerCode ? addressBySAP.customerCode : '');
      setTelephoneOffice(typeof editedAddress.telephoneNumber == 'string' ? editedAddress.telephoneNumber : editedAddress && editedAddress.telephoneNumber && editedAddress.telephoneNumber.length > 0 && editedAddress.telephoneNumber[0].telephone);
      setPoBox(editedAddress.poBox);
      setSelectEmirates(editedAddress.region);
      setSelectedRegionCode(editedAddress.region);
      setSelectTrade(editedAddress.tradeLicenseNo);
      setSelectTradeId(editedAddress.tradeLicenseNoId)
      setStreetName(editedAddress.streetName);
      setCity(editedAddress.city);
      setCountry((isIntBuisness && isEdited === false && !isView) ? isDefaultUAE : editedAddress.country);
      setIbSalesRegion(editedAddress.salesRegion)
      setPostalCode(editedAddress.postalCode);
      setLatitude(editedAddress.lattitude);
      setLongitude(editedAddress.longitude);
      setEmailId(typeof editedAddress.emailId == 'string' ? editedAddress.emailId : editedAddress && editedAddress.emailId && editedAddress.emailId.length > 0 && editedAddress.emailId[0].emailId);
      setFirstName(editedAddress.location ? editedAddress.location : editedAddress.companyName);
      setLastName(editedAddress.customerLastName);
      setTransportationZone(editedAddress.transportationZoneCode);
      if (editedAddress.companyBrands && editedAddress.companyBrands.length > 0) {
        setCompanyBrands(editedAddress.companyBrands);
        const uniqueNames: any = [...new Set(editedAddress.companyBrands.flatMap((brand: any) => brand.shippingDetails && brand.shippingDetails.shippingPartnerName))];
        if (uniqueNames && uniqueNames.length > 0) {
          setUniqueContactPersonNames(uniqueNames);
        } else {
          setUniqueContactPersonNames(['']);
        }
      }
      if (editedAddress && editedAddress.personToAcceptDelivery && editedAddress.personToAcceptDelivery.length > 0) {
        let data: any = [];
        editedAddress.personToAcceptDelivery.map((tl: any) => {
          if (tl.documentId) {
            data.push({
              name: tl.name,
              isValidName: tl.name ? true : false,
              isValidEmail: true,
              document: { ...tl.documentId },
              expiryDate: tl.expiryDate,
              ValidationErrorMessage: "",
              isValidContactNumber: true,
              isDocumentUploaded: tl.documentId && tl.documentId.url ? true : false,
              isValidExpiryDate: true,
              contactNumber: tl.contactNumber,
              emailId: tl.emailId
            })
          }
        });
        setAuthorizedPersons(data);
      }
      if (editedAddress && editedAddress.pictureOfDeliveryLocation && editedAddress.pictureOfDeliveryLocation.documentId) {
        const { id } = editedAddress.pictureOfDeliveryLocation.documentId;
        setDeliveryLocation({ documentId: id })
        setDeliveryLocationRegFile(editedAddress.pictureOfDeliveryLocation.documentId);
      }
      if (editedAddress && editedAddress.photoOfPremises && editedAddress.photoOfPremises.documentId) {
        const { id } = editedAddress.photoOfPremises.documentId;
        setPermisesDocumentId({ documentId: id })
        setPermises(editedAddress.photoOfPremises.documentId);
      }
      if (editedAddress && editedAddress.authorityLetter && editedAddress.authorityLetter.documentId) {
        const { id } = editedAddress.authorityLetter.documentId;
        setAuthorityDocumentId({ documentId: id })
        setAuthority(editedAddress.authorityLetter.documentId);
      }
    }
  }

  useEffect(() => {
    populateAddressDetails(editedAddress);
    dispatch(getTradeRegion(b2bUserId));
  }, [editedAddress])


  const validateLocationField = (locationId: string, locationValue: string) => {
    if (!validateFormFields(locationId, locationValue)) {
      setInvalidLocatioName(true);
      setLocationNameError(true);
      setIsValid(false);
    } else if (firstAddressCheck !== true && locationValue && locationValue.length > 33) {
      setLocationNameErrorMessage("It should not exceed 33 characters.")
      setLocationNameError(true);
      setInvalidLocatioName(true);
      setLocationNameError(true);
      setIsValid(false);
    } else {
      setInvalidLocatioName(false);
      setLocationNameError(false);
      setIsValid(true);
    }
  }

  const onChangeLocationName = (e: any) => {
    const locationValue = e.target.value;
    setLocationName(locationValue);
    setFirstName(locationValue);
    if (e.target.required) {
      if (locationValue === "" || locationValue.trim() === "") {
        setLocationNameError(true);
      } else if (firstAddressCheck !== true && locationValue && locationValue.length > 33) {
        setLocationNameErrorMessage("It should not exceed 33 characters.")
        setLocationNameError(true);
        setInvalidLocatioName(true);
        setLocationNameError(true);
        setIsValid(false);
      } else {
        validateLocationField(e.target.id, locationValue)
      }
    }
  }

  const validateTelephoneValue = (telephoneId: string, telephoneValue: string) => {
    if (!isIntBuisness && (telephoneValue.charAt(0) != "0") && telephoneValue.length > 9) {
      setTelephoneOfficeValidtionMessage(ValidationErrorMessage.MOBILE_TEXT);
      setInvalidTelephoneOffice(true);
      setTelephoneOfficeError(true);
      setIsValid(false);
      // errorSetterName(true);
    }
    //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
    else if (!isIntBuisness && telephoneValue.charAt(0) != "0") {
      // errorErrorMessage("It should be start with 5 or 0.")
      setTelephoneOfficeValidtionMessage("It should be start with 0.")
      setInvalidTelephoneOffice(true);
      setTelephoneOfficeError(true);
      setIsValid(false);
    }
    else if (!isIntBuisness && telephoneValue.length > 9) {
      setTelephoneOfficeValidtionMessage("It should not exceed 9 digits.")
      setTelephoneOfficeError(true);
      setInvalidTelephoneOffice(true);
      setTelephoneOfficeError(true);
      setIsValid(false);
    } else if (isIntBuisness && telephoneValue.length > 30) {
      setTelephoneOfficeValidtionMessage("It should not exceed 30 digits.")
      setTelephoneOfficeError(true);
      setInvalidTelephoneOffice(true);
      setTelephoneOfficeError(true);
      setIsValid(false);
    }
    else {
      setInvalidTelephoneOffice(false);
      setIsValid(true);
    }
  }

  // useEffect(() => {
  //   if (trimmedString) {
  //     onChangeLocationName({ target: { value: trimmedString } })
  //     setLocationName(trimmedString);
  //     setFirstName(trimmedString);
  //   }
  // }, [trimmedString])

  const onChangeCompanyName = (e: any) => {
    setCompanyName(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setCompanyNameError(true);
      else
        setCompanyNameError(false);
    }
  }

  const onChangeTelephoneOffice = (e: any) => {
    const telephoneValue = e.target.value
    const telephoneId = e.target.id
    setTelephoneOffice(telephoneValue);
    if (e.target.required) {
      if (telephoneValue === "" || telephoneValue.trim() === "") {
        setInvalidTelephoneOffice(true);
        setTelephoneOfficeError(true);
      }
      else {
        setInvalidTelephoneOffice(false);
        setTelephoneOfficeError(false);
      }
    }
    validateTelephoneValue(telephoneId, telephoneValue)
  }

  const onChangePoBox = (e: any) => {
    const value = e.target.value
    setPoBox(value);
    if (e.target.required) {
      if (value === "" || value.trim() === "") {
        setPoBoxError(true);
        setInvalidPoBox(true)
      }
      else if (value.length > 7) {
        setPoBoxError(true);
        setInvalidPoBox(true);
      }
      else {
        setPoBoxError(false);
        setInvalidPoBox(false)
      }
    }
  }

  const onChangeDealerAddress = (e: any) => {
    setDealerAddress(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setDealerAddressError(true);
      else
        setDealerAddressError(false);
    }
  }

  const onChangeCity = (e: any) => {
    setCity(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setCityError(true);
      else {
        if (!validateFormFields(e.target.id, e.target.value)) {
          setCityError(true);
          setInvalidCityName(true);
        }
        else {
          setCityError(false);
          setInvalidCityName(false);
        }
      }
    }
  }

  const onChangeStreetName = (e: any) => {
    const value = e.target.value
    setStreetName(value);
    if (e.target.required) {
      if (value === "" || value.trim() === "") {
        setStreetNameError(true);
      }
      else {
        if (!validateFormFields(e.target.id, value)) {
          setStreetNameError(true);
          setInvalidStreetName(true)
        }
        else {
          setStreetNameError(false);
          setInvalidStreetName(false)
        }
      }
    }
  }

  const onChangeCountry = (e: any) => {
    setCountry(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setCountryError(true);
      else {
        if (!validateFormFields(e.target.id, e.target.value)) {
          setInvalidCountryName(true);
          setCountryError(true);
        }
        else {
          setCountryError(false);
          setInvalidCountryName(false);
        }
      }
    }
  }

  const onChangePostalCode = (e: any) => {
    setPostalCode(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setPostalCodeError(true);
      else
        setPostalCodeError(false);
    }
  }

  const onChangeEmiratesRegion = (e: any) => {
    setSelectedRegionCode(e.target.value);
    setSelectEmirates(e.target.value);
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectEmiratesError(true);
    }
    else {
      setSelectEmiratesError(false);
    }
  }
  const onChangeTradeLicenseNumber = (e: any) => {
    setSelectTradeId(e.target.value);
    if (tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0) {
      let selected = tradeRegionData.data.filter((data: any) => data.id === e.target.value);
      setSelectTrade(selected && selected.length > 0 && selected[0].tradeLicenseNo);
    }
    if (e.target.value === "" || e.target.value.trim() === "") {
      setSelectTradeError(true);
    }
    else {
      setSelectTradeError(false);
    }
  }
  const onChangeAuthorizedName = (e: any, index: any) => {
    let authorizedPersonsRow = [...authorizedPersons];
    authorizedPersonsRow[index].name = e.target.value;
    authorizedPersonsRow[index].isValidName = e.target.value ? true : false
    setAuthorizedPersons(authorizedPersonsRow)
    if (e.target.value === "" || e.target.value.trim() === "") {
      setIsValid(false);
      authorizedPersonsRow[index].isValidName = false;
      authorizedPersonsRow[index].isValid = false;
    } else {
      if (!validateFormFields('authorizeName', e.target.value)) {
        authorizedPersonsRow[index].isValidName = false;
        authorizedPersonsRow[index].isValid = false;
        authorizedPersonsRow[index].ValidationErrorMessage = ValidationErrorMessage.ALPHA_NUMERIC_TEXT;
        setIsValid(false);
      }
      else {
        authorizedPersonsRow[index].isValidName = true;
        authorizedPersonsRow[index].isValid = true;
        authorizedPersonsRow[index].ValidationErrorMessage = "";
        setIsValid(true);
      }
    }
  }

  const onChangeContactNumber = (e: any, index: any) => {
    let authorizedPersonsRow = [...authorizedPersons];
    authorizedPersonsRow[index].contactNumber = e.target.value;
    setAuthorizedPersons(authorizedPersonsRow)
    if (e.target.value.length > 10) {
      authorizedPersonsRow[index].isValidContactNumber = false;
      authorizedPersonsRow[index].ValidationErrorMessage = ValidationErrorMessage.CONTACT_NUMBER;
    }
    else {
      authorizedPersonsRow[index].isValidContactNumber = true
      authorizedPersonsRow[index].ValidationErrorMessage = "";
    }
  }

  const onChangeEmailId = (e: any, index: any) => {
    let authorizedPersonsRow = [...authorizedPersons];
    if (emailvalidation(e.target.value, index)) {
      authorizedPersonsRow[index].isValidEmail = true;
      authorizedPersonsRow[index].isValid = true;
      setIsValid(true);
    } else {
      authorizedPersonsRow[index].isValidEmail = false;
      authorizedPersonsRow[index].isValid = false;
      setIsValid(false);
    }
    authorizedPersonsRow[index].isValidEmail = emailvalidation(e.target.value, index)
    authorizedPersonsRow[index].emailId = e.target.value;
    setAuthorizedPersons(authorizedPersonsRow)
  }

  const createNewAddress = () => {
    setSubmit(false);
    if (!validateAuthorisedPersons()) {
      setInvalidAuthorized(true);
    }
    else {
      setInvalidAuthorized(false);
    }
    let authorizedPList: any = [];
    let authorizedPersonsRow = [...authorizedPersons];
    if (authorizedPersonsRow.length > 0) {
      authorizedPersonsRow.map((item: any, index: any) => {
        if (item.name && item.name !== '' && item.name !== undefined) {
          let itemRow = {
            name: item.name,
            documentId: item.documentId ? item.documentId : item.document && item.document.id ? item.document.id : '',
            expiryDate: item.expiryDate ? moment(item.expiryDate).utc().format() : item.document && item.document.expiryDate ? moment(item.document.expiryDate).utc().format() : '',
            contactNumber: item.contactNumber,
            emailId: item.emailId
          }
          authorizedPList.push(itemRow)
        }
      })
    }
    let updatedBrands = companyBrandDetails && companyBrandDetails.length > 0 && companyBrandDetails.map((brand: any) => {
      return {
        ...brand,
        shippingDetails: {
          ...brand.shippingDetails,
          shippingPartnerName: uniqueContactPersonNames.filter((name: any) => name !== ''), // Filter out empty names
        },
      };
    });

    if (customer.toLowerCase() === 'workshop') {
      let deliveringPlants: Array<[]> = shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.deliveringPlant
      let deliveryingPlantValue = ''
      if (deliveringPlants && deliveringPlants.length > 0) {
        let filteredDeliveringPlantForWorkshop: Array<[]> = deliveringPlants.filter((deliveryPlant: any) => deliveryPlant.brand === "2195")

        let foundAssignedRegionCodeValue: any = filteredDeliveringPlantForWorkshop.find((deliveryPlant: any) => deliveryPlant.regionCode.includes(selectEmirates))
        if (foundAssignedRegionCodeValue) {
          deliveryingPlantValue = foundAssignedRegionCodeValue.value
        }
      }
      let brandDetails = [...updatedBrands]
      for (let i = 0; i < brandDetails.length; i++) {
        const brand = { ...brandDetails[i] };
        brand.shippingDetails.deliveringPlant = deliveryingPlantValue
        brandDetails[i] = brand
      }
      updatedBrands = brandDetails
    }
    setCompanyBrands(updatedBrands);
    // setUniqueContactPersonNames(['']);
    let updatedData = {};
    let data = {
      "sapCustomerId": sapId,
      "location": locationName,
      "customerFirstName": firstName ? firstName : "",
      "telephoneNumber": telephoneOffice,
      "poBox": poBox,
      "tradeLicenseNo": selectTrade,
      "tradeLicenseNoId": selectTradeId,
      "region": selectEmirates,
      "city": city,
      "postalCode": postalCode,
      "streetName": streetName,
      "country": country,
      "transportationZone": transportationZone ? getZone(transportationZone) : '',
      "transportationZoneCode": transportationZone,
      "shiftToPartyCustomerNumber": defaultAddress ? '' : customerNumber ? customerNumber : '',
      "isExisting": isExistingAddress ? true : false,
      "customerLastName": lastName ? lastName : '',
      "personToAcceptDelivery": authorizedPList,
      "pictureOfDeliveryLocation": deliveryLocation,
      "photoOfPremises": permisesDocumentId,
      "authorityLetter": authorityDocumentId,
      "lattitude": latitude,
      "longitude": longitude,
      "emailId": email,
      "addedBy": webApplicationLoginId,
      "addedByRole": "kam",
      "shippingLabels": [{ name: "SORT1", value: shippingLabelOne }, { name: "SORT2", value: shippingLabelTwo }],
      "companyBrands": updatedBrands
    }
    if (isEdited) {
      updatedData = {
        "sapCustomerId": sapId,
        "location": locationName,
        "customerFirstName": firstName ? firstName : "",
        "telephoneNumber": telephoneOffice,
        "poBox": poBox,
        "tradeLicenseNo": selectTrade,
        "tradeLicenseNoId": selectTradeId,
        "region": selectEmirates,
        "city": city,
        "postalCode": postalCode,
        "streetName": streetName,
        "country": country,
        "transportationZone": transportationZone ? getZone(transportationZone) : '',
        "transportationZoneCode": transportationZone,
        "shiftToPartyCustomerNumber": defaultAddress ? '' : customerNumber ? customerNumber : '',
        "isExisting": isExistingAddress ? true : false,
        "customerLastName": lastName ? lastName : '',
        "userType": "kam",
        "personToAcceptDelivery": authorizedPList,
        "pictureOfDeliveryLocation": deliveryLocation,
        "photoOfPremises": permisesDocumentId,
        "authorityLetter": authorityDocumentId,
        "lattitude": latitude,
        "longitude": longitude,
        "emailId": email,
        "addedBy": webApplicationLoginId,
        "addedByRole": "kam",
        "shippingLabels": [{ name: "SORT1", value: shippingLabelOne }, { name: "SORT2", value: shippingLabelTwo }],
        "companyBrands": updatedBrands
      }
    }
    if (isExistingAddress && !customerNumber) {
      if (!customerNumber || validateCustomerNumber(customerNumber) == false) {
        showErrorMessage('Kindly enter the existing address’s Ship to party Customer ID or uncheck the option Pull from existing address');
        setIsPullfromSAP(true);
        handleopenCloseErrorSnackbar(true);
        setCustomerNumberError(true);
        // setSubmit(true);
      }
    } else {
      ValidateOnSaveAddress().then((res: any) => {
        if (res && isValid) {
          if (!isIntBuisness && !deliveryLocationRegFile && selectedDelivery === "DLV") {
            setSubmit(true);
          }
          if (!isIntBuisness && !permises && selectedDelivery === "DLV") {
            setSubmit(true);
          }
          if (!isIntBuisness && !authority && selectedDelivery === "DLV" && editedAddress && editedAddress.dafault) {
            setSubmit(true);
          }
          else {
            getNewAddressData(isEdited ? updatedData : data);
            setOpenDialog();
          }
        }
        else if (firstAddressCheck !== true && locationName && locationName.length > 33) {
          showErrorMessage('Name of Location It should not exceed 33 characters.');
          handleopenCloseErrorSnackbar(true);
          setSubmit(true);
        } else {
          showErrorMessage('Please fill all mandatory fields and upload all mandatory documents');
          handleopenCloseErrorSnackbar(true);
          setSubmit(true);
        }
      })
    }
  }

  const onClickBrowseFile = async (type: any, item: any, setCallback: any, index?: any) => {
    setLoading(true);
    showErrorMessage(null);
    handleopenCloseErrorSnackbar(false);
    setInvalidDoucmentupload(false);
    setInvalidPhotopremises(false);

    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf'],
      authorityDocExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1,
      canUploadAuthorityFile = authorityDocExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (type !== 'authorityLetter' && !canUploadFile) {
      showErrorMessage('Invalid File type format, please upload jpg, pdf or png.');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
      if (type == 'pictureOfDeliveryLocation') {
        setInvalidDoucmentupload(true);
      }
      if (type == 'photoOfPremises') {
        setInvalidPhotopremises(true);
      }
      return;
    }
    if (type === 'authorityLetter' && !canUploadAuthorityFile) {
      showErrorMessage('Invalid File type format.');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
      return;
    }

    if (item && item.size < 5000000) {
      setLoading(true);
      let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
      if (setCallback) {
        if (type == "document") {
          let authorizedPersonsRow = [...authorizedPersons];
          authorizedPersonsRow[index].document = item;
          let expiryDate = moment(authorizedPersonsRow[index].expiryDate).utc().format()
          keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
          setCurrentEmiratesIndex(index);
          setCallback(authorizedPersonsRow);
        }
        else {
          setCallback(item);
        }
      }
      const customHeaders = {
        "Accept": "application/json",
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": "",
        "Accept-Language": "EN",
      };

      let docType = '';
      if (type === 'document') { docType = 'Emirates Id File' }
      else if (type === 'pictureOfDeliveryLocation') { docType = 'Picture of Delivery Location File' }
      else if (type === 'photoOfPremises') { docType = 'Photo of Permises File' }
      else if (type === 'authorityLetter') { docType = 'Authority Letter' }
      const formData: any = new FormData();
      formData.append("entityName", "CUSTOMER");
      formData.append("keyAttribute", keyAttribute);
      formData.append("fileUpload", item);
      formData.append("document", 'true');
      formData.append("Attributes", `key=Brand&value=${item}`);
      formData.append("sapCustomerId", sapId);
      formData.append("docType", docType);
      formData.append("label", type);
      formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
      formData.append("uploadedByRole", 'dealerUser');
      formData.append("uploadedBywebApplicationLoginId", '');
      formData.append("selectedFileName", item.name);

      let documentRes: any = await dispatch(updateDocument(formData, customHeaders));
      if (documentRes && documentRes.message && documentRes.message.status === 'S') {
        setLoading(false);
        const { label, id } = documentRes.data;
        if (label === "pictureOfDeliveryLocation") {
          setDeliveryLocation({ documentId: id });
          setDeliveryLocationRegFile(documentRes.data)
        }
        else if (label === "photoOfPremises") {
          setPermisesDocumentId({ documentId: id });
          setPermises(documentRes.data);
        } else if (label === "authorityLetter") {
          setAuthorityDocumentId({ documentId: id });
          setAuthority(documentRes.data);
        }
        else if (label === "document") {
          let authorizedPersonsRow = [...authorizedPersons];
          authorizedPersonsRow[index].documentId = id;
          authorizedPersonsRow[index].document = documentRes.data;
          setAuthorizedPersons(authorizedPersonsRow)
        }
      } else {
        showErrorMessage('Document upload failed');
        handleopenCloseErrorSnackbar(true);
        setLoading(false);
        if (type === "pictureOfDeliveryLocation") {
          setDeliveryLocation({ documentId: '' });
          setDeliveryLocationRegFile(null)
        }
        else if (type === "photoOfPremises") {
          setPermisesDocumentId({ documentId: '' });
          setPermises(null);
        }
        else if (type === "authorityLetter") {
          setAuthorityDocumentId({ documentId: '' });
          setAuthority(null);
        }
        else if (type === "document") {
          let authorizedPersonsRow = [...authorizedPersons];
          authorizedPersonsRow[index].documentId = null;
          authorizedPersonsRow[index].document = null;
          setAuthorizedPersons(authorizedPersonsRow)
        }
      }
    } else {
      showErrorMessage('Please upload less than 5MB file');
      handleopenCloseErrorSnackbar(true);
      setLoading(false);
    }
    setLoading(false);
  }

  // const deleteAddressDoc = async (type: any, data: any, index?: any) => {
  //   dispatch(resetValues());
  //   const obj = {
  //     "type": type
  //   }
  //   let response: any = await dispatch(deleteAddressDocument(editedAddress && editedAddress.id, data.id, obj));
  //   if (response && response.message && response.message.status === 'S') {
  //     if (type === 'pictureOfDeliveryLocation') {
  //       setDeliveryLocation({ documentId: '' });
  //       setDeliveryLocationRegFile(null);
  //     }
  //     if (type === 'photoOfPremises') {
  //       setPermises(null);
  //       setPermisesDocumentId({ documentId: '' });
  //     }
  //     if (type === 'personToAcceptDelivery') {
  //       let persons = [...authorizedPersons]
  //       persons[index].document = null;
  //     }
  //   }
  // }

  const deleteAddressDoc = async (index?: any) => {
    dispatch(resetValues());
    let data: any;
    if (documentType == 'photoOfPremises') {
      data = permises;
    } else if (documentType == 'personToAcceptDelivery') {
      let apRow = [...authorizedPersons]
      data = apRow[currentEmiratesIndex].document;
    } else if (documentType == 'pictureOfDeliveryLocation') {
      data = deliveryLocationRegFile;
    } else if (documentType == 'authorityLetter') {
      data = authority;
    }
    const obj = {
      "type": documentType
    }
    let response: any = await dispatch(deleteAddressDocument(editedAddress && editedAddress.id, data.id, obj, b2bUserId));
    if (response && response.message && response.message.status === 'S') {
      if (documentType === 'pictureOfDeliveryLocation') {
        setDeliveryLocation({ documentId: '' });
        setDeliveryLocationRegFile(null);
      }
      if (documentType === 'photoOfPremises') {
        setPermises(null);
        setPermisesDocumentId({ documentId: '' });
      }
      if (documentType === 'authorityLetter') {
        setAuthority(null);
        setAuthorityDocumentId({ documentId: '' });
      }
      if (documentType === 'personToAcceptDelivery') {
        let persons = [...authorizedPersons]
        persons[currentEmiratesIndex].document = null;
        persons[currentEmiratesIndex].isDocumentUploaded = false;
        persons[currentEmiratesIndex].expiryDate = null;
      }
      handleopenCloseDocument(false);
    } else {
      showErrorMessage(response.error);
      handleopenCloseErrorSnackbar(true);
    }
  }

  const handleInputChange = (e: any, type: any, index: number, errorSetterName?: any, errorErrorMessage?: any) => {
    let apRow = [...authorizedPersons]
    switch (type) {
      case "name":
        apRow[index].name = e.target.value;
        apRow[index].isValidName = e.target.value ? true : false
        break;
      case "document":
        handleopenCloseDocument(true);
        setCurrentEmiratesIndex(index);
        setDocumentType('personToAcceptDelivery');
        break;
      case "expiryDate":
        apRow[index].expiryDate = e.target.value;
        break;
      default: ;
    }
    setAuthorizedPersons(apRow);
  }
  const AddressList = (part: any, index: any) => (
    <>
      <TableRow>
        <TableCell className={`p-0 ${classes.bodyContent}`}>
          <IconButton aria-label="delete" size="small"
            className={`${classes.buttonIconDel} p-1 align-center`}>
            <AddIcon fontSize="inherit" />
          </IconButton>

          <IconButton aria-label="delete" size="small"
            onClick={(e) => { removeAddress(e, index); }} className={`${classes.buttonIconDel} p-1 align-center`}>
            <DeleteIcon fontSize="inherit" />
          </IconButton>
          {(
            <>
              <br />
              <span className="errorMsg">Add Address Details</span>
            </>
          )}
        </TableCell>
      </TableRow>
      )
    </>
  );

  const classes = useStyles();
  function getDocumentName(item: any) {
    if (item.document && item.document.selectedFileName) {
      return item.document.selectedFileName
    }
    else if (item.document && item.document.name) {
      return item.document.name
    }
  }

  function isDisabled(index: any) {
    if (!authorizedPersons[index].expiryDate) {
      return false;
    }
    else {
      return true;
    }
    // }
  }

  const onChangeLattitude = (e: any) => {
    setLatitude(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setLattitudeError(true);
      else
        setLattitudeError(false);
    }
  }

  const onChangeEmiratesRegionCode = (e: any) => {
    setSelectedRegionCode(e.target.value);
  }

  const onChangeFirstName = (e: any) => {
    const value = e.target.value
    setFirstName(value);
    // if (e.target.required) {
    //   if (value === "" || value.trim() === "") {
    //     setFirstNameError(true);
    //   }
    //   else {
    //     if (!validateFormFields(e.target.id, value)) {
    //       setFirstNameError(true);
    //       setInvalidFirstName(true)
    //     }
    //     else {
    //       setFirstNameError(false);
    //       setInvalidFirstName(false)
    //     }
    //   }
    // }
  }

  const onChangeLongitude = (e: any) => {
    setLongitude(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setLongitudeError(true);
      else
        setLongitudeError(false);
    }
  }

  function datePickerValid(e: any, type: any, index?: any) {
    if (type === 'document') {
      let authorizedPersonsRow = [...authorizedPersons]
      if (e === "Invalid Date Format") {
        authorizedPersonsRow[index].dateError = "Invalid Date Format"
        authorizedPersonsRow[index].invalidDate = true
      } else if (e === "Date should be less than today date.") {
        authorizedPersonsRow[index].dateError = "Date should be greater than today date."
        authorizedPersonsRow[index].invalidDate = true
      } else if (e === "Date should not be before minimal date") {
        authorizedPersonsRow[index].dateError = "Date should not be before minimal date"
        authorizedPersonsRow[index].invalidDate = true
      } else {
        authorizedPersonsRow[index].dateError = '';
        authorizedPersonsRow[index].invalidDate = false
      }
    } else {
      if (e === "Invalid Date Format") {
        type(false);
      } else if (e === "Date should not be before minimal date") {
        type(false);
      } else if (e === "Date should not be after maximal date") {
        type(false);
      } else {
        type(true);
      }
    }
  }


  useEffect(() => {
    if (selectEmirates !== '' && regionDataByCountry && regionDataByCountry.length > 0) {
      regionDataByCountry && regionDataByCountry.length > 0 && regionDataByCountry.map((r: any) => {
        if ((r.Code === selectEmirates)) {
        } else if (r.Region === selectEmirates) {
          setSelectEmirates(r.Code);
          setSelectedRegionCode(r.Code);
        }
      })
    }
  }, [regionDataByCountry, selectEmirates]);


  const handleEmailId = (e: any) => {
    setEmailId(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        setEmailIdError(true);
      else
        setEmailIdError(false);
    }

    if (!emailvalidation(e.target.value, "")) {
      setInvalidEmailId(true)
    } else {
      setInvalidEmailId(false)
    }

  }



  return (
    <Fragment>
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      {loading && (<FullscreenLoader />)}
      {isEdit && (
        <div className={`${classes.root} onboarding-field`}>
          <>
            <Toolbar
              className={clsx(classes.root)}>
              <div className="col-12 mt-4">
                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                  <h4 className="shift-to-party-page-title">
                    {((isEdited && props.status === 'Incomplete from KAM' && !isView) || (isEdited && props.status === 'Incomplete from FSE' && !isView) || (isEdited && props.status === 'Awaiting feedback from FSE' && !isView)) ? 'Edit Address' : isEdited ? 'View Address' : 'Add New Address'}
                    <div className="float-right d-flex flex-row"
                    >
                      {editedAddress.shiftToPartyCustomerNumber || isView || (editedAddress.dafault) ? '' : (
                        <div className="dealer-table-content px-2 button-add-new-address">
                          <ThemeProvider theme={theme}>
                            <Checkbox
                              checked={isExistingAddress}
                              // disabled={props.dealerStatus == 'In-Progress' ? false : true}
                              onChange={async (e) => {
                                setDefaultAddress(false);
                                setIsExistingAddress(e.target.checked);
                                setIsPullfromSAP(false);
                                setCustomerNumber("");
                                setStreetName("");
                                setPoBox("");
                                setTelephoneOffice("");
                                setCity("");
                                setCountry("");
                                setPostalCode("");
                                setFirstName(trimmedString);
                                setEmailId("");
                                setSelectedRegionCode("");
                                setSelectEmirates("");
                              }}
                              className="checkbox button-secondary" />
                          </ThemeProvider>
                          <span className="dealer-table-content">Pull From Existing Address</span>
                        </div>
                      )}
                      {!isEdited && <div className="dealer-table-content px-2 button-add-new-address">
                        <ThemeProvider theme={theme}>
                          <Checkbox
                            checked={defaultAddress}
                            disabled={false}
                            onChange={async (e) => {
                              setDefaultAddress(e.target.checked);
                              setIsExistingAddress(false);
                              setCustomerNumber("");
                              setStreetName("");
                              setPoBox("");
                              setTelephoneOffice("");
                              setFirstName(trimmedString);
                              setCity("");
                              setCountry("");
                              setPostalCode("");
                              setEmailId("");
                              setSelectedRegionCode("");
                              setSelectEmirates("");
                              setIsPullfromSAP(false);
                              const yes = shipToPartyAddressData.data && shipToPartyAddressData.data.addresses.filter((ele: any) => {
                                return ele.dafault
                              })
                              await dispatch(getSelectedShipToPartyAddress(b2bUserId, yes[0].id));
                            }}
                            className="checkbox button-secondary" />
                        </ThemeProvider>
                        <span className="dealer-table-content">Copy From Head Office Address </span>
                      </div>}
                      <div className="margin-icon">
                        <IconButton className="">
                          <CloseIcon onClick={() => setOpenDialog()} />
                        </IconButton>
                      </div>
                    </div>
                  </h4>
                </Typography>
              </div>
            </Toolbar>
            {editedAddress.shiftToPartyCustomerNumber || (editedAddress.dafault) || isView ? '' : (
              <>
                {(isExistingAddress && !editedAddress.shiftToPartyCustomerNumber) && (
                  <Toolbar className='offset-6'>
                    <TextField
                      id="Ship to party Customer ID" value={customerNumber}
                      error={customerNumberError}
                      disabled={isEdited && editedAddress.shiftToPartyCustomerNumber}
                      onChange={(e: any) => handleCustomerChange(e)}
                      size="small"
                      onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
                      type="number"
                      label="Ship to party Customer ID" required variant="outlined" margin="dense" />
                    <Button variant="contained"
                      disabled={isEdited && editedAddress.shiftToPartyCustomerNumber}
                      onClick={() => handleSubmitCustomerNo()}
                      color="secondary" className={`createFavourite ${classes.createButton}`}
                    >Submit
                    </Button>
                  </Toolbar>
                )}
              </>
            )}
            <div className='row m-0'>
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  id="location"
                  size="small"
                  disabled={editedAddress.shiftToPartyCustomerNumber ? true : isFieldsDisabled() || isView ? true : false}
                  className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                  label="Name of Location"
                  variant="outlined"
                  required
                  inputProps={firstAddressCheck !== true ? { maxLength: 33 } : { maxLength: 250 }}
                  value={locationName}
                  error={locationNameError}
                  onChange={(e: any) => onChangeLocationName(e)}
                  helperText={locationName && invalidLocationName && <span style={{ color: 'red' }}>{locationNameErrorMessage}</span>}
                // helperText={invalidLocationName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                />
              </Toolbar>
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  required={isPullFromSAP && telephoneOffice ? true : isPullFromSAP ? false : true}
                  type="number"
                  id="telephoneOffice"
                  onChange={(e: any) => onChangeTelephoneOffice(e)}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  error={isPullFromSAP ? false : telephoneOfficeError}
                  disabled={(isIntBuisness && editedAddress.dafault) ? true : (props.status === 'Incomplete from KAM' && editedAddress.dafault) ? true : editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('telephoneOffice') ? true : isView ? true : false}
                  value={telephoneOffice}
                  inputProps={{ maxLength: 20 }}
                  size="small"
                  helperText={telephoneOffice && invalidTelephoneOffice && <span style={{ color: 'red' }}>{telephoneOfficeErrorMessage}</span>}
                  className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                  label={sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" ? "Contact Telephone Number" : "Telephone Office"}
                  variant="outlined"
                />
              </Toolbar>
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  required={isIntBuisness ? false : customer.toLowerCase() === "workshop" ? false : isPullFromSAP && poBox ? true : isPullFromSAP ? false : true}
                  type="number"
                  id="poBox"
                  onChange={(e: any) => onChangePoBox(e)}
                  disabled={editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('pobox') ? true : isView ? true : false}
                  error={isPullFromSAP ? false : poBoxError}
                  value={poBox}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  inputProps={{ maxLength: 7 }}
                  size="small"
                  helperText={invalidPoBox && poBox.length > 7 && <span style={{ color: 'red' }}>{ValidationErrorMessage.POBOX_TEXT}</span>}
                  className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                  label="PO Box"
                  variant="outlined"
                />
              </Toolbar>
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  required
                  id="emiratesRegion"
                  error={selectEmiratesError}
                  size="small"
                  disabled={(isIntBuisness && editedAddress.dafault) ? true : (props.status === 'Incomplete from KAM' && editedAddress.dafault) ? true : editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && selectEmirates && disabledFields.includes('region') ? true : isView ? true : false}
                  value={selectEmirates}
                  // helperText={invalidSelectEmirates && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_LIMIT_TEXT}</span>}
                  select
                  className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                  label="Select Emirates/Region"
                  onChange={(e: any) => onChangeEmiratesRegion(e)}
                  variant="outlined" >
                  {regionDataByCountry.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}> {item.Region}{" "} {"-"} {" "} {item.Code} </MenuItem>))}
                </TextField>
              </Toolbar>
              {editedAddress && editedAddress.dafault !== true ?
                <>
                  <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <div className="col-sm-12 col-md-12 col-lg-12 mb-2 mr-1 px-0 ml-0">
                      <TextField id="firstname" type="text"
                        disabled
                        onKeyDown={(e: any) => ["+", "-"].includes(e.key) && e.preventDefault()}
                        size="small" className="col-sm-10 commonRoundedInputs-dropdown"
                        label="Shipping Location Name" variant="outlined" margin="dense"
                        value={firstName}
                        // helperText={invalidFirstName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                        // error={firstNameError}
                        inputProps={{ maxLength: 33 }}
                        onChange={(e: any) => onChangeFirstName(e)}
                      />
                      <TextField
                        disabled
                        value={selectedRegionCode}
                        id="regioncode" className="col-2 commonRoundedInputs-rightside-textfield aed1-width pr-2" margin="dense" variant="outlined">
                        {regionDataByCountry.map((item: any) => (
                          <MenuItem key={item.Code} value={item.Code}>{item.Code} </MenuItem>))}
                      </TextField>
                    </div>
                  </Toolbar>
                  <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                    <TextField
                      id="emailid"
                      onChange={(e: any) => handleEmailId(e)}
                      error={isPullFromSAP ? false : emailIdError || invalidEmailId}
                      disabled={(sessionStorage.getItem("userType") === "KAM" || props.status === "Submitted by FSE") ? true : editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('emailId') ? true : !isEdited && (editedAddress && editedAddress.dafault) || !(editedAddress && editedAddress.dafault) ? false : isView ? true : false}
                      required={isIntBuisness ? false : isPullFromSAP && email ? true : isPullFromSAP ? false : true}
                      value={email}
                      size="small"
                      type="text"
                      className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                      label="Email Id"
                      variant="outlined"
                      helperText={invalidEmailId && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                    />
                  </Toolbar>
                </>
                : ''}
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  id="streename"
                  size="small"
                  disabled={(props.status === 'Incomplete from KAM' && editedAddress.dafault) ? true : editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('streetName') ? true : isView ? true : false}
                  className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                  label="Street Name"
                  variant="outlined"
                  inputProps={{ maxLength: 250 }}
                  required={isIntBuisness ? false : isPullFromSAP && streetName ? true : isPullFromSAP ? false : true}
                  helperText={invalidStreetName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                  value={streetName}
                  error={isPullFromSAP ? false : streetNameError}
                  onChange={(e: any) => onChangeStreetName(e)}
                />
              </Toolbar>
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  required={isIntBuisness ? false : isPullFromSAP && city ? true : isPullFromSAP ? false : true}
                  id="city"
                  onChange={(e: any) => onChangeCity(e)}
                  error={isPullFromSAP ? false : cityError}
                  disabled={editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('city') ? true : isView ? true : false}
                  value={city}
                  inputProps={{ maxLength: 250 }}
                  size="small"
                  className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                  label="City"
                  helperText={invalidCityName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                  variant="outlined"
                />
              </Toolbar>
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField select id="country"
                  error={isPullFromSAP ? false : countryError}
                  value={(isIntBuisness && isEdited === false && !isView) ? isDefaultUAE : country}
                  // disabled
                  disabled={(props.status === 'Incomplete from KAM' && editedAddress.dafault) ? true : isIntBuisness ? true : editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('country') ? true : isView ? true : false}
                  // disabled={isView ? true : false}
                  helperText={invalidCountryName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                  // onChange={(e: any) => handleInputChange(e, setCountry, setCountryError, setCountryErrorMessage, "country")}
                  onChange={(e: any) => onChangeCountry(e)}
                  required={isPullFromSAP && country ? true : isPullFromSAP ? false : true}
                  size="small" className="col-sm-12 col-lg-12 commonRoundedInputs"
                  label="Country" variant="outlined" margin="dense">
                  {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
                </TextField>
              </Toolbar>
              {isIntBuisness &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    id="ibSalesRegion"
                    value={salesRegion}
                    disabled={true}
                    size="small"
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    inputProps={{ maxLength: 30 }}
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="IB Sales Region"
                    variant="outlined"
                  />
                </Toolbar>
              }
              {!isIntBuisness && customer !== "Government" && customer !== "Fleet" &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    id="postalCode"
                    onChange={(e: any) => onChangePostalCode(e)}
                    error={isPullFromSAP ? false : postalCodeError}
                    value={postalCode}
                    disabled={editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && disabledFields.includes('postalCode') ? true : isView ? true : false}
                    // disabled={isView ? true : false}
                    size="small"
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    inputProps={{ maxLength: 20 }}
                    type="number"
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="Postal Code"
                    variant="outlined"
                  /></Toolbar>}
              {(customer === "Government" || customer === "Fleet") &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    required={isIntBuisness ? false : customer !== "Government" && selectedDelivery === "DLV" || customer !== "Workshop" && customer !== "Government" ? true : false}
                    id="tradeLicenseNumber"
                    error={selectTradeError}
                    // disabled={isView ? true : isFieldsDisabled() ? true : false}
                    disabled={isView ? true : false}
                    size="small"
                    value={selectTradeId}
                    // helperText={invalidSelectEmirates && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_LIMIT_TEXT}</span>}
                    select
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="Select Trade license number"
                    onChange={(e: any) => onChangeTradeLicenseNumber(e)}
                    variant="outlined" >
                    <MenuItem key={"Select Option"} value={""}>{"Select Option"} </MenuItem>
                    {tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && tradeRegionData.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.tradeLicenseNo} </MenuItem>))}
                  </TextField></Toolbar>}
              {(customer !== "Government" && customer !== "Fleet") &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    required={isIntBuisness ? false : customer !== "Government" && selectedDelivery === "DLV" || customer !== "Workshop" && customer !== "Government" ? true : false}
                    id="tradeLicenseNumber"
                    error={selectTradeError}
                    // disabled={setdisable}
                    disabled={isView ? true : false}
                    size="small"
                    value={selectTradeId}
                    select
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="Select Trade license number"
                    onChange={(e: any) => onChangeTradeLicenseNumber(e)}
                    variant="outlined" >
                    <MenuItem key={"Select Option"} value={""}>{"Select Option"} </MenuItem>
                    {tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && tradeRegionData.data.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.tradeLicenseNo} </MenuItem>))}
                  </TextField></Toolbar>}
              <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                <TextField
                  id="longitude"
                  onChange={(e: any) => onChangeLongitude(e)}
                  error={isIntBuisness || (selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE') ? false : longitudeError}
                  // disabled={setdisable}
                  disabled={isView ? true : false}
                  required={isIntBuisness || (selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE') ? false : true}
                  value={longitude}
                  size="small"
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  type="number"
                  className="col-lg-12 col-md-12 col-sm-12 pr-3 commonRoundedInputs common-input-font-size"
                  label="Longitude"
                  variant="outlined"
                />
              </Toolbar>
              {(customer.toLowerCase() === "government" || customer.toLowerCase() === "fleet") &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    id="latitude"
                    onChange={(e: any) => onChangeLattitude(e)}
                    error={lattitudeError}
                    // disabled={setdisable}
                    disabled={isView ? true : false}
                    required={isIntBuisness ? false : true}
                    value={latitude}
                    size="small"
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    type="number"
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="Latitude"
                    variant="outlined"
                  /></Toolbar>}
              {customer.toLowerCase() !== "government" && customer.toLowerCase() !== "fleet" &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField id="latitude"
                    onChange={(e: any) => onChangeLattitude(e)}
                    error={isIntBuisness || (selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE') ? false : lattitudeError}
                    // disabled={setdisable}
                    disabled={isView ? true : false}
                    required={isIntBuisness || (selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE') ? false : true}
                    value={latitude}
                    size="small"
                    onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                    type="number"
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="Latitude"
                    variant="outlined"
                  /></Toolbar>}
              {editedAddress && editedAddress.dafault === false && editedAddress.shiftToPartyCustomerNumber &&
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    id="Ship to party Customer No"
                    disabled
                    value={customerNumber || editedAddress.shiftToPartyCustomerNumber}
                    size="small"
                    type="number"
                    className="col-lg-12 col-md-12 col-sm-12 commonRoundedInputs common-input-font-size"
                    label="Ship to party Customer No"
                    variant="outlined"
                  />
                </Toolbar>
              }
              {editedAddress && editedAddress.dafault === true ?
                <Toolbar className="col-sm-6 col-md-6 col-lg-6 mb-2">
                  <TextField
                    id="emailid"
                    onChange={(e: any) => handleEmailId(e)}
                    error={emailIdError || invalidEmailId}
                    disabled={editedAddress.shiftToPartyCustomerNumber ? true : isPullFromSAP && email && disabledFields.includes('emailId') ? true : !isEdited && (editedAddress && editedAddress.dafault) || !(editedAddress && editedAddress.dafault) ? false : true}
                    required
                    value={email}
                    size="small"
                    type="text"
                    className="col-sm-12 col-md-12 col-lg-12 pr-3 commonRoundedInputs common-input-font-size"
                    label="Email Id"
                    variant="outlined"
                    helperText={invalidEmailId && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                  />
                </Toolbar>
                : ""}
            </div>
            {!firstAddressCheck && (
              <div className="mb-2 border-top">
                <div className="row col-lg-12  mt-3">
                  <h6 className="page-sub-title ml-4">Contact Person Name</h6>
                </div>
                <div className="row col-md-12 m-2">
                  {uniqueContactPersonNames.map((name, index) => (
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3 ml-n2">
                      <TextField
                        id="Contact Person Name"
                        value={name}
                        disabled={isView}
                        onChange={(e) => handlePersonNameChange(index, e.target.value)}
                        size="small"
                        className="col-12 pr-2 commonRoundedInputs common-input-font-size"
                        label="Contact Person Name"
                        variant="outlined"
                      />
                    </div>
                  ))}
                </div>
                <div className="row col-md-12 m-0">
                  <div className={isView || isFieldsDisabled() ? 'row-space-start disabledCustomclass' : 'row-space-start'}>
                    <Button
                      disabled={isView || isFieldsDisabled()}
                      className="text-info"
                      startIcon={<AddIcon className="font-size-18" />}
                      onClick={() => addContactPerson()}
                    ><span className="onshiftoparty-font">Add Name
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className="mb-2 border-top">
              <div className="row col-lg-12  mt-3">
                <h6 className="page-sub-title ml-4">Authorized Person to Accept the Deliveries</h6>
              </div>
              <div className="row col-lg-12">
                <h6 className="info-sub-header-document pb-3 ml-4">Supported File Extension : .jpg, .pdf, .jpeg, .png, upto 10 MB can be uploaded.</h6>
              </div>
              {
                authorizedPersons && authorizedPersons.length > 0 && authorizedPersons.map((item: any, index: any) => {
                  return (
                    <div className="row col-md-12 m-0" key={item.documentId}>
                      <div className="col-md-6 col-lg-6">
                        <TextField
                          required={isIntBuisness ? false : selectedDelivery === "DLV" ? true : false}
                          // inputMode='numeric'
                          disabled={isView ? true : false}
                          value={item.name}
                          error={isIntBuisness ? false : customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? false : !item.isValidName}
                          onChange={(e: any) => { onChangeAuthorizedName(e, index) }}
                          helperText={customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? '' : !item.isValidName && item.ValidationErrorMessage && <span style={{ color: 'red' }}>{item.ValidationErrorMessage}</span>}
                          size="small" className="col-sm-12 my-2 pr-3 commonRoundedInputs"
                          label="Name" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <TextField
                          required={isIntBuisness ? false : selectedDelivery === "DLV" ? true : false}
                          disabled={isView ? true : false}
                          type="number"
                          value={parseInt(item.contactNumber)}
                          error={isIntBuisness ? false : customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? false : !item.isValidContactNumber}
                          onChange={(e: any) => { onChangeContactNumber(e, index) }}
                          helperText={!item.isValidContactNumber && item.contactNumber && item.contactNumber.length > 0 && <span style={{ color: 'red' }}>{item.ValidationErrorMessage}</span>}
                          size="small" className="col-sm-12 my-2 pr-3 commonRoundedInputs"
                          label="Contact Number" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <TextField
                          required={isIntBuisness ? false : (customer === "Government" || customer === "Fleet") || selectedDelivery === "DLV" ? true : false}
                          disabled={isView ? true : false}
                          value={item.emailId}
                          error={isIntBuisness ? false : customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? false : customer !== "Government" ? !item.isValidEmail && item.emailId || item.emailError ? true : false : !item.isValidEmail ? true : false}
                          onChange={(e: any) => { onChangeEmailId(e, index) }}
                          helperText={!item.isValidEmail && item.emailId && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                          size="small" className="col-sm-12 my-2 pr-3 commonRoundedInputs"
                          label="Email ID" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-3 col-lg-3 pr-3 pt-1">
                        {item.document ?
                          <div className="file-name">
                            <span className="cursor-pointer" onClick={() => {
                              window.open(item.document.url)
                            }}>{getDocumentName(item)}</span>
                            <IconButton className="p-1 mt-2" aria-label="delete" disabled={isView ? true : false}
                              onClick={(e: any) => handleInputChange({ target: null }, "document", index)}>
                              <CancelIcon className="file-close-color" fontSize="small" />
                            </IconButton>
                          </div>
                          : <div>
                            <Button
                              component="label"
                              variant="contained"
                              disabled={!isDisabled(index) || isView ? true : false}
                              color="secondary"
                              className={`col-md-12 rounded-button-dealer my-2 mt-2 px-4 ${!isDisabled(index) ? classes.oppacityLess : ""}`}
                            // className="col-md-12 rounded-button-dealer my-2 mt-2  px-4"
                            > Upload Emirates ID
                              <input type="file" hidden
                                onClick={(e: any) => {
                                  const { target = {} } = e || {}
                                  target.value = ""
                                  showErrorMessage('');
                                  handleopenCloseErrorSnackbar(false);
                                }}
                                onChange={(e) =>
                                  (e.target && e.target.files) ?
                                    onClickBrowseFile('document', e.target.files[0], setAuthorizedPersons, index) : setAuthorizedPersons(item)
                                }
                              />
                            </Button>
                            {isIntBuisness || isOutsideFreezone ? '' : customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? '' : (<>
                              {!item.isDocumentUploaded && isSubmit ?
                                <div style={{ color: 'red' }}>Please upload the Emirates Id (jpg, pdf or png)</div> : ""}
                            </>)}
                          </div>
                        }
                      </div>
                      <div className="col-md-3 col-lg-3">
                        <KeyboardDatePicker
                          error={isIntBuisness ? false : customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? false : item.dateError || !item.isValidExpiryDate && isSubmit}
                          helperText={isIntBuisness ? '' : customer.toLowerCase() === "workshop" && selectedDelivery !== "DLV" ? '' : item.dateError || !item.isValidExpiryDate && isSubmit && <span style={{ color: 'red' }}>Please mention the expiry date</span>}
                          className="col-sm-11 my-2 pr-3 commonRoundedInputs cal-icon"
                          autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                          format="dd/MM/yyyy" margin="normal" label="Expiry Date"
                          views={["year", "month", "date"]}
                          required={isIntBuisness ? false : (customer === "Government" || customer === "Fleet") || selectedDelivery === "DLV" ? true : false}
                          disablePast
                          onError={(e, v) => datePickerValid(e, 'document', index)}
                          disabled={isView ? true : false}
                          openTo="year"
                          InputProps={{ readOnly: true }}
                          value={authorizedPersons[index].expiryDate}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                          onChange={(date: Date | null) => {
                            if (date) {
                              let currentDate = date;
                              let currentTime = new Date();
                              date.setHours(currentTime.getHours());
                              date.setMinutes(currentTime.getMinutes());
                              date.setSeconds(currentTime.getSeconds());
                              setVisaExpiryDate(date);
                              let authorizedPersonsRow = [...authorizedPersons];
                              authorizedPersonsRow[index].expiryDate = date ? date : currentDate;
                              authorizedPersons[index].isValidExpiryDate = true;
                              setAuthorizedPersons(authorizedPersonsRow)
                            }
                          }}
                        />
                        {/* {!item.isValidExpiryDate ?
                          <span style={{ color: 'red' }}>Please mention the expiry date"</span>: ""} */}
                        {authorizedPersons && authorizedPersons.length === 1 ? '' : (
                          <IconButton className="col-sm-1 mt-3" size="small"
                            onClick={() => {
                              // deleteAuthorizedPersonsRows(item, index);
                              setCurrentEmiratesIndex(index);
                              handleopenClose(true);
                            }}>
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  )
                })
              }
              <div className="row col-md-12 m-0">
                <div className={isView || isFieldsDisabled() ? 'row-space-start disabledCustomclass' : 'row-space-start'}>
                  <Button
                    disabled={isFieldsDisabled()}
                    className="text-info"
                    startIcon={<AddIcon className="font-size-18" />}
                    onClick={() => addAuthorizedPersonsRows()}
                  ><span className="onshiftoparty-font">Add More
                    </span>
                  </Button>
                </div>
              </div>
            </div>
            {!isIntBuisness && customer !== "Government" && customerTierType && <div className="row col-lg-12 col-md-12 m-0 p-0 border-top border-bottom">
              <div className="col-md-6 col-lg-6 mb-2 mt-3 pl-4 ml-1">
                <h6 className="document-content">Picture Of Delivery Location {selectedDelivery !== "SELF-PIC" && customer !== "Fleet" ? <span style={{ color: 'grey' }}>*</span> : ""} </h6>
                <small className="document-sub-content pb-3">Photos(.jpg, .png, .pdf)</small>
              </div>
              <div className="col-md-3 col-lg-3 mb-2 mt-3 mr-3">
                {deliveryLocationRegFile !== null ?
                  <span className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(deliveryLocationRegFile.url)
                    }}>
                      {deliveryLocationRegFile.name ? deliveryLocationRegFile.name : deliveryLocationRegFile.selectedFileName}
                    </span>
                    <IconButton disabled={isView ? true : false} className={isFieldsDisabled() ? 'p-1 disabledCustomclass' : 'p-1'} aria-label="delete" onClick={(e) => {
                      // deleteAddressDoc('pictureOfDeliveryLocation', deliveryLocationRegFile);
                      // setDeliveryLocationRegFile(null);
                      setDocumentType('pictureOfDeliveryLocation');
                      handleopenCloseDocument(true);
                    }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </span>
                  : <div> <Button
                    variant="contained"
                    disabled={isView ? true : false}
                    component="label"
                    color="secondary"
                    className="col-md-11 rounded-button-dealer my-2 mt-2  px-4"
                  >Browse File
                    <input type="file" hidden
                      onChange={(e) =>
                        (e.target && e.target.files) ?
                          onClickBrowseFile('pictureOfDeliveryLocation', e.target.files[0], setDeliveryLocationRegFile) : setDeliveryLocationRegFile(null)
                      }
                    />
                  </Button>
                    {(!deliveryLocationRegFile && isSubmit && selectedDelivery !== "SELF-PIC") && customer !== "Fleet" ?
                      <span style={{ color: 'red' }}>Required </span> : ""}
                    {invalidDoucmentupload ?
                      <span style={{ color: 'red' }}>Only .png, .pdf, .jpg files formats are allowed</span> : ""}
                  </div>
                }
              </div>
            </div>}
            {!isIntBuisness && customer !== "Government" && customerTierType && <div className="row col-lg-12 col-md-12 m-0 p-0 border-top border-bottom">
              <div className="col-md-6 col-lg-6 mb-2 mt-3 pl-4 ml-1">
                <h6 className="document-content">Photo Of Premises  {selectedDelivery !== "SELF-PIC" && customer !== "Fleet" ? <span style={{ color: 'grey' }}>*</span> : ""}</h6>
                <small className="document-sub-content pb-3">Photos(.jpg, .pdf, .png)</small>
              </div>
              <div className="col-md-3 col-lg-3 mb-2 mt-3 mr-3">
                {permises !== null ?
                  <div className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(permises.url)
                    }}>{permises.name ? permises.name : permises.selectedFileName}</span>
                    <IconButton disabled={isView ? true : false} className={isFieldsDisabled() ? 'p-1 disabledCustomclass' : 'p-1'} aria-label="delete" onClick={(e) => {
                      // deleteAddressDoc('photoOfPremises', permises);
                      // setPermises(null);
                      setDocumentType('photoOfPremises');
                      handleopenCloseDocument(true);
                    }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </div>
                  :
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      disabled={isView ? true : false}
                      color="secondary"
                      className="col-md-11 rounded-button-dealer my-2 mt-2  px-4"
                    > Browse File
                      <input type="file" hidden
                        onChange={(e) =>
                          (e.target && e.target.files) ?
                            onClickBrowseFile('photoOfPremises', e.target.files[0], setPermises) : setPermises(null)
                        }
                      />
                    </Button>
                    {(!permises && isSubmit) && selectedDelivery !== "SELF-PIC" && customer !== "Fleet" ?
                      <span style={{ color: 'red' }}>Required{' '}</span> : ""}
                    {invalidPhotopremises ?
                      <span style={{ color: 'red' }}>Only .png, .pdf, .jpg files formats are allowed</span> : ""}
                  </div>
                }
              </div>
            </div>}
            <div className="row col-lg-12 col-md-12 m-0 p-0 border-top border-bottom">
              <div className="col-md-6 col-lg-6 mb-2 mt-3 pl-4 ml-1">
                <h6 className="document-content">Upload Authority letter{isIntBuisness || (selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE') ? "" : !defaultAddress && firstAddressCheck === true && editedAddress && editedAddress.dafault || selectedDelivery === "DLV" && editedAddress && editedAddress.dafault && !defaultAddress ? <span style={{ color: 'grey' }}>*</span> : ""}</h6>
                <small className="document-sub-content pb-3">Upload (.pdf, .doc, .docx, .png, .jpeg, .jpg)</small>
                <br></br>
                <a href="./LetterOfAuthorityToReceivePartsFromAl-FuttaimAutomotive.docx" download="Letter Of Authority To Receive Parts From Al-Futtaim Automotive.docx">
                  <IconButton size="small" className={isFieldsDisabled() ? 'disabledCustomclass' : ''}>
                    <AssignmentReturnedIcon className="download-icon mr-1" />
                    <span className="download-offer mr-2">
                      Download Template
                    </span>
                  </IconButton>
                </a>
              </div>
              <div className="col-md-3 col-lg-3 mb-2 mt-3 mr-3">
                {authority !== null ?
                  <div className="file-name">
                    <span className="cursor-pointer" onClick={() => {
                      window.open(authority.url)
                    }}>{authority.name ? authority.name : authority.selectedFileName}</span>
                    <IconButton disabled={isView ? true : false} className={isFieldsDisabled() ? 'p-1 disabledCustomclass' : 'p-1'} aria-label="delete" onClick={(e) => {
                      // deleteAddressDoc('authorityLetter', authority);
                      // setAuthority(null);
                      setDocumentType('authorityLetter');
                      handleopenCloseDocument(true);
                    }}>
                      <CancelIcon className="file-close-color" fontSize="small" />
                    </IconButton>
                  </div>
                  :
                  <div>
                    <Button
                      variant="contained"
                      disabled={isView ? true : false}
                      component="label"
                      color="secondary"
                      className="col-md-11 rounded-button-dealer my-2 mt-2  px-4"
                    > Browse File
                      <input type="file" hidden
                        onChange={(e) =>
                          (e.target && e.target.files) ?
                            onClickBrowseFile('authorityLetter', e.target.files[0], setAuthority) : setAuthority(null)
                        }
                      />
                    </Button>
                    {isIntBuisness || (selectedDelivery === "SELF-PIC" && sessionStorage.getItem('userType') === 'FSE') ? "" : (!authority && isSubmit) && selectedDelivery !== "SELF-PIC" && editedAddress && editedAddress.dafault || firstAddressCheck === true && editedAddress && editedAddress.dafault && isSubmit ?
                      <span style={{ color: 'red' }}>Required{' '}</span> : ""}
                  </div>
                }
              </div>
            </div>
          </>
        </div>
      )
      }
      <DialogActions className="mt-3 mb-2 justify-content-end">
        {((props.status === 'Incomplete from KAM' && isView === false) || (props.status === 'Incomplete from FSE' && isView === false) || (props.status === 'Awaiting feedback from FSE' && isView === false)) && (
          <Button variant="contained"
            // disabled={!isValid}
            color="secondary" className={`createFavourite ${classes.createButton}`}
            onClick={() => {
              if (isExistingAddress && customerNumber && !editedAddress.shiftToPartyCustomerNumber) {
                if (isSubmitClicked) {
                  createNewAddress()
                } else {
                  showErrorMessage('Please click on submit button to validate the ship to party no');
                  handleopenCloseErrorSnackbar(true);
                }
              } else {
                createNewAddress()
              }
            }}
          >{((isEdited && props.status === 'Incomplete from KAM') || (isEdited && props.status === 'Incomplete from FSE') || (isEdited && props.status === 'Awaiting feedback from FSE')) ? 'Update' : 'Save'}
          </Button>
        )}
        <Button variant="contained" className={classes.closeButton}
          onClick={() => setOpenDialog()}
        >Cancel</Button>
      </DialogActions>
      <DeleteDocumentDialog
        open={openDeleteDialogDocument}
        text="Are you sure you want to delete this document ?"
        handleopenCloseDocument={(data: any) => {
          if (data === 'delete')
            deleteAddressDoc();
          if (!data) handleopenCloseDocument(false);
        }} />
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete details ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteAuthorizedPersonsRows();
          if (!data) handleopenClose(false);
        }} />
    </Fragment >
  );
})