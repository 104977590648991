/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import GroupIcon from '@material-ui/icons/Group';
import Snackbar from './../../common/components/Snackbar';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import TablePagination from '@material-ui/core/TablePagination';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { ButtonGroup, Tab, Tabs } from "@material-ui/core";
import defaultLogo from '../../assets/default-user-logo.png';
import { getNewBrand } from "../../common/components/util";
import { get } from "lodash";
import { userTypes } from "../../constants/userTypes";
import { createTheme, makeStyles, ThemeProvider } from '@material-ui/core/styles';
import Visibility from "@material-ui/icons/Visibility";
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Paper from "@material-ui/core/Paper";
import { getallCreditRequestKam, getCreditRequestDetails } from "../../store/actions/creditRequest.actions";
import { getAllCreditRequestStatus } from "../../store/actions/dealer.action";

const theme = createTheme({
    palette: {
        primary: {
            main: '#1A9FE0'
        },
        secondary: {
            main: '#FF0000',
        }
    },
});

const useStyles = makeStyles(() => ({

    subtitle: {
        display: "flex"
    },
    grid: {
        marginLeft: 234
    }
}));

function a11yProps(index: any) {
    return {
        id: `wrapped-tab-${index}`,
        'aria-controls': `wrapped-tabpanel-${index}`,
    };
}

const crediRequesttList = [
    {
        name: "Abc",
        tradeLicenseNumber: "123456789",
        telephoneNumber: "9874521589",
        email: "abc@gmail.com",
        status: "Active",
        brand: "Toyota",
        type: "New",
        kamName: "Dealer"
    }
];

const CreditRequest: React.FC = () => {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const [page, setPage] = React.useState(0);

    const [searchValue, setSearchValue] = React.useState<string>("");
    const [filterValue, setFilterValue] = React.useState("showAll");
    const [allCreditList, setAllCreditList] = React.useState<any>(null);
    const [totalCount, setTotalCount] = React.useState<any>(0);
    const [creditStatus, setCreditStatus] = React.useState<any>();
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [loading, setLoading] = React.useState<any>(false);
    const history = useHistory();

    useEffect(() => {
        if (!allCreditList && !loading) getCreditRequest();
    }, []);


    const getCreditRequest = async () => {
        setLoading(true);
        const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
        let status: any = await dispatch(getAllCreditRequestStatus('kam'));
        if (status) {
            setLoading(false);
            if (status.message.status == "S") {
                if (status.data && status.data.length > 0) {
                    setSuccessMessage("Fetched succesfully");
                    let temp = status.data
                    temp.push({
                        code: "showAll",
                        title: "Show All"
                    })
                    temp = temp.filter((element: any) => {
                        return element.code !== "SUB" && element.code !== "AF"
                    })
                    setCreditStatus(temp);
                }
            }
            else {
                setSuccessMessage('something went wrong.');
                setOpenErrorSnackbar(true);
            }
        }
        setLoading(true);
        let crStatus: any = await dispatch(getallCreditRequestKam("", "", webApplicationLoginId, page));
        if (status) {
            setLoading(false);
            if (crStatus && crStatus.data && crStatus.data.requestList && crStatus.data.requestList.length > 0) {
                setTotalCount(crStatus.data.totaCount)
                setAllCreditList(JSON.parse(JSON.stringify(crStatus.data.requestList)));
            }
        }
    }

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const searchDealerList = async (value: any, isSearch: any) => {
        setLoading(true);
        let temp = value ? value : "";
        setSearchValue(temp);
        const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
        let searchValueTemp: any = searchValue && !isSearch ? searchValue : "";
        let searchFilterTemp: any = filterValue !== 'showAll' ? filterValue : "";
        setLoading(true);
        let responseData: any = await dispatch(getallCreditRequestKam(searchFilterTemp, temp, webApplicationLoginId, page));
        if (responseData) {
            if (responseData) {
                if (responseData.message && responseData.message.status == "S") {
                    setAllCreditList(JSON.parse(JSON.stringify(responseData.data.requestList)));
                }
                setLoading(false);
            }
        }
        setLoading(false);
    }

    const filterDealersByKAM = async (e: any) => {
        let searchFilter = e.target.value ? e.target.value : "";
        setLoading(true);
        if (searchFilter == "showAll") {
            searchFilter = "";
        }
        const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
        let searchValueTemp = searchValue ? searchValue : "";
        let crStatus: any = await dispatch(getallCreditRequestKam(searchFilter, searchValueTemp, webApplicationLoginId, 0));
        if (crStatus) {
            setLoading(false);
            if (crStatus.message && crStatus.message.status == "S") {
                setPage(0);
                setTotalCount(crStatus.data.totaCount)
                // setAllCreditList(JSON.parse(JSON.stringify(crStatus.data.requestList)));
                setAllCreditList([]);

                setAllCreditList(crStatus.data.requestList);

            }
        }
    }
    const handleChangePage = async (newPage: number) => {
        const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
        if (searchValue.trim() === "" && filterValue.trim() === "") {
            setLoading(true);
            let responseData: any = await dispatch(getallCreditRequestKam("", "", webApplicationLoginId, newPage));
            if (responseData) {
                if (responseData.message && responseData.message.status == "S") {
                    setAllCreditList(JSON.parse(JSON.stringify(responseData.data.requestList)));
                }
                setLoading(false);
            }
        }
        else if (searchValue.trim() === "" && filterValue.trim() !== "") {
            setLoading(true);
            let filtervalueTemp = "";
            if (filterValue == "showAll") {
                filtervalueTemp = "";
            }
            else {
                filtervalueTemp = filterValue;
            }
            let responseData: any = await dispatch(getallCreditRequestKam(filtervalueTemp.trim(), "", webApplicationLoginId, newPage));
            if (responseData) {
                if (responseData.message && responseData.message.status == "S") {
                    setAllCreditList(JSON.parse(JSON.stringify(responseData.data.requestList)));
                }
                setLoading(false);
            }
        }
        else {
            setLoading(true);
            let responseData: any = await dispatch(getallCreditRequestKam("", searchValue.trim(), webApplicationLoginId, newPage));
            if (responseData) {
                if (responseData.message && responseData.message.status == "S") {
                    setAllCreditList(JSON.parse(JSON.stringify(responseData.data.requestList)));
                }
                setLoading(false);
            }
        }
        setLoading(false);
        setPage(newPage);
    };

    return (
        <Fragment>
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Credit Request</h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>
            <div className="d-flex align-items-center">
                List of all new credit request
                <div className="ml-auto">
                    {<TextField
                        id="kamList"
                        select
                        label="Filter By Status"
                        variant="outlined"
                        className="rounded-input-dealer mw-200p mr-2 mb-2"
                        size="small"
                        value={filterValue}
                        onChange={(e: any) => {
                            filterDealersByKAM(e);
                            setFilterValue(e.target.value)
                        }}
                    >
                        {creditStatus && creditStatus && creditStatus.map((item: any, index: any) => (
                            <MenuItem key={item.code} value={item.code}>
                                {item.title}
                            </MenuItem>
                        ))}
                    </TextField>}
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search</InputLabel>
                        <OutlinedInput
                            id="dealer-search"
                            value={searchValue}
                            onKeyPress={(ev) => {
                                // if (ev.key === 'Enter') {
                                //     searchDealerList(searchValue, true);
                                // }
                            }}
                            onChange={(e: any) => {
                                setSearchValue(e.target.value);
                                if (e.target.value.trim() === "") {
                                    setSearchValue("");
                                    searchDealerList(e.target.value, false);
                                }
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="Search"
                                        edge="end"
                                        disabled={searchValue === "" || searchValue === null || !searchValue}
                                        onClick={(e: any) => {
                                            searchDealerList(searchValue, true);
                                        }}
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={50} margin="dense" className="rounded-input-dealer mw-200p mr-2 mb-2" />
                    </FormControl>
                </div>
            </div>

            {loading ? <FullscreenLoader /> : (

                <Grid item xs={12} className="mt-2 cardCommon">
                    <TableContainer component={Paper} className="height-500">
                        <Table aria-label="dealer table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <div className="dealer-table-title">Customer</div>
                                        <div className="dealer-table-title-desc">Logo, Name &amp; Trade No</div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="dealer-table-title">Contact</div>
                                        <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="dealer-table-title">Brand</div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="dealer-table-title">Type</div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="dealer-table-title">Request By</div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="dealer-table-title">Status</div>
                                    </TableCell>
                                    <TableCell >
                                        <div className="dealer-table-title">Action</div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allCreditList && allCreditList.map((item: any) => {
                                    return <TableRow key={item.companyName}>
                                        <TableCell component="th" scope="row" className="mw-200p">
                                            <Grid container>
                                                <div className="d-flex">
                                                    <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                                                        <div className="dealer-table-content dealer-company-name"
                                                            onClick={async () => {
                                                            }}>{item.companyName}</div>
                                                        <div className="dealer-table-subcontent">TL. {item.treadLicenseNumber}</div>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </TableCell>
                                        <TableCell className="mw-150p">
                                            <div className="dealer-table-content">{item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                                            <div className="dealer-table-subcontent">{item.contactDetails.email ? item.contactDetails.email : "-"}</div>
                                        </TableCell>
                                        <TableCell className="mw-150p">
                                            <div className="dealer-table-content">{item.brand ? getNewBrand(item.brand) : "-"}</div>
                                        </TableCell>
                                        <TableCell className="mw-150p">
                                            <div className="dealer-table-content">{item.requestType ? item.requestType : "-"}</div>
                                        </TableCell>
                                        <TableCell className="mw-150p">
                                            <div className="dealer-table-content">{item.kamName ? item.kamName : "-"}</div>
                                        </TableCell>
                                        <TableCell className="mw-150p">
                                            {item.status && item.status === "Rejected" ?
                                                <div className="dealer-table-content" style={{ color: "red" }}>{item.status ? item.status : "-"}</div>
                                                : item.status && item.status === "Approved" ? <div className="dealer-table-content" style={{ color: "limegreen" }}>{item.status ? item.status : "-"}</div>
                                                    : item.status && item.status === "Submitted" ? <div className="dealer-table-content" style={{ color: "darkorange" }}>{item.status ? item.status : "-"}</div>
                                                        : <div className="dealer-table-content">{item.status ? item.status : "-"}</div>
                                            }
                                        </TableCell>
                                        <TableCell className="mw-230p">
                                            <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                                <Button className="text-info btn-link mr-3"
                                                    startIcon={<AttachMoneyIcon />}
                                                    onClick={() => {
                                                        dispatch(getCreditRequestDetails(item.id, item.sapCustomerId, 'kam'));
                                                        history.push('/kamcreditdetails');
                                                    }}>
                                                    <span className="ml-n2 cursor-pointer">{item.status == "Rejected" || item.status == "Approved" ? "View Application" : item.status == "Credit Team Review" ? "View Credit Request" : "Credit Request"}
                                                    </span></Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                        {allCreditList && allCreditList.length === 0 && (
                            <div className="my-5 mx-auto text-center">No dealer found</div>
                        )}
                        {<div className="col-12 d-flex justify-content-end">
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={totalCount}
                                rowsPerPage={10}
                                page={page}
                                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
                            />
                        </div>}
                    </TableContainer>
                </Grid>
            )}
            <Snackbar
                open={openSnackbar}
                handleopenClose={() => {
                    handleopenCloseSnackbar(false)
                }} text={successMessage} />
            <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseErrorSnackbar(false)
                }} text={successMessage} />
        </Fragment>
    );
};

export default CreditRequest;
