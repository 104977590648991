import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import tempKamLogo from "./afg.jpeg"
import { Markup } from 'interweave';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);



const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgb(108 197 229 / 14%);',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);



const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);


const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  bodyText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

export default function TermsAndConditionsDialog(props: any) {

  const classes = useStyles();
  const { termsAndConditions }: any = useSelector((state: any) => state.termsAndConditions);
  const termsAndConditionsData = get(termsAndConditions, 'data', []);
  const [expanded, setExpanded] = React.useState<string | false>();
  const [isSelected, setSelected] = React.useState<any>([])
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  React.useEffect(() => {
    let data: any = []
    termsAndConditionsData && termsAndConditionsData.forEach((ele: any) => {
      data.push(true)
    })
    setSelected(data)
  }, [termsAndConditionsData])

  React.useEffect(() => {
    setExpanded(false);
  }, []);

  const handleClose = () => {
    let data: any = []
    termsAndConditionsData && termsAndConditionsData.forEach((ele: any) => {
      data.push(true)
    })
    setSelected(data)
  }

  return (
    <div className="float-left">
      {termsAndConditionsData && termsAndConditionsData.length > 0 &&
        <Dialog
          open={props.open ? true : false}
          onClose={() => { props.handleopenClose(false); setExpanded(false); }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="md"
        >
          <DialogContent>
            <Grid container>
              <Grid item xs={1}>
                <img src={tempKamLogo} alt="logo" width="70px" height="70px" />
              </Grid>
              <Grid item xs={11}>
                <div>


                  <DialogContentText id="alert-dialog-description">
                    {customerTierType === 'Workshop' ? (<>
                      {termsAndConditionsData && termsAndConditionsData.map((data: any, i: any) => (
                        <Accordion square expanded={expanded === data.name && isSelected[i] === false} onChange={handleChange(data.name)}>
                          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                            // expandIcon={<ExpandMoreIcon />}
                            onClick={() => {
                              let data: any = [...isSelected]
                              let dataActual: any = []
                              data.forEach((ele: any, i2: any) => {
                                if (i === i2) {
                                  data[i2] = !data[i]
                                } else if (i !== i2) {
                                  data[i2] = true
                                }
                              })
                              setSelected(data)
                            }}
                          >
                            <span className="" style={{ fontSize: "18px" }}>{isSelected[i] ? "+" : "-"}</span>  <h6 className="ml-2 font-weight-600"> {data.title}</h6>
                          </AccordionSummary>
                          <AccordionDetails>
                            <div className={`mt-3 ${classes.bodyText}`}>
                              <Markup content={data.Article_Body} />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </>) : (
                      <>
                        {termsAndConditionsData && termsAndConditionsData.map((data: any, i: any) => (
                          <>
                            {data.title !== 'Credit Card Payments' && (
                              <Accordion square expanded={expanded === data.name && isSelected[i] === false} onChange={handleChange(data.name)}>
                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                  // expandIcon={<ExpandMoreIcon />}
                                  onClick={() => {
                                    let data: any = [...isSelected]
                                    let dataActual: any = []
                                    data.forEach((ele: any, i2: any) => {
                                      if (i === i2) {
                                        data[i2] = !data[i]
                                      } else if (i !== i2) {
                                        data[i2] = true
                                      }
                                    })
                                    setSelected(data)
                                  }}
                                >
                                  <span className="" style={{ fontSize: "18px" }}>{isSelected[i] ? "+" : "-"}</span>  <h6 className="ml-2 font-weight-600"> {data.title}</h6>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <div className={`mt-3 ${classes.bodyText}`}>
                                    <Markup content={data.Article_Body} />
                                  </div>
                                </AccordionDetails>
                              </Accordion>

                            )}
                          </>
                        ))}
                      </>
                    )}
                  </DialogContentText>
                </div>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button className={`mr-4 ${classes.cancel}`} onClick={() => { props.handleopenClose(false); setExpanded(false); handleClose() }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div >
  );
}
