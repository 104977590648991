import { PROFILE } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { profileservice } from "../../services/profile.service";

export const profileActions = {
    getProfile,
    updateProfile,
    isSellingPrice,
    supportPostAPI,
    getDocumentExpiryStatus,
};

export function getProfile(id: any, userType: string) {
    return async (dispatch: any) => {
        // dispatch(apiStart(PROFILE.GET_PROFILE_FETCHING));
        try {
            const response = await profileservice.getProfile(id, userType);
            dispatch(apiSuccess(PROFILE.GET_PROFILE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.GET_PROFILE_ERROR, error));
            return error;
        }
    };
}

export function getProfilePlacedSummary(id: any, userType: string) {
    return async (dispatch: any) => {
        // dispatch(apiStart(PROFILE.GET_PROFILE_FETCHING));
        try {
            const response = await profileservice.getProfilePlacedSummary(id, userType);
            dispatch(apiSuccess(PROFILE.GET_PROFILE_PLACED_PROFILE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.GET_PROFILE_PLACED_PROFILE_ERROR, error));
            return error;
        }
    };
}

export function updateProfile(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(PROFILE.PUT_PROFILE_FETCHING));
        try {
            const response = await profileservice.updateProfile(data);
            dispatch(apiSuccess(PROFILE.PUT_PROFILE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.PUT_PROFILE_ERROR, error));
            return error;
        }
    };
}

export function supportPostAPI(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(PROFILE.POST_SUPPORT_FETCHING));
        try {
            const response = await profileservice.supportPostAPI(data);
            dispatch(apiSuccess(PROFILE.POST_SUPPORT_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.POST_SUPPORT_ERROR, error));
            return error;
        }
    };
}

export function isSellingPrice(data: any) {
    return async (dispatch: any) => {
        dispatch(apiSuccess(PROFILE.IS_SELLING_PRICE_TOGGLE_BUTTON, data));
        return data || {};
    };
}

export function getDocumentExpiryStatus(sapCustomerID: any, documentList: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(PROFILE.GET_DOCUMENT_EXPIRY_FETCHING));
        try {
            const response = await profileservice.getDocumentExpiryStatus(sapCustomerID, documentList);
            dispatch(apiSuccess(PROFILE.GET_DOCUMENT_EXPIRY_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(PROFILE.GET_DOCUMENT_EXPIRY_ERROR, error));
            return error;
        }
    };
}