import { tasksService } from "../../services/tasks.service";
import { TASKS } from "./actionsTypes";
import { apiError, apiStart, apiSuccess } from "./apiActions";

export const announcementsActions = {
  getTasks,
  closeTask,
  postOpenTask,
  getDocumentReport,
  setDocumentReportData,
  handleApproveReupload
};

export function getTasks(page: number) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.GET_TASKS_FETCHING));
    try {
      // const response = AnnouncementList.Offers.OffersList;
      const response = await tasksService.getTasks(page);
      dispatch(apiSuccess(TASKS.GET_TASKS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(TASKS.GET_TASKS_ERROR, error));
      return error;
    }
  };
}

export function getDocumentReport(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.GET_DOCUMENT_REPORT_FETCHING));
    try {
      // const response = AnnouncementList.Offers.OffersList;
      const response = await tasksService.getDocumentReport(data);
      dispatch(apiSuccess(TASKS.GET_DOCUMENT_REPORT_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(TASKS.GET_DOCUMENT_REPORT_ERROR, error));
      return error;
    }
  };
}

export function setDocumentReportData(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.SET_DOCUMENT_REPORT, data));
  }
}

export function closeTask(id: string) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.CLOSE_TASKS_FETCHING));
    try {
      const response = await tasksService.closeTasks(id);
      dispatch(apiSuccess(TASKS.CLOSE_TASKS_SUCCESS, response));
      return response || {};
    } catch (error) {
      dispatch(apiError(TASKS.CLOSE_TASKS_ERROR, error));
      return error;
    }
  };
}

export function postOpenTask(data: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.POST_TASKS_FETCHING));
    try {
      const response = await tasksService.postOpenTask(data, page);
      dispatch(apiSuccess(TASKS.POST_TASKS_SUCCESS, response));
      return response || {};
    } catch (error) {
      dispatch(apiError(TASKS.POST_TASKS_ERROR, error));
      return error;
    }
  };
}

export function getReviewDocuments(data: any, page?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.POST_REVIEW_TASKS_FETCHING));
    try {
      const response = await tasksService.getReviewDocuments(data, page);
      dispatch(apiSuccess(TASKS.POST_REVIEW_TASKS_SUCCESS, response));
      return response || {};
    } catch (error) {
      dispatch(apiError(TASKS.POST_REVIEW_TASKS_ERROR, error));
      return error;
    }
  };
}

export function handleApproveReupload(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(TASKS.TASK_APPROVE_REUPLOAD_FETCHING));
    try {
      const response = await tasksService.handleApproveReupload(data);
      dispatch(apiSuccess(TASKS.TASK_APPROVE_REUPLOAD_SUCCESS, response));
      return response || {};
    } catch (error) {
      dispatch(apiError(TASKS.TASK_APPROVE_REUPLOAD_ERROR, error));
      return error;
    }
  };
}