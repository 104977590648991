/* eslint-disable  */
import React, { Fragment, Dispatch, useEffect } from "react";
import IconButton from '@material-ui/core/IconButton';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import NotificationIcon from "../../common/components/NotificationIcon";
import { get } from "lodash";
import XLSX from "xlsx";
import { getDeliveryDetails } from "../../store/actions/delivery.actions";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito'
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    minWidth: 100
  },
  actionButton: {
    color: '#1A9FE0',
  },
}));

const columns = [
  {
    id: 'deliveryItem',
    label: 'S. No',
    align: 'left',
  },
  {
    id: 'materialNumber',
    label: 'Part No.',
    align: 'left'
  },
  {
    id: 'shortTextForSalesOrderItem',
    label: 'Description',
    align: 'left'
  },
  {
    id: 'actualQuantityDelivered',
    label: 'Quantity',
    align: 'left'
  },
  {
    id: 'baseUnitOfMeasure',
    label: 'Unit',
    align: 'left'
  },
];

const DeliveryDetails: React.FC = () => {
  const history = useHistory()
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = React.useState(true)
  const [tableData, setTableData] = React.useState([])
  const [deliveryData, setDeliveryData] = React.useState<any>({})
  const [address, setAddress] = React.useState([]);
  const [billingAddress, setBillingAddress] = React.useState([]);
  const rowsPerPage = 10;
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const { deliveryDetails }: any = useSelector((state: any) => state.delivery)
  const { deliveryList, viewDeliverySelected }: any = useSelector((state: any) => state.delivery);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const shipToPartyFeatureAccess = get(profileDetails, 'data.data.customerInfo.shipToPartyFeatureAccess', '');


  const showDownloadOrders = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ORDER);
  const addresses: any = get(
    profileDetails,
    "data.data.customerInfo.addresses",
    []
  );


  useEffect(() => {
    const shippingAddress = addresses && addresses.length >= 1 && addresses.filter((ele: any) => {
      return (ele.dafault) && (deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber !== ele.shiftToPartyCustomerNumber)

    })
    const billingAddress = addresses && addresses.length >= 1 && addresses.filter((ele: any) => {
      return (ele.dafault)

    })
    setAddress(shippingAddress)
    setBillingAddress(billingAddress)
  }, [addresses, deliveryData])

  useEffect(() => {
    const deliveryData = get(deliveryList, 'data.data.deliveryList', {})[0];
    if (deliveryData && deliveryData.delivery) {
      dispatch(getDeliveryDetails(viewDeliverySelected && viewDeliverySelected.data && viewDeliverySelected.data.enquiryNumber, viewDeliverySelected && viewDeliverySelected.data && viewDeliverySelected.data.deliveryNo, viewDeliverySelected && viewDeliverySelected.data && viewDeliverySelected.data.make, deliveryData && deliveryData.sapCustomerId));
    }
  }, [deliveryList, viewDeliverySelected])

  useEffect(() => {
    const tableData = get(deliveryDetails, 'data.data.deliveryItem', []);
    const deliveryData = get(deliveryDetails, 'data.data', {});
    const deliveryDataList = get(deliveryList, 'data.data.deliveryList', {})[0];

    if (deliveryDataList && deliveryDataList.delivery) {
      setTableData(tableData)
      setDeliveryData(deliveryData)
    }
  }, [deliveryDetails])

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(getDeliveryDetails(deliveryData.deliveryList.enquiryNumber, deliveryData.deliveryList.delivery, sessionStorage.getItem('selectedOrderMake'), deliveryData && deliveryData.sapCustomerId, newPage));
  };

  const handleopenClose = () => {
    setOpen(false)
    return false
  }

  const exportData = () => {
    if (tableData && Array.isArray(tableData) && tableData.length > 0) {
      let filename = "deliveryItems.xlsx";
      let data: any = [];
      tableData.map((item: any) => {
        data.push({
          SNo: item.deliveryItem,
          PartNumber: item.materialNumber,
          Description: item.shortTextForSalesOrderItem,
          Quantity: item.actualQuantityDelivered,
          Unit: item.baseUnitOfMeasure
        });
        return undefined;
      });
      var ws = XLSX.utils.json_to_sheet(data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  };

  if (deliveryDetails && deliveryDetails.loading) {
    return <FullscreenLoader />
  }

  if (deliveryDetails && deliveryDetails.err && deliveryDetails.err.statusText) {
    return (
      <Snackbar
        open={open}
        type="error"
        handleopenClose={handleopenClose}
        message={deliveryDetails.err.statusText.data.message.message ? deliveryDetails.err.statusText.data.message.message : 'There was a problem fetching the delivery list'}
      />
    )
  }

  return (
    <Fragment>
      {deliveryList && deliveryList.loading ? <FullscreenLoader /> : <>
        <div className="d-flex align-items-center">
          <h2 className="page-title">
            Delivery Details
            <Breadcrumbs>
              <Typography color="textSecondary" className="breadcrumb-text-order-history">Order History</Typography>
              <Typography color="textSecondary" className="breadcrumb-text-order-history">Delivery List</Typography>
              <Typography color="textPrimary" className="breadcrumb-text-delivery-details">Delivery Details</Typography>
            </Breadcrumbs>
          </h2>
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        </div>

        <div className="row mt-1">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title">Delivery Details</h5>
              <IconButton onClick={() => { history.push(`/deliverylist`); setTableData([]); setDeliveryData({}) }} className={`ml-auto ${classes.actionButton}`}>
                <ChevronLeftIcon fontSize="small" />
                <span className="backLinkLabel">Back to List</span>
              </IconButton>
            </div>
          </div>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <div className="cardCommon d-flex font-11">
              <div className="col-lg-12 col-md-12 col-sm-12 pt-3 pb-3 pl-4 pr-4">
                <h6 className="title mb-3">Enquiry Details</h6>
                <div className="row">
                  <div className="col-3 col-md-3 col-sm-6">
                    <div className="subTitle mb-2">Delivery No:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.delivery}</span>
                    </div>
                    <div className="subTitle mb-2">Enquiry No:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.enquiryNumber}</span>
                    </div>
                    <div className="subTitle mb-2">Created On:
                      <span className="subTitleValue">{sessionStorage.getItem('selectedOrderCreatedOn')}</span>
                    </div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 pl-2">
                    <div className="subTitle mb-2">Invoice Status:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.billingStatus}</span>
                    </div>
                    <div className="subTitle mb-2">Order No:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.salesDocument}</span>
                    </div>
                    <div className="subTitle mb-2">Description:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.description ? deliveryData.deliveryList.description : "-"}</span>
                    </div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 pl-2">
                    <div className="subTitle mb-2">Shipping Plan Status:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.shippingPlanningStatus}</span>
                    </div>
                    <div className="subTitle mb-2">Shipping Receiving Point:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.shippingPointOrreceivingPoint ? deliveryData.deliveryList.shippingPointOrreceivingPoint : "-"}</span>
                    </div>
                    <div className="subTitle mb-2">Picking Status:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.pickingStatus}</span>
                    </div>
                  </div>
                  <div className="col-3 col-md-3 col-sm-3 pl-2">
                    <div className="subTitle mb-2">Overall Delivery Status:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.deliveryOverallStatus}</span>
                    </div>
                    <div className="subTitle mb-2">Goods Issue Status:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.goodsMovementStatus}</span>
                    </div>
                    <div className="subTitle mb-2">Invoice No:
                      <span className="subTitleValue">{deliveryData && deliveryData.deliveryList && deliveryData.deliveryList.billingDocument ? deliveryData.deliveryList.billingDocument : "-"}</span>
                    </div>
                  </div>
                </div>
                {/* {shipToPartyFeatureAccess && ( */}
                <>
                  <h6 className="title mb-3 mt-1">Shipping Address</h6>
                  <div className="row">
                    <div className="col-4 col-md-4 col-sm-4">
                      <div className="subTitle mb-2 pr-1">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.companyName ? 'Company Name:' : "Location Name:"}
                        <span className="ml-4 margin-companyName">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.companyName && deliveryData.shiftToPartyAddressDetails.companyName ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.companyName && deliveryData.shiftToPartyAddressDetails.companyName : deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.location ? deliveryData.shiftToPartyAddressDetails.location : '-'}</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4 pl-2">
                      <div className="subTitle mb-2">City:
                        <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city && deliveryData.shiftToPartyAddressDetails.city ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city && deliveryData.shiftToPartyAddressDetails.city : "-"
                        }</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4 pl-2">
                      <div className="subTitle mb-2">Country:
                        <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.country && deliveryData.shiftToPartyAddressDetails.country ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.country && deliveryData.shiftToPartyAddressDetails.country : "-"
                        }</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4">
                      <div className="subTitle mb-2 pr-1">Telephone Number:
                        <span className="ml-4 margin-telephoneNumber">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.telephoneNumber && deliveryData.shiftToPartyAddressDetails.telephoneNumber ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.telephoneNumber && deliveryData.shiftToPartyAddressDetails.telephoneNumber : "-"}</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4 pl-2">
                      <div className="subTitle mb-2">PO Box:
                        <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city && deliveryData.shiftToPartyAddressDetails.poBox ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city && deliveryData.shiftToPartyAddressDetails.poBox : "-"
                        }</span>
                      </div>
                    </div>
                    <div className="col-4 col-md-4 col-sm-4 pl-2">
                      <div className="subTitle mb-2">Emirates/Region:
                        <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.region && deliveryData.shiftToPartyAddressDetails.region ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.region && deliveryData.shiftToPartyAddressDetails.region : "-"
                        } {deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city && deliveryData.shiftToPartyAddressDetails.city ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city && deliveryData.shiftToPartyAddressDetails.city : "-"
                          }
                        </span>
                      </div>
                    </div>

                    <div className="col-4 col-md-4 col-sm-4">
                      <div className="subTitle mb-2 ">Transportation Zone:
                        <span className="ml-2 ">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.transportationZone && deliveryData.shiftToPartyAddressDetails.transportationZone ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.transportationZone && deliveryData.shiftToPartyAddressDetails.transportationZone : "-"}</span>
                      </div>
                    </div>

                    <div className="col-4 col-md-4 col-sm-4 pl-2">
                      <div className="subTitle mb-2 ">Ship to party no:
                        <span className="ml-2 ">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber : "-"}</span>
                      </div>
                    </div>


                  </div>
                  {/* {address && address.length >= 1 && address.map((ele: any) => {
                      return (
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4">
                            <div className="subTitle mb-2 pr-1">Company Name:
                              <span className="ml-4 margin-companyName">{ele.companyName ? ele.companyName : "-"}</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">City:
                              <span className="ml-3">{ele.city ? ele.city : "-"
                              }</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">Country:
                              <span className="ml-3">{ele.country ? ele.country : "-"
                              }</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4">
                            <div className="subTitle mb-2 pr-1">Telephone Number:
                              <span className="ml-4 margin-telephoneNumber">{ele.telephoneNumber ? ele.telephoneNumber : "-"}</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">PO Box:
                              <span className="ml-3">{ele.poBox ? ele.poBox : "-"
                              }</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">Emirates/Region:
                              <span className="ml-3">{ele.region ? ele.region : "-"
                              } {ele.city ? ele.city : "-"
                                }
                              </span>
                            </div>
                          </div>

                          <div className="col-4 col-md-4 col-sm-4">
                            <div className="subTitle mb-2 ">Transportation Zone:
                              <span className="ml-2 ">{ele.transportationZone ? ele.transportationZone : "-"}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })} */}
                </>
                {/* )} */}
                {sessionStorage.getItem('userType') === 'FSE' ? <>
                  <h6 className="title mb-3 mt-1">Billing Address</h6>
                  <div className="row">
                        <div className="col-4 col-md-4 col-sm-4">
                          <div className="subTitle mb-2 pr-1">Company Name:
                            <span className="ml-4 margin-companyName">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.companyName ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.companyName : "-"}</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">City:
                            <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city : "-"
                            }</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">Country:
                            <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.country ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.country : "-"
                            }</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4">
                          <div className="subTitle mb-2 pr-1">Telephone Number:
                            <span className="ml-4 margin-telephoneNumber">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.telephoneNumber ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.telephoneNumber : "-"}</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">PO Box:
                            <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.poBox ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.poBox : "-"
                            }</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">Emirates/Region:
                            <span className="ml-3">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.region ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.region : "-"
                            } {deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.city : "-"
                              }
                            </span>
                          </div>
                        </div>

                        <div className="col-4 col-md-4 col-sm-4">
                          <div className="subTitle mb-2 ">Transportation Zone:
                            <span className="ml-2 ">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.transportationZone ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.transportationZone : "-"}</span>
                          </div>
                        </div>

                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2 ">Ship to party no:
                            <span className="ml-2 ">{deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber ? deliveryData && deliveryData.shiftToPartyAddressDetails && deliveryData.shiftToPartyAddressDetails.shiftToPartyCustomerNumber : "-"}</span>
                          </div>
                        </div>
                      </div>
                  {/* {address && address.length >= 1 && address.map((ele: any) => {
                      return (
                        <div className="row">
                          <div className="col-4 col-md-4 col-sm-4">
                            <div className="subTitle mb-2 pr-1">Company Name:
                              <span className="ml-4 margin-companyName">{ele.companyName ? ele.companyName : "-"}</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">City:
                              <span className="ml-3">{ele.city ? ele.city : "-"
                              }</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">Country:
                              <span className="ml-3">{ele.country ? ele.country : "-"
                              }</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4">
                            <div className="subTitle mb-2 pr-1">Telephone Number:
                              <span className="ml-4 margin-telephoneNumber">{ele.telephoneNumber ? ele.telephoneNumber : "-"}</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">PO Box:
                              <span className="ml-3">{ele.poBox ? ele.poBox : "-"
                              }</span>
                            </div>
                          </div>
                          <div className="col-4 col-md-4 col-sm-4 pl-2">
                            <div className="subTitle mb-2">Emirates/Region:
                              <span className="ml-3">{ele.region ? ele.region : "-"
                              } {ele.city ? ele.city : "-"
                                }
                              </span>
                            </div>
                          </div>

                          <div className="col-4 col-md-4 col-sm-4">
                            <div className="subTitle mb-2 ">Transportation Zone:
                              <span className="ml-2 ">{ele.transportationZone ? ele.transportationZone : "-"}</span>
                            </div>
                          </div>
                        </div>
                      )
                    })} */}
                </> :
                <>
                  <h6 className="title mb-3 mt-1">Billing Address</h6>
                {billingAddress && billingAddress.length >= 1 && billingAddress.map((ele: any) => {
                    return (
                      <div className="row">
                        <div className="col-4 col-md-4 col-sm-4">
                          <div className="subTitle mb-2 pr-1">Company Name:
                            <span className="ml-4 margin-companyName">{ele.companyName ? ele.companyName : "-"}</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">City:
                            <span className="ml-3">{ele.city ? ele.city : "-"
                            }</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">Country:
                            <span className="ml-3">{ele.country ? ele.country : "-"
                            }</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4">
                          <div className="subTitle mb-2 pr-1">Telephone Number:
                            <span className="ml-4 margin-telephoneNumber">{ele.telephoneNumber ? ele.telephoneNumber : "-"}</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">PO Box:
                            <span className="ml-3">{ele.poBox ? ele.poBox : "-"
                            }</span>
                          </div>
                        </div>
                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2">Emirates/Region:
                            <span className="ml-3">{ele.region ? ele.region : "-"
                            } {ele.city ? ele.city : "-"
                              }
                            </span>
                          </div>
                        </div>

                        <div className="col-4 col-md-4 col-sm-4">
                          <div className="subTitle mb-2 ">Transportation Zone:
                            <span className="ml-2 ">{ele.transportationZone ? ele.transportationZone : "-"}</span>
                          </div>
                        </div>

                        <div className="col-4 col-md-4 col-sm-4 pl-2">
                          <div className="subTitle mb-2 ">Ship to party no:
                            <span className="ml-2 ">{ele.shiftToPartyCustomerNumber ? ele.shiftToPartyCustomerNumber : "-"}</span>
                          </div>
                        </div>
                      </div>
                    )
                  })} 
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title">Delivery Items</h5>
              {showDownloadOrders && (
                <div className="exportExcel ml-auto" onClick={() => exportData()} style={{ cursor: 'pointer' }}>
                  <IconButton className="exportExcel" size="small" >
                    <ArrowDownwardIcon fontSize="inherit" />
                  </IconButton>
                  Export to Excel
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <div className="cardCommon orderItems">
              <TableContainer className={`classes.container`}>
                <Table aria-label="table" >
                  <TableHead className="w-100">
                    <TableRow>
                      <TableCell className={classes.tableHead}>S. No</TableCell>
                      <TableCell className={classes.tableHead}>Part No.</TableCell>
                      <TableCell className={classes.tableHead}>Description</TableCell>
                      <TableCell className={classes.tableHead}>Quantity</TableCell>
                      <TableCell className={classes.tableHead}>Unit</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any) => {
                      return (
                        <TableRow>
                          {
                            columns.map((column: any) => {
                              const value: any = row[column.id];
                              return (
                                <TableCell key={column.id} className={classes.bodyContent}>
                                  {value}
                                </TableCell>
                              );
                            })
                          }
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="col-12 d-flex justify-content-end">
                <TablePagination
                  rowsPerPageOptions={[10]}
                  component="div"
                  count={tableData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                />
              </div>
            </div>
          </div>
        </div>
      </>}

    </Fragment>
  );
};

export default DeliveryDetails;

