import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import XLSX from 'xlsx'

import IconButton from '@material-ui/core/IconButton';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";

import "./PartsHistory.css";

const useStyles = makeStyles((theme) => ({
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 140,
  },
}));

const PartsHistoryTableList: React.FC = () => {
  const classes = useStyles();

  const { partsHistoryList }: any = useSelector((state: any) => state.orderHistory);
  const showDownloadOrders = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.DOWNLOAD_ORDER);

  const exportDataToExcel = () => {
    if (partsHistoryList && partsHistoryList.data && Array.isArray(partsHistoryList.data)) {
      let filename = 'partshistoryexport.xlsx';
      var ws = XLSX.utils.json_to_sheet(partsHistoryList.data);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "sheet1");
      XLSX.writeFile(wb, filename);
    }
  }

  return (
    <Fragment>
      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center justify-content-between">
            <h5 className="page-sub-title">List of Parts</h5>
            {showDownloadOrders && (
              <div className="exportExcel mr-3" onClick={() => exportDataToExcel()}>
                <IconButton className="exportExcel" size="small">
                  <ArrowDownwardIcon fontSize="inherit" />
                </IconButton>
                Export to Excel
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="row mt-1">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12">
          <div className="cardCommon mh-300p">
            <TableContainer>
              <Table aria-label="table" >
                <TableHead className="w-100">
                  <TableRow>
                    <TableCell className={classes.tableHead}>Levels</TableCell>
                    <TableCell className={classes.tableHead}>Article No</TableCell>
                    <TableCell className={classes.tableHead}>Article Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {partsHistoryList && Array.isArray(partsHistoryList.data) && partsHistoryList.data.map((part: any, index: any) => (
                    <TableRow>
                      <TableCell>{part.supercessionLevel}</TableCell>
                      <TableCell>{part.materialNumber}</TableCell>
                      <TableCell>{part.materialDescription}</TableCell>
                    </TableRow>
                  )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PartsHistoryTableList;
