/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import LeftMenu from "../LeftMenu/LeftMenu";
import { Switch, Route, Redirect } from "react-router";
import Dealers from "../Dealers/Dealers";
import OrgSetting from "../OrgSetting/OrgSetting";
import OrderEnquiry from "../orderEnquiry/orderEnquiry";
import OrderHistory from "../OrderHistory/OrderHistory";
import PartsHistory from "../PartsHistory/PartsHistory";
import OrderDetails from "../OrderHistory/OrderDetails";
import DeliveryList from "../OrderHistory/DeliveryList";
import DeliveryDetails from "../OrderHistory/DeliveryDetails";
import CreateEnquiry from "../CreateEnquiry/CreateEnquiry";
import Favorites from '../favorites/favorites';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/account.actions";
// import CryptoJS from 'crypto-js'
import { signOut, useUserDispatch } from "../../context/UserContext.js";
import Profile from "../Profile/Profile";
import AnnouncementDetail from '../Announcements/AnnouncementDetail'
import SpecialOfferDetail from "../SpecialOffer/SpecialOfferDetail";
import AdminDashboard from "../AdminDashboard/AdminDashboard";
import AdminProfile from "../AdminProfile/AdminProfile";
import DealerUsers from "../Dealers/DealerUsers";
import ErrorBoundary from "../../common/errorBoundary/ErrorBoundary";
import { useMsal } from "@azure/msal-react";
import { AccountRestricted } from "../../common/AccountRestricted";
import Dashboard from "../Dashboard/Dashboard";
import { logoutUser } from "../../store/actions/logon.actions";
import CreditApplication from "../newUserOnboard/creditApplication";
import ShipToParty from "../newUserOnboard/shipToParty";
import ViewEditDealerDetailsKam from "../ViewEditDealerDetailsKam/ViewEditDealerDetailsKam";
import NewDealerRequestsDetails from "../NewDealerRequestsDetails/NewDealerRequestsDetails";
import Onboarding from "../newUserOnboard/onboarding";
import CreditRequest from "../CreditRequest/creditRequest";
import CreditRequestList from "../CreditRequestList/CreditRequestList";
import CreditRequestDetails from "../CreditRequestList/CreditRequestDetails";
// import CreditRequestDetails from "../CreditRequest/";
import CreditRequestOrg from "../OrgSetting/CreditRequest";
import ViewCreditDetails from "../OrgSetting/ViewCreditDetails";
import ViewCreditKAM from "../Dealers/ViewCreditKam";
import CreditDetails from "../CreditRequest/creditDetails";
import AddNewDealerKam from "../Dealers/AddDealer/AddNewDealerKam";
import PlanFeatures from "../PlanFeatures/PlanFeatures";
import SuperDealer from "../DealerSuperAdmin/SuperDealer";
import EditDealer from "../DealerSuperAdmin/EditDealer";
import SetTargetDealerList from "../SetTarget/SetTargetDealerlist";
import SetTarget from "../SetTarget/SetTarget";
import ViewTargetDealer from "../Dealers/ViewTargetDealer";
import Achivements from "../Achivements/Achivements";
import OesCatalogue from "../Catalogue/OesCatalogue";
import OrderSummary from "../CreateEnquiry/OrderSummary";
import OrderHistoryPayment from "../OrderHistory/OrderHistoryPayment";
import ViewDeliveryDetails from "../OrderHistory/ViewDeliveryDetails";
import ViewDelivery from "../OrderHistory/ViewDelivery";
import SpecialPriceRequest from "../SpecialPrice/SpecialPriceRequest";
import ViewPriceRequest from "../SpecialPrice/ViewPriceRequest";
import ViewRequestStatus from "../SpecialPrice/ViewRequestStatus";
import RequestSpecialPrice from "../SpecialPrice/RequestSpecialPrice";
import SpecialPriceKam from "../SpecialPriceKam/SpecialPriceKam";
import EditRequestSpecialPrice from "../SpecialPrice/EditRequestSpecialPrice";
import ViewPriceRequestKam from "../SpecialPriceKam/ViewPriceRequestKam";
import MySchedule from "../mySchedule/mySchedule";
import Quotation from "../Quotation/Quotation";
import DataArchival from "../dataArchival/dataArchivalList";


const Admin: React.FC = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const userDispatch = useUserDispatch();
  const userData = useSelector((state: any) => { return state.account && state.account.userData });
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  let isAdmin: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType && profileDetails.data.data.basicInfo.userType.toUpperCase() == "KAM")
    || (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType && profileDetails.data.data.basicInfo.userType.toUpperCase() == "FSE")
    || sessionStorage.getItem('userType') === 'FSE' ? true : false;
  let isGuestUser = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType === 'guestAdmin' ? true : false;
  const { instance } = useMsal();
  const callLogout = async () => {
    await dispatch(logoutUser());
    await dispatch(logout());
    signOut(userDispatch);
    instance.logoutRedirect({ postLogoutRedirectUri: isAdmin ? "/admin" : "/" });
  }

  useEffect(() => {
    document.addEventListener('click', checkTokenExpiry)
    if (sessionStorage) {
      if (Number(sessionStorage.getItem('refreshTokenExpiresOn')) > 1 && Date.now() > Number(sessionStorage.getItem('refreshTokenExpiresOn'))) {
        callLogout();
      }
    }
  }, []) // eslint-disable-line

  const checkTokenExpiry = (event: any) => {
    if (Number(sessionStorage.getItem('refreshTokenExpiresOn')) > 1 && Date.now() > Number(sessionStorage.getItem('refreshTokenExpiresOn'))) {
      callLogout();
    }
  }


  // const handleUserInfo = (access_token: any) => {

  // }

  useEffect(() => {
    if (userData) {

    }
  }, [userData])

  return (
    <Fragment>
      {/* <Notifications /> */}
      <div className="d-flex w-100 overflow-hidden">
        {profileDetails && profileDetails.data && profileDetails.data.data && <AccountRestricted>
          <LeftMenu />
          <div id="content-wrapper" className='d-flex w-100 pl-3 py-4 pr-2'>
            <div className='col-12'>
              <ErrorBoundary>
                <Switch>
                  <Route path="/dashboard">{isAdmin ? <AdminDashboard /> : <Dashboard />}</Route>
                  <Route path="/achievements"><Achivements /></Route>
                  <Route path="/oescatalogue" ><OesCatalogue /></Route>
                  <Route path="/specialpricerequest"><SpecialPriceRequest /></Route>
                  <Route path="/viewspecialprice"><ViewPriceRequest /></Route>
                  <Route path="/achievementskam"><Achivements /></Route>
                  <Route path="/achievementssuperadmin"><Achivements /></Route>
                  <Route path="/dealers"><Dealers /></Route>
                  <Route path="/ordersummarypayment"><CreateEnquiry /></Route>
                  <Route path="/orderhistorypayment"><OrderHistoryPayment /></Route>
                  <Route path="/planfeatures"><PlanFeatures /></Route>
                  <Route path="/orgSetting"><OrgSetting /></Route>
                  <Route path="/viewcreditdetails"><ViewCreditDetails /></Route>
                  <Route path="/creditrequestorg"><CreditRequestOrg /></Route>
                  <Route path="/viewcreditkam"><ViewCreditKAM /></Route>
                  <Route path="/orderenquiry"><OrderEnquiry /></Route>
                  <Route path="/favorites"><Favorites /></Route>
                  <Route path="/orderhistory"><OrderHistory /></Route>
                  <Route path="/archiveddata"><DataArchival /></Route>
                  <Route path="/quotation"><Quotation /></Route>
                  <Route path="/partshistory"><PartsHistory /></Route>
                  <Route path="/orderdetails"><OrderDetails /></Route>
                  <Route path="/deliverylist"><DeliveryList /></Route>
                  <Route path="/deliverydetails"><DeliveryDetails /></Route>
                  <Route path="/createenquiry"><CreateEnquiry /></Route>
                  <Route path="/viewenquiry"><CreateEnquiry /></Route>
                  <Route path="/updateenquiry"><CreateEnquiry /></Route>
                  <Route path="/profile"><Profile /></Route>
                  <Route path="/superadmindealers"><SuperDealer /></Route>
                  <Route path="/editsuperdealer"><EditDealer /></Route>
                  <Route path="/announcements"><AnnouncementDetail /></Route>
                  <Route path="/specialoffers"><SpecialOfferDetail /></Route>
                  <Route path="/adminspecialoffers"><SpecialOfferDetail /></Route>
                  <Route path="/specialofferdetail"><SpecialOfferDetail /></Route>
                  <Route path="/adminprofile"><AdminProfile /></Route>
                  <Route path="/dealerusers"><DealerUsers /></Route>
                  <Route path="/requestspecialprice"><RequestSpecialPrice /></Route>
                  <Route path="/myschedule"><MySchedule /></Route>
                  <Route path="/editrequestspecialprice"><EditRequestSpecialPrice /></Route>
                  {/* <Route path="/specialprice"><SpecialPrice /></Route> */}
                  <Route path="/specialpricekam"><SpecialPriceKam /></Route>
                  <Route path="/viewspecialpricekam"><ViewPriceRequestKam /></Route>
                  <Route path="/viewrequeststatus"><ViewRequestStatus /></Route>
                  <Route path="/onboarding"><Onboarding /></Route>
                  <Route path="/creditteam"><CreditRequest /></Route>
                  <Route path="/settarget"><SetTarget /></Route>
                  <Route path="/dealerstarget"><SetTargetDealerList /></Route>
                  <Route path="/viewdelivery"><ViewDelivery /></Route>
                  <Route path="/deliverydetails"><DeliveryDetails /></Route>
                  <Route path="/ViewDeliverDetails"><ViewDeliveryDetails /></Route>
                  <Route path="/creditteamdetails"><CreditDetails /></Route>
                  <Route path="/createcreditrequest"><CreditApplication /></Route>
                  <Route path="/shiptoparty"><ShipToParty /></Route>
                  <Route path="/viewtarget"><ViewTargetDealer /></Route>
                  <Route path="/dealerrequestdetails"><NewDealerRequestsDetails /></Route>
                  <Route path="/addnewdealer"><AddNewDealerKam /></Route>
                  <Route path="/vieweditdealerdetails"><ViewEditDealerDetailsKam /></Route>
                  <Route path="/creditlist"><CreditRequestList /></Route>
                  <Route path="/kamcreditdetails"><CreditRequestDetails /></Route>
                  <Route path="/"> {isGuestUser ? <Redirect to='/onboarding' /> : <Redirect to='/dashboard' />}
                  </Route>
                  {/* {isAdmin ? (
                    <Route path="/"> {userType === 'creditTeam' ? <Redirect to='/creditteam' /> : <Redirect to='/dashboard' />}
                    </Route>
                  ) : (
                    <Route path="/"> {isGuestUser ? <Redirect to='/onboarding' /> : <Redirect to='/dashboard' />}
                    </Route>
                  )} */}
                </Switch>
              </ErrorBoundary>
            </div>
          </div>
        </AccountRestricted>}
      </div>
    </Fragment>
  );
};

export default Admin;
