/* eslint-disable */
import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { downloadQuotation } from "../../store/actions/quotation.action";
import { useDispatch } from "react-redux";

const OrderHistoryViewQuotation = (props: any) => {
  const { enquiryNumber, quotationNumber, viewQuotationUrl, brandId } = props
  const [open, setOpen] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const dispatch: any = useDispatch()

  // ON CHANGE OPEN - CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleDownload = async (type: any) => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId, type))
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
    } else {
      if (type === 'pdf') {
        fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = "quotation" + ".pdf";
          link.click();
        });
      }
      else {
        window.open(data && data.data, "_blank");
      }
      // window.open(data && data.data)
    }
  }

  return (
    <div>
      <React.Fragment>
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog className="full-dialog" fullWidth={true} maxWidth="xl" open={props.openViewQuotation ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleCloseViewQuotation()
            }
          }}>
          <DialogTitle className="pb-0" id="form-dialog-title">
            <div className="page-title">View Quotation
              <IconButton aria-label="close" className="float-right" onClick={() => { props.handleCloseViewQuotation(); }}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers className="p-0">
            <iframe
              title="pdf"
              // src="https://strautohlcommqa.blob.core.windows.net/mallstore/Sales_Quotation_20005922.pdf"
              src={viewQuotationUrl}
              className="h-100vh w-100"
              width="100%"
            />
          </DialogContent>
          <DialogActions className=" p-3 justify-content-start">
            <div className="float-left">
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => {
                handleDownload('pdf')
              }}>
                Download Quotation
              </Button>
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => {
                handleDownload('xls')
              }}>
                Download Quotation In Excel
              </Button>
            </div>
          </DialogActions>
          <DialogActions className="justify-content-end mt-n-65">
            <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { props.handleCloseViewQuotation(); }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    </div >
  )
}
export default OrderHistoryViewQuotation;
