import XLSX from 'xlsx';

export const tsvToJson = (tsvText) => {
    let allTextLines = tsvText.split(/\r\n|\n/);
    let headers = allTextLines[0].split(/\t|,/);
    let lines = [];
    for (let i = 1; i < allTextLines.length; i++) {
        let data = allTextLines[i].split(/\t|,/);
        if (data.length === headers.length) {
            let row = {};
            for (let j = 0; j < headers.length; j++) {
                row[headers[j]] = data[j];
            }
            lines.push(row);
        }
    }
    return lines;
}

export const readFile = async (uploadedFile, readfilecallback) => {
    if (uploadedFile) {
        try {
            var f = uploadedFile;
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: "binary" });
                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];
                let data = XLSX.utils.sheet_to_csv(ws, { blankrows: false });
                data = convertToJson(data);
                let sendData = []
                if (data && Array.isArray(data)) {
                    data.map((item) => {
                        if (item && item.quantity !== '') {
                            item['productId'] = item.productId ? item.productId : item.partnumber
                            sendData.push({
                                productId: item.productId ? item.productId : item.partnumber,
                                quantity: parseInt(item.quantity),
                            });
                            return item
                        }
                        return item;
                    })
                    sendData = sendData.filter((item) => item.productId);
                    if (readfilecallback) {
                        readfilecallback(sendData)
                    }
                } else {
                    // onuploadedFileChanged('error in file');
                }
            };
            reader.readAsBinaryString(f);
        } catch (e) {
            console.log(e);
        }
    }
}

export const convertToJson = (csv) => {
    var lines = csv.split("\n");
    var result = [];
    var headers = lines[0].split(",");
    for (var i = 1; i < lines.length; i++) {
        var obj = {};
        var currentline = lines[i].split(",");
        for (var j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);
    }
    return result;
}

export const getSellingPrice = (price, isSellingPrice, percentage) => {
    if (typeof(price) == "undefined") {
        return 0;
    }
    if (isSellingPrice) {
        return price;
    } else {
        return (parseFloat(price) * (parseFloat(percentage) + 100)) / 100
    }
}

export const getYears = () => {
    const year = new Date().getFullYear();
    let years = [];
    years = Array.from(new Array(10), (val, index) => index + year);
    if (year > 2022) {
        for (var i = year - 1; i >= 2022; i--) {
            years.unshift(i)
        }
    }
    return years;
}

export const getInternationalNumbers = (number) => {
    let numberValue = number ? new Intl.NumberFormat('en-US').format(number) : "0";
    return "AED " + numberValue;
    // return number ? new Intl.NumberFormat('en-US', { style: 'currency', currency: 'AED' }).format(number) : "0";
}

