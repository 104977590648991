/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { Button, Dialog } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import {
  getUniqueEnquirySessionId,
} from "../../store/actions/createUpdateEnquiry.actions";
import { setOrderEnquiryDetails } from "../../store/actions/orderEnquiry.action";
import { getUniqueTimestamp } from "../../common/components/util";

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  title: {
    flex: '1 1 100%',
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 160,
    fontWeight: 900,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.742rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  correctionText: {
    color: '#133F8A',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  textFontColor: {
    color: 'red',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 900,
    textTransform: 'inherit'
  }
}));

export default function ExpiredDialog24Hrs(props: any) {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = React.useState<boolean>(false);
  const history = useHistory()
  const { viewSpecialPriceDetails }: any = useSelector((state: any) => state.requestSpecialPrice);

  return (
    <Fragment>
      {/* {loading && <FullscreenLoader />} */}
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenClose(false); props.setSuccessMessage(''); props.handleopenCloseSnackbar(false); }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <Snackbar
          open={props.openRequestSnackbar ? true : false}
          type={props.openErrorSnackbar ? 'error' : ''}
          handleopenClose={() => { }} message={props.successMessage}
        />
        <DialogContent>
          <div className={classes.root}>
            <div className="col-12 mt-1 px-0 pr-0">
              <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                <h4 className="shift-to-party-page-title">
                  <IconButton aria-label="close" className="float-right pr-5 mt-2" onClick={() => { props.handleopenClose(false, false, true); }}>
                    <CloseIcon />
                  </IconButton>
                </h4>
              </Typography>
            </div>
            <DialogContent>
              <div className={classes.icon}>
                <WarningIcon className="delete-warning" fontSize="small" />
              </div>
              <div>
                <DialogContentText id="alert-dialog-description">
                  <div className="">
                    <h6 className="mt-2 mb-0 dealer-expired-heading">Special Offered Price expired </h6>
                  </div>
                </DialogContentText>
              </div>
            </DialogContent>
          </div>
          <div className="col-md-12 mt-1 mb-2 px-5">
            <div className="d-flex align-items-center">
              <div className="dealer-expired-subheading">
                Your Special price request has expired after approval because you have exceeded the 24-hour window to confirm the order.
                You can still place the order with the Standard prices or you can again submit your Special price request by creating a new enquiry.
              </div>
            </div>
          </div>
        </DialogContent>
        <div className="col-12 mt-2 mb-2">
          <div className="d-flex align-items-center">
            <div className="row-space-start ml-auto">
              <DialogActions className="mt-3 mb-1 justify-content-end">
                <Button variant="contained"
                  color="secondary" className="check-availability-button-access-tab"
                  onClick={() => {
                    let partsData: any = [];
                    setLoading(true);
                    sessionStorage.setItem("enquiryModeDetails", '');
                    viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 &&
                      viewSpecialPriceDetails.data.data.data.map((ele: any) => {
                        ele && ele.products.map((details: any) => {
                          partsData.push({
                            productId: details.productId,
                            quantity: details.quantity
                          })
                        })
                      })
                    setLoading(false);
                    props.handleopenClose(false, true);
                    sessionStorage.setItem('partsData', JSON.stringify([...partsData]));
                    dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                    dispatch(setOrderEnquiryDetails('create'));
                    history.push('/createenquiry');
                  }}
                >Copy & Create New Enquiry
                </Button>
                <Button variant="contained" className={classes.closeButton}
                  onClick={() => { props.handleopenClose(false); }}
                >View Enquiry
                </Button>
              </DialogActions>
            </div>
          </div>
        </div>
      </Dialog>
      {/* <Dashboard
                setOpenEnquiry={setOpenEnquiry}>
            </Dashboard> */}
    </Fragment>
  );
}
