/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Paper from "@material-ui/core/Paper";
import { MenuItem, Tab, TableHead, Tabs, Checkbox, InputAdornment, TextField, Tooltip, ListItemText } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import Button from '@material-ui/core/Button';
import MoveOutDialog from "../../ViewEditDealerDetailsKam/MoveOutDialog";
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Select from '@material-ui/core/Select';
import AnnouncementIcon from "../../../common/components/AnnouncementIcon";
import NotificationIcon from "../../../common/components/NotificationIcon";
import DeleteDialog from "../../../common/components/deleteDialog";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AddNewOwnerPartnerDialog from './addNewOwnerPartnerDialog';
import { get, isUndefined, stubFalse, truncate } from 'lodash';
import { getActualBrand, getNewBrand, getUniqueTimestamp } from '../../../common/components/util';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { editPartnerDetails, getDealerDetails, getPartnerDetails, saveDealerDetails, savePartnerDetails, deletePartnerDetails, setCustomerType, getNationality, resetValues, setStatus, getAllPartners, changeCustomerType, getRegion, getDealingIn, getTypeOfTrade, getIBCountryAndSalesRegion } from '../../../store/actions/onboard.actions';
import { ValidationErrorMessage } from "../../../constants/messages";
import moment from "moment-timezone";
import Dialog from '@material-ui/core/Dialog';
// import ReturnRequestSnackbar from "../../../../common/components/returnRequestSnackbar";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { KeyboardDatePicker } from "@material-ui/pickers";
import FullscreenLoader from '../../../common/components/FullscreenLoader';
import ActionButtonsAddNewKam from './ActionButtons';
import ReturnRequestSnackbar from '../../../common/components/CreatedEnquirySnackbar';
import { getAllBrands } from "../../../store/actions/dealer.action";
import { FormControl, FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  // createButton: {
  //   backgroundColor: '#1A9FE0',
  //   width: 140,
  //   fontWeight: 800,
  //   textTransform: 'inherit',
  //   fontFamily: 'Nunito',
  //   fontSize: '0.688rem',
  //   color: '#FFFFFF',
  //   borderRadius: '2.5rem',
  //   height: 35,
  //   textAlign: 'center',
  //   marginRight: 8
  // },
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },

  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  dropdown: {
    // min-width: 100% !important;
    // font-size: large;
    minWidth: 100,
    fontSize: 'large'
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'nationality', numeric: true, disablePadding: false, label: sessionStorage.getItem('customerOnboardingType') === "Workshop" ? '' : 'Nationality' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}
export const AddNewKamDealerDetails = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [error, setError] = React.useState<any>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isView, setIsView] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [selected, setSelected] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [customerLastName, setCustomerLastName] = React.useState<string>("");
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [companyNameErrorMessage, setCompanyNameErrorMessage] = React.useState<string>("");
  const [userConfirmation, setUserConfirmation] = React.useState<any>(false);
  const [invalidCountry, setInvalidCountry] = React.useState<boolean>(false);
  const [countryErrorMessage, setCountryErrorMessage] = React.useState<string>("");
  const [postalCode, setPostalCode] = React.useState<string>("");
  const [postalCodeError, setPostalCodeError] = React.useState<boolean>(false);
  const [invalidPostalCode, setInvalidPostalCode] = React.useState<boolean>(false);
  const [city, setCity] = React.useState<string>("");
  const [cityError, setCityError] = React.useState<boolean>(false);
  const [invalidCity, setInvalidCity] = React.useState<boolean>(false);
  const [addStreet, setAddStreet] = React.useState<string>("");
  const [headOfficeAddress, setAddress] = React.useState<any>({});
  const [shippingLabels, setShippingLabels] = React.useState<any>([{ name: "SORT1", value: '' }, { name: "SORT2", value: '' }]);
  const [addStreetError, setAddStreetError] = React.useState<boolean>(false);
  const [invalidAddStreet, setInvalidAddStreet] = React.useState<boolean>(false);
  const [addStreetErrorMessage, setAddStreetErrorMessage] = React.useState<string>("");
  const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean>(false);
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [mobileNumber, setMobileNumber] = React.useState<string>("");
  const [mobileNumberError, setMobileNumberError] = React.useState<boolean>(false);
  const [telephoneOfficError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [TelephoneOfficeErrorMessage, setTelephoneOfficeErrorMessage] = React.useState<string>("");
  const [mobileNumberErrorMessage, setMobileNumberErrorMessage] = React.useState<string>("");
  const [vatRegistrationNumber, setVatRegistrationNumber] = React.useState<string>("");
  const [vatRegistrationNumberError, setVatRegistrationNumberError] = React.useState<boolean>(false);
  const [invalidVatRegistrationNumber, setInvalidVatRegistrationNumber] = React.useState<boolean>(false);
  const [VatRegistrationNumberErrorMessage, setVatRegistrationNumberErrorMessage] = React.useState<string>("");
  const [dealerEmail, setDealerEmail] = React.useState<string>("");
  const [dealerEmailError, setDealerEmailError] = React.useState<boolean>(false);
  const [invalidDealerEmail, setInvalidDealerEmail] = React.useState<boolean>(false);
  const [headOfficeRegion, setHeadOfficeRegion] = React.useState<string>("");
  const [headOfficeRegionError, setHeadOfficeRegionError] = React.useState<boolean>(false);
  const [invalidHeadOfficeRegion, setInvalidHeadOfficeRegion] = React.useState<boolean>(false);
  const [typeOfTrade, setTypeOfTrade] = React.useState<string>("");
  const [typeOfTradeError, setTypeOfTradeError] = React.useState<boolean>(false);
  const [invalidTypeOfTrade, setInvalidTypeOfTrade] = React.useState<boolean>(false);
  const [dealingIn, setDealingIn] = React.useState<string>("");
  const [dealingInError, setDealingInError] = React.useState<boolean>(false);
  const [invalidDealingIn, setInvalidDealingIn] = React.useState<boolean>(false);
  const [annualTurnover, setAnnualTurnover] = React.useState<string>("");
  const [establishedDate, setEstablishedDate] = React.useState<any>(null);
  const [establishedDateError, setEstablishedDateError] = React.useState<boolean>(false);
  const [invalidEstablishedDate, setInvalidEstablishedDate] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [PoBoxErrorMessage, setPoBoxErrorMessage] = React.useState<string>("");
  const [selectedPartnerData, setSelectedPartnerData] = React.useState<any>("");
  const [selectBrand, setSelectBrand] = React.useState<any>([]);
  const [regionDataNew, setRegionData] = React.useState<any>([]);
  const [selectBrandError, setSelectBrandError] = React.useState<boolean>(false);
  const [invalidSelectBrand, setInvalidSelectBrand] = React.useState<boolean>(false);
  const [creditLimit, setCreditLimit] = React.useState<string>("");
  const [creditLimitError, setCreditLimitError] = React.useState<boolean>(false);
  const [invalidCreditLimit, setInvalidCreditLimit] = React.useState<boolean>(false);
  const [initalStockOrder, setInitalStockOrder] = React.useState<string>("");
  const [initalStockOrderError, setInitalStockOrderError] = React.useState<boolean>(false);
  const [invalidInitalStockOrder, setInvalidInitalStockOrder] = React.useState<boolean>(false);
  const [totalInvestment, setTotalInvestment] = React.useState<string>("");
  const [totalInvestmentError, setTotalInvestmentError] = React.useState<boolean>(false);
  const [invalidTotalInvestment, setInvalidTotalInvestment] = React.useState<boolean>(false);
  const [otherBusinessEstablishments, setOtherBusinessEstablishments] = React.useState<string>("");
  const [OtherBusinessEstablishmentsErrorMessage, setOtherBusinessEstablishmentsErrorMessage] = React.useState<string>("");
  const [otherBusinessEstablishmentsError, setOtherBusinessEstablishmentsError] = React.useState<boolean>(false);
  const [invalidOtherBusinessEstablishments, setInvalidOtherBusinessEstablishments] = React.useState<boolean>(false);
  const [otherBrandsProductsSold, setOtherBrandsProductsSold] = React.useState<string>("");
  const [OtherBrandsProductsSoldErrorMessage, setOtherBrandsProductsSoldErrorMessage] = React.useState<string>("");
  const [otherBrandsProductsSoldError, setOtherBrandsProductsSoldError] = React.useState<boolean>(false);
  const [invalidOtherBrandsProductsSold, setInvalidOtherBrandsProductsSold] = React.useState<boolean>(false);
  const [creditLimitSelector, setCreditLimitSelector] = React.useState<string>("AED");
  const [creditLimitSelectorError, setCreditLimitSelectorError] = React.useState<boolean>(false);
  const [invalidCreditLimitSelector, setInvalidCreditLimitSelector] = React.useState<boolean>(false);
  const [initalStockOrderSelector, setInitalStockOrderSelector] = React.useState<string>("AED");
  const [totalInvestmentSelector, setTotalInvestmentSelector] = React.useState<string>("AED");
  const [totalInvestmentSelectorError, setTotalInvestmentSelectorError] = React.useState<boolean>(false);
  const [invalidTotalInvestmentSelector, setInvalidTotalInvestmentSelector] = React.useState<boolean>(false);
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [annualTurnoverSelector, setAnnualTurnoverSelector] = React.useState<string>("AED");
  const [brandList, setBrandList] = React.useState<any>([]);
  const [brandListError, setBrandListError] = React.useState<any>([]);
  const [selectedRegion, setSelectedRegion] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [dataUpdated, setDataUpdated] = React.useState<any>(false);
  const currencies = ["AED"];
  const [selectedIBCurrency, setSelectedIBCurrency] = React.useState<any>("");
  const [ibCurrency, setIbCurrency] = React.useState<any>("");
  const [ibCurrencyError, setIbCurrencyError] = React.useState<boolean>(false);
  const [typeOfDelete, setTypeOfDelete] = React.useState<string>("");
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [tradeRowRefDeleteIndex, setTradeRowRefDeleteIndex] = React.useState<any>();
  const [selectedTradeReference, setSelectedTradeReference] = React.useState<any>([]);
  const [inCompleteFileds, setInCompleteFileds] = React.useState<any>(false);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  const [email, setEmail] = React.useState("");
  const [isDeactivated, setIsDeactivated] = React.useState("");
  const [deactivationReason, setDeactivationReason] = React.useState("");
  const { customerDetails, regionData, dealingInData, typeofTradeData,
    savePartnerData, editPartnerData, deletePartnerData, dealerDetails, getAllPartnersData,
    customerType, getNationalityData }: any = useSelector((state: any) => state.onboard);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [isChangeCountry, setIsChangeCountry] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { brandsList, generateB2bId }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(generateB2bId, "data.createdByB2bUserId", "");
  const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
  const customerInfo = dealerDetails && dealerDetails.data && isExistingUser
    ? dealerDetails && dealerDetails.data : customerDetails && customerDetails.data && customerDetails.data.customerInformation;
  const [tradeReference, setTradeReference] = React.useState<any>([
    // {
    //   companyName: "",
    //   name: '',
    //   addresses: "",
    //   telephone: ""
    // }
  ]);

  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [tradeReferenceErrors, setTradeReferenceErrors] = React.useState<any>([
    // {
    //   companyNameError: false,
    //   companyNameErrorMessage: "",
    //   nameError: false,
    //   nameErrorMessage: "",
    //   addressError: false,
    //   addressErrorMessage: "",
    //   telephoneError: false,
    //   telephoneErrorMessage: "",
    // }
  ]);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const history = useHistory();
  const groupCode = sessionStorage.getItem('groupCode');
  const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem('customerOnboardingType') : get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";
  const { customerTypeData, getIBCountryAndRegionData }: any = useSelector((state: any) => state.onboard);
  const customerTypeAccess: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.customerTypeAccess) || "";
  const [customerAcceessForIB, setCustomerAcceessForIB] = React.useState(false);
  const [salesRegion, setSalesRegion] = React.useState<any>("");
  const [iBEnableHeadOfficeRegion, setIBEnableHeadOfficeRegion] = React.useState(false);
  const customer: any = sessionStorage.getItem('customerOnboardingType');
  const [existWorkShop, setExistWorkShopBrand] = React.useState<any>([]);
  const [oesBrandList, setOesBrandList] = React.useState<any>([]);
  let onboardingType: any = sessionStorage.getItem('customerOnboardingType');
  const [countryData, setCountryData] = React.useState<any>([]);
  const [orgIds, setOrgIds] = React.useState<any>([]);
  const [country, setCountry] = React.useState<string>(userType === "FSE" && customer === "Government" || userType === "FSE" && customer === "Fleet" || userType === "FSE" && customer === "Workshop" ? "AE" : customer === "Dealer" ? "AE" : "");
  const [countryError, setCountryError] = React.useState<boolean>(false);

  // TESTING PURPOSED ADDED 
  const companyBrands = [
    {
      "organizationId": "2002",
      "shippingDetails": {
        "shippingCondition": "03",
        "deliveringPlant": "T027",
        "priority": "01",
        "maxPartialDeliveriesPerItem": "1",
        "currency": "AED",
        "shippingPartnerName": []
      }
    },
    {
      "organizationId": "2001",
      "shippingDetails": {
        "shippingCondition": "03",
        "deliveringPlant": "M4AC",
        "priority": "01",
        "maxPartialDeliveriesPerItem": "1",
        "currency": "AED",
        "shippingPartnerName": []
      }
    },
    {
      "organizationId": "2380",
      "shippingDetails": {
        "shippingCondition": "03",
        "deliveringPlant": "E4AA",
        "priority": "01",
        "maxPartialDeliveriesPerItem": "1",
        "currency": "AED",
        "shippingPartnerName": []
      }
    }
  ]

  useEffect(() => {
    if (customerTypeAccess && customerTypeAccess.length > 0) {
      if (customerTypeAccess.includes("intbusiness")) {
        setCustomerAcceessForIB(true);
      } else {
        setCustomerAcceessForIB(false);
      }
    }
  }, [customerTypeAccess])

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };
  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };
  const deleteCustomerData = async () => {
    let data: any = await dispatch(changeCustomerType({ b2bUserId: b2bUserId }));
    if (data && data.message && data.message.status === 'S') {
      history.push('/dealers');
    } else {
      setSuccessMessage(data.error);
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
    }
  }
  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  useEffect(() => {
    // if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" && sessionStorage.getItem('customerType') === 'existing') {
    //   if (customerDetails && customerDetails.data && customerDetails.data.companyBrands && customerDetails.data.companyBrands.length > 0) {
    //     customerDetails && customerDetails.data && customerDetails.data.companyBrands && customerDetails.data.companyBrands.length > 0 && customerDetails.data.companyBrands.map((ele: any) => {
    //       if (ele && ele.shippingDetails) {
    //         setSelectedIBCurrency(ele && ele.shippingDetails && ele.shippingDetails.currency);
    //         setIbCurrency(ele && ele.shippingDetails && ele.shippingDetails.currency);
    //       } else {
    //         setSuccessMessage("Currency is not assigned to the annual turnover.");
    //         setOpenErrorSnackbar(true);
    //         handleopenCloseSnackbar(true);
    //       }
    //     })
    //   }
    // }
    // TESTING PURPOSED ADDED 
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" && sessionStorage.getItem('customerType') === 'existing') {
      if (customerInfo && customerInfo.companyBrands && customerInfo.companyBrands.length > 0 && customerInfo.companyBrands) {
        customerInfo.companyBrands && customerInfo.companyBrands.map((ele: any) => {
          if (ele && ele.shippingDetails && ele.shippingDetails) {
            setSelectedIBCurrency(ele && ele.shippingDetails && ele.shippingDetails.currency);
            setIbCurrency(ele && ele.shippingDetails && ele.shippingDetails.currency);
          }
        })
      }
    }
    // -------------------------------------------------------------------------------------------------------
    if (customerDetails && customerDetails.data && customerDetails.data.customerInformation && customerDetails.data.customerInformation.addresses && customerDetails.data.customerInformation.addresses.length > 0 && customerDetails.data.customerInformation.addresses[0].region && customerDetails.data.customerInformation.addresses[0].region !== '') {
      if (customerDetails.data.customerInformation.addresses[0].country == 'AE') {
        regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
          if (r.Code === customerDetails.data.customerInformation.addresses[0].region) {
            if (customerDetails.data.customerInformation.addresses[0].country) {
              setCountry(customerDetails.data.customerInformation.addresses[0].country);
              if (getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion && getIBCountryAndRegionData.data.countryAndSalesRegion.length > 0) {
                getSalesRegion(getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion, customerDetails.data.customerInformation.addresses[0].country)
              }
            }
            setHeadOfficeRegion(r.Region);
            setSelectedRegion(r);
          }
        })
      } else {
        if (customerDetails.data.customerInformation.addresses[0].country) {
          setCountry(customerDetails.data.customerInformation.addresses[0].country);
          if (getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion && getIBCountryAndRegionData.data.countryAndSalesRegion.length > 0) {
            getSalesRegion(getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion, customerDetails.data.customerInformation.addresses[0].country),
              getRegionByRegionCodeforFSE(getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion, customerDetails.data.customerInformation.addresses.length > 0 && customerDetails.data.customerInformation.addresses[0].region, customerDetails.data.customerInformation.addresses[0].country)
          }
        }
      }
    }

    // }
    const typeOfTrades: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
      return customerDetails && customerDetails.data && customerDetails.data.customerInformation && customerDetails.data.customerInformation.customerGroupCode === ele.groupCode;
    })
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
        return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
      })
      if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
      if (typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id) setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)
    } else {
      if (sessionStorage.getItem('customerType') == "existing") {
        setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
        if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
        const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
          return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
        })
        if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
        if (typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id) setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)

      } else {
        customerTypeData && customerTypeData.data && customerTypeData.data.length > 0 && customerTypeData.data[0].customerTypes && customerTypeData.data[0].customerTypes.length > 0 && customerTypeData.data[0].customerTypes.map((ele: any) => {
          ele && ele.subType && ele.subType.map((check: any) => {
            setTypeOfTrade(check && check.name)
          })
        })
        if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
        const typeOfTrades1: any = typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.filter((ele: any) => {
          return groupCode === ele.groupCode || typeOfTrade == ele.title || typeOfTrade == ele.id || typeOfTrade == ele.groupCode;
        })
        if (typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id) setTypeOfTrade(typeOfTrades && typeOfTrades[0] && typeOfTrades[0].id)
        if (typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id) setTypeOfTrade(typeOfTrades1 && typeOfTrades1[0] && typeOfTrades1[0].id)
      }
    }
  }, [customerDetails, regionData, typeofTradeData, getIBCountryAndRegionData, typeOfTrade]);

  function getRegionsByCountryCode(arr: any, countryCode: any) {
    return arr.filter((obj: any) => obj.countryCode === countryCode).map((obj: any) => ({ Code: obj.regionCode, Region: obj.regionDesc }));
  }

  function getRegionByRegionCodeforFSE(arr: any, regionCode: any, countryCode: any) {
    let filtered = arr.filter((obj: any) => ((obj.regionCode === regionCode) && (obj.countryCode === countryCode)));
    if (filtered.length > 0) {
      setHeadOfficeRegion(filtered[0].regionCode);
      setSelectedRegion({ Region: filtered[0].regionDesc, Code: filtered[0].regionDesc })
    }
  }

  useEffect(() => {
    loadPartners();
  }, []);

  useEffect(() => {
    if (country) {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Country === country) {
          setCountry(r);
        }
      })
    }
    if (userType === "FSE" && customer === "Government" || userType === "FSE" && customer === "Fleet" || customer === "Dealer") {
      getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((r: any) => {
        if (r.Code === 'AE') {
          setCountry(r.Code);
        }
      })
    }
  }, [country, getNationalityData])

  const loadPartners = async () => {
    dispatch(resetValues());
    setLoading(true);
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
    dispatch(getAllBrands(sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "Int. Business" : sessionStorage.getItem('customerOnboardingType') === "Workshop" ? "Workshop" : sessionStorage.getItem('customerOnboardingType') === "Government" ? "Government" : sessionStorage.getItem('customerOnboardingType') === "Fleet" ? "Fleet" : "Dealer"));
    // if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
    dispatch(getRegion(sessionStorage.getItem('customerOnboardingType')));
    // }

    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
    } else {
      dispatch(getDealingIn());
    }
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
    } else {
      dispatch(getTypeOfTrade());
    }
    let ibCountryData: any;
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      ibCountryData = await dispatch(getIBCountryAndSalesRegion());
    }
    handleopenCloseSnackbar(false);
    dispatch(getAllPartners(b2bUserId));
    const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails'));

    if (onboardingType && onboardingType.toLowerCase() === 'workshop') {
      if (brandsList && brandsList.data && brandsList.data.length > 0) {
        let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
        setOesBrandList(oesBrands);
        if (oesBrands && oesBrands.length > 0)
          setSelectBrand([getNewBrand(oesBrands[0].name)]);
      } else {
        setOesBrandList([{ "name": "OES", "displayName": "Tradepoint", "brandId": "2195", "brandType": "OES", "paymentBrandCode": "" }])
        setSelectBrand([getNewBrand("OES")]);
      }
    }
    if (data && data.data && data.data.customerTierType === 'Workshop') {
      if (data.data.selectedBrand && data.data.selectedBrand.length > 0) {
        let orgdata: any = [];
        data.data.selectedBrand.map((org: any) => {
          orgdata.push(getNewBrand(org.make));
        })
        setSelectBrand(orgdata)
        setOesBrandList([{ "name": "OES", "displayName": "Tradepoint", "brandId": "2195", "brandType": "OES", "paymentBrandCode": "" }])
      } else {
        if (brandsList && brandsList.data && brandsList.data.length > 0) {
          let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
          setOesBrandList(oesBrands);
          if (oesBrands && oesBrands.length > 0)
            setSelectBrand([getNewBrand(oesBrands[0].name)]);
        } else {
          setOesBrandList([{ "name": "OES", "displayName": "Tradepoint", "brandId": "2195", "brandType": "OES", "paymentBrandCode": "" }])
          setSelectBrand([getNewBrand("OES")]);
        }
      }
    } else {
      if (data && data.data && data.data.organizationIds && data.data.organizationIds.length > 0) {
        setOrgIds(data.data.organizationIds.filter((brand: any) => brand && brand.make !== 'Famco'));
        let orgdata: any = [];
        data.data.organizationIds.map((org: any) => {
          if (org.organizationId !== '2003')
            orgdata.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make));
        })
        setSelectBrand(orgdata);
      }
    }

    if (data && data.data && Object.keys(data.data.companyDetails).length > 0) {
      setTradeReference(data.data.treadReference);
      if (data && data.data && data.data.isNewCustomer) {
        sessionStorage.setItem('customerType', 'new');
      } else {
        sessionStorage.setItem('customerType', 'existing');
      }
      setCompanyName(data.data.companyDetails.customerFirstName);
      setCustomerLastName(data.data.companyDetails.customerLastName);
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber);
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setSalesRegion(data.data.companyDetails.salesRegion);
      setIbCurrency(data.data.companyDetails.companyCurrencyType);
      setSelectedIBCurrency(data.data.companyDetails.companyCurrencyType);
      if (ibCountryData && ibCountryData.data && ibCountryData.data.countryAndSalesRegion && ibCountryData.data.countryAndSalesRegion.length > 0) {
        const regionData1 = getRegionsByCountryCode(ibCountryData.data.countryAndSalesRegion, data.data.companyDetails.country)
        setRegionData(regionData1);
      }
      if (data && data.data && data.data.isNewCustomer && sessionStorage.getItem('dealerDetailsSaved') === "Yes") {
        setHeadOfficeRegion(data.data.companyDetails.regionCode);
      } else {
        setHeadOfficeRegion(data.data.companyDetails.regionCode);
      }
      setTypeOfTrade(data.data.companyDetails.typeOfTrade);

      if (data && data.data && data.data.isNewCustomer) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
          tradereferenceItems.push({
            name: element.name,
            addresses: element.addresses,
            companyName: element.companyName,
            telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        });

        if (data.data.treadReference.length == 0 && tradeReference.length == 0) {
          tradereferenceItems.push({
            companyName: "",
            name: "",
            addresses: "",
            telephone: ""
          })
          tradeError.push({
            companyNameError: false,
            companyNameErrorMessage: "",
            nameError: false,
            nameErrorMessage: "",
            addressError: false,
            addressErrorMessage: "",
            telephoneError: false,
            telephoneErrorMessage: "",
          })
        }
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
      if (data && data.data && !data.data.isNewCustomer && tradeReference.length == 0) {
        let tradereferenceItems = [...tradeReference];
        let tradeError = [...tradeReferenceErrors];
        tradereferenceItems.push({
          companyName: "",
          addresses: "",
          name: "",
          telephone: ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
        setTradeReferenceErrors(tradeError)
        setTradeReference(tradereferenceItems)
      }
      if (data.data.isNewCustomer) {
        setDealerEmail(data.data.companyDetails.emails && data.data.companyDetails.emails.length > 0 && data.data.companyDetails.emails[0].emailId);
        setTelephoneOffice(data.data.companyDetails.telephones && data.data.companyDetails.telephones.length > 0 && data.data.companyDetails.telephones[0].telephone);
      } else {
        setDealerEmail(data.data.companyDetails.emails && data.data.companyDetails.emails.length > 0 && data.data.companyDetails.emails[0].emailId);
        setTelephoneOffice(data.data.companyDetails.telephones && data.data.companyDetails.telephones.length > 0 && data.data.companyDetails.telephones[0].telephone);
        setHeadOfficeRegion(data.data.companyDetails.regionCode);
      }
      setDealingIn(data.data.companyDetails.dealingIn);
      // setTypeOfTrade(data.data.companyDetails.typeOfTrade === "TRD21" ? "Int. Business" : data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setEstablishedDate(data.data.companyDetails.establishedDate);
    }
    else {
      // }
      // if (data.data && !data.data.isNewCustomer && tradeReference.length == 0) {

      if (ibCountryData && ibCountryData.data && ibCountryData.data.countryAndSalesRegion && ibCountryData.data.countryAndSalesRegion.length > 0) {
        const regionData1 = getRegionsByCountryCode(ibCountryData.data.countryAndSalesRegion, country ? country : customerInfo && customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country)
        setRegionData(regionData1);
      }
      let tradereferenceItems = [...tradeReference];
      let tradeError = [...tradeReferenceErrors];
      if (data && data.data && data.data.treadReference && data.data.treadReference.length == 0 && tradeReference.length == 0) {
        tradereferenceItems.push({
          companyName: "",
          name: "",
          addresses: "",
          telephone: ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
      }
      setTradeReferenceErrors(tradeError)
      setTradeReference(tradereferenceItems)
    }
    if (data && data.data && data.data.addresses && data.data.addresses.length > 0) {
      setAddress(data.data.addresses[0])
      setAddStreet(data.data.addresses[0].streetName)
      setPostalCode(data.data.addresses[0].postalCode)
      setCity(data.data.addresses[0].city);
      setCountry(data.data.addresses[0].country);

      setPoBox(data.data.addresses[0].poBox);
      setLatitude(data.data.addresses[0].lattitude)
      setLongitude(data.data.addresses[0].longitude)
      setEmail(data.data.addresses[0].emailId)
      setDeactivationReason(data.data.addresses[0].deactivationReason)
      setIsDeactivated(data.data.addresses[0].isDeactivated)
      if (data.data.addresses[0].shippingLabels && data.data.addresses[0].shippingLabels.length > 0) setShippingLabels(data.data.addresses[0].shippingLabels);
    }
    if (data && data.data && data.data.isNewCustomer) {
      // setHeadOfficeRegion("");
    } else {
      if (customerInfo && customerInfo.emails && customerInfo.emails.length > 0) {
        setDealerEmail(customerInfo.emails[0].emailId);
      }
      if (customerInfo && customerInfo.valueAddedTaxRegistration) {
        setVatRegistrationNumber(customerInfo.valueAddedTaxRegistration);
      }
      if (customerInfo && customerInfo.telephones && customerInfo.telephones.length > 0) {
        let mobileno: any = customerInfo.telephones.find((item: any) => item.phoneType === "PRIMARYMOBILE")
        let telephone: any = customerInfo.telephones.find((item: any) => item.phoneType === "PRIMARYLANDLINE")
        if (telephone && telephone.telephone) {
          setTelephoneOffice(telephone.telephone);
        }
        if (mobileno && mobileno.telephone) {
          setMobileNumber(mobileno.telephone);
        }
      }
    }
    if (data && data.data && data.data.otherBusinessEstablishments) {
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments);
    }
    if (data && data.data && data.data.otherBrandsOrProductsSold) {
      setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold);
    }
    if (data && data.data && data.data.totalInvesment) {
      setTotalInvestment(data.data.totalInvesment);
    }
    if (data && data.data && data.data.initialStockOrder) {
      setInitalStockOrder(data.data.initialStockOrder);
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
      if (data.data && data.data.organizationIds && data.data.organizationIds.length > 0) {
        let orgdata: any = [];
        data.data.organizationIds.map((org: any) => {
          if (org.organizationId !== '2003')
            orgdata.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make));
        })
        setSelectBrand(orgdata);
      }
    }
    if (data.data && data.data.initialStockOrder) {
      setCreditLimit(data.data.initialStockOrder);

    }
    if (data.data && data.data.totalInvesment) {
      setCreditLimit(data.data.totalInvesment);

    }
    if (data.data && data.data.otherBusinessEstablishments) {
      setCreditLimit(data.data.otherBusinessEstablishments);

    }
    if (data.data && data.data.otherBrandsOrProductsSold) {
      setCreditLimit(data.data.otherBrandsOrProductsSold);

    }
    if (sessionStorage.getItem('customerType') === "new" && sessionStorage.getItem('dealerDetailsSaved') !== "Yes") {
      setHeadOfficeRegion("");
      setSelectedRegion("");
    } else {
    }
    if (isExistingUser || (data.data && data.data.status === 'Submitted' && !customerType)) dispatch(setCustomerType('existing'));
    setLoading(false);
  }

  function isDisabled() {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') {
      return true;
    } else {
      return false;
    }
  }


  const getSalesRegion = (arr: any, country1?: any) => {
    let newCountry: any = country1 ? country1 : country;
    let filtered = arr.filter((obj: any) => obj.countryCode === newCountry);
    if (filtered.length > 0) {
      setSalesRegion(filtered[0].salesRegion)
    }
  }

  useEffect(() => {
    if (headOfficeRegion !== '' && userType !== 'FSE') {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if (r.Code === headOfficeRegion) {
          setHeadOfficeRegion(r.Code);
          setSelectedRegion(r);
        }
      })
    }
    if ((userType === 'FSE' || sessionStorage.getItem('customerOnboardingType') === "Int. Business") && !isChangeCountry) {
      regionDataNew && regionDataNew.length > 0 && regionDataNew.map((r: any) => {
        if (r.Code === headOfficeRegion) {
          setHeadOfficeRegion(r.Code);
          setSelectedRegion(r);
          // getSalesRegion(getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion, r.Code)
        }
      })
    }
  }, [headOfficeRegion, regionData, regionDataNew]);
  useEffect(() => {
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      let countryDataDetails: any = [];
      countryDataDetails = getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion.sort((a: any, b: any) => {
        if (a.countryName < b.countryName) return -1;
        if (a.countryName > b.countryName) return 1;
        return 0;
      });
      let unique: any = [];
      let uniqueCountry: any = [];
      if (countryDataDetails != null) {
        uniqueCountry = countryDataDetails.filter((item: any) => {
          const { countryName } = item;
          if (!unique.includes(countryName)) {
            unique.push(countryName);
            return item;
          }
        })
      }

      // let data: any = [... new Set(countryDataDetails)];
      setCountryData({ countries: uniqueCountry });
    }
  }, [getIBCountryAndRegionData]);

  const brands = [
    // {
    //   "make": "Famco",
    //   "organizationId": "2003",
    // },
    {
      "make": "Honda",
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "organizationId": "2001",
    },
    {
      "make": "OES",
      "organizationId": "2195",
    }
  ]

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, errorErrorMessage?: any, type?: any) => {
    props.setDataUpdated(true);
    setterName(e.target.value);

    if (type == 'country') {
      setSalesRegion('');
      setIsChangeCountry(true);
      if (getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion && getIBCountryAndRegionData.data.countryAndSalesRegion.length > 0) {
        getSalesRegion(getIBCountryAndRegionData.data.countryAndSalesRegion, e.target.value || country);
        const regionArray = getRegionsByCountryCode(getIBCountryAndRegionData.data.countryAndSalesRegion, e.target.value || country)
        setRegionData(regionArray);
      }
      setHeadOfficeRegion('');
      setSelectedRegion(null);
    }
    if (type == 'region') {
      setIsChangeCountry(false);
    }
    if (type === 'brand') {
      // setterName(e.target.value);
      errorSetterName(false);
    }
    else if (type === 'intialStock') {
      props.setDataUpdated(true);
      setCreditLimit(e.target.value)
      errorSetterName(false);
    }
    else if (type === "brandproductsold") {
      props.setDataUpdated(true);
    }
    else if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (type === "vatReg") {
        props.setDataUpdated(true);
        if (e.target.value != "") {
          if (!validateForm(e.target.value, "vatReg")) {
            errorErrorMessage(ValidationErrorMessage.TAX_REGISTRATION_TEXT)
            errorSetterName(true);
          }
          else {
            errorErrorMessage("")
            errorSetterName(false);
          }
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
    }
    if (type === "telephone") {
      props.setDataUpdated(true);
      if ((sessionStorage.getItem('customerOnboardingType') !== "Int. Business") || (customer && customer !== "Government")) {
        if (e.target.value != "") {
          //if ((e.target.value.charAt(0) != "5" || e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
          if (userType === "FSE" && customer === "Int. Business" && country === "AE") {
            if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
              errorErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
              errorSetterName(true);
            }
            //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
            else if (e.target.value.charAt(0) != "0") {
              //errorErrorMessage("It should be start with 5 or 0.")
              errorErrorMessage("It should be start with 0.")
              errorSetterName(true);
            }
            else if (e.target.value.length > 9) {
              errorErrorMessage("It should not exceed 9 digits.")
              setTelephoneOfficeError(true);
            }
            else {
              errorErrorMessage("")
              errorSetterName(false);
            }
          } else if (userType !== "FSE" && customer !== "Int. Business") {
            if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
              errorErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
              errorSetterName(true);
            }
            //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
            else if (e.target.value.charAt(0) != "0") {
              //errorErrorMessage("It should be start with 5 or 0.")
              errorErrorMessage("It should be start with 0.")
              errorSetterName(true);
            }
            else if (e.target.value.length > 9) {
              errorErrorMessage("It should not exceed 9 digits.")
              setTelephoneOfficeError(true);
            }
            else {
              errorErrorMessage("")
              errorSetterName(false);
            }
          } else {
            errorErrorMessage("")
            errorSetterName(false);
          }
        }
      } else if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
        if (e.target.value != "") {
          if (e.target.value.length > 30) {
            errorErrorMessage("It should not exceed 30 digits.")
            setTelephoneOfficeError(true);
          }
          else {
            errorErrorMessage("")
            errorSetterName(false);
          }
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "mobileno") {
      setMobileNumberError(validateMobile(e.target.value) === false ? true : false);
    }
    else if (type === "companyName") {
      props.setDataUpdated(true);
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          errorErrorMessage(ValidationErrorMessage.LENGTH)
          errorSetterName(true);
        }

        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
    }
    else if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (type === "pobox") {
        props.setDataUpdated(true);
        if (e.target.value != "") {
          if (e.target.value.length > 7) {
            errorErrorMessage(ValidationErrorMessage.POBOX_TEXT);
            errorSetterName(true);
          }
          else {
            errorErrorMessage("")
            errorSetterName(false);
          }
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
    }
    else {
      if (errorSetterName && type !== 'brand' && typeof e.target.value === "string") {
        if (e.target.value === "" || (e.target.value && e.target.value.trim() === "")) {
          errorSetterName(true);
        }
        else {
          errorErrorMessage("");
          errorSetterName(false);
        }
      }
    }
  }

  function validateMobile(number: string) {
    const re = sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? /^\d{5,30}$/ : /^\d{10}$/;
    return re.test(number);
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };


  const tradeReferenceItem = (e: any, index: any, type: any) => {
    let trade = [...tradeReference];
    let tradeErrors = [...tradeReferenceErrors];
    if (type === "companyName") {
      setDataUpdated(true);
      trade[index].companyName = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].companyNameError = true;
          tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        }
        else {
          tradeErrors[index].companyNameError = false;
          tradeErrors[index].companyNameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].companyNameError = false;
        tradeErrors[index].companyNameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }
    else if (type === "name") {
      setDataUpdated(true);
      trade[index].name = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].nmeError = true;
          tradeErrors[index].nameErrorMessage = ValidationErrorMessage.COMPANY_NAME_TEXT;
        }
        else {
          tradeErrors[index].nameError = false;
          tradeErrors[index].nameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].nameError = false;
        tradeErrors[index].nameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }
    else if (type === "addresses") {
      trade[index].addresses = e.target.value;
      tradeErrors[index].addressError = false;
      tradeErrors[index].addressErrorMessage = "";
      setDataUpdated(true);
      // if (e.target.value != "") {
      //   if (!validateForm(e.target.value, "addresses")) {
      //     tradeErrors[index].addressError = true;
      //     tradeErrors[index].addressErrorMessage = ValidationErrorMessage.SPECIAL_CHARACTERS;
      //   }
      //   else {
      //     tradeErrors[index].addressError = false;
      //     tradeErrors[index].addressErrorMessage = "";
      //   }
      // }
      // else {
      //   tradeErrors[index].addressError = false;
      //   tradeErrors[index].addressErrorMessage = "";
      // }
    }
    else if (type == "telephone") {
      setDataUpdated(true);
      trade[index].telephone = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 20) {
          tradeErrors[index].telephoneError = true;
          tradeErrors[index].telephoneErrorMessage = ValidationErrorMessage.ANNUAL_TURNOVER_TEXT;
        }
        else {
          tradeErrors[index].telephoneError = false;
          tradeErrors[index].telephoneErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].telephoneError = false;
        tradeErrors[index].telephoneErrorMessage = "";
      }
    }
    setTradeReferenceErrors(tradeErrors);
    setTradeReference(trade)
  }

  function validateForm(value: any, type: string) {
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      switch (type) {
        case "country":
        // case "bussinessEst":
        case "brandproductsold":
        case "brands":
          {
            let re = /^[a-z\d\-_\s]+$/i;
            return !re.test(value) ? false : true;
          }
        // case "companyName":
        // case "address": {
        //   let re = /^[a-zA-Z0-9 ]$/;
        //   return !re.test(value) ? false : true;
        // }

        case "vatReg": {
          const re = /^[0-9]{15}$/;
          return !re.test(value) ? false : true;
        }

        case "telephone":
          {
            const re = customerAcceessForIB ? /^[0-9]{5,30}$/ : /^[0-9]{9}$/;
            return !re.test(value) ? false : true;
          }

        case "street":
          {
            let re = /^[a-zA-Z0-9 ]{1,250}$/;
            return !re.test(value) ? false : true;
          }
      }
    }
  }

  function validateExistingTab() {
    setOpenErrorSnackbar(false);
    handleopenCloseSnackbar(false);
    let isValid: boolean = true;
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!telephoneOffice) || (telephoneOffice && telephoneOffice.trim() === '')) {
        setTelephoneOfficeError(true);
        isValid = false;
      }
    }

    // if ((!mobileNumber) || (mobileNumber && mobileNumber.trim() === '')) {
    //   setMobileNumberError(true);
    //   isValid = false;
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer === "Government") {
        setDealingInError(false);
      } else if ((!dealingIn) || (dealingIn && dealingIn.trim() === '')) {
        setDealingInError(true);
        isValid = false;
      }
    }
    if (customer === "Fleet" && dealingIn || customer === "Workshop" && dealingIn) {
      setDealingInError(false);
    }
    // if ((!typeOfTrade) || (typeOfTrade && typeOfTrade.trim() === '')) {
    //   setTypeOfTradeError(true);
    //   isValid = false;
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (!establishedDate) {
          setEstablishedDateError(true);
          isValid = false;
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government" || customer !== "Fleet") {
        if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
          if (error) {
            isValid = false;
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (establishedDate) {
          var varDate = new Date(establishedDate);
          var today = new Date();
          if (varDate > today) {
            setInvalidDate(true);
            setError('Date should be less than today date.')
            setEstablishedDateError(true);
            isValid = false;
          }
          else {
            setEstablishedDateError(false);
            setInvalidDate(false);
            setError(null)
          }
        }
      }
    }

    if ((userType === 'FSE' || sessionStorage.getItem('userType') === "FSE") && sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      if (ibCurrency === "") {
        isValid = false;
        setIbCurrencyError(true);
        // setOpenErrorSnackbar(true);
        // handleopenCloseSnackbar(true);
        // setSuccessMessage('Please Select Currency Type');
      }
    }
    if (getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length === 0) {
      isValid = false;
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage('Please add atleast one Owner/Partner');
    }
    return isValid;
  }

  function validateNewDealerDetailsTab() {
    let isValid: boolean = true;
    if (!validateExistingTab()) {
      isValid = false;
    }
    if (companyName === "") {
      setCompanyNameError(true);
      isValid = false;
    }
    else if (companyName.length > 250) {
      setCompanyNameError(true);
      isValid = false;
    }
    if (selectBrand.length === 0) {
      setSelectBrandError(true);
      isValid = false;
    }
    else {
      setSelectBrandError(false);
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
        if (vatRegistrationNumber.trim() == "") {
          setVatRegistrationNumberError(true);
          isValid = false;
        }
        if (vatRegistrationNumber != "") {
          if (!validateForm(vatRegistrationNumber, 'vatReg')) {
            setVatRegistrationNumberError(true);
            isValid = false;
          }
        }
      }
    }

    // if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
    if (telephoneOffice && typeof telephoneOffice === 'string' && (telephoneOffice.trim() == "" || telephoneOffice.length > 30)) {
      setTelephoneOfficeError(true);
      isValid = false;
    }
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (userType === "FSE" && customer === "Int. Business" && country === "AE") {
          if (telephoneOffice && typeof telephoneOffice === 'string') {
            //if ((telephoneOffice.charAt(0) != "5" || telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
            if ((telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
              setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
              setTelephoneOfficeError(true);
              isValid = false;
            }
            //else if (telephoneOffice.charAt(0) != "5" && telephoneOffice.charAt(0) != "0") {
            else if (telephoneOffice.charAt(0) != "0") {
              //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
              setTelephoneOfficeErrorMessage("It should be start with 0.")
              setTelephoneOfficeError(true);
              isValid = false;
            }
            else if (telephoneOffice.length > 9) {
              setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
              setTelephoneOfficeError(true);
              isValid = false;
            }
            else {
              setTelephoneOfficeErrorMessage("")
              setTelephoneOfficeError(false);
            }
          }
        } else if (userType !== "FSE" && customer !== "Int. Business") {
          if (telephoneOffice && typeof telephoneOffice === 'string') {
            //if ((telephoneOffice.charAt(0) != "5" || telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
            if ((telephoneOffice.charAt(0) != "0") && telephoneOffice.length > 9) {
              setTelephoneOfficeErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
              setTelephoneOfficeError(true);
              isValid = false;
            }
            //else if (telephoneOffice.charAt(0) != "5" && telephoneOffice.charAt(0) != "0") {
            else if (telephoneOffice.charAt(0) != "0") {
              //setTelephoneOfficeErrorMessage("It should be start with 5 or 0.")
              setTelephoneOfficeErrorMessage("It should be start with 0.")
              setTelephoneOfficeError(true);
              isValid = false;
            }
            else if (telephoneOffice.length > 9) {
              setTelephoneOfficeErrorMessage("It should not exceed 9 digits.")
              setTelephoneOfficeError(true);
              isValid = false;
            }
            else {
              setTelephoneOfficeErrorMessage("")
              setTelephoneOfficeError(false);
            }
          }
        } else {
          setTelephoneOfficeErrorMessage("")
          setTelephoneOfficeError(false);
        }
      }
    }
    if ((!country) || (country && country.trim() === undefined)) {
      setCountryError(true);

      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
          if (customer !== "Fleet") {
            if ((!totalInvestment) || (totalInvestment && totalInvestment.trim() === '')) {
              setTotalInvestmentError(true);
              isValid = false;
            }
            else {
              setTotalInvestmentError(false);
            }
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
        if (customer !== "Government") {
          if ((!initalStockOrder) || (initalStockOrder && initalStockOrder.trim() === '')) {
            setInitalStockOrderError(true);
            isValid = false;
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      if ((!mobileNumber) || (mobileNumber && mobileNumber.trim() === '') || validateMobile(mobileNumber) === false) {
        setMobileNumberError(true);
        isValid = false;
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
          if (customer !== "Fleet") {
            if ((!creditLimit) || (creditLimit && creditLimit.trim() === '')) {
              setCreditLimitError(true);
              isValid = false;
            }
            if (creditLimit.trim() !== "") {
              setCreditLimitError(false);
              setInvalidCreditLimit(false);
            }
          }
        }
      }
    }
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!addStreet) || (addStreet && addStreet.trim() === '')) {
        setAddStreetError(true);
        isValid = false;
      }
      if (addStreet.trim() !== "") {
        setInvalidAddStreet(false);
        setAddStreetError(false);
      }
    }
    if ((!city) || (city && city.trim() === '')) {
      setCityError(true);
      isValid = false;
    }
    // if (city.trim() !== "") {
    //   if (!(/^[a-z\d\-_\s]+$/i.test(city.trim()))) {
    //     setInvalidCity(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidCity(false);
    //   }
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
        if ((!poBox) || (poBox && poBox.trim() === '')) {
          setPoBoxError(true);
          isValid = false;
        }
        if (poBox.trim() !== "") {
          if (poBox.split('').length > 7 || !(/^[a-z\d\-_\s]+$/i.test(poBox.trim()))) {
            setInvalidPoBox(true);
            isValid = false;
          }
          else {
            setInvalidPoBox(false);
          }
        }
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (customer !== "Fleet") {
          if (customer && customer !== "Workshop") {
            if ((!postalCode) || (postalCode && postalCode.trim() === '')) {
              setPostalCodeError(true);
              isValid = false;
            }
            else {
              setPostalCodeError(false);
            }
          }
        }
      }
    }
    if (dealerEmail.trim() == "") {
      setDealerEmailError(true);
      isValid = false;
    }
    if (dealerEmail.trim() != "") {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dealerEmail.trim()))) {
        setInvalidDealerEmail(true);
        isValid = false;
      }
      else {
        setInvalidDealerEmail(false);
      }
    }
    // if (otherBusinessEstablishments.trim() == "") {
    //   // if (!validateForm(otherBusinessEstablishments, 'bussinessEst')) {
    //     // setOtherBusinessEstablishmentsErrorMessage(ValidationErrorMessage.SPECIAL_CHARACTERS)
    //     setOtherBusinessEstablishmentsError(true);
    //     isValid = false;
    //   }
    //   else {
    //     setOtherBusinessEstablishmentsError(false);
    //   // }
    // }
    // if (otherBrandsProductsSold.trim() !== "") {
    //   if (!(/^[a-z\d\-_\s]+$/i.test(otherBrandsProductsSold.trim()))) {
    //     setInvalidOtherBrandsProductsSold(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidOtherBrandsProductsSold(false);
    //   }
    // }

    if (sessionStorage.getItem('customerType') === 'new' && customer === "Fleet" && dealerEmail || sessionStorage.getItem('customerType') === 'new' && customer === "Workshop" && dealerEmail) {
      setDealerEmailError(false);
    }
    if (sessionStorage.getItem('customerType') === 'new' && customer === "Fleet" && headOfficeRegion || sessionStorage.getItem('customerType') === 'new' && customer === "Workshop" && headOfficeRegion) {
      setHeadOfficeRegionError(false);
    }
    if (sessionStorage.getItem('customerType') === 'new' && customer === "Fleet" && country || sessionStorage.getItem('customerType') === 'new' && customer === "Workshop" && country) {
      setCountryError(false);
    }
    if (sessionStorage.getItem('customerType') === 'new' && customer === "Fleet" && city || sessionStorage.getItem('customerType') === 'new' && customer === "Workshop" && city) {
      setCityError(false);
    }
    if (customer === "Government" && headOfficeRegion) {
      setHeadOfficeRegionError(false);
    }
    if (customer === "Government" && dealerEmail) {
      setDealerEmailError(false);
    }
    if (customer === "Government" && city) {
      setCityError(false);
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (customer !== "Government") {
        if (sessionStorage.getItem('customerOnboardingType') !== "Workshop") {
          if (creditLimit.trim() == "") {
            setCreditLimitError(true);
            isValid = false;
          }
          else {
            setCreditLimitError(false);
          }
        }
      }
    }
    if (customer !== "Government") {
      if (customer !== "Workshop") {
        let trade1 = [...tradeReference];
        let tradeError = [...tradeReferenceErrors]
        trade1 && trade1.length > 0 && trade1.forEach((element: any, index: any) => {
          if (element.companyName == "" || element.companyName.length > 250) {
            isValid = false;
            tradeError[index].companyNameError = true;
          }
          else {
            tradeError[index].companyNameError = false;
          }
          if (element.name == "") {
            isValid = false;
            tradeError[index].nameError = true;
          }
          else {
            tradeError[index].nameError = false;
          }
          if (element.addresses == "") {
            tradeError[index].addressError = true;
            isValid = false;
          }
          else {
            tradeError[index].addressError = false;
          }
          if (element.telephone == "" || element.telephone.length > 20) {
            tradeError[index].telephoneError = true;
            isValid = false;
          }
          else {
            tradeError[index].telephoneError = false;
          }
        })
      }
    }
    return isValid;
  }

  // ON CHANGE OF DATE VALIDTIONS
  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        if (sessionStorage.getItem('customerOnboardingType') !== "Government") {
          setError("Invalid Date Format");
          state(true);
        }
      }
    } else {
      if (establishedDate) {
        var varDate = new Date(establishedDate);
        var today = new Date();
        if (varDate >= today) {
          setInvalidDate(true);
          setError('It should be less than today date.')
          setEstablishedDateError(true);
        }
        else {
          setEstablishedDateError(false);
          state(false);
          setError(null)
        }
      }
    }
  }

  // ON CLICK OF SAVE FORM VALIDATIONS & API INTEGRATIONS
  async function saveDetails(isSubmitted?: any) {
    let region: any;
    if (headOfficeRegion) {
      if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
        regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
          if ((r.Code === headOfficeRegion) || (r.Region === headOfficeRegion)) {
            setHeadOfficeRegion(r.Code);
            region = r;
            setSelectedRegion(r);
          }
        })
      } else {
        regionDataNew && regionDataNew.length > 0 && regionDataNew.map((r: any) => {
          if ((r.Code === headOfficeRegion) || (r.Region === headOfficeRegion)) {
            setHeadOfficeRegion(r.Code);
            region = r;
            setSelectedRegion(r);
          }
        })
      }

    }
    setDataUpdated(false)
    props.setDataUpdated(false);
    if (sessionStorage.getItem('customerOnboardingType') === 'Workshop') {
      if (sessionStorage.getItem('customerType') === 'existing') {
        if (validateExistingTab()) {
          let selectedBrands: any[] = [];
          customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.organizationId === element.organizationId)[0]);
          });
          if (headOfficeAddress) {
            headOfficeAddress.companyName = customerInfo.customerFirstName ? customerInfo.customerFirstName + '' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerFirstName = customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerLastName = customerInfo.customerLastName ? customerInfo.customerLastName : "";
            headOfficeAddress.telephoneNumber = telephoneOffice ? telephoneOffice : customerInfo.telephones && customerInfo.telephones.length > 0 && customerInfo.telephones[0].phoneType === 'PRIMARYLANDLINE' ? customerInfo.telephones[0].telephone : customerInfo.companyDetails && customerInfo.companyDetails.telephones && customerInfo.companyDetails.telephones.length > 0 && customerInfo.companyDetails.telephones[0].telephone ? customerInfo.companyDetails.telephones[0].telephone : '';
            headOfficeAddress.poBox = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox;
            headOfficeAddress.city = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city;
            headOfficeAddress.country = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country;
            headOfficeAddress.streetName = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '';
            headOfficeAddress.postalCode = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '';
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = true;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.id = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].id ? customerInfo.addresses[0].id : getUniqueTimestamp().toString();
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = userType.toLowerCase() === 'fse' ? 'fse' : "kam"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.shippingLabels = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].shippingLabels ? customerInfo.addresses[0].shippingLabels : shippingLabels;
          }
          let telephone: any;

          if (customerInfo.telephones && customerInfo.telephones.length > 0) {
            telephone = customerInfo.telephones.filter((item: any) => item.phoneType === 'PRIMARYLANDLINE');
            if (telephone && telephone.length === 0) {
              telephone = [{
                "country": country,
                telephone: telephoneOffice && typeof telephoneOffice === 'string' ? telephoneOffice : "",
                "phoneType": "PRIMARYMOBILE"
              }
              ]
            }
          }
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            "companyDetails": {
              "title": customerInfo && customerInfo.title,
              "companyName": customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerFirstName": customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerLastName": customerInfo.customerLastName ? customerInfo.customerLastName : '',
              "vatRegistrationNumber": vatRegistrationNumber ? vatRegistrationNumber : customerInfo.valueAddedTaxRegistration ? customerInfo.valueAddedTaxRegistration : '',
              "telephones": telephone ? telephone : customerInfo.companyDetails && customerInfo.companyDetails.telephones ? customerInfo.companyDetails.telephones : '',
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": customerInfo.emails ? customerInfo.emails : customerInfo.companyDetails && customerInfo.companyDetails.emails,
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "mobileNumber": mobileNumber,
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox,
              "taxRegistrationNo": "",
              "country": country,
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            // "organizationIds": selectedBrands.filter((brand: any) => brand),
            "organizationIds": [
              {
                "make": "OES",
                "organizationId": "2195"
              }
            ],
            "selectedBrand": [
              {
                "make": "OES",
                "materilGroup": [],
                "organizationId": "2195"
              },
              {
                "make": "Toyota",
                "materilGroup": [],
                "organizationId": "2001"
              },
              {
                "make": "Honda",
                "materilGroup": [],
                "organizationId": "2002"
              },
              {
                "make": "TE",
                "materilGroup": [],
                "organizationId": "2380"
              }

            ],
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": [],
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
            setDataUpdated(false)
            props.setDataUpdated(false);
            sessionStorage.setItem('dealerDetailsSaved', "Yes");
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          // if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
          // } else {
          //   if ((!telephoneOffice && telephoneOfficError) || (!mobileNumber && mobileNumberError)) {
          //     setOpenErrorSnackbar(true);
          //     handleopenCloseSnackbar(true);
          //     setSuccessMessage('Telephone Office and Mobile number is mandatory');
          //   } else {
          //     setOpenErrorSnackbar(true);
          //     handleopenCloseSnackbar(true);
          //     setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
          //   }
          // }
        }
      }
      else if (sessionStorage.getItem('customerType') === 'new') {
        if (validateNewDealerDetailsTab()) {
          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "name": element.name,
                "companyName": element.companyName,
                "addresses": element.addresses,
                "telephones": [{
                  "country": country,
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })
          let oes: any[] = [];
          oesBrandList && oesBrandList.length > 0 && oesBrandList.forEach((element: any) => {
            oes.push(brands.filter((item: any) => item.make === element.name)[0]);
          });


          let selectedBrands: any[] = [];
          selectBrand && selectBrand.length > 0 && selectBrand.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.make === getActualBrand(element))[0]);
          });
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.streetName = addStreet;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code;
            headOfficeAddress.dafault = true;
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.isExisting = false;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
            "isNewCustomer": true,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            "companyDetails": {
              "title": 'M/s',
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "telephones": [
                {
                  "country": country,
                  "telephone": telephoneOffice,
                  "phoneType": "PRIMARYMOBILE"
                }
              ],
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "country": country,
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": [
                {
                  "emailId": dealerEmail,
                  "emailType": "PRIMARY"
                }
              ],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": poBox,
              "mobileNumber": mobileNumber,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            // "organizationIds": selectedBrands.filter((brand: any) => brand),
            "organizationIds": oes.filter((brand: any) => brand),
            "selectedBrand": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : creditLimitSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": traderefe,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            setDataUpdated(false);
            props.setDataUpdated(false);
            sessionStorage.setItem('dealerDetailsSaved', "Yes");
            if (isSubmitted) props.setTabValue(1);
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    } else {
      if (sessionStorage.getItem('customerType') === 'existing') {
        if (validateExistingTab()) {
          let selectedBrands: any[] = [];
          customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.organizationId === element.organizationId)[0]);
          });
          if (headOfficeAddress) {
            headOfficeAddress.companyName = customerInfo.customerFirstName ? customerInfo.customerFirstName + '' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerFirstName = customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '';
            headOfficeAddress.customerLastName = customerInfo.customerLastName ? customerInfo.customerLastName : "";
            headOfficeAddress.telephoneNumber = telephoneOffice ? telephoneOffice : customerInfo.telephones && customerInfo.telephones.length > 0 && customerInfo.telephones[0].phoneType === 'PRIMARYLANDLINE' ? customerInfo.telephones[0].telephone : customerInfo.companyDetails && customerInfo.companyDetails.telephones && customerInfo.companyDetails.telephones.length > 0 && customerInfo.companyDetails.telephones[0].telephone ? customerInfo.companyDetails.telephones[0].telephone : '';
            headOfficeAddress.poBox = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox;
            headOfficeAddress.city = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].city;
            headOfficeAddress.country = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].country;
            headOfficeAddress.streetName = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '';
            headOfficeAddress.postalCode = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '';
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code ? selectedRegion.Code : '';
            headOfficeAddress.dafault = true;
            headOfficeAddress.isExisting = true;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.id = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].id ? customerInfo.addresses[0].id : getUniqueTimestamp().toString();
            headOfficeAddress.lattitude = latitude
            headOfficeAddress.longitude = longitude
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.addedBy = b2bUserId
            headOfficeAddress.addedByRole = userType.toLowerCase() === 'fse' ? 'fse' : "kam"
            headOfficeAddress.deactivationReason = deactivationReason
            headOfficeAddress.isDeactivated = isDeactivated
            headOfficeAddress.shippingLabels = customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].shippingLabels ? customerInfo.addresses[0].shippingLabels : shippingLabels;
          }
          let telephone: any;

          if (customerInfo.telephones && customerInfo.telephones.length > 0) {
            telephone = customerInfo.telephones.filter((item: any) => item.phoneType === 'PRIMARYLANDLINE');
            if (telephone && telephone.length === 0) {
              telephone = [{
                "country": country,
                telephone: telephoneOffice && typeof telephoneOffice === 'string' ? telephoneOffice : "",
                "phoneType": "PRIMARYMOBILE"
              }
              ]
            }
          }
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.customerCode ? customerInfo.customerCode : customerInfo && customerInfo.sapCustomerId,
            "isNewCustomer": false,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            "companyDetails": {
              "title": customerInfo && customerInfo.title,
              "companyName": customerInfo.customerFirstName ? customerInfo.customerFirstName + ' ' + customerInfo.customerLastName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerFirstName": customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails ? customerInfo.companyDetails.companyName : '',
              "customerLastName": customerInfo.customerLastName ? customerInfo.customerLastName : '',
              "vatRegistrationNumber": vatRegistrationNumber ? vatRegistrationNumber : customerInfo.valueAddedTaxRegistration ? customerInfo.valueAddedTaxRegistration : '',
              "telephones": telephone ? telephone : customerInfo.companyDetails && customerInfo.companyDetails.telephones ? customerInfo.companyDetails.telephones : '',
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": customerInfo.emails ? customerInfo.emails : customerInfo.companyDetails && customerInfo.companyDetails.emails,
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "mobileNumber": mobileNumber,
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].poBox,
              "taxRegistrationNo": "",
              "country": country,
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": [],
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            if (isSubmitted) props.setTabValue(1);
            setDataUpdated(false)
            props.setDataUpdated(false);
            sessionStorage.setItem('dealerDetailsSaved', "Yes");
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          // if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
          // } else {
          //   if ((!telephoneOffice && telephoneOfficError) || (!mobileNumber && mobileNumberError)) {
          //     setOpenErrorSnackbar(true);
          //     handleopenCloseSnackbar(true);
          //     setSuccessMessage('Telephone Office and Mobile number is mandatory');
          //   } else {
          //     setOpenErrorSnackbar(true);
          //     handleopenCloseSnackbar(true);
          //     setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
          //   }
          // }
        }
      }
      else if (sessionStorage.getItem('customerType') === 'new') {
        if (validateNewDealerDetailsTab()) {
          let traderefe: any[] = [];
          tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
            if (element.companyName && element.addresses && element.telephone) {
              traderefe.push({
                "title": "M/s",
                "name": element.name,
                "companyName": element.companyName,
                "addresses": element.addresses,
                "telephones": [{
                  "country": country,
                  telephone: element.telephone ? element.telephone : "",
                  "phoneType": "PRIMARYMOBILE"
                }
                ]
              })
            }
          })
          let selectedBrands: any[] = [];
          selectBrand && selectBrand.length > 0 && selectBrand.forEach((element: any) => {
            selectedBrands.push(brands.filter((item: any) => item.make === getActualBrand(element))[0]);
          });
          if (headOfficeAddress) {
            headOfficeAddress.companyName = companyName + ' ' + customerLastName;
            headOfficeAddress.customerFirstName = companyName;
            headOfficeAddress.customerLastName = customerLastName;
            headOfficeAddress.telephoneNumber = telephoneOffice;
            headOfficeAddress.poBox = poBox;
            headOfficeAddress.city = city;
            headOfficeAddress.country = country;
            headOfficeAddress.mobileNumber = mobileNumber;
            headOfficeAddress.salesRegion = salesRegion;
            headOfficeAddress.streetName = addStreet;
            headOfficeAddress.postalCode = postalCode;
            headOfficeAddress.region = region && region.Code ? region.Code : selectedRegion && selectedRegion.Code;
            headOfficeAddress.dafault = true;
            headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
            headOfficeAddress.isExisting = false;
            headOfficeAddress.firstAddress = true;
            headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
            headOfficeAddress.shippingLabels = shippingLabels;
          }
          let data = {
            "b2bUserId": b2bUserId,
            "sapCustomerId": customerInfo && customerInfo.sapCustomerId ? customerInfo.sapCustomerId : '',
            "isNewCustomer": true,
            "customerTierType": sessionStorage.getItem('customerOnboardingType'),
            "companyDetails": {
              "title": 'M/s',
              "companyName": companyName + ' ' + customerLastName,
              "customerFirstName": companyName,
              "customerLastName": customerLastName,
              "vatRegistrationNumber": vatRegistrationNumber,
              "telephones": [
                {
                  "country": country,
                  "telephone": telephoneOffice,
                  "phoneType": "PRIMARYMOBILE"
                }
              ],
              "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
              "country": country,
              "region": region && region.Region ? region.Region : selectedRegion && selectedRegion.Region,
              "regionCode": region && region.Code ? region.Code : selectedRegion && selectedRegion.Code,
              "salesRegion": salesRegion ? salesRegion : '',
              "emails": [
                {
                  "emailId": dealerEmail,
                  "emailType": "PRIMARY"
                }
              ],
              "dealingIn": dealingIn,
              "tradeLicenseNo": "",
              "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
              "companyAnnualTurnover": annualTurnover,
              "typeOfTrade": typeOfTrade,
              "poBox": poBox,
              "mobileNumber": mobileNumber,
              "taxRegistrationNo": "",
              "lobId": "AUTO",
              "orgId": "2001",
              "channelId": "EVENT"
            },
            "addresses": [headOfficeAddress],
            "organizationIds": selectedBrands.filter((brand: any) => brand),
            "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : creditLimitSelector,
            "initialStockOrder": initalStockOrder,
            "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
            "totalInvesment": totalInvestment,
            "otherBusinessEstablishments": otherBusinessEstablishments,
            "otherBrandsOrProductsSold": otherBrandsProductsSold,
            "treadReference": traderefe,
          }
          const savedData: any = await dispatch(saveDealerDetails(data));
          if (savedData && savedData.message && savedData.message.status === 'S') {
            setSuccessMessage(savedData.message.message)
            handleopenCloseSnackbar(true);
            setDataUpdated(false);
            props.setDataUpdated(false);
            sessionStorage.setItem('dealerDetailsSaved', "Yes");
            if (isSubmitted) props.setTabValue(1);
          } else {
            setOpenErrorSnackbar(true);
            handleopenCloseSnackbar(true);
            setSuccessMessage(savedData.error);
          }
        } else {
          setOpenErrorSnackbar(true);
          handleopenCloseSnackbar(true);
          setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
        }
      }
    }
  }

  const handleNextClick = async () => {
    saveDetails(true);
  }

  // useEffect(() => {
  //   if (brandsList && brandsList.data && brandsList.data.length > 0) {
  //     let data: any;
  //     if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
  //       data = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco" && brand.brandId !== '2195');
  //     } else {
  //       data = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
  //     }
  //     let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
  //     let workshopExist: any = brandsList.data.filter((brand: any) => brand.displayName !== "Famco" && brand.displayName !== 'All');
  //     setBrandList(data);
  //     // setOesBrandList(oesBrands);
  //     // setExistWorkShopBrand(workshopExist)
  //   }
  // }, [brandsList])

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      if (sessionStorage.getItem('customerOnboardingType') === 'workshop' || sessionStorage.getItem('customerOnboardingType') === 'fleet' || sessionStorage.getItem('customerOnboardingType') === 'Fleet' || sessionStorage.getItem('customerOnboardingType') === 'Government' || sessionStorage.getItem('customerOnboardingType') === 'government' || sessionStorage.getItem('customerOnboardingType') === 'Dealer' || sessionStorage.getItem('customerOnboardingType') === 'dealer' || sessionStorage.getItem('customerOnboardingType') === 'Workshop') {
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if (onboardingType && onboardingType.toLowerCase() !== 'workshop' && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        // data = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
      } else {
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if (onboardingType && onboardingType.toLowerCase() !== 'workshop' && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });

        // data = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
      }
      setBrandList(data);
      let oesBrands: any = brandsList.data.filter((brand: any) => brand.brandType === 'OES');
      let workshopExist: any = brandsList.data.filter((brand: any) => brand.displayName !== "Famco" && brand.displayName !== 'All');
      setOesBrandList(oesBrands);
      setExistWorkShopBrand(workshopExist)
    }
  }, [brandsList])

  const handleClose = () => {
    setOpen(false);
  };

  function resetValue() {
    handleClose();
    dispatch(resetValues());
  }

  useEffect(() => {
    if (savePartnerData && savePartnerData.data && savePartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(savePartnerData.data.message.message)
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
    }
  }, [savePartnerData]);

  useEffect(() => {
    if (deletePartnerData && deletePartnerData.data && deletePartnerData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deletePartnerData.data.message.message)
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
      dispatch(getAllPartners(b2bUserId));
    }
  }, [deletePartnerData]);

  useEffect(() => {
    if (editPartnerData && editPartnerData.data && editPartnerData.data.message) {
      dispatch(getAllPartners(b2bUserId));
      setOpen(false);
      resetValue();
      setSuccessMessage(editPartnerData.data.message.message)
      setOpenErrorSnackbar(false);
      handleopenCloseSnackbar(true);
    }
  }, [editPartnerData]);

  const updateNewPartnerData = (data: any) => {
    dispatch(editPartnerDetails(data));
  }

  const onClickDeleteAddress = (partnerData: any) => {
    setTypeOfDelete('address')
    setDeleteMessage("Are you sure you want to delete this Owner/Partner ?");
    handleopenClose(true);
    setSelectedPartnerData(partnerData);
  }
  const onClickDeleteTradeRef = () => {
    setTypeOfDelete('trade')
    setDeleteMessage("Are you sure you want to delete this Trade Reference ?");
    handleopenClose(true);
  }

  const deletePartner = () => {
    dispatch(deletePartnerDetails(selectedPartnerData.id, b2bUserId));
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsView(false);
  };

  const setOwnerData = (data: any) => {
    dispatch(savePartnerDetails(data));
  }

  const addTradeRow = () => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors];
    if (!isDisabledTradeRow()) {
      if (trade[trade.length - 1].companyName || trade[trade.length - 1].name || trade[trade.length - 1].address || trade[trade.length - 1].telephone) {
        const data = {
          name: '',
          companyName: "",
          addresses: "",
          telephone: ""
        }
        const errors =
        {
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        }
        // validateDealerDetailsTradeReferance();
        trade.push(data);
        setTradeReference(trade);
        tradeError.push(errors)
        setTradeReferenceErrors(tradeError);
      }
    }
  }

  const deleteTradeRow = (index: any) => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors]

    trade = trade.filter((data, i) => {
      if (index != i) {
        return data;
      }
    })
    tradeError = trade.filter((data, i) => {
      if (i <= trade.length) {
        return data;
      }
    })
    setTradeReferenceErrors(tradeError);
    setTradeReference(trade);
    handleopenClose(false);
  }

  const isDisabledTradeRow = () => {
    let length = tradeReference.length - 1
    if (tradeReference.length && ((tradeReference[length].companyName == "" || tradeReference[length].name == "" || tradeReference[length].addresses == "" || tradeReference[length].telephone == ""))) {
      // if (tradeReference.length == 1) {
      let tradeError = [...tradeReferenceErrors];
      if (tradeReference[length].name == "") {
        tradeError[length].nameError = true;
      }
      else {
        tradeError[length].nameError = false;
      }
      if (tradeReference[length].companyName == "") {
        tradeError[length].companyNameError = true;
      }
      else {
        tradeError[length].companyNameError = false;
      }
      if (tradeReference[length].addresses == "") {
        tradeReferenceErrors[length].addressError = true;
      }
      else {
        tradeReferenceErrors[length].addressError = false;
      }
      if (tradeReference[length].telephone == "") {
        tradeReferenceErrors[length].telephoneError = true;
      }
      else {
        tradeReferenceErrors[length].telephoneError = false;
      }
      setTradeReferenceErrors(tradeError)
      // }
      return true;
    }
    return false;
  }

  const selectAllBrands = () => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      brandsList.data.map((brand: any) => {
        if (brand && brand.displayName) {
          brand.displayName === 'Tradepoint' ? data.push('ALAC') : data.push(brand.displayName)
        }
      });
      setSelectBrand(data);
    }
  }

  const handleSelectAllClick = (event: any, value: any) => {
    if (event.target.checked && value === 'All') {
      selectAllBrands();
    } else if (event.target.checked === false && value === 'All') {
      setBrandList([]);
    }
  };

  //TABLE HEADER CODE
  function EnhancedTableHead(props: any) {
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              className={classes.bodyContent}
              key={headCell.id}
              padding={headCell.disablePadding ? 'none' : 'normal'}
            >
              {/* {headCell.label}
               */}
              {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ?
                headCell.label === "Nationality" ? "" && headCell.label : headCell.label : headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleEditPartnerDetails = (partner: any, isView: any) => {
    dispatch(getPartnerDetails(b2bUserId, partner.id));
    handleClickOpen();
    setIsView(isView);
    setIsEdit(true);
    setEditData(partner);
  }

  const verifySuccess = () => {
    props.loadDealerDetails();
  }

  const handleChange = (event: any) => {
    setSelectedIBCurrency(event.target.value);
    setIbCurrency(event.target.value);
  };

  return (
    <Fragment>
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      {(userType === 'FSE' || sessionStorage.getItem('userType') === "FSE") && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
        <div className="d-flex flex-row border-bottom mb-2">
          <div><h6 className="page-title ml-3 mt-3" style={{ fontSize: "15px" }}>Currency Type :</h6></div>
          <div className="row">
            <FormControl component="fieldset" name="customer-type" className="pl-4">
              <RadioGroup className="mt-2 ml-4" row onChange={handleChange} value={selectedIBCurrency}>
                <FormControlLabel
                  value={"AED"}
                  control={<Radio color="primary" />}
                  label="AED" disabled={sessionStorage.getItem('customerType') === 'existing' ? true : isDisabled()} />
                <FormControlLabel
                  value={"USD"}
                  control={<Radio color="primary" />}
                  label="USD"
                  disabled={sessionStorage.getItem('customerType') === 'existing' ? true : isDisabled()}
                />
              </RadioGroup>
              {ibCurrencyError && sessionStorage.getItem('customerType') !== 'existing' && <span className="px-3" style={{ color: 'red' }}>{"Please select currency type"}</span>}
            </FormControl>
          </div>
          <div></div>
        </div>
        : ""}
      <div className="row m-0">
        <div className="col-12">
          <h6 className="dealer-table-content mb-3 ml-1 mt-3">Company Details</h6>
        </div>
        {loading && (<FullscreenLoader />)}
        {sessionStorage.getItem('customerType') === 'existing' ? (
          <>
            {userType === 'FSE' && (
              <div className="col-md-4 col-lg-4">
                <TextField id="country"
                  disabled
                  required
                  value={country}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Country" variant="outlined" margin="dense">
                </TextField>
              </div>
            )}
            {userType === 'FSE' && customer !== "Fleet" && customer !== "Government" && customer !== "Workshop" && (
              <div className="col-md-4 col-lg-4">
                <TextField id="salesRegion"
                  disabled
                  required
                  value={salesRegion}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="IB Sales Region" variant="outlined" margin="dense">
                </TextField>
              </div>
            )}
            <div className="col-md-4 col-lg-4">
              <TextField id="headOfficeRegion"
                error={headOfficeRegionError}
                helperText={invalidHeadOfficeRegion}
                disabled
                // disabled={isDisabled() ? isDisabled() : customerInfo && customerInfo.addresses && customerInfo.addresses.length > 0 && customerInfo.addresses[0].region ? true : false}
                required
                value={selectedRegion && selectedRegion.Region ? selectedRegion.Region : headOfficeRegion}
                onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion, 'region')}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label={customerTierType === "Workshop" ? "Trade Point Counter Location" : "Head Office Region"} variant="outlined" margin="dense">
              </TextField>
            </div>
            <div className="col-md-4 col-lg-4">
              <KeyboardDatePicker
                required={(sessionStorage.getItem('customerOnboardingType') === "Dealer") ? true : (sessionStorage.getItem('customerOnboardingType') === "Fleet") ? true : (sessionStorage.getItem('customerOnboardingType') === "Workshop") ? true : (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") || (sessionStorage.getItem('customerOnboardingType') !== "Government") ? false : true}
                disableFuture
                views={["year", "month", "date"]}
                openTo="year"
                disabled={isDisabled()}
                autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                onError={(e, v) => datePickerValid(e, setInvalidDate)}
                format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
                onChange={(date: Date | null) => {
                  setDataUpdated(true)
                  if (date) {
                    setEstablishedDate(date);
                    setEstablishedDateError(false)
                  } else {
                    setEstablishedDateError(true)
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="rounded-input-dealer w-100 mt-2 cal-icon"
                error={(sessionStorage.getItem('customerOnboardingType') === "Int. Business") || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ? false : establishedDateError || invalidDate}
                helperText={(sessionStorage.getItem('customerOnboardingType') === "Int. Business") || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ? false : invalidDate && <span style={{ color: 'red' }}>{error}</span>}
              />
            </div>
            {userType === "FSE" && customer === "Government" ? "" :
              <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 onboarding-field">
                {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
                  <TextField disabled defaultValue={ibCurrency} value={ibCurrency} onChange={(e: any) => handleInputChange(e, setSelectedIBCurrency)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                    <MenuItem key={ibCurrency} value={ibCurrency}>{ibCurrency}</MenuItem>
                  </TextField>
                  :
                  <TextField
                    disabled
                    defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>}
                <TextField id="annualTurnover salestoCustomerKam" type="number"
                  value={annualTurnover}
                  // error={annualTurnoverSelectorError}
                  // helperText={invalidAnnualTurnover}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
                  size="small" className="col-sm-9 commonRoundedInputs-textfield aed1-width"
                  label="Annual Turnover" variant="outlined" margin="dense" />
              </div>}
            <div className="col-md-4 col-lg-4">
              <TextField select id="dealingIn"
                error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : customer === "Government" ? false : dealingInError}
                helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : customer === "Government" ? false : invalidDealingIn}
                disabled={isDisabled()}
                required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || customer === "Government" ? false : true}
                value={dealingIn}
                onChange={(e: any) => handleInputChange(e, setDealingIn,
                  setDealingInError, setInvalidDealingIn)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label={customer === "Government" ? "Type of Ownership" : "Dealing In"} variant="outlined" margin="dense" >
                {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                  dealingInData.data[0].regions.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
              </TextField>
            </div>
            <div className="col-md-4 col-lg-4">
              <TextField id="typeOfTrade"
                // error={typeOfTradeError}
                // helperText={invalidTypeOfTrade}
                select
                disabled
                value={typeOfTrade}
                onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Type of Trade" required variant="outlined" margin="dense">
                {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                ))}
              </TextField>
            </div>
            {customerInfo && (
              <div className="row m-0 col-12 mt-3">
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" || customer === "Workshop" ? "Company Name Line 1*" : "Company Name Line 1"}</div>
                  <div className="subTitle mt-2">{customerInfo.title}{' '}{customerInfo.customerFirstName ? customerInfo.customerFirstName : customerInfo.companyDetails && customerInfo.companyDetails.companyName ? customerInfo.companyDetails.companyName : '-'}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">Company Name Line 2</div>
                  <div className="subTitle mt-2">{customerInfo.customerLastName ? customerInfo.customerLastName : '-'}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" ? "VAT Registration No*" : "VAT Registration No"}</div>
                  <div className="subTitle mt-2">{vatRegistrationNumber ? vatRegistrationNumber : customerInfo.valueAddedTaxRegistration ? customerInfo.valueAddedTaxRegistration : '-'}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  {customerTierType === "Workshop" ? <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" || customer === "Workshop" ? "Contact Telephone Number*" : "Contact Telephone Number"}</div> : <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" ? "Telephone Office(Landline No.)*" : "Telephone Office(Landline No.)"}</div>}
                  <div className="subTitle mt-2">{telephoneOffice ? telephoneOffice : customerInfo.telephones && customerInfo.telephones.length > 0 && customerInfo.telephones[0].phoneType === 'PRIMARYLANDLINE' ? customerInfo.telephones[0].telephone : customerInfo && customerInfo.companyDetails && customerInfo.companyDetails.telephones && customerInfo.companyDetails.telephones.length > 0 && customerInfo.companyDetails.telephones[0].telephone ? customerInfo.companyDetails.telephones[0].telephone : '-'}</div>
                </div>
                {(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
                  || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government")
                  || (sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Workshop") ?
                  "" :
                  <div className="col-3 col-md-3 col-sm-3 mb-4">
                    <div className="info-sub-title-value font-size-12">Mobile Number</div>
                    <div className="subTitle mt-2">{mobileNumber ? mobileNumber : customerInfo.mobileNumber ? customerInfo.mobileNumber : customerInfo.companyDetails && customerInfo.companyDetails.mobileNumber ? customerInfo.companyDetails.mobileNumber : '-'}</div>
                  </div>}
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" || customer === "Workshop" ? "Customer Email*" : "Customer Email"}</div>
                  <div className="subTitle mt-2">{customerInfo.emails && customerInfo.emails.length > 0 ? customerInfo.emails[0].emailId : customerInfo && customerInfo.companyDetails && customerInfo.companyDetails.emails && customerInfo.companyDetails.emails.length > 0 && customerInfo.companyDetails.emails[0].emailId ? customerInfo.companyDetails.emails[0].emailId : '-'}</div>
                </div>
                {customerInfo.addresses && customerInfo.addresses.length > 0 && (
                  <>
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" ? "PO Box*" : "PO Box"}</div>
                      <div className="subTitle mt-2">{customerInfo.addresses[0].poBox ? customerInfo.addresses[0].poBox : '-'}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" || customer === "Workshop" ? "Street Name*" : "Street Name"}</div>
                      <div className="subTitle mt-2">{customerInfo.addresses[0].streetName ? customerInfo.addresses[0].streetName : '-'}</div>
                    </div>
                    {userType !== 'FSE' && (
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" || customer === "Workshop" ? "Country*" : "Country"}</div>
                        <div className="subTitle mt-2">{customerInfo.addresses[0].country ? customerInfo.addresses[0].country : '-'}</div>
                      </div>
                    )}
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" || customer === "Workshop" ? "City*" : "City"}</div>
                      <div className="subTitle mt-2">{customerInfo.addresses[0].city ? customerInfo.addresses[0].city : '-'}</div>
                    </div>
                    {(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business")
                      || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
                      || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                      "" :
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">Postal Code</div>
                        <div className="subTitle mt-2">{customerInfo.addresses[0].postalCode ? customerInfo.addresses[0].postalCode : '-'}</div>
                      </div>}
                  </>
                )}
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">{customer === "Fleet" || customer === "Government" ? "Al-Futtaim Customer Number*" : "Al-Futtaim Customer Number"}</div>
                  <div className="subTitle mt-2">{customerInfo.customerCode ? customerInfo.customerCode : customerInfo.sapCustomerId}</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="col-md-4 col-lg-4">
              <TextField required id="companyName"
                value={companyName}
                error={companyNameError}
                inputProps={{ maxLength: 40 }}
                // onChange={(e: any) => onChangeCompany(e)}
                onChange={(e: any) => handleInputChange(e, setCompanyName, setCompanyNameError, setCompanyNameErrorMessage, "companyName")}
                disabled={isDisabled()}
                label="Company Name Line 1" className="commonRoundedInputs w-100"
                helperText={companyNameError && <span style={{ color: 'red' }}>{companyNameErrorMessage}</span>}
                InputProps={{
                  startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                  endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                }} margin="dense" variant="outlined" />
            </div>
            <div className="col-md-4 col-lg-4">
              <TextField id="customerLastName"
                value={customerLastName}
                inputProps={{ maxLength: 40 }}
                onChange={(e: any) => handleInputChange(e, setCustomerLastName, "customerLastName")}
                disabled={isDisabled()}
                label="Company Name Line 2" className="commonRoundedInputs w-100"
                InputProps={{
                  // startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                  endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                }} margin="dense" variant="outlined" />
            </div>
            <div className="col-md-4 col-lg-4">
              <TextField id="vatRegistrationNumber"
                type="number"
                value={vatRegistrationNumber}
                disabled={isDisabled()}
                error={vatRegistrationNumberError}
                helperText={vatRegistrationNumberError && <span style={{ color: 'red' }}>{VatRegistrationNumberErrorMessage}</span>}
                onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                onChange={(e: any) => handleInputChange(e, setVatRegistrationNumber, setVatRegistrationNumberError, setVatRegistrationNumberErrorMessage, "vatReg")}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="VAT Registration Number" required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('userType') === "FSE" && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? false : true} variant="outlined" margin="dense" />
            </div>
            {sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField id="telephoneOffice"
                  error={telephoneOfficError}
                  type="number"
                  disabled={isDisabled()}
                  helperText={telephoneOfficError && <span style={{ color: 'red' }}>{TelephoneOfficeErrorMessage}</span>}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  onChange={(e: any) => handleInputChange(e, setTelephoneOffice, setTelephoneOfficeError, setTelephoneOfficeErrorMessage, "telephone")}
                  value={telephoneOffice} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={customerTierType === "Workshop" ? "Contact Telephone Number" : "Telephone Office(Landline No.)"} required variant="outlined" margin="dense" />
              </div>
              :
              <div className="col-md-4 col-lg-4">
                <TextField id="telephoneOffice"
                  error={telephoneOfficError}
                  type="number"
                  disabled={isDisabled()}
                  helperText={telephoneOfficError && <span style={{ color: 'red' }}>{TelephoneOfficeErrorMessage}</span>}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  onChange={(e: any) => handleInputChange(e, setTelephoneOffice, setTelephoneOfficeError, setTelephoneOfficeErrorMessage, "telephone")}
                  value={telephoneOffice} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={customerTierType === "Workshop" ? "Contact Telephone Number" : "Telephone Office(Landline No.)"} required variant="outlined" margin="dense" />
              </div>
            }
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField id="mobileno"
                  type="number"
                  required
                  error={mobileNumberError}
                  disabled={isDisabled()}
                  helperText={mobileNumberError && <span style={{ color: 'red' }}>It can be upto 30 digits.</span>}
                  onChange={(e: any) => handleInputChange(e, setMobileNumber, setMobileNumberError, setMobileNumberErrorMessage, "mobileno")}
                  value={mobileNumber} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Mobile Number" variant="outlined" margin="dense" />
              </div>
              : ""}
            <div className="col-md-4 col-lg-4">
              <KeyboardDatePicker disableFuture
                required={(sessionStorage.getItem('customerOnboardingType') === "Dealer") ? true : (sessionStorage.getItem('customerOnboardingType') === "Fleet") || (customer && customer === "Workshop") ? true : (customer && customer !== "Government") || (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") ? false : true} autoOk size="small" disabled={isDisabled()}
                disableToolbar variant="inline" inputVariant="outlined"
                views={["year", "month", "date"]}
                openTo="year"
                onError={(e, v) => datePickerValid(e, setInvalidEstablishedDate)}
                format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
                error={(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business") || (customer && customer === "Government") ? false : establishedDateError}
                onChange={(date: Date | null) => {
                  setDataUpdated(true);
                  if (date) {
                    setEstablishedDate(date);
                    setEstablishedDateError(false)
                  } else {
                    setEstablishedDateError(true)
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                helperText={(sessionStorage.getItem('customerOnboardingType') === "Int. Business") || (customer === "Government") ? false : invalidDate && <span style={{ color: 'red' }}>{error}</span>}
              />
            </div>
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField select id="country"
                  error={countryError}
                  value={country}
                  onChange={(e: any) => handleInputChange(e, setCountry, setCountryError, setCountryErrorMessage, "country")}
                  disabled={userType === "FSE" && customer === "Government" || userType === "FSE" && customer === "Workshop" || userType === "FSE" && customer === "Fleet" || sessionStorage.getItem('customerOnboardingType') === "Dealer" || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted'}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Country" required variant="outlined" margin="dense">
                  {countryData && countryData.countries && countryData.countries.map((item: any) => (
                    <MenuItem key={item.countryCode} value={item.countryCode}>{item.countryName} </MenuItem>
                  ))}
                </TextField>
              </div>
              : <div className="col-md-4 col-lg-4">
                <TextField select id="country"
                  error={countryError}
                  value={country}
                  onChange={(e: any) => handleInputChange(e, setCountry, setCountryError, setCountryErrorMessage, "country")}
                  disabled={userType === "FSE" && customer === "Government" || userType === "FSE" && customer === "Workshop" || userType === "FSE" && customer === "Fleet" || sessionStorage.getItem('customerOnboardingType') === "Dealer" || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted'}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Country" required variant="outlined" margin="dense">
                  {getNationalityData && getNationalityData.data && getNationalityData.data.length > 0 && getNationalityData.data[0].countries.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Country} </MenuItem>))}
                </TextField>
              </div>}
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField id="ibSalesRegion"
                  value={salesRegion}
                  disabled
                  onChange={(e: any) => handleInputChange(e, setSalesRegion)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="IB Sales Region" required variant="outlined" margin="dense" >
                  {getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion.map((item: any) => (
                    <MenuItem key={item.salesRegion} value={item.salesRegion}>{item.salesRegion} </MenuItem>))}
                </TextField>
              </div>
              : ""}
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField select id="headOfficeRegion"
                  error={headOfficeRegionError}
                  helperText={invalidHeadOfficeRegion}
                  value={selectedRegion && selectedRegion.Code ? selectedRegion.Code : headOfficeRegion}
                  disabled={country ? false : true}
                  onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion, 'region')}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={customerTierType === "Workshop" ? "Trade Point Counter Location" : "Head Office Region"} required variant="outlined" margin="dense" >
                  {regionDataNew && regionDataNew.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                </TextField>
              </div>
              :
              <div className="col-md-4 col-lg-4">
                <TextField select id="headOfficeRegion"
                  error={headOfficeRegionError}
                  helperText={invalidHeadOfficeRegion}
                  value={selectedRegion && selectedRegion.Code ? selectedRegion.Code : headOfficeRegion}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion, 'region')}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={customerTierType === "Workshop" ? "Trade Point Counter Location" : "Head Office Region"} required variant="outlined" margin="dense" >
                  {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                </TextField>
              </div>
            }
            <div className="col-md-4 col-lg-4">
              <TextField id="dealerEmail"
                error={dealerEmailError}
                value={dealerEmail}
                disabled={isDisabled()}
                helperText={invalidDealerEmail && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                onChange={(e: any) => handleInputChange(e, setDealerEmail, setDealerEmailError, setInvalidDealerEmail)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Customer Email" required variant="outlined" margin="dense" />
            </div>
            {sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField select id="dealingIn"
                  value={dealingIn}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setDealingIn)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={customer === "Government" ? "Type of Ownership" : "Dealing In"} variant="outlined" margin="dense" >
                  {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                    dealingInData.data[0].regions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              </div>
              :
              <div className="col-md-4 col-lg-4">
                <TextField select id="dealingIn"
                  error={customer === "Government" ? false : dealingInError}
                  helperText={customer === "Government" ? false : invalidDealingIn}
                  value={dealingIn}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setDealingIn, setDealingInError, setInvalidDealingIn)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={customer === "Government" ? "Type of Ownership" : "Dealing In"} required={customer === "Government" ? false : true} variant="outlined" margin="dense" >
                  {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                    dealingInData.data[0].regions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              </div>}
            {customer === "Government" ? "" :
              <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 onboarding-field">
                {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
                  <TextField disabled defaultValue={ibCurrency} value={ibCurrency} onChange={(e: any) => handleInputChange(e, setSelectedIBCurrency)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                    <MenuItem key={ibCurrency} value={ibCurrency}>{ibCurrency}</MenuItem>
                  </TextField>
                  :
                  <TextField disabled defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown" margin="dense" variant="outlined">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                }
                <TextField id="annualTurnover" type="number"
                  value={annualTurnover}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
                  size="small" className="col-sm-9 commonRoundedInputs-textfield aed1-width"
                  label="Annual Turnover" variant="outlined" margin="dense" />
              </div>}
            {sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField id="typeOfTrade"
                  value={typeOfTrade === "TRD21" ? "Int. Business" : typeOfTrade}
                  disabled
                  onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type of Trade" required variant="outlined" margin="dense">
                  {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                  ))}
                </TextField>
              </div>
              :
              <div className="col-md-4 col-lg-4">
                <TextField id="typeOfTrade"
                  // error={typeOfTradeError}
                  // helperText={invalidTypeOfTrade}
                  select
                  value={typeOfTrade}
                  disabled
                  onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type of Trade" required variant="outlined" margin="dense">
                  {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>
                  ))}
                </TextField>
              </div>}
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField id="poBox"
                  type="number"
                  value={poBox}
                  onChange={(e: any) => handleInputChange(e, setPoBox, setPoBoxError, setPoBoxErrorMessage, "pobox")}
                  disabled={isDisabled()}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="PO Box" variant="outlined" margin="dense" />
              </div>
              : <div className="col-md-4 col-lg-4">
                <TextField id="poBox"
                  type="number"
                  error={poBoxError}
                  value={poBox}
                  onChange={(e: any) => handleInputChange(e, setPoBox, setPoBoxError, setPoBoxErrorMessage, "pobox")}
                  disabled={isDisabled()}
                  helperText={poBoxError && <span style={{ color: 'red' }}>{PoBoxErrorMessage}</span>}
                  onKeyDown={(e: any) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="PO Box" required={sessionStorage.getItem('userType') === "FSE" && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? false : true} variant="outlined" margin="dense" />
              </div>}
            {sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                <TextField id="addStreet"
                  value={addStreet}
                  onChange={(e: any) => handleInputChange(e, setAddStreet, setAddStreetError, setInvalidAddStreet, "street")}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Street Name" variant="outlined" margin="dense" />
              </div>
              :
              <div className="col-md-4 col-lg-4">
                <TextField id="addStreet"
                  error={addStreetError}
                  value={addStreet}
                  // disabl
                  onChange={(e: any) => handleInputChange(e, setAddStreet, setAddStreetError, setInvalidAddStreet, "street")}
                  // helperText={addStreetError && <span style={{ color: 'red' }}>{addStreetErrorMessage}</span>}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Street Name" required variant="outlined" margin="dense" />
              </div>}
            <div className="col-md-4 col-lg-4">
              <TextField id="city"
                error={cityError}
                value={city}
                // disabled
                disabled={isDisabled()}
                onChange={(e: any) => handleInputChange(e, setCity, setCityError, setInvalidCity)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="City" required variant="outlined" margin="dense" />
            </div>
            {(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business")
              || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
              || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
              "" :
              <div className="col-md-4 col-lg-4">
                <TextField id="postalCode"
                  type="number"
                  error={postalCodeError}
                  helperText={invalidPostalCode}
                  value={postalCode}
                  onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                  disabled={isDisabled()}
                  onChange={(e: any) => handleInputChange(e, setPostalCode, setPostalCodeError, setInvalidPostalCode)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Postal Code" required={sessionStorage.getItem('userType') === "FSE" && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? false : true} variant="outlined" margin="dense" />
              </div>}
          </>
        )}
        <div className="row col-12  mt-2 mb-2 pl-3 border-top">
          <div className="col-lg-4 mt-3">
            <h6 className="dealer-table-content mb-2 ml-1">Owner/Partners Details</h6>
          </div>
          <div className="col-md-8 bg-white pb-0 pl-lg-10 mt-3">
            <Button variant="contained" color="primary"
              className="blueActionButton rounded-pill ml-3 w-200p float-right"
              disabled={isDisabled()}
              onClick={() => {
                handleClickOpen();
                setTypeOfDailog('add')
              }}>Add Owner/Partner
            </Button>
          </div>
          {getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length > 0 && (
            <div className="row col-12 mt-2 mb-2 pl-3 border-top">
              <div className="col-lg-12 mt-3">
                <TableContainer>
                  <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead numSelected={selected.length} />
                    <TableBody>
                      <>
                        {getAllPartnersData.data.map((newPartnerData: any, index: any) => (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell component="th"
                              scope="row" padding="none" className="document-tablecell-data">
                              {newPartnerData.firstName}{'  '}{newPartnerData.lastName}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.email}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.mobile}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.nationality}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.designation}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.emiratesIdNumber}
                            </TableCell>
                            <TableCell align="left">
                              <div className="row">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                  {isAdmin && (
                                    <Button
                                      onClick={() => {
                                        setTypeOfDailog('view')
                                        handleEditPartnerDetails(newPartnerData, true);
                                      }}
                                      className='text-info'
                                      startIcon={<Visibility />}
                                    >
                                      View
                                    </Button>
                                  )}
                                  {isAdmin && (
                                    <Button
                                      className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                      disabled={isDisabled()}
                                      startIcon={<EditOutlinedIcon />}
                                      onClick={() => {
                                        setTypeOfDailog('edit')
                                        handleEditPartnerDetails(newPartnerData, false);
                                      }}>
                                      Edit
                                    </Button>
                                  )}
                                  {isAdmin && (
                                    <Button
                                      onClick={() => onClickDeleteAddress(newPartnerData)}
                                      className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                      disabled={isDisabled()}
                                      startIcon={<DeleteOutlineOutlinedIcon />}
                                    >
                                      Delete
                                    </Button>
                                  )}
                                </ButtonGroup>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                        }
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          <Dialog className="pb-0"
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose()
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown>
            <AddNewOwnerPartnerDialog
              setOpenDialog={handleClose} setOwnerData={setOwnerData}
              updateNewPartnerData={updateNewPartnerData}
              isView={isView} typeofDailog={typeofDailog}
            />
          </Dialog>
        </div>
        <div className="row col-12  mt-2 mb-3 pl-3 border-top">
          <div className="col-12 mt-3">
            <h6 className="dealer-table-content mb-2 ml-1">Brand & Others</h6>
          </div>
          {sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') !== "Workshop" ? (
            <>
              <div className="info-sub-title-value col-12 ml-1">Brands</div>
              <div className="mt-3 ml-3">
                {customerInfo && customerInfo.organizationIds && customerInfo.organizationIds.length > 0 && customerInfo.organizationIds.map((org: any, index: any) => {
                  const brand: any = brands.filter(x => x.organizationId === org.organizationId);
                  if (org.organizationId && org.organizationId !== '2003' && brand && brand.length > 0) {
                    return (
                      (sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
                        || (sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                        <Button key={index} onClick={() => {
                        }}
                          className="brandsButton mr-2 cursor-default"
                          variant="contained">
                          {org.organizationId} - {(getNewBrand(brands.filter(x => x.organizationId === org.organizationId)[0].make)) === 'Tradepoint' ? 'ALAC' : getNewBrand(brands.filter(x => x.organizationId === org.organizationId)[0].make)}
                        </Button> :
                        <Button key={index} onClick={() => {
                        }}
                          className="brandsButton mr-2 cursor-default"
                          variant="contained">
                          {(getNewBrand(brands.filter(x => x.organizationId === org.organizationId)[0].make)) === 'Tradepoint' ? 'ALAC' : getNewBrand(brands.filter(x => x.organizationId === org.organizationId)[0].make)}
                        </Button>)
                  }
                })
                }
              </div>
            </>
          ) : sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Workshop" ?
            <>
              <div className="info-sub-title-value col-12">Brands</div>
              <div className="mt-3">
                {existWorkShop.map((ele: any, index: any) => (
                  <Button key={index} onClick={() => {
                  }}
                    className="brandsButton mr-2 cursor-default"
                    variant="contained">
                    {ele.brandId} - {ele.displayName}
                  </Button>
                ))}
              </div>
            </> :
            (
              <>
                <div className="col-md-8 col-lg-8">
                  <FormControl className="commonRoundedInputs w-100 mt-2" size="small" variant="outlined">
                    <InputLabel error={selectBrandError} id="companyBrandsLabel">Select Brand *</InputLabel>
                    <Select
                      multiple
                      required
                      variant="outlined"
                      className={classes.dropdown}
                      disabled={isDisabled()}
                      value={selectBrand}
                      error={selectBrandError}
                      onChange={(e: any) => handleInputChange(e, setSelectBrand, setSelectBrandError, setInvalidSelectBrand, 'brand')}
                      input={<OutlinedInput label="Select Brand" />}
                      renderValue={(selected: any) => selected.join(', ')}>
                      {brandList && brandList.length > 0 && brandList.map((brand: any, i: any) => (
                        <MenuItem key={i} disabled={brand.brandType === "OES" && customer && customer.toLowerCase() === 'workshop'} value={brand.brandType === "OES" && customer && customer.toLowerCase() !== 'workshop' ? 'ALAC' : brand.displayName}
                        // disabled={brand.brandType === "OES"}
                        >
                          <Checkbox onChange={(e: any) => {
                            // if (brand.brandType !== "OES")
                            //  handleSelectAllClick(e, brand.displayName)
                            handleSelectAllClick(e, brand.brandType === "OES" && customer && customer.toLowerCase() !== 'workshop' ? 'ALAC' : brand.displayName)
                          }}
                            disabled={brand.brandType === "OES" && customer && customer.toLowerCase() === 'workshop'}
                            checked={selectBrand && selectBrand.includes(brand.displayName) ? true : false}
                          />
                          <p className="pr-2"> {brand.brandId}</p><p className="pr-2">{"-"}</p>
                          {/* <ListItemText primary={brand.brandType === "OES" ? 'ALAC' : brand.displayName} /> */}
                          <span className={selectBrand && selectBrand.includes(brand.displayName) ? "brandsButton-content" : ""}>{brand.brandType === "OES" && customer && customer.toLowerCase() !== 'workshop' ? 'ALAC' : brand.displayName}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business"
                  || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government")
                  || sessionStorage.getItem('customerOnboardingType') === "Workshop" ?
                  "" :
                  <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                    <TextField select id="creditLimitSelector"
                      error={creditLimitSelectorError}
                      value={creditLimitSelector} disabled defaultValue={currencies[0]}
                      onChange={(e: any) => handleInputChange(e, setCreditLimitSelector, setCreditLimitSelectorError, setInvalidCreditLimitSelector)}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                      {currencies.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    </TextField>
                  </div>
                }
                {(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business")
                  || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government")
                  || sessionStorage.getItem('customerOnboardingType') === "Workshop" ?
                  "" :
                  <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                    <TextField id="creditLimit"
                      type="number"
                      error={initalStockOrderError}
                      // helperText={initalStockOrderError}
                      value={initalStockOrder}
                      disabled={isDisabled()}
                      onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      onChange={(e: any) => handleInputChange(e, setInitalStockOrder, setInitalStockOrderError, setInvalidInitalStockOrder, 'intialStock')}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                      label="Initial Stock Order*" variant="outlined" margin="dense" />
                  </div>
                }
                {sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Int. Business"
                  || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government")
                  || sessionStorage.getItem('customerOnboardingType') === "Workshop" ?
                  "" :
                  <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                    <TextField select id="totalInvestmentSelector"
                      error={totalInvestmentSelectorError}
                      value={totalInvestmentSelector}
                      disabled onChange={(e: any) => handleInputChange(e, setTotalInvestmentSelector, setTotalInvestmentSelectorError, setInvalidTotalInvestmentSelector)}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                      {currencies.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    </TextField>
                  </div>
                }
                {sessionStorage.getItem('customerOnboardingType') === "Int. Business" && sessionStorage.getItem('customerType') === 'new' || sessionStorage.getItem('customerOnboardingType') === "Int. Business" && sessionStorage.getItem('customerType') === 'existing'
                  || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government")
                  || sessionStorage.getItem('customerOnboardingType') === "Workshop" ?
                  "" :
                  <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                    <TextField id="totalInvestment"
                      type="number"
                      error={(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
                        || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                        false : totalInvestmentError}
                      helperText={(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
                        || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                        false : invalidTotalInvestment}
                      value={totalInvestment}
                      disabled={isDisabled()}
                      onKeyDown={(e: any) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                      onChange={(e: any) => handleInputChange(e, setTotalInvestment, setTotalInvestmentError, setInvalidTotalInvestment)}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                      label={(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Fleet" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Fleet")
                        || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                        "Total Investment" : "Total Investment*"} variant="outlined" margin="dense" />
                  </div>}
                {(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                  " " :
                  <div className="col-md-4 col-lg-4">
                    <TextField id="otherBusinessEstablishments"
                      // error={otherBusinessEstablishmentsError}
                      // helperText={otherBusinessEstablishmentsError && <span style={{ color: 'red' }}>{OtherBusinessEstablishmentsErrorMessage}</span>}
                      value={otherBusinessEstablishments}
                      onChange={(e: any) => handleInputChange(e, setOtherBusinessEstablishments)}
                      disabled={isDisabled()}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Other Business Establishments" variant="outlined" margin="dense" />
                  </div>}
                {(sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government") ?
                  " " :
                  <div className="col-md-4 col-lg-4">
                    <TextField id="otherBrandsProductsSold"
                      // error={otherBrandsProductsSoldError}
                      // helperText={otherBrandsProductsSoldError && <span style={{ color: 'red' }}>{OtherBrandsProductsSoldErrorMessage}</span>}
                      value={otherBrandsProductsSold}
                      onChange={(e: any) => handleInputChange(e, setOtherBrandsProductsSold, setOtherBrandsProductsSoldError, setOtherBrandsProductsSoldErrorMessage, "brandproductsold")}
                      disabled={isDisabled()}
                      size="small" className="col-sm-12 my-2 commonRoundedInputs"
                      label="Other Brands/Products Sold" variant="outlined" margin="dense" />
                  </div>}
              </>
            )}
        </div>
        {(sessionStorage.getItem('customerType') === 'existing') || (sessionStorage.getItem('customerType') === 'new' && sessionStorage.getItem('customerOnboardingType') === "Government" || sessionStorage.getItem('customerType') === 'existing' && sessionStorage.getItem('customerOnboardingType') === "Government")
          || (sessionStorage.getItem('customerOnboardingType') === "Workshop") ?
          '' : (
            <>
              <div className="row col-md-12 m-0 border-top pl-3" >
                <h6 className="dealer-table-content mb-2 mt-4">Trade Reference</h6>
              </div>
              {tradeReference.map((tradeItem: any, index: any) => {
                return (
                  <div className="col-md-12 m-0" key={index}>
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.name}
                          type="text"
                          disabled={isDisabled()}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "name")}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].nameErrorMessage}</span>}
                          name={tradeItem.name + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Name" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.companyName}
                          type="text"
                          disabled={isDisabled()}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "companyName")}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                          }}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].companyNameErrorMessage}</span>}
                          name={tradeItem.companyName + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Company Name" variant="outlined" margin="dense" />
                      </div>
                    </div>
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.addresses}
                          type="text"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError}
                          onChange={(e: any) => tradeReferenceItem(e, index, "addresses")}
                          disabled={isDisabled()}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].addressErrorMessage}</span>}
                          inputProps={{ maxLenght: 30 }}
                          name={tradeItem.addresses + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Address" variant="outlined" margin="dense" />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.telephone}
                          type="number"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError}
                          onChange={(e: any) => tradeReferenceItem(e, index, 'telephone')}
                          disabled={isDisabled()}
                          onKeyDown={(e: any) => ["e", "E", "-"].includes(e.key) && e.preventDefault()}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].telephoneErrorMessage}</span>}
                          inputProps={{ maxLenght: 20 }}
                          name={tradeItem.telephone + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Telephone" variant="outlined" margin="dense" />
                      </div>
                      {
                        tradeReference.length !== 1 ?
                          <div className="col-md-4 col-lg-2">
                            <IconButton className={isDisabled() ? "text-info disabledCustomclass mr-1 mt-n2" : 'mr-1 mt-n2 text-info'} size="small"
                              disabled={isDisabled()}
                              onClick={() => {
                                onClickDeleteTradeRef();
                                setTradeRowRefDeleteIndex(index)
                                // deleteTradeRow(index)
                              }}                      >
                              <DeleteIcon fontSize="inherit" />
                              <span className="font-size-13 load-from-fav ml-1 mt-0">
                                Delete
                              </span>
                            </IconButton>
                          </div>
                          : ""}
                    </div>
                  </div>
                )
              })}
              <div className="row col-md-12 m-0">
                <div className="row-space-start">
                  <Button
                    className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                    startIcon={<AddIcon className="font-size-18" />}
                    disabled={isDisabled()}
                    // disabled={isDisabled() ? isDisabled() : isDisabledTradeRow()}
                    onClick={() => addTradeRow()}
                  ><span className="onshiftoparty-font">Add More
                    </span>
                  </Button>
                </div>
              </div>
            </>
          )}
      </div>
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
        <div className="col-12 display-flex p-0">
          <div className="col-8 p-0">
            <div className="row m-0">
              {/* <div className="col-5 p-0">
                <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                {dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted' ? (
                  <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                ) : (
                  <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                    <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                      Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                  </p>
                )}
              </div> */}
              {/* {dealerDetails && dealerDetails.data && dealerDetails.data.status !== 'Submitted' && ( */}
              <div className="col-4 mt-3 p-0">
                <Button variant="contained" className="border-left rounded-pill ml-4 w-150p mb-2"
                  onClick={() => {
                    if (ibCurrency === undefined || ibCurrency === "" && sessionStorage.getItem('customerType') === 'existing' && (userType === 'FSE' || sessionStorage.getItem('userType') === "FSE") && sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
                      setIbCurrencyError(true);
                      setOpenErrorSnackbar(true);
                      handleopenCloseSnackbar(true);
                      setSuccessMessage('Currency is not fetched from SAP. Please check internally.');
                    } else {
                      saveDetails(false)
                    }
                  }}
                >Save Form</Button>
              </div>
              {/* )} */}
            </div>
          </div>
          <div className="col-4 onboard-footer float-right">
            <div className="p-3 mr-3">
              <Button variant="contained" color="primary"
                onClick={() => {
                  if (ibCurrency === undefined || ibCurrency === "" && sessionStorage.getItem('customerType') === 'existing' && (userType === 'FSE' || sessionStorage.getItem('userType') === "FSE") && sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
                    setIbCurrencyError(true);
                    setOpenErrorSnackbar(true);
                    handleopenCloseSnackbar(true);
                    setSuccessMessage('Currency is not fetched from SAP. Please check internally.');
                  } else {
                    handleNextClick()
                  }
                }}
                className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{props.showSave ? 'Next' : 'Next'}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <DeleteDialog
        open={openDeleteDialog}
        type={typeOfDelete}
        text={deleteMessage}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            deletePartner()
          }
          else if (data === 'tradeRef') {
            deleteTradeRow(tradeRowRefDeleteIndex)
          }
          if (!data) handleopenClose(false);
        }} />
      <DeleteDialog
        open={confirmDialog}
        text="Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost."
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteCustomerData();
          if (!data) {
            loadPartners();
            handleopenCloseConfirmPopup(false);
          }
        }} />
    </Fragment >
  )
}

export default AddNewKamDealerDetails;