/* eslint-disable */
import React, {
    Fragment,
    Dispatch,
    useEffect,
} from "react";
import "./AdminDashboard.css";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import TablePagination from "@material-ui/core/TablePagination";
import { getReviewDocuments } from "../../store/actions/tasks.actions";
import { get } from "lodash";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import LaunchIcon from '@material-ui/icons/Launch';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import { MenuItem } from "@material-ui/core";
import { getRegion } from "../../store/actions/onboard.actions";
import moment from "moment-timezone";
import { isEmpty } from 'lodash';
import ApproveDialog from "./approveDialog";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "2px 4px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        textTransform: "none",
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    collapseBg: {
        background: "rgba(66, 94, 108, 0.07)",
    },
    icon: {
        float: "left",
        margin: "0 20px 0px 0",
        height: "50",
        fill: "white",
    },
    bodyContent: {
        color: "#666666",
        fontWeight: 600,
        fontSize: 12,
        fontFamily: "Nunito",
        padding: 0,
        align: "center",
        maxWidth: "165px",
    },

    collapseIcon: {
        color: "#000000",
        fontWeight: 500,
    },
    tableHead: {
        color: "#133F8A",
        background: "#FFFFFF",
        fontWeight: 800,
        fontSize: 13,
        fontFamily: "Nunito",
        paddingTop: 15,
        lineHeight: 0,
        position: "sticky",
    },
    tableTitle: {
        fontWeight: 800,
        background: "FFFFFF",
        fontSize: 15,
        fontFamily: "Nunito",
        paddingLeft: 15,
        paddingTop: 15,
        paddingBottom: 15,
        position: "sticky",
        top: 0,
        color: '#000000'
    },
    tableContainer: {
        overflow: "none",
        height: "388px",
        borderTop: "1px solid #0000001A",
    },
    tableScroll: {
        overflow: "auto",
    },
    blueText: {
        fontSize: 11,
        fontWeight: 700,
        paddingRight: 0,
        color: "#1A9FE0",
        fontFamily: "Nunito"
    },

    subTableCell: {
        color: "#666666",
        fontSize: 10,
        fontFamily: "Nunito",
        fontWeight: 600
        // minWidth: 100,
    },
    // redText: {
    //   color: "#EC4242",
    //   fontSize: 11,
    //   fontWeight: 700,
    //   fontFamily: "Nunito",
    // },
    actionButton: {
        color: "#1A9FE0",
    },
    subTableBodyContent: {
        color: "#666666",
        fontWeight: 600,
        fontSize: "0.75rem",
        fontFamily: "Nunito",
        width: 140,
    },
}));

const reviewColumnsFSE: any = [
    {
        id: "date",
        label: "Date",
        align: "left",
    },
    {
        id: "name",
        label: "Name",
        align: "left",
    },
    {
        id: "documentName",
        label: "Document Name",
        align: "left",
    },
    {
        id: "previousDocument",
        label: "Previous Document",
        align: "left",
    },
    {
        id: "revisedDocument",
        label: "Revised Document",
        align: "left",
    },
    {
        id: "status",
        label: "Status",
        align: "left",
    },
    {
        id: "action",
        label: "Action",
        align: "left",
    },
];

export default function ReviewDcouments(props: any) {
    const classes = useStyles();
    const dispatch: Dispatch<any> = useDispatch();
    const [page, setPage] = React.useState(0);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const { tasks, postOpenTaskData, reviewDocuments }: any = useSelector((state: any) => state.tasks);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
    let userTypeByProfileAPI = sessionStorage.getItem('userType-Profile-API');
    const [value, setValue] = React.useState(0);
    const [searchValue, setSearchValue] = React.useState<string>("");
    const [startDate, setstartDate] = React.useState<any>(null);
    const [endDate, setendDate] = React.useState<any>(null);
    const [filterByRegion, setFilterByRegion] = React.useState(userTypeByProfileAPI === "KAM" ? "All" : '');
    const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
    const [loading, setLoading] = React.useState<any>(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [item, setItem] = React.useState<any>();
    const [isApprove, setIsApprove] = React.useState<any>();
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const { regionData }: any = useSelector((state: any) => state.onboard);
    const rowsPerPage = 10
    const region: any = get(profileDetails, 'data.data.basicInfo.region', '');
    const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const resetData = () => {
        setPage(0);
        setSearchValue('');
        setstartDate(null);
        setendDate(null);
        setFilterByRegion('')
    }
    interface TabPanelProps {
        children?: React.ReactNode;
        index: any;
        value: any;
    }

    useEffect(() => {
        sessionStorage.setItem('webApplicationLoginId', webApplicationLoginId);
        if (regionData && regionData.data && regionData.data.length > 0) {
        } else {
            if (userTypeByProfileAPI === "FSE" || userTypeByProfileAPI === "KAM") {
                dispatch(getRegion('All'));
            }
        }
    }, []);

    const getAllReviewDocuments = async (newPage?: any, page?: any, regions?: any, isExport?: any) => {
        setLoading(true);
        let data: any = {
            "sapCustomerId": searchValue ? searchValue : '',
            "customerName": searchValue ? searchValue : '',
            "webApplicationLoginId": webApplicationLoginId,
            "fromDate": startDate ? moment(startDate).utc().format("YYYY-MM-DD") : "",
            "toDate": endDate ? moment(endDate).utc().format("YYYY-MM-DD") : "",
            "regionCode": regions ? regions : filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : "",
        }

        // if (isExport) data.isExport = true

        let savedData: any = await dispatch(getReviewDocuments(data, newPage ? newPage : page));
        if (savedData && savedData.message && savedData.message.status === 'S') {
            setLoading(false);
            return savedData.data
        } else if (savedData && savedData.message && savedData.message.status === "E" || savedData && savedData.error) {
            // handleopenCloseErrorSnackbar(true);
            // showErrorMessage(savedData && savedData.error);
            // handleopenCloseSnackbar(false);
            setLoading(false);
        }
    }

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleOpenApproveDialog = (data: any) => {
        setOpenApproveDialog(data ? true : false)
    }

    return (
        <Fragment>
            <Snackbar
                open={openSnackbar}
                handleopenClose={() => {
                    handleopenCloseSnackbar(false)
                }} message={successMessage} />
            <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseErrorSnackbar(false)
                }} message={successMessage} />

            {openApproveDialog &&
                <ApproveDialog open={openApproveDialog}
                    isApprove={isApprove}
                    item={item}
                    handleopenClose={handleOpenApproveDialog}
                    getAllReviewDocuments={getAllReviewDocuments}
                    setSuccessMessage={setSuccessMessage}
                    handleOpenCloseSnackbar={handleopenCloseSnackbar}
                    setOpenSnackbar={setOpenSnackbar}
                    setOpenErrorSnackbar={setOpenErrorSnackbar}
                />
            }
            <div className="row mt-3">
                <>
                    <TableContainer className={classes.tableContainer}>
                        <Table aria-label="table">
                            <TableHead>
                                <TableRow>
                                    {reviewColumnsFSE.map((column: any) => (
                                        <TableCell
                                            className={classes.tableHead}
                                            key={column.id}
                                            align={column.align}
                                            style={{ paddingRight: 0 }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {reviewDocuments && reviewDocuments.data && reviewDocuments.data.data && reviewDocuments.data?.data?.data && reviewDocuments.data?.data?.data?.documentList?.map((row: any) => (
                                    <TableRow key={row.id}>
                                        <TableCell className="font-12 font-weight-bold dealer-table-content mw-150p">{row && row.uploadedDate ? row && row.uploadedDate : "-"}</TableCell>
                                        <TableCell className={`${classes.bodyContent}`}>
                                            <div className="d-flex">
                                                <div className="cursor-none" style={{ display: "inline" }}>
                                                    <h6 className={classes.blueText}>
                                                        {row.companyTitle} {row.companyName}
                                                    </h6>
                                                    <h6 className={`pt-1 ${classes.subTableCell}`}>SAP ID : {row && row.sapCustomerId}</h6>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={`mw-210p ${classes.bodyContent}`}>
                                            <div className="d-flex">
                                                <div className="cursor-none" style={{ display: "inline" }}>
                                                    <h6 className="font-12 font-weight-bold text-red mw-100p">
                                                        {row.documentName}
                                                    </h6>
                                                    <h6 className={`pt-1 ${classes.subTableCell}`}>{row && row.otherInfo}</h6>
                                                </div>
                                            </div></TableCell>
                                        <TableCell>
                                            <div className="d-flex">
                                                <div style={{ display: "inline" }}>
                                                    <span className="cursor-pointer text-blue mr-2" onClick={() => {
                                                        window.open(row.previousDocumentUrl)
                                                    }}><img src="./fileArrowDown.svg" className="mr-2 w-12px text-blue" />
                                                        {row.docType ? row.docType : 'Previous File'}
                                                    </span>
                                                    <h6 className={`pt-1 ${classes.subTableCell}`}>Exp Date : {row && row.previousExpiryDate ? moment(row && row.previousExpiryDate).utc().format("YYYY-MM-DD") : "-"}</h6>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <div className="d-flex">
                                                <div style={{ display: "inline" }}>
                                                    <span className="cursor-pointer text-blue" onClick={() => {
                                                        window.open(row.documentUrl)
                                                    }}><img src="./fileArrowDown.svg" className="w-12px mr-2 text-blue" />{row.docType ? row.docType : 'New File'}</span>
                                                    <h6 className={`pt-1 ${classes.subTableCell}`}>Exp Date : {row && row.expiryDate ? moment(row && row.expiryDate).utc().format("YYYY-MM-DD") : "-"}</h6>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className={row && row.documentStatus === "Review Pending" ? " font-12 font-weight-bold text-info mw-100p" : row && row.documentStatus === "KAM Review Pending" ? " font-12 font-weight-bold text-orange mw-100p" : row && row.documentStatus === "Re Upload" ? " font-12 font-weight-bold text-red mw-100p" : " font-12 font-weight-bold text-black mw-100p"}>{row && row.documentStatus === "Re Upload" ? 'Re-upload' : row.documentStatus}</TableCell>
                                        <TableCell className="mw-2100p">
                                            <div style={{ background: "white", }}>
                                                {<IconButton aria-describedby="simple-popover" onClick={(event: any) => {
                                                    if (row.documentStatus === "Re Upload") {
                                                        handleOpenApproveDialog(true);
                                                        handleClose();
                                                        setIsApprove('reject')
                                                    } else {
                                                        handleClick(event);
                                                    }
                                                    setItem(row);
                                                }}
                                                    disabled={row.documentStatus === "KAM Review Pending"}
                                                    className={classes.actionButton} size="small">
                                                    <div className={row.documentStatus === "KAM Review Pending" ? "px-2 pt-1 pb-1 boxshadow-icon-disabled" : "px-2 pt-1 pb-1 boxshadow-icon"} >
                                                        {row.documentStatus === "Re Upload" ? <LaunchIcon fontSize="inherit" className={classes.actionButton} /> : <MoreVertIcon fontSize="inherit" className={classes.actionButton} />}
                                                    </div>
                                                </IconButton>}
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {isEmpty(reviewDocuments && reviewDocuments.data && reviewDocuments.data.data && reviewDocuments.data?.data?.data && reviewDocuments.data?.data?.data?.documentList) &&
                            <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Review Documents Found.</div>
                        }
                    </TableContainer>
                </>
            </div>
            <Menu
                id="simple-popover"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorReference="anchorEl"
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                className="ml-3 mt-5 "
            >
                <MenuItem className="mr-4"
                    onClick={() => {
                        handleOpenApproveDialog(true);
                        handleClose();
                        setIsApprove('approve')
                    }}><div className={classes.icon}>
                        <CheckIcon className="check-icon" fontSize="small" />
                    </div>Approve
                </MenuItem>
                
                <MenuItem
                    onClick={() => {
                        setIsApprove('reupload')
                        handleOpenApproveDialog(true);
                        handleClose();
                    }}> <div 
                    className={classes.icon}>
                        <ClearIcon className="cancel-icon" fontSize="small" />
                    </div>Re-Upload
                </MenuItem>
            </Menu>
        </Fragment>
    );
};
