import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll";

const NavigationArrow: React.FC = () => {
    return (
        <AnchorLink href="#top" className="scroll-top btn bg-black text-white tp-btn position-fixed poppins-700 p-2 overflow-hidden bottom-80">
            <div className="d-flex"> 
                <span className="material-icons-outlined"> arrow_upward </span> 
                <span className="ml-3 text position-absolute"> Back to top</span> 
            </div>
        </AnchorLink>
    )
}

export default NavigationArrow