/* eslint-disable */
import React from "react"
import { useHistory } from "react-router-dom"
import image1 from './../../assets/mainLogoBlue.png'
import { MsalProvider, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { b2cPolicies, msalConfig } from "../../authConfig";
import { API_ENDPOINTS } from '../../constants/apiConfig';
import AnchorLink from "react-anchor-link-smooth-scroll";

const NavBar = (props:any) => {
    const history = useHistory()
    const { instance } = useMsal();
    const redirect_uri = encodeURIComponent(`${API_ENDPOINTS.ON_BOARDING_URL}/login`);

    return (
    <nav className="navbar tp navbar-expand-lg position-fixed w-100 pt-0 pb-0 mt-0 mb-0 pa-5 text-center bg-white"> 
        {/* <a className="navbar-brand" href="javascript:void(0);"> <img className="maxw150" src={props && props.pageData && props.pageData.logo && props.pageData.logo.Brand_logo ? props.pageData.logo.Brand_logo :  image1} alt="logo" /> </a> */}
        <a className="navbar-brand" href="javascript:void(0);"> <img className="maxw150" src={ image1} alt="logo" /> </a>
        <button className="navbar-toggler px-0" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"> 
            <span className="material-icons-outlined">menu</span> 
        </button>
        <div className="collapse navbar-collapse mt-3 mt-lg-0" id="navbarContent">
            {/* <ul className="navbar-nav mr-auto text-white">
                <li className="nav-item text-white font-weight-500"> 
                <AnchorLink className="text-white nav-link" href="#about">About us</AnchorLink> </li>
                <li className="nav-item text-white"> <AnchorLink className="nav-link text-white" href="#locations">Our Locations</AnchorLink> </li>
                <li className="nav-item text-white"> <AnchorLink className="nav-link text-white" href="#contactus">Contact Us</AnchorLink> </li>
            </ul> */}
               <ul className="navbar-nav mr-auto">
                {/* <li className="nav-item active"> <a className="nav-link" href="javascript:void(0);" id="ourBrandBtn">Brands</a> </li>
                <li className="nav-item"> <a className="nav-link" href="javascript:void(0);">Resources</a> </li>
                <li className="nav-item"> <a className="nav-link" href="javascript:void(0);">Inspirational</a> </li>
                <li className="nav-item"> <a className="nav-link" href="javascript:void(0);"> Trade Point</a> </li> */}
            </ul>
            <div className="my-2 my-lg-0"> 
                <button className="btn btn-outline-primary tp-btn mr-3" onClick={() => instance.loginRedirect(loginRequest)}>Login</button> 
                <button className="btn btn-primary tp-btn" onClick={() => {
                     let signupURL = `https://${b2cPolicies.authorityDomain}/${b2cPolicies.tenantUrl}/oauth2/v2.0/authorize?p=${b2cPolicies.names.signUp}&client_id=${msalConfig.auth.clientId}&nonce=defaultNonce&redirect_uri=${redirect_uri}&scope=openid&response_type=id_token&prompt=login`
                     window.location.href  = signupURL;
                }}>Register</button> 
            </div>
        </div>
    </nav>
    )
}

export default NavBar