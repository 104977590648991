import { useState, Dispatch, useRef } from "react"
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import SelectEnquiry from "./SelectEnquiry";
import { API_ENDPOINTS } from "../../constants/apiConfig";
import { useHistory } from 'react-router-dom';
import queryString from "query-string";
import { createUpdateEnquiryService } from "../../services/createUpdateEnquiry.service ";
import { getUniqueEnquirySessionId, setEnquiryId } from "../../store/actions/createUpdateEnquiry.actions";
import { getUniqueTimestamp } from "../../common/components/util";
import { setOrderEnquiryDetails } from "../../store/actions/orderEnquiry.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";

const OesCatalogue = () => {

  const dispatch: Dispatch<any> = useDispatch();
  const [openEnquiry, setOpenEnquiry] = useState(false);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState<string>('');
  const history = useHistory();
  const { enquiryId }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );
  const handleOpenEnquiry = (open?: any) => {
    setOpenEnquiry(open ? true : false);
  };

  const id_token = sessionStorage.getItem('idToken');
  const ref = useRef<any>();
  const onLoad = () => {
    const url = ref.current.contentWindow.location.href;
    setUrl(url);
  }

  const getData = async () => {
    const { query } = queryString.parseUrl(url);
    let partInfo = await createUpdateEnquiryService.getTecdocData(query.requestId);
    if (partInfo && partInfo.data && partInfo.data.length > 0 && partInfo.data[0].selectedData) {
      let parsedData = JSON.parse(partInfo.data[0].selectedData.parts);
      if (parsedData && parsedData.length > 0) {
        if (partInfo.data[0].selectedData && partInfo.data[0].selectedData.masterenquiryId && partInfo.data[0].selectedData.masterenquiryId !== '') {
          sessionStorage.setItem('partsData', JSON.stringify([...parsedData]))
          sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
            mode: 'update',
            masterId: partInfo.data[0].selectedData.masterenquiryId
          }));
          await dispatch(setEnquiryId(null));
          history.push('/updateenquiry');
        } else {
          sessionStorage.setItem('partsData', JSON.stringify([...parsedData]));
          dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
          dispatch(setOrderEnquiryDetails('create'));
          if (enquiryId === 'create') {
            history.push('/createenquiry');
          } else {
            handleOpenEnquiry(true);
          }
          await dispatch(setEnquiryId(null));
        }
      }
    }
  }

  React.useEffect(() => {
    if (url && typeof url == 'string' && url !== '' && (url.includes('oescatalogue') || url.includes('updateenquiry'))) {
      getData()
    } else if (url && typeof url == 'string' && url !== '' && !url.includes('oescatalogue') || !url.includes('updateenquiry')) {
      const interval = setInterval(() => {
        setUrl(ref.current.contentWindow.location.href);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [url]);

  const getAccessToken = async () => {
    setLoading(true);
    let accessData = await createUpdateEnquiryService.getb2cAccessToken();
    if (accessData && accessData.data) {
      sessionStorage.setItem('b2cAccessToken', accessData.data.access_token)
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getAccessToken();
  }, []);

  return (
    <div className="w-100 h-100vh">
      {loading && <FullscreenLoader />}
      {!loading && (
        <div className="col-12 col-lg-12 col-md-12 p-0 oes-class">
          {openEnquiry ? (
            <iframe
              src=
              {`${API_ENDPOINTS.TEC_DOC_URL}/alfuttaim-catalog/en/?catalog=alfuttaim-catalog-trade-point-user&id_token=${id_token}&access_token=${sessionStorage.getItem('b2cAccessToken')}`}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
              title="oes"
              onLoad={onLoad}
              ref={ref}
              className="w-104 h-100vh"
              style={{ color: "red" }} id="iframe-url" >
            </iframe>
          ) : (
            <iframe
              src=
              {enquiryId && enquiryId !== 'create' ? `${API_ENDPOINTS.TEC_DOC_URL}/alfuttaim-catalog/en/?masterenquiryId=${enquiryId}&catalog=alfuttaim-catalog-trade-point-user&id_token=${id_token}&access_token=${sessionStorage.getItem('b2cAccessToken')}`
                : `${API_ENDPOINTS.TEC_DOC_URL}/alfuttaim-catalog/en/?catalog=alfuttaim-catalog-trade-point-user&id_token=${id_token}&access_token=${sessionStorage.getItem('b2cAccessToken')}`}
              // {`${API_ENDPOINTS.TEC_DOC_URL}/alfuttaim-catalog/en/?catalog=alfuttaim-catalog-trade-point-user&id_token=${id_token}&access_token=${sessionStorage.getItem('b2cAccessToken')}`}
              sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
              title="oes"
              onLoad={onLoad}
              ref={ref}
              className="w-104 h-100vh"
              style={{ color: "red" }} id="iframe-url" >
            </iframe>
          )}

          <SelectEnquiry open={openEnquiry} handleopenClose={(data: any) => {
            handleOpenEnquiry(false)
          }} />
        </div>
      )}
    </div>
  )
}
export default OesCatalogue