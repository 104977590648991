import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";


export const profileservice = {
    getProfile,
    updateProfile,
    profileStatus,
    getProfilePlacedSummary,
    supportPostAPI,
    getDocumentExpiryStatus
};

async function getProfile(id: any, userType: string) {
    const apiPath = API_ENDPOINTS.PROFILE_ENDPOINT;
    const options = {
        path: `${apiPath}?id=${id}&userType=${userType}&details=basic,customer,access`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function updateProfile(data: any) {
    const apiPath = API_ENDPOINTS.PROFILE_ENDPOINT;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Put",
        bodyObj: data,
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function profileStatus(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/customer/${data}/accountInfo`;
    const options = {
        // cors_url: ADMIN_TOOL_ENDPOINTS.CORS_URL,
        path: apiPath,
        method: "Get",
        customHeaders: {
            // "X-Subscription-Key": CPM_MASS_SERVICE.AUTH_KEY
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getProfilePlacedSummary(id: any, userType: string) {
    //const apiPath= `https://autob2bbffqa.corp.al-futtaim.com/automotive/b2b/v1/users/getUserDetailsForPayment`,
    const apiPath = API_ENDPOINTS.PROFILE_ENDPOINT_PLACEDSUMMARY;
    const options = {
        path: `${apiPath}?id=${id}&userType=${userType}&details=basic,customer,access`,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}

async function supportPostAPI(data: any) {
    const apiPath = API_ENDPOINTS.ONBOARD + `/cms/comms/sendSupportEmail`;
    const options = {
        path: apiPath,
        customHeaders: {},
        method: "POST",
        bodyObj: data,
        formdata: true
    };
    return await makeRequest(options)
}

async function getDocumentExpiryStatus(sapCustomerID: any, documentList: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + `/onboarding/customer/getInvalidDocumentCount/${sapCustomerID}?documentList=${true}`;
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
    };
    return await makeRequest(options)
}