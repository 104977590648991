/* eslint-disable */
import React, { Fragment } from 'react';
import { useEffect, Dispatch } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { getSpecialPriceKam, getSpecialPriceStatusKam, viewSpecialPriceKamAPI, requestCorrectionToKam, requestCorrectionDetailsKam } from "../../store/actions/specialPriceKam.actions";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
// import './Access.css';
import moment from "moment-timezone";
import { Dialog } from '@material-ui/core';
import _ from "lodash";

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function RequestCorrectionKam(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const [correctionText, setCorrectionText] = React.useState<any>("");
    const [correctionTextErrorMessage, setCorrectionTextErrorMessage] = React.useState<any>("");
    const [correctionTextError, setCorrectionTextError] = React.useState<boolean>(false);
    const { handleOpenCorrection } = props;
    const [verify, setVerify] = React.useState<any>(false);
    const classes = useStyles();
    const { requestCorrectionApi, saveRequestCorrectionApi, historyListRequestCorrectionApi }: any = useSelector((state: any) => state.onboard);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [correctionList, setCorrectionList] = React.useState([]);
    const { openRequestSnackbar } = props;
    const regex: any = /<br\s*[\/]?>/gi;
    const { viewSpecialPriceKam, requestCorrectionKam } = useSelector((state: any) => state.specialPriceKam)
    const [responseIDData, setResponseIDData] = React.useState<any>();
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const displayName = _.get(profileDetails, "data.data.basicInfo.displayName", "");
    const [B2bEnquiryId, setB2bEnquiryId] = React.useState<any>();
    const [sapCustomerIdData, setSapCustomerIdData] = React.useState<any>();
    const [loading, setLoading] = React.useState<boolean>(false);

    useEffect(() => {
        let responseID: any
        let sapIDData: any
        let B2bEnquiryIdData: any

        viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data &&
            viewSpecialPriceKam.data.data.data && (
                <>
                    {responseID = viewSpecialPriceKam?.data?.data?.data?.id}
                    {B2bEnquiryIdData = viewSpecialPriceKam?.data?.data?.data?.B2bEnquiryId}
                    {sapIDData = viewSpecialPriceKam?.data?.data?.data?.sapCustomerId}
                </>
            )
        setResponseIDData(responseID)
        setB2bEnquiryId(B2bEnquiryIdData)
        setSapCustomerIdData(sapIDData)
    }, [])

    useEffect(() => {
        setCorrectionList(requestCorrectionKam && requestCorrectionKam.data && requestCorrectionKam.data.data && requestCorrectionKam.data.data.history)
    }, [requestCorrectionKam])


    // ONCHANGE OF TEXTAREA-AUTOSIZE 
    const handleCustomerChange = (e: any) => {
        setCorrectionText(e.target.value);
        if (e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "") {
                setCorrectionTextError(true);
            }
            else {
                setCorrectionTextError(false);
            }
        }
    }

    // VALIDATION FOR TEXTAREA OF CORRECTION MESSAGE 
    function validateCorrectionMessage() {
        let isValid: boolean = true;
        if (!correctionText || correctionText.trim() == "") {
            setCorrectionTextError(true);
            isValid = false;
            return
        } else {
            if (correctionText.split('').length > 500) {
                setCorrectionTextError(false);
                setCorrectionTextErrorMessage(true);
                isValid = false;
                return
            }
            else {
                setCorrectionTextError(false);
                setCorrectionTextErrorMessage(false);
            }
        }
        if (isValid) {
            requestCorrectionMain();
        }
    }

    // FOR POST API CALL FOR REQUEST CORRECTION API
    const requestCorrectionMain = async () => {
        setLoading(true);
        let data = {
            "sapCustomerId": sapCustomerIdData,
            "requestId": responseIDData,
            "requestCorrectionMsg": correctionText.replace(/(\r\n|\r|\n)/g, '<br />'),
            "addedBy": sessionStorage.getItem('webApplicationLoginId'),
            "addedByName": displayName
        }
        let savedData: any = await dispatch(requestCorrectionToKam(data));
        if (savedData && savedData.message && savedData.message.status === 'S') {
            setCorrectionTextErrorMessage(false);
            setCorrectionTextError(false);
            props.handleopenCloseErrorSnackbar(false);
            setCorrectionText(null);
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage(savedData && savedData.message && savedData.message.message);
            setVerify(false);
            props.handleOpenCorrection(false);
            setLoading(false);
            dispatch(viewSpecialPriceKamAPI(responseIDData, "pricing_team"));
        } else if (savedData && savedData.message && savedData.message.status === "E") {
            props.handleopenCloseErrorSnackbar(true);
            props.showErrorMessage(savedData && savedData.error);
            setCorrectionTextError(false);
            setCorrectionText(null);
            props.handleopenCloseSnackbar(false);
            setVerify(false);
            props.handleOpenCorrection(false);
            setLoading(false);
        }
        props.handleOpenCorrection(false);
    }

    // ONCLICK OF VIEW HISTORY 
    const onClickViewHistory = async (id: any) => {
        setVerify(true);
        let data: any = await dispatch(requestCorrectionDetailsKam(id))
        if (data && data.message && data.message.status === "S") {
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage("Fetched data successfully");
        } else {
            props.handleopenCloseErrorSnackbar(true);
            props.showErrorMessage("Error in fetching the data");
        }
    }

    return (
        <Fragment>
            <Dialog
                open={props.openRequesCorrection ? true : false}
                onClose={() => {
                    props.handleOpenCorrection(false);
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    handleopenClose={() => { props.handleOpenCorrection(false); setCorrectionText(null); }} message={props.successMessage}
                />
                <div className={classes.root}>
                    <div className="col-12 mt-3 p-0">
                        {verify && (
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title px-4">
                                    Correction History
                                    <IconButton aria-label="close" className="float-right" onClick={() => { props.handleOpenCorrection(false); setVerify(false); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); setCorrectionText(null); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        )}
                    </div>
                    {!verify && (
                        <div className="row justify-content-end">
                            <div className="col-10 mt-0">
                                <DialogContent>
                                    <div className={classes.icon}>
                                        <CheckCircleIcon className="correction-meesage-warning" fontSize="small" />
                                    </div>
                                    <div>
                                        <DialogContentText id="alert-dialog-description">
                                            <div className={`mt-3 ${classes.correctionTextCSS}`}>
                                                <h6 className="mb-0">Please write your correction message. </h6>
                                            </div>
                                        </DialogContentText>
                                    </div>
                                </DialogContent>
                            </div>
                            <div className="col-2 mt-0">
                                <IconButton aria-label="close" className="float-right pr-5 mt-3 cursor-pointer" onClick={() => {
                                    props.handleOpenCorrection(false); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); props.handleopenCloseErrorSnackbar(false); props.handleopenCloseSnackbar(false); setCorrectionText(null); setVerify(false);
                                }}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </div>
                    )}
                    <DialogContent>
                        {verify && (
                            <div className="col-12">
                                <div className="d-flex flex-row border-bottom">
                                    <IconButton className="p-0 m-1 toast-header-icon" edge="end" aria-label="add"
                                        onClick={() => { setVerify(false); }}>
                                        <ChevronLeftIcon fontSize="medium" />
                                        <span className="info-sub-header-document-text m-2 mt-2 px-0">Back</span>
                                    </IconButton>
                                </div>
                                {correctionList&&correctionList.map((data: any) => {
                                    return <div>
                                        <div className="subTitle text-preline mt-2">{data.message ? data.message.replace(regex, "\n") : '-'}</div>
                                        <h6 className="document-sub-content pt-1 pb-2 mb-2 border-bottom">{data.addedOn ? moment(data.addedOn).tz('Asia/Dubai').format('hh:mm A DD MMM YYYY') : '-'}</h6>
                                    </div>
                                })
                                }
                            </div>
                        )}
                    </DialogContent>
                </div>
                {!verify && (
                    <div className="row col-md-12 mb-1 mt-0 px-5">
                        <div className="col-sm-12 col-md-12 px-5 pr-2 mb-0 mt-0">
                            <div className="col-6">
                                <TextareaAutosize id="correctionText" value={correctionText} onChange={(e: any) => handleCustomerChange(e)}
                                    aria-label="minimum height" minRows={6}
                                    placeholder="Correction Message.." className="textArea-correction p-4"
                                />
                            </div>
                            {correctionTextError &&
                                <span className="col-12" style={{ color: 'red' }}>Please enter correction message.</span>
                            }
                            {correctionTextErrorMessage &&
                                <span className="col-12" style={{ color: 'red' }}>Only 500 characters are allowed.</span>
                            }
                        </div>
                    </div>
                )}
                {!verify && (
                    <div className="row-space-start col-12">
                        <div className="col-12 m-0 p-0">
                            <div className="row mb-0 px-2">
                                <div className="col-12 mt-2">
                                    <div className="d-flex align-items-center">
                                        <Button
                                            className="mr-2 text-info"
                                            startIcon={<HistoryIcon className="history-icon font-size-22 mb-0 mt-2" />}
                                            onClick={() => { }}
                                        ><span className="onAccess-font mt-3 mb-2 pr-0" onClick={() => { onClickViewHistory(props.id); }}
                                        >View History
                                            </span>
                                        </Button>
                                        <div className="row-space-start ml-auto">
                                            <DialogActions className="mt-3 mb-1 justify-content-end">
                                                <Button variant="contained"
                                                    color="secondary" className="submit-correction-button"
                                                    onClick={() => { validateCorrectionMessage(); }}
                                                >Submit
                                                </Button>
                                                <Button variant="contained" className={classes.closeButton}
                                                    onClick={() => {
                                                        props.handleOpenCorrection();
                                                        handleOpenCorrection();
                                                        setCorrectionTextErrorMessage(false); setCorrectionTextError(false); props.handleopenCloseErrorSnackbar(false); props.handleopenCloseSnackbar(false); setCorrectionText(null); setVerify(false);
                                                    }}
                                                >Cancel
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row-space-start ml-auto">
                    <DialogActions className="mb-1 justify-content-end">
                        {verify && (
                            <Button variant="contained" className={classes.closeButton}
                                onClick={() => { props.handleOpenCorrection(); setVerify(false); setCorrectionTextErrorMessage(false); setCorrectionTextError(false); }}
                            >Close
                            </Button>
                        )}
                    </DialogActions>
                </div>
            </Dialog>
        </Fragment>
    );
}