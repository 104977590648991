/* eslint-disable */
import React, {
  Fragment,
  Dispatch,
  useEffect,
} from "react";
import "./AdminDashboard.css";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import SpecialOffers from "../SpecialOffer/SpecialOffers";
import Announcements from "../AdminAnnouncement/Announcements";
import TablePagination from "@material-ui/core/TablePagination";
import Avatar from "@material-ui/core/Avatar";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import { closeTask, getTasks, getDocumentReport, postOpenTask, setDocumentReportData, getReviewDocuments } from "../../store/actions/tasks.actions";
import { get } from "lodash";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import DealerDetailsDialog from "../Dealers/DealerDetailsDialog";
import ReportComponent from "./ReportComponent";
import { getIP } from "../../common/components/util";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import Menu, { MenuProps } from '@material-ui/core/Menu';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from '@material-ui/icons/Search';
import TextField from "@material-ui/core/TextField";
import { Badge, MenuItem } from "@material-ui/core";
import DocumentReportDialog from "./documentReportDialog";
import { getRegion } from "../../store/actions/onboard.actions";
import moment from "moment-timezone";
import XLSX from "xlsx";
import { isEmpty } from 'lodash';
import { notifyUserMail } from "../../store/actions/notifications.action";
import DatePicker from '../../common/components/dateRangePicker';
import ApproveDialog from "./approveDialog";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import ReviewDcouments from "./reviewDocuments";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    textTransform: "none",
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  collapseBg: {
    background: "rgba(66, 94, 108, 0.07)",
  },
  icon: {
    float: "left",
    margin: "0 20px 0px 0",
    height: "50",
    fill: "white",
  },
  bodyContent: {
    color: "#666666",
    fontWeight: 600,
    fontSize: 12,
    fontFamily: "Nunito",
    padding: 0,
    align: "center",
    maxWidth: "165px",
  },

  collapseIcon: {
    color: "#000000",
    fontWeight: 500,
  },
  tableHead: {
    color: "#133F8A",
    background: "#FFFFFF",
    fontWeight: 800,
    fontSize: 13,
    fontFamily: "Nunito",
    paddingTop: 15,
    lineHeight: 0,
    position: "sticky",
  },
  tableTitle: {
    fontWeight: 800,
    background: "FFFFFF",
    fontSize: 15,
    fontFamily: "Nunito",
    paddingLeft: 15,
    paddingTop: 15,
    paddingBottom: 15,
    position: "sticky",
    top: 0,
    color: '#000000'
  },
  tableContainer: {
    overflow: "none",
    height: "388px",
    borderTop: "1px solid #0000001A",
  },
  tableScroll: {
    overflow: "auto",
  },
  blueText: {
    fontSize: 11,
    fontWeight: 700,
    paddingRight: 0,
    color: "#1A9FE0",
    fontFamily: "Nunito"
  },

  subTableCell: {
    color: "#666666",
    fontSize: 10,
    fontFamily: "Nunito",
    fontWeight: 600
    // minWidth: 100,
  },
  // redText: {
  //   color: "#EC4242",
  //   fontSize: 11,
  //   fontWeight: 700,
  //   fontFamily: "Nunito",
  // },
  actionButton: {
    color: "#1A9FE0",
  },
  subTableBodyContent: {
    color: "#666666",
    fontWeight: 600,
    fontSize: "0.75rem",
    fontFamily: "Nunito",
    width: 140,
  },
}));

interface Column {
  id: "name" | "openTask" | "action";
  label: string;
  align: "left";
}

interface ColumnFSE {
  id: "date" | "name" | "openTask" | "expiryDate" | "action";
  label: string;
  align: "left";
}


const columns: Column[] = [
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "openTask",
    label: "Open Task",
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    align: "left",
  },
];

const columnsFSE: ColumnFSE[] = [
  {
    id: "date",
    label: "Date",
    align: "left",
  },
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "openTask",
    label: "Open Task",
    align: "left",
  },
  {
    id: "expiryDate",
    label: "Expiry Date",
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    align: "left",
  },
];

const reviewColumnsFSE: any = [
  {
    id: "date",
    label: "Date",
    align: "left",
  },
  {
    id: "name",
    label: "Name",
    align: "left",
  },
  {
    id: "documentName",
    label: "Document Name",
    align: "left",
  },
  {
    id: "customerDocuments",
    label: "Customer Document(s)",
    align: "left",
  },
  {
    id: "expiryDate",
    label: "Expiry Date",
    align: "left",
  },
  {
    id: "expiryDateUpdated",
    label: "Expiry Date(Updated Doc)",
    align: "left",
  },
  {
    id: "status",
    label: "Status",
    align: "left",
  },
  {
    id: "action",
    label: "Action",
    align: "left",
  },
];

const CUSTOMTAB_ACTIVE = withStyles({
  root: {
    textTransform: "none",
    color: "#1A9FE0",
    fontWeight: 700,
    fontFamily: "Nunito",

    paddingBottom: 4,
    paddingLeft: 0,
    height: "55px",
    alignItems: "left",
  },
})(Tab);

const CUSTOMTAB_INACTIVE = withStyles({
  root: {
    textTransform: "none",
    color: "#999999",
    fontWeight: 800,
    paddingLeft: 0,
    fontFamily: "Nunito",

    paddingBottom: 4,
    height: "55px",
  },
})(Tab);


const AdminDashboard: React.FC = () => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [page, setPage] = React.useState(0);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [closeTaskSuccess, setCloseTaskSuccess] = React.useState("");
  const [openDealerDetailDialog, setOpenDealerDetailDialog] = React.useState(false);
  const { tasks, postOpenTaskData, reviewDocuments }: any = useSelector((state: any) => state.tasks);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
  const tasksList = get(tasks, 'tasksList.data.openTaskList', []);
  // const { termsAndConditions }: any = useSelector((state: any) => state.termsAndConditions);
  const iPExist = sessionStorage.getItem('clientIp');
  let userTypeByProfileAPI = sessionStorage.getItem('userType-Profile-API');
  const [value, setValue] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState<string>("");
  const [startDate, setstartDate] = React.useState<any>(null);
  const [endDate, setendDate] = React.useState<any>(null);
  const [filterByRegion, setFilterByRegion] = React.useState(userTypeByProfileAPI === "KAM" ? "All" : '');
  const [docReportOpen, setDocumentReportOpen] = React.useState(false);
  const [reviewDocumentCount, setReviewDocumentCount] = React.useState(0);
  const [openDocumentExpiryStatusDialog, setOpenDocumentExpiryStatusDialog] = React.useState(false);
  const [openApproveDialog, setOpenApproveDialog] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [item, setItem] = React.useState<any>();
  const [isApprove, setIsApprove] = React.useState<any>();
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const { regionData }: any = useSelector((state: any) => state.onboard);
  const rowsPerPage = 10
  const region: any = get(profileDetails, 'data.data.basicInfo.region', '');
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const resetData = () => {
    setPage(0);
    setSearchValue('');
    setstartDate(null);
    setendDate(null);
  }
  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  useEffect(() => {
    // if (!termsAndConditions) dispatch(getTermsAndConditions());
    if (iPExist) {
    } else {
      getIP();
    }
    sessionStorage.setItem('webApplicationLoginId', webApplicationLoginId);
    // dispatch(userLogon('Admin'));
    // if (tasks === null || tasks.tasksList === null) {
    if (tasks && !tasks.tasksList) dispatch(getTasks(0));
    // }
    if (regionData && regionData.data && regionData.data.length > 0) {
    } else {
      if (userTypeByProfileAPI === "FSE" || userTypeByProfileAPI === "KAM") {
        dispatch(getRegion('All'));
      }
    }
  }, []);

  const setRegion = () => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      if (region && region.length > 0) {
        let data: any = regionData.data[0]?.regions?.find((item: any) => item.Region === region[0] || item.Region.includes(region[0]));
        if (data && data.Code) {
          setFilterByRegion(data.Code);
          getAllOpenTask(0, 0, data.Code);
        } else {
          getAllOpenTask();
        }
      } else {
        getAllOpenTask();
      }
    }
  }

  useEffect(() => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      setRegion();
    }
  }, [regionData]); // eslint-disable-line

  const getAllOpenTask = async (newPage?: any, page?: any, regions?: any, isExport?: any) => {
    setLoading(true);
    let data: any = {
      "sapCustomerId": searchValue ? searchValue : '',
      "customerName": searchValue ? searchValue : '',
      "webApplicationLoginId": webApplicationLoginId,
      "fromDate": startDate ? moment(startDate).utc().format("YYYY-MM-DD") : "",
      "toDate": endDate ? moment(endDate).utc().format("YYYY-MM-DD") : "",
      "regionCode": regions ? regions : filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : "",
    }

    if (isExport) data.isExport = true

    let savedData: any = await dispatch(postOpenTask(data, newPage ? newPage : page));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      setLoading(false);
      return savedData.data
    } else if (savedData && savedData.message && savedData.message.status === "E" || savedData && savedData.error) {
      // handleopenCloseErrorSnackbar(true);
      // showErrorMessage(savedData && savedData.error);
      // handleopenCloseSnackbar(false);
      setLoading(false);
    }
  }

  const getAllReviewDocuments = async (newPage?: any, page?: any, regions?: any, search?: any) => {
    setLoading(true);
    let data: any = {
      "sapCustomerId": search === '' ? search : searchValue ? searchValue : '',
      "customerName": search === '' ? search : searchValue ? searchValue : '',
      "webApplicationLoginId": webApplicationLoginId,
      "fromDate": search === '' ? '' : startDate ? moment(startDate).utc().format("YYYY-MM-DD") : "",
      "toDate": search === '' ? '' : endDate ? moment(endDate).utc().format("YYYY-MM-DD") : "",
      "regionCode": regions || regions === '' ? regions : filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : "",
    }

    let savedData: any = await dispatch(getReviewDocuments(data, newPage ? newPage : page));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      setLoading(false);
      return savedData.data
    } else if (savedData && savedData.message && savedData.message.status === "E" || savedData && savedData.error) {
      // handleopenCloseErrorSnackbar(true);
      // showErrorMessage(savedData && savedData.error);
      // handleopenCloseSnackbar(false);
      setLoading(false);
    }
  }

  const exportData = async () => {
    let data: any = await getAllOpenTask(0, 0, '', true);
    if (data && data.openTaskList && data.openTaskList.length > 0) {
      if (data.openTaskList.length > 0) {
        let filename = "exportOpenTasks.xlsx";
        let exportData: any = [];
        await data.openTaskList.map((item: any) => {
          // if (item.activityType === "document_expired") {
          if (item) {
            exportData.push({
              'Date': moment(item.createdAt).utc().format("YYYY-MM-DD"),
              'Customer Name': item.companyTitle + " " + item.companyName,
              'SAPCustomerID': item.sapCustomerId,
              'Open Task': item.title,
              'Expiry Date': moment(item.expiryDate).utc().format("YYYY-MM-DD"),
            });
          }
        });
        var ws = XLSX.utils.json_to_sheet(exportData);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "sheet1");
        await XLSX.writeFile(wb, filename);
        getAllOpenTask(0, 0);
      }
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenApproveDialog = (data: any) => {
    setOpenApproveDialog(data ? true : false)
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`dealer-tabpanel-${index}`}
        aria-labelledby={`dealer-tab-${index}`}
        {...other}
      >
        {value === index && (
          <div>{children}</div>
        )}
      </div>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }

  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      state(false);
      // setSubmitDisabled(true);
    } else if (e === 'Date should not be before minimal date') {
      state(false);
    } else if (e === 'Date should not be after maximal date') {
      state(false);
    } else {
      state(true);
    }
  }

  function Row(props: { row: any }) {
    const { row } = props;
    const taskDoneLoading = get(tasks, 'closeTask.data.loading', false);
    const openTaskLoading = postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.openTaskList;

    const handleopenCloseSnackbar = (open?: any) => {
      setOpenSnackbar(open ? true : false);
    };

    const handleCloseTask = async () => {
      const closeTask: any = await dispatch(getTasks(page));
      if (closeTask && closeTask.message && closeTask.message.status === "S") {
        setOpenSnackbar(true);
        setSuccessMessage("Task closed successfully.");
      } else {
        setOpenErrorSnackbar(true);
        setSuccessMessage("There was an error closing this task.");
      }
      setTimeout(() => {
        setOpenSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 4000)
    }


    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell className={`mw-210p ${classes.bodyContent}`}>
            <div className="mx-3 d-flex">
              <div style={{ display: "inline", position: 'relative' }}>
                <Avatar src={row.logo} style={{ position: 'absolute', margin: 0, top: '50%', transform: 'translateY(-50%)' }} />
              </div>
              <div className="ml-2 py-1 pr-1 cursor-none" style={{ display: "inline" }}>
                <h6 className={classes.blueText} style={{ marginLeft: '45px' }}
                // onClick={async () => {
                //   await dispatch(getDealerDetails(row.sapCustomerId, 'dealerDetails'))
                // }}
                >
                  {row.companyTitle} {row.companyName}
                </h6>
                {/* Add trade number when available */}
                <h6 className={`pt-1 ${classes.subTableCell}`} style={{ marginLeft: '45px' }}>-</h6>
              </div>
            </div>
          </TableCell>
          <TableCell className="font-12 font-weight-bold text-red mw-210p">{row.title}</TableCell>
          <TableCell align="left">
            <Button fullWidth className="font-size-11 rounded-pill" disabled={taskDoneLoading} variant="contained" color="primary"
              onClick={() => {
                dispatch(closeTask(row.id));
                handleCloseTask();
              }}>
              Done</Button>
          </TableCell>
        </TableRow>
        {openSnackbar &&
          <Snackbar
            open={openSnackbar}
            handleopenClose={() => {
              handleopenCloseSnackbar(false)
            }}
            text={closeTaskSuccess === "S" ? "Task closed successfully!" : "There was an error closing this task."} />
        }
      </React.Fragment>
    );
  }

  function RowFSE(props: { row: any }) {
    const { row } = props;
    const taskDoneLoading = get(tasks, 'closeTask.data.loading', false);
    const openTaskLoading = postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.openTaskList;

    const handleopenCloseSnackbar = (open?: any) => {
      setOpenSnackbar(open ? true : false);
    };

    return (
      <React.Fragment>
        <TableRow hover role="checkbox" tabIndex={-1}>
          <TableCell className="font-12 font-weight-bold dealer-table-content mw-210p">{row && row.createdAt ? moment(row && row.createdAt).utc().format("YYYY-MM-DD") : "-"}</TableCell>
          <TableCell className={`mw-210p ${classes.bodyContent}`}>
            <div className="mx-3 d-flex">
              <div style={{ display: "inline", position: 'relative' }}>
                <Avatar src={row.logo} style={{ position: 'absolute', margin: 0, top: '50%', transform: 'translateY(-50%)' }} />
              </div>
              <div className="ml-2 py-1 pr-1 cursor-none" style={{ display: "inline" }}>
                <h6 className={classes.blueText} style={{ marginLeft: '45px' }}
                // onClick={async () => {
                //   await dispatch(getDealerDetails(row.sapCustomerId, 'dealerDetails'))
                // }}
                >
                  {row.companyTitle} {row.companyName}
                </h6>
                <h6 className={`pt-1 ${classes.subTableCell}`} style={{ marginLeft: '45px' }}>SAP ID : {row && row.sapCustomerId}</h6>
              </div>
            </div>
          </TableCell>
          <TableCell className={row && row.severity === "3" ? "font-12 font-weight-bold text-red mw-210p" : row && row.severity === "2" ? "font-12 font-weight-bold text-orange mw-210p" : row && row.severity === "1" ? "font-12 font-weight-bold text-blue mw-210p" : "font-12 font-weight-bold text-black mw-210p"}>{row.title}</TableCell>
          <TableCell className="font-12 font-weight-bold dealer-table-content mw-210p">{row && row.expiryDate ? moment(row && row.expiryDate).utc().format("YYYY-MM-DD") : "-"}</TableCell>
          {/* {userTypeByProfileAPI === "FSE" ? */}
          {row && row.activityType === "document_expired" ?
            <TableCell align="left" className="col-2">
              <Button
                variant="contained"
                color="secondary"
                className="rounded-button-dealer bd-highlight"
                onClick={async () => {
                  let data: any = {
                    "sapCustomerId": row.sapCustomerId,
                    "webApplicationLoginId": webApplicationLoginId ? webApplicationLoginId : sessionStorage.getItem('webApplicationLoginId'),
                    "documentId": row.documentId
                  }
                  if (userTypeByProfileAPI === "KAM") {
                    data['fromKam'] = true;
                  }
                  let res: any = await dispatch(notifyUserMail(data));
                  if (res && res.message && res.message.status === "S") {
                    setSuccessMessage(res.message.message);
                    handleopenCloseSnackbar(true);
                  } else {
                    setSuccessMessage(res.error);
                    handleopenCloseErrorSnackbar(true);
                  }

                  setTimeout(() => {
                    setOpenSnackbar(false);
                    setOpenErrorSnackbar(false);
                  }, 5000)
                }}
              >
                Notify User
              </Button>
            </TableCell>
            : <TableCell align="left" className="col-2"> - </TableCell>
          }
          {/* <TableCell align="left">
              <Button fullWidth className="font-size-11 rounded-pill" disabled={taskDoneLoading} variant="contained" color="primary"
                onClick={() => {
                  dispatch(closeTask(row.id));
                  handleCloseTask();
                }}>
                Done</Button>
            </TableCell> */}
        </TableRow>
      </React.Fragment>
    );
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
    if (value === 0) {
      getAllOpenTask(newPage);
    } else {
      getAllReviewDocuments(newPage);
    }
  };

  const handleChange = async (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    await resetData();
    if (newValue === 1) {
      await setFilterByRegion('')
      await getAllReviewDocuments(0, 0, '', '')
    } else {
      await setRegion();
    }
  };

  const documentReport = async () => {
    // if (userTypeByProfileAPI === 'FSE') {
    // let data: any = {
    //   "sapCustomerId": "",
    //   "customerName": "",
    //   "webApplicationLoginId": webApplicationLoginId ? webApplicationLoginId : sessionStorage.getItem('webApplicationLoginId'),
    //   "fromDate": "",
    //   "toDate": "",
    // }
    // await dispatch(getDocumentReport(data, page));
    setDocumentReportOpen(true);
    // }
  };

  const handleOpenCloseReportPopup = (action: any) => {
    setDocumentReportOpen(action);
    if (action == false) {
      dispatch(setDocumentReportData(null));
    }
  }

  const tasksListLoading = tasks && tasks.tasksList && tasks.tasksList?.loading;
  const openTaskLoading = (postOpenTaskData && postOpenTaskData?.loading) || (reviewDocuments && reviewDocuments?.loading)

  return (
    <>
      {/* {userTypeByProfileAPI === "FSE" ? */}
      <Fragment>
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={successMessage} />
        <div className="d-flex align-items-center">
          <h2 className="page-title">
            Dashboard
          </h2>
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        </div>
        {openDealerDetailDialog &&
          <DealerDetailsDialog selectedTabValue={0} open={openDealerDetailDialog} onClose={(value: boolean) => setOpenDealerDetailDialog(value)} />
        }
        {docReportOpen &&
          <DocumentReportDialog
            open={docReportOpen}
            handleOpenCloseReportPopup={handleOpenCloseReportPopup} />}

        {/* {openApproveDialog &&
          <ApproveDialog open={openApproveDialog}
            isApprove={isApprove}
            item={item}
            handleopenClose={handleOpenApproveDialog} />
        } */}

        <div className="col-12 cardCommon specialOffers lostSaleReport">
          <div className="col-12 row card d-flex flex-row-reverse bd-highlight card-height-kam-filters mt-4 mb-2">
            {/* <div className="col- 1">
              <IconButton aria-label="close" className="mt-3 ml-n3" onClick={async () => {
                if (expiryDate) {
                  setExpiryDate(null)
                  setPage(0);
                  setLoading(true);
                  let data = {
                    "sapCustomerId": searchValue ? searchValue : '',
                    "customerName": searchValue ? searchValue : '',
                    "webApplicationLoginId": webApplicationLoginId,
                    "expiryDate": "",
                    "regionCode": filterByRegion ? filterByRegion : '',
                  }
                  let savedData: any = await dispatch(postOpenTask(data, page));
                  if (savedData && savedData.message && savedData.message.status === 'S') {
                    setLoading(false);
                  }
                }
              }
              }>
                <CloseIcon style={{ fill: "white", backgroundColor: "#1a9fe0", borderRadius: "45px", padding: "4", fontSize: "23" }} />
              </IconButton>
            </div> */}
            <div className="col-4 mt-3 float-right">
              <DatePicker
                startDate={startDate}
                endDate={endDate}
                setstartDate={setstartDate}
                setendDate={setendDate}
                // isEnableOnlyThirtyDays={true}
                isDisableBeforeOneYear={false}
                onChange={async (start: any, end: any) => {
                  setstartDate(start);
                  setendDate(end);
                  if (start && end) {
                    let data = {
                      "sapCustomerId": searchValue ? searchValue : '',
                      "customerName": searchValue ? searchValue : '',
                      "webApplicationLoginId": webApplicationLoginId,
                      "fromDate": start ? moment(start).utc().format("YYYY-MM-DD") : "",
                      "toDate": end ? moment(end).utc().format("YYYY-MM-DD") : "",
                      "regionCode": filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : '',
                    }
                    let savedData: any;
                    if (value === 0) {
                      savedData = await dispatch(postOpenTask(data, page));
                    } else {
                      savedData = await dispatch(getReviewDocuments(data, page));
                    }
                    if (savedData && savedData.message && savedData.message.status === 'S') {
                      setLoading(false);
                    } else {
                      setLoading(false);
                    }
                  } else if (start === null && end === null) {
                    let data = {
                      "sapCustomerId": searchValue ? searchValue : '',
                      "customerName": searchValue ? searchValue : '',
                      "webApplicationLoginId": webApplicationLoginId,
                      "fromDate": "",
                      "toDate": "",
                      "regionCode": filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : '',
                    }
                    let savedData: any;
                    if (value === 0) {
                      savedData = await dispatch(postOpenTask(data, page));
                    } else {
                      savedData = await dispatch(getReviewDocuments(data, page));
                    }
                    if (savedData && savedData.message && savedData.message.status === 'S') {
                      setLoading(false);
                    } else {
                      setLoading(false);
                    }
                  }
                }}
              />
            </div>
            <FormControl variant="outlined" className="mt-3 col-3">
              <InputLabel htmlFor="dealer-search" className="pl-2" margin="dense">Search by Customer or SAP ID</InputLabel>
              <OutlinedInput
                id="dealer-search"
                value={searchValue}
                onKeyPress={async (ev) => {
                  if (ev.key === 'Enter') {
                    setPage(0);
                    setLoading(true);
                    let data = {
                      "sapCustomerId": searchValue ? searchValue : '',
                      "customerName": searchValue ? searchValue : '',
                      "webApplicationLoginId": webApplicationLoginId,
                      "fromDate": startDate ? moment(startDate).utc().format("YYYY-MM-DD") : "",
                      "toDate": endDate ? moment(endDate).utc().format("YYYY-MM-DD") : "",
                      "regionCode": filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : '',
                    }
                    let savedData: any;
                    if (value === 0) {
                      savedData = await dispatch(postOpenTask(data, page));
                    } else {
                      savedData = await dispatch(getReviewDocuments(data, page));
                    }
                    if (savedData && savedData.message && savedData.message.status === 'S') {
                      setLoading(false);
                    } else {
                      setLoading(false);
                    }
                  }
                }}
                onChange={(e: any) => {
                  setSearchValue(e.target.value);
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Search by Customer or SAP ID"
                      edge="end"
                      onClick={async (e: any) => {
                        setLoading(true);
                        let data = {
                          "sapCustomerId": searchValue ? searchValue : '',
                          "customerName": searchValue ? searchValue : '',
                          "webApplicationLoginId": webApplicationLoginId,
                          "fromDate": startDate ? moment(startDate).utc().format("YYYY-MM-DD") : "",
                          "toDate": endDate ? moment(endDate).utc().format("YYYY-MM-DD") : "",
                          "regionCode": filterByRegion == 'All' ? '' : filterByRegion ? filterByRegion : '',
                        }
                        let savedData: any;
                        if (value === 0) {
                          savedData = await dispatch(postOpenTask(data, page));
                        } else {
                          savedData = await dispatch(getReviewDocuments(data, page));
                        }
                        if (savedData && savedData.message && savedData.message.status === 'S') {
                          setLoading(false);
                        } else {
                          setLoading(false);
                        }
                        setPage(0);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                labelWidth={200} margin="dense" className="rounded-input-dealer bd-highlight mw-120p mr-2 mb-2" />
            </FormControl>
            <div className="col-2 mt-3">
              <TextField
                id="filterByRegion"
                select
                label="Filter By Region"
                variant="outlined"
                className="col-12 rounded-input-dealer bd-highlight mw-120p mr-2 mb-2"
                size="small"
                onChange={async (e: any) => {
                  setPage(0);
                  if (e.target.value === '0') {
                    setFilterByRegion('')
                  } else {
                    setFilterByRegion(e.target.value)
                  }
                  setLoading(true);
                  let data = {
                    "sapCustomerId": searchValue ? searchValue : "",
                    "customerName": searchValue ? searchValue : "",
                    "webApplicationLoginId": webApplicationLoginId,
                    "fromDate": startDate ? moment(startDate).utc().format("YYYY-MM-DD") : "",
                    "toDate": endDate ? moment(endDate).utc().format("YYYY-MM-DD") : "",
                    "regionCode": e.target.value === '0' || e.target.value === 'All' ? '' : e.target.value,
                  }
                  let savedData: any;
                  if (value === 0) {
                    savedData = await dispatch(postOpenTask(data, page));
                  } else {
                    savedData = await dispatch(getReviewDocuments(data, page));
                  }
                  if (savedData && savedData.message && savedData.message.status === 'S') {
                    setLoading(false);
                  } else {
                    setLoading(false);
                  }
                }}
                value={filterByRegion}
              >
                {userTypeByProfileAPI === "KAM" && (
                  <MenuItem value={"All"}>
                    All
                  </MenuItem>
                )}
                {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                <MenuItem value={"0"}>
                  Clear Filter
                </MenuItem>
              </TextField>
            </div>
            <div className="mt-3 mr-3">
              <Button
                fullWidth
                size="small"
                color="primary"
                variant="contained"
                className="rounded-pill font-size-11 py-2 px-0"
                onClick={documentReport}
              >
                Document Report
              </Button>
            </div>
          </div>
          <div className="col-12">
            <div className="col-4 opentask">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                variant="fullWidth"
                className="font-weight-700 font-bold"
                TabIndicatorProps={{
                  style: {
                    height: 3,
                    padding: 0,
                    margin: 0,
                    backgroundColor: "#1A9FE0",
                    borderRadius: "2px",
                  },
                }}
              >
                <CUSTOMTAB_ACTIVE className="font-weight-700 font-bold" label="Open Task" {...a11yProps(0)} />
                <CUSTOMTAB_ACTIVE label="Review Documents" {...a11yProps(1)} />
                <Badge badgeContent={reviewDocumentCount} color="primary">
                </Badge>
              </Tabs>
            </div>
            <TabPanel value={value} index={0}>
              <div className="row mt-3">
                <div className="col-lg-12 col-ml-12 col-xl-12 mb-4">
                  <div className="cardCommon mh-300p">
                    {tasksListLoading || openTaskLoading || sessionStorage.getItem('webApplicationLoginId') === "" ? <FullscreenLoader /> :
                      <>
                        <TableContainer className={classes.tableContainer}>
                          <Table aria-label="table">
                            <TableHead>
                              <TableRow>
                                {columnsFSE.map((column) => (
                                  <TableCell
                                    className={classes.tableHead}
                                    key={column.id}
                                    align={column.align}
                                    style={{ paddingRight: 0 }}
                                  >
                                    {column.label}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody className={classes.tableScroll}>
                              {postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.openTaskList.map((row: any) => {
                                return <RowFSE key={row.id} row={row} />;
                              })}
                            </TableBody>
                          </Table>
                          {userType !== "KAM" && isEmpty(postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.openTaskList) &&
                            <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Open Task Found.</div>
                          }
                          {userType === "KAM" && isEmpty(postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.openTaskList) &&
                            <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Open Task Found.</div>
                          }
                        </TableContainer>
                        <div className="col-12 d-flex justify-content-between mt-0">
                          <div className="mt-3">
                            <Button
                              fullWidth
                              size="small"
                              color="primary"
                              variant="contained"
                              disabled={userType === "KAM" ? true : false}
                              className="rounded-pill font-size-11 py-2 px-0"
                              onClick={() => {
                                exportData();
                              }}
                            >
                              Export Open Task
                            </Button>
                          </div>
                          <div className="mt-2 mr-3">
                            <TablePagination
                              rowsPerPageOptions={[10]}
                              component="div"
                              count={postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.totalCount ? postOpenTaskData && postOpenTaskData.data && postOpenTaskData.data.data && postOpenTaskData.data.data.data && postOpenTaskData.data.data.data.totalCount : 0}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
                            />
                          </div>
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel value={value} index={1}>
              {loading || openTaskLoading || sessionStorage.getItem('webApplicationLoginId') === "" ? <FullscreenLoader /> :
                <div className="cardCommon col-12">
                  <ReviewDcouments value={value} />
                  <div className="d-flex justify-content-end mt-0">
                    <div className="mt-2 mr-3 float-right">
                      <TablePagination
                        rowsPerPageOptions={[10]}
                        component="div"
                        count={reviewDocuments && reviewDocuments.data && reviewDocuments.data.data && reviewDocuments.data.data.data && reviewDocuments.data.data.data.count ? reviewDocuments && reviewDocuments.data && reviewDocuments.data.data && reviewDocuments.data.data.data && reviewDocuments.data.data.data.count : 0}
                        rowsPerPage={10}
                        page={page}
                        onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
                      />
                    </div>
                  </div>
                </div>
              }
            </TabPanel>
            <div className="row mt-2">
              <div className="col-lg-4 col-ml-4 col-xl-4 mb-2">
                <Announcements />
              </div>
              <div className="col-lg-4 col-ml-4 col-xl-4 mb-2">
                {/* <h5 className="page-sub-title mb-2">Reports</h5> */}
                <ReportComponent />
              </div>
              <div className="col-lg-4 col-ml-4 col-xl-4 mb-2">
                <SpecialOffers />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default AdminDashboard;
