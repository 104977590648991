import React, { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
  createDealer as addDealer, createDealerUser as addDealerUser,
  getAllDealers, checkUserIdAvailability, verifySAPCustomerId,
  updateDealerData as updateDealerAction, updateDealerUser as updateDealerUserAction, uploadDocument, deleteDocument, sendEmail, getUploadedDocuments, updateDealerInProgressUser,
} from "../../store/actions/dealer.action";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CountryList from '../../components/Dealers/CountryList.json';
import RegionList from '../../components/Dealers/RegionList.json';
import { API_ENDPOINTS } from "../../constants/apiConfig";
import moment from "moment-timezone";
import Loading from '../../common/components/loading';
import ErrorIcon from '@material-ui/icons/Error';
import { Tooltip } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Snackbar from './../../common/components/Snackbar';
import { get, isEmpty } from 'lodash';
import { withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Messages, ValidationErrorMessage } from '../../constants/messages';
import { getNewBrand } from '../../common/components/util';
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dealer-tabpanel-${index}`}
      aria-labelledby={`dealer-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>{children}</div>
      )}
    </div>
  );
}

function allTabProps(index: any) {
  return {
    id: `dealer-tab-${index}`,
    'aria-controls': `dealer-tabpanel-${index}`,
  };
}

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const BrandMenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

const brands = [
  // {
  //   "make": "Famco",
  //   "organizationId": "2003",
  // },
  {
    "make": "Honda",
    "organizationId": "2002",
  },
  {
    "make": "TE",
    "organizationId": "2380",
  },
  {
    "make": "Toyota",
    "organizationId": "2001",
  },
  {
    "make": "OES",
    "organizationId": "2195",
  }
]
const currencies = ["AED", "USD"];
const languages = ["AE", "EN"];


export default function AddDealerDialog(props: any) {
  const today: any = moment(new Date()).format("YYYY-MM-DD");
  const [companyName, setCompanyName] = React.useState<string>("");
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [companyEmail, setCompanyEmail] = React.useState<string>("");
  const [companyEmailError, setCompanyEmailError] = React.useState<boolean>(false);
  const [companyTelephone, setCompanyTelephone] = React.useState<string>("");
  const [companyTelephoneError, setCompanyTelephoneError] = React.useState<boolean>(false);
  const [companyTradeLicense, setCompanyTradeLicense] = React.useState<string>("");
  const [companyTradeLicenseError, setCompanyTradeLicenseError] = React.useState<boolean>(false);
  const [taxRegistrationNo, setTaxRegistrationNo] = React.useState<string>("");
  const [taxRegistrationNoError, setTaxRegistrationNoError] = React.useState<boolean>(false);
  const [companyAddress, setCompanyAddress] = React.useState<string>("");
  const [companyAddressError, setCompanyAddressError] = React.useState<boolean>(false);
  const [companyTOCurrency, setCompanyTOCurrency] = React.useState<string>(currencies[0]);
  const [companyAnnualTurnover, setCompanyAnnualTurnover] = React.useState<string>("");

  const [ownerFirstName, setOwnerFirstName] = React.useState<string>("");
  const [ownerFirstNameError, setOwnerFirstNameError] = React.useState<boolean>(false);
  const [ownerLastName, setOwnerLastName] = React.useState<string>("");
  const [ownerLastNameError, setOwnerLastNameError] = React.useState<boolean>(false);
  const [ownerEmail, setOwnerEmail] = React.useState<string>("");
  const [ownerEmailError, setOwnerEmailError] = React.useState<boolean>(false);
  const [ownerMobile, setOwnerMobile] = React.useState<string>("");
  const [ownerMobileError, setOwnerMobileError] = React.useState<boolean>(false);

  const [companyBankName, setCompanyBankName] = React.useState<string>("");
  const [companyIBAN, setCompanyIBAN] = React.useState<string>("");
  const [companyBankAddress, setCompanyBankAddress] = React.useState<string>("");

  const [brandNames, setBrandNames] = React.useState<string[]>([]);
  // const [brandNamesError, setBrandNamesError] = React.useState<boolean>(false);
  // const [brandCurrency, setBrandCurrency] = React.useState<string>(currencies[0]);
  // const [companyCreditLimit, setCompanyCreditLimit] = React.useState<string>("");
  const [companySAPCustId, setCompanySAPCustId] = React.useState<string>("");
  const [companySAPCustIdError, setCompanySAPCustIdError] = React.useState<boolean>(false);
  const [companyLanguage, setCompanyLanguage] = React.useState<string>("");
  const [companyLanguageError, setCompanyLanguageError] = React.useState<boolean>(false);
  const [companyCurrency, setCompanyCurrency] = React.useState<string>("");
  const [companyCurrencyError, setCompanyCurrencyError] = React.useState<boolean>(false);


  const [passportExpiryDate, setPassportExpiryDate] = React.useState<any>(null);
  const [visaExpiryDate, setVisaExpiryDate] = React.useState<any>(null);
  const [emiratesIdExpiryDate, setEmirateIdExpiryDate] = React.useState<any>(null);
  const [tradeLicenseExpiryDate, setTradeLicenseExpiryDate] = React.useState<any>(null);
  const [membershipExpiryDate, setMembershipExpiryDate] = React.useState<any>(null);
  const [vatExpiryDate, setVatExpiryDate] = React.useState<any>(null);

  const [isValidPassportExpiryDate, setIsValidPassportExpiryDate] = React.useState<any>(true);
  const [isValidVisaExpiryDate, setIsValidVisaExpiryDate] = React.useState<any>(true);
  const [isValidEmiratesIdExpiryDate, setIsValidEmirateIdExpiryDate] = React.useState<any>(true);
  const [isValidTradeLicenseExpiryDate, setIsValidTradeLicenseExpiryDate] = React.useState<any>(true);
  const [isValidMembershipExpiryDate, setIsValidMembershipExpiryDate] = React.useState<any>(true);
  const [isValidVatExpiryDate, setIsValidVatExpiryDate] = React.useState<any>(true);

  const [poaFile, setPOAFile] = React.useState<any>(null);
  const [bankStatementFile, setBankStatementFile] = React.useState<any>(null);
  const [bankGuaranteeFile, setBankGuaranteeFile] = React.useState<any>(null);
  const [vatReturnFile, setVatReturnFile] = React.useState<any>(null);
  const [visitReportFile, setVisitReportFile] = React.useState<any>(null);
  const [passportFile, setPassportFile] = React.useState<any>(null);
  const [visaCopyFile, setVisaCopyFile] = React.useState<any>(null);
  const [emiratesIdFile, setEmiratesIdFile] = React.useState<any>(null);
  const [tradeLicenseFile, setTradeLicenseFile] = React.useState<any>(null);
  const [membershipFile, setMembershipFile] = React.useState<any>(null);
  const [vatTRNFile, setVatTRNFile] = React.useState<any>(null);

  const [adminUserId, setAdminUserId] = React.useState<string>("");
  const [adminUserIdError, setAdminUserIdError] = React.useState<boolean>(false);
  const [adminTitle, setAdminTitle] = React.useState<string>("");
  const [adminFirstName, setAdminFirstName] = React.useState<string>("");
  const [adminFirstNameError, setAdminFirstNameError] = React.useState<boolean>(false);
  const [adminLastName, setAdminLastName] = React.useState<string>("");
  const [adminLastNameError, setAdminLastNameError] = React.useState<boolean>(false);
  const [adminMobile, setAdminMobile] = React.useState<string>("");
  const [adminMobileError, setAdminMobileError] = React.useState<boolean>(false);
  const [adminEmail, setAdminEmail] = React.useState<string>("");
  const [adminEmailError, setAdminEmailError] = React.useState<boolean>(false);
  const [adminUserType, setAdminUserType] = React.useState<string>("Admin");
  const [adminUserTypeError, setAdminUserTypeError] = React.useState<boolean>(false);
  const [adminPhone2, setAdminPhone2] = React.useState<string>("");
  const [adminValidFrom, setAdminValidFrom] = React.useState<Date | null>(today);
  const [adminValidFromError, setAdminValidFromError] = React.useState<boolean>(false);
  const [adminValidTo, setAdminValidTo] = React.useState<Date | null>(new Date('9999-12-31'));
  const [adminValidToError, setAdminValidToError] = React.useState<boolean>(false);
  const [adminFax, setAdminFax] = React.useState<string>("");
  const [adminCountry, setAdminCountry] = React.useState<string>("");
  const [adminRegion, setAdminRegion] = React.useState<string>("");
  const [lockIndicator, setLockIndicator] = React.useState<boolean>(false);
  const [lockReason, setLockReason] = React.useState<string>("");

  // const [companyNameDisabled, setCompanyNameDisabled] = React.useState<any>();
  // const [companyEmailDisabled, setCompanyEmailDisabled] = React.useState<any>();
  // const [companyTelephoneDisabled, setCompanyTelephoneDisabled] =React.useState<any>();
  // const [companyAddressDisabled, setCompanyAddressDisabled] = React.useState<any>();
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);
  const [isUserIdValid, setIsUserIdValid] = React.useState<boolean>(false);
  const [userIdErrorText, setUserIdErrorText] = React.useState<string>("");
  const [sapCustIdErrorText, setSAPCustIdErrorText] = React.useState<string>("");
  const [isSAPCustIdValid, setIsSAPCustIdValid] = React.useState<boolean>(false);
  const [isSAPCustIdDisabled, setIsSAPCustIdDisabled] = React.useState<boolean>(false);
  const [isWebLoginIdDisabled, setIsWebLoginIdDisabled] = React.useState<boolean>(false);
  // const [invalidTelephone, setInvalidTelephone] = React.useState<boolean>(false);
  const [invalidMobile, setInvalidMobile] = React.useState<boolean>(false);
  const [invalidTax, setInvalidTax] = React.useState<boolean>(false);
  // const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [companyIBANInvalid, setCompanyIBANInvalid] = React.useState<boolean>(false);
  // const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean>(false);
  const [invalidOwnerFirstName, setInvalidOwnerFirstName] = React.useState<boolean>(false);
  const [invalidOwnerLastName, setInvalidOwnerLastName] = React.useState<boolean>(false);
  const [companyBankNameInvalid, setCompanyBankNameInvalid] = React.useState<boolean>(false);
  const [invalidAdminMobile, setInvalidAdminMobile] = React.useState<boolean>(false);
  const [invalidAdminPhone2, setInvalidAdminPhone2] = React.useState<boolean>(false);
  const [invalidAdminFax, setInvalidAdminFax] = React.useState<boolean>(false);
  const [invalidAdminFirstName, setInvalidAdminFirstName] = React.useState<boolean>(false);
  const [invalidAdminLastName, setInvalidAdminLastName] = React.useState<boolean>(false);
  const [invalidAnnualTurnover, setInvalidAnnualTurnover] = React.useState<boolean>(false);
  const [invalidCompanyTradeLicense, setInvalidCompanyTradeLicense] = React.useState<boolean>(false);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [apiResponseText, setApiResponseText] = React.useState(false);

  const [tabValue, setTabValue] = React.useState(0);
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { verifiedSAPCustomer, dealerDetails, createDealer, updateDealer, createDealerUser, uploadedDocuments, updateDealerUser, uploadDocumentData }: any = useSelector((state: any) => state.dealer);
  let adminDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.userDetails) ? dealerDetails.data.data.userDetails : null;
  const dispatch: Dispatch<any> = useDispatch();

  useEffect(() => {
    if (props.isEdit === true) {
      populateDealerDetails();
      setIsUserIdValid(true);
      setIsSAPCustIdValid(true);
      setIsSAPCustIdDisabled(true);
      setIsWebLoginIdDisabled(true);
    } else {
      setIsUserIdValid(false);
      setIsSAPCustIdValid(false);
      setIsSAPCustIdDisabled(false);
      setIsWebLoginIdDisabled(false);
    }
  }, [props.isEdit, dealerDetails]); // eslint-disable-line

  useEffect(() => {
    const sapCustomerId: any = get(dealerDetails, 'data.data.customerDetails.sapCustomerId', false),
      organization = get(dealerDetails, 'data.data.customerDetails.organizationIds[0]', {});

    if (sapCustomerId) {
      const customHeaders = {
        "Lob-Id": "AUTO",
        "Channel-Id": "B2B",
        "Org-Id": organization.organizationId || "",
        "Accept-Language": "EN",
      };

      dispatch(getUploadedDocuments(customHeaders, sapCustomerId));
    };
  }, [dealerDetails]); // eslint-disable-line

  useEffect(() => {
    const { data } = uploadedDocuments || {},
      sapCustomerId = get(dealerDetails, 'data.data.customerDetails.sapCustomerId', false);
    if (isEmpty(data)) return;
    // let documents: any = [];
    data.map((item: any) => { // eslint-disable-line
      const { label, expiryDate, url, id } = item;

      let name = '';
      if (label === 'poaFile') { name = 'Power Of Attorney' }
      else if (label === 'bankStatementFile') { name = 'Bank Statements' }
      else if (label === 'bankGuaranteeFile') { name = 'Bank Guarantee' }
      else if (label === 'vatReturnFile') { name = 'VAT Return' }
      else if (label === 'visitReportFile') { name = 'Visit Report' }
      else if (label === 'passportFile') { name = 'Valid Passport Copy'; setPassportExpiryDate(expiryDate) }
      else if (label === 'visaCopyFile') { name = 'Valid Visa Copy'; setVisaExpiryDate(expiryDate) }
      else if (label === 'emiratesIdFile') { name = 'Valid Emirates ID Copy'; setEmirateIdExpiryDate(expiryDate) }
      else if (label === 'tradeLicenseFile') { name = 'Valid Trade License'; setTradeLicenseExpiryDate(expiryDate) }
      else if (label === 'membershipFile') { name = 'Membership Certificate'; setMembershipExpiryDate(expiryDate) }
      else if (label === 'vatTRNFile') { name = 'VAT TRN Certificate'; setVatExpiryDate(expiryDate) }

      const doc = {
        label,
        url,
        name,
        expiryDate,
        id,
        sapCustomerId
      };

      if (label === 'poaFile') { setPOAFile(doc) }
      else if (label === 'bankStatementFile') { setBankStatementFile(doc) }
      else if (label === 'bankGuaranteeFile') { setBankGuaranteeFile(doc) }
      else if (label === 'vatReturnFile') { setVatReturnFile(doc) }
      else if (label === 'visitReportFile') { setVisitReportFile(doc) }
      else if (label === 'passportFile') { setPassportFile(doc) }
      else if (label === 'visaCopyFile') { setVisaCopyFile(doc) }
      else if (label === 'emiratesIdFile') { setEmiratesIdFile(doc) }
      else if (label === 'tradeLicenseFile') { setTradeLicenseFile(doc) }
      else if (label === 'membershipFile') { setMembershipFile(doc) }
      else if (label === 'vatTRNFile') { setVatTRNFile(doc) }
    });
  }, [uploadedDocuments]); // eslint-disable-line

  useEffect(() => {
    const item = uploadDocumentData && uploadDocumentData.data && uploadDocumentData.data.data && uploadDocumentData.data.data.data ? uploadDocumentData.data.data.data : {};

    let sapCustomerId;
    if (props.isEdit) sapCustomerId = get(dealerDetails, 'data.data.customerDetails.sapCustomerId', false);
    if (!props.isEdit) sapCustomerId = createDealer && createDealer.data && createDealer.data.data && createDealer.data.data.data ? createDealer.data.data.data.sapCustomerId : "";

    const { label, expiryDate, url, id } = item;
    let name = '';
    if (label === 'poaFile') { name = 'Power Of Attorney' }
    else if (label === 'bankStatementFile') { name = 'Bank Statement' }
    else if (label === 'bankGuaranteeFile') { name = 'Bank Guarantee' }
    else if (label === 'vatReturnFile') { name = 'VAT Return' }
    else if (label === 'visitReportFile') { name = 'Visit Report' }
    else if (label === 'passportFile') { name = 'Valid Passport Copy'; setPassportExpiryDate(expiryDate) }
    else if (label === 'visaCopyFile') { name = 'Valid Visa Copy'; setVisaExpiryDate(expiryDate) }
    else if (label === 'emiratesIdFile') { name = 'Valid Emirates ID Copy'; setEmirateIdExpiryDate(expiryDate) }
    else if (label === 'tradeLicenseFile') { name = 'Valid Trade License With Partner/Shareholder'; setTradeLicenseExpiryDate(expiryDate) }
    else if (label === 'membershipFile') { name = 'Membership Certificate'; setMembershipExpiryDate(expiryDate) }
    else if (label === 'vatTRNFile') { name = 'VAT TRN Certificate'; setVatExpiryDate(expiryDate) }

    const doc = {
      label,
      url,
      name,
      expiryDate,
      id,
      sapCustomerId
    };

    if (label === 'poaFile') { setPOAFile(doc) }
    else if (label === 'bankStatementFile') { setBankStatementFile(doc) }
    else if (label === 'bankGuaranteeFile') { setBankGuaranteeFile(doc) }
    else if (label === 'vatReturnFile') { setVatReturnFile(doc) }
    else if (label === 'visitReportFile') { setVisitReportFile(doc) }
    else if (label === 'passportFile') { setPassportFile(doc) }
    else if (label === 'visaCopyFile') { setVisaCopyFile(doc) }
    else if (label === 'emiratesIdFile') { setEmiratesIdFile(doc) }
    else if (label === 'tradeLicenseFile') { setTradeLicenseFile(doc) }
    else if (label === 'membershipFile') { setMembershipFile(doc) }
    else if (label === 'vatTRNFile') { setVatTRNFile(doc) }
  }, [uploadDocumentData]); // eslint-disable-line

  useEffect(() => {
    if (tabValue === 2 && !props.isEdit)
      setSubmitDisabled(true);
    else
      setSubmitDisabled(false);
  }, [tabValue]); // eslint-disable-line

  useEffect(() => {
    if (isValidPassportExpiryDate && isValidVisaExpiryDate && isValidEmiratesIdExpiryDate && isValidTradeLicenseExpiryDate && isValidMembershipExpiryDate && isValidVatExpiryDate) {
      setSubmitDisabled(false);
    }
    else {
      setSubmitDisabled(true);
    }
  }, [isValidPassportExpiryDate, isValidVisaExpiryDate, isValidEmiratesIdExpiryDate, isValidTradeLicenseExpiryDate, isValidMembershipExpiryDate, isValidVatExpiryDate]);

  useEffect(() => {
    if (adminUserId === "") {
      setIsWebLoginIdDisabled(false);
      setIsUserIdValid(false);
    }
    if (adminValidTo === null) {
      setAdminValidTo(new Date('9999-12-31'))
    }
    if (adminValidFrom === null) {
      setAdminValidFrom(today)
    }
  }, [isWebLoginIdDisabled]) // eslint-disable-line

  const emailLinkOnDealerCreate = (createDealerUser: any) => {
    if (createDealerUser && createDealerUser.data) {
      if (createDealerUser.data.sendLink) {
        const data = {
          "fromAddress": "tradepoint@alfuttaim.com",
          "toAddress": createDealerUser.data.email,
          "subject": "Account Verification and Self-Registration",
          "senderName": "Auto B2B",
          "templateName": "B2B_CUSTOMER_INVITE",
          "templateData": {
            "DateOfMail": moment(new Date()).format("DD/MM/YYYY"),
            "Url": `${API_ENDPOINTS.ON_BOARDING_URL}/signup?b2bUserId=${createDealerUser.data.b2bUserId}`,
            "CustomerName": "",//createDealer && createDealer.data && createDealer.data.data && createDealer.data.data.data ? `${createDealer.data.data.data.title} ${createDealer.data.data.data.companyName}` : '',
            "Name": `${createDealerUser.data.firstName} ${createDealerUser.data.lastName}`
          }
        }
        dispatch(sendEmail(data));
      }
    }
  };

  // const handleBrandChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   let currentBrands = event.target.value as string[];
  //   setBrandNames(currentBrands);
  //   if (currentBrands && currentBrands.length > 0)
  //     setBrandNamesError(false);
  //   else
  //     setBrandNamesError(true);
  // };

  // const handleBrandChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   const { options } = event.target as HTMLSelectElement;
  //   const value: string[] = [];
  //   for (let i = 0, l = options.length; i < l; i += 1) {
  //     if (options[i].selected) {
  //       value.push(options[i].value);
  //     }
  //   }
  //   setBrandNames(value);
  // };

  function formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }

  function parseDateFromString(dateString: string) {
    let d = dateString.split('-');
    try {
      return d && d.length ? new Date(d[1] + "/" + d[0] + "/" + d[2]) : null;
    } catch (err) {
      return null;
    }
  }

  function validateMobile(number: string) {
    const re = /^[5]\d{8}$/;
    return re.test(number);
  }

  // function validateLandline(number: string) {
  //   const re = /^(?:[1-9]\d*|0)$/;

  //   return companyTelephone.length !== 8 || !re.test(number) ? false : true;
  // }

  function validateTaxRegistration(number: string) {
    const re = /^[0-9]\d{14}$/;
    return re.test(number);
  }

  function validateAdminUserID(value: string) {
    if (value.includes(" ") || value.split('').length > 15) {
      return false;
    }
    else {
      return true;
    }
  }


  function validateCompanyIBAN(value: string) {
    const re = value.split('');

    if (re.length < 23) {
      return false;
    }

    const filterNumbers = re.filter((r) => {
      return !/\D/.test(r);
    })

    if (re.length === 23 && /\D/.test(re[0]) && /\D/.test(re[1]) && filterNumbers.length === 21) {
      return true;
    }
    else {
      return false;
    }
  }

  function validateDealerTab() {
    let isValid: boolean = true;
    if (companySAPCustId.trim() === "") {
      setCompanySAPCustIdError(true);
      isValid = false;
    }
    // if (companyName.trim() === "") {
    //   setCompanyNameError(true);
    //   isValid = false;
    // }
    // if (companyName.trim() !== "") {
    //   if (companyName.split('').length > 250) {
    //     setInvalidCompanyName(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidCompanyName(false);
    //   }
    // }
    if (companyAnnualTurnover.trim() !== "") {
      if (companyAnnualTurnover.split('').length > 20) {
        setInvalidAnnualTurnover(true);
        isValid = false;
      }
      else {
        setInvalidAnnualTurnover(false);
      }
    }
    // if (companyEmail.trim() === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(companyEmail.trim()))) {  // eslint-disable-line
    //   setCompanyEmailError(true);
    //   isValid = false;
    // }
    // if (companyTelephone.trim() === "") {
    //   setCompanyTelephoneError(true);
    //   isValid = false;
    // }
    // if (companyTelephone.trim() !== "") {
    //   if (!validateLandline(companyTelephone)) {
    //     setInvalidTelephone(true);
    //     isValid = false;
    //   }
    //   else {
    //     setInvalidTelephone(false);
    //   }
    // }
    if (companyTradeLicense.trim() === "") {
      setCompanyTradeLicenseError(true);
      isValid = false;
    }
    if (companyTradeLicense.trim() !== "") {
      if (companyTradeLicense.split('').length > 55) {
        setInvalidCompanyTradeLicense(true);
        isValid = false;
      }
      else {
        setInvalidCompanyTradeLicense(false);
      }
    }
    if (taxRegistrationNo.trim() === "") {
      setTaxRegistrationNoError(true);
      isValid = false;
    }
    if (taxRegistrationNo.trim() !== "") {
      if (!validateTaxRegistration(taxRegistrationNo)) {
        isValid = false;
      }
      if (validateTaxRegistration(taxRegistrationNo) === false) {
        setInvalidTax(true);
        isValid = false;
      }
      else {
        setInvalidTax(false);
      }
    }
    // if (companyAddress.trim() === "") {
    //   setCompanyAddressError(true);
    //   isValid = false;
    // }
    if (ownerFirstName.trim() === "") {
      setOwnerFirstNameError(true);
      isValid = false;
    }
    if (ownerFirstName.trim() !== "") {
      if (ownerFirstName.split('').length > 35) {
        setInvalidOwnerFirstName(true);
        isValid = false;
      }
      else {
        setInvalidOwnerFirstName(false);
      }
    }
    if (ownerLastName.trim() === "") {
      setOwnerLastNameError(true);
      isValid = false;
    }
    if (ownerLastName.trim() !== "") {
      if (ownerLastName.split('').length > 35) {
        setInvalidOwnerLastName(true);
        isValid = false;
      }
      else {
        setInvalidOwnerLastName(false);
      }
    }
    if (ownerEmail.trim() === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ownerEmail.trim()))) { // eslint-disable-line
      setOwnerEmailError(true);
      isValid = false;
    }
    if (ownerMobile.trim() === "") {
      setOwnerMobileError(true);
      isValid = false;
    }
    if (ownerMobile.trim() !== "") {
      if (!validateMobile(ownerMobile)) {
        isValid = false;
      }
      if (validateMobile(ownerMobile) === false) {
        setInvalidMobile(true);
        isValid = false;
      }
      else {
        setInvalidMobile(false);
      }
    }
    if (brandNames.length === 0) {
      // setBrandNamesError(true);
      isValid = false;
    }
    if (companyLanguage.trim() === "") {
      setCompanyLanguageError(true);
      isValid = false;
    }
    if (companyCurrency.trim() === "") {
      setCompanyCurrencyError(true);
      isValid = false;
    }
    if (companyBankName.trim() !== "") {
      if (companyBankName.split("").length > 100) {
        setCompanyBankNameInvalid(true);
        isValid = false;
      }
      else {
        setCompanyBankNameInvalid(false);
      }
    }
    if (companyIBAN.trim() !== "") {
      if (!validateCompanyIBAN(companyIBAN)) {
        isValid = false;
      }
      if (validateCompanyIBAN(companyIBAN) === false) {
        setCompanyIBANInvalid(true);
        isValid = false;
      }
      else {
        setCompanyIBANInvalid(false);
      }
    }
    return isValid;
  }

  function validateAdminTab() {
    let isValid: boolean = true;

    if (adminUserId.trim() === "") {
      setAdminUserIdError(true);
      isValid = false;
    }
    if (lockReason.trim() === "" && lockIndicator === true) {
      setAdminUserIdError(true);
      isValid = false;
    }
    if (adminFirstName.trim() === "") {
      setAdminFirstNameError(true);
      isValid = false;
    }
    if (adminFirstName.trim() !== "") {
      if (adminFirstName.split('').length > 35) {
        setInvalidAdminFirstName(true);
        isValid = false;
      }
      else {
        setInvalidAdminFirstName(false);
      }
    }
    if (adminLastName.trim() === "") {
      setAdminLastNameError(true);
      isValid = false;
    }
    if (adminLastName.trim() !== "") {
      if (adminLastName.split('').length > 35) {
        setInvalidAdminLastName(true);
        isValid = false;
      }
      else {
        setInvalidAdminLastName(false);
      }
    }
    if (adminMobile.trim() === "") {
      setAdminMobileError(true);
      isValid = false;
    }
    if (adminMobile.trim() !== "") {
      if (!validateMobile(adminMobile)) {
        isValid = false;
      }
      if (validateMobile(adminMobile) === false) {
        setInvalidAdminMobile(true);
        isValid = false;
      }
      else {
        setInvalidAdminMobile(false);
      }
    }
    if (adminFax.trim() !== "") {
      if (adminFax.split('').length > 9) {
        setInvalidAdminFax(true);
        isValid = false;
      }
      else {
        setInvalidAdminFax(false);
      }
    }
    if (adminPhone2.trim() !== "") {
      if (adminPhone2.split('').length > 9) {
        setInvalidAdminPhone2(true);
        isValid = false;
      }
      else {
        setInvalidAdminPhone2(false);
      }
    }
    if (adminEmail.trim() === "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(adminEmail.trim()))) { // eslint-disable-line
      setAdminEmailError(true);
      isValid = false;
    }
    if (adminUserType.trim() === "") {
      setAdminUserTypeError(true);
      isValid = false;
    }
    if (!adminValidFrom) {
      setAdminValidFromError(true);
      isValid = false;
    }
    if (!adminValidTo) {
      setAdminValidToError(true);
      isValid = false;
    }
    if (adminValidFrom && adminValidTo) {
      if (new Date(adminValidFrom) > new Date(adminValidTo)) {
        setInvalidDate(true);
        isValid = false;
      }
      else {
        setInvalidDate(false);
      }
    }
    return isValid;
  }
  const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
  const basicInfo = get(profileDetails, 'data.data.basicInfo', "");
  // const userType = get(profileDetails, 'data.data.basicInfo.userType', "");

  function getDealerTabData() {
    let selectedBrands: any[] = [];
    brandNames.forEach(element => {
      selectedBrands.push(brands.filter(item => item.make === element)[0]);
    });

    return {
      "createdByUsername": "",
      "createdByName": basicInfo.firstName + " " + basicInfo.lastName,
      "createdBywebApplicationLoginId": webApplicationLoginId,
      "createdByRole": basicInfo.userType,
      "createdByEmail": "",
      "createdByPhoneNumber": "",
      "companyDetails": {
        "title": "M/s",
        "companyName": companyName.trim(),
        "emails": [
          {
            "emailId": companyEmail.trim(),
            "emailType": "PRIMARY"
          }
        ],
        "telephones": [
          {
            "country": "-",
            "telephone": companyTelephone.trim(),
            "phoneType": "PRIMARYMOBILE"
          }
        ],
        "tradeLicenseNo": companyTradeLicense.trim(),
        "addresses": companyAddress.trim(),
        "companyCurrencyType": companyCurrency.trim(),
        "taxRegistrationNo": taxRegistrationNo.trim(),
        "companyAnnualTurnover": companyAnnualTurnover
      },
      "customerInformation": {
        "title": "",
        "customerFirstName": ownerFirstName.trim(),
        "customerLastName": ownerLastName.trim(),
        "customerMiddleName": "",
        "nationality": "",
        "partnerCategory": "",
        "gender": "",
        "emails": [
          {
            "emailId": ownerEmail.trim(),
            "emailType": "PRIMARY"
          }
        ],
        "phoneNumber": ownerMobile.trim()
      },
      "bankDetails": {
        "bankName": companyBankName.trim(),
        "bankAddress": companyBankAddress.trim(),
        "bankIBAN": companyIBAN.trim()
      },
      "organizationIds": selectedBrands.filter((brand: any) => brand),
      "sapCustomerId": companySAPCustId.trim(),
      // "channelId": "EVENT",
      "language": companyLanguage.trim(),
      "creditCurrencyType": "",
      "overallCreditLimit": "",
      "currency": companyCurrency.trim()
    };
  }

  function getAdminTabData() {
    let validFromDateString: string = adminValidFrom ? formatDate(adminValidFrom) : "";
    let validToDateString: string = adminValidTo ? formatDate(adminValidTo) : "";
    let sapCustomerId: string = createDealer && createDealer.data && createDealer.data.data && createDealer.data.data.data ? createDealer.data.data.data.sapCustomerId : "";
    let sapCustomerIdEdit: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails && dealerDetails.data.data.customerDetails.sapCustomerId) ? dealerDetails.data.data.customerDetails.sapCustomerId : null;

    return {
      "sessionDetails": {
        "webApplicationLoginId": webApplicationLoginId,
        "numberofInvalidLogons": "0",
        "clientIPAddress": ""
      },
      "title": adminTitle,
      "firstName": adminFirstName.trim(),
      "lastName": adminLastName.trim(),
      "webApplicationLoginId": adminUserId.trim(),
      "firstTelephoneNumber": adminMobile.trim(),
      "email": adminEmail.trim(),
      "userType": adminUserType.trim(),
      "mobileNumber": adminPhone2.trim(),
      "validFromDate": validFromDateString,
      "validToDate": validToDateString,
      "faxNumber": adminFax.trim(),
      "sapCustomerId": sapCustomerIdEdit ? sapCustomerIdEdit : sapCustomerId,
      "region": adminRegion,
      "countryKey": adminCountry,
      "lockIndicator": lockIndicator,
      "lockReason": lockReason,
      "salesOffice": "",
      "distributionChannel": "",
      "division": "",
      "shipToParty": "",
      "billToParty": "",
      "payerId": "",
      "salesGroup": "",
      "salesEmployee": "",
      "allowIpPattern": "",
      "creditControlArea": "",
      "street": "",
      "postalCode": "",
      "b2bAdminId": "",
      "assignKam": ""
    };
  }

  function populateDealerDetails() {
    let companyDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.companyDetails : null;
    let ownerDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.customerInformation : null;
    let companyBankDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.bankDetails : null;
    let companyBrands: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails.organizationIds : null;
    let customerInfo: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.customerDetails) ? dealerDetails.data.data.customerDetails : null;
    let adminDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.userDetails) ? dealerDetails.data.data.userDetails : null;

    //dealer tab
    companyDetails && companyDetails.companyName ? setCompanyName(companyDetails.companyName) : setCompanyName("");
    companyDetails && companyDetails.tradeLicenseNo ? setCompanyTradeLicense(companyDetails.tradeLicenseNo) : setCompanyTradeLicense("");
    companyDetails && companyDetails.taxRegistrationNo ? setTaxRegistrationNo(companyDetails.taxRegistrationNo) : setTaxRegistrationNo("");
    companyDetails && companyDetails.emails[0].emailId ? setCompanyEmail(companyDetails.emails[0].emailId) : setCompanyEmail("");

    companyDetails && companyDetails.telephones[0].telephone ? setCompanyTelephone(companyDetails.telephones[0].telephone) : setCompanyTelephone("");
    companyDetails && companyDetails.addresses ? setCompanyAddress(companyDetails.addresses) : setCompanyAddress("");
    companyDetails && companyDetails.companyAnnualTurnover ? setCompanyAnnualTurnover(companyDetails.companyAnnualTurnover) : setCompanyAnnualTurnover("");

    companyDetails && companyDetails.companyCurrencyType ? setCompanyTOCurrency(companyDetails.companyCurrencyType) : setCompanyTOCurrency("");


    ownerDetails && ownerDetails.customerFirstName ? setOwnerFirstName(ownerDetails.customerFirstName) : setOwnerFirstName("");
    ownerDetails && ownerDetails.customerLastName ? setOwnerLastName(ownerDetails.customerLastName) : setOwnerLastName("");
    ownerDetails && ownerDetails.emails[0].emailId ? setOwnerEmail(ownerDetails.emails[0].emailId) : setOwnerEmail("");
    ownerDetails && ownerDetails.phoneNumber ? setOwnerMobile(ownerDetails.phoneNumber) : setOwnerMobile("");

    companyBankDetails && companyBankDetails.bankAddress ? setCompanyBankAddress(companyBankDetails.bankAddress) : setCompanyBankAddress("");
    companyBankDetails && companyBankDetails.bankName ? setCompanyBankName(companyBankDetails.bankName) : setCompanyBankName("");
    companyBankDetails && companyBankDetails.bankIBAN ? setCompanyIBAN(companyBankDetails.bankIBAN) : setCompanyIBAN("");

    // customerInfo && customerInfo.overallCreditLimit ? setCompanyCreditLimit(customerInfo.overallCreditLimit) : setCompanyCreditLimit("");
    customerInfo && customerInfo.sapCustomerId ? setCompanySAPCustId(customerInfo.sapCustomerId) : setCompanySAPCustId("");
    customerInfo && customerInfo.language ? setCompanyLanguage(customerInfo.language) : setCompanyLanguage("");
    customerInfo && customerInfo.currency ? setCompanyCurrency(customerInfo.currency) : setCompanyCurrency("");
    // customerInfo && customerInfo.creditCurrencyType ? setBrandCurrency(customerInfo.creditCurrencyType) : setBrandCurrency("");

    if (companyBrands && companyBrands.length > 0) {
      let fetchedBrands: string[] = [];
      companyBrands.forEach((item: any) => {
        let fetchedBrand = brands.filter(x => x.organizationId === item.organizationId)[0];
        if (fetchedBrand && fetchedBrand.make !== "")
          fetchedBrands.push(fetchedBrand.make);
      });
      setBrandNames(fetchedBrands);
    }

    //admin tab
    adminDetails && adminDetails.title ? setAdminTitle(adminDetails.title) : setAdminTitle("");
    adminDetails && adminDetails.firstName ? setAdminFirstName(adminDetails.firstName) : setAdminFirstName("");
    adminDetails && adminDetails.lastName ? setAdminLastName(adminDetails.lastName) : setAdminLastName("");
    adminDetails && adminDetails.firstTelephoneNumber ? setAdminMobile(adminDetails.firstTelephoneNumber) : setAdminMobile("");
    adminDetails && adminDetails.mobileNumber ? setAdminPhone2(adminDetails.mobileNumber) : setAdminPhone2("");
    adminDetails && adminDetails.email ? setAdminEmail(adminDetails.email) : setAdminEmail("");
    adminDetails && adminDetails.userType ? setAdminUserType(adminDetails.userType) : setAdminUserType("Admin");
    adminDetails && adminDetails.webApplicationLoginId ? setAdminUserId(adminDetails.webApplicationLoginId) : setAdminUserId("");
    adminDetails && adminDetails.region ? setAdminRegion(adminDetails.region) : setAdminRegion("");
    adminDetails && adminDetails.validFromDate ? setAdminValidFrom(parseDateFromString(adminDetails.validFromDate)) : setAdminValidFrom(null);
    adminDetails && adminDetails.validToDate ? setAdminValidTo(parseDateFromString(adminDetails.validToDate)) : setAdminValidTo(null);
    adminDetails && adminDetails.faxNumber ? setAdminFax(adminDetails.faxNumber) : setAdminFax("");
    adminDetails && adminDetails.countryKey ? setAdminCountry(adminDetails.countryKey) : setAdminCountry("");
    adminDetails && adminDetails.lockIndicator ? setLockIndicator(adminDetails.lockIndicator) : setLockIndicator(false);
    adminDetails && adminDetails.lockReason ? setLockReason(adminDetails.lockReason) : setLockReason("");
  }
  const showMessage = (data: any, customerUpdated?: any) => {
    const apiResponse = get(data, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
  }
  const handleNextClick = async () => {
    let currentTab = tabValue;
    if (currentTab === 0) {
      if (validateDealerTab()) {
        if (!props.isEdit) {
          let dealerData = getDealerTabData();
          dispatch(addDealer(dealerData));
          setTabValue(1);
        } else {
          let dealerData = getDealerTabData();
          let result = await dispatch(updateDealerAction(companySAPCustId, dealerData));
          showMessage(result);
          setTabValue(1);
        }
      }
    }
    else if (currentTab === 2) {
      if (validateAdminTab()) {
        if (!props.isEdit || (props.isEdit && isWebLoginIdDisabled === false)) {
          let adminData = getAdminTabData();
          let addedUser = await dispatch(addDealerUser(adminData));
          emailLinkOnDealerCreate(addedUser);
          closeAddDealerDialog();
        } else {
          let payload = getAdminTabDataForUpdate();
          if (adminDetails.status === "In Progress") {
            await dispatch(updateDealerInProgressUser(payload))
          }
          else {
            await dispatch(updateDealerUserAction(payload));
          }
          closeAddDealerDialog();
        }
      }
    }
    else if (currentTab === 1 && props.hideAdmin) {
      closeAddDealerDialog();
    }
    else {
      currentTab < 2 ? setTabValue(currentTab + 1) : closeAddDealerDialog();
    }
  }

  const disableNext = (createDealer && createDealer.loading) || (updateDealer && updateDealer.loading);
  const nextLoading = ((createDealer && createDealer.loading) || (updateDealer && updateDealer.loading));
  const disableSubmit = (createDealerUser && createDealerUser.loading) || (updateDealerUser && updateDealerUser.loading);
  const submitLoading = (createDealerUser && createDealerUser.loading) || (updateDealerUser && updateDealerUser.loading);


  const onClickBrowseFile = (type: any, item: any, setCallback: any) => {
    const name = item && item.name ? item.name : '',
      doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
      doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
      canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
    if (!canUploadFile) {
      return;
    }
    let sapCustomerId;
    if (props.isEdit) sapCustomerId = get(dealerDetails, 'data.data.customerDetails.sapCustomerId', false);
    const organization = get(dealerDetails, 'data.data.customerDetails.organizationIds[0]', {}),
      canNotUploadWithoutDate = type === 'passportFile' || type === 'visaCopyFile' || type === 'emiratesIdFile' ||
        type === 'tradeLicenseFile' || type === 'membershipFile' || type === 'vatTRNFile';

    if (!props.isEdit) sapCustomerId = createDealer && createDealer.data && createDealer.data.data && createDealer.data.data.data ? createDealer.data.data.data.sapCustomerId : "";

    const formData: any = new FormData();

    let keyAttribute = `fieldname=${type}&docExtension=${doctExtension}`;
    let expiryDate: any = '';

    if (!sapCustomerId) { return; }

    if (setCallback) setCallback(item);

    if (canNotUploadWithoutDate) {
      if (type === 'passportFile') expiryDate = moment(passportExpiryDate).utc().format();
      else if (type === 'visaCopyFile') expiryDate = moment(visaExpiryDate).utc().format();
      else if (type === 'emiratesIdFile') expiryDate = moment(emiratesIdExpiryDate).utc().format();
      else if (type === 'tradeLicenseFile') expiryDate = moment(tradeLicenseExpiryDate).utc().format();
      else if (type === 'membershipFile') expiryDate = moment(membershipExpiryDate).utc().format();
      else if (type === 'vatTRNFile') expiryDate = moment(vatExpiryDate).utc().format();

      keyAttribute = `${keyAttribute}&expiryDate=${expiryDate}`;
    }

    const customHeaders = {
      "Accept": "application/json",
      "Lob-Id": "AUTO",
      "Channel-Id": "B2B",
      "Org-Id": organization.organizationId || "",
      "Accept-Language": "EN",
    };

    let docType = '';

    if (type === 'poaFile') { docType = 'Power Of Attorney' }
    else if (type === 'bankStatementFile') { docType = 'Bank Statements' }
    else if (type === 'bankGuaranteeFile') { docType = 'Bank Guarantee' }
    else if (type === 'vatReturnFile') { docType = 'Vat Return' }
    else if (type === 'visitReportFile') { docType = 'Visit Report' }
    else if (type === 'passportFile') { docType = 'Valid Passport Copy' }
    else if (type === 'visaCopyFile') { docType = 'Valid Visa Copy' }
    else if (type === 'emiratesIdFile') { docType = 'Valid Emirates ID Copy' }
    else if (type === 'tradeLicenseFile') { docType = 'Valid Trade License' }
    else if (type === 'membershipFile') { docType = 'Membership Certificate' }
    else if (type === 'vatTRNFile') { docType = 'VAT TRN Certificate' }

    formData.append("entityName", "CUSTOMER");
    formData.append("keyAttribute", keyAttribute);
    formData.append("fileUpload", item);
    formData.append("document", 'true');
    formData.append("Attributes", `key=Brand&value=${item}`);
    formData.append("sapCustomerId", sapCustomerId);
    formData.append("docType", docType);
    formData.append("uploadedByRole", props.hideAdmin ? 'dealerUser' : 'kam');
    formData.append("uploadedBywebApplicationLoginId", webApplicationLoginId);

    dispatch(uploadDocument(formData, customHeaders));
  }

  function getAdminTabDataForUpdate() {
    let validFromDateString: string = adminValidFrom ? formatDate(adminValidFrom) : "";
    let validToDateString: string = adminValidTo ? formatDate(adminValidTo) : "";
    let userDetails = dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.userDetails ? dealerDetails.data.data.userDetails : null;

    return {
      "sessionDetails": {
        "webApplicationLoginId": webApplicationLoginId,
        "clientIPAddress": ""
      },
      b2bUserId: userDetails.b2bUserId,
      "title": adminTitle,
      "firstName": adminFirstName.trim(),
      "lastName": adminLastName.trim(),
      "firstTelephoneNumber": adminMobile.trim(),
      "email": adminEmail.trim(),
      "mobileNumber": adminPhone2.trim(),
      "validFromDate": validFromDateString,
      "validToDate": validToDateString,
      "faxNumber": adminFax.trim(),
      "region": adminRegion,
      "countryKey": adminCountry,
      "lockIndicator": lockIndicator,
      "lockReason": lockReason,
      "postalCode": userDetails.postalCode,
      "street": userDetails.street,
    }
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const handleEmailChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e.target.value))) // eslint-disable-line
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const handleDropdownChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const closeAddDealerDialog = async (showSnackbar?: boolean) => {
    await dispatch(getAllDealers("", "All"));
    setTabValue(0);
    resetValues();
    showSnackbar ? props.handleopenClose(true) : props.handleopenClose(false);
  }

  const resetValues = () => {
    //dealer tab
    setCompanyName("");
    setIsWebLoginIdDisabled(false);
    setCompanyNameError(false);
    setCompanyEmail("");
    setCompanyEmailError(false);
    setCompanyTelephone("");
    setCompanyTelephoneError(false);
    setInvalidAdminFax(false);
    setInvalidAnnualTurnover(false);
    setInvalidAdminPhone2(false);
    setInvalidAdminMobile(false);
    // setInvalidTelephone(false);
    setInvalidMobile(false);
    setInvalidDate(false);
    setInvalidTax(false);
    setCompanyIBANInvalid(false);
    // setInvalidCompanyName(false);
    setInvalidOwnerFirstName(false);
    setInvalidOwnerLastName(false);
    setCompanyBankNameInvalid(false);
    setCompanyTradeLicense("");
    setCompanyTradeLicenseError(false);
    setTaxRegistrationNoError(false);
    setTaxRegistrationNo("");
    setCompanyAddress("");
    setCompanyAddressError(false);
    setCompanyTOCurrency(currencies[0]);
    setCompanyAnnualTurnover("");
    setOwnerFirstName("");
    setOwnerFirstNameError(false);
    setOwnerLastName("");
    setOwnerLastNameError(false);
    setOwnerEmail("");
    setOwnerEmailError(false);
    setOwnerMobile("");
    setOwnerMobileError(false);
    setCompanyBankName("");
    setCompanyIBAN("");
    setCompanyBankAddress("");
    setBrandNames([]);
    // setBrandNamesError(false);
    // setBrandCurrency(currencies[0]);
    // setCompanyCreditLimit("");
    setCompanySAPCustId("");
    setCompanyLanguage("");
    setCompanyLanguageError(false);
    setCompanyCurrency("");
    setCompanyCurrencyError(false);

    setCompanySAPCustIdError(false);
    setSAPCustIdErrorText("");
    setIsSAPCustIdValid(false);
    setSubmitDisabled(false);

    //admin tab
    setAdminUserId("");
    setAdminUserIdError(false);
    setUserIdErrorText("");
    setAdminTitle("");
    setAdminFirstName("");
    setAdminFirstNameError(false);
    setAdminLastName("");
    setAdminLastNameError(false);
    setAdminMobile("");
    setAdminMobileError(false);
    setAdminEmail("");
    setAdminEmailError(false);
    setAdminUserType("Admin");
    setAdminUserTypeError(false);
    setAdminPhone2("");
    setAdminValidFrom(today);
    setAdminValidFromError(false);
    setAdminValidTo(new Date('9999-12-31'));
    setAdminValidToError(false);
    setInvalidAdminFirstName(false);
    setInvalidAdminLastName(false);
    setAdminFax("");
    setAdminCountry("");
    setAdminRegion("");
    setLockIndicator(false);
    setLockReason("");

    setPassportExpiryDate(null);
    setVisaExpiryDate(null);
    setEmirateIdExpiryDate(null);
    setTradeLicenseExpiryDate(null);
    setMembershipExpiryDate(null);
    setVatExpiryDate(null);
    setIsValidPassportExpiryDate(true);
    setIsValidVisaExpiryDate(true);
    setIsValidEmirateIdExpiryDate(true);
    setIsValidTradeLicenseExpiryDate(true);
    setIsValidMembershipExpiryDate(true);
    setIsValidVatExpiryDate(true);
    setPOAFile(null);
    setBankStatementFile(null);
    setBankGuaranteeFile(null);
    setVatReturnFile(null);
    setVisitReportFile(null);
    setPassportFile(null);
    setVisaCopyFile(null);
    setEmiratesIdFile(null);
    setTradeLicenseFile(null);
    setMembershipFile(null);
    setVatTRNFile(null);
  }

  const copyOwnerDetails = (e: any) => {
    if (e.target.checked) {
      setAdminFirstName(ownerFirstName);
      setAdminLastName(ownerLastName);
      setAdminEmail(ownerEmail);
      setAdminMobile(ownerMobile);
    } else {
      setAdminFirstName("");
      setAdminLastName("");
      setAdminEmail("");
      setAdminMobile("");
    }
  }

  const checkUserAvailability = async (e: any) => {
    if (validateAdminUserID(adminUserId) === false) {
      setSubmitDisabled(true);
      setIsUserIdValid(false);
      setAdminUserIdError(true);
      setUserIdErrorText("Cannot exceed 15 characters and must not contain spaces.");
    }
    else {
      if (adminUserId === "" || adminUserId.trim() === "") {
        setAdminUserIdError(true);
      }
      else {
        let postData = {
          "sapCustomerId": companySAPCustId.trim(),
          "webApplicationLoginId": adminUserId.trim()
        };
        let isUserAvailable: any = await dispatch(checkUserIdAvailability(postData));
        if (isUserAvailable && isUserAvailable.data === false) {
          setSubmitDisabled(false);
          setIsUserIdValid(true);
          setAdminUserIdError(false);
          setUserIdErrorText("");
        } else {
          setSubmitDisabled(true);
          setIsUserIdValid(false);
          setAdminUserIdError(true);
          setUserIdErrorText("Web Login Id already exists");
        }
      }
    }
  }

  const verifySAPCustId = async (e: any) => {
    if (companySAPCustId === "" || companySAPCustId.trim() === "") {
      setCompanySAPCustIdError(true);
    }
    else {
      let verifiedSAPCustomer: any = await dispatch(verifySAPCustomerId(companySAPCustId.trim()));
      if (verifiedSAPCustomer && verifiedSAPCustomer.message && verifiedSAPCustomer.message.status === 'S'
        && verifiedSAPCustomer.data && Object.keys(verifiedSAPCustomer.data).length > 0) {

        if (verifiedSAPCustomer.data.existInB2b) {
          setSubmitDisabled(true);
          setIsSAPCustIdValid(false);
          setCompanySAPCustIdError(true);
          setSAPCustIdErrorText("Dealer already added");
        } else {
          setSubmitDisabled(false);
          setIsSAPCustIdValid(true);
          setCompanySAPCustIdError(false);
          setSAPCustIdErrorText("");
          populateDealerFromSAP(verifiedSAPCustomer.data);
        }
      } else {
        setSubmitDisabled(true);
        setIsSAPCustIdValid(false);
        setCompanySAPCustIdError(true);
        setSAPCustIdErrorText("SAP customer id does not exists");
      }
    }
  }

  function populateDealerFromSAP(data: any) {
    let customerInformation: any = (data && data.customerInformation) ? data.customerInformation : null;
    //dealer tab
    customerInformation.customerFirstName ? setCompanyName(customerInformation.customerFirstName + " " + customerInformation.customerLastName) : setCompanyName("");
    // customerInformation.customerFirstName !== "" ? setCompanyNameDisabled(true) : setCompanyNameDisabled(false);
    customerInformation.emails[0] && customerInformation.emails[0].emailId ? setCompanyEmail(customerInformation.emails[0].emailId) : setCompanyEmail("");
    // customerInformation.emails[0] && customerInformation.emails[0].emailId !== "" ? setCompanyEmailDisabled(true) : setCompanyEmailDisabled(false);
    customerInformation.telephones[0] && customerInformation.telephones[0].telephone ? setCompanyTelephone(customerInformation.telephones[0].telephone) : setCompanyTelephone("");
    // customerInformation.telephones[0] && customerInformation.telephones[0].telephone !== "" ? setCompanyTelephoneDisabled(true) : setCompanyTelephoneDisabled(false);
    customerInformation.addresses[0] ? setCompanyAddress(getAddressAsString(customerInformation.addresses[0])) : setCompanyAddress("");
    // customerInformation.addresses[0] !== "" ? setCompanyAddressDisabled(true) : setCompanyAddressDisabled(false);

    if (customerInformation.organizationIds && customerInformation.organizationIds.length > 0) {
      let fetchedBrands: string[] = [];
      customerInformation.organizationIds.forEach((item: any) => {
        let fetchedBrand = brands.filter(x => x.organizationId === item.organizationId)[0];
        if (fetchedBrand && fetchedBrand.make !== "")
          fetchedBrands.push(fetchedBrand.make);
      });
      setBrandNames(fetchedBrands);
    }
  }

  function getAddressAsString(data: any) {
    return Object.values(data).join(", ");
  }

  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      state(false);
      setSubmitDisabled(true);
    } else {
      state(true);
    }
  }

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  return <Dialog
    open={props.open ? true : false}
    onClose={(event, reason) => {
      if (reason !== 'backdropClick') {
        closeAddDealerDialog()
      }
    }}
    fullWidth={true}
    maxWidth={'md'}
    disableEscapeKeyDown>
    <DialogTitle className="pb-0" id="form-dialog-title">
      <div className="page-title">{props.isEdit === true ? "Edit Dealer" : "Add New Dealer"}
        <IconButton aria-label="close" className="float-right" onClick={() => closeAddDealerDialog()}>
          <CloseIcon />
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent className="p-0">
      <div className="p-0">
        <Paper square>
          <Tabs className="pl-2" value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="Dealer Tab">
            <Tab disabled={!props.isEdit} label="Customer Details" {...allTabProps(0)} />
            <Tab disabled={!props.isEdit} label="Upload Documents" {...allTabProps(1)} />
            {props.hideAdmin ? '' : <Tab disabled={!props.isEdit} label="Admin Details" {...allTabProps(2)} />}
          </Tabs>
        </Paper>
        <TabPanel value={tabValue} index={0}>
          <div className="pl-4 pt-3">
            <h6 className="dealer-table-content pb-2">Company Details</h6>
            <Grid container>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField id="companySAPCustId" required value={companySAPCustId}
                  error={companySAPCustIdError} helperText={sapCustIdErrorText}
                  disabled={isSAPCustIdDisabled}
                  onChange={(e: any) => {
                    setIsSAPCustIdValid(false);
                    setSubmitDisabled(true);
                    setSAPCustIdErrorText("");
                    handleInputChange(e, setCompanySAPCustId, setCompanySAPCustIdError)
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{isSAPCustIdValid && <CheckCircleIcon className="valid-user-id" />}</InputAdornment>,
                  }}
                  label="SAP Customer ID" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <Button variant="contained" disabled={(isSAPCustIdDisabled) || (verifiedSAPCustomer && verifiedSAPCustomer.loading)} color="secondary" className="rounded-button-dealer mt-2 py-2 px-4"
                  onClick={(e: any) => { verifySAPCustId(e) }}>{verifiedSAPCustomer && verifiedSAPCustomer.loading && <Loading />}Verify SAP Customer Id</Button>
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4  pb-2">
                {/* <TextField id="companyType" value="Ms" select variant="outlined"
                                    className="rounded-input-dealer" size="small">
                                    <MenuItem value="Ms">M/s</MenuItem>
                                </TextField> */}
                <TextField required id="companyName" error={companyNameError}
                  value={companyName} onChange={(e: any) => handleInputChange(e, setCompanyName, setCompanyNameError)} disabled label="Company Name" className="rounded-input-dealer w-100"
                  // helperText={invalidCompanyName && <span style={{ color: 'red' }}>{ValidationErrorMessage.COMPANY_NAME_TEXT}</span>}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                    endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                  }} margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="companyEmail" error={companyEmailError}
                  // helperText={companyEmail.trim() !== "" && companyEmailError && "Invalid email"}
                  value={companyEmail} onChange={(e: any) => handleEmailChange(e, setCompanyEmail, setCompanyEmailError)} disabled label="Email ID" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="companyTelephone" placeholder={Messages.LANDLINE_PLACEHOLDER_TEXT} error={companyTelephoneError}
                  value={companyTelephone} onChange={(e: any) => handleInputChange(e, setCompanyTelephone, setCompanyTelephoneError)} disabled
                  // helperText={invalidTelephone && <span style={{ color: 'red' }}>{ValidationErrorMessage.LANDLINE_TEXT}</span>} 
                  label={sessionStorage.getItem('customerOnboardingType') === 'workshop' ? "Contact Telephone Number" : "Telephone Office"} className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="companyTradeLicense" error={companyTradeLicenseError || invalidCompanyTradeLicense} helperText={invalidCompanyTradeLicense && <span style={{ color: 'red' }}>{ValidationErrorMessage.TRADE_LICENSE_TEXT}</span>}
                  value={companyTradeLicense} onChange={(e: any) => handleInputChange(e, setCompanyTradeLicense, setCompanyTradeLicenseError)} label="Trade License No" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="taxRegistrationNo" placeholder={ValidationErrorMessage.TAX_REGISTRATION_TEXT} error={taxRegistrationNoError || invalidTax} helperText={invalidTax && <span style={{ color: 'red' }}>{ValidationErrorMessage.TAX_REGISTRATION_TEXT}</span>}
                  value={taxRegistrationNo} onChange={(e: any) => handleInputChange(e, setTaxRegistrationNo, setTaxRegistrationNoError)} label="Tax Registration No" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required defaultValue={currencies[0]} value={companyTOCurrency} onChange={(e: any) => handleInputChange(e, setCompanyTOCurrency)} select id="companyTOCurrency" className="rounded-input-dealer w-25" margin="dense" variant="outlined">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
                <TextField type="number" value={companyAnnualTurnover} error={invalidAnnualTurnover} onChange={(e: any) => handleInputChange(e, setCompanyAnnualTurnover)} id="companyAnnualTurnover" label="Annual Turnover" className="rounded-input-dealer w-75" margin="dense" variant="outlined" helperText={invalidAnnualTurnover && <span style={{ color: 'red' }}>{ValidationErrorMessage.ANNUAL_TURNOVER_TEXT}</span>} />
              </Grid>
              <Grid item xs={12} sm={12} className="pr-4 pb-2">
                <TextField required id="companyAddress" error={companyAddressError}
                  value={companyAddress} onChange={(e: any) => handleInputChange(e, setCompanyAddress, setCompanyAddressError)} disabled label="Address" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
            </Grid>
            <h6 className="dealer-table-content pt-3 pb-2">Owner/Partner Details</h6>
            <Grid container>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="ownerFirstName" error={ownerFirstNameError || invalidOwnerFirstName} helperText={invalidOwnerFirstName && <span style={{ color: 'red' }}>{ValidationErrorMessage.OWNER_FIRST_NAME_TEXT}</span>}
                  value={ownerFirstName} onChange={(e: any) => handleInputChange(e, setOwnerFirstName, setOwnerFirstNameError)} label="First Name" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="ownerLastName" error={ownerLastNameError || invalidOwnerLastName} helperText={invalidOwnerLastName && <span style={{ color: 'red' }}>{ValidationErrorMessage.OWNER_LAST_NAME_TEXT}</span>}
                  value={ownerLastName} onChange={(e: any) => handleInputChange(e, setOwnerLastName, setOwnerLastNameError)} label="Last Name" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="ownerEmail" error={ownerEmailError} helperText={ownerEmail.trim() !== "" && ownerEmailError && ValidationErrorMessage.EMAIL_TEXT}
                  value={ownerEmail} onChange={(e: any) => handleEmailChange(e, setOwnerEmail, setOwnerEmailError)} label="Email ID" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required id="ownerMobile" placeholder={Messages.MOBILE_PLACEHOLDER_TEXT} error={ownerMobileError || invalidMobile} helperText={invalidMobile && <span style={{ color: 'red' }}>{ValidationErrorMessage.MOBILE_TEXT}</span>}
                  value={ownerMobile} onChange={(e: any) => handleInputChange(e, setOwnerMobile, setOwnerMobileError)} label="Mobile No." className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
            </Grid>
            <h6 className="dealer-table-content pt-3 pb-2">Bank Details</h6>
            <Grid container>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField id="companyBankName" value={companyBankName} onChange={(e: any) => handleInputChange(e, setCompanyBankName)}
                  helperText={companyBankNameInvalid && <span style={{ color: 'red' }}>{ValidationErrorMessage.COMPANY_BANK_NAME_TEXT}</span>}
                  label="Bank Name" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField id="companyIBAN" value={companyIBAN} onChange={(e: any) => handleInputChange(e, setCompanyIBAN)}
                  label="IBAN" className="rounded-input-dealer w-100" margin="dense" variant="outlined" helperText={companyIBANInvalid && <span style={{ color: 'red' }}>{ValidationErrorMessage.COMPANY_IBAN_TEXT}</span>} />
              </Grid>
              <Grid item xs={12} className="pr-4 pb-2">
                <TextField id="companyBankAddress" value={companyBankAddress} onChange={(e: any) => handleInputChange(e, setCompanyBankAddress)}
                  label="Address" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
              </Grid>
            </Grid>
            <h6 className="dealer-table-content pt-3 pb-4">Brand &amp; Others</h6>
            <Grid container>
              <Grid item xs={12} className="pr-4 pb-2">
                <FormControl required className="rounded-input-dealer w-100" variant="outlined">
                  {/* <InputLabel id="companyBrandsLabel">Brands</InputLabel> */}
                  <Grid container spacing={1}>
                    {(brandNames as string[]).map((value) => (
                      <Grid item key={getNewBrand(value)}>
                        <Chip key={getNewBrand(value)} label={getNewBrand(value)} style={{ width: 'fit-content', marginTop: '-10px' }} />
                      </Grid>
                    ))}
                  </Grid>
                  {/* <Select
                    disabled
                    labelId="companyBrandsLabel"
                    id="companyBrandsSelect"
                    error={brandNamesError}
                    multiple
                    variant="outlined"
                    value={brandNames}
                    onChange={handleBrandChange}
                    input={<Input id="companyBrandsInput" />}
                    renderValue={(selected) => (
                      <div>
                        {(selected as string[]).map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </div>
                    )}
                    MenuProps={BrandMenuProps}
                  >
                    {brands.map((item) => (
                      <MenuItem key={item.organizationId} value={item.make}>
                        {item.make}
                      </MenuItem>
                    ))}
                  </Select> */}
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required defaultValue={currencies[0]} value={brandCurrency} onChange={(e: any) => handleInputChange(e, setBrandCurrency)}
                  select id="brandCurrency" className="rounded-input-dealer w-25" margin="dense" variant="outlined">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
                <TextField id="companyCreditLimit" type="number" value={companyCreditLimit} onChange={(e: any) => handleInputChange(e, setCompanyCreditLimit)}
                  label="Credit Limit (Overall)" className="rounded-input-dealer w-75" margin="dense" variant="outlined" />
              </Grid> */}
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required select id="companyLanguage" error={companyLanguageError}
                  value={companyLanguage} onChange={(e: any) => handleDropdownChange(e, setCompanyLanguage, setCompanyLanguageError)} label="Language" className="rounded-input-dealer w-100" margin="dense" variant="outlined">
                  {languages.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} className="pr-4 pb-2">
                <TextField required select id="companyCurrency" error={companyCurrencyError}
                  value={companyCurrency} onChange={(e: any) => handleDropdownChange(e, setCompanyCurrency, setCompanyCurrencyError)} label="Currency" className="rounded-input-dealer w-100" margin="dense" variant="outlined">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>
              </Grid>
            </Grid>
          </div>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Grid container className="pl-4 pr-2">
            <Grid item xs={12} className="mt-2 mb-4">
              <span className="info-text">Supported File Extensions: .jpg, .jpeg, .png, .pdf, .doc, .docx etc.</span>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              <h6 className="dealer-table-content">Power Of Attorney</h6>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              {poaFile !== null ?
                <span className="file-name">
                  {poaFile.name}
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setPOAFile(null); dispatch(deleteDocument(poaFile.id, poaFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained" component="label" color="secondary" className="rounded-button-dealer pl-4 pr-4">
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('poaFile', e.target.files[0], setPOAFile) : setPOAFile(null)
                  } />
                </Button>
              }
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              <h6 className="dealer-table-content">Bank Statements</h6>
              <span className="info-text">Latest 3 Months (PDF)</span>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              {bankStatementFile !== null ?
                <span className="file-name">
                  {bankStatementFile.name}
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setBankStatementFile(null); dispatch(deleteDocument(bankStatementFile.id, bankStatementFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained" component="label" color="secondary" className="rounded-button-dealer pl-4 pr-4">
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('bankStatementFile', e.target.files[0], setBankStatementFile) : setBankStatementFile(null)
                  } />
                </Button>}
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              <h6 className="dealer-table-content">Bank Guarantee</h6>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              {bankGuaranteeFile !== null ?
                <span className="file-name">
                  {bankGuaranteeFile.name}
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setBankGuaranteeFile(null); dispatch(deleteDocument(bankGuaranteeFile.id, bankGuaranteeFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained" component="label" color="secondary" className="rounded-button-dealer pl-4 pr-4">
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('bankGuaranteeFile', e.target.files[0], setBankGuaranteeFile) : setBankGuaranteeFile(null)
                  } />
                </Button>
              }
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              <h6 className="dealer-table-content">Vat Return</h6>
              <span className="info-text">Quarterly</span>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-3">
              {vatReturnFile !== null ?
                <span className="file-name">
                  {vatReturnFile.name}
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setVatReturnFile(null); dispatch(deleteDocument(vatReturnFile.id, vatReturnFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained" component="label" color="secondary" className="rounded-button-dealer pl-4 pr-4">
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('vatReturnFile', e.target.files[0], setVatReturnFile) : setVatReturnFile(null)
                  } />
                </Button>
              }
            </Grid>

            <Grid item xs={6} sm={3} className="pb-3">
              <h6 className="dealer-table-content">Visit Report</h6>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-3">
              {visitReportFile !== null ?
                <span className="file-name">
                  {visitReportFile.name}
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setVisitReportFile(null); dispatch(deleteDocument(visitReportFile.id, visitReportFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </span>
                : <Button variant="contained" component="label" color="secondary" className="rounded-button-dealer pl-4 pr-4">
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('visitReportFile', e.target.files[0], setVisitReportFile) : setVisitReportFile(null)
                  } />
                </Button>
              }
            </Grid>
            <Grid item xs={12} className="mt-2 mb-4">
              <span className="info-sub-header">Expiry dates are mandatory to upload below documents.</span>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-1  mt-2">
              <h6 className="dealer-table-content">Valid Passport Copy</h6>
              <span className="info-text">Owner / Sponsor Manager</span>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-1">
              {passportFile !== null ?
                <div className="file-name">
                  <span>{passportFile.name}</span>
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setPassportFile(null); setPassportExpiryDate(null); dispatch(deleteDocument(passportFile.id, passportFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={passportExpiryDate && isValidPassportExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={!passportExpiryDate || isValidPassportExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('passportFile', e.target.files[0], setPassportFile) : setPassportFile(null)
                  } />
                </Button>
              }
              <Tooltip title={props.isEdit && passportExpiryDate && passportFile ? 'Kindly upload new document and later update Expiry date' : ''} placement="top">
                <span>
                  <KeyboardDatePicker disablePast
                    views={["year", "month", "date"]}
                    openTo="year"
                    onError={(e, v) => datePickerValid(e, setIsValidPassportExpiryDate)}
                    disabled={props.isEdit && passportExpiryDate && passportFile ? true : false} autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={passportExpiryDate}
                    onChange={(date: Date | null) => { setPassportExpiryDate(date); !date && setPassportFile(null) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer width-160 mt-2 cal-icon"
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-1  mt-2">
              <h6 className="dealer-table-content">Valid Visa Copy</h6>
              <span className="info-text">Owner / Manager</span>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-1">
              {visaCopyFile !== null ?
                <div className="file-name">
                  <span>{visaCopyFile.name}</span>
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setVisaCopyFile(null); setVisaExpiryDate(null); dispatch(deleteDocument(visaCopyFile.id, visaCopyFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={visaExpiryDate && isValidVisaExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={!visaExpiryDate || isValidVisaExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('visaCopyFile', e.target.files[0], setVisaCopyFile) : setVisaCopyFile(null)
                  } />
                </Button>
              }
              <Tooltip title={props.isEdit && visaExpiryDate && visaCopyFile ? 'Kindly upload new document and later update Expiry date' : ''} placement="top">
                <span>
                  <KeyboardDatePicker disablePast
                    views={["year", "month", "date"]}
                    openTo="year"
                    onError={(e, v) => datePickerValid(e, setIsValidVisaExpiryDate)}
                    autoOk size="small" disabled={props.isEdit && visaExpiryDate && visaCopyFile ? true : false} disableToolbar variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={visaExpiryDate}
                    onChange={(date: Date | null) => { setVisaExpiryDate(date); !date && setVisaCopyFile(null) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer width-160 mt-2 cal-icon"
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-1  mt-2">
              <h6 className="dealer-table-content">Valid Emirates ID Copy</h6>
              <span className="info-text">Owner / Sponsor Manager</span>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-1">
              {emiratesIdFile !== null ?
                <div className="file-name">
                  <span>{emiratesIdFile.name}</span>
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setEmiratesIdFile(null); setEmirateIdExpiryDate(null); dispatch(deleteDocument(emiratesIdFile.id, emiratesIdFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={emiratesIdExpiryDate && isValidEmiratesIdExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={!emiratesIdExpiryDate || isValidEmiratesIdExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('emiratesIdFile', e.target.files[0], setEmiratesIdFile) : setEmiratesIdFile(null)
                  } />
                </Button>
              }
              <Tooltip title={props.isEdit && emiratesIdExpiryDate && emiratesIdFile ? 'Kindly upload new document and later update Expiry date' : ''} placement="top">
                <span>
                  <KeyboardDatePicker disablePast
                    views={["year", "month", "date"]}
                    openTo="year"
                    onError={(e, v) => datePickerValid(e, setIsValidEmirateIdExpiryDate)}
                    autoOk size="small" disabled={props.isEdit && emiratesIdExpiryDate && emiratesIdFile ? true : false} disableToolbar variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={emiratesIdExpiryDate}
                    onChange={(date: Date | null) => { setEmirateIdExpiryDate(date); !date && setEmiratesIdFile(null) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer width-160 mt-2 cal-icon"
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-1  mt-2">
              <h6 className="dealer-table-content">Valid Trade License</h6>
              <span className="info-text">Partner / Shareholder Page</span>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-1">
              {tradeLicenseFile !== null ?
                <div className="file-name">
                  <span>{tradeLicenseFile.name}</span>
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setTradeLicenseFile(null); setTradeLicenseExpiryDate(null); dispatch(deleteDocument(tradeLicenseFile.id, tradeLicenseFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={tradeLicenseExpiryDate && isValidTradeLicenseExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={!tradeLicenseExpiryDate || isValidTradeLicenseExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('tradeLicenseFile', e.target.files[0], setTradeLicenseFile) : setTradeLicenseFile(null)
                  } />
                </Button>
              }
              <Tooltip title={props.isEdit && tradeLicenseExpiryDate && tradeLicenseFile ? 'Kindly upload new document and later update Expiry date' : ''} placement="top">
                <span>
                  <KeyboardDatePicker disablePast autoOk
                    views={["year", "month", "date"]}
                    openTo="year"
                    onError={(e, v) => datePickerValid(e, setIsValidTradeLicenseExpiryDate)}
                    size="small" disabled={props.isEdit && tradeLicenseExpiryDate && tradeLicenseFile ? true : false} disableToolbar variant="inline" inputVariant="outlined"
                    format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={tradeLicenseExpiryDate}
                    onChange={(date: Date | null) => { setTradeLicenseExpiryDate(date); !date && setTradeLicenseFile(null) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer width-160 mt-2 cal-icon"
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-1  mt-2">
              <h6 className="dealer-table-content">Membership Certificate</h6>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-1">
              {membershipFile !== null ?
                <div className="file-name">
                  <span>{membershipFile.name}</span>
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setMembershipFile(null); setMembershipExpiryDate(null); dispatch(deleteDocument(membershipFile.id, membershipFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={membershipExpiryDate && isValidMembershipExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={!membershipExpiryDate || isValidMembershipExpiryDate === false}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('membershipFile', e.target.files[0], setMembershipFile) : setMembershipFile(null)
                  } />
                </Button>
              }
              <Tooltip title={props.isEdit && membershipExpiryDate && membershipFile ? 'Kindly upload new document and later update Expiry date' : ''} placement="top">
                <span>
                  <KeyboardDatePicker disablePast autoOk size="small" disabled={props.isEdit && membershipExpiryDate && membershipFile ? true : false} disableToolbar variant="inline" inputVariant="outlined"
                    views={["year", "month", "date"]}
                    openTo="year"
                    onError={(e, v) => datePickerValid(e, setIsValidMembershipExpiryDate)}
                    format="dd/MM/yyyy" margin="normal" label="Expiry Date" value={membershipExpiryDate}
                    onChange={(date: Date | null) => { setMembershipExpiryDate(date); !date && setMembershipFile(null) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer width-160 mt-2 cal-icon"
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={6} sm={3} className="pb-1 mt-2">
              <h6 className="dealer-table-content">VAT TRN Certificate</h6>
            </Grid>
            <Grid item xs={6} sm={9} className="pb-1">
              {vatTRNFile !== null ?
                <div className="file-name">
                  <span>{vatTRNFile.name}</span>
                  <IconButton className="p-1" aria-label="delete" onClick={(e) => { setVatTRNFile(null); setVatExpiryDate(null); dispatch(deleteDocument(vatTRNFile.id, vatTRNFile.sapCustomerId, true)); }}>
                    <CancelIcon className="file-close-color" fontSize="small" />
                  </IconButton>
                </div>
                : <Button
                  variant="contained"
                  component={vatExpiryDate && isValidVatExpiryDate ? "label" : "button"}
                  color="secondary"
                  className="rounded-button-dealer mt-2 mr-4 px-4"
                  disabled={!vatExpiryDate || !isValidVatExpiryDate}
                >
                  Browse File
                  <input type="file" hidden onChange={(e) =>
                    (e.target && e.target.files) ?
                      onClickBrowseFile('vatTRNFile', e.target.files[0], setVatTRNFile) : setVatTRNFile(null)
                  } />
                </Button>
              }
              <Tooltip title={props.isEdit && vatExpiryDate && vatTRNFile ? 'Kindly upload new document and later update Issue Date' : ''} placement="top">
                <span>
                  <KeyboardDatePicker disableFuture autoOk size="small" disabled={props.isEdit && vatExpiryDate && vatTRNFile ? true : false} disableToolbar variant="inline" inputVariant="outlined"
                    views={["year", "month", "date"]}
                    openTo="year"
                    onError={(e, v) => datePickerValid(e, setIsValidVatExpiryDate)}
                    format="dd/MM/yyyy" margin="normal" label="Issue Date" value={vatExpiryDate}
                    onChange={(date: Date | null) => { setVatExpiryDate(date); !date && setVatTRNFile(null) }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    className="rounded-input-dealer width-160 mt-2 cal-icon"
                  />
                </span>
              </Tooltip>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <Grid container className="pl-4 pr-2">
            <Grid item xs={12} className="mt-2 mb-2 text-muted">
              <FormControlLabel className="ml-0 font-12" control={<Checkbox name="checkedB" onClick={(e) => { copyOwnerDetails(e) }} color="primary" className="mr-2" />}
                label="Copy Owner/Partner details from Dealer tab" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField required id="adminUserId" error={adminUserIdError} helperText={userIdErrorText}
                disabled={isWebLoginIdDisabled}
                value={adminUserId} onChange={(e: any) => {
                  setIsUserIdValid(false);
                  setSubmitDisabled(true);
                  setUserIdErrorText("");
                  handleInputChange(e, setAdminUserId, setAdminUserIdError);
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{isUserIdValid && <CheckCircleIcon className="valid-user-id" />}</InputAdornment>,
                }} label="Web Login Id" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <Button variant="contained" disabled={isWebLoginIdDisabled} color="secondary" className="rounded-button-dealer mt-2 py-2 px-4"
                onClick={(e: any) => { checkUserAvailability(e) }}>Check Availability</Button>
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField select id="adminTitle"
                value={adminTitle} onChange={(e: any) => handleDropdownChange(e, setAdminTitle)} label="Title" className="rounded-input-dealer w-25" margin="dense" variant="outlined">
                <MenuItem key={1} value={"Mr"}>Mr.</MenuItem>
                <MenuItem key={2} value={"Mrs"}>Mrs.</MenuItem>
                <MenuItem key={3} value={"Ms"}>Ms.</MenuItem>
                <MenuItem key={4} value={"Dr"}>Dr.</MenuItem>
                <MenuItem key={5} value={"Engr"}>Engr.</MenuItem>
                <MenuItem key={6} value={"H.H"}>H.H</MenuItem>
                <MenuItem key={7} value={"Heirs of"}>Heirs of</MenuItem>
                <MenuItem key={8} value={"Sheikh"}>Sheikh</MenuItem>
                <MenuItem key={9} value={"Sheikha"}>Sheikha</MenuItem>
              </TextField>
              <TextField required id="adminFirstName" error={adminFirstNameError || invalidAdminFirstName} helperText={invalidAdminFirstName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ADMIN_FIRST_NAME_TEXT}</span>}
                value={adminFirstName} onChange={(e: any) => handleInputChange(e, setAdminFirstName, setAdminFirstNameError)} label="First Name" className="rounded-input-dealer w-75" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField required id="adminLastName" error={adminLastNameError || invalidAdminLastName} helperText={invalidAdminLastName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ADMIN_LAST_NAME_TEXT}</span>}
                value={adminLastName} onChange={(e: any) => handleInputChange(e, setAdminLastName, setAdminLastNameError)} label="Last Name" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField required id="adminMobile" placeholder={Messages.MOBILE_PLACEHOLDER_TEXT} error={adminMobileError || invalidAdminMobile} helperText={invalidAdminMobile && <span style={{ color: 'red' }}>{ValidationErrorMessage.MOBILE_TEXT}</span>}
                value={adminMobile} onChange={(e: any) => handleInputChange(e, setAdminMobile, setAdminMobileError)} label="Mobile" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField required id="adminEmail" error={adminEmailError} helperText={adminEmail.trim() !== "" && adminEmailError && ValidationErrorMessage.EMAIL_TEXT}
                value={adminEmail} onChange={(e: any) => handleEmailChange(e, setAdminEmail, setAdminEmailError)} label="Email" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField required disabled select id="adminUsertype" error={adminUserTypeError}
                value={adminUserType} onChange={(e: any) => handleDropdownChange(e, setAdminUserType, setAdminUserTypeError)} label="User Type" className="rounded-input-dealer w-100" margin="dense" variant="outlined">
                <MenuItem key={1} value={"Admin"}>Admin</MenuItem>
                <MenuItem key={2} value={"User"}>User</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField id="adminphone2" helperText={invalidAdminPhone2 && <span style={{ color: 'red' }}>{ValidationErrorMessage.PHONE_TWO_TEXT}</span>}
                value={adminPhone2} onChange={(e: any) => handleInputChange(e, setAdminPhone2)} label="Phone2" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <KeyboardDatePicker required autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Valid From" value={adminValidFrom === null ? today : adminValidFrom}
                views={["year", "month", "date"]}
                openTo="year"
                onChange={(date: Date | null) => {
                  setAdminValidFrom(date);
                  setAdminValidFromError(false);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="rounded-input-dealer w-100 mt-2 cal-icon"
                error={adminValidFromError}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <KeyboardDatePicker required
                views={["year", "month", "date"]}
                openTo="year"
                autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                format="dd/MM/yyyy" margin="normal" label="Valid To" value={adminValidTo === null ? new Date('9999-12-31') : adminValidTo}
                onChange={(date: Date | null) => {
                  setAdminValidTo(date);
                  setAdminValidToError(false);
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="rounded-input-dealer w-100 mt-2 cal-icon"
                error={adminValidToError}
                maxDate={new Date('9999-12-31')}
                helperText={invalidDate && <span style={{ color: 'red' }}>Valid to date must be greater than valid from date.</span>}
              />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField id="adminFax" helperText={invalidAdminFax && <span style={{ color: 'red' }}>{ValidationErrorMessage.FAX_TEXT}</span>}
                value={adminFax} onChange={(e: any) => handleInputChange(e, setAdminFax)} label="Fax" className="rounded-input-dealer w-100" margin="dense" variant="outlined" />
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField select id="adminCountry"
                value={adminCountry} onChange={(e: any) => handleDropdownChange(e, setAdminCountry)} label="Country" className="rounded-input-dealer w-100" margin="dense" variant="outlined">
                {CountryList.data.map(item => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Country}</MenuItem>))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} className="pr-4 pb-2">
              <TextField select id="adminRegion"
                value={adminRegion} onChange={(e: any) => handleDropdownChange(e, setAdminRegion)} label="Region" className="rounded-input-dealer w-100" margin="dense" variant="outlined">
                {RegionList.data.map(item => (
                  <MenuItem key={item.Code} value={item.Code}>{item.Region}</MenuItem>))}
              </TextField>
            </Grid>
            {props.isEdit && (adminDetails && adminDetails.status !== 'In Progress') && <>
              <div className="col-sm-12 mb-2 row justifyContentHorizontal align-items-center">
                <div className="lock-indicator font-size-12">Lock User</div>
                <Typography component="div" className="ml-5">
                  <Grid component="label" container alignItems="center" spacing={1}>
                    <Grid item>
                      <AntSwitch
                        checked={lockIndicator}
                        onChange={() => { setLockIndicator(!lockIndicator); if (!lockIndicator === false) setLockReason("") }} />
                    </Grid>
                  </Grid>
                </Typography>
              </div>
              {lockIndicator
                && (
                  <Grid item xs={12} className="pr-4 pb-2">
                    <TextField
                      helperText={lockReason === "" && <span style={{ color: 'red' }}>Please Enter Reason.</span>}
                      required
                      multiline disabled={!lockIndicator} value={lockReason}
                      onChange={(e: any) => setLockReason(e.target.value)} rows={3}
                      id="adminLockReason" label="Lock Reason" className="rounded-input-dealer w-100"
                      margin="dense" variant="outlined" />
                  </Grid>
                )
              }
            </>}
          </Grid>
        </TabPanel>
      </div>
    </DialogContent>
    {/* <Snackbar anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
              }}
              open={(createDealerUser && createDealerUser.data) || (updateDealerUser && updateDealerUser.data)}
              autoHideDuration={6000}            
    >
      <Alert severity={"success"}>
                    User {updateDealerUser && updateDealerUser.data ? "Updated" : "Added"} Successfully!
      </Alert>
    </Snackbar>  */}
    <DialogActions className="my-2 mr-4">
      <Button variant="contained" color="primary" className="rounded-pill" disabled={submitDisabled || ((tabValue === 2 || (tabValue === 1)) ? disableSubmit : disableNext)} onClick={() => handleNextClick()}>{(tabValue === 2 || (tabValue === 1 && props.hideAdmin)) ? <span>{submitLoading && <Loading />} Submit</span> : <span>{nextLoading && <Loading />} Next</span>}</Button>
      <Button variant="contained" className="rounded-pill pl-4 pr-4" onClick={() => closeAddDealerDialog()} >Close</Button>
    </DialogActions>

    <Snackbar
      open={openSnackbar}
      handleopenClose={() => {
        handleopenCloseSnackbar(false)
      }} text={apiResponseText}
    />
  </Dialog>
}
