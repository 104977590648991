/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import tempKamLogo from "./afg.jpeg"
import { Markup } from 'interweave';
import { get } from 'lodash';
import { Grid } from '@material-ui/core';
import { getTermsAndConditions } from "../../../store/actions/termsAndConditions.action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    yes: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#1A9FE0',
        textTransform: 'capitalize'
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    bodyText: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function TermsAndConditions(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const { termsAndConditions }: any = useSelector((state: any) => state.termsAndConditions);
    const termsAndConditionsData = get(termsAndConditions, 'data', {});
    const history: any = useHistory();

    useEffect(() => {
        if(!termsAndConditions)
        dispatch(getTermsAndConditions());
    }, [termsAndConditions]);

    return (
        <Fragment>
            {termsAndConditionsData &&
                <div className="col-12 row m-0 p-5">
                    <div className="col-12 mt-4 mr-5">
                        <div className="col-12 d-flex justify-content-center">
                            {/* <img src={tempKamLogo} alt="logo" width="70px" height="70px" /> */}
                            <Markup content={termsAndConditionsData.Article_Body} />
                        </div>
                    </div>
                    <div className="col-12 mt-3">
                        {/* <div className="col-12 d-flex justify-content-center">
                            <Button className={`blueActionButton border-left rounded-pill ml-4 mb-2`} onClick={() => {  history.push('/onboarding') }}>
                                Back To Complete Registration
                            </Button>
                        </div> */}
                    </div>
                </div>
            }
        </Fragment>
    );
}
