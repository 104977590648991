/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import RequestOrder from "./RequestOrder";
import UploadLpo from "./UploadLpo";
import CancelQuotation from "./CancelQuotation";
import { downloadQuotation, viewLPO } from "../../store/actions/quotation.action";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { useDispatch, useSelector } from "react-redux";
import _, { get } from "lodash";

const useStyles = makeStyles((theme) => ({

}));

const ViewLPO = (props: any) => {
  const { brandId, enquiryNumber, quotationNumber, sapId, fileExtension, orderNumber, value, myQuotation, user, page, status, handleClose } = props
  const [open, setOpen] = React.useState(false);
  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [openCancelQuotation, setÖpenCancelQuotation] = React.useState(false)
  const classes = useStyles();
  const { profileDetails, isSellingPriceData }: any = useSelector((state: any) => state.profileDetails);
  let sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  if (sessionStorage.getItem('userType') === 'FSE') {
    sapCustomerId = sapId;
  } else {
    sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  }
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const dispatch: Dispatch<any> = useDispatch();
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [isUpdate, setIsUpdate] = React.useState<any>(true)

  const handleUploadLpo = () => {
    setOpenUploadLpo(false)
  }

  const handleCloseRequestOrder = () => {
    // handleViewLPO();
    setOpenRequestOrder(false)
  }

  const handleCancelQuotation = () => {
    setÖpenCancelQuotation(false)
  }

  // ON CHANGE OPEN - CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleViewLPO = async () => {
    if (props.quotationNumber) {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, props.enquiryNumber, sapCustomerId, props.quotationNumber, ""))
      if (data && data.error) {
        showErrorMessage(data && data.error)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
      } else {
        if (data) {
          // setViewQuotationUrl(data)
          if (fileExtension === 'pdf') {
            fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
              const file = new Blob([resp], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = fileURL + ".pdf";
              link.click();
            });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            window.open(props.viewQuotationUrl);
          } else {
            window.open(props.viewQuotationUrl);
          }
        }
      }
    } else {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, props.enquiryNumber, sapCustomerId, "", props.orderNumber))
      if (data && data.error) {
        showErrorMessage(data && data.error)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
      } else {
        if (data) {
          if (fileExtension === 'pdf') {
            fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
              const file = new Blob([resp], { type: 'application/pdf' });
              const fileURL = URL.createObjectURL(file);
              const link = document.createElement('a');
              link.href = fileURL;
              link.download = "LPO" + ".pdf";
              link.click();
            });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            window.open(props.viewQuotationUrl);
          } else {
            window.open(props.viewQuotationUrl);
          }
        }
      }
    }
  }

  const updateLpo = async () => {
    props.handleCloseViewLPO();
    handleClose()
    handleClose()
    setOpenRequestOrder(true)
  }

  return (
    <div className="full-dialog">
      <React.Fragment>
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog className="full-dialog" fullWidth={true} maxWidth="xl" open={props.openViewLPO ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleCloseViewLPO()
            }
          }}>
          <DialogTitle className="pb-0" id="form-dialog-title">
            <div className="page-title">View LPO
              <IconButton aria-label="close" className="float-right" onClick={() => props.handleCloseViewLPO()}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers className="p-0">
            {fileExtension === 'pdf' && (
              <iframe
                title="pdf"
                src={props.viewQuotationUrl}
                className="h-100vh w-100"
                width="100%"
              />
            )}
            {(fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') && (
              <img
                src={props.viewQuotationUrl}
                alt="format"
                className="h-100vh w-100"
                width="100%"
              />
            )}
            {/* {(fileExtension === 'eml' || fileExtension === 'msg') && (
              <iframe
                title="eml"
                src={props.viewQuotationUrl}
                className="h-100vh w-100"
                width="100%"
              />
            )} */}
          </DialogContent>
          <DialogActions className=" p-3 justify-content-start">
            <div className="float-left">
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); handleViewLPO(); handleClose() }}>
                Download LPO
              </Button>
              {props.quotationNumber && props.orderNumber ? "" :
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={updateLpo}>
                Update LPO
              </Button>}
              {/* <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => { setÖpenCancelQuotation(true)}}>
                Cancel Quotation
              </Button> */}
            </div>
          </DialogActions>
          <DialogActions className="justify-content-end mt-n-65">
            {/* <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); setOpenRequestOrder(true) }}>
              Request Quotation
            </Button> */}
            <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { props.handleCloseViewLPO(); handleClose() }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      {/* <RequestOrder openRequestOrder={openRequestOrder} handleCloseRequestOrder={handleCloseRequestOrder} /> */}
      <CancelQuotation openCancelQuotation={openCancelQuotation} handleCancelQuotation={handleCancelQuotation} />
      <RequestOrder openRequestOrder={openRequestOrder} handleCloseRequestOrder={handleCloseRequestOrder} brandId={brandId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} isUpdate={isUpdate} />
    </div >
  )
}
export default ViewLPO;
