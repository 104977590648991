import React, { useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import VolumeUpOutlinedIcon from '@material-ui/icons/VolumeUpOutlined';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';

import { getAnnouncements, getAnnouncementDetail } from '../../store/actions/announcements.actions';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },

  },
  gutters: {
    paddingLeft: "8px",
    paddingRight: "8px"
  }
}))(MenuItem);

const AnnouncementIcon: React.FC = () => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const dispatch: Dispatch<any> = useDispatch();
  const { announcements }: any = useSelector((state: any) => state.announcements);
  const history = useHistory();

  // useEffect(() => {
  //   if (!announcements)
  //     dispatch(getAnnouncements());
  // }, [announcements, dispatch]);

  const menuStyle = {
    fontFamily: "Nunito !important",
  };
  const menuHeaderStyle = {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#666666"
  };
  const menuItemContainer = {
    maxHeight: "350px",
    marginTop: '5px'
  };
  const menuItemStyle = {
    width: "420px"
  };
  const menuItemHeaderStyle = {
    fontSize: "13px",
    fontWeight: 600,
    color: "#666666"
  };
  const menuItemDescStyle = {
    fontSize: "12px",
    fontWeight: 500,
    color: "#666666"
  };
  const menuItemDateStyle = {
    fontSize: "11px",
    fontWeight: 500,
    color: "#999999",
    opacity: 1
  };
  const menuItemIconCenter = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  };

  return (
    <>
      <IconButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        <Badge badgeContent={announcements && announcements.data && announcements.data.data && announcements.data.data.Offers && announcements.data.data.Offers.OffersList && announcements.data.data.Offers.OffersList.length} color="error">
          <VolumeUpOutlinedIcon />
        </Badge>
      </IconButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        onClick={handleClose}
        style={menuStyle}
      >
        <Typography className="ml-2 mb-2" style={menuHeaderStyle}>Announcements</Typography>
        <Divider />
        <div style={menuItemContainer}>
          {announcements && announcements.data && announcements.data.data && announcements.data.data.Offers && announcements.data.data.Offers.OffersList && announcements.data.data.Offers.OffersList.length > 0 && announcements.data.data.Offers.OffersList.map((item: any) =>
            <StyledMenuItem onClick={(e) => {
              dispatch(getAnnouncementDetail(item.id, item.name));
              history.push('/announcements');
            }} key={item.id}>
              {/* <div className="mt-2" style={menuItemStyle} key={item.id}>
                            <Typography style={menuItemHeaderStyle} noWrap>{item.title}</Typography>
                            <Typography style={menuItemDescStyle} noWrap>{item.description}</Typography>
                            <Typography style={menuItemDateStyle}>{item.date}</Typography>
                        </div> */}
              <div className="row" style={menuItemStyle} >
                <div className="col-2" style={menuItemIconCenter}>
                  <VolumeUpOutlinedIcon />
                  <Divider orientation="vertical" className="ml-2" flexItem />
                </div>
                <div className="col-10">
                  <Typography style={menuItemHeaderStyle} noWrap>{item.title}</Typography>
                  <Typography style={menuItemDescStyle} noWrap>{item.description}</Typography>
                  <Typography style={menuItemDateStyle}>{item.date}</Typography>
                </div>
              </div>
            </StyledMenuItem>
          )}
        </div>
      </StyledMenu>
    </>
  )
}

export default AnnouncementIcon
