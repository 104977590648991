import React from "react"

const Footer = (props:any) => {
    
    return (
      <footer className="py-5 bg-333">
          <div className="container xl text-white">
            <div className="row align-items-center">
              <div className="col-lg-3 text-center text-lg-left">© Copyright 2022 Al-Futtaim</div>
              <div className="col-lg-6 text-center my-4 my-lg-0">
                <ul className="m-0 footer-menu">
                <li className="d-inline-block my-2 my-sm-0"><a href="mailto:alac.tradepoint@alfuttaim.com" className="text-white">Contact Us</a></li>
                {/* {props?.pageData?.labels && Object.keys(props?.pageData?.labels) && Object.keys(props?.pageData?.labels).map((item:any)=>{
                    return  <li className="d-inline-block my-2 my-sm-0"><a href="javascript:void(0);" className="text-white">{props?.pageData?.labels[item]}</a></li>
                  })} */}
                </ul>
              </div>
              <div className="col-lg-3 text-center text-lg-right">
                {/* <ul className="m-0">
                  <li className="d-inline-block ml-3"><a href="javascript:void(0);"><span className="material-icons-outlined text-white"> thumb_up </span> </a></li>
                  <li className="d-inline-block ml-3"><a href="javascript:void(0);"><span className="material-icons-outlined text-white"> public </span> </a></li>
                  <li className="d-inline-block ml-3"><a href="javascript:void(0);"><span className="material-icons-outlined text-white"> sentiment_satisfied </span> </a></li>
                  <li className="d-inline-block ml-3"><a href="javascript:void(0);"><span className="material-icons-outlined text-white"> smart_display </span> </a></li>
                </ul> */}
              </div>
            </div>
          </div>
      </footer>
    )
}

export default Footer