/* eslint-disable */
import React, { useEffect, useCallback, useState } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import RequestOrder from "./RequestOrder";
import UploadLpo from "./UploadLpo";
import CancelQuotation from "./CancelQuotation";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { downloadQuotation } from "../../store/actions/quotation.action";
import { useDispatch } from "react-redux";
import XLSX from "xlsx";
const useStyles = makeStyles((theme) => ({

}));

const ViewQuotation = (props: any) => {
  const { enquiryNumber, page, viewQuotationUrl, quotationNumber, sapId, orderNumber, value, status, myQuotation, user, brandId, quotationStatus, handleCloseViewQuotation } = props
  const [open, setOpen] = React.useState(false);
  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [openCancelQuotation, setÖpenCancelQuotation] = React.useState(false)
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const dispatch: any = useDispatch()
  const classes = useStyles()

  const handleUploadLpo = () => {
    setOpenUploadLpo(false)
  }

  const handleCloseRequestOrder = () => {
    setOpenRequestOrder(false);
    if (sessionStorage.getItem('userType') === 'FSE') {
      props.loadFSEQuotation()
    } else {
      props.loadQuotation();
    }
  }

  const handleCancelQuotation = () => {
    setÖpenCancelQuotation(false)
  }

  // ON CHANGE OPEN - CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };


  const handleDownload = async (type: any) => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId, type));
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
    } else {
      if (type == 'pdf') {
        fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = "quotation" + ".pdf";
          link.click();
        });
      } else {
        window.open(data && data.data, "_blank");
      }
    }
  }

  return (
    <div>
      <React.Fragment>
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog className="full-dialog" fullWidth={true} maxWidth="xl" open={props.openViewQuotation ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleCloseViewQuotation()
            }
          }}>
          <DialogTitle className="pb-0" id="form-dialog-title">
            <div className="page-title">View Quotation
              <IconButton aria-label="close" className="float-right" onClick={() => { props.handleCloseViewQuotation(); }}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers className="p-0">
            <iframe
              title="pdf"
              src={viewQuotationUrl}
              className="h-100vh w-100"
              width="100%"
            />
          </DialogContent>
          <DialogActions className=" p-3 justify-content-start">
            <div className="float-left">
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => handleDownload('pdf')}>
                Download Quotation
              </Button>
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => handleDownload('xls')}>
                Download Quotation In Excel
              </Button>
              {!props.isOrder && quotationStatus === "Open" && (
                <Button variant="contained" disabled={quotationStatus === "Open" ? false : true} className="submit-button font-size-11 mr-3" onClick={() => { setÖpenCancelQuotation(true) }}>
                  Cancel Quotation
                </Button>
              )}
            </div>
          </DialogActions>
          <DialogActions className="justify-content-end mt-n-65">
            {!props.isOrder && quotationStatus === "Open" && sessionStorage.getItem('userType') !== 'FSE' && (
              <Button variant="contained"
                disabled={quotationStatus === "Open" ? false : true}
                className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); setOpenRequestOrder(true); props.handleCloseViewQuotation(false) }}>
                Request Order
              </Button>
            )}
            <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { props.handleCloseViewQuotation(); }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <RequestOrder openRequestOrder={openRequestOrder} handleCloseRequestOrder={handleCloseRequestOrder} brandId={brandId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} handleCloseViewQuotation={handleCloseViewQuotation} />
      <CancelQuotation openCancelQuotation={openCancelQuotation} handleCancelQuotation={handleCancelQuotation} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} handleCloseViewQuotation={handleCloseViewQuotation} />
    </div >
  )
}
export default ViewQuotation;
