import { ORDER_SUMMARY } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  orderSummary: null,
  offlinePaymentUpdate: null,
  orderSUmmaryDetails: null
};

function orderSummary(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case ORDER_SUMMARY.GET_ORDER_SUMMARY_FETCHING:
      return {
        ...state,
        orderSummary: { loading: true, data: null, err: null }
      };
    case ORDER_SUMMARY.GET_ORDER_SUMMARY_SUCCES:
      return {
        ...state,
        orderSummary: {
          loading: false,
          data:
            action.payload.data && action.payload.data.data,
          err: null
        }
      };
    case ORDER_SUMMARY.GET_ORDER_SUMMARY_ERROR:
      return {
        ...state,
        orderSummary: { loading: false, data: null, err: action.payload.error }
      };

      case ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_FETCHING:
        return {
          ...state,
          orderSUmmaryDetails: { loading: true, data: null, err: null }
        };
      case ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_SUCCESS:
        return {
          ...state,
          orderSUmmaryDetails: {
            loading: false,
            data:
              action.payload.data && action.payload.data,
            err: null
          }
        };
      case ORDER_SUMMARY.GET_ORDER_SUMMARY_DETAILS_ERROR:
        return {
          ...state,
          orderSUmmaryDetails: { loading: false, data: null, err: action.payload.error }
        };

    case ORDER_SUMMARY.GET_OFFLINEMODE_UPDATE_FETCHING:
      return {
        ...state,
        offlinePaymentUpdate: { loading: false, data: null, err: null }
      };
    case ORDER_SUMMARY.GET_OFFLINEMODE_UPDATE_SUCCESS:
      return {
        ...state,
        offlinePaymentUpdate: { loading: false, data:action.payload, err: null }
      }
    case ORDER_SUMMARY.GET_OFFLINEMODE_UPDATE_ERROR:
      return {
        ...state,
        offlinePaymentUpdate: {
          loading: false, data: null, err: action.payload.error
        }
      }
    default:
      return state;
  }
};

export default orderSummary;