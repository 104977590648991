/* eslint-disable */
import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import OpenRequestOrder from "./OpenRequestOrder";
import OpenViewCancelQuotation from "./OpenCancelQuotation";
import _ from "lodash";
import { downloadQuotation } from "../../store/actions/quotation.action";

const OpenViewQuotation = (props: any) => {
  const { enquiryNumber, page, quotationNumber, sapId, viewQuotationUrl, orderNumber, value, status, myQuotation, user, orgId, brandId, quotationStatus, handleCloseViewQuotation, handleClose } = props
  const [open, setOpen] = React.useState(false);
  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [openCancelQuotation, setÖpenCancelQuotation] = React.useState(false)
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const dispatch: any = useDispatch()


  const handleUploadLpo = () => {
    setOpenUploadLpo(false)
  }

  const handleCloseRequestOrder = () => {
    setOpenRequestOrder(false)
  }

  const handleCancelQuotation = () => {
    setÖpenCancelQuotation(false)
  }

  const handleDownload = async (type: any) => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId, type))
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
    } else {
      if (type === 'pdf') {
        fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = "quotation" + ".pdf";
          link.click();
        });
      }
      else {
        window.open(data && data.data, "_blank");
      }
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  return (
    <div>
      <React.Fragment>
        <span className="cursor-pointer mr-2" onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        </span>
        <Dialog className="full-dialog" fullWidth={true} maxWidth="xl" open={props.openViewQuotation ? true : false} aria-labelledby="customized-dialog-title"
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              props.handleCloseViewQuotation()
            }
          }}>
          <DialogTitle className="pb-0" id="form-dialog-title">
            <div className="page-title">View Quotation
              <IconButton aria-label="close" className="float-right" onClick={() => { handleCloseViewQuotation(); }}>
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <DialogContent dividers className="p-0">
            <iframe
              title="pdf"
              // src="https://strautohlcommqa.blob.core.windows.net/mallstore/Sales_Quotation_20005922.pdf"
              src={viewQuotationUrl}
              className="h-100vh w-100"
              width="100%"
            />
          </DialogContent>
          <DialogActions className=" p-3 justify-content-start">
            <div className="float-left">
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => {
                handleDownload('pdf')
              }}>
                Download Quotation
              </Button>
              <Button variant="contained" className="submit-button font-size-11 mr-3" onClick={() => {
                handleDownload('xls')
              }}>
                Download Quotation In Excel
              </Button>
              {!props.isOrder && (
                <Button variant="contained"
                  // disabled={quotationStatus === "Open" ? false : true}
                  className="submit-button font-size-11 mr-3" onClick={() => { setÖpenCancelQuotation(true) }}>
                  Cancel Quotation
                </Button>
              )}
            </div>
          </DialogActions>
          <DialogActions className="justify-content-end mt-n-65">
            {!props.isOrder && sessionStorage.getItem('userType') !== 'FSE' && (
              <Button variant="contained"
                // disabled={quotationStatus === "Open" ? false : true}
                className="submit-button font-size-11 mr-3" onClick={() => { setOpen(false); setOpenRequestOrder(true) }}>
                Request Order
              </Button>
            )}
            <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { handleCloseViewQuotation(); }}>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
      <OpenRequestOrder openRequestOrder={openRequestOrder} handleCloseViewQuotation={handleCloseViewQuotation} handleCloseRequestOrder={handleCloseRequestOrder} handleClose={handleClose} orgId={orgId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} />
      <OpenViewCancelQuotation handleCloseViewQuotation={handleCloseViewQuotation} openCancelQuotation={openCancelQuotation} orgId={orgId} handleCancelQuotation={handleCancelQuotation} enquiryNumber={enquiryNumber} handleClose={handleClose} quotationNumber={quotationNumber} sapId={sapId} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} />
    </div >
  )
}
export default OpenViewQuotation;
