/* eslint-disable */
import React, { Fragment, Dispatch, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useEffect } from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Visibility from "@material-ui/icons/Visibility";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import { InputAdornment, MenuItem, Tooltip, Button, FormControl, Checkbox } from '@material-ui/core';
import { ValidationErrorMessage } from '../../constants/messages';
import ErrorIcon from '@material-ui/icons/Error';
import { getActualBrand, getNewBrand, getUniqueTimestamp } from '../../common/components/util';
import { editPartnerDetails, getDealerDetails, getPartnerDetails, savePartnerDetails, deletePartnerDetails, setCustomerType, resetValues, setStatus, getAllPartners, getNationality, getRegion, getDealingIn, getTypeOfTrade } from '../../store/actions/editDealer.actions';
import { get } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles, createTheme } from '@material-ui/core/styles';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import ActionButtons from './ActionButtons';
import AddNewOwnerPartnerDialog from './addNewOwnerPartnerDialog'
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import { getAllBrands, updateDealerData } from '../../store/actions/dealer.action';
import moment from "moment-timezone";
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { getRefreshDataFromSAPCustomerDetails } from "../../store/actions/dealer.action";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import { getNewlyAddedBrand } from "../../common/components/util";
import BrandUpdatedDialog from './BrandUpdatedDialog';

const useStyles = makeStyles((theme) => ({
  iconButton: {
    padding: 10,
  },
  button: {
    borderRadius: "1.25rem",
    minWidth: 170,
    margin: "0 0 0 .5%",
    backgroundColor: "#1A9FE0",
    color: "white",
    height: "2.25rem",
    fontSize: "0.7rem",
  },
  header: {
    color: '#666666',
    fontWeight: 900,
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    textAlign: 'left',
    minWidth: 90,
    maxWidth: 120
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
  },
}));
const theme = createTheme({
  palette: {
    secondary: {
      main: '#1A9FE0'
    },
  }
});

//TABLE HEADER COLOUMNS CODE
const headCells = [
  { id: 'firstName', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'email', numeric: true, disablePadding: false, label: 'Email' },
  { id: 'mobile', numeric: true, disablePadding: false, label: 'Mobile' },
  { id: 'nationality', numeric: true, disablePadding: false, label: 'Nationality' },
  { id: 'designation', numeric: true, disablePadding: false, label: 'Designation' },
  { id: 'emiratesIdNo', numeric: true, disablePadding: false, label: 'Emirates ID No' },
  { id: 'actions', numeric: true, disablePadding: false, label: 'Action' },
];

const DealerDetails = (props: any) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [isAdmin, setIsAdmin] = React.useState(true);
  const [isView, setIsView] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [error, setError] = React.useState<any>(null)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [selected, setSelected] = React.useState<any>([]);
  const [companyName, setCompanyName] = React.useState<string>("");
  const [companyNameError, setCompanyNameError] = React.useState<boolean>(false);
  const [invalidCompanyName, setInvalidCompanyName] = React.useState<boolean>(false);
  const [emailId, setEmailId] = React.useState<string>("");
  const [customerTierType, setCustomerTierType] = React.useState<string>("");
  const [telephoneOffice, setTelephoneOffice] = React.useState<string>("");
  const [telephoneOfficError, setTelephoneOfficeError] = React.useState<boolean>(false);
  const [invalidTelephoneOffice, setInvalidTelephoneOffice] = React.useState<boolean>(false);
  const [vatRegistrationNumber, setVatRegistrationNumber] = React.useState<string>("");
  const [vatRegistrationNumberError, setVatRegistrationNumberError] = React.useState<boolean>(false);
  const [invalidVatRegistrationNumber, setInvalidVatRegistrationNumber] = React.useState<boolean>(false);
  const [dealerEmail, setDealerEmail] = React.useState<string>("");
  const [dealerEmailError, setDealerEmailError] = React.useState<boolean>(false);
  const [invalidDealerEmail, setInvalidDealerEmail] = React.useState<boolean>(false);
  const [headOfficeRegion, setHeadOfficeRegion] = React.useState<string>("");
  const [headOfficeRegionError, setHeadOfficeRegionError] = React.useState<boolean>(false);
  const [invalidHeadOfficeRegion, setInvalidHeadOfficeRegion] = React.useState<boolean>(false);
  const [typeOfTrade, setTypeOfTrade] = React.useState<string>("");
  const [typeOfTradeError, setTypeOfTradeError] = React.useState<boolean>(false);
  const [invalidTypeOfTrade, setInvalidTypeOfTrade] = React.useState<boolean>(false);
  const [dealingIn, setDealingIn] = React.useState<string>("");
  const [dealingInError, setDealingInError] = React.useState<boolean>(false);
  const [invalidDealingIn, setInvalidDealingIn] = React.useState<boolean>(false);
  const [annualTurnover, setAnnualTurnover] = React.useState<string>("");
  const [annualTurnoverError, setAnnualTurnoverError] = React.useState<boolean>(false);
  const [establishedDate, setEstablishedDate] = React.useState<any>(null);
  const [establishedDateError, setEstablishedDateError] = React.useState<boolean>(false);
  const [poBox, setPoBox] = React.useState<string>("");
  const [poBoxError, setPoBoxError] = React.useState<boolean>(false);
  const [invalidPoBox, setInvalidPoBox] = React.useState<boolean>(false);
  const [headOfficeAddress, setHeadOfficeAddress] = React.useState<any>({});
  const [shippingLabels, setShippingLabels] = React.useState<any>([{ name: "SORT1", value: '' }, { name: "SORT2", value: '' }]);
  const [transportationZone, setTransportationZone] = React.useState<any>("");
  const [transportationZoneCode, setTransportationZoneCode] = React.useState<any>("");
  const [isExisting, setIsExisting] = React.useState<any>("");
  const [shiftToPartyCustomerNumber, setShiftToPartyCustomerNumber] = React.useState<any>("");
  const [firstName, setFirstName] = React.useState<string>("");
  const [firstNameError, setFirstNameError] = React.useState<boolean>(false);
  const [invalidFirstName, setInvalidFirstName] = React.useState<boolean>(false);
  const [lastName, setLastName] = React.useState<string>("");
  const [lastNameError, setLastNameError] = React.useState<boolean>(false);
  const [invalidLastName, setInvalidLastName] = React.useState<boolean>(false);
  const [ownerDesignation, setOwnerDesignation] = React.useState<string>("");
  const [ownerDesignationError, setOwnerDesignationError] = React.useState<boolean>(false);
  const [invalidOwnerDesignation, setInvalidOwnerDesignation] = React.useState<boolean>(false);
  const [ownerNationality, setOwnerNationality] = React.useState<string>("");
  const [isNewCustomer, setIsNewCustomer] = React.useState<any>();
  const [ownerNationalityError, setOwnerNationalityError] = React.useState<boolean>(false);
  const [invalidOwnerNationality, setInvalidOwnerNationality] = React.useState<boolean>(false);
  const [ownerEmailId, setOwnerEmailId] = React.useState<string>("");
  const [ownerEmailIdError, setOwnerEmailIdError] = React.useState<boolean>(false);
  const [ownerEmiratesIdNo, setOwnerEmiratesIdNo] = React.useState<string>("");
  const [ownerEmiratesIdNoError, setOwnerEmiratesIdNoError] = React.useState<boolean>(false);
  const [bankName, setBankName] = React.useState<string>("");
  const [selectedPartnerData, setSelectedPartnerData] = React.useState<any>("");
  const [bankNameError, setBankNameError] = React.useState<boolean>(false);
  const [invalidBankName, setInvalidBankName] = React.useState<boolean>(false);
  const [accountNo, setAccountNo] = React.useState<string>("");
  const [accountNoError, setAccountNoError] = React.useState<boolean>(false);
  const [contactName, setContactName] = React.useState<string>("");
  const [contactNameError, setContactNameError] = React.useState<boolean>(false);
  const [telephone, setTelephone] = React.useState<string>("");
  const [telephoneError, setTelephoneError] = React.useState<boolean>(false);
  const [address, setAddress] = React.useState<string>("");
  const [addressError, setAddressError] = React.useState<boolean>(false);
  const [selectBrand, setSelectBrand] = React.useState<any>([]);
  const [orgIds, setOrgIds] = React.useState<any>([]);
  const [actualBrands, setActualBrands] = React.useState<any>([]);
  const [inActiveBrands, setInActiveBrands] = React.useState<any>([]);
  const [selectWorkshopOrgBrand, setSelectWorkshopOrgBrand] = React.useState<any>([]);
  const [initalStockOrder, setInitalStockOrder] = React.useState<string>("");
  const [initalStockOrderError, setInitalStockOrderError] = React.useState<boolean>(false);
  const [totalInvestment, setTotalInvestment] = React.useState<string>("");
  const [otherBusinessEstablishments, setOtherBusinessEstablishments] = React.useState<string>("");
  const [otherBrandsProductsSold, setOtherBrandsProductsSold] = React.useState<string>("");
  const [otherBrandsProductsSoldError, setOtherBrandsProductsSoldError] = React.useState<boolean>(false);
  const [invalidOtherBrandsProductsSold, setInvalidOtherBrandsProductsSold] = React.useState<boolean>(false);
  const [initalStockOrderSelector, setInitalStockOrderSelector] = React.useState<string>("");
  const [totalInvestmentSelector, setTotalInvestmentSelector] = React.useState<string>("AED");
  const [headOfficeRegionCode, setHeadOfficeRegionCode] = React.useState<string>("");
  const [annualTurnoverSelector, setAnnualTurnoverSelector] = React.useState<string>("AED");
  const [streetName, setstreetName] = React.useState<string>("");
  const [streetNameError, setstreetNameError] = React.useState<boolean>(false);
  const [invalidstreetName, setInvalidstreetName] = React.useState<boolean>(false)
  const [city, setcity] = React.useState<string>("");
  const [cityError, setcityError] = React.useState<boolean>(false);
  const [invalidcity, setInvalidcity] = React.useState<boolean>(false)
  const [country, setcountry] = React.useState<string>("");
  const [countryError, setcountryError] = React.useState<boolean>(false);
  const [invalidcountry, setInvalidcountry] = React.useState<boolean>(false)
  const [postalCode, setpostalCode] = React.useState<string>("");
  const [dealerstatus, setStatus] = React.useState<any>("");
  const [postalCodeError, setpostalCodeError] = React.useState<boolean>(false);
  const [invalidpostalCode, setInvalidpostalCode] = React.useState<boolean>(false)
  const [brandLists, setBrandList] = React.useState<any>([]);
  const [dataUpdated, setdataUpdated] = React.useState<any>(false);
  const [creditLimit, setCreditLimit] = React.useState<string>("");
  const [selectedRegion, setSelectedRegion] = React.useState<any>();
  const [dataFetched, setDataFetched] = React.useState<any>(false);
  const [loading, setLoading] = React.useState<any>(false);
  const currencies = ["AED"];
  const dealingInOptions = ['Corporate', 'Patnership', 'Sole Patnership'];
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const { customerDetails, regionData, dealingInData, typeofTradeData, getNationalityData,
    savePartnerData, editPartnerData, deletePartnerData, dealerDetails, getAllPartnersData,
    customerType, getIBCountryAndRegionData }: any = useSelector((state: any) => state.onboard);
  const { brandsList, selectedNewDealer, isEditDealer }: any = useSelector((state: any) => state.dealer);
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const sapCustomerId = get(selectedNewDealer, "sapCustomerId", "");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const customerInfo = dealerDetails && dealerDetails.data;
  const [tradeReference, setTradeReference] = React.useState<any>([]);
  const [tradeReferenceErrors, setTradeReferenceErrors] = React.useState<any>([]);
  const [selectBrandError, setSelectBrandError] = React.useState<boolean>(false);
  const [totalInvestmentSelectorError, setTotalInvestmentSelectorError] = React.useState<boolean>(false);
  const [invalidSelectBrand, setInvalidSelectBrand] = React.useState<boolean>(false);
  const [totalInvestmentError, setTotalInvestmentError] = React.useState<boolean>(false);
  const [invalidTotalInvestment, setInvalidTotalInvestment] = React.useState<boolean>(false);
  const [invalidTotalInvestmentSelector, setInvalidTotalInvestmentSelector] = React.useState<boolean>(false);
  const [invalidInitalStockOrder, setInvalidInitalStockOrder] = React.useState<boolean>(false);
  const [otherBusinessEstablishmentsError, setOtherBusinessEstablishmentsError] = React.useState<boolean>(false);
  const [otherBusinessEstablishmentsErrorMessage, setOtherBusinessEstablishmentsErrorMessage] = React.useState<string>("");
  const [invalidOtherBusinessEstablishments, setInvalidOtherBusinessEstablishments] = React.useState<boolean>(false);
  const [selectedDealerInfo, setselectedDealerInfo] = React.useState<any>(null);
  const [creditLimitSelectorError, setCreditLimitSelectorError] = React.useState<boolean>(false);
  const [invalidCreditLimitSelector, setInvalidCreditLimitSelector] = React.useState<boolean>(false);
  const [creditLimitError, setCreditLimitError] = React.useState<boolean>(false);
  const [invalidCreditLimit, setInvalidCreditLimit] = React.useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [typeOfDelete, setTypeOfDelete] = React.useState<string>("");
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [typeofDailog, setTypeOfDailog] = React.useState<string>("");
  const [tradeRowRefDeleteIndex, setTradeRowRefDeleteIndex] = React.useState<any>();
  const [customer, setCustomer] = React.useState<any>("")
  const [userType, setUserType] = React.useState("")
  const [latitude, setLatitude] = React.useState("")
  const [longitude, setLongitude] = React.useState("")
  const [email, setEmail] = React.useState("");
  const [isDeactivated, setIsDeactivated] = React.useState("");
  const [deactivationReason, setDeactivationReason] = React.useState("");
  const [mobileNumber, setMobileNumber] = React.useState<string>("");
  const [mobileNumberError, setMobileNumberError] = React.useState<boolean>(false);
  const [regionDataNew, setRegionData] = React.useState<any>([]);
  const [newlySelectedBrands, setNewlySelectedBrands] = React.useState<any>([]);
  const [salesRegion, setSalesRegion] = React.useState<any>("");
  const customerTypeAccess: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.customerTypeAccess) || "";
  const [customerAcceessForIB, setCustomerAcceessForIB] = React.useState(false);
  const customerStatus = sessionStorage.getItem('status');
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const displayName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.displayName;
  const [customerLastName, setCustomerLastName] = React.useState<string>("");
  const [ibCurrency, setIbCurrency] = React.useState<any>("");
  const [ibCurrencyError, setIbCurrencyError] = React.useState<boolean>(false);
  const [selectedIBCurrency, setSelectedIBCurrency] = React.useState<any>("");
  const isAddedByFse = sessionStorage.getItem('isAddedByFse') === 'FSE' ? true : false;
  let onboardingType: any = sessionStorage.getItem('customerOnboardingType');
  const [showBrandsUpdatedDialog, setShowBrandsUpdatedDialog] = React.useState<boolean>(false);
  const [checked, setChecked] = React.useState<any>(null);

  useEffect(() => {
    if (customerTypeAccess && customerTypeAccess.length > 0) {
      if (customerTypeAccess.includes("intbusiness")) {
        setCustomerAcceessForIB(true);
      } else {
        setCustomerAcceessForIB(false);
      }
    }
  }, [customerTypeAccess]);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  useEffect(() => {
    if (!dataFetched) loadPartners();
  }, []);

  useEffect(() => {
  }, [dealerstatus]);

  useEffect(() => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      if (customer !== 'Int. Business') {
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if ((customer && customer.toLowerCase() !== 'workshop' && onboardingType && onboardingType.toLowerCase !== 'workshop') && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        // data = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
        // else {
        //   data = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
        // }

        // data = any = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco");
        setBrandList(data);
      } else {
        // let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All' && brand.displayName !== "Famco" && brand.brandId !== '2195');
        brandsList.data.map((brand: any) => {
          if (brand.displayName !== 'All' && brand.displayName !== "Famco") {
            if ((customer && customer.toLowerCase() !== 'workshop' && onboardingType && onboardingType.toLowerCase !== 'workshop') && brand.displayName === 'Tradepoint') {
              brand.displayName = 'ALAC'
            }
            data.push(brand);
          }
        });
        setBrandList(data);
      }
    }
  }, [brandsList, customer])

  const loadPartners = async () => {
    setLoading(true);
    handleopenCloseSnackbar(false);
    setSuccessMessage('');
    dispatch(resetValues());
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
    dispatch(getRegion());
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
    } else {
      dispatch(getDealingIn());
    }
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
    } else {
      dispatch(getTypeOfTrade());
    }
    dispatch(getAllPartners(sapCustomerId));
    const data: any = await dispatch(getDealerDetails(sapCustomerId, 'dealerDetails'));
    sessionStorage.setItem('customerOnboardingType', data && data.data && data.data.customerTierType);
    if (data && data.data && Object.keys(data.data.companyDetails).length > 0 && !dataFetched) {
      if (data.data.customerTierType === 'Workshop') {
        await dispatch(getAllBrands('workshop'));
      } else if (data.data.customerTierType === 'Government') {
        await dispatch(getAllBrands('government'));
      } else if (data.data.customerTierType === 'Dealer') {
        await dispatch(getAllBrands('dealer'));
      } else if (data.data.customerTierType === 'Fleet') {
        await dispatch(getAllBrands('fleet'));
      }
      setHeadOfficeRegion(data.data.companyDetails.region);
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setSalesRegion(data.data.companyDetails.salesRegion);
      setIsNewCustomer(data.data.isNewCustomer);
      setHeadOfficeRegionCode(data.data.companyDetails.regionCode);
      setDealingIn(data.data.companyDetails.dealingIn);
      setTypeOfTrade(data.data.companyDetails.typeOfTrade === "Export customer" ? "TRD21" : data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setIbCurrency(data.data.companyDetails.companyCurrencyType);
      setSelectedIBCurrency(data.data.companyDetails.companyCurrencyType);
      sessionStorage.setItem("ibCurrency", data.data.companyDetails.companyCurrencyType)
      setEstablishedDate(data.data.companyDetails.establishedDate === "Invalid date" ? null : data.data.companyDetails.establishedDate);
      setTradeReference(data.data.treadReference)
      setselectedDealerInfo(data.data)
      setCompanyName(data.data.companyDetails.customerFirstName);
      setCustomerLastName(data.data.companyDetails.customerLastName);
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber)
      setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setDealerEmail(data.data.companyDetails.emails[0].emailId)
      setPoBox(data.data.companyDetails.poBox)
      let selectedBrandsTemp: any = []
      sessionStorage.setItem("customerOnboardingType", data.data.customerTierType)
      if (data.data.customerTierType === 'Workshop') {
        if (data.data.selectedBrand && data.data.selectedBrand.length > 0) {
          let orgdata: any = [];
          data.data.selectedBrand.map((org: any) => {
            selectedBrandsTemp.push(getNewBrand(org.make));
            orgdata.push(org)
          })
          setSelectBrand(selectedBrandsTemp);
          setActualBrands(selectedBrandsTemp);
        }
        if (data.data.organizationIds && data.data.organizationIds.length > 0) {
          // let orgdata: any = [];
          // data.data.organizationIds.map((org: any) => {
          //   selectedBrandsTemp.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make))
          // });
          // setSelectBrand(orgdata);
          setSelectWorkshopOrgBrand(data.data.organizationIds)
        }
      } else {
        if (data.data.organizationIds && data.data.organizationIds.length > 0) {
          let orgdata: any = [];
          let inActiveBrands: any = [];
          data.data.organizationIds.map((org: any) => {
            if (org.isActive !== false) {
              selectedBrandsTemp.push(org.make === 'OES' && sessionStorage.getItem('customerOnboardingType') !== 'workshop' ? getNewlyAddedBrand(org.make) : getNewBrand(org.make));
              orgdata.push(org);
            } else {
              inActiveBrands.push(org.make === 'OES' && sessionStorage.getItem('customerOnboardingType') !== 'workshop' ? getNewlyAddedBrand(org.make) : getNewBrand(org.make));
            }
          })
          setSelectBrand(selectedBrandsTemp);
          setActualBrands(selectedBrandsTemp);
          setInActiveBrands(inActiveBrands);
        }
      }
      if (data && data.data && data.data.organizationIds && data.data.organizationIds.length > 0) {
        setOrgIds(data.data.organizationIds);
      }
      setSelectBrand(selectedBrandsTemp)
      let tradereferenceItems = [...tradeReference];
      let tradeError = [...tradeReferenceErrors];
      tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
        tradereferenceItems.push({
          addresses: element.addresses,
          companyName: element.companyName,
          name: element.name,
          telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
      });
      // if (data.data.treadReference.length == 0) {
      //   tradereferenceItems.push({
      //     companyName: "",
      //     name: "",
      //     addresses: "",
      //     telephone: ""
      //   })
      //   tradeError.push({
      //     companyNameError: false,
      //     companyNameErrorMessage: "",
      //     nameError: false,
      //     nameErrorMessage: "",
      //     addressError: false,
      //     addressErrorMessage: "",
      //     telephoneError: false,
      //     telephoneErrorMessage: "",
      //   })
      // }

      setTradeReferenceErrors(tradeError)
      setTradeReference(tradereferenceItems)
      setTradeReference(tradereferenceItems)
      if (data.data.companyDetails.emails && data.data.companyDetails.emails[0])
        setEmailId(data.data.companyDetails.emails[0].emailId)
      if (data.data.companyDetails.telephones && data.data.companyDetails.telephones[0])
        setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setInitalStockOrder(data.data.initialStockOrder)
      setInitalStockOrderSelector(data.data.stockOrderCurrencyType)
      setTotalInvestment(data.data.totalInvesment);
      setCustomerTierType(data.data.customerTierType);
      setTotalInvestmentSelector(data.data.totalInvesmentCurrencyType)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      if (data.data.addresses && data.data.addresses[0]) {
        setHeadOfficeAddress(data.data.addresses[0])
        setstreetName(data.data.addresses[0].streetName)
        setcity(data.data.addresses[0].city)
        setcountry(data.data.addresses[0].country)
        setpostalCode(data.data.addresses[0].postalCode)
        setShiftToPartyCustomerNumber(data.data.addresses[0].shiftToPartyCustomerNumber)
        setIsExisting(data.data.addresses[0].isExisting)
        setTransportationZoneCode(data.data.addresses[0].transportationZoneCode)
        setTransportationZone(data.data.addresses[0].transportationZone)
        setLatitude(data.data.addresses[0].lattitude)
        setLongitude(data.data.addresses[0].longitude)
        setEmail(data.data.addresses[0].emailId)
        setDeactivationReason(data.data.addresses[0].deactivationReason)
        setIsDeactivated(data.data.addresses[0].isDeactivated)
        if (data.data.addresses[0].shippingLabels && data.data.addresses[0].shippingLabels.length > 0) setShippingLabels(data.data.addresses[0].shippingLabels);
      }
      if (data.data.status) {
        setStatus(data.data.status)
      }
    }
    if (!dataFetched) setDataFetched(true);
    if (true) dispatch(setCustomerType('existing'));
    setLoading(false);
    if (data && data.data && data.data.customerTierType) setCustomer(data && data.data && data.data.customerTierType)
    setUserType(data && data.data && data.data.isNewCustomer)
  }

  const loadPartnersOnClickOfRefresh = async () => {
    setLoading(true);
    handleopenCloseSnackbar(false);
    setSuccessMessage('');
    dispatch(resetValues());
    if (getNationalityData && getNationalityData.data && getNationalityData.data.length > 0) {
    } else {
      dispatch(getNationality());
    }
    dispatch(getRegion());
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
    } else {
      dispatch(getDealingIn());
    }
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
    } else {
      dispatch(getTypeOfTrade());
    }
    dispatch(getAllPartners(sapCustomerId));
    const data: any = await dispatch(getDealerDetails(sapCustomerId, 'dealerDetails'));
    sessionStorage.setItem('customerOnboardingType', data && data.data && data.data.customerTierType);
    if (data && data.data && Object.keys(data.data.companyDetails).length > 0 && dataFetched) {
      if (data.data.customerTierType === 'Workshop') {
        await dispatch(getAllBrands('workshop'));
      } else if (data.data.customerTierType === 'Government') {
        await dispatch(getAllBrands('government'));
      } else if (data.data.customerTierType === 'Dealer') {
        await dispatch(getAllBrands('dealer'));
      } else if (data.data.customerTierType === 'Fleet') {
        await dispatch(getAllBrands('fleet'));
      }
      setHeadOfficeRegion(data.data.companyDetails.region);
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setSalesRegion(data.data.companyDetails.salesRegion);
      setIsNewCustomer(data.data.isNewCustomer);
      setHeadOfficeRegionCode(data.data.companyDetails.regionCode);
      setDealingIn(data.data.companyDetails.dealingIn);
      setTypeOfTrade(data.data.companyDetails.typeOfTrade === "Export customer" ? "TRD21" : data.data.companyDetails.typeOfTrade);
      setAnnualTurnover(data.data.companyDetails.companyAnnualTurnover);
      setEstablishedDate(data.data.companyDetails.establishedDate === "Invalid date" ? null : data.data.companyDetails.establishedDate);
      setTradeReference(data.data.treadReference)
      setselectedDealerInfo(data.data)
      setCompanyName(data.data.companyDetails.customerFirstName);
      setCustomerLastName(data.data.companyDetails.customerLastName);
      setVatRegistrationNumber(data.data.companyDetails.vatRegistrationNumber)
      setTelephoneOffice(data.data.companyDetails.telephones[0].telephone)
      setDealerEmail(data.data.companyDetails.emails[0].emailId)
      setPoBox(data.data.companyDetails.poBox)
      let selectedBrandsTemp: any = []
      sessionStorage.setItem("customerOnboardingType", data.data.customerTierType)
      if (data.data.customerTierType === 'Workshop') {
        let orgdata: any = [];
        if (data.data.selectedBrand && data.data.selectedBrand.length > 0) {
          data.data.selectedBrand.map((org: any) => {
            selectedBrandsTemp.push(getNewBrand(org.make));
          })
          setSelectBrand(orgdata);
        }
        if (data.data.organizationIds && data.data.organizationIds.length > 0) {
          let orgdata: any = [];
          data.data.organizationIds.map((org: any) => {
            if (org.isActive !== false)
              selectedBrandsTemp.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make));
          })
          setSelectBrand(orgdata);
          setSelectWorkshopOrgBrand(data.data.organizationIds)
        }
      } else {
        if (data.data.organizationIds && data.data.organizationIds.length > 0) {
          let orgdata: any = [];
          data.data.organizationIds.map((org: any) => {
              if (org.isActive !== false && org.organizationId !== '2003')
              selectedBrandsTemp.push(org.make === 'OES' ? 'ALAC' : getNewBrand(org.make));
          })
          setSelectBrand(orgdata);
        }
      }
      setSelectBrand(selectedBrandsTemp)
      let tradereferenceItems = [...tradeReference];
      let tradeError = [...tradeReferenceErrors];
      tradeReference.length == 0 && data.data.treadReference && data.data.treadReference.length && data.data.treadReference.forEach((element: any) => {
        tradereferenceItems.push({
          addresses: element.addresses,
          companyName: element.companyName,
          name: element.name,
          telephone: element && element.telephone && typeof element.telephone == 'string' ? element.telephone : element && element.telephones && element.telephones.length > 0 && element.telephones[0] && element.telephones[0].telephone ? element.telephones[0].telephone : ""
        })
        tradeError.push({
          companyNameError: false,
          companyNameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        })
      });
      // if (data.data.treadReference.length == 0) {
      //   tradereferenceItems.push({
      //     companyName: "",
      //     name: "",
      //     addresses: "",
      //     telephone: ""
      //   })
      //   tradeError.push({
      //     companyNameError: false,
      //     companyNameErrorMessage: "",
      //     nameError: false,
      //     nameErrorMessage: "",
      //     addressError: false,
      //     addressErrorMessage: "",
      //     telephoneError: false,
      //     telephoneErrorMessage: "",
      //   })
      // }

      setTradeReferenceErrors(tradeError)
      setTradeReference(tradereferenceItems)
      setTradeReference(tradereferenceItems)
      if (data.data.companyDetails.emails && data.data.companyDetails.emails[0])
        setEmailId(data.data.companyDetails.emails[0].emailId)
      if (data.data.companyDetails.telephones && data.data.companyDetails.telephones[0])
        setTelephoneOffice(data.data.companyDetails.telephones[0].telephone);
      setMobileNumber(data.data.companyDetails.mobileNumber);
      setInitalStockOrder(data.data.initialStockOrder)
      setInitalStockOrderSelector(data.data.stockOrderCurrencyType)
      setTotalInvestment(data.data.totalInvesment);
      setCustomerTierType(data.data.customerTierType);
      setTotalInvestmentSelector(data.data.totalInvesmentCurrencyType)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      setOtherBrandsProductsSold(data.data.otherBrandsOrProductsSold)
      setOtherBusinessEstablishments(data.data.otherBusinessEstablishments)
      if (data.data.addresses && data.data.addresses[0]) {
        setHeadOfficeAddress(data.data.addresses[0])
        setstreetName(data.data.addresses[0].streetName)
        setcity(data.data.addresses[0].city)
        setcountry(data.data.addresses[0].country)
        setpostalCode(data.data.addresses[0].postalCode)
        setShiftToPartyCustomerNumber(data.data.addresses[0].shiftToPartyCustomerNumber)
        setIsExisting(data.data.addresses[0].isExisting)
        setTransportationZoneCode(data.data.addresses[0].transportationZoneCode)
        setTransportationZone(data.data.addresses[0].transportationZone)
        setLatitude(data.data.addresses[0].lattitude)
        setLongitude(data.data.addresses[0].longitude)
        setEmail(data.data.addresses[0].emailId)
        setDeactivationReason(data.data.addresses[0].deactivationReason)
        setIsDeactivated(data.data.addresses[0].isDeactivated)
        if (data.data.addresses[0].shippingLabels && data.data.addresses[0].shippingLabels.length > 0) setShippingLabels(data.data.addresses[0].shippingLabels);
      }
      if (data.data.status) {
        setStatus(data.data.status)
      }
    }
    setLoading(false);
    if (data && data.data && data.data.customerTierType) setCustomer(data && data.data && data.data.customerTierType)
    setUserType(data && data.data && data.data.isNewCustomer)
  }

  function resetValue() {
    setIsEdit(false);
    handleClose();
    dispatch(resetValues());
  }

  function isDisabled() {
    if (false) {
      return true;
    } else {
      return false;
    }
  }


  const getTypeOfTradeData = (typeOfTrade: any) => {
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
      var typeOfTradeName = typeofTradeData.data[0].tradeType.filter((obj: any) => obj.id == typeOfTrade)
      if (typeOfTradeName && typeOfTradeName.length > 0) return typeOfTradeName[0].title;
    }
    return "";
  }

  const getDealingInData = (dealingIn: any) => {
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
      var dealingInName = dealingInData.data[0].regions.filter((obj: any) => obj.id == dealingIn)
      if (dealingInName && dealingInName.length > 0) return dealingInName[0].title;
    }
    return "";
  }

  useEffect(() => {
    handleopenCloseSnackbar(false);
    setSuccessMessage('');
    if (savePartnerData && savePartnerData.data && savePartnerData.data.message) {
      dispatch(getAllPartners(sapCustomerId));
      setOpen(false);
      resetValue();
      setSuccessMessage(savePartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      props.setDataUpdated(true);
    }
  }, [savePartnerData]);

  useEffect(() => {
    handleopenCloseSnackbar(false);
    setSuccessMessage('');
    if (deletePartnerData && deletePartnerData.data && deletePartnerData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deletePartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getAllPartners(sapCustomerId));
      props.setDataUpdated(true);
    }
  }, [deletePartnerData]);

  useEffect(() => {
    handleopenCloseSnackbar(false);
    setSuccessMessage('');
    if (editPartnerData && editPartnerData.data && editPartnerData.data.message) {
      dispatch(getAllPartners(sapCustomerId));
      setOpen(false);
      resetValue();
      setSuccessMessage(editPartnerData.data.message.message)
      handleopenCloseSnackbar(true);
      props.setDataUpdated(true);
    }
  }, [editPartnerData]);

  // useEffect(() => {
  //   if (headOfficeRegion) {
  //     regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
  //       if (r.Region === headOfficeRegion) {
  //         setHeadOfficeRegion(r.Region)
  //         setSelectedRegion(r);
  //       }
  //     })
  //   }
  // }, [headOfficeRegion, regionData])

  useEffect(() => {
    if (headOfficeRegion !== '' && sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((r: any) => {
        if ((r.Code === headOfficeRegion) || (r.Region === headOfficeRegion)) {
          setHeadOfficeRegion(r.Region);
          setHeadOfficeRegionCode(r.Code);
          setSelectedRegion(r);
        }
      })
    }
    if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
      regionDataNew && regionDataNew.length > 0 && regionDataNew.map((r: any) => {
        if ((r.Code === headOfficeRegion) || (r.Region === headOfficeRegion)) {
          setHeadOfficeRegion(r.Region);
          setHeadOfficeRegionCode(r.Code);
          setSelectedRegion(r);
        }
      })
    }
  }, [headOfficeRegion, regionData, regionDataNew]);

  function getRegionsByCountryCode(arr: any, countryCode: any) {
    return arr.filter((obj: any) => obj.countryCode === countryCode).map((obj: any) => ({ Code: obj.regionCode, Region: obj.regionDesc }));
  }

  const brands = [
    // {
    //   "make": "Famco",
    //   "organizationId": "2003",
    // },
    {
      "make": "Honda",
      "organizationId": "2002",
    },
    {
      "make": "TE",
      "organizationId": "2380",
    },
    {
      "make": "Toyota",
      "organizationId": "2001",
    },
    {
      "make": "OES",
      "organizationId": "2195",
    }
  ]

  const getSalesRegion = (arr: any, country: any) => {
    let filtered = arr.filter((obj: any) => obj.countryCode === country);
    if (filtered.length > 0) {
      setSalesRegion(filtered[0].salesRegion)
    }
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any, errorErrorMessage?: any, type?: any) => {
    props.setDataUpdated(true);
    setterName(e.target.value);
    if (type == 'country') {
      setSalesRegion('');
      setHeadOfficeRegion('');
      if (getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion && getIBCountryAndRegionData.data.countryAndSalesRegion.length > 0) {
        getSalesRegion(getIBCountryAndRegionData.data.countryAndSalesRegion, e.target.value || country);
        const regionArray = getRegionsByCountryCode(getIBCountryAndRegionData.data.countryAndSalesRegion, e.target.value || country)
        setRegionData(regionArray);
      }
    }
    if (type === 'brand') {
      props.setDataUpdated(true);
      // setterName(e.target.value);
      errorSetterName(false);
    }
    else if (type === 'intialStock') {
      props.setDataUpdated(true);
      setCreditLimit(e.target.value)
      errorSetterName(false);
    }
    // else if (type === "bussinessEst") {
    //   if (e.target.value != "") {
    //     if (!validateForm(e.target.value, "bussinessEst")) {
    //       errorErrorMessage(ValidationErrorMessage.SPECIAL_CHARACTERS)
    //       errorSetterName(true);
    //     }
    //     else {
    //       errorErrorMessage("")
    //       errorSetterName(false);
    //     }
    //   }
    //   else {
    //     errorErrorMessage("")
    //     errorSetterName(false);
    //   }
    // }
    else if (type === "brandproductsold") {
      props.setDataUpdated(true);
      // if (e.target.value != "") {
      //   if (!validateForm(e.target.value, "brandproductsold")) {
      //     errorSetterName(true);
      //   }
      //   else {
      //     errorSetterName(false);
      //   }
      // }
      // else {
      //   errorSetterName(false);
      // }
    }
    // else if (type === "street") {
    //   if (e.target.value != "") {
    //     if (e.target.value.length > 250) {
    //       errorErrorMessage(ValidationErrorMessage.LENGTH)
    //       errorSetterName(true);
    //     // } else if (!validateForm(e.target.value, "street")) {
    //     //   errorSetterName(true);
    //     }
    //     else {
    //       errorSetterName(false);
    //     }
    //   }
    //   else {
    //     errorSetterName(false);
    //   }
    // }
    // else if (type === "city") {
    //   if (e.target.value != "") {
    //      if (!validateForm(e.target.value, "city")) {
    //       errorSetterName(true);
    //     }
    //     else {
    //       errorSetterName(false);
    //     }
    //   }
    //   else {
    //     errorSetterName(false);
    //   }
    // }
    else if (type === "vatReg") {
      props.setDataUpdated(true);
      if (e.target.value != "") {
        if (!validateForm(e.target.value, "vatReg")) {
          errorErrorMessage(ValidationErrorMessage.TAX_REGISTRATION_TEXT)
          errorSetterName(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "telephone") {
      props.setDataUpdated(true);
      if (e.target.value != "") {
        //if ((e.target.value.charAt(0) != "5" || e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
        if ((e.target.value.charAt(0) != "0") && e.target.value.length > 9) {
          errorErrorMessage(ValidationErrorMessage.MOBILE_TEXT)
          errorSetterName(true);
        }
        //else if (e.target.value.charAt(0) != "5" && e.target.value.charAt(0) != "0") {
        else if (e.target.value.charAt(0) != "0") {
          // errorErrorMessage("It should be start with 5 or 0.")
          errorErrorMessage("It should be start with 0.")
          errorSetterName(true);
        }
        else if (e.target.value.length > 9) {
          errorErrorMessage("It should not exceed 9 digits.")
          setTelephoneOfficeError(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else if (type === "companyName") {
      props.setDataUpdated(true);
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          errorErrorMessage(ValidationErrorMessage.LENGTH)
          errorSetterName(true);
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   errorErrorMessage(ValidationErrorMessage.LENGTH);
        //   errorSetterName(true);

        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   errorErrorMessage(ValidationErrorMessage.SPECIAL_CHARACTERS)
        //   errorSetterName(true);
        // }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
    }
    else if (type === "pobox") {
      props.setDataUpdated(true);
      if (e.target.value != "") {
        if (e.target.value.length > 7) {
          errorErrorMessage(ValidationErrorMessage.POBOX_TEXT);
          errorSetterName(true);
        }
        else {
          errorErrorMessage("")
          errorSetterName(false);
        }
      }
      else {
        errorErrorMessage("")
        errorSetterName(false);
      }
    }
    else {
      props.setDataUpdated(true);
      setterName(e.target.value);
      if (errorSetterName) {
        if (!e.target.value && !Array.isArray(e.target.value) && !e.target.value.trim()) {
          errorSetterName(true);
        }
        else {
          errorErrorMessage("");
          errorSetterName(false);
        }
      }
    }
  }

  const handleInputChangetradeRef = (e: any, fieldname: any, index: any) => {
    let trade = [...tradeReference];
    let tradeErrors = [...tradeReferenceErrors];
    if (fieldname === "name") {
      // setdataUpdated(true)
      trade[index].name = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].nameError = true;
          tradeErrors[index].nameErrorMessage = ValidationErrorMessage.COMPANY_NAME_TEXT;
        }
        else {
          tradeErrors[index].nameError = false;
          tradeErrors[index].nameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].nameError = false;
        tradeErrors[index].nameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }

    if (fieldname === "companyName") {
      // setdataUpdated(true)
      trade[index].companyName = e.target.value;
      if (e.target.value != "") {
        if (e.target.value.length > 250) {
          tradeErrors[index].companyNameError = true;
          tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        }
        // else if (e.target.value.length > 250 && validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.LENGTH;
        // }
        // else if (e.target.value.length <= 250 && !validateForm(e.target.value, "companyName")) {
        //   tradeErrors[index].companyNameError = true;
        //   tradeErrors[index].companyNameErrorMessage = ValidationErrorMessage.SPECIAL_CHARACTERS;
        // }
        else {
          tradeErrors[index].companyNameError = false;
          tradeErrors[index].companyNameErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].companyNameError = false;
        tradeErrors[index].companyNameErrorMessage = "";
      }
      setTradeReferenceErrors(tradeErrors);
      setTradeReference(trade)
    }
    else if (fieldname === "addresses") {
      // setdataUpdated(true)
      trade[index].addresses = e.target.value;
      tradeErrors[index].addressError = false;
      tradeErrors[index].addressErrorMessage = "";
    }
    else if (fieldname == "telephone") {
      // setdataUpdated(true)
      trade[index].telephone = e.target.value;
      if (e.target.value != "") {
        if (e.target.value && e.target.value.length > 20) {
          tradeErrors[index].telephoneError = true;
          tradeErrors[index].telephoneErrorMessage = ValidationErrorMessage.ANNUAL_TURNOVER_TEXT;
        } else if (sessionStorage.getItem('customerOnboardingType') === "Int. Business" && e.target.value && e.target.value.length > 30) {
          tradeErrors[index].telephoneError = true;
          tradeErrors[index].telephoneErrorMessage = ValidationErrorMessage.TELEPHONE_IB_CUSTOMER_ERROR_TEXT;
        }
        else {
          tradeErrors[index].telephoneError = false;
          tradeErrors[index].telephoneErrorMessage = "";
        }
      }
      else {
        tradeErrors[index].telephoneError = false;
        tradeErrors[index].telephoneErrorMessage = "";
      }
    }

    setTradeReferenceErrors(tradeErrors);
    setTradeReference(trade)
    // let trade: any = [...tradeReference];
    // trade[index][fieldname] = e.target.value
    // setTradeReference(trade)
  }
  function validateForm(value: any, type: string) {
    switch (type) {
      case "bussinessEst":
        {
          let re = /^[a-z\d\-_\s]+$/i;
          return !re.test(value) ? false : true;
        }
      case "country":
      case "brandproductsold":
        {
          let re = /^[a-z\d\-_\s]+$/i;
          return !re.test(value) ? false : true;
        }
      // case "companyName":
      // case "address": {
      //   let re = /^[a-zA-Z0-9 ]$/;
      //   return !re.test(value) ? false : true;
      // }
      case "vatReg": {
        const re = /^[0-9]{15}$/;
        return !re.test(value) ? false : true;
      }
      case "telephone":
        {
          const re = sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? /^[0-9]{5,30}$/ : /^[0-9]{9}$/;
          return !re.test(value) ? false : true;
        }
      case "mobileno":
        {
          setMobileNumberError(validateMobile(value) === false ? true : false);
        }
    }
  }

  function validateMobile(number: string) {
    const re = sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? /^\d{5,30}$/ : /^\d{10}$/;
    return re.test(number);
  }

  function validateLandline(number: string) {
    const re = /^(?:[1-9]\d*|0)$/;
    return number.length !== 8 || !re.test(number) ? false : true;
  }

  function validateExistingTab() {
    let isValid: boolean = true;
    if ((!headOfficeRegion) || (headOfficeRegion && headOfficeRegion.trim() === '')) {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!dealingIn && customer !== "Government"
      ) || (dealingIn && dealingIn.trim() === '') && customer !== "Government") {
        setDealingInError(true);
        isValid = false;
      }
    }
    // if (sessionStorage.getItem('customerOnboardingType') === "Int. Business") {
    //   if ((!mobileNumber) || (mobileNumber && mobileNumber.trim() === '') || validateMobile(mobileNumber) === false) {
    //     setMobileNumberError(true);
    //     isValid = false;
    //   }
    // }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if ((!typeOfTrade) || (typeOfTrade && typeOfTrade.trim() === '')) {
        setTypeOfTradeError(true);
        isValid = false;
      }
    }
    if (sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (!establishedDate && customer !== "Government") {
        setEstablishedDateError(true);
        isValid = false;
      }
    }
    if (error) {
      isValid = false;
    }
    if (establishedDate) {
      var varDate = new Date(establishedDate);
      var today = new Date();
      if (varDate > today) {
        setInvalidDate(true);
        setError('Date should be less than today date.')
        setEstablishedDateError(true);
        isValid = false;
      }
      else {
        setEstablishedDateError(false);
        setInvalidDate(false);
        setError(null)
      }
    }
    if (tradeReference && tradeReference.length > 0) {
      let trade1 = [...tradeReference];
      let tradeError = [...tradeReferenceErrors]
      trade1 && trade1.length > 0 && trade1.forEach((element: any, index: any) => {
        if (customer !== "Government" && userType) {
          if (element.companyName == "" || element.companyName.length > 250) {
            isValid = false;
            tradeError[index].companyNameError = true;
          }
          else {
            tradeError[index].companyNameError = false;
          }
          if (element.name == "") {
            isValid = false;
            tradeError[index].nameError = true;
          }
          else {
            tradeError[index].nameError = false;
          }
          if (element.addresses == "") {
            tradeReferenceErrors[index].addressError = true;
            isValid = false;
          }
          else {
            tradeReferenceErrors[index].addressError = false;
          }

          if (element.telephone == "" || element.telephone.length > 20) {
            tradeReferenceErrors[index].telephoneError = true;
            isValid = false;
          }
          else {
            tradeReferenceErrors[index].telephoneError = false;
          }
        }

      })
    }
    if (getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length === 0) {
      isValid = false;
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage('Please add atleast one Owner/Partner');
    }
    return isValid;
  }

  function validateNewDealerDetailsTab() {
    let isValid: boolean = true;
    if (companyName.trim() == "") {
      setCompanyNameError(true);
      isValid = false;
    }
    if (companyName.trim() !== "") {
      if (companyName.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(companyName.trim()))) {
        setInvalidCompanyName(true);
        isValid = false;
      }
      else {
        setInvalidCompanyName(false);
      }
    }
    if (!isAddedByFse && sessionStorage.getItem('customerOnboardingType') === "Workshop") {
      if (vatRegistrationNumber.trim() == "" && customer !== "Workshop") {
        setVatRegistrationNumberError(true);
        isValid = false;
      }
      if (vatRegistrationNumber.trim() !== "") {
        if (vatRegistrationNumber.split('').length > 15 || !(/^\d+$/.test(companyName.trim()))) {
          setInvalidVatRegistrationNumber(true);
          isValid = false;
        }
        else {
          setInvalidVatRegistrationNumber(false);
        }
      }
    }
    if (telephoneOffice.trim() == "" || telephoneOffice.length > 30) {
      setTelephoneOfficeError(true);
      isValid = false;
    }
    if (telephoneOffice.trim() !== "") {
      if (!validateLandline(telephoneOffice.trim())) {
        setInvalidTelephoneOffice(true);
        isValid = false;
      }
      else {
        setInvalidTelephoneOffice(false);
      }
    }
    if (headOfficeRegion.trim() == "") {
      setHeadOfficeRegionError(true);
      isValid = false;
    }
    if (dealerEmail.trim() == "") {
      setDealerEmailError(true);
      isValid = false;
    }
    if (dealerEmail.trim() != "") {
      if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(dealerEmail.trim()))) {
        setInvalidDealerEmail(true);
        isValid = false;
      }
      else {
        setInvalidDealerEmail(false);
      }
    }
    if (dealingIn.trim() == "") {
      setDealingInError(true);
      isValid = false;
    }
    if (annualTurnover.trim() == "") {
      setAnnualTurnoverError(true);
      isValid = false;
    }
    if (firstName.trim() == "") {
      setFirstNameError(true);
      isValid = false;
    }
    if (firstName.trim() !== "") {
      if (firstName.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(firstName.trim()))) {
        setInvalidFirstName(true);
        isValid = false;
      }
      else {
        setInvalidFirstName(false);
      }
    }
    if (lastName.trim() == "") {
      setLastNameError(true);
      isValid = false;
    }
    if (lastName.trim() !== "") {
      if (lastName.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(lastName.trim()))) {
        setInvalidLastName(true);
        isValid = false;
      }
      else {
        setInvalidLastName(false);
      }
    }
    if (ownerNationality.trim() == "") {
      setOwnerNationalityError(true);
      isValid = false;
    }
    if (ownerNationality.trim() !== "") {
      if (ownerNationality.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(ownerNationality.trim()))) {
        setInvalidOwnerNationality(true);
        isValid = false;
      }
      else {
        setInvalidOwnerNationality(false);
      }
    }
    if (ownerDesignation.trim() == "") {
      setOwnerDesignationError(true);
      isValid = false;
    }
    if (ownerDesignation.trim() !== "") {
      if (ownerNationality.split('').length > 250 || !(/^[a-z\d\-_\s]+$/i.test(ownerDesignation.trim()))) {
        setInvalidOwnerDesignation(true);
        isValid = false;
      }
      else {
        setInvalidOwnerDesignation(false);
      }
    }
    if (ownerEmailId.trim() == "" || !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(ownerEmailId.trim()))) {
      setOwnerEmailIdError(true);
      isValid = false;
    }
    if (ownerEmiratesIdNo.trim() == "") {
      setOwnerEmiratesIdNoError(true);
      isValid = false;
    }
    if (bankName.trim() == "") {
      setBankNameError(true);
      isValid = false;
    }
    if (accountNo.trim() == "") {
      setAccountNoError(true);
      isValid = false;
    }
    if (contactName.trim() == "") {
      setContactNameError(true);
      isValid = false;
    }
    if (telephone.trim() == "") {
      setTelephoneError(true);
      isValid = false;
    }
    if (address.trim() == "") {
      setAddressError(true);
      isValid = false;
    }

    if (otherBrandsProductsSold.trim() !== "") {
      if (!(/^[a-z\d\-_\s]+$/i.test(otherBrandsProductsSold.trim()))) {
        setInvalidOtherBrandsProductsSold(true);
        isValid = false;
      }
      else {
        setInvalidOtherBrandsProductsSold(false);
      }
    }
    if (initalStockOrder.trim() == "") {
      setInitalStockOrderError(true);
      isValid = false;
    }
    return isValid;
  }


  function datePickerValid(e: any, state: any) {
    if (e === "Invalid Date Format") {
      if (establishedDate) {
        setError("Invalid Date Format");
        state(true);
      }
    } else {
      var varDate = new Date(establishedDate);
      var today = new Date();
      if (varDate > today) {
        setInvalidDate(true);
        setError('Date should be less than today date.')
        setEstablishedDateError(true);
      }
      else {
        setEstablishedDateError(false);
        state(false);
        setError(null)
      }
    }
  }

  const saveDetails = async (isSubmitted?: any) => {
    if (validateExistingTab()) {
      let selectBrandTemp = selectBrand;
      let isBrandsUpdated = false;
      let selectedBrands: any[] = [];
      let dataOld: any = selectedDealerInfo
      let addedBrandIds: any = new Set();

      selectedBrands = orgIds.map((brand: any) => ({
        ...brand,
        isActive: selectBrand.includes(brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make))
      }));

      isBrandsUpdated = JSON.stringify(actualBrands.sort()) !== JSON.stringify(selectBrand.sort());

      const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");

      if (headOfficeAddress) {
        headOfficeAddress.companyName = companyName + ' ' + customerLastName;
        headOfficeAddress.customerFirstName = companyName;
        headOfficeAddress.customerLastName = customerLastName;
        headOfficeAddress.telephoneNumber = telephoneOffice ? telephoneOffice : '';
        headOfficeAddress.poBox = poBox ? poBox : '';
        headOfficeAddress.city = city ? city : '';
        headOfficeAddress.country = country ? country : '';
        headOfficeAddress.streetName = streetName ? streetName : '';
        headOfficeAddress.mobileNumber = mobileNumber ? mobileNumber : '';
        headOfficeAddress.salesRegion = salesRegion ? salesRegion : '';
        headOfficeAddress.postalCode = postalCode ? postalCode : '';
        headOfficeAddress.region = headOfficeRegionCode ? headOfficeRegionCode : '';
        headOfficeAddress.id = headOfficeAddress.id ? headOfficeAddress.id : getUniqueTimestamp().toString();
        headOfficeAddress.lattitude = latitude
        headOfficeAddress.longitude = longitude
        headOfficeAddress.emailId = dealerEmail ? dealerEmail : email
        headOfficeAddress.addedBy = b2bUserId
        headOfficeAddress.addedByRole = "kam"
        headOfficeAddress.deactivationReason = deactivationReason
        headOfficeAddress.isDeactivated = isDeactivated
        headOfficeAddress.shippingLabels = shippingLabels;
      }

      let traderefe: any[] = [];
      tradeReference && tradeReference.length > 0 && tradeReference.forEach((element: any) => {
        if (element.companyName && element.name && element.addresses && element.telephone) {
          traderefe.push({
            "title": "M/s",
            "companyName": element.companyName,
            name: element.name,
            "addresses": element.addresses,
            "telephones": [{
              "country": "-",
              telephone: element.telephone ? element.telephone : "",
              "phoneType": "PRIMARYMOBILE"
            }]
          })
        }
      })
      let data: any = {
        "b2bUserId": b2bUserId,
        "sapCustomerId": dataOld && dataOld.customerCode ? dataOld.customerCode : dataOld && dataOld.sapCustomerId,
        "customerTierType": customerTierType,
        "isNewCustomer": false,
        "brandUpdateAddRemove": isBrandsUpdated,
        "companyDetails": {
          "title": dataOld && dataOld.title ? dataOld.title : "M/s",
          "companyName": companyName + ' ' + customerLastName,
          "customerFirstName": companyName,
          "customerLastName": customerLastName,
          "vatRegistrationNumber": vatRegistrationNumber,
          "establishedDate": establishedDate && establishedDate == 'Invalid date' ? '' : moment(establishedDate).utc().format(),
          "region": headOfficeRegion,
          "regionCode": headOfficeRegionCode,
          "mobileNumber": mobileNumber ? mobileNumber : "",
          "salesRegion": salesRegion ? salesRegion : '',
          "emails": [{ emailId: emailId, emailType: "PRIMARY" }],
          "dealingIn": dealingIn,
          "tradeLicenseNo": "",
          "companyCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector,
          "companyAnnualTurnover": annualTurnover,
          "typeOfTrade": typeOfTrade,
          "telephones": [{ country: country, phoneType: "PRIMARYMOBILE", telephone: telephoneOffice }],
          "country": country,
          "poBox": poBox,
          "taxRegistrationNo": "",
          "lobId": "AUTO",
          "orgId": "2001",
          "channelId": "EVENT"
        },
        "addresses": [headOfficeAddress],
        "organizationIds": customerTierType === 'Workshop' ? selectWorkshopOrgBrand : selectedBrands.filter((brand: any) => brand && brand.make !== 'Famco'),
        "stockOrderCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : initalStockOrderSelector,
        "initialStockOrder": initalStockOrder,
        "totalInvesmentCurrencyType": sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" : totalInvestmentSelector,
        "totalInvesment": totalInvestment,
        "otherBusinessEstablishments": otherBusinessEstablishments,
        "otherBrandsOrProductsSold": otherBrandsProductsSold,
        "treadReference": traderefe,
        "isSubmitted": false,
      }


      if (customerTierType === 'Workshop') {
        data.selectedBrand = selectedBrands.filter((brand: any) => brand)
      }
      const savedData: any = await dispatch(updateDealerData(sapCustomerId, data));
      if (savedData && savedData.message && savedData.message.status === 'S') {
        props.setDataUpdated(false);
        props.setselectedTab(1);
        props.setSuccessMessage("Customer details updated successfully")
        props.handleopenCloseSnackbar(true);
      } else {
        setOpenErrorSnackbar(true);
        handleopenCloseSnackbar(true);
        setSuccessMessage(savedData.error);
      }
    } else {
      setOpenErrorSnackbar(true);
      handleopenCloseSnackbar(true);
      setSuccessMessage('Please fill all mandatory fields and add atleast one Owner/Partner');
    }
  }

  // useEffect(() => {
  //   if (brandsList && brandsList.data && brandsList.data.length > 0) {
  //     let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
  //     setBrandList(data);
  //   }
  // }, [brandsList])

  // useEffect(() => {
  //   if (brandsList && brandsList.data && brandsList.data.length > 0) {
  //     let data: any = brandsList.data.filter((brand: any) => brand.displayName !== 'All');
  //     setBrandList(data);
  //   }
  // }, [brandsList])

  const handleClose = () => {
    setOpen(false);
  };

  const setOwnerData = (data: any) => {
    dispatch(savePartnerDetails(data));
  }

  const updateNewPartnerData = (data: any) => {
    dispatch(editPartnerDetails(data));
  }

  const onClickDeleteAddress = (partnerData: any) => {
    setTypeOfDelete('address')
    setDeleteMessage("Are you sure you want to delete this Owner/Partner ?");
    handleopenClose(true);
    setSelectedPartnerData(partnerData);
  }
  const onClickDeleteTradeRef = () => {
    setTypeOfDelete('trade')
    setDeleteMessage("Are you sure you want to delete this Trade Reference ?");
    handleopenClose(true);
  }

  const deletePartner = () => {
    dispatch(deletePartnerDetails(selectedPartnerData.id ? selectedPartnerData.id : sapCustomerId, selectedPartnerData.sapCustomerId));
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const selectAllBrands = () => {
    if (brandsList && brandsList.data && brandsList.data.length > 0) {
      let data: any = [];
      brandsList.data.map((brand: any) => data.push(brand.displayName));
      setSelectBrand(data);
    }
  }

  const handleSelectAllClick = (event: any, value: any) => {
    // setShowBrandsUpdatedDialog(true);
    if (event.target.checked && value === 'All') {
      selectAllBrands();
    } else if (event.target.checked === false && value === 'All') {
      setBrandList([]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsView(false);
  };

  const isDisabledTradeRow = () => {
    let tradeError = [...tradeReferenceErrors]
    let length = tradeReference.length - 1;
    tradeError[length].companyNameError = false;
    tradeError[length].nameError = false;
    tradeError[length].addressError = false;

    if (tradeReference.length && ((tradeReference[length].companyName == "" || tradeReference[length].name == "" || tradeReference[length].addresses == "" || tradeReference[length].telephone == ""))) {
      // if (tradeReference.length == 1) {
      if (tradeReference[length].companyName == "") {
        tradeError[length].companyNameError = true;
      }
      else {
        tradeError[length].companyNameError = false;
      }
      if (tradeReference[length].name == "") {
        tradeError[length].nameError = true;
      }
      else {
        tradeError[length].nameError = false;
      }
      if (tradeReference[length].addresses == "") {
        tradeReferenceErrors[length].addressError = true;
      }
      else {
        tradeReferenceErrors[length].addressError = false;
      }
      if (tradeReference[length].telephone == "") {
        tradeReferenceErrors[length].telephoneError = true;
      }
      else {
        tradeReferenceErrors[length].telephoneError = false;
      }
      setTradeReferenceErrors(tradeError)
      // }
      return true;
    }
    return false;
  }

  // FUNCTION CODE FOR ADD NEW ROW
  const addTradeRow = () => {
    let trade = [...tradeReference];
    let tradeError = [...tradeReferenceErrors];
    if (!isDisabledTradeRow()) {
      if (trade[trade.length - 1].companyName || trade[trade.length - 1].name || trade[trade.length - 1].addresses || trade[trade.length - 1].telephone) {
        const data = {
          companyName: "",
          name: "",
          addresses: "",
          telephone: ""
        }
        const errors =
        {
          companyNameError: false,
          companyNameErrorMessage: "",
          nameError: false,
          nameErrorMessage: "",
          addressError: false,
          addressErrorMessage: "",
          telephoneError: false,
          telephoneErrorMessage: "",
        }

        // validateDealerDetailsTradeReferance();
        trade.push(data);
        setTradeReference(trade);
        tradeError.push(errors)
        setTradeReferenceErrors(tradeError);
      }
    }
  }

  // FUNCTION CODE TO DELETE ROW
  const deleteTradeRow = (index: any) => {
    let trade: any = [...tradeReference];
    trade.splice(index, 1);
    let tradeError = [...tradeReferenceErrors]
    tradeError = trade.filter((data: any, i: any) => {
      if (i <= trade.length) {
        return data;
      }
    })
    setTradeReferenceErrors(tradeError);
    setTradeReference(trade);
    handleopenClose(false);
  }


  useEffect(() => {
    if ((checked === false || checked === true) && newlySelectedBrands && newlySelectedBrands) {
      setShowBrandsUpdatedDialog(true);
    }
  }, [checked, newlySelectedBrands]);

  function EnhancedTableHead(props: any) {
    const classes = useStyles();
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <>
              {/* {!isEditDealer && headCell.label === 'Action' ? '' : ( */}
              <TableCell
                className={classes.bodyContent}
                key={headCell.id}
                padding={headCell.disablePadding ? 'none' : 'normal'}
              >
                {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ?
                  headCell.label === "Nationality" ? "" && headCell.label : headCell.label : headCell.label}
              </TableCell>
              {/* )} */}
            </>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleEditPartnerDetails = (partner: any, isView: any) => {
    dispatch(getPartnerDetails(partner.id, sapCustomerId));
    handleClickOpen();
    setIsEdit(true);
    setIsView(isView);
    setEditData(partner);
  }

  const onChangeMobileNumber = (e: any) => {
    props.setDataUpdated(true);
    setMobileNumber(e.target.value)
    if (userType !== 'FSE' && customerAcceessForIB === false || sessionStorage.getItem('customerOnboardingType') !== "Int. Business") {
      if (e.target.value != "") {
        if (!validateForm(e.target.value, 'mobileno')) {
          setMobileNumberError(true);
        }
        else {
          setMobileNumberError(false);
        }
      }
      else {
        setMobileNumberError(false);
      }
    }
  }

  const handleChange = (event: any) => {
    setSelectedIBCurrency(event.target.value);
    setIbCurrency(event.target.value);
  };

  const onChangeBrand = (newBrands: any, existingBrands: any) => {
    setNewlySelectedBrands(newBrands);
  };


  return (
    <Fragment>
      <ReturnRequestSnackbar
        open={openSnackbar}
        type={openErrorSnackbar ? 'error' : ''}
        handleopenClose={() => {
          handleopenCloseSnackbar(false);
        }} message={successMessage} />
      {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
        <div className="d-flex flex-row border-bottom mb-2 pl-3">
          <div><h6 className="page-title pl-4 mt-3" style={{ fontSize: "15px" }}>Currency Type :</h6></div>
          <div className="row">
            <FormControl component="fieldset" name="customer-type" className="pl-4">
              <RadioGroup className="mt-2 ml-4" row onChange={handleChange} value={selectedIBCurrency}>
                <FormControlLabel
                  value={"AED"}
                  control={<Radio color="primary" />}
                  label="AED" disabled />
                <FormControlLabel
                  value={"USD"}
                  control={<Radio color="primary" />}
                  label="USD"
                  disabled
                />
              </RadioGroup>
              {ibCurrencyError && <span className="px-3" style={{ color: 'red' }}>{"Please select currency type"}</span>}
            </FormControl>
          </div>
          <div></div>
        </div>
        : ""}
      {dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ?
        <div className="card col-12">
          <div className="row px-2">
            <div className="col-12 mt-1">
              <div className="d-flex align-items-center">
                <h6 className="dealer-table-content px-3">Company Details</h6>
                <div className="row-space-start ml-auto">
                  <Tooltip title="Pull real-time information from SAP." placement="top">
                    <Button
                      className="mr-2 text-info"
                      startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                      onClick={async () => {
                        setLoading(true);
                        let data: any = await dispatch(getRefreshDataFromSAPCustomerDetails("refreshCustomerDetails", sapCustomerId, webApplicationLoginId, displayName.replace(/\s+/g, ' ')));
                        if (data && data.status === 400 || data && data.status === "E" || data && data.message && data.message.status === "E") {
                          setOpenErrorSnackbar(true);
                          handleopenCloseSnackbar(true);
                          setSuccessMessage(data.error);
                          setLoading(false);
                        } else {
                          loadPartnersOnClickOfRefresh();
                          setSuccessMessage(data && data.message && data.message.message)
                          handleopenCloseSnackbar(true);
                          setLoading(false);
                        }
                      }}
                    ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Refresh
                      </span>
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        : ""}
      <div className="row m-0 p-4">
        {dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ? "" :
          <div className="col-12">
            <h6 className="dealer-table-content mb-2">Company Details</h6>
          </div>}
        {loading && (<FullscreenLoader />)}
        {false ? (
          <>
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <>
                <div className="col-md-4 col-lg-4">
                  <TextField id="Country"
                    error={countryError}
                    disabled
                    helperText={invalidcountry}
                    value={country}
                    onChange={(e: any) => handleInputChange(e, setcountry, setcountryError, setInvalidcountry)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Country" required variant="outlined" margin="dense" />
                </div>
                <div className="col-md-4 col-lg-4">
                  <TextField id="ibSalesRegion"
                    value={salesRegion}
                    disabled
                    onChange={(e: any) => handleInputChange(e, setSalesRegion)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="IB Sales Region" required variant="outlined" margin="dense" >
                    {getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion.map((item: any) => (
                      <MenuItem key={item.salesRegion} value={item.salesRegion}>{item.salesRegion} </MenuItem>))}
                  </TextField>
                </div>
              </>
              : ""}
            <div className="col-md-4 col-lg-4">
              <TextField id="headOfficeRegion"
                error={headOfficeRegionError}
                helperText={invalidHeadOfficeRegion}
                disabled
                required
                value={selectedRegion && selectedRegion.Region ? selectedRegion.Region : headOfficeRegion}
                onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label={sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? "Trade Point Counter Location" : "Head Office Region"} variant="outlined" margin="dense">
              </TextField>
            </div>
            <div className="col-md-4 col-lg-4">
              <KeyboardDatePicker required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : true}
                disableFuture
                disabled={dealerstatus == "Active" ? true : false}
                views={["year", "month", "date"]}
                openTo="year"
                // disabled={true}
                autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                onError={(e, v) => datePickerValid(e, setInvalidDate)}
                format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
                onChange={(date: Date | null) => {
                  props.setDataUpdated(true);
                  if (date) {
                    setEstablishedDate(date);
                    setEstablishedDateError(false)
                  } else {
                    setEstablishedDateError(true)
                  }
                }}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                className="rounded-input-dealer w-100 mt-2 cal-icon"
                error={establishedDateError || invalidDate}
                helperText={invalidDate && <span style={{ color: 'red' }}>{error}</span>}
              />
            </div>
            {customer !== "Government" && <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 salestoCustomerKam">
              {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
                <TextField disabled defaultValue={ibCurrency} value={ibCurrency} onChange={(e: any) => handleInputChange(e, setSelectedIBCurrency)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined">
                  <MenuItem key={ibCurrency} value={ibCurrency}>{ibCurrency}</MenuItem>
                </TextField>
                :
                <TextField
                  // disabled
                  defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined">
                  {currencies.map(item => (
                    <MenuItem key={item} value={item}>{item}</MenuItem>))}
                </TextField>}
              <TextField id="annualTurnover" type="number"
                value={annualTurnover}
                disabled={isDisabled()}
                onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
                size="small" className="col-sm-9 commonRoundedInputs-textfield turnover-width"
                label="Annual Turnover" variant="outlined" margin="dense" />
            </div>}
            <div className="col-md-4 col-lg-4">
              <TextField select id="dealingIn"
                error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : dealingInError}
                helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidDealingIn}
                // disabled={isDisabled()}
                required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : customer !== "Government" ? true : false}
                value={dealingIn}
                onChange={(e: any) => {
                  handleInputChange(e, setDealingIn,
                    setDealingInError, setInvalidDealingIn)
                }}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label="Type Of Ownership" variant="outlined" margin="dense" >
                {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                  dealingInData.data[0].regions.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
              </TextField>
            </div>
            <div className="col-md-4 col-lg-4">
              <TextField select id="typeOfTrade"
                // error={typeOfTradeError}
                // helperText={invalidTypeOfTrade}
                // disabled={isDisabled()}
                disabled
                value={typeOfTrade}
                onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                // setTypeOfTradeError, setInvalidTypeOfTrade)}
                size="small" className="col-sm-12 my-2 commonRoundedInputs"
                label={!isAddedByFse && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? "Dealing In" : "Type of Trade"} required variant="outlined" margin="dense">
                {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                  <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
              </TextField>
            </div>
            {selectedDealerInfo && (
              <div className="row m-0 col-12 mt-3">
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">Company Name Line 1</div>
                  <div className="subTitle mt-2">{selectedDealerInfo.title}{' '}{selectedDealerInfo.customerFirstName ? selectedDealerInfo.customerFirstName : selectedDealerInfo.companyDetails && selectedDealerInfo.companyDetails.companyName ? selectedDealerInfo.companyDetails.companyName : '-'}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">Company Name Line 2</div>
                  <div className="subTitle mt-2">{selectedDealerInfo.customerLastName ? selectedDealerInfo.customerLastName : '-'}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12"> VAT Registration No</div>
                  <div className="subTitle mt-2">-</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="info-sub-title-value font-size-12">Contact Telephone Number</div> : <div className="info-sub-title-value font-size-12">Telephone Office(Landline No.)</div>}
                  <div className="subTitle mt-2">{selectedDealerInfo.telephones && selectedDealerInfo.telephones.length > 0 ? selectedDealerInfo.telephones[0].telephone : selectedDealerInfo && selectedDealerInfo.companyDetails && selectedDealerInfo.companyDetails.telephones && selectedDealerInfo.companyDetails.telephones.length > 0 && selectedDealerInfo.companyDetails.telephones[0].telephone ? selectedDealerInfo.companyDetails.telephones[0].telephone : '-'}</div>
                </div>
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">Customer Email</div>
                  <div className="subTitle mt-2">{selectedDealerInfo.emails && selectedDealerInfo.emails.length > 0 ? selectedDealerInfo.emails[0].emailId : selectedDealerInfo && selectedDealerInfo.companyDetails && selectedDealerInfo.companyDetails.emails && selectedDealerInfo.companyDetails.emails.length > 0 && selectedDealerInfo.companyDetails.emails[0].emailId ? selectedDealerInfo.companyDetails.emails[0].emailId : '-'}</div>
                </div>
                {selectedDealerInfo.addresses && selectedDealerInfo.addresses.length > 0 && (
                  <>
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">PO Box</div>
                      <div className="subTitle mt-2">{selectedDealerInfo.addresses[0].poBox ? selectedDealerInfo.addresses[0].poBox : '-'}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">Street Name</div>
                      <div className="subTitle mt-2">{selectedDealerInfo.addresses[0].streetName ? selectedDealerInfo.addresses[0].streetName : '-'}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">Country</div>
                      <div className="subTitle mt-2">{selectedDealerInfo.addresses[0].country ? selectedDealerInfo.addresses[0].country : '-'}</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3 mb-4">
                      <div className="info-sub-title-value font-size-12">City</div>
                      <div className="subTitle mt-2">{selectedDealerInfo.addresses[0].city ? selectedDealerInfo.addresses[0].city : '-'}</div>
                    </div>
                    {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" :
                      <div className="col-3 col-md-3 col-sm-3 mb-4">
                        <div className="info-sub-title-value font-size-12">Postal Code</div>
                        <div className="subTitle mt-2">{selectedDealerInfo.addresses[0].postalCode ? selectedDealerInfo.addresses[0].postalCode : '-'}</div>
                      </div>}
                  </>
                )}
                <div className="col-3 col-md-3 col-sm-3 mb-4">
                  <div className="info-sub-title-value font-size-12">Al-Futtaim Customer Number</div>
                  <div className="subTitle mt-2">{selectedDealerInfo.customerCode ? selectedDealerInfo.customerCode : selectedDealerInfo.sapCustomerId}</div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField required id="companyName"
                  value={companyName}
                  error={companyNameError}
                  disabled
                  onChange={(e: any) => handleInputChange(e, setCompanyName, setCompanyNameError, setInvalidCompanyName)}
                  label="Company Name Line 1" className="commonRoundedInputs w-100"
                  helperText={invalidCompanyName && <span style={{ color: 'red' }}>{ValidationErrorMessage.COMPANY_NAME_TEXT}</span>}
                  inputProps={{ maxLength: 40 }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                    endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                  }} margin="dense" variant="outlined" />
              ) : (
                <>
                  <div className="info-sub-title">Company Name Line 1 </div>
                  <div className="info-sub-title-value mt-2 mb-2">{companyName ? companyName : '-'}</div>
                </>
              )}
            </div>
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="companyNameLine2"
                  value={customerLastName}
                  disabled
                  inputProps={{ maxLength: 40 }}
                  onChange={(e: any) => handleInputChange(e, setCustomerLastName)}
                  label="Company Name Line 2" className="commonRoundedInputs w-100"
                  InputProps={{
                    // startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                    endAdornment: <Tooltip title="As per trade license" placement="top"><ErrorIcon /></Tooltip>,
                  }} margin="dense" variant="outlined" />
              ) : (
                <>
                  <div className="info-sub-title">Company Name Line 2 </div>
                  <div className="info-sub-title-value mt-2 mb-2">{customerLastName ? customerLastName : '-'}</div>
                </>
              )}
            </div>
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="vatRegistrationNumber"
                  value={vatRegistrationNumber}
                  error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || !isAddedByFse && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? false : vatRegistrationNumberError}
                  disabled
                  helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidVatRegistrationNumber && <span style={{ color: 'red' }}>{ValidationErrorMessage.VAT_TEXT}</span>}
                  onChange={(e: any) => handleInputChange(e, setVatRegistrationNumber, setVatRegistrationNumberError, setInvalidVatRegistrationNumber)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="VAT Registration Number" required={customer === "Workshop" || sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : true} variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title">VAT Registration Number</div>
                  <div className="info-sub-title-value mt-2">{vatRegistrationNumber ? vatRegistrationNumber : '-'}</div>
                </>
              )}
            </div>
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="telephoneOffice"
                  error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : telephoneOfficError}
                  disabled
                  helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidTelephoneOffice && <span style={{ color: 'red' }}>{ValidationErrorMessage.LANDLINE_PHONE_TEXT}</span>}
                  onChange={(e: any) => handleInputChange(e, setTelephoneOffice, setTelephoneOfficeError, setInvalidTelephoneOffice)}
                  value={telephoneOffice} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? "Contact Telephone Number" : "Telephone Office(Landline No.)"} required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : true} variant="outlined" margin="dense" />
              ) : (
                <>
                  {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="info-sub-title">Contact Telephone Number</div> : <div className="info-sub-title">Telephone Office(Landline No.)</div>}
                  <div className="info-sub-title-value mt-2">{telephoneOffice ? telephoneOffice : '-'}</div>
                </>
              )}
            </div>
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                {isEditDealer ? (
                  <TextField id="mobileno"
                    type="number"
                    required
                    error={mobileNumberError}
                    disabled={isDisabled() || dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId}
                    onChange={(e: any) => onChangeMobileNumber(e)}
                    value={mobileNumber} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Mobile Number" variant="outlined" margin="dense" />
                ) : (
                  <>
                    {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
                      <>
                        <div className="info-sub-title mt-3">Mobile Number</div>
                        <div className="info-sub-title-value mt-2">{mobileNumber ? mobileNumber : '-'}</div>
                      </>
                      : ''}
                  </>
                )}
              </div>
              : ""}
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <KeyboardDatePicker required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : customer !== "Government" ? true : false}
                  disableFuture
                  disabled={dealerstatus == "Active" ? false : true}
                  // disabled
                  views={["year", "month", "date"]}
                  openTo="year"
                  autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                  onError={(e, v) => datePickerValid(e, setInvalidDate)}
                  format="dd/MM/yyyy" margin="normal" label="Established Date" value={establishedDate}
                  onChange={(date: Date | null) => {
                    props.setDataUpdated(true);
                    if (date || date === null) {
                      setEstablishedDate(date);
                      setEstablishedDateError(false)
                    } else {
                      setEstablishedDateError(true)
                    }
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className="rounded-input-dealer w-100 mt-2 cal-icon"
                  error={customer === "Government" && establishedDate === "" ? false : establishedDateError || invalidDate}
                  helperText={invalidDate && <span style={{ color: 'red' }}>{customer === "Government" && establishedDate === "" ? "" : error}</span>}
                />
              ) : (
                <>
                  <div className="info-sub-title mt-3">Established Date</div>
                  <div className="info-sub-title-value mt-2">{establishedDate ? moment(establishedDate).format('DD MMM YYYY') : '-'}</div>
                </>
              )}
            </div>
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                {isEditDealer ? (
                  <TextField id="Country"
                    error={countryError}
                    disabled
                    helperText={invalidcountry}
                    value={country}
                    onChange={(e: any) => handleInputChange(e, setcountry, setcountryError, setInvalidcountry)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Country" required variant="outlined" margin="dense" />
                ) : (
                  <>
                    <div className="info-sub-title mt-3">Country</div>
                    <div className="info-sub-title-value mt-2">{country ? country : '-'}</div>
                  </>
                )}
              </div>
              : ''}
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <div className="col-md-4 col-lg-4">
                {isEditDealer ? (
                  <TextField id="ibSalesRegion"
                    value={salesRegion}
                    disabled
                    onChange={(e: any) => handleInputChange(e, setSalesRegion)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="IB Sales Region" required variant="outlined" margin="dense" >
                    {getIBCountryAndRegionData && getIBCountryAndRegionData.data && getIBCountryAndRegionData.data.countryAndSalesRegion.map((item: any) => (
                      <MenuItem key={item.salesRegion} value={item.salesRegion}>{item.salesRegion} </MenuItem>))}
                  </TextField>
                ) : (
                  <>
                    {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
                      <>
                        <div className="info-sub-title mt-3">IB Sales Region</div>
                        <div className="info-sub-title-value mt-2">{salesRegion ? salesRegion : '-'}</div>
                      </>
                      : ''}
                  </>
                )}
              </div>
              : ""}
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="headOfficeRegion"
                  error={headOfficeRegionError}
                  helperText={invalidHeadOfficeRegion}
                  disabled
                  required
                  value={selectedRegion && selectedRegion.Region ? selectedRegion.Region : headOfficeRegion}
                  onChange={(e: any) => handleInputChange(e, setHeadOfficeRegion, setHeadOfficeRegionError, setInvalidHeadOfficeRegion)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? "Trade Point Counter Location" : "Head Office Region"} variant="outlined" margin="dense">
                </TextField>
              ) : (
                <>
                  {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || customerTierType === "Workshop" || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="info-sub-title mt-3">Trade Point Counter Location</div> : <div className="info-sub-title mt-3">Head Office Region</div>}
                  <div className="info-sub-title-value mt-2">{selectedRegion && selectedRegion.Region ? selectedRegion.Region : headOfficeRegion ? headOfficeRegion : '-'}</div>
                </>
              )}
            </div>
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="dealerEmail"
                  error={dealerEmailError}
                  disabled
                  value={dealerEmail}
                  helperText={invalidDealerEmail && <span style={{ color: 'red' }}>{ValidationErrorMessage.EMAIL_TEXT}</span>}
                  onChange={(e: any) => handleInputChange(e, setDealerEmail, setDealerEmailError, setInvalidDealerEmail)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Customer Email" required variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title mt-3">Customer Email</div>
                  <div className="info-sub-title-value mt-2">{dealerEmail}</div>
                </>
              )}
            </div>
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField select id="dealingIn"
                  error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : dealingInError}
                  helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidDealingIn}
                  value={dealingIn}
                  disabled={dealerstatus == "Active" ? false : true}
                  // disabled
                  onChange={(e: any) => { handleInputChange(e, setDealingIn, setDealingInError, setInvalidDealingIn) }}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Type Of Ownership" required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : customer !== "Government" ? true : false} variant="outlined" margin="dense" >
                  {dealingInData && dealingInData.data && dealingInData.data.length > 0 &&
                    dealingInData.data[0].regions.map((item: any) => (
                      <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              ) : (
                <>
                  <div className="info-sub-title mt-3">Dealing In</div>
                  <div className="info-sub-title-value mt-2">{getDealingInData(dealingIn) ? getDealingInData(dealingIn) : '-'}</div>
                </>
              )}
            </div>
            {customer !== "Government" && <div className="col-sm-4 col-md-4 col-lg-4 mb-2 pr-0 salestoCustomerKam">
              {isEditDealer ? (
                <>
                  {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
                    <TextField disabled defaultValue={ibCurrency} value={ibCurrency} onChange={(e: any) => handleInputChange(e, setSelectedIBCurrency)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined">
                      <MenuItem key={ibCurrency} value={ibCurrency}>{ibCurrency}</MenuItem>
                    </TextField>
                    :
                    <TextField disabled defaultValue={currencies[0]} value={annualTurnoverSelector} onChange={(e: any) => handleInputChange(e, setAnnualTurnoverSelector)} select id="annualTurnoverSelector" className="commonRoundedInputs-dropdown aed-width" margin="dense" variant="outlined">
                      {currencies.map(item => (
                        <MenuItem key={item} value={item}>{item}</MenuItem>))}
                    </TextField>}
                  <TextField id="annualTurnover" type="number"
                    value={annualTurnover}
                    disabled={dealerstatus == "Active" ? false : true}
                    // disabled
                    // error={annualTurnoverSelectorError}
                    // helperText={invalidAnnualTurnover}
                    onChange={(e: any) => handleInputChange(e, setAnnualTurnover)}
                    size="small" className="col-sm-9 commonRoundedInputs-textfield turnover-width"
                    label="Annual Turnover" variant="outlined" margin="dense" />
                </>
              ) : (
                <>
                  <div className="info-sub-title mt-3">Annual Turnover</div>
                  <div className="info-sub-title-value mt-2">{sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? ibCurrency : annualTurnoverSelector}{' '}{annualTurnover ? annualTurnover : '-'}</div>
                </>
              )}
            </div>}
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="typeOfTrade"
                  // error={typeOfTradeError}
                  // helperText={invalidTypeOfTrade}
                  value={typeOfTrade}
                  select
                  // disabled={dealerstatus == "Active" ? false : true}
                  disabled
                  onChange={(e: any) => handleInputChange(e, setTypeOfTrade)}
                  // setTypeOfTradeError, setInvalidTypeOfTrade)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label={!isAddedByFse && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? "Dealing In" : "Type of Trade"} required variant="outlined" margin="dense">
                  {typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0 && typeofTradeData.data[0].tradeType.map((item: any) => (
                    <MenuItem key={item.id} value={item.id}>{item.title}</MenuItem>))}
                </TextField>
              ) : (
                <>
                  <div className="info-sub-title mt-3">{!isAddedByFse && sessionStorage.getItem('customerOnboardingType') === "Workshop" ? "Dealing In" : "Type of Trade"}</div>
                  <div className="info-sub-title-value mt-2">{getTypeOfTradeData(typeOfTrade) ? getTypeOfTradeData(typeOfTrade) : '-'}</div>
                </>
              )}
            </div>
            {sessionStorage.getItem('customerOnboardingType') === 'Workshop' &&
              <div className="col-md-4 col-lg-4">
                {isEditDealer ? (
                  <TextField id="poBox"
                    disabled
                    helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidPoBox}
                    value={poBox}
                    onChange={(e: any) => handleInputChange(e, setPoBox, setInvalidPoBox)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="PO Box" required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerOnboardingType') === "Workshop" ? false : true} variant="outlined" margin="dense" />
                ) : (
                  <>
                    <div className="info-sub-title mt-3">PO Box</div>
                    <div className="info-sub-title-value mt-2">{poBox ? poBox : '-'}</div>
                  </>
                )}
              </div>
            }
            {customer !== "Workshop" &&
              <div className="col-md-4 col-lg-4">
                {isEditDealer ? (
                  <TextField id="poBox"
                    error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : poBoxError}
                    disabled
                    helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidPoBox}
                    value={poBox}
                    onChange={(e: any) => handleInputChange(e, setPoBox, setPoBoxError, setInvalidPoBox)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="PO Box" required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" || sessionStorage.getItem('customerOnboardingType') === "Workshop" ? false : true} variant="outlined" margin="dense" />
                ) : (
                  <>
                    <div className="info-sub-title mt-3">PO Box</div>
                    <div className="info-sub-title-value mt-2">{poBox ? poBox : '-'}</div>
                  </>
                )}
              </div>
            }
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="Street Name"
                  error={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : streetNameError}
                  disabled
                  helperText={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : invalidstreetName}
                  value={streetName}
                  onChange={(e: any) => handleInputChange(e, setstreetName, setstreetNameError, setInvalidstreetName)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Street Name" required={sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? false : true} variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title mt-3">Street Name</div>
                  <div className="info-sub-title-value mt-2">{streetName ? streetName : '-'}</div>
                </>
              )}
            </div >
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="City"
                  error={cityError}
                  disabled
                  helperText={invalidcity}
                  value={city}
                  onChange={(e: any) => handleInputChange(e, setcity, setcityError, setInvalidcity)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="City" required variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title mt-3">City</div>
                  <div className="info-sub-title-value mt-2">{city ? city : '-'}</div>
                </>
              )
              }
            </div >
            {sessionStorage.getItem('customerOnboardingType') === "Int. Business" ? "" :
              <div className="col-md-4 col-lg-4">
                {isEditDealer ? (
                  <TextField id="Country"
                    error={countryError}
                    disabled
                    helperText={invalidcountry}
                    value={country}
                    onChange={(e: any) => handleInputChange(e, setcountry, setcountryError, setInvalidcountry)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Country" required variant="outlined" margin="dense" />
                ) : (
                  <>
                    <div className="info-sub-title mt-3">Country</div>
                    <div className="info-sub-title-value mt-2">{country ? country : '-'}</div>
                  </>
                )}
              </div >
            }
            {sessionStorage.getItem('customerOnboardingType') !== 'Workshop' && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && customer !== "Government" && customer !== "Fleet" && userType && <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="Postal Code"
                  error={postalCodeError}
                  disabled
                  helperText={invalidpostalCode}
                  value={postalCode}
                  onChange={(e: any) => handleInputChange(e, setpostalCode, setpostalCodeError, setInvalidpostalCode)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Postal Code" required variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title mt-3">Postal Code</div>
                  <div className="info-sub-title-value mt-2">{postalCode ? postalCode : '-'}</div>
                </>
              )}
            </div>}
            {sessionStorage.getItem('customerOnboardingType') === 'Workshop' && userType && <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="Postal Code"
                  // error={postalCodeError}
                  disabled
                  helperText={invalidpostalCode}
                  value={postalCode}
                  onChange={(e: any) => handleInputChange(e, setpostalCode, setInvalidpostalCode)}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Postal Code" variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title mt-3">Postal Code</div>
                  <div className="info-sub-title-value mt-2">{postalCode ? postalCode : '-'}</div>
                </>
              )}
            </div>}
            <div className="col-md-4 col-lg-4">
              {isEditDealer ? (
                <TextField id="Al-Futtaim Customer Number"
                  disabled
                  value={sapCustomerId}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="Al-Futtaim Customer Number" required variant="outlined" margin="dense" />
              ) : (
                <>
                  <div className="info-sub-title mt-3">Al-Futtaim Customer Number</div>
                  <div className="info-sub-title-value mt-2">{sapCustomerId ? sapCustomerId : '-'}</div>
                </>
              )}
            </div >
          </>
        )}
        <div className="row col-12 mt-2 mb-2 pl-2 pr-0 border-top">
          <div className="col-lg-4 mt-3">
            <h6 className="dealer-table-content mb-2">Owner/Partners Details</h6>
          </div>
          <div className="col-md-8 bg-white pb-0 pl-lg-10 mt-3">
            {isEditDealer && (
              <Button variant="contained" color="primary"
                className="rounded-button-dealer ml-3 w-200p float-right"
                // disabled={isDisabled()}
                disabled={dealerstatus == "Active" ? false : true}
                // disabled
                onClick={() => {
                  handleClickOpen();
                  //setIsEdit(false);
                  setTypeOfDailog('add')
                }}>Add Owner/Partner
              </Button>
            )}
          </div>
          {getAllPartnersData && getAllPartnersData.data && getAllPartnersData.data.length > 0 && (
            <div className="row col-12 mt-2 mb-2 pl-2 border-top pr-0">
              <div className="col-lg-12 mt-3 pr-0">
                <TableContainer>
                  <Table aria-labelledby="tableTitle" aria-label="enhanced table">
                    <EnhancedTableHead numSelected={selected.length} />
                    <TableBody>
                      <>
                        {getAllPartnersData.data.map((newPartnerData: any, index: any) => (
                          <TableRow hover tabIndex={-1} key={index}>
                            <TableCell component="th"
                              scope="row" padding="none" className="document-tablecell-data">
                              {newPartnerData.firstName}{'  '}{newPartnerData.lastName}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.email}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.mobile}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.nationality}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.designation}
                            </TableCell>
                            <TableCell align="left" className="document-tablecell-data">
                              {newPartnerData.emiratesIdNumber}
                            </TableCell>
                            <TableCell align="left">
                              <div className="row">
                                <ButtonGroup variant="text" color="primary" aria-label="text primary button group text-capitalize">
                                  <Button
                                    startIcon={<Visibility />}
                                    onClick={() => {
                                      setTypeOfDailog('view')
                                      handleEditPartnerDetails(newPartnerData, true);
                                    }}>
                                    View
                                  </Button>
                                </ButtonGroup>
                                {isEditDealer && (
                                  <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                                    {isAdmin && (
                                      <Button
                                        className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                                        disabled={dealerstatus == "Active" ? false : true}
                                        // disabled
                                        startIcon={<EditOutlinedIcon />}
                                        onClick={() => {
                                          setTypeOfDailog('edit')
                                          handleEditPartnerDetails(newPartnerData, false);
                                        }}>
                                        Edit
                                      </Button>
                                    )}
                                    {isAdmin && (
                                      <Button
                                        onClick={() => { onClickDeleteAddress(newPartnerData); }}
                                        // className="disabledCustomclass"
                                        disabled={dealerstatus == "Active" ? false : true}
                                        // disabled
                                        startIcon={<DeleteOutlineOutlinedIcon />}
                                      >
                                        Delete
                                      </Button>
                                    )}
                                  </ButtonGroup>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))
                        }
                      </>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}
          <Dialog className="pb-0"
            open={open}
            onClose={(event, reason) => {
              if (reason !== 'backdropClick') {
                handleClose()
              }
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md"
            disableEscapeKeyDown>
            <AddNewOwnerPartnerDialog
              setOpenDialog={handleClose} setOwnerData={setOwnerData}
              updateNewPartnerData={updateNewPartnerData}
              isView={isView} typeofDailog={typeofDailog}
            />
          </Dialog>
        </div>
        <div className="row col-12  mt-2 mb-3 pl-3 border-top">
          <div className="col-12 mt-3">
            <h6 className="dealer-table-content mb-2">Brands</h6>
          </div>
          {isNewCustomer ? (
            <>
              <div className="col-md-8 col-lg-8">
                {isEditDealer ? (
                  <FormControl className="commonRoundedInputs w-100 mt-2" size="small" variant="outlined">
                    <InputLabel id="companyBrandsLabel">Select Brand *</InputLabel>
                    <Select
                      multiple
                      required
                      variant="outlined"
                      value={selectBrand}
                      error={selectBrandError}
                      onChange={(e: any) => {
                        onChangeBrand(e.target.value, selectBrand)
                      }
                      }
                      input={<OutlinedInput label="Select Brand" />}
                      renderValue={(selected: any) => selected.join(', ')}>
                      {orgIds && orgIds.length > 0 && orgIds.map((brand: any, i: any) => (
                        <MenuItem key={i} value={brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)}
                        >
                          <Checkbox onChange={(e: any) => {
                            if (e.target.checked) {
                              setChecked(true);
                            } else {
                              setChecked(false);
                            }
                          }}
                            checked={selectBrand && selectBrand.includes(brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)) ? true : false}
                          />
                          {/* {getNewBrand(brand.make)} */}
                          <p className="pr-2"> {brand.organizationId}</p><p className="pr-2">{"-"}</p>
                          <span className={selectBrand && selectBrand.includes(brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)) ? "brandsButton-content" : ""}>{brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    {selectBrand.map((org: any, index: any) => (
                      <Button key={index} onClick={() => {
                      }}
                        className="brandsButton mr-2 cursor-default"
                        variant="contained">
                        {getNewBrand(org) === 'Tradepoint' && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(org)}
                      </Button>
                    ))}
                  </>
                )}
              </div>
              {isEditDealer && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && customer !== "Government" && userType && customer !== "Workshop" ? (
                <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                  <TextField select id="creditLimitSelector"
                    error={creditLimitSelectorError}
                    disabled={dealerstatus == "Active" ? false : true}
                    value={initalStockOrderSelector} defaultValue={currencies[0]}
                    onChange={(e: any) => handleInputChange(e, setInitalStockOrderSelector, setCreditLimitSelectorError, setInvalidCreditLimitSelector)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                </div>
              ) : (
                <>
                  <div>
                  </div>
                </>
              )}
              {isEditDealer && customer !== "Government" && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && userType && customer !== "Workshop" ? (
                <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                  <TextField id="creditLimit"
                    error={creditLimitError}
                    helperText={invalidCreditLimit}
                    value={initalStockOrder}
                    disabled={dealerstatus == "Active" ? false : true}
                    onChange={(e: any) => handleInputChange(e, setInitalStockOrder, setCreditLimitError, setInvalidCreditLimit)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                    label="Initial Stock Order" variant="outlined" margin="dense" />
                </div>
              ) : customer !== "Government" && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && userType && customer !== "Workshop" && (
                <>
                  <div className="col-4">
                    <div className="info-sub-title mt-3">Initial Stock Order</div>
                    <div className="info-sub-title-value mt-2">{initalStockOrder ? initalStockOrder : '-'}</div>
                  </div>
                </>
              )}
              {isEditDealer && customer !== "Government" && userType && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && customer !== "Workshop" ? (
                <div className="col-sm-4 col-md-2 col-lg-1 mb-2 pr-0">
                  <TextField select id="totalInvestmentSelector"
                    error={totalInvestmentSelectorError}
                    value={totalInvestmentSelector} defaultValue={currencies[0]}
                    disabled={dealerstatus == "Active" ? false : true}
                    onChange={(e: any) => handleInputChange(e, setTotalInvestmentSelector, setTotalInvestmentError, setInvalidTotalInvestmentSelector)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" variant="outlined" margin="dense">
                    {currencies.map(item => (
                      <MenuItem key={item} value={item}>{item}</MenuItem>))}
                  </TextField>
                </div>
              ) : (
                <>
                  <div className="">
                  </div>
                </>
              )}
              {isEditDealer && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && customer !== "Government" && userType && customer !== "Workshop" ? (
                <div className="col-sm-4 col-md-4 col-lg-3 pr-3 mb-2 pl-0">
                  <TextField id="totalInvestment"
                    error={totalInvestmentError}
                    helperText={invalidTotalInvestment}
                    value={totalInvestment}
                    disabled={dealerstatus == "Active" ? false : true}

                    onChange={(e: any) => handleInputChange(e, setTotalInvestment, setTotalInvestmentError, setInvalidTotalInvestment)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                    label="Total Investment" variant="outlined" margin="dense" />
                </div>
              ) : customer !== "Government" && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && userType && customer !== "Workshop" && (
                <>
                  <div className="col-4">
                    <div className="info-sub-title mt-3">Total Investment</div>
                    <div className="info-sub-title-value mt-2">{totalInvestment ? totalInvestment : '-'}</div>
                  </div>
                </>
              )}
              {isEditDealer && customer !== "Government" && userType ? (
                <div className="col-md-4 col-lg-4">
                  <TextField id="otherBusinessEstablishments"
                    // error={otherBusinessEstablishmentsError}
                    // helperText={otherBusinessEstablishmentsError && otherBusinessEstablishments.length > 0 && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                    value={otherBusinessEstablishments}
                    disabled={dealerstatus == "Active" ? false : true}
                    onChange={(e: any) => handleInputChange(e, setOtherBusinessEstablishments)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Other Business Establishments" variant="outlined" margin="dense" />
                </div>
              ) : customer !== "Government" && userType && (
                <>
                  <div className="col-4">
                    <div className="info-sub-title mt-3">Other Business Establishments</div>
                    <div className="info-sub-title-value mt-2">{otherBusinessEstablishments ? otherBusinessEstablishments : '-'}</div>
                  </div>
                </>
              )}
              {isEditDealer && customer !== "Government" && userType ? (
                <div className="col-md-4 col-lg-4">
                  <TextField id="otherBrandsProductsSold"
                    disabled={dealerstatus == "Active" ? false : true}
                    // error={otherBrandsProductsSoldError}
                    // helperText={invalidOtherBrandsProductsSold && <span style={{ color: 'red' }}>{ValidationErrorMessage.ALPHA_NUMERIC_TEXT}</span>}
                    value={otherBrandsProductsSold}
                    onChange={(e: any) => handleInputChange(e, setOtherBrandsProductsSold, setOtherBrandsProductsSoldError, setInvalidOtherBrandsProductsSold)}
                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                    label="Other Brands/Products Sold" variant="outlined" margin="dense" />
                </div>
              ) : customer !== "Government" && userType && (
                <>
                  <div className="col-4">
                    <div className="info-sub-title mt-3">Other Brands/Products Sold</div>
                    <div className="info-sub-title-value mt-2">{otherBrandsProductsSold ? otherBrandsProductsSold : '-'}</div>
                  </div>
                </>
              )}
            </>
          ) :
            (
              // <>
              //   {selectBrand.map((org: any, index: any) => (
              //     <Button key={index} onClick={() => {
              //     }}
              //       className="brandsButton mr-2 cursor-default"
              //       variant="contained">
              //       {getNewBrand(org)}
              //     </Button>
              //   ))}
              // </>
              <>
                {isEditDealer ? (
                  <FormControl className="commonRoundedInputs w-100 mt-2" size="small" variant="outlined">
                    <InputLabel id="companyBrandsLabel">Select Brand *</InputLabel>
                    <Select
                      multiple
                      required
                      variant="outlined"
                      value={selectBrand}
                      error={selectBrandError}
                      onChange={(e: any) => {
                        onChangeBrand(e.target.value, selectBrand)
                      }
                      }
                      input={<OutlinedInput label="Select Brand" />}
                      renderValue={(selected: any) => selected.join(', ')}>
                      {orgIds && orgIds.length > 0 && orgIds.map((brand: any, i: any) => (
                        <MenuItem key={i} value={brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)}
                        >
                          <Checkbox onChange={(e: any) => {
                            if (e.target.checked) {
                              setChecked(true);
                            } else {
                              setChecked(false);
                            }
                          }}
                            checked={selectBrand && selectBrand.includes(brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)) ? true : false}
                          />
                          {/* {getNewBrand(brand.make)} */}
                          <p className="pr-2"> {brand.organizationId}</p><p className="pr-2">{"-"}</p>
                          <span className={selectBrand && selectBrand.includes(brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)) ? "brandsButton-content" : ""}>{brand.make === "OES" && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(brand.make)}</span>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <>
                    {selectBrand.map((org: any, index: any) => (
                      <Button key={index} onClick={() => {
                      }}
                        className="brandsButton mr-2 cursor-default"
                        variant="contained">
                        {getNewBrand(org) === 'Tradepoint' && customerTierType && customerTierType.toLowerCase() !== "workshop" ? 'ALAC' : getNewBrand(org)}
                      </Button>
                    ))}
                  </>
                )}
              </>
            )}
        </div>
        {isNewCustomer === false ? '' : customer !== "Government" && userType && customer !== "Workshop" && (

          <>
            <div className="row col-md-12 m-0 border-top pl-3" >
              <h6 className="dealer-table-content mb-2 mt-4">Trade Reference</h6>
            </div>
            {tradeReference.map((tradeItem: any, index: any) => {
              return (
                <div className="col-md-12 m-0" key={index}>
                  {isEditDealer && (
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          value={tradeItem.name}
                          required
                          type="text"
                          name={tradeItem.name + index}
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].nameError}
                          disabled={dealerstatus == "Active" ? false : true}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Name" variant="outlined" margin="dense"
                          onChange={(e: any) => {
                            handleInputChangetradeRef(e, 'name', index)
                          }}
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          value={tradeItem.companyName}
                          required
                          type="text"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError}
                          name={tradeItem.companyName + index}
                          disabled={dealerstatus == "Active" ? false : true}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">M/s</InputAdornment>,
                          }}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index] && tradeReferenceErrors[index].companyNameErrorMessage}</span>}

                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Company Name" variant="outlined" margin="dense"
                          onChange={(e: any) => {
                            handleInputChangetradeRef(e, 'companyName', index)
                          }}
                        />
                      </div>
                    </div>
                  )}
                  {isEditDealer && (
                    <div className="row p-0 m-0">
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          value={tradeItem.addresses}
                          type="text"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].addressError}
                          // name={tradeItem.address + index}
                          disabled={dealerstatus == "Active" ? false : true}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Address" variant="outlined" margin="dense"
                          onChange={(e: any) => {
                            handleInputChangetradeRef(e, 'addresses', index)
                          }}
                        />
                      </div>
                      <div className="col-md-4 col-lg-4">
                        <TextField
                          required
                          type="number"
                          error={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError}
                          disabled={dealerstatus == "Active" ? false : true}
                          helperText={tradeReferenceErrors[index] && tradeReferenceErrors[index].telephoneError &&
                            <span style={{ color: 'red' }}>{tradeReferenceErrors[index].telephoneErrorMessage}</span>}
                          value={tradeItem.telephones && tradeItem.telephones.length > 0 && tradeItem.telephones[index]?.telephone ? tradeItem.telephones && tradeItem.telephones.length > 0 && tradeItem.telephones[index]?.telephone : tradeItem && tradeItem?.telephone ? tradeItem && tradeItem?.telephone : ""}
                          // name={tradeItem.telephones && tradeItem.telephones.length > 0 && tradeItem.telephones[0].telephone + index}
                          size="small" className="col-sm-12 my-2 commonRoundedInputs"
                          label="Telephone" variant="outlined" margin="dense"
                          onChange={(e: any) => {
                            handleInputChangetradeRef(e, 'telephone', index)
                          }}
                        />
                      </div>
                      {isEditDealer && tradeReference.length !== 1 && (
                        <div className="col-md-4 col-lg-2">.
                          <IconButton disabled={dealerstatus == "Active" ? false : true}
                            className="mr-1 mt-n2" size="small" onClick={() => {
                              onClickDeleteTradeRef();
                              setTradeRowRefDeleteIndex(index)
                              // deleteTradeRow(index)
                            }}
                          >
                            <DeleteIcon
                              fontSize="inherit" />
                            <span className="font-size-13 load-from-fav ml-1 mt-0">
                              Delete
                            </span>
                          </IconButton>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )
            })}
            {!isEditDealer && (
              <div className="col-12 row mb-0 mt-0 px-2">
                <div className="col-12 mt-1 mb-1">
                  <div className="row m-0 col-12 mt-2 mb-3">
                    <div className="col-3 col-md-3 col-sm-3">
                      <div className="info-sub-title-value font-size-12">Name</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3">
                      <div className="info-sub-title-value font-size-12">Company Name</div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3">
                      <div className="info-sub-title-value font-size-12">Address </div>
                    </div>
                    <div className="col-3 col-md-3 col-sm-3">
                      <div className="info-sub-title-value font-size-12">Telephone</div>
                    </div>
                  </div>
                  {tradeReference.map((tradeItem: any, index: any) => {
                    return (
                      <div className="col-12 m-0 p-0 border-bottom">
                        <div className="row m-0">
                          <div className="col-3 col-md-3 col-sm-3 mt-2 mb-2">
                            <div className="subTitle mt-2">{tradeItem.name ? tradeItem.name : '-'}</div>
                          </div>
                          <div className="col-3 col-md-3 col-sm-3 mt-2 mb-2">
                            <div className="subTitle mt-2">{tradeItem.companyName ? tradeItem.companyName : '-'}</div>
                          </div>
                          <div className="col-3 col-md-3 col-sm-3 mt-2 mb-2">
                            <div className="subTitle mt-2">{tradeItem.addresses ? tradeItem.addresses : '-'}</div>
                          </div>
                          <div className="col-3 col-md-3 col-sm-3 mt-2 mb-2">
                            <div className="subTitle mt-2">{typeof tradeItem.telephone === 'string' ? tradeItem.telephone : tradeItem.telephones && tradeItem.telephones.length > 0 && tradeItem.telephones[0].telephone ? tradeItem.telephones[0].telephone : '-'}</div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            )}
            {customer !== "Government" && userType && <div className="row col-md-12 m-0">
              <div className="row-space-start">
                {isEditDealer && (
                  <Button
                    disabled={dealerstatus == "Active" ? false : true}
                    className="text-info"
                    startIcon={<AddIcon className="font-size-18" />}
                    onClick={() => addTradeRow()}
                  ><span className="onshiftoparty-font">Add More
                    </span>
                  </Button>
                )}
              </div>
            </div>}
          </>
        )}
      </div >
      <DeleteDialog
        open={openDeleteDialog}
        text={deleteMessage}
        type={typeOfDelete}
        handleopenClose={(data: any) => {
          if (data === 'delete') {
            deletePartner()
          }
          else if (data === 'tradeRef') {
            deleteTradeRow(tradeRowRefDeleteIndex)
          }
          if (!data) handleopenClose(false);
        }} />
      <BrandUpdatedDialog
        open={showBrandsUpdatedDialog}
        checked={checked}
        removedBrand={checked === false ? selectBrand.filter((brand: any) => !newlySelectedBrands.includes(brand)) : ''}
        handleopenClose={(data: any) => {
          if (data === 'success') {
            setSelectBrand(newlySelectedBrands);
            setShowBrandsUpdatedDialog(false);
          } else {
            setSelectBrand(selectBrand);
            setShowBrandsUpdatedDialog(false);
          }
        }}
      />
      {isEditDealer && (
        <ActionButtons
          openRequestSnackbar={true}
          deactiveShow={true}
          selectedDealerInfo={selectedDealerInfo}
          dealerStatus={dealerstatus}
          saveCallback={() => {
            saveDetails()
          }}
          data
        />
      )}
    </Fragment >
  )
}

export default DealerDetails