import React, { Dispatch, Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import "./AddUserDialog.css";
import {
  createDealerUser as addDealerUser,
  checkUserIdAvailability,
  updateDealerUser as updateDealerUserAction,
  getAllUsers,
  getAccessParam,
  addAccessParam,
  updateAccessParam,
  updateDealerInProgressUser,
  setUserDetails,
  getDealerDetails,
  getUserDetails,
  getKamAccessParam,
  postSetDefaultAddress
} from "../../store/actions/dealer.action";
import { FormControlLabel, Radio } from "@material-ui/core";
import { withStyles, Theme, createStyles, makeStyles, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ThemeProvider } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CountryList from '../../components/Dealers/CountryList.json';
import Loading from '../../common/components/loading';
import { get } from 'lodash';
// import Snackbar from './../../common/components/Snackbar';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import moment from "moment-timezone";
import { Messages, ValidationErrorMessage } from '../../constants/messages';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import _ from "lodash";

import { getRegion } from '../../store/actions/editDealer.actions';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 400,
  },
  input: {
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  submitButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  cancelButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: 1000,
    height: 35,
    textAlign: 'center',
    marginRight: 24,
  },
  close: {
    color: '#000000',
    fontWeight: 900,
    fontSize: 18
  }
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}

export default function AddUserDialog(props: any) {
  const [sapCustomerId, setSAPCustomerId] = React.useState<string>(props.sapCustomerId);
  // const [selectedUserId, setSelectedUserId] = React.useState<string>(props.selectedUserId);
  const classes = useStyles();
  const today: any = moment(new Date()).format("YYYY-MM-DD");
  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const shipToPartyFeatureAccess = get(profileDetails, 'data.data.customerInfo.shipToPartyFeatureAccess', '');
  const { createDealerUser, updateDealerUser, userDetailsInfo, accessParam, userDetails, accessParamAdd, accessParamUpdate }: any = useSelector((state: any) => state.dealer);
  const [adminUserId, setAdminUserId] = React.useState<string>("");
  const [adminUserIdError, setAdminUserIdError] = React.useState<boolean>(false);
  const [adminTitle, setAdminTitle] = React.useState<string>("");
  const [adminFirstName, setAdminFirstName] = React.useState<string>("");
  const [adminFirstNameError, setAdminFirstNameError] = React.useState<boolean>(false);
  const [adminLastName, setAdminLastName] = React.useState<string>("");
  const [adminLastNameError, setAdminLastNameError] = React.useState<boolean>(false);
  const [adminMobile, setAdminMobile] = React.useState<string>("");
  const [adminMobileError, setAdminMobileError] = React.useState<boolean>(false);
  const [adminEmail, setAdminEmail] = React.useState<string>("");
  const [adminEmailError, setAdminEmailError] = React.useState<boolean>(false);
  const [adminUserType, setAdminUserType] = React.useState<string>("User");
  const [adminUserTypeError, setAdminUserTypeError] = React.useState<boolean>(false);
  const [adminPhone2, setAdminPhone2] = React.useState<string>("");
  const [adminValidFrom, setAdminValidFrom] = React.useState<Date | null>(today);
  const [adminValidFromError, setAdminValidFromError] = React.useState<boolean>(false);
  const [adminValidTo, setAdminValidTo] = React.useState<Date | null>(new Date('9999-12-31'));
  const [adminValidToError, setAdminValidToError] = React.useState<boolean>(false);
  const [adminFax, setAdminFax] = React.useState<string>("");
  const [adminCountry, setAdminCountry] = React.useState<string>("");
  const [adminRegion, setAdminRegion] = React.useState<string>("");
  const [invalidEmail, setInvalidEmail] = React.useState<boolean>(false);
  const [invalidDate, setInvalidDate] = React.useState<boolean>(false);
  const [invalidMobile, setInvalidMobile] = React.useState<boolean>(false);
  const [invalidPhone2, setInvalidPhone2] = React.useState<boolean>(false);
  const [invalidFax, setInvalidFax] = React.useState<boolean>(false);
  const [invalidAdminFirstName, setInvalidAdminFirstName] = React.useState<boolean>(false);
  const [invalidAdminLastName, setInvalidAdminLastName] = React.useState<boolean>(false);
  const [lockIndicator, setLockIndicator] = React.useState<boolean>(false);
  const [lockReason, setLockReason] = React.useState<string>("");
  const [submitDisabled, setSubmitDisabled] = React.useState<boolean>(false);
  const [isUserIdValid, setIsUserIdValid] = React.useState<boolean>(false);
  const [userIdErrorText, setUserIdErrorText] = React.useState<string>("");
  const [userIdSuccessMessage, setUserIdSuccessMessage] = React.useState<string>("");
  const [isWebLoginIdDisabled, setIsWebLoginIdDisabled] = React.useState<boolean>(false);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [updatefeatureCode, setupdatefeatureCode] = React.useState<any>([]);
  const [userAddedSuccessfully, setUserAddedSuccessfully] = React.useState<any>(false);
  const [displayAddressTab, showAddressTab] = React.useState<any>(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [apiResponseText, setApiResponseText] = React.useState(false);
  const [newUserb2bId, setNewUserB2bId] = React.useState();
  const [isExistingDealer, setIsExistingDealer] = React.useState<any>(false);
  const [isCreatedUser, setIsCreatedUser] = React.useState<boolean>(false);
  const [isCancelCallAccess, setIsCancelCallAccess] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState<any>(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);

  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);

  const sapCustomerID = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', '');
  const adminId = get(profileDetails, 'data.data.basicInfo.adminId', '');
  const [openSnackbarType, setOpenSnackbarType] = React.useState("");
  let defaultAddressId: any = get(dealerDetails, 'data.data.defaultAddressId', '');
  const isAdmin = _.get(profileDetails, "data.data.basicInfo.userType", "");
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');


  useEffect(() => {
    setSAPCustomerId(props.sapCustomerId);
    dispatch(getRegion());
    if (props.isEdit === true) {
      setIsUserIdValid(true);
      setIsWebLoginIdDisabled(true);
    } else {
      setIsUserIdValid(false);
      setUserIdSuccessMessage("");
      setIsWebLoginIdDisabled(false);
    }
  }, [props.isEdit, props.selectedUserId, props.sapCustomerId]);

  useEffect(() => {
    if (accessParam && accessParam.data && accessParam.data.userAccessParameterData) {
      let data: any = [];
      if (accessParam && accessParam.data && accessParam.data.userAccessParameterData) {
        accessParam && accessParam.data && accessParam.data.userAccessParameterData.map((access: any) => {
          if (access.featureCode === 'MANAGE_USER' && userDetailsInfo && userDetailsInfo.data && userDetailsInfo.data.data && userDetailsInfo.data.data.userType !== 'Admin') {
            access.value = false;
          }
          if (access.featureCode == 'SELECT_DEFAULT_ADDRESS') {
            if (access.value) {
              showAddressTab(true);
            } else {
              showAddressTab(false);
            }
          }
          data.push(access);
        })
      }
      setupdatefeatureCode(data);
    }
  }, [accessParam]); // eslint-disable-line

  const userBasicInfo = get(profileDetails, 'data.data.basicInfo', "");

  useEffect(() => {
    if (props.isEdit === true && userDetailsInfo && userDetailsInfo.data) {
      populateUserDetails();
    }
    else {
      populateCountryAndRegion();
    }
  }, [userDetailsInfo, userBasicInfo]); // eslint-disable-line

  const isDisabled = () => {
    if (props.isView === true) {
      return true;
    } else {
      return false;
    }
  }

  const handleInputChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName && e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const handleDropdownChange = (e: any, setterName: any, errorSetterName?: any) => {
    setterName(e.target.value);
    if (errorSetterName) {
      if (e.target.value === "" || e.target.value.trim() === "")
        errorSetterName(true);
      else
        errorSetterName(false);
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const resetValues = () => {
    setInvalidEmail(false);
    setInvalidFax(false);
    setInvalidMobile(false);
    setInvalidPhone2(false);
    setInvalidDate(false);
    setInvalidAdminFirstName(false);
    setInvalidAdminLastName(false);
    setAdminUserId("");
    setAdminUserIdError(false);
    setUserIdErrorText("");
    setUserIdSuccessMessage("");
    setAdminTitle("");
    setAdminFirstName("");
    setAdminFirstNameError(false);
    setAdminLastName("");
    setAdminLastNameError(false);
    setAdminMobile("");
    setAdminMobileError(false);
    setAdminEmail("");
    setAdminEmailError(false);
    setAdminUserType("User");
    setAdminUserTypeError(false);
    setAdminPhone2("");
    setAdminValidFrom(today);
    setAdminValidFromError(false);
    setAdminValidTo(new Date('9999-12-31'));
    setAdminValidToError(false);
    setAdminFax("");
    setAdminCountry("");
    setAdminRegion("");
    setSubmitDisabled(false);
    setLockIndicator(false);
    setLockReason("");
  }

  const checkUserAvailability = async (e: any) => {
    if (validateAdminUserID(adminUserId) === false) {
      setSubmitDisabled(true);
      setIsUserIdValid(false);
      setUserIdSuccessMessage("");
      setAdminUserIdError(true);
      setUserIdErrorText("Cannot exceed 15 characters and must not contain spaces.");
    }
    else {
      if (adminUserId === "" || adminUserId.trim() === "") {
        setAdminUserIdError(true);
      }
      else {
        let postData = {
          "sapCustomerId": sapCustomerId,
          "webApplicationLoginId": adminUserId.trim()
        };
        let isUserAvailable: any = await dispatch(checkUserIdAvailability(postData));
        if (isUserAvailable && isUserAvailable.data === false) {
          setSubmitDisabled(false);
          setIsUserIdValid(true);
          setUserIdSuccessMessage("Id is available");
          setAdminUserIdError(false);
          setUserIdErrorText("");
        } else {
          setSubmitDisabled(true);
          setAdminUserIdError(true);
          setUserIdErrorText("Web Login Id already exists");
          setIsUserIdValid(false);
          setUserIdSuccessMessage("");
        }
      }
    }
  }
  const showMessage = (data: any, userCreated?: any) => {
    const apiResponse = get(data, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    }
  }

  const getUsers = () => {
    if (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.sapCustomerId) {
      dispatch(getAllUsers(profileDetails.data.data.basicInfo.sapCustomerId, props.page));
    }
  }

  const handleNextClick = async () => {
    if (isDisabled()) {
      if (adminUserType === "Admin" && userBasicInfo.b2bUserId === userDetails.b2bUserId) {
        closeAddUserDialog(false)
      } else {
        setselectedTab(1);
      }
    } else {
      let currentTab = selectedTab;
      setUserAddedSuccessfully(false);
      if (currentTab === 0) {
        if (validateUsersTab()) {
          if (!props.isEdit) {
            let newUserData: any;
            let addUsersData = getUsersTabData();
            setLoading(true);
            newUserData = await dispatch(addDealerUser(addUsersData));
            if (newUserData && newUserData.message && newUserData.message.status === 'S') {
              let data: any = await dispatch(getUserDetails(newUserData.data.b2bUserId));
              if (props.isEdit === false) setIsCancelCallAccess(true);
              dispatch(setUserDetails(data.data));
              setIsCreatedUser(true);
              props.setInProgress(true);
              props.setSelectedUserId(newUserData.data.b2bUserId);
              const newUserId: any = get(newUserData, 'data.b2bUserId', "");
              setNewUserB2bId(newUserId);
              dispatch(getAccessParam(newUserId, adminId ? adminId : userBasicInfo && userBasicInfo.adminId, props.isEdit ? 'edit' : 'add'));
              setUserAddedSuccessfully(true);
              showMessage(newUserData);
              if (adminUserType === "Admin" && userBasicInfo.b2bUserId === userDetails.b2bUserId) {
                setTimeout(() => {
                  closeAddUserDialog(false)
                }, 2000)
              } else {
                setLoading(false);
                dispatch(getDealerDetails(props.sapCustomerId ? props.sapCustomerId : sapCustomerId, 'shipToParty', userDetails?.webApplicationLoginId));
                setselectedTab(1);
              }
            }
          } else {
            let payload = getUsersTabDataForUpdate();
            let editedData;
            dispatch(getAccessParam(userDetails && userDetails.b2bUserId, adminId ? adminId : userBasicInfo && userBasicInfo.adminId, props.isEdit ? 'edit' : 'add'));
            if (props.inProgress) {
              setLoading(true);
              editedData = await dispatch(updateDealerInProgressUser(payload));
            }
            else {
              editedData = await dispatch(updateDealerUserAction(payload));
            }
            showMessage(editedData);
            if (adminUserType === "Admin" && userBasicInfo.b2bUserId === userDetails.b2bUserId) {
              setTimeout(() => {
                closeAddUserDialog(false)
              }, 2000)
            } else {
              setLoading(false);
              setselectedTab(1);
            }
          }
        }
      }
      else if (currentTab === 1) {
        if (!props.isEdit || isCreatedUser) {
          let data = {
            b2bUserId: newUserb2bId,
            sapCustomerId: props.sapCustomerId,
            featureAccessibility: updatefeatureCode
          }
          let userData = await dispatch(addAccessParam(data));
          let accessParamSuccess = get(userData, 'message.status', "").toLowerCase() === "s";
          props.setIsEdit(true);
          if (accessParamSuccess) {
            setUserAddedSuccessfully(true);
            showMessage(accessParamSuccess);
            handleopenCloseSnackbar(true);
            setOpenSnackbar(true);
            setLoading(true);
          }
          // if (displayAddressTab) {
          await dispatch(getDealerDetails(props.sapCustomerId ? props.sapCustomerId : sapCustomerId, 'shipToParty', userDetails?.webApplicationLoginId));
          setselectedTab(2);
          setLoading(false);
          setIsCreatedUser(false);
          // } else {
          //   setTimeout(() => {
          //     closeAddUserDialog(false)
          //     setIsCreatedUser(false);
          //   }, 2000)
          // }
        } else {
          if (userDetails.dataType === "masterData") {
            let data = {
              b2bUserId: createDealerUser && createDealerUser.data && createDealerUser.data.data && createDealerUser.data.data.b2bUserId,
              sapCustomerId: props.sapCustomerId,
              featureAccessibility: updatefeatureCode
            }
            let addParam = await dispatch(addAccessParam(data));
            showMessage(addParam);
            setLoading(true);
            // if (displayAddressTab) {
            await dispatch(getDealerDetails(props.sapCustomerId ? props.sapCustomerId : sapCustomerId, 'shipToParty', userDetails?.webApplicationLoginId));
            setLoading(false);
            setselectedTab(2);
            // } else {
            //   setTimeout(() => {
            //     closeAddUserDialog(false)
            //   }, 2000)
            // }
          } else {
            let data = {
              b2bUserId: userDetails && userDetails.b2bUserId,
              featureAccessibility: updatefeatureCode
            }
            let updateParam = await dispatch(updateAccessParam(data));
            showMessage(updateParam);
            setLoading(true);
            // if (displayAddressTab) {
            setLoading(false);
            await dispatch(getDealerDetails(props.sapCustomerId ? props.sapCustomerId : sapCustomerId, 'shipToParty', userDetails?.webApplicationLoginId));
            setselectedTab(2);
            // } else {
            //   setTimeout(() => {
            //     closeAddUserDialog(false)
            //   }, 2000)
            // }
          }
        }
      }
      else {
        closeAddUserDialog();
      }
    }
  }

  const closeAddUserDialog = async (showSnackbar?: boolean) => {
    if (isCreatedUser && isCancelCallAccess) {
      let data = {
        b2bUserId: newUserb2bId,
        sapCustomerId: props.sapCustomerId,
        featureAccessibility: updatefeatureCode
      }
      await dispatch(addAccessParam(data));
      setselectedTab(0);
      resetValues();
      getUsers();
      setIsCreatedUser(false);
      setIsCancelCallAccess(false);
      showSnackbar ? props.handleopenClose(true) : props.handleopenClose(false);
    } else {
      dispatch(getAllUsers(sapCustomerId, props.page));
      setselectedTab(0);
      resetValues();
      // getUsers();
      showSnackbar ? props.handleopenClose(true) : props.handleopenClose(false);
    }
  }

  function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function validateMobile(number: string) {
    const re = /^[5]\d{8}$/;
    return re.test(number);
  }

  function validatePhone2(number: string) {
    const re = /^\d([0-9 -]{0,10}\d)?$/;
    return re.test(number);
  }

  function validateFax(number: string) {
    const re = /^\d([0-9 -]{0,10}\d)?$/;
    return re.test(number);
  }

  function validateAdminUserID(value: string) {
    if (value.includes(" ") || value.split('').length > 15) {
      return false;
    }
    else {
      return true;
    }
  }

  function validateUsersTab() {
    let isValid: boolean = true;
    if (adminUserId.trim() === "") {
      setAdminUserIdError(true);
      isValid = false;
    }
    if (lockReason.trim() === "" && lockIndicator === true) {
      setAdminUserIdError(true);
      isValid = false;
    }
    if (adminFirstName.trim() === "") {
      setAdminFirstNameError(true);
      isValid = false;
    }
    if (adminFirstName.trim() !== "") {
      if (adminFirstName.split('').length > 35) {
        setInvalidAdminFirstName(true);
        isValid = false;
      }
      else {
        setInvalidAdminFirstName(false);
      }
    }
    if (adminLastName.trim() === "") {
      setAdminLastNameError(true);
      isValid = false;
    }
    if (adminLastName.trim() !== "") {
      if (adminLastName.split('').length > 35) {
        setInvalidAdminLastName(true);
        isValid = false;
      }
      else {
        setInvalidAdminLastName(false);
      }
    }
    if (adminMobile.trim() === "") {
      setAdminMobileError(true);
      isValid = false;
    }
    if (adminMobile.trim() !== "") {
      if (!validateMobile(adminMobile)) {
        isValid = false;
      }
      if (validateMobile(adminMobile) === false) {
        setInvalidMobile(true);
      }
      else {
        setInvalidMobile(false);
      }
    }
    if (adminEmail.trim() === "") {
      setAdminEmailError(true);
      isValid = false;
    }
    if (adminEmail.trim() !== "") {
      if (!validateEmail(adminEmail)) {
        isValid = false;
      }
      if (validateEmail(adminEmail) === false) {
        setInvalidEmail(true);
      }
      else {
        setInvalidEmail(false);
      }
    }
    if (adminPhone2 === "") {
      setInvalidPhone2(false);
    }
    if (adminPhone2 !== "") {
      if (!validatePhone2(adminPhone2)) {
        isValid = false;
      }
      if (validatePhone2(adminPhone2) === false) {
        setInvalidPhone2(true);
      }
      else {
        setInvalidPhone2(false);
      }
    }
    if (adminFax === "") {
      setInvalidFax(false);
    }
    if (adminFax !== "") {
      if (!validateFax(adminFax)) {
        isValid = false;
      }
      if (validateFax(adminFax) === false) {
        setInvalidFax(true);
      }
      else {
        setInvalidFax(false);
      }
    }
    if (adminUserType.trim() === "") {
      setAdminUserTypeError(true);
      isValid = false;
    }
    if (!adminValidFrom) {
      setAdminValidFromError(true);
      isValid = false;
    }
    if (!adminValidTo) {
      setAdminValidToError(true);
      isValid = false;
    }
    if (adminValidFrom && adminValidTo) {
      if (new Date(adminValidFrom) > new Date(adminValidTo)) {
        setInvalidDate(true);
        isValid = false;
      }
      else {
        setInvalidDate(false);
      }
    }
    if (!isValid) {
      showErrorMessage('Please fill all mandatory fields');
      handleopenCloseErrorSnackbar(true);
    } else {
      return isValid;
    }
  }

  function getUsersTabData() {
    let validFromDateString: string = adminValidFrom ? formatDate(adminValidFrom) : "";
    let validToDateString: string = adminValidTo ? formatDate(adminValidTo) : "";
    return {
      "sessionDetails": {
        "webApplicationLoginId": profileDetails.data.data.basicInfo.webApplicationLoginId,
        "clientIPAddress": sessionStorage.getItem('clientIp'),
      },
      "title": adminTitle,
      "firstName": adminFirstName.trim(),
      "lastName": adminLastName.trim(),
      "webApplicationLoginId": adminUserId.trim(),
      "firstTelephoneNumber": adminMobile.trim(),
      "email": adminEmail.trim(),
      "userType": adminUserType.trim(),
      "mobileNumber": adminPhone2.trim(),
      "validFromDate": validFromDateString,
      "validToDate": validToDateString,
      "faxNumber": adminFax.trim(),
      "sapCustomerId": sapCustomerId,
      "region": adminRegion,
      "countryKey": adminCountry,
      "lockIndicator": lockIndicator,
      "lockReason": lockReason,
      "salesOffice": "",
      "distributionChannel": "",
      "division": "",
      "shipToParty": "",
      "billToParty": "",
      "payerId": "",
      "salesGroup": "",
      "salesEmployee": "",
      "allowIpPattern": "",
      "creditControlArea": "",
      "street": "",
      "postalCode": "",
      "b2bAdminId": userBasicInfo.b2bUserId ? userBasicInfo.b2bUserId : '',
      "assignKam": ""
    };
  }

  function getUsersTabDataForUpdate() {
    let validFromDateString: string = adminValidFrom ? formatDate(adminValidFrom) : "";
    let validToDateString: string = adminValidTo ? formatDate(adminValidTo) : "";
    let userDetails: any = userDetailsInfo && userDetailsInfo.data && userDetailsInfo.data.data;
    return {
      "sessionDetails": {
        "webApplicationLoginId": profileDetails.data.data.basicInfo.webApplicationLoginId,
        "clientIPAddress": sessionStorage.getItem('clientIp'),
      },
      "b2bUserId": userDetails.b2bUserId,
      "title": adminTitle.trim(),
      "firstName": adminFirstName.trim(),
      "lastName": adminLastName.trim(),
      "firstTelephoneNumber": adminMobile.trim(),
      "email": adminEmail.trim(),
      "mobileNumber": adminPhone2.trim(),
      "validFromDate": validFromDateString,
      "validToDate": validToDateString,
      "faxNumber": adminFax.trim(),
      "region": adminRegion.trim(),
      "countryKey": adminCountry.trim(),
      "postalCode": userDetails.postalCode,
      "street": userDetails.street,
      "lockIndicator": lockIndicator,
      "lockReason": lockReason,
      "payerId": userDetails.payerId,
      "status": userDetails.status,
      "organizationIds": userDetails.organizationIds
    };
  }

  function populateCountryAndRegion() {
    if (sessionStorage.getItem('userType') !== 'kam') {
      setAdminRegion(userBasicInfo.region);
      setAdminCountry(userBasicInfo.countryKey);
    }
  }

  const { regionData }: any = useSelector((state: any) => state.onboard);

  function populateUserDetails() {
    let userDetails: any = userDetailsInfo && userDetailsInfo.data && userDetailsInfo.data.data;
    userDetails && userDetails.title ? setAdminTitle(userDetails.title) : setAdminTitle("");
    userDetails && userDetails.firstName ? setAdminFirstName(userDetails.firstName) : setAdminFirstName("");
    userDetails && userDetails.lastName ? setAdminLastName(userDetails.lastName) : setAdminLastName("");
    userDetails && userDetails.firstTelephoneNumber ? setAdminMobile(userDetails.firstTelephoneNumber) : setAdminMobile("");
    userDetails && userDetails.mobileNumber ? setAdminPhone2(userDetails.mobileNumber) : setAdminPhone2("");
    userDetails && userDetails.email ? setAdminEmail(userDetails.email) : setAdminEmail("");
    userDetails && userDetails.userType ? setAdminUserType(userDetails.userType) : setAdminUserType("Admin");
    userDetails && userDetails.webApplicationLoginId ? setAdminUserId(userDetails.webApplicationLoginId) : setAdminUserId("");
    userDetails && userDetails.validFromDate ? setAdminValidFrom(parseDateFromString(userDetails.validFromDate)) : setAdminValidFrom(null);
    userDetails && userDetails.validToDate ? setAdminValidTo(parseDateFromString(userDetails.validToDate)) : setAdminValidTo(null);
    userDetails && userDetails.faxNumber ? setAdminFax(userDetails.faxNumber) : setAdminFax("");
    userDetails && userDetails.lockIndicator ? setLockIndicator(userDetails.lockIndicator) : setLockIndicator(false);
    userDetails && userDetails.lockReason ? setLockReason(userDetails.lockReason) : setLockReason("");
    if (sessionStorage.getItem('userType') == 'kam') {
      userDetails && userDetails.region ? setAdminRegion(userDetails.region) : setAdminRegion("");
      userDetails && userDetails.countryKey ? setAdminCountry(userDetails.countryKey) : setAdminCountry("");
    } else {
      userBasicInfo && userBasicInfo.region ? setAdminRegion(userBasicInfo.region) : setAdminRegion("");
      userBasicInfo && userBasicInfo.countryKey ? setAdminCountry(userBasicInfo.countryKey) : setAdminCountry("");
    }
  }

  function formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  function parseDateFromString(dateString: string) {
    let d = dateString.split('-');
    try {
      return d && d.length ? new Date(d[1] + "/" + d[0] + "/" + d[2]) : null;
    } catch (err) {
      return null;
    }
  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  function updateParamVal(code: any) {
    let newFeatureCode: any = [];
    updatefeatureCode.forEach((element: any) => {
      if (element.featureCode === code) {
        element.value = !element.value;
        if (code === 'SELECT_DEFAULT_ADDRESS') {
          if (element.value) {
            showAddressTab(true);
          } else {
            showAddressTab(false);

          }
        }
      }

      newFeatureCode.push(element);
    });
    setupdatefeatureCode(newFeatureCode);
  }

  const disableNext = (createDealerUser && createDealerUser.loading) || (updateDealerUser && updateDealerUser.loading);
  const nextLoading = ((createDealerUser && createDealerUser.loading) || (updateDealerUser && updateDealerUser.loading));
  const disableSubmit = (accessParamAdd && accessParamAdd.loading) || (accessParamUpdate && accessParamUpdate.loading);
  const submitLoading = (accessParamAdd && accessParamAdd.loading) || (accessParamUpdate && accessParamUpdate.loading) || (createDealerUser && createDealerUser.loading) || (updateDealerUser && updateDealerUser.loading);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const setMarkAsDefaultAddress = async (id: any) => {
    let data: any = {
      "addressId": id,
      "sapCustomerId": sapCustomerID,
      "b2bUserId": userDetailsInfo && userDetailsInfo.data && userDetailsInfo.data.data && userDetailsInfo.data.data.b2bUserId,
      "webApplicationLoginId": webApplicationLoginId,
      "addressSetByAdmin": isAdmin === "Admin" ? true : false,
      "adminB2bUserId": isAdmin === "Admin" ? b2bUserId : "",
    }
    let defaultAddressData: any = await dispatch(postSetDefaultAddress(data))
    if (defaultAddressData && defaultAddressData.message && defaultAddressData.message.status === 'S') {
      defaultAddressId = defaultAddressData.data.addressId;
      setOpenErrorSnackbar(false);
      setOpenSnackbar(true);
      setApiResponseText(defaultAddressData && defaultAddressData.message && defaultAddressData.message.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);

      dispatch(getDealerDetails(props.sapCustomerId ? props.sapCustomerId : sapCustomerId, 'shipToParty', userDetails?.webApplicationLoginId));
    } else {
      setOpenSnackbar(false);
      handleopenCloseErrorSnackbar(true);
      setOpenErrorSnackbar(true);
      showErrorMessage(defaultAddressData && defaultAddressData.message);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }
  }

  return (
    <Fragment>
      {loading && (<FullscreenLoader />)}
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={apiResponseText} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <Dialog
        open={props.open ? true : false}
        onClose={() => closeAddUserDialog(false)}
        disableBackdropClick={true}
        fullWidth={true}
        maxWidth={'md'}
      >
        <DialogTitle className="pb-0">
          <div className="page-title">
            {props.isView ? 'View User' : (
              <>
                {props.isEdit && !isCreatedUser ? "Edit User" : "Add New User"}
              </>
            )}
            <IconButton onClick={() => closeAddUserDialog(false)} className="float-right">
              <CloseIcon className={classes.close} />
            </IconButton>
          </div>
          <div className="mt-3">
            <ThemeProvider theme={theme}>
              <Paper square>
                <Tabs
                  className="tabSelect"
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    setselectedTab(newValue);
                    if (newValue === 1) {
                      if (props.isAdmin) {
                        dispatch(getKamAccessParam(userDetails && userDetails.b2bUserId, props.sapCustomerId));
                      } else {
                        dispatch(getAccessParam(userDetails && userDetails.b2bUserId, adminId ? adminId : userBasicInfo && userBasicInfo.adminId, props.isEdit ? 'edit' : 'add'));
                      }
                    } else if (newValue === 2) {
                      dispatch(getDealerDetails(props.sapCustomerId ? props.sapCustomerId : sapCustomerId, 'shipToParty', userDetails?.webApplicationLoginId));
                    }
                  }}
                  aria-label="disabled tabs example">
                  <Tab label="User Details"
                    // disabled={!props.isEdit} 
                    value={0} {...a11yProps(0)}>
                    {/* <div className="add-user-tabs">1</div> */}
                  </Tab>
                  {adminUserType === "Admin" && userBasicInfo.b2bUserId === userDetails.b2bUserId ? null :
                    <Tab disabled={!props.isEdit} label="Access Parameter" value={1} {...a11yProps(1)} />
                  }
                  <Tab disabled={!props.isEdit} label="Shipping Address" value={2} {...a11yProps(2)} />
                </Tabs>
              </Paper>
            </ThemeProvider>
          </div>
        </DialogTitle>
        <DialogContent>
          <TabPanel value={selectedTab} index={0}>
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-2 mt-1">
                <TextField required id="adminUserId" error={adminUserIdError} helperText={userIdErrorText}
                  disabled={isDisabled() ? isDisabled() : isWebLoginIdDisabled}
                  value={adminUserId} onChange={(e: any) => {
                    setIsUserIdValid(false);
                    setUserIdSuccessMessage("");
                    setSubmitDisabled(true);
                    setUserIdErrorText("");
                    handleInputChange(e, setAdminUserId, setAdminUserIdError);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{isUserIdValid && <CheckCircleIcon className="valid-user-id" />}</InputAdornment>,
                  }} label="Web Login Id" size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  variant="outlined" margin="dense" />
                <div>
                  {isUserIdValid && userIdSuccessMessage && <span className="successMessage">Id is Available</span>}
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-2 mt-1">
                <Button
                  disabled={isDisabled() ? isDisabled() : isWebLoginIdDisabled}
                  onClick={(e: any) => { checkUserAvailability(e) }}
                  variant="contained"
                  className={isDisabled() ? 'check-availability-button font-size-11 mt-2 disabledCustomclass' : 'check-availability-button font-size-11 mt-2'}
                >
                  Check Availability
                </Button>
                <div>
                  {(adminUserId !== "") && submitDisabled && !userIdErrorText && (
                    <span className="errorMsg">Please do check Availability to proceed</span>
                  )}
                </div>
              </div>
              <div className="col-sm-12 col-md-2 col-lg-2 mb-2 pr-0">
                <TextField select id="adminTitle"
                  disabled={isDisabled()}
                  value={adminTitle} onChange={(e: any) => handleDropdownChange(e, setAdminTitle)} size="small" className="col-sm-12 my-2 commonRoundedInputs-dropdown" label="Title" variant="outlined" margin="dense">
                  <MenuItem key={1} value={"Mr"}>Mr.</MenuItem>
                  <MenuItem key={2} value={"Mrs"}>Mrs.</MenuItem>
                  <MenuItem key={3} value={"Ms"}>Ms.</MenuItem>
                  <MenuItem key={4} value={"Dr"}>Dr.</MenuItem>
                  <MenuItem key={5} value={"Engr"}>Engr.</MenuItem>
                  <MenuItem key={6} value={"H.H"}>H.H</MenuItem>
                  <MenuItem key={7} value={"Heirs of"}>Heirs of</MenuItem>
                  <MenuItem key={8} value={"Sheikh"}>Sheikh</MenuItem>
                  <MenuItem key={9} value={"Sheikha"}>Sheikha</MenuItem>
                </TextField>
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4 pr-3 mb-2 pl-0">
                <TextField id="adminFirstName" error={adminFirstNameError || invalidAdminFirstName} helperText={invalidAdminFirstName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ADMIN_FIRST_NAME_TEXT}</span>}
                  value={adminFirstName} onChange={(e: any) => handleInputChange(e, setAdminFirstName, setAdminFirstNameError)} size="small" className="col-sm-12 my-2 commonRoundedInputs-textfield"
                  disabled={isDisabled()}
                  label="First Name" required variant="outlined" margin="dense" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-2">
                <TextField id="adminLastName" error={adminLastNameError || invalidAdminLastName} helperText={invalidAdminLastName && <span style={{ color: 'red' }}>{ValidationErrorMessage.ADMIN_LAST_NAME_TEXT}</span>}
                  value={adminLastName} onChange={(e: any) => handleInputChange(e, setAdminLastName, setAdminLastNameError)} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  disabled={isDisabled()}
                  label="Last Name" required variant="outlined" margin="dense" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pr-3">
                <TextField id="adminMobile" error={adminMobileError} placeholder={Messages.MOBILE_PLACEHOLDER_TEXT}
                  value={adminMobile} onChange={(e: any) => handleInputChange(e, setAdminMobile, setAdminMobileError)} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  disabled={isDisabled()}
                  label="Mobile" required variant="outlined" margin="dense" helperText={invalidMobile && <span style={{ color: 'red' }}>It should start with 5 and contains 9 digits.</span>} />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pl-3">
                <TextField id="adminEmail" error={adminEmailError}
                  value={adminEmail} onChange={(e: any) => handleInputChange(e, setAdminEmail, setAdminEmailError)} size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  disabled={isDisabled()}
                  label="Email" required variant="outlined" margin="dense" helperText={invalidEmail && <span style={{ color: 'red' }}>Please enter a valid email address.</span>} />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-2">
                <TextField disabled select id="adminUsertype" error={adminUserTypeError}
                  value={adminUserType} onChange={(e: any) => handleDropdownChange(e, setAdminUserType, setAdminUserTypeError)}
                  variant="outlined" label="User Type" className="col-sm-12 my-2 commonRoundedInputs" size="small" required margin="dense">
                  <MenuItem key={1} value={"Admin"}>Admin</MenuItem>
                  <MenuItem key={2} value={"User"}>User</MenuItem>
                </TextField>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-2">
                <TextField id="adminphone2" helperText={invalidPhone2 && <span style={{ color: 'red' }}>Please use numbers only and it must not exceed 12 digits.</span>}
                  disabled={isDisabled()}
                  value={adminPhone2} onChange={(e: any) => handleInputChange(e, setAdminPhone2)} label="Phone2" size="small" className="col-sm-12 my-2 commonRoundedInputs" variant="outlined" margin="dense" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-2">
                <KeyboardDatePicker
                  required autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                  disabled={isDisabled()}
                  format="dd/MM/yyyy" margin="normal" label="Valid From" value={adminValidFrom}
                  onChange={(date: Date | null) => {
                    setAdminValidFrom(date);
                    setAdminValidFromError(false);
                  }}
                  views={["year", "month", "date"]}
                  openTo="year"
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                  error={adminValidFromError}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-2">
                <KeyboardDatePicker
                  required autoOk size="small" disableToolbar variant="inline" inputVariant="outlined"
                  format="dd/MM/yyyy" margin="normal" label="Valid To" value={adminValidTo}
                  disabled={isDisabled()}
                  onChange={(date: Date | null) => {
                    setAdminValidTo(date);
                    setAdminValidToError(false);
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  className="col-sm-12 my-2 commonRoundedInputs cal-icon"
                  error={adminValidToError}
                  views={["year", "month", "date"]}
                  openTo="year"
                  maxDate={new Date('9999-12-31')}
                  helperText={invalidDate && <span style={{ color: 'red' }}>Valid to date must be greater than valid from date.</span>}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-2">
                <TextField id="adminFax"
                  helperText={invalidFax && <span style={{ color: 'red' }}>Please use numbers only and it must not exceed 12 digits.</span>}
                  value={adminFax} onChange={(e: any) => handleInputChange(e, setAdminFax)}
                  disabled={isDisabled()}
                  size="small" className="col-sm-12 my-2 commonRoundedInputs"
                  label="FAX" variant="outlined" margin="dense" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pl-3 mb-2">
                <TextField select variant="outlined" id="adminCountry"
                  value={adminCountry} onChange={(e: any) => handleDropdownChange(e, setAdminCountry)}
                  disabled={true}
                  label="Country" className="col-sm-12 my-2 commonRoundedInputs" size="small" margin="dense">
                  {CountryList.data.map(item => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Country}</MenuItem>))}
                </TextField>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-2">
                <TextField select variant="outlined"
                  id="adminRegion"
                  disabled={true}
                  value={userBasicInfo.region && sessionStorage.getItem('userType') != 'kam' ? userBasicInfo.region : adminRegion} onChange={(e: any) => handleDropdownChange(e, setAdminRegion)}
                  className="col-sm-12 my-2 commonRoundedInputs" label="Region" size="small" margin="dense">
                  {regionData && regionData.data && regionData.data.length > 0 && regionData.data[0].regions.map((item: any) => (
                    <MenuItem key={item.Code} value={item.Code}>{item.Region} </MenuItem>))}
                </TextField>
              </div>
              {props.isEdit && !props.inProgress && <>
                {adminUserType === "Admin" && userBasicInfo.b2bUserId === userDetails.b2bUserId ? null :
                  <div className="col-sm-12 mb-2 row justifyContentHorizontal align-items-center">
                    <div className="lock-indicator font-size-12 px-3 mt-2">Lock User</div>
                    <Typography component="div" className="ml-5">
                      <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>
                          <AntSwitch disabled={isDisabled()} checked={lockIndicator} onChange={() => { setLockIndicator(!lockIndicator); if (!lockIndicator === false) setLockReason("") }} />
                        </Grid>
                      </Grid>
                    </Typography>
                  </div>
                }
                {/* <div className="access-parameter-field">{index.label === "Download Invoice to Excel" ? "Download Invoice" : index.label}</div>
                            <Typography component="div">
                                <Grid component="label" container alignItems="center" spacing={1}>
                                    <Grid item>
                                        <AntSwitch  disabled={index.label === 'Manage Users' || index.label === 'Manage Document Expiry'} checked={index.value} onChange={() => updateParamVal(index.featureCode)} />
                                    </Grid>
                                </Grid>
                            </Typography>
                        </div> */}
                {lockIndicator && (
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <TextField
                      helperText={lockReason === "" && <span style={{ color: 'red' }}>Please Enter Reason.</span>}
                      required
                      disabled={isDisabled() ? isDisabled() : !lockIndicator}
                      value={lockReason} onChange={(e: any) => setLockReason(e.target.value)}
                      className="col-sm-12 my-2 commonRoundedInputs"
                      label="Lock Reason"
                      multiline
                      rows={3}
                      variant="outlined"
                    />
                  </div>
                )}
              </>}
            </div>
            <div className="row mt-2 mb-3 justify-content-end">
              {isDisabled() ? '' : (
                <Button autoFocus className="add-user-tabs-button font-size-11 mr-2"
                  disabled={submitDisabled || disableNext} onClick={() => handleNextClick()}>
                  {nextLoading && <Loading />} {adminUserType === "Admin" && userBasicInfo.b2bUserId === userDetails.b2bUserId ? "Submit" : "Next"}</Button>
              )}
              {/* <Button autoFocus className="add-user-tabs-button font-size-11 mr-2" disabled={submitDisabled || disableNext} onClick={() => handleNextClick()}>{nextLoading && <Loading />}Next</Button> */}
              <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => closeAddUserDialog(false)}>Cancel</Button>
            </div>
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            <div className="row">
              {updatefeatureCode && updatefeatureCode.map((index: any) => (
                <>
                  {customerTierType === 'Workshop' ? (
                    <>
                      {index.featureCode !== 'VIEW_CR_STATUS' && index.featureCode !== 'ACHIEVEMENTS_MODULE' && (
                        <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-4 mt-3 row-space-between">
                          <div className="access-parameter-field">{index.label === "Download Invoice to Excel" ? "Download Invoice" : index.label}</div>
                          <Typography component="div">
                            <Grid component="label" container alignItems="center" spacing={1}>
                              <Grid item>
                                <AntSwitch disabled={isDisabled() ? isDisabled() : index.featureCode === 'MANAGE_USER' || index.featureCode === 'DOC_EXP' || (index.featureCode === 'SELECT_DEFAULT_ADDRESS' && !shipToPartyFeatureAccess)} checked={index.featureCode === 'MANAGE_USER' ? false : index.value} onChange={() => updateParamVal(index.featureCode)} />
                              </Grid>
                            </Grid>
                          </Typography>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="col-sm-12 col-md-6 col-lg-6 pr-3 mb-4 mt-3 row-space-between">
                      <div className="access-parameter-field">{index.label === "Download Invoice to Excel" ? "Download Invoice" : index.label}</div>
                      <Typography component="div">
                        <Grid component="label" container alignItems="center" spacing={1}>
                          <Grid item>
                            <AntSwitch disabled={isDisabled() ? isDisabled() : index.featureCode === 'MANAGE_USER' || index.featureCode === 'DOC_EXP' || (index.featureCode === 'SELECT_DEFAULT_ADDRESS' && !shipToPartyFeatureAccess)} checked={index.featureCode === 'MANAGE_USER' ? false : index.value} onChange={() => updateParamVal(index.featureCode)} />
                          </Grid>
                        </Grid>
                      </Typography>
                    </div>
                  )}
                </>
              ))}
            </div>
            <div className="row mt-2 mb-3 justify-content-end">
              {isDisabled() ? '' : (
                <Button autoFocus className="add-user-tabs-button font-size-11 mr-2" onClick={() => handleNextClick()}>Next</Button>
              )}
              {/* {isDisabled() || displayAddressTab ? '' : (
                <Button autoFocus className="add-user-tabs-button font-size-11 mr-2" disabled={disableSubmit} onClick={() => handleNextClick()}>{submitLoading && <Loading />} Submit</Button>
              )} */}
              <Button variant="contained" className="cancel-button font-size-11 mr-2" onClick={() => closeAddUserDialog(false)}>Cancel</Button>
            </div>
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            {dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data.addresses && dealerDetails.data.data.addresses.length && dealerDetails.data.data.addresses.map((addresses: any, index: any) => (
              <div className="col-12 m-0 pl-3 p-2 border-bottom">
                <div className="row">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 row">
                    {/* <div>
                      <FormControlLabel className="mt-5"
                        value="" control={<Radio color="primary" />}
                        label="" />
                    </div> */}
                    <div className="card-body pt-1" key={index}>
                      <div className="w-75 d-inline-block mt-2">
                        <div className="shipAddTitle">{addresses.dafault ? addresses.companyName : addresses.location} &nbsp;
                          {addresses.dafault && (
                            <Button
                              className="defaultAddressButton cursor-default"
                              size="small"
                              variant="contained"
                              color="secondary"
                            >Head Office</Button>
                          )}
                        </div>
                        <div className="shipAdd mt-2">{addresses.streetName ? addresses.streetName + " - " : ""}  {addresses.city ? addresses.city + " - " : ""} {addresses.country}</div>
                        {customerTierType === "Workshop" ? <div className="shipAdd">Contact Telephone Number: {addresses.telephoneNumber}</div> : <div className="shipAdd">Telephone Office: {addresses.telephoneNumber}</div>}
                        <div className="shipAdd">PO Box: {addresses.poBox}</div>
                        <div className="shipAdd">Emirates/Region: {addresses.region} {getRegionName(addresses.region)}</div>
                        <div className="shipAdd">Ship To Party Customer ID:{" "}{addresses.shiftToPartyCustomerNumber ? addresses.shiftToPartyCustomerNumber : '-'}</div>
                        <div className="px-0 col-md-3 col-lg-3">
                        </div>
                      </div>
                      {shipToPartyFeatureAccess && (<>
                        {defaultAddressId === addresses.id ?
                          <div className="markDefaultIcon w-25 d-inline-block text-right">
                            <Button
                              variant="contained"
                              color="primary"
                              className="rounded-pill cursor-default mt-n3"
                            >Default Address</Button>
                          </div>
                          : ""}
                        {defaultAddressId !== addresses.id && !displayAddressTab && !props.isView && (
                          <div className="markDefaultIcon w-25 d-inline-block text-right">
                            <Button
                              className="text-info"
                              startIcon={
                                // defaultAddressId !== addresses.id ? 
                                <CheckCircleIcon className="valid-user-id" />
                                // : ""
                              }
                              onClick={() => {
                                // setAddressID(addresses.id);
                                setMarkAsDefaultAddress(addresses.id);
                              }}><span className="onshiftoparty-font">
                                {/* {defaultAddressId !== addresses.id ? */}
                                Mark as Default
                                {/* : ''} */}
                              </span>
                            </Button>
                          </div>
                        )}
                      </>)}
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div className="row mt-2 mb-3 justify-content-end">

              {isDisabled() ? '' : (
                <Button autoFocus className="add-user-tabs-button font-size-11 mr-2" disabled={disableSubmit} onClick={() => handleNextClick()}>{submitLoading && <Loading />} Submit</Button>
              )}
              <Button variant="contained" className="cancel-button font-size-11 mr-2" onClick={() => closeAddUserDialog(false)}>Cancel</Button>
            </div>
          </TabPanel>
        </DialogContent>
      </Dialog >
    </Fragment>
  )
}
