import { Route } from "react-router";
import LoginAzure from "../../components/Login/LoginAzure";
import AdminLoginAzure from "../../components/Login/AdminLoginAzure";


export function PrivateRoute({ isAuthenticated, children, ...rest }: any): JSX.Element {
    const isAdminLogin = window.location.href.includes("/admin") || window.location.href.includes("/creditteam");

    const loggedInuserType = sessionStorage.getItem('userType');

    return (
        <Route
            {...rest}
            render={() =>
                isAuthenticated ? (children) : isAdminLogin || ((loggedInuserType && loggedInuserType.toLowerCase() == "kam") || (loggedInuserType && loggedInuserType.toLowerCase() == "fse")  || (loggedInuserType && loggedInuserType.toLowerCase() == 'creditTeam') || (loggedInuserType && loggedInuserType.toLowerCase() == 'afg_super_admin'))||(loggedInuserType && loggedInuserType.toLowerCase() == 'pricing_team') ? <AdminLoginAzure /> : <LoginAzure /> //eslint-disable-line
            }
        />
    );
}
