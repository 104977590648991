import React, { Fragment, Dispatch, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import { Prompt, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import TextField from '@material-ui/core/TextField';
import NotificationIcon from '../../common/components/NotificationIcon';
import { updateDocument } from '../../store/actions/onboard.actions';
import { creditLimitService } from "../../services/creditLimit.service";
import _ from 'lodash';
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import { deleteBankStatementOfCreditRequest } from '../../store/actions/dealer.action';
import { getBrandName } from '../../common/components/util';
import DeleteDialog from '../../common/components/deleteDialog';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));

export default function CreditRequestOrg(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const history: any = useHistory();
    const [dataSubmitted, setdataSubmitted] = React.useState<any>(false);
    const [dataUpdated, setDataUpdated] = React.useState(false);
    const [updateMode, setupdateMode] = React.useState<any>(false);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState<any>(null)
    const [disableWolePage, setdisableWolePage] = React.useState<any>(false)
    const [emptyWholePage, setemptyWholePage] = React.useState<any>(false)
    var brandData: any = sessionStorage.getItem('creditBrand');
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [documentIndex, setDocumentIndex] = React.useState<any>(null)
    const [documentItem, setDocumentItem] = React.useState<any>(null)

    brandData = brandData ? JSON.parse(brandData) : null;
    const [creditLimitData, setcreditLimitData] = React.useState<any>({
        "b2bUserId": "",
        "sapCustomerId": "",
        "requestId": "",
        "salesOrganization": brandData && brandData.brandId,
        "make": brandData && brandData.brand,
        "creditPeriodInDays": "0",
        "creditControlArea": "",
        "creditAmount": brandData && brandData.creditLimit,
        // "brandCredit": [

        // ],
        "bankDetails": {
            "branchName": "",
            "bankName": "",
            "accountNo": "",
            "IBANNo": "",
            "telephone": "",
            "contactName": "",
            "address": ""
        },
        "documents": [
            {
                "type": "bankStatement",
                "documentId": ""
            },
            {
                "type": "aecbConsentForm",
                "documentId": ""
            },
            {
                "type": "managementAccount",
                "documentId": ""
            },
            {
                "type": "vatReturnStatement",
                "documentId": ""
            },
            {
                "type": "contract",
                "documentId": ""
            },
            {
                "type": "tenancyContractCopy",
                "documentId": ""
            },
            {
                "type": "auditedFinancialStatement",
                "documentId": ""
            },
            {
                "type": "companyProfileDocument",
                "documentId": ""
            },
            {
                "type": "authorizationLatter",
                "documentId": ""
            },
            {
                "type": "rtaFleetListReport",
                "documentId": ""
            },

            {
                "type": "additionalDocuments",
                "documentId": ""
            }
        ]
    });

    const fieldLabelsAndConditions: any = {
        "bankStatement": {
            label: 'Bank Statement*',
            subLabel: 'Latest 6 months',
            required: true
        },
        "aecbConsentForm": {
            label: 'AECB Consent Form*',
            subLabel: '',
            required: true
        },
        "managementAccount": {
            label: 'Management Account*',
            subLabel: 'Current year (Financial statement/P&L/Balance sheet)',
            required: true
        },
        "vatReturnStatement": {
            label: 'VAT Return Statement*',
            subLabel: 'Last 2 years',
            required: true
        },
        "contract": {
            label: 'Contract*',
            subLabel: 'Latest 6 months',
            required: false
        },
        "tenancyContractCopy": {
            label: 'Tenancy Contract Copy',
            subLabel: '--',
            required: true
        },
        "auditedFinancialStatement": {
            label: 'Audited Financial Statements',
            subLabel: 'Last 3 years',
            required: false
        },
        "companyProfileDocument": {
            label: 'Company Profile Document',
            subLabel: '--',
            required: false
        },
        "authorizationLatter": {
            label: 'Authorization Letter',
            subLabel: '--',
            required: false
        },
        "rtaFleetListReport": {
            label: 'RTA Report / Fleet List Report',
            subLabel: '--',
            required: false
        },
        "additionalDocuments": {
            label: 'Additional Documents',
            subLabel: '--',
            required: false
        },
    }

    const currencies = ["AED"];
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const b2bUserId = _.get(profileDetails, "data.data.basicInfo.b2bUserId", "");
    const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
    const customerTierType = _.get(profileDetails, 'data.data.customerInfo.customerTierType', '');

    const handleInputChangeBrands = (e: any, setterName: any, index: any) => {
        if (setterName) {
            let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
            creditLimitDataTemp['brandCredit'][index][setterName] = e && e.target && e.target.value ? parseInt(e.target.value) ? e.target.value : 1 : '';
            setcreditLimitData(creditLimitDataTemp);
        }
    }

    const handleInputChangeBankDetails = (e: any, setterName: any) => {
        setDataUpdated(true);
        if (setterName) {
            let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
            creditLimitDataTemp['bankDetails'][setterName] = e && e.target && e.target.value ? e.target.value : '';
            setcreditLimitData(creditLimitDataTemp);
        }
    }

    const handleInputChangeDocuments = async (e: any, setterName: any, index?: any) => {
        setDataUpdated(true);
        let item = e && e.target && e.target.files && e.target.files[0];
        if (item) {
            const name = item && item.name ? item.name : '',
                doctExtension = (name && name.substring(name.lastIndexOf('.') + 1, name.length)) || (name),
                doctExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'],
                canUploadFile = doctExtensions.indexOf(doctExtension.toLowerCase()) !== -1;
            let keyAttribute = `fieldname=${'creditRequest' + setterName}&docExtension=${doctExtension}`;
            if (item && item.size > 2000000) {
                setOpenSnackbar(true);
                setSuccessMessage("Please upload less than 2MB file.");
                setOpenErrorSnackbar(true);
                return;
            }
            if (!canUploadFile) {
                setOpenSnackbar(true);
                setSuccessMessage("Invalid File type format.");
                setOpenErrorSnackbar(true);
                return;
            }
            const customHeaders = {
                "Accept": "application/json",
                "Lob-Id": "AUTO",
                "Channel-Id": "B2B",
                "Org-Id": "",
                "Accept-Language": "EN",
            };
            const formData: any = new FormData();
            formData.append("entityName", "CUSTOMER");
            formData.append("keyAttribute", keyAttribute);
            formData.append("fileUpload", item);
            formData.append("document", 'true');
            formData.append("Attributes", `key=Brand&value=${item}`);
            formData.append("sapCustomerId", sapCustomerId || creditLimitData.sapCustomerId);
            formData.append("docType", 'Bank Documents');
            formData.append("label", setterName);
            formData.append("b2bUserId", b2bUserId ? b2bUserId : '');
            formData.append("brand", brandData && brandData.brandId);
            formData.append("uploadedByRole", 'dealerUser');
            formData.append("selectedFileName", item.name);
            formData.append("uploadedBywebApplicationLoginId", '');
            let dataDocument: any = await dispatch(updateDocument(formData, customHeaders));
            if (dataDocument && dataDocument.data) {
                let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
                creditLimitDataTemp['documents'][index]['documentId'] = dataDocument.data;
                setcreditLimitData(creditLimitDataTemp);
            }
        } else {
            let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
            dispatch(deleteBankStatementOfCreditRequest(creditLimitDataTemp['documents'][index]['documentId']['id'], {
                "type": creditLimitDataTemp['documents'][index]['type']
            }, brandData && brandData.id ? brandData.id : ''));
            creditLimitDataTemp['documents'][index]['documentId'] = '';
            setcreditLimitData(creditLimitDataTemp);
            setOpenDeleteDialog(false)
        }
    }
    const deleteDocuments = async (e: any, item: any, index?: any) => {
        setDocumentIndex(index);
        setOpenDeleteDialog(true);
        setSuccessMessage(null);
        setDocumentItem(item)
    }

    const handleopenClose = (open?: any) => {
        setOpenDeleteDialog(open ? true : false);
    };


    useEffect(() => {
        loadCreditLimits()
    }, [])

    const loadCreditLimits = async () => {
        var brandDataTemp: any = sessionStorage.getItem('creditBrand');
        if (brandDataTemp) {
            brandDataTemp = JSON.parse(brandDataTemp)
        }
        let requestData: any = await creditLimitService.getDefaultCreditAdditionalData(brandDataTemp && brandDataTemp.id, sapCustomerId);
        if (requestData && requestData.data) {
            if (requestData.data) {
                setupdateMode(false)
                let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
                if (requestData.data['documents'] && Array.isArray(requestData.data['documents']) && requestData.data['documents'].length > 0) {
                    creditLimitDataTemp['documents'] = creditLimitDataTemp['documents'].map((item: any) => {
                        let findItem = requestData.data['documents'].find((subitem: any) => item.type == subitem.type)
                        return findItem ? findItem : item
                    })
                }
                creditLimitDataTemp['make'] = requestData.data['make'];
                creditLimitDataTemp['creditPeriodInDays'] = requestData.data['creditPeriodInDays'];
                creditLimitDataTemp['creditControlArea'] = requestData.data['creditControlArea'];
                creditLimitDataTemp['creditAmount'] = requestData.data['creditAmount'];
                creditLimitDataTemp['bankDetails'] = requestData.data['bankDetails'];
                setcreditLimitData(creditLimitDataTemp);
                setupdateMode(true)
            }
        }
    }

    const submitForm = async () => {
        let creditLimitDataTemp = JSON.parse(JSON.stringify(creditLimitData));
        setdataSubmitted(true)
        creditLimitDataTemp['documents'].map((item: any) => {
            item.documentId = item.documentId && item.documentId.id ? item.documentId.id : ''
            return item
        })
        creditLimitDataTemp['b2bUserId'] = b2bUserId;
        creditLimitDataTemp['webApplicationLoginId'] = sessionStorage.getItem('webApplicationLoginId');
        creditLimitDataTemp['sapCustomerId'] = sapCustomerId || creditLimitDataTemp['sapCustomerId'];
        let hasValidData = true;
        // creditLimitDataTemp['brandCredit'] && creditLimitDataTemp['brandCredit'].map((item: any) => {
        //     if (!item.creditPeriod || !item.currencyType || !item.creditRequired) {
        //         hasValidData = false
        //     }
        // })
        const requiredBandField = ['address', 'IBANNo', 'accountNo', 'bankName', 'telephone', 'contactName']
        requiredBandField.map((item: any) => {
            if (!creditLimitDataTemp['bankDetails'] || !creditLimitDataTemp['bankDetails'][item]) {
                hasValidData = false
            }
        })
        const requiredDocument = ['bankStatement', "aecbConsentForm", "contract", "managementAccount", "vatReturnStatement"]
        creditLimitData.documents.map((item: any) => {
            if (requiredDocument.includes(item['type']) && !item['documentId']) {
                hasValidData = false;
            }
        })
        if (hasValidData) {
            let data: any = null;
            if (updateMode) {
                data = await creditLimitService.updateDefaultCreditAdditionalData(creditLimitDataTemp, sapCustomerId, brandData.id);
            } else {
                data = await creditLimitService.createDefaultCreditAdditionalData(creditLimitDataTemp, sapCustomerId);
            }
            if (data && data.data) {
                setDataUpdated(false);
                setSuccessMessage(data.message.message)
                setOpenSnackbar(true)
                setTimeout(() => {
                    history.push("/orgsetting");
                    setOpenErrorSnackbar(false)
                }, 2000);
            } else {
                setSuccessMessage("Credit Request creation failed")
                setOpenSnackbar(true)
                setOpenErrorSnackbar(false)
            }
        } else {
            setOpenSnackbar(true)
            setSuccessMessage("Please fill all mandatory fields and upload all mandatory documents")
            setOpenErrorSnackbar(true)
        }
    }

    return (
        <Fragment>
            <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
            </Prompt>
            <ReturnRequestSnackbar
                open={openSnackbar}
                type={openErrorSnackbar ? "error" : null}
                message={successMessage}
                handleopenClose={() => {
                    setOpenSnackbar(false)
                    // setSuccessMessage("")
                    setOpenErrorSnackbar(false)
                }}
            />
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Credit Request
                </h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 mt-2 mb-1">
                    <div className="d-flex align-items-center mb-2">
                        <h6 className="page-sub-title mb-0 mt-2">Credit Request for '{getBrandName(brandData.brand, customerTierType)}'</h6>
                        <div className="row-space-start ml-auto sm">
                            <Button variant="contained" color="primary" onClick={(e) => { e.preventDefault(); history.push('/orgsetting'); }} className="blueActionButton border-left rounded-pill ml-4">Back </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card col-12 m-0 p-1">
                <div className="row mt-1 mb-1 px-3">
                    <Grid container>
                        <Grid item xs={4}>
                            <div className="info-sub-title-value mt-3 px-3">Brand</div>
                            <div className="subTitle mt-1 mb-3 px-3">
                                <Button
                                    className="brandbutton cursor-default"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                >{brandData && brandData.brand ? getBrandName(brandData.brand, customerTierType) : "-"}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="info-sub-title-value mt-3">Credit Amount</div>
                            <div className="subTitle mt-1 mb-3">
                                <Button
                                    className="creditAmountButton cursor-default"
                                    size="small"
                                    variant="contained"
                                    color="secondary"
                                >AED {brandData && brandData.creditLimit}</Button>
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="info-sub-title-value mt-3">Credit Period (Days)</div>
                            <div className="subTitle mt-1 mb-3">{brandData && brandData.paymentType && brandData.durationText ? brandData.durationText : '-'}</div>
                        </Grid>
                    </Grid>
                </div>
            </div>
            <div className="card col-12 m-0 p-2">
                <div className="row mt-3 mb-1">
                    <h6 className="dealer-table-content mb-1 px-4 mt-0 mb-0">Bank Details</h6>
                </div>
                <div className="row mb-1 mt-0 px-4">
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-1 mt-2 row-space-between ">
                        <TextField id="hondaCreditPeriodDays"
                            value={creditLimitData.bankDetails.bankName || ''}
                            error={!creditLimitData.bankDetails.bankName && dataSubmitted}
                            // helperText={invalidHondabankNameDays}
                            onChange={(e: any) => handleInputChangeBankDetails(e, "bankName")}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            inputProps={{ maxLength: 100 }}
                            label="Bank Name" required variant="outlined" margin="dense" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                        <TextField id="hondaCreditPeriodDays"
                            value={creditLimitData.bankDetails.accountNo || ''}
                            error={!creditLimitData.bankDetails.accountNo && dataSubmitted}
                            // helperText={invalidHondaaccountNoDays}
                            onChange={(e: any) => handleInputChangeBankDetails(e, "accountNo")}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            inputProps={{ maxLength: 23 }}
                            label="Account No." required variant="outlined" margin="dense" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                        <TextField id="hondaCreditPeriodDays"
                            value={creditLimitData.bankDetails.IBANNo || ''}
                            error={!creditLimitData.bankDetails.IBANNo && dataSubmitted}
                            // helperText={invalidHondaIBANNoDays}
                            onChange={(e: any) => handleInputChangeBankDetails(e, "IBANNo")}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            inputProps={{ maxLength: 100 }}
                            label="IBAN No" required variant="outlined" margin="dense" />
                    </div>
                </div>
                <div className="row mb-1 mt-0 px-4">
                    <div className="col-sm-12 col-md-4 col-sm-4 px-1 pr-2 mb-1 mt-2 row-space-between ">
                        <TextField id="hondaCreditPeriodDays"
                            value={creditLimitData.bankDetails.telephone || ''}
                            type="number"
                            error={!creditLimitData.bankDetails.telephone && dataSubmitted}
                            // helperText={invalidHondatelephoneDays}
                            onChange={(e: any) => handleInputChangeBankDetails(e, "telephone")}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            inputProps={{ maxLength: 20 }}
                            required label="Telephone" variant="outlined" margin="dense" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                        <TextField id="hondaCreditPeriodDays"
                            value={creditLimitData.bankDetails.contactName || ''}
                            error={!creditLimitData.bankDetails.contactName && dataSubmitted}
                            // helperText={invalidHondacontactNameDays}
                            onChange={(e: any) => handleInputChangeBankDetails(e, "contactName")}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            inputProps={{ maxLength: 100 }}
                            required label="Contact Name" variant="outlined" margin="dense" />
                    </div>
                    <div className="col-sm-12 col-md-4 col-sm-4 px-3 pr-2 mb-1 mt-2 row-space-between">
                        <TextField id="hondaCreditPeriodDays"
                            value={creditLimitData.bankDetails.address || ''}
                            error={!creditLimitData.bankDetails.address && dataSubmitted}
                            // helperText={invalidHondaaddressDays}
                            onChange={(e: any) => handleInputChangeBankDetails(e, "address")}
                            size="small" className="col-sm-12 my-2 commonRoundedInputs"
                            inputProps={{ maxLength: 250 }}
                            label="Address" required variant="outlined" margin="dense" />
                    </div>
                </div>
            </div>
            <div className="card col-12 m-0 p-2">
                <div className="row mt-3 mb-3">
                    <h6 className="dealer-table-content px-4 mt-0 mb-0">Required Documents</h6><br />
                    <p className="info-sub-header-document">(Supported File Extension : .jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB can be uploaded.)</p>
                </div>
            </div>
            <div className="row m-0 border-bottom">
                {creditLimitData.documents && Array.isArray(creditLimitData.documents) && creditLimitData.documents.map((item: any, index: any) => {
                    return <>
                        <div className="card col-lg-6 col-md-6 border-bottom">
                            <div className="row m-0">
                                <div className="col-md-8 col-lg-8 pr-2 mb-2 pl-0 px-2">
                                    <h6 className="document-content pt-3 pb-2">{
                                        fieldLabelsAndConditions[item.type] ? fieldLabelsAndConditions[item.type].label : item.type}</h6>
                                    <small className="document-content">
                                        {fieldLabelsAndConditions[item.type] ? fieldLabelsAndConditions[item.type].subLabel : '--'}
                                    </small>
                                </div>
                                <div className="col-md-4 col-lg-4 pr-5 mb-2 pl-0 mt-2">
                                    {item.documentId ?
                                        <span className="file-name">
                                            <span onClick={() => {
                                                window.open(item.documentId.url)
                                            }}>{item.documentId.fileName ? item.documentId.selectedFileName : item.documentId.fileName}</span>
                                            {!disableWolePage && <IconButton className="p-1" aria-label="delete" onClick={(e) => {
                                                deleteDocuments(null, item.type, index)
                                            }}>
                                                <CancelIcon className="file-close-color" fontSize="small" />
                                            </IconButton>}
                                        </span>
                                        : <Button variant="contained"
                                            component="label" color="secondary"
                                            className="rounded-button-dealer pl-4 pr-4 mt-2">
                                            Browse File
                                            <input type="file" hidden onChange={(e) => {
                                                handleInputChangeDocuments(e, item.type, index)
                                            }
                                            } />
                                        </Button>
                                    }
                                </div>
                            </div>
                        </div>
                        {index == 4 && <div className="card col-12 m-0 p-2">
                            <div className="row mt-3 mb-3">
                                <h6 className="dealer-table-content px-4 mt-0 mb-0">Additional Documents</h6><br />
                                <p className="info-sub-header-document">(Supported File Extension : .jpg, .jpeg, .png, .pdf, .doc, .docx, upto 2 MB can be uploaded.)</p>
                            </div>
                        </div>
                        }
                    </>
                })}
            </div>
            <div className="card col-lg-6 col-md-6 border-bottom mt-4 mb-4">
            </div>
            <div className="card col-lg-6 col-md-6 border-bottom mt-4 mb-4">
            </div>
            <div className="row card card-body p-2 fixed-bottom">
                <div className="col-12 display-flex">
                    <div className="col-2"></div>
                    <div className="col-6"></div>
                    <div className="col-4 onboard-footer">
                        <div className="p-3 mr-5">
                            <Button variant="contained" color="primary" className="rounded-pill ml-3 w-150p"
                                onClick={() => { submitForm() }}
                            > {updateMode ? "Update" : "Save"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <DeleteDialog
                open={openDeleteDialog}
                text="Are you sure you want to delete File ?"
                handleopenClose={(data: any) => {
                    if (data === 'delete') handleInputChangeDocuments(null, documentItem, documentIndex);
                    if (!data) handleopenClose(false);
                }} />
        </Fragment >
    );
}