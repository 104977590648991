/* eslint-disable */
import React from "react"

const NavigationArrow: React.FC = () => {
    return (
        <a href="javascript:void(0);" className="scroll-top d-none btn bg-333 text-white tp-btn position-fixed poppins-700 p-2 overflow-hidden d-none">
            <div className="d-flex"> 
                <span className="material-icons-outlined"> arrow_upward </span> 
                <span className="ml-3 text position-absolute"> Back to top</span> 
            </div>
        </a>
    )
}

export default NavigationArrow