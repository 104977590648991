import { API_ENDPOINTS, DELIVERY } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const deliveryService = {
    getDeliveryList,
    getDeliveryDetails,
    downloadInvoice
};

async function getDeliveryList(orderNumber: any, enquiryNumber: any, make: any, backOrderNumber: any, sapId: any, page?: any) {
    const apiPath = API_ENDPOINTS.DELIVERY + DELIVERY.LIST;
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: {
            "deliveryList": {
                "salesDocument": orderNumber ? orderNumber : "",
                "forwardOrderNumber": "",
                "shippingReceivingPoint": "",
                "description": "",
                "delivery": "",
                "pickingStatus": "",
                "shippingPlanningStatus": "",
                "goodsMovementStatus": "",
                "actualGoodsMovementDate": "",
                "billingStatus": "",
                "billingDateForBillingIndexPrintout": ""
            },
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "enquiryNumber": enquiryNumber,
            "make": make,
            "sapCustomerId": sapId,
        },
        customHeaders: {
            // "Paging-Info": `start-index=${page ? page : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getDeliveryDetails(enquiryNumber: any, deliveryNumber: any, make: any, sapId: any, page?: any) {
    const apiPath = API_ENDPOINTS.DELIVERY + DELIVERY.DETAILS;
    const options = {
        path: `${apiPath}`,
        method: "POST",
        bodyObj: {
            "sessionDetails": {
                "webApplicationLoginId": sessionStorage.getItem('webApplicationLoginId'),
                "clientIPAddress": sessionStorage.getItem('clientIp'),
            },
            "enquiryNumber": enquiryNumber,
            "delivery": deliveryNumber,
            "make": make,
            "sapCustomerId": sapId,
        },
        customHeaders: {
            "Paging-Info": `start-index=${page ? page * 10 : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function downloadInvoice(deliveryNumber: any, enquiryNumber: any) {
    const apiPath = API_ENDPOINTS.DELIVERY + `/delivery/invoice?deliveryNumber=${deliveryNumber}&orgId=${enquiryNumber}`;
    const options = {
        path: `${apiPath}`,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
        },
        FormData: true
    }
    return await makeRequest(options)
}