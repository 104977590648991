/* eslint-disable */
import React from "react"
import { useSelector } from "react-redux"


const About = (props: any) => {
  const features = useSelector((state: any) => {
    return state.home.features
  })

  return (
    <div className="section">
      <div className="container xl text-center">
        <h2 className="poppins-700 text-black">{props.pageData && props.pageData.Trade_Point && props.pageData.Trade_Point.heading}</h2>
        <p className="mt-4 font-16">{props.pageData && props.pageData.Trade_Point && props.pageData.Trade_Point.short_desc}</p>
        <div className="row mt-5 mb-3 justify-content-center">
          <div className="col-md-6 my-2 justify-content-center">
            {props.pageData && props.pageData.Trade_Point && props.pageData.Trade_Point.video_script_1 &&<iframe className="w-100 videoHomepPage" src={`//fast.wistia.net/embed/iframe/nuv2etjasd?wchannelid=t4vi3bpars&wvideoid=nuv2etjasd&videoFoam=true&playerColor=ff0000`} title="OES catalogue"   name="wistia_embed"   height="360"></iframe>}
          </div>
          <div className="col-md-6 my-2 justify-content-center">
            {props.pageData && props.pageData.Trade_Point && props.pageData.Trade_Point.video_script_2 &&<iframe className="w-100 videoHomepPage" src={`//fast.wistia.net/embed/iframe/hvu8roifbp?wchannelid=t4vi3bpars&wvideoid=nuv2etjasd&videoFoam=true&playerColor=ff0000`} title="OES catalogue"   name="wistia_embed"   height="360"></iframe>}
          </div>
        </div>
        <div className="row justify-content-center">
          {props.pageData && props.pageData.Trade_Point && props.pageData.Trade_Point.list && props.pageData.Trade_Point.list.map((ele: any) => {
            return (
              <div key={ele.id} className="col-sm-6 col-lg-3 mt-5">
                <img className="material-icons-outlined text-primary font-72 servicesImages" src={ele.image && ele.image.renditionList && ele.image.renditionList[0] && ele.image.renditionList[0].resourceUri} />
                <h5 className="text-black poppins-700 my-3">{ele.title}</h5>
                <p className="font-16">{ele.short_desc}</p>
              </div>
            )
          })}

          {/* <div className="col-sm-6 col-lg-3 mt-5"> 
              <span className="material-icons-outlined text-primary font-72"> speed </span>
              <h5 className="text-black poppins-700 my-3">Fastest Delivery</h5>
              <p className="font-16">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu</p>
            </div>
            <div className="col-sm-6 col-lg-3 mt-5"> 
              <span className="material-icons-outlined text-primary font-72"> verified </span>
              <h5 className="text-black poppins-700 my-3">Genuine Parts</h5>
              <p className="font-16">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod</p>
            </div>
            <div className="col-sm-6 col-lg-3 mt-5"> 
              <span className="material-icons-outlined text-primary font-72"> settings_suggest </span>
              <h5 className="text-black poppins-700 my-3">Best Service</h5>
              <p className="font-16">At vero eos et accusamus et iusto odio dignissimos ducimus qui</p>
            </div>
            <div className="col-sm-6 col-lg-3 mt-5"> 
              <span className="material-icons-outlined text-primary font-72"> local_police </span>
              <h5 className="text-black poppins-700 my-3">Trusted  Brands</h5>
              <p className="font-16">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantiu</p>
            </div> */}
        </div>
      </div>
    </div>
  )
}

export default About