import { requestSpecialPriceService } from "../../services/requestSpecialPrice.service";
import { REQUEST_SPECIAL_PRICE, VIEW_SELECTED_PARTS } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";

export const requestSpecialPriceActions = {
  getRequestSpecialPriceAPI,
  requestSpecialPriceAccessAPI,
  viewRequestData,
  requestModifyData,

};

export function getRequestSpecialPriceAPI(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(REQUEST_SPECIAL_PRICE.GET_REQUEST_SPECIAL_PRICE_FETCHING));
    try {
      const response = await requestSpecialPriceService.getRequestSpecialPriceAPI(data);
      dispatch(apiSuccess(REQUEST_SPECIAL_PRICE.GET_REQUEST_SPECIAL_PRICE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(REQUEST_SPECIAL_PRICE.GET_REQUEST_SPECIAL_PRICE_ERROR, error));
      return error;
    }
  };
}

export function requestSpecialPriceAccessAPI(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(REQUEST_SPECIAL_PRICE.REQUEST_SPECIAL_PRICE_ACCESS_FETCHING));
    try {
      const response = await requestSpecialPriceService.requestSpecialPriceAccessAPI(data);
      dispatch(apiSuccess(REQUEST_SPECIAL_PRICE.REQUEST_SPECIAL_PRICE_ACCESS_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(REQUEST_SPECIAL_PRICE.REQUEST_SPECIAL_PRICE_ACCESS_ERROR, error));
      return error;
    }
  };
}

export function getViewRequestStatusAPI(B2bEnquiryId: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(REQUEST_SPECIAL_PRICE.VIEW_SPECIAL_PRICE_FETCHING));
    try {
      const response = await requestSpecialPriceService.getViewRequestStatusAPI(B2bEnquiryId);
      dispatch(apiSuccess(REQUEST_SPECIAL_PRICE.VIEW_SPECIAL_PRICE_SUCCESS, response));
      return response || [];
    } catch (error) {
      dispatch(apiError(REQUEST_SPECIAL_PRICE.VIEW_SPECIAL_PRICE_ERROR, error));
      return error;
    }
  };
}

export function viewRequestData(data: any) {
  return async (dispatch: any) => {
    let viewData = {
      viewRequestData: data
    }
    dispatch(apiStart(VIEW_SELECTED_PARTS.VIEW_SELECTED_SUCCESS, viewData))
  }
}

export function requestModifyData(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(VIEW_SELECTED_PARTS.MODIFY_SPR_DATA_SUCCESS, data))
  }
}

