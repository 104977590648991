import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { logout } from '../../store/actions/account.actions';
import { useDispatch } from 'react-redux';
import { signOut, useUserDispatch } from '../../context/UserContext';
import { useMsal } from '@azure/msal-react';
import { logoutUser } from "../../store/actions/logon.actions";

const useStyles = makeStyles(() => ({
  icon: {
    float: "left",
    margin: "0 20px 20px 0",
    height: "100%",
    fill: "white",
  },
  yes: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#1A9FE0',
    textTransform: 'capitalize'
  },
  cancel: {
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    color: '#919191',
    textTransform: 'capitalize'
  },
  logoutText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  }
}));

export default function EstimationPromtDialog(props: any) {

  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const userDispatch = useUserDispatch();
  const { instance } = useMsal();

  return (
    <div className="float-left">
      <Dialog
        open={props.open ? true : false}
        onClose={() => { props.handleopenClose(false) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
      >
        <DialogContent>
          <div className={classes.icon}>
            <WarningIcon className="delete-warning" fontSize="small" />
          </div>
          <div>
            <DialogContentText component="span" id="alert-dialog-description">
              <div className={`mt-3 ${classes.logoutText}`}>
                {props.text}
              </div>
            </DialogContentText>
          </div>
        </DialogContent>
        <DialogActions>
          <Button className={`mr-4 ${classes.yes}`} onClick={() => { props.handleopenClose(false) }} >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  );
}
