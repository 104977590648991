import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const myscheduleService = {
    getMyScheduleList,
    createSchedule,
    editSchedule,
    deleteSchedule,
};

async function getMyScheduleList(date?: any) {
    const apiPath = API_ENDPOINTS.MY_SCHEDULE
    const options = {
        // path: `${apiPath}/getScheduledLeaves`,
        path: `${apiPath}/getScheduledLeaves/${date}`,
        method: "GET",
        bodyObj: {
        },
        // customHeaders: {
        //     "userId": sessionStorage.getItem('webApplicationLoginId'),
        //     "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        // },
        formdata: true
    };
    return await makeRequest(options)
}

async function createSchedule(data: any) {
    const apiPath = API_ENDPOINTS.MY_SCHEDULE;
    const options = {
        path: `${apiPath}/addScheduledLeave`,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true,
    };
    return await makeRequest(options)
}

async function editSchedule(data: any) {
    const apiPath = API_ENDPOINTS.MY_SCHEDULE;
    const options = {
        path: `${apiPath}/updateScheduledLeave`,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "Lob-Id": "AUTO",
            // "Channel-Id": "B2B",
        },
        formdata: true,
    };
    return await makeRequest(options)
}

async function deleteSchedule(data: any) {
    const apiPath = API_ENDPOINTS.MY_SCHEDULE;
    const options = {
        path: `${apiPath}/deleteScheduledLeave`,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            // "Lob-Id": "AUTO",
            // "Channel-Id": "B2B",
        },
        formdata: true,
    };
    return await makeRequest(options)
}
