import { SPECIAL_PRICE, SPECIAL_PRICE_KAM } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  specialPriceKam: null,
  specialPriceStatusKam: null,
  specialPriceSaveFormAPI: null,
  forwardRequestToKam: null,
  saveFormData: null,
  rejectPartData: null,
  rejectRequestData: null,
  checkandavailibilityData: null,
  checkAvailabilityResetData: null,
  forwardToKamData: null,
  requestCorrectionKamData: null,
  reopenedSpecialRequestData: null
};

function specialPriceKam(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case SPECIAL_PRICE_KAM.GET_SPECIALPRICE_LIST_FETCHING:
      return {
        ...state,
        specialPriceKam: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE_KAM.GET_SPECIALPRICE_LIST_SUCCESS:
      return {
        ...state,
        specialPriceKam: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE_KAM.GET_SPECIALPRICE_LIST_ERROR:
      return {
        ...state,
        specialPriceKam: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE_KAM.GET_SPECIALPRICE_STATUS_FETCHING:
      return {
        ...state,
        specialPriceStatusKam: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE_KAM.GET_SPECIALPRICE_STATUS_SUCCES:
      return {
        ...state,
        specialPriceStatusKam: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE_KAM.GET_SPECIALPRICE_STATUS_ERROR:
      return {
        ...state,
        specialPriceStatusKam: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE_KAM.VIEW_SPECIALPRCIEREQUEST_FETCHING:
      return {
        ...state,
        viewSpecialPriceKam: { loading: true, data: null, err: action.payload }
      }
    case SPECIAL_PRICE_KAM.VIEW_SPECIALPRICEREQUEST_SUCCESS:
      return {
        ...state,
        viewSpecialPriceKam: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE_KAM.VIEW_SPECIALRICEREQUEST_ERROR:
      return {
        ...state,
        viewSpecialPriceKam: { loading: false, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_FETCHING:
      return {
        ...state,
        addComments: { loading: true, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        addComments: { loading: false, data: action.payload, err: null }
      }

    case SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_ERROR:
      return {
        ...state,
        addComments: { loading: false, data: null, err: action.payload }
      }
    //  case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_FETCHING:
    //  return {
    //    ...state,
    //    getCommentsPart:{loading:true,data:null,err:action.payload}
    //  }   
    //  case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_SUCCESS:
    //  return {
    //    ...state,
    //    getCommentsPart:{loading:false,data:action.payload,err:null}
    //  }  
    //  case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_ERROR:
    //  return {
    //    ...state,
    //    getCommentsPart:{loading:false,data:null,err:action.payload}
    //  },
    case SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_FETCHING:
      return {
        ...state,
        forwardRequestToKam: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_SUCCESS:
      return {
        ...state,
        forwardRequestToKam: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_ERROR:
      return {
        ...state,
        forwardRequestToKam: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.SAVE_FORM_API_FETCHING:
      return {
        ...state,
        saveFormData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.SAVE_FORM_API_SUCCESS:
      return {
        ...state,
        saveFormData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.SAVE_FORM_API_ERROR:
      return {
        ...state,
        saveFormData: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_FETCHING:
      return {
        ...state,
        getCommentsPart: { loading: true, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        getCommentsPart: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_ERROR:
      return {
        ...state,
        getCommentsPart: { loading: false, data: null, err: action.payload }
      }
    case SPECIAL_PRICE_KAM.ADDCOMMENTTOTAL_SPECIALPRICE_FETCHING:
      return {
        ...state,
        addCommentsPartTotalKam: { loading: true, data: null, err: null }
      }
    case SPECIAL_PRICE_KAM.ADDCOMMENTTOTAL_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        addCommentsPartTotalKam: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE_KAM.ADDCOMMENTTOTAL_SPECIALPRICE_ERROR:
      return {
        ...state,
        addCommentPartsTotalKam: { loading: false, data: null, err: action.payload }
      }
    case SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_FETHCING:
      return {
        ...state,
        getCommentsPartsTotalKam: { loading: true, data: action.payload, err: null }
      }
    case SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        getCommentsPartsTotalKam: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_ERROR:
      return {
        ...state,
        getCommentsPartsTotalKam: { loading: false, data: null, err: action.payload }
      }

    case SPECIAL_PRICE.REJECT_BY_PART_API_FETCHING:
      return {
        ...state,
        rejectPartData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.REJECT_BY_PART_API_SUCCESS:
      return {
        ...state,
        rejectPartData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.REJECT_BY_PART_API_ERROR:
      return {
        ...state,
        rejectPartData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.REJECT_REQUEST_API_FETCHING:
      return {
        ...state,
        rejectRequestData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.REJECT_REQUEST_API_SUCCESS:
      return {
        ...state,
        rejectRequestData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.REJECT_REQUEST_API_ERROR:
      return {
        ...state,
        rejectRequestData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.CHECK_AVALIABLILITY_API_FETCHING:
      return {
        ...state,
        checkandavailibilityData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.CHECK_AVALIABLILITY_API_SUCCESS:
      return {
        ...state,
        checkandavailibilityData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.CHECK_AVALIABLILITY_API_ERROR:
      return {
        ...state,
        checkandavailibilityData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.CHECK_AVALIABLILITY_RESET_DATA:
      return {
        ...state,
        checkandavailibilityData: null
      };

    case SPECIAL_PRICE.POST_REQUEST_CORRECTION_KAM_FETCHING:
      return {
        ...state,
        requestCorrectionKamData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_REQUEST_CORRECTION_KAM_SUCCESS:
      return {
        ...state,
        requestCorrectionKamData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_REQUEST_CORRECTION_KAM_ERROR:
      return {
        ...state,
        requestCorrectionKamData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.POST_FORWARD_TO_CUSTOMER_FETCHING:
      return {
        ...state,
        forwardToKamData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_FORWARD_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        forwardToKamData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_FORWARD_TO_CUSTOMER_ERROR:
      return {
        ...state,
        forwardToKamData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.POST_STATUS_REOPENED_API_FETCHING:
      return {
        ...state,
        reopenedSpecialRequestData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_STATUS_REOPENED_API_SUCCESS:
      return {
        ...state,
        reopenedSpecialRequestData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_STATUS_REOPENED_API_ERROR:
      return {
        ...state,
        reopenedSpecialRequestData: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE_KAM.GET_REQUEST_CORRECTION__KAM_FETCHING:
      return {
        ...state,
        requestCorrectionKam: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE_KAM.GET_REQUEST_CORRRECTION_KAM_SUCCES:
      return {
        ...state,
        requestCorrectionKam: { loading: true, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE_KAM.GET_REQUEST_CORRECTION_KAM_ERROR:
      return {
        ...state,
        requestCorrectionKam: { loading: true, data: null, err: null }
      };
    default:
      return state;
  }
};

export default specialPriceKam;