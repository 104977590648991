/* eslint-disable */
import React, { useEffect, useCallback } from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useDropzone } from 'react-dropzone'
import CancelIcon from "@material-ui/icons/CancelRounded";
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { getFSEQuotation, uploadLpo } from '../../store/actions/quotation.action';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { uploadLpoEmail } from "../../store/actions/quotation.action";
import "./Quotation.css"
import { getQuotation } from "../../store/actions/quotation.action";
import FullscreenLoader from "../../common/components/FullscreenLoader";

const useStyles = makeStyles((theme) => ({
    collapseBg: {
        background: 'rgba(66, 94, 108, 0.07)'
    },
    rowHighlight: {
        background: 'rgba(67, 95, 107, 0.15)',
        color: '#666666',
        fontWeight: 700
    },
    orangeRowHighlight: {
        background: 'rgba(240, 223, 0, 0.12)',
        color: '#666666',
        fontWeight: 700
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 140,

    },
    bodyContentSmall: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 40,
    },
    collapseIcon: {
        color: '#000000',
        fontWeight: 500,
    },
    tableHeadSmall: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 40,
    },
    tableHeadSmallBrand: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 200,
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        lineHeight: 0,
        width: 140,
    },
    subTableHead: {
        color: '#444444',
        fontWeight: 100,
        width: 135,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead1: {
        color: '#444444',
        fontWeight: 450,
        width: 90,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead2: {
        color: '#444444',
        fontWeight: 800,
        width: 97,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead3: {
        color: '#444444',
        fontWeight: 800,
        width: 150,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead4: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead5: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHead6: {
        color: '#444444',
        fontWeight: 800,
        width: 140,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    subTableHeadSmall: {
        color: '#444444',
        fontWeight: 800,
        width: 40,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
    },
    actionButton: {
        color: '#1A9FE0',

    },
    actionRequest: {
        color: "white !important",
        background: '#1A9FE0',
        borderRadius: "14px",
        paddingLeft: 8,
        height: 20,
        width: 20,
        "&:hover": {
            background: "#1A9FE0",
            color: "white !important",
            borderRadius: "14px",
            paddingLeft: 8,
            height: 20,
            width: 20,
        },
    },
    requestQuestion: {
        color: '#1A9FE0',
        "&:hover": {
            background: "yellow",

        },

    },
    subTableBodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 140,
    },
    subTableBodyContentSmall: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito',
        padding: 10,
        width: 40,
    }
}));

const UploadLpo = (props: any) => {
    const { quotationNumber, sapId, enquiryNumber, brandId, orderNumber, status, value, myQuotation, user, page } = props;

    const { openUploadLpo } = props;
    const [open, setOpen] = React.useState(false);
    const [uploadedFileName, setuploadedFileName] = React.useState('');
    const [uploadedFileNameError, setuploadedFileNameError] = React.useState(false);
    const [error, setError] = React.useState<any>('');
    const [size, setSize] = React.useState<any>(0);
    const [lpoNumber, setLpoNumber] = React.useState("")
    const [lpoError, setLpoError] = React.useState(false)
    const [file, setFile] = React.useState<any>("");
    const [termsAndConditions, setTermsAndConditions] = React.useState(false)
    const [openSnackbar, setOpenSnackBar] = React.useState<any>(false)
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false)
    const [successMessage, setSuccessMessage] = React.useState("")
    const [validateError, setValidateError] = React.useState(false);
    const classes = useStyles()
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const { lpoQuotation, quotationEmail }: any = useSelector((state: any) => state.quotation)
    const dispatch: any = useDispatch()

    let sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
    if (sessionStorage.getItem('userType') === 'FSE') {
        sapCustomerId = sapId;
    } else {
        sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
    }
    function checkLength(quantity: any) {
        return quantity.toString().length <= 5;
    }

    const fileUploadChange = useCallback((data: any) => {
        if (sessionStorage.getItem('userType') === 'FSE') {
            if (data[0] && data[0].name.toLowerCase() && (data[0].name.toLowerCase().includes('.pdf') || data[0].name.toLowerCase().includes('.png') || data[0].name.toLowerCase().includes('.jpg') || data[0].name.toLowerCase().includes('.jpeg'))) {
                if (Number(data[0] && data[0].name.toLowerCase() && (data[0].size)) < 10485760) {
                    // readFile(data[0]);
                    setError('');
                    setuploadedFileNameError(false);
                    setuploadedFileName("")
                } else {
                    setError('File should not exceed 10mb');
                    setuploadedFileNameError(false);
                    setuploadedFileName(data[0] && data[0].name)
                }
            } else {
                setError('Supported File Extensions: .jpg, .jpeg, .png, .pdf etc.');
                setuploadedFileNameError(false);
                setuploadedFileName(data[0] && data[0].name)
            }
        } else {
            if (data[0] && data[0].name.toLowerCase() && data[0].name.toLowerCase().includes('.pdf')) {
                if (Number(data[0] && data[0].name.toLowerCase() && (data[0].size)) < 10485760) {
                    // readFile(data[0]);
                    setError('');
                    setuploadedFileNameError(false);
                    setuploadedFileName("")
                } else {
                    setError('File should not exceed 10mb');
                    setuploadedFileNameError(false);
                    setuploadedFileName(data[0] && data[0].name)
                }
            } else {
                setError('Selected file is not an pdf file.');
                setuploadedFileNameError(false);
                setuploadedFileName(data[0] && data[0].name)
            }
        }
        let uploader: any = document.getElementById('storesfileupload');
        if (uploader) uploader.value = "";
    }, []) // eslint-disable-line

    const onDrop = useCallback((acceptedFiles: any) => {
        setSize(acceptedFiles[0].size)
        fileUploadChange(acceptedFiles)
        setFile(acceptedFiles && acceptedFiles[0])
        setuploadedFileName(acceptedFiles[0] && acceptedFiles[0].name)
    }, [fileUploadChange])

    useEffect(() => {
        if (uploadedFileName && !uploadedFileNameError && error === '') {
            setOpen(false);
        }
    }, [uploadedFileName, uploadedFileNameError, error])

    useEffect(() => {
        if (!open) {
            setuploadedFileName('');
            setuploadedFileNameError(false);
            setError('');
            setSize("")
            setFile("")
        }
    }, [open, openUploadLpo])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const convertToSize = (value: any) => {
        // return Math.abs(Number(value)) >= 1.0e+9
        //     ? (Math.abs(Number(value)) / 1.0e+9).toFixed(2) + "BYTES"
        //     : Math.abs(Number(value)) >= 1.0e+6
        //         ? (Math.abs(Number(value)) / 1.0e+6).toFixed(2) + "MB"
        //         : Math.abs(Number(value)) >= 1.0e+3
        //             ? (Math.abs(Number(value))) + "KB"
        //             : Math.abs(Number(value));

        let Value = Number(value) / 1024
        return Math.round(Value) + "KB"
    }


    const handleLpo = (e: any) => {
        setLpoNumber(e.target.value)
        if (e.target.value.length <= 35) {
            setValidateError(false)
        } else if (e.target.value.length > 35) {
            setValidateError(true)
        }
    }

    const handleSubmit = async () => {
        const formData: any = new FormData();

        const fileName = file && file.name.substring(file.name.lastIndexOf('/') + 1);
        const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);

        let keyAttribute = `Brand=""&fieldname=${"lpo"}&docExtension=${fileExtension}`
        const customHeaders = {
            "Accept": "application/json",
            "Lob-Id": "AUTO",
            "Channel-Id": "B2B",
            "Org-Id": "",
            "Accept-Language": "EN",
        };

        formData.append("entityName", "CUSTOMER");
        formData.append("keyAttribute", keyAttribute);
        formData.append("Attributes", `key=Brand&value=${file}`);
        formData.append("document", 'false');
        formData.append("sapCustomerId", sapCustomerId);
        formData.append("uploadedByRole", sessionStorage.getItem('userType') === 'FSE' ? 'FSE' : 'dealerUser');
        formData.append("uploadedBywebApplicationLoginId", sessionStorage.getItem('webApplicationLoginId'));
        formData.append("docType", 'lpo');
        formData.append("fileUpload", file);
        formData.append("label", 'lpoFile');
        formData.append("orgId", brandId);
        formData.append("quotationNumber", quotationNumber);
        formData.append("b2bEnquiryId", enquiryNumber);
        formData.append("lpoNumber", lpoNumber);
        formData.append("selectedFileName", file.name);
        formData.append("orderNumber", orderNumber);

        let data = await dispatch(uploadLpo(formData, customHeaders))


        if (data && data.error) {
            setOpenErrorSnackbar(true);
            setSuccessMessage(data.error);
            setOpenSnackBar(true);
        } else {

            let data: any = {
                quotationNumber: quotationNumber,
                orderNumber: orderNumber,
                sapCustomerId: sapCustomerId,
                B2bEnquiryId: enquiryNumber,
                lpoUploadedFrom: "quotationlist"
            };

            await dispatch(uploadLpoEmail(data));

            if (sessionStorage.getItem('userType') === 'FSE') {
                let payload = {
                    "userRole": "FSE",
                    "loginUserId": sessionStorage.getItem('webApplicationLoginId'),
                    "openQuotation": false,
                    "isDateFilterApply": false,
                    "fromDate": "",
                    "toDate": "",
                    "filterByStatus": "",
                    "searchBy": "",
                    "filterByCustomer": [],
                    "filterByInterUserIds": [sessionStorage.getItem('webApplicationLoginId')]
                }
                dispatch(getFSEQuotation(payload, page))
            } else {
                let quotation = {
                    "filterBy": status === "All" ? "" : status,
                    "searchBy": value,
                    "myQuotation": myQuotation || user ? true : false,
                    "webApplicationLoginId": user ? user : sessionStorage.getItem('webApplicationLoginId'),
                    "sapCustomerId": sapCustomerId,
                }
                dispatch(getQuotation(quotation, page))
            }

            setSuccessMessage("LPO Uploaded Successfully");
            setOpenSnackBar(true);
            setOpenErrorSnackbar(false);
            props.handleUploadLpo();
            setLpoNumber("")
            setTermsAndConditions(false);
        }
    }


    const handleTermsAndCondition = (e: any) => {
        setTermsAndConditions(e.target.checked)
    }


    const handleopenCloseSnackbar = (open: any) => {
        setOpenSnackBar(open ? true : false);
    }

    const handleDownload = () => {
        const url = window.URL.createObjectURL(new Blob([file]));
        const link = document.createElement("a")
        link.href = url
        link.download = uploadedFileName
        document.body.appendChild(link)
        link.click()
    }




    return (
        <div>
            <React.Fragment>
                {(lpoQuotation && lpoQuotation.loading || quotationEmail && quotationEmail.loading) && <FullscreenLoader />}
                <Snackbar
                    open={openSnackbar}
                    type={openErrorSnackbar ? 'error' : ''}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} message={successMessage} />

                <span className="cursor-pointer mr-2" onClick={(e) => {
                    setuploadedFileName('');
                    setuploadedFileNameError(false);
                    e.preventDefault();
                    setOpen(true);
                }}>
                </span>

                <Dialog fullWidth={true} maxWidth="sm" open={props.openUploadLpo ? true : false} onClose={() => {
                    props.handleUploadLpo();
                    setuploadedFileName('');
                    setuploadedFileNameError(false);
                    setError('');
                    setSize("")
                    setFile("")
                    setLpoNumber("")
                    setTermsAndConditions(false);
                }} aria-labelledby="form-dialog-title">
                    <DialogContent>
                        <div className="d-flex justifyContentHorizontalSpacebetween ">
                            <h4 className="page-title">Upload Revised LPO.</h4>
                        </div>
                        <div className="d-flex mt-3 py-4 dropzoneImport" {...getRootProps()}>
                            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center dashed-right-border">

                                <div className="dragHereIcon justify-content-center">
                                    <CloudUploadIcon fontSize="large" />
                                </div>
                                <div className="dragHereText ml-2">Drag your LPO file here</div>
                                <input className="d-none" type="file" hidden id="productsfileupload"{...getInputProps()} />

                            </div>
                            <div className="d-flex col-lg-6 col-md-6 col-sm-12 justify-content-center align-items-center">
                                <Button
                                    className="font-size-11 addToEnquiryButton"
                                    size="small"
                                    // onClick={() => {
                                    //   fileUploadAction()
                                    // }}
                                    variant="contained" color="primary">Browse LPO</Button>
                            </div>
                        </div>


                        {/* {uploadedFileName && !uploadedFileNameError && error === '' && <div className="d-flex font-weight-bold justify-content-center mt-2 text-success">{uploadedFileName} imported succesfully!</div>} */}
                        {uploadedFileNameError && <div className="d-flex font-weight-bold justify-content-center mt-2 text-danger">{uploadedFileName} - Invalid data format</div>}


                        {uploadedFileName &&
                            <div className="d-flex flex row pl-4">
                                <div className="mt-2 row">
                                    <div className="file uploadFile" onClick={handleDownload}>{uploadedFileName} ({convertToSize(size)}/10MB)</div>
                                    <div className="pl-2" onClick={() => {
                                        setuploadedFileName('');
                                        setuploadedFileNameError(false);
                                        setError('');
                                        setSize("")
                                        setFile("")
                                    }}>
                                        <IconButton className={`${classes.actionButton}`} size="small">
                                            <CancelIcon fontSize="inherit" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>}

                        {error && <div className="d-flex font-weight-bold justify-content-center mt-2 text-danger">{uploadedFileName} - {error}</div>}
                        <div className="col-7 pl-0 ml-0 mt-2">
                            <TextField id="customer Number"
                                value={lpoNumber}
                                error={lpoError}
                                onChange={(e: any) => handleLpo(e)}
                                size="small" className="col-sm-12 my-2 pl-0 ml-0 commonRoundedInputs hiddenNo"
                                type="text"
                                label="LPO Number" required variant="outlined" margin="dense" />
                            {validateError && <div className="d-flex font-weight-bold justify-content-center pl-0 ml-1 mt-2 text-danger">LPO Number Should Not Exceed 35 Characters</div>}
                        </div>
                        {/* Un comment after Terms and conditions cms api is done and add termsAndConditions check on below submit button */}

                        {/* <div className="row mt-2">   <Checkbox checked={termsAndConditions} onChange={handleTermsAndCondition} /> <div className="d-flex flex-row mt-2 margin-text quotation-text "> By submitting you are accepting the <div className="pl-1 pr-1 color-text" style={{ textDecoration: "underline" }}>{" "}{"T&C"}</div></div> </div> */}
                    </DialogContent>
                    <DialogActions className="mt-2 mb-4 justify-content-end">
                        <div className="submit-margin">
                            <Button variant="contained" className={`submit-button font-size-11 mr-3 pt-4 ${lpoNumber && file.size && !error && !uploadedFileNameError && !validateError ? "" : "lpo-button-disabled"}`} onClick={() => { setOpen(false); handleSubmit() }} >
                                Submit
                            </Button>
                        </div>
                        <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => { props.handleUploadLpo(); setLpoNumber(""); setTermsAndConditions(false); }}>
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        </div>
    )
}
export default UploadLpo