/* eslint-disable */
import _ from "lodash";
import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const requestQuoatationServices = {
    postRequestQuotation, postOpenQuotation, viewLPO
}

export const quotation = {
    getQuotation, uploadRequestOrder, uploadLpo, getQuotationListStatus,
    cancelQuotation, getUser, downloadQuotation, uploadLpoEmail, getFSEQuotation
}

async function postRequestQuotation(data: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {},
    }
    return await makeRequest(options)
}

export async function postOpenQuotation(data: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/openQuotation`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {},
    }
    return await makeRequest(options)
}

export async function viewLPO(webApplicationLoginId: any, enqId: any, sapID: any, id: any, orderNumber: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/document?webApplicationLoginId=${webApplicationLoginId}&masterEnquiryId=${enqId}&sapEnquiryId=${sapID}&quotationNumber=${id}&orderNumber=${orderNumber}`
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}

export async function getQuotation(data: any, page: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/list`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}

export async function getFSEQuotation(data: any, page?: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/list/sapInterUser`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
            "Paging-Info": `start-index=${page ? (parseInt(page) * 10) : 0}|no-of-records=10`
        },
        formdata: true
    };
    return await makeRequest(options)
}



export async function getQuotationListStatus() {
    const apiPath = API_ENDPOINTS.QUOTATION + "/users/formField/quotationStatusList"

    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {},
        FormData: true,
    }

    return await makeRequest(options)
}

export async function uploadRequestOrder(data: any) {
    const path = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/request-order`
    const options = {
        path,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

export async function uploadLpo(data: any, customHeaders: any) {
    const path = API_ENDPOINTS.QUOTATION_REQUEST + '/documents';
    const options = {
        path,
        method: "POST",
        bodyObj: data,
        customHeaders,
        formdata: true
    };
    return await makeRequest(options)
}

export async function cancelQuotation(data: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/cancel`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)

}

export async function getUser(data: any,) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/userList/${data}?webApplicationLoginId=${sessionStorage.getItem('webApplicationLoginId')}`
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
        formdata: false
    };
    return await makeRequest(options)

}

export async function downloadQuotation(enqId: any, id: any, brandId: any, type?: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/details/quotation?webApplicationLoginId=${sessionStorage.getItem('webApplicationLoginId')}&masterEnquiryId=${enqId}&quotationNumber=${id}&brandId=${brandId}&qType=${type}`
    const options = {
        path: apiPath,
        method: "GET",
        bodyObj: {},
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)

}

export async function uploadLpoEmail(data: any) {
    const apiPath = API_ENDPOINTS.QUOTATION_REQUEST + `/quotation/uploadLpoEmail`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {
        },
        formdata: true
    };
    return await makeRequest(options)
}