/* eslint-disable */
import React from "react"
import Dialog from '@material-ui/core/Dialog';
import TermsAndConditionsLandingPageDialog from "./TermsAndConditionsLandingPageDialog";

const Footer = (props: any) => {
  const [open, setOpen] = React.useState(false);
  const [propsData, setPropsData] = React.useState(false);

  const handleOpenClose = (isOpen: any) => {
    setOpen(isOpen);
    setPropsData(props?.pageData);
  };

  const handleClosePopup = () => {
    setOpen(false);
  }

  return (
    <footer className="py-0 bg-blue" id="contactus">
      <Dialog className="pb-0"
        open={open}
        onClose={() => handleOpenClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <TermsAndConditionsLandingPageDialog propsData={propsData} handleClosePopup={handleClosePopup} />
      </Dialog>
      <div className="px-5 text-white">
        <div className="row align-items-center">
          <div className="col-lg-3 text-center">© Copyright 2022 Al-Futtaim</div>
          <div className="col-lg-3 text-center">
            <li className="d-inline-block my-2 my-sm-0 text-white" ><a onClick={() => { handleOpenClose(true); }}>Terms & Conditions</a></li>
          </div>
          <div className="col-lg-3 text-center">
            <ul className="m-0 footer-menu">
              <li className="d-inline-block my-2 my-sm-0"><a href="mailto:alac.tradepoint@alfuttaim.com" className="text-white">Contact Us</a></li>
            </ul>
          </div>
          <div className="col-lg-3 text-center">
            {props && props.pageData && props.pageData.logo && props.pageData.logo.autocenter_logo ? (
              <a className="navbar-brand" href="javascript:void(0);"> <img height={90} src={props.pageData.logo.autocenter_logo} alt="logo" /> </a>
            ) : (<img src="./ALAC-logo-Desktop.png" height={90} />)}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer