/* eslint-disable */
import { QA_ENV, PRE_PROD_ENV, PRODUCTION_ENV, PRODUCTION_TEST_ENV } from './../authConfig'

export const FETCH_TIMEOUT = 300000;
export const LOADING_INDICATOR_DELAY = 5000;

/* Internet facing public URL DEV */
export const APP_DEV_PUBLIC_URL = "http://localhost:3000";
/* DEV ENVIRONMENT */
export const APP_DEV_URL = "http://localhost:3000";
/* TEST ENVIRONMENT */
export const APP_TEST_URL = "http://localhost:3000";
/* PROD ENVIRONMENT */
export const APP_PROD_URL = "http://localhost:3000";
// const appName = process.env.REACT_APP_APP_NAME || "AutoB2B";
var confAppURL = process.env.REACT_APP_APP_URL || "https://autob2bdev.corp.al-futtaim.com"; //"https://autob2bqa.corp.al-futtaim.com";
var bffLayer = process.env.REACT_APP_BFF_LAYER_URL || "https://autob2bdev.corp.al-futtaim.com"; //"https://autob2bbffqa.corp.al-futtaim.com";
var bffPrefix = process.env.REACT_APP_BFF_LAYER_PREFIX || "/automotive/b2b/v1";
var dev_url = "https://autob2bdev.corp.al-futtaim.com"
var tecDocUrl = "https://web.stg.tecalliance.net";
var qaEnqBffLayer = "https://autob2bdev.corp.al-futtaim.com"; //"https://autob2bqa.corp.al-futtaim.com";

if (QA_ENV == window.location.hostname.toLowerCase()) {
    confAppURL = 'https://autob2bqa.corp.al-futtaim.com'
    bffLayer = 'https://autob2bbffqa.corp.al-futtaim.com'
    qaEnqBffLayer = "https://autob2bqa.corp.al-futtaim.com"
    tecDocUrl = "https://web.test.tecalliance.net";
} else if (PRODUCTION_TEST_ENV == window.location.hostname.toLowerCase()) {
    confAppURL = 'https://tradepoint.corp.al-futtaim.com'
    bffLayer = 'https://tradepoint.corp.al-futtaim.com'
    qaEnqBffLayer = "https://tradepoint.corp.al-futtaim.com";
    tecDocUrl = "https://web.tecalliance.net" // Need to change as per techalliance team
} else if (PRE_PROD_ENV == window.location.hostname.toLowerCase()) {
    confAppURL = 'https://aftradepoint.corp.al-futtaim.com'
    bffLayer = "https://autob2bbffpp.corp.al-futtaim.com"
    qaEnqBffLayer = "https://aftradepoint.corp.al-futtaim.com";
    tecDocUrl = "https://web.test.tecalliance.net" // Need to change as per techalliance team
} else if (PRODUCTION_ENV == window.location.hostname.toLowerCase()) {
    confAppURL = 'https://tradepoint.al-futtaimauto.com'
    bffLayer = 'https://tradepoint.al-futtaimauto.com'
    qaEnqBffLayer = "https://tradepoint.al-futtaimauto.com";
    tecDocUrl = "https://web.tecalliance.net" // Need to change as per techalliance team
}

export var API_ENDPOINTS = {
    MAIN_ENDPOINT: "http://localhost:3000",
    CORS_URL: "https://cors-anywhere.herokuapp.com/",
    TEC_DOC_URL: tecDocUrl,
    GET_TOKEN_ENDPOINT: `${bffLayer}${bffPrefix}/users/logon/jwtToken`,
    GET_KAM_TOKEN_ENDPOINT: `${bffLayer}${bffPrefix}/users/oauth/kam`,
    VALIDATE_TOKEN_ENDPOINT: `${bffLayer}${bffPrefix}/users/logon/validateToken`,
    LOGON_ENDPOINT: `${bffLayer}${bffPrefix}`,
    PRODUCTS_ENDPOINT: `${qaEnqBffLayer}${bffPrefix}`,
    CREATE_UPDATE_ENQUIRY: `${qaEnqBffLayer}${bffPrefix}`,
    ENQUIRY: `${qaEnqBffLayer}${bffPrefix}`,
    DEALER_ENDPOINT: `${bffLayer}${bffPrefix}/users`,
    KAM_REVIEW_ENDPOINT: `${bffLayer}${bffPrefix}/users`,
    PROFILE_ENDPOINT: `${bffLayer}${bffPrefix}/users/userProfile`,
    PROFILE_ENDPOINT_PLACEDSUMMARY: `${bffLayer}${bffPrefix}/users/getUserDetailsForPayment`,
    ONBOARD: `${bffLayer}${bffPrefix}`,
    COMMUNICATION_ENDPOINT: `${bffLayer}${bffPrefix}`,
    CMS_ENDPOINT: `${bffLayer}${bffPrefix}/cms`,
    EMAIL_ENDPOINT: `${bffLayer}${bffPrefix}/cms/comms/email`,
    ON_BOARDING_URL: `${confAppURL}`,
    KAMUSERS: `${bffLayer}${bffPrefix}/users/kamUsers`,
    CREDIT_LIMIT_NEW: `${bffLayer}${bffPrefix}/users/dealer/creditLimit/dealer`,
    CREDIT_LIMIT_NEW_ACHIEVEMENT: `${bffLayer}${bffPrefix}/users/dealer`,
    RETURN_REQUEST: `${qaEnqBffLayer}${bffPrefix}/enquiries/orders/return-request`,
    CREDIT_LIMIT: `${bffLayer}${bffPrefix}/users/creditLimit`,
    SPECIAL_PRICE: `${bffLayer}${bffPrefix}/enquiries/specialPrice`,
    QUOTATION_REQUEST: `${qaEnqBffLayer}${bffPrefix}/enquiries`,
    QUOTATION: `${bffLayer}${bffPrefix}`,
    CREDIT_REQUEST: `${bffLayer}${bffPrefix}/users/onboarding`,
    TASKS: `${bffLayer}${bffPrefix}/users/openTask`,
    OPENTASK: `${bffLayer}${bffPrefix}/users/customer/getOpenTasks`,
    REVIEWDOCUMENT: `${bffLayer}${bffPrefix}/users/customer/getReviewDocument`,
    APPROVEDOCUMENT: `${bffLayer}${bffPrefix}/users/customer/documentReview`,
    DELIVERY: `${qaEnqBffLayer}${bffPrefix}/enquiries/orders`,
    MATERIAL_GROUPS: `${qaEnqBffLayer}${bffPrefix}/enquiries/getMaterialGroup`,
    SPECIAL_PRICE_REQUEST: `${bffLayer}${bffPrefix}/enquiries/specialPrice/special-price-request-list`,
    SPECIAL_PRICE_REQUEST_TO_KAM: `${bffLayer}${bffPrefix}/enquiries/specialPrice`,
    SPECIAL_PRICE_STATUS: `${bffLayer}${bffPrefix}/users/formField/specialPriceRequestStatus`,
    SPECIAL_PRICE_VIEWSPECIALPRICE: `${bffLayer}${bffPrefix}/enquiries/specialPrice/special-price-request-list`,
    MY_SCHEDULE: `${bffLayer}${bffPrefix}/enquiries/specialPrice`

};

export const DELIVERY = {
    LIST: "/delivery-list",
    DETAILS: "/delivery-details",
};

export const ORDER_ENQUIRY = {
    API_PATH: "/enquiries",
    GET: "/get",
    PUT: "/put",
    POST: "/post",
    DELETE: "/delete",
    INVOICE: "/invoice",
    FORMFIELD: '/formField'
};

export const SPECIAL_OFFERS = {
    SRV: "cmpnt",
    SOURCE: "library",
    WCM_PAGE_RESET_ALL: "TRUE",
    SUBTYPE: "json",
    CMPNT_NAME: "onestop_Shared/render/ArticleList",
    WCM_PAGE_ARTICLE_LIST: "2",
    S_PATH: "Onestop_en/Home/offers",
};

export const FAVORITES = {
    API_PATH: "/enquiries/favourites",
    GET: "/get",
    PUT: "/put",
    POST: "/post",
    DELETE: "/delete",
};

export const MYSCHEDULE = {
    API_PATH: "/enquiries/specialPrice",
    GET: "/get",
    PUT: "/put",
    POST: "/post",
    DELETE: "/delete",
}

export const NOTIFICATIONS = {
    API_PATH: "/notifications",
    GET: "/get",
    PUT: "/put",
    POST: "/post",
    DELETE: "/delete",
};

export const LOGON = {
    API_PATH: "/users/logon",
    GET: "/get",
    PUT: "/put",
    POST: "/post",
    DELETE: "/delete",
    LOGOUT: "/users/logon/logout"
};

export const ONBOARDING = {
    GETB2CUSERDATA: "/users/oauth/user",
    GETB2CIDINSIGNUPFLOW: "/users/oauth/user/b2c",
    UPDATEID: "/users/updateB2cId",
    VERIFYDATA: "/users/verifyLink",
};

export const PRODUCTS = {
    API_PATH: "/enquiries/products",
    GET: "/get",
    PUT: "/put",
    POST: "/post",
    DELETE: "/delete",
};

export const ONBOARD = {
    API_PATH: "/onboarding",
    GET_IB_CUST_COUNTRY_REGION: '/formField',
};

export const CREATE_UPDATE_ENQUIRY = {
    CREATE: "/enquiries",
    UPDATE: "/enquiries",
    VERIFY: "/verify",
    ORDERS: "/orders",
    CANCEL: "/cancel",
    GETSINGLE: "/enquiries",
    POST: "/post",
    DELETE: "/delete",
    DETAILS: '/details',
};

export const KAMREVIEW = {
    SAVE_KAM_REVIEW_DETAILS: '/onboarding/customer'
}

export const DEALER = {
    ADD_DEALER: "/customer",
    ADD_DEALER_USER: "/user",
    GET_ALL_DEALERS: "/allCustomer",
    GET_ALL_DEALERS_REQUESTS: "/onboarding/newDealerList",
    GET_DEALER_DETAILS: "/customer",
    GET_CUSTOMER_FILTER_STATUS: '/users/formField/newCustomerStatus',
    CHECK_USERID_AVAILABILITY: "/userAvailability",
    UPDATE_DEALER: "/customer",
    UPDATE_DEALER_USER: "/user",
    UPDATE_DEALER_SUB_USER: "/subuser",
    GET_ALL_USERS: "/all",
    ADD_PARAMETER: "/userParameter",
    VERIFY_SAPCUSTOMERID: "/customer/verify",
    GET_ACCESS_PARAM: "/accessParameter",
    ADD_ACCESS_PARAM: "/accessParameter",
    UPDATE_ACCESS_PARAM: "/accessParameter",
    GET_USER_PARAMETER: "/userParameter",
    DOCUMENTS: '/documents',
    RESENDINVITATION: '/resendInvitationLink',
    FORMFIELD: '/formField',
    GET_KAM_REVIEW: '/kamReview'
}

export const REGION_EMAIL = {
    GET_DL_EMAIL_REGION: "/customers/getDLEmailByRegion"
}