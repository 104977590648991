/* eslint-disable */
import React, { FormEvent, Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../store/actions/account.actions";
import _ from "lodash";

import logoicon from "../../assets/mainLogo.png"
import { useUserDispatch, loginUser } from "../../context/UserContext";
import CryptoJS from 'crypto-js'
import FullscreenLoader from '../../common/components/FullscreenLoader'

import { useHistory } from "react-router-dom";
import NavigationArrow from "./NavigationArrow";
import NavBar from "./NavBar";
import Content from "./Content";
import { startGetBrands, startGetFeatures } from "../../store/actions/home.actions";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useState } from "react";
import { cmsAPIService } from '../../services/cms.service'

const WorkshopHomeComponent: React.FC = (props: any) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [pageData, setPageData] = useState(null)
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(startGetFeatures())
    dispatch(startGetBrands())
    loadPageData()
  }, [])


  const loadPageData = async () => {
    setLoading(true);
    let response = await cmsAPIService.getWorkshopHomepageDetails();
    if (response && response.data) {
      setLoading(false);
      setPageData(response.data)
    }
  }

  return (
    // Home Comp
    <div className="d-block w-100 workshop" id="top">
      {loading && <FullscreenLoader />}
      {/* Navigation Arrow */}
      <NavigationArrow />

      {/* NavBar */}
      <NavBar pageData={pageData} />

      {/* Site Content */}
      <Content pageData={pageData} />
    </div>
  );
};

export default WorkshopHomeComponent;
