/* eslint-disable */

import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { createTheme } from '@material-ui/core/styles';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    createButton: {
        backgroundColor: '#1A9FE0',
        width: 140,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#FFFFFF',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 8
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    }
}));
const theme = createTheme({
    palette: {
        secondary: {
            main: '#1A9FE0'
        },
    }
});

export default function DeactivateAddressInfoDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const { handleopenClose } = props;
    const history: any = useHistory();
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [dealerDetails, setDealerDetails] = React.useState<any>();
    const { customerDetails }: any = useSelector((state: any) => state.onboard);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
    const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
        ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
    const leftMenuVisibility: any = useSelector(
        (state: any) => state.global && state.global.sideMenuOpened
    );
    const shipToPartCustomerNo = sessionStorage.getItem('shipToPartCustomerNo');

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    return (
        <div className="float-left">
            <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseErrorSnackbar(false)
                }} message={errorMessage} />
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <div className="justifyContentHorizontalSpacebetween align-items-center">
                    <h4 className="shift-to-party-details-page-title px-3 ml-2 mt-4 mb-2">Deactivated Shipping Address Details</h4>
                </div>
                <DialogContent>
                    {props.isAddressDataFound === false && (
                        <div>
                            <DialogContentText id="alert-dialog-description">
                                <div className="pr-2 mb-1 pl-0 pt-3 pb-1">
                                    <span className="dealer-heading-add-info">Title -
                                        <span className="download-offer-add-info mr-2 px-2 ">
                                            Shipping Address Deactivated
                                        </span>
                                    </span>
                                </div>
                                <div className="pr-2 mb-1 pl-0 pt-2 pb-1">
                                    <span className="dealer-heading-add-info">Deactivated Ship to Party ID-
                                        <span className="download-offer-add-info mr-2 px-2 ">
                                            {shipToPartCustomerNo ? shipToPartCustomerNo : "-"}
                                        </span>
                                    </span>
                                </div>
                                <div className="pr-2 mb-2 pl-0 pt-2 pb-2">
                                    <span className="dealer-heading-add-info">Users Associated with Deactivated Shipping Address-
                                        <span className="download-offer-add-info mr-2 px-2">
                                            {props.userNameCheck}
                                        </span>
                                    </span>
                                </div>
                            </DialogContentText>
                        </div>

                    )}
                    {props.isAddressDataFound === true && (
                        <div className="pr-2 mb-1 pl-0 pt-3 pb-1">
                            <span className="dealer-heading-add-info">This address was not a default address for any Customer user.</span>
                        </div>
                    )}
                </DialogContent>
                <DialogActions className="mt-3 mb-3 justify-content-end">
                    <Button variant="contained" className={classes.closeButton}
                        onClick={() => {
                            props.handleopenClose(false);
                            sessionStorage.setItem("shipToPartCustomerNo", "")
                        }}>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

