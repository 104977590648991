/* eslint-disable */
import React, { Fragment, Dispatch, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartsHistoryTableList from "./PartsHistoryTableList";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import "./PartsHistory.css";

import { getPartList, setPageNumber } from "../../store/actions/orderHistory.actions";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import NotificationIcon from "../../common/components/NotificationIcon";
import _, { get } from "lodash";
import { useEffect } from "react";
import { apiSuccess } from "../../store/actions/apiActions";
import { ORDER_HISTORY } from "../../store/actions/actionsTypes";

const PartsHistory: React.FC = () => {

  const [searchText, setsearchText] = useState<any>('');
  const dispatch: Dispatch<any> = useDispatch();
  const { partsHistoryList }: any = useSelector((state: any) => state.orderHistory);
  const { materialGroups }: any = useSelector((state: any) => state.materialGroups);
  let materialGroupsData = get(materialGroups, 'data.data', null);
  const { isSellingPriceData, profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< EXISTING CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  // let enquiryMappingReverse: any = {};

  // materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
  //   enquiryMappingReverse[group.make] = group.materialGroup.toString();
  // })

  // let enquiryMapping: any = {};

  // materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
  //   group.materialGroup.forEach((item: any) => {
  //     enquiryMapping[item] = group.make;
  //   })
  // })

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NEW CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  let enquiryMappingReverse: any = {};

  let temp: any = []

  if (customerTierType !== "Workshop" && materialGroupsData) {
    for (let item of materialGroupsData) {
      if (item.make != "OES") {
        temp.push(item)
      }
    }
    // let removeOES = materialGroupsData && materialGroupsData.filter((ele: any) => {
    //   console.log("ELELELELELs", ele)
    //   return ele.make != "OES"
    // })
  } else if (customerTierType === "Workshop" && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.selectedBrand) {
    let selectedBrand = profileDetails?.data?.data?.customerInfo?.selectedBrand
    if (selectedBrand.length == 1 && selectedBrand[0].organizationId === "2195") {
      let brand = _.filter(materialGroupsData, (i) => {
        return i.orgId == "2195"
      });
      if (!_.isEmpty(brand)) {
        temp.push(brand[0])
      }
    } else if (selectedBrand.length > 1) {
      for (let item of selectedBrand) {
        let brand = _.filter(materialGroupsData, (i) => {
          return i.orgId == item.organizationId
        });
        if (!_.isEmpty(brand)) {
          temp.push(brand[0])
        }
      }
      let mergeMaterialGroup: any = [];
      for (let i of temp) {
        mergeMaterialGroup = mergeMaterialGroup.concat(i.materialGroup)
      }
      let finalMaterialGroup = [{
        displayName: "Tradepoint",
        make: "OES",
        materialGroup: mergeMaterialGroup,
        orgId: "2195"
      }]
      temp = finalMaterialGroup
    }
  }
  materialGroupsData = temp;
  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    enquiryMappingReverse[group.make] = group.materialGroup.toString();
  })
  let enquiryMapping: any = {};

  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    group.materialGroup.forEach((item: any) => {
      enquiryMapping[item] = group.make;
    })
  })

  console.log(partsHistoryList && Array.isArray(partsHistoryList.data), partsHistoryList?.data, 'parts data');

  useEffect(() => {
    dispatch(apiSuccess(ORDER_HISTORY.GET_PARTS_HISTORY_LIST_SUCCESS, []));
  }, [dispatch])

  // useEffect(()=>{
  //   if(searchText===""){
  //     dispatch(getPartList(customerTierType, searchText , enquiryMapping));
  //   }
  // },[searchText])


  const loadOrderList = useCallback(() => {
    dispatch(getPartList(customerTierType, searchText, enquiryMapping));
    dispatch(setPageNumber(null));
  }, [dispatch, searchText]); //eslint-disable-line

  const showFullScreenLoader = partsHistoryList && partsHistoryList.loading;

  return (
    <Fragment>
      {(showFullScreenLoader) && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Supersession
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="cardCommon p-3">
            <div className="d-flex align-items-center">
              <h5 className="page-sub-title mb-2">Search Supersession</h5>
            </div>
            <TextField
              value={searchText}
              onChange={(e) => {
                setsearchText(e.target.value ? e.target.value.toUpperCase() : e.target.value)
              }}
              onKeyDown={(e: any) => {
                if (e.keyCode == 13) {
                  loadOrderList()
                }
              }}
              size="small" className="col-4 col-sm-6 col-md-4 col-lg-4 mr-3 common-input-font-size commonRoundedInputs searchField" label="Search By Parts No" variant="outlined" />
            <Button
              className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                loadOrderList()
              }}
            >Search</Button>
          </div>
          <PartsHistoryTableList />
        </div>
      </div>
    </Fragment>
  );
};

export default PartsHistory;
