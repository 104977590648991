import { SPECIAL_PRICE } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  specialPrice: null,
  specialPriceStatus: null,
  specialPriceSaveFormAPI: null,
  forwardRequestToKam: null,
  saveFormData: null,
  rejectPartData: null,
  rejectRequestData: null,
  checkandavailibilityData: null,
  checkAvailabilityResetData: null,
  orderno: null,
  reopenedDetails: null,
  reopenedSpecialRequestData: null,
  userAccessRestrictionData: null
};

function specialPrice(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case SPECIAL_PRICE.GET_SPECIALPRICE_LIST_FETCHING:
      return {
        ...state,
        specialPrice: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.GET_SPECIALPRICE_LIST_SUCCESS:
      return {
        ...state,
        orderno: null,
        specialPrice: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.GET_SPECIALPRICE_LIST_ERROR:
      return {
        ...state,
        specialPrice: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE.GET_SPECIALPRICE_STATUS_FETCHING:
      return {
        ...state,
        specialPriceStatus: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.GET_SPECIALPRICE_STATUS_SUCCES:
      return {
        ...state,
        specialPriceStatus: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.STORE_ORDER_NUMBER:
      return {
        ...state,
        orderno: { loading: false, data: action.payload.data, err: null }
      };

    case SPECIAL_PRICE.STORE_DETAILS_REOPENED:
      return {
        ...state,
        reopenedDetails: { loading: false, data: action.payload.data, err: null }
      };

    case SPECIAL_PRICE.GET_SPECIALPRICE_STATUS_ERROR:
      return {
        ...state,
        specialPriceStatus: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE.VIEW_SPECIALPRCIEREQUEST_FETCHING:
      return {
        ...state,
        viewSpecialPrice: { loading: true, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.VIEW_SPECIALPRICEREQUEST_SUCCESS:
      return {
        ...state,
        viewSpecialPrice: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE.VIEW_SPECIALRICEREQUEST_ERROR:
      return {
        ...state,
        viewSpecialPrice: { loading: false, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_FETCHING:
      return {
        ...state,
        addComments: { loading: true, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        addComments: { loading: false, data: action.payload, err: null }
      }

    case SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_ERROR:
      return {
        ...state,
        addComments: { loading: false, data: null, err: action.payload }
      }
    //  case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_FETCHING:
    //  return {
    //    ...state,
    //    getCommentsPart:{loading:true,data:null,err:action.payload}
    //  }   
    //  case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_SUCCESS:
    //  return {
    //    ...state,
    //    getCommentsPart:{loading:false,data:action.payload,err:null}
    //  }  
    //  case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_ERROR:
    //  return {
    //    ...state,
    //    getCommentsPart:{loading:false,data:null,err:action.payload}
    //  },
    case SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_FETCHING:
      return {
        ...state,
        forwardRequestToKam: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_SUCCESS:
      return {
        ...state,
        forwardRequestToKam: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_ERROR:
      return {
        ...state,
        forwardRequestToKam: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.SAVE_FORM_API_FETCHING:
      return {
        ...state,
        saveFormData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.SAVE_FORM_API_SUCCESS:
      return {
        ...state,
        saveFormData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.SAVE_FORM_API_ERROR:
      return {
        ...state,
        saveFormData: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_FETCHING:
      return {
        ...state,
        getCommentsPart: { loading: true, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        getCommentsPart: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_ERROR:
      return {
        ...state,
        getCommentsPart: { loading: false, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.ADDCOMMENTTOTAL_SPECIALPRICE_FETCHING:
      return {
        ...state,
        addCommentsPartTotal: { loading: true, data: null, err: null }
      }
    case SPECIAL_PRICE.ADDCOMMENTTOTAL_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        addCommentsPartTotal: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE.ADDCOMMENTTOTAL_SPECIALPRICE_ERROR:
      return {
        ...state,
        addCommentPartsTotal: { loading: false, data: null, err: action.payload }
      }
    case SPECIAL_PRICE.GETCOMMENTTOTAL_SPECIALPRICE_FETHCING:
      return {
        ...state,
        getCommentsPartsTotal: { loading: true, data: action.payload, err: null }
      }
    case SPECIAL_PRICE.GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS:
      return {
        ...state,
        getCommentsPartsTotal: { loading: false, data: action.payload, err: null }
      }
    case SPECIAL_PRICE.GETCOMMENTTOTAL_SPECIALPRICE_ERROR:
      return {
        ...state,
        getCommentsPartsTotal: { loading: false, data: null, err: action.payload }
      }

    case SPECIAL_PRICE.REJECT_BY_PART_API_FETCHING:
      return {
        ...state,
        rejectPartData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.REJECT_BY_PART_API_SUCCESS:
      return {
        ...state,
        rejectPartData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.REJECT_BY_PART_API_ERROR:
      return {
        ...state,
        rejectPartData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.REJECT_REQUEST_API_FETCHING:
      return {
        ...state,
        rejectRequestData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.REJECT_REQUEST_API_SUCCESS:
      return {
        ...state,
        rejectRequestData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.REJECT_REQUEST_API_ERROR:
      return {
        ...state,
        rejectRequestData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.CHECK_AVALIABLILITY_API_FETCHING:
      return {
        ...state,
        checkandavailibilityData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.CHECK_AVALIABLILITY_API_SUCCESS:
      return {
        ...state,
        checkandavailibilityData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.CHECK_AVALIABLILITY_API_ERROR:
      return {
        ...state,
        checkandavailibilityData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.CHECK_AVALIABLILITY_RESET_DATA:
      return {
        ...state,
        checkandavailibilityData: null
      };
    // case SPECIAL_PRICE.CHECK_AVALIABLILITY_RESET_DATA_API_SUCCESS:
    //   return {
    //     ...state,
    //     checkAvailabilityResetData: { loading: false, data: action.payload.data, err: null }
    //   };
    // case SPECIAL_PRICE.CHECK_AVALIABLILITY_RESET_DATA_API_ERROR:
    //   return {
    //     ...state,
    //     checkAvailabilityResetData: { loading: false, data: null, err: action.payload }
    //   };

    case SPECIAL_PRICE.POST_STATUS_REOPENED_API_FETCHING:
      return {
        ...state,
        reopenedSpecialRequestData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_STATUS_REOPENED_API_SUCCESS:
      return {
        ...state,
        reopenedSpecialRequestData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_STATUS_REOPENED_API_ERROR:
      return {
        ...state,
        reopenedSpecialRequestData: { loading: false, data: null, err: action.payload }
      };

    case SPECIAL_PRICE.POST_BACK_BUTTON_RESTRICTION_FETCHING:
      return {
        ...state,
        userAccessRestrictionData: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.POST_BACK_BUTTON_RESTRICTION_SUCCESS:
      return {
        ...state,
        userAccessRestrictionData: { loading: false, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.POST_BACK_BUTTON_RESTRICTION_ERROR:
      return {
        ...state,
        userAccessRestrictionData: { loading: false, data: null, err: action.payload }
      };
    case SPECIAL_PRICE.GET_REQUEST_CORRECTION__PRCING_FETCHING:
      return {
        ...state,
        requestCorrection: { loading: true, data: null, err: null }
      };
    case SPECIAL_PRICE.GET_REQUEST_CORRRECTION_PRICING_SUCCES:
      return {
        ...state,
        requestCorrection: { loading: true, data: action.payload.data, err: null }
      };
    case SPECIAL_PRICE.GET_REQUEST_CORRECTION_PRICING_ERROR:
      return {
        ...state,
        requestCorrection: { loading: true, data: null, err: null }
      };
    default:
      return state;
  }
};

export default specialPrice;