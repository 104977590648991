/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './RejectDialog.css';
import './DeactivateDailog.css';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 80,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    updatedBrandText: {
        color: '#000000',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }

}));

export default function BrandUpdatedDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const customer: any = sessionStorage.getItem('customerOnboardingType');
    const RequestedBy: any = sessionStorage.getItem('RequestedBy');

    function validatedUpdatedBrand() {
        // props.handleopenCloseSnackbar(true);
        props.handleopenClose(false);
    }

    return (
        <Fragment>
            <Dialog
                open={props.open ? true : false}
                onClose={(event, reason) => {
                    if (reason !== 'backdropClick') {
                        props.handleopenClose(false);
                    }
                }}
                disableEscapeKeyDown
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                {/* <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    handleopenClose={() => { props.handleopenCloseSnackbar(false); }} message={successMessage}
                /> */}
                {props.checked === true ?
                    <>
                        {(customer && customer === "Workshop") || customer === "Int. Business" ?
                            <DialogContent>
                                <div className={classes.root}>
                                    <DialogContent>
                                        <div>
                                            <DialogContentText id="alert-dialog-description">
                                                <div className={`mt-3 ${classes.updatedBrandText}`}>
                                                    <h6 className="mb-0">By providing new brand access, we assume the Customer is already extended with the new Brand in SAP.</h6>
                                                </div>
                                            </DialogContentText>
                                        </div>
                                    </DialogContent>
                                </div>
                            </DialogContent>
                            :
                            customer && customer === "Dealer" || customer === "Government" || customer === "Fleet" ?
                                <DialogContent>
                                    <div className={classes.root}>
                                        <DialogContent>
                                            <div>
                                                <DialogContentText id="alert-dialog-description">
                                                    <div className={`mt-3 ${classes.updatedBrandText}`}>
                                                        <h6 className="mb-0">By providing new brand access, we assume the Customer is already extended with the new Brand and its Credit limit in SAP.</h6>
                                                    </div>
                                                </DialogContentText>
                                            </div>
                                        </DialogContent>
                                    </div>
                                </DialogContent> : ""}
                    </>
                    :
                    <DialogContent>
                        <div className={classes.root}>
                            <DialogContent>
                                <div>
                                    <DialogContentText id="alert-dialog-description">
                                        <div className={`mt-3 ${classes.updatedBrandText}`}>
                                            <h6 className="mb-0">By removing the brand access, the Customer will not be able to transact with that Brand. Are you sure you want to remove <strong>{props.removedBrand[0]}</strong> access from the Customer ?</h6>
                                        </div>
                                    </DialogContentText>
                                </div>
                            </DialogContent>
                        </div>
                    </DialogContent>
                }
                <div className="row-space-start col-12 mb-2 mt-0">
                    <div className="col-12 m-0 p-0">
                        <div className="row mb-0 px-2">
                            <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="row-space-start ml-auto">
                                        <DialogActions className="mt-3 mb-1 justify-content-end">
                                            <Button variant="contained" color="primary"
                                                onClick={() => { props.handleopenClose('success'); }}
                                                className="blueActionButton border-left rounded-pill pr-0 w-100p ml-2 mr-2"> Yes
                                            </Button>
                                            <Button variant="contained" className={classes.closeButton}
                                                onClick={() => { props.handleopenClose(false); }}
                                            >Cancel
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment >
    );
}
