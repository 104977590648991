import React, { Fragment, Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import { get } from "lodash";
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from './../../common/components/Snackbar';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import TablePagination from '@material-ui/core/TablePagination';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { ButtonGroup, Tab, Tabs } from "@material-ui/core";
import { getNewBrand } from "../../common/components/util";
import { createTheme } from '@material-ui/core/styles';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { getAllCreditRequestStatus } from "../../store/actions/dealer.action";
import { getAllCreditRequest, getCreditRequestDetails } from "../../store/actions/creditRequest.actions";
import { getRegion } from "../../store/actions/onboard.actions";

const CreditRequest: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [creditStatus, setCreditStatus] = React.useState<any>();
  const [creditList, setCreditList] = React.useState<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const [filterValue, setFilterValue] = React.useState<any>('showAll');
  const [page, setPage] = React.useState<any>(0);
  const [totalCount, setTotalCount] = React.useState<any>(0);
  const { regionData }: any = useSelector((state: any) => state.onboard);
  const history = useHistory();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  useEffect(() => {
    getCreditRequest();
    dispatch(getRegion(customerTierType));
  }, []);

  const getCreditRequest = async () => {
    let crStatus: any = await dispatch(getAllCreditRequestStatus('creditTeam'));
    if (crStatus && crStatus.data && crStatus.data.length > 0) {
      let temp = crStatus.data
      temp.push({
        code: "showAll",
        title: "Show All"
      })
      temp=temp.filter((element:any)=>{
        return element.code!=="SUB" && element.code!=="AF"
    })
      setCreditStatus(temp);
    }

    setLoading(true);
    let crList: any = await dispatch(getAllCreditRequest('', page));

    if (crList && crList.message && crList.message.status === 'S') {
      setPage(0)
      setTotalCount(crList.data.totaCount)
      setCreditList(crList.data && crList.data.requestList);
      setLoading(false);
    }
  }

  const menuItemStyle = {
    width: "350px"
  };

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  const filterCRByStatus = async (e: any) => {
    setPage(0);
    let searchFilter = e.target.value ? e.target.value : "";
    setLoading(true);
    if (searchFilter == "showAll") {
      searchFilter = "";
    }
    dispatch(getAllCreditRequest(searchFilter.trim(), page));
    let crList: any = await dispatch(getAllCreditRequest(searchFilter.trim(), 0));
    if (crList && crList.message && crList.message.status === 'S') {
      setCreditList(crList.data && crList.data.requestList);
      setPage(0)
      setTotalCount(crList.data.totaCount)
      setLoading(false);
    }
  }

  const handleChangePage = async (newPage: number) => {
    if (filterValue.trim() === "" && filterValue.trim() === "") {
      setLoading(true);
      let crList: any = await dispatch(getAllCreditRequest(filterValue.trim(), newPage));
      if (crList && crList.message && crList.message.status === 'S') {
        setCreditList(crList.data && crList.data.requestList);
        setLoading(false);
      }
    }
    else {
      setLoading(true);
      let filtervalueTemp = "";
      if (filterValue == "showAll") {
        filtervalueTemp = "";
      }
      else {
        filtervalueTemp = filterValue;
      }
      let crList: any = await dispatch(getAllCreditRequest(filtervalueTemp.trim(), newPage));
      if (crList && crList.message && crList.message.status === 'S') {
        setCreditList(crList.data && crList.data.requestList);
        setLoading(false);
      }
    }
    setLoading(false);
    setPage(newPage);
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          Credit Request</h2>
        <div className="ml-auto">
          <NotificationIcon />
        </div>
      </div>
      <div className="d-flex align-items-center">
        List of All Credit Request By KAM
        <div className="ml-auto">
          <TextField
            id="kamList"
            select
            label="Filter By Status"
            variant="outlined"
            className="rounded-input-dealer mw-200p mr-2 mb-2"
            size="small"
            onChange={(e: any) => { setPage(0); filterCRByStatus(e); setFilterValue(e.target.value) }}
            value={filterValue}
          >
            {creditStatus && creditStatus && creditStatus.map((item: any, index: any) => (
              <MenuItem key={item.code} value={item.code}>
                {item.title}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

      {loading ? <FullscreenLoader /> : (

        <Grid item xs={12} className="mt-2 cardCommon">

          <TableContainer className="height-500">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Customer</div>
                    <div className="dealer-table-title-desc">Logo, Name &amp; Trade No</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Address</div>
                    <div className="dealer-table-title-desc">Region &amp; Address</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Contact</div>
                    <div className="dealer-table-title-desc">Telephone &amp; Email</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Assigned</div>
                    <div className="dealer-table-title-desc">KAM</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Brands</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title mb-3">Type</div>
                  </TableCell>
                  <TableCell><div className="dealer-table-title mb-3">Status</div></TableCell>
                  <TableCell ><div className="dealer-table-title mb-3">Action</div></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {creditList && creditList.length > 0 && creditList.map((item: any) =>
                  <TableRow key={item.name}>
                    <TableCell component="th" scope="row" className="mw-200p">
                      <Grid container>
                        <div className="d-flex">
                          <div className="ml-2 py-1 pr-1" style={{ display: "inline" }}>
                            <div className="dealer-table-content dealer-company-name"
                              onClick={async () => {
                              }}>{item.companyName}</div>
                            <div className="dealer-table-subcontent">TL. {item.treadLicenseNumber}</div>
                          </div>
                        </div>
                      </Grid>
                    </TableCell>
                    <TableCell className="mw-150p">
                      <div className="dealer-table-content">{item.addresses && item.addresses.region ? getRegionName(item.addresses.region) : "-"}</div>
                      <div className="dealer-table-subcontent">{item.addresses.street}{item.addresses.street ? ',' : ''}{item.addresses.city},{item.addresses.country}</div>
                    </TableCell>
                    <TableCell className="mw-150p">
                      <div className="dealer-table-content">{item.contactDetails.telephoneNumber ? item.contactDetails.telephoneNumber : "-"}</div>
                      <div className="dealer-table-subcontent">{item.contactDetails.email ? item.contactDetails.email : "-"}</div>
                    </TableCell>
                    <TableCell className="mw-150p">
                      <div className="dealer-table-content">{item.assignKam ? item.assignKam : "-"}</div>
                    </TableCell>
                    <TableCell className="mw-150p">
                      <div className="dealer-table-content">{item.brand ? getNewBrand(item.brand) : "-"}</div>
                    </TableCell>
                    <TableCell className="mw-150p">
                      <div className="dealer-table-content">{item.requestType ? item.requestType : "-"}</div>
                    </TableCell>
                    <TableCell className="mw-150p">
                      {item.status && item.status === "Rejected" ?
                        <div className="dealer-table-content" style={{ color: "red" }}>{item.status ? item.status : "-"}</div>
                        : item.status && item.status === "Approved" ? <div className="dealer-table-content" style={{ color: "limegreen" }}>{item.status ? item.status : "-"}</div>
                          : item.status && item.status === "Submitted" ? <div className="dealer-table-content" style={{ color: "darkorange" }}>{item.status ? item.status : "-"}</div>
                            : <div className="dealer-table-content">{item.status ? item.status : "-"}</div>
                      }
                    </TableCell>
                    <TableCell className="mw-230p">
                      <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
                        <Button className="text-info btn-link mr-3"
                          startIcon={<AttachMoneyIcon />}
                          onClick={() => { dispatch(getCreditRequestDetails(item.id, item.sapCustomerId, 'creditTeam')); history.push('/creditteamdetails') }}>
                          <span className="ml-n2 cursor-pointer">{item.status == "Rejected" || item.status == "Approved" ? "View Application" : item.status == "Credit Team Review" ? "View Credit Request" : "Credit Request"}
                          </span></Button>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>)}
              </TableBody>
            </Table>
            {creditList && creditList.length === 0 && (
              <div className="my-5 mx-auto text-center">No credit request found</div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={totalCount}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage) }}
              />
            </div>}
          </TableContainer>

        </Grid>
      )}
    </Fragment>
  );
};

export default CreditRequest;
