/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Paper from "@material-ui/core/Paper";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { Tab, Tabs } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Documents from "./Documents";
import ShipToParty from "./ShipToParty";
import KamReview from "./KamReview";
import AccessKam from './Access';
import DealerDetails from "./DealerDetailsKam";
import { getAllBrands, setTabValue } from "../../store/actions/dealer.action";
import { getDealingIn, getRegion, getTypeOfTrade } from "../../store/actions/onboard.actions";
import DeleteDialog from "../../common/components/deleteDialog";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MoveOutDialog from "./MoveOutDialog";
import { getDealerDetails, getShipToPartyAddress } from '../../store/actions/onboard.actions';
import { get } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { AddNewAddressDailog } from './AddNewAddressDailog';
import Dialog from '@material-ui/core/Dialog';
import { AddShipToPartyAddress, UpdatedShipToPartyAddress, } from '../../store/actions/onboard.actions';
import { stat } from "fs";
const useStyles = makeStyles(() => ({
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

const Dealers: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { allDealers }: any = useSelector((state: any) => state.dealer);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [newValue, setNewValue] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const history = useHistory();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [open, setOpen] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [sendToSAP, setSendToSAP] = React.useState(false);
  const [moveOutDialog, setmoveOutDialog] = React.useState(false);
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [editedAddress, setEditAddress] = React.useState<any>({});
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [sapId, setSapId] = React.useState("");
  const [selectedDelivery, setSelectedDelivery] = React.useState<any>("DLV");
  const [isOutsideFreezone, setIsOutsideFreezone] = React.useState(true);
  const [isIntBuisness, setIsIntBuisness] = React.useState(false);
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("");
  const [salesRegion, setSalesRegion] = React.useState("");
  const [selected, setSelected] = React.useState("")
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [status, setStatus] = React.useState<any>('');
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);
  const classes = useStyles();
  const { regionData, shipToPartyAddressData, getSelectedAddress, dealerDetails, dealingInData, typeofTradeData }: any = useSelector((state: any) => state.onboard);
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const customerTypeAccess: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.customerTypeAccess) || "";
  const [customerAcceessForIB, setCustomerAcceessForIB] = React.useState(false);
  const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";

  useEffect(() => {
    if (customerTypeAccess && customerTypeAccess.length > 0) {
      if (customerTypeAccess.includes("intbusiness")) {
        setCustomerAcceessForIB(true);
      } else {
        setCustomerAcceessForIB(false);
      }
    }
  }, [customerTypeAccess])

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setSelectedDelivery(shipToPartyAddressData.data.deliveryType ? shipToPartyAddressData.data.deliveryType : "DLV")
      setSapId(shipToPartyAddressData.data.sapCustomerId);
      setMasterCompanyBrands(shipToPartyAddressData.data.masterCompanyBrands)
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
      // setLoading(false);
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      // setLoading(false);
    }
    else {
      // setLoading(false);
      setSelected("");
    }

  }, [shipToPartyAddressData, getSelectedAddress])

  // useEffect(() => {
  //   if (shipToPartyAddressData && shipToPartyAddressData.data) {
  //     // setLoading(false);
  //     // shipToPartyAddressData.data.addresses["type"] = "";
  //     // setAddressList(setaddressData(shipToPartyAddressData.data.addresses));
  //     // setSelectedDelivery(shipToPartyAddressData.data.deliveryType)
  //     setSapId(shipToPartyAddressData.data.sapCustomerId);
  //   }
  //   else if (shipToPartyAddressData && shipToPartyAddressData.err) {
  //     // setLoading(false);
  //   }
  //   if (getSelectedAddress && getSelectedAddress.data) {
  //     // setLoading(false);
  //   }
  //   else {
  //     // setLoading(false);
  //     setSelected("");
  //   }

  // }, [shipToPartyAddressData, getSelectedAddress])



  useEffect(() => {
    setStatus(dealerDetails && dealerDetails.data && dealerDetails.data.status)
  }, [dealerDetails])

  useEffect(() => {
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
    } else {
      dispatch(getDealingIn());
    }
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
    } else {
      dispatch(getTypeOfTrade());
    }
    // loadData()
  }, []);

  const loadData = async () => {
    const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails,documents,kamReview'));
    dispatch(getRegion(data && data.data && data.data.customerTierType));
    if (data && data.data && data.data.customerTierType === 'Workshop') {
      await dispatch(getAllBrands('workshop'));
      sessionStorage.setItem('customerType', 'workshop');
    } else {
      await dispatch(getAllBrands('dealer'));
      if (data && data.data && data.data.isNewCustomer) {
        sessionStorage.setItem('customerType', 'new');
      } else {
        sessionStorage.setItem('customerType', 'existing');
      }
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    history.push('/dealers');
    // dispatch(setTabValue(1));
  }

  const handlePropsValue = (value: any) => {

    setIsOutsideFreezone(value.isCustomerAcceptingDelivery)
    setIsIntBuisness(value.isIntBuisness)
    setIsDefaultUAE(value.isDefaultUAE)
    setSalesRegion(value.salesRegion)
  }

  function a11yProps(index: any, dataUpdated: any) {
    // if(dataUpdated){
    //   setmoveOutDialog(true)
    // }
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseMoveOut = (open?: any) => {
    setmoveOutDialog(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(b2bUserId, data, selected))
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result: any = await dispatch(AddShipToPartyAddress(b2bUserId, data));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      } else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
        handleopenClose(false);
        setOpenErrorSnackbar(true);
        setSuccessMessage(apiResponse);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000);
      }
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };


  return (
    <Fragment>
      {false && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          View Customer Details</h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2 mb-2">
          <div className="d-flex align-items-center mt-4">
            <h6 className="dealer-table-content mb-0">Please verify customer details :</h6>
            <div className="dealer-table-content mb-0 px-2"><h6 className="page-sub-title mb-0">
              {dealerDetails && dealerDetails.data && dealerDetails.data.companyName ? dealerDetails.data.companyName : "-"}
            </h6></div>
            {sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <>
                <div className="ml-4"></div>
                <h6 className="dealer-table-content">Requested by KAM : </h6>
                <div className="dealer-table-content px-2 mt-1"><h6 className="page-sub-title">
                  {dealerDetails && dealerDetails.data && dealerDetails.data.assignKamDetails && dealerDetails.data.assignKamDetails.name ? dealerDetails.data.assignKamDetails.name : "-"}{""}({dealerDetails && dealerDetails.data && dealerDetails.data.assignKamDetails && dealerDetails.data.assignKamDetails.webApplicationLoginId ? dealerDetails.data.assignKamDetails.webApplicationLoginId : "-"})
                </h6></div>
                <div className="ml-4"></div>
                {/* <h6 className="dealer-table-content">KAM ID :</h6>
                <div className="dealer-table-content px-2 mt-1"><h6 className="page-sub-title">
                  {dealerDetails && dealerDetails.data && dealerDetails.data.assignKamDetails && dealerDetails.data.assignKamDetails.webApplicationLoginId ? dealerDetails.data.assignKamDetails.webApplicationLoginId : "-"}
                </h6></div> */}
              </>
              : ""}
            {sessionStorage.getItem('userType') === 'kam' && sessionStorage.getItem('customerOnboardingType') === "Int. Business" ?
              <>
                <div className="ml-4"></div>
                <h6 className="dealer-table-content">Requested by FSE : </h6>
                <div className="dealer-table-content px-2 mt-1"><h6 className="page-sub-title">
                  {dealerDetails && dealerDetails.data && dealerDetails.data.fseDetails && dealerDetails.data.fseDetails.name ? dealerDetails.data.fseDetails.name : "-"} {" "} ({dealerDetails && dealerDetails.data && dealerDetails.data.fseDetails && dealerDetails.data.fseDetails.webApplicationLoginId ? dealerDetails.data.fseDetails.webApplicationLoginId : "-"})
                </h6></div>
                <div className="ml-4"></div>
                {/* <h6 className="dealer-table-content">FSE ID :</h6>
                <div className="dealer-table-content px-2 mt-1"><h6 className="page-sub-title">
                  {dealerDetails && dealerDetails.data && dealerDetails.data.fseDetails && dealerDetails.data.fseDetails.webApplicationLoginId ? dealerDetails.data.fseDetails.webApplicationLoginId : "-"}
                </h6></div> */}
              </>
              : ""}
            <div className="row-space-start ml-auto sm">
              {((selectedTab == 2 && isDefaultUAE === "AE" && sessionStorage.getItem('customerOnboardingType') === "Int. Business" && ((sessionStorage.getItem("userType") === "kam" && status === "Incomplete from KAM") || (sessionStorage.getItem('userType') === "FSE" && (status === "Incomplete from FSE" || status === "Awaiting feedback from FSE" || status === "Submitted by FSE")))) || (selectedTab == 2 && sessionStorage.getItem('customerOnboardingType') !== "Int. Business" && ((sessionStorage.getItem("userType") === "kam" && status === "Incomplete from KAM") || (sessionStorage.getItem('userType') === "FSE" && (status === "Incomplete from FSE" || status === "Awaiting feedback from FSE" || status === "Submitted by FSE"))))) && <Button
                onClick={() => {
                  setOpen(true)
                }}
                className="text-info"
                disabled={status === "Submitted by FSE" ? true : false}
                startIcon={<AddIcon />}
              ><span className="onshiftoparty-font">
                  Add New Address
                </span>
              </Button>}
              <Button variant="contained" color="primary" onClick={(e) => {
                e.preventDefault();
                if (dataUpdated) {
                  handleopenCloseMoveOut(true)
                } else {
                  history.push('/dealers');
                  dispatch(setTabValue(1));
                }
              }} className="blueActionButton border-left rounded-pill">Back to Customer list</Button>
            </div>
          </div>
        </div>
      </div>
      <Grid container className="marginScrollableArea">
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={apiResponseText} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={successMessage} />
        <Grid item xs={12} sm={12}>
          <ThemeProvider theme={theme}>
            <Paper square>
              <div className="border-bottom">
                <Tabs
                  // className="tabSelect"
                  className={dealerDetails && dealerDetails.data && (dealerDetails.data.status === "Incomplete from KAM" || dealerDetails.data.status === "Incomplete from FSE") ? "remove-cursor-icon" : "tabSelect"}
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from KAM") || (dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from FSE")) {
                      if (!dataUpdated) {
                        setselectedTab(newValue);
                      } else {
                        setNewValue(newValue);
                        handleopenClose(true);
                      }
                    }
                  }}>
                  <Tab label="Customer Details" value={0} {...a11yProps('DealerList', dataUpdated)} />
                  <Tab label="Documents" value={1} {...a11yProps('Documents.', dataUpdated)} />
                  <Tab label="Shipping Address" value={2} {...a11yProps('ShipToParty', dataUpdated)} />
                  {sessionStorage.getItem('userType') === 'FSE' ? '' : (
                    <Tab label="KAM Review" value={3} {...a11yProps('KamReview', dataUpdated)} />
                  )}
                  {sessionStorage.getItem('userType') === 'FSE' ? '' : (
                    <Tab label="Access" value={4} {...a11yProps('Access', dataUpdated)} />
                  )}
                </Tabs>
              </div>
              <div className="d-block w-100">
                {selectedTab == 0 && <DealerDetails dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 1 && <Documents dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 2 && <ShipToParty dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} setSendToSAP={setSendToSAP} freeZoneProps={handlePropsValue} handleSubmit={handleSubmit} />}
                {selectedTab == 3 && <KamReview dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
                {selectedTab == 4 && <AccessKam dataUpdated={dataUpdated} setDataUpdated={setDataUpdated} setTabValue={setselectedTab} />}
              </div>
            </Paper>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} firstAddressCheck={firstAddressCheck} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} status={status} selectedDelivery={selectedDelivery} isView={false} isOutsideFreezone={isOutsideFreezone}
          isIntBuisness={isIntBuisness} isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
      </Dialog>

      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text={sendToSAP ? 'Please send the address(es) to SAP before saving and approving the Customer else the data will not get updated.' : "Do you wish to proceed without saving the data?"}
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === "back") {
            setselectedTab(newValue); handleopenClose(false); setDataUpdated(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      <MoveOutDialog
        open={moveOutDialog && dataUpdated}
        text="Do you wish to proceed without saving the data move out?"
        handleopenClose={(data: any) => {

          if (data) {
            dispatch(setTabValue(1));
            history.push('/dealers');
            handleopenCloseMoveOut(false);
          }
          else if (data === 'back') {

          }
          if (!data) { handleopenCloseMoveOut(false); }
        }} />
      <Prompt when={dataUpdated} message={sendToSAP ? 'Please send the address(es) to SAP before saving and approving the Customer else the data will not get updated.' : "Do you wish to proceed without saving the data?"}
      >
      </Prompt>
    </Fragment >
  );
};

export default Dealers;
