/* eslint-disable */
import React, { Dispatch, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Snackbar from './../../common/components/Snackbar';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import { getNewBrand } from "../../common/components/util";
import { getNewlyAddedBrand } from "../../common/components/util";
import { createTheme, makeStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { getViewRequestStatusAPI, requestModifyData } from "../../store/actions/requestSpecialPrice.actions";
import moment from "moment";
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import Alert from '@material-ui/lab/Alert';
import FullscreenLoader from "../../common/components/FullscreenLoader";
import defaultLogo from '../../assets/default-user-logo.png';
import _, { get } from "lodash";

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

const useStyles = makeStyles(() => ({

  subtitle: {
    display: "flex"
  },
  grid: {
    marginLeft: 234
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito'
  },
}));

const ViewRequestStatus: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const classes = useStyles();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const history = useHistory();
  const { viewSpecialPriceDetails, isSPRModifyData }: any = useSelector((state: any) => state.requestSpecialPrice);
  const [selectedTab, setselectedTab] = React.useState('Date');
  const [viewComments, setViewComments] = React.useState<any>(false);
  const [showMessageForCommets, setShowMessageForCommets] = React.useState<any>(false);
  const { dealerDetails }: any = useSelector((state: any) => state.dealer);
  const customerDetails: any = (dealerDetails && dealerDetails.data && dealerDetails.data.data) ? dealerDetails.data.data : null;
  const [openSpecialPrice, setOpenSpecialPrice] = React.useState<any>(false)
  const [prodData, setProdData] = React.useState<any>();
  const [b2bID, setB2bID] = React.useState<any>();
  const [liveStatus, setLiveStatus] = React.useState<any>();
  const [rejectedReason, setRejectedReason] = React.useState<any>();
  const [date, setDate] = React.useState<any>();
  const [estimatedTotal, setEstimatedTotal] = React.useState<any>('');
  const [comment, setComment] = React.useState<any>();
  const [productIdDetails, setProductIdDetails] = React.useState<any>();
  const [productIdDecriptionDetails, setProductIdDecriptionDetails] = React.useState<any>();
  const [addedOn, setAddedOn] = React.useState<any>();
  const [addedByRole, setAddedByRole] = React.useState<any>();
  const [addedByName, setAddedByName] = React.useState<any>();
  const [addedBy, setAddedBy] = React.useState<any>();
  const [profilePic, setProfilePic] = React.useState<any>();
  const [showNote, setShowNote] = React.useState<any>()
  const [isDiscountPricePart, setIsDiscountPricePart] = React.useState<boolean>(false);
  const [isRejectPricePart, setIsRejectPricePart] = React.useState<boolean>(false);
  const [discountTotal, setDiscountTotal] = React.useState<any>("")
  const { enquiryList, enquiryStatus } = useSelector((state: any) => state.orderEnquiry);
  const { enquiryInfo }: any = useSelector((state: any) => state.createUpdateEnquiry);
  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);
  const { viewingFromOrderHistory }: any = useSelector((state: any) => state.orderHistory);
  const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem('customerTierType') ? sessionStorage.getItem('customerTierType') : get(profileDetails, 'data.data.customerInfo.customerTierType', '') : get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  const { selectedParts }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );

  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );

  // TO CAPTURE THE VIEW DATA COMING FROM API
  useEffect(() => {
    let B2bEnqID: any
    let updatedDate: any
    let commentDetails: any
    let addedOnDetails: any
    let addedByRoleDetails: any
    let addedByNameDetails: any
    let addedByDetails: any
    let profilePicData: any
    let notes: any
    let liveCurrentStatus: any
    let liveRejectedReason: any

    viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
      B2bEnqID = ele && ele.B2bEnquiryId
      updatedDate = ele && ele.createdOn
      liveCurrentStatus = ele && ele.status
      liveRejectedReason = ele && ele.rejectReson

      ele && ele.products.map((data: any) => {
        data.comments.map((details: any) => {
          addedOnDetails = details.addedOn
          addedByRoleDetails = details.addedByRole
          addedByNameDetails = details.addedByName
          addedByDetails = details.addedBy
          commentDetails = details.comment
          profilePicData = details.profilePic
          notes = details.expiredAfterApproval
        })
      })
    })
    setB2bID(B2bEnqID)
    setDate(updatedDate)
    setLiveStatus(liveCurrentStatus)
    setRejectedReason(liveRejectedReason)
    setAddedBy(addedByDetails)
    setAddedByName(addedByNameDetails)
    setAddedByRole(addedByRoleDetails)
    setAddedOn(addedOnDetails)
    setShowNote(notes)
    // setComment(commentDetails)
    setProfilePic(profilePicData)

    let isDiscountPart: any = []
    let discountTotal: any = 0
    let total: any = 0;
    let isRejectedPart: any = [];

    viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].products.forEach((ele: any) => {
      isDiscountPart.push(ele.isSpecialPriced);
      isRejectedPart.push(ele.isRejected);
      total += parseFloat(ele.nspev) * parseFloat((ele.nowAvailability)) + parseFloat(ele.aop) * parseFloat((ele.airAvailability))
      if (ele.isSpecialPriced == false) {
        discountTotal += parseFloat(ele.nspev) * parseFloat((ele.nowAvailability)) + parseFloat(ele.aop) * parseFloat((ele.airAvailability))
      }
    })
    // setTotal(total)
    if (isDiscountPart && isDiscountPart.length > 0 && isDiscountPart.includes(true)) {
      setIsDiscountPricePart(true);
    }
    if (isRejectedPart && isRejectedPart.length > 0 && isRejectedPart.includes(true)) {
      setIsRejectPricePart(true);
    }
    setDiscountTotal(discountTotal)
  }, [viewSpecialPriceDetails])

  // TO CAPTURE THE ESTIMATED TOTAL
  useEffect(() => {
    let estimateTotal: any
    selectedParts && selectedParts && (
      estimateTotal = selectedParts.estPartsTotal
    )
    setEstimatedTotal(estimateTotal)
  }, [selectedParts])


  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setselectedTab(newValue);
  };

  const handleopenClose = (open?: any) => {
    setSuccessMessage(null);
  };

  const handleCloseSpecialPrice = () => {
    setOpenSpecialPrice(false);
  }

  // ON CLICK OF EDIT BUTTON FUNCTIONALITY 
  const handelRequestEdit = async () => {
    if (viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status !== "Cancelled") {
      let data: any = await dispatch(getViewRequestStatusAPI(b2bID))

      if (data && data.data.length !== 0) {
        history.push("editrequestspecialprice")
      }
    }

  }

  // ON CLICK- OF CLOSE BUTTON REDIRECT TO THE SAME ENQUIRY FUNCTIONALITY
  const closeButtonResetData = () => {
    let status: any;
    if (viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status) {
      status = viewSpecialPriceDetails.data.data.data[0].status;
    }
    let viewingFromid: any = viewingFromOrderHistory && viewingFromOrderHistory.data && viewingFromOrderHistory.data && viewingFromOrderHistory.data.id
    let b2bId: any = b2bID
    if (viewingFromid === b2bId && viewingFromOrderHistory && viewingFromOrderHistory.data && viewingFromOrderHistory.data && viewingFromOrderHistory.data.isViewingFromOrderHistory) {
      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
        mode: 'view',
        masterId: b2bID
      }))
      history.push('/viewenquiry');
    } else {
      let enquiryStatus = enquiryInfo && enquiryInfo.data && enquiryInfo.data.data && enquiryInfo.data.data.length > 0 && enquiryInfo.data.data.filter((enquiry: any) => enquiry.status.toLowerCase() === 'failed' || enquiry.status.toLowerCase() === "enquiry cancelled" || enquiry.status.toLowerCase() === "fully cancelled" || enquiry.status.toLowerCase() === "cancel")
      if (enquiryStatus && enquiryStatus.length > 0) {
        sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
          mode: 'view',
          masterId: b2bID,
          orderType: "Cancelled"
        }))
        history.push('/viewenquiry');
      } else {
        history.push('/updateenquiry');
        sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
          mode: 'update',
          masterId: b2bID
        }))
      }
    }
  }

  const isBluelegend = (ele: any) => {
    let airPrice: any = ele.airOfferedPriceType == "byCurrency" ? parseFloat(ele.airOfferedPrice) : parseFloat(ele.airOfferedCalculatedPrice)
    let nowPrice: any = ele.nowOfferedPriceType == "byCurrency" ? parseFloat(ele.nowOfferedPrice) : parseFloat(ele.nowOfferedCalculatedPrice)
    if (ele.nowAvailabilityChecked && ele.airAvailabilityChecked) {
      if (((parseFloat(ele.nspev)) > parseFloat(nowPrice)) || (parseFloat(ele.aop) > airPrice)) {
        return true;
      } else {
        return false;
      }
    } else if (ele.nowAvailabilityChecked) {
      if ((parseFloat(ele.nspev) > nowPrice)) {
        return true;
      } else {
        return false;
      }
    } else if (ele.airAvailabilityChecked) {
      if ((parseFloat(ele.aop) > airPrice)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }


  return (
    <>
      {viewSpecialPriceDetails && viewSpecialPriceDetails.loading && <FullscreenLoader />}
      <div>
        <div>
          <div className="d-flex align-items-center mt-2 mb-4">
            <h2 className="page-title"> View Status </h2>
            <h3 className="page-sub-title mb-0 px-3">(Enquiry Id- {b2bID ? b2bID : "-"})</h3>
            <div className="ml-auto">

              {
                viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status !== "inPrgress" ? null : (
                  <IconButton className="mr-1 searchbarOptions" size="small"
                    onClick={() => {
                      dispatch(requestModifyData(true))
                      closeButtonResetData()
                    }}>
                    <span className="font-size-13 load-from-fav ml-1">Modify Data</span>
                  </IconButton>
                )
              }

              <AnnouncementIcon />
              <NotificationIcon />
            </div>
          </div>
        </div>
      </div>
      {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data &&
        viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 ?
        <>
          {!viewComments && (
            <div className="row mt-4">
              <div className="col-12 row">
                <div className="col-6">
                  <div className="d-flex flex-row">
                    <div className="d-flex mb-1 mt-3">
                      {/* {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status == "Cancelled" ? */}
                      {/* {(viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status !== "inPrgress") && !isSPRModifyData ?
                        
                        ""
                        : <IconButton
                          disabled={(viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status !== "inPrgress") && !isSPRModifyData}
                          className="p-0 m-1 toast-icon" edge="end" aria-label="add"
                          onClick={() => { }}>
                          <span className="info-sub-header-text m-1 mb-1 px-0"
                            onClick={handelRequestEdit}>Edit Request</span>
                        </IconButton>} */}
                    </div>
                    <div className="d-flex mt-3 mb-1">
                      {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].status !== "inPrgress" ?
                        "" : <h6 className="m-1 mt-1 mb-1">|</h6>}
                      {liveStatus !== "Corrections" && liveStatus !== "KAM Review" && liveStatus !== "Submitted" && liveStatus !== "Timed Out" && liveStatus !== "Rejected" && liveStatus !== "Approved" && liveStatus !== "New Request"
                        ?
                        <div className="row mt-2 px-1">
                          <p className="status-heading row px-4">Date:</p> <span className="font-weight-bold totalAed p-0">{moment(date).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</span>
                        </div>
                        :
                        <div className="row mt-2 px-1">
                          <p className="status-heading row pr-4 px-4">Date:</p> <span className="font-weight-bold totalAed p-0 px-4">{moment(date).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className="col-6 float-right">
                  <div className="legendItemspr m-2 mt-4 float-right">
                    <span className="dot-red"></span>
                    <span className="legendItemLabel ml-2">Not approved / Not offered</span>
                  </div>
                  <div className="legendItemCreateEnquiry m-2 mt-4 float-right">
                    <span className="dot-blue"></span>
                    <span className="legendItemLabel mr-3 ml-2">Special Price</span>
                  </div>
                  <div className="legendItemCreateEnquiry m-2 mt-4 float-right">
                    <span className="dot"></span>
                    <span className="legendItemLabel mr-3 ml-2">Discounted Price</span>
                  </div>
                </div>

                {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].expiredAfterApproval === true && liveStatus === "Approved" ?
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 disclaimer-notes">Your Special price request has expired after approval because you have exceeded the 24-hour window to confirm the order. You can still place the order with the Standard prices or you can again submit your Special price request by creating a new enquiry.</div><div className="p-2"></div></div>
                  : liveStatus === "Approved" ?
                    <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 live-status">Your request is processed & you have received the below-offered prices.</div><div className="p-2"></div></div>
                    : ""
                }

                {liveStatus === "New Request" && (
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 live-status">Your request is under consideration.</div><div className="p-2"></div></div>
                )}

                {liveStatus === "Rejected" && (
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 disclaimer-notes">Your request was not approved due to the reason: ({rejectedReason}).</div><div className="p-2"></div></div>
                )}

                {liveStatus === "Timed Out" && (
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 disclaimer-notes">Sorry, your request got timed out. Please contact our sales team to reopen it.</div><div className="p-2"></div></div>
                )}

                {liveStatus === "Submitted" && (
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 live-status">Your request is under consideration.</div><div className="p-2"></div></div>
                )}

                {liveStatus === "KAM Review" && (
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 live-status">Your request is under consideration.</div><div className="p-2"></div></div>
                )}

                {liveStatus === "Corrections" && (
                  <div className="d-flex"><div className="status-heading  mt-2 px-3">Status:</div><div className="p-2 live-status">Your request is under consideration.</div><div className="p-2"></div></div>
                )}
                <Grid item xs={12} className="mt-2 cardCommon">
                  <TableContainer component={Paper} className="height-500 mb-5 pb-5">
                    <Table aria-label="dealer table">
                      <TableHead>
                        <TableRow>
                          <TableCell />
                          <TableCell>
                            <div className="dealer-table-title">Part No.</div>
                            <div className="dealer-table-title-desc"></div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title">Brand</div>
                            <div className="dealer-table-title-desc"></div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title mt-3">NSP</div>
                            <div className="dealer-table-title-desc">(Excl. VAT)</div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title">NOW Qty</div>
                            <div className="dealer-table-title-desc"></div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title mt-3">NOW Req Price</div>
                            <div className="dealer-table-title-desc">(Per Qty)</div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title-heading mt-3">NOW Offered Price</div>
                            <div className="dealer-table-title-desc">(Per Qty)</div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title">BOP</div>
                            <div className="dealer-table-title-desc"></div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title">BO Qty</div>
                            <div className="dealer-table-title-desc"></div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title mt-3">BO Req Price</div>
                            <div className="dealer-table-title-desc">(Per Qty)</div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title-heading mt-3">BO Offered Price</div>
                            <div className="dealer-table-title-desc">(Per Qty)</div>
                          </TableCell>
                          <TableCell>
                            <div className="dealer-table-title">Comments</div>
                            <div className="dealer-table-title-desc"></div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
                        return <TableBody>
                          {ele.products.map((data: any) => {
                            return <TableRow>
                              <TableCell>
                                {!data.isSpecialPriced && (data.nowRequestPrice || data.airRequestPrice) && liveStatus == "Approved" && (
                                  <>
                                    {isBluelegend(data) && !data.isRejected ? (
                                      <span className="legendItemCreateEnquiry">
                                        <span className="dot-blue"></span>
                                      </span>
                                    ) : (
                                      <span className="legendItemspr">
                                        <span className="dot-red"></span>
                                      </span>
                                    )}
                                  </>
                                )}
                                {data.isSpecialPriced &&
                                  <span className="legendItemCreateEnquiry">
                                    <span className="dot"></span>
                                  </span>
                                }
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content">
                                  {data.productId ? data.productId : "-"}
                                </div>
                                <div className="dealer-table-title-desc">{data.productIdDescription ? data.productIdDescription : '-'}</div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content-brand">
                                  {data.make && customerTierType !== 'Workshop' ? getNewlyAddedBrand(data.make) : data.make ? getNewBrand(data.make) : "-"}
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content">
                                  {data.nsp ? data.nsp : '-'}
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content">
                                  {data.nowAvailability ? data.nowAvailability : '-'}
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                {/* <div className="dealer-table-content">
                                                                    {data.nowRequestPrice ? data.nowRequestPrice : '-'}
                                                                </div> */}
                                <div className="d-flex flex-row">
                                  <div>{data.nowRequestPrice === "" ? "-" : data.nowRequestCalculatedPrice === "" ? "-" : data.nowRequestPriceType == "byCurrency" ? `${data.nowRequestPrice}` : `${data.nowRequestCalculatedPrice}`} </div>
                                  <div className="pl-2 requestedPriceColor">{data.nowRequestPriceType == "byCurrency" ? `${data.nowRequestCalculatedPrice ? "(-" : ""}${data.nowRequestCalculatedPrice}${data.nowRequestCalculatedPrice ? ")" : ""}` : `${data.nowRequestPrice ? "(-" : ""}${data.nowRequestPrice}${data.nowRequestCalculatedPrice ? "%)" : ""}`} </div>
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="d-flex flex-row">
                                  <div>{data.nowOfferedPrice === "" ? "-" : data.nowOfferedCalculatedPrice === "" ? "-" : data.nowOfferedPriceType == "byCurrency" ? `${data.nowOfferedPrice}` : `${data.nowOfferedCalculatedPrice}`} </div>
                                  <div className="pl-2 requestedPriceColor">{data.nowOfferedPriceType == "byCurrency" ? `${data.nowOfferedCalculatedPrice ? "(-" : ""}${data.nowOfferedCalculatedPrice}${data.nowOfferedCalculatedPrice ? ")" : ""}` : `${data.nowOfferedPrice ? "(-" : ""}${data.nowOfferedPrice}${data.nowOfferedCalculatedPrice ? "%)" : ""}`} </div>
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content">
                                  {data.aop ? data.aop : '-'}
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content">
                                  {data.airAvailability ? data.airAvailability : '-'}
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="d-flex flex-row">
                                  <div>{data.airRequestPrice === "" ? "-" : data.airRequestCalculatedPrice === "" ? "-" : data.airRequestPriceType == "byCurrency" ? `${data.airRequestPrice}` : `${data.airRequestCalculatedPrice}`}</div>
                                  <div className="pl-2 requestedPriceColor">{data.airRequestPriceType == "byCurrency" ? `${data.airRequestCalculatedPrice ? "(" : ""}${data.airRequestCalculatedPrice}${data.airRequestCalculatedPrice ? ")" : ""}` : `${data.airRequestPrice ? "(-" : ""}${data.airRequestPrice}${data.airRequestPrice ? "%)" : ""}`}</div>
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="d-flex flex-row">
                                  <div>{data.airOfferedPrices === "" ? "-" : data.airOfferedCalculatedPrice === "" ? "-" : data.airOfferedPriceType == "byCurrency" ? `${data.airOfferedPrice}` : `${data.airOfferedCalculatedPrice}`}</div>
                                  <div className="pl-2 requestedPriceColor">{data.airOfferedPriceType == "byCurrency" ? `${data.airOfferedCalculatedPrice ? "(-" : ""}${data.airOfferedCalculatedPrice}${data.airOfferedCalculatedPrice ? ")" : ""}` : `${data.airOfferedPrice ? "(-" : ""}${data.airOfferedPrice}${data.airOfferedPrice ? "%)" : ""}`}</div>
                                </div>
                              </TableCell>
                              <TableCell className="mw-80p">
                                <div className="dealer-table-content-subheading cursor-pointer"
                                  onClick={() => {
                                    setViewComments(true);
                                    setProductIdDetails(data && data.productId ? data.productId : "")
                                    setComment(data && data.comments ? data.comments : [])
                                    setProductIdDecriptionDetails(data && data.productIdDescription ? data.productIdDescription : "")
                                  }}>View Comments
                                </div>
                              </TableCell>
                            </TableRow>
                          })}
                        </TableBody>
                      })}
                    </Table>
                    <div className="col-12 card payment-dialog-content mb-2" id="demo-radio-buttons-group-label">
                      <div className="row col-12 d-flex justify-content-start">

                        <div className="row col-4 pl-0 px-2">
                          {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
                            return <div className="d-flex flex-column ">
                              <div className="row   font-style-color-header-status ml-1"> Estimated Total : AED   {ele && ele.estimateGrandTotal ? parseFloat(ele.estimateGrandTotal).toFixed(2) : "-"}</div>
                              <div className="pb-1 ml-1 font-weight-light "> For All Parts</div>
                            </div>
                          })}
                        </div>
                        {/* // <div className="col-4 pl-0 px-0 pr-3"> Estimated Total : {" "} AED {" "}
                        //   {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
                        //     return <>
                        //       {ele && ele.estimateGrandTotal ? parseFloat(ele.estimateGrandTotal).toFixed(2) : "-"}
                        //     </>
                        //   })}
                        // </div> */}
                        <div className="row col-4 pl-0 px-0">
                          {isDiscountPricePart && (
                            <div className="d-flex flex-column ">
                              <div className="row   font-style-color-header-status ml-1"> Estimated Total : AED  {discountTotal.toFixed(2)} </div>
                              <div className="pb-1 ml-1 font-weight-light ">Excluding Discount Parts</div>
                            </div>
                          )}
                        </div>
                        <div className="row col-4 pl-4 px-0">
                          {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
                            return <>
                              {ele && ele.requestTotalPriceCalculated ?
                                <div className="px-0">
                                  Requested Total : {" "}
                                  {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data.length > 0 && viewSpecialPriceDetails.data.data.data.map((ele: any) => {
                                    return <>
                                      AED {" "} {ele.requestTotalPriceType == "By AED" ? `${ele.requestTotalPrice}` : `${ele.requestTotalPriceCalculated}`} {""}
                                      {ele.requestTotalPriceType == "By AED" ? `${ele.requestTotalPriceCalculated ? "(" : ""}${ele.requestTotalPriceCalculated}${ele.requestTotalPriceCalculated ? ")" : ""}` : `${ele.requestTotalPrice ? "(-" : ""}${ele.requestTotalPrice}${ele.requestTotalPrice ? "%)" : ""}`}
                                    </>
                                  })}
                                </div>

                                : ""}
                            </>
                          })}
                        </div>
                      </div>
                    </div>
                    {/* {viewSpecialPriceDetails && viewSpecialPriceDetails.data && viewSpecialPriceDetails.data.data && viewSpecialPriceDetails.data.data.data && viewSpecialPriceDetails.data.data.data[0] && viewSpecialPriceDetails.data.data.data[0].expiredAfterApproval === true && (
                                            <div className="col-12 row px-4 brands-heading-text-purchase-history mt-3"><p className='row disclaimer-notes px-3'>Note - Your Special price request has expired after approval because you have exceeded the 24-hour window to confirm the order. You can still place the order with the Standard prices or you can again submit your Special price request by creating a new enquiry.</p></div>
                                        )} */}
                  </TableContainer>
                </Grid>
              </div>
              <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 d-flex mt-2 p-0">
                  <div className="col-6 d-flex flex-row">
                  </div>
                  <div className="col-6 onboard-footer">
                    <div className="">
                      <Button variant="contained" className="closebutton-styling rounded-pill mb-2 mr-2 ml-2"
                        onClick={async () => {
                          dispatch(requestModifyData(false))
                          closeButtonResetData();
                        }}
                      >Close
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          }
          {
            viewComments && (
              <>
                <div className="col-sm-12 col-md-12 col-lg-12 pr-3 mb-3 d-flex flex-row">
                  <p className="pr-2">
                    <IconButton className="p-0 pr-4 mb-1 toast-icon" edge="end" aria-label="add"
                      onClick={() => { setViewComments(false); }}>
                      <ChevronLeftIcon fontSize="medium" />
                      <span className="info-sub-header-text px-0"
                        onClick={() => { setViewComments(false); }}>Back</span>
                    </IconButton>
                  </p>
                  <div className="pr-2" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "20px", opacity: 0.3 }}>
                  </div>
                  <p className="pr-2">
                    {moment(addedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}
                  </p>
                  <div className="pr-2" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "20px", opacity: 0.3 }}>
                  </div>
                  <p className="pr-2">
                    {productIdDetails ? productIdDetails : "-"}
                  </p>
                  <div className="pr-2" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "20px", opacity: 0.3 }}>
                  </div>
                  <p className="pr-2">
                    {productIdDecriptionDetails ? productIdDecriptionDetails : "-"}
                  </p>
                </div>
                <>
                  {comment.length > 0 ? comment.map((details: any) => {
                    return <div className="col-12 row">
                      {/* <div className="col-1 pr-0 pt-4">
                                                {customerDetails && customerDetails.logo
                                                    ? <img src={customerDetails && customerDetails.logo} alt="Logo Client" className="brandLogo-viewspecial-price" />
                                                    : <img src={NoImage} alt="Logo Client" className="brandLogo-viewspecial-price" />}
                                            </div> */}
                      <div className="d-flex pt-4">
                        <div style={{ display: "inline" }}>
                          <img
                            src={defaultLogo}
                            alt="Logo Client"
                            className="dealer-grid-logo"
                          /></div>
                      </div>
                      <div className="col-8 pt-4">
                        <p className="message-viewstatus">{details.comment ? details.comment : "No Comments Available"}</p>
                        <div className="col-12 row message-viewstatus mt-3">{details.addedByRole} <h6 className="row px-3"> |</h6><h6 className="info-sub-title-text">{moment(details.addedOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</h6></div>
                      </div>
                    </div>
                  })
                    : <Alert severity="info">No Comments Available !</Alert>}
                </>
                <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                  <div className="col-12 d-flex mt-2 p-0">
                    <div className="col-6 d-flex flex-row">
                    </div>
                    <div className="col-6 onboard-footer">
                      <div className="">
                        <Button variant="contained" className="closebutton-styling rounded-pill mb-2 mr-2 ml-2"
                          onClick={async () => {
                            history.push('/updateenquiry');
                            sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                              mode: 'update',
                              masterId: b2bID
                            }))
                          }}>Close
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
          }
        </>
        :
        <>
          <div className="col-12 row">
            <Alert severity="info">There is no special price request for this enquiry number !</Alert>
          </div>
          <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
            <div className="col-12 d-flex mt-2 p-0">
              <div className="col-6 d-flex flex-row">
              </div>
              <div className="col-6 onboard-footer">
                <div className="">
                  <Button variant="contained" className="closebutton-styling rounded-pill mb-2 mr-2 ml-2"
                    onClick={async () => {
                      history.push('/updateenquiry');
                      sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                        mode: 'update',
                        masterId: b2bID
                      }))
                    }}>Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      }
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} text={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} text={successMessage} />
    </>
  )
};

export default ViewRequestStatus;
