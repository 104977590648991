/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CheckIcon from "@material-ui/icons/Check";
import WarningIcon from "@material-ui/icons/Warning";
import ClearIcon from "@material-ui/icons/Clear";
import { Button, Dialog } from '@material-ui/core';
import { get } from 'lodash';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { handleApproveReupload } from '../../store/actions/tasks.actions';
import FullscreenLoader from '../../common/components/FullscreenLoader';

const useStyles = makeStyles(() => ({
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    }
}));

export default function ApproveDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [rejectionReason, setRejectionReason] = React.useState<string>('');
    const { item } = props;
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const webApplicationLoginId = get(profileDetails, 'data.data.basicInfo.webApplicationLoginId', "");
    const userType: any = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.userType) || "";

    const handleApprove = async () => {
        if (props.isApprove === 'reject') {
            props.handleopenClose(false);
        } else {
            setLoading(true);
            console.log(item);
            let data: any = {
                "webApplicationLoginId": webApplicationLoginId,
                "userType": userType && userType.toLowerCase() === 'kam' ? 'KAM' : "FSE",
                "documentId": item.documentId,
                "sapCustomerId": item.sapCustomerId,
                "documentName": item.documentName,
                "type": item.type,
                "approvalStatus": props.isApprove === 'approve' ? "approve" : "reject",// in case approve value is "approve"
                "rejectionMessage": rejectionReason,
                "id": item.id
            }
            setLoading(true);
            let resData: any = await dispatch(handleApproveReupload(data));
            if (resData && resData.message.status === 'S') {
                props.setSuccessMessage(resData.message.message);
                props.setOpenSnackbar(true);
                props.handleOpenCloseSnackbar(true);
                props.setOpenErrorSnackbar(false);
                setLoading(false);
                setTimeout(() => {
                    props.getAllReviewDocuments()
                    props.handleopenClose(false);
                }, 2000)
            }
        }

    }

    return (
        <Fragment>
            {loading && <FullscreenLoader />}
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false); props.setSuccessMessage(''); props.handleopenCloseSnackbar(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogContent>
                    {props.isApprove === 'approve' && (
                        <DialogContent className='p-0'>
                            <div className={classes.icon}>
                                <CheckIcon className="delete-warning" fontSize="small" />
                            </div>
                            <div className="dealer-expired-subText mt-3">
                                Are you sure to approve submitted document ?
                            </div>
                        </DialogContent>
                    )}
                    {props.isApprove === 'reject' && (
                        <DialogContent className='p-0'>
                            <div className={classes.icon}>
                                <WarningIcon className="delete-warning" fontSize="small" />
                            </div>
                            <div className="dealer-expired-subText mt-2">
                                Requested by : <span className='font-bold text-info'>{item.rejectedBy}</span>   <br />
                                Re-upload reason : {item.rejectionMessage}
                                {/* You have submitted wrong {item.documentName},
                                please submit the relevant documents. */}
                            </div>
                        </DialogContent>
                    )}
                    {props.isApprove === 'reupload' && (
                        <DialogContent className='p-0'>
                            <div className={classes.icon}>
                                <ClearIcon className="delete-danger" fontSize="small" />
                            </div>
                            <div className="dealer-expired-subText mt-3">
                                Please write your correction message to customer ?
                            </div>
                            <div className="row col-md-12 mb-1 mt-0">
                                <div className="col-sm-12 col-md-12 pl-6 pr-4 mb-0 mt-0">

                                    <TextareaAutosize
                                        value={rejectionReason}
                                        onChange={(e: any) => setRejectionReason(e.target.value)}
                                        // maxLength={250}
                                        aria-label="minimum height" minRows={3} placeholder="Message.." className="textArea-correction p-3"
                                    />
                                </div>
                                {/* {rejectionMessageError &&
                            <span className="col-12 px-5" style={{ color: 'red' }}>Please enter reject message.</span>
                        }
                        {rejectTextErrorMessage &&
                            <span className="col-12 px-5" style={{ color: 'red' }}>Only 250 characters are allowed.</span>
                        } */}
                            </div>
                        </DialogContent>
                    )}
                </DialogContent>
                <div className="col-12 mb-2">
                    <div className="d-flex align-items-center">
                        <div className="row-space-start ml-auto">
                            <DialogActions className="mb-1 justify-content-end">
                                <Button variant="contained"
                                    color="secondary" className={props.isApprove === 'reupload' && !rejectionReason ? 'check-availability-button-access-tab actionButtomButtomsBlueDisabled' : 'check-availability-button-access-tab'}
                                    onClick={async () => {
                                        handleApprove();
                                    }}
                                    disabled={props.isApprove === 'reupload' && !rejectionReason}
                                > {props.isApprove === 'reupload' ? 'Submit' : props.isApprove === 'reject' ? 'Ok' : 'Yes'}
                                </Button>
                                {props.isApprove !== 'reject' && (
                                    <Button variant="contained" className={classes.closeButton}
                                        onClick={() => { props.handleopenClose(false); }}
                                    >Cancel
                                    </Button>
                                )}
                            </DialogActions>
                        </div>
                    </div>
                </div>
            </Dialog>
        </Fragment>
    );
}
