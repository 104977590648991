/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Paper from '@material-ui/core/Paper';
import { Prompt, useHistory } from 'react-router-dom';
import { DealerDetails } from './dealerDetails';
import Documents from './documents';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import DeleteDialog from "../../common/components/deleteDialog";
import { getAllBrands } from '../../store/actions/dealer.action';
import { getDealerDetails, getDealingIn, getRegion, getTypeOfTrade, AddShipToPartyAddress, } from '../../store/actions/onboard.actions';
import { get } from 'lodash';
import NotificationIcon from '../../common/components/NotificationIcon';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { getTermsAndConditions } from '../../store/actions/termsAndConditions.action';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import ShipToParty from './shipToParty';
import ShipToPartyCustomer from './shipToPartyCustomer';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import { AddNewAddressDailog } from './AddNewAddressDailog';
import { useRef } from 'react';

const CustomerOnboarding: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const [tabValue, setTabValue] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [newValue, setNewValue] = React.useState<any>();
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [dealerStatus, setdealerStatus] = useState<string>('')
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const b2bUserId = get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const isKamOnboarded = get(profileDetails, "data.data.basicInfo.isAddedByKam", false);
  const history = useHistory();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [open, setOpen] = React.useState<any>(false);
  const [selected, setSelected] = React.useState<string>("");
  const [deliveryType, setDeliveryType] = React.useState<string>("")
  const [sapId, setSapId] = React.useState<string>();
  const [apiResponseText, setApiResponseText] = React.useState(false);
  const [editedAddress, setEditAddress] = React.useState({});
  const [dealerDetails, setDealerDetails] = React.useState<any>()
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const addressDialogRef: any = useRef();
  const { customerTier }: any = useSelector((state: any) => state.onboard);
  const { regionData, shipToPartyAddressData, typeofTradeData, dealingInData }: any = useSelector((state: any) => state.onboard);
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);

  useEffect(() => {
    loadStatusMessage()
  }, [])

  useEffect(() => {
    loadDealerDetails()
    dispatch(getTermsAndConditions());
  }, [tabValue]);

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      setMasterCompanyBrands(shipToPartyAddressData.data.masterCompanyBrands);
      setDeliveryType(shipToPartyAddressData.data.deliveryType ? shipToPartyAddressData.data.deliveryType : 'DLV')
      setSapId(shipToPartyAddressData.data.sapCustomerId);
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {

    }
  }, [shipToPartyAddressData])

  useEffect(() => {
    let type: any = sessionStorage.getItem('customerOnboardingType');
    dispatch(getAllBrands(type && type.toLowerCase() === 'workshop' ? 'workshop' : type && type.toLowerCase() === 'dealer' ? 'dealer' : type && type.toLowerCase() === 'government' ? 'government' : 'fleet'));
    dispatch(getRegion(sessionStorage.getItem('customerOnboardingType')));
    if (dealingInData && dealingInData.data && dealingInData.data.length > 0) {
    } else {
      dispatch(getDealingIn());
    }
    if (typeofTradeData && typeofTradeData.data && typeofTradeData.data.length > 0) {
    } else {
      dispatch(getTypeOfTrade());
    }
  }, []);


  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const getNewAddressData = async (data: any) => {

    let result: any = await dispatch(AddShipToPartyAddress(b2bUserId, data));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    } else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
      handleopenClose(false);
      setOpenErrorSnackbar(true);
      setSuccessMessage(apiResponse);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }
    // setSelected("");

  }

  const loadStatusMessage = async () => {
    setLoading(true);
    let data: any = await dispatch(getDealerDetails(b2bUserId, 'shiptoparty'));
    if (data && data.data) {
      setDealerDetails(data);
    }
    setLoading(false);
  }


  function tabProps(index: any) {
    return {
      id: `wrapped-tab-${index}`,
      'aria-controls': `wrapped-tabpanel-${index}`,
    };
  }

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#1A9FE0'
      },
      secondary: {
        main: '#FF0000',
      }
    },
  });

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };



  const loadDealerDetails = async (message?: any) => {
    // setLoading(true);
    let data: any = await dispatch(getDealerDetails(b2bUserId, tabValue === 0 ? 'dealerDetails' : 'documents'));
    setdealerStatus(data && data.data && data.data.status)
    setDealerDetails(data);
    if (data && data.data && data.data.status) setLoading(false);
    if ((data && data.data && data.data.status === 'Submitted') || (data && data.data && data.data.status === 'Confirmed from Dealer')) {
      if (tabValue === 1 && message) {
        // setSuccessMessage('Request submitted Successfully without Credit Request');
        // handleopenCloseSnackbar(true);
      }
      // history.push('/onboarding');
    }
  }

  const handleClose = () => {
    setSelected("");
    setOpen(false);
  };

  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active') {
      return true;
    } else {
      return false;
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  return (
    <Fragment>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === "back") {
            setTabValue(newValue); handleopenClose(false); setDataUpdated(false);
          }
          if (!data) { handleopenClose(false); }
        }} />
      <Prompt when={dataUpdated} message={"Do you wish to proceed without saving the data?"}>
      </Prompt>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={successMessage} />
      {loading ? <FullscreenLoader /> : (
        <Fragment>
          <>
            <div className="d-flex align-items-center pt-0 user-onboard">
              <h2 className="page-title">
                Complete Registration
              </h2>
              <div className="ml-auto">
                <NotificationIcon />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="d-flex align-items-center mb-2">
                  <h6 className="dealer-table-content mb-0">Please complete your details</h6>
                  <div className="row-space-start ml-auto">
                    {tabValue == 2 && <Button
                      onClick={() => {
                        setOpen(true)
                      }}
                      className={isDisabled() ? "text-info disabledCustomclass" : 'text-info'}
                      disabled={isDisabled()}
                      startIcon={<AddIcon />}
                    ><span className="onshiftoparty-font">
                        Add New Address
                      </span>
                    </Button>}
                    <h6 className="dealer-table-content mb-0 mr-2">Status</h6>
                    <Button
                      className="defaultAddressButton cursor-default"
                      size="small"
                      variant="contained"
                      color="secondary"
                    >
                      {dealerStatus ? dealerStatus : 'In-Progress'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </>
          <Grid container className="marginScrollableArea">
            <Grid item xs={12} sm={12}>
              <ThemeProvider theme={theme}>
                <Paper square className="bg-transparent">
                  <div className="card border-bottom">
                    <Tabs
                      className={`${(dealerStatus && dealerStatus.toLowerCase() === 'in progress' || dealerStatus && dealerStatus.toLowerCase() === "awaiting feedback") ? 'tabSelect cursor-default' : 'tabSelect'}`}
                      textColor="primary"
                      indicatorColor="primary"
                      value={tabValue}
                      onChange={(e: any, data: any) => {
                        if (dealerStatus && dealerStatus.toLowerCase() === 'submitted' || dealerStatus && dealerStatus.toLowerCase() === 'dealer confirmation pending' || dealerStatus && dealerStatus.toLowerCase() === 'confirmed from dealer') {
                          if (!dataUpdated) {
                            handleTabChange(e, data)
                          } else {
                            setNewValue(data);
                            handleopenClose(true);
                          }
                        }
                      }}
                      aria-label="disabled tabs example">
                      <Tab
                        className={`${(dealerStatus && dealerStatus.toLowerCase() === 'in progress' || dealerStatus && dealerStatus.toLowerCase() === "awaiting feedback") ? 'cursor-default' : ''}`} label="Customer Details" {...tabProps(0)} />
                      <Tab className={`${(dealerStatus && dealerStatus.toLowerCase() === 'in progress' || dealerStatus && dealerStatus.toLowerCase() === "awaiting feedback") ? 'cursor-default' : ''}`} label="Documents" {...tabProps(1)} />
                      {isKamOnboarded ? (
                        <Tab className={`${(dealerStatus && dealerStatus.toLowerCase() === 'in progress' || dealerStatus && dealerStatus.toLowerCase() === "awaiting feedback") ? 'cursor-default' : ''}`} label="Shipping Address" {...tabProps(2)} />
                      ) : (
                        <Tab className={`${(dealerStatus && dealerStatus.toLowerCase() === 'in progress' || dealerStatus && dealerStatus.toLowerCase() === "awaiting feedback") ? 'cursor-default' : ''}`} label="Shipping Address" {...tabProps(2)} />
                      )}
                    </Tabs>
                  </div>
                  <div className="d-block w-100 bg-white">
                    {tabValue == 0 && <DealerDetails setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} dataUpdated={dataUpdated} setdealerStatus={setdealerStatus} />}
                    {tabValue == 1 && <Documents setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} dataUpdated={dataUpdated} setdealerStatus={setdealerStatus} />}
                    {tabValue == 2 && isKamOnboarded && <ShipToParty setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} setdealerStatus={setdealerStatus} />}
                    {tabValue == 2 && !isKamOnboarded && <ShipToPartyCustomer setTabValue={setTabValue} loadDealerDetails={loadDealerDetails} setDataUpdated={setDataUpdated} setdealerStatus={setdealerStatus} />}
                  </div>
                </Paper>
              </ThemeProvider>
            </Grid>
          </Grid>
          <Dialog
            open={open}
            onClose={(event, reason) => {
              // if (reason !== 'backdropClick') {
              handleClose()
              // }
            }}
            disableEscapeKeyDown
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="md">
            <AddNewAddressDailog setOpenDialog={handleClose} firstAddressCheck={firstAddressCheck} deliveryType={deliveryType} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={false} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} ref={addressDialogRef} addDealer={false} dealerStatus={dealerStatus} />
          </Dialog>
        </Fragment>
      )}
    </Fragment>
  )
}

export default CustomerOnboarding;
