import React, { useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, TextField, Button } from '@material-ui/core/';
import DatePicker from '../../common/components/dateRangePicker';

const DataArchival = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [data, setData] = useState([
        { orderNo: '123', price: '$100' },
        { orderNo: '456', price: '$150' },
        // Add more data as needed
    ]);
    const [searchText, setsearchText] = useState<any>('');
    const [startDate, setstartDate] = useState<any>(null);
    const [endDate, setendDate] = useState<any>(null);

    const handleSearchChange = (e: any) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = data.filter((item) =>
        item.orderNo.includes(searchTerm)
    );

    return (
        <div>
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Data Archival
                </h2>
            </div>
            <div className="row mt-4">
                <div className="col-12">
                    <div className="cardCommon p-3">
                        <div className="d-flex align-items-center">
                            <h5 className="page-sub-title mb-2">Search Archived Data</h5>
                        </div>
                        <TextField
                            value={searchText}
                            onChange={(e) => {
                                setsearchText(e.target.value)
                            }}
                            size="small" className="col-3 col-sm-5 col-md-3 col-lg-3 common-input-font-size commonRoundedInputs searchField" label="Search by Order No" variant="outlined" />
                        <div className="col-4 col-sm-6 col-md-4 col-lg-4 d-inline-flex date-picker-50">
                            <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                setstartDate={setstartDate}
                                setendDate={setendDate}
                                isEnableOnlyThirtyDays={true}
                                onChange={(start: any, end: any) => {
                                    setstartDate(start);
                                    setendDate(end);
                                }}
                            />
                        </div>
                        <TextField
                            value={searchText}
                            onChange={(e) => {
                                setsearchText(e.target.value)
                            }}
                            size="small" className="col-3 col-sm-5 col-md-3 col-lg-3 common-input-font-size commonRoundedInputs searchField" label="Search by Invoice No" variant="outlined" />
                        <Button
                            className="col-1 col-sm-2 col-md-1 col-lg-1 font-size-11 pl-0 addOrderEnqButton ml-2"
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                //   loadOrderList()
                            }}
                        >Search</Button>
                    </div>
                </div>
            </div>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Order No</TableCell>
                        <TableCell>Price</TableCell>
                        {/* Add more table headers if needed */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {filteredData.map((item, index) => (
                        <TableRow key={index}>
                            <TableCell>{item.orderNo}</TableCell>
                            <TableCell>{item.price}</TableCell>
                            {/* Add more table cells if needed */}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default DataArchival;
