/* eslint-disable */
import React, { Fragment, Dispatch, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TextField from '@material-ui/core/TextField';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Checkbox from '@material-ui/core/Checkbox';
import AddIcon from '@material-ui/icons/Add';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { createFavorites, updateFavorites } from '../../store/actions/favorites.actions';
import { searchProducts, removeProductData } from "../../store/actions/products.actions";
import ErrorFormat from '../../common/components/errorFormat';
import Loading from '../../common/components/loading';
import ImportDialog from '../CreateEnquiry/ImportExcelDialog';
import { getNewBrand, getNewlyAddedBrand } from '../../common/components/util';
import { RESTRICTED_ACCESS } from '../../constants/restrictedAccessConstants';
import _, { get } from "lodash";

const headCells = [
  { id: 'SrNo', numeric: true, disablePadding: true, label: 'Sr. No.' },
  { id: 'Part No', numeric: true, disablePadding: true, label: 'Part No' },
  { id: 'Desc', numeric: false, disablePadding: true, label: 'Desc' },
  { id: 'qty', numeric: true, disablePadding: true, label: 'Qty' },
  { id: 'Brand', numeric: false, disablePadding: true, label: 'Brand' },
  { id: 'action', numeric: true, disablePadding: true, label: 'Action' },
];

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  header: {
    color: "#133F8A",
    fontWeight: 900,
    fontSize: "0.813rem",
    fontFamily: 'Nunito'
  },
  title: {
    flex: '1 1 100%',
  },
  buttonDel: {
    color: "#1A9FE0",
    fontSize: '0.625rem',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    float: 'right'
  },
  buttonIconDel: {
    color: "#1A9FE0",
  },
  buttonExcel: {
    fontSize: '0.75rem',
    textTransform: "none",
    color: "#1A9FE0",
    float: "right",
  },
  buttonAdd: {
    margin: "0.6rem 0.6rem 0.6rem 1.2rem",
    fontSize: 35,
    borderRadius: 45,
    height: 35,
    width: 35,
    textTransform: 'none',
  },
  tablehead: {
    color: "#133F8A",
    fontSize: "0.813rem",
    fontFamily: 'Nunito',
    fontWeight: 900,
  },
  bodyContent: {
    color: '#666666',
    fontSize: '0.813rem',
    fontFamily: 'Nunito',
    fontWeight: 600
  },
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 140,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
}));

export default function CreateFavorite(props: any) {
  const [selected, setSelected] = React.useState<any>([]);
  const [parts, setParts] = React.useState<any>([]);
  const [brands, setBrands] = React.useState([]);
  const [i, setIndex] = React.useState();
  const [name, setName] = React.useState('');
  const [addPart, setIsAddPart] = React.useState(false);
  const [isGlobalChecked, setIsGlobalChecked] = React.useState(false);
  const dispatch: Dispatch<any> = useDispatch();
  const { productsData } = useSelector((state: any) => state.products);
  const { createFavorite, favoritesDetails, favoritesList, updateFavorite }: any = useSelector((state: any) => state.favorites);
  const [nameError, showError] = React.useState<boolean>(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const { enquirySessionId }: any = useSelector(
    (state: any) => state.createUpdateEnquiry
  );
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { materialGroups }: any = useSelector((state: any) => state.materialGroups)
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  let materialGroupsData = get(materialGroups, 'data.data', []);
  const b2bUserId = get(profileDetails, 'data.data.basicInfo.b2bUserId', "");
  const firstName = get(profileDetails, 'data.data.basicInfo.firstName', "");
  let inputRefs: any = [];

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< EXISTING CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // let enquiryMapping: any = {};

  // materialGroupsData.forEach((group: any) => {
  //   group.materialGroup.forEach((item: any) => {
  //     enquiryMapping[item] = group.make;
  //   })
  // })

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> NEW CODE >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  let enquiryMappingReverse: any = {};

  let temp: any = []

  if (customerTierType !== "Workshop") {
    for (let item of materialGroupsData) {
      // if (item.make != "OES") {
      temp.push(item)
      // }
    }
    // let removeOES = materialGroupsData && materialGroupsData.filter((ele: any) => {
    //   console.log("ELELELELELs", ele)
    //   return ele.make != "OES"
    // })
  } else if (customerTierType === "Workshop" && profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.selectedBrand) {
    let selectedBrand = profileDetails?.data?.data?.customerInfo?.selectedBrand
    if (selectedBrand.length == 1 && selectedBrand[0].organizationId === "2195") {
      let brand = _.filter(materialGroupsData, (i) => {
        return i.orgId == "2195"
      });
      if (!_.isEmpty(brand)) {
        temp.push(brand[0])
      }
    } else if (selectedBrand.length > 1) {
      for (let item of selectedBrand) {
        let brand = _.filter(materialGroupsData, (i) => {
          return i.orgId == item.organizationId
        });
        if (!_.isEmpty(brand)) {
          temp.push(brand[0])
        }
      }
      let mergeMaterialGroup: any = [];
      for (let i of temp) {
        mergeMaterialGroup = mergeMaterialGroup.concat(i.materialGroup)
      }
      let finalMaterialGroup = [{
        displayName: "Tradepoint",
        make: "OES",
        materialGroup: mergeMaterialGroup,
        orgId: "2195"
      }]
      temp = finalMaterialGroup
    }
  }
  materialGroupsData = temp;
  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    enquiryMappingReverse[group.make] = group.materialGroup.toString();
  })

  let enquiryMapping: any = {};

  materialGroupsData && Array.isArray(materialGroupsData) && materialGroupsData.forEach((group: any) => {
    group.materialGroup.forEach((item: any) => {
      enquiryMapping[item] = group.make;
    })
  })

  useEffect(() => {
    if (parts && parts.length > 0) {
      setParts(parts);
      let make: any = [];
      let addedParts: any = [];
      parts.map((part: any) => (
        addedParts.push(part.added)
      ));
      let isAllPartsAdded = addedParts && addedParts && addedParts.every((p: any) => p === true);
      parts.map((part: any) => {
        if (part.quantity && part.productId && part.make && isAllPartsAdded) {
          setIsValid(true);
          make.push(part.make);
        } else {
          setIsValid(false)
        }
        return undefined;
      });
      setBrands(make);
    } else if (parts && parts.length === 0) {
      setBrands([]);
    }
  }, [parts]);

  useEffect(() => {
    if (favoritesDetails && favoritesDetails.data && favoritesDetails.data.id) {
      setName(favoritesDetails.data.name);
      setIsGlobalChecked(favoritesDetails.data.isGlobal);
      setBrands(favoritesDetails.data.make);
      if (favoritesDetails.data.products && favoritesDetails.data.products.length > 0) {
        favoritesDetails.data.products.map((product: any) => product.added = true);
        setParts(favoritesDetails.data.products);
      }
    }
  }, [favoritesDetails])

  const getUniqueBrands = (brands: any) => {
    let uniqueBrands = brands.filter((c: any, index: any) => {
      return brands.indexOf(c) === index;
    });
    return uniqueBrands;
  }

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelecteds: any = parts.map((n: any, index: any) => n.productId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: any, name: any) => {
    const selectedIndex: any = selected.indexOf(name);
    let newSelected: any = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const saveParts = useCallback((index: any) => {
    const partsData: any = [...parts];
    const newbrands: any = [...brands];
    const newData = partsData[index];
    if (newData && newData.quantity >= 1) {
      partsData.map((product: any) => {
        if (product && product.productId && newData.productId.trim() && product.productId === newData.productId.trim() && product.make && !newData.added) {
          product.quantity = parseInt(product.quantity);
          product.quantity += newData.quantity && parseInt(newData.quantity) ? parseInt(newData.quantity) : 1;
          product.quantity = product.quantity && product.quantity.toString() && product.quantity.toString().slice(0, 5);
          product.error = null;
          newData.added = true;
          partsData.splice(index, 1);
          setParts(partsData);
        }
        return undefined;
      });

      if (productsData && productsData.data && productsData.data.data && productsData.data.data.length > 0 && productsData.data.data[0].attributes && productsData.data.data[0].attributes.materialGroup && enquiryMapping[`${formatMaterialGroup(productsData.data.data[0].attributes.materialGroup.value.toUpperCase())}`] && !newData.added) {
        newData.productIdDescription = productsData.data.data[0].description && productsData.data.data[0].description.EN;
        newData.make = enquiryMapping[`${formatMaterialGroup(productsData.data.data[0].attributes.materialGroup.value.toUpperCase())}`];
        newData.error = '';
        newData.added = true;
        newbrands.push(newData.make);
        setBrands(newbrands);
        partsData.splice(index, 1);
        partsData.push(newData);
        setParts(partsData);
        dispatch(removeProductData());
      } else if (!newData.added) {
        partsData[index].error = 'Invalid Part Number';
        setParts(partsData);
      }
    } else {
      if (partsData && partsData[index]) partsData[index].qerror = 'required';
      setParts(partsData);
    }
  }, [productsData, brands, parts, dispatch]) //eslint-disable-line

  useEffect(() => {
    if (productsData && productsData.data && productsData.data.data && addPart) {
      saveParts(i);
    }
  }, [productsData, addPart, i]); // eslint-disable-line

  function EnhancedTableHead(props: any) {
    const { classes, onSelectAllClick, numSelected, rowCount } = props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" className="pl-3">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all parts' }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className={classes.header}
              align="left"
              padding={headCell.disablePadding ? 'none' : 'default'}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const removeParts = (e: any, index: any) => {
    const partsData: any = [...parts];
    partsData.splice(index, 1);
    setParts(partsData);
  };

  const onChangePartNo = (e: any, index: any) => {
    const partsData: any = [...parts];
    partsData[index].productId = e.target.value;
    setParts(partsData);
    // setPartsAdd(Math.random());
  }

  const onChangeName = (e: any) => {
    setName(e.target.value);
    if (e.target.required) {
      if (e.target.value === "" || e.target.value.trim() === "")
        showError(true);
      else
        showError(false);
    }
  }

  const onChangeQuantity = (e: any, index: any) => {
    let value: any = e.target.value && parseInt(e.target.value) && /^[+]?\d+([.]\d+)?$/.test(e.target.value) &&
      parseInt(e.target.value) >= 1
      ? parseInt(e.target.value)
      : ''
    const partsData: any = [...parts];
    delete partsData[index].qerror;
    partsData[index].quantity = value;
    setParts(partsData);
  }

  const deleteSelectedParts = () => {
    let productsTemp: any = JSON.parse(
      JSON.stringify(parts)
    );
    productsTemp = productsTemp.filter(
      (item: any) => !selected.includes(item.productId)
    );
    setParts([...productsTemp]);
  }

  const showImportExcel = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.UPLOAD_ENQUIRY_EXCEL);

  const PartsForm = (part: any, index: any) => (
    <>
      {part.make ? (
        <TableRow key={index}>
          <TableCell className="pl-3">
            <Checkbox
              checked={selected && selected.includes(part.productId) ? true : false}
              inputProps={{ 'aria-labelledby': `enhanced-table-checkbox-${index}` }}
              onClick={(event: any) => handleClick(event, part.productId)}
            />
          </TableCell>
          <TableCell className={classes.bodyContent}>{index + 1}</TableCell>
          <TableCell className={`w-200p p-0 ${classes.bodyContent}`}>{part.productId}</TableCell>
          <TableCell className={`w-150p p-0 ${classes.bodyContent}`}>{part.productIdDescription}</TableCell>
          {favoritesDetails && favoritesDetails.data && favoritesDetails.data.id ? (
            <TableCell className={`w-100p p-0 ${classes.bodyContent}`}><TextField
              onChange={(e: any) => onChangeQuantity(e, index)}
              value={part.quantity}
              size="small"
              className="commonRoundedInputs common-input-font-size"
              label="Quantity"
              inputProps={{ maxLength: 5 }}
              variant="outlined"
            /></TableCell>
          ) : (
            <TableCell className={`p-0 ${classes.bodyContent}`}>{part.quantity}</TableCell>
          )}
          <TableCell className={`p-0 ${classes.bodyContent}`}>
            {(customerTierType && customerTierType.toLowerCase() === 'workshop' || customerTypeAccess.includes("workshop")) ? getNewBrand(part.make) : getNewlyAddedBrand(part.make)}
          </TableCell>
          <TableCell className={`p-0 ${classes.bodyContent}`}>
            <IconButton aria-label="delete" size="small" onClick={(e) => { removeParts(e, index); }} className={classes.buttonIconDel}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow key={index} className={classes.bodyContent}>
          <TableCell className="pl-3">
            <Checkbox
              inputProps={{ 'aria-labelledby': `parts-checkbox-${index}` }}
            />
          </TableCell>
          <TableCell className={classes.bodyContent}>{index + 1}</TableCell>
          <TableCell className={`w-200p p-0 ${classes.bodyContent}`}>
            <TextField
              autoFocus
              inputRef={ref => inputRefs.push(ref)}
              onKeyDown={(e: any) => {
                const event = e;
                if (e.keyCode == 13) {
                  if (part.productId && part.productId.trim()) {
                    inputRefs[1].focus();
                    event.preventDefault();
                  }
                }
              }}
              onChange={(e: any) => onChangePartNo(e, index)}
              value={part.productId}
              size="small"
              className="commonRoundedInputs  w-100"
              label="Part Number"
              variant="outlined"
            />
            {part.error ? <h6 className="text-red font-12">{part.error}</h6> : ''}
          </TableCell>
          <TableCell className={`w-150p p-0 ${classes.bodyContent}`}></TableCell>
          <TableCell className={`w-100p p-0 ${classes.bodyContent}`}>
            <TextField
              onChange={(e: any) => onChangeQuantity(e, index)}
              inputRef={ref => inputRefs.push(ref)}
              onKeyDown={(e: any) => {
                const event = e;
                if (e.keyCode == 13) {
                  if (part.productId && part.productId.trim()) {
                    dispatch(searchProducts(customerTierType, part.productId, enquirySessionId, firstName, b2bUserId, [{ productId: part.productId, quantity: part.quantity }]));
                    setIndex(index);
                    setIsAddPart(true);
                    inputRefs = [];
                  }
                }
              }}
              value={part.quantity}
              size="small"
              className="commonRoundedInputs w-100"
              label="Quantity"
              inputProps={{ maxLength: 5 }}
              variant="outlined"
            />
            {part.qerror && !part.quantity && (
              <h6 className="text-red font-12">{part.qerror}</h6>
            )}
          </TableCell>
          <TableCell className={`p-0 ${classes.bodyContent}`}></TableCell>
          <TableCell className={`p-0 ${classes.bodyContent}`}>
            <IconButton aria-label="delete" size="small" onClick={(e) => {
              dispatch(searchProducts(customerTierType, part.productId, enquirySessionId, firstName, b2bUserId, [{ productId: part.productId, quantity: part.quantity }])); setIndex(index); setIsAddPart(true);
            }} className={`${classes.buttonIconDel} p-1 align-center`}>
              <AddIcon fontSize="inherit" />
            </IconButton>
            <IconButton aria-label="delete" size="small" onClick={(e) => { removeParts(e, index); }} className={`${classes.buttonIconDel} p-1 align-center`}>
              <DeleteIcon fontSize="inherit" />
            </IconButton>
            {(
              <>
                <br />
                <span className="errorMsg">Add Parts</span>
              </>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );

  const addParts = () => {
    showErrorMessage(null);
    const newPartsData: any = [...parts];
    newPartsData.push({
      productId: '', quantity: 1, added: false
    });
    setParts(newPartsData);
  };

  function isNameExists(name: any, favorite: any) {
    if ((favorite && favorite.id) && (favorite && favorite.name === name)) return false;
    let array: any = [];
    favoritesList && favoritesList.data && favoritesList.data.favouriteList && favoritesList.data.favouriteList.length > 0 && favoritesList.data.favouriteList.map((favorite: any) => {
      array.push(favorite.name)
      return undefined;
    });
    if (array.includes(name)) return true;
  }

  async function createUpdateFavorite(favorite: any) {
    showErrorMessage(null);
    const favoriteData: any = {};
    const data: any = [];
    let isExist: any = false;
    if (name && name.trim() !== "") isExist = await isNameExists(name, favorite);
    if (name && name.trim() !== "" && !isExist) {
      if (parts && parts.length > 0) {
        parts.map((part: any) => {
          delete part.error;
          delete part.added;
          data.push(part);
          return undefined;
        });
        setParts(data);
      }
      favoriteData.name = name;
      favoriteData.isGlobal = isGlobalChecked;
      favoriteData.make = getUniqueBrands([...brands]);
      favoriteData.updatedAt = new Date();
      favoriteData.customerId = sessionStorage.getItem('customerId');
      favoriteData.userId = sessionStorage.getItem('webApplicationLoginId');
      favoriteData.customerName = sessionStorage.getItem('customerName');
      favoriteData.isDeleted = false;
      favoriteData.products = [...parts];

      if (favorite && favorite.id) {
        if (favoriteData.make && favoriteData.make.length === 0 && favoriteData.products && favoriteData.products.length === 0) {
          showErrorMessage('Please add at leaset one part');
        } else {
          dispatch(updateFavorites(favorite.id, favoriteData));
        }
      } else {
        if (favoriteData.make && favoriteData.make.length === 0 && favoriteData.products && favoriteData.products.length === 0) {
          showErrorMessage('Please add at leaset one part');
        } else {
          dispatch(createFavorites(favoriteData));
        }
      }
    } else {
      isExist ? showErrorMessage('Favorite name is already exist') : showError(true);
    }
  }

  const formatMaterialGroup = (data: any) => {
    if (data) {
      return data.substring(0, 5).toUpperCase()
    } else {
      return "XXXXX"
    }
  }

  const commonInsertionLogic = async (jsonData: any, fetchFromRedux?: any) => {
    if (jsonData && Array.isArray(jsonData) && jsonData.length > 0) {
      let tempData = jsonData.filter((item: any) => item.productId && item.productId.trim());
      let partsList: any = tempData.map(item => {
        return item.productId.trim();
      })
      let searchProduct: any = [];
      tempData.map(item => (
        searchProduct.push({ productId: item.productId.trim(), quantity: item.quantity })
      ));
      let apiData: any = await dispatch(searchProducts(customerTierType, partsList, enquirySessionId, firstName, b2bUserId, searchProduct));
      let reduxData: any = sessionStorage.getItem("tempProducts");
      let productsTemp: any = JSON.parse(
        JSON.stringify(
          fetchFromRedux && reduxData ? JSON.parse(reduxData) : parts
        )
      );
      // let productsTemp: any = favoritesDetails && favoritesDetails.data && favoritesDetails.data.products ? favoritesDetails.data.products : [];
      jsonData.map((item: any) => {
        let isPresent: any = apiData && apiData.data && apiData.data.length > 0 ? apiData.data.find((responsePart: any) => {
          return responsePart.productId.trim().toUpperCase() === item.productId.trim().toUpperCase();
        }) : null;
        if (item.productId && item.productId.trim() && productsTemp && Array.isArray(productsTemp)) {
          let containsItem = false;
          productsTemp.map((product: any) => {
            if (product.productId && item.productId.trim() && product.productId.toUpperCase() === item.productId.trim().toUpperCase()) {
              product.quantity = parseInt(product.quantity);
              product.quantity += item.quantity && parseInt(item.quantity) ? parseInt(item.quantity) : 1;
              product.quantity = product.quantity && product.quantity.toString() && product.quantity.toString().slice(0, 5)
              product.make = isPresent && isPresent.attributes && isPresent.attributes.materialGroup ? enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`] : '';
              product.error = null;
              product.added = true;
              containsItem = true;
            }
            return product
          })
          if (!containsItem) {
            productsTemp.push({
              "quantity": item.quantity && parseInt(item.quantity) ? parseInt(item.quantity) : 1,
              "productId": isPresent && isPresent.productId ? isPresent.productId : item.productId.trim(),
              "make": isPresent && isPresent.attributes && isPresent.attributes.materialGroup ? enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`] : '',
              "materialGroup": isPresent && isPresent.attributes && isPresent.attributes.materialGroup ? isPresent.attributes.materialGroup.value : "",
              "productIdDescription": isPresent && isPresent.description ? isPresent.description["EN"] : "",
              "orgId": isPresent && isPresent.orgId ? isPresent.orgId : "",
              "isValid": isPresent && isPresent.attributes && isPresent.attributes.materialGroup && enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`] ? true : false,
              "added": isPresent && isPresent.attributes && isPresent.attributes.materialGroup && enquiryMapping[`${formatMaterialGroup(isPresent.attributes.materialGroup.value.toUpperCase())}`] ? true : false,
              "error": 'Invalid Part Number',
            })
          }
        }
        return undefined;
      })
      setParts([...productsTemp]);
    }
  }

  const classes = useStyles();

  const manageGlobal = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.MANAGEGLOBAL_FAV);

  return (
    <Fragment>
      <div className={classes.root}>
        <>
          <Toolbar
            className={clsx(classes.root)}
          >
            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
              <h4 className="page-title"> {favoritesDetails && favoritesDetails.data && favoritesDetails.data.id ? 'Update Favorites' : 'Create Favorites'}
                {selected.length > 0 ? (
                  <>
                    <Button className={classes.buttonDel} startIcon={<DeleteIcon fontSize="small" />} onClick={() => deleteSelectedParts()}>
                      <span className="text-info">Delete Selected</span>
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </h4>
            </Typography>

          </Toolbar>
          <Toolbar className="mb-2">
            <TextField
              required
              id="name"
              onChange={(e: any) => onChangeName(e)}
              error={nameError}
              value={name}
              size="small"
              className="col-lg-6 col-md-6 col-sm-6 pr-3 commonRoundedInputs common-input-font-size"
              label="Enter Favorite Name"
              variant="outlined"
            />
            {manageGlobal && <Checkbox
              checked={isGlobalChecked}
              onChange={() => setIsGlobalChecked(!isGlobalChecked)}
              className="checkbox" />}
            <span className="text-info mr-5">Is Global</span>
            {showImportExcel &&
              <ImportDialog
                isOpened={() => {
                  sessionStorage.setItem(
                    "tempProducts",
                    JSON.stringify(parts ? parts : [])
                  );
                }}
                commonInsertionLogic={(jsonData: any) => {
                  commonInsertionLogic(jsonData, true);
                }}
              />
            }
          </Toolbar>
        </>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            // size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected && selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={parts && parts.length}
            />
            <TableBody>
              <>
                {(parts && parts.length > 0 && parts.map((part: any, index: any) => (
                  PartsForm(part, index)
                )))}
                <IconButton aria-label="delete" onClick={() => { addParts(); }} className={classes.buttonAdd}>
                  <AddIcon fontSize="medium" />
                </IconButton>
              </>
            </TableBody>
          </Table>
        </TableContainer>
      </div >
      <DialogContent>
        {((createFavorite && createFavorite.error) || (updateFavorite && updateFavorite.error)) && (
          <ErrorFormat error={createFavorite.error || updateFavorite.error} />
        )}
        {((createFavorite && createFavorite.loading) || (updateFavorite && updateFavorite.loading)) && (
          <div className="text-center mx-auto">
            <Loading />
          </div>
        )}
        <div className="mx-auto text-center text-red">{errorMessage}</div>
      </DialogContent>

      <DialogActions className="mt-3 mb-2 justify-content-end">
        {favoritesDetails && favoritesDetails.data && favoritesDetails.data.id ? (
          <Button variant="contained" disabled={!isValid}
            color="secondary" className={`createFavourite ${classes.createButton}`} onClick={() => createUpdateFavorite(favoritesDetails.data)}>
            Update
          </Button>
        ) : (
          <Button variant="contained" disabled={!isValid}
            color="secondary" className={`createFavourite ${classes.createButton}`} onClick={() => createUpdateFavorite(null)}>
            Create
          </Button>
        )}
        <Button variant="contained" className={classes.closeButton} onClick={() => props.setOpenDialog()}>Close</Button>
      </DialogActions>
    </Fragment>
  );
}
