/* eslint-disable */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import './RequestCorrection.css';
import moment from "moment-timezone";
import { Dialog } from '@material-ui/core';
import { useState, useEffect } from 'react';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    correctionTextCSS: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }
}));


const RequestCorrection = (props: any) => {
    const [verify, setVerify] = useState<any>(false);
    const [correctionList, setCorrectionList] = React.useState([])
    const { requestCorrection } = useSelector((state: any) => state.specialPrice)
    const classes = useStyles()
    const regex: any = /<br\s*[\/]?>/gi;

    useEffect(() => {
        setCorrectionList(requestCorrection && requestCorrection.data && requestCorrection.data.data && requestCorrection.data.data.history)
    }, [requestCorrection])



    return (
        <Fragment>
            <Dialog
                open={props.openCorrection ? true : false}
                onClose={() => { props.handleCloseCorrection(); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <div className={classes.root}>
                    <div className="col-12 mt-3 p-0">
                        {!verify && (
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">

                                <h4 className="shift-to-party-page-title px-4 d-flex flex-row">
                                    <h4 className="shift-to-party-page-title mt-2">Correction History  </h4>
                                    <IconButton aria-label="close" className="float-right" style={{ marginLeft: "200px" }} onClick={() => { props.handleCloseCorrection(); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        )}
                    </div>
                    {!verify && (
                        <div className="row justify-content-end">
                            <div className="col-10 mt-0">
                                <DialogContent>
                                    {/* <div className={classes.icon}>
                                    <CheckCircleIcon className="correction-meesage-warning" fontSize="small" />
                                </div> */}
                                    <div>
                                        <DialogContentText id="alert-dialog-description">
                                            {/* <div className={`mt-3 ${classes.correctionTextCSS}`}>
                                            <h6 className="mb-0">Please write your correction message. </h6>
                                        </div> */}
                                        </DialogContentText>
                                    </div>
                                    {correctionList && correctionList.map((data: any) => {
                                        return <div>
                                            <div className="subTitle text-preline mt-2">{data.message ? data.message.replace(regex, "\n") : '-'}</div>
                                            <h6 className="document-sub-content pt-1 pb-2 mb-2 border-bottom">{data.addedOn ? moment(data.addedOn).tz('Asia/Dubai').format('hh:mm A DD MMM YYYY') : '-'}</h6>
                                        </div>
                                    })
                                    }
                                </DialogContent>
                            </div>
                            <div className="col-2 mt-0">
                                <IconButton aria-label="close" className="float-right pr-5 mt-3 cursor-pointer" onClick={() => { props.handleopenClose(false); }}>
                                    {/* <CloseIcon /> */}
                                </IconButton>
                            </div>
                        </div>
                    )}

                </div>
                {/* {!verify && (
                <div className="row col-md-12 mb-1 mt-0 px-5">
                    <div className="col-sm-12 col-md-12 px-5 pr-2 mb-0 mt-0">
                        <div className="col-6">
                            <TextareaAutosize id="correctionText" value={correctionText} onChange={(e: any) => handleCustomerChange(e)}
                                aria-label="minimum height" minRows={6}
                                placeholder="Correction Message.." className="textArea-correction p-4"
                            />
                        </div>
                        {correctionTextError &&
                            <span className="col-12" style={{ color: 'red' }}>Please enter correction message.</span>
                        }
                        {correctionTextErrorMessage &&
                            <span className="col-12" style={{ color: 'red' }}>Only 500 characters are allowed.</span>
                        }
                    </div>
                </div>
            )} */}
                {!verify && (
                    <div className="row-space-start col-12">
                        <div className="col-12 m-0 p-0">
                            <div className="row mb-0 px-2">
                                <div className="col-12 mt-2">
                                    <div className="d-flex align-items-center">
                                        <div className="row-space-start ml-auto">
                                            <DialogActions className="mt-3 mb-1 justify-content-end">
                                                <Button variant="contained" className={classes.closeButton}
                                                    onClick={() => { props.handleCloseCorrection() }}
                                                >Close
                                                </Button>
                                            </DialogActions>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <div className="row-space-start ml-auto">
                    <DialogActions className="mb-1 justify-content-end">
                        {verify && (
                            <Button variant="contained" className={classes.closeButton}
                                onClick={() => { props.handleCloseCorrection() }}
                            >Close
                            </Button>
                        )}
                    </DialogActions>
                </div>
            </Dialog>
        </Fragment>
    )
}

export default RequestCorrection