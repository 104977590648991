import { ANNOUNCEMENTS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
  announcements: null,
  announcementDetail: null
};

function announcementsReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    case ANNOUNCEMENTS.GET_ANNOUNCEMENTS_FETCHING:
      return {
        ...state,
        announcements: { loading: true, data: null, err: null }
      };
    case ANNOUNCEMENTS.GET_ANNOUNCEMENTS_SUCCESS:
      return {
        ...state,
        announcements: { loading: false, data: action.payload.data, err: null }
      };
    case ANNOUNCEMENTS.GET_ANNOUNCEMENTS_ERROR:
      return {
        ...state,
        announcements: { loading: false, data: null, err: action.payload }
      };
    case ANNOUNCEMENTS.GET_ANNOUNCEMENT_DETAIL_FETCHING:
      return {
        ...state,
        announcementDetail: { loading: true, data: null, err: null }
      };
    case ANNOUNCEMENTS.GET_ANNOUNCEMENT_DETAIL_SUCCESS:
      return {
        ...state,
        announcementDetail: { loading: false, data: action.payload.data, err: null }
      };
    case ANNOUNCEMENTS.GET_ANNOUNCEMENT_DETAIL_ERROR:
      return {
        ...state,
        announcementDetail: { loading: false, data: null, err: action.payload }
      };
    case ANNOUNCEMENTS.SEND_ANNOUNCEMENT_TO_CUSTOMER_FETCHING:
      return {
        ...state,
        announcementNotifyRes: { loading: true, data: null, err: null }
      }
    case ANNOUNCEMENTS.SEND_ANNOUNCEMENT_TO_CUSTOMER_SUCCESS:
      return {
        ...state,
        announcementNotifyRes: { loading: false, data: action.payload.data, err: null }
      }
    case ANNOUNCEMENTS.SEND_ANNOUNCEMENT_TO_CUSTOMER_ERROR:
      return {
        ...state,
        announcementNotifyRes: { loading: false, data: null, err: action.payload }
      }
    default:
      return state;
  }
};

export default announcementsReducer;
