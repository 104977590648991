
import { MYSCHEDULE } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { myscheduleService } from "../../services/myschedule.service";

export const myscheduleActions = {
  getMyschedulesList,
  getMyscheduleDetails,
  createMyschedules,
  updateMyschedules,
  deleteMyschedules
};

export function getMyschedulesList( date?: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(MYSCHEDULE.GET_MYSCHEDULE_LIST_FETCHING));
    const response = await myscheduleService.getMyScheduleList(date);
    if (response.error) {
      dispatch(apiError(MYSCHEDULE.GET_MYSCHEDULE_LIST_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(MYSCHEDULE.GET_MYSCHEDULE_LIST_SUCCESS, response));
      return response || [];
    }
  }
}

export function getMyscheduleDetails(favorite: any) {
  return async (dispatch: any) => {
    dispatch(apiSuccess(MYSCHEDULE.GET_MYSCHEDULE_DETAILS_SUCCESS, favorite));
    return favorite || {};
  };
}

export function createMyschedules(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(MYSCHEDULE.CREATE_MYSCHEDULE_FETCHING));
    const response = await myscheduleService.createSchedule(data);
    if (response.error) {
      dispatch(apiError(MYSCHEDULE.CREATE_MYSCHEDULE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(MYSCHEDULE.CREATE_MYSCHEDULE_SUCCESS, response));
      return response || [];
    }
  }
}

export function updateMyschedules(data: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(MYSCHEDULE.UPDATE_MYSCHEDULE_FETCHING));
    const response = await myscheduleService.editSchedule(data);
    if (response.error) {
      dispatch(apiError(MYSCHEDULE.UPDATE_MYSCHEDULE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(MYSCHEDULE.UPDATE_MYSCHEDULE_SUCCESS, response));
      return response || [];
    }
  };
}

export function deleteMyschedules(id: any) {
  return async (dispatch: any) => {
    dispatch(apiStart(MYSCHEDULE.DELETE_MYSCHEDULE_FETCHING));
    const response = await myscheduleService.deleteSchedule(id);
    if (response.error) {
      dispatch(apiError(MYSCHEDULE.DELETE_MYSCHEDULE_ERROR, response));
      return response || [];
    } else {
      dispatch(apiSuccess(MYSCHEDULE.DELETE_MYSCHEDULE_SUCCESS, response));
      return response || [];
    }

  };
}
