import { API_ENDPOINTS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware";

export const requestSpecialPriceService = {
    getRequestSpecialPriceAPI,
    requestSpecialPriceAccessAPI,
    getViewRequestStatusAPI
};

async function getRequestSpecialPriceAPI(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE + `/submitOrSaveRequestDetails`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function requestSpecialPriceAccessAPI(data: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE + `/special-price-request-access`
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}

async function getViewRequestStatusAPI(B2bEnquiryId: any) {
    const apiPath = API_ENDPOINTS.SPECIAL_PRICE + `/specialPriceDetails/${B2bEnquiryId} `
    const options = {
        path: apiPath,
        method: "GET",
        formdata: true,
        customHeaders: {}, 
    };
    return await makeRequest(options)
}
