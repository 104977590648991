import { REQUEST_SPECIAL_PRICE, VIEW_SELECTED_PARTS } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState: any = {
    requestSpecialPriceDetails: null,
    requestAccessData: null,
    viewSpecialPriceDetails: null,
    viewRequestDataAPI: null,
    isSPRModifyData: false
};

function requestSpecialPriceReducer(state: any = initialState, action: IActionBase): IGlobal {
    switch (action.type) {
        case REQUEST_SPECIAL_PRICE.GET_REQUEST_SPECIAL_PRICE_FETCHING:
            return {
                ...state,
                requestSpecialPriceDetails: { loading: true, data: null, err: null }
            };
        case REQUEST_SPECIAL_PRICE.GET_REQUEST_SPECIAL_PRICE_SUCCESS:

            return {
                ...state,
                requestSpecialPriceDetails: { loading: false, data: action.payload, err: null }
            };
        case REQUEST_SPECIAL_PRICE.GET_REQUEST_SPECIAL_PRICE_ERROR:
            return {
                ...state,
                requestSpecialPriceDetails: { loading: false, data: null, err: action.payload }
            };

        case REQUEST_SPECIAL_PRICE.REQUEST_SPECIAL_PRICE_ACCESS_FETCHING:
            return {
                ...state,
                requestAccessData: { loading: true, data: null, err: null }
            };
        case REQUEST_SPECIAL_PRICE.REQUEST_SPECIAL_PRICE_ACCESS_SUCCESS:
            return {
                ...state,
                requestAccessData: { loading: false, data: action.payload, err: null }
            };
        case REQUEST_SPECIAL_PRICE.REQUEST_SPECIAL_PRICE_ACCESS_ERROR:
            return {
                ...state,
                requestAccessData: { loading: false, data: null, err: action.payload }
            };

        case REQUEST_SPECIAL_PRICE.VIEW_SPECIAL_PRICE_FETCHING:
            return {
                ...state,
                viewSpecialPriceDetails: { loading: true, data: null, err: null }
            };
        case REQUEST_SPECIAL_PRICE.VIEW_SPECIAL_PRICE_SUCCESS:
            return {
                ...state,
                viewSpecialPriceDetails: { loading: false, data: action.payload, err: null }
            };
        case REQUEST_SPECIAL_PRICE.VIEW_SPECIAL_PRICE_ERROR:
            return {
                ...state,
                viewSpecialPriceDetails: { loading: false, data: null, err: action.payload }
            };
        case VIEW_SELECTED_PARTS.VIEW_SELECTED_SUCCESS:
            return {
                ...state,
                viewRequestDataAPI: action.payload
            };
        case VIEW_SELECTED_PARTS.MODIFY_SPR_DATA_SUCCESS:
            return {
                ...state,
                isSPRModifyData: action.payload
            };
        default:
            return state;
    }
};
export default requestSpecialPriceReducer;