/* eslint-disable */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Prompt, useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import { getNewBrand } from '../../common/components/util';
import { getNewlyAddedBrand } from '../../common/components/util';
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import { getSellingPrice } from '../../helpers/commonmethod';
import MakePaymentDialog from './MakePaymentDialog';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import DeleteDialog from '../../common/components/deleteDialog';
import { DASHBOARD_REDIRECT_EXCLUSION } from '../../constants/dashboardRedirectExclusion';
import { useEffect } from 'react';
import { useCallback } from 'react';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
const useStyles = makeStyles(() => ({
  header: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.625rem',
    fontFamily: 'Nunito',
    minWidth: 100
  },
  brand: {
    color: '#444444',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  body: {
    color: '#666666',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  bodyColor: {
    color: 'green',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  bodyColor1: {
    color: '#ff0000',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  bodyColor2: {
    color: 'red',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  price: {
    color: '#E88B00',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  paymentStatus: {
    color: '#E88B00',
    fontSize: 10,
    fontFamily: 'Nunito',
    fontWeight: 800,
  },
  credit: {
    backgroundColor: '#E88B00',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 9,
    padding: '2px 10px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
  },
  backOrder: {
    backgroundColor: '#1A9FE0',
    borderRadius: 20,
    color: '#FFFFFF',
    fontSize: 9,
    padding: '2px 10px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
  },
  orderCreated: {
    minWidth: "120px !important",
    border: "1px solid #4ec766",
    background: '#4ec766',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#4ec766',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  makePayment: {
    minWidth: "120px !important",
    border: "1px solid",
    background: '#1A9FE0',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#1A9FE0',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    },
    "&:disabled": {
      background: '#8ED1FC',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  retry: {
    minWidth: "120px !important",
    background: '#1A9FE0',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#1A9FE0',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    },
    "&:disabled": {
      background: '#8ED1FC',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  makePaymentCreditUtilized: {
    minWidth: "120px !important",
    border: "1px solid",
    background: '#696969',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: '#696969',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  orderFailed: {
    minWidth: "120px !important",
    border: "1px solid red",
    background: 'red',
    borderRadius: 100,
    color: 'white !important',
    fontSize: "9px !important",
    padding: '2px 20px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    "&:hover, &:focus": {
      background: 'red',
      borderRadius: 100,
      color: '#FFFFFF',
      fontSize: 9,
      padding: '2px 20px',
      textTransform: 'capitalize',
      fontFamily: 'Nunito',
    }
  },
  rowCustomSummary: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));
const columns = [
  {
    id: 'brand',
    label: 'Brand',
    align: 'left',
  },
  {
    id: 'orderno',
    label: 'Order No.',
    align: 'left',
  },
  {
    id: 'referenceno',
    label: 'Reference No.',
    align: 'left'
  },
  {
    id: 'lines',
    label: 'Lines',
    align: 'left'
  },
  {
    id: 'price',
    label: 'Price',
    align: 'left'
  },
  {
    id: 'orderStatus',
    label: 'Order Status',
    align: 'left'
  },
];

const OrderSummary = (props: any) => {

  const classes = useStyles();
  const history = useHistory();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [orderData, setOrderData] = React.useState<any>(null);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [address, setAddress] = React.useState('');
  const [goToOrder, setGoOrder] = React.useState(false);
  const [offlineData, setOfflineData] = React.useState<any>([])
  const getChoosenCustomerName: any = sessionStorage.getItem("CompanyName");
  const getChoosenCustomerSapCustomerId = sessionStorage.getItem('selectedSapCustomerId');
  const getSelectedOrderType = sessionStorage.getItem('orderType');
  const getSelectedSO = sessionStorage.getItem('selectOffice');
  const getSelectedOfficeTitle = sessionStorage.getItem('selectedOfficeTitle');
  const getOrderCreatedBy: any = sessionStorage.getItem('orderCreatedBy');

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const { isSellingPriceData, profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { offlinePaymentUpdate }: any = useSelector((state: any) => state.orderSummary)
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  let customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  if (sessionStorage.getItem('userType') === 'FSE') {
    customerTierType = props.orderData && props.orderData.length > 0 && props.orderData[0].customerTierType;
  }
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');


  const companyCurrencyType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType
  let collectionPoints: any = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.collectionPointsOfOrder && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints;
  const { getCustomerDetailsBySapId }: any = useSelector((state: any) => state.dealer);
  const companyCurrencyTypeByFSE: any = sessionStorage.getItem('companyCurrencyType');

  let addresses = get(profileDetails, "data.data.customerInfo.addresses", "");
  if (sessionStorage.getItem('userType') === 'FSE') {
    addresses = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.addresses
    collectionPoints = getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.collectionPointsOfOrder && getCustomerDetailsBySapId.data.collectionPointsOfOrder.collectionPoints;
  }


  // let address = get(
  //   profileDetails,
  //   "data.data.customerInfo.addresses",
  //   ""
  // );

  function sortOfflineData(offlineData: any, orderData: any) {
    if (offlineData && offlineData.length > 0) {
      // Create a map to store the order of orderNumbers from orderData
      const orderMap = new Map();
      // Populate the map with orderNumbers and their indices
      orderData.forEach((item: any, index: any) => {
        orderMap.set(item.orderNumber, index);
      });

      // Sort offlineData based on the indices from the orderMap
      return offlineData.sort((a: any, b: any) => {
        return orderMap.get(a.orderNumber) - orderMap.get(b.orderNumber);
      });
    }
  }


  useEffect(() => {
    if(offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data && offlinePaymentUpdate.data.data[0]) {
      let sortData: any = sortOfflineData(offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"], props.orderData);
      setOfflineData(sortData);
    }
    let paymentStatus: any = []
    let gotoOrder = props.orderData && Array.isArray(props.orderData) && props.orderData.map((ele: any, i: any) => {
      let obj = offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"][i]
      if (obj && obj.orderNumber === ele.orderNumber && obj.paymentStatus === "Payment Due") {
        paymentStatus.push("Payment due")
      }
    })
    if (paymentStatus.length !== 0) {
      setGoOrder(true)
    } else if (paymentStatus.length === 0) {
      setGoOrder(false)
    }
  }, [offlinePaymentUpdate, props.orderData])

  useEffect(() => {
    let order = props.orderData && Array.isArray(props.orderData) && props.orderData.map((ele: any) => {
      if (ele.paymentStatus === "Payment Due") {
        setGoOrder(true)
      }
    })
    let newAddress: any = '';
    if (props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0) {
      let defaultAddress = addresses && addresses.length > 0 && addresses.find((ele: any) => ele.shiftToPartyCustomerNumber === props.orderData[0].shiftToPartyCustomerNumber);
      if (typeof defaultAddress != 'string') {
        if (typeof defaultAddress == 'object') {
          let data = addresses.find((item: any) => item.firstAddress)
          if (defaultAddress) {
            newAddress = `${defaultAddress.streetName}, ${defaultAddress.region}, ${defaultAddress.city}, ${defaultAddress.country} ,${defaultAddress.postalCode}, ${defaultAddress.shiftToPartyCustomerNumber}`
          } else {
            newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${data.shiftToPartyCustomerNumber}`
          }
        } else {
          if (sessionStorage.getItem('userType') === 'FSE') {
            let data = addresses.find((item: any) => item.firstAddress)
            newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${props.orderData[0].shiftToPartyCustomerNumber}`
          } else {
            let data = addresses.find((item: any) => item.firstAddress)
            newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${data.shiftToPartyCustomerNumber}`
          }
        }
      }
      setAddress(newAddress)
    }
  }, [props.orderData && Array.isArray(props.orderData) && props.orderData])

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };
  const showCreateChangeEnquiry = !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.CREATE_CHANGE_ENQUIRY);

  const redirectToEnquiry = async (enquiry: any) => {
    await sessionStorage.setItem("current-enquiry-no", enquiry.B2bEnquiryId)

    await sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
      mode: 'update',
      masterId: enquiry.B2bEnquiryId
    }))
    history.push('/updateenquiry', { reload: true });

  }

  const handleClickOpen = (item: any) => {
    setOrderData(item);
    setOpenDialog(true);
  };

  // ON CLICK OF BACK BUTTON FUNCTIONALITY 
  // const handleBackClick = () => {
  //   if (dataUpdated) {
  //     setDeleteMessage('Do you wish to proceed without saving the data?')
  //     handleopenClose(true);
  //   } else {
  //     // props.setTabValue(0)
  //   }
  // }

  const getPaymentUpdate = (data: any) => {
    let data2: any = []
    data2.push(data)
    return data
  }

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <div className="d-flex align-items-center mt-2 mb-2">
        <h2 className="page-title px-2">
          Order Summary
        </h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <>
        <DialogContent className="p-0">
          <div className="d-flex justifyContentHorizontalSpacebetween align-items-center col-12 my-2">
            <div className="mr-1">
              {/* <CheckCircleIcon className="order-placed-tick" fontSize="large" />
              <span className="order-placed ml-3">Order Placed. Please proceed for payment. </span> */}
            </div>
          </div>
          <div className="row order-placed-message ml-1 mt-3 px-2">Order details:</div>
          <div className="m-2 row">
            {sessionStorage.getItem('userType') === 'FSE' && (
              <div className="row px-1 ">
                <div className="tandcText m-2 row">
                  {/* <div className="tandcHeading row px-2">Selected Customer - <span className="tandcHeading-font ml-1">{getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.companyName ? getCustomerDetailsBySapId.data.companyName : "-"}</span> {" "} */}
                  <div className="tandcHeading row px-3">Customer : <span className="tandcHeading-font-type ml-1">{getChoosenCustomerName ? getChoosenCustomerName : "-"}</span> {" "}
                    <span className="tandcHeading-font-type ml-1">
                      {getChoosenCustomerSapCustomerId ?
                        // `(${getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.sapCustomerId ? getCustomerDetailsBySapId.data.sapCustomerId : "-"})`}
                        `(${getChoosenCustomerSapCustomerId ? getChoosenCustomerSapCustomerId : "-"})` : ""}
                    </span></div>
                </div>
                {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType && getCustomerDetailsBySapId.data.customerTierType.toLowerCase() === "workshop" || sessionStorage.getItem('customerTypeofCustomer') === "workshop" ?
                  <>
                    {getSelectedSO && getSelectedOrderType ?
                      <>
                        <div className="tandcHeading row px-4 mt-2">Order Type : <span className="tandcHeading-font-type ml-1">{getSelectedOrderType === "CRD" ? "Credit" : getSelectedOrderType === "CASH" ? "Cash" : "-"}</span> {" "}
                        </div>
                        <div className="tandcHeading row px-4 mt-2">Sales Office : <span className="tandcHeading-font-type ml-1">{" "}{getSelectedOfficeTitle ? getSelectedOfficeTitle : "-"}</span> {" "}
                        </div>
                      </> : ""}
                  </>
                  : ""}
                {getCustomerDetailsBySapId && getCustomerDetailsBySapId.data && getCustomerDetailsBySapId.data.customerTierType === "Int. Business" || sessionStorage.getItem('customerTypeofCustomer') === 'int. business' ?
                  <>
                    {getOrderCreatedBy ?
                      <>
                        <div className="tandcHeading row px-4 mt-2">Order Created By : <span className="tandcHeading-font-type ml-1">{getOrderCreatedBy === "MA" ? "Multi Auto" : getOrderCreatedBy === "AA" ? "Arch Auto" : "-"}</span> {" "}
                        </div>
                      </> : ""}
                  </>
                  : ""}
              </div>
            )}
            <h6 className="ml-auto font-15 font-weight-600 mt-1">Date -  {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].createdOn ? moment(new Date(props.orderData[0].createdOn)).utc().format('DD MMM YYYY') : '-'}</h6>
          </div>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 p-0">
            <div className="col-12 border-top w-100 p-0">
              <div className={`enquiry-number ${classes.rowCustomSummary} justifyContentHorizontalSpacebetween align-items-center`}>
                <div>Enquiry Number : {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].B2bEnquiryId ? props.orderData[0].B2bEnquiryId : '-'}</div>
                <div>Reference Number : {props.orderData && Array.isArray(props.orderData) && props.orderData.length > 0 && props.orderData[0] && props.orderData[0].masterReferenceNumber ? props.orderData[0].masterReferenceNumber : '-'}</div>
              </div>
            </div>
            {/* <div className="col-12 border-bottom"></div> */}
            <TableContainer className="height-400 mb-5 pb-5">
              <Table>
                <TableHead className="w-100">
                  <TableRow>
                    {columns.map((column: any) => (
                      <TableCell
                        key={column.id}
                        className={classes.header}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                    <TableCell className={classes.header}>Payment Status</TableCell>
                    <TableCell className={classes.header}>Transaction ID </TableCell>
                    <TableCell className={classes.header}>Request ID </TableCell>
                    {customerTierType && customerTierType.toLowerCase() === "fleet" || customerTierType === "Fleet" ||
                      customerTierType && customerTierType.toLowerCase() === "government" || customerTierType === "Government" ||
                      (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'dealer') ||
                      (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'int. business')
                      || (sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('customerTypeofCustomer') === 'int. business')
                      ?
                      "" : <TableCell className={classes.header}>Action</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.orderData && Array.isArray(props.orderData) && props.orderData.map((item: any, i: any) => {
                    let priceFinal: any = item && item.price && typeof item.price == 'string' ? parseFloat(item.price) : item.price;
                    priceFinal = priceFinal.toFixed(2)
                    if (item) {
                      return <TableRow style={{ backgroundColor: !item.orderNumber ? "#b9b9b9" : "transparent" }}>
                        <TableCell className={classes.brand}>{customerTierType && customerTierType.toLowerCase() === "workshop" ? getNewBrand(item.make) : getNewlyAddedBrand(item.make)}
                          {!item.orderNumber && item.status && !(item.status.toLowerCase().includes('failed')) && (
                            <small className="text-red position-absolute font-weight-normal d-block mt-1">{item.sapOrderMessage} </small>
                          )}
                          {item.sapOrderMessage && item.sapOrderMessage.includes("not allow external procurement") && (
                            <small className="text-red position-absolute font-weight-normal d-block mt-1">Some parts are not allowed for external procurement</small>
                          )}
                        </TableCell>
                        <TableCell className={classes.body}>{item.orderNumber}</TableCell>
                        <TableCell className={classes.body}>{item.enquiryReferenceNumber}</TableCell>
                        <TableCell className={classes.body}>{item.lines}</TableCell>
                        <TableCell className={!item.orderNumber ? 'text-red' : `${classes.body}`}>
                          {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {parseFloat(getSellingPrice(priceFinal, isSellingPriceData, markupPercentage)).toFixed(2)}</TableCell>
                        <TableCell className={item.status && typeof item.status == 'string' && (item.status === "Back Order Created" || item.status === "Back order created" || item.status === "Order Created") ? classes.bodyColor : classes.bodyColor2}>{item.status && typeof item.status == 'string' && item.status}
                        </TableCell>
                        {item.paymentStatus === "Credit Utilized" && (item.status && typeof item.status == 'string') ?
                          <TableCell className='row'>
                            <span className='row'>
                              <p className={`row ${!item.orderNumber ? 'font - colorchanges' : 'font-colorgreenchanges'} pl-4 mt-1 pr-3`}>
                                {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                              </p>
                              {isSellingPriceData &&
                                <p className='row'>
                                  {offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                    offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" ? <> </> : <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                    <p className='pl-3'><ErrorIcon className='icon-color' /> </p>
                                  </Tooltip>
                                  }
                                </p>}
                            </span>
                          </TableCell>
                          :
                          <TableCell className={classes.bodyColor1}>
                            {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                          </TableCell>
                        }
                        {console.log("---------------------", customerTierType)}
                        <TableCell className={classes.body}>{offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus && offlineData[i].paymentStatus.includes('Offline Payment Pending') || (item.paymentStatus && item.paymentStatus.includes('Offline Payment Pending')) ? '-' : item.paymentStatus === "Declined" || item.paymentStatus === "Payment Received" ? item.TransactionID : '-'}</TableCell>
                        <TableCell className={classes.body}>{offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus && offlineData[i].paymentStatus.includes('Offline Payment Pending') || (item.paymentStatus && item.paymentStatus.includes('Offline Payment Pending')) ? '-' : item.paymentStatus === "Declined" || item.paymentStatus === "Payment Received" ? item.requestId : '-'}</TableCell>
                        {customerTierType && customerTierType.toLowerCase() === "fleet" || customerTierType === "Fleet" ||
                          customerTierType && customerTierType.toLowerCase() === "government" || customerTierType === "Government" ||
                          (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'dealer') ||
                          (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'int. business') || (sessionStorage.getItem('userType') === 'FSE' && sessionStorage.getItem('customerTypeofCustomer') === 'int. business')
                          ? "" :
                          <>
                            {
                              !item.orderNumber ?
                                <TableCell>
                                  <Button
                                    className={classes.retry}
                                    size="small"
                                    variant="contained"
                                    onClick={() => { redirectToEnquiry(item); }}
                                  > Retry
                                  </Button>
                                </TableCell> : item.paymentStatus === "Credit Utilized" && (item.status && typeof item.status == 'string') ?
                                  <TableCell>
                                    {isSellingPriceData && <>{offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                      offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                      offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" ? <> </> : <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                      <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                    </Tooltip>}
                                      { }  <Button
                                        className={classes.makePayment}
                                        disabled={priceFinal === "0.00" || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || !isSellingPriceData || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" ||
                                          item && item.paymentStatus === "Offline Payment Pending" ||
                                          offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                          offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}
                                        size="small"
                                        variant="contained"
                                        onClick={() => { handleClickOpen(item); }}
                                      > Make Payment
                                      </Button> </>}
                                  </TableCell>
                                  : <TableCell>
                                    <Button
                                      className={classes.makePayment}
                                      size="small"
                                      disabled={priceFinal === "0.00" ||
                                        !isSellingPriceData ||
                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" ||
                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData && offlineData[i] && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Pending Approval" || item && item.paymentStatus === "Pending Approval" ||
                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment in Progress" || item && item.paymentStatus === "Payment in Progress" ||
                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}
                                      variant="contained"
                                      onClick={() => { handleClickOpen(item); }}
                                    > Make Payment
                                    </Button>
                                  </TableCell>
                            }
                          </>
                        }
                      </TableRow>
                    }
                    return null
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </DialogContent>
        <div className="p-0">
          <div className="row no-gutters table-bottom p-0">
            <div className="col-lg-3 col-md-3 col-sm-6 p-3 border-right-bottom border">
              <p className="bottom-field-name-table">
                Total Price
              </p>
              <p className="bottom-field-value-table">
                {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {props.orderTotal && parseFloat(props.orderTotal) ? parseFloat(getSellingPrice(parseFloat(props.orderTotal), isSellingPriceData, markupPercentage)).toFixed(2) : '-'}
                <span className="vat ml-1">(Incl. of VAT)</span>
              </p>
            </div>
            {customerTierType && customerTierType.toLowerCase() === "workshop" ?
              <>
                {props.orderData && Array.isArray(props.orderData) && props.orderData.map((item: any, i: any) => {
                  return <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom border">
                    <p className="bottom-field-name-table">
                      {item.deliveryType === "Onsite Delivery" ? "Shipping Address" : item.deliveryType === "Self Pickup" ? "Collection Point" : ""}
                    </p>
                    {item.deliveryType === "Onsite Delivery" ?
                      <p className="bottom-field-value-table">
                        {address}
                      </p>
                      :
                      item.deliveryType === "Self Pickup" ?
                        <p className="bottom-field-value-table">
                          {collectionPoints && collectionPoints.length > 0 && collectionPoints.map((ele: any) => {
                            return <p> {ele.defineTPCounter ? ele.defineTPCounter : ""}, {" "} {ele.area ? ele.area : ""}, {" "} {ele.landlinePhone ? "Landline No :" : ""} {" "} {ele.landlinePhone ? ele.landlinePhone : ""}</p>
                          })}
                        </p>
                        :
                        ""
                    }
                  </div>
                })}
              </>
              : ''}
            {customerTierType && customerTierType.toLowerCase() !== "workshop" ?
              <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom border">
                <p className="bottom-field-name-table">
                  Office Address
                </p>
                <p className="bottom-field-value-table">
                  {address}
                </p>
              </div>
              : ''
            }
            <div className="col-lg-3 col-md-3 col-sm-6 p-2 border-bottom border d-flex justify-content-center">
              <Button variant="contained" color="primary" className="mt-2 actionButtomButtomsBlue" onClick={() => {
                if ((customerTierType && customerTierType.toLowerCase() === "fleet") || customerTierType === "Fleet" || (customerTierType && customerTierType.toLowerCase()) === "government" || customerTierType === "Government") {
                  history.push("/orderhistory");
                  setDataUpdated(false); handleopenClose(false);
                } else if ((sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'dealer') ||
                  (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'int. business')) {
                  setDataUpdated(false); handleopenClose(false);
                  history.push("/orderhistory");
                } else if (goToOrder) {
                  setDataUpdated(true); handleopenClose(true);
                } else {
                  history.push("/orderhistory")
                }
              }}
              // disabled={sessionStorage.getItem('userType') === 'FSE'}
              >
                Go to Order History
              </Button>
            </div>
          </div>
        </div>
        <DeleteDialog
          open={confirmDialog && dataUpdated}
          text={<div>
            <h6 className='font-text mt-0'>Would you like to make payment later?</h6>
            <p className='mt-1'>If not paid within 24 hours, your order will get automatically cancelled. Delivery will not be scheduled until payment is made.</p>
          </div>}
          handleopenClose={(data: any) => {
            if (data === 'delete') { history.push('/orderhistory'); setDataUpdated(false); handleopenClose(false); }
            if (!data) { handleopenClose(false); }
          }} />
        <Dialog className="pb-0"
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth="sm"
        >
          {/* <MakePaymentDialog setOpenDialog={handleCloseDialog} orderData={orderData}/> */}
          <MakePaymentDialog orderData={orderData} setSuccessMessage={setSuccessMessage} getPaymentUpdate={getPaymentUpdate}
            setOpenErrorSnackbar={setOpenErrorSnackbar}
            openErrorSnackbar={openErrorSnackbar}
            handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog} />
        </Dialog >
        {((goToOrder && customerTierType && customerTierType.toLowerCase() === "workshop") || (goToOrder && customerTierType && customerTierType === "Workshop") ||
          (sessionStorage.getItem('userType') !== 'FSE' && goToOrder && customerTierType && customerTierType.toLowerCase() === "dealer") || (sessionStorage.getItem('userType') !== 'FSE' && goToOrder && customerTierType === "Dealer"))
          && <Prompt when={window.location.pathname == '/ordersummarypayment'} message={"Would you like to make payment later?"}></Prompt>}
      </>
    </div>
  )
}
export default OrderSummary