/* eslint-disable  */
import React, { Fragment } from "react";
import "./DeliveryList.css";
import IconButton from '@material-ui/core/IconButton';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/Visibility';
import NotificationIcon from "../../common/components/NotificationIcon";
import { get } from "lodash";
import { Tooltip } from "@material-ui/core";
import { getDeliveryDetails } from "../../store/actions/delivery.actions";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import TextField from '@material-ui/core/TextField';
import DatePicker from '../../common/components/dateRangePicker';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import Collapse from "@material-ui/core/Collapse";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment-timezone";
import { Redirect } from "react-router-dom";
import XLSX from "xlsx";
import { useCallback } from "react";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    input: {
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    bodyContent: {
        color: '#666666',
        fontWeight: 600,
        fontSize: '0.75rem',
        fontFamily: 'Nunito'
    },
    tableHead: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        minWidth: 100
    },
    actionButton: {
        color: '#1A9FE0',
    },
}));

const columns = [
    /* {
        id: 'orderEnquiryNo',
        label: 'Order Enquiry No.',
        align: 'left',
    }, */
    {
        id: 'delivery',
        label: 'Delivery No.',
        align: 'left',
    },
    {
        id: 'salesDocument',
        label: 'Order No.',
        align: 'left'
    },
    {
        id: 'description',
        label: 'Description',
        align: 'left'
    },
    {
        id: 'billingStatus',
        label: 'Billing Status',
        align: 'left'
    },
    {
        id: 'goodsMovementStatus',
        label: 'Goods Movement Status',
        align: 'left'
    },
    {
        id: 'shippingPlanningStatus',
        label: 'Shipping Planning Status',
        align: 'left'
    },
    {
        id: 'shippingReceivingPoint',
        label: 'Shipping Receiving Point',
        align: 'left'
    },
    {
        id: 'pickingStatus',
        label: 'Picking Status',
        align: 'left'
    },
];

const ViewDelivery: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [searchText, setsearchText] = React.useState("");
    const [startDate, setstartDate] = React.useState("");
    const [endDate, setendDate] = React.useState("");
    const [status, setstatus] = React.useState("");
    const [open, setOpen] = React.useState(false);
    const rowsPerPage = 10;

    const { viewDelivery, deliveryList }: any = useSelector((state: any) => state.delivery);
    const deliveryData = get(deliveryList, 'data.data.deliveryList', {});

    const loadViewDelivery = useCallback(() => {

    }, [searchText, startDate, status, endDate])


    const handlePageChange = () => {

    }

    const handleredirect = (i: any) => {
        dispatch(getDeliveryDetails(sessionStorage.getItem('selectedOrderEnquiryNumber'), deliveryData.delivery, sessionStorage.getItem('selectedOrderMake'), deliveryData && deliveryData.sapCustomerId));
        //  history.push(`/ViewDeliverDetails`)
        //     history.push({
        //     pathname:"/ViewDeliverDetails",
        //     state:{details:i}               
        // })
        <Redirect to="/ViewDeliverDetails" />
        history.push({
            pathname: "/ViewDeliverDetails",
        }
        )
    }

    const exportData = () => {
        if (viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.length >= 1) {
            let filename = "deliveryItems.xlsx";
            let data: any = [];

            viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.map((ele: any) => {
                data.push([{
                    "Enquiry No": sessionStorage.getItem("selectedOrderEnquiryNumber"),
                    "Reference No": ele.enquiryReferenceNumber,
                    "Delivery No": "--",
                    "Order No": ele.orderNumber,
                    "Invoice No": "--",
                    "User Name": ele.userName,
                    "Created On": moment(new Date(viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.createdOn)).utc().format('DD MMM YYYY'),
                    "Status": ele.status
                }])
            })


            var ws = XLSX.utils.json_to_sheet(data);
            var wb = XLSX.utils.book_new();
            viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.forEach((ele: any, i: any) => {
                return XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(data[i]), ele.displayName)
            })
                ;
            XLSX.writeFile(wb, filename);
        }
    };






    return (
        <Fragment>
            {/* {deliveryList.loading && <FullscreenLoader />} */}
            <div className="d-flex align-items-center">
                <h2 className="page-title">
                    Delivery List
                    <Breadcrumbs>
                        <Typography color="textSecondary" className="breadcrumb-text-order-history">Order History</Typography>
                        <Typography color="textPrimary" className="breadcrumb-text-delivery-details">Delivery List</Typography>
                    </Breadcrumbs>
                </h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-12">
                    <div className="cardCommon p-3">
                        <div className="d-flex align-items-center">
                            <h5 className="page-sub-title mb-2">Search Delivery</h5>
                        </div>
                        <TextField
                            value={searchText}
                            onChange={(e: any) => {
                                setsearchText(e.target.value)
                            }}
                            size="small" className="col-4 col-sm-6 col-md-4 col-lg-4 common-input-font-size commonRoundedInputs searchField" label="Search by Order Enquiry No/Order No/Reference No" variant="outlined" />
                        <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50">
                            <DatePicker
                                startDate={startDate}
                                endDate={endDate}
                                setstartDate={setstartDate}
                                setendDate={setendDate}
                                onChange={(start: any, end: any) => {
                                    setstartDate(start);
                                    setendDate(end);
                                }}
                            />
                        </div>
                        <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0  ">
                            <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                                <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                                <Select
                                    labelId="demo-simple-select-outlined-label"
                                    id="demo-simple-select-outlined"
                                    value={status}
                                    onChange={(e: any) => {
                                        setstatus(e.target.value)
                                    }}
                                    label="Age"
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"Order Created"}>Order Created</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <Button
                            className="col-2 col-sm-6 col-md-2 col-lg-2 font-size-11 pl-0 addOrderEnqButton"
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                loadViewDelivery()
                            }}>
                            Search
                        </Button>
                        {/* <div className="ml-2 mt-2">
                            <Checkbox className="checkbox" />
                            <span className="myOrders">My Deliveries Only</span>
                        </div> */}
                    </div>
                </div>
            </div>

            <div className="row mt-4">
                <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="d-flex align-items-center">
                        <h5 className="page-sub-title">List of Deliveries</h5>
                        <IconButton className={`ml-auto ${classes.actionButton}`} onClick={() => { history.push(`/orderhistory`) }}>
                            <ChevronLeftIcon fontSize="small" />
                            <span className="backLinkLabel">Back to List</span>
                        </IconButton>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="cardCommon orderItems">
                        <TableContainer className={`classes.container`}>
                            <Table aria-label="table">
                                <TableHead className="w-100">
                                    <TableRow>
                                        <TableCell className={classes.tableHead}>Order Enquiry No.</TableCell>
                                        <TableCell className={classes.tableHead}>Reference No.</TableCell>
                                        <TableCell className={classes.tableHead}>Delivery No.</TableCell>
                                        <TableCell className={classes.tableHead}>Order No.</TableCell>
                                        <TableCell className={classes.tableHead}>Invoice No.</TableCell>
                                        <TableCell className={classes.tableHead}>Username</TableCell>
                                        <TableCell className={classes.tableHead}>Created On</TableCell>
                                        <TableCell className={classes.tableHead}>Status</TableCell>
                                        <TableCell className={classes.tableHead}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {/* <TableRow>
                                        {
                                            columns.map((column: any) => {
                                                const value: any = deliveryData[column.id];
                                                return (
                                                    <TableCell key={column.id} className={classes.bodyContent}>
                                                        {value}
                                                    </TableCell>
                                                );
                                            })
                                        }
                                        <TableCell>
                                        <Tooltip title="View Delivery Details" placement="top">
                                            <IconButton size="small" className={classes.actionButton} onClick={() => { 
                                                dispatch(getDeliveryDetails(sessionStorage.getItem('selectedOrderEnquiryNumber'), deliveryData.delivery, sessionStorage.getItem('selectedOrderMake')));
                                                history.push(`/deliverydetails`)}}
                                            >
                                                <VisibilityIcon fontSize="inherit"/>
                                            </IconButton>   
                                        </Tooltip>
                                        </TableCell>
                                    </TableRow> */}
                                    {/* <tr
                                        className="tableRowPanelsCreateEnquiry"
                                    >
                                        <td className="tdPanelsCreateEnquiry d-flex flex-row tdPanelsCreateEnquiry blueLabelHeaderTable">

                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => setOpen(!open)}
                                            >
                                                {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                                            </IconButton>
                                            <div className="pt-2 pl-2">  {sessionStorage.getItem("selectedOrderEnquiryNumber")} </div>

                                        </td>
                                        <td style={{ alignItems: "end" }} className="ml-auto align-items-end tdPanelsCreateEnquiry blueLabelHeaderTable align-items-end">
                                        </td>
                                        <td className="tdPanelsCreateEnquiry">

                                        </td>
                                        <td className="tdPanelsCreateEnquiry">

                                        </td >
                                        <td className="tdPanelsCreateEnquiry">

                                        </td>
                                        <td className="tdPanelsCreateEnquiry">

                                        </td>
                                        <td className="tdPanelsCreateEnquiry">

                                        </td>  <td className="tdPanelsCreateEnquiry">

                                        </td>  <td className="tdchildCreateEnquiry">
                                            <IconButton className="exportExcel" size="small" >
                                                <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={exportData}/>
                                            </IconButton>
                                        </td>
                                    </tr>
                                    <tr className={"tableRowPanelsCreateEnquiry"}>
                                        <td className="p-0" >
                                            <Collapse in={open} timeout="auto" unmountOnExit></Collapse>
                                        </td>
                                    </tr> */}
                                    {!open &&
                                        <>
                                            {viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.map((ele: any, i: any) => {
                                                return (
                                                    <>
                                                        <tr className={""}>
                                                            <td className="tdchildCreateEnquiry">
                                                                <ins style={{ color: "#1A9FE0" }}>{sessionStorage.getItem("selectedOrderEnquiryNumber")?.split("").slice(0, 5).join("")}</ins>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry">
                                                                <p>{ele.enquiryReferenceNumber}</p>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry">
                                                                <p>--</p>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry">
                                                                <p>{viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.orderId}</p>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry">
                                                                <p>--</p>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry">
                                                                <p>{ele.userName}</p>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry">
                                                                <p>{moment(new Date(viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.createdOn)).utc().format('DD MMM YYYY')}</p>
                                                            </td>
                                                            <td className="tdchildCreateEnquiry ">{ele.status}</td>
                                                            <td className="tdchildCreateEnquiry font-10 font-weight-700 text-444">  <Tooltip title="View Delivery Details" placement="top">
                                                                <IconButton size="small" className={classes.actionButton}
                                                                    onClick={() => {
                                                                        handleredirect(i)
                                                                        sessionStorage.setItem("selectedDelivery", i)
                                                                    }}
                                                                >
                                                                    <VisibilityIcon fontSize="inherit" />
                                                                </IconButton>

                                                            </Tooltip> <IconButton className="exportExcel" size="small" >
                                                                    <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={exportData} />
                                                                </IconButton></td>
                                                        </tr>

                                                    </>
                                                )

                                            })}

                                        </>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <div className="col-12 d-flex justify-content-end">
                            <TablePagination
                                rowsPerPageOptions={[10]}
                                component="div"
                                count={viewDelivery.data && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder && viewDelivery.data.parentOrder.subEnquiries.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ViewDelivery;