import { ACHIEVEMENT } from "../actions/actionsTypes";
import { IGlobal } from "../models/global.interface";
import { IActionBase } from "../models/root.interface";

const initialState = {
  purchaseHistory: null,
  isAccessAchievement: null,
  purchaseHistoryByYear: null
}

function achievementReducer(state: any = initialState, action: IActionBase): IGlobal {
  switch (action.type) {
    // case ACHIEVEMENT.GET_PURCHASE_FETCHING:
    //     return {
    //         ...state,
    //         purchaseHistory:{loading:true,data:null,err:null}
    //     };
    // case ACHIEVEMENT.GET_PURCHASE_SUCCESS:
    //    return  {
    //        ... state,
    //        purchaseHistory:{loading:true,data:action.payload.data.data,err:null}
    //    };
    // case ACHIEVEMENT.GET_PURCHASE_ERROR:
    //     return {
    //         ... state,
    //         purchaseHistory:{loading:true,data:null,err:action.payload}
    //     };        
    case ACHIEVEMENT.GET_PURCHASE_BY_YEAR_FETCHING:
      return {
        ...state,
        purchaseHistoryByYear: { loading: true, data: null, err: null }
      };
    case ACHIEVEMENT.GET_PURCHASE_BY_YEAR_SUCCESS:
      return {
        ...state,
        purchaseHistoryByYear: { loading: true, data: action.payload.data.data, err: null }
      }
    case ACHIEVEMENT.GET_PURCHASE_BY_YEAR_ERROR:
      return {
        ...state,
        purchaseHistoryByYear: { loading: true, data: null, err: action.payload }
      };
    case ACHIEVEMENT.IS_ACCESS_ACHIEVEMENT_FETCHING:
      return {
        ...state,
        isAccessAchievement: { loading: true, data: null, err: null }
      };
    case ACHIEVEMENT.IS_ACCESS_ACHIEVEMENT_SUCCESS:
      return {
        ...state,
        isAccessAchievement: { loading: true, data: action.payload.data.data, err: null }
      };
    case ACHIEVEMENT.IS_ACCESS_ACHIEVEMENT_ERROR:
      return {
        ...state,
        isAccessAchievement: { loading: true, data: null, err: action.payload }
      };
    default:
      return state

  }
}
export default achievementReducer
