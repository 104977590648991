/* eslint-disable */
import React, { useEffect, Dispatch } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from "react-router-dom";
import moment from "moment-timezone";
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { getNewBrand, getNewlyAddedBrand } from '../../common/components/util';
import { getSellingPrice } from '../../helpers/commonmethod';
import MakePaymentDialog from '../CreateEnquiry/MakePaymentDialog';
import { Tooltip } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import HistoryMakePaymentDialog from "../CreateEnquiry/OrderHistoryMakePaymentDialog";
import { PinDropSharp } from '@material-ui/icons';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import DeleteDialog from '../../common/components/deleteDialog';
import { useLocation } from 'react-router';
import { Prompt } from "react-router-dom";
import RefreshIcon from '@material-ui/icons/Refresh';
import { getOrderSummary } from '../../store/actions/orderSummary.actions';
// import { getOrderSummaryDetails } from '../../store/actions/orderSummary.actions';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles(() => ({
    header: {
        color: '#133F8A',
        fontWeight: 900,
        fontSize: '0.625rem',
        fontFamily: 'Nunito',
        minWidth: 100
    },
    brand: {
        color: '#444444',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    body: {
        color: '#666666',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    price: {
        color: '#E88B00',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    paymentStatus: {
        color: '#E88B00',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    credit: {
        backgroundColor: '#E88B00',
        borderRadius: 20,
        color: '#FFFFFF',
        fontSize: 9,
        padding: '2px 10px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
    },
    backOrder: {
        backgroundColor: '#1A9FE0',
        borderRadius: 20,
        color: '#FFFFFF',
        fontSize: 9,
        padding: '2px 10px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
    },
    orderCreated: {
        minWidth: "120px !important",
        border: "1px solid #4ec766",
        background: '#4ec766',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: '#4ec766',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    makePayment: {
        minWidth: "120px !important",
        border: "1px solid",
        background: '#1A9FE0',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: '#1A9FE0',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        },
        "&:disabled": {
            background: '#8ED1FC',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    bodyColor: {
        color: '#0089ff',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor1: {
        color: '#ff0000',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor2: {
        color: '#008000',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor3: {
        color: '#00ff00',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    bodyColor4: {
        color: '#800080',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 800,
    },
    makePaymentCreditUtilized: {
        minWidth: "120px !important",
        border: "1px solid",
        background: '#696969',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: '#696969',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    orderFailed: {
        minWidth: "120px !important",
        border: "1px solid red",
        background: 'red',
        borderRadius: 100,
        color: 'white !important',
        fontSize: "9px !important",
        padding: '2px 20px',
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        "&:hover, &:focus": {
            background: 'red',
            borderRadius: 100,
            color: '#FFFFFF',
            fontSize: 9,
            padding: '2px 20px',
            textTransform: 'capitalize',
            fontFamily: 'Nunito',
        }
    },
    rowCustomSummary: {
        display: 'flex',
        flexWrap: 'wrap'
    }
}));
const columns = [
    {
        id: 'brand',
        label: 'Brand',
        align: 'left',
    },
    {
        id: 'orderno',
        label: 'Order No.',
        align: 'left',
    },
    {
        id: 'referenceno',
        label: 'Reference No.',
        align: 'left'
    },
    {
        id: 'lines',
        label: 'Lines',
        align: 'left'
    },
    {
        id: 'price',
        label: 'Price',
        align: 'left'
    },
    {
        id: 'orderStatus',
        label: 'Order Status',
        align: 'left'
    },
];

const OrderHistoryPayment = (props: any) => {
    // const { orderData } = props&&props.orderData;
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [openDialog, setOpenDialog] = React.useState(false);
    //  const [orderData, setOrderData] = React.useState<any>(null);
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [Order, setOrder] = React.useState<any>("");
    const [address, setAddress] = React.useState<any>("");
    const [fseNewAddress, setFseNewAddress] = React.useState<any>("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [dataUpdated, setDataUpdated] = React.useState(false);
    const [confirmDialog, setConfirmDialog] = React.useState(false);
    const { orderSummary, orderSUmmaryDetails }: any = useSelector((state: any) => state.orderSummary)
    const [placedIndex, setPlacedIndex] = React.useState<any>(typeof (sessionStorage.getItem("placed_order_index")) !== null && sessionStorage.getItem("placed_order_index"))
    const [loading, setLoading] = React.useState<any>(false);
    const [goToOrder, setGoOrder] = React.useState(false);
    const [offlineData, setOfflineData] = React.useState<any>([])
    const { offlinePaymentUpdate }: any = useSelector((state: any) => state.orderSummary)

    useEffect(() => {
        if (history.location.pathname === "/orderhistorypayment") {
            history.push("/orderhistory")
        }
        return () => {
        }
    }, [])

    function sortOfflineData(offlineData: any, orderData: any) {
        if (offlineData && offlineData.length > 0) {
            // Create a map to store the order of orderNumbers from orderData
            const orderMap = new Map();
            // Populate the map with orderNumbers and their indices
            orderData && orderData.forEach((item: any, index: any) => {
                orderMap.set(item.orderNumber, index);
            });

            // Sort offlineData based on the indices from the orderMap
            return offlineData.sort((a: any, b: any) => {
                return orderMap.get(a.orderNumber) - orderMap.get(b.orderNumber);
            });
        }
    }


    useEffect(() => {
        if (offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data && offlinePaymentUpdate.data.data[0]) {
            let sortData: any = sortOfflineData(offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"], orderSummary && orderSummary.data && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList);
            setOfflineData(sortData);
        }
        let paymentStatus: any = []
        let gotoOrder = orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((ele: any, i: any) => {
            let obj = offlinePaymentUpdate && offlinePaymentUpdate.data && offlinePaymentUpdate.data.data[0] && offlinePaymentUpdate.data.data[0]["data"][i]
            if (obj && obj.orderNumber === ele.orderNumber && obj.paymentStatus === "Payment Due") {
                paymentStatus.push("Payment due")
            }
        })
        if (paymentStatus.length !== 0) {
            setGoOrder(true)
        } else if (paymentStatus.length === 0) {
            setGoOrder(false)
        }
    }, [offlinePaymentUpdate])

    useEffect(() => {
        let data: any
        data = orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.filter((ele: any, i: any) => {
            return <>
                {setFseNewAddress(`${ele && ele.shiftToPartyAddressDetails.streetName}, ${ele && ele.shiftToPartyAddressDetails.region}, ${ele && ele.shiftToPartyAddressDetails.city}, ${ele && ele.shiftToPartyAddressDetails.country} ,${ele && ele.shiftToPartyAddressDetails.postalCode}, ${ele && ele.shiftToPartyAddressDetails.shiftToPartyCustomerNumber}`)}
            </>
        })
    }, [orderSummary])


    useEffect(() => {
        let order = orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((ele: any) => {
            if (ele.paymentStatus === "Payment Due") {
                setGoOrder(true)
            }
        })
    }, [orderSummary && orderSummary.data && orderSummary.data])

    const calculatePrice = (orderData: any) => {
        let cal: any = 0;
        let data = orderSummary
        orderSummary && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.forEach((ele: any) => {
            cal = parseFloat(cal) + parseFloat(ele.price)
        })
        return parseFloat(cal).toFixed(2)
    }


    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleopenClose = (open?: any) => {
        setConfirmDialog(open ? true : false);
        setSuccessMessage(null);
    };

    const { isSellingPriceData, profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
    const [selectedOrder, setselectedOrder] = React.useState<any>(null);
    let customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
    if (sessionStorage.getItem('userType') === 'FSE') {
        customerTierType = props && props.orderData && props.orderData.customerType;
    }
    const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
    const companyCurrencyType = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.companyCurrencyType
    const collectionPoints = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.collectionPointsOfOrder && profileDetails.data.data.customerInfo.collectionPointsOfOrder.collectionPoints;
    const companyCurrencyTypeByFSE: any = sessionStorage.getItem('companyCurrencyType');

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    const handleClickOpen = (props: any) => {
        //  setOrderData(item);
        setOpenDialog(true);
        props.handleClickOpen()
    };

    let addresses = get(
        profileDetails,
        "data.data.customerInfo.addresses",
        ""
    );

    React.useEffect(() => {
        let newAddress: any = '';
        if (props && props.orderData && typeof props.orderData === 'object') {
            let defaultAddress = addresses && addresses.length > 0 && addresses.find((ele: any) => ele.shiftToPartyCustomerNumber === props.orderData.shiftToPartyCustomerNumber);
            if (typeof defaultAddress != 'string') {
                if (typeof defaultAddress == 'object') {
                    let data = addresses.find((item: any) => item.firstAddress)
                    if (defaultAddress) {
                        newAddress = `${defaultAddress.streetName}, ${defaultAddress.region}, ${defaultAddress.city}, ${defaultAddress.country} ,${defaultAddress.postalCode}, ${defaultAddress.shiftToPartyCustomerNumber}`
                    } else {
                        newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${data.shiftToPartyCustomerNumber}`
                    }
                } else {
                    let data = addresses.find((item: any) => item.firstAddress)
                    newAddress = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}, ${data.shiftToPartyCustomerNumber}`
                }
            }
            setAddress(newAddress);
        }
    }, [props && props.orderData, addresses])

    // if (typeof address != 'string') {
    //     if (typeof address == 'object') {
    //         let data = address.find((item: any) => item.firstAddress)
    //         if (data) {
    //             address = `${data.streetName}, ${data.region}, ${data.city}, ${data.country} ,${data.postalCode}`
    //         } else {
    //             address = ``
    //         }
    //     } else {
    //         address = ""
    //     }
    // }



    return (
        <div>
            <div className="d-flex align-items-center mb-2">
                <h2 className="page-title px-3">
                    Order Summary
                </h2>
                <div className="ml-auto">
                    <AnnouncementIcon />
                    <NotificationIcon />
                </div>
            </div>
            {loading && (<FullscreenLoader />)}
            {orderSummary && orderSummary.loading && <FullscreenLoader />}
            <>
                <DialogContent className="p-0">
                    <div className="d-flex justifyContentHorizontalSpacebetween align-items-center col-12 my-2">
                        {/* <div className="mr-1"
                        > <CheckCircleIcon className="order-placed-tick" fontSize="large" />
                            <span className="order-placed ml-3">Order Placed.  Please proceed for payment.</span>
                        </div> */}
                        <span className="order-placed-message ml-1 mt-3">Payment details:</span>
                        <h6 className="ml-auto font-15 font-weight-600">
                            {/* <span className="mr-2">
                                <IconButton className="searchbarOptions" size="small">
                                    <RefreshIcon className="searchbarOptions mb-1 cursor-pointer"
                                        fontSize="medium"
                                        onClick={() => {
                                            dispatch(getOrderSummary(orderSUmmaryDetails.data.customerTierType, orderSUmmaryDetails.data.masterId, orderSUmmaryDetails.data.orderNumber1))
                                        }}
                                    ></RefreshIcon>
                                    <span className="font-size-13 load-from-fav ml-1" onClick={() => {
                                        dispatch(getOrderSummary(orderSUmmaryDetails.data.customerTierType, orderSUmmaryDetails.data.masterId, orderSUmmaryDetails.data.orderNumber1))
                                    }}>Refresh</span>
                                </IconButton>
                            </span> */}
                            <span className="ml-auto font-15 font-weight-600"> Date -  {moment(new Date(props && props.orderData && props.orderData.parentOrder && props.orderData.parentOrder.createdOn)).utc().format('DD MMM YYYY')}</span>
                        </h6>
                        {/* <h6 className="ml-auto font-15 font-weight-600">Date -  {moment(new Date(props && props.orderData && props.orderData.parentOrder && props.orderData.parentOrder.createdOn)).utc().format('DD MMM YYYY')}</h6> */}
                    </div>
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 p-0">
                        <div className="col-12 border-top w-100 p-0">
                            <div className={`enquiry-number ${classes.rowCustomSummary} justifyContentHorizontalSpacebetween align-items-center`}>
                                <div>Enquiry Number : {props && props.orderData && props.orderData.parentOrder && props.orderData.parentOrder.masterId}</div>
                                <div>Reference Number : {props && props.orderData && props.orderData.parentOrder && props.orderData.parentOrder.masterReferenceNumber}</div>
                            </div>
                        </div>
                        {/* <div className="col-12 border-bottom"></div> */}
                        <TableContainer className="height-400 mb-5 pb-5">
                            <Table>
                                <TableHead className="w-100">
                                    <TableRow>
                                        {columns.map((column: any) => (
                                            <TableCell
                                                key={column.id}
                                                className={classes.header}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                        <TableCell className={classes.header}>Payment Status </TableCell>
                                        <TableCell className={classes.header}>Transaction ID </TableCell>
                                        <TableCell className={classes.header}>Request ID </TableCell>
                                        {(sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === "int. business") || (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'dealer') || (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'fleet') || (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'government')
                                            ?
                                            "" : <TableCell className={classes.header}>Action</TableCell>}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderSummary && orderSummary.data && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((item: any, i: any) => {
                                        let priceFinal: any = item && item.price && typeof item.price == 'string' ? parseFloat(item.price) : item.price;
                                        priceFinal = priceFinal.toFixed(2)
                                        if (item) {
                                            return <TableRow>
                                                <TableCell className={!item.orderNumber ? 'text-red' : `${classes.brand}`}>
                                                    {(customerTierType && customerTierType === 'Workshop' || customerTypeAccess.includes("workshop")) ? getNewBrand(item.make) : getNewlyAddedBrand(item.make)}
                                                    {!item.orderNumber && item.status && !(item.status.toLowerCase().includes('failed')) && (
                                                        <small className="text-red position-absolute font-weight-normal d-block mt-1">{item.sapOrderMessage} </small>
                                                    )}
                                                    {item.sapOrderMessage && item.sapOrderMessage.includes("not allow external procurement") && (
                                                        <small className="text-red position-absolute font-weight-normal d-block mt-1">Some parts are not allowed for external procurement</small>
                                                    )}
                                                </TableCell>
                                                <TableCell className={classes.body}>{item.orderNumber}</TableCell>
                                                <TableCell className={classes.body}>{item.enquiryReferenceNumber}</TableCell>
                                                <TableCell className={classes.body}>{item.lines}</TableCell>
                                                <TableCell className={!item.orderNumber ? 'text-red' : `${classes.body}`}>
                                                    {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {priceFinal}</TableCell>
                                                <TableCell className={item.status && typeof item.status == 'string' && item.status && item.status && typeof item.status == 'string' && item.status === "Back Order Created" || item.status === "Back order created" ? classes.bodyColor : classes.bodyColor2}>
                                                    {item.status && typeof item.status == 'string' && item.status}
                                                </TableCell>
                                                {item.paymentStatus === "Credit Utilized" ?
                                                    <TableCell className='row'>
                                                        <span className='row'>
                                                            <p className="row font-colorchanges pl-4 mt-1 pr-3">
                                                                {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                                                            </p>
                                                            {isSellingPriceData &&
                                                                <p className='row'>
                                                                    {offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item.goodsMovementStatus == 'Completed' ||
                                                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                        offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? <> </> : <Tooltip title="The order amount was deducted from your available credit." placement="top">
                                                                        <p className='pl-3'><ErrorIcon className='icon-color' /> </p>
                                                                    </Tooltip>
                                                                    }
                                                                </p>}
                                                        </span>
                                                    </TableCell>
                                                    :
                                                    <TableCell className={item.paymentStatus === "Payment Due" ? classes.bodyColor1 : item.paymentStatus === "Expired" ? classes.bodyColor1 : item.paymentStatus === "Declined" ? classes.bodyColor1 : item.paymentStatus === "Offline Payment Pending" ? classes.bodyColor2 : item.paymentStatus === "Payment Received" ? classes.bodyColor2 : classes.bodyColor4}>
                                                        {offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus ? offlineData[i].paymentStatus : item.paymentStatus}
                                                    </TableCell>
                                                }
                                                <TableCell className={classes.body}>{offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus && offlineData[i].paymentStatus.includes('Offline Payment Pending') || (item.paymentStatus && item.paymentStatus.includes('Offline Payment Pending')) ? '-' : item.paymentStatus === "Declined" || item.paymentStatus === "Payment Received" ? item.TransactionID : '-'}</TableCell>
                                                <TableCell className={classes.body}>{offlineData && offlineData.length >= 1 && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus && offlineData[i].paymentStatus.includes('Offline Payment Pending') || (item.paymentStatus && item.paymentStatus.includes('Offline Payment Pending')) ? '-' : item.paymentStatus === "Declined" || item.paymentStatus === "Payment Received" ? item.requestId : '-'}</TableCell>
                                                {/* {item.paymentStatus === "APPROVED" ?
                                                <TableCell>
                                                    {isSellingPriceData && <Button
                                                        className={classes.makePayment}
                                                        size="small"
                                                        disabled
                                                        variant="contained"
                                                        onClick={() => { handleClickOpen(item); }}
                                                    > Make Payment
                                                    </Button>}
                                                </TableCell>
                                                : <TableCell>
                                                    {isSellingPriceData && <Button
                                                        className={classes.makePayment}
                                                        size="small"
                                                        variant="contained"
                                                        disabled={priceFinal === "0.00" ? true : false}
                                                        onClick={props.handleClickOpen}
                                                        > Make Payment
                                                    </Button>}
                                                </TableCell>
                                            } */}
                                                {(sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === "int. business") || (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'dealer') || (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'fleet') || (sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() === 'government')
                                                    ? "" :
                                                    <TableCell>
                                                        {item.paymentStatus === "Credit Utilized" ?
                                                            <>
                                                                {isSellingPriceData &&
                                                                    <>
                                                                        {offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item.goodsMovementStatus == 'Completed' ||
                                                                            offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                            offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                            offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? <> </> : <Tooltip title="Your credit has been utilized. you can still save your credit limit by paying now using different payment modes." placement="top">
                                                                            <p>Wish to pay now ? <ErrorIcon className='icon-color' /> </p>
                                                                        </Tooltip>}
                                                                        <Button
                                                                            className={classes.makePayment}
                                                                            size="small"
                                                                            variant="contained"
                                                                            disabled={priceFinal == 0 || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || item.goodsMovementStatus == 'Completed' || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.status === "Offline Payment Pending" ||
                                                                                offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                                offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Pending Approval" || item && item.paymentStatus === "Pending Approval" ||
                                                                                offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment in Progress" || item && item.paymentStatus === "Payment in Progress" ||
                                                                                offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}
                                                                            // onClick={() => { handleClickOpen(item); }}
                                                                            onClick={() => { handleClickOpen(props); props.handleClickOpen(""); sessionStorage.setItem("placed_order_index", i) }}
                                                                        > Make Payment
                                                                        </Button>
                                                                    </>
                                                                }
                                                            </>
                                                            :
                                                            item.paymentStatus === "Payment Received" || item.goodsMovementStatus == 'Completed' ?
                                                                <>

                                                                    {isSellingPriceData && <Button
                                                                        className={classes.makePayment}
                                                                        size="small"
                                                                        variant="contained"
                                                                        disabled
                                                                        onClick={() => { handleClickOpen(props); props.handleClickOpen(""); sessionStorage.setItem("placed_order_index", i) }}
                                                                    > Make Payment
                                                                    </Button>}
                                                                </>
                                                                :
                                                                <>
                                                                    {isSellingPriceData && <Button
                                                                        className={classes.makePayment}
                                                                        size="small"
                                                                        variant="contained"
                                                                        disabled={priceFinal == 0 || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment Received" || item && item.goodsMovementStatus == 'Completed' || offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending" || item && item.paymentStatus === "Offline Payment Pending" ||
                                                                            offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Payment at Counter)" || item && item.paymentStatus === "Offline Payment Pending (Payment at Counter)" ||
                                                                            offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Payment in Progress" || item && item.paymentStatus === "Payment in Progress" ||
                                                                            offlineData && offlineData[i] && offlineData[i].orderNumber === item.orderNumber && offlineData[i].paymentStatus === "Offline Payment Pending (Credit card at delivery )" || item && item.paymentStatus === "Offline Payment Pending (Credit card at delivery )" ? true : false}
                                                                        onClick={() => { handleClickOpen(props); props.handleClickOpen(""); sessionStorage.setItem("placed_order_index", i) }}
                                                                    > Make Payment
                                                                    </Button>}

                                                                </>
                                                        }
                                                    </TableCell>
                                                }
                                            </TableRow>
                                        }
                                        return null
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </DialogContent>
                <div className="p-0">
                    <div className="row no-gutters table-bottom p-0">
                        <div className="col-lg-3 col-md-3 col-sm-6 p-3 border">
                            <p className="bottom-field-name-table">
                                Total Price
                            </p>
                            <p className="bottom-field-value-table">
                                {sessionStorage.getItem('userType') === 'FSE' ? `${companyCurrencyTypeByFSE}` : customerTierType === "Int. Business" ? `${companyCurrencyType}` : 'AED'} {parseFloat(getSellingPrice(parseFloat(calculatePrice("")), isSellingPriceData, markupPercentage)).toFixed(2)}
                                <span className="vat ml-1">(Incl. of VAT)</span>
                            </p>
                        </div>
                        {sessionStorage.getItem('userType') !== 'FSE' && customerTierType.toLowerCase() === "workshop" || customerTierType === "Workshop" ?
                            <>
                                {orderSummary && orderSummary.data && orderSummary.data && orderSummary.data.length > 0 && orderSummary.data[0].orderSummeryList.map((item: any, i: any) => {
                                    return <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom border">
                                        <p className="bottom-field-name-table">
                                            {item.deliveryType === "Onsite Delivery" ? "Shipping Address" : item.deliveryType === "Self Pickup" ? "Collection Point" : ""}
                                        </p>
                                        {item.deliveryType === "Onsite Delivery" ?
                                            <p className="bottom-field-value-table">
                                                {sessionStorage.getItem('userType') === 'FSE' ? fseNewAddress : address}
                                            </p>
                                            :
                                            item.deliveryType === "Self Pickup" ?
                                                <p className="bottom-field-value-table">
                                                    {collectionPoints && collectionPoints.length > 0 && collectionPoints.map((ele: any) => {
                                                        return <p> {ele.defineTPCounter ? ele.defineTPCounter : ""}, {" "} {ele.area ? ele.area : ""}, {" "} {ele.landlinePhone ? "Landline No :" : ""} {" "} {ele.landlinePhone ? ele.landlinePhone : ""}</p>
                                                    })}
                                                </p>
                                                :
                                                ""
                                        }
                                    </div>
                                })}
                            </>
                            : ''}
                        {customerTierType.toLowerCase() !== "workshop" || customerTierType !== "Workshop" ?
                            <div className="col-lg-6 col-md-6 col-sm-12 p-3 border-right-bottom border">
                                <p className="bottom-field-name-table">
                                    Office Address
                                </p>
                                <p className="bottom-field-value-table">
                                    {sessionStorage.getItem('userType') === 'FSE' ? fseNewAddress : address}
                                </p>
                            </div>
                            : ''
                        }
                        <div className="col-lg-3 col-md-3 col-sm-6 p-2 border-bottom border d-flex justify-content-center">
                            <Button variant="contained" color="primary" className="mt-2 actionButtomButtomsBlue" onClick={() => {
                                if (goToOrder === true) {
                                    if ((sessionStorage.getItem('userType') === 'FSE' && customerTierType && customerTierType.toLowerCase() !== 'workshop')) {
                                        setDataUpdated(false); handleopenClose(false);
                                        history.push('/orderhistory')
                                        props.handleClickOpen("str");
                                        <FullscreenLoader />
                                    } else {
                                        setDataUpdated(true); handleopenClose(true);
                                    }
                                } else {
                                    history.push('/orderhistory')
                                    props.handleClickOpen("str");
                                    <FullscreenLoader />
                                }
                            }}>Go to Order History
                            </Button>
                        </div>
                    </div>
                </div>
                <DeleteDialog
                    open={confirmDialog && dataUpdated}
                    text={<div>
                        <h6 className='font-text mt-0'>Would you like to make payment later?</h6>
                        <p className='mt-1'>If not paid within 24 hours, your order will get automatically cancelled. Delivery will not be scheduled until payment is made.</p>
                    </div>}
                    handleopenClose={(data: any) => {
                        if (data === 'delete') {
                            <FullscreenLoader />
                            props.handleClickOpen("str");
                            history.push('/orderhistory'); setDataUpdated(false); handleopenClose(false);
                        }
                        if (!data) { handleopenClose(false); }
                    }} />
                {/* <Dialog className="pb-0"
                    open={openDialog}
                    onClose={handleCloseDialog}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <HistoryMakePaymentDialog orderData={orderData} setSuccessMessage={setSuccessMessage}
                        setOpenErrorSnackbar={setOpenErrorSnackbar}
                        openErrorSnackbar={openErrorSnackbar}
                        handleopenCloseSnackbar={handleopenCloseSnackbar} setOpenDialog={handleCloseDialog} />
                </Dialog > */}
                {((goToOrder && customerTierType && customerTierType.toLowerCase() === "workshop") || (goToOrder && customerTierType && customerTierType === "Workshop") || (goToOrder && customerTierType && customerTierType.toLowerCase() === "dealer") || (goToOrder && customerTierType && customerTierType === "Dealer")) && <Prompt when={window.location.pathname == '/orderhistorypayment'} message={"Would you like to make payment later?"}></Prompt>}
            </>
        </div>
    )
}
export default OrderHistoryPayment