/* eslint-disable */
import React from 'react'
import { useSelector } from 'react-redux'
import { Markup } from 'interweave';

const apiMaps = "AIzaSyBfiUhBQsJ5ZhKUiGWOyQ7z5uiAtXK9cAA";


const OurLocations = (props: any) => {

  const defaultProps = { center: { lat: 23.4677, lng: 53.7369 }, zoom: 11 };
  const imageSource = useSelector((state: any) => {
    return state.home.brands
  })

  return (
    <div className="section">
      <div className="container xl text-center">
        <h2 className="poppins-700 text-black">
          {props?.pageData?.locations?.heading}
        </h2>
        <p className="mt-4 font-16">{props?.pageData?.locations?.short_desc}</p>
        <div className="row mt-5 mb-3 align-items-left">
          <div className="row col-12">
            {props?.pageData?.locations?.list && props?.pageData?.locations?.list.length > 0 && props?.pageData?.locations?.list.map((location: any, i: any) => (
              <div key={i} className="col-md-6 mb-4">
                <div className="row">
                  <div className="col-6 text-left">
                    <h5 className="text-black poppins-700">{location.title}</h5>
                    <Markup content={location.short_desc} />
                  </div>
                  <div className="col-6">
                    <iframe id="iframeId" className="w-100" frameBorder="0" src={`https://maps.google.com/maps?q=${location.map_coordinates.split(',')[0]},${location.map_coordinates.split(',')[1]}&z=12&amp;&hl=es;&output=embed`}></iframe>
                    <button className="btn btn-outline-primary tp-btn w-100" onClick={() => { window.open(location.directions_link, '_blank') }}>Get Directions</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}


export default OurLocations