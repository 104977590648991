import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import moment from "moment-timezone";
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { isEmpty } from 'lodash';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    title: {
        flex: '1 1 100%',
    },
    createButton: {
        backgroundColor: '#1A9FE0',
        width: 180,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#FFFFFF',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 8
    },
    bodyContent: {
        color: "#666666",
        fontWeight: 600,
        fontSize: 12,
        fontFamily: "Nunito",
        padding: 0,
        align: "center",
        maxWidth: "165px",
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    addUpdateDailogTitle: {
        position: "absolute",
        top: 10,
        left: 10
    },
}));

export default function DocumentExpiryStatus(props: any) {
    const classes = useStyles();
    const history = useHistory();
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const blockIntent = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.customerInfo && profileDetails.data.data.customerInfo.blockIntent;
    const { getDocumentExpiryStatusData }: any = useSelector((state: any) => state.profileDetails);

    return (
        <Fragment>
            {/* {getDocumentExpiryStatusData && <FullscreenLoader />} */}
            <Dialog
                open={props.open}
                // onClose={() => { props.handleopenClose(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth={'md'}
            >
                <div className={classes.root}>
                    {/* <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} text={successMessage} />
                <Snackbar
                    open={openErrorSnackbar}
                    type="error"
                    handleopenClose={() => {
                        handleopenCloseErrorSnackbar(false)
                    }} message={errorMessage} /> */}
                    <>
                        <Toolbar
                            className={clsx(classes.root)}>
                            <div className="col-12 mb-2 mt-2">
                                <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                    <h4 className="shift-to-party-page-title">
                                        <div className={classes.addUpdateDailogTitle}>
                                            Document Expiry Status
                                        </div>
                                        <IconButton aria-label="close" className="float-right" onClick={() => {
                                            props.handleopenClose(false);
                                        }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </h4>
                                </Typography>
                            </div>
                        </Toolbar>
                    </>
                </div>
                <div className="row mt-4 mb-3">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="d-flex align-items-center">
                            <div className="legendItem ml-auto">
                                <span className="reddot"></span>
                                <span className="legendItemLabel mr-4">Exceeded the grace period</span>
                                <span className="orangedot"></span>
                                <span className="legendItemLabel mr-4">In the grace period</span>
                                <span className="bluedot"></span>
                                <span className="legendItemLabel mr-5">Going to expire</span>
                            </div>
                        </div>
                    </div>
                </div>
                {!isEmpty(getDocumentExpiryStatusData) && getDocumentExpiryStatusData && getDocumentExpiryStatusData.data && getDocumentExpiryStatusData.data.data && getDocumentExpiryStatusData.data.data.messages.length > 0 && getDocumentExpiryStatusData.data.data.messages.map((ele: any) => {
                    return <div className='px-4'>
                        <div className={ele && ele.severity === "3" ? "subTitle-red text-preline mt-2 px-3" : ele && ele.severity === "2" ? "subTitle-yellow text-preline mt-2 px-3" : "subTitle-blue text-preline mt-2 px-3"}>{ele && ele.message}</div>
                        <h6 className="document-sub-content pt-1 pb-2 mb-2 border-bottom px-3">Expiry Date - <span className='document-sub-content-dark'>
                            {ele.expiryDate ? moment(ele.expiryDate).tz('Asia/Dubai').format('YYYY-MM-DD') : '-'}</span>
                        </h6>
                    </div>
                })
                }
                {isEmpty(getDocumentExpiryStatusData && getDocumentExpiryStatusData.data && getDocumentExpiryStatusData.data.data && getDocumentExpiryStatusData.data.data.messages) &&
                    <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Document Expiry Found.</div>
                }
                <DialogActions className="mt-3 mb-3 justify-content-end">
                    <Button variant="contained"
                        color="secondary" className={`createFavourite ${classes.createButton}`}
                        onClick={() => {
                            props.handleopenClose(false);
                            history.push('/orgsetting');
                        }}
                    >Go to Org. Setting
                    </Button>
                    <Button variant="contained" className={classes.closeButton}
                        disabled={blockIntent === true ? true : false}
                        onClick={() => {
                            props.handleopenClose(false);
                        }}
                    >Skip</Button>
                </DialogActions>
            </Dialog>
        </Fragment >
    );
}