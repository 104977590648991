// import {specialprice } from "../../services/specialPrice.servise";
import { SPECIAL_PRICE, SPECIAL_PRICE_KAM } from "./actionsTypes";
import { specialprice } from "../../services/specialPriceKam.service.";
import { apiStart, apiError, apiSuccess } from "./apiActions";


export function getSpecialPriceKam(statusValue: any, isFilterByDate: any, fromDate: any, toDate: any, search: any, page: any,id:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.GET_SPECIALPRICE_LIST_FETCHING));
        try {
            const response = await specialprice.getSpecialPriceKam(statusValue, isFilterByDate, fromDate, toDate, search, page,id);
            dispatch(apiSuccess(SPECIAL_PRICE.GET_SPECIALPRICE_LIST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.GET_SPECIALPRICE_LIST_ERROR, error));
            return error;
        }
    };
}

export function checkAvailabilityResetData() {
    return async (dispatch: any) => {
        return dispatch({type:SPECIAL_PRICE.CHECK_AVALIABLILITY_RESET_DATA })
    }
}

export function getSpecialPriceStatusKam() {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE_KAM.GET_SPECIALPRICE_STATUS_FETCHING));
        try {
            const response = await specialprice.getSpecialPriceStatusKam()
            dispatch(apiSuccess(SPECIAL_PRICE_KAM.GET_SPECIALPRICE_STATUS_SUCCES, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE_KAM.GET_SPECIALPRICE_STATUS_ERROR, error));
            return error;
        }
    }
}

export function viewSpecialPriceKamAPI(id: any, name: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.VIEW_SPECIALPRCIEREQUEST_FETCHING));
        try {
            const response = await specialprice.viewSpecialPriceKamAPI(id, name)
            dispatch(apiSuccess(SPECIAL_PRICE.VIEW_SPECIALPRICEREQUEST_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.VIEW_SPECIALRICEREQUEST_ERROR, error));
            return error;
        }
    }
}

export function addComment(id: any, uid: any, addedBy: any, addedName: any, addedRole: any, comment: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_FETCHING));
        try {
            const response = await specialprice.addComment(id, uid, addedBy, addedName, addedRole, comment)
            dispatch(apiSuccess(SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_ERROR, error));
            return error;
        }
    }
}

export function getCommentPart(id: any, uid: any, sapCustomerId: any, role: string = 'pricing_team') {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_FETCHING));
        try {
            const response = await specialprice.getCommentPart(id, uid, sapCustomerId, role)
            dispatch(apiSuccess(SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.GETCOMMENTPART_SPECIALPRICE_ERROR, error));
            return error;
        }
    }
}

// export function addCommentTotal(id: any, name: any) {
//     return async (dispatch: any) => {
//         dispatch(apiStart(SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_FETCHING));
//         try {
//             const response = await specialprice.viewSpecialPrice(id, name)
//             dispatch(apiSuccess(SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_SUCCESS, response));
//             return response || [];
//         } catch (error) {
//             dispatch(apiError(SPECIAL_PRICE.ADDCOMMENT_SPECIALPRICE_ERROR, error));
//             return error;
//         }
//     }
// }

export function postForwardRequestToKam(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_FETCHING));
        try {
            const response = await specialprice.postForwardRequestToKam(data)
            dispatch(apiSuccess(SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.POST_FORWARD_TO_KAM_STATUS_ERROR, error));
            return error;
        }
    }
}

export function addCommentTotalKam(id: any, uid: any, addedBy: any, addedName: any, addedRole: any, comment: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE_KAM.ADDCOMMENTTOTAL_SPECIALPRICE_FETCHING));
        try {
            const response = await specialprice.addCommentAll(id, uid, addedBy, addedName, addedRole, comment)
            dispatch(apiSuccess(SPECIAL_PRICE_KAM.ADDCOMMENT_SPECIALPRICE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE_KAM.ADDCOMMENTTOTAL_SPECIALPRICE_ERROR, error));
            return error;
        }
    }
}

export function postSaveFormData(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.SAVE_FORM_API_FETCHING));
        try {
            const response = await specialprice.postSaveFormData(data)
            dispatch(apiSuccess(SPECIAL_PRICE.SAVE_FORM_API_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.SAVE_FORM_API_ERROR, error));
            return error;
        }
    }
}

export function rejectPart(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.REJECT_BY_PART_API_FETCHING));
        try {
            const response = await specialprice.rejectPart(data)
            dispatch(apiSuccess(SPECIAL_PRICE.REJECT_BY_PART_API_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.REJECT_BY_PART_API_ERROR, error));
            return error;
        }
    }
}

export function rejectRequest(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.REJECT_REQUEST_API_FETCHING));
        try {
            const response = await specialprice.rejectRequest(data)
            dispatch(apiSuccess(SPECIAL_PRICE.REJECT_REQUEST_API_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.REJECT_REQUEST_API_ERROR, error));
            return error;
        }
    }
}

export function getCommentTotalKam(id: any,  sapCustomerId:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_FETHCING));
        try {
            const response = await specialprice.getCommentPartTotal(id, sapCustomerId)
            dispatch(apiSuccess(SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_ERROR, error));
            return error;
        }
    }
}

export function getCommentTotal(id: any,  sapCustomerId:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_FETHCING));
        try {
            const response = await specialprice.getCommentPartTotal(id, sapCustomerId)
            dispatch(apiSuccess(SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE_KAM.GETCOMMENTTOTAL_SPECIALPRICE_ERROR, error));
            return error;
        }
    }
}


export function getComment(id: any,  sapCustomerId:any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.GETCOMMENTTOTAL_SPECIALPRICE_FETHCING));
        try {
            const response = await specialprice.getCommentPartTotal(id, sapCustomerId)
            dispatch(apiSuccess(SPECIAL_PRICE.GETCOMMENTTOTAL_SPECIALPRICE_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.GETCOMMENTTOTAL_SPECIALPRICE_ERROR, error));
            return error;
        }
    }
}

export function checkandavaliabilityApi(B2bEnquiryId: any, data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.CHECK_AVALIABLILITY_API_FETCHING));
        try {
            const response = await specialprice.checkandavaliabilityApi(B2bEnquiryId, data)
            dispatch(apiSuccess(SPECIAL_PRICE.CHECK_AVALIABLILITY_API_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.CHECK_AVALIABLILITY_API_ERROR, error));
            return error;
        }
    }
}

export function requestCorrectionToKam(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.POST_REQUEST_CORRECTION_KAM_FETCHING));
        try {
            const response = await specialprice.requestCorrectionToKam(data)
            dispatch(apiSuccess(SPECIAL_PRICE.POST_REQUEST_CORRECTION_KAM_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.POST_REQUEST_CORRECTION_KAM_ERROR, error));
            return error;
        }
    }
}

export function fowardToCustomerKam(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.POST_FORWARD_TO_CUSTOMER_FETCHING));
        try {
            const response = await specialprice.fowardToCustomerKam(data)
            dispatch(apiSuccess(SPECIAL_PRICE.POST_FORWARD_TO_CUSTOMER_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.POST_FORWARD_TO_CUSTOMER_ERROR, error));
            return error;
        }
    }
}

export function statusReopenedKam(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE.POST_STATUS_REOPENED_API_FETCHING));
        try {
            const response = await specialprice.statusReopenedKam(data)
            dispatch(apiSuccess(SPECIAL_PRICE.POST_STATUS_REOPENED_API_SUCCESS, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE.POST_STATUS_REOPENED_API_ERROR, error));
            return error;
        }
    }
}

export function requestCorrectionDetailsKam(id: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(SPECIAL_PRICE_KAM.GET_REQUEST_CORRECTION__KAM_FETCHING));
        try {
            const response = await specialprice.getRequestCorrectionKam(id)
            dispatch(apiSuccess(SPECIAL_PRICE_KAM.GET_REQUEST_CORRRECTION_KAM_SUCCES, response));
            return response || [];
        } catch (error) {
            dispatch(apiError(SPECIAL_PRICE_KAM.GET_REQUEST_CORRECTION_KAM_ERROR, error));
            return error;
        }
    }
}