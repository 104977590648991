/* eslint-disable */
import React from "react";
import { useEffect, Dispatch } from "react";
import Button from '@material-ui/core/Button';
import { useHistory } from "react-router";
import IconButton from '@material-ui/core/IconButton';
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from "@material-ui/icons/Cancel";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import Badge from '@material-ui/core/Badge';
import ViewWriteComments from "./ViewWriteComments";
import { useSelector } from 'react-redux';
import RejectPartDialog from "./RejectPartDialog";
import RejectPriceRequestDialog from "./RejectPriceRequestDialog";
import VisibilityIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ViewJustification from "./ViewJustification";
import XLSX, { read, readFile, utils } from "xlsx";
import { addComment, getCommentPart, getCommentTotalKam, checkAvailabilityResetData, viewSpecialPriceKamAPI } from "../../store/actions/specialPriceKam.actions";
import { useDispatch, } from "react-redux";
import moment from "moment";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import ViewWriteCommentsTotal from "./ViewWriteCommentsTotal";
import _, { get } from "lodash";
import { getSpecialPrice, postForwardRequestToKam, postSaveFormData, checkandavaliabilityApi } from "../../store/actions/specialprice.actions";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { Prompt } from "react-router-dom"
import GetAppIcon from "@material-ui/icons/GetApp";
import ListAltIcon from '@material-ui/icons/ListAlt';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from "@material-ui/icons/Help";
import "./ViewPriceRequestKam.css"
import RequestCorrectionKam from "./RequestCorrectionKam"
import SentToCustomerDialog from "./SentToCustomerDialog";
import { Divider } from "@material-ui/core";
import Documents from "./Documents"

const useStyles = makeStyles((theme) => ({
  actionButton: {
    color: 'black',
    height: "15px",
    width: "15px"
  },
  actionButtonEye: {
    color: '#1A9FE0',
  },
  actionButtonComment: {
    color: '#1A9FE0',
    height: "20px",
    width: "20px"
  },
}))

const styles = makeStyles({
  notchedOutline: { borderColor: "rgba(224, 224, 224, 1) !important", borderRadius: "25px 0px 0px 25px" },
  root: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "blue",
      height: "14px",
      fontSize: "11px"
    },

  },
  root2: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "black",
      height: "14.2px",
      fontSize: "",

    },
  },
  root3: {
    "& .MuiOutlinedInput-inputMarginDense": {
      paddingBottom: "11px !important",
      paddingTop: "11px !important",
      borderRadius: "40px 40px 0px 0px !important",
      color: "black",
      height: "14px",
      fontSize: "11px",

    },

  },
  notchedOutline1: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "0px 0px 0px 0px", },
  notchedOutline2: { borderTopColor: "rgba(224, 224, 224, 1) !important", borderBottomColor: "rgba(224, 224, 224, 1) !important", borderLeftColor: "rgba(224, 224, 224, 1) !important", borderRightColor: "rgba(224, 224, 224, 1) !important", borderRadius: "0px 25px 25px 0px", background: "", color: "" },
})


const ViewPriceRequestKam: React.FC = () => {
  const classes = useStyles();
  const history: any = useHistory()
  const [open, setOpen] = React.useState(false);
  const [openRequesCorrection, setOpenRequestion] = React.useState(false);
  const [openRejectPart, setOpenRejectPart] = React.useState(false);
  const [openPriceRequest, setOpenPriceRequest] = React.useState(false);
  const [openJustfication, setOpenJustification] = React.useState(false);
  const [percentage, setPercentage] = React.useState("%0");
  const [offeredBy, setOfferedBy] = React.useState("Parts")
  /* special price dropdown*/
  const [valuePriceNow, setValuePriceNow] = React.useState<any>([]);
  const [valueSelectPriceNow, setValueSelectPriceNow] = React.useState<any>([]);
  const [valueCalculatedNow, setValueCalculatedNow] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<any>('');
  const [valuePriceBo, setValuePriceBo] = React.useState<any>([]);
  const [valueSelectPriceBo, setValueSelectPriceBo] = React.useState<any>([]);
  const [valueCalculatedBo, setValueCalculatedBo] = React.useState<any>([]);
  const [select, setSetSelect] = React.useState<any>([]);
  const [selectByTotal, setSelectByTotal] = React.useState<any>("By AED");
  const [offeredTotalAED, setOfferedTotalAED] = React.useState<any>("");
  const [offeredTotalPercent, setOfferedTotalPercent] = React.useState<any>("");
  const [calculatedTotal, setTotalCalculatedTotal] = React.useState<any>("");
  const [totalError, setTotalError] = React.useState<any>(false);
  const [totalOfferedAED, setTotalOfferedAED] = React.useState<any>("")
  const [excelError, setExcelError] = React.useState<any>("");
  const [importDataExcel, setImportDataExcel] = React.useState<any>("");
  const [isImport, setIsImport] = React.useState<boolean>(false);
  const [id, setId] = React.useState<any>("")
  const [uid, setUid] = React.useState<any>("")
  const [openTotal, setOpenTotal] = React.useState(false);
  const [sapId, setSapId] = React.useState("")
  const [total, setTotal] = React.useState<any>("")
  const [discountTotal, setDiscountTotal] = React.useState<any>("")
  const [isDiscountPricePart, setIsDiscountPricePart] = React.useState<boolean>(false)
  const [totalComment, setTotalComment] = React.useState(0);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const { viewSpecialPriceKam, viewSpecialPrice, checkandavailibilityData, forwardRequestToKam, saveFormData } = useSelector((state: any) => state.specialPriceKam)
  const { orderno } = useSelector((state: any) => state.specialPrice)

  const dispatch: Dispatch<any> = useDispatch();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [showApproveDialog, setshowApproveDialog] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [responseSuccess, setResponseSuccess] = React.useState<boolean>(false);
  const sapCustomerId = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
  const classes2 = styles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [productData, setProductData] = React.useState<any>();
  const [responseDetails, setResponseDetails] = React.useState<any>();

  const [responseIDData, setResponseIDData] = React.useState<any>();
  const b2bUserId = _.get(profileDetails, "data.data.basicInfo.b2bUserId", "");
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");

  const [estimateGrandTotal, setEstimateGrandTotal] = React.useState<any>();
  const [requestTotalPriceType, setRequestTotalPriceType] = React.useState<any>();
  const [requestTotalPriceCurrency, setRequestTotalPriceCurrency] = React.useState<any>();
  const [requestTotalPrice, setRequestTotalPrice] = React.useState<any>();
  const [requestedTotalParts, setRequestedTotalParts] = React.useState("");
  const [requestTotalPriceCalculated, setRequestTotalPriceCalculated] = React.useState<any>();
  const [responseStatus, setResponseStatus] = React.useState<any>();
  const [requestedTotal, setRequestedTotal] = React.useState<any>("")

  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const [B2bEnquiryId, setB2bEnquiryId] = React.useState<any>();
  const [sapCustomerIdData, setSapCustomerIdData] = React.useState<any>();
  const [offeredTotalData, setOfferedTotalData] = React.useState<any>();
  const [dataUpdatedPrompt, setDataUpdatedPrompt] = React.useState<boolean>(false);
  const b2cUserId = get(
    profileDetails,
    "data.data.basicInfo.b2cUserId",
    ""
  );
  const [nowPricevalid, setNowPricevalid] = React.useState<any>();
  const [boPricevalid, setBoPricevalid] = React.useState<any>();
  const [textRejectPartError, setTextRejectPartError] = React.useState<boolean>(false);
  const [textRejectPartErrorMessage, setTextRejectPartErrorMessage] = React.useState<boolean>(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);

  const [nowAndBoValue, setNowAndBoValue] = React.useState<Array<any>>([]);
  const [RequestedTotalPartsTotal, setRequestedTotalPartsTotal] = React.useState<any>("")
  const [nowValue, setNowValue] = React.useState<any>();
  const [boValue, setBoValue] = React.useState<any>();
  const [offeredValue, setOfferedValue] = React.useState<any>();
  const [quantity, setQuantity] = React.useState<any>("")
  const [url, setUrl] = React.useState([])
  const [openDocuments, setOpenDocuments] = React.useState(false)

  const handleOpenCorrection = () => {
    setOpenRequestion(false);
  }


  const orgIdBrandsMapping: any = {
    "2001": "Toyota",
    "2002": "Honda",
    "2003": "Famco",
    "2380": "TE",
    "2195": "OES",
    "7099": "Toyota"
  }


  useEffect(() => {
    if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.length >= 1) {
      let len = viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products;
      let item: any = []
      len.forEach((ele: any, i: any) => {
        let errorNow = false
        let errorBo = false
        let valueNow: any = []
        let valueBo: any = []
        let valueAEDNow: any = 0
        let valueAEDBo: any = 0
        valueAEDNow = ((parseFloat(ele.nowCost) - parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"])) / parseFloat(ele.nowCost));
        valueAEDNow = (valueAEDNow * 100).toFixed(2) + "%"
        valueNow.push(valueAEDNow)
        valueAEDBo = (parseFloat(ele.aop) - parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"])) / parseFloat(ele.aop);
        valueAEDBo = (valueAEDBo * 100).toFixed(2) + "%"
        valueBo.push(valueAEDBo)
        if (parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"]) >= parseFloat(ele.nowCost)) {
          errorNow = true
        } else if (parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"]) >= parseFloat(ele.aop)) {
          errorBo = true
        }
        item.push({
          value: "By AED", priceAED: importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"] ? parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"]) : "", priceAEDExcel: importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"] ? importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"] : "", pricePercent: "", priceGreaterThanNow: "", errorNow: parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"]) >= parseFloat(ele.nowCost) ? true : false, valueBo: "By AED", calcuatedPrice: importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Now"] ? valueAEDNow : "", priceBo: valueAEDNow,
          priceBoAED: importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"] ? parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"]) : "", priceBoAEDExcel: importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"] ? importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"] : "", priceBoPercent: "", priceGreaterThanBo: "", calcuatedPriceBo: valueAEDBo, errorBo: parseFloat(importDataExcel && importDataExcel[i] && importDataExcel[i]["Offered Price Bo"]) >= parseFloat(ele.aop) ? true : false
        })
      })


      if (isImport) {
        setValuePriceNow(item);
        setValueSelectPriceNow(item);
        setValueCalculatedNow(item);
        setValuePriceBo(item);
        setValueSelectPriceBo(item);
        setValueCalculatedBo(item);
      }
    }
  }, [importDataExcel, isImport])

  useEffect(() => {
    checkandavailibilityData && checkandavailibilityData.data && checkandavailibilityData.data.data && checkandavailibilityData.data.data.data && viewSpecialPriceKam.data.data.data.map((ele: any, i: any) => {
      return (
        <>
          {console.log("Looped - data", ele)}
          {ele && ele.verifiedData.map((data: any) => {
            console.log("INDSIDE - DATA", data)
          })}
        </>
      )
    })
  }, [checkandavailibilityData])

  useEffect(() => {
    let nowAvaliabilityValue: any
    let boAvaliabilityValue: any
    let nowAndAirAvailabilityValue: Array<any> = []

    checkandavailibilityData && checkandavailibilityData.data && checkandavailibilityData.data.data && checkandavailibilityData.data.data.map((subEnquiries: any, i: any) => {
      return (
        <>
          {subEnquiries &&
            subEnquiries["verifiedData"] &&
            subEnquiries["verifiedData"]["confirmations"] &&
            Array.isArray(subEnquiries["verifiedData"]["confirmations"]) &&
            subEnquiries["verifiedData"]["confirmations"].map((confirmationsingle: any) => {
              nowAvaliabilityValue = confirmationsingle && confirmationsingle.deliveryBucketId === "NOW" ? confirmationsingle && confirmationsingle.availableQuantityforProposedArticle : "0"
              boAvaliabilityValue = confirmationsingle && confirmationsingle.deliveryBucketId === "AIR" ? confirmationsingle && confirmationsingle.availableQuantityforProposedArticle : "0"

              nowAndAirAvailabilityValue.push({
                nowAvaliabilityValue, boAvaliabilityValue
              })
            })}
        </>
      )
    })
    setNowAndBoValue(nowAndAirAvailabilityValue)
    setNowValue(nowAvaliabilityValue)
    // setBoValue(boAvaliabilityValue)
  }, [checkandavailibilityData])

  useEffect(() => {
    return () => {
      dispatch(checkAvailabilityResetData());
    }
  }, [])



  useEffect(() => {
    let responseID: any
    let responseUpdatedByPricingTeam: any
    let offeredTotalPriceData: any
    let offeredTotalPriceCurrencyData: any
    let requestTotalPriceCalculatedData: any
    let requestTotalPriceData: any
    let requestTotalPriceCurrencyData: any
    let requestTotalPriceTypeData: any
    let estimateGrandTotalData: any
    let statusData: any
    let sapIDData: any
    let B2bEnquiryIdData: any

    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data &&
      viewSpecialPriceKam.data.data.data && (
        <>
          {responseID = viewSpecialPriceKam?.data?.data?.data?.id}
          {requestTotalPriceCalculatedData = viewSpecialPriceKam?.data?.data?.data?.requestTotalPriceCalculated}
          {requestTotalPriceData = viewSpecialPriceKam?.data?.data?.data?.requestTotalPrice}
          {requestTotalPriceCurrencyData = viewSpecialPriceKam?.data?.data?.data?.requestTotalPriceCurrency}
          {requestTotalPriceTypeData = viewSpecialPriceKam?.data?.data?.data?.requestTotalPriceType}
          {estimateGrandTotalData = viewSpecialPriceKam?.data?.data?.data?.estimateGrandTotal}
          {statusData = viewSpecialPriceKam?.data?.data?.data?.status}
          {B2bEnquiryIdData = viewSpecialPriceKam?.data?.data?.data?.B2bEnquiryId}
          {sapIDData = viewSpecialPriceKam?.data?.data?.data?.sapCustomerId}
        </>
      )
    setResponseIDData(responseID)
    setEstimateGrandTotal(estimateGrandTotalData)
    setRequestTotalPriceType(requestTotalPriceTypeData)
    setRequestTotalPriceCurrency(requestTotalPriceCurrencyData)
    setRequestTotalPrice(requestTotalPriceData)
    setRequestTotalPriceCalculated(requestTotalPriceCalculatedData)
    setResponseStatus(statusData)
    setB2bEnquiryId(B2bEnquiryIdData)
    setSapCustomerIdData(sapIDData)
  }, [])

  useEffect(() => {
    let quantitiyStore: any = []
    let responseData: any
    let nowPricevalidation: any
    let airpriceValidation: any
    let quantity: any = 0
    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.forEach((ele: any, i: any) => {
      quantity += parseFloat(ele.nowAvailability) + parseFloat(ele.airAvailability)
    })
    if (quantity >= 1) {
      setQuantity(quantity)
    }
    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.map((ele: any, i: any) => {
      return (
        <>
          {nowPricevalidation = valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"])}
          {airpriceValidation = valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["valueBo"] === "By AED" ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"]}
        </>
      )
    })
    setNowPricevalid(nowPricevalidation);
    setBoPricevalid(airpriceValidation);

    let url: any = []
    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.attachments.forEach((ele: any) => {
      url.push({ name: ele.url })
    })
    setUrl(url)
  }, [viewSpecialPriceKam])

  useEffect(() => {
    let quantitiyStore: any = []
    let responseData: any

    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.map((ele: any, i: any) => {
      return (
        <>
          {quantitiyStore.push({
            "uid": ele.uid,

            "nowRequestPriceType": ele && ele.nowRequestPriceType ? ele && ele.nowRequestPriceType : "",
            "nowRequestPrice": ele && ele.nowRequestPrice ? ele && ele.nowRequestPrice : "",
            "nowRequestCalculatedPrice": ele && ele.nowRequestCalculatedPrice ? ele && ele.nowRequestCalculatedPrice : "",

            "airRequestPriceType": ele && ele.airRequestPriceType ? ele && ele.airRequestPriceType : "",
            "airRequestPrice": ele && ele.airRequestPrice ? ele && ele.airRequestPrice : "",
            "airRequestCalculatedPrice": ele && ele.airRequestCalculatedPrice ? ele && ele.airRequestCalculatedPrice : "",

            "nowOfferedPriceType": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] && valueSelectPriceNow[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "nowOfferedPrice": valueSelectPriceNow && valueSelectPriceNow[i] && valueSelectPriceNow[i]["value"] === "By AED" ? valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["priceAED"]) : valuePriceNow && valuePriceNow[i] && (valuePriceNow[i]["pricePercent"]),
            "nowOfferedCalculatedPrice": valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] ? valueCalculatedNow && valueCalculatedNow[i] && valueCalculatedNow[i]["calcuatedPrice"] : "",

            "airOfferedPriceType": valueSelectPriceBo && valueSelectPriceBo[i] && valueSelectPriceBo[i]["value"] && valueSelectPriceBo[i]["value"] === "By AED" ? "byCurrency" : "byPercentage",
            "airOfferedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] ? valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoAED"] : valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"],
            "airOfferedCalculatedPrice": valuePriceBo && valuePriceBo[i] && valuePriceBo[i]["priceBoPercent"] ? valueCalculatedBo && valueCalculatedBo[i] && valueCalculatedBo[i]["calcuatedPriceBo"] : "",
          })}
        </>
      )
    })
    setProductData(quantitiyStore)
  }, [viewSpecialPriceKam])



  useEffect(() => {
    let len = viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.length;
    let item: any = []
    let isDiscountPart: any = []
    let total: any = 0
    let discountTotal: any = 0
    let commentTotal: any = viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.commentOnTotal && viewSpecialPriceKam.data.data.data.commentOnTotal && viewSpecialPriceKam.data.data.data.commentOnTotal.length ? viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.commentOnTotal.length : 0
    let offeredValue: any = ""
    for (let i = 0; i < len; i++) {
      item.push({ value: "By AED", priceAED: "", pricePercent: "", priceGreaterThanNow: "", errorNow: false, valueBo: "By AED", caluclatedPrice: "", calculatedPriceGreaterNow: "", priceBo: "", priceBoAED: "", priceBoPercent: "", priceGreaterThanBo: "", calcuatedPriceBo: "", errorBo: false, calculatedPriceGreaterThanBo: "" })
    }
    if (!isImport) {
      setValuePriceNow(item);
      setValueSelectPriceNow(item);
      setValueCalculatedNow(item);
      setValuePriceBo(item);
      setValueSelectPriceBo(item);
      setValueCalculatedBo(item);
    }
    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.forEach((ele: any) => {
      isDiscountPart.push(ele.isSpecialPriced);
      total += parseFloat(ele.nspev) * parseFloat((ele.nowAvailability)) + parseFloat(ele.aop) * parseFloat((ele.airAvailability))
      if (ele.isSpecialPriced == false) {
        discountTotal += parseFloat(ele.nspev) * parseFloat((ele.nowAvailability)) + parseFloat(ele.aop) * parseFloat((ele.airAvailability))
      }
    })
    setTotal(total)
    if (isDiscountPart && isDiscountPart.length > 0 && isDiscountPart.includes(true)) {
      setIsDiscountPricePart(true);
    }
    setDiscountTotal(discountTotal)
    setTotal(total)
    setTotalComment(commentTotal ? commentTotal : "")
    let RequestedTotal: any = 0
    let RequestedTotalParts: any = 0
    let RequestedTotalPartsTotal: any = 0
    if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.
      data.data.data && viewSpecialPriceKam.data.data.data.requestTotalPriceType === "By AED") {
      if (quantity || quantity === "") {
        RequestedTotal = (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestTotalPrice)
      }
    } else {
      if (quantity || quantity === "") {
        RequestedTotal = (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestTotalPriceCalculated)
      }

    }
    viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.forEach((ele2: any) => {
      if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalParts += (parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) * (ele2.airAvailability) : 0)
      } else if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byPercentage") {
        RequestedTotalParts += (parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) * (ele2.airAvailability) : 0)
      } else if (ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalParts += (parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) * (ele2.airAvailability) : 0)
      } else if (
        ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byPercentage"
      ) {
        RequestedTotalParts += (parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) * (ele2.nowAvailability) : 0) + (parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) * (ele2.airAvailability) : 0)
      }
    })
    viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.forEach((ele2: any) => {
      if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) : 0 + parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) : 0
      } else if (ele2.nowRequestPriceType == "byCurrency" && ele2.airRequestPriceType == "byPercentage") {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestPrice) ? parseFloat(ele2.nowRequestPrice) : 0 + parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) : 0
      } else if (ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byCurrency") {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) : 0 + parseFloat(ele2.airRequestPrice) ? parseFloat(ele2.airRequestPrice) : 0
      } else if (
        ele2.nowRequestPriceType == "byPercentage" && ele2.airRequestPriceType == "byPercentage"
      ) {
        RequestedTotalPartsTotal += parseFloat(ele2.nowRequestCalculatedPrice) ? parseFloat(ele2.nowRequestCalculatedPrice) : 0 + parseFloat(ele2.airRequestCalculatedPrice) ? parseFloat(ele2.airRequestCalculatedPrice) : 0
      }
    })
    setRequestedTotal(RequestedTotal)
    setRequestedTotalParts(RequestedTotalParts)
    setRequestedTotalPartsTotal(RequestedTotalPartsTotal ? RequestedTotalPartsTotal : 0);
    //Offered By Total
    setSelectByTotal(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency === "byPercentage" ? "By %" : "By AED")
    setOfferedTotalAED(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency !== "byPercentage" ? viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPrice : "")
    setOfferedTotalPercent(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency === "byPercentage" ? viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPrice : "")
    setTotalCalculatedTotal(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPriceCalculated)

    //Offered value
    if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType) {
      offeredValue = viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType
    } else {
      offeredValue = viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestedByType
    }
    if (offeredValue) {
      setOfferedValue(offeredValue)
    }

  }, [viewSpecialPriceKam])

  useEffect(() => {
    let sum: any = 0
    valuePriceNow.forEach((ele: any) => {
      sum += ele.priceAED ? parseFloat(ele.priceAED) : 0
    })
    valuePriceBo.forEach((ele: any) => {
      sum += ele.priceBoAED ? parseFloat(ele.priceBoAED) : 0
    })
    setTotalOfferedAED(sum ? sum : "")
  }, [valuePriceNow, valuePriceBo])


  // ON CHANGE OPEN CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  // ON CHANGE OPEN CLOSE - SUCCESS SNACKBAR
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  function handleopenClose() {
    setOpen(false)
  }
  function handleopenCloseTotal() {
    setOpenTotal(false)
  }
  function handleOpenCloseRejectPartDialog() {
    setOpenRejectPart(false)
  }

  function handleOpenSpecialPrice() {
    setOpenPriceRequest(false)
  }
  function handleJustification() {
    setOpenJustification(false)
  }

  function handleDocuments() {
    setOpenDocuments(false)
  }


  // FOR POST API CALL FOR CHECK AVALIABLITITY & PRICE
  const checkAvailibilityAndPriceAPI = async () => {
    setLoading(true)
    setDataUpdatedPrompt(false)
    const data = {
      "sessionDetails": {
        "webApplicationLoginId": webApplicationLoginId,
        "clientIPAddress": sessionStorage.getItem('clientIp'),
        "loginPassword": "",
        "applicationSecurityKey": "",
        "initialLogonIndicator": "",
        "initialPassword": ""
      },
      "sapCustomerId": sapCustomerIdData,
      "role": "kam"
    }
    let savedData: any = await dispatch(checkandavaliabilityApi(B2bEnquiryId, data));
    if (savedData && savedData.message && savedData.message.status === 'S') {
      setSuccessMessage(savedData && savedData.message && savedData.message.message);
      handleopenCloseSnackbar(true);
      setLoading(false)
    } else if (savedData && savedData.message && savedData.message.status === "E" || savedData.error) {
      showErrorMessage(savedData && savedData.error)
      handleopenCloseErrorSnackbar(true);
      setOpenErrorSnackbar(true);
      setLoading(false)
      setTimeout(() => {
        handleopenCloseErrorSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 2000)
    }
  }


  function TotalOfferedAed() {
    let sum: any = 0
    if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType) {
      if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType
        === "part") {
        viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.forEach((ele: any) => {
          if (!ele.isRejected) {
            sum += (ele.nowOfferedPriceType === "byPercentage" ? parseFloat(ele.nowOfferedCalculatedPrice) ? parseFloat(ele.nowOfferedCalculatedPrice) * (ele.nowAvailability) : 0 : parseFloat(ele.nowOfferedPrice) ? parseFloat(ele.nowOfferedPrice) * (ele.nowAvailability) : 0) + (ele.airOfferedPriceType === "byPercentage" ? parseFloat(ele.airOfferedCalculatedPrice) ? parseFloat(ele.airOfferedCalculatedPrice) * (ele.airAvailability) : 0 : parseFloat(ele.airOfferedPrice) ? parseFloat(ele.airOfferedPrice) * (ele.airAvailability) : 0)
          } else if (ele.nowRequestPrice && ele.isRejected) {
            sum += parseFloat(ele.nspev) * parseFloat(ele.nowAvailability)
          } else if (ele.airRequestPrice && ele.isRejected) {
            sum += parseFloat(ele.aop) * parseFloat(ele.airAvailability)
          }
        })
      } else if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType
        === "total") {
        sum += viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency === "By AED" ?
          parseFloat(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPrice) : parseFloat(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCalculated)
      }
    } else {
      if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestedByType
        === "part") {
        viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.forEach((ele: any) => {
          if (!ele.isRejected) {
            sum += ele.nowOfferedPriceType === "byPercentage" ? parseFloat(ele.nowOfferedCalculatedPrice) ? parseFloat(ele.nowOfferedCalculatedPrice) * (ele.nowAvailability) : 0 : parseFloat(ele.nowOfferedPrice) ? parseFloat(ele.nowOfferedPrice) * (ele.nowAvailability) : 0 + (ele.airOfferedPriceType
              === "byPercentage" ? parseFloat(ele.airOfferedCalculatedPrice) ? parseFloat(ele.airOfferedCalculatedPrice) * (ele.airAvailability) : 0 : parseFloat(ele.airOfferedPrice) ? parseFloat(ele.airOfferedPrice) * (ele.airAvailability) : 0)
          } else if (ele.nowRequestPrice && ele.isRejected) {
            sum += parseFloat(ele.nspev) * parseFloat(ele.nowAvailability)
          } else if (ele.airRequestPrice && ele.isRejected) {
            sum += parseFloat(ele.aop) * parseFloat(ele.airAvailability)
          }
        })
      } else if (viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestedByType
        === "total") {
        sum += viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency && viewSpecialPriceKam.data.data.data.offeredTotalPriceCurrency === "By AED" ? (parseFloat(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPrice)) : parseFloat(viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceCalculated)
      }
    }
    return sum ? parseFloat(sum).toFixed(2) : 0
  }


  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = null
  }

  const handleDownload = (e: any) => {
    if (url.length >= 1) {
      setOpenDocuments(true)
    } else {
      setOpenDocuments(false)
    }
    // window.removeEventListener('beforeunload', handleBeforeUnload)
    // url.forEach((file: any, i: any) => {
    //     const link = document.createElement('a');
    //     link.download = `image-${i}`
    //     link.href = file.name;
    //     document.body.appendChild(link);
    //     link.click()
    //     document.body.removeChild(link)

    // })
    // window.removeEventListener('beforeunload', handleBeforeUnload)

  }

  const isBluelegend = (ele: any) => {
    let airPrice: any = ele.airOfferedPriceType == "byCurrency" ? parseFloat(ele.airOfferedPrice) : parseFloat(ele.airOfferedCalculatedPrice)
    let nowPrice: any = ele.nowOfferedPriceType == "byCurrency" ? parseFloat(ele.nowOfferedPrice) : parseFloat(ele.nowOfferedCalculatedPrice)
    if (ele.nowAvailabilityChecked && ele.airAvailabilityChecked) {
      if (((parseFloat(ele.nspev)) > parseFloat(nowPrice)) || (parseFloat(ele.aop) > airPrice)) {
        return true;
      } else {
        return false;
      }
    } else if (ele.nowAvailabilityChecked) {
      if ((parseFloat(ele.nspev) > nowPrice)) {
        return true;
      } else {
        return false;
      }
    } else if (ele.airAvailabilityChecked) {
      if ((parseFloat(ele.aop) > airPrice)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  return (
    <div className="">
      {viewSpecialPriceKam && viewSpecialPriceKam.loading && <FullscreenLoader />}
      {checkandavailibilityData && checkandavailibilityData.loading && <FullscreenLoader />}

      <div className="mb-5 margin-bottom">
        <div className="">
          <div className="">
            <div className="">
              <div className="mb-4 row pl-3">
                <h2 className="page-title pr-3">
                  Special Price Request {'  '}
                </h2>
                <p className="pr-2 pl-2 margin-top-vertical">{viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.sapCustomerId

                }</p>
                <div className="pr-2 margin-top-vertical" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "25px", opacity: 0.3 }}>
                </div>
                <p className="pr-2 mt-1 margin-top-vertical">{viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.customerType
                }</p>
                <div className="pr-2 mt-1 margin-top-vertical" style={{ borderLeft: "1px solid rgba(0, 0, 0, 0.87)", height: "25px", opacity: 0.3 }}>
                </div>
                <p className="pr-2 margin-top-vertical">{
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.customerName
                }</p>
                <div className="ml-auto d-flex flex-row">
                  <div className="d-flex flex-row mt-3">
                    <div className="legendItemCreateEnquiry ml-3 mt-1 greenLegendMargin">
                      <span className="dot-blue"></span>
                    </div>
                    <span className="legendItemLabel mr-4 ml-2">Special Price</span>
                    <div className="legendItemspr ml-3 mt-1 greenLegendMargin">
                      <span className="dot-red"></span>
                    </div>
                    <span className="legendItemLabel mr-4 ml-2">Not approved / Not offered</span>
                    <div className="legendItemCreateEnquiry ml-3 mt-1 greenLegendMargin">
                      <span className="dot"></span>
                    </div>
                    <span className="legendItemLabel mr-4 ml-2">Discounted Price</span>
                  </div>
                  <NotificationIcon />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12" style={{ height: " !important" }}>
              <div className="d-flex align-items-center">
                <div className="dealer-table-content mb-0 pl-1"><h6 className="page-sub-title mb-0">
                  (Order No - {orderno && orderno.data && orderno.data.length > 0 ? orderno.data.map((o: any, i: any) => (<span>{o.orderNumber}{i === (orderno.data.length - 1) ? '' : ', '}</span>)) : <span className="text-danger">Order Not Placed</span>})
                </h6></div>
                <div className="dealer-table-content mb-0 pl-1"><h6 className="page-sub-title mb-0">
                </h6></div>
                <div className="dealer-table-content mb-0 px-2"><h6 className="page-sub-title mb-0">
                  (Enquiry Id-{viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.B2bEnquiryId})
                </h6></div>

                <div className="row-space-start ml-auto sm">
                  <IconButton size="small" className={classes.actionButtonEye} onClick={() => {
                    setOpenJustification(true)
                  }}
                  >
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                  <span className="pl-2 pr-2 fontTop viewJustificationHover" onClick={() => {
                    setOpenJustification(true)
                  }}>View Justification</span>
                  {viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.attachments.length >= 1 ?
                    <div>
                      <IconButton className="exportExcel" size="small" onClick={handleDownload} >
                        <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={handleDownload} />
                      </IconButton>
                      <span className="pl-2 pr-2 fontTop hover-cursor" onClick={handleDownload}>Download Proof</span>
                    </div>
                    :
                    <div>
                      <Tooltip title="This special price has no proof attached" placement="top">
                        <IconButton className="exportExcel" size="small" onClick={handleDownload} >
                          <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={handleDownload} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="This special price has no proof attached" placement="top">
                        <span className="pl-2 pr-2 fontTop hover-cursor" onClick={handleDownload}>Download Proof</span>
                      </Tooltip>
                    </div>
                  }
                  <Button variant="contained" color="primary" onClick={(e) => {
                    e.preventDefault();
                    history.push("specialpricekam");
                  }} className=" border-left rounded-pill">Back To List</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 ">
              <div className="d-flex align-items-center">
                <div className="row-space-start sm">
                  <div className="button-hover button-hover1 d-flex flex-column " style={{}}>
                    <label className="button-hover  button-hover1 import-excel-error d-flex flex-row pr-4  padding-excel">
                      <div className="pr-5 mr-5 import-excel-error margin_left"></div>
                      {excelError ? excelError : ""}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="cardCommon marginBottomviewSpecialPriceKam">
              <div className="enquiryPartsSpecialPrice " unselectable="on">
                <div className="customTableContainerSpecialPrice ">
                  <div className="tableRootSpecialPrice">
                    <thead className="tableHeadSpecialPrice">
                      <tr className="tSpecialPrice">
                        <th className="thSpecialPrice">
                          <h6 className="blueLabelTableSpecialPrice"># Part No.</h6>
                          <div className="greyLabelTable">
                            Part Name &amp; Desc
                          </div>
                        </th>
                        <th className="thSpecialPrice mw-100p">
                          <h6 className="blueLabelTableSpecialPrice">Quantity</h6>
                          <div className="row d-flex flex-row no-gutters">
                            <div className="col-3 greyLabelTable">Req </div>
                            <div className="col-3 greyLabelTable pl-2">NOW </div>
                            <div className="col-3 pl-4 greyLabelTable">BO</div>

                          </div>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444 mt-0 pt-0">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">Total ATP</h6>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice">NSP</h6><div className="greyLabelTable">(Excl. VAT)</div>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice">NOW Req Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice mw-10p font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">NOW Offered Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444 mt-0 pt-0">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">BOP</h6>
                        </th>
                        <th className="thSpecialPrice font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice">BO Req Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice mw-100p font-10 font-weight-700 text-444">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">BO Offered Price</h6><div className="greyLabelTable">(Per Qty)</div>
                        </th>
                        <th className="thSpecialPrice mw-160p font-10 font-weight-700 text-444 mt-0 pt-0">
                          <h6 className="blueLabelTableSpecialPrice mt-0 pt-0">Comment</h6>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.products.map((ele: any, i: any) => {
                        return (
                          <tr className={`${ele.isRejected === false ? "tableSpecialPrice" : "row-color-changes"}`}>
                            <td className="tdchildCreateEnquiry">
                              <div className="innergreyLabelTable position-relative pl-3">
                                <>
                                  {ele.isRejected ?
                                    <span className="dotlegendItemRedCreateEnquiry greenLegendMargin mt-13px"></span>
                                    :
                                    <span className="pr-3"></span>
                                  }
                                  {!ele.isSpecialPriced && (ele.nowRequestPrice || ele.airRequestPrice) && (
                                    <>
                                      {isBluelegend(ele) && !ele.isRejected ? (
                                        <span className="dotlegendItemBlueCreateEnquiry greenLegendMargin mt-13px"></span>
                                      ) : (
                                        <>
                                          {!ele.isRejected && (
                                            <span className=" dotlegendItemRedCreateEnquiry greenLegendMargin mt-13px"></span>
                                          )}
                                        </>
                                      )}
                                    </>
                                  )}
                                  {ele.isSpecialPriced ?
                                    <span className="dotlegendItemCreateEnquiry greenLegendMargin"></span>
                                    :
                                    <span className="pr-3"></span>
                                  }
                                </>
                              </div>
                              <div className="innergreyLabelTable position-relative pl-3">
                                {ele.productId}
                              </div>
                              <div className="greyLabelTable partNumberYextTable pl-3">
                                {ele.productIdDescription}
                              </div>
                            </td>

                            <td className="tdchildSpecialPrice"><div className="dealer-table-contentSpecialPrice d-flex flex-row ">
                              <div className="pl-0 ml-0" style={{ minWidth: "35px" }}>{ele.quantity
                              }</div>
                              <div className="" style={{ minWidth: "38px" }}>{ele.nowAvailability
                              }</div>
                              <div className="mw-50p">{ele.airAvailability}</div>
                            </div></td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">
                              <div className="dealer-table-contentSpecialPrice d-flex flex-row ">
                                <div className="pl-3 ml-0" style={{ minWidth: "35px" }}>{parseInt(nowAndBoValue && nowAndBoValue[i] && nowAndBoValue[i].nowAvaliabilityValue || "0").toFixed(0)}</div>
                                {/* <div className="" style={{ minWidth: "38px" }}>{parseInt(nowAndBoValue && nowAndBoValue[i] && nowAndBoValue[i].boAvaliabilityValue || "0").toFixed(0)}</div> */}
                              </div>
                            </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">{ele.nspev}</td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">
                              <div className="d-flex flex-row">
                                <div>{ele.nowRequestPriceType == "byCurrency" ? `${ele.nowRequestPrice}` : `${ele.nowRequestCalculatedPrice}`} </div>
                                <div className="pl-2 requestedPriceColor">{ele.nowRequestPriceType == "byCurrency" ? `${ele.nowRequestCalculatedPrice ? "(-" : ""}${ele.nowRequestCalculatedPrice}${ele.nowRequestCalculatedPrice ? ")" : ""}` : `${ele.nowRequestPrice ? "(-" : ""}${ele.nowRequestPrice}${ele.nowRequestCalculatedPrice ? "%)" : ""}`} </div>
                              </div>
                            </td>

                            <td className="tdchildSpecialPrice">
                              <div className="">
                                <div className=" mb-2 pr-0 d-flex flex-row " style={{ position: "relative" }}>
                                  <div className="mt-2">
                                    {ele.nowOfferedPriceType === "byPercentage" ? ele.nowOfferedCalculatedPrice : ele.nowOfferedPrice}
                                  </div>
                                  <div className="pl-2 mt-2 requestedPriceColor">{ele.nowOfferedPriceType == "byCurrency" ? `${ele.nowOfferedCalculatedPrice ? "(-" : ""}${ele.nowOfferedCalculatedPrice}${ele.nowOfferedCalculatedPrice ? ")" : ""}` : `${ele.nowOfferedPrice ? "(-" : ""}${ele.nowOfferedPrice}${ele.nowOfferedCalculatedPrice ? "%)" : ""}`} </div>
                                </div>

                              </div>
                            </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice">{ele.aop} </td>
                            <td className="tdchildSpecialPrice dealer-table-contentSpecialPrice"><div className="d-flex flex-row">
                              <div>{ele.airRequestPriceType == "byCurrency" ? `${ele.airRequestPrice}` : `${ele.airRequestCalculatedPrice}`}</div>
                              <div className="pl-2 requestedPriceColor">{ele.airRequestPriceType == "byCurrency" ? `${ele.airRequestCalculatedPrice ? "(" : ""}${ele.airRequestCalculatedPrice}${ele.airRequestCalculatedPrice ? ")" : ""}` : `${ele.airRequestPrice ? "(-" : ""}${ele.airRequestPrice}${ele.airRequestCalculatedPrice ? "%)" : ""}`}</div>
                            </div></td>

                            <td className="tdchildSpecialPrice">
                              <div>
                                <div className=" mb-2 pr-0 d-flex flex-row " style={{ position: "relative" }}>
                                  <div className="mt-2">
                                    {ele.airOfferedPriceType === "byPercentage" ? ele.airOfferedCalculatedPrice : ele.airOfferedPrice}
                                  </div>
                                  <div className="pl-2 mt-2 requestedPriceColor">{ele.airOfferedPriceType == "byCurrency" ? `${ele.airOfferedCalculatedPrice ? "(-" : ""}${ele.airOfferedCalculatedPrice}${ele.airOfferedCalculatedPrice ? ")" : ""}` : `${ele.airOfferedPrice ? "(-" : ""}${ele.airOfferedPrice}${ele.airOfferedCalculatedPrice ? "%)" : ""}`} </div>
                                </div>
                                {(valuePriceBo && valuePriceBo[i] && valuePriceBo[i] && valuePriceBo[i]["errorBo"]) === true && <p className="errorPrice">Offered Price Cannot Greater Than Net Price</p>}
                              </div></td>
                            <td className="tdchildCreateEnquiry"> <IconButton
                              aria-controls="customized-menu"
                              aria-haspopup="true"
                              onClick={() => {
                                setOpen(true)
                                setId(viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.id)
                                setUid(ele.uid)
                                // dispatch(addComment( viewSpecialPriceKam &&viewSpecialPriceKam.data.data&&viewSpecialPriceKam.data.data.data))
                                dispatch(getCommentPart(viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.id, ele.uid, viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.sapCustomerId, 'kam'))
                                setSapId(viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.sapCustomerId)

                              }}>
                              <Badge
                                badgeContent={ele.unreadCommentCounts ? ele.unreadCommentCounts : ele.totalCommentCount
                                }
                                color="error"
                                className={ele.unreadCommentCounts ? "backgroundRed" : "backgroundGrey"}>
                                <QuestionAnswerOutlinedIcon className={classes.actionButtonComment} />
                              </Badge>

                            </IconButton></td>
                          </tr>)
                      })
                      }
                    </tbody>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-12 col-sm-12 d-flex borderBottomViewPrice">
                <div className="row pb-3 height-bottom col-12">
                  <div className="col-lg-4 col-md-4 col-sm-6 pb-0 pt-3 pl-3 pr-3 d-flex flex-row">
                    <p className="fontBottomHeader">
                      Estimated Total (excl. of VAT):
                    </p>
                    <p className="fontBottomBold pl-1">
                      AED {parseFloat(total).toFixed(2)}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 p-3 d-flex flex-row">
                    <p className="fontBottomHeader">
                      Requested Total
                    </p>
                    <p className="fontBottomBold pl-1" >
                      : {offeredBy === "Parts" ? requestedTotalParts ? parseFloat(requestedTotalParts).toFixed(2) : (parseFloat(requestedTotal).toFixed(2)) : (requestedTotal) ? parseFloat(requestedTotal).toFixed(2) : parseFloat(requestedTotalParts).toFixed(2)}
                    </p>
                  </div>
                  {offeredValue
                    === "part" ?
                    <div className="col-lg-3 col-md-3 col-sm-6 p-3  d-flex flex-row mltotal">
                      <p className="fontBottomHeader pl-1">
                        Offered Total
                      </p>
                      <p className="pl-1 fontBottomBold">
                        : {TotalOfferedAed()}
                      </p>
                    </div>
                    :
                    // Total Text Field

                    <div className="">
                      <div className=" mb-2 pr-0  d-flex flex-row " style={{ position: "relative" }}>
                        <div className=" pt-3 pb-3 d-flex flex-row mltotal">
                          <p className="fontBottomHeader pl-1">
                            Offered Total
                          </p>
                          <p className="pl-1 fontBottomBold">
                            : {TotalOfferedAed()}
                          </p>
                        </div>
                        <div className="mt-1 pl-5">
                          <IconButton
                            className=""
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            onClick={() => {
                              setOpenTotal(true)
                              setId(viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.id && viewSpecialPriceKam.data.data.data.id)
                              dispatch(getCommentTotalKam(viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.id, viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.sapCustomerId
                              ))
                              setSapId(viewSpecialPriceKam && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data.sapCustomerId)
                            }}>
                            <Badge
                              badgeContent={viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.commentCountOnTotal ? viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.commentCountOnTotal : totalComment
                              }
                              color="error"
                              className={viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.commentCountOnTotal
                                ? "backgroundRed" : totalComment ? "backgroundGrey" : viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.commentCountOnTotal == 0 ? "backgroundNone" : ""}>
                              <QuestionAnswerOutlinedIcon className={classes.actionButtonComment} />
                            </Badge>
                          </IconButton>
                        </div>



                      </div>
                      {totalError === true && <div className="errorPrice">Please Enter Correct Price</div>}
                    </div>
                  }
                </div>
              </div>
              <div className="col-12 col-lg-12 col-sm-12 d-flex mt-n3">
                {isDiscountPricePart && (
                  <div className="row tableBottom col-12">
                    <div className="pt-0 pl-3 d-flex flex-row total-margin">
                      <p className="fontBottomHeader">
                        Estimated Total (Excluding Discounted Parts) :
                      </p>
                      <p className="fontBottomBold pl-1">
                        AED {parseFloat(discountTotal).toFixed(2)}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {open && <ViewWriteComments open={open}
            openRequestSnackbar={openSnackbar}
            showErrorMessage={showErrorMessage}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
            setSuccessMessage={setSuccessMessage}
            handleopenClose={handleopenClose} id={id} uid={uid} sapId={sapId} />}
          {openTotal && <ViewWriteCommentsTotal
            openRequestSnackbar={openSnackbar}
            showErrorMessage={showErrorMessage}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
            setSuccessMessage={setSuccessMessage}
            openTotal={openTotal} handleopenCloseTotal={handleopenCloseTotal} id={id} uid={uid} sapId={sapId} />}
          {openRejectPart && <RejectPartDialog openRejectPart={openRejectPart} handleOpenCloseRejectPartDialog={handleOpenCloseRejectPartDialog} />}
          {openPriceRequest && <RejectPriceRequestDialog openPriceRequest={openPriceRequest} handleOpenSpecialPrice={handleOpenSpecialPrice} />}
          {openJustfication && <ViewJustification openJustfication={openJustfication} handleJustification={handleJustification} />}
        </div>
        {/* Reject dialog for cancelling the part */}
      </div>
      <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280 " : "row card card-body p-2 fixed-bottom ml-70"} style={{ zIndex: 5, overflow: "hidden" }}>
        <div className="col-lg-12 d-flex mt-2 p-0">
          <div className="col-lg-6 d-flex flex-row">
            <div className="d-flex flex-column ml-5">
              <p className="fontCommonSpecialPrice">Status</p>
              <p className="fontBottomBold">{viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status}</p>
            </div>
            <div className="d-flex flex-column ml-5">
              <p className="fontCommonSpecialPrice">Requested By</p>
              <p className="fontBottomBold">{viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestedByType
                === "part" ? "Parts" : "Total"}</p>
            </div>
            <div className="d-flex flex-column ml-5">
              <p className="fontCommonSpecialPrice">Offered By</p>
              <p className="fontBottomBold">{viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType ?
                viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.offeredTotalPriceType === "part" ? "Parts" : "Total" : viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.requestedByType === "part" ? "Parts" : "Total"}</p>
              <p>
                {/* <TextField select id="Offered By"
                                value={offeredBy}
                                label="" variant="outlined" margin="dense"
                                size="small" className="col-sm-12 my-2 commonRoundedInputs hiddenNo" type="number"
                                onChange={(e: any) => {
                                    setOfferedBy(e.target.value);
                                }}
                            >
                                {["Parts", "Total"].map((ele: any) => {
                                    return (
                                        <MenuItem value={ele}>{ele}</MenuItem>
                                    )
                                })}
                            </TextField> */}
              </p>
            </div>
          </div>
          <div className="col-lg-6 onboard-footer">
            <div className="">
              <Button onClick={() => { checkAvailibilityAndPriceAPI() }} style={{ border: "" }} className={`actionButtonBottom  rounded-pill ml-2 mb-2 ${''}`}>Check Availability</Button>
              <Button
                disabled={
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Rejected" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Approved" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Cancelled" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Timed Out"
                }
                variant="contained"
                onClick={async () => {
                  setOpenRequestion(true)
                }}
                className={`backgroundRequestCorrection backgroundRequestCorrection border-left rounded-pill ml-4 mb-2`} >Request Correction</Button>
              <Button
                disabled={
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Rejected" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Approved" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Cancelled" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Timed Out" ||
                  viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.status && viewSpecialPriceKam.data.data.data.status === "Corrections"
                }
                variant="contained"
                onClick={async () => {
                  setshowApproveDialog(true);
                }}
                color="primary" className={`blueActionButton border-left rounded-pill ml-4 mb-2`} >Send to Customer</Button>
            </div>
          </div>
        </div>
      </div>
      <Prompt when={dataUpdatedPrompt} message={"Do you wish to proceed without saving the data?"}></Prompt>
      <Snackbar
        open={openSnackbar}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} message={successMessage} />
      <Snackbar
        open={openErrorSnackbar}
        type="error"
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={errorMessage} />
      <SentToCustomerDialog
        open={showApproveDialog}
        openRequestSnackbar={openSnackbar}
        showErrorMessage={showErrorMessage}
        setSuccessMessage={setSuccessMessage}
        handleopenCloseSnackbar={handleopenCloseSnackbar}
        handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar}
        handleopenClose={(data: any) => {
          setshowApproveDialog(data ? true : false)
        }}
      />
      <RequestCorrectionKam handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} handleopenCloseErrorSnackbar={handleopenCloseErrorSnackbar} showErrorMessage={showErrorMessage} openRequesCorrection={openRequesCorrection} handleOpenCorrection={handleOpenCorrection} id={viewSpecialPriceKam && viewSpecialPriceKam.data && viewSpecialPriceKam.data.data && viewSpecialPriceKam.data.data.data && viewSpecialPriceKam.data.data.data.id} />
      <Documents openDocuments={openDocuments} handleDocuments={handleDocuments} />
    </div >
  )
}

export default ViewPriceRequestKam;