import React from 'react';
import logoicon from "../../assets/mainLogoBlue.png";
import Button from '@material-ui/core/Button';


const ExpiredPage: React.FC = () => {

    return (
    //     <Fragment>
    //     <div className="center-alignment mt-5">
    //         <img src={logoicon} alt="Logo" className="h-40 mt-1" />
    //     </div>
    //     <div className="center-alignment mt-4">
    //         <div className="expired-error">504</div>
    //     </div>
    //     <div className="center-alignment mt-1">
    //         <div className="expired-link-message">Your link got expired</div>
    //     </div>
    //     <div className="center-alignment mt-3">
    //         <div className="expired-link-desc">Hi, Your login link has expired, because you haven't used it. Login link expired in every 24hrs and can be used once.</div>
    //     </div>
    //     <div className="center-alignment mt-1">
    //         <div className="expired-link-desc">You can create one by clicking on Request button too.</div>
    //     </div>
    //     <div className="center-alignment mt-4">
    //         <Button
    //             size="small"
    //             color="primary"
    //             variant="contained"
    //             className="rounded-pill go-to-home-button font-size-11"
    //             onClick={() => {history.push('/') }}
    //             >
    //             Go to home
    //         </Button>
    //     </div>
    // </Fragment>
        <div style={{ width: '100vw', height: '100vh' }}>
        {/* Al-Futtaim Logo */}
        <div className="center-alignment mt-5">
            <img src={logoicon} alt="Logo" className="h-40 mt-1" />
        </div>
        {/* Error Icon */}
        <div className="center-alignment mt-4">
           <div className="expired-error">504</div>
        </div>
        {/* Body Message */}
        <div className="center-alignment">
            <div className="expired-link-message">There has been an error.</div>
        </div>
        <div className="center-alignment mt-3">
            <div className="expired-link-desc">Click below to go to the home page.</div>
        </div>
        {/* Button */}
        <div className="center-alignment mt-4">
            <Button
                size="small"
                color="primary"
                variant="contained"
                className="rounded-pill go-to-home-button font-size-11"
                onClick={() => window.location.reload()}
            >
                Home
            </Button>
        </div>
    </div>
    )
}

export default ExpiredPage
