/* eslint-disable */
import React, { Fragment, Dispatch, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import AnnouncementIcon from '../../common/components/AnnouncementIcon';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import UsersParameterDialog from './UsersParameterDialog';
import { getAllUsers, getShipToParty, getUserDetails, getUserParameters, resendInvitationLink, sendEmail, setUserDetails } from '../../store/actions/dealer.action';
import Snackbar from '../../common/components/CreatedEnquirySnackbar'
import Loading from '../../common/components/loading';
import SearchBar from '../favorites/searchBar';
import NotificationIcon from '../../common/components/NotificationIcon';
import moment from "moment-timezone";
import { API_ENDPOINTS } from '../../constants/apiConfig';
import { Visibility } from '@material-ui/icons';
import AddUserDialog from '../OrgSetting/AddUserDialog';


const DealerUsers: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  const [openParamDialog, setOpenParamDialog] = useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState("");
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openAddUser, setOpenAddUser] = React.useState(false);
  const [inProgress, setInProgress] = React.useState(false);
  const [isUserDialogForEdit, setIsUserDialogForEdit] = React.useState(false);
  const [isUserDialogForView, setIsUserDialogForView] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [isModifyParameter, setIsModifyParameter] = React.useState<any>(false);
  const [shipToPartyFeatureAccess, setShipToPartyFeatureAccess] = React.useState(false);
  const [page, setPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const { dealerDetails, userDetails, usersList, addParamInfo, resendInvitation, editParamInfo }: any = useSelector((state: any) => state.dealer);
  const userType = sessionStorage.getItem('userType');
  const [sapCustomerId, setSapCustomerId] = React.useState<any>();

  useEffect(() => {
    if (addParamInfo && addParamInfo.data) {
      setOpenParamDialog(false);
      if (addParamInfo && addParamInfo.data && addParamInfo.data.message) {
        setSuccessMessage(addParamInfo.data.message.message);
      } else {
        setSuccessMessage('Parameters added successfully.');
      }
      dispatch(getAllUsers(userDetails.sapCustomerId, page, searchValue, isModifyParameter));
      handleopenCloseSnackbar(true);
    }

  }, [addParamInfo, userDetails, dispatch]);

  const handleopenCloseAddUser = (open?: any) => {
    setOpenAddUser(open ? true : false);
  };

  const emailLinkOnResendInvitation = (createDealerUser: any) => {
    if (createDealerUser && createDealerUser.data) {
      if (createDealerUser.data.sendLink) {
        const data = {
          "fromAddress": "tradepoint@alfuttaim.com",
          "toAddress": createDealerUser.data.email,
          "subject": "Account Verification and Self-Registration",
          "senderName": "Auto B2B",
          "templateName": "B2B_CUSTOMER_INVITE",
          "templateData": {
            "DateOfMail": moment(new Date()).format("DD/MM/YYYY"),
            "Url": `${API_ENDPOINTS.ON_BOARDING_URL}/signup?b2bUserId=${createDealerUser.data.b2bUserId}`,
            "CustomerName": "",
            "Name": `${createDealerUser.data.firstName} ${createDealerUser.data.lastName}`
          }
        }
        dispatch(sendEmail(data));
      }
    }
  }

  useEffect(() => {
    if (resendInvitation && resendInvitation.data && resendInvitation.data.message && resendInvitation.data.message.status === 'S') {
      setSuccessMessage('Invitation link send successfully.');
      handleopenCloseSnackbar(true);
      emailLinkOnResendInvitation(resendInvitation.data);
    }
  }, [resendInvitation]) // eslint-disable-line

  useEffect(() => {
    if (searchValue === "" && !openAddUser) {
      setPage(0);
      setIsModifyParameter(true);
      if (dealerDetails && dealerDetails.data && dealerDetails.data.data && dealerDetails.data.data)
        dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, page, searchValue, isModifyParameter));
    }
  }, [searchValue, dispatch, dealerDetails, openAddUser]);

  useEffect(() => {
    if (usersList && usersList.data && usersList.data.data && usersList.data.data.customerDetails) {
      setShipToPartyFeatureAccess(usersList.data.data.customerDetails.shipToPartyFeatureAccess);
    }
  }, [usersList]);

  useEffect(() => {
    if (editParamInfo && editParamInfo.data) {
      setOpenParamDialog(false);
      if (editParamInfo && editParamInfo.data && editParamInfo.data.message) {
        setSuccessMessage(editParamInfo.data.message.message);
      } else {
        setSuccessMessage('Parameters updated successfully.');
      }
      dispatch(getAllUsers(userDetails.sapCustomerId, page, searchValue, isModifyParameter));
      handleopenCloseSnackbar(true);
    }
  }, [editParamInfo, dispatch, userDetails, page]);

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleChangePage = (newPage: number) => {
    if (searchValue.trim() === "") {
      dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, newPage, searchValue, isModifyParameter));
    }
    else {
      dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, newPage, searchValue.trim(), isModifyParameter));
    }
    setPage(newPage);
  };

  return (
    <Fragment>
      <Snackbar
        open={openSnackbar}
        message={successMessage}
        handleopenClose={() => {
          handleopenCloseSnackbar(false)
        }} />
      <UsersParameterDialog open={openParamDialog} onClose={() => setOpenParamDialog(false)} shipToPartyFeatureAccess={shipToPartyFeatureAccess} />
      <div className="d-flex align-items-center">
        <h2 className="page-title mb-2">
          Users</h2>
        {/* <Breadcrumbs aria-label="breadcrumb" className="ml-3">
                    Users
                    
                    <Breadcrumbs aria-label="breadcrumb" className="mb-3">
                        <Link color="inherit" href="/"
                            onClick={(e: any) => {
                                e.preventDefault();
                                history.push('/dealers');
                            }}>
                            Dealers
                        </Link>
                        <Typography color="textPrimary">Users</Typography>
                    </Breadcrumbs>
                 */}
        {userType !== 'afg_super_admin' && (
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <div className="page-sub-title mt-2">Users List -  {usersList && usersList.data && usersList.data.data && usersList.data.data.customerDetails && usersList.data.data.customerDetails.companyTitle}{' '}{usersList && usersList.data && usersList.data.data && usersList.data.data.customerDetails && usersList.data.data.customerDetails.companyName}</div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="float-right">
            <Grid container>
              <Grid item className="pt-2 pr-2">
                <Link href="/" className="dealer-company-name mr-2"
                  onClick={(e: any) => {
                    e.preventDefault();
                    // eslint-disable-next-line no-lone-blocks
                    { userType === 'afg_super_admin' ? history.push('/superadmindealers') : history.push('/dealers') }
                  }}>
                  <KeyboardArrowLeftIcon />
                  Back to Customer List
                </Link>
              </Grid>
              <Grid item>
                <SearchBar callAPI={() => {
                  dispatch(getAllUsers(dealerDetails.data.data.sapCustomerId, 0, searchValue, isModifyParameter));
                  setPage(0);
                }} value={searchValue} onChange={(e: any) => setSearchValue(e.target.value)} label="Login ID/Internal ID (for AFG use)" className="pl-2" />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} className="mt-2 cardCommon">
          <TableContainer component={Paper} className="height-500">
            <Table aria-label="dealer table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <div className="dealer-table-title">Name</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">User Type</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Mobile</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Email ID</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Internal ID (for AFG use)</div>
                  </TableCell>
                  <TableCell>
                    <div className="dealer-table-title">Login ID</div>
                  </TableCell>
                  <TableCell><div className="dealer-table-title">Status</div></TableCell>
                  <TableCell><div className="dealer-table-title">Action</div></TableCell>
                  {userType !== 'afg_super_admin' && sessionStorage.getItem('userType') !== 'FSE' && (
                    <TableCell><div className="dealer-table-title">Invitation Link</div></TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {usersList && usersList.data && usersList.data.data && usersList.data.data.userList && usersList.data.data.userList.length > 0 && usersList.data.data.userList.map((user: any) => (
                  <TableRow key={user.webApplicationLoginId}>
                    <TableCell className="dealer-table-content">{user.firstName}{' '}{user.lastName}</TableCell>
                    <TableCell className="dealer-table-content">{user.userType}</TableCell>
                    <TableCell className="dealer-table-content">{user.firstTelephoneNumber ? user.firstTelephoneNumber : '-'}</TableCell>
                    <TableCell className="dealer-table-content">{user.email}</TableCell>
                    <TableCell className="dealer-table-content">{user.webApplicationLoginId}</TableCell>
                    <TableCell className="dealer-table-content">{user.b2cUserId}</TableCell>
                    {/* <TableCell className="dealer-table-content">
                        {user && user.organizationIds && user.organizationIds.length > 0 && user.organizationIds.map((org: any, index: any) => (
                          <React.Fragment key={index}>{org.make}{user.organizationIds.length === index + 1 ? '' : ','}</React.Fragment>
                        ))}
                      </TableCell> */}
                    <TableCell className="dealer-table-content mw-100p">{user.status}</TableCell>
                    <TableCell className="mw-240p">
                      {/* ToDO - Change the condition based on data from api */}
                      {user.status === 'In Progress' && userType !== 'afg_super_admin' && sessionStorage.getItem('userType') !== 'FSE' && (
                        <Button className="text-info btn-link" startIcon={<AddIcon />}
                          onClick={() => {
                            setOpenParamDialog(true);
                            dispatch(setUserDetails(user));
                            dispatch(getUserParameters(user.sapCustomerId, user.b2bUserId));
                            if (usersList && usersList.data && usersList.data.data && usersList.data.data.customerDetails && usersList.data.data.customerDetails.shipToPartyFeatureAccess) {
                              dispatch(getShipToParty(user.sapCustomerId, user.b2bUserId))
                            }
                          }}
                        >Add Parameter</Button>
                      )}
                      {user.status === 'Active' && userType !== 'afg_super_admin' && sessionStorage.getItem('userType') !== 'FSE' &&
                        (
                          <Button className="text-info btn-link" startIcon={<EditOutlinedIcon />}
                            onClick={() => {
                              setOpenParamDialog(true);
                              dispatch(setUserDetails(user));
                              dispatch(getUserParameters(user.sapCustomerId, user.b2bUserId));
                              if (usersList && usersList.data && usersList.data.data && usersList.data.data.customerDetails && usersList.data.data.customerDetails.shipToPartyFeatureAccess) {
                                dispatch(getShipToParty(user.sapCustomerId, user.b2bUserId))
                              }
                            }}
                          >{'Modify Parameter'}</Button>
                        )}
                      <Button className="text-info btn-link ml-3" startIcon={<Visibility />}
                        onClick={() => {
                          setSelectedUserId(user.b2bUserId);
                          setSapCustomerId(user.sapCustomerId);
                          setIsUserDialogForEdit(true);
                          setIsUserDialogForView(true);
                          if (user.status.toLowerCase() === "in progress") {
                            setInProgress(true);
                          }
                          else {
                            setInProgress(false);
                          }
                          dispatch(getUserDetails(user.b2bUserId));
                          dispatch(setUserDetails(user));
                          handleopenCloseAddUser(true);
                        }}
                      >View</Button>
                    </TableCell>
                    {userType !== 'afg_super_admin' && sessionStorage.getItem('userType') !== 'FSE' && (
                      <TableCell>
                        {user.registrationStatus === 'inProgress' && user.parameterAdded === true ? (
                          <Button className="text-info btn-link text-underline"
                            onClick={() => { dispatch(resendInvitationLink(user.b2bUserId)) }}
                          >Resend</Button>
                        ) : ''}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {usersList && usersList.loading && (
                  <TableRow><td><Loading /></td></TableRow>
                )}
              </TableBody>
            </Table>
            {usersList && usersList.data && usersList.data.data && usersList.data.data.userList && usersList.data.data.userList.length === 0 && (
              <div className="my-5 mx-auto text-center">
                No users found.
              </div>
            )}
            {<div className="col-12 d-flex justify-content-end">
              <TablePagination
                rowsPerPageOptions={[10]}
                component="div"
                count={usersList && usersList.data && usersList.data.data && usersList.data.data.totaCount ? usersList.data.data.totaCount : 0}
                rowsPerPage={10}
                page={page}
                onPageChange={(e: any, newPage: number) => { handleChangePage(newPage); }}
              />
            </div>}
            {openAddUser &&
              <AddUserDialog
                open={openAddUser}
                isEdit={isUserDialogForEdit}
                setIsEdit={setIsUserDialogForEdit}
                isView={isUserDialogForView}
                setIsView={setIsUserDialogForView}
                setInProgress={setInProgress}
                setSelectedUserId={setSelectedUserId}
                page={page}
                inProgress={inProgress}
                sapCustomerId={sapCustomerId}
                isAdmin={true}
                selectedUserId={selectedUserId}
                handleopenClose={(data: any) => {
                  if (data) {
                    handleopenCloseSnackbar(true)
                  }
                  handleopenCloseAddUser(false)
                }}
              />
            }
          </TableContainer>
        </Grid>
      </Grid>

    </Fragment>
  )
}

export default DealerUsers
