/* eslint-disable */
import React, { Fragment, Dispatch } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CancelIcon from '@material-ui/icons/Cancel';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import { get } from 'lodash';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import FullscreenLoader from '../../common/components/FullscreenLoader';
import { useEffect } from "react";
import { getSpecialPrice, postForwardRequestToKam, postSaveFormData, rejectPart, viewSpecialPriceAPI } from "../../store/actions/specialprice.actions";
import WarningIcon from "@material-ui/icons/Warning";



const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 120,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    rejectText: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    },
    logoutText: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
      },
}));

export default function RejectPartDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const { openRequestSnackbar, setOpenRejectDialog } = props;
    const [loading, setLoading] = React.useState<boolean>(false);
    const { viewSpecialPrice, rejectRequestData, rejectPartData } = useSelector((state: any) => state.specialPrice)
    const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
    const [responseIDData, setResponseIDData] = React.useState<any>();
    const [sapId, setSapId] = React.useState<any>();
    // const [UID, setUID] = React.useState<any>();
    const [uidDetailsId, setUidDetailsId] = React.useState<Array<any>>([]);
    const [textRejectPart, setRejectPart] = React.useState<any>('');
    const [textRejectPartError, setTextRejectPartError] = React.useState<boolean>(false);
    const [textRejectPartErrorMessage, setTextRejectPartErrorMessage] = React.useState<boolean>(false);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const sapCustomerId = get(profileDetails, 'data.data.basicInfo.sapCustomerId', "");
    const userName = (profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo.firstName + " " + profileDetails.data.data.basicInfo.lastName) || "";
    
    useEffect(() => {
        let responseID: any
        let responseSapId: any

        viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data &&
            viewSpecialPrice.data.data.data && (
                <>
                    {responseID = viewSpecialPrice?.data?.data?.data?.id}
                    {responseSapId = viewSpecialPrice?.data?.data?.data?.sapCustomerId}

                </>
            )
        setResponseIDData(responseID)
        setSapId(responseSapId)
    }, [])

    // useEffect(() => {
    //     let uidData: any
    //     viewSpecialPrice && viewSpecialPrice.data && viewSpecialPrice.data.data && viewSpecialPrice.data.data.data && viewSpecialPrice.data.data.data.products.map((ele: any, i: any) => {
    //         return (
    //             <>
    //                 {uidData = ele.uid}
    //             </>
    //         )
    //     })
    //     setUID(uidData)
    // }, [viewSpecialPrice])

    // ONCHANGE OF TEXTAREA-AUTOSIZE 
    const handleCustomerChange = (e: any) => {
        setRejectPart(e.target.value);
        if (e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "") {
                setTextRejectPartError(true);
            }
            else {
                setTextRejectPartError(false);
            }
        }
    }

    // VALIDATION FOR TEXTAREA OF REJECT MESSAGE 
    function validateRejectMessage() {
        let isValid: boolean = true;
        if (!textRejectPart || textRejectPart.trim() == "") {
            setTextRejectPartError(true);
            isValid = false;
            return
        } else {
            if (textRejectPart.split('').length > 250) {
                setTextRejectPartError(false);
                setTextRejectPartErrorMessage(true);
                isValid = false;
                return
            }
            else {
                setTextRejectPartError(false);
                setTextRejectPartErrorMessage(false);
            }
        }
        if (isValid) {
            rejectMessageAPI();
            setRejectPart(null);
        }
    }

    const handeleViewSpecialPrice = async (id: any, name: "pricing_team") => {
        setLoading(true);
        let data: any = await dispatch(viewSpecialPriceAPI(responseIDData, name))

        if (data.message.status === "E") {
            setLoading(false);
            props.setErrorSnackBar(true)
            props.setErrorMessage(data && data.message && data.message.message)
        } else {
            history.push('/viewspecialprice');
            setLoading(false);
        }
    }

    // FOR REJECT MESSAGE API
    const rejectMessageAPI = async () => {
        props.setDataUpdatedPrompt(false)
        setLoading(true)
        let data =
        {
            "requestId": responseIDData,
            "rejectionType": "bypart",
            "sapCustomerId": sapId,
            "uid": props.UID,
            "rejectedBy": webApplicationLoginId,
            "rejectedByName": userName,
            "rejectReson": textRejectPart,
            "rejectedByRole": sessionStorage.getItem('userType')
        }
        let savedData: any = await dispatch(rejectPart(data));
        if (savedData && savedData.message && savedData.message.status === 'S') {
            props.handleopenCloseErrorSnackbar(false);
            props.handleopenCloseSnackbar(true);
            props.setSuccessMessage(savedData && savedData.message && savedData.message.message);
            props.handleOpenCloseRejectPartDialog();
            setTextRejectPartErrorMessage(false);
            setTextRejectPartError(false);
            setLoading(false);
            setRejectPart(null);
            props.setRemoveValidationPopup(true);
            handeleViewSpecialPrice(responseIDData, "pricing_team");
            history.push('/viewspecialprice');
        } else if (savedData && savedData.message && savedData.message.status === "E") {
            props.handleopenCloseErrorSnackbar(true);
            props.showErrorMessage(savedData && savedData.error);
            setTimeout(() => {
                props.handleOpenCloseRejectPartDialog();
                setTextRejectPartErrorMessage(false);
                props.setRemoveValidationPopup(true);
                props.handleopenCloseSnackbar(false);
                setTextRejectPartError(false);
                setLoading(false);
                setRejectPart(null);
            }, 2000)
        }
    }


    return (
        <Fragment>
            {rejectRequestData && rejectRequestData.loading && <FullscreenLoader />}
            {viewSpecialPrice && viewSpecialPrice.loading && <FullscreenLoader />}
            <Dialog
                open={props.openRejectPart}
                // onClose={() => { props.handleopenClose(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}

            >
                <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    handleopenClose={() => { }} message={props.successMessage}
                />
                <DialogContent>
                    <div className={classes.root}>
                        <div className="col-12 mt-0">
                            <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                <h4 className="shift-to-party-page-title px-3">
                                    <IconButton aria-label="close" className="float-right pr-4 mt-2"
                                        onClick={() => { props.handleOpenCloseRejectPartDialog(); setTextRejectPartErrorMessage(false); setTextRejectPartError(false); setRejectPart(null); }}>
                                        <CloseIcon />
                                    </IconButton>
                                </h4>
                            </Typography>
                        </div>
                        <DialogContent>
                            <div className={`${classes.icon} hoverRejectDialog`} onClick={props.handleOpenCloseRejectPartDialog}>
                                <CancelIcon className="reject-meesage-warning" fontSize="small" />
                            </div>
                            <div>
                                <DialogContentText id="alert-dialog-description">
                                    <div className={`mt-3 ${classes.rejectText}`}>
                                        <h6 className="mb-0"> Are you sure you want to reject this part price request ? </h6>
                                    </div>
                                </DialogContentText>
                            </div>
                        </DialogContent>
                    </div>
                    <div className="row col-md-12 mb-1 mt-0 px-5">
                        <div className="col-sm-12 col-md-12 px-4 pr-2 mb-0 mt-0">
                            <div className="col-6 pl-4 borderTextPart">
                                <TextareaAutosize value={textRejectPart}
                                    // maxLength={250}
                                    aria-label="minimum height" minRows={3} placeholder="Reason.." className="borderTextPart textArea-correction p-4"
                                    onChange={(e: any) => handleCustomerChange(e)}
                                />
                            </div>
                        </div>
                        {textRejectPartError &&
                            <span className="col-12 px-5" style={{ color: 'red' }}>Please enter reject message.</span>
                        }
                        {textRejectPartErrorMessage &&
                            <span className="col-12 px-5" style={{ color: 'red' }}>Only 250 characters are allowed.</span>
                        }
                    </div>
                </DialogContent>
                <div className="row-space-start col-12 mb-2 mt-0">
                    <div className="col-12 m-0 p-0">
                        <div className="row mb-0 px-2">
                            <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="row-space-start ml-auto">
                                        <DialogActions className="mt-3 mb-1 justify-content-end">
                                            <Button variant="contained"
                                                color="secondary" className="submit-reject-button"
                                                onClick={() => { validateRejectMessage(); }}
                                            >Reject
                                            </Button>
                                            <Button variant="contained" className={classes.closeButton}
                                                onClick={() => { props.handleOpenCloseRejectPartDialog(); setTextRejectPartErrorMessage(false); setTextRejectPartError(false); setRejectPart(null); }}
                                            >Cancel
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>

            {/* Confirm reject dialog */}
            {/* <Dialog
              disableBackdropClick={true}
              open={true}
              fullWidth={true}
              maxWidth={'sm'}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <div className={classes.icon}>
                  <WarningIcon className="delete-warning" fontSize="small" />
                </div>
                <div>
                  <DialogContentText id="alert-dialog-description">
                    <div className={`mt-3 ${classes.logoutText}`}>
                      Are you sure you want to leave this Page ?
                    </div>
                  </DialogContentText>
                </div>
              </DialogContent>
              <DialogActions>
                <>
                  <Button
                    className={`mx-4 `}
                    onClick={async () => {
                    
                    }}>Yes</Button>
                  <Button
                    className={`mr-4 ${classes.cancel}`}
                    onClick={() => {
                   
                    }}>No</Button>
                </>
              </DialogActions>
            </Dialog> */}
        </Fragment>
    );
}
