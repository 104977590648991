import React from "react"

import About from "./About"
import Footer from "./Footer"
import HomeContent from "./HomeContent"
import OurBrands from "./OurBrands"
import OurLocations from './OurLocations'
import Register from "./Register"

const Content = (props: any) => {

    return (
        <div className="site-content">
            {/* HomeContent */}
            <HomeContent pageData={props.pageData} />.

            {/* About (loop) */}
            <div id="about">
            <About pageData={props.pageData} />
            </div>
            

            {/* Register with us */}
            <Register pageData={props.pageData} />

            {/* Our Brands */}
            <div id="locations">
            <OurLocations pageData={props.pageData} />
            </div>

            {/* Our Brands */}
            <OurBrands pageData={props.pageData} />

            {/* Footer */}
            <Footer pageData={props.pageData} />
        </div>
    )
}

export default Content