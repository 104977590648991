/* eslint-disable */
import React, { Fragment, Dispatch, useEffect } from "react";
import { Prompt, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Grid from '@material-ui/core/Grid';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Paper from "@material-ui/core/Paper";
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { Tab, Tabs } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import Documents from "./Documents";
import ShipToParty from "./ShipToParty";
import KamReview from "./KamReview";
import AccessKam from './Access';
import AccessIBKam from "./AccessIBKam";
import DealerDetails from "./DealerDetailsKam";
import { getAllBrands, isEdit, setTabValue } from "../../store/actions/dealer.action";
import { getDealingIn, getRegion, getTypeOfTrade } from "../../store/actions/onboard.actions";
import DeleteDialog from "../../common/components/deleteDialog";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { getDealerDetails } from "../../store/actions/editDealer.actions";
import ReturnRequestSnackbar from '../../common/components/CreatedEnquirySnackbar';
import MoveOutDialog from "./MoveOutDialog";
import { get } from 'lodash';
import AddIcon from '@material-ui/icons/Add';
import { AddNewAddressDailog } from './AddNewAddressDailog';
import Dialog from '@material-ui/core/Dialog';

import { getShipToPartyAddress, AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues, getTransportationZone, setCustomerType, postDeliveryType } from '../../store/actions/editDealer.actions';

const useStyles = makeStyles(() => ({
  createButton: {
    backgroundColor: '#1A9FE0',
    width: 140,
    fontWeight: 800,
    textTransform: 'inherit',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#FFFFFF',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 8
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
    secondary: {
      main: '#FF0000',
    }
  },
});

function a11yProps(index: any) {
  return {
    id: `wrapped-tab-${index}`,
    'aria-controls': `wrapped-tabpanel-${index}`,
  };
}


const Dealers: React.FC = () => {
  const dispatch: Dispatch<any> = useDispatch();
  const { isEditDealer }: any = useSelector((state: any) => state.dealer);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [selectedTab, setselectedTab] = React.useState(0);
  const [newValue, setNewValue] = React.useState<any>();
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [moveOutDialog, setmoveOutDialog] = React.useState(false);
  const history = useHistory();
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const [successMessage, setSuccessMessage] = React.useState<any>(null)
  const [open, setOpen] = React.useState(false);
  const [dataUpdated, setDataUpdated] = React.useState(false);
  const [sendToSAP, setSendToSAP] = React.useState(false);
  const [selectedDeliveryType, setSelectedDeliveryType] = React.useState<string>('');
  const [dataUpdatedQuotation, setDataUpdatedQuotation] = React.useState(false);
  const [sapId, setSapId] = React.useState("");
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [editedAddress, setEditAddress] = React.useState<any>({});
  const [selected, setSelected] = React.useState<string>("");
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState("")
  const [isOutsideFreezone, setIsOutsideFreezone] = React.useState(true);
  const [isIntBuisness, setIsIntBuisness] = React.useState(false);
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("");
  const [salesRegion, setSalesRegion] = React.useState("");
  const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
  const { dealerDetails, regionData }: any = useSelector((state: any) => state.onboard);
  const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
  const classes = useStyles();
  const userType = sessionStorage.getItem('userType');
  const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [isDefaultAdd, setIsDefaultAdd] = React.useState<any>(false);
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);

  useEffect(() => {
    if (dealerDetails && dealerDetails.data) {
      sessionStorage.setItem('customerOnboardingType', dealerDetails.data.customerTierType);
      if (dealerDetails.data.addresses && dealerDetails.data.addresses.length > 0) {
        setIsDefaultUAE(dealerDetails.data.addresses[0].country);
        setSelectedDeliveryType(dealerDetails.data.deliveryType ? dealerDetails.data.deliveryType : "DLV");
        setSapId(dealerDetails.data.sapCustomerId);
      }
    }
    else if (dealerDetails && dealerDetails.err) {

    }
    // if (getSelectedAddress && getSelectedAddress.data) {
    //   setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    // }
    // else {
    //   setSelected("");
    // }

  }, [dealerDetails])


  const handleClose = () => {
    setOpen(false);
  };

  const handleopenClose = (open?: any) => {
    setConfirmDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleopenCloseMoveOut = (open?: any) => {
    setmoveOutDialog(open ? true : false);
  };

  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  // useEffect(() => {
  //   loadData()
  // }, []);

  const loadData = async () => {
    const data: any = await dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));
    if (data && data.data && data.data.addresses && data.data.addresses.length > 0) {
      setMasterCompanyBrands(data.data.masterCompanyBrands);
    }

    // dispatch(getRegion());
    // dispatch(getDealingIn());
    // dispatch(getTypeOfTrade());
    // const data: any = await dispatch(getDealerDetails(b2bUserId, 'dealerDetails,documents,kamReview'));
    // if (data && data.data && data.data.customerTierType === 'Workshop') {
    //   await dispatch(getAllBrands('workshop'));
    //   sessionStorage.setItem('customerType', 'workshop');
    // } else {
    //   await dispatch(getAllBrands('dealer'));
    //   if (data && data.data && data.data.isNewCustomer) {
    //     sessionStorage.setItem('customerType', 'new');
    //   } else {
    //     sessionStorage.setItem('customerType', 'existing');
    //   }
    // }
  }

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(sapCustomerId, data, selected))
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(sapCustomerId, data));
      const apiResponse = get(result, 'message', {});
      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        // dispatch(getDealerDetails(sapCustomerId, 'shipToParty'));

        setOpenSnackbar(true);
        setSuccessMessage(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
      else if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "e") {
        setOpenErrorSnackbar(true);
        setErrorMessage(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);

      }
      setSelected("");
    }
  }

  const handleopenCloseErrorSnackbar = (open: any) => {
    setOpenErrorSnackbar(open ? open : false)
  }

  const handlePropsValue = (value: any) => {
    setIsOutsideFreezone(value.isCustomerAcceptingDelivery)
    setIsIntBuisness(value.isIntBuisness)
    setIsDefaultUAE(value.isDefaultUAE)
    setSalesRegion(value.salesRegion)
  }

  function isDisabled() {
    if ((dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Submitted') || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer' || dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Active') {
      return true;
    } else {
      return false;
    }
  }
  return (
    <Fragment>
      {false && <FullscreenLoader />}
      <div className="d-flex align-items-center">
        <h2 className="page-title">
          {isEditDealer ? 'Edit' : 'View'} Customer Details</h2>
        <div className="ml-auto">
          <AnnouncementIcon />
          <NotificationIcon />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mt-2 mb-2">
          <div className="d-flex align-items-center mb-2">
            <h6 className="dealer-table-content mb-0">Please verify customer details: </h6>
            {sessionStorage.setItem('customerIdGenrated', dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId)}
            <div className="row dealer-table-content mb-0 px-2"><h6 className="row page-sub-title mb-0 mr-2 ml-2">
              {dealerDetails && dealerDetails.data && dealerDetails.data.companyName ? dealerDetails.data.companyName : "-"}
            </h6>
              (Customer Id: {" "}{dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId ? dealerDetails.data.sapCustomerId : "-"})</div>
            <div className="row-space-start ml-auto sm">
              {userType !== 'afg_super_admin' && !isEditDealer && userType !== 'FSE' &&
                <Button variant="contained" color="primary" onClick={(e) => { dispatch(isEdit(true)) }} className="blueActionButton border-left rounded-pill ml-4 mr-2">Edit </Button>
              }
              {(isDefaultUAE === "AE" && isEditDealer === true) ?
                <div className="row-space-start ml-auto">
                  {selectedTab == 2 && <Button
                    onClick={() => {
                      setOpen(true)
                    }}
                    className="text-info"
                    disabled={false}
                    startIcon={<AddIcon />}
                  ><span className="onshiftoparty-font">
                      Add New Address
                    </span>
                  </Button>}
                </div>
                : ""}
              <ReturnRequestSnackbar
                open={openSnackbar}
                handleopenClose={() => {
                  handleopenCloseSnackbar(false)
                }} message={successMessage} />
              <Button variant="contained" color="primary" onClick={(e) => {
                e.preventDefault();
                if (dataUpdated) {
                  handleopenCloseMoveOut(true)
                } else {
                  dispatch(setTabValue(0));
                  { userType === 'afg_super_admin' ? history.push('/superadmindealers') : history.push('/dealers') }
                  sessionStorage.setItem('isAddedByFse', "");
                  sessionStorage.setItem('assignFse', "");
                }
              }}
                className="blueActionButton border-left rounded-pill">Back to Customer list</Button>
            </div>
          </div>
        </div>
      </div>
      <Grid container className="marginScrollableArea">
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        <Grid item xs={12} sm={12}>
          <ThemeProvider theme={theme}>
            <Paper square>
              <div className="border-bottom">
                <Tabs
                  className="tabSelect"
                  value={selectedTab}
                  textColor="primary"
                  indicatorColor="primary"
                  onChange={(event, newValue) => {
                    if (!dataUpdated) {
                      setselectedTab(newValue);
                    } else {
                      setNewValue(newValue);
                      handleopenClose(true);
                    }
                  }}>
                  <Tab label="Customer Details" value={0} {...a11yProps('DealerList')} />
                  <Tab label="Documents" value={1} {...a11yProps('Documents.')} />
                  <Tab label="Shipping Address" value={2} {...a11yProps('ShipToParty')} />
                  <Tab label="KAM Review" value={3} {...a11yProps('KamReview')} />
                  <Tab label="Access" value={4} {...a11yProps('Access')} />
                </Tabs>
              </div>
              <div className="d-block w-100">
                {selectedTab == 0 && <DealerDetails setDataUpdated={setDataUpdated} handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} setselectedTab={setselectedTab} />}
                {selectedTab == 1 && <Documents setDataUpdated={setDataUpdated} handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} setselectedTab={setselectedTab} />}
                {selectedTab == 2 && <ShipToParty setDataUpdated={setDataUpdated} handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} setselectedTab={setselectedTab} setSendToSAP={setSendToSAP} sendToSAP={sendToSAP} freeZoneProps={handlePropsValue} />}
                {selectedTab == 3 && <KamReview setDataUpdated={setDataUpdated} handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} setselectedTab={setselectedTab} />}
                {selectedTab == 4 && sessionStorage.getItem('isAddedByFse') !== "FSE" && <AccessKam setDataUpdated={setDataUpdated} handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} />}
                {selectedTab == 4 && sessionStorage.getItem('isAddedByFse') === "FSE" && <AccessIBKam setDataUpdated={setDataUpdated} handleopenCloseSnackbar={handleopenCloseSnackbar} setSuccessMessage={setSuccessMessage} />}
              </div>
            </Paper>
          </ThemeProvider>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">

        <AddNewAddressDailog setOpenDialog={handleClose} isDefaultAdd={isDefaultAdd} firstAddressCheck={firstAddressCheck} setDataUpdatedQuotation={setDataUpdatedQuotation} selectedDeliveryType={selectedDeliveryType} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} isEditDealer={isEditDealer} isOutsideFreezone={isOutsideFreezone}
          isIntBuisness={isIntBuisness} isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
      </Dialog>
      <DeleteDialog
        open={confirmDialog && dataUpdated}
        text={sendToSAP ? 'You will have to send the address(es) to SAP before updating the Customer details else the data will not get updated and will be lost.' : "Do you wish to proceed without saving the data?"}
        handleopenClose={(data: any) => {
          if (data === 'delete' || data === 'back') { setselectedTab(newValue); handleopenClose(false); setDataUpdated(false); }
          if (!data) { handleopenClose(false); }
        }}
        sendToSAP={sendToSAP ? true : false}
      />
      <MoveOutDialog
        open={moveOutDialog && dataUpdated}
        text="Do you wish to proceed without saving the data?"
        handleopenClose={(data: any) => {
          if (data) {
            history.push('/dealers');
            dispatch(setTabValue(0));
            handleopenCloseMoveOut(false);
          }
          if (!data) { handleopenCloseMoveOut(false); }
        }} />
      <Prompt when={dataUpdated} message={sendToSAP ? 'You will have to send the address(es) to SAP before updating the Customer details else the data will not get updated and will be lost.' : "Do you wish to proceed without saving the data?"}
      >
      </Prompt>
    </Fragment>
  );
};

export default Dealers;
