
import { NOTIFICATIONS } from "./actionsTypes";
import { apiStart, apiError, apiSuccess } from "./apiActions";
import { notificationsService } from "../../services/notification.service";

export const notificationActions = {
    getAllNotifications,
    markNotificationRead,
    getAllOnbardingNotifications
};

export function getAllNotifications(sapCustomerId: any, userType: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(NOTIFICATIONS.GET_ALL_NOTIFICATIONS_FETCHING));
        const response = await notificationsService.getAllNotification(sapCustomerId, userType);
        if (response.error) {
            dispatch(apiError(NOTIFICATIONS.GET_ALL_NOTIFICATIONS_ERROR, response));
            return response || [];
        } else {
            dispatch(apiSuccess(NOTIFICATIONS.GET_ALL_NOTIFICATIONS_SUCCESS, response));
            return response || [];
        }
    }
}

export function getAllOnbardingNotifications(b2bId: any, userType: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(NOTIFICATIONS.GET_ALL_NOTIFICATIONS_FETCHING));
        const response = await notificationsService.getAllOnbardingNotifications(b2bId, userType);
        if (response.error) {
            dispatch(apiError(NOTIFICATIONS.GET_ALL_NOTIFICATIONS_ERROR, response));
            return response || [];
        } else {
            dispatch(apiSuccess(NOTIFICATIONS.GET_ALL_NOTIFICATIONS_SUCCESS, response));
            return response || [];
        }
    }
}

export function markNotificationRead(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(NOTIFICATIONS.MARK_NOTIFICATIONS_READ));
        const response = await notificationsService.MarkNotificationAsRead(data);
        if (response.error) {
            dispatch(apiError(NOTIFICATIONS.MARK_NOTIFICATIONS_READ_ERROR, response));
            return response || [];
        } else {
            dispatch(apiSuccess(NOTIFICATIONS.MARK_NOTIFICATIONS_READ_SUCCESS, response));
            return response || [];
        }
    }
}

export function addNotifications(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(NOTIFICATIONS.ADD_NOTIFICATION));
        const response = await notificationsService.AddNotification(data);
        if (response.error) {
            dispatch(apiError(NOTIFICATIONS.ADD_NOTIFICATION_ERROR, response));
            return response || [];
        } else {
            dispatch(apiSuccess(NOTIFICATIONS.ADD_NOTIFICATION_SUCCESS, response));
            return response || [];
        }
    }
}

export function notifyUserMail(data: any) {
    return async (dispatch: any) => {
        dispatch(apiStart(NOTIFICATIONS.NOTIFY_USER_MAIL));
        const response = await notificationsService.NotifyUserMail(data);
        if (response.error) {
            dispatch(apiError(NOTIFICATIONS.NOTIFY_USER_MAIL_ERROR, response));
            return response || [];
        } else {
            dispatch(apiSuccess(NOTIFICATIONS.NOTIFY_USER_MAIL_SUCCESS, response));
            return response || [];
        }
    }
}

export const ADD_NOTIFICATION: string = "ADD_NOTIFICATION";
export const REMOVE_NOTIFICATION: string = "REMOVE_NOTIFICATION";

export function addNotification(title: string, text: string, error?: string): IAddNotificationActionType {
    return { type: ADD_NOTIFICATION, text: text, title: title, error: (error ? error : '') };
}

export function removeNotification(id: number): IRemoveNotificationActionType {
    return { type: REMOVE_NOTIFICATION, id: id };
}

interface IAddNotificationActionType { type: string, text: string, title: string, error?: string };
interface IRemoveNotificationActionType { type: string, id: number };
