/* eslint-disable */
import React, { Fragment, Dispatch, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import CancelIcon from '@material-ui/icons/Cancel';
import './RejectDialog.css';
import './DeactivateDailog.css';
import { useHistory } from 'react-router-dom';
import { Dialog } from '@material-ui/core';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { deactivateAddress, activateAddress } from '../../store/actions/editDealer.actions';
import DeactivateAddressInfoDialog from './DeactivateAddressInfoDialog';
import set from 'date-fns/esm/fp/set/index.js';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    title: {
        flex: '1 1 100%',
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 80,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    },
    icon: {
        float: "left",
        margin: "0 20px 20px 0",
        height: "100%",
        fill: "white",
    },
    cancel: {
        fontSize: '0.813rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        color: '#919191',
        textTransform: 'capitalize'
    },
    deactivateText: {
        color: '#616161',
        fontSize: '0.875rem',
        fontFamily: 'Nunito',
        fontWeight: 700,
        textTransform: 'inherit'
    }

}));

export default function DeactivateDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const [successMessage, setSuccessMessage] = React.useState<any>(null);
    const [openSnackbar, setOpenSnackbar] = React.useState(false);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [apiResponseText, setApiResponseText] = React.useState('');
    const [deactivationMessage, setdeactivationMessage] = React.useState<any>('');
    const [deactivateTextErrorMessage, setdeactivateTextErrorMessage] = React.useState<boolean>(false);
    const history = useHistory()
    const { selectedNewDealer }: any = useSelector((state: any) => state.dealer);
    // const b2bUserId = get(selectedNewDealer, "createdByB2bUserId", "");
    const [deactivationMessageError, setdeactivationMessageError] = React.useState<boolean>(false);
    const [deactivationErrorMessage, setdeactivationErrorMessage] = React.useState<string>("");
    const [submitclassName, setSubmitclassName] = React.useState<string>("");
    const { openRequestSnackbar, setOpendeactivateDialog, status, message } = props;
    const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
    const sapCustomerId: any = selectedNewDealer && selectedNewDealer.sapCustomerId;
    const [openDeactivteInfoDialog, setOpenDeactivteInfoDialog] = React.useState<boolean>(false);
    const { deactivateAddressData }: any = useSelector((state: any) => state.onboard);
    const [openDeactivateAddressInfo, setOpenDeactivateAddressInfo] = React.useState<any>(false);
    const [userNameCheck, setUserNameCheck] = React.useState<any>();
    const [isAddressDataFound, setIsAddressDataFound] = React.useState<boolean>(false);

    useEffect(() => {
        if (props.message) {
            setdeactivationMessage(props.message)
        }
        if (props.status == "Deactive") {
            setSubmitclassName("submit-activate-button");
        }
        else if (props.status == "Active") {
            setSubmitclassName("submit-deactivate-button");
        }
        // if(props.dealerStatus=="Deactive"){}
    }, [status])

    useEffect(() => {
        if (deactivateAddressData && deactivateAddressData.data && deactivateAddressData.data.length > 0) {
            let userName: any
            let firstNameLastNames = deactivateAddressData.data.map((item: any) => item.firstNameLastName);
            userName = firstNameLastNames.join(', ');
            setUserNameCheck(userName);
        }
    }, [deactivateAddressData])

    // VALIDATION FOR MESSAGE

    // function validation(data: any) {
    //     const re = /^[a-z0-9 ]+$/i;
    //     return !re.test(data) ? false : true;
    // }

    // // ONCHANGE OF TEXTAREA-AUTOSIZE 
    // const handleCustomerChange = (e: any) => {
    //     setdeactivationMessage(e.target.value);
    //     if (e.target.value) {
    //         if (e.target.value === "" || e.target.value.trim() === "") {
    //             setdeactivationErrorMessage("");
    //             setdeactivationMessageError(true);
    //         }
    //         else if (e.target.value.length > 500) {
    //             setdeactivationErrorMessage("It should not exceed 500 characters");
    //             setdeactivationMessageError(true);
    //         }
    //         else if (!validation(e.target.value)) {
    //             setdeactivationErrorMessage("It should not allowed special characters");
    //             setdeactivationMessageError(true);
    //         }
    //         else {
    //             setdeactivationMessageError(false);

    //         }
    //     }
    //     else {
    //         setdeactivationErrorMessage("");
    //     }

    // }

    // ONCHANGE OF TEXTAREA-AUTOSIZE 
    const handleCustomerChange = (e: any) => {
        setdeactivationMessage(e.target.value);
        if (e.target.required) {
            if (e.target.value === "" || e.target.value.trim() === "") {
                setdeactivationMessageError(true);
            }
            else {
                setdeactivationMessageError(false);
            }
        }
    }

    const handleopenCloseSnackbar = (open?: any) => {
        setOpenSnackbar(open ? true : false);
    };

    // VALIDATION FOR TEXTAREA OF DEACTIVATE MESSAGE 
    function validatedeactivateMessage() {
        let isValid: boolean = true;
        if (props.status == "Active") {
            if (deactivationMessage && deactivationMessage.trim() == "") {
                setdeactivationMessageError(true);
                setdeactivationErrorMessage("please enter deactivate message");
                isValid = false;
                return
            } else if (deactivationMessage && deactivationMessage.split('').length > 500) {

                setdeactivationMessageError(false);
                setdeactivateTextErrorMessage(true);
                isValid = false;
                return


            } else {
                setdeactivationMessageError(false);
                setdeactivateTextErrorMessage(false);
                isValid = true
            }
        }
        if (isValid) {
            deactivateMessageAPI();
            setSuccessMessage('Customer deactivated successfully');
        }
    }

    // // FOR DEACTIVATE MESSAGE API
    const deactivateMessageAPI = async () => {
        let action = ""
        if (props.status == "Deactive") {
            let payload = {
                "actionTakenBy": sessionStorage.getItem('webApplicationLoginId'),
                "addressId": props.id,
                "id": sapCustomerId,
                "action": "activate",
                "clientIpAddress": sessionStorage.getItem("clientIp")
            }
            let crStatus: any = await dispatch(activateAddress(payload))
            if (crStatus.message.status == "S") {
                // // setTimeout(() => {
                // history.push('/dealers')
                // dispatch(getDealerDetails(props.sapId, 'shipToParty'));
                props.handleResponse(false, crStatus.message.message)
                props.handleopenClose(false)
                setdeactivationMessage(null);
                setOpenDeactivteInfoDialog(false);
                setIsAddressDataFound(false);
            } else if (crStatus.error) {
                props.handleResponse(true, crStatus.error)
                props.handleopenClose(false)
                setdeactivationMessage(null);
                setOpenDeactivteInfoDialog(false);
                setIsAddressDataFound(false);
            }
        }
        else if (props.status == "Active") {
            if (deactivationMessage && deactivationMessage.length >= 1) {
                setdeactivationMessageError(false);
                let payload = {
                    "actionTakenBy": sessionStorage.getItem('webApplicationLoginId'),
                    "addressId": props.id,
                    "id": sapCustomerId,
                    "action": "deactivate",
                    "deactivationReason": deactivationMessage,
                    "clientIpAddress": sessionStorage.getItem("clientIp")
                }
                let crStatus: any = await dispatch(deactivateAddress(payload));
                if (crStatus.message.status == "S") {
                    // // setTimeout(() => {
                    // history.push('/dealers')
                    // dispatch(getDealerDetails(props.sapId, 'shipToParty'));
                    props.handleResponse(false, crStatus.message.message)
                    props.handleopenClose(false)
                    setdeactivationMessage(null);
                    if (crStatus && crStatus.data && crStatus.data.length > 0) {
                        setOpenDeactivteInfoDialog(true);
                        setIsAddressDataFound(false);
                    } else if (crStatus && crStatus.data && crStatus.data.length === 0) {
                        // props.handleResponse(true, "Default Address is not Assigned to this Address.")
                        props.handleopenClose(false)
                        setdeactivationMessage(null);
                        setOpenDeactivteInfoDialog(true);
                        setIsAddressDataFound(true);
                    }
                } else if (crStatus.error) {
                    props.handleResponse(true, crStatus.error)
                    props.handleopenClose(false)
                    setdeactivationMessage(null);
                    setOpenDeactivteInfoDialog(false);
                    setIsAddressDataFound(true);
                }

            } else if (!deactivationMessage) {
                setdeactivationMessageError(true);
            }

        }
    }

    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    return (
        <Fragment>
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false); setdeactivationMessage(""); setdeactivateTextErrorMessage(false); props.handleopenCloseSnackbar(false); }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <Snackbar
                    open={openSnackbar}
                    handleopenClose={() => {
                        handleopenCloseSnackbar(false)
                    }} message={apiResponseText} />
                <Snackbar
                    open={openErrorSnackbar}
                    type="error"
                    handleopenClose={() => {
                        handleopenCloseErrorSnackbar(false)
                    }} message={apiResponseText} />
                {/* <Snackbar
                    open={props.openRequestSnackbar ? true : false}
                    handleopenClose={() => { props.handleopenCloseSnackbar(false); setdeactivationMessage(null); }} message={successMessage}
                /> */}
                <DialogContent>
                    <div className={classes.root}>
                        {
                            props.status == "Active" ?
                                <div className="col-12 mt-0">
                                    <Typography className={classes.title} variant="h4" id="tableTitle" component="div">
                                        <h4 className="shift-to-party-page-title px-3">
                                            <IconButton aria-label="close" className="float-right pr-4 mt-2"
                                                onClick={() => { props.handleopenClose(false); setdeactivateTextErrorMessage(false); setdeactivationMessageError(false); props.handleopenCloseSnackbar(false); setdeactivationMessage(null); }}>
                                                <CloseIcon />
                                            </IconButton>
                                        </h4>
                                    </Typography>
                                </div> : ""}
                        <DialogContent>
                            {
                                props.status == "Active" ?
                                    <div className={classes.icon}>
                                        {/* <CancelIcon className="deactivate-meesage-warning" fontSize="small" /> */}
                                    </div>
                                    : ""}
                            <div>
                                <DialogContentText id="alert-dialog-description">
                                    <div className={`mt-3 ${classes.deactivateText}`}>
                                        <h6 className="mb-0">{props.status == "Deactive" ? "Are you sure to activate ?" : "Are you sure to deactivate?"}</h6>
                                    </div>
                                </DialogContentText>
                            </div>
                        </DialogContent>
                    </div>
                    {
                        props.status == "Active" ?
                            <div className="row col-md-12 mb-1 mt-0 px-5">
                                <div className="col-sm-12 col-md-12 px-4 pr-2 mb-0 mt-0">
                                    <div className="col-6">
                                        <TextareaAutosize value={deactivationMessage}
                                            // maxLength={250}
                                            aria-label="minimum height" minRows={6} placeholder="Reason.." className="textArea-correction p-4"
                                            onChange={(e: any) => handleCustomerChange(e)}
                                        />
                                    </div>
                                </div>
                                {deactivationMessageError &&
                                    <span className="col-12 px-5" style={{ color: 'red' }}>Please enter reason for Deactivation.</span>
                                }
                                {deactivateTextErrorMessage &&
                                    <span className="col-12 px-5" style={{ color: 'red' }}>Reason cannot exceed 150 Characters.</span>
                                }
                            </div>
                            : props.message && props.message.length > 0 ? <div className="col-sm-12 col-md-12 px-4 pr-2 mb-0 mt-0">
                                <div className="col-6">
                                    <TextareaAutosize disabled={true} value={deactivationMessage}
                                        // maxLength={250}
                                        aria-label="minimum height" minRows={6} placeholder="Reason.." className="textArea-correction p-4"
                                        onChange={(e: any) => handleCustomerChange(e)}
                                    />
                                </div>
                            </div> : ""}
                </DialogContent>
                <div className="row-space-start col-12 mb-2 mt-0">
                    <div className="col-12 m-0 p-0">
                        <div className="row mb-0 px-2">
                            <div className="col-12 mt-2">
                                <div className="d-flex align-items-center">
                                    <div className="row-space-start ml-auto">
                                        <DialogActions className="mt-3 mb-1 justify-content-end">
                                            <Button variant="contained"
                                                color="secondary" className={submitclassName}
                                                onClick={() => { validatedeactivateMessage(); }}
                                            >{props.status == "Deactive" ? "Yes" : "Submit"}
                                            </Button>
                                            <Button variant="contained" className={classes.closeButton}
                                                onClick={() => { props.handleopenClose(false); setdeactivateTextErrorMessage(false); setdeactivationMessageError(false); props.handleopenCloseSnackbar(false); setdeactivationMessage(null); }}
                                            >{props.status == "Deactive" ? "No" : "Cancel"}
                                            </Button>
                                        </DialogActions>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
            <DeactivateAddressInfoDialog
                open={openDeactivteInfoDialog}
                isAddressDataFound={isAddressDataFound}
                userNameCheck={userNameCheck}
                handleopenClose={(data: any) => {
                    if (data) {
                        setOpenDeactivteInfoDialog(true)
                    } else {
                        setOpenDeactivteInfoDialog(false)
                    }
                }}
            />
        </Fragment>
    );
}
