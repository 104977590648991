import * as React from "react";
import "react-dates/initialize";
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

function DatePicker(props: any): JSX.Element {
  const [focusedInput, setFocusedInput] = React.useState(null);
  // const [startDate, setStartDate] = React.useState(null);
  // const [endDate, setEndDate] = React.useState(null);

  const { startDate, endDate } = props;

  const onChange = (start: any, end: any) => {
    props.setstartDate(start);
    props.setendDate(end);
    props.onChange(start, end)
  }

  const onFocusChange = (focus: any) => {
    setFocusedInput(focus)
  }

  const isOutsideRange = (day: any) => {
    if (startDate) {
      if (props.isEnableOnlyThirtyDays) {
        const thirtyDaysFromNow = new Date(startDate);
        thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
        return day.isBefore(new Date(startDate)) || day.isAfter(thirtyDaysFromNow);
      }
      if (props.isDisableBeforeOneYear) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        return day.isBefore(oneYearAgo);
      }
    } else {
      if (props.isDisableBeforeOneYear) {
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        return day.isBefore(oneYearAgo);
      }
    }
    return false;
  };


  return (
    <DateRangePicker
      displayFormat="DD/MM/yyyy"
      startDate={startDate}
      isOutsideRange={isOutsideRange}
      startDateId="startDate"
      startDatePlaceholderText="From Date"
      endDatePlaceholderText="To Date"
      endDate={endDate}
      endDateId="endDate"
      showClearDates
      onDatesChange={({ startDate, endDate }) => onChange(startDate, endDate)}
      focusedInput={focusedInput}
      onFocusChange={focusedInput => onFocusChange(focusedInput)}
      readOnly
    />
  );
}

export default DatePicker;
