/* eslint-disable */
import AnnouncementIcon from "../../common/components/AnnouncementIcon";
import NotificationIcon from "../../common/components/NotificationIcon";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Theme, createTheme } from "@material-ui/core/styles";
import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon"
import "./Quotation.css"
import AddIcon from '@material-ui/icons/Add';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Button } from "@material-ui/core";
import QuotationList from "./QuotationList";
import { useEffect } from "preact/hooks";
import { getEnquiriesList, getFSEEnquiriesList, getFSEList, getOrderEnquiryStatus } from '../../store/actions/orderEnquiry.action';
import { getCustomerDetailsByCustomerID, getFSECustomerList, getFSEEnquiryStatus } from "../../store/actions/dealer.action";
import { getFSEQuotation, getQuotation, getQuotationListStatus, postOpenQuotation } from "../../store/actions/quotation.action";
import { useSelector, } from "react-redux";
import { useDispatch } from "react-redux";
import _, { get } from "lodash";
import FullscreenLoader from "../../common/components/FullscreenLoader";
import { useHistory } from "react-router-dom";
import OpenQuotationDialog from "./OpenQuotationDialog";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import OpenQuotationCancelDialog from "./OpenQuotationCancelDialog";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import { getUser } from "../../store/actions/quotation.action";
import TextField from "@material-ui/core/TextField";
import LaunchIcon from "@material-ui/icons/Launch"
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import DatePicker from '../../common/components/dateRangePicker';
import Grid from '@material-ui/core/Grid';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from "@material-ui/core/styles";
import { ALL_STATUS } from "../../constants/orderHistoryConstants";
import { getNewBrand, getUniqueTimestamp } from "../../common/components/util";
import { cancelEnquiry, getUniqueEnquirySessionId } from "../../store/actions/createUpdateEnquiry.actions";
import ChooseCustomerDialog from "../CreateEnquiry/ChooseCustomerDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0.125rem 175',
    display: 'flex',
    alignItems: 'center',
    width: 300,
    borderRadius: "1.25rem",
    height: "2.25rem",
    minWidth: 100,
    margin: 0,
    border: "2px solid black",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        border: "2px solid red"
      }
    }
  },
  actionButton: {
    color: '#1A9FE0',
  },
  actionButtonOpenQuotation: {
    color: '#1A9FE0',
    paddingRight: 5,
    fontSize: '1.5rem',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
    fontSize: '2rem',
    padding: '0.6rem',
    color: '#999999',
    fontWeight: 400,
    fontFamily: 'Nunito'
  },
  iconButton: {
    color: '#444444',
    fontWeight: 700,

  },
}));

const List = (props: any) => {
  return (
    <SvgIcon {...props} style={{}}>

      <path d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"></path>
      <circle fill="red" />
    </SvgIcon>
  )
}

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const Quotation: React.FC = () => {
  const classes = useStyles({ checkedIcon: "", icon: "" })
  const [myQuotation, setMyQuotation] = React.useState(true)
  const [status, setStatus] = React.useState("All")
  const [user, setUser] = React.useState<any>("")
  const [value, setValue] = React.useState("")
  const [page, setPage] = React.useState(0)
  const dispatch: any = useDispatch()
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const { quotationListStatus, openQuotationRequest, quotationUser }: any = useSelector((state: any) => state.quotation)
  const { quotation }: any = useSelector((state: any) => state.quotation)
  const history: any = useHistory()
  const [openQuotation, setOpenQuotation] = React.useState(false);
  const webApplicationLoginId = sessionStorage.getItem('webApplicationLoginId');
  const sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [loading, setLoading] = React.useState<any>(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [openGenratedQuotation, setOpenGenratedQuotation] = React.useState<any>(false);
  const [searchText, setsearchText] = React.useState<any>('');
  const [startDate, setstartDate] = React.useState<any>(null);
  const [endDate, setendDate] = React.useState<any>(null);
  const [isDateFilterApply, setIsDateFilterApply] = React.useState<any>(false);
  const [filterByStatus, setFilterByStatus] = React.useState<any>('All');
  const [fseName, setFseName] = React.useState<any>(sessionStorage.getItem('webApplicationLoginId'));
  const [fseWebApplicationLoginId, setFseWebApplicationLoginId] = React.useState<any>("");
  const [open, setOpen] = React.useState(-1);
  const { enquiryList, enquiryStatus, fseList } = useSelector((state: any) => state.orderEnquiry);
  const { getCustomerDetailsBySapId, getFSECustomerListData, getFSEEnquiryStatusData }: any = useSelector((state: any) => state.dealer);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedCustomerId, setSelectedCustomerId] = React.useState<any>('');
  const [searchTextByID, setSearchTextByID] = React.useState<any>('');
  const [customerId, setCustomerId] = React.useState<any>([]);
  const [selectedCustomerName, setSelectedCustomerName] = React.useState<any>('');
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const [isMyEnquiryChecked, setIsMyEnquiryChecked] = React.useState(true)
  const [isResetFilters, setIsResetFilters] = React.useState(false)
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');
  const [isOrderEnquiryCreated, setIsOrderEnquiryCreated] = React.useState<boolean>(false);
  const [openChooseCustomerDialog, setOpenChooseCustomerDialog] = React.useState(false);

  React.useEffect(() => {
    let status: any = openQuotationRequest && openQuotationRequest.data && openQuotationRequest.data.data && openQuotationRequest.data.data.records && openQuotationRequest.data.data.records.length > 0 && openQuotationRequest.data.data.records.filter((ele: any) => {
      return (
        ele && ele.quotationStatus === "Open"
      )
    })
    if (status && status.length >= 1) {
      setOpenGenratedQuotation(true);
    } else {
      setOpenGenratedQuotation(false);
    }
  }, [openQuotationRequest])

  const getAllCustomerIds = () => {
    let customer_ids: string[] = [];
    if (getFSECustomerListData && getFSECustomerListData.data) {
      customer_ids = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => item.sapCustomerId);
    }
    return customer_ids;
  }

  const openCloseChooseCustomer = () => {
    setOpenChooseCustomerDialog(false);
  }

  const handleChooseCustomer = () => {
    setOpenChooseCustomerDialog(true);
  }

  React.useEffect(() => {
    if (sessionStorage.getItem('userType') === 'FSE') {
      if (searchText === '' && startDate === null && endDate === null && sessionStorage.getItem('userType') === 'FSE' && getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
        let searchData: any = {};
        if (fseName && fseName !== '') {
          if (fseName === 'All') {
            const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);
            searchData["fseWebApplicationLoginId"] = webApplicationLoginIds;
            setFseWebApplicationLoginId(webApplicationLoginIds);
          } else if (fseName === '') {
            searchData["fseWebApplicationLoginId"] = [];
            setFseWebApplicationLoginId("");
          } else {
            searchData["fseWebApplicationLoginId"] = [fseName];
            setFseWebApplicationLoginId(fseName);
          }
        } else {
          searchData["fseWebApplicationLoginId"] = [];
          setFseWebApplicationLoginId("");
        }

        if (selectedCustomerName && selectedCustomerName !== '') {
          searchData["customerId"] = [selectedCustomerName];
        } else if (fseName === '') {
          searchData["customerId"] = getAllCustomerIds();
        } else {
          searchData["customerId"] = []
        }
      }
    }
    setFilterByStatus('All');
  }, [searchText, startDate, endDate, isMyEnquiryChecked, getFSECustomerListData]); // eslint-disable-line

  const webApplicationLoginIds: string[] = fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((item: any) => item.webApplicationLoginId);

  React.useEffect(() => {
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
  }, [getFSECustomerListData]);

  const loadQuotation = (input?: any ) => {
    let data = {
      "filterBy": status === "All" ? "" : status,
      "searchBy": input,
      "myQuotation": myQuotation ? true : false,
      "webApplicationLoginId": user ? user : sessionStorage.getItem('webApplicationLoginId'),
      "sapCustomerId": sapCustomerId,
    }
    dispatch(getQuotation(data, page))
  }

  const loadFSEQuotation = (input?: any) => {
    let data = {
      "userRole": "FSE",
      "loginUserId": sessionStorage.getItem('webApplicationLoginId'),
      "openQuotation": false,
      "isDateFilterApply": startDate && endDate ? true : false,
      "fromDate": startDate ? startDate : "",
      "toDate": endDate ? endDate : "",
      "filterByStatus": filterByStatus === "All" ? "" : filterByStatus,
      "searchBy": input,
      "filterByCustomer": selectedCustomerName ? selectedCustomerName : fseName === '' ? getAllCustomerIds : "",
      "filterByInterUserIds": fseName === "All" ? webApplicationLoginIds : [fseName]
    }
    dispatch(getFSEQuotation(data, page))
  }

  React.useEffect(() => {
    if (sessionStorage.getItem('userType') !== 'FSE')
      loadQuotation(value);
  }, [myQuotation, page, status, user])
  // }, [myQuotation, page, value, status, user])

  React.useEffect(() => {
    if (sessionStorage.getItem('userType') === 'FSE') {
      setUser("");
      loadFSEQuotation(value);
    }
  }, [myQuotation, page])
  // }, [myQuotation, page, value, status, user])

  React.useEffect(() => {
    if (quotationListStatus && quotationListStatus.data && quotationListStatus.data.data) {
    } else {
      dispatch(getQuotationListStatus())
    }
    if (sessionStorage.getItem('userType') !== 'FSE') {
      if (quotationUser && quotationUser.data && quotationUser.data.data && quotationUser.data.data.users && quotationUser.data.data.users.length > 0) {
      } else {
        dispatch(getUser(sapCustomerId))
      }
    }
    if (sessionStorage.getItem('userType') === 'FSE') {
      // if (getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0) {
      // } else {
      dispatch(getFSECustomerList());
      // }
      if (fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0) {
      } else {
        dispatch(getFSEList());
      }
    }
  }, [])

  const handlechange = (e: any) => {
    setValue(e.target.value)
    setPage(0);
  }

  const handleClickOpen = () => {
    // setOpen(true);
  };

  const handlePage = (page: any) => {
    setPage(page);
  }

  const handleQuotation = (e: any) => {
    setMyQuotation(e.target.checked)
    setPage(0);
  }

  const handleCloseOpenQuotation = () => {
    setOpenQuotation(false);
    if (sessionStorage.getItem('userType') === 'FSE') {
      loadFSEQuotation(value)
    } else {
      loadQuotation(value);
    }
  }

  // ON CHANGE OPEN CLOSE - SUCCESS SNACKBAR
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  // ON CHANGE OPEN - CLOSE - ERORR SNACKBAR
  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchTextByID("");
  };

  const onSearchCustomerId = (searchText: any) => {
    setSearchTextByID(searchText);
    // getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.map((item: any) => (
    //   console.log("__________________", item)
    // ))
    setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    if (searchText.length > 0) {
      const filteredIds = getFSECustomerListData && getFSECustomerListData.data && getFSECustomerListData.data.length > 0 && getFSECustomerListData.data.filter((sapId: any) => sapId.sapCustomerId.toLowerCase().includes(searchText.toLowerCase()) || sapId.name.toLowerCase().includes(searchText.toLowerCase()));
      setCustomerId(filteredIds);
    } else {
      setCustomerId(getFSECustomerListData && getFSECustomerListData.data);
    }
  };

  const openQuotationAPI = async () => {
    let data: any;
    if (sessionStorage.getItem('userType') === 'FSE') {
      let payload = {
        "userRole": "FSE",
        "loginUserId": sessionStorage.getItem('webApplicationLoginId'),
        "openQuotation": true,
        "isDateFilterApply": false,
        "fromDate": "",
        "toDate": "",
        "filterByStatus": "",
        "searchBy": "",
        "filterByCustomer": [],
        "filterByInterUserIds": [sessionStorage.getItem('webApplicationLoginId')]
      }
      data = await dispatch(getFSEQuotation(payload, page))
    } else {
      data = await dispatch(postOpenQuotation({
        "myQuotation": true,
        "webApplicationLoginId": webApplicationLoginId,
        "sapCustomerId": sapCustomerId
      }))
    }
    if (data && data.message && data.message.status === 'S') {
      // setSuccessMessage(data && data.message && data.message.message);
      // handleopenCloseSnackbar(true);
      setLoading(false)
      // if (openGenratedQuotation === true) {
      setOpenQuotation(true)
      // } else if (openGenratedQuotation === false) {
      //   setOpenQuotation(false)
      //   showErrorMessage("At least one quotation should needs to be generated or have open Status.");
      //   handleopenCloseSnackbar(true);
      //   setOpenErrorSnackbar(true);
      // }
    } else if (data && data.message && data.message.status === "E") {
      showErrorMessage(data && data.error);
      handleopenCloseSnackbar(true);
      setOpenErrorSnackbar(true);
      setLoading(false)
      setOpenQuotation(false);
      setTimeout(() => {
        handleopenCloseSnackbar(false);
        setOpenErrorSnackbar(false);
      }, 2000)
    }
  }

  const handleClickSapId = (event: any, sapId: any, name: any) => {
    setSelectedCustomerId(sapId);
    setSelectedCustomerName(name);
  };

  const resetFilters = () => {
    setIsResetFilters(true)
    setsearchText('');
    setPage(0);
    setValue("");
    setstartDate(null);
    setendDate(null);
    setFseName(sessionStorage.getItem('webApplicationLoginId'));
    setFilterByStatus('');
    setSelectedCustomerId('');
    setSelectedCustomerName('');
    setIsDateFilterApply(false);
    let data = {
      "userRole": "FSE",
      "loginUserId": sessionStorage.getItem('webApplicationLoginId'),
      "openQuotation": false,
      "isDateFilterApply": false,
      "fromDate": "",
      "toDate": "",
      "filterByStatus": "",
      "searchBy": "",
      "filterByCustomer": "",
      "filterByInterUserIds": [sessionStorage.getItem('webApplicationLoginId')]
    }
    dispatch(getFSEQuotation(data, page))
  }

  return (
    <div>
      {loading && (<FullscreenLoader />)}
      {(openQuotationRequest && openQuotationRequest.loading) && <FullscreenLoader />}
      {(quotationListStatus && quotationListStatus.loading || quotation && quotation.loading) && <FullscreenLoader />}
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <h2 className="page-title">
            Quotations
          </h2>
          <div className="ml-auto">
            <AnnouncementIcon />
            <NotificationIcon />
          </div>
        </div>
        <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={successMessage} />
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={errorMessage} />
        {sessionStorage.getItem('userType') === 'FSE' ?
          <div className="row mt-4">
            <div className="col-12">
              <div className="cardCommon p-3">
                <div className="d-flex align-items-center">
                  <h5 className="page-sub-title mb-2">Search Enquiry</h5>
                </div>
                <div className={"col-4 col-sm-6 col-md-4 col-lg-4  d-inline-flex pl-0 "}>
                  <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                    <InputLabel id="demo-simple-select-outlined-label">Search by Enq No/Quotation No/Ref No</InputLabel>
                    <OutlinedInput
                      id="dealer-search"
                      value={value}
                      // onKeyPress={(ev) => {
                      //   if (ev.key === 'Enter') {
                      //     setLoading(true);
                      //     loadFSEQuotation();
                      //     setLoading(false);
                      //     setPage(0);
                      //   }
                      // }}
                      onChange={(e) => {
                        setValue(e.target.value);
                        if (e.target.value.trim() === "") {
                          setLoading(true);
                          loadFSEQuotation(e.target.value);
                          setLoading(false);
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="Search by Enquiry no or Customer Name."
                            edge="start"
                            className="col-4 col-sm-6 col-md-4 col-lg-4  d-inline-flex pl-3 icon-margin"
                            disabled={value === "" || value === null || !value}
                            onClick={(e: any) => {
                              setLoading(true);
                              loadFSEQuotation(value);
                              setLoading(false);
                              setPage(0);
                            }}
                          >
                            <SearchIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={240} margin="dense" className="rounded-input-specialPrice bd-highlight mw-180p mr-2 mb-2 inputWidthQuotation" />
                  </FormControl>
                </div>
                <div className="col-4 col-sm-12 col-md-4 col-lg-4 d-inline-flex date-picker-50">
                  <DatePicker
                    startDate={startDate}
                    endDate={endDate}
                    setstartDate={setstartDate}
                    setendDate={setendDate}
                    onChange={(start: any, end: any) => {
                      setstartDate(start);
                      setendDate(end);
                    }}
                  />
                </div>
                <div className={"col-4 col-sm-6 col-md-4 col-lg-4  d-inline-flex pl-0 "}>
                  <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                    <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={filterByStatus}
                      onChange={(e) => {
                        setFilterByStatus(e.target.value)
                      }}
                      label="Status"
                    >
                      <MenuItem key={"ALL_STATUS"}
                        value={"All"}>All</MenuItem>
                      {quotationListStatus && quotationListStatus.data && quotationListStatus.data.data && quotationListStatus.data.data[0] && quotationListStatus.data.data[0].quotationStatusList.map((status: any, i: any) => (
                        <MenuItem key={i} value={status.title}>{status.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                {sessionStorage.getItem('userType') === 'FSE' && (
                  <div className="mt-4 d-flex flex-row">
                    <div className="col-2 col-sm-6 col-md-2 col-lg-2  d-inline-flex pl-0">
                      <TextField
                        id="customerId"
                        variant="outlined"
                        size="small"
                        onClick={handleClick}
                        value={selectedCustomerId}
                        label="Select By Customer"
                        aria-controls="customized-menu"
                        className="commonRoundedInputs w-100"
                        aria-haspopup="true"
                        placeholder="Filter By Customer"
                      >
                      </TextField>
                      <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        className="dealer-custom-dropdown"
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <div>
                          <div className="mt-2">
                            <div className="searchDealer">
                              <IconButton
                                edge="start"
                                className="ml-2"
                                disabled
                              >
                                <SearchIcon fontSize="inherit" />
                              </IconButton>
                              <TextField value={searchTextByID} onChange={(e) => { onSearchCustomerId(e.target.value) }}
                                size="small" className="ml-n2 mt-1" placeholder="Search Customer(s)" variant="outlined" />
                            </div>
                          </div>
                          {customerId && customerId.length > 0 && customerId.map((sapId: any, i: any) => (
                            <Grid key={i} container className="border-bottom">
                              <MenuItem key={i} value={sapId.sapCustomerId}>
                                <ListItemText primary={sapId.sapCustomerId} onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleClose() }} /><p className="px-5" onClick={(event) => { handleClickSapId(event, sapId.name, sapId.sapCustomerId); handleClose() }}> {" "} {" "} {sapId.name}</p>
                              </MenuItem>
                            </Grid>
                          ))}
                          {customerId && customerId.length === 0 && (
                            <Grid container >
                              <div className="d-flex justify-content-center text-center mx-auto">No Customers found.</div>
                            </Grid>
                          )}
                        </div>
                      </StyledMenu>
                    </div>
                    <div className="col-2 col-sm-6 col-md-2 col-lg-2 d-inline-flex pl-0 pr-0">
                      <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                        <InputLabel id="demo-simple-select-outlined-label">Select By FSE</InputLabel>
                        <Select
                          labelId="demo-simple-select-outlined-label"
                          id="demo-simple-select-outlined"
                          value={fseName}
                          onChange={(e) => {
                            setFseName(e.target.value)
                          }}
                          label="Select By FSE"
                        >
                          <MenuItem value={"All"}> {sessionStorage.getItem('userType') === 'FSE' ? "All FSE" : "All"} </MenuItem>
                          {fseList && fseList.data && fseList.data.data && fseList.data.data.length > 0 && fseList.data.data.map((fse: any, i: any) => (
                            <MenuItem key={fse.name}
                              value={fse.webApplicationLoginId}>{fse.name} - {fse.webApplicationLoginId}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                    <div className="col-2 offset-1 row">
                      <span className="myOrders text-my-quotation cursor-pointer my-quotation row pl-3"
                        onClick={() => { openQuotationAPI() }}><LaunchIcon fontSize="inherit"
                          className={classes.actionButtonOpenQuotation} />Open Quotations</span>
                    </div>
                    <div className='offset-2 px-4 col-8 row'>
                      <Button variant="contained" className="cancel-button font-size-11 mr-3" onClick={() => resetFilters()}>Reset Filters</Button>
                      <Button
                        className="font-size-11 addOrderEnqButton"
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setPage(0);
                          loadFSEQuotation(value);
                        }}
                      >Search
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          : ""}
        {sessionStorage.getItem('userType') !== 'FSE' ?
          <div className="col-12 d-flex flex-row padding-left-quotation-list mt-3 p-0">
            <div className="d-flex align-items-center padding-left-quotation-list">
              <h5 className="pl-0 mb-2 mt-2 page-sub-title padding-left-quotation-list mr-3">Quotation list</h5>
            </div>
            <div className="ml-auto row">
              <div className="row pr-3">
                <div className="mt-0 ">
                  <div>
                    <Checkbox checked={myQuotation} className="my-checkbox" color="secondary"
                      onChange={handleQuotation}
                    />
                  </div>
                </div>
                <span className="myOrders text-my-quotation my-quotation">My Quotations</span>
                <div className="mt-0 ">
                </div>
                <span className="myOrders text-my-quotation cursor-pointer my-quotation pl-3" onClick={() => { openQuotationAPI() }}><LaunchIcon fontSize="inherit" className={classes.actionButtonOpenQuotation} />Open Quotations</span>
              </div>
              <div className="pl-2 pr-2">
                <FormControl variant="outlined" className="">
                  <InputLabel htmlFor="dealer-search" className="pl-2 pr-2" margin="dense">Search by Enq No/Quotation No/Ref No</InputLabel>
                  <OutlinedInput
                    id="dealer-search"
                    value={value}
                    // onKeyPress={(ev) => {
                    //   if (ev.key === 'Enter') {
                    //     setLoading(true);
                    //     loadQuotation();
                    //     setLoading(false);
                    //     setPage(0);
                    //   }
                    // }}
                    // onChange={handlechange}
                    onChange={(e) => {
                      setValue(e.target.value);
                      if (e.target.value.trim() === "") {
                        setLoading(true);
                        loadQuotation(e.target.value);
                        setLoading(false);
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Search by Enquiry no or Customer Name."
                          edge="start"
                          className="pl-3 icon-margin"
                          disabled={value === "" || value === null || !value}
                          onClick={(e: any) => {
                            setLoading(true);
                            loadQuotation(value);
                            setLoading(false);
                            setPage(0);
                          }}
                        >
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={240} margin="dense" className="rounded-input-specialPrice bd-highlight mw-180p mr-2 mb-2 inputWidthQuotation" />
                </FormControl>
              </div>
              <div className="pl-0">
                <FormControl size="small" variant="outlined" className="rounded-input-dealer w-100">
                  <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={status}
                    onChange={(e: any) => {
                      setStatus(e.target.value)
                      setPage(0);
                    }}
                    label="Status"
                  >
                    <MenuItem key={"ALL_STATUS"}
                      value={"All"}>All</MenuItem>
                    {quotationListStatus && quotationListStatus.data && quotationListStatus.data.data && quotationListStatus.data.data[0] && quotationListStatus.data.data[0].quotationStatusList.map((status: any, i: any) => (
                      <MenuItem key={i} value={status.title}>{status.title}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className="ml-2">
                <TextField
                  id="kamList"
                  select
                  label="Select User"
                  variant="outlined"
                  className="rounded-input-dealer bd-highlight mr-0"
                  size="small"
                  onChange={(e: any) => { setUser(e.target.value); setPage(0); }}
                  value={user}
                >
                  {quotationUser && quotationUser.data && quotationUser.data.data && quotationUser.data.data.users.map((status: any, i: any) => (
                    <MenuItem key={i} value={status.webApplicationLoginId
                    }>{status.name}</MenuItem>
                  ))}
                </TextField>
              </div>
              <div className="">
                <Button
                  variant="contained"
                  color="secondary"
                  className="createFavourite ml-2 margin"
                  startIcon={<AddIcon />}
                  onClick={() => { history.push('/createenquiry') }}
                >
                  Create Quotation
                </Button>
              </div>
            </div>
          </div>
          : ""}
      </div>
      <div className="row mt-4">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="d-flex align-items-center">
            {sessionStorage.getItem('userType') === 'FSE' ?
              <div className="d-flex align-items-center padding-left-quotation-list px-2">
                <h5 className="pl-0 mt-1 mb-1 page-sub-title padding-left-quotation-list mr-3">Quotation list</h5>
              </div>
              : ""}
            {/* <h5 className="page-sub-title">List of Orders</h5> */}
            <div className="row legendItem ml-auto">
              {/* <span className="orangedot"></span>
                            <span className="legendItemLabel mr-4">Return Created</span> */}
              {sessionStorage.getItem('userType') !== 'FSE' ?
                <>
                  <span className="dot"></span>
                  <span className="legendItemLabel pr-2">Stock not available</span>
                  <Tooltip title="No Stock. Please contact Al Futtaim Sales Executives for availability." placement="top">
                    <IconButton className={classes.actionButton} size="small">
                      <InfoIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </>
                : ""}
              {sessionStorage.getItem('userType') === 'FSE' ?
                <>              <div className="mt-1"><span className="row dot mt-3"></span></div>
                  <div><span className="row legendItemLabel pr-3 mt-3">Stock not available</span></div>
                  <Tooltip title="No Stock. Please contact Al Futtaim Sales Executives for availability." placement="top">
                    <IconButton className={classes.actionButton} size="small">
                      <InfoIcon fontSize="inherit" className="" />
                    </IconButton>
                  </Tooltip>
                </>
                : ""}
              {sessionStorage.getItem('userType') === 'FSE' ?
                <div className="row mr-3 px-2 mb-2">
                  <Button
                    variant="contained"
                    color="secondary"
                    className="createFavourite ml-2 margin"
                    startIcon={<AddIcon />}
                    onClick={() => {
                      if (sessionStorage.getItem('userType') === 'FSE') {
                        setIsOrderEnquiryCreated(true);
                        handleChooseCustomer();
                        dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                      } else {
                        dispatch(getUniqueEnquirySessionId(getUniqueTimestamp()));
                        history.push('/createenquiry');
                      }
                    }}
                  >
                    Create Quotation
                  </Button>
                </div>
                : ""}
            </div>
          </div>
        </div>
      </div>
      {openChooseCustomerDialog && <ChooseCustomerDialog openChooseCustomerDialog={openChooseCustomerDialog} openCloseChooseCustomer={openCloseChooseCustomer} isOrderEnquiryCreated={isOrderEnquiryCreated} />}
      <QuotationList handlePage={handlePage} status={status} value={value} myQuotation={myQuotation} user={user} page={page} loadQuotation={loadQuotation} loadFSEQuotation={loadFSEQuotation} />
      <OpenQuotationDialog openQuotation={openQuotation}
        handleCloseOpenQuotation={handleCloseOpenQuotation} setOpenErrorSnackbar={setOpenErrorSnackbar}
        handleopenCloseSnackbar={handleopenCloseSnackbar} showErrorMessage={showErrorMessage}
        openQuotationAPI={openQuotationAPI} />
      <OpenQuotationCancelDialog openQuotationAPI={openQuotationAPI} />
    </div >
  )
}
export default Quotation
