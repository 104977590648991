/* eslint-disable */
import React, { Fragment, Dispatch, useState, useEffect } from "react";
// import ReturnRequestDialog from './ReturnRequestDialog'
import "../OrderHistory/OrderHistory.css";
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Collapse from '@material-ui/core/Collapse';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Add from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { RESTRICTED_ACCESS } from "../../constants/restrictedAccessConstants";
import { getSellingPrice } from "../../helpers/commonmethod";
// import OrderHistoryPayment from "./OrderHistoryPayment";
// import { createUpdateEnquiryService } from '../../services/createUpdateEnquiry.service ';
import "./Quotation.css"
import LaunchIcon from "@material-ui/icons/Launch"
import VisibilityIcon from "@material-ui/icons/Visibility";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpIcon from "@material-ui/icons/Help"
import CancelIcon from "@material-ui/icons/CancelRounded"
import RequestOrder from "./RequestOrder";
import UploadLpo from "./UploadLpo";
import ViewQuotation from "./ViewQuotation";
import CancelQuotation from "./CancelQuotation";
import { useDispatch, useSelector } from "react-redux";
import { quotation } from "../../services/quotation.service";
import _, { get } from "lodash";
import moment from "moment-timezone";
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import ViewLPO from "./ViewLpo";
import { useHistory } from "react-router-dom";
import { downloadQuotation } from "../../store/actions/quotation.action";
import { getNewBrand } from "../../common/components/util";
import Tooltip from '@material-ui/core/Tooltip';
import { viewLPO } from "../../store/actions/quotation.action";
import { Button } from "@material-ui/core";
import { verifyQuotationQueueCheck } from "../../store/actions/createUpdateEnquiry.actions";
// import parse from 'emailjs-mime-parser';
// import * as MailParser from 'mailparser';
// import * as nodemailer from 'nodemailer';
import { getNewlyAddedBrand } from "../../common/components/util";
import { getCustomerDetailsByCustomerID } from "../../store/actions/dealer.action";

const useStyles = makeStyles((theme) => ({
  collapseBg: {
    background: 'rgba(66, 94, 108, 0.07)'
  },
  rowHighlight: {
    background: 'rgba(67, 95, 107, 0.15)',
    color: '#666666',
    fontWeight: 700
  },
  orangeRowHighlight: {
    background: 'rgba(240, 223, 0, 0.12)',
    color: '#666666',
    fontWeight: 700
  },
  bodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  bodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  },
  collapseIcon: {
    color: '#000000',
    fontWeight: 500,
  },
  tableHeadSmall: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 40,
  },
  tableHeadSmallBrand: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 200,
  },
  tableHeadCreatedBY: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 200,
  },
  tableHead: {
    color: '#133F8A',
    fontWeight: 900,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    lineHeight: 0,
    width: 140,
  },
  subTableHead: {
    color: '#444444',
    fontWeight: 800,
    width: 135,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHead1: {
    color: '#444444',
    fontWeight: 800,
    width: 90,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHead2: {
    color: '#444444',
    fontWeight: 800,
    width: 97,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHead3: {
    color: '#444444',
    fontWeight: 800,
    width: 150,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHead4: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHead5: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHead6: {
    color: '#444444',
    fontWeight: 800,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTableHeadSmall: {
    color: '#444444',
    fontWeight: 800,
    width: 40,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable: {
    color: '#444444',
    fontWeight: 350,
    width: 135,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable1: {
    color: '#444444',
    fontWeight: 350,
    width: 90,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable2: {
    color: '#444444',
    fontWeight: 350,
    width: 97,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable3: {
    color: '#444444',
    fontWeight: 350,
    width: 150,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable4: {
    color: '#444444',
    fontWeight: 350,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable5: {
    color: '#444444',
    fontWeight: 350,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  subTable6: {
    color: '#444444',
    fontWeight: 350,
    width: 140,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
  },
  actionButton: {
    color: '#1A9FE0',

  },
  actionRequest: {
    color: "white",
    background: "#1A9FE0",
    border: "2px solid #1A9FE0",
    borderRadius: "8px",
    width: "1rem",
    height: "1rem",
    "&:hover": {
      background: "#1A9FE0",
      color: "white !important",
      borderRadius: "14px",
      width: "1rem",
      height: "1rem",

    },
  },
  requestQuestion: {
    color: '#1A9FE0',
    "&:hover": {
      background: "yellow",

    },

  },
  subTableBodyContent: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 140,
  },
  subTableBodyContentSmall: {
    color: '#666666',
    fontWeight: 600,
    fontSize: '0.75rem',
    fontFamily: 'Nunito',
    padding: 10,
    width: 40,
  }
}));
const QuotationList = (props: any) => {
  const { page } = props

  const { handlePage, status, value, myQuotation, user, loadQuotation, loadFSEQuotation } = props
  const [open, setOpen] = React.useState(0)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openAchorEl, setOpenAchorEl] = React.useState(false);
  const [openRequestOrder, setOpenRequestOrder] = React.useState(false);
  const [openViewQuotation, setOpenViewQuotation] = React.useState(false);
  const [openViewLPO, setOpenViewLPO] = React.useState(false);
  const [fileExtention, setFileExtension] = React.useState('pdf');
  const [openUploadLpo, setOpenUploadLpo] = React.useState(false);
  const [openCancelQuotation, setÖpenCancelQuotation] = React.useState(false);
  const [quotationStatus, setQuotationStatus] = React.useState("")
  const [enquiryNumber, setEnquiryNumber] = React.useState("")
  const [quotationNumber, setQuotationNumber] = React.useState(null);
  const [sapId, setSAPId] = React.useState(null);
  const [brandId, setBrandId] = React.useState("");
  const [orderNumber, setOrderNumber] = React.useState("")
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [errorMessage, showErrorMessage] = React.useState<any>(null);
  const [successMessage, showSuccessMessage] = React.useState<any>(null);
  const [viewQuotationUrl, setViewQuotationUrl] = React.useState("")
  const [lpo, setLpoAttachments] = React.useState<any>([]);
  const [isUpdate, setIsUpdate] = React.useState<any>(false)

  const dispatch: any = useDispatch()
  const { quotation }: any = useSelector((state: any) => state.quotation)
  const classes = useStyles();
  const history: any = useHistory();
  const { profileDetails, isSellingPriceData } = useSelector((state: any) => state.profileDetails)
  const markupPercentage = get(profileDetails, 'data.data.customerInfo.markupPercentage', false);
  let sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  if (sessionStorage.getItem('userType') === 'FSE') {
    sapCustomerId = sapId;
  } else {
    sapCustomerId = _.get(profileDetails, "data.data.basicInfo.sapCustomerId", "");
  }
  const webApplicationLoginId: any = sessionStorage.getItem("webApplicationLoginId");
  const customerTierType = get(profileDetails, 'data.data.customerInfo.customerTierType', '');
  const customerTypeAccess = get(profileDetails, 'data.data.basicInfo.customerTypeAccess', '');

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const id = open ? "simple-popover" : undefined

  const handleClose = () => {
    setOpenAchorEl(false);
    setAnchorEl(null);
  }


  const handleCloseRequestOrder = () => {
    setOpenRequestOrder(false)
    setOpenAchorEl(false);
    setAnchorEl(null);
  }

  const handleCloseViewQuotation = () => {
    setOpenViewQuotation(false);
    setOpenAchorEl(false);
    setAnchorEl(null);
    // if (sessionStorage.getItem('userType') === 'FSE') {
    //   loadFSEQuotation()
    // } else {
    //   loadQuotation();
    // }
  }

  const handleCloseViewLPO = () => {
    setOpenViewLPO(false);
  }

  const handleUploadLpo = () => {
    setOpenUploadLpo(false)
    setOpenAchorEl(false);
    setAnchorEl(null);
  }

  const handleCancelQuotation = () => {
    setÖpenCancelQuotation(false);
    setOpenAchorEl(false);
    setAnchorEl(null);
  }

  const handlePageChange = (event: any, page: any) => {
    handlePage(page)
  }

  const handleDownload = async (type: any) => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId, type))
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
    } else {
      if (type === 'pdf') {
        fetch(data).then(resp => resp.arrayBuffer()).then(resp => {
          const file = new Blob([resp], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          const link = document.createElement('a');
          link.href = fileURL;
          link.download = "quotation" + ".pdf";
          link.click();
        });
      } else {
        window.open(data && data.data, "_blank");
      }
      // window.open(data && data.data)
    }
  }

  const handleViewQuotation = async () => {
    let data: any = await dispatch(downloadQuotation(enquiryNumber, quotationNumber, brandId))
    if (data && data.error) {
      showErrorMessage(data && data.error)
      setOpenErrorSnackbar(true);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000)
      setOpenViewQuotation(false)
    } else {
      // window.open(data && data.data)
      if (data && data.status == 'S' || typeof data !== 'object') {
        setOpenViewQuotation(true)
        setViewQuotationUrl(data)
      }
    }
  }

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };

  const handleViewLPO = async (orderNo: any, quotationNo: any) => {
    if (quotationNo) {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, enquiryNumber, sapCustomerId, quotationNo, ""))
      if (data && data.statusText && data.statusText.data.success === false) {
        showErrorMessage(data && data.statusText && data.statusText.data.message && data.statusText.data.message.message)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
        setOpenViewLPO(false)
      } else {
        if (lpo && lpo.length > 0) {
          const fileName = lpo[0] && lpo[0].url.substring(lpo[0].url.lastIndexOf('/') + 1);
          const fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
          setFileExtension(fileExtension.toLowerCase());
          if (fileExtension === 'pdf') {
            setViewQuotationUrl(data && typeof data === 'string' ? data : lpo[0].url);
            setOpenViewLPO(true)
            // fetch(lpo[0].url ? lpo[0].url : data).then(resp => resp.arrayBuffer()).then(resp => {
            //   const file = new Blob([resp], { type: 'application/pdf' });
            //   const fileURL = URL.createObjectURL(file);
            //   setViewQuotationUrl(fileURL)
            //   if (fileURL) {
            //     setOpenViewLPO(true)
            //   }
            // });
          } else if (fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg') {
            setViewQuotationUrl(lpo[0].url);
            if (lpo[0].url) {
              setOpenViewLPO(true)
            }
          }
          else if (fileExtension === 'msg' || fileExtension === 'eml') {
            setViewQuotationUrl(lpo[0].url);
            if (lpo[0].url) {
              setOpenViewLPO(true)
            }
          }
        }
      }
    } else {
      let data: any = await dispatch(viewLPO(webApplicationLoginId, enquiryNumber, sapCustomerId, "", orderNo))
      if (data && data.error) {
        showErrorMessage(data && data.error)
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false);
        }, 2000)
        setOpenViewLPO(false)
      } else {
        // window.open(data && data.data)
        if (data) {
          setOpenViewLPO(true)
          setViewQuotationUrl(data)
        }
      }
    }
  }

  const verifyQuotation = async (data: any, element: any) => {
    let res: any = await dispatch(verifyQuotationQueueCheck(element.enquiryNumber, data.B2bEnquiryId, data.sapCustomerId ? data.sapCustomerId : sapCustomerId));
    if (res && res.data && res.message && res.message.status == 'S') {
      if (res.data.success) {
        handleopenCloseErrorSnackbar(true);
        showSuccessMessage(res.message.message);
        showErrorMessage(null);
        if (sessionStorage.getItem('userType') === 'FSE') {
          loadFSEQuotation()
        } else {
          loadQuotation();
        }
      } else if (res.data === 'There is quotaion number already Present ') {
        handleopenCloseErrorSnackbar(true);
        showSuccessMessage(null);
        showErrorMessage(res.data);
        if (sessionStorage.getItem('userType') === 'FSE') {
          loadFSEQuotation()
        } else {
          loadQuotation();
        }
      } else if (!res.data.success) {
        handleopenCloseErrorSnackbar(true);
        showSuccessMessage(null);
        showErrorMessage('Quotation creation is in queue, Please press refresh button after 10 sec');
      }
    } else {
      showErrorMessage('Please press refresh button after 10 sec');
      handleopenCloseErrorSnackbar(true);
    }
  }

  return (
    <div>
      <Snackbar
        open={openErrorSnackbar}
        type={errorMessage ? 'error' : 'success'}
        handleopenClose={() => {
          handleopenCloseErrorSnackbar(false)
        }} message={successMessage || errorMessage} />
      <div className="row mt-1 pb-5 mb-5">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 mb-5 pb-5">
          <div className="cardCommon mh-300p">
            <TableContainer>
              <Table aria-label="table" >
                <TableHead className="w-100">
                  <TableRow>
                    <TableCell className={classes.tableHeadSmall}></TableCell>
                    <TableCell className={classes.tableHead}>Enquiry No.</TableCell>
                    {sessionStorage.getItem('userType') === 'FSE' && (
                      <TableCell className={classes.tableHead}>Customer Name</TableCell>
                    )}
                    <TableCell className={sessionStorage.getItem('userType') === 'FSE' ? classes.tableHeadCreatedBY : classes.tableHead}>Created By</TableCell>
                    <TableCell className={classes.tableHeadSmallBrand}>Brands</TableCell>
                    <TableCell className={classes.tableHead}> <h6 className="totalHead">Estimated Total</h6>
                      <div className="text-muted font-10 font-weight-600">(incl. of Vat)</div></TableCell>
                    <TableCell className={classes.tableHead}>
                      <h6 className="totalHead">Date</h6>
                    </TableCell>
                    <TableCell className={classes.tableHead}>Status</TableCell>
                    <TableCell className={classes.tableHead}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.records.map((data: any, index: any) => {
                    const { subEnquiries } = data || {};
                    const showViewEnquiry = sessionStorage.getItem('userType') === 'FSE' ? true : !sessionStorage.getItem('restrictFeat')?.includes(RESTRICTED_ACCESS.VIEW_ENQUIRY_DETAILS);
                    return (
                      <React.Fragment>
                        <TableRow key={data.B2bEnquiryId} hover role="checkbox" tabIndex={-1} onClick={() => setOpen(open === index + 1 ? -1 : index + 1)} className="hover-pointer" >
                          <TableCell className={classes.bodyContentSmall}>
                            <IconButton aria-label="expand row" size="small" className={classes.collapseIcon}>
                              {open > 0 && open === index + 1 ? <RemoveIcon fontSize="inherit" /> : <Add fontSize="inherit" />}
                            </IconButton>
                          </TableCell>
                          <TableCell className={classes.bodyContent}>{data.B2bEnquiryId
                          }</TableCell>
                          {sessionStorage.getItem('userType') === 'FSE' && (
                            <TableCell className={classes.bodyContent}>{data.customerName
                            }</TableCell>
                          )}
                          <TableCell className={classes.bodyContent}>{sessionStorage.getItem('userType') === 'FSE' ? data.userName : data.createdByName}</TableCell>
                          <TableCell className={classes.bodyContent}>{data && data.brand && data.brand.includes("OES") && (customerTierType && customerTierType.toLowerCase() !== 'workshop' || customerTypeAccess && !customerTypeAccess.includes("workshop")) ? data.brand.replace("OES", "ALAC") : getNewBrand(data.brand)}</TableCell>
                          <TableCell align="left" className={classes.bodyContent}>
                            {parseFloat(getSellingPrice(!!data.estimatedGrandTotal && data.estimatedGrandTotal, isSellingPriceData, markupPercentage)).toFixed(2)}
                          </TableCell>
                          <TableCell className={classes.bodyContent}>{moment(data.createdOn).tz('Asia/Dubai').format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                          <TableCell className={classes.bodyContent}>{data.status}</TableCell>
                          <TableCell>
                            {showViewEnquiry &&
                              <Tooltip title="View Order Enquiry" placement="top" onClick={async () => { }}>
                                <IconButton className={classes.actionButton} size="small"
                                  onClick={async () => {
                                    if (sessionStorage.getItem('userType') === 'FSE') {
                                      await dispatch(getCustomerDetailsByCustomerID(data?.sapCustomerId))
                                    }
                                    sessionStorage.setItem('enquiryModeDetails', JSON.stringify({
                                      mode: 'view',
                                      masterId: data.B2bEnquiryId,
                                      quotation: true
                                    }))
                                    history.push('/viewenquiry');
                                  }}
                                >
                                  <LaunchIcon fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.collapseBg}>
                          <TableCell className="p-0 w-100" colSpan={12}>
                            <Collapse in={open > 0 && open === index + 1 ? true : false}>
                              <Box>
                                <Table aria-label="table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell className={classes.subTableHeadSmall}>
                                      </TableCell>
                                      <TableCell className={classes.subTableHead}>
                                        <span className=""></span>
                                        Quotation Number</TableCell>
                                      <TableCell className={`${classes.subTableHead1} `}>Brand</TableCell>
                                      <TableCell className={`${classes.subTableHead2}`}>Order ID</TableCell>
                                      <TableCell className={classes.subTableHead3}>Reference Number</TableCell>
                                      <TableCell className={classes.subTableHead4}>
                                        Quotation Expiry Date
                                      </TableCell>
                                      <TableCell className={`${classes.subTableHead5} padding-status`}>Status</TableCell>
                                      <TableCell className={`${classes.subTableHead6} padding-action`}>Action</TableCell>
                                    </TableRow>
                                    {data && data.subRecords && data.subRecords.length > 0 && data.subRecords.map((ele: any) => {
                                      return (
                                        <TableRow>
                                          <TableCell className={classes.subTableHeadSmall}>
                                            {!ele.isNowOrderQuotation &&
                                              (
                                                <span className="orangedot"></span>
                                              )}
                                          </TableCell>
                                          <TableCell className={classes.subTable}>
                                            <span className=""></span>
                                            {ele.quotationStatus == "Queued" || ele.isEnqueued ? (<Button variant="contained" className="submit-button font-size-11" onClick={() => verifyQuotation(data, ele)}>
                                              Refresh
                                            </Button>)
                                              : ele.quotationNumber
                                            }
                                          </TableCell>
                                          <TableCell className={`${classes.subTable1}`}>
                                            {ele && ele.make === 'OES' && (customerTierType && customerTierType.toLowerCase() !== 'workshop' || customerTypeAccess && !customerTypeAccess.includes("workshop")) ? getNewlyAddedBrand(ele.make) : getNewBrand(ele.make)}
                                          </TableCell>
                                          <TableCell className={`${classes.subTable2}`}>{ele.orderNumber ? ele.orderNumber : ele.backOrderNumber}</TableCell>
                                          <TableCell className={classes.subTable3}>{ele.enquiryReferenceNumber}</TableCell>
                                          <TableCell className={classes.subTable4}>
                                            {moment(ele.expiryDate).tz('Asia/Dubai').format('YYYY-MM-DD')}
                                          </TableCell>
                                          <TableCell className={`${classes.subTable5} padding-status`}>{ele.quotationStatus}</TableCell>
                                          <TableCell className={`${classes.subTable6} padding-action`}>
                                            <div style={{ background: "", }}>
                                              {<IconButton aria-describedby={id} onClick={(event: any) => {
                                                handleClick(event);
                                                setQuotationStatus(ele.quotationStatus);
                                                setQuotationNumber(ele.quotationNumber);
                                                setSAPId(data.sapCustomerId);
                                                setEnquiryNumber(data.B2bEnquiryId);
                                                setBrandId(ele.brandId);
                                                setOrderNumber(ele.orderNumber);
                                                setLpoAttachments(ele.lpoAttachments)
                                              }} className={classes.actionButton} size="small">
                                                <div className="px-2 pt-1 pb-1 boxshadow-icon"> <MoreVertIcon fontSize="inherit" className={classes.actionButton} /> </div>
                                              </IconButton>}
                                            </div>
                                          </TableCell>
                                        </TableRow>)
                                    })}
                                  </TableHead>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell >
                        </TableRow >
                      </React.Fragment >
                    );
                  })}
                </TableBody>
              </Table>
              {quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.records.length === 0 &&
                <div className="mt-5 justify-content-center mx-auto text-center d-flex">No Quotations Found.</div>
              }
            </TableContainer>
            <div className="col-12 d-flex justify-content-end">
              <TablePagination
                className="pagination"
                rowsPerPageOptions={[10]}
                component="div"
                count={quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.counts ? parseFloat(quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.counts) : quotation && quotation.data && quotation.data.data && quotation.data.data.data && quotation.data.data.data.records && quotation.data.data.data.records.length ? quotation.data.data.data.records.length : 10}
                rowsPerPage={10}
                page={page}
                onPageChange={handlePageChange}
              />
            </div>
          </div>
        </div>
        <Menu
          id={id}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorReference="anchorEl"
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          className="ml-3 mt-5 "
        >
          <MenuItem disabled={quotationNumber ? false : true} className="pl-3 pr-3" onClick={handleViewQuotation}>
            <div className="pr-2">
              <IconButton className={`${classes.actionButton} `} size="small"
              >
                <VisibilityIcon fontSize="inherit" />
              </IconButton>
            </div>
            View Quotation</MenuItem>

          {quotationStatus !== "Open" && quotationStatus !== "Order requested" && quotationStatus !== "Order placed" && quotationStatus !== "Cancelled" && quotationStatus !== "Expired" && <MenuItem disabled={quotationNumber ? false : true} className="quotation-text" onClick={handleDownload}>
            <div className="pr-2">
              <IconButton className="exportExcel " size="small" disabled={quotationNumber ? false : true}>
                <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => handleDownload('pdf')} />
              </IconButton>
            </div>
            Download Quotation
          </MenuItem>}
          {quotationStatus !== "Open" && quotationStatus !== "Order requested" && quotationStatus !== "Order placed" && quotationStatus !== "Cancelled" && quotationStatus !== "Expired" && <MenuItem disabled={quotationNumber ? false : true} className="quotation-text" onClick={handleDownload}>
            <div className="pr-2">
              <IconButton className="exportExcel " size="small" disabled={quotationNumber ? false : true}>
                <ArrowDownwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => handleDownload('xls')} />
              </IconButton>
            </div>
            Download Quotation In Excel
          </MenuItem>}
          {quotationStatus === "Open" && sessionStorage.getItem('userType') !== 'FSE' && <MenuItem className="quotation-text" disabled={quotationNumber ? false : true} onClick={() => { setOpenRequestOrder(true) }}>
            <div className="pr-2">
              <IconButton className="exportExcel " size="small">
                <ArrowForwardIcon className={` ${classes.actionRequest}`} style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => { }} />
              </IconButton>
            </div>
            <div className=""> Request Order </div>
            <div className="pr-2">
              <IconButton className={`${classes.actionButton} `} size="small">
                <div className="">
                  <Tooltip title="For further processing by Al-Futtaim please Request Order and upload LPO." placement="top">
                    <HelpIcon fontSize="inherit" className="float-right" />
                  </Tooltip>
                </div>
              </IconButton>
            </div>
          </MenuItem>}
          {(quotationStatus === "Order requested" || quotationStatus === "Order placed") && sessionStorage.getItem('userType') !== 'FSE' &&
            <MenuItem className="pl-3 pr-3" onClick={() => {
              handleViewLPO(orderNumber, quotationNumber);
              // setOrderNumber(subEnquiry && subEnquiry.isAirOrder ? subEnquiry.backOrderNumber : subEnquiry.orderNumber);
            }}>
              <div className="pr-2">
                <IconButton className={`${classes.actionButton} `} size="small">
                  <VisibilityIcon fontSize="inherit" />
                </IconButton>
              </div>
              View LPO</MenuItem>}
          {sessionStorage.getItem('userType') !== 'FSE' && (<>
            {quotationStatus !== "Open" && quotationStatus !== "Order requested" && quotationStatus !== "Order placed" && !orderNumber && quotationStatus !== "Cancelled" && quotationStatus !== "Expired" && <MenuItem disabled={quotationNumber ? false : true} onClick={() => { setOpenUploadLpo(true) }} className="quotation-text">
              <div className="pr-2">
                <IconButton className="exportExcel" size="small" >
                  <ArrowUpwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => { }} />
                </IconButton>
              </div>
              {sessionStorage.getItem('userType') === 'FSE' ? "Upload LPO / Confirmation Doc." : "Upload LPO"}
              <div>
                <div className="pr-2">
                  <IconButton className={`${classes.actionButton} `} size="small">
                    <div className="">
                      <Tooltip title="Order will not be processed by Al-Futtaim unless LPO is uploaded." placement="top">
                        <HelpIcon className="float-right" fontSize="inherit" />
                      </Tooltip>
                    </div>
                  </IconButton>
                </div>
              </div>
            </MenuItem>}
            {quotationStatus !== "Open" && quotationStatus !== "Order requested" && quotationStatus !== "Order placed" && quotationStatus !== "Cancelled" && quotationStatus !== "Expired" && <MenuItem disabled={quotationNumber ? false : true} className=" quotation-text" onClick={() => { setÖpenCancelQuotation(true) }}>
              <div className="pr-2">
                <IconButton className={`${classes.actionButton} `} size="small">
                  <CancelIcon fontSize="inherit" />
                </IconButton >
              </div>
              Cancel Quotation
            </MenuItem>}
          </>)}
          {sessionStorage.getItem('userType') === 'FSE' && (<>
            {quotationStatus !== "Order requested" && quotationStatus !== "Order placed" && !orderNumber && lpo && lpo.length === 0 && quotationStatus !== "Cancelled" && quotationStatus !== "Expired" &&
              <MenuItem
                disabled={quotationNumber ? false : true}
                onClick={() => { setOpenUploadLpo(true) }} className="quotation-text">
                <div className="pr-2">
                  <IconButton className="exportExcel" size="small" >
                    <ArrowUpwardIcon style={{ border: "2px solid #1A9FE0", borderRadius: "8px" }} fontSize="inherit" onClick={() => { }} />
                  </IconButton>
                </div>
                Upload LPO
                <div>
                  <div className="pr-2">
                    <IconButton className={`${classes.actionButton} `} size="small">
                      <div className="">
                        <Tooltip title="Order will not be processed by Al-Futtaim unless LPO is uploaded." placement="top">
                          <HelpIcon className="float-right" fontSize="inherit" />
                        </Tooltip>
                      </div>
                    </IconButton>
                  </div>
                </div>
              </MenuItem>}
            {lpo && lpo.length > 0 &&
              <MenuItem className="pl-3 pr-3" onClick={() => {
                handleViewLPO(orderNumber, quotationNumber);
                // setOrderNumber(subEnquiry && subEnquiry.isAirOrder ? subEnquiry.backOrderNumber : subEnquiry.orderNumber);
              }}>
                <div className="pr-2">
                  <IconButton className={`${classes.actionButton} `} size="small">
                    <VisibilityIcon fontSize="inherit" />
                  </IconButton>
                </div>
                View LPO</MenuItem>}
            {quotationStatus !== "Order requested" && quotationStatus !== "Order placed" && quotationStatus !== "Cancelled" && quotationStatus !== "Expired" &&
              <MenuItem
                disabled={quotationNumber ? false : true}
                className=" quotation-text"
                onClick={() => { setÖpenCancelQuotation(true) }}>
                <div className="pr-2">
                  <IconButton className={`${classes.actionButton} `} size="small">
                    <CancelIcon fontSize="inherit" />
                  </IconButton >
                </div>
                Cancel Quotation
              </MenuItem>}
          </>)}
        </Menu>
      </div>
      <RequestOrder openRequestOrder={openRequestOrder} handleCloseRequestOrder={handleCloseRequestOrder} brandId={brandId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} handleClose={handleClose} isUpdate={isUpdate} />
      <ViewQuotation openViewQuotation={openViewQuotation} handleCloseViewQuotation={handleCloseViewQuotation} brandId={brandId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} quotationStatus={quotationStatus} viewQuotationUrl={viewQuotationUrl} loadQuotation={loadQuotation} loadFSEQuotation={loadFSEQuotation} />
      <UploadLpo openUploadLpo={openUploadLpo} handleUploadLpo={handleUploadLpo} brandId={brandId} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} />
      <CancelQuotation openCancelQuotation={openCancelQuotation} handleCancelQuotation={handleCancelQuotation} enquiryNumber={enquiryNumber} quotationNumber={quotationNumber} sapId={sapId} orderNumber={orderNumber} status={status} value={value} myQuotation={myQuotation} user={user} page={page} />
      <ViewLPO openViewLPO={openViewLPO} handleCloseViewLPO={handleCloseViewLPO} quotationNumber={quotationNumber} sapId={sapId} fileExtension={fileExtention} orderNumber={orderNumber} enquiryNumber={enquiryNumber} viewQuotationUrl={viewQuotationUrl} openRequestOrder={openRequestOrder} handleCloseRequestOrder={handleCloseRequestOrder} brandId={brandId} status={status} value={value} myQuotation={myQuotation} user={user} page={page} handleClose={handleClose} handleViewLPO={handleViewLPO} />
    </div>
  )
}
export default QuotationList