/* eslint-disable */
import React, { Fragment, useEffect, Dispatch } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import DeleteDialog from '../../common/components/deleteDialog';
import Visibility from "@material-ui/icons/Visibility";
import { AddNewAddressDailog } from './AddNewAddressDailog';
import { getShipToPartyAddress, AddShipToPartyAddress, deleteShipToPartyAddress, getSelectedShipToPartyAddress, UpdatedShipToPartyAddress, getTradeRegion, resetValues, getTransportationZone, setCustomerType, getDeliveryType, updateDeliveryType, sendToSAP, customerAcceptingDeliveryInUAE, submitAddressTab } from '../../store/actions/onboard.actions';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import AddIcon from '@material-ui/icons/Add';
import { MenuItem, Button, makeStyles, DialogContent, DialogContentText, DialogActions, FormGroup, Switch, Theme } from '@material-ui/core';
import { get } from 'lodash';
import FullscreenLoader from './../../common/components/FullscreenLoader';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Alert from '@material-ui/lab/Alert';
import ActionButtons from './ActionButtons';
import './shiptoparty.css';
import { Help } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { FormControl, FormControlLabel, Radio, RadioGroup, } from "@material-ui/core";
import SendToSAPDialog from './SendToSAPDialog';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import WarningIcon from "@material-ui/icons/Warning";
import DeleteIcon from '@material-ui/icons/Delete';
import SubmitDialog from '../newUserOnboard/submitYourRequestDialog';
import { withStyles, createStyles, createTheme } from '@material-ui/core/styles';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { getRefreshDataFromSAP, getShipToPartyDropDownValues } from "../../store/actions/dealer.action";
import { getNewBrand } from '../../common/components/util';
import { getNewlyAddedBrand } from "../../common/components/util";

const useStyles = makeStyles((theme) => ({
  footer: {
    paddingLeft: 220
  },
  icon: {
    float: "left",
    // margin: "0 10px px 0",
    height: "100%",
    fill: "white",
  },
  close: {
    color: '#000000',
    fontWeight: 900
  },
  correctionText: {
    color: '#616161',
    fontSize: '0.875rem',
    fontFamily: 'Nunito',
    fontWeight: 700,
    textTransform: 'inherit'
  },
  closeButton: {
    backgroundColor: '#EAEAEA',
    width: 120,
    fontWeight: 800,
    textTransform: 'capitalize',
    fontFamily: 'Nunito',
    fontSize: '0.688rem',
    color: '#666666',
    borderRadius: '2.5rem',
    height: 35,
    textAlign: 'center',
    marginRight: 24
  },
}));

const theme = createTheme({
  palette: {
    primary: {
      main: '#1A9FE0'
    },
  },
});

const orgIdBrandsMapping: any = {
  "2001": "Toyota",
  "2002": "Honda",
  "2003": "Famco",
  "2380": "TE",
  "2195": 'OES'
}

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 60,
      height: 28,
      padding: 0,
    },
    switchBase: {
      padding: 5,
      color: '#FFFFFF',
    },
    thumb: {
      width: 17,
      height: 17,
    },
    track: {
      background: '#444444',
      opacity: '1 !important',
      borderRadius: '2rem',
      position: 'relative',
      '&:before, &:after': {
        display: 'inline-block',
        position: 'absolute',
        top: '50%',
        width: '50%',
        transform: 'translateY(-50%)',
        color: '#FFFFFF',
        textAlign: 'center',
        fontSize: 10,
        fontFamily: 'Nunito',
        fontWeight: 500
      },
      '&:before': {
        content: '"Yes"',
        left: 7,
        opacity: 0,
      },
      '&:after': {
        content: '"No"',
        right: 6,
      },
    },
    checked: {
      '&$switchBase': {
        color: '#FFFFFF',
        transform: 'translateX(33px)',
      },
      '& $thumb': {
        backgroundColor: '#FFFFFF',
      },
      '& + $track': {
        '&:before': {
          opacity: 1,
        },
        '&:after': {
          opacity: 0,
        }
      },
    },
  }),
)(Switch);

export const ShipToParty = (props: any) => {
  const classes = useStyles();
  const dispatch: Dispatch<any> = useDispatch();
  const [successMessage, setSuccessMessage] = React.useState<any>(null);
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [isValid, setIsValid] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState(false);
  const [openConfirmationPopup, setOpenConfirmationPopup] = React.useState(false)
  const [status, setStatus] = React.useState<any>('');
  const [shippingAddress, setShippingAddress] = React.useState<any>(null);
  const { selectedNewDealer, getDataFromSAPOnClickRefresh, shippingDropdownValues }: any = useSelector((state: any) => state.dealer);
  const b2bUserId: any = selectedNewDealer && selectedNewDealer.createdByB2bUserId;
  const [selected, setSelected] = React.useState<string>("");
  const [sapId, setSapId] = React.useState<string>();
  const [isEdited, setIsEdited] = React.useState<boolean>(false);
  const [apiResponseText, setApiResponseText] = React.useState('');
  const [tempSetCustomer, TempSetCustomer] = React.useState<any>(["New", "Existing"]);
  const [loading, setLoading] = React.useState<any>(true);
  const leftMenuVisibility: any = useSelector(
    (state: any) => state.global && state.global.sideMenuOpened
  );
  const [deleteMessage, setDeleteMessage] = React.useState<any>();
  const [formSubmitted, setformSubmitted] = React.useState(false);
  const [openSubmitDialog, setOpenSubmitDialog] = React.useState(false);
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const { shipToPartyAddressData, getSelectedAddress, regionData, updateAddressData, tradeRegionData, deleteAddressData, saveNewAdress, sendToSapAddress, zoneData, dealerDetails, deliveryTypes, getDeliveryTypeData }: any = useSelector((state: any) => state.onboard);
  const [isView, setIsView] = React.useState<any>(false)
  const [editedAddress, setEditAddress] = React.useState({});
  const [sendToSAPPayload, setSendToSAPPayload] = React.useState({});
  const [selectedAddress, setSelectedAddress] = React.useState<any>(null);
  const [addressList, setAddressList] = React.useState<any>([]);
  const [selectedDelivery, setSelectedDelivery] = React.useState<any>("DLV");
  const [isCustomerAcceptingDelivery, setIsCustomerAcceptingDelivery] = React.useState(true);
  const [isDefaultUAE, setIsDefaultUAE] = React.useState("");
  const [salesRegion, setSalesRegion] = React.useState("");
  const [deliveryList, setDeliveryList] = React.useState<any>("");
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState<boolean>(false);
  const [addressIndex, setAddressIndex] = React.useState<any>(false);
  const [confirmationSentToSAP, setConfirmationSentToSAP] = React.useState<boolean>(false);
  const [firstAddressCheck, setFirstAddressCheck] = React.useState<any>(false);
  const [shippingId, setShippingId] = React.useState("");
  const customer: any = sessionStorage.getItem("customerOnboardingType")
  const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
  const displayName = profileDetails && profileDetails.data && profileDetails.data.data && profileDetails.data.data.basicInfo && profileDetails.data.data.basicInfo.displayName;
  const [masterCompanyBrands, setMasterCompanyBrands] = React.useState<any>([]);
  const customerTierType = sessionStorage.getItem('userType') === 'FSE' ? sessionStorage.getItem('customerTierType') ? sessionStorage.getItem('customerTierType') : get(profileDetails, 'data.data.customerInfo.customerTierType', '') : get(profileDetails, 'data.data.customerInfo.customerTierType', '');

  useEffect(() => {
    if (dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Incomplete from KAM' || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Incomplete from FSE" || dealerDetails && dealerDetails.data && dealerDetails.data.status === "Awaiting feedback from FSE") {
      dispatch(getTradeRegion(b2bUserId))
    }
  }, [dealerDetails])

  const handleopenCloseErrorSnackbar = (open?: any) => {
    setOpenErrorSnackbar(open ? true : false);
  };
  const handleopenCloseSnackbar = (open?: any) => {
    setOpenSnackbar(open ? true : false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getNewAddressData = async (data: any) => {
    if (isEdited) {
      let result = await dispatch(UpdatedShipToPartyAddress(b2bUserId, data, selected))
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);
        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }
    }
    else {
      let result = await dispatch(AddShipToPartyAddress(b2bUserId, data));
      const apiResponse = get(result, 'message', {});

      if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
        setOpenSnackbar(true);
        setApiResponseText(apiResponse.message);


        setTimeout(() => {
          setOpenSnackbar(false);
        }, 2000);
      }

      setSelected("");
    }
  }

  const getRegionName = (regionCode: any) => {
    if (regionData && regionData.data && regionData.data.length > 0) {
      var regionName = regionData.data[0].regions.filter((obj: any) => obj.Code == regionCode)
      return regionName && regionName.length > 0 ? regionName[0].Region : '-';
    }
    return "";
  }

  const onClickDeleteAddress = async (shippingAddress: any) => {
    handleopenClose(true);
    setIsEdited(false);
    setSelected("");
    setShippingAddress(shippingAddress);

  }

  const deleteShippingAddress = async (action: any) => {
    handleopenClose(false);
    var userType = {
      "userType": "kam"
    }
    let result: any = await dispatch(deleteShipToPartyAddress(b2bUserId, shippingId, userType));
    const apiResponse = get(result, 'message', {});
    if (apiResponse && apiResponse.status && apiResponse.status.toLowerCase() === "s") {
      setOpenSnackbar(true);
      setApiResponseText(apiResponse.message);
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 2000);
    } else if (result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.status.toLowerCase() === "e") {
      handleopenClose(false);
      setOpenErrorSnackbar(true);
      setApiResponseText(result && result.statusText && result.statusText.data && result.statusText.data.message && result.statusText.data.message.message);
      setTimeout(() => {
        setOpenErrorSnackbar(false);
      }, 2000);
    }

  }


  const isValidShipToPartyTab = () => {
    if (customer === "Int. Business") {
      return true
    }
    if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0 && customer !== "Government" && customer !== "Int. Business") {
      if (tradeRegionData && tradeRegionData.data && tradeRegionData.data.length > 0 && customer !== "Government") {
        let selected = tradeRegionData.data.filter((data: any) => data.id === addressList[0].tradeLicenseNoId);
        if (selected && selected.length > 0 && selected[0].tradeLicenseNo) {
          return true;
        } else {
          if (customer && customer.toLowerCase() === 'workshop' && selectedDelivery !== "DLV") {
            return true;
          } else {
            setOpenErrorSnackbar(true);
            setSuccessMessage('Please add Trade License Number');
            return false;
          }
        }
      }
    } else {
      if (addressList && addressList.length > 0 && addressList[0].personToAcceptDelivery && addressList[0].personToAcceptDelivery.length > 0) {
        return true;
      } else {
        if (customer && customer.toLowerCase() === 'workshop' && selectedDelivery !== "DLV") {
          return true;
        } else {
          setOpenErrorSnackbar(true);
          setSuccessMessage('Please add Authorized Person to Accept the Deliveries');
          return false;
        }
      }
    }
  }

  const checkShippingDetailsMandotoryFields = (companyBrands: Array<any>) => {
    try {
      let c1Brands: any = companyBrands.filter((b: any) => b.organizationId !== '2003')
      let filteredEmptyArray = c1Brands.filter((shippingDetail: any) => shippingDetail.shippingDetails.priority === '' || shippingDetail.shippingDetails.shippingCondition === '' || shippingDetail.shippingDetails.deliveringPlant === '')

      return filteredEmptyArray.length > 0 ? true : false
    } catch (error: any) {
      console.log("Error Handling", error.message);
      return true
    }
  }

  const onClickSendToSAP = async (addressIndex: any) => {
    let address = [...addressList]
    setSelectedAddress(address[addressIndex]);
    let newSelectedAddress = address[addressIndex] || selectedAddress;
    let payloadData: any = {};

    if ((!newSelectedAddress.transportationZone && newSelectedAddress.transportationZone === "") || !newSelectedAddress.shippingLabels || ((newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels.length === 0) || (newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels[0] && newSelectedAddress.shippingLabels[0].value === "") || (checkShippingDetailsMandotoryFields(newSelectedAddress.companyBrands)))) {
      setOpenErrorSnackbar(true);
      setSuccessMessage('Please fill all the mandatory data')
      setOpenConfirmDialog(false)
      return true
    } else if ((newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels[0] && newSelectedAddress.shippingLabels[0].value.length > 20) || (newSelectedAddress.shippingLabels && newSelectedAddress.shippingLabels[1] && newSelectedAddress.shippingLabels[1].value.length > 20)) {
      setOpenErrorSnackbar(true);
      setSuccessMessage('Shipping label value should not more than 20 digits.')
      setOpenConfirmDialog(false)
      return true
    }
    if (newSelectedAddress && newSelectedAddress.type === "New") {
      /** Removed NOT default condition  */
      if (newSelectedAddress.sapCustomerId === '' && newSelectedAddress.dafault && newSelectedAddress.shiftToPartyCustomerNumber === '') {
        newSelectedAddress.showSapId = false;
        props.setSendToSAP(true);
        let payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": false,
          "shiftToPartyCustomerNumber": "",
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": b2bUserId,
          "action": "save_in_db",                       //when ship to party number & sapCustomerId is blank 
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress?.companyBrands.filter((b: any) => b.organizationId !== '2003')
        }
        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getShipToPartyAddress(b2bUserId));
        } else {
          setApiResponseText(response && response.error);
          handleopenCloseErrorSnackbar(true);
        }
      }
      else if (newSelectedAddress.sapCustomerId !== '' && newSelectedAddress.shiftToPartyCustomerNumber === '') {
        newSelectedAddress.showSapId = true;
        props.setSendToSAP(true);
        payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": false,
          "shiftToPartyCustomerNumber": "",
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": b2bUserId,
          "action": "add_in_sap",
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress.companyBrands.filter((b: any) => b.organizationId !== '2003')
        };
        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getShipToPartyAddress(b2bUserId));
        } else {
          setOpenErrorSnackbar(true);
          setSuccessMessage(response && response.error);
        }
      }
    }
    else {
      if (newSelectedAddress.shiftToPartyCustomerNumber !== '' && newSelectedAddress.sapCustomerId !== '') {
        newSelectedAddress.showSapId = true;
        props.setSendToSAP(true);
        payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": true,
          "shiftToPartyCustomerNumber": newSelectedAddress.shiftToPartyCustomerNumber,
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": b2bUserId,
          "action": "update_in_sap",
          "shippingLabels": newSelectedAddress.shippingLabels,
          "companyBrands": newSelectedAddress.companyBrands.filter((b: any) => b.organizationId !== '2003')
        };

        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getShipToPartyAddress(b2bUserId));
        } else {
          setOpenErrorSnackbar(true);
          setSuccessMessage(response && response.error);
        }
      } else if (!newSelectedAddress.dafault && newSelectedAddress.shiftToPartyCustomerNumber === '' && newSelectedAddress.sapCustomerId !== '') {
        newSelectedAddress.showSapId = true;
        props.setSendToSAP(true);
        payloadData = {
          "addressId": newSelectedAddress.id,
          "isExisting": true,
          "shiftToPartyCustomerNumber": "",
          "transportationZoneCode": newSelectedAddress.transportationZoneCode,
          "transportationZone": newSelectedAddress.transportationZone,
          "id": b2bUserId,
          "action": "update_in_sap"
        };
        let response: any = await dispatch(sendToSAP(payloadData));
        if (response && response.message && response.message.status === 'S') {
          props.setDataUpdated(false);
          props.setSendToSAP(false);
          setApiResponseText(response.message.message);
          handleopenCloseSnackbar(true);
          dispatch(getShipToPartyAddress(b2bUserId));
        } else {
          setOpenErrorSnackbar(true);
          setSuccessMessage(response && response.error);
        }
      }
    }
    setOpenConfirmDialog(false)
  }

  const handleopenClose = (open?: any) => {
    setOpenDeleteDialog(open ? true : false);
    setSuccessMessage(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setIsEdited(false);
    setEditAddress([]);
  };

  const onClickEditAddress = async (addressId: any) => {
    setIsEdited(true);
    await dispatch(getSelectedShipToPartyAddress(b2bUserId, addressId));
    setSelected(addressId);
    setOpen(true);
  }

  function getZoneCode(zoneName: any) {
    let zoneCode: any = '';
    if (zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone
      && zoneData.data[0].transportationZone.length > 0)
      zoneData.data[0].transportationZone.map((zone: any) => {
        if (zone.title === zoneName) {
          zoneCode = zone.code
        }
      });
    return zoneCode;
  }


  const onChangeZone = async (e: any, data: any, index: any, sapKeys: string, companyDetailsIndex?: any) => {
    props.setDataUpdated(true);
    // setConfirmationSentToSAP(true);
    /**** TO DO: JAYAPRAKASH COMMENT On the head office address we are updating api on each click.. Let user wait to update the shipping label also... Need to verify this scenerio to Bhargavi or with tester teams */
    let address = [...addressList]
    if (e.target.value && e.target.value && sapKeys === 'transportationZone') {
      setSelectedAddress(address[index]);
      /** CODE WAS COMMENTED TO AVOID CALLING THE SENDTOSAP API FOR HEAD OFFICE NEW ADDRES */
      // if (address[index].type == "New") {
      //   if (address[index].sapCustomerId === '' && address[index].dafault && address[index].shiftToPartyCustomerNumber === '') {
      //     address[index].showSapId = false;
      //     props.setSendToSAP(false);
      //     let payloadData = {
      //       "addressId": address[index].id,
      //       "isExisting": false,
      //       "shiftToPartyCustomerNumber": "",
      //       "transportationZoneCode": getZoneCode(e.target.value),
      //       "transportationZone": e.target.value,
      //       "id": b2bUserId,
      //       "action": "save_in_db"                       //when ship to party number & sapCustomerId is blank 
      //     }
      //     let response: any = await dispatch(sendToSAP(payloadData));
      //     if (response && response.message && response.message.status === 'S') {
      //       props.setDataUpdated(false);
      //       props.setSendToSAP(false);
      //       setApiResponseText(response.message.message);
      //       handleopenCloseSnackbar(true);
      //       dispatch(getShipToPartyAddress(b2bUserId));
      //     } else {
      //       setApiResponseText(response && response.error);
      //       handleopenCloseErrorSnackbar(true);
      //     }
      //   } else {
      //     address[index].showSapId = true;
      //     props.setSendToSAP(true);
      //     address[index].transportationZone = e.target.value;
      //     address[index].transportationZoneCode = getZoneCode(e.target.value);
      //   }
      // }
      // else {
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].transportationZone = e.target.value;
      address[index].transportationZoneCode = getZoneCode(e.target.value);
      // }
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && e.target.value.length <= 20 && sapKeys === 'shippingLabelOne') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      if (address && address[index] && address[index].shippingLabels && address[index].shippingLabels.length > 0 && address[index].shippingLabels.find((ele: any) => ele.name === "SORT1")) {
        address[index].shippingLabels[0].value = e.target.value
      } else {
        address[index].shippingLabels = [
          {
            "name": "SORT1",
            "value": e.target.value
          },
          {
            "name": "SORT2",
            "value": ""
          }
        ]
      }
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && e.target.value.length <= 20 && sapKeys === 'shippingLabelTwo') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      if (address && address[index] && address[index].shippingLabels && address[index].shippingLabels.length > 0 && address[index].shippingLabels.find((ele: any) => ele.name === "SORT2")) {
        address[index].shippingLabels[1].value = e.target.value
      } else {
        address[index].shippingLabels = [
          {
            "name": "SORT1",
            "value": ''
          },
          {
            "name": "SORT2",
            "value": e.target.value
          }
        ]
      }
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && sapKeys === 'priority') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].companyBrands[companyDetailsIndex].shippingDetails.priority = e.target.value
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && sapKeys === 'shippingCondition') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].companyBrands[companyDetailsIndex].shippingDetails.shippingCondition = e.target.value
      setAddressList(address);
    }
    else if (((e.target && e.target.value) || (e.target && e.target.value === "")) && sapKeys === 'maxPartialDeliveriesPerItem') {
      setSelectedAddress(address[index]);
      address[index].showSapId = true;
      props.setSendToSAP(true);
      address[index].companyBrands[companyDetailsIndex].shippingDetails.maxPartialDeliveriesPerItem = e.target.value
      setAddressList(address);
    }
    else if (!e.target.value && sapKeys === 'transportationZone') {
      address[index].shiftToPartyCustomerNumber = "";
      setAddressList(address);
    }
  }

  const handleCustomerAcceptingDelivery = (event: React.ChangeEvent<HTMLInputElement>) => {
    customerAcceptDelivery(event.target.checked, shipToPartyAddressData.data.salesRegion, isDefaultUAE, true)
  };

  const customerAcceptDelivery = async (isCustomerAcceptingDeliveryValue: boolean, salesRegion: string = "", isDefaultUAE: string, updateAPI: boolean) => {
    props.freeZoneProps({
      isDefaultUAE,
      salesRegion,
      isCustomerAcceptingDelivery: isCustomerAcceptingDeliveryValue,
      isIntBuisness: customer === "Int. Business" ? true : false
    })
    setIsCustomerAcceptingDelivery(isCustomerAcceptingDeliveryValue);
    if (updateAPI) {
      let data: any = await dispatch(customerAcceptingDeliveryInUAE({
        "id": b2bUserId, "typeOfData": "acceptDeliveryInUAE", "updatedBy": sessionStorage.getItem('webApplicationLoginId'),
        "updatedByRole": sessionStorage.getItem("userType") === "FSE" ? 'FSE' : 'kam',
        "acceptDeliveryInUAE": isCustomerAcceptingDeliveryValue
      }));
      if (data && data.message && data.message.status === 'S') {
        handleopenClose(false);
        setSuccessMessage("Outsidefreezone is updated")
        handleopenCloseSnackbar(true);
      } else {
        setSuccessMessage(data.error);
        setOpenErrorSnackbar(true);
        setTimeout(() => {
          setOpenErrorSnackbar(false)
        }, 2000);
        handleopenCloseSnackbar(true);
      }
    }

  }

  const onChangeNew = (e: any, data: any, index: any) => {
    // props.setDataUpdated(true);
    if (e.target.value && e.target.value) {
      let address = [...addressList]
      address[index].type = e.target.value;
      if (e.target.value == "New") {
        address[index].isExisting = false;
        address[index].transportationZone = "";
        address[index].transportationZoneCode = getZoneCode(e.target.value);
      }
      else {
        address[index].isExisting = true;
        address[index].shiftToPartyCustomerNumber = "";
      }
      // address[index].transportationZoneCode = getZoneCode(e.target.value);
      setAddressList(address);
    }
  }

  const saveZoneAddress = async () => {
    // props.setTabValue(3);


    // if (status !== 'Incomplete from KAM') {
    //   setIsValid(true);
    //   let isValidTemp = true
    //   let address = [...addressList];
    //   let data: any = {
    //     addresses: []
    //   };
    //   address.map((add: any, index: any) => {
    //     if (index === 0 && add.transportationZone && add.transportationZoneCode && !add.isExisting) {
    //       data.addresses.push({
    //         id: add.id,
    //         transportationZone: add.transportationZone,
    //         transportationZoneCode: add.transportationZoneCode,
    //         isExisting: add.isExisting,
    //       })
    //     }
    //     else if (add.isExisting && add.shiftToPartyCustomerNumber) {
    //       data.addresses.push({
    //         id: add.id,
    //         isExisting: add.isExisting,
    //         shiftToPartyCustomerNumber: add.shiftToPartyCustomerNumber
    //       })
    //     } else {
    //       isValidTemp = false;
    //       setIsValid(false);
    //     }
    //   });
    //   if (isValidTemp && address.length === data.addresses.length) {
    //     let savedZone: any = await dispatch(saveZone(b2bUserId, data));
    //     if (savedZone && savedZone.message && savedZone.message.status === 'S') {
    //       props.setDataUpdated(false);
    //       setApiResponseText(savedZone.message.message);
    //       handleopenCloseSnackbar(true);
    //       return true;
    //     } else {
    //       setApiResponseText(savedZone.error);
    //       handleopenCloseErrorSnackbar(true);
    //       return false;
    //     }
    //   } else {
    //     setApiResponseText('Please fill all mandatory fields');
    //     handleopenCloseErrorSnackbar(true);
    //   }
    // }
  }

  // useEffect(() => {

  //   if ((saveNewAdress && saveNewAdress.data && saveNewAdress.data.data) || (updateAddressData && updateAddressData.data && updateAddressData.data.data) || (deleteAddressData && deleteAddressData.data && deleteAddressData.data.data)) {
  //     dispatch(getShipToPartyAddress(b2bUserId));
  //   }
  // }, [saveNewAdress, updateAddressData, deleteAddressData])



  useEffect(() => {
    // if (saveNewAdress) {
    //   setLoading(false);
    // }
    if (saveNewAdress && saveNewAdress.data && saveNewAdress.data.message) {
      handleopenClose(false);
      setSuccessMessage(saveNewAdress.data.message.message)
      handleopenCloseSnackbar(false);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [saveNewAdress]);

  useEffect(() => {
    if (sessionStorage.getItem('customerOnboardingType') === 'Workshop') {
      dispatch(getDeliveryType());
    }
  }, [])

  useEffect(() => {
    // if (updateAddressData) {
    //   setLoading(false);
    // }
    if (updateAddressData && updateAddressData.data && updateAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(updateAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [updateAddressData]);

  useEffect(() => {
    // if (deleteAddressData) {
    //   setLoading(false);
    // }
    if (deleteAddressData && deleteAddressData.data && deleteAddressData.data.message) {
      handleopenClose(false);
      setSuccessMessage(deleteAddressData.data.message.message)
      handleopenCloseSnackbar(true);
      dispatch(getShipToPartyAddress(b2bUserId));
      props.setDataUpdated(true);
    }
  }, [deleteAddressData]);

  useEffect(() => {
    loadshipDetails();
  }, []);

  const loadshipDetails = async () => {
    setLoading(true);
    props.setDataUpdated(false)
    props.setSendToSAP(false);
    let response: any = await dispatch(getShipToPartyAddress(b2bUserId));
    if (zoneData && zoneData.data && zoneData.data.length > 0) {
    } else {
      await dispatch(getTransportationZone(b2bUserId));
    }
    if (customer && customer.toLowerCase() === 'workshop') {
      await dispatch(getShipToPartyDropDownValues('Workshop'));
    } else {
      await dispatch(getShipToPartyDropDownValues());
    }

    if (response) {
      setLoading(false);
    }
    setSuccessMessage(null)
    handleopenCloseSnackbar(false);
    dispatch(resetValues());
  }

  useEffect(() => {
    if (shipToPartyAddressData && shipToPartyAddressData.data) {
      // setLoading(false);
      // shipToPartyAddressData.data.addresses["type"] = "";
      let mbrands: any = shipToPartyAddressData.data.masterCompanyBrands.filter((b: any) => b.organizationId !== '2003')
      setMasterCompanyBrands(mbrands);
      setAddressList(setaddressData(shipToPartyAddressData.data.addresses));
      setSelectedDelivery(shipToPartyAddressData.data.deliveryType ? shipToPartyAddressData.data.deliveryType : "DLV")
      setSapId(shipToPartyAddressData.data.sapCustomerId);
      let defaultAddress = shipToPartyAddressData.data.addresses.find((address: any) => address.dafault)
      if (defaultAddress) {
        setIsDefaultUAE(defaultAddress.country)
        setSalesRegion(defaultAddress.salesRegion)
        if (isDefaultUAE === "" || isDefaultUAE !== defaultAddress.country) {
          customerAcceptDelivery(shipToPartyAddressData.data.acceptDeliveryInUAE, shipToPartyAddressData.data.salesRegion, defaultAddress.country, false)
        }
      }
    }
    else if (shipToPartyAddressData && shipToPartyAddressData.err) {
      // setLoading(false);
    }
    if (getSelectedAddress && getSelectedAddress.data) {
      // setLoading(false);
      setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    }
    else {
      // setLoading(false);
      setSelected("");
    }
    // if (getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.data) {
    //   // setLoading(false);
    //   // shipToPartyAddressData.data.addresses["type"] = "";
    //   setAddressList(setaddressData(getDataFromSAPOnClickRefresh.data.addresses));
    //   setSelectedDelivery(getDataFromSAPOnClickRefresh.data.deliveryType ? getDataFromSAPOnClickRefresh.data.deliveryType : "DLV")
    //   setSapId(getDataFromSAPOnClickRefresh.data.sapCustomerId);
    //   let defaultAddress = getDataFromSAPOnClickRefresh.data.addresses.find((address: any) => address.dafault)
    //   if (defaultAddress) {
    //     setIsDefaultUAE(defaultAddress.country)
    //     setSalesRegion(defaultAddress.salesRegion)
    //     if (isDefaultUAE === "" || isDefaultUAE !== defaultAddress.country) {
    //       customerAcceptDelivery(getDataFromSAPOnClickRefresh.data.acceptDeliveryInUAE, getDataFromSAPOnClickRefresh.data.salesRegion, defaultAddress.country, false)
    //     }
    //   }
    // }
    // else if (getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.err) {
    //   // setLoading(false);
    // }
    // if (getSelectedAddress && getSelectedAddress.data) {
    //   // setLoading(false);
    //   setEditAddress(getSelectedAddress ? getSelectedAddress.data : {})
    // }
    // else {
    //   // setLoading(false);
    //   setSelected("");
    // }
  }, [shipToPartyAddressData, getSelectedAddress])


  function isDisabled() {

    if (status === 'Incomplete from KAM' || status === "Incomplete from FSE" || status === "Awaiting feedback from FSE" || status === 'Dealer confirmation pending') {
      return false;
    } else {
      return true;
    }
  }

  const handleBackClick = () => {
    props.setTabValue(1)

    if (props.dataUpdated) {
      // setDeleteMessage('Do you wish to proceed without saving the data?');
      // handleopenClose(true);
    } else {
      props.setTabValue(1)
    }
  }

  const handleNextClick = async () => {
    // if (await saveZoneAddress()) {
    if (sessionStorage.getItem("userType") !== "FSE") {
      props.setTabValue(3);
    }
    // }
  }

  const handleopenCloseConfirmPopup = (open?: any) => {
    setConfirmDialog(open ? true : false);
  };

  const changeDealerType = () => {
    handleopenCloseConfirmPopup(true);
  }

  const handleopenCloseSubmit = (open?: any) => {
    setformSubmitted(true);
    // if (validateExistingTab()) {
    //   setOpenSubmitDialog(open ? true : false);
    // }
  };

  function setaddressData(data: any) {
    data.forEach((element: any) => {
      element["type"] = "";
      if (element.isExisting == false) {
        element.type = "New";
        setStatus(dealerDetails && dealerDetails.data && dealerDetails.data.status);
        element.isExisting = false;
      } else {
        element.type = "Existing";
        setStatus(dealerDetails && dealerDetails.data && dealerDetails.data.status);
        element.isExisting = true;
      }
    });
    return data;
  }

  const handleChange = async (e: any) => {
    setSelectedDelivery(e.target.value)
    let data: any = {
      "id": b2bUserId,
      "deliveryType": e.target.value,
      "updatedBy": sessionStorage.getItem('webApplicationLoginId'),
      "updatedByRole": sessionStorage.getItem("userType") === "FSE" ? 'FSE' : 'kam',
    }
    let updatedData: any = await dispatch(updateDeliveryType(data));
    if (updatedData && updatedData.message && updatedData.message.status === 'S') {
      dispatch(getShipToPartyAddress(b2bUserId));
    } else {

    }
  }

  return (
    <Fragment>
      {/* {loading && <FullscreenLoader />} */}
      {loading && <FullscreenLoader />}
      {shipToPartyAddressData && shipToPartyAddressData.loading && <FullscreenLoader />}
      {getDataFromSAPOnClickRefresh && getDataFromSAPOnClickRefresh.loading && <FullscreenLoader />}
      {getSelectedAddress && getSelectedAddress.loading && <FullscreenLoader />}
      {updateAddressData && updateAddressData.loading && <FullscreenLoader />}
      {deleteAddressData && deleteAddressData.loading && <FullscreenLoader />}
      {saveNewAdress && saveNewAdress.loading && <FullscreenLoader />}
      {/* <div className="col-12">
        <div className="d-flex align-items-center mb-2">
          <div className="row-space-start ml-auto">
            <Button
              className="text-info disabledCustomclass"
              startIcon={<AddIcon className="font-size-18" />}
              disabled
              // disabled={shipToPartyAddressData && shipToPartyAddressData.err && shipToPartyAddressData.err.error}
              onClick={() => {
                handleClickOpen();
              }}
            ><span className="onshiftoparty-font">Add New Address
              </span>
            </Button> 
          </div>
        </div>
      </div> */}
      <div className="row">
        {(shipToPartyAddressData && shipToPartyAddressData.err) && (
          <div className="col-12">
            <Alert severity="info">Please submit Complete Registration details prior to Ship to Party!</Alert>
          </div>
        )}
        {customer === "Int. Business" && (
          <FormGroup className="mt-4 pl-4" aria-label="position" row>
            <FormControlLabel
              control={<AntSwitch color="primary" checked={isCustomerAcceptingDelivery} onChange={handleCustomerAcceptingDelivery} />}
              label={<h6 className={`${classes.correctionText}`}>Is the customer accepting deliveries within UAE to a PDC authorized delivery address ?&nbsp;</h6>}
              labelPlacement="start"
              disabled={sessionStorage.getItem("userType") === "FSE" && status !== "Submitted by FSE" ? false : true}
            />
          </FormGroup>
        )}
        {sessionStorage.getItem('customerOnboardingType') === 'Workshop' && (
          <FormControl component="fieldset" name="customer-type" className="mt-4 pl-2">
            <h6 className="page-title ml-4" style={{ fontSize: "15px" }}>Delivery Type</h6>
            <RadioGroup className="mt-2 ml-4" row onChange={handleChange} value={selectedDelivery}>
              {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                if (ele.value === "SELF-PIC") {
                  return (<>
                    <FormControlLabel
                      value={ele.value}
                      control={<Radio color="primary" />}
                      label={ele.title} disabled={isDisabled()} />
                  </>)
                } else if (ele.value ===
                  "DLV") {
                  return (<>
                    <FormControlLabel
                      value={ele.value}
                      control={<Radio color="primary" />}
                      label={ele.title}
                      disabled={isDisabled()}
                    />
                  </>)
                }
              })}
            </RadioGroup>
          </FormControl>
        )}
        {sessionStorage.getItem('customerOnboardingType') === 'Workshop' ?
          <>
            <div className='col-12 mb-2 px-2'>
              {deliveryTypes && deliveryTypes.data && deliveryTypes.data[0] && deliveryTypes.data[0].deliveryType.map((ele: any) => {
                if (ele.title === "Self-pickup" && selectedDelivery === "SELF-PIC") {
                  return (<>
                    <h6 className="dealer-table-content mb-0 px-4">{ele.title === "Self-pickup" && selectedDelivery === "SELF-PIC" ? ele.description : ""}</h6>
                  </>)
                } else if (ele.value === "DLV") {
                  return (<>
                    <h6 className="dealer-table-content mb-0 px-4">{ele.title === "Delivery" && selectedDelivery !== "SELF-PIC" ? ele.description : ""}</h6>
                  </>)
                }
              })}
            </div>
            <div className="border-bottom"></div>
          </>
          : ""}
        {addressList && addressList.length > 0 && addressList.map((shipppingAddress: any, index: any) => (
          <>
            <div className="col-12 m-0">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                  <div className="card-body border-bottom pt-1" key={index}>
                    <div className="w-50 d-inline-block">
                      <div className="shipAddTitle">{shipppingAddress.dafault ? shipppingAddress.companyName : shipppingAddress.location} &nbsp;
                        {shipppingAddress.dafault && (
                          <Button
                            className="defaultAddressButton cursor-default"
                            size="small"
                            variant="contained"
                            color="secondary"
                          >Head Office</Button>
                        )}
                      </div>
                    </div>
                    <div className="markDefaultIcon w-50 d-inline-block text-right">
                      {!isDisabled() && (
                        <div>
                          {((sessionStorage.getItem("userType") === "kam" && status === "Incomplete from KAM") || (sessionStorage.getItem("userType") === "FSE" && (status === "Incomplete from FSE" || status === "Awaiting feedback from FSE"))) && <Button
                            className="text-info"
                            // disabled={dealerDetails && dealerDetails.data && dealerDetails.data.status === 'Confirmed from Dealer'}
                            disabled={isDisabled()}
                            startIcon={((shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0) || (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customer === "Int. Business")) ? <EditOutlinedIcon /> : <AddIcon />}
                            onClick={() => {
                              setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                              onClickEditAddress(shipppingAddress.id)
                              setIsView(false)
                            }}><span className="onshiftoparty-font">
                              {((shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0) || (shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && customer === "Int. Business")) ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'}
                            </span>
                          </Button>}
                          {((sessionStorage.getItem("userType") === "kam" && (!shipppingAddress.dafault && status === "Incomplete from KAM" && !shipppingAddress.shiftToPartyCustomerNumber)) || (sessionStorage.getItem("userType") === "FSE" && ((!shipppingAddress.dafault && status === "Incomplete from KAM" && !shipppingAddress.shiftToPartyCustomerNumber) || (!shipppingAddress.dafault && status === "Incomplete from FSE" && !shipppingAddress.shiftToPartyCustomerNumber) || (!shipppingAddress.dafault && status === "Awaiting feedback from FSE" && !shipppingAddress.shiftToPartyCustomerNumber)))) && <span>
                            <Button
                              onClick={() => { setShippingId(shipppingAddress.id); setOpenDeleteDialog(true) }}
                              disabled={shipppingAddress.shiftToPartyCustomerNumber || shipppingAddress.transportationZone || isDisabled() ? true : false}
                              className="text-info"
                              startIcon={<DeleteIcon />}
                            > <span className="onshiftoparty-font">Delete</span>
                            </Button>
                          </span>}
                          {((sessionStorage.getItem("userType") === "kam" && status !== "Incomplete from KAM") || (sessionStorage.getItem("userType") === "fse" && status !== "Incomplete from KAM" && status !== "Incomplete from FSE" && status !== "Awaiting feedback from FSE")) && <Button
                            className="text-info"
                            startIcon={<Visibility />}
                            onClick={() => {
                              onClickEditAddress(shipppingAddress.id)
                              setIsView(true)
                            }}><span className="onshiftoparty-font">View</span>
                          </Button>}
                          {shipppingAddress.shiftToPartyCustomerNumber ?
                            <Tooltip title="Pull real-time information from SAP." placement="top">
                              <Button
                                className="mr-2 text-info"
                                startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                                onClick={async () => {
                                  setLoading(true);
                                  let data: any = await dispatch(getRefreshDataFromSAP("refreshAddress", b2bUserId, shipppingAddress.shiftToPartyCustomerNumber, sessionStorage.getItem('webApplicationLoginId'), displayName.replace(/\s+/g, ' '), shipppingAddress.id));
                                  setLoading(false);
                                  if (data && data.message && data.message.status === "E" || data && data.error || data && data.status === 400) {
                                    setSuccessMessage(data && data.message && data.message.message ? data.message.message : data && data.error ? data.error : "Error to fetch details")
                                    handleopenCloseErrorSnackbar(true);
                                  } else {
                                    dispatch(getShipToPartyAddress(b2bUserId));
                                    handleopenCloseErrorSnackbar(false);
                                    setAddressList(data && data.data && data.data.addresses);
                                    setApiResponseText(data && data.message && data.message.message);
                                    handleopenCloseSnackbar(true);
                                  }
                                }}
                              ><span className="onshiftoparty-font mt-1 mb-2 pr-0">Refresh
                                </span>
                              </Button>
                            </Tooltip>
                            : ''}
                        </div>
                      )}
                      {isDisabled() && (
                        <div>
                          <Button
                            className={status == 'Dealer confirmation pending' ? 'text-info disabledCustomclass' : 'text-info'}
                            disabled={status == 'Dealer confirmation pending'}
                            startIcon={<Visibility />}
                            // startIcon={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? <EditOutlinedIcon /> : <AddIcon />}
                            onClick={() => {
                              setFirstAddressCheck(shipppingAddress && shipppingAddress.firstAddress)
                              onClickEditAddress(shipppingAddress.id)
                              setIsView(true)
                            }}><span className="onshiftoparty-font">
                              View
                              {/* {shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length > 0 ? 'Edit' : 'Add Authorized Person to Accept the Deliveries'} */}
                            </span>
                          </Button>
                          {shipppingAddress.shiftToPartyCustomerNumber ?
                            <Tooltip title="Pull real-time information from SAP." placement="top">
                              <Button
                                className="text-info"
                                startIcon={<RotateLeftIcon className="font-size-18 mb-1" />}
                                onClick={async () => {
                                  setLoading(true);
                                  let data: any = await dispatch(getRefreshDataFromSAP("refreshAddress", b2bUserId, shipppingAddress.shiftToPartyCustomerNumber, sessionStorage.getItem('webApplicationLoginId'), displayName.replace(/\s+/g, ' '), shipppingAddress.id));
                                  setLoading(false);
                                  if (data && data.message && data.message.status === "E" || data && data.error || data && data.status === 400) {
                                    setSuccessMessage(data && data.message && data.message.message ? data.message.message : data && data.error ? data.error : "Error to fetch details")
                                    handleopenCloseErrorSnackbar(true);
                                  } else {
                                    dispatch(getShipToPartyAddress(b2bUserId));
                                    handleopenCloseErrorSnackbar(false);
                                    setAddressList(data && data.data && data.data.addresses);
                                    setApiResponseText(data && data.message && data.message.message);
                                    handleopenCloseSnackbar(true);
                                  }
                                }}
                              ><span className="onshiftoparty-font mt-1">Refresh
                                </span>
                              </Button>
                            </Tooltip>
                            : ''}
                        </div>
                      )}
                    </div>
                    <div>
                      <div className="shipAdd mt-2">{shipppingAddress.streetName ? shipppingAddress.streetName + " - " : ""}  {shipppingAddress.city ? shipppingAddress.city + " - " : ""} {shipppingAddress.country}</div>
                      {sessionStorage.getItem('customerOnboardingType') === 'Workshop' || sessionStorage.getItem('customerOnboardingType') === 'workshop' ? <div className="shipAdd">Contact Telephone Number: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div> : <div className="shipAdd">Telephone Office: {typeof shipppingAddress.telephoneNumber == 'string' ? shipppingAddress.telephoneNumber : shipppingAddress && shipppingAddress.telephoneNumber && shipppingAddress.telephoneNumber.length > 0 && shipppingAddress.telephoneNumber[0].telephone}</div>}
                      {/* <div className="shipAdd">PO Box: {shipppingAddress.poBox}</div> */}
                      <div className="shipAdd">Emirates/Region: {shipppingAddress.region} {getRegionName(shipppingAddress.region)}</div>
                      {customer === "Int. Business" && <div className="shipAdd">IB Sales Region: {salesRegion}</div>}
                      {/* <div className="shipAdd">Shift To Party Customer Number:{" "}{shipppingAddress.shiftToPartyCustomerNumber ? shipppingAddress.shiftToPartyCustomerNumber : '-'}</div> */}
                      <div className="shipAdd">Ship To Party Customer ID:{" "}{shipppingAddress.shiftToPartyCustomerNumber ? shipppingAddress.shiftToPartyCustomerNumber : '-'}</div>
                      {/* <div> */}
                    </div>
                    <div className='card-body row p-0 m-0 mt-2'>
                      <div className="pl-0 col-12 width" style={{ display: "flex" }}>
                        <div className="px-0 col-md-1 col-lg-1 col-1 typeOfCustomer" style={{ display: "flex" }}>
                          {shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE" && (
                            <TextField select id="New"
                              required
                              disabled
                              // error={!shipppingAddress.transportationZone && !isValid}
                              value={shipppingAddress.type}
                              onChange={(e: any) => { onChangeNew(e, shipppingAddress, index); props.setDataUpdated(true); }}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              variant="outlined" margin="dense" >
                              {tempSetCustomer.length > 0 && tempSetCustomer.map((zone: any, index: any) => (
                                <MenuItem value={zone}> {zone} </MenuItem>
                              ))}
                            </TextField>
                          )}
                          {shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE" && (
                            <TextField select id="New"
                              required
                              disabled
                              // error={!shipppingAddress.transportationZone && !isValid}
                              value={shipppingAddress.type}
                              onChange={(e: any) => { onChangeNew(e, shipppingAddress, index); props.setDataUpdated(true); }}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              variant="outlined" margin="dense" >
                              {tempSetCustomer.length > 0 && tempSetCustomer.map((zone: any, index: any) => (
                                <MenuItem value={zone}> {zone} </MenuItem>
                              ))}
                            </TextField>
                          )}
                        </div>
                        <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer" style={{ display: "flex" }}>
                          <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && selectedDelivery !== "SELF-PIC" ?
                            'Customer needs to fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                            {/* {!shipppingAddress.isExisting ? */}
                            <>
                              {shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE" && (
                                <>
                                  <TextField select id="transportationZone"
                                    required
                                    // disabled={selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || shipppingAddress.transportationZone}
                                    disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                    error={!shipppingAddress.transportationZone && !isValid}
                                    value={shipppingAddress.transportationZone}
                                    onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'transportationZone')}
                                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                    label="Transportation Zone" variant="outlined" margin="dense" >
                                    {zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone && zoneData.data[0].transportationZone.length > 0 && zoneData.data[0].transportationZone.map((zone: any, index: any) => (
                                      <MenuItem key={zone.code} value={zone.title}> {zone.title} </MenuItem>
                                    ))}
                                  </TextField>
                                </>
                              )}
                              {shipppingAddress.dafault && (
                                <>
                                  {sessionStorage.getItem("userType") !== "FSE" && (<TextField select id="transportationZone"
                                    required
                                    // disabled={selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || shipppingAddress.transportationZone}
                                    disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.shiftToPartyCustomerNumber && sessionStorage.getItem("userType") !== "kam" ? true : shipppingAddress.sapCustomerId === '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                    error={!shipppingAddress.transportationZone && !isValid}
                                    value={shipppingAddress.transportationZone}
                                    onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'transportationZone')}
                                    size="small" className="col-sm-12 my-2 commonRoundedInputs"
                                    label="Transportation Zone" variant="outlined" margin="dense" >
                                    {zoneData && zoneData.data && zoneData.data.length > 0 && zoneData.data[0].transportationZone && zoneData.data[0].transportationZone.length > 0 && zoneData.data[0].transportationZone.map((zone: any, index: any) => (
                                      <MenuItem key={zone.code} value={zone.title}> {zone.title} </MenuItem>
                                    ))}
                                  </TextField>)}
                                </>
                              )}
                            </>
                            {/* } */}
                          </Tooltip>
                        </div>
                        <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && selectedDelivery !== "SELF-PIC" ?
                          'Customer needs to fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                          {/* {!shipppingAddress.isExisting ? */}
                          <>
                            {shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE" && (
                              <>
                                {customer !== "Int. Business" && shipppingAddress.shiftToPartyCustomerNumber && (
                                  <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer" style={{ display: "flex" }}>
                                    <TextField type="text" id="shipToParty"
                                      required
                                      error={!shipppingAddress.shiftToPartyCustomerNumber && !isValid}
                                      value={shipppingAddress.shiftToPartyCustomerNumber}
                                      // disabled={selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || shipppingAddress.shiftToPartyCustomerNumber}
                                      // disabled={selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                      disabled
                                      onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shipToParty')}  //TODO: Jayaprakash Comment: SInce it's always disbaled why there is a onCHange method it's called here
                                      size="small" className="col-sm-12 my-2 ml-2 commonRoundedInputs"
                                      label="Ship To Party Customer number" variant="outlined" margin="dense" >
                                    </TextField>
                                    {/* <CheckCircleIcon className="order-placed-tick mt-3 ml-1" fontSize="small" /> */}
                                  </div>
                                )}
                              </>
                            )}
                            {shipppingAddress.dafault && (
                              <>
                                {sessionStorage.getItem("userType") !== "FSE" && shipppingAddress.shiftToPartyCustomerNumber && (
                                  <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer" style={{ display: "flex" }}>
                                    <TextField type="text" id="shipToParty"
                                      required
                                      error={!shipppingAddress.shiftToPartyCustomerNumber && !isValid}
                                      value={shipppingAddress.shiftToPartyCustomerNumber}
                                      // disabled={selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 || shipppingAddress.shiftToPartyCustomerNumber}
                                      // disabled={selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                      disabled
                                      onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shipToParty')} //TODO: Disabled still onchange is there
                                      size="small" className="col-sm-12 my-2 ml-2 commonRoundedInputs"
                                      label="Ship To Party Customer number" variant="outlined" margin="dense" >
                                    </TextField>
                                    {/* <CheckCircleIcon className="order-placed-tick mt-3 ml-1" fontSize="small" /> */}
                                  </div>
                                )}
                              </>
                            )}
                          </>
                          {/* } */}
                        </Tooltip>
                        <>
                          {status !== "Incomplete from FSE" && status !== "Awaiting feedback from FSE" && status !== "Submitted by FSE" && shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 && selectedDelivery !== "SELF-PIC" && (
                            // <div className="px-0 col-md-1 col-lg-1 col-1" style={{ display: "flex" }}>
                            <Tooltip title={shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0 ?
                              'Customer needs to fill all the address details before assigning Transportation zone/Ship to party customer number ' : ''} placement="top">
                              <Button
                                className="cursor-normal mb-1 text-info"
                                startIcon={<Help />}
                              >
                              </Button>
                            </Tooltip>
                            // </div>
                          )}
                        </>
                        <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer" style={{ display: "flex" }}>
                          {((shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE") || (shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE")) &&
                            <Tooltip title={'This is the name printed on the shipping label of the handling unit'} placement="top">
                              <TextField type="text" id="ShippingLabelName1"
                                required
                                disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.shiftToPartyCustomerNumber && sessionStorage.getItem("userType") !== "kam" ? true : shipppingAddress.sapCustomerId === '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                                value={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[0] && shipppingAddress.shippingLabels[0].value}
                                helperText={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[0] && shipppingAddress.shippingLabels[0].value.length > 20 ? 'should not more than 20 digits.' : ''}
                                onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shippingLabelOne')}
                                size="small" className="col-sm-12 my-2 ml-2 commonRoundedInputs"
                                label="Shipping Label Name 1" variant="outlined" margin="dense" >
                              </TextField>
                            </Tooltip>}
                        </div>
                        <div className="px-0 col-md-2 col-lg-2 col-2 typeOfCustomer" style={{ display: "flex" }}>
                          {((shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE") || (shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE")) && <Tooltip title={'This is the name printed on the shipping label of the handling unit'} placement="top">
                            <TextField type="text" id="ShippingLabelName2"
                              disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.shiftToPartyCustomerNumber && sessionStorage.getItem("userType") !== "kam" ? true : shipppingAddress.sapCustomerId === '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                              value={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[1] && shipppingAddress.shippingLabels[1].value}
                              helperText={shipppingAddress && shipppingAddress.shippingLabels && shipppingAddress.shippingLabels[1] && shipppingAddress.shippingLabels[1].value.length > 20 ? 'should not more than 20 digits.' : ''}
                              onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shippingLabelTwo')}
                              size="small" className="col-sm-12 my-2 ml-2 commonRoundedInputs"
                              label="Shipping Label Name 2" variant="outlined" margin="dense" >
                            </TextField>
                          </Tooltip>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {((shipppingAddress.sapCustomerId !== '' && !shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE") || (shipppingAddress.dafault && sessionStorage.getItem("userType") !== "FSE")) &&
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                    <div className='row bg-gray-200 p-2 m-0'>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700'><span className='ml-2'>Brand Name</span></div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Delivery Priority</div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Shipping conditions</div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Delivering Plant</div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 font-weight-700 text-center'>Max. partial deliveries</div>
                    </div>
                    {shipppingAddress && shipppingAddress.companyBrands && shipppingAddress.companyBrands.length > 0 && shipppingAddress.companyBrands.map((companyBrand: any, companyBrandIndex: any) => (
                      companyBrand.organizationId !== '2003' ?
                        <div className='row m-0 p-2 border-bottom'>
                          <div className='col-2 col-lg-2 col-md-2 col-sm-2 mt-3'>{sessionStorage.getItem('customerOnboardingType') !== 'Workshop' ? getNewlyAddedBrand(orgIdBrandsMapping[companyBrand.organizationId]) : getNewBrand(orgIdBrandsMapping[companyBrand.organizationId])}</div>
                          <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                            <TextField select id="priority"
                              required
                              disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                              error={!companyBrand.shippingDetails.priority && !isValid}
                              value={companyBrand.shippingDetails.priority}
                              onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'priority', companyBrandIndex)}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              label="Delivery Priority" variant="outlined" margin="dense" >
                              {shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.deliveryPriority && shippingDropdownValues.data.data.deliveryPriority.length > 0 && shippingDropdownValues.data.data.deliveryPriority.map((dp: any, index: any) => (
                                <MenuItem key={dp.value} value={dp.value}> {dp.title} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                            <TextField select id="shippingCondition"
                              required
                              disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                              error={!companyBrand.shippingDetails.shippingCondition && !isValid}
                              value={companyBrand.shippingDetails.shippingCondition}
                              onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'shippingCondition', companyBrandIndex)}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              label="Shipping conditions" variant="outlined" margin="dense" >
                              {shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.shippingConditions && shippingDropdownValues.data.data.shippingConditions.length > 0 && shippingDropdownValues.data.data.shippingConditions.map((dp: any, index: any) => (
                                <MenuItem key={dp.value} value={dp.value}> {dp.title} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                            <TextField id="deliveryPlant"
                              required
                              disabled
                              value={companyBrand.shippingDetails.deliveringPlant}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              label="Delivering Plant" variant="outlined" margin="dense" >
                            </TextField>
                          </div>
                          <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                            <TextField select id="maxPartialDeliveriesPerItem"
                              disabled={shipppingAddress.shiftToPartyCustomerNumber && shipppingAddress.shiftToPartyCustomerNumber !== '' ? true : shipppingAddress && shipppingAddress.shiftToPartyCustomerNumber === "" && sessionStorage.getItem("userType") === "kam" ? false : shipppingAddress.sapCustomerId == '' && !shipppingAddress.dafault ? true : selectedDelivery === "SELF-PIC" ? false : shipppingAddress && shipppingAddress.personToAcceptDelivery && shipppingAddress.personToAcceptDelivery.length === 0}
                              // error={!companyBrand.shippingDetails.maxPartialDeliveriesPerItem && !isValid}
                              value={companyBrand.shippingDetails.maxPartialDeliveriesPerItem}
                              onChange={(e: any) => onChangeZone(e, shipppingAddress, index, 'maxPartialDeliveriesPerItem', companyBrandIndex)}
                              size="small" className="col-sm-12 my-2 commonRoundedInputs"
                              label="Max. partial deliveries" variant="outlined" margin="dense" >
                              {shippingDropdownValues && shippingDropdownValues.data && shippingDropdownValues.data.data && shippingDropdownValues.data.data.maxPartialDeliveries && shippingDropdownValues.data.data.maxPartialDeliveries.length > 0 && shippingDropdownValues.data.data.maxPartialDeliveries.map((dp: any, index: any) => (
                                <MenuItem key={dp.value} value={dp.value}> {dp.title} </MenuItem>
                              ))}
                            </TextField>
                          </div>
                          <div className='col-2 col-lg-2 col-md-2 col-sm-2'>
                            {shipppingAddress.showSapId === true && (shipppingAddress && shipppingAddress.companyBrands && shipppingAddress.companyBrands.length) - 1 === companyBrandIndex ?
                              <Button variant="contained" onClick={() => {
                                setAddressIndex(index);
                                let address = [...addressList]
                                setSelectedAddress(address[index]);
                                if (shipppingAddress.sapCustomerId === '' && shipppingAddress.dafault && shipppingAddress.shiftToPartyCustomerNumber === '') {
                                  onClickSendToSAP(index)
                                } else {
                                  setOpenConfirmDialog(true);
                                }
                              }} color="primary" className="blueActionButton border-left rounded-pill ml-4 mt-2">
                                {shipppingAddress.sapCustomerId === '' && shipppingAddress.dafault && shipppingAddress.shiftToPartyCustomerNumber === '' ? "Save" : "Sent to SAP"}
                              </Button>
                              : ""}
                          </div>
                        </div> : ''
                    ))}
                  </div>
                }
              </div>
            </div>
            {((status === "Incomplete from KAM") || sessionStorage.getItem('userType') === "FSE") && (
              <div className={leftMenuVisibility ? "row card card-body p-2 fixed-bottom ml-280" : "row card card-body p-2 fixed-bottom ml-70"}>
                <div className="col-12 display-flex p-0">
                  <div className="col-8 p-0">
                    <div className={`row m-0`}>
                      {/* <div className="col-5 p-0">
                        <p className="mt-2 ml-4 shipAdd">Dealer Type</p>
                        {dealerDetails.data.status === 'Submitted' ? (
                          <p className="ml-4 mt-2 text-capitalize">{sessionStorage.getItem('customerType')} User</p>
                        ) : (
                          <p className="cursor-pointer ml-4 mt-2" onClick={() => { changeDealerType() }}>
                            <span className="document-content text-capitalize mt-1">{sessionStorage.getItem('customerType')}{' '}
                              Dealer</span><KeyboardArrowDownIcon />{'  '}<span className="info-sub-header-document">Change Type</span>
                          </p>
                        )}
                      </div> */}
                      {(status !== 'Incomplete from KAM' && status !== "Incomplete from FSE" && status !== "Awaiting feedback from FSE" && status !== "Submitted by FSE") && (
                        <div className="col-4 mt-3 p-0">
                          <Button variant="contained" className="border-left rounded-pill ml-5 w-150p mb-2"
                            onClick={() => saveZoneAddress()}
                          >Next</Button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-4 onboard-footer float-right">
                    <div className="p-3 mr-3">
                      <Button variant="contained" className="rounded-pill pl-0 pr-0 w-150p"
                        onClick={() =>
                          handleBackClick()
                        }
                      >Back</Button>
                    </div>
                    <div className="p-3 mr-3">
                      <Button variant="contained" color="primary"
                        disabled={status === "Submitted by FSE"}
                        onClick={() => {
                          // setDeleteMessage("Are you sure you want to switch from the current Dealer type. By doing so, all your saved data will be lost.");
                          // handleopenCloseSubmit(true);
                          if (status === "Incomplete from FSE" || status === "Awaiting feedback from FSE") {
                            if (isValidShipToPartyTab()) {
                              setOpenConfirmationPopup(true)
                            }
                          } else if (status === "Incomplete from KAM") {
                            if (isValidShipToPartyTab()) {
                              handleNextClick();
                            }
                          } else {
                            handleNextClick();
                          }
                        }}
                        className="blueActionButton border-left rounded-pill pr-0 w-150p ml-2 mr-2" >{sessionStorage.getItem("userType") === "FSE" ? "Submit" : props.showSave ? 'Next' : 'Next'}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </>
        ))}
      </div >
      <SubmitDialog
        open={openConfirmationPopup}
        handleopenClose={async (data: any) => {
          if (data === "Submit") {
            if (isValidShipToPartyTab()) {
              if (sessionStorage.getItem("userType") === "FSE") {
                let data = {
                  "b2bUserId": b2bUserId,
                }
                setLoading(true);
                const isSaveAddress: any = await dispatch(submitAddressTab(data));
                setOpenConfirmationPopup(false);
                if (isSaveAddress && isSaveAddress.message) {
                  setLoading(false);
                  setApiResponseText(isSaveAddress.message.message ? isSaveAddress.message.message : 'Customer details save successfully');
                  setOpenSnackbar(true)
                  setTimeout(() => {
                    setOpenSnackbar(false)
                    props.handleSubmit();
                  }, 2000);
                }
              }
            }
          } else {
            setOpenConfirmationPopup(false)
          }
          if (!data) setOpenConfirmationPopup(false)
        }}
      />
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose()
          }
        }}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md">
        <AddNewAddressDailog setOpenDialog={handleClose} firstAddressCheck={firstAddressCheck} getNewAddressData={getNewAddressData} editedAddress={editedAddress} isEdited={isEdited} regionData={regionData} sapId={sapId} masterCompanyBrands={masterCompanyBrands} status={status} selectedDelivery={selectedDelivery} isView={isView} isOutsideFreezone={isCustomerAcceptingDelivery}
          isIntBuisness={customer === "Int. Business" ? true : false} isDefaultUAE={isDefaultUAE} salesRegion={salesRegion} />
      </Dialog>
      <SendToSAPDialog
        open={openConfirmDialog}
        selectedAddress={selectedAddress}
        handleopenClose={(data: any) => {
          if (data === 'success') {
            onClickSendToSAP(addressIndex);
          } else {
            setOpenConfirmDialog(false)
          }
        }}
      />
      <DeleteDialog
        open={openDeleteDialog}
        text="Are you sure you want to delete this shipping address ?"
        handleopenClose={(data: any) => {
          if (data === 'delete') deleteShippingAddress(data);
          if (!data) handleopenClose(false);
        }} />
      {
        apiResponseText && <Snackbar
          open={openSnackbar}
          handleopenClose={() => {
            handleopenCloseSnackbar(false)
          }} message={apiResponseText} />
      }
      {
        <Snackbar
          open={openErrorSnackbar}
          type="error"
          handleopenClose={() => {
            handleopenCloseErrorSnackbar(false)
          }} message={successMessage} />
      }
      {
        ((dealerDetails && dealerDetails.data && dealerDetails.data.status !== "Incomplete from KAM") && (sessionStorage.getItem("userType") !== "FSE")) && (
          <ActionButtons
            isSaveDisabled={true}
            iscorrectionDisabled={((status === "Incomplete from KAM") || (status === "Incomplete from FSE") || (status === "Awaiting feedback from FSE")) ? true : false}
            isapproveDisabled={true}
            isrejectDisabled={status === "Submitted by FSE" ? false : true}
            saveCallback={() => {
              // saveZoneAddress();
            }}
            correctionCallback={() => { }}
            approveCallback={() => { }}
            rejectCallback={() => { }}
            setOpenErrorSnackbar={setOpenErrorSnackbar}
            openErrorSnackbar={openErrorSnackbar}
            handleopenCloseSnackbar={handleopenCloseSnackbar}
            setSuccessMessage={setApiResponseText}
            setDataUpdated={props.setDataUpdated}
          />)
      }
    </Fragment >
  )
}
export default ShipToParty;
