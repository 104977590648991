/* eslint-disable */
import React, { Fragment, useEffect, useState, Dispatch, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import WarningIcon from "@material-ui/icons/Warning";
import { createTheme } from '@material-ui/core/styles';
import Snackbar from '../../common/components/CreatedEnquirySnackbar';
import { useDispatch, useSelector } from "react-redux";
import { get } from 'lodash';
import { useHistory } from "react-router-dom";
import Checkbox from '@material-ui/core/Checkbox';
import { ThemeProvider } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
    icon: {
        float: "left",
        margin: "0 20px 80px 0",
        height: "100%",
        fill: "white",
    },
    createButton: {
        backgroundColor: '#1A9FE0',
        width: 140,
        fontWeight: 800,
        textTransform: 'inherit',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#FFFFFF',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 8
    },
    closeButton: {
        backgroundColor: '#EAEAEA',
        width: 140,
        fontWeight: 800,
        textTransform: 'capitalize',
        fontFamily: 'Nunito',
        fontSize: '0.688rem',
        color: '#666666',
        borderRadius: '2.5rem',
        height: 35,
        textAlign: 'center',
        marginRight: 24
    }
}));
const theme = createTheme({
    palette: {
        secondary: {
            main: '#1A9FE0'
        },
    }
});

export default function SendToSAPDialog(props: any) {
    const dispatch: Dispatch<any> = useDispatch();
    const classes = useStyles();
    const { handleopenClose } = props;
    const history: any = useHistory();
    const [errorMessage, showErrorMessage] = React.useState<any>(null);
    const [openErrorSnackbar, setOpenErrorSnackbar] = React.useState(false);
    const [dealerDetails, setDealerDetails] = React.useState<any>();
    const { customerDetails }: any = useSelector((state: any) => state.onboard);
    const { profileDetails }: any = useSelector((state: any) => state.profileDetails);
    const isExistingUser = get(profileDetails, "data.data.basicInfo.isExistingUser", "");
    const sapCustomerId = dealerDetails && dealerDetails.data && isExistingUser
        ? dealerDetails && dealerDetails.data && dealerDetails.data.sapCustomerId : customerDetails && customerDetails.data && customerDetails.data.customerInformation.customerCode;
    const leftMenuVisibility: any = useSelector(
        (state: any) => state.global && state.global.sideMenuOpened
    );
    const [checkConfirmation, setCheckConfirmation] = React.useState<any>(false);
    const handleopenCloseErrorSnackbar = (open?: any) => {
        setOpenErrorSnackbar(open ? true : false);
    };

    return (
        <div className="float-left">
            <Snackbar
                open={openErrorSnackbar}
                type="error"
                handleopenClose={() => {
                    handleopenCloseErrorSnackbar(false)
                }} message={errorMessage} />
            <Dialog
                open={props.open ? true : false}
                onClose={() => { props.handleopenClose(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <div className="justifyContentHorizontalSpacebetween align-items-center">
                    <h4 className="page-title px-3 ml-2 mt-4 mb-2">Confirmation</h4>
                </div>
                <DialogContent>
                    <div className={classes.icon}>
                        <WarningIcon className="delete-warning" fontSize="small" />
                    </div>
                    <div className="mt-2">
                        <h5 className="submit-dialog-content-SAP-Dialog">By confirming you will create a new Ship-to-Party code in SAP. Once created you will not be able to modify the address details.<br /> Click “Confirm” to proceed or “Cancel” to return. </h5>
                    </div>
                    <div>
                        <DialogContentText id="alert-dialog-description">
                            {/* <div className="mt-2"></div> */}
                            <div className="pr-2 mb-2 pl-0 pt-3 pb-1">
                                <span className="dealer-table-content">Selected Transportation Zone -
                                    <span className="download-offer mr-2 px-2 cursor-pointer">
                                        {props.selectedAddress && props.selectedAddress.transportationZone}
                                    </span>
                                </span>
                            </div>
                        </DialogContentText>
                    </div>
                    <div className='ml-2 px-2'>
                        <DialogContentText id="alert-dialog-description">
                            <div className="mb-2 pl-5 pt-2 pb-2">
                                <ThemeProvider theme={theme}>
                                    <Checkbox
                                        checked={checkConfirmation}
                                        onChange={(e) => {
                                            setCheckConfirmation(e.target.checked)
                                        }}
                                        className="checkbox mb-1" />
                                </ThemeProvider>
                                <span className="dealer-table-content mt-3">Did you check with the Warehouse for the right Transportation zone ?<br/></span>
                            </div>
                        </DialogContentText>
                    </div>
                </DialogContent>
                <DialogActions className="mt-3 mb-3 justify-content-end">
                    <Button variant="contained"
                        disabled={checkConfirmation === false}
                        color="primary"
                        className="blueActionButton border-left rounded-pill"
                        onClick={() => {
                            setCheckConfirmation(false);
                            props.handleopenClose('success');
                        }}>Confirm
                    </Button>
                    <Button variant="contained" className={classes.closeButton}
                        onClick={() => {
                            setCheckConfirmation(false);
                            props.handleopenClose(false);
                        }}>Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

