import { API_ENDPOINTS, NOTIFICATIONS } from "../constants/apiConfig";
import { makeRequest } from "../middleware/axiosMiddleware"

export const notificationsService = {
    getAllNotification,
    MarkNotificationAsRead,
    AddNotification,
    getAllOnbardingNotifications,
    NotifyUserMail
};

async function getAllNotification(sapCustomerID: any, userType: any, page?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + NOTIFICATIONS.API_PATH;
    const options = {
        path: `${apiPath}/${sapCustomerID}?userType=${userType}`,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
            "userId": sessionStorage.getItem('webApplicationLoginId'),
            "Paging-Info": `start-index=0|no-of-records=100`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function getAllOnbardingNotifications(b2bId: any, userType: any, page?: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + NOTIFICATIONS.API_PATH;
    const options = {
        path: `${apiPath}/${b2bId}?userType=${userType}`,
        method: "GET",
        bodyObj: {
        },
        customHeaders: {
            "userId": sessionStorage.getItem('webApplicationLoginId'),
            "Paging-Info": `start-index=0|no-of-records=100`
        },
        formdata: true
    };
    return await makeRequest(options)
}

async function MarkNotificationAsRead(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + NOTIFICATIONS.API_PATH;
    const options = {
        path: apiPath,
        method: "PUT",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function AddNotification(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + NOTIFICATIONS.API_PATH;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}

async function NotifyUserMail(data: any) {
    const apiPath = API_ENDPOINTS.DEALER_ENDPOINT + NOTIFICATIONS.API_PATH + `/specific/${data.sapCustomerId}`;
    const options = {
        path: apiPath,
        method: "POST",
        bodyObj: data,
        customHeaders: {},
        formdata: true
    };
    return await makeRequest(options)
}